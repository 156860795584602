import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import EmailEditor from "react-email-editor";
class DnD extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <h1>DnD Demo</h1>

        <EmailEditor ref={editor => (this.editor = editor)} />
      </React.Fragment>
    );
  };
  // Dynamic Table ---starts

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9"></div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">{this._renderFormArea()}</form>
              <div className="row btn-container form-button"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return { queryData: state.queryData, crudData: state.crudData };
};

export default connect(mapStateToProps)(DnD);
