import React from "react";

const Footer = () => {
  const versionNo = (window && window.config && window.config.VERSIONNO) || '2.0.0'
  return (
    <footer className="col-12 footer-div">
      <span className="dashboard_footer_txt">
        {localStorage.getItem("copyrightyear")} © Toshiba Global Commerce Solutions [deviceid: {localStorage.getItem("randomDeviceId")}]
        {/* [{localStorage.getItem("buildversion")}] */}
        [v{versionNo}]
      </span>
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/toshiba-01.svg`}
        className="dashboard_footer_img"
      />
    </footer>
  );
};
Footer.propTypes = {};

export default Footer;
