import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactSwitch from 'react-switch';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import produce from 'immer';
import TimePicker from 'rc-time-picker';
import {
    crudReset,
    updateStore,
    createStore,
    storeGroupSearchByFields,
    queryCategoryTree,
    createStoreTag,
    updateStoreTag,
    storeTagsSearchByFields,
    searchGeneral,
} from '../../../../actions/actions';
import StatusComp from '../../../../components/StatusComp';
import ConfirmAlert from '../../../../components/ConfirmAlert';
import 'rc-time-picker/assets/index.css';
import DynamicDateTimePicker from '../../../../components/DynamicDateTimePicker';
import {
    formatDate,
    formatLanguageArray,
    toDate,
} from '../../../../helpers/Util';
import Filter from '../../../../components/Filter';
import MultiSelect from '../../../../components/MultiSelect';
import * as CONSTANTS from '../../../../constants/constants';
import SimpleDropdown from '../../../../components/SimpleDropdown';

class AddStore extends React.PureComponent {
    dayoptions = {
        mon: 'monday',
        tue: 'tuesday',
        wed: 'wednesday',
        thu: 'thursday',
        fri: 'friday',
        sat: 'saturday',
        sun: 'sunday',
    };

    // Dropdown options for VAT registered
    vatRegisteredOptions = [
        { name: 'Select', id: '' },
        { name: 'Y', id: 'Y' },
        { name: 'N', id: 'N' },
    ];

    companyListOptions = [
        { name: 'Select', id: '' },
        { name: 'C00001', id: 'C00001' },
    ];

    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.biraccreditedinitialstatus = false;
        this.pendindbirconfigsave = false;
        this.biraccreditedupdatedstatus = false;
        this.biraccreditedupdated = {};
        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            // Formrelated
            companyid: this.companyId,
            title: '',
            title_short: '',
            storeid: '',
            groupid: '',
            storetimezone: '0',
            status: 'New',
            address: '',
            country: '',
            city: '',
            email: '',
            fax: '',
            tel: '',
            mobile: '',
            latitude: '',
            longitude: '',
            storeopentime: '00:00',
            storeclosetime: '23:59',
            basecur: '',
            // selectedStoreGroup: '',
            appid: '',
            taxregid: '',
            inventoryupdate: '',

            // BIR related
            taggroup: 'storeprops',
            tagtype: 'birinfo',
            tagtypevalue: 'store_birinfo',
            birid: '',
            biradditionalfields: '',
            biraccredited: false,
            possupplier: '',
            possupplieraddress: '',
            possuppliertin: '',
            accreditation: '',
            accreditationeffectivedate: new Date(),
            accreditationexpirydate: new Date(),
            vatregistered: '',
            registeredname: '',
            businessname: '',
            businesstin: '',
            branchaddress: '',
            branchcontact: '',
            branchcode: '',
            storetype: '',

            storeidError: '',
            groupidError: '',
            titleError: '',
            basecurError: '',
            storetimezoneError: '',
            taxregidError: '',
            appidError: '',
            inventoryupdateError: '',
            companyidError: '',
            vendor_storeid: '',

            additionalFields: [
                {
                    pickdate: '',
                    starttime: '',
                    endtime: '',
                    id: 0,
                },
            ],
            lastordertime: {},
            bymonth: {},
            closedatearr: [],

            tablecols: [
                { dataFieldId: 'pickdate', label: 'Date', addRow: true },
                { dataFieldId: 'starttime', label: 'Start Time', addRow: true },
                { dataFieldId: 'endtime', label: 'End Time', addRow: true },
                { dataFieldId: 'AD', label: '' },
            ],

            specialDiscounts: {},
            specialDiscount: [],
        };
    }

    componentWillReceiveProps = () => {
        const { specialDiscounts, storeBIRInfo } = this.props.queryData;

        let _specialDiscounts = {};
        if (
            specialDiscounts === undefined ||
            specialDiscounts['subcategories'] === undefined ||
            specialDiscounts['subcategories'].length <= 0
        ) {
        } else {
            specialDiscounts.subcategories.forEach((e) => {
                if (e.catgroup !== undefined && e.catgroup !== '') {
                    _specialDiscounts[[e.catgroup]] = e.catgroup;
                }
            });
        }

        this.setState({ specialDiscounts: _specialDiscounts });

        if (storeBIRInfo && storeBIRInfo.length > 0) {
            this._updateBIRInfo(storeBIRInfo[0]);
        }
    };

    componentDidUpdate = (prevProps) => {
        const { specialDiscounts, storeBIRInfo } = this.props.queryData;
        const {
            specialDiscounts: prevspecialDiscounts,
            storeBIRInfo: prevstoreBIRInfo,
        } = prevProps.queryData;
        if (specialDiscounts !== prevspecialDiscounts) {
            let _specialDiscounts = {};
            if (
                specialDiscounts === undefined ||
                specialDiscounts['subcategories'] === undefined ||
                specialDiscounts['subcategories'].length <= 0
            ) {
            } else {
                specialDiscounts.subcategories.forEach((e) => {
                    if (e.catgroup !== undefined && e.catgroup !== '') {
                        _specialDiscounts[[e.catgroup]] = e.catgroup;
                    }
                });
            }

            this.setState({ specialDiscounts: _specialDiscounts });
        }

        if (storeBIRInfo !== prevstoreBIRInfo) {
            if (storeBIRInfo && storeBIRInfo.length > 0) {
                this._updateBIRInfo(storeBIRInfo[0]);
            }
        }
    };

    componentWillMount = () => {
        //clear if there is any existing errors
        const { stores } = this.props.queryData;
        this.setState({ formError: false, formErrorMsg: '' });
        this.props.crudData.errormsg = '';
        this.props.crudData.isSuccess = false;
        this.props.queryData.storeBIRInfo = [];

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            //const birItem = this.props.location.state.birItem

            if ((isEdit || isView) && rec) {
                const {
                    id,
                    groupid,
                    title,
                    title_short,
                    storeid,
                    status,
                    storetimezone,
                    address,
                    country,
                    city,
                    email,
                    fax,
                    tel,
                    mobile,
                    latitude,
                    longitude,
                    basecur,
                    appid,
                    taxregid,
                    inventoryupdate,
                    specialdiscounts,
                    companyid,
                    vendor_storeid,
                } = rec;

                this.setState({
                    id,
                    companyid,
                    groupid,
                    title,
                    status,
                    title_short,
                    storeid,
                    status,
                    storetimezone,
                    address,
                    country,
                    city,
                    email,
                    fax,
                    tel,
                    mobile,
                    latitude,
                    longitude,
                    basecur,
                    vendor_storeid,
                    appid: appid.trim(),
                    taxregid: taxregid.trim(),
                    inventoryupdate: inventoryupdate.trim(),
                    specialDiscount:
                        window.config.SPECIAL_DISCOUNTS_ENABLED &&
                        specialdiscounts !== undefined
                            ? specialdiscounts.split(',')
                            : [],
                });

                this.setState({ storeid }, () => {
                    this._queryStoreBIRInfo();
                });
            } else {
                this.setState({ companyid: this.companyId });
            }
        }

        let queryParam = `status=Active&companyid=${this.companyId}`;
        this.props.dispatch(storeGroupSearchByFields(queryParam));

        if (window.config.SPECIAL_DISCOUNTS_ENABLED) {
            this.props.dispatch(
                queryCategoryTree(
                    '',
                    'SPECIALDISCOUNT',
                    '',
                    CONSTANTS.SPECIAL_DISCOUNT_TREE_SEARCH,
                    CONSTANTS.SPECIAL_DISCOUNT_SEARCH_SUCCESS,
                    CONSTANTS.SPECIAL_DISCOUNT_SEARCH_FAILURE,
                ),
            );
        }

        // if (stores && stores.length === 1) {
        //     this.props.dispatch(
        //         searchGeneral(
        //             'system/v1/store/search/fields',
        //             CONSTANTS.SEARCH_STORE_SUCCESS,
        //             CONSTANTS.SEARCH_STORE_FAILURE,
        //         ),
        //     );
        // }
    };

    _queryStoreBIRInfo = () => {
        if (window.config.BIR_ENABLED) {
            if (this._isViewMode() || this._isEditMode()) {
                let queryParam = `storeid=${this.state.storeid}&taggroup=storeprops&tagtype=birinfo&status=Active`;
                this.props.dispatch(
                    storeTagsSearchByFields(
                        queryParam,
                        CONSTANTS.SEARCH_STORE_BIRINFO_FIELDS_SILENT,
                        CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_SUCCESS,
                        CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_FAILURE,
                    ),
                );
            }
        }
    };

    _isEditMode = () => {
        return (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.isEdit
        );
    };

    _isViewMode = () => {
        return (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.isView
        );
    };

    _updateBIRFields = (birItem) => {
        this.biraccreditedinitialstatus = this.state.biraccredited;

        const { additionalfields } = birItem;

        if (this.state.biraccredited) {
            this.setState(
                {
                    biradditionalfields: additionalfields
                        ? additionalfields
                        : '',
                    possupplier: additionalfields
                        ? additionalfields['birinfo']['possupplier']
                        : '',
                    possupplieraddress: additionalfields
                        ? additionalfields['birinfo']['possupplieraddress']
                        : '',
                    possuppliertin: additionalfields
                        ? additionalfields['birinfo']['possuppliertin']
                        : '',
                    accreditation: additionalfields
                        ? additionalfields['birinfo']['accreditationno']
                        : '',
                    accreditationeffectivedate: additionalfields
                        ? this.toFormatDate(
                              additionalfields['birinfo'][
                                  'accreditationeffectivedate'
                              ],
                          )
                        : new Date(),
                    accreditationexpirydate: additionalfields
                        ? this.toFormatDate(
                              additionalfields['birinfo'][
                                  'accreditationexpirydate'
                              ],
                          )
                        : new Date(),

                    vatregistered: additionalfields
                        ? additionalfields['birinfo']['vatregistered']
                        : '',
                    registeredname: additionalfields
                        ? additionalfields['birinfo']['registeredname']
                        : '',
                    businessname: additionalfields
                        ? additionalfields['birinfo']['businessname']
                        : '',
                    businesstin: additionalfields
                        ? additionalfields['birinfo']['tin']
                        : '',
                    branchaddress: additionalfields
                        ? additionalfields['birinfo']['branchaddress']
                        : '',
                    branchcontact: additionalfields
                        ? additionalfields['birinfo']['branchcontact']
                        : '',
                    branchcode: additionalfields
                        ? additionalfields['birinfo']['branchcode']
                        : '',
                    storetype: additionalfields
                        ? additionalfields['birinfo']['storetype']
                        : '',
                },
                () => {
                    this.biraccreditedinitialstatus = this.state.biraccredited;
                },
            );
        }
    };

    _updateBIRInfo = (birItem) => {
        const { additionalfields } = birItem;

        this.setState(
            {
                birid: birItem['id'] ? birItem['id'] : null,
                biradditionalfields: additionalfields ? additionalfields : '',
                biraccredited: additionalfields
                    ? additionalfields['birenabled'] === 'Y'
                    : false,
            },
            () => {
                this._updateBIRFields(birItem);
            },
        );
    };

    _storeGroupOnChange = (selectedValue) => {
        this.setState({
            groupid: selectedValue,
        });
    };

    _filterStoreGroups = (list) => {
        if (!list) {
            return [];
        }

        const mappedFilters = list.map((listItem, index) => {
            return {
                key: index,
                id: listItem['groupid'],
                name: listItem['title'],
            };
        });

        var uniquelist = mappedFilters.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);
        uniquelist.unshift({ id: '', name: 'Select' });
        return uniquelist;
    };

    _inventoryUpdateOptions = () => {
        return [
            {
                id: '',
                name: 'Select',
            },
            {
                id: 'store',
                name: 'store',
            },
            {
                id: 'invallocation',
                name: 'invallocation',
            },
        ];
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;

        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    // convert the date to the right format for sending
    toFormatDate = (dateStr) => {
        if (dateStr) {
            const dateandtime = dateStr.split(' ');
            const date = dateandtime[0];
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
        return '';
    };

    handleChangeStoreId = (event) => {
        const inputValue = event.target.value;
        const digitsOnly = inputValue.replace(/\D/g, ''); // Remove non-digit characters

        if (digitsOnly.length <= 4) {
            this.setState({ storeid: digitsOnly });
        }
    };

    checkStoreExist = (storeId) => {
        const { stores } = this.props.queryData;
        const isStoreExist = stores.find(
            (element) => element.storeid === storeId,
        );
        if (isStoreExist && isStoreExist.id) {
            return true;
        }
        return false;
    };

    _renderMainFormDetails = () => {
        const { storeGroups } = this.props.queryData;

        return (
            <>
                <h4 className="sub-title">Store Details</h4>
                <div className="grid px-2">
                    <div className="form-group col-4">
                        <label htmlFor="storeid" className="floatLeft required">
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={this.handleChangeStoreId}
                            disabled={this._isEditMode() || this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeidError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="storeid" className="floatLeft">
                            Vendor Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="vendor_storeid"
                            value={this.state.vendor_storeid}
                            onChange={(event) =>
                                this.setState({
                                    vendor_storeid: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="subtitle" className="floatLeft">
                            Sub Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitle"
                            value={this.state.title_short}
                            onChange={(event) =>
                                this.setState({
                                    title_short: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="groupid" className="floatLeft required">
                            Store Group
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="storeGroup"
                                value={this.state.groupid}
                                disabled={true}
                            />
                        ) : (
                            <Filter
                                cssClass="form-control"
                                filterOptions={this._filterStoreGroups(
                                    storeGroups,
                                )}
                                selectedOption={this.state.groupid}
                                className="dynamicDescInp"
                                callbackChangeFilter={(event) => {
                                    this._storeGroupOnChange(
                                        event.target.value,
                                    );
                                }}
                                id="storeGroup"
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.groupidError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="basecur" className="floatLeft required">
                            Currency
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="basecur"
                            value={this.state.basecur}
                            onChange={(event) =>
                                this.setState({
                                    basecur: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.basecurError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label
                            htmlFor="storetimezone"
                            className="floatLeft required"
                        >
                            Store Timezone
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="storetimezone"
                            value={this.state.storetimezone}
                            onChange={(event) =>
                                this.setState({
                                    storetimezone: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.storetimezoneError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label
                            htmlFor="companyid"
                            className="floatLeft required"
                        >
                            Company Id
                        </label>
                        <SimpleDropdown
                            id="companyid"
                            listoptions={this.companyListOptions}
                            selectedOption={this.state.companyid}
                            callbackChangeFilter={(value) => {
                                this.setState({ companyid: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.companyidError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="taxregid" className="floatLeft">
                            Tax ID
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="taxregid"
                            value={this.state.taxregid}
                            onChange={(event) =>
                                this.setState({
                                    taxregid: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.taxregidError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="appid" className="floatLeft">
                            App ID
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="appid"
                            value={this.state.appid}
                            onChange={(event) =>
                                this.setState({ appid: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.appidError}
                        </div>
                    </div>
                    <div className="form-group col-4">
                        <label htmlFor="inventoryupdate" className="floatLeft">
                            Inventory Update
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="inventoryupdate"
                                value={this.state.inventoryupdate}
                                disabled={true}
                            />
                        ) : (
                            <Filter
                                cssClass="form-control"
                                filterOptions={this._inventoryUpdateOptions()}
                                selectedOption={this.state.inventoryupdate}
                                className="dynamicDescInp"
                                callbackChangeFilter={(event) => {
                                    this.setState({
                                        inventoryupdate: event.target.value,
                                    });
                                }}
                                id="inventoryupdate"
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.inventoryupdateError}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    _renderSpecialDiscountInformation = () => {
        return (
            <>
                <h4 className="sub-title">Other Configuration</h4>
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="trxgroup" className="floatLeft">
                            Special Discounts
                        </label>
                        <div className="row">
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="specialdiscount"
                                    value={this.state.specialDiscount}
                                    disabled={true}
                                />
                            ) : (
                                <MultiSelect
                                    filterOptions={formatLanguageArray(
                                        this.state.specialDiscounts,
                                    )}
                                    setSelectedFieldValues={(value) => {
                                        this._handleSpecialDiscount(value);
                                    }}
                                    id="specialdiscount"
                                    itemsSelected={this.state.specialDiscount}
                                    label="Select"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    _handleSpecialDiscount = (value) => {
        this.setState({ specialDiscount: value });
    };

    _renderBIRInformation = () => {
        const isBirEnabled = this.state.biraccredited;
        let birChunk;
        if (isBirEnabled) {
            birChunk = this._renderBIRFields();
        }
        return (
            <>
                <h4 className="sub-title">BIR</h4>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft switch-label"
                        >
                            BIR Accredited
                        </label>
                        <ReactSwitch
                            checked={this.state.biraccredited}
                            onChange={this.handleBIRSwitch}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="discount-tax-switch"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    {birChunk}
                </div>
            </>
        );
    };

    _renderBIRFields = () => {
        return (
            <>
                <div className=" row">
                    <div className="form-group col-12">
                        <label htmlFor="possupplier" className="floatLeft">
                            POS Supplier
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="possupplier"
                            value={this.state.possupplier}
                            onChange={(event) =>
                                this.setState({
                                    possupplier: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-12">
                        <label
                            htmlFor="possupplieraddress"
                            className="floatLeft"
                        >
                            POS Supplier Address
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="possupplieraddress"
                            value={this.state.possupplieraddress}
                            onChange={(event) =>
                                this.setState({
                                    possupplieraddress: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="possuppliertin" className="floatLeft">
                            POS Supplier TIN
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="possuppliertin"
                            value={this.state.possuppliertin}
                            onChange={(event) =>
                                this.setState({
                                    possuppliertin: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="accreditation" className="floatLeft">
                            Accreditation #
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="accreditation"
                            value={this.state.accreditation}
                            onChange={(event) =>
                                this.setState({
                                    accreditation: event.target.value,
                                })
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-6">
                        <label
                            htmlFor="accreditationeffectivedate"
                            className="floatLeft"
                        >
                            Accreditation Effectivity Date
                        </label>
                        <DatePicker
                            onChange={(value) => {
                                this.setState({
                                    accreditationeffectivedate: value,
                                });
                            }}
                            value={this.state.accreditationeffectivedate}
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-6">
                        <label
                            htmlFor="accreditationexpirydate"
                            className="floatLeft"
                        >
                            Accreditation Valid Until
                        </label>
                        <DatePicker
                            minDate={this.state.accreditationeffectivedate}
                            onChange={(value) => {
                                this.setState({
                                    accreditationexpirydate: value,
                                });
                            }}
                            value={this.state.accreditationexpirydate}
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="vatregistered" className="floatLeft">
                            VAT Registered
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="vatregistered"
                                value={this.state.vatregistered}
                                disabled={true}
                            />
                        ) : (
                            <SimpleDropdown
                                id="vatregistered"
                                listoptions={this.vatRegisteredOptions}
                                selectedOption={this.state.vatregistered}
                                callbackChangeFilter={(value) => {
                                    this.setState({ vatregistered: value });
                                }}
                            />
                        )}
                    </div>
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="registeredname" className="floatLeft">
                            Registered Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="registeredname"
                            value={this.state.registeredname}
                            onChange={(event) =>
                                this.setState({
                                    registeredname: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="businessname" className="floatLeft">
                            Business Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="businessname"
                            value={this.state.businessname}
                            onChange={(event) =>
                                this.setState({
                                    businessname: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="businesstin" className="floatLeft">
                            Business TIN
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="businesstin"
                            value={this.state.businesstin}
                            onChange={(event) =>
                                this.setState({
                                    businesstin: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="branchcontact" className="floatLeft">
                            Branch Contact
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="branchcontact"
                            value={this.state.branchcontact}
                            onChange={(event) =>
                                this.setState({
                                    branchcontact: event.target.value,
                                })
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="branchcode" className="floatLeft">
                            Branch Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="branchcode"
                            value={this.state.branchcode}
                            onChange={(event) =>
                                this.setState({
                                    branchcode: event.target.value,
                                })
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-lg-6 col-sm-6">
                        <label htmlFor="storetype" className="floatLeft">
                            Store Type
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storetype"
                            name="storetype"
                            value={this.state.storetype}
                            onChange={(event) =>
                                this.setState({
                                    storetype: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className=" row">
                    <div className="form-group col-12">
                        <label htmlFor="branchaddress" className="floatLeft">
                            Branch Address
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="branchaddress"
                            value={this.state.branchaddress}
                            onChange={(event) =>
                                this.setState({
                                    branchaddress: event.target.value,
                                })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
            </>
        );
    };

    _renderContactInformation = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Contact Information</div>
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="address" className="floatLeft">
                            Address
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={this.state.address}
                            onChange={(event) =>
                                this.setState({ address: event.target.value })
                            }
                            disabled={this._isViewMode()}
                            // maxLength="150"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="country" className="floatLeft">
                            Country
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="country"
                            value={this.state.country}
                            onChange={(event) =>
                                this.setState({ country: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="city" className="floatLeft">
                            City
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            value={this.state.city}
                            onChange={(event) =>
                                this.setState({ city: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="tel" className="floatLeft">
                            Telephone
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="tel"
                            value={this.state.tel}
                            onChange={(event) =>
                                this.setState({ tel: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        {/* <div className="invalid-feedback">{this.state.phoneError}</div> */}
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="fax" className="floatLeft">
                            Fax
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="fax"
                            value={this.state.fax}
                            onChange={(event) =>
                                this.setState({ fax: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="email" className="floatLeft">
                            Email
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            value={this.state.email}
                            onChange={(event) =>
                                this.setState({ email: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.emailError}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderTimePicker = () => {
        let days = Object.values(this.dayoptions);
        return (
            <React.Fragment>
                <div className="row sub-title">Store Hours</div>
                <div className="row">
                    <div className="col-lg-3 col-sm-3 label2">Day</div>
                    <div className="col-lg-3 col-sm-3 label2">Start Time</div>
                    <div className="col-lg-3 col-sm-3 label2">End Time</div>
                </div>
                {days.map((elem) => (
                    <div className="row">
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            {elem}
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.bymonth[
                                            elem.substring(0, 3) + '_starttime'
                                        ],
                                        'HH:mm',
                                    ).isValid()
                                        ? moment(
                                              this.state.bymonth[
                                                  elem.substring(0, 3) +
                                                      '_starttime'
                                              ],
                                              'HH:mm',
                                          )
                                        : ''
                                }
                                name={elem + '_starttime'}
                                onChange={(time) =>
                                    this.handleChange(
                                        time,
                                        elem.substring(0, 3) + '_starttime',
                                    )
                                }
                                showSecond={false}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.bymonth[
                                            elem.substring(0, 3) + '_endtime'
                                        ],
                                        'HH:mm',
                                    ).isValid()
                                        ? moment(
                                              this.state.bymonth[
                                                  elem.substring(0, 3) +
                                                      '_endtime'
                                              ],
                                              'HH:mm',
                                          )
                                        : ''
                                }
                                name={elem + '_endtime'}
                                onChange={(time) =>
                                    this.handleChange(
                                        time,
                                        elem.substring(0, 3) + '_endtime',
                                    )
                                }
                                showSecond={false}
                            />
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    buttonHanlder = (value) => {
        var dateformat = [
            ('0' + value.getDate()).slice(-2) +
                '-' +
                ('0' + (value.getMonth() + 1)).slice(-2) +
                '-' +
                value.getFullYear(),
        ];
        this.setState((prevState) => ({
            closedatearr: [...prevState.closedatearr, ...dateformat],
        }));
    };

    textareaHandler = (val) => {
        const { closedatearr } = this.state;
        let filterdata = closedatearr.filter((item) => item !== val);
        this.setState({
            closedatearr: filterdata,
        });
    };

    _renderStoreHours = () => {
        let checkempty = this.state.closedatearr.filter((e) => e !== '');
        let closedate = [...new Set(checkempty)];
        var contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '200px',
        };

        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-9 col-sm-12">
                        {this.renderTimePicker()}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-10 col-sm-12">
                        <DynamicDateTimePicker
                            columns={this.state.tablecols}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-10 col-sm-12">
                        <label
                            htmlFor="storeclosedate"
                            className="floatLeft label2"
                        >
                            Store Close Date / Holiday
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-7">
                        <DatePicker
                            locale="en-GB"
                            onChange={(value) => {
                                this.setState({ closedate: value }, () => {
                                    this.buttonHanlder(value);
                                });
                            }}
                            value={new Date()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-7 col-sm-12">
                        <div style={contentareastyle}>
                            {closedate.map((datevalue) => {
                                return (
                                    <div
                                        className="text-content"
                                        key={datevalue.toString()}
                                    >
                                        <input
                                            type="text"
                                            value={datevalue}
                                            className="inputfield"
                                            readOnly
                                        />
                                        <button
                                            className="btn-primary btntext"
                                            type="button"
                                            onClick={() =>
                                                this.textareaHandler(datevalue)
                                            }
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderGeneralInformation = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Links</div>
                <div className=" row">
                    <div className="form-group col-12">
                        <label htmlFor="latitude" className="floatLeft">
                            Latitude
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="latitude"
                            value={this.state.latitude}
                            onChange={(event) =>
                                this.setState({ latitude: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="longitude" className="floatLeft">
                            Longitude
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="longitude"
                            value={this.state.longitude}
                            onChange={(event) =>
                                this.setState({ longitude: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="status"
                                value={this.state.status}
                                disabled={true}
                            />
                        ) : (
                            <StatusComp
                                selectedOption={this.state.status}
                                callbackChangeFilter={(v) => {
                                    this.setState({ status: v });
                                }}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <>
                <div className="grid">
                    <div className="col-9">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 ">
                            {this._renderMainFormDetails()}
                        </div>
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mt-4">
                            {this._renderContactInformation()}
                        </div>
                        {window.config.BIR_ENABLED && (
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mt-4">
                                {this._renderBIRInformation()}
                            </div>
                        )}
                    </div>
                    <div className="col-3">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderStatusDetails()}
                        </div>
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mt-4">
                            {this._renderGeneralInformation()}
                        </div>
                        {window.config.SPECIAL_DISCOUNTS_ENABLED && (
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1 mt-4">
                                {this._renderSpecialDiscountInformation()}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/stores"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={this._createStore}
                >
                    {isEditMode ? 'Update' : 'Save'}
                </button>
                <Link
                    to="/ui/stores"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _clearErrorMessages = () => {
        this.setState({
            storeidError: '',
            titleError: '',
            basecurError: '',
            groupidError: '',
            formError: false,
            formErrorMsg: '',
            companyidError: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { storeid, title, groupid, basecur, storetimezone, companyid } =
            this.state;
        let valid = true;
        if (storeid === 0 || storeid.trim().length === 0) {
            this.setState({ storeidError: 'Please enter a storeid' });
            valid = false;
        }

        if (storeid && !this._isEditMode()) {
            const isStoreExist = this.checkStoreExist(storeid);
            if (isStoreExist) {
                this.setState({ storeidError: 'Store already existed.' });
                valid = false;
            }
        }

        if (companyid.trim() === '') {
            this.setState({ companyidError: 'Please enter company id' });
            valid = false;
        }

        if (title.trim() === '') {
            this.setState({ titleError: 'Please enter a title' });
            valid = false;
        }

        if (groupid.trim() === '') {
            this.setState({ groupidError: 'Please enter a valid group id' });
            valid = false;
        }

        if (basecur.trim() === '') {
            this.setState({ basecurError: 'Please enter a Valid Currency' });
            valid = false;
        }

        if (storetimezone.trim() === '') {
            this.setState({
                storetimezoneError: 'Please enter a Valid Timezone',
            });
            valid = false;
        }

        return valid;
    };

    checkValidDates = (obj) => {
        for (var key in obj) {
            let arr = obj[key].split(',');
            if (arr.includes('Invalid date')) {
                obj[key] = '';
            }
        }
        return obj;
    };

    filterkeys(obj, arr) {
        for (const key of Object.keys(obj)) {
            if (!arr.includes(key)) {
                delete obj[key];
            }
        }
        return obj;
    }

    objectbydate = (fields) => {
        const isEmpty = fields.filter(
            (e) => e.pickdate !== '' || e.starttime !== '' || e.endtime !== '',
        );
        if (isEmpty.length > 0) {
            return fields.reduce(
                (acc, cur) => ({
                    ...acc,
                    [toDate(cur.pickdate)]:
                        moment(cur.starttime).format('HH:mm') +
                        ',' +
                        moment(cur.endtime).format('HH:mm'),
                }),
                {},
            );
        }
    };

    updateStateValues = () => {
        const { lastordertime } = this.state;
        let result = Object.entries(lastordertime).reduce(
            (r, [k, v]) => {
                if (v === '') {
                    r.month[k + '_starttime'] = '';
                    r.month[k + '_endtime'] = '';
                    return r;
                }
                var [starttime, endtime] = v.split(',');
                if (k.length === 3) {
                    r.month[k + '_starttime'] = starttime;
                    r.month[k + '_endtime'] = endtime;
                } else if (k === 'closedate') {
                    delete lastordertime[k];
                } else {
                    r.fields.push({
                        pickdate: moment(k, 'DD-MM-YYYY', true).toDate(),
                        starttime: moment(starttime, 'HH:mm'),
                        endtime: moment(endtime, 'HH:mm'),
                        id: r.fields.length,
                    });
                }
                return r;
            },
            { month: {}, fields: [] },
        );
        if (Object.keys(result).length !== 0) {
            let addfields =
                result.fields.length > 0
                    ? result.fields
                    : [{ pickdate: '', starttime: '', endtime: '', id: 0 }];
            this.setState({
                additionalFields: addfields,
                bymonth: result.month,
            });
        }
    };

    handleTime(key, time) {
        const { lastordertime } = this.state;
        let keys = key.split('_');
        let hours = lastordertime[keys[0]].split(',');
        if (keys[1] === 'starttime') {
            hours[0] = time;
        } else {
            hours[1] = time;
        }
        return {
            ...lastordertime,
            [keys[0]]: hours.join(','),
        };
    }

    handleChange(time, name) {
        let timeformat = moment(time).format('HH:mm');
        let updatelastordertime = this.handleTime(name, timeformat);
        this.setState(
            {
                bymonth: {
                    [name]: timeformat,
                },
                lastordertime: updatelastordertime,
            },
            () => {
                this.updateStateValues();
            },
        );
    }

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;
        if (rowLength !== 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id,
            );
            this.setState({ additionalFields: updateValues });
        } else {
            this.setState({
                additionalFields: [
                    { pickdate: '', starttime: '', endtime: '', id: 0 },
                ],
            });
        }
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            pickdate: '',
            starttime: '',
            endtime: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log('Rows After Add', this.state.additionalFields);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'pickdate'
                    ? moment(event, 'DD-MM-YYYY', true).toDate()
                    : moment(event, 'HH:mm'),
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    handleBIRSwitch = (biraccredited) => {
        this.setState({ biraccredited });
    };

    getCreatePayload = () => {
        const {
            companyid,
            title,
            title_short,
            storeid,
            groupid,
            status,
            storetimezone,
            address,
            country,
            city,
            email,
            tel,
            fax,
            latitude,
            longitude,
            basecur,
            appid,
            taxregid,
            inventoryupdate,
            specialDiscount,
            vendor_storeid,
        } = this.state;

        let body = {
            companyid,
            title,
            title_short,
            storeid,
            groupid,
            status,
            storetimezone,
            address,
            country,
            city,
            email,
            tel,
            fax,
            latitude,
            longitude,
            basecur,
            vendor_storeid,
            appid: appid.length <= 0 ? ' ' : appid,
            taxregid: taxregid.length <= 0 ? ' ' : taxregid,
            inventoryupdate:
                inventoryupdate.length <= 0 ? ' ' : inventoryupdate,
            specialdiscounts: window.config.SPECIAL_DISCOUNTS_ENABLED
                ? specialDiscount
                      .filter((ele) => {
                          return ele !== '';
                      })
                      .join(',')
                : null,
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };
        return payload;
    };

    // check if store tag exists
    checkStoreTagExist = () => {
        let queryParam = `storeid=${this.state.storeid}&taggroup=${this.state.taggroup}&tagtype=${this.state.tagtype}&status=Active`;
        this.props.dispatch(storeTagsSearchByFields(queryParam));
    };

    getBirInfo = () => {
        return {
            possupplier: this.biraccreditedupdated.possupplier,
            possupplieraddress: this.biraccreditedupdated.possupplieraddress,
            possuppliertin: this.biraccreditedupdated.possuppliertin,
            accreditationno: this.biraccreditedupdated.accreditationno,
            accreditationeffectivedate:
                this.biraccreditedupdated.accreditationeffectivedate,
            accreditationexpirydate:
                this.biraccreditedupdated.accreditationexpirydate,
            vatregistered: this.biraccreditedupdated.vatregistered ? 'Y' : 'N',
            registeredname: this.biraccreditedupdated.registeredname,
            businessname: this.biraccreditedupdated.businessname,
            branchaddress: this.biraccreditedupdated.branchaddress,
            branchcontact: this.biraccreditedupdated.branchcontact,
            branchcode: this.biraccreditedupdated.branchcode,
            tin: this.biraccreditedupdated.tin,
            storetype: this.biraccreditedupdated.storetype,
        };
    };

    getAdditionalFieldsBIR = () => {
        return {
            birenabled: this.biraccreditedupdatedstatus ? 'Y' : 'N',
            birinfo: this.biraccreditedupdatedstatus ? this.getBirInfo() : {},
        };
    };

    getCreatePayloadBIR = () => {
        const { storeid, terminalid, title, taggroup, tagtype, tagtypevalue } =
            this.state;

        let body = {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status: 'Active',
            additionalfields: this.getAdditionalFieldsBIR(),
        };

        if (this.state.birid !== undefined && this.state.birid != '') {
            body['id'] = this.state.birid;
        }

        const payload = {
            body: body,
        };
        return payload;
    };

    _createStore = () => {
        if (this.validateForm()) {
            //reset before update the value based on the current settings
            this.pendindbirconfigsave = false;

            if (window.config.BIR_ENABLED) {
                if (
                    this.state.biraccredited ||
                    this.biraccreditedinitialstatus
                ) {
                    this.pendindbirconfigsave = true;
                    this.biraccreditedupdatedstatus = this.state.biraccredited;
                    this.biraccreditedupdated = {
                        possupplier: this.state.possupplier,
                        possupplieraddress: this.state.possupplieraddress,
                        possuppliertin: this.state.possuppliertin,
                        accreditationno: this.state.accreditation,
                        accreditationeffectivedate: formatDate(
                            this.state.accreditationeffectivedate,
                        ),
                        accreditationexpirydate: formatDate(
                            this.state.accreditationexpirydate,
                        ),
                        vatregistered: this.state.vatregistered ? 'Y' : 'N',
                        registeredname: this.state.registeredname,
                        businessname: this.state.businessname,
                        branchaddress: this.state.branchaddress,
                        branchcontact: this.state.branchcontact,
                        branchcode: this.state.branchcode,
                        tin: this.state.businesstin,
                        storetype: this.state.storetype,
                    };
                }
            }
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStore(this.getCreatePayload(), this.state.id),
                );
            } else {
                this.props.dispatch(createStore(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Please clear all the errors to continue !!!',
            });
            window.scrollTo(0, 0);
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { createUpdateStoreSuccess, isSuccess } = this.props.crudData;

        let showAlert = createUpdateStoreSuccess;
        if (createUpdateStoreSuccess) {
            if (this.pendindbirconfigsave) {
                if (this.state.birid !== undefined && this.state.birid != '') {
                    this.props.dispatch(
                        updateStoreTag(
                            this.getCreatePayloadBIR(),
                            this.state.birid,
                        ),
                    );
                } else {
                    this.props.dispatch(
                        createStoreTag(this.getCreatePayloadBIR()),
                    );
                }
                showAlert = false;
                this.pendindbirconfigsave = false;
            } else {
                showAlert = createUpdateStoreSuccess || isSuccess;
            }
        }

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="grid pl-3 ml-1 mb-3">
                        <h2 className="page-header">
                            {this._isEditMode()
                                ? 'Edit Store'
                                : this._isViewMode()
                                  ? 'View Store'
                                  : 'Add Store'}
                        </h2>
                    </div>
                    <div className="grid px-4">
                        {this._renderErrorMessage()}
                        {this._renderFormArea()}
                    </div>
                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>
                    <ConfirmAlert
                        show={showAlert}
                        handleClose={this.hideModal}
                        to="/ui/stores"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Store updated successfully'
                                    : 'Store created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStore);
