import React from "react";
import "primeflex/primeflex.css";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Message } from 'primereact/message';

const ReportWarning = () => {
    const msg = (window && window.config && window.config.REPORT_WARNING_MESSAGE) || 'Running and exporting report during store hours could impact on the performance.'
    return <div style={{ marginBottom: '20px'}}>
        <Message severity="warn" text={msg} />
    </div>
};

export default ReportWarning;
