import React from "react";
import { connect } from "react-redux";
import DisplayArticleTemplate from "./../ArticleTemplate/DisplayArticleTemplate";
import {
  updateArticleMode,
  updateArticleModeType,
  queryArticleFormOptions
} from "../../../../actions/actions";
import { renderArticleOptionsFilter } from "./../../../../helpers/Util";

class DisplayApplicationArticles extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articleModeType: "-1"
    };
  }

  componentWillMount = () => {
    this.setState({
      articleModeType: this.props.queryData.articleModeType || "-1"
    });
  };

  componentDidMount = () => {
    this.props.dispatch(queryArticleFormOptions({ body: {
      status: "Active",
      articlegroup: "Page Article"
    }}))
  }

  _renderNoData = () => {
    return (
      <React.Fragment>
        <div className="row noStoreform">
          <div className="col-lg-6 col-sm-12">
            <p className="noStoreHeader">Page Articles</p>
            <p className="noStoreBody">
              User can create or display different page articles here.Choose page article
              to continue.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12">
            <img
              className="noStoreImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/img_vector_product.svg`}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _getComponent = () => {
     const { articleModeType } = this.state;
     if(articleModeType !== -1)
       return <DisplayArticleTemplate articleName={articleModeType}></DisplayArticleTemplate>
  };

  render() {
    const { articleOptions } = this.props.queryData;
    const articleopt = renderArticleOptionsFilter(articleOptions);
    return (
      <React.Fragment>
       
          <div>
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <h2 className="page-header">Page Article Management</h2>
              </div>
              <div className="col-lg-4 col-sm-12 alignRight">               
                <select
                  name="example"
                  className="simpleSearchSelect1"
                  value={this.state.articleModeType}
                  onChange={event => {
                    this.setState({ articleModeType: event.target.value });
                    this.props.dispatch(
                      updateArticleModeType({ body: { mode: event.target.value } })
                    );
                  }}
                  style={{ marginLeft: "2px" }}
                >
                  {articleopt.length !== 0 &&
                    articleopt.map((option, index) => {
                      return (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
        {this.state.articleModeType === "-1"
          ? this._renderNoData()
          : this._getComponent()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayApplicationArticles);
