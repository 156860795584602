import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LinkButton from "../../../components/LinkButton";
import {
  updateWordTranslation,
  createWordTranslation,
  crudReset
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusComp from "../../../components/StatusComp";
import { produce } from "immer";
import DynamicTable from "../../../components/DynamicTable";
import { objtoarr } from "../../../helpers/Util";
import SimpleSelect from "../../../components/SimpleSelect";

class AddWordTranslation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      wrdcode: "",
      language: "",
      langlist: {},
      wrdgroup: "",
      status: "Active",
      additionalFields: [
        {id: 0, field: "", value: ""}
      ],
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      isView: false,

      // Errors
      wrdcodeerror: "",
      wrdgrouperror: ""
    };
  }

  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode() || this._isViewMode()) {
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };
  
  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isView = this.props.location.state.isView;
      if (rec) {
        const {
          id,
          wrdcode,
          wrdgroup,
          translation,
          status
        } = rec;
        let defaultaddField =  [{field: "", value: "", id: 0}];
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.isView = isView;
            draft.wrdcode = wrdcode ? wrdcode : "";
            draft.wrdgroup = wrdgroup ? wrdgroup : "";
            draft.status = status ? status : [];
            draft.additionalFields = typeof translation !== "undefined" &&
            translation !== null &&
            Object.keys(translation).length !== 0        
            ? objtoarr(translation) : 
            defaultaddField 
          })
        );
      }
    }
  };

  handleAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.additionalFields.length;
    let lastele = this.state.additionalFields[rowLength - 1];
    const item = {
        field: "",
        value: "",
        id: lastele.id + 1
    };
    this.setState(
        {
            additionalFields: [...this.state.additionalFields, item]
        },
        () => {
            console.log("Rows After Add", this.state.additionalFields);
        }
    );
};

handleInputChange = (event, dataField, row) => {
    let obj = {
        id: row.id,
        [dataField]:
            dataField === "validation" ? [event.target.value] : event.target.value
    };
    this.setState(
        produce(draft => {
            if (draft.additionalFields.length === 0) {
                draft.additionalFields.push(obj);
            } else {
                let found = false;
                draft.additionalFields.forEach(command => {
                    if (command.id === obj.id) {
                        Object.assign(command, obj);
                        found = true;
                    }
                });
                if (!found) {
                    draft.additionalFields.push(obj);
                }
            }
        }),
        () => { }
    );
};
deleteRow = row => {
    let rowId = row.id;
    const rowLength = this.state.additionalFields.length;

    if (rowLength > 1) {
        /*  this.setState(
          produce(draft => {
            const objIndex = draft.additionalFields.findIndex(
              field => field.id === rowId
            );
            if (objIndex > -1) {
              draft.additionalFields.splice(objIndex, 1);
            }
          })
        ); */
        const updateValues = this.state.additionalFields.filter(
            value => rowId !== value.id
        );

        this.setState({ additionalFields: updateValues });
    }
};

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  getCreatePayload = () => {
    const {
      wrdcode,
      wrdgroup,
      language,
      additionalFields,
      status
    } = this.state;
    let addfield = additionalFields.reduce(function (acc, item) {
      // if(item.field === "" || item.value === ""){
      //     return {}
      // }
      // else {
        if(item.field.trim() !== "" && item.value.trim() !== ""){
          acc[item.field] = item.value
          return acc
        }
          return acc
      }, {})
    let body = {
        wrdcode,
        wrdgroup,
        language,
        status,
        translation: addfield
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createWordTranslation = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateWordTranslation(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createWordTranslation(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };

  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      wrdcodeerror: "",
      wrdgrouperror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { wrdcode, wrdgroup } = this.state;
    let valid = true;
    if (wrdcode.trim() === "") {
      this.setState({
        wrdcodeerror: "Word code is required."
      });
      valid = false;
    } 
    if (wrdgroup.trim() === "") {
      this.setState({
        wrdgrouperror: "Word group is required."
      });
      valid = false;
    }
    return valid;
  };

  _isEditMode = () => {
    return this.state.isEdit;
  };
 
  _isViewMode = () => {
    return this.state.isView;
  };
  
  _renderMainButton = () => {
    if (this._isViewMode()) {
      return (
        <React.Fragment>
          <LinkButton
            to="/ui/word-translation"
            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          >
            {`Close`}
          </LinkButton>
        </React.Fragment>
      );
    }

    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createWordTranslation()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createWordTranslation()}
          >
            Update
          </button>
        )}

        <LinkButton
          to="/ui/word-translation"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          {`Cancel`}
        </LinkButton>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="wrdcode" className="floatLeft">
              Word Code
            </label>
            <input
              type="text"
              className="form-control"
              id="wrdcode"
              value={this.state.wrdcode}
              onChange={event => {
                this.setState({ wrdcode: event.target.value });
              }}
              disabled={this._isViewMode()}
            />
             <div className="invalid-feedback">{this.state.wrdcodeerror}</div>
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
              disabled={this._isViewMode()}
            />
          </div>          
        </div>

        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="wrdgroup" className="floatLeft">
              Word Group
            </label>
            <input
              type="text"
              className="form-control"
              id="wrdgroup"
              value={this.state.wrdgroup}
              onChange={event => {
                this.setState({ wrdgroup: event.target.value });
              }}
              disabled={this._isViewMode()}
            />
            <div className="invalid-feedback">{this.state.wrdgrouperror}</div>
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
              disabled={this._isViewMode()}
            />
          </div>               
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12 noPadding noMargin">
              {this._renderAdditionalFields()}
          </div>       
        </div>
      </React.Fragment>
    );
  };

   hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  _renderAdditionalFields = () => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="form-group col-lg-12 col-sm-12 label2">
                    <label htmlFor="translation" className="floatLeft">
                        Translation
                    </label>
                </div>
                <div className="form-group col-lg-12 col-sm-12">
                    <DynamicTable
                        columns={this._isViewMode() ? [
                          { dataFieldId: "field", label: "Key", addRow: true },
                          { dataFieldId: "value", label: "Value", addRow: true }
                        ] : [
                          { dataFieldId: "field", label: "Key", addRow: true },
                            { dataFieldId: "value", label: "Value", addRow: true },
                            { dataFieldId: "AD", label: "" }
                        ]}
                        rows={this.state.additionalFields}
                        deleteRow={this.deleteRow}
                        addRow={this.handleAddRow}
                        handleInputChange={this.handleInputChange}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isViewMode() ? "Word Translation" :
                this._isEditMode() 
                  ? "Edit Word Translation"
                  : "Add Word Translation"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv text-center">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/word-translation"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode()
                  ? "Word Translation updated successfully!"
                  : "Word Translation created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddWordTranslation);
