import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import {
    searchGeneral,
    crudReset,
    userGroupSearch,
    userRoleSearch,
    updateUser, // props.dispatch(storeSearchByFields(`countryid=${countryId}`))will
    createUser,
    userRoleAccessSearch,
    userAccessSearch,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Modal from 'react-awesome-modal';
import UserRoleAccessTree from '../UserRoles/UserRoleAccessTree';
import { getAccessTypeDesc, getModuleDesc } from '../../../helpers/Util';
import StoreSearch from './StoreSearch';

import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { map } from 'lodash';
import { InputSwitch } from 'primereact/inputswitch';
import { Password } from 'primereact/password';

const statusConfigOptions = [
    { name: 'New', code: 'New' },
    { name: 'Active', code: 'Active' },
    { name: 'Inactive', code: 'Inactive' },
    { name: 'Updated', code: 'Updated' },
    { name: 'Deleted', code: 'Deleted' },
];

const generateOptionList = (list, key, label) =>
    map(list, (item) => ({
        name: item[key],
        code: item[label],
    }));

const AddUser = (props) => {
    const { queryData, location } = props;
    const { isSuccess } = props.crudData;
    const { userGroups } = props.queryData;
    const { state } = location;
    const [userGroupOptionList, setUserGroupOptionList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [record, setRecord] = useState({});
    const [selectedStores, setSelectedStores] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState();
    const [isRoleModalShow, setIsRoleModalShow] = useState(false);
    const [userIdError, setUserIdError] = useState('');
    const [userCodeError, setUserCodeError] = useState('');
    const [userIdCodeError, setUserIdCodeError] = useState('');
    const [isPosUser, setIsPosUser] = useState(false);
    const [prevPassword, setPrevPassword] = useState('');
    const [prevUserCodePassword, setPrevUserCodePassword] = useState('');

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        if (state) {
            const { isEdit, isView, item } = state;

            if (isEdit) {
                setIsEditMode(true);
            }

            if (isView) {
                setIsViewMode(true);
            }

            if (item && item.id) {
                setRecord(item);
                setPrevPassword(item.password);
                setPrevUserCodePassword(item.usercodepassword);
                setSelectedStores(item.store_access.split(','));
            }
        }
    }, [state]);

    useEffect(() => {
        props.dispatch(userGroupSearch(`companyid=${companyId}&status=Active`));
        props.dispatch(userAccessSearch('status=Active'));
    }, []);

    useEffect(() => {
        if (record.usergroup && userGroups.length > 0) {
            const userrole = userGroups.find(
                (ele) => ele.groupcode == record.usergroup
            );

            if (userrole && userrole.roles) {
                getUserRoleAndAccessList(userrole.roles);
            }
        }
    }, [record, userGroups]);

    const getUserRoleAndAccessList = (rolecode) => {
        props.dispatch(userRoleSearch(`rolecode=${rolecode}`));
    };

    useEffect(() => {
        const { userGroups, users, userRoles, usersByuserId } = queryData;
        if (userGroupOptionList.length === 0 && userGroups.length > 0) {
            const userGroupOptions = generateOptionList(
                userGroups,
                'title',
                'groupcode'
            );
            setUserGroupOptionList(userGroupOptions);
            setSelectedUserGroup(
                userGroupOptions.find((t) => t.code == record.usergroup)
            );
        }
        if (
            formik.values.userid !== record.userid &&
            usersByuserId.length > 0 &&
            formik.values.userid.length > 0
        ) {
            let existUserId = usersByuserId.find(
                (u) => u.userid == formik.values.userid
            );
            if (existUserId && existUserId.id) {
                setUserIdError(`User already existed.`);
            }
        }
        if (usersByuserId.length == 0 && formik.values.userid.length > 0) {
            setUserIdError('');
        }
        if (
            formik.values.usercode !== record.usercode &&
            users &&
            users.length > 0 &&
            formik.values.usercode.length > 0
        ) {
            let existUserCode = users.find(
                (u) => u.usercode == formik.values.usercode
            );
            if (existUserCode && existUserCode.id) {
                setUserCodeError(
                    `POS User Code ${formik.values.usercode} already existed.`
                );
            }
        }
        if (users.length == 0) {
            setUserCodeError('');
        }
        if (userRoles.length > 0) {
            setRoles(userRoles);
        }
    }, [queryData]);

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('store_access', selectedStores.join(','));
        }
    }, [isSuccess]);

    useEffect(() => {
        if (userGroupOptionList.length > 0 && record.usergroup) {
            setSelectedUserGroup(
                userGroupOptionList.find((t) => t.code == record.usergroup)
            );
        }
    }, [userGroupOptionList, record]);

    const isNumericRegex = (str) => {
        return /^\d+(\.\d+)?$/.test(str);
    };

    const formik = useFormik({
        initialValues: {
            firstname: record?.firstname || '',
            userid: record?.userid || '',
            password: record?.password || '',
            usergroup: record?.usergroup || '',
            storeid: record?.store_access || '',
            emailid: record?.emailid || '',
            mobileno: record?.mobileno || '',
            usercode: record?.usercode || '',
            usercodepassword: record?.usercodepassword || '',
            companyid: companyId,
            lastname: record?.lastname || '',
            folderaccess: record?.folderaccess || '/',
            status:
                record && record.status
                    ? statusConfigOptions.find(
                          (item) => item.code === record.status
                      )
                    : statusConfigOptions.find(
                          (status) => status.code == 'New'
                      ),
        },
        validate: (data) => {
            let errors = {};
            if (!data.firstname) {
                errors.firstname = 'Name is required.';
            }
            if (!data.emailid) {
                errors.emailid = 'Email is required.';
            }
            if (!selectedUserGroup) {
                errors.usergroup = 'User goup is required.';
            }
            if (!data.storeid) {
                errors.storeid = 'Store is required.';
            }
            if (selectedStores.length < 1) {
                errors.storeid = 'Store is required.';
            }
            if (data.userid && !data.password) {
                errors.password = 'Password is required.';
            }
            if (!isPosUser && !data.userid) {
                errors.userid = 'User id is required.';
            }
            if (data.usercode && !data.usercodepassword) {
                errors.usercodepassword = 'POS Password is required.';
            }
            if (
                !isNumericRegex(data.usercodepassword) &&
                prevUserCodePassword !== data.usercodepassword
            ) {
                errors.usercodepassword = 'POS Password must be number.';
            }
            if (data.userid && data.password.length < 4) {
                errors.password =
                    'Password should be minimum 4 characters long.';
            }
            return errors;
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
                usergroup: selectedUserGroup.code ? selectedUserGroup.code : '',
                status: values.status ? values.status.code : '',
                store_access: selectedStores.join(','),
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            delete data.storeid;
            if (record.password == data.password) {
                delete data.password;
            }
            if (record.usercodepassword == data.usercodepassword) {
                delete data.usercodepassword;
            }
            if (extraValidate(data)) {
                if (isPosUser) {
                    data.userid = data.usercode;
                    data.password = data.usercodepassword;
                }
                if (prevPassword == data.password) {
                    delete data.password;
                }
                handleSubmit(data);
            } else {
                console.log('clear error');
            }
        },
    });

    const extraValidate = (data) => {
        if (!data.usercode) {
            setUserIdCodeError('POS User Code is required');
            return false;
        }
        if (!userCodeError && !userIdError) {
            return true;
        }
        return false;
    };

    const handleSelectUserGroup = (e) => {
        setSelectedUserGroup(e.value);
        const userrole = userGroups.find(
            (ele) => ele.groupcode == e.value.code
        );
        if (userrole && userrole.roles) {
            getUserRoleAndAccessList(userrole.roles);
        }
        formik.setFieldError('usergroup', '');
    };

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleSubmit = (data) => {
        if (isEditMode) {
            props.dispatch(updateUser({ body: data }));
        } else {
            props.dispatch(createUser({ body: data }));
        }
    };

    const handleUserIdChange = (event) => {
        setUserIdCodeError('');
        const userId = event.target.value;
        props.dispatch(
            searchGeneral(
                'system/v1/user/search/fields',
                { body: { userid: userId } },
                'SEARCH_USERS_BY_USERID_SUCCESS',
                'SEARCH_USERS_BY_USERID_FAILURE'
            )
        );
        formik.setFieldValue('userid', event.target.value);
    };

    const handleUserCodeChange = (e) => {
        setUserIdCodeError('');
        if (e.target.validity.valid) {
            const usercode = e.target.value;
            props.dispatch(
                searchGeneral(
                    'system/v1/user/search/fields',
                    { body: { usercode: usercode } },
                    'SEARCH_USERS_SUCCESS',
                    'SEARCH_USERS_FAILURE'
                )
            );
            formik.setFieldValue('usercode', usercode);
        }
    };

    const renderUserType = () => {
        return (
            <>
                {!isPosUser && (
                    <>
                        <div className="field col-6 md:col-6 mb-2">
                            <label htmlFor="userid">User Id*</label>
                            <InputText
                                id="userid"
                                name="userid"
                                value={formik.values.userid}
                                onChange={handleUserIdChange}
                            />
                            {userIdError && (
                                <small className="p-error" id="1">
                                    {userIdError}
                                </small>
                            )}
                            {getFormErrorMessage('userid')}
                        </div>
                        <div className="field col-6 md:col-6 mb-2">
                            <label
                                htmlFor="password"
                                className={classNames({
                                    'p-error': isFormFieldValid('password'),
                                })}
                            >
                                Password
                            </label>
                            <Password
                                id="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                toggleMask
                                feedback={false}
                                disabled={isViewMode}
                            />
                            {getFormErrorMessage('password')}
                        </div>
                    </>
                )}

                <div className="field col-6 md:col-6 mb-2">
                    <label
                        htmlFor="usercode"
                        className={classNames({
                            'p-error': isFormFieldValid('usercode'),
                        })}
                    >
                        POS User Code*
                    </label>
                    <input
                        type="text"
                        pattern="[0-9]*"
                        maxLength="10"
                        className="p-inputtext p-component"
                        id="usercode"
                        name="usercode"
                        value={formik.values.usercode}
                        onChange={handleUserCodeChange}
                        disabled={isViewMode}
                    />
                    {userCodeError && (
                        <small className="p-error" id="2">
                            {userCodeError}
                        </small>
                    )}
                    {userIdCodeError && (
                        <small className="p-error" id="1">
                            {userIdCodeError}
                        </small>
                    )}
                </div>
                <div className="field col-6 md:col-6 mb-2">
                    <label
                        htmlFor="usercodepassword"
                        className={classNames({
                            'p-error': isFormFieldValid('usercodepassword'),
                        })}
                    >
                        POS Password*
                    </label>
                    <Password
                        id="usercodepassword"
                        name="usercodepassword"
                        value={formik.values.usercodepassword}
                        onChange={formik.handleChange}
                        toggleMask
                        feedback={false}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('usercodepassword')}
                </div>
            </>
        );
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="firstname"
                            className={classNames({
                                'p-error': isFormFieldValid('firstname'),
                            })}
                        >
                            Name*
                        </label>
                        <InputText
                            id="firstname"
                            name="firstname"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('firstname')}
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="emailid"
                            className={classNames({
                                'p-error': isFormFieldValid('emailid'),
                            })}
                        >
                            Email Address*
                        </label>
                        <InputText
                            id="emailid"
                            name="emailid"
                            value={formik.values.emailid}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('emailid')}
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="mobileno"
                            className={classNames({
                                'p-error': isFormFieldValid('mobileno'),
                            })}
                        >
                            Mobile No.
                        </label>
                        <InputText
                            id="mobileno"
                            name="mobileno"
                            value={formik.values.mobileno}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="usergroup"
                            className={classNames({
                                'p-error': isFormFieldValid('usergroup'),
                            })}
                        >
                            User Group*
                        </label>
                        <Dropdown
                            id="usergroup"
                            name="usergroup"
                            value={selectedUserGroup}
                            onChange={handleSelectUserGroup}
                            options={userGroupOptionList}
                            optionLabel="name"
                            placeholder="Select Group"
                        />
                        {getFormErrorMessage('usergroup')}
                    </div>
                    <div className="field col-12 md:col-12 mb-2">
                        <label htmlFor="userid" className="w-100">
                            POS User
                        </label>
                        <InputSwitch
                            checked={isPosUser}
                            onChange={(e) => setIsPosUser(e.value)}
                        />
                    </div>
                    {renderUserType()}
                </div>
            </>
        );
    };

    const renderMainButton = () => {
        if (isViewMode) {
            return (
                <Link
                    to={{
                        pathname: '/ui/users',
                        state: { currPage: state ? state.currPage : 1, pageSize: state ? state.pageSize : 10 },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Close
                </Link>
            );
        }
        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Update
                    </button>
                )}

                <Link
                    to={{
                        pathname: '/ui/users',
                        state: { currPage: state ? state.currPage : 1, pageSize: state ? state.pageSize : 10 },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };

    const hideModal = (e) => {
        e.preventDefault();
        props.dispatch(crudReset());
    };

    const onSelectStore = (chosenStores) => {
        let storesToAdd = [];
        chosenStores.forEach((e) => {
            if (!selectedStores.includes(e)) {
                storesToAdd.push(e);
            }
        });

        storesToAdd = storesToAdd.concat(selectedStores);
        if (storesToAdd.length <= 0) {
            return;
        }
        setSelectedStores(storesToAdd);

        const stores = storesToAdd.join(',');
        formik.setFieldValue('storeid', stores);
    };

    const renderStoreSelection = () => {
        const { stores } = props.queryData;

        let selectedStores = [];
        stores.map((store) => {
            if (formik.values.storeid.split(',').includes(store.storeid)) {
                let item = { label: store.title, value: store.storeid };
                selectedStores.push(item);
            }
        });

        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="stores" className="floatLeft required">
                            Store
                        </label>

                        <StoreSearch
                            onSelectStore={onSelectStore}
                            disabled={isViewMode}
                        />
                        {getFormErrorMessage('storeid')}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        {renderSelectedStores()}
                    </div>
                </div>
            </>
        );
    };

    const removeSelectedStore = (event, s) => {
        if (event) {
            event.preventDefault();
        }
        let filterStores = selectedStores.filter((e) => e != s);
        setSelectedStores(filterStores);
    };

    const renderSelectedStores = () => {
        if (selectedStores && selectedStores.length <= 0) {
            return;
        }

        let contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '150px',
        };
        return (
            <div className="row" style={{ marginTop: '0.6em' }}>
                <div style={contentareastyle}>
                    {selectedStores.map((s) => {
                        return (
                            <div
                                style={{
                                    padding: '2px 2px',
                                    margin: '2px 2px',
                                    borderRadius: '4px',
                                    background: '#347bf6',
                                }}
                                key={s}
                            >
                                <label style={{ color: 'white' }}>{s}</label>
                                {!isViewMode && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            removeSelectedStore(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderStatusDetails = () => {
        return (
            <Card style={{ marginBottom: '2em' }}>
                <div className="formgrid grid">
                    <div className="field col flex flex-column">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusConfigOptions}
                            optionLabel="name"
                            placeholder="Select"
                            disabled={isViewMode}
                        />
                    </div>
                </div>
            </Card>
        );
    };

    const showRoleDetails = (e, rolecode) => {
        setIsRoleModalShow(true);
        props.dispatch(
            userRoleAccessSearch(`rolecode=${rolecode}&status=Active`)
        );
        if (e) {
            e.preventDefault();
        }
    };

    const renderUserRoleList = () => {
        return (
            <div
                className="splitFrmDiv noPadding"
                style={{ marginTop: '16px' }}
            >
                <h3 className="page-title">User Roles</h3>
                <div className="no_margin_title_devide" />

                <ul style={{ paddingLeft: '0px', marginTop: '16px' }}>
                    {roles.map((element) => (
                        <li
                            key={element.id}
                            style={{
                                listStyle: 'none',
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={(e) =>
                                showRoleDetails(e, element.rolecode)
                            }
                        >
                            {element.title}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const groupByModules = (userAccess) => {
        return userAccess.reduce((cache, access) => {
            const module = access.module;
            if (module in cache) {
                return { ...cache, [module]: cache[module].concat(access) };
            }
            return { ...cache, [module]: [access] };
        }, {});
    };

    const groupByAccessTypes = (userAccess) => {
        return userAccess.reduce((cache, access) => {
            const accesstype = access.accesstype;
            if (accesstype in cache) {
                return {
                    ...cache,
                    [accesstype]: cache[accesstype].concat(access),
                };
            }
            return { ...cache, [accesstype]: [access] };
        }, {});
    };

    const renderShowDetailsModal = () => {
        const { userAccess, userRolesAccess } = queryData;

        const accesses = userRolesAccess.map((e) => e.accesscode);

        const nodes = [];
        const groupOfAccessTypes = groupByAccessTypes(userAccess);
        for (const [accesstype, access] of Object.entries(groupOfAccessTypes)) {
            const groupOfModules = groupByModules(access);

            const accessnode = {
                value: accesstype,
                label: getAccessTypeDesc(accesstype),
                icon: '',
                showCheckbox: false,
                children: [],
            };
            const accessNodes = [];
            for (const [module, access] of Object.entries(groupOfModules)) {
                const moduleDesc = getModuleDesc(module);
                if (moduleDesc === 'UNDEFINED') {
                    continue;
                }
                const children = [];
                access.forEach((e) => {
                    if (accesses.includes(e.accesscode)) {
                        let child = {
                            value: '',
                            label: '',
                            icon: '',
                            showCheckbox: false,
                        };
                        child.value = e.accesscode;
                        child.label = e.title;
                        children.push(child);
                    }
                });
                if (children.length <= 0) {
                    continue;
                }
                const node = {
                    value: '',
                    label: '',
                    icon: '',
                    showCheckbox: false,
                    children: [],
                };
                node.label = moduleDesc; //  getModuleDesc(module)
                node.value = module;
                node.children = children;
                accessNodes.push(node);
            }

            if (accessNodes.length <= 0) {
                continue;
            }
            accessnode.children = accessNodes;
            nodes.push(accessnode);
        }

        return (
            <Modal
                visible={isRoleModalShow}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => setIsRoleModalShow(false)}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">Access Details</div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={() => setIsRoleModalShow(false)}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            {nodes.length > 0 && (
                                <UserRoleAccessTree nodes={nodes} />
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const renderErrorMessage = () => {
        const { isSuccess, errormsg } = props.crudData;
        if (errormsg && !isSuccess) {
            return (
                <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ marginBottom: '0px' }}
                >
                    {errormsg ? 'Invalid Param' : ''}
                </div>
            );
        }
        return;
    };

    return (
        <>
            {renderShowDetailsModal()}
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {isEditMode ? 'Edit User' : 'Add User'}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">{renderErrorMessage()}</div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="row rowBottom">
                                <div className="col-md-8 col-sm-12 ">
                                    <div className="splitFrmDiv noPadding noMargin">
                                        {renderFormArea()}
                                    </div>
                                    {renderUserRoleList()}
                                </div>
                                <div className="col-md-3 col-sm-12 doubleCol">
                                    <Card style={{ marginBottom: '2em' }}>
                                        {renderStoreSelection()}
                                    </Card>
                                    {renderStatusDetails()}
                                </div>
                            </div>
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {renderMainButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ConfirmAlert
                show={isSuccess}
                handleClose={hideModal}
                to="/ui/users"
                children={
                    <div style={{ padding: '2em', color: 'green' }}>
                        {isEditMode
                            ? 'User updated successfully'
                            : 'User created successfully'}
                    </div>
                }
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddUser);
