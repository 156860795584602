import React from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const FileIntegrationDetail = (props) => {
    const { record, hideDetail } = props;
    const renderMainButton = () => {
        return (
            <>
                <Button
                    label="Cancel"
                    className="p-button-secondary p-button-lg"
                    onClick={() => {
                        hideDetail();
                    }}
                />
            </>
        );
    };

    const detailItemList = [
        { label: 'File Name', val: 'filename' },
        { label: 'File Path', val: 'filepath' },
        { label: 'File Size', val: 'filesize' },
        { label: 'File Stage', val: 'filestage' },
        { label: 'File Group', val: 'filegroup' },
        { label: 'File Type', val: 'filetype' },
        { label: 'File Lines', val: 'filelines' },
        { label: 'Processed Lines', val: 'processedlines' },
        { label: 'Processed', val: 'processed' },
        { label: 'Processed File Path', val: 'processedfilepath' },
        { label: 'Error', val: 'error' },
        { label: 'Error File Path', val: 'errorfilepath' },
        { label: 'Processed On', val: 'processedon' },
        { label: 'Created On', val: 'createdon' },
        { label: 'Last Updated', val: 'lastupdated' },
    ];
    return (
        <>
            <div className="form_height">
                <div className="w-100">
                    <h2 className="page-header my-4 mx-4">
                        File Integration Detail
                    </h2>
                </div>
                <div className="grid px-3">
                    <div className="col-12">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {detailItemList.map((item, key) => (
                                <RecordRow
                                    key={key}
                                    label={item.label}
                                    value={record[item.val]}
                                />
                                // <div className="col-4 mb-2" key={key}>
                                //     <label htmlFor="customerid">
                                //         {item.label}
                                //     </label>
                                //     <InputText
                                //         id="filename"
                                //         name="filename"
                                //         value={record[item.val]}
                                //         readOnly
                                //     />
                                // </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row btn-container form-button">
                <div className="col-sm-12 col-lg-12">{renderMainButton()}</div>
            </div>
        </>
    );
};

const RecordRow = (props) => {
    const { label, value } = props;
    return (
        <div className="col-6 col-md-6 flex">
            <div className="grid w-full p-2">
                <div className="col-3 col-md-3">
                    <label
                        htmlFor="storeid"
                        className="c-blue fw-500 f-16 mini_title"
                    >
                        {label}
                    </label>
                </div>
                <div className="col-9 col-md-9">
                    <label
                        htmlFor="storeid"
                        style={{
                            display: 'block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '100%',
                        }}
                    >
                        {value}
                    </label>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(FileIntegrationDetail);
