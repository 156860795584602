import React, {useCallback, useState} from 'react';
import { Link } from 'react-router-dom';
import { canDelete, canView, canWrite } from '../helpers/Util';

export function CreateEditDeleteHook (iViewUrl, iDeleteUser, allPermissions) {

  const [deleteRecId, setDeleteRecId] = useState(-1)

  const _cellFormatter = useCallback((cell, row, rowIndex, formatExtraData) => {
    //const permissions = localStorage.getItem('permission')
    
    if (row.status === 'Deleted') { return <React.Fragment></React.Fragment> }

    
      return (
        <span>
          {canView(allPermissions) && (
          <Link
            to={{
              pathname: iViewUrl,
              state: { item: row, isEdit: false, isView: true }
            }}
          >
            <img
              alt=''
              title='View'
              className='tableImage'
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
            />
          </Link>
)}
          {canWrite(allPermissions) && (
          <Link
            to={{
              pathname: iViewUrl,
              state: { item: row, isEdit: true }
            }}
          >
            <img
              alt=''
              title='Edit'
              className='tableImage'
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link>
          )}
          {canDelete(allPermissions) && (
          <img
            alt=''
            title='Delete'
            className='tableImage'
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => iDeleteUser(row)}
          />
          )}
        </span>
      );
         
    // else {
    //   return <React.Fragment></React.Fragment>;
    // }
  }, [])

  return {
    cellFormatter: _cellFormatter,
    deleteRecId: deleteRecId,
    setDeleteRecId: setDeleteRecId
  }
}
