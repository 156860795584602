import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import moment from 'moment';
import {
    exportReport,
    searchGeneral,
} from '../../../actions/actions';
import * as CONSTANTS from '../../../constants/constants';
import ConfirmAlert from '../../../components/ConfirmAlert';
import PrimeTable from '../../../components/PrimeTable';
import ReportWarning from '../../../components/ReportWarning';

const eipStatusOptions = [
    { name: 'Select', code: '' },
    { name: 'Error', code: 'error' },
    { name: 'Send', code: 'send' },
    { name: 'Success', code: 'success' },
];

const booleanOptions = [
    { name: 'Select', code: '' },
    { name: 'Y', code: 'Y' },
    { name: 'N', code: 'N' },
];

const ExportEipMessage = (props) => {
    const { hideExport } = props;
    const { jobtasklogs, reportexport } = props.queryData;

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [message, setMessage] = useState('');
    const [showDialog, setShowDialog] = useState(false);

    const renderMainButton = () => {
        return (
            <>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        hideExport();
                    }}
                >
                    Cancel
                </button>
            </>
        );
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
    };

    const handleExport = (data) => {
        const parsedValues = {
            ...data,
            msgstatus: data.msgstatus ? data.msgstatus.code : '',
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        clearEmptyParam(parsedValues);
        const queryObj = { ...parsedValues };
        const queryParams = new URLSearchParams(queryObj).toString();
        setMessage('Exported Sucessfully')
        props.dispatch(
            exportReport(
                '/eip/v1/dx/report/eiptopicmsgoffset/export',
                queryParams
            )
        );
        setShowDialog(true)
    };

    const hideAlertModal = (e) => {
        //this.setState({ message: '', action: '' });
        // setMessage('')
        // e.preventDefault();
        // props.dispatch(resetAction());
        // props.queryData.reportexport = {};
        setShowDialog(false);
        callFetch();
    };

    useEffect(() => {
        callFetch();
    }, []);

    const callFetch = (fetchType) => {
        props.dispatch(
            searchGeneral(
                'system/v1/jobtask/search/fields',
                createFetchUrl(),
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    const createFetchUrl = () => {
        const requestBody = {
            body: {
                pagesize: pageSize,
                pageno: currentPage,
                tasktype: 'eiptopicmsgoffset-report',
            },
        };
        return requestBody;
    };

    const columns = [
        { field: 'status', header: 'Status' },
        { field: 'createdon', header: 'Exported On' },
    ];

    const pathBodyTemplate = (rowData) => {
        const { additionalfields } = rowData;
        return additionalfields.filepath;
    };

    const filterFormatter = (
        row,
        excludedfields = ['pagesize', 'pageno', 'filepath']
    ) => {
        let filters = '';
        if (row.additionalfields) {
            let filterArr = [];
            for (const [key, value] of Object.entries(row.additionalfields)) {
                if (excludedfields.includes(key)) {
                    continue;
                }
                filterArr.push(`${key}=${value}`);
            }
            filters = filterArr.join(' | ');
        }
        return filters;
    };

    const filterBodyTemplate = (row) => filterFormatter(row);

    const actionTemplate = (rowData) => {
        if (rowData.status !== 'exported') {
            return;
        }
        return (
            <div className="flex">
                <img
                    title="View"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                    alt="ViewButtonImage"
                    onClick={() => handleDownload(rowData)}
                />
            </div>
        );
    };

    const handleDownload = (row) => {
        const { filepath } = row.additionalfields;
        var link = document.createElement('a');
        if (link.download !== undefined) {
            var url = localStorage.getItem('imagepath') + filepath;
            link.setAttribute('href', url);

            var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const pageChangeCallback = (currPage) => {
        setCurrentPage(currPage);
        const requestBody = {
            body: {
                pagesize: pageSize,
                pageno: currPage,
                tasktype: 'eiptopicmsgoffset-report',
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/jobtask/search/fields',
                requestBody,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    const pageSizeChangecallback = (pagesize) => {
        setPageSize(pagesize);
        const requestBody = {
            body: {
                pagesize: pagesize,
                pageno: currentPage,
                tasktype: 'eiptopicmsgoffset-report',
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/jobtask/search/fields',
                requestBody,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    return (
        <>
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            <h2 className="page-header">
                                Eip Messages
                                <img
                                    title="Refresh"
                                    onClick={callFetch}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <ExportParamForm handleExport={handleExport} />
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                    <ReportWarning />
                                        <PrimeTable
                                            tablePageSize={pageSize}
                                            //isSearched={this.state.isSearched}
                                            showFilterSearch={false}
                                            list={jobtasklogs}
                                            columns={columns}
                                            actionColumn={actionTemplate}
                                            pageSizeChangecallback={(
                                                pageSize
                                            ) =>
                                                pageSizeChangecallback(pageSize)
                                            }
                                            pageChangeCallback={(currPage) => {
                                                pageChangeCallback(currPage);
                                            }}
                                        >
                                            <Column
                                                field="path"
                                                header="Path"
                                                body={pathBodyTemplate}
                                                sortable
                                                style={{
                                                    wordBreak: 'break-word',
                                                }}
                                            ></Column>
                                            <Column
                                                field="filters"
                                                header="Filters"
                                                body={filterBodyTemplate}
                                            />
                                        </PrimeTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmAlert
                    show={showDialog}
                    handleClose={hideAlertModal}
                    children={
                        <div
                            style={
                                reportexport &&
                                reportexport.status === 'running'
                                    ? { padding: '2em', color: 'green' }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {reportexport && reportexport.status === 'running'
                                ? message
                                : ''}
                        </div>
                    }
                />
            </>
            <div className="row btn-container form-button">
                <div className="col-sm-12 col-lg-12">{renderMainButton()}</div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

const ExportParamForm = (props) => {
    const { handleExport } = props;
    const formik = useFormik({
        initialValues: {
            msgid: '',
            // date_from: new Date(),
            // date_till: new Date(),
            msgDate: new Date(),
            msgstatus: eipStatusOptions.find(
                (status) => status.code == 'error'
            ),
            topic: '',
            consumerid: '',
            msgsend: '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                msgsend: values.msgsend ? values.msgsend.code : '',
                date_from: values.msgDate,
                date_till: values.msgDate
            };
            delete data.msgDate
            //console.log('data is', data)
            handleExport(data);
        },
    });

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgid">Msg Id</label>
                        <InputText
                            id="msgid"
                            type="text"
                            name="msgid"
                            value={formik.values.msgid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    {/* <div className="field col-3 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div> */}
                    <div className="field col-3 md:col-3">
                        <label htmlFor="date_till">Date</label>
                        <Calendar
                            id="msgDate"
                            value={formik.values.msgDate}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            maxDate={formik.values.msgDate}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgstatus">Message Status</label>
                        <Dropdown
                            id="msgstatus"
                            name="msgstatus"
                            value={formik.values.msgstatus}
                            onChange={formik.handleChange}
                            options={eipStatusOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgsend">Message Send</label>
                        <Dropdown
                            id="msgsend"
                            name="msgsend"
                            value={formik.values.msgsend}
                            onChange={formik.handleChange}
                            options={booleanOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="topic">Topic</label>
                        <InputText
                            id="topic"
                            type="text"
                            name="topic"
                            value={formik.values.topic}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="msgid">Consumer Id</label>
                        <InputText
                            id="consumerid"
                            type="text"
                            name="consumerid"
                            value={formik.values.consumerid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3"
                        style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button
                                type="submit"
                                label="Export"
                                className="p-button-success mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default connect(mapStateToProps)(ExportEipMessage);
