import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import {
    searchGeneral,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from '../../../components/Loading';
import DatePicker from 'react-date-picker';
import Modal from 'react-awesome-modal';
import {
    dateTypeForMember,
    searchFieldForMember,
    operators,
    statusValues,
} from './tablesCols';

class IssueMemberCoupons extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pages: 10,
            currPage: 1,

            search_field_mem: 'storegroup',
            search_condi_mem: 'eq',
            search_value_mem: '',
            date_from_mem: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            date_till_mem: new Date(),
            date_type_mem: 'createdon',
            showSearchDiv: false,
            showMemberSearchDiv: false,
            selected: '',
            openDeleteprompt: false,
            showCustomerSearchDiv: true,
            tableColsMem: [
                {
                    dataField: 'memberid',
                    text: 'Member Id',
                    sort: true,
                },
                {
                    dataField: 'userid',
                    text: 'User Id',
                    sort: true,
                },
                {
                    dataField: 'firstname',
                    text: 'First Name',
                    sort: true,
                },
                {
                    dataField: 'lastname',
                    text: 'Last Name',
                    sort: true,
                },
                {
                    dataField: 'emailid',
                    text: 'Email',
                    sort: true,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },
                {
                    dataField: 'mobileno',
                    text: 'Mobile',
                    sort: true,
                },
            ],
        };
    }

    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.memberid],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x !== row.memberid),
            }));
        }
    };

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map((r) => r.memberid);
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };
    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD_MEM');
    };

    handlefromdateChangeForMem = (value) => {
        this.setState({
            date_from_mem: value,
        });
    };
    handletodateChangeForMem = (value) => {
        this.setState({
            date_till_mem: value,
        });
    };
    // API Calls

    createFetchUrlMem = () => {
        const {
            search_field_mem,
            search_condi_mem,
            search_value_mem,
            date_type_mem,
            pages,
            currPage,
        } = this.state;
        const requestBody = {
            body: {
                search_field: search_field_mem,
                search_condi: search_condi_mem,
                search_value: search_value_mem,
                date_type: date_type_mem,
                pageno: currPage,
                pagesize: pages,
            },
        };
        return requestBody;
    };
    callFetch = (fetchType) => {
        if (fetchType === 'ONLOAD_MEM' || fetchType === 'SEARCH_MEM') {
            const { pages, currPage } = this.state;
            this.props.dispatch(
                searchGeneral(
                    'crm/v1/member/search',
                    this.createFetchUrlMem(pages, currPage),
                    'SEARCH_MEMBER_PROFILE_SUCCESS',
                    'SEARCH_MEMBER_PROFILE_FAILURE'
                )
            );
        } else {
            const { pages, currPage } = this.state;

            this.props.dispatch(
                searchGeneral(
                    'campaign/v1/coupon/transaction/search',
                    this.createFetchUrl(pages, currPage),
                    'SEARCH_MEMBER_PROFILE_SUCCESS',
                    'SEARCH_MEMBER_PROFILE_FAILURE'
                )
            );
        }
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'ONLOAD_MEM':
                this.callFetch('ONLOAD_MEM');
                break;
            case 'SEARCH_MEM':
                this.callFetch('SEARCH_MEM');
                break;
        }
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('ONLOAD_MEM')
        );
    }

    // member Search
    _openSearchDiv1 = () => {
        this.setState({ showMemberSearchDiv: true });
    };
    _closeSearchDiv1 = () => {
        this.setState({ showMemberSearchDiv: false });
    };
    _renderSearchForm1 = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="date_from_mem" className="floatLeft">
                            Date From
                        </label>
                        <DatePicker
                            onChange={this.handlefromdateChangeForMem}
                            value={this.state.date_from_mem}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="date_till_mem" className="floatLeft">
                            Date To
                        </label>
                        <DatePicker
                            onChange={this.handletodateChangeForMem}
                            value={this.state.date_till_mem}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="date_type_mem" className="floatLeft">
                            Date Type
                        </label>
                        <div className="row">
                            <select
                                name="date_type_mem"
                                className="simpleSearchSelect2"
                                value={this.state.date_type_mem}
                                onChange={(event) =>
                                    this.setState({
                                        date_type_mem: event.target.value,
                                    })
                                }
                            >
                                {dateTypeForMember.length != 0 &&
                                    dateTypeForMember.map((option, index) => {
                                        const { name, id } = option;
                                        return (
                                            <option key={index} value={id}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="search_field_mem" className="floatLeft">
                            Search By
                        </label>
                        <div className="row">
                            <select
                                name="search_field_mem"
                                className="simpleSearchSelect2"
                                value={this.state.search_field_mem}
                                onChange={(event) =>
                                    this.setState({
                                        search_field_mem: event.target.value,
                                    })
                                }
                            >
                                {searchFieldForMember.length != 0 &&
                                    searchFieldForMember.map(
                                        (option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="search_condi_mem" className="floatLeft">
                            Search Condition
                        </label>
                        <div className="row">
                            <select
                                name="search_condi_mem"
                                className="simpleSearchSelect2"
                                value={this.state.search_condi_mem}
                                onChange={(event) =>
                                    this.setState({
                                        search_condi_mem: event.target.value,
                                    })
                                }
                            >
                                {operators.length != 0 &&
                                    operators.map((option, index) => {
                                        const { name, id } = option;
                                        return (
                                            <option key={index} value={id}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="search_value_mem" className="floatLeft">
                            Search Value
                        </label>

                        {this.state.search_field_mem !== 'status' ? (
                            <input
                                type="text"
                                value={this.state.query}
                                className="form-control "
                                placeholder="Enter value"
                                onChange={(event) =>
                                    this.setState({
                                        search_value_mem: event.target.value,
                                    })
                                }
                            />
                        ) : (
                            <select
                                name="example"
                                className="form-control"
                                value={this.state.search_value_mem}
                                onChange={(event) =>
                                    this.setState({
                                        search_value_mem: event.target.value,
                                    })
                                }
                            >
                                {statusValues.length != 0 &&
                                    statusValues.map((option, index) => {
                                        const { name, id } = option;
                                        return (
                                            <option key={index} value={id}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                        )}
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.callQueryApiForFetch('SEARCH_MEM');
                                this._closeSearchDiv1();
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.callQueryApiForFetch('ONLOAD_MEM');
                                this._closeSearchDiv1();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderSearchModal1 = () => {
        return (
            <Modal
                visible={this.state.showCustomerSearchDiv}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeSearchDiv1()}
            >
                <div className="modalForm searchModalForm1">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            Customer Search
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this._closeSearchDiv1}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {this._renderSearchForm1()}
                </div>
            </Modal>
        );
    };
    _renderSearchBtn1 = () => {
        return (
            <button
                type="submit"
                className="page-header-btn icon_btn display-inline"
                onClick={(event) => {
                    if (event) event.preventDefault();
                    this._openSearchDiv1();
                }}
            >
                Advanced Customer Filter
            </button>
        );
    };

    renderFilterBtn = () => {
        return (
            <Button
                label="Advanced Customer Filter"
                icon="pi pi-filter"
                className="p-button-outlined"
                onClick={() => this._openSearchDiv1()}
            />
        );
    };

    _renderIssueCouponBtn = () => {
        if (this.state.selected === '') {
            return (
                <Button
                    disabled
                    label="Issue Coupon"
                    icon="pi pi-gift"
                    className="p-button-outlined p-button-success"
                />
            )
        }
        return (
            <Link
                to={{
                    pathname: '/ui/member-coupon/issue-coupon',
                    state: {
                        param: this.state.selected,
                    },
                }}
            >
                <Button
                    disabled={this.state.selected === '' ? true : false}
                    label="Issue Coupon"
                    icon="pi pi-gift"
                    className="p-button-outlined p-button-success"
                />
            </Link>
        );
    };

    handleSelectMember = ids => {
        this.setState({ selected: ids })
    }

    callApi = params => {
        this._callFetch(params)
    }

    _callFetch = params => {
        const { pageno, pagesize } = params
        let body = {
            search_field: 'emailid',
            search_condi: 'like',
            search_value: '',
            pageno: pageno,
            pagesize: pagesize
        };

        const requestBody = {
            body: body,
        };
        this.props.dispatch(
            searchGeneral(
                `crm/v1/member/search`,
                requestBody,
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE'
            )
        );
    };

    _renderIssueCoupon = () => {
        const { userProfileList, errormsg: queryError } = this.props.queryData;
        const { errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        if (queryError) {
            errormsg = queryError;
        }
        if (crudError) {
            errormsg = crudError;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-8 col-sm-12 noPadding noMargin chatBtnDiv">
                        <img
                            className="chatBtn"
                            src={`${process.env.PUBLIC_URL}/assets/icons/chat.png`}
                        />
                        Select the customer ids to issue coupon
                    </div>
                    <div className="col-lg-4 col-sm-12 alignRight noPadding noMargin">
                        {this._renderIssueCouponBtn()}
                    </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback errordiv">{errormsg}</div>
                <IssueCouponTable
                    userProfileList={userProfileList}
                    handleSelectMember={this.handleSelectMember}
                    callApi={this.callApi}
                />
            </React.Fragment>
        );
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    render() {
        const {
            userProfileList,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }
        if (!userProfileList && !errormsg) {
            return <Loading />;
        }

        if (!userProfileList || this.props.queryData.apiPending) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                {this.state.showMemberSearchDiv && this._renderSearchModal1()}
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                Customer Coupon
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD_MEM');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                {this._renderIssueCoupon()}
                            </form>
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const IssueCouponTable = props => {
    const { userProfileList, handleSelectMember, callApi } = props
    const [first, setFirst] = useState(0);
    const [tableRows, setRows] = useState(10);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedRecords, setSelectedRecords] = useState(null);

    const handlePageSizeChange = (e) => {
        e.preventDefault();
        setPageSize(e.target.value);
        setRows(e.target.value);
        callApi({ pageno: currentPage, pagesize: e.target.value});
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        callApi({ pageno: currentPage + 1, pagesize: pageSize});
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        callApi({ pageno: currentPage - 1, pagesize: pageSize});
    };

    const renderPaginationTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span
                        className="mx-1"
                        style={{
                            color: 'var(--text-color)',
                            userSelect: 'none',
                        }}
                    >
                        Items per page:{' '}
                    </span>
                    <Dropdown
                        value={pageSize}
                        options={dropdownOptions}
                        onChange={(e) => handlePageSizeChange(e)}
                    />
                </React.Fragment>
            );
        },
        CurrentPageReport: () => {
            return (
                <div>
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled': currentPage == 1,
                        })}
                        disabled={currentPage == 1}
                        aria-label="Previous Page"
                        onClick={handlePrevPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                    {currentPage}
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled': userProfileList.length < pageSize,
                        })}
                        aria-label="Next Page"
                        onClick={handleNextPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-right"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                </div>
            );
        },
    };

    const handleSelectedRecord = (records) => {
        setSelectedRecords(records);
        const ids = records.map((record) => record.memberid).join(',');
        handleSelectMember(ids)
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    return (
        <DataTable
            className="lazy-loading"
            value={userProfileList}
            showGridlines
            columnResizeMode="fit"
            responsiveLayout="scroll"
            paginatorClassName="justify-content-end"
            paginator
            paginatorTemplate={renderPaginationTemplate}
            first={first}
            rows={tableRows}
            selectionMode="checkbox"
            selection={selectedRecords}
            onSelectionChange={(e) => handleSelectedRecord(e.value)}
            dataKey="id"
            onPage={onCustomPage}
            emptyMessage="Your search does not retrieve any data. Please search again using another criteria."
        >
            <Column
                selectionMode="multiple"
                headerStyle={{
                    width: '3em',
                }}
            />
            <Column field="memberid" header="Customer Code" />
            <Column field="companyname" header="Customer Name" />
            <Column field="firstname" header="First Name" />
            <Column field="lastname" header="Last Name" />
            <Column field="contactno" header="Contact No" />
            <Column field="emailid" header="Email" />
            <Column field="status" header="Status" />
        </DataTable>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(IssueMemberCoupons);
