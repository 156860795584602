import React from "react";
import { Chart } from "chart.js";
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets = this.props.data;
    this.myChart.update();
  }

  componentDidMount() {
    Chart.plugins.register({
      beforeDraw: function (c) {
        var ctx = c.chart.ctx;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      }
    })
    var c = document.getElementById(this.props.id);
    var ctx = c.getContext("2d");
    var gradients = [];    
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(84,135,216,1)");
    gradient.addColorStop(1, "rgba(84,135,216,0)");
    gradients.push(gradient);

    if (typeof this.myChart != "undefined") {
      this.myChart.destroy();
    }
    this.myChart = new Chart(this.canvasRef.current, {
      type: "line",

      options: {
        responsive: true,
        legend: { display: true, position: "bottom" },
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
              title: function(tooltipItems, data) {
                  return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
              }
          }
       },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0
              }
            }
          ],
          xAxes: [{
            scaleLabel: {
                display: true,
            }
          }]
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data,
      }  
    });
  }

  render() {
    return (
      <div className="chartCanvas">
        <canvas ref={this.canvasRef} id={this.props.id} />
      </div>
    );
  }
}
export default LineChart;