import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { searchGeneral } from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import PrimeTable from '../../components/PrimeTable';
import FileExtensionDetail from './FileIntegrationDetail';
import PreviewImage from '../../components/ImageComponents/PreviewImage';
import RefreshIcon from '../../components/ImageComponents/RefreshIcon';

const fileGroupOptions = [
    { name: 'Select', code: '' },
    { name: 'Customer', code: 'customer' },
    { name: 'Inventory Rep', code: 'inventory-rep' },
    { name: 'Invoice', code: 'invoice' },
    { name: 'Product', code: 'product' },
    { name: 'Sales Order', code: 'sales-order' },
    { name: 'Sales Return', code: 'sales-return' },
    { name: 'Stock', code: 'stock' },
];

const booleanOptions = [
    { name: 'Select', code: '' },
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
];

const fileTypeOptions = [
    { name: 'Select', code: '' },
    { name: 'Inbound', code: 'inbound' },
    { name: 'Outpound', code: 'outpound' },
];

const FileIntegrationReport = (props) => {
    const { replenishmentList } = props.queryData;
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [viewDetail, setViewDetail] = useState(false);
    const [record, setRecord] = useState({});
    const [date_from, setDateFrom] = useState(new Date());
    const [filterData, setFilterData] = useState();

    const createFetchUrl = (params) => {
        props.dispatch(
            searchGeneral(
                `ext-ns/v1/sys/integration/files`,
                { body: params },
                CONSTANTS.REPLENISHMENT_GET_ALL_SUCCESS,
                CONSTANTS.REPLENISHMENT_GET_ALL_FAILURE,
            ),
        );
    };

    const callApi = (params) => {
        props.dispatch(
            searchGeneral(
                `ext-ns/v1/sys/integration/files`,
                params,
                CONSTANTS.REPLENISHMENT_GET_ALL_SUCCESS,
                CONSTANTS.REPLENISHMENT_GET_ALL_FAILURE,
            )
        );
    };

    const pageSizeChangecallback = (recordCount) => {
        setPageSize(recordCount);
        setCurrPage(1);
        createFetchUrl({
            pagesize: recordCount,
            pageno: 1,
        });
    };

    const pageChangeCallback = (pageNo) => {
        setCurrPage(pageNo);
        createFetchUrl({
            pagesize: pageSize,
            pageno: pageNo,
        });
    };

    useEffect(() => {
        //initLoad();
    }, []);

    const initLoad = () => {
        createFetchUrl({
            pagesize: pageSize,
            pageno: currPage,
        });
    };

    const handleDetailView = (row) => {
        setRecord(row);
        setViewDetail(true);
    };

    const actionTemplate = (row) => {
        return (
            <span onClick={() => handleDetailView(row)}>
                <PreviewImage />
            </span>
        );
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
    };

    const handleSearch = (data) => {
        setDateFrom(data.date_from);
        clearEmptyParam(data);
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data,
            error: data.error ? data.error.code : '',
            processed: data.processed ? data.processed.code : '',
            filetype: data.filetype ? data.filetype.code : '',
            filegroup: data.filegroup ? data.filegroup.code : '',
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        clearEmptyParam(parsedValues);
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: currPage,
            },
        };
        callApi(requestBody);
    };

    const columns = [
        { field: 'filename', header: 'File Name' },
        { field: 'filesize', header: 'File Size' },
        { field: 'filetype', header: 'File Type' },
        { field: 'filegroup', header: 'File Group' },
        { field: 'filelines', header: 'File Lines' },
        { field: 'filestage', header: 'File Stage' },
        { field: 'processed', header: 'Processed' },
        { field: 'error', header: 'Error' },
        { field: 'lastupdated', header: 'Last Updated' },
    ];

    const renderListView = () => {
        return (
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">
                            File integration report
                            <RefreshIcon handleClick={() => handleSearch(filterData)} />
                        </h2>
                    </div>
                </div>
                <div className="frmDiv mx-3 mt-3">
                    <FilterForm handleSearch={handleSearch} data={filterData} />
                    <div className="mt-4">
                        <PrimeTable
                            list={replenishmentList}
                            columns={columns}
                            actionColumn={actionTemplate}
                            pageSizeChangecallback={(pageSize) => {
                                pageSizeChangecallback(pageSize);
                            }}
                            pageChangeCallback={(currPage) => {
                                pageChangeCallback(currPage);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {viewDetail && (
                <FileExtensionDetail
                    record={record}
                    hideDetail={() => setViewDetail(false)}
                />
            )}
            {!viewDetail && renderListView()}
        </>
    );
};

const FilterForm = (props) => {
    const { handleSearch, data } = props;
    const formik = useFormik({
        initialValues: {
            filename: '',
            date_from: data?.date_from ?? new Date(),
            date_till: data?.date_till ?? new Date(),
            date_type: 'lastupdated',
            filetype: '',
            processed: '',
            error: '',
            filegroup: ''
        },
        onSubmit: (values) => {
            const data = {
                ...values,
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(
                    ([_, value]) =>
                        value !== undefined && value !== '' && value !== null,
                ),
            );
            handleSearch(filteredData);
        },
    });

    useEffect(() => {
        const data = formik.initialValues
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) =>
                    value !== undefined && value !== '' && value !== null,
            ),
        );
        handleSearch(filteredData);
    }, [])

    const renderFormArea = () => {
        return (
            <>
                <div className="p-fluid formgrid grid w-100 mb-4">
                    <div className="field col-3">
                        <label htmlFor="filename">File Name</label>
                        <InputText
                            id="terminalid"
                            type="text"
                            name="terminalid"
                            value={formik.values.terminalid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="status">Processed</label>
                        <Dropdown
                            id="processed"
                            name="processed"
                            value={formik.values.processed}
                            onChange={formik.handleChange}
                            options={booleanOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            maxDate={formik.values.date_till}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="date_till">Date To</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="filetype">File Type</label>
                        <Dropdown
                            id="filetype"
                            name="filetype"
                            value={formik.values.filetype}
                            onChange={formik.handleChange}
                            options={fileTypeOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="filetype">File Group</label>
                        <Dropdown
                            id="filegroup"
                            name="filegroup"
                            value={formik.values.filegroup}
                            onChange={formik.handleChange}
                            options={fileGroupOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="error">Error</label>
                        <Dropdown
                            id="error"
                            name="error"
                            value={formik.values.error}
                            onChange={formik.handleChange}
                            options={booleanOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3"
                        style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(FileIntegrationReport);
