import { combineReducers } from "redux";
import crudReducer from "./crudReducer";
import loginReducer from "./loginReducer";
import queryReducer from "./queryReducer";

const reducer = combineReducers({
  loginData: loginReducer,
  crudData: crudReducer,
  queryData: queryReducer
});

export default reducer;
