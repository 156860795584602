import React from 'react';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// import { PDFViewer } from '@react-pdf/renderer';
import { padNumber } from '../../../helpers/Util';

// const getDateFormat = (dateStr) => {
//     const dateParts = dateStr.split(' ');
//     const dateArr = dateParts[0].split('-');
//     return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
// };

// const styles = StyleSheet.create({
//     pdfTable: {
//         width: '100%',
//     },
//     tableHeader: {
//         fontWeight: '700',
//         fontSize: '16px',
//         textAlign: 'center',
//         borderBottom: '1px solid #dee2e6',
//         color: '#000',
//         padding: 8,
//     },
//     title: {
//         height: 29,
//         textAlign: 'center',
//         fontSize: 16,
//         fontWeight: 'bold',
//         padding: 12,
//         color: '#000',
//     },
//     tCellRow: {
//         display: 'flex',
//         flexDirection: 'row',
//         borderBottom: '1px solid #dee2e6',
//     },
//     tCell: {
//         flex: 1,
//         textAlign: 'center',
//         fontSize: '10px',
//         borderRight: '1px solid #dee2e6',
//     },
//     tDataCell: {
//         flex: 1,
//         textAlign: 'center',
//         fontSize: '10px',
//         borderRight: '1px solid #dee2e6',
//     },
//     tInnerCell: {
//         padding: '16px 4px 8px 4px',
//     },
//     mb4: {
//         marginBottom: '4px',
//     },
//     tDataCellPadding: {
//         padding: '4px 0px',
//     },
// });

// const getCurrentDateTime = () => {
//     const now = new Date();
//     const twoDigit = (num) => (num < 10 ? `0${num}` : num);
//     const year = now.getFullYear();
//     const month = twoDigit(now.getMonth() + 1); // Months are zero-based
//     const day = twoDigit(now.getDate());
//     const hours = twoDigit(now.getHours());
//     const minutes = twoDigit(now.getMinutes());
//     const seconds = twoDigit(now.getSeconds());

//     const formattedDateTime = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;

//     return formattedDateTime;
// }

// const getFivePercentDiscountAmount = (item) => {
//     const { sc05, pwd05 } = item.specialdiscount
//     if (sc05 && sc05.specialdiscountamount) {
//         return isVoidTrxType(item.trxtype, sc05.specialdiscountamount)
//     }
//     if (pwd05 && pwd05.specialdiscountamount) {
//         return isVoidTrxType(item.trxtype, pwd05.specialdiscountamount)
//     }
//     return '';
// };

// const getTwentyPercentDiscountAmount = (item) => {
//     const { sc20, pwd20 } = item.specialdiscount
//     if (sc20 && sc20.specialdiscountamount) {
//         return isVoidTrxType(item.trxtype, sc20.specialdiscountamount)
//     }
//     if (pwd20 && pwd20.specialdiscountamount) {
//         return isVoidTrxType(item.trxtype, pwd20.specialdiscountamount)
//     }
//     return '';
// };

// const isVoidTrxType = (trxType, value) => {
//     if (trxType === 'void' && value > 0) {
//         return '-'+ value.toFixed(2)
//     }
//     return value.toFixed(2)
// }

// const getTerminalInfo = (terminalInfo) => {
//     const { additionalfields } = terminalInfo
//     const { birinfo } = additionalfields
//     return birinfo.activate ? birinfo : {}
// }

// const getReturnOrVoidNo = (item) => {
//     const { trxtype, voidno, retuno } = item
//     if (trxtype === 'void') {
//         return voidno
//     }
//     else if (trxtype === 'return') {
//         return retuno
//     }
// }

// const addSpaceEveryNCharacters = (inputString, n) => {
//     const regex = new RegExp(`(.{1,${n}})`, 'g');
//     return inputString.replace(regex, '$1 ');
// }

// const getVersionName = versionList => {
//     return versionList[0].translation['system.pos.product.header']
// }

// const ScPdf = (props) => {
//     const { data, storeInfo, terminalInfo, dataType, reportDate, versionList } = props;
//     const { additionalfields } = storeInfo;
//     const terminalData = getTerminalInfo(terminalInfo)
//     const storeData = additionalfields.birinfo ? additionalfields.birinfo : {}
//     const getDiscountTypeTitle = () => {
//         if (dataType === 'pwd') {
//             return 'PWD';
//         } else {
//             return 'Senior Citizen';
//         }
//     };
//     const version = versionList.length > 0 ? getVersionName(versionList) : 'Digital eXperience (DX) Version 1.0'
//     return (
//         <PDFViewer width="100%" height="600">
//             <Document>
//                 <Page size="A4" style={styles.page} orientation="landscape">
//                     <View
//                         style={{
//                             fontSize: '10px',
//                             textAlign: 'center',
//                             marginTop: '20px',
//                         }}
//                     >
//                         <Text style={styles.mb4}>{storeData.businessname}</Text>
//                         {storeData.storetype !== '' && (
//                             <Text style={styles.mb4}>{storeData.storetype}</Text>
//                         )}
//                         <Text style={styles.mb4}>{storeData.registeredname}</Text>
//                         <Text style={styles.mb4}>{storeData.branchaddress}</Text>
//                         <Text style={styles.mb4}>{storeData.tin}</Text>
//                         <Text style={styles.mb4}>
//                             Report Date: {reportDate}
//                         </Text>
//                     </View>
//                     <View
//                         style={{
//                             fontSize: '10px',
//                             marginTop: '20px',
//                             marginLeft: '20px',
//                         }}
//                     >
//                         <Text style={styles.mb4}>
//                             {version}
//                         </Text>
//                         <Text style={styles.mb4}>
//                             Branch: {storeData.branchcode}
//                         </Text>
//                         <Text style={styles.mb4}>Terminal: {terminalInfo.terminalid}</Text>
//                         <Text style={styles.mb4}>
//                             Serial No: {terminalData.serialno}
//                         </Text>
//                         <Text style={styles.mb4}>
//                             MIN: {terminalData.min}
//                         </Text>
//                         <Text style={styles.mb4}>
//                             PTU: {terminalData.ptu}
//                         </Text>
//                         <Text style={styles.mb4}>
//                             Date and Time Generated: {getCurrentDateTime()}
//                         </Text>
//                         <Text style={styles.mb4}>
//                             Generated By: {localStorage.getItem('userId')}
//                         </Text>
//                     </View>
//                     <View style={styles.pdfTable}>
//                         <View>
//                             <Text style={styles.tableHeader}>
//                                 {getDiscountTypeTitle()} Sales Book/Report
//                             </Text>
//                         </View>
//                         <View style={styles.tCellRow}>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#A6A6A6',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>Date</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#A6A6A6',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>Transaction Type</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#00B0F0',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>Name of {getDiscountTypeTitle()}</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#ED7D31',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>OSCA ID No./ SC ID No.</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#9999FF',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>{getDiscountTypeTitle()} TIN</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#FFC000',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>SI/OR Number</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#FFD966',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>Return/Void No</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#92D050',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>Sales (inclusive of VAT)</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#ED7D31',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>VAT Amount</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#E7E6E6',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>VAT Exempt Sales</Text>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     flex: '2',
//                                     backgroundColor: '#5B9BD5',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         display: 'flex',
//                                         flexDirection: 'column',
//                                         justifyContent: 'space-between',
//                                         height: '50px',
//                                     }}
//                                 >
//                                     <View
//                                         style={{
//                                             borderBottom: '1px solid #dee2e6',
//                                             padding: '4px',
//                                             height: '50%',
//                                         }}
//                                     >
//                                         <Text>Discount</Text>
//                                     </View>
//                                     <View
//                                         style={{
//                                             display: 'flex',
//                                             flexDirection: 'row',
//                                             justifyContent: 'space-between',
//                                             height: '50%',
//                                         }}
//                                     >
//                                         <View
//                                             style={{
//                                                 flex: '1',
//                                                 borderRight:
//                                                     '1px solid #dee2e6',
//                                                 justifyContent: 'center',
//                                             }}
//                                         >
//                                             <Text>5%</Text>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 flex: '1',
//                                                 justifyContent: 'center',
//                                             }}
//                                         >
//                                             <Text>20%</Text>
//                                         </View>
//                                     </View>
//                                 </View>
//                             </View>
//                             <View
//                                 style={{
//                                     ...styles.tCell,
//                                     backgroundColor: '#A6A6A6',
//                                 }}
//                             >
//                                 <View
//                                     style={{
//                                         ...styles.tInnerCell,
//                                     }}
//                                 >
//                                     <Text>Net Sales</Text>
//                                 </View>
//                             </View>
//                         </View>
//                         {data.map((item, index) => (
//                             <View style={styles.tCellRow} key={index}>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {getDateFormat(item.trxdate)}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {item.trxtype}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {item.membername}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     <Text>{item.memberid}</Text>
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     <Text>{addSpaceEveryNCharacters(item.tin, 9)}</Text>
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {item.invoicenoresetno} - {padNumber(item.invoiceno, 9)}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {getReturnOrVoidNo(item)}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {isVoidTrxType(item.trxtype, item.grossprice)}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {isVoidTrxType(item.trxtype, item.grosstax)}
//                                 </Text>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {isVoidTrxType(item.trxtype, item.salesexemptedamount)}
//                                 </Text>
//                                 <View
//                                     style={{
//                                         ...styles.tDataCell,
//                                         display: 'flex',
//                                         flex: '2',
//                                         flexDirection: 'row',
//                                     }}
//                                 >
//                                     <Text
//                                         style={{
//                                             ...styles.tDataCellPadding,
//                                             flex: '1',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                             borderRight: '1px solid #dee2e6',
//                                         }}
//                                     >
//                                         {getFivePercentDiscountAmount(item)}
//                                     </Text>
//                                     <Text
//                                         style={{
//                                             ...styles.tDataCellPadding,
//                                             flex: '1',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                         }}
//                                     >
//                                         {getTwentyPercentDiscountAmount(item)}
//                                     </Text>
//                                 </View>
//                                 <Text
//                                     style={{
//                                         ...styles.tDataCell,
//                                         ...styles.tDataCellPadding,
//                                     }}
//                                 >
//                                     {isVoidTrxType(item.trxtype, item.netamount)}
//                                 </Text>
//                             </View>
//                         ))}
//                     </View>
//                 </Page>
//             </Document>
//         </PDFViewer>
//     );
// };

const ScPdf = () => {
    return (
        <></>
    )
}

export default ScPdf;
