import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LinkButton from "../../../../components/LinkButton";
import ImagePick from "../../../../components/ImagePick";
import {
  updateArticleInfoApi,
  createArticleInfoApi,
  crudReset,
  //getBeaconsList,
  //getGeofenceList,
  getStoreById,
  updateArticleMode
} from "../../../../actions/actions";
import ConfirmAlert from "../../../../components/ConfirmAlert";
import StatusComp from "../../../../components/StatusComp";
import { produce } from "immer";
import TenantCode from "../../../../components/TenantCode";
import SimpleSelect from "./../../../../components/SimpleSelect";
import MultiSelect from "./../../../../components/MultiSelect";
import { renderBeaconOrGeofenceList } from "./../../../../helpers/Util";

class AddAppGuide extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      articlecode: "",
      title: "",
      desc: "",
      titlelong: "",
      group: "",
      category: "",
      status: "New",
      images: {},
      articlegroup: "",
      sortorder: 0,
      language: "",
      langlist: {},
      formError: false,
      formErrorMsg: "",
      isEdit: false,

      // Errors
      storeiderror: "",
      titleerror: "",
      descError: "",
      //beacons: "",
      //geofences: "",
      storelabel: ""
    };
  }
  // setBeaconsSelected = value => {
  //   this.setState({
  //     beacons: value
  //   });
  // };
  // setGeoFenceSelected = value => {
  //   this.setState({
  //     geofences: value
  //   });
  // };
  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      //if (storeArray) this.setState({ storelabel: storeArray.title });
      if (storeArray) {
        storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };
  componentWillMount = () => {
    // this.props.dispatch(
    //   getBeaconsList({ body: { language: "en", status: "New,Active" } })
    // );
    // this.props.dispatch(
    //   getGeofenceList({ body: { language: "en", status: "New,Active" } })
    // );

    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          storeid,
          title,
          articlecode,
          fields,
          status,
          sortorder,
          images,
          articletype,
          titlelong,
          category,
          group,
          articlegroup
        } = rec;
        this.props.dispatch(getStoreById(storeid));

        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.articletype = articletype ? articletype : "";
            draft.articlecode = articlecode ? articlecode : "";
            draft.desc = fields.desc ? fields.desc : "";
            draft.titlelong = fields.titlelong ? fields.titlelong : "";
            draft.group = fields.group ? fields.group : "";
            draft.category = fields.category ? fields.category : "";
            draft.arttype = fields.arttype ? fields.arttype : "";
            // draft.beacons = fields.beacons ? fields.beacons.split(",") : "";
            // draft.geofences = fields.geofences
            //   ? fields.geofences.split(",")
            //   : "";

            draft.images = {
              image1:  rec.hasOwnProperty("images") === true ? images.image1 : '',
              image2:  rec.hasOwnProperty("images") === true ? images.image2 : '',
              image3:  rec.hasOwnProperty("images") === true ? images.image3 : '',
              image4: rec.hasOwnProperty("images") === true ? images.image4 : '',
              image5: rec.hasOwnProperty("images") === true ? images.image5 : '',
              image6: rec.hasOwnProperty("images") === true ? images.image6 : '',
              image7: rec.hasOwnProperty("images") === true ? images.image7 : '',
              image8: rec.hasOwnProperty("images") === true ? images.image8 : '',
              image9: rec.hasOwnProperty("images") === true ? images.image9 : '',
              image10: rec.hasOwnProperty("images") === true ? images.image10 : ''
            };
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  newImageObj(fields) {
    let newIndex = 1;
    let oldIndex = 1;
    const newfields = { image1: "", image2: "", image3: "", image4: "", image5: "",
    image6: "", image7: "", image8: "", image9: "", image10: ""
   };
    while (fields[`image${oldIndex}`] != null) {
      const newKey = `image${newIndex}`;
      const oldKey = `image${oldIndex}`;
      if (fields[oldKey]) {
        newfields[newKey] = fields[oldKey];
        newIndex++;
      }
      oldIndex++;
    }
    return newfields;
  }

  getCreatePayload = () => {
    const {
      storeid,
      title,
      articlecode,
      desc,
      titlelong,
      category,
      group,
      status,
      sortorder,
      images,
      language,
      arttype,
      articlegroup,
      //geofences,
      //beacons
    } = this.state;
   
    let body = {
      storeid: storeid,
      title: title,
      language: language,
      articlecode,
      publishmode: "manual",
      articletype: "App Guide",
      status: status,
      sortorder: sortorder,
      articlegroup,
      fields: {
        title: arttype || title,
        desc,
        titlelong,
        group,
        category
        //geofences: geofences.toString(),
        //beacons: beacons.toString()
      },
      images: {
        image1: images.image1 || "",
        image2: images.image2 || "",
        image3: images.image3 || "",
        image4: images.image4 || "",
        image5: images.image5 || "",
        image6: images.image6 || "",
        image7: images.image7 || "",
        image8: images.image8 || "",
        image9: images.image9 || "",
        image10: images.image10 || ""
      }
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createArticle = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
        this._updateArticleMode();
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
        this._updateArticleMode();
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      //storeiderror: "",
      subtypeerror: "",
      titleerror: "",
      descError: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, arttype, title, desc } = this.state;
    let valid = true;
    // if (storeid.trim() === "") {
    //   this.setState({
    //     storeiderror: "Store id is required."
    //   });
    //   valid = false;
    // } 
    if (title.trim() === "") {
      this.setState({
        titleerror: "Title is required."
      });
      valid = false;
    }
    if (desc.trim() === "") {
      this.setState({
        descError: "Description is required."
      });
      valid = false;
    }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
 
  _updateArticleMode = () =>{
    const isEditMode = this._isEditMode();
    if(!isEditMode){
      const { articleMode } = this.props.location.state;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articleMode } }) 
      );
    }
    else {
      const { articletype } = this.props.location.state.item;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articletype } }) 
      );
    }
  }

  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Update
          </button>
        )}

        {/* <Link
          to="/ui/articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link> */}
        <LinkButton
          to="/ui/articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          onClick={() => this._updateArticleMode()}
        >
          {`Cancel`}
        </LinkButton>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
     return (
       <React.Fragment>
         <div className="row sub-title">Actions</div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
         </div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft ">
              Sortorder
            </label>
            <input
              type="number"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />           
          </div> 
        </div>
       </React.Fragment>
     )
  }

  _renderMainFormDetails = () => {
    const { languageConfig, beaconsList, geoFencesList } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articletype" className="floatLeft">
              Article Type
            </label>
            <input
              type="text"
              className="form-control"
              id="articletype"
              value="App Guide"
              disabled={true}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => {
                this.setState({ articlegroup: event.target.value });
              }}
            />
          </div> 
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlecode" className="floatLeft">
              Article Code
            </label>
            <input
              type="text"
              className="form-control"
              id="articlecode"
              readOnly={true}
              value={this.state.articlecode}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="arttype" className="floatLeft">
              Article Sub Type
            </label>
            <input
              type="text"
              className="form-control"
              id="arttype"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="category" className="floatLeft">
              Category
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              value={this.state.category}
              onChange={event => {
                this.setState({ category: event.target.value });
              }}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="group" className="floatLeft">
              Group
            </label>
            <input
              type="text"
              className="form-control"
              id="group"
              value={this.state.group}
              onChange={event => {
                this.setState({ group: event.target.value });
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desc" className="floatLeft required">
              Description
            </label>
            <textarea
              className="form-control"
              id="desc"
              value={this.state.desc}
              onChange={event => {
                this.setState({ desc: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.descError}</div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="titlelong" className="floatLeft">
              Title Long
            </label>
            <textarea
              className="form-control"
              id="titlelong"
              value={this.state.titlelong}
              onChange={event => {
                this.setState({ titlelong: event.target.value });
              }}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="beacons" className="floatLeft">
              Beacons
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(beaconsList)}
              setSelectedFieldValues={this.setBeaconsSelected}
              id="beacons"
              itemsSelected={this.state.beacons}
            />
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="geofences" className="floatLeft">
              GeoFences
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(geoFencesList)}
              setSelectedFieldValues={this.setGeoFenceSelected}
              id="geofences"
              itemsSelected={this.state.geofences}
            />
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="image1" className="floatLeft">
              Images
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={10}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Edit App Guide"
                  : "Add App Guide"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/articles"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "App Guide updated successfully!"
                  : "App Guide created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddAppGuide);
