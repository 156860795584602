import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { connect } from 'react-redux';
import {
    queryPropertyConfigByFields,
    createPointRule,
    updatePointRule,
    crudReset,
} from '../../../actions/actions';
import { Link } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import * as CONSTANTS from '../../../constants/constants';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { formatDate } from '../../../helpers/Util';

const valueByOptionList = [
    { name: 'Amt', code: 'Amt' },
    { name: 'Quantity', code: 'Qty' },
];

const toDate = (dateStr) => {
    let totalStr = dateStr.split(' ');
    if (totalStr.length > 1) {
        var st = totalStr[0];
        var parts = st.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    } else {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }
};

const AddRabbitPointRule = (props) => {
    const { queryData, location } = props;
    const { isSuccess } = props.crudData;
    const { state } = location;
    const [ruleGroupOpionList, setRuleGroupOptionList] = useState([]);
    const [ruleTypeOpionList, setRuleTypeOptionList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [record, setRecord] = useState({});

    useEffect(() => {
        if (state && state.isEdit) {
            setIsEditMode(true);
        }
        if (state && state.isView) {
            setIsViewMode(true);
        }
        if (state && state.item) {
            const { item } = state;
            if (item && item.id) {
                setRecord(item);
            }
        }
    }, [state]);

    useEffect(() => {
        props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'RABBIT_RULE_GROUP',
                    },
                },
                CONSTANTS.SEARCH_PROP_CONFIG_FIELDS,
                CONSTANTS.SEARCH_PROP_CONFIG_SUCCESS,
                CONSTANTS.SEARCH_PROP_CONFIG_FAILURE
            )
        );
        props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'RABBIT_RULE_TYPE',
                    },
                },
                CONSTANTS.SEARCH_PROP_CONFIG_POINTRULETYPES,
                CONSTANTS.SEARCH_PROP_CONFIG_POINTRULETYPES_SUCCESS,
                CONSTANTS.SEARCH_PROP_CONFIG_POINTRULETYPES_FAILURE
            )
        );
    }, []);

    useEffect(() => {
        const { propConfigs, rabbitPointRuleTypes } = queryData;
        if (
            propConfigs &&
            propConfigs.length > 0 &&
            propConfigs[0].additionalfields
        ) {
            const rabbitPointRuleGroupKeys = Object.values(
                propConfigs[0].additionalfields
            );
            const obj = rabbitPointRuleGroupKeys.reduce(
                (accumulator, value) => {
                    return { ...accumulator, [value]: value };
                },
                {}
            );
            const output = Object.entries(obj).reduce(
                (result, [name, code]) => {
                    result.push({ name, code });
                    return result;
                },
                []
            );
            setRuleGroupOptionList(output);
        }

        if (
            rabbitPointRuleTypes &&
            rabbitPointRuleTypes.length > 0 &&
            rabbitPointRuleTypes[0].additionalfields
        ) {
            const rabbitPointRuleTypeKeys = Object.values(
                rabbitPointRuleTypes[0].additionalfields
            );
            const obj = rabbitPointRuleTypeKeys.reduce((accumulator, value) => {
                return { ...accumulator, [value]: value };
            }, {});
            const output = Object.entries(obj).reduce(
                (result, [name, code]) => {
                    result.push({ name, code });
                    return result;
                },
                []
            );
            setRuleTypeOptionList(output);
        }
    }, [queryData]);

    const getValidFrom = () => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = currentDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    };

    const getValidTill = () => {
        const currentDate = new Date();
        // Add 100 years to the current date
        const futureDate = new Date(currentDate);
        futureDate.setFullYear(currentDate.getFullYear() + 100);

        const day = String(futureDate.getDate()).padStart(2, '0');
        const month = String(futureDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = futureDate.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    };

    const formik = useFormik({
        initialValues: {
            rulecode: record?.rulecode || '',
            storeid: 'global',
            title: record?.title || '',
            ruletype: record?.ruletype
                ? ruleTypeOpionList.find(
                      (item) => item.code === record.ruletype
                  )
                : '',
            rulegroup: record?.rulegroup
                ? ruleGroupOpionList.find(
                      (item) => item.code === record.rulegroup
                  )
                : '',
            valueby: record?.valueby
                ? valueByOptionList.find((item) => item.code === record.valueby)
                : valueByOptionList.find((item) => item.code === 'Amt'),
            valuebatch: '1',
            status: 'Active',
            valuemin: record?.valuemin || 0,
            valuemax: record?.valuemax || 0,
            // validfrom: !isEditMode ? getValidFrom() : record.validfrom,
            // validtill: !isEditMode ? getValidTill() : record.validtill,
            validfrom: record?.validfrom ? toDate(record.validfrom) : '',
            validtill: record?.validtill ? toDate(record.validtill) : '',
            pointearn: record?.pointearn || ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.title) {
                errors.title = 'Title is required.';
            }
            if (!data.rulegroup) {
                errors.rulegroup = 'Rule goup is required.';
            }
            if (!data.validfrom) {
                errors.validfrom = 'Please select a from date.';
            }
            if (!data.validtill) {
                errors.validtill = 'Please enter to date.';
            }
            return errors;
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
                ruletype: values.ruletype ? values.ruletype.code : '',
                rulegroup: values.rulegroup ? values.rulegroup.code : '',
                valueby: values.valueby ? values.valueby.code : '',
                validfrom: formatDate(values.validfrom),
                validtill: formatDate(values.validtill),
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            //console.log(data)
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleSubmit = (data) => {
        if (isEditMode) {
            props.dispatch(updatePointRule({ body: data }));
        } else {
            props.dispatch(createPointRule({ body: data }));
        }
    };

    const handleValueMaxChange = (event) => {
        formik.setFieldValue('valuemax', parseInt(event.target.value));
    };

    const handleValueMinChange = (event) => {
        formik.setFieldValue('valuemin', parseInt(event.target.value));
    };

    const handleRuleCodeChange = e => {
        if (e.target.validity.valid) {
            const rulecode = e.target.value;
            formik.setFieldValue('rulecode', rulecode);
        }
    }

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="date_from">Rule Code</label>
                        <InputText
                            id="rulecode"
                            value={formik.values.rulecode}
                            onChange={handleRuleCodeChange}
                            pattern="^[a-zA-Z0-9]+$"
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="title"
                            className={classNames({
                                'p-error': isFormFieldValid('title'),
                            })}
                        >
                            Title*
                        </label>
                        <InputText
                            id="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('title')}
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="rulegroup"
                            className={classNames({
                                'p-error': isFormFieldValid('rulegroup'),
                            })}
                        >
                            Rule Group*
                        </label>
                        <Dropdown
                            id="rulegroup"
                            name="rulegroup"
                            value={formik.values.rulegroup}
                            onChange={formik.handleChange}
                            options={ruleGroupOpionList}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {getFormErrorMessage('rulegroup')}
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="ruletype">Rule Type</label>
                        <Dropdown
                            id="ruletype"
                            name="ruletype"
                            value={formik.values.ruletype}
                            onChange={formik.handleChange}
                            options={ruleTypeOpionList}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="ruletype">Value By</label>
                        <Dropdown
                            id="valueby"
                            name="valueby"
                            value={formik.values.valueby}
                            onChange={formik.handleChange}
                            options={valueByOptionList}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="valuebatch">Value Batch</label>
                        <InputNumber
                            name="valuebatch"
                            value={formik.values.valuebatch}
                            readOnly
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="valuemin">Minimum Value</label>
                        <InputNumber
                            id="valuemin"
                            inputId="valuemin"
                            value={formik.values.valuemin}
                            onValueChange={handleValueMinChange}
                            mode="decimal"
                            min={0}
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="valuemax">Maximum Value</label>
                        <InputNumber
                            id="valuemax"
                            inputId="valuemax"
                            value={formik.values.valuemax}
                            onValueChange={handleValueMaxChange}
                            mode="decimal"
                            min={0}
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="validfrom"
                            className={classNames({
                                'p-error': isFormFieldValid('validfrom'),
                            })}
                        >
                            Valid From*
                        </label>
                        <Calendar
                            id="validfrom"
                            value={formik.values.validfrom}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                        {getFormErrorMessage('validfrom')}
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label
                            htmlFor="validtill"
                            className={classNames({
                                'p-error': isFormFieldValid('validtill'),
                            })}
                        >
                            Valid Till*
                        </label>
                        <Calendar
                            id="validtill"
                            value={formik.values.validtill}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.validfrom}
                            showIcon
                        />
                        {getFormErrorMessage('validtill')}
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="valuemin">Point Earn</label>
                        <InputNumber
                            id="pointearn"
                            inputId="pointearn"
                            value={formik.values.pointearn}
                            onValueChange={formik.handleChange}
                            mode="decimal"
                            min={0}
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderMainButton = () => {
        if (isViewMode) {
            return (
                <>
                    <Link
                        to="/ui/rabbit-point-rule"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </>
            );
        }
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/rabbit-point-rule"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    const hideModal = (e) => {
        e.preventDefault();
        props.dispatch(crudReset());
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {isEditMode
                                    ? 'Edit Rabbit Point Rule'
                                    : 'Add Rabbit Point Rule'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="row rowBottom">
                                <div className="col-md-8 col-sm-12 splitFrmDiv noPadding noMargin">
                                    {renderFormArea()}
                                </div>
                            </div>
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {renderMainButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ConfirmAlert
                show={isSuccess}
                handleClose={hideModal}
                to="/ui/rabbit-point-rule"
                children={
                    <div style={{ padding: '2em', color: 'green' }}>
                        {isEditMode
                            ? 'Rabbit Point Rule updated successfully'
                            : 'Rabbit Point Rule created successfully'}
                    </div>
                }
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddRabbitPointRule);
