import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import {
    searchGeneral,
    deleteUserProfile,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from '../../../components/Loading';
import formik from '../../../hooks/useFormik';
import { CreateEditDeleteHook } from '../../../hooks/useTableEditor';
import PrimeTable from '../../../components/PrimeTable';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../helpers/Util';

const _validationSchema = Yup.object().shape({});

const MemberProfileList = (props) => {
    const _initSearchedInfo = useMemo(() => {
        if (
            props.location &&
            props.location.state &&
            props.location.state.searchedInfo
        ) {
            return props.location.state.searchedInfo;
        } else {
            return {
                pageSize: 10,
                currPage: 1,
                search_field: 'emailid',
                search_condi: 'like',
                search_value: ''
            };
        }
    }, [props.location]);

    const _initSearchInfo = useMemo(() => {
        if (
            props.location &&
            props.location.state &&
            props.location.state.searchInfo
        ) {
            return props.location.state.searchInfo;
        } else {
            return {
                search_field: 'emailid',
                search_condi: 'like',
                search_value: ''
            };
        }
    }, [props.location]);

    const [message, setMessage] = useState('');
    const [searchedInfo, setSearchedInfo] = useState(_initSearchedInfo);
    const initialSearchInfo = _initSearchInfo;

    const handleClear = () => {
        const body = {
            search_field: 'emailid',
            search_condi: 'like',
            search_value: ''
        };

        setSearchedInfo({
            ...body,
            pageSize: 10,
                currPage: 1,
        })

        const requestBody = {
            body: body,
        };
        props.dispatch(
            searchGeneral(
                `crm/v1/member/search`,
                requestBody,
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE',
            ),
        );
    }

    const _submitForm = (values, { setSubmitting }) => {
        const newSearchInfo = {
            search_field: values.search_field ? values.search_field.id : '',
            search_condi: values.search_condi,
            search_value: values.search_value
        };

        setSearchedInfo({
            ...searchedInfo,
            ...newSearchInfo,
            currPage: 1,
        });
        setSubmitting(false);
    };

    const _deleteUser = useCallback((rowData) => {
        setMessage('Customer profile deleted successfully');
        editDeleteTable.setDeleteRecId(rowData.id);
    }, []);

    const allPermissions = permissions('CRM', 'DE_CRM_MEMBER_PROFILE');

    const {
        values,
        errors,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
    } = formik(initialSearchInfo, _validationSchema, _submitForm);

    const editDeleteTable = CreateEditDeleteHook(
        '/ui/member-profile/addNew',
        _deleteUser,
        allPermissions,
    );

    const actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/member-profile/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}
                <Link
                    to={{
                        pathname: '/ui/member-profile/addNew',
                        state: {
                            item: row,
                            isEdit: true,
                        },
                    }}
                >
                    <img
                        title="Edit"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                    />
                </Link>
            </span>
        );
    };

    const _createFetchUrl = () => {
        props.history.replace(window.location.pathname, {
            searchInfo: { ...values },
            searchedInfo: { ...searchedInfo },
        });

        let body = {
            pagesize: searchedInfo.pageSize,
            pageno: searchedInfo.currPage,
        };

        body = {
            ...body,
            search_field: searchedInfo.search_field,
            search_condi: searchedInfo.search_condi,
            search_value: searchedInfo.search_value
        };

        const requestBody = {
            body: body,
        };

        return requestBody;
    };

    const _callFetch = () => {
        props.dispatch(
            searchGeneral(
                `crm/v1/member/search`,
                _createFetchUrl(),
                'SEARCH_MEMBER_PROFILE_SUCCESS',
                'SEARCH_MEMBER_PROFILE_FAILURE',
            ),
        );
    };

    useEffect(() => {
        _callFetch();
    }, [searchedInfo]);

    const _renderAddNewBtn = useMemo(() => {
        const { history } = props;
        // if (!canWrite(allPermissions)) {
        //     return;
        // }
        return (
            <Button
                label="Add New Customer"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/member-profile/addNew')}
            />
        );
    }, [handleSubmit, isSubmitting]);

    const _shdDeleteRec = useCallback(
        (arg) => {
            editDeleteTable.setDeleteRecId(-1);
            if (arg) {
                props.dispatch(deleteUserProfile(editDeleteTable.deleteRecId));
            }
        },
        [editDeleteTable.deleteRecId, props],
    );

    const _hideAlertModal = useCallback(
        (e) => {
            setMessage('');
            e.preventDefault();
            props.dispatch(crudReset());
            setSearchedInfo({ ...searchedInfo, currPage: 1 });
        },
        [props, searchedInfo],
    );

    const _hideErrorAlertModal = useCallback(
        (e, refresh) => {
            setMessage('');
            e.preventDefault();
            props.dispatch(crudReset());
            props.dispatch(resetAction());
        },
        [props],
    );

    const _renderSearchCiteria = () => {
        const searchField = [
            { name: 'Select DataField', id: '' },
            { name: 'Customer Name', id: 'companyname' },
            { name: 'Customer Code', id: 'memberid' },
            { name: 'Email', id: 'emailid' },
            { name: 'Store Id', id: 'storeid' },
            { name: 'Salesperson', id: 'salespersonname' },
            { name: 'Collector', id: 'collectorname' },
            { name: 'Status', id: 'status' },
        ];
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="field col-3 md:col-3">
                        <label htmlFor="search_field">Search By</label>
                        <Dropdown
                            name="search_field"
                            value={values.search_field}
                            onChange={handleChange}
                            options={searchField}
                            optionLabel="name"
                            className="w-full"
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="search_value" className="floatLeft">
                            Search Value
                        </label>
                        <InputText
                            id="search_value"
                            name="search_value"
                            value={values.search_value}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field mb-2" style={{ display: 'flex' }}>
                        <div className='mr-4' style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            />
                        </div>
                        {/* <div style={{ marginTop: '28px' }}>
                            <Button
                                type="button"
                                label="Cancel"
                                className="p-button-secondry mr-4"
                                onClick={handleClear}
                                severity="secondary"
                            />
                        </div> */}
                    </div>
                </div>
            </>
        );
    };

    const {
        userProfileList,
        errormsg: queryError,
        servererror: serverError,
    } = props.queryData;

    const { isSuccess, errormsg: crudError } = props.crudData;

    let errormsg = '';
    let refresh = false;
    if (serverError) {
        errormsg = serverError;
        refresh = false;
    }
    if (queryError) {
        errormsg = queryError;
        refresh = true;
    }
    if (crudError) {
        errormsg = crudError;
        refresh = true;
    }

    if (!userProfileList && !errormsg) {
        return <Loading />;
    }

    if (!userProfileList || props.queryData.apiPending) {
        return <Loading />;
    }

    const columns = [
        { field: 'memberid', header: 'Customer Code' },
        { field: 'companyname', header: 'Customer Name' },
        { field: 'taxnumber', header: 'Customer TIN' },
        { field: 'address', header: 'Address' },
    ];

    const nameTemplate = (rowData) => {
        const { firstname, lastname } = rowData;
        return firstname + ' ' + lastname;
    };

    return (
        <>
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">
                            Customer List
                            <img
                                alt=""
                                title="Refresh"
                                onClick={_callFetch}
                                className="refreshtree"
                                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                            />
                        </h2>
                    </div>
                    <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                        {_renderAddNewBtn}
                    </div>
                </div>
                <div className="row form-container">
                    <div className="col">
                        <form className="frmDiv" onSubmit={handleSubmit}>
                            {_renderSearchCiteria()}
                            <div className="title_devide" />
                            <div className="invalid-feedback">{errormsg}</div>
                            <PrimeTable
                                responsive={true}
                                list={userProfileList}
                                columns={columns}
                                actionColumn={actionTemplate}
                                pageSizeChangecallback={(pageSize) => {
                                    setSearchedInfo({
                                        ...searchedInfo,
                                        currPage: 1,
                                        pageSize: pageSize,
                                    });
                                }}
                                pageChangeCallback={(currPage) => {
                                    setSearchedInfo({
                                        ...searchedInfo,
                                        currPage: currPage,
                                    });
                                }}
                            >
                                <Column
                                    field="contactname"
                                    header="Contact Name"
                                    body={nameTemplate}
                                />
                                <Column
                                    field="contactno"
                                    header="Contact Number"
                                />
                                <Column
                                    field="storeid"
                                    header="Store Id"
                                />
                                <Column
                                    field="salespersonname"
                                    header="Saleperson"
                                />
                                <Column
                                    field="collectorname"
                                    header="Collector"
                                />
                                <Column field="status" header="Status" />
                            </PrimeTable>
                        </form>
                    </div>
                </div>

                <ConfirmAlert
                    show={editDeleteTable.deleteRecId !== -1}
                    isPrompt={true}
                    children={
                        <div className="deleteAlertDiv">
                            Are you sure you want to delete this customer
                            profile?
                            <div style={{ margin: '2em' }}>
                                <button
                                    className="btn btn-themes btn-rounded btn-sec"
                                    onClick={() => _shdDeleteRec(false)}
                                >
                                    No
                                </button>
                                <button
                                    className="btn btn-themes  btn-rounded deletBtn"
                                    onClick={() => _shdDeleteRec(true)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    }
                />
                <ConfirmAlert
                    show={isSuccess}
                    handleClose={_hideAlertModal}
                    children={
                        <div
                            style={
                                isSuccess
                                    ? { padding: '2em', color: 'green' }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {isSuccess ? message : ''}
                        </div>
                    }
                />
                <ConfirmAlert
                    show={errormsg}
                    handleClose={(event) =>
                        _hideErrorAlertModal(event, refresh)
                    }
                    children={
                        <div
                            style={
                                errormsg
                                    ? {
                                          padding: '2em',
                                          color: 'red',
                                          fontWeight: '500',
                                      }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {errormsg ? `${errormsg}.` : ''}
                        </div>
                    }
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(MemberProfileList);
