import React from "react";
import { Chart } from "chart.js";
class GroupedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets = this.props.data;
    this.myChart.update(); 
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "bar",
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          callbacks: {
              title: function(tooltipItems, data) {
                  return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
              }
          }
       },
        legend: {
          position: "bottom"
        },

        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.props.yaxes
              }
            }
          ],
          xAxes: [
            {
              offset: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.props.xaxes
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data,
        barThickness: 40
      }
    });
  }

  render() {
    return (
      <div className="chartCanvas">
        <canvas ref={this.canvasRef} id={this.props.id} />
      </div>
    );
  }
}
export default GroupedBarChart;