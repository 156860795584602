import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { produce } from 'immer';
import Select, { components } from 'react-select';
import ReactSwitch from 'react-switch';
import { RadioButton } from 'primereact/radiobutton';
import {
    crudReset,
    updateStoreTag,
    createStoreTag,
    storeTerminalsSearchByFields,
    queryPropertyConfigByFields,
    storeDevicesSearchByFields,
    storeTagsSearchByFields,
} from '../../../../actions/actions';

import ConfirmAlert from '../../../../components/ConfirmAlert';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import { formatLanguageArray } from '../../../../helpers/Util';
import {
    SEARCH_PROP_CONFIG_TENDERTAGTYPES,
    SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE,
    SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
    TENDERTAGTYPES,
    TENDER_GROUP_PTYPES,
    SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES,
    SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS,
    SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE
} from '../../../../constants/constants';
import SimpleImageInput from '../../../../components/SimpleImageInput';
import _ from 'lodash';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class AddTerminalTenders extends React.PureComponent {
    editModeStatus = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'InActive', id: 'InActive' },
    ];

    tagTypeOptions = [
        { name: 'Select', id: '' },
        { name: 'Store Tender', id: 'tender' },
    ];

    tenderGroupOptions = [
        { name: 'Select', id: '' },
        { name: 'cash', id: 'cash' },
        { name: 'rabbit', id: 'rabbit' },
        { name: 'bbl', id: 'bbl' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: '',
            terminalid: '',
            title: '',
            taggroup: 'tprops',
            tagtype: 'tender',
            tenderTagTypeOptions: {},
            tagtypevalue: '',
            tendergroup: '',
            storedevices: [],
            deviceid: '',

            status: 'New',
            sortorder: 1,
            additionalFields: {},
            storeterminals: [],
            selectedTerminal: {},

            storelabel: '',
            // Errors
            terminaliderror: '',
            titleerror: '',
            tenderdescerror: '',
            storeiderror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tagtypevalueerror: '',
            returntypeerror: '',
            tendergrouperror: '',

            tender: false,
            tenderdesc: '',
            opencashdrawer: false,
            loan: false,
            pickup: false,
            voidtender: false,
            returntender: false,
            minamount: 0,
            maxamount: 0,
            tendercount: 0,
            returntype: '',
            autopickup: false,
            tenderimg: '',
            tenderinstruction: '',
            tenderanimationimg: '',
            storecashinout: false,
            enableoffline: false,
            cashcount: false,
            mgrintervention: false,
            offlinetender: false,
            collectiontender: false,
            //default props
            autolocktimeout: 0,
            autolocktimeouterror: '',
            terminalTenderGroupOptions: []
        };
    }

    handleTenderSwitch = (tender) => {
        this.setState({ tender });
    };

    handleLoanSwitch = (loan) => {
        this.setState({ loan });
    };

    handleReturnTenderSwitch = (returntender) => {
        this.setState({ returntender });
    };

    handleVoidTenderSwitch = (voidtender) => {
        this.setState({ voidtender });
    };

    handleOpenCashdrawerSwitch = (opencashdrawer) => {
        this.setState({ opencashdrawer });
    };

    handleReturnTypeSelection = (e) => {
        this.setState({ returntype: e.target.value });
    };

    handleStoreCashInOutSwitch = (storecashinout) => {
        this.setState({ storecashinout });
    };

    handleEnableOfflineSwitch = (enableoffline) => {
        this.setState({ enableoffline });
    };

    handleOfflineTenderSwitch = (offlinetender) => {
        this.setState({ offlinetender });
    };

    handleMgrInterventionSwitch = (mgrintervention) => {
        this.setState({ mgrintervention });
    };

    handleCashCountSwitch = (cashcount) => {
        this.setState({ cashcount });
    };

    getAdditionalFields = () => {
        if (this.state.tagtype === 'tender') {
            return {
                tenderdesc: this.state.tenderdesc,
                tender: this.state.tender ? 'Y' : 'N',
                voidtender: this.state.voidtender ? 'Y' : 'N',
                loan: this.state.loan ? 'Y' : 'N',
                pickup: this.state.autopickup ? 'N' : 'Y',
                autopickup: this.state.autopickup ? 'Y' : 'N',
                opencashdrawer: this.state.opencashdrawer ? 'Y' : 'N',
                returntender: this.state.returntender ? 'Y' : 'N',
                returntype: this.state.returntype,
                minamount: this.state.minamount,
                maxamount: this.state.maxamount,
                tendercount: this.state.tendercount,
                tenderimg: this.state.tenderimg,
                tenderinstruction: this.state.tenderinstruction,
                tenderanimationimg: this.state.tenderanimationimg,
                tendergroup: this.state.tendergroup,
                offlinetender: this.state.offlinetender ? 'Y' : 'N',
                cashcount: this.state.cashcount ? 'Y' : 'N',
                mgrintervention: this.state.mgrintervention ? 'Y' : 'N',
                storecashinout: this.state.storecashinout ? 'Y' : 'N',
                enableoffline: this.state.enableoffline ? 'Y' : 'N',
                collectiontender: this.state.collectiontender ? 'Y' : 'N',
                deviceid: this.state.deviceid,
            };
        } else if (this.state.tagtype === 'tprops') {
            return {
                autolocktimeout: this.state.autolocktimeout,
            };
        } else {
            return null;
        }
    };

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
        } = this.state;

        let additionalFields = this.getAdditionalFields();

        additionalFields = {
            ...additionalFields,
            loan: this.state.autopickup ? 'N' : this.state.loan ? 'Y' : 'N',
            cashcount: this.state.autopickup
                ? 'N'
                : this.state.cashcount
                  ? 'Y'
                  : 'N',
            storecashinout: this.state.autopickup
                ? 'N'
                : this.state.storecashinout
                  ? 'Y'
                  : 'N',
        };

        let body = {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
            additionalfields: additionalFields,
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };

        return payload;
    };

    updateTagTypeValueOptions = (tagtype) => {
        if (tagtype === 'cash') {
            this.setState({ tagtypevalue: 'cash' });
        } else if (
            tagtype === 'visa' ||
            tagtype === 'master' ||
            tagtype === 'rabbit' ||
            tagtype === 'rabbitline'
        ) {
            this.setState({ tagtypevalue: 'eft' });
        } else if (tagtype === 'ccv') {
            this.setState({ tagtypevalue: 'ccv' });
        }
    };

    _saveTerminalConfig = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreTag(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createStoreTag(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            taggrouperror: '',
            tagtypeerror: '',
            // tagtypevalueerror: '',
            tenderdescerror: '',
            tendergrouperror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            storeid,
            terminalid,
            taggroup,
            tagtype,
            tagtypevalue,
            returntender,
            returntype,
            tenderdesc,
            tendergroup,
            tagtypevalueerror,
        } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'Please enter storeid' });
            valid = false;
        }
        if (terminalid.trim() === '') {
            this.setState({ terminaliderror: 'Please enter terminal id' });
            valid = false;
        }
        if (taggroup.trim().length <= 0) {
            this.setState({ taggrouperror: 'Please select tag group' });
            valid = false;
        }

        if (tagtype.trim().length <= 0) {
            this.setState({ tagtypeerror: 'Please select tag type' });
            valid = false;
        }

        if (tagtype.trim() === 'tender') {
            if (tagtypevalue.trim().length <= 0) {
                this.setState({
                    tagtypevalueerror: 'Please select tag type value',
                });
                valid = false;
            } else if (tagtypevalue.trim() === 'Select') {
                this.setState({
                    tagtypevalueerror: 'Please select tag type value',
                });
                valid = false;
            }

            if (tenderdesc.trim().length <= 0) {
                this.setState({
                    tenderdescerror: 'Please enter the tender description',
                });
            }
            if (tendergroup.trim().length <= 0) {
                this.setState({ tendergrouperror: 'Please select tag group' });
            }
        }

        if (tagtypevalueerror.trim() !== '') {
            valid = false;
        }

        if (returntender) {
            if (returntype === '') {
                this.setState({ returntypeerror: 'Please select return type' });
                valid = false;
            }
        }

        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/terminaltenders"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._saveTerminalConfig(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._saveTerminalConfig(e)}
                    >
                        Update
                    </button>
                )}
                <Link
                    to={{
                        pathname: '/ui/terminaltenders',
                        state: { terminalid: this.state.terminalid },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _renderDoubleColSubSecDivider = () => {
        return <div className="doubleCol-subsec-divider"></div>;
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    handleTerminalSelection = (selected) => {
        this.setState({
            selectedTerminal: selected,
        });
        if (selected) {
            this.setState({ terminalid: selected.value, terminaliderror: '' });
        } else {
            this.setState({ terminalid: '' });
        }
    };

    _renderBasicDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Basic Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecode"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={(event) =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    {/* <div className="row" style={{ marginBottom: 0 }}> */}
                    <div className="form-group col-lg-6 col-sm-12">
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label
                                htmlFor="terminalid"
                                className="floatLeft required"
                            >
                                Terminal Id
                            </label>
                        </div>
                        {this._isViewMode() || this._isEditMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="terminalid"
                                value={
                                    this.state.selectedTerminal &&
                                    this.state.selectedTerminal.length > 0
                                        ? this.state.selectedTerminal[0].label
                                        : ''
                                }
                                disabled={true}
                            />
                        ) : (
                            <Select
                                options={this.state.storeterminals}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={this.handleTerminalSelection}
                                allowSelectAll={false}
                                value={this.state.selectedTerminal}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.terminaliderror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="tagtypevalue"
                            className="col-form-label "
                        >
                            Tag Type Value
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                disabled={true}
                            />
                        ) : this.state.tagtype === 'tender' &&
                          !(this._isEditMode() || this._isViewMode()) ? (
                            <StatusOptionComp
                                statusoption={formatLanguageArray(
                                    this.state.tenderTagTypeOptions
                                )}
                                selectedOption={this.state.tagtypevalue}
                                callbackChangeFilter={(value) => {
                                    this.setState(
                                        { tagtypevalue: value },
                                        () => {
                                            this.checkTenderForStore();
                                        }
                                    );
                                }}
                            />
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                onChange={(event) =>
                                    this.setState(
                                        { tagtypevalue: event.target.value },
                                        () => {
                                            this.checkTenderForStore();
                                        }
                                    )
                                }
                                disabled={
                                    this._isViewMode() ||
                                    (this._isEditMode() &&
                                        this.state.tagtype === 'tender')
                                }
                            />
                        )}

                        <div className="invalid-feedback">
                            {this.state.tagtypevalueerror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderTenderSettings = () => {
        if (this.state.tagtype === 'tender') {
            return (
                <React.Fragment>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="grid">
                        <div className="col-4">
                            <label
                                htmlFor="tenderdesc"
                                className="floatLeft required"
                            >
                                Description
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                value={this.state.tenderdesc}
                                onChange={(event) =>
                                    this.setState({
                                        tenderdesc: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.tenderdescerror}
                            </div>
                        </div>

                        <div className="col-4">
                            <label
                                htmlFor="tendergroup"
                                className="floatLeft required"
                            >
                                Tender Group
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.tendergroup}
                                    disabled={true}
                                />
                            ) : this.state.tagtype === 'tender' &&
                              !this._isViewMode() ? (
                                <StatusOptionComp
                                    statusoption={this.state.terminalTenderGroupOptions}
                                    selectedOption={this.state.tendergroup}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ tendergroup: value });
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.tendergroup}
                                    onChange={(event) =>
                                        this.setState({
                                            tendergroup: event.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() ||
                                        (this._isEditMode() &&
                                            this.state.tagtype === 'tender')
                                    }
                                />
                            )}
                            <div className="invalid-feedback">
                                {this.state.tendergrouperror}
                            </div>
                        </div>
                        <div className="col-4">
                            <label
                                htmlFor="selecteddevices"
                                className="floatLeft"
                            >
                                Select Device
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deviceid"
                                    value={this.state.deviceid}
                                    disabled={true}
                                />
                            ) : this.state.tagtype === 'tender' &&
                              !this._isViewMode() ? (
                                <StatusOptionComp
                                    statusoption={this.state.storedevices}
                                    selectedOption={this.state.deviceid}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ deviceid: value });
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deviceid"
                                    value={this.state.deviceid}
                                    onChange={(event) =>
                                        this.setState({
                                            deviceid: event.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() ||
                                        (this._isEditMode() &&
                                            this.state.tagtype === 'tender')
                                    }
                                />
                            )}
                        </div>
                    </div>

                    <div className="grid">
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Sales Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.tender}
                                onChange={this.handleTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Open Cash Drawer
                            </label>
                            <ReactSwitch
                                checked={this.state.opencashdrawer}
                                onChange={this.handleOpenCashdrawerSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="voidtender"
                                className="floatLeft switch-label"
                            >
                                Void Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.voidtender}
                                onChange={this.handleVoidTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Return Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.returntender}
                                onChange={this.handleReturnTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        {this.state.returntender && (
                            <div className="col-3">
                                <label
                                    htmlFor="returntype"
                                    className="switch-label required mb-2"
                                >
                                    Return Type
                                </label>
                                <div>
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="returntype"
                                            name="returntype"
                                            value="cash"
                                            onChange={(e) =>
                                                this.setState({
                                                    returntype: e.value,
                                                })
                                            }
                                            checked={
                                                this.state.returntype === 'cash'
                                            }
                                        />
                                        <label htmlFor="returntype">Cash</label>
                                    </div>
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="returntype"
                                            name="returntype"
                                            value="ccv"
                                            onChange={(e) =>
                                                this.setState({
                                                    returntype: e.value,
                                                })
                                            }
                                            checked={
                                                this.state.returntype === 'ccv'
                                            }
                                        />
                                        <label htmlFor="returntype">CCV</label>
                                    </div>
                                    <div className="invalid-feedback mb-2">
                                        {this.state.returntypeerror}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="grid">
                        <div className="form-group col-3">
                            <label
                                htmlFor="voidtender"
                                className="floatLeft switch-label"
                            >
                                Manager Intervention
                            </label>
                            <ReactSwitch
                                checked={this.state.mgrintervention}
                                onChange={this.handleMgrInterventionSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Offline Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.offlinetender}
                                onChange={this.handleOfflineTenderSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Collection Tender
                            </label>
                            <ReactSwitch
                                checked={this.state.collectiontender}
                                onChange={() =>
                                    this.setState({
                                        collectiontender:
                                            !this.state.collectiontender,
                                    })
                                }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Enable Offline
                            </label>
                            <ReactSwitch
                                checked={this.state.enableoffline}
                                onChange={this.handleEnableOfflineSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Auto Pickup
                            </label>
                            <ReactSwitch
                                checked={this.state.autopickup}
                                onChange={() =>
                                    this.setState({
                                        autopickup: !this.state.autopickup,
                                    })
                                }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="grid">
                        {!this.state.autopickup && (
                            <div className="form-group col-3">
                                <label
                                    htmlFor="validfrom"
                                    className="floatLeft switch-label"
                                >
                                    Loan
                                </label>
                                <ReactSwitch
                                    checked={this.state.loan}
                                    onChange={this.handleLoanSwitch}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="discount-tax-switch"
                                    disabled={this._isViewMode()}
                                />
                            </div>
                        )}
                        {!this.state.autopickup && (
                            <div className="form-group col-3">
                                <label
                                    htmlFor="validfrom"
                                    className="floatLeft switch-label"
                                >
                                    Cash Count
                                </label>
                                <ReactSwitch
                                    checked={this.state.cashcount}
                                    onChange={this.handleCashCountSwitch}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="discount-tax-switch"
                                    disabled={this._isViewMode()}
                                />
                            </div>
                        )}
                        {!this.state.autopickup && (
                            <div className="form-group col-3">
                                <label
                                    htmlFor="validfrom"
                                    className="floatLeft switch-label"
                                >
                                    Store Cash In/Out
                                </label>
                                <ReactSwitch
                                    checked={this.state.storecashinout}
                                    onChange={this.handleStoreCashInOutSwitch}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="discount-tax-switch"
                                    disabled={this._isViewMode()}
                                />
                            </div>
                        )}
                    </div>

                    <div className="grid">
                        <div className="form-group col-4">
                            <label htmlFor="minamount" className="floatLeft">
                                Min Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="minamount"
                                value={this.state.minamount}
                                onChange={(event) =>
                                    this.setState({
                                        minamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="maxamount" className="floatLeft">
                                Max Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="maxamount"
                                value={this.state.maxamount}
                                onChange={(event) =>
                                    this.setState({
                                        maxamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="tendercount" className="floatLeft">
                                Tender Count (0-Unlimited)
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="tendercount"
                                value={this.state.tendercount}
                                onChange={(event) =>
                                    this.setState({
                                        tendercount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="grid">
                        <div className="form-group col-12">
                            <label htmlFor="tenderimg" className="floatLeft">
                                Tender Image
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tenderimg"
                                    value={this.state.tenderimg}
                                    onChange={(event) =>
                                        this.setState({
                                            tenderimg: event.target.value,
                                        })
                                    }
                                    disabled={true}
                                />
                            ) : (
                                <SimpleImageInput
                                    placeholder="Choose Image.."
                                    setImage={(value) => {
                                        this.setState({ tenderimg: value });
                                    }}
                                    value={this.state.tenderimg}
                                ></SimpleImageInput>
                            )}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="form-group col-12">
                            <label
                                htmlFor="tenderinstruction"
                                className="floatLeft"
                            >
                                Tender Instructions
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderinstruction"
                                value={this.state.tenderinstruction}
                                onChange={(event) =>
                                    this.setState({
                                        tenderinstruction: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="form-group col-12">
                            <label
                                htmlFor="tenderanimationimg"
                                className="floatLeft"
                            >
                                Tender Animation
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderanimationimg"
                                value={this.state.tenderanimationimg}
                                onChange={(event) =>
                                    this.setState({
                                        tenderanimationimg: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _renderDefaultSettings = () => {
        if (this.state.tagtype === 'tprops') {
            return (
                <React.Fragment>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="tenderdesc"
                                className="floatLeft required"
                            >
                                Auto Lock Timeout
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                value={this.state.autolocktimeout}
                                onChange={(event) =>
                                    this.setState({
                                        autolocktimeout: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.autolocktimeouterror}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    _renderOtherPropDetails = () => {
        return (
            <div className="row">
                <div className="form-group col-lg-12 col-sm-12">
                    <label htmlFor="status" className="floatLeft">
                        Status
                    </label>
                    {this._isViewMode() ? (
                        <input
                            type="text"
                            className="form-control"
                            id="status"
                            value={this.state.status}
                            disabled={true}
                        />
                    ) : (
                        <StatusOptionComp
                            statusoption={this.editModeStatus}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    _renderMainForm = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderBasicDetails()}
                            {this._renderTenderSettings()}
                            {this._renderDefaultSettings()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                        >
                            {this._renderOtherPropDetails()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    componentWillReceiveProps = (nextProps) => {
        const { storeDevices, tenderTagTypes: propConfigs, storeTenderGroupTypes } = nextProps.queryData;
    
        const hasAdditionalFields = (array) =>
            array && array.length > 0 && array[0].additionalfields;
    
        // Update store devices
        const storedevices = storeDevices && storeDevices.length > 0 
            ? [{ name: 'Select', id: '' }, ...storeDevices.map((e) => ({ name: e.title, id: e.deviceid }))]
            : [{ name: 'Select', id: '' }];
        this.setState({ storedevices });
    
        // Update tender tag type options
        if (hasAdditionalFields(propConfigs)) {
            this.setState({
                tenderTagTypeOptions: {
                    Select: 'Select',
                    ...propConfigs[0].additionalfields
                }
            });
        }
    
        // Update terminal tender group options
        if (hasAdditionalFields(storeTenderGroupTypes)) {
            const terminalTenderGroupOptions = [
                { name: 'Select', id: '' },
                ...Object.entries(storeTenderGroupTypes[0].additionalfields).map(([key, value]) => ({
                    name: value,
                    id: key
                }))
            ];
            this.setState({
                terminalTenderGroupOptions
            });
        }
    };
    

    componentDidMount = () => {
        this.props.dispatch(
            storeTerminalsSearchByFields(
                `storeid=${this.state.storeid}&devicegroup=terminal&status=Active`
            )
        );
    };

    componentWillMount = () => {
        const storeid = localStorage.getItem('storeid');
        this.setState({ storeid });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    title,
                    taggroup,
                    tagtype,
                    tagtypevalue,
                    status,
                    additionalfields,
                } = rec;
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.terminalid = terminalid ? terminalid : '';
                        draft.taggroup = taggroup ? taggroup : '';
                        draft.tagtype = tagtype ? tagtype : '';
                        draft.tagtypevalue = tagtypevalue ? tagtypevalue : '';
                        draft.status = status ? status : '';

                        draft.additionalFields = additionalfields;
                        if (additionalfields) {
                            if (additionalfields['tenderdesc']) {
                                draft.tenderdesc =
                                    additionalfields['tenderdesc'];
                            }
                            if (additionalfields['tender']) {
                                draft.tender =
                                    additionalfields['tender'] === 'Y';
                            }
                            if (additionalfields['collectiontender']) {
                                draft.collectiontender =
                                    additionalfields['collectiontender'] ===
                                    'Y';
                            }
                            if (additionalfields['voidtender']) {
                                draft.voidtender =
                                    additionalfields['voidtender'] === 'Y';
                            }
                            if (additionalfields['loan']) {
                                draft.loan = additionalfields['loan'] === 'Y';
                            }
                            if (additionalfields['pickup']) {
                                draft.pickup =
                                    additionalfields['pickup'] === 'Y';
                            }
                            if (additionalfields['autopickup']) {
                                draft.autopickup =
                                    additionalfields['autopickup'] === 'Y';
                            }
                            if (additionalfields['opencashdrawer']) {
                                draft.opencashdrawer =
                                    additionalfields['opencashdrawer'] === 'Y';
                            }
                            if (additionalfields['returntender']) {
                                draft.returntender =
                                    additionalfields['returntender'] === 'Y';
                            }
                            if (additionalfields['returntype']) {
                                draft.returntype =
                                    additionalfields['returntype'];
                            }
                            if (additionalfields['minamount']) {
                                draft.minamount = additionalfields['minamount'];
                            }
                            if (additionalfields['maxamount']) {
                                draft.maxamount = additionalfields['maxamount'];
                            }
                            if (additionalfields['tendercount']) {
                                draft.tendercount =
                                    additionalfields['tendercount'];
                            }
                            if (additionalfields['tenderimg']) {
                                draft.tenderimg = additionalfields['tenderimg'];
                            }
                            if (additionalfields['tenderinstruction']) {
                                draft.tenderinstruction =
                                    additionalfields['tenderinstruction'];
                            }
                            if (additionalfields['tenderanimationimg']) {
                                draft.tenderanimationimg =
                                    additionalfields['tenderanimationimg'];
                            }
                            if (additionalfields['tendergroup']) {
                                draft.tendergroup =
                                    additionalfields['tendergroup'];
                            }
                            if (additionalfields['offlinetender']) {
                                draft.offlinetender =
                                    additionalfields['offlinetender'] === 'Y';
                            }
                            if (additionalfields['cashcount']) {
                                draft.cashcount =
                                    additionalfields['cashcount'] === 'Y';
                            }
                            if (additionalfields['mgrintervention']) {
                                draft.mgrintervention =
                                    additionalfields['mgrintervention'] === 'Y';
                            }
                            if (additionalfields['storecashinout']) {
                                draft.storecashinout =
                                    additionalfields['storecashinout'] === 'Y';
                            }
                            if (additionalfields['enableoffline']) {
                                draft.enableoffline =
                                    additionalfields['enableoffline'] === 'Y';
                            }
                            if (additionalfields['deviceid']) {
                                draft.deviceid = additionalfields['deviceid'];
                            }
                        }
                    })
                );
            }
        }

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: TENDERTAGTYPES,
                    },
                },
                SEARCH_PROP_CONFIG_TENDERTAGTYPES,
                SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE
            )
        );

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: TENDER_GROUP_PTYPES,
                    },
                },
                SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES,
                SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS,
                SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE
            )
        );

        if (storeid && storeid !== '') {
            this.props.dispatch(
                storeDevicesSearchByFields(
                    `status=Active&storeid=${storeid}&devicegroup=eft`
                )
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeTerminals, storeDevices, storeTags } =
            this.props.queryData;
        const {
            storeTerminals: prevStoreTerminals,
            storeDevices: prevstoreDevices,
            storeTags: prevstoreTags,
        } = prevProps.queryData;

        if (storeTerminals !== prevStoreTerminals) {
            if (storeTerminals && storeTerminals.length > 0) {
                const options = storeTerminals.map((e) => ({
                    label: e.terminalid + ' - ' + e.title,
                    value: e.terminalid,
                }));
                this.setState({ storeterminals: options });
                if (this.state.terminalid != '') {
                    const _selectedTerminal = storeTerminals.filter(
                        (e) => e.terminalid === this.state.terminalid
                    );
                    if (_selectedTerminal && _selectedTerminal.length > 0) {
                        const selectedTerminal = _selectedTerminal.map((e) => ({
                            label: e.terminalid + ' - ' + e.title,
                            value: e.terminalid,
                        }));
                        this.setState({ selectedTerminal: selectedTerminal });
                    }
                }
            } else {
                this.setState({ storeterminals: [], selectedTerminal: {} });
            }
        }

        if (storeDevices !== prevstoreDevices) {
            if (storeDevices && storeDevices.length > 0) {
                var returnedDevices = storeDevices.map((e) => ({
                    name: e.title,
                    id: e.deviceid,
                }));
                this.setState({
                    storedevices: [{ name: 'Select', id: '' }].concat(
                        returnedDevices
                    ),
                });
            } else {
                this.setState({ storedevices: [{ name: 'Select', id: '' }] });
            }
        }

        if (storeTags !== prevstoreTags) {
            if (storeTags && storeTags.length > 0) {
                this.setState({ tagtypevalueerror: '' });
            } else {
                this.setState({
                    tagtypevalueerror: 'store tender not defined',
                });
            }
        }
    };

    checkTenderForStore = () => {
        let queryParam = `storeid=${this.state.storeid}&taggroup=storeprops&tagtype=tender&tagtypevalue=${this.state.tagtypevalue}`;
        this.props.dispatch(storeTagsSearchByFields(queryParam));
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Terminal Tender'
                                    : this._isViewMode()
                                      ? 'View Terminal Tender'
                                      : 'Add Terminal Tender'}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderMainForm()}
                        </div>
                    </div>

                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/terminaltenders"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Terminal Tender updated successfully'
                                    : 'Terminal Tender created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddTerminalTenders);
