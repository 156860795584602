import React from "react";
import { connect } from "react-redux";
import SimpleSelect from "./../../../../components/SimpleSelect";
class Member extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: Object.keys(this.props.member).length !== 0
        ? this.props.member
        : [{ field: "", value: "", id: 0 }]
    };
  }

  componentWillMount = () => {
    // const requestBody = {
    //   body: {
    //     title: "MEMBER_PROPS",
    //     category: "Config"
    //   }
    // };
    // this.props.dispatch(
    //   queryDefaultConfigByTitle(
    //     requestBody,
    //     "MEMBER_PROPS_BY_TITLE",
    //     "MEMBER_PROPS_BY_TITLE_SUCCESS",
    //     "MEMBER_PROPS_BY_TITLE_FAILURE"
    //   )
    // );
    // const requestBody1 = {
    //   body: {
    //     title: "OPERATOR",
    //     category: "Config"
    //   }
    // };
    // this.props.dispatch(
    //   queryDefaultConfigByTitle(
    //     requestBody1,
    //     "OPERATORS_BY_TITLE",
    //     "OPERATORS_BY_TITLE_SUCCESS",
    //     "OPERATORS_BY_TITLE_FAILURE"
    //   )
    // );
  };
  createUI() {
   // const { memberProps, operatorsConfig } = this.props.queryData;
    //console.log(memberProps);
    return this.state.values.map((value, index) => {
      let idx = value.id;
      let field = value.field;
      //let condi = value.condi;
      return (
        <div className="row" key={index}>
          <div className="col-md-5 col-sm-12 noPadding">
            <SimpleSelect
              objArray={{
                "membersegment": "Member Segment"
              }}
              callbackChangeFilter={value => {
                this.handleChange("field", value, idx);
              }}
              selectedOption={field}
              id="field"
            />
          </div>
           {/* <div className="col-md-3 col-sm-12">
            <SimpleSelect
              objArray={operatorsConfig.pvalues}
              callbackChangeFilter={value => {
                this.handleChange("condi", value, idx);
              }}
              selectedOption={condi}
              id="condi"
            />
          </div> */}
          <div className="col-md-5 col-sm-12">
            <input
              id="value"
              type="text"
              value={value.value}
              className="dynamicDescInp"
              onChange={event =>
                this.handleChange(event.target.id, event.target.value, idx)
              }
            />
          </div>

          <div className="col-md-2 col-sm-12 alignLeft">
            <img
              className={`dynamicbtn1 dynamicBtn1-`+idx}
              onClick={event => this.removeClick(event, idx)}
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            />
          </div>
        </div>
      );
    });
  }

  handleChange = (fieldId, fieldValue, id) => {
    const newProducts = this.state.values.map(value => {
      if (id !== value.id) return value;

      return { ...value, [fieldId]: fieldValue };
    });
    this.setState({ values: newProducts }, () => {
      this.props.setMemberProps(this.state.values);
    });
  };

  addClick = event => {
    let lastEle = this.state.values[this.state.values.length - 1];

    const item = {
      field: "",
      //operator: "",
      value: "",
      id: lastEle.id + 1
    };
    this.setState({
      values: [...this.state.values, item]
    });
    if (event) event.preventDefault();
  };
  removeClick = (evt, idx) => {
    // const updateValues = [...this.state.values];
    // updateValues.splice(idx, 1);
    const updateValues = this.state.values.filter(value => idx !== value.id);

    this.setState({ values: updateValues });
  };
  render() {
    return (
      <React.Fragment>
        {this.createUI()}
        <div className="row">
          <button className="addNewRow" onClick={event => this.addClick(event)}>
            Add New
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(Member);
