import React from 'react';

const EditImage = () => {
    return (
        <img
            title="Edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            alt="edit"
        />
    );
};

export default EditImage;
