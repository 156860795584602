import React from "react";
import ExportButton from "../../../components/TableExportButton";
import LinkButton from "../../../components/LinkButton";
import SimpleTable from "../../../components/SimpleTable";

import { produce } from "immer";
import {
  searchGeneral,
  crudReset,
  resetAction
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import DatePicker from "react-date-picker";
import Modal from "react-awesome-modal";
import { formatDate } from "../../../helpers/Util";
const dateType = [
  { name: "Select Date Type", id: "" },
  { name: "Last Updated", id: "lastupdated" },
  { name: "Date Created", id: "createdon" }
];
const searchField = [
  { name: "Select DataField", id: "" },
  { name: "First Name", id: "firstname" },
  { name: "Last Name", id: "lastname" },
  { name: "Member Id", id: "memberid" },
  { name: "Email Id", id: "emailid" },
  { name: "Status", id: "status" }
];
const operators = [
  { name: "Select Condition", id: "-1" },
  { name: "Equal", id: "eq" },
  { name: "Like", id: "like" }
];

export const statusValues = [
  { name: "All", id: "" },
  { name: "New", id: "New" },
  { name: "Active", id: "Active" },
  { name: "Deleted", id: "Deleted" }
];
class MemberProfileReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pages: "",
      currPage: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      date_from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      date_till: new Date(),
      date_type: "createdon",
      showSearchDiv: false,
      tableCols: [
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
          dataField: "userid",
          text: "User Id",
          sort: true
        },
        {
          dataField: "firstname",
          text: "First Name",
          sort: true
        },
        {
          dataField: "lastname",
          text: "Last Name",
          sort: true
        },
        {
          dataField: "emailid",
          text: "Email",
          sort: true
        },
        {
          dataField: "mobileno",
          text: "Mobile",
          sort: true
        },
        {
          dataField: "signupby",
          text: "Sign Up By",
          sort: true
        },
        {
          dataField: "status",
          text: "Status"
        }
      ]
    };
  }

  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  
  // API Calls
  createFetchUrl = (
    pages,
    currPage
  ) => {
    const {
      search_field,
      search_condi,
      search_value,
      date_from,
      date_till,
      date_type
    } = this.state;
    const requestBody = {
      body: {
        /* pg_sz: pages,
        c_p: currPage */
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value,
        date_from: formatDate(date_from),
        date_till: formatDate(date_till),
        date_type: date_type,
        pagesize: pages,
        pageno: currPage
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    const { pages, currPage, language, status } = this.state;

    this.props.dispatch(
      searchGeneral(
        "crm/v1/member/search",
        this.createFetchUrl(pages, currPage),
        "SEARCH_MEMBER_PROFILE_SUCCESS",
        "SEARCH_MEMBER_PROFILE_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        const [pgNumber] = rest;

        newState = produce(this.state, draft => {
          draft.currPage = pgNumber - 1;
        });
        this.setState({ ...newState }, () => {
          this.callFetch("PAGECLICK");
        });

        break;
    }
  };
  searchQuery = () => {
    /*  if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("SEARCH");
      }
    ); */
  };
  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage }, () =>
      this.callQueryApiForFetch("ONLOAD")
    );
  }

  pageNumberOnClick = pgNumber => {
    this.callQueryApiForFetch("PAGECLICK", pgNumber);
  };

  

  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  handlefromdateChange = value => {
    this.setState({
      date_from: value
    });
  };
  handletodateChange = value => {
    this.setState({
      date_till: value
    });
  };
  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };
  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };
  _renderSearchByDate = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.handlefromdateChange}
              value={this.state.date_from}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="Transaction Date From" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.handletodateChange}
              value={this.state.date_till}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="date_type" className="floatLeft">
              Date Type
            </label>
            <div className="row">
              <select
                name="date_type"
                className="simpleSearchSelect2"
                value={this.state.date_type}
                onChange={event =>
                  this.setState({ date_type: event.target.value })
                }
              >
                {dateType.length != 0 &&
                  dateType.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_field" className="floatLeft">
              Search By
            </label>
            <div className="row">
              <select
                name="search_field"
                className="simpleSearchSelect2"
                value={this.state.search_field}
                onChange={event =>
                  this.setState({ search_field: event.target.value })
                }
              >
                {searchField.length != 0 &&
                  searchField.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_condi" className="floatLeft">
              Search Field
            </label>
            <div className="row">
              <select
                name="search_condi"
                className="simpleSearchSelect2"
                value={this.state.search_condi}
                onChange={event =>
                  this.setState({ search_condi: event.target.value })
                }
              >
                {operators.length != 0 &&
                  operators.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="search_value" className="floatLeft">
              Search Value
            </label>
            {this.state.search_field !== "status" ? (
              <input
                type="text"
                value={this.state.query}
                className="form-control"
                placeholder="Enter value"
                onChange={event =>
                  this.setState({ search_value: event.target.value })
                }
              />
            ) : (
              <select
                name="example"
                className="form-control"
                value={this.state.search_value}
                onChange={event =>
                  this.setState({ search_value: event.target.value })
                }
              >
                {statusValues.length != 0 &&
                  statusValues.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>
        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("SEARCH");
                this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="50%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm searchModalForm1">
          <div className="row">
            <div className="col-xs-12 col-md-12 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          {this._renderSearchByDate()}
        </div>
      </Modal>
    );
  };
  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };
  
  // exportTableData(col, items){
  //    var mapped = col.map(e=> ({label: e.text, key: e.dataField}));
  // }

  getTableData = (records, headers) => {
    const tablerow = records.map(e => [e.memberid, e.userid, e.firstname, e.lastname, e.emailid, e.mobileno, e.signupby, e.status]);
    const colheader = headers.filter(e => e.text !== "Actions").map(e => ({ label: e.text, key: e.dataField }))
    tablerow.unshift(colheader.map(e=>e.label));
    return tablerow;
  }

  render() {
    const {
      userProfileList,
      errormsg: queryError,
      servererror: serverError
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }

    if (!userProfileList && !errormsg) {
      return <Loading />;
    }

    if (!userProfileList || this.props.queryData.apiPending) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        {this._renderSearchModal()}
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">Member Profile Report</h2>
            </div>
            <div className="col-md-3 text-right noPadding noMargin">
              <ExportButton 
                title="Member Profile Report"
                tabledata ={this.getTableData(userProfileList, this.state.tableCols)}
              />
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-10 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Member Profile</h3>
                  </div>
                  <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
                    {this._renderSearchBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={userProfileList}
                  callback={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>

          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this member profile?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(MemberProfileReport);
