import React from "react";
import SimpleSelect from "../../../../components/SimpleSelect";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import { toDate } from "./../../../../helpers/Util";
import moment from "moment";

class CouponValidity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      couponby: this.props.couponby || "Day",
      couponextend: this.props.couponextend || "",
      extendexpiry: this.props.extendexpiry || "",
      svalue: this.props.svalue || "",
      evalue: this.props.evalue || "",
      maxqty: this.props.maxqty || ""
    };
  }
  onCardSelect = value => {
    this.setState({ couponby: value });
  };
  componentWillMount = () => {
    if (this.props.couponby === "fixdate") {
      this.setState({
        svalue: toDate(this.props.svalue),
        evalue: toDate(this.props.evalue)
      });
    }
  };
  render() {
    const { couponby, svalue, evalue } = this.state;
    return (
      <div>
        <div className="row ">
          <div className="form-group col-lg-5 col-sm-12 noPadding noMargin">
            <SimpleSelect
              objArray={{
                "Day": "By Days"
              }}
              callbackChangeFilter={value => {
                this.setState({ couponby: value }, () =>
                  this.props.setCouponBy(value)
                );
              }}
              selectedOption={this.state.couponby}
              id="language"
            />
          </div>
        </div>
        {couponby === "Day" && (
          <div className="row noPadding">
            <div className="form-group col-lg-5 col-sm-12 noPadding">
              <label htmlFor="svalue" className="floatLeft ">
                Days From*
              </label>
              <input
                type="number"
                className="form-control"
                id="svalue"
                placeholder="From Day"
                value={this.state.svalue}
                min="0"
                onChange={event => {
                  this.setState({ svalue: event.currentTarget.value });
                  this.props.setStartValue(event.currentTarget.value);
                }}
              />
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="form-group col-lg-5 col-sm-12 noPadding">
              <label htmlFor="evalue" className="floatLeft ">
                Days Till*
              </label>
              <input
                type="number"
                //pattern="[0-9]*"
                className="form-control"
                id="evalue"
                placeholder="Till Day"
                value={this.state.evalue}
                min="0"
                onChange={event => {
                  this.setState({ evalue: event.currentTarget.value });
                  this.props.setEndValue(event.currentTarget.value);
                }}
              />
            </div>
            <div className="invalid-feedback">
                            {this.props.couponvalidityError}
                        </div>
          </div>
        )}
        {couponby === "fixdate" && (
          <div className="row ">
            <div className="row ">
              <label htmlFor="notification" className="floatLeft ">
                Choose a date range
              </label>
            </div>

            <div className="col-lg-4 col-sm-12 noPadding noMargin">
              <DatePicker
                onChange={value => {
                  this.setState({ svalue: value });
                  this.props.setStartValue(value);
                }}
                value={
                  moment(this.state.svalue, "DD-MM-YYYY",true).isValid()==false ? 
                  "" : this.props.svalue
                }
              />
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-4 col-sm-12 noPadding noMargin">
              <DatePicker
                onChange={value => {
                  this.setState({ evalue: value });
                  this.props.setEndValue(value);
                }}
                value={
                  moment(this.state.evalue, "DD-MM-YYYY",true).isValid()==false ? 
                  "" : this.props.evalue
                }
              />
            </div>
          </div>
        )}
         <div className="row">           
          <div className="form-group col-lg-5 col-sm-12 noPadding noMargin">
            <label htmlFor="couponvalidextend" className="floatLeft">
              Extend Coupon Validity
            </label>
            <input
              type="text"
              className="form-control"
              id="couponvalidextend"
              value={this.state.couponextend}
              onChange={event => {
                this.setState({ couponextend: event.target.value });
                this.props.setCouponExtend(event.target.value);
              }}
            />
          </div>
          <div className="col-lg-1 col-sm-12"></div>
          <div className="form-group col-lg-5 col-sm-12 noPadding noMargin">
            <label htmlFor="extendexpiry" className="floatLeft">
              Extend Expiry
            </label>
            <SimpleSelect
              objArray={{
                Y: "Yes",
                N: "No"
              }}
              callbackChangeFilter={value =>
                  this.setState({ extendexpiry: value },()=>{
                    this.props.setCouponExpiry(value);
                  })
              }
              selectedOption={this.state.extendexpiry}
              id="extendexpiry"
            />
          </div>
        </div>
        <div className="row" style={{ display: 'none'}}>
          <div className="form-group col-lg-5 col-sm-12 noPadding noMargin">
            <label htmlFor="maxqty" className="floatLeft">
              Maximum Quantity
            </label>
            <input
              type="number"
              className="form-control"
              id="maxqty"
              value={this.state.maxqty}
              min="0"
              onChange={event => {
                this.setState({ maxqty: event.target.value });
                this.props.setCouponMaxQty(event.target.value);
              }}           
            />
            <div className="invalid-feedback">{this.state.maxqtyError}</div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(CouponValidity);
