import React, { Component } from "react";
import DatePicker from "react-date-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";


class DynamicDateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDatePicker = (date, name, field, row) => {
    if (moment(date, "DD-MM-YYYY", true).isValid() == true) {
      this.props.handleInputChange(date, field, row);
      this.setState({ [name]: date });
    }
  };

  handleTimePicker = (time, name, field, row) => {
    this.props.handleInputChange(time, field, row);
    this.setState({
      [name]: time
    });
  };

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;
    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  
  renderRowData = (column, row, col, index, rowId, rowleng) => {
    if (column.addRow) {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          {column.dataFieldId === "pickdate" ? (
            <DatePicker
              locale="en-GB"
              className="datepicker"
              name={"pickdate_" + rowId}
              onChange={e =>
                this.handleDatePicker(
                  e,
                  "pickdate_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={this.state["pickdate_" + rowId] || this.props.rows.filter(r=>r.id===row.id)[0].pickdate}
            />
          ) : column.dataFieldId === "starttime" ? (
            <TimePicker
              name={"starttime_" + rowId}
              onChange={e =>
                this.handleTimePicker(
                  e,
                  "starttime_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={(moment(this.state["starttime_" + rowId], 'HH:mm').isValid() ? 
              moment(this.state["starttime_" + rowId], 'HH:mm') : '')
               || (moment(this.props.rows.filter(r=>r.id===row.id)[0].starttime, "HH:mm").isValid() ?
               moment(this.props.rows.filter(r=>r.id===row.id)[0].starttime, "HH:mm") : ''
               )}
              showSecond={false}
            />
          ) : (
            <TimePicker
              name={"endtime_" + rowId}
              onChange={e =>
                this.handleTimePicker(
                  e,
                  "endtime_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={(moment(this.state["endtime_" + rowId], "HH:mm").isValid() ?
              moment(this.state["endtime_" + rowId], 'HH:mm') : '')
              || (moment(this.props.rows.filter(r=>r.id===row.id)[0].endtime, "HH:mm").isValid() ? 
              moment(this.props.rows.filter(r=>r.id===row.id)[0].endtime, "HH:mm") : ''
              )}
              showSecond={false}
            />
          )}
        </td>
      );
    }
    if (col === "AD") {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          <img
            className="addBtn1"
            onClick={this.props.addRow}
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
          />

          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row)}
            className="addBtn2"
          />
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <React.Fragment>
        <div className="dynamicDateTimePicker">
          <table>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicDateTimePicker;
