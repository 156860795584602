import React from "react";

class FrequencyComp extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      frequency: this.props.frequency || "Once"
    };
  }

  onCardSelect = value => {
    this.setState({ frequency: value }, () => this.props.setFrequency(value));
  };

  render() {
    const { frequency } = this.state;
    return (
      <div className="row ">
        <div className="col-lg-2 col-sm-12 noPadding noMargin">
          <p
            className={
              frequency === "Once" ? "cardSelected" : "cardNonSelected"
            }
            onClick={() => this.onCardSelect("Once")}
          >
            Once
          </p>
        </div>
        <div className="col-lg-2 col-sm-12 noPadding noMargin">
          <p
            className={
              frequency === "Daily" ? "cardSelected" : "cardNonSelected"
            }
            onClick={() => this.onCardSelect("Daily")}
          >
            Daily
          </p>
        </div>
        <div className="col-lg-2 col-sm-12 noPadding noMargin">
          <p
            className={
              frequency === "Monthly" ? "cardSelected" : "cardNonSelected"
            }
            onClick={() => this.onCardSelect("Monthly")}
          >
            Monthly
          </p>
        </div>
        <div className="col-lg-2 col-sm-12 noPadding noMargin">
          <p
            className={
              frequency === "Yearly" ? "cardSelected" : "cardNonSelected"
            }
            onClick={() => this.onCardSelect("Yearly")}
          >
            Yearly
          </p>
        </div>
      </div>
    );
  }
}
export default FrequencyComp;
