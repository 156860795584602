import React, { Component } from "react";
import { renderFilter } from "../helpers/Util";
import Modal from "react-awesome-modal";

import makeAnimated from "react-select/animated";
import Select, { components } from "react-select";
const defaultchecked = [
  { id: "Y", name: "Yes" },
  { id: "N", name: "No" }
];
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

class DynamicGroupAddOn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMapModal: false,
      selected: []
    };
  }

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );

  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;
    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.itemidx;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  renderRowData = (column, row, col, index, rowId, rowleng) => {
    const groups = renderFilter(this.props.groups || [{}]);
    const addongrpid = this.props.addongrpid;

    if (column.addRow) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial"
          }}
        >
          <input
            type="text"
            value={row[column.dataFieldId]}
            disabled={column.disabled}
            className="addoninput"
            onChange={event =>
              this.props.handleInputChange(
                event,
                column.dataFieldId,
                row,
                addongrpid
              )
            }
          />
        </td>
      );
    }
    if (column.itemid) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial"
          }}
        >
          <input
            type="text"
            value={`${this.props.addongrpid}:${row[column.dataFieldId]}`}
            readOnly={true}
            className="addoninput"
            style={{ width: "5em" }}
          />
        </td>
      );
    }
    if (column.modal) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial"
          }}
        >
          <input
            type="text"
            // defaultValue={row[column.dataFieldId]}
            value={
              row["additionalfields"] && row["additionalfields"].title
                ? row["additionalfields"].title
                : ""
            }
            disabled={column.disabled}
            className="addoninput productcodemodal"
            readOnly={true}
            onClick={event =>
              this.props.showProductModal(
                event,
                row[column.dataFieldId],
                addongrpid,
                row
              )
            }
          />
        </td>
      );
    }
    if (column.addField) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial"
          }}
        >
          <input
            type="text"
            value={
              row["additionalfields"] && row["additionalfields"].title
                ? row["additionalfields"].title
                : ""
            }
            readOnly={true}
            className="addoninput"
          />
        </td>
      );
    }
    if (column.check) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial",
            width: "12em"
          }}
        >
          <select
            name="select"
            className="form-control"
            value={row[column.dataFieldId]}
            onChange={event =>
              this.props.handleInputChange(
                event,
                column.dataFieldId,
                row,
                addongrpid,
                "",
                this.props.multiSelect
              )
            }
            disabled={column.disabled}
          >
            {defaultchecked.length != 0 &&
              defaultchecked.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </td>
      );
    }
    if (column.checkaddon) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial",
            width: "12em"
          }}
        >
          <select
            name="select"
            className="form-control"
            value={row[column.dataFieldId]}
            onChange={event =>
              this.props.handleInputChange(
                event,
                column.dataFieldId,
                row,
                addongrpid,
                "",
                //this.props.multiSelect
              )
            }
            disabled={column.disabled}
          >
            {defaultchecked.length != 0 &&
              defaultchecked.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </td>
      );
    }
    if (column.forTax) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial",
            width: "12em"
          }}
        >
          <select
            name={"taxcategory_" + rowId}
            className="form-control"
            value={row[column.dataFieldId]}
            onChange={event =>
              this.props.handleInputChange(
                event,
                column.dataFieldId,
                row,
                addongrpid
              )
            }
            disabled={column.disabled}
          >
            {this.props.taxfields.length != 0 &&
              this.props.taxfields.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </td>
      );
    }
    // if(column.sortorder){
    //   return (
    //   <td
    //   key={`tableview-td-${rowId}-${index}`}
    //   style={{
    //     verticalAlign: "initial"
    //   }}
    // >
    //   <input
    //     type="number"
    //     value={
    //       row["sortorder"] ? row["sortorder"]
    //         : ""
    //     }
    //     //readOnly={true}
    //     className="addoninput"
    //   />
    // </td>
    //   );
    // }
    if (col === "AD") {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{
            verticalAlign: "initial"
          }}
        >
          <img
            className="addBtn1"
            onClick={event => {
              this.props.addRow(event, this.props.addongrpid);
            }}
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
          />

          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row, addongrpid)}
            className="addBtn2"
          />
        </td>
      );
    }

    if (column.itemmap === true) {
      const labels = this._getItemsMap(row[column.dataFieldId]);
      const values = this._getItemsMapForSelect(row[column.dataFieldId]);
      const selectoptions = this.props.beforemulselectopen(addongrpid);

      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{ verticalAlign: "top" }}
        >
          <div
            className="form-control"
            style={{ height: "3em" }}
            onClick={() =>
              this._showMapModal(
                column.dataFieldId,
                row,
                addongrpid,
                values,
                selectoptions
              )
            }
          >
            {labels.map((value, index) => {
              return (
                <span key={index} className="badge badge-secondary">
                  {value}
                </span>
              );
            })}
          </div>
        </td>
      );
    }

    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  _updateMapModal = () => {};
  _showMapModal = (dataFieldId, row, addongrpid, values, selectoptions) => {
    const array = selectoptions.filter(option => {
      return values.indexOf(option.value) >= 0;
    });
    this.setState({
      dataFieldId,
      row,
      addongrpid,
      showMapModal: true,
      selected: array
    });
  };
  _hideMapModal = () => {
    this.setState({ showMapModal: false });
  };

  groupBy = (data, key) => {
    let groupedArray = [];
    const groupoptions = data.reduce(
      (result, item) => ({
        ...result,
        [item["group"]]: [...(result[item["group"]] || []), item]
      }),
      {}
    );
    Object.entries(groupoptions).forEach(value => {
      let item = { label: value[0], options: value[1] };
      groupedArray.push(item);
    });
    return groupedArray;
  };
  _renderMapModal = () => {
    const { dataFieldId, row, addongrpid } = this.state;
    const Group = props => (
      <div style={groupStyles}>
        <components.Group {...props} />
      </div>
    );
    const Option = props => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
            <label style={{ marginLeft: "2px" }}>{props.label}</label>
          </components.Option>
        </div>
      );
    };
    const allOption = {
      label: "Select all",
      value: "*"
    };
    const ValueContainer = ({ children, ...props }) => {
      const currentValues = props.getValue();
      let toBeRendered = children;
      if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
      }

      return (
        <components.ValueContainer {...props}>
          {toBeRendered}
        </components.ValueContainer>
      );
    };
    const MultiValue = props => {
      let labelToBeDisplayed = `${props.data.label} `;
      if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All is selected";
      }
      return (
        <components.MultiValue {...props}>
          <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
      );
    };
    const animatedComponents = makeAnimated();
    return (
      <Modal
        visible={this.state.showMapModal}
        width="80%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._hideMapModal()}
      >
        <div className="comboform">
          <div className="row">
            <div className="col-xs-12 col-md-12 alignRight">
              <a onClick={this._hideMapModal} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="row" style={{ marginTop: "10px", height: "22em" }}>
            <div className="col-12">
              <Select
                value={this.state.selected}
                onChange={value => {
                  this.setState({ selected: value });
                }}
                options={this.groupBy(
                  this.props.beforemulselectopen(addongrpid),
                  addongrpid
                )}
                components={{
                  Option,
                  MultiValue,
                  ValueContainer,
                  animatedComponents,
                  Group
                }}
                classNamePrefix="react-select"
                className="react-select-container"
                autosize={true}
                noResultsText="No Products Found"
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "#6c90c1",
                    primary25: "#f5f5f5"
                  }
                })}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12" style={{ textAlign: "right" }}>
              <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={() => {
                  let values = [];
                  if (this.state.selected && this.state.selected.length !== 0) {
                    values = this.state.selected.map(val => {
                      return val.value;
                    });
                  }
                  this._hideMapModal();
                  this.props.setMapping(values, dataFieldId, row, addongrpid);
                }}
              >
                Update Mapping
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  _getItemsMap = itemmap => {
    const alloptions = this.props.beforemulselectopen();
    let finalArray = [];
    if (alloptions.length > 0) {
      let retArray = [];
      if (itemmap) {
        let keys = Object.keys(itemmap);
        let values = Object.values(itemmap);
        let valuestring = "";

        for (var i = 0; i < keys.length; i++) {
          if (values[i].indexOf(",") > 0) {
            valuestring = values[i].split(",");
          } else {
            valuestring = values[i];
          }
          for (var j = 0; j < valuestring.length; j++) {
            retArray.push(`${keys[i]}:${valuestring[j]}`);
          }
        }
      }

      for (var i = 0; i < retArray.length; i++) {
        alloptions.map(option => {
          if (option.value === retArray[i]) {
            finalArray.push(option.label);
          }
        });
      }
    }
    return finalArray;
  };
  _getItemsMapForSelect = itemmap => {
    let retArray = [];
    if (itemmap) {
      let keys = Object.keys(itemmap);
      let values = Object.values(itemmap);
      let valuestring = "";

      for (var i = 0; i < keys.length; i++) {
        if (values[i].indexOf(",") > 0) {
          valuestring = values[i].split(",");
        } else {
          valuestring = values[i];
        }
        for (var j = 0; j < valuestring.length; j++) {
          retArray.push(`${keys[i]}:${valuestring[j]}`);
        }
      }
    }
    return retArray;
  };
  render() {
    return (
      <React.Fragment>
        <div className="dynamicTableAddon">
          <table style={{ height: this.props.height }}>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
          {this.state.showMapModal && this._renderMapModal()}
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicGroupAddOn;
