import React, { Component } from "react";
import DatePicker from "react-date-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { renderConfigInMultiSelect } from "../helpers/Util";
import MultiSelect from "./MultiSelect";

const tieroptions = {
  T1: "Public Price",
  T2: "Special Menu",
  T3: "Member Price"
};

class DynamicDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDatePicker = (date, name, field, row) => {
    if (moment(date, "DD-MM-YYYY", true).isValid()) {
      this.props.handleDatePicker(date, field, row);
      this.setState({ [name]: date });
    }
  };

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  
  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;
    return (
      <tbody>
        {this.props.rows.map(row => {
          let renderIndex = row.id;
          const rowId = `row_${renderIndex}`;
          return (
            <tr key={`tableview-tr-inner-${renderIndex}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  renderIndex,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  setTier = (value, index) => {
    value = value.filter(e => e !== '')
    // console.log('set value for rowId', index, value, value.length)
    this.props.handleInputChange('segments', value, index);
    this.setState({['tiers_row_'+index]: value})
    // this.setState({
    //   tiers: value
    // });
  }

  setBasePrice = (value, index) => {
    //console.log('set baseprice value for rowId', index, value)
    this.props.handleInputChange('baseprice', value, index);
    this.setState({['baseprice_row_'+index]: value})
  }
  
  renderRowData = (column, row, col, index, rowId, rowIndex, rowleng) => {
    //console.log('renderAddRow for::', index, rowId)
    if (column.addRow) {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          {column.dataFieldId === "validfrom" ? (
            <DatePicker
              locale="en-GB"
              className="datepicker"
              name={"validfrom_" + rowId}
              onChange={e =>
                this.handleDatePicker(
                  e,
                  "validfrom_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={this.state["validfrom_" + rowId] || this.props.rows.filter(r=>r.id===row.id)[0].validfrom}
            />
          ) : column.dataFieldId === "validtill" ? (
            <DatePicker
              locale="en-GB"
              className="datepicker"
              name={"validtill_" + rowId}
              onChange={e =>
                this.handleDatePicker(
                  e,
                  "validtill_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={this.state["validtill_" + rowId] || this.props.rows.filter(r=>r.id===row.id)[0].validtill}
              minDate={this.state["validfrom_" + rowId] ||  this.props.rows.filter(r=>r.id===row.id)[0].validfrom}
            />
          ) : column.dataFieldId === 'segments' ? (
            <MultiSelect
              filterOptions={renderConfigInMultiSelect(tieroptions)}
              setSelectedFieldValues={this.setTier}
              // id={`tiers_+${rowId}`}
              id={rowIndex}
              label="Select Tiers"
              itemsSelected={this.state['tiers_' + rowId]  || this.props.rows.filter(r=>r.id===row.id)[0].segments.split(',')}
            />
          ) : column.dataFieldId === 'baseprice' ? (
            <input
              type="text"
              className="form-control"
              id="baseprice"
              value={this.state["baseprice_" + rowId] || this.props.rows.filter(r=>r.id===row.id)[0].baseprice}
              onChange={event => {
                this.setBasePrice(event.target.value, rowIndex)
              }}
            />
          ) : (<div/>)}
        </td>
      );
    }

    if (col === "AD") {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          <img
            className="addBtn1"
            onClick={this.props.addRow}
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
          />

          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row)}
            className="addBtn2"
          />
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };

  render() {
    return (
      <React.Fragment>
        <div className="dynamicDateTimePicker">
          <table>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicDatePicker;
