import React from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { produce } from "immer";
import { queryMediaList } from "../actions/actions";

class SimpleImageInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      background: "#fff",
      showImageList: false,
      imageSelected: "",
      id: "",
      folderPath: "/media/images"
    };
  }
  folderOnClick = folderpath => {
    this.setState({ folderPath: folderpath }, () => {
      this.callQueryApiForFetch("FETCHLIST", folderpath);
    });
  };

  createFetchUrl = folderPath => {
    const requestBody = {
      body: {
        path: folderPath
      }
    };
    return requestBody;
  };
  callFetch = () => {
    const { folderPath } = this.state;
    this.props.dispatch(queryMediaList(this.createFetchUrl(folderPath)));
  };
  callQueryApiForFetch = (triggerPoint, ...rest) => {
    var newState;
    switch (triggerPoint) {
      case "FETCHLIST":
        const [path] = rest;
        newState = produce(this.state, draft => {
          draft.folderPath = path;
        });
        this.setState({ ...newState }, () => {
          this.callFetch();
        });
    }
  };
  componentWillMount = () => {
    let path = this.state.folderPath;
    this.callQueryApiForFetch("FETCHLIST", "/media/images");
  };
  _openImageList = event => {
    this.setState({ showImageList: true }, () =>
      this.callQueryApiForFetch("FETCHLIST", "/media/images")
    );

    if (event) event.preventDefault();
  };
  _closeImageList = event => {
    this.setState({ folderPath: "/media/images", showImageList: false });

    if (event) event.preventDefault();
  };
  _selectImage = event => {
    if (event) event.preventDefault();
    this._closeImageList();
    this.setState({ imageSelected: this.state.imageSelected }, () => {
      this._closeImageList();
    });
    if (event) event.preventDefault();
    this.props.setImage(this.state.imageSelected);
  };
  _clearImage = event => {
    if (event) event.preventDefault();
    this.setState({ imageSelected: "" }, () => {
      this._closeImageList();
    });
    if (event) event.preventDefault();
    this.props.setImage("");
  }
  // Render images list display
  _renderFolder = (file, index) => {
    return (
      <div
        key={index}
        className="col-xs-6 col-lg-2 noPadding noMargin folderStyle"
      >
        <div
          className="card "
          onClick={() => this.folderOnClick(file.path)}
          style={{ padding: "8px" }}
        >
          <div className="card-body">
            <img
              className="card-img-top folderPreview"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_media_folder.svg`}
              alt="Card image cap"
            />
            <div className="title_devide2" />
            <p className="card-text text-center">{file.medianame}</p>
          </div>
        </div>
      </div>
    );
  };
  _renderBreadCrumb = () => {
    const { folderPath } = this.state;
    let folders = folderPath.slice(1).split("/");

    return (
      <div className="breadcrumb">
        {folders.length != 0 &&
          folders.map((folder, i) => {
            let link =
              folderPath.substring(0, folderPath.lastIndexOf(folder)) + folder;
            return (
              <a onClick={() => this.folderOnClick(link)} key={i}>
                {folder}
              </a>
            );
          })}
      </div>
    );
  };
  _renderImagePreview = (file, index) => {
    return (
      <div
        key={index}
        className="col-lg-2 col-sm-6 noPadding noMargin folderStyle"
      >
        <div
          className={`card ${
            this.state.id === file.id ? `imageSelected` : `imageNotSelected`
          }`}
          style={{ padding: "8px" }}
        >
          <div
            className="card-body"
            onClick={() => {
              let image = file.path;
              this.setState({ imageSelected: image, id: file.id });
            }}
            onDoubleClick={() => {
              let image = file.path;
              this.setState({ imageSelected: image, id: file.id }, () => {
                this._selectImage();
                this._closeImageList();
              });
            }}
          >
            <img
              className="card-img-top imgPreview"
              src={`${localStorage.getItem("imagepath")}${file.path}`}
              onError={e => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
              }}
              alt="Missing Image"
            />
            <div className="title_devide2" />
            <p className="card-text text-center">{file.medianame}</p>
          </div>
        </div>
      </div>
    );
  };
  // Render images list display
  _renderForm = mediaList => {
    return (
      <div className="row">
        {mediaList.length === undefined || mediaList.length === 0 ? (
          <React.Fragment />
        ) : (
          mediaList.map((file, index) =>
            file.mediatype === "FL"
              ? this._renderFolder(file, index)
              : this._renderImagePreview(file, index)
          )
        )}
        </div>
    );
  };
  _renderImageModal = () => {
    const { mediaList } = this.props.queryData;
    return (
      <Modal
        visible={this.state.showImageList}
        width="80%"
        height="80%"
        effect="fadeInRight"
        onClickAway={this._closeImageList}
      >
        <div className="modalForm imageModalForm">
          <div className="row">
            <div className="col-xs-12 col-md-6" />
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeImageList}>X</a>
            </div>
          </div>
          <div className="title-devide" />
          {this._renderBreadCrumb()}
          {this._renderForm(mediaList)}

          <div className="row btn-container1">
            <div className="col-lg-6 col-md-12 col-sm-12 " />
            <div className="col-lg-6 col-md-12 col-sm-12 alignRight">
              <button
                type="submit"
                onClick={this._selectImage}
                className="btn btn-themes btn-rounded"
              >
                Select
              </button>
              <button
                type="submit"
                onClick={this._closeImageList}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={this._clearImage}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div className={`${(this.props.className || "row")}`}>
        <div className="col-lg-12 col-sm-10 noPadding noMargin">
          <input
            type="text"
            className="imagemodal"
            id="image"
            readOnly={true}
            value={this.props.value}
            onClick={this._openImageList}
          />
        </div>
        {this._renderImageModal()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(SimpleImageInput);
