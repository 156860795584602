import React from "react";
import { connect } from "react-redux";
import LinkButton from "../../../components/LinkButton";
import { searchFields } from "./tableCols";
import {
  deleteDeliveryArea,
  crudReset,
  searchGeneral,
  resetAction,
  updateDefaultConfig
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleTable from "../../../components/SimpleTable";
import Modal from "react-awesome-modal";
import StatusOptionComp from "../../../components/StatusOptionComp";
import DatePicker from "react-date-picker";
import TenantCode from "../../../components/TenantCode";
import { toDate } from "../../../helpers/Util";

class DisplayShippingItem extends React.PureComponent {
  statusShip = [
    { name: "Select", id: "" },
    { name: "Shipping", id: "Shipping" },
    { name: "Shipped", id: "Shipped" },
    { name: "Received", id: "Received" },
    { name: "Delivered", id: "Delivered" },
    { name: "Completed", id: "Completed" },
    { name: "Cancel", id: "Cancel" }
  ];
  constructor(props) {
    super(props);
    this.state = {
      pages: 10,
      currPage: 0,
      status: "Active",
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      language: "",
      langlist: {},
      cartItems:[],
      shippinglist:[],
      bookdate: "",
      orderno: "",
      expanded: "",
      selected: "",
      exptaskcode: "",
      selected: "",
      storeid: "",
      orderid: "",
      datefrom: new Date(),
      datetill: new Date(),
      shipstatus: "",
      templatetype: "Shipping",
      memberid: "",
        tableCols1: [
            {
                dataField: "orderid",
                text: "Order Id",
                sort: true
            },
            {
                dataField: "items",
                text: "Product Name",
                formatter: this.itemFormatter,
                sort: true
            },
            {
                dataField: "items",
                text: "Shipping Qty",
                formatter: this.showShippingQty,
                sort: true
            },
            {
              dataField: "items",
              text: "Ordered Qty",
              formatter: this.showQty,
              sort: true
            },
            {
                dataField: "ordershippedon",
                text: "Shipped Date",
                formatter: this.showShipDate,
                sort: true                
            },
            {
                dataField: "orderdeliveredon",
                text: "Delivered Date",
                formatter: this.showDeliverDate,
                sort: true
            },
            {
              dataField: "cancelledon",
              text: "Cancel Date",
              formatter: this.showCancelDate
            },
            {
              dataField: "shippingstatus",
              text: "Shipping Status",
              sort: true
            },
            {
                dataField: "",
                text: "Actions",
                formatter: this.cellFormatter,
                formatExtraData: {
                    action: "ED"
                }
            }
        ]
    };
  }

  componentWillMount = () => {
    const { deliverydata } = this.props;
    if (JSON.stringify(deliverydata) !== "{}"){
      this.callFetchById(deliverydata.orderid);
  }
}

componentDidUpdate = (prevProps) => {
  const { shippingTaskList } = this.props.queryData;
  const { shippingTaskList: prevshippingTaskList } = prevProps.queryData;
  if(shippingTaskList !== prevshippingTaskList) {
    if (shippingTaskList.length > 0) {
      let newshiplist = shippingTaskList.map(e => ({
        ...e,
        items: e.items.map(i => ({
          ...i,
          currency: e.currency,
          ordershippedon: e.ordershippedon,
          orderdeliveredon: e.orderdeliveredon,
          cancelledon: e.cancelledon,
          shippingstatus: e.shippingstatus
        }))
      }))
      this.setState({
        shippinglist: newshiplist
      })
    }
    else{
      this.setState({
        shippinglist: []
      })
    }
  }    
}

  componentWillReceiveProps = (prevProps) => {
    const { languageConfig } = this.props.queryData;
      if (languageConfig.length > 0) {
        const list = languageConfig[0].additionalfields
        this.setState({
          langlist: list
        })
      }
  }


  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const permissions = localStorage.getItem("permission");
    let rowlist = Object.assign({},row,{items: row.items.map(e=>
      ({  ...e,
         currency:row.currency,
         shippingstatus: row.shippingstatus
      }))});
    if (row.status !== "Deleted" && permissions.split(",").includes("W")) {
      let selectedrow = {item: rowlist, isEdit: true}
      return (
        <span>
             <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
              onClick={()=>this.props.showPage(selectedrow)}
            />
        </span>
      );
    }
  };

  itemFormatter = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
            <div className="row-box">
              <div className="flex-row">
                <div className="col-wrapper">
                  {e.productcode + "-" + e.description}
                  <div style={{ color: "#6d6d6d", fontSize: "12px" }}>{"[" + e.itemsize + "/" + e.itemcolor + "]"}</div>
                </div>
              </div>
            </div>
          </div>
         )
      }
      else{
        return ""
      }
    }
  }


  showShipDate = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>             
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                {e.shippingstatus == "Shipped" || e.shippingstatus == "Delivered" || e.shippingstatus== "Completed"?
                 e.ordershippedon : ""
                }
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showCancelDate = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>             
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingstatus=="Cancel" ? e.cancelledon : ""}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showDeliverDate = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>             
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingstatus==="Delivered" || e.shippingstatus==="Completed" ? e.orderdeliveredon : ""}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showShippingQty = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
          return row.items.map(e=>
            <div className="row-box">
              <div className="flex-row">
                <div className="col-wrapper">
                  {e.shippingqty}
                </div>
              </div>
            </div>
          )
      }
      else{
        return
      }
    }    
  }
  

  showQty = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
          return row.items.map(e=>
            <div className="row-box">
             <div className="flex-row">
              <div className="col-wrapper">
                {e.quantity}
              </div>
            </div>
          </div>
          )
      }
      else{
        return
      }
    }    
  }


  createFetchUrl = (orderid) => {
    const requestBody = {
      body: {
        orderid: orderid
      }
    }
    return requestBody;
  }

  // API Calls
  callFetchById = (oid) => {
    this.props.dispatch(
      searchGeneral(
        `ops/v1/task/shipping/search/fields`,
        this.createFetchUrl(oid),
        "SEARCH_SHIPPINGTASK_SUCCESS",
        "SEARCH_SHIPPINGTASK_FAILURE"
      )
    );
  }
 
  callFetch = fetchType => { 
    if(fetchType === "SEARCH"){
      this.props.dispatch(
        searchGeneral(
          "ops/v1/task/shipping/search/fields",
          this.createFetchByFields(fetchType),
          "SEARCH_SHIPPINGTASK_SUCCESS",
          "SEARCH_SHIPPINGTASK_FAILURE"
        )
      );
    }
  };


  createFetchByFields  = () =>  {
    const { storeid, memberid, orderid, datefrom, datetill, shipstatus, templatetype } = this.state;
      const requestBody = {
        body: {
          datefrom: toDate(datefrom),
          datetill: toDate(datetill),
          storeid,
          orderid,
          memberid,
          status: shipstatus,
          templatetype
        }
      };
      return requestBody;   
  }

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetchById("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
    }
  };


  searchQuery = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("ONLOAD");
      }
    );
  };

  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };

  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };

  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="70%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Shippment Search</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {this._renderSearchForm()}
        </div>
      </Modal>
    );
  };

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  hanldedatefrom = value => {
    this.setState({
      datefrom: value
    });
  };

  hanldedatetill = value => {
    this.setState({
      datetill: value
    });
  };

  _renderSearchForm = () => {
    const { deliverytypeList } = this.props.queryData;
    let dlist = Object.assign({}, ...deliverytypeList.map(e=>(e.additionalfields)));
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="templatetype" className="floatLeft">
              Template Type
            </label>
            <input
              type="text"
              className="form-control"
              id="templatetype"
              value={this.state.templatetype}
              readOnly={true}
            />
          </div>              
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="datefrom" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.hanldedatefrom}
              value={this.state.datefrom}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="datetill" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.hanldedatetill}
              value={this.state.datetill}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="orderid" className="floatLeft">
              Order Id
            </label>
            <input
              type="text"
              className="form-control"
              id="orderid"
              value={this.state.orderid}
              onChange={event => this.setState({ orderid: event.target.value })}
            />
          </div>     
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="memberid" className="floatLeft">
              Member Id
            </label>
            <input
              type="text"
              className="form-control"
              id="memberid"
              value={this.state.memberid}
              onChange={event => this.setState({ memberid: event.target.value })}
            />
          </div>  
          </div>  
          <div className="row">      
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusOptionComp
              statusoption={this.statusShip}
              selectedOption={this.state.shipstatus}
              callbackChangeFilter={value => {
                this.setState({ shipstatus: value });
              }}
            />
          </div>
        </div>

        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                  this.callQueryApiForFetch("SEARCH");
                  this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }



  _renderAddNewBtn = () => {
    const permissions = localStorage.getItem("permission");
    let selectedrow = {item: "", isEdit: false}
    return (
        <button
          className="page-header-btn icon_btn display-inline "
          onClick={
            ()=>{
              this.props.showPage(selectedrow);
            }
          }
          disabled={!permissions.split(",").includes("W")}
        >
          <img
            title="edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
          />
          {`Add New`}
        </button>
    );
  };

  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  deleteStoreInfo = rowData => {
    this.setState({ message: "Order Fulfillment deleted successfully!" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };


  
  render() {
    const {
      shippingTaskList,
      returnTaskList,
      errormsg: queryError,
      servererror: serverError
    } = this.props.queryData;
    const { errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }
    return (
      <React.Fragment>
        <div>
        {this.state.showSearchDiv && this._renderSearchModal()}
          <div className="form_height" style={{minHeight: "180px"}}>     
            <div className="row sub-title"> Shipping Information </div>
            <div className="row form-container">
            <div className="col noPadding noMargin">
              <form className="splitFrmDiv1Sec">    
                <div className="row">
                  <div className="col-lg-10 col-sm-12 noPadding noMargin">
                  </div>
                  {/* <div className="col-lg-2  col-md-6 col-sm-12 alignRight noPadding noMargin">
                     {this._renderSearchBtn()}
                  </div> */}
                  <div className="col-lg-2 col-md-12 col-sm-12 alignRight noPadding noMargin">
                    {this._renderAddNewBtn()}
                  </div>
                </div>           
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>

                <React.Fragment>
                  <SimpleTable
                    columns={this.state.tableCols1}
                    rows={this.state.shippinglist}
                    callback={() => {
                      this.callQueryApiForFetch("ONLOAD");
                    }}
                  />
                </React.Fragment>
              </form>
            </div>
          </div>    
          </div>     
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayShippingItem);
