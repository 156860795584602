import React from 'react';
import CheckboxTree from 'react-checkbox-tree-enhanced';

class UserRoleAccessTree extends React.PureComponent {

    constructor(props) {
        super(props)

        this.onCheck = this.onCheck.bind(this)
        this.onExpand = this.onExpand.bind(this)

        this.state = {
            selectedRoles: props.selectedRoles,
            expanded: []
        }
    }

//    static getDerivedStateFromProps = () => {

//     }

    componentDidUpdate = (prevProps) => {
        console.log('prevProps', prevProps.selectedRoles)
        console.log('props::', this.props.selectedRoles)
        //this.setState({selectedRoles: this.props.selectedRoles})
    }

    onCheck = (selectedRoles) => {
        console.log('newly selected::', selectedRoles)
        this.setState({ selectedRoles: selectedRoles })
        this.props.saveSelected(selectedRoles)
    }

    onExpand = (expanded) => {
        this.setState({ expanded: expanded })
    }

    render() {

        //Don't delete this log. If deleted, at very first load of the component, the selectedroles are not updating in edit mode
        //console.log('selectedRoles at UserRolesAccessTree', this.state.selectedRoles)
        //const { selectedRoles } = this.props.selectedRoles
        return (            
            <div className="customOl">
                {/* {this.state.selectedRoles} */}
                <CheckboxTree
                    nodes={this.props.nodes}
                    checked={this.state.selectedRoles}
                    // checked = {selectedRoles}
                    expanded={this.state.expanded}
                    onCheck={this.onCheck}
                    onExpand={this.onExpand}
                    // onCheck={(selectedRoles) => {
                    //     this.setState({selectedRoles: selectedRoles})
                    //     this.props.saveSelected(selectedRoles)
                    // }}
                    // onExpand={(expanded) => {this.setState({ expanded: expanded })}}
                    icons={{
                        check: <span className="rct-icon rct-icon-check" />
                    }}
                    nativeCheckboxes={true}
                />
            </div>
        )
    }
}


export default UserRoleAccessTree;