import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

const SimpleTable = (props) => {
  const { keyField } = props

  const _drawNoRecord = () => {
    if (typeof props.rows === 'undefined' || (props.rows.length === 0 && currPage === 1)) {
      return <div className="tableNoData">
        Your search does not retrieve any data. Please search again
        using another criteria.
      </div>
    }
    else {
      return <></>
    }
  }

  const pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    const currPage = props.withCurrPage || 1
    return (
      <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <ul className="pagination react-bootstrap-table-page-btns-ul">
          {
            currPage !== 1 &&
            <li className="page-item">
              <a href="#" className="page-link" onClick={()=>props.pageChangeCallback(currPage - 1)}>&lt;</a>
            </li>
          }
          {
            (currPage !== 1 || props.rows.length > 0) &&
            <li className="active page-item">
              <a href="#" className="page-link">{ props.withCurrPage }</a>
            </li>
          }
          {
            (props.rows.length >= props.withPageSize) &&
            <li className="page-item">
              <a href="#" className="page-link" onClick={()=>props.pageChangeCallback(currPage + 1)}>&gt;</a>
            </li>
          }
        </ul>
      </div>
    );
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {props.rows.length !== 0 &&
        options.map(option => (
          <button
            key={`pg-key-${option.text}`}
            type="submit"
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
            className={`btn ${
              currSizePerPage === `${option.page}`
                ? "btn-secondary"
                : "btn-warning"
            }`}
          >
            {option.text}
          </button>
        ))}
    </div>
  );

  const options = {
    sizePerPageRenderer,
    onSizePerPageChange: (sizePerPage, page) => {
      props.callback()
    }
  };

  const optionsWithFeedback = {
    sizePerPageRenderer,
    onSizePerPageChange: (sizePerPage, page) => {
      props.callback(sizePerPage)
    },
    withFirstAndLast: true,
    pageListRenderer,
    sizePerPage: props.withPageSize,
    hideSizePerPage: props.hideSizePerPage
  };

  const option = {
    noDataText: (
      <i
        className="fa fa-circle-o-notch fa-spin"
        style={{ fontSize: "24px" }}
      ></i>
    )
  };

  const { columns, rows, defaultSorted, withoutSearchBar } = props;
  const rowStyle = props.rowStyle;
  const currPage = props.withCurrPage || 1
  const paginationUsed = props.withPageFeedback?optionsWithFeedback:options

  return (
    <ToolkitProvider
      keyField= {keyField ? keyField : "id"}
      data={rows}
      columns={columns}
      search
      bootstrap4
    >
      {props => (
        <div>
          {
            (!withoutSearchBar) &&
              <div className="col-12 alignRight noPadding noMargin">
                <SearchBar
                  style={{
                    border: "1px solid #007bff",
                    height: "2.9em",
                    borderRadius: "4px",
                    color: "#007bff",
                    marginLeft: "2px",
                    marginBottom: "4px"
                  }}
                  {...props.searchProps}
                />
              </div>
          }
          <div className="table-wrapper">
            <BootstrapTable
              {...props.baseProps}
              rowStyle={rowStyle}
              pagination={paginationFactory(paginationUsed)}
              options={option}
              defaultSorted={typeof defaultSorted == "undefined" ? [] : defaultSorted}
            />
            {_drawNoRecord()}
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
}

export default SimpleTable;
