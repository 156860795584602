import React from 'react'
import { connect } from 'react-redux';
import { dataSyncLogsById } from '../../../actions/actions';

class ViewDataSyncLog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      topic: '',
      status: '',
      error: '',
      action: '',
      recid: '',
      recpk: '',
      msgcode: '',
      headertext: '',
      msgtext: '',
      logtext: '',
      createdon: ''
    };
  }

  componentWillMount() {

    console.log('willmount..')
    if (this.props.logid) {
      console.log('willmount..', this.props.logid)
      this.props.dispatch(dataSyncLogsById(this.props.logid))
    }

  }

  componentDidUpdate = (prevProps) => {
    const { datasynclog } = this.props.queryData;
    const { datasynclog: prevdatasynclog } = prevProps.queryData;
    if(datasynclog !== prevdatasynclog) {
     console.log('update datasynclog::', datasynclog)

     if (datasynclog) {
      
      console.log('received the datasynclog:::', datasynclog)

    const {
      id,
      topic,
      status,
      error,
      action,
      recid,
      recpk,
      msgcode,
      headertext,
      msgtext,
      logtext,
      createdon,

      
    } = datasynclog

    this.setState({
      id,
      topic,
      status,
      error,
      action,
      recid,
      recpk,
      msgcode,
      headertext,
      msgtext,
      logtext,
      createdon
    })
    }

   }
 }

  render() {
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Data Sync Log</h2>
            </div>
          </div>

          <div className="row form-container">
            <div className="col col-lg-9">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Id
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="id"
                      value={this.state.id}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Topic
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="topic"
                      value={this.state.topic}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Status
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="status"
                      value={this.state.status}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Error
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="error"
                      value={this.state.error}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Action
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="action"
                      value={this.state.action}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Rec Id
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="recid"
                      value={this.state.recid}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Rec Pk
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="recpk"
                      value={this.state.recpk}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Msg Code
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="msgcode"
                      value={this.state.msgcode}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Header Text
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <textarea
                      className="form-control apilogLeftRightPadding"
                      id="headertext"
                      value={this.state.headertext}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Msg Text
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <textarea
                      className="form-control apilogLeftRightPadding"
                      id="msgtext"
                      value={this.state.msgtext}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Log Text
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="logtext"
                      value={this.state.logtext}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Created On
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="createdon"
                      value={this.state.createdon}
                      disabled={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row btn-container form-button">
                      <div className="col-sm-12 col-lg-12">
                          {this._renderMainButton()}
                      </div>
                  </div>
      </React.Fragment>
    );
  }

  _renderMainButton = () => {

      return (
          <React.Fragment>
              <button
                  type="submit"
                  className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                  onClick={() => {
                      this.props.hideLogDetails();
                  }}
              >
                  Cancel
              </button>
          </React.Fragment>
      );
  };
}


const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(ViewDataSyncLog);