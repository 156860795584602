import React from "react";
import { queryPropertyConfigByFields } from "../../../actions/actions";
import { connect } from "react-redux";
import SimpleSelect from "../../../components/SimpleSelect";

class DeliveryMessage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: Object.keys(this.props.delivery).length !== 0
        ? this.props.delivery
        : [{ field: "", value: "", id: 0 }]
    };
  }

  componentWillMount = () => {
      const requestBody = {
        body: {
          propgroup: "LANGUAGE_CONFIG",
          //category: "Config"
        }
      };
      // this.props.dispatch(
      //   queryPropertyConfigByTitle(
      //     requestBody,
      //     "GET_LANGUAGE_CONFIG_BY_TITLE",
      //     "LANGUAGE_CONFIG_BY_TITLE_SUCCESS",
      //     "LANGUAGE_CONFIG_BY_TITLE_FAILURE"
      //   )
      // );
  };
  createUI() {
    const { languageConfig } = this.props.queryData;
    return this.state.values.map((value, index) => {
      let idx = value.id;
      let field = value.field;
      return (
        <div className="row" key={index}>
          <div className="col-md-2 col-sm-12 noPadding">
            <SimpleSelect
             objArray={languageConfig.length > 0 ? languageConfig[0].additionalfields : []}
              callbackChangeFilter={value => {
                this.handleChange("field", value, idx);
              }}
              selectedOption={field}
              id="field"
            />
          </div>
           {/* <div className="col-md-3 col-sm-12">
            <SimpleSelect
              objArray={operatorsConfig.pvalues}
              callbackChangeFilter={value => {
                this.handleChange("condi", value, idx);
              }}
              selectedOption={condi}
              id="condi"
            />
          </div> */}
          <div className="col-md-8 col-sm-12">
            <input
              id="value"
              type="text"
              value={value.value}
              className="dynamicDescInp"
              onChange={event =>
                this.handleChange(event.target.id, event.target.value, idx)
              }
            />
          </div>

          <div className="col-md-2 col-sm-12 alignLeft">
            <img
              className={`dynamicbtn1 dynamicBtn1-`+idx}
              onClick={event => this.removeClick(event, idx)}
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            />
          </div>
        </div>
      );
    });
  }

  handleChange = (fieldId, fieldValue, id) => {
    const newProducts = this.state.values.map(value => {
      if (id !== value.id) return value;

      return { ...value, [fieldId]: fieldValue };
    });
    this.setState({ values: newProducts }, () => {
      this.props.setDeliveryProps(this.state.values);
    });
  };

  addClick = event => {
    let lastEle = this.state.values[this.state.values.length - 1];

    const item = {
      field: "",
      //operator: "",
      value: "",
      id: lastEle.id + 1
    };
    this.setState({
      values: [...this.state.values, item]
    });
    if (event) event.preventDefault();
  };
  removeClick = (evt, idx) => {
    // const updateValues = [...this.state.values];
    // updateValues.splice(idx, 1);
    const updateValues = this.state.values.filter(value => idx !== value.id);

    this.setState({ values: updateValues });
  };
  render() {
    return (
      <React.Fragment>
        {this.createUI()}
        <div className="row">
          <button className="addNewRow" onClick={event => this.addClick(event)}>
            Add New
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DeliveryMessage);
