import React from 'react';
import { Link } from 'react-router-dom';
import { searchFields } from './tableCols';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import {
    deleteArticleInfoApi,
    unpublishArticleInfoApi,
    publisArticleInfoApi,
    copyArticleInfoApi,
    searchGeneral,
    crudReset,
    resetAction,
    updateDefaultConfig,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from '../../../components/Loading';
import SimpleSearcher from '../../../components/SimpleSearcher';
import MultiSelect from './../../../components/MultiSelect';
import {
    canPublish,
    canView,
    canWrite,
    formatLanguageArray,
    permissions,
    sortArray,
} from '../../../helpers/Util';
import PrimeTable from '../../../components/PrimeTable';

class DisplayProductInfo extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_CMS_PRODUCT_ARTICLE';
        this.module = 'CMS';
        this.allPermissions = [];

        this.state = {
            pageSize: 10,
            currPage: 1,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            search_field: 'additionalfield1',
            search_condi: 'like',
            language: '',
            langlist: {},
            search_value: '',
            sortorder: '',
        };
    }

    actionTemplate = row => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/product-info/addproductinfo',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/product-info/addproductinfo',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}
                {this.allPermissions.includes('Delete') && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteStoreInfo(row)}
                    />
                )}
                <img
                    title="Copy"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                    onClick={() => this.copyRecord(row)}
                />
                {canPublish(this.allPermissions) && (
                    <img
                        title={
                            row.status === 'Active'
                                ? 'Unpublish Article'
                                : 'Publish Article'
                        }
                        className="tableImage2"
                        src={
                            row.status === 'Active'
                                ? `${process.env.PUBLIC_URL}/assets/icons/ic_unpublic.svg`
                                : `${process.env.PUBLIC_URL}/assets/icons/ic_public.svg`
                        }
                        onClick={
                            row.status === 'Active'
                                ? () => this.unpublishArticle(row)
                                : () => this.publishArticle(row)
                        }
                    />
                )}
            </span>
        );
    }

    // API Calls
    createFetchUrl = (fetchType) => {
        const {
            language,
            search_field,
            search_condi,
            search_value,
            currPage,
            pageSize,
        } = this.state;
        const requestBody = {
            body: {
                articletype: 'Product Information',
                language: language.toString(),
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
                pageno: currPage,
                pagesize: pageSize,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'cms/v1/article-workflow/search',
                this.createFetchUrl(fetchType),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    callQueryApiForFetch = () => {
        this.callFetch('ONLOAD');
    };

    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
                currPage: 1,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            }
        );
    };
    copyRecord = (row) => {
        this.setState({ message: 'Item Copied Suucessfully' });
        this.props.dispatch(copyArticleInfoApi(row.id));
    };
    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage.toString().split(',') }, () =>
            this.callQueryApiForFetch('ONLOAD')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    componentWillReceiveProps = () => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }
    };

    _renderAddNewBtn = () => {
      const { history } = this.props
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
          <Button
          label="Add New"
          icon="pi pi-plus"
          className="p-button-outlined"
          onClick={() => history.push('/ui/product-info/addproductinfo')}
      />
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                unpublishArticleInfoApi(this.state.delete_rec_id)
            );
            this.props.dispatch(deleteArticleInfoApi(this.state.delete_rec_id));
        }
    };
    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    deleteStoreInfo = (rowData) => {
        this.setState({ message: 'Product Article deleted successfully!' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };
    unpublishArticle = (rowData) => {
        this.setState({ message: 'Product Article unpublished successfully!' });
        this.props.dispatch(unpublishArticleInfoApi(rowData.id));
    };
    publishArticle = (rowData) => {
        this.setState({ message: 'Product Article published successfully!' });
        this.props.dispatch(publisArticleInfoApi(rowData.id));
    };

    render() {
        const {
            articleFlowData,
            languageConfig,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (!articleFlowData && !errormsg) {
            return <Loading />;
        }
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'fields.productcode', header: 'Product Code' },
            { field: 'title', header: 'Article Title' },
            { field: 'status', header: 'Status' },
            { field: 'language', header: 'Language' },
            { field: 'fields.description', header: 'Description', style: { width: '30%', textOverflow: 'ellipsis'} }
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">
                                Product Article
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                        <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 noPadding noMargin">
                                        <h3 className="page-title">
                                            Product Registration
                                        </h3>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 alignRight noPadding noMargin">
                                        <SimpleSearcher
                                            filterOptions={searchFields}
                                            setAndSearch={this.searchQuery}
                                            search_condi={
                                                this.state.search_condi
                                            }
                                            search_value={
                                                this.state.search_value
                                            }
                                            search_field={
                                                this.state.search_field
                                            }
                                        ></SimpleSearcher>
                                    </div>
                                    <div className="col-lg-2 col-sm-12 noPadding noMargin alignRight">
                                        <MultiSelect
                                            filterOptions={formatLanguageArray(
                                                this.state.langlist
                                            )}
                                            setSelectedFieldValues={(value) => {
                                                this.setState(
                                                    { language: value },
                                                    () => {
                                                        this.callQueryApiForFetch(
                                                            'ONLOAD'
                                                        );
                                                    }
                                                );
                                                this.props.dispatch(
                                                    updateDefaultConfig(
                                                        {
                                                            body: {
                                                                language: value,
                                                            },
                                                        },
                                                        () => {
                                                            this.callQueryApiForFetch(
                                                                'ONLOAD'
                                                            );
                                                        }
                                                    )
                                                );
                                            }}
                                            id="language"
                                            itemsSelected={this.state.language}
                                            label="Select Language"
                                        />
                                    </div>
                                    
                                </div>
                                <div className="title_devide" />
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                        tablePageSize={this.state.pageSize}
                                        list={sortArray(articleFlowData)}
                                        isSearched={this.state.isSearched}
                                        columns={columns}
                                        actionColumn={this.actionTemplate}
                                        linkColumn={this.linkTemplate}
                                        pageChangeCallback={(currPage) => {
                                            this.setState(
                                                {
                                                    currPage: currPage,
                                                },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                        pageSizeChangecallback={(pageSize) => {
                                            this.setState(
                                                produce(this.state, (draft) => {
                                                    if (draft.pageSize < pageSize) {
                                                        draft.currPage = 1;
                                                    }
                                                    draft.pageSize = pageSize;
                                                }),
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'ONLOAD'
                                                    );
                                                }
                                            );
                                        }}
                                    />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this product
                                article?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayProductInfo);
