import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import ExportButton from '../../../components/TableExportButton';
import { connect } from 'react-redux';
import {
    searchGeneral,
    deleteProductCatalog,
    queryCategoryTree,
    resetAction,
} from '../../../actions/actions';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../helpers/Util';
import PrimeTable from '../../../components/PrimeTable';
import ProductFilter from './ProductFilter';
import PreviewImage from '../../../components/ImageComponents/PreviewImage';
import EditImage from '../../../components/ImageComponents/EditImage';
import DeleteImage from '../../../components/ImageComponents/DeleteImage';

const ProductList = (props) => {
    const { prodSpecList, categoryCodeArray, articleFlowData, categoryTree } =
        props.queryData;

    const [state, setState] = useState({
        pages: 10,
        currPage: 1,
        ptype: '',
        productcode: '',
        title: '',
        status: 'Active',
        categorycodes: '',
        openDeleteprompt: false,
        delete_rec_id: '',
        message: '',
    });

    const [productTypeOptions, setProductTypeOptions] = useState([]);

    const module = 'SALES';
    const group = 'DE_SAL_PRODUCT_CATALOG';
    const allPermissions = permissions(module, group) || [];

    const tableCols = [
        {
            dataField: 'productcode',
            text: 'Product Code',
            sort: true,
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        // {
        //     dataField: 'brand',
        //     text: 'Brand',
        //     sort: true,
        // },
        {
            dataField: 'ptype',
            text: 'Product Type',
            sort: true,
        },
        {
            dataField: 'categorycodes',
            text: 'Product Category Code',
            sort: true,
        },
        {
            dataField: 'pstopsell',
            text: 'Stop Sell',
            sort: true,
        },
    ];

    useEffect(() => {
        props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
        props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                {
                    body: {
                        search_field: 'pid',
                        search_condi: 'eq',
                        search_value: 181,
                    },
                },
                'FETCH_PROD_SPEC_SUCCESS',
                'FETCH_PROD_SPEC_FAILURE',
            ),
        );
        initLoad();
    }, []);

    useEffect(() => {
        if (prodSpecList.length > 0) {
            let fieldarr = prodSpecList.map((e) => ({
                id: e.categorycode,
                name: e.title,
            }));
            fieldarr.unshift({ id: '', name: 'Select' });
            setProductTypeOptions(fieldarr);
        }
    }, [prodSpecList]);

    const initLoad = () => {
        props.dispatch(
            searchGeneral(
                'sales/v1/product/search/fields',
                createFetchUrl(),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    };

    const pageSizeChangecallback = (pageSize) => {
        setState({ ...state, pages: pageSize });
        const {
            pages,
            currPage,
            categorycodes,
            ptype,
            productcode,
            title,
            status,
        } = state;

        const body = {
            status: status,
            title: title,
            productcode: productcode,
            ptype: ptype,
            categorycode: categorycodes,
            pagesize: pageSize,
            pageno: currPage,
        };

        const requestBody = {
            body,
        };

        props.dispatch(
            searchGeneral(
                'sales/v1/product/search/fields',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    };

    const pageChangeCallback = (pageNo) => {
        setState({ ...state, currPage: pageNo });
        const { pages, categorycodes, ptype, productcode, title, status } =
            state;

        const body = {
            status: status,
            title: title,
            productcode: productcode,
            ptype: ptype,
            categorycode: categorycodes,
            pagesize: pages,
            pageno: pageNo,
        };

        const requestBody = {
            body,
        };

        props.dispatch(
            searchGeneral(
                'sales/v1/product/search/fields',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    };

    const createFetchUrl = () => {
        const {
            pages,
            currPage,
            categorycodes,
            ptype,
            productcode,
            title,
            status,
        } = state;

        const body = {
            status: status,
            title: title,
            productcode: productcode,
            ptype: ptype,
            categorycode: categorycodes,
            pagesize: pages,
            pageno: currPage,
        };

        const requestBody = {
            body,
        };
        return requestBody;
    };

    const handleSearch = (data) => {
        const body = {
            ...data,
        };

        const requestBody = {
            body,
        };
        props.dispatch(
            searchGeneral(
                'sales/v1/product/search/fields',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    };

    const renderAddNewBtn = () => {
        if (!canWrite(allPermissions)) {
            return;
        }
        return (
            <Link
                to={{
                    pathname: '/ui/product-catalog/addNew',
                }}
            >
                <Button
                    label="Add Product"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                />
            </Link>
        );
    };

    const deleteProduct = (rowData) => {
        // this.setState({ message: 'Deleted article successfully' });
        // this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    const actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }
        return (
            <span>
                {canView(allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/product-catalog/addNew',
                            state: { item: row, isEdit: false, isView: true },
                        }}
                    >
                        <PreviewImage />
                    </Link>
                )}
                {canWrite(allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/product-catalog/addNew',
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <EditImage />
                    </Link>
                )}

                {canDelete(allPermissions) && (
                    <DeleteImage onClick={() => deleteProduct(row)} />
                )}
            </span>
        );
    };

    const getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.productcode,
            e.title,
            e.brand,
            e.ptype,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    const columns = [
        { field: 'productcode', header: 'Product Code' },
        { field: 'title', header: 'Title' },
        { field: 'ptype', header: 'Product Type' },
        { field: 'categorycodes', header: 'Category Code' },
    ];
    return (
        <>
            <div className="grid mb-4 px-4 w-full">
                <h2 className="page-header">Product Catalog</h2>
                <div className="" style={{ marginLeft: 'auto'}}>
                    <ExportButton
                        title="Product Catalog"
                        tabledata={getTableData(articleFlowData, tableCols)}
                    />
                </div>
            </div>
            <div className="grid bg-white pv-20 ph-20 br-2 bg-white py-4 border-round-xl shadow-1 mx-4">
                <div className="w-full">
                    <ProductFilter
                        productTypeOptions={productTypeOptions}
                        productcategorytreeList={categoryTree}
                        handleSearch={handleSearch}
                    />
                </div>
                <div className="flex w-full justify-content-end my-4">
                    {renderAddNewBtn()}
                </div>
                <PrimeTable
                    list={articleFlowData}
                    columns={columns}
                    actionColumn={actionTemplate}
                    pageSizeChangecallback={(pageSize) => {
                        pageSizeChangecallback(pageSize);
                    }}
                    pageChangeCallback={(pageNo) => {
                        pageChangeCallback(pageNo);
                    }}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ProductList);
