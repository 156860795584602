import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import {
    deleteCoupon,
    searchGeneral,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from '../../../components/Loading';
import SimpleSearcher from '../../../components/SimpleSearcher';
import PrimeTable from '../../../components/PrimeTable';
import { searchFields } from './tableCols';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../helpers/Util';

class DisplayCoupons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'CAMP';
        this.group = 'DE_CAMP_COUPONS';
        this.allPermissions = [];

        this.state = {
            currPage: 0,
            pageSize: 10,
            pageNo: 1,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
        };
    }

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/coupon-management/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/coupon-management/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteCoupon(row)}
                    />
                )}
            </span>
        );
    };
    // API Calls
    createFetchUrl = () => {
        const { search_field, search_condi, search_value } = this.state;

        const requestBody = {
            body: {
                articletype: 'Coupon Information',
                language: 'en',
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/search',
                this.createFetchUrl(fetchType),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
        }
    };
    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            }
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('ONLOAD')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    _renderAddNewBtn = () => {
        const { history } = this.props;
        if (!this.allPermissions.includes('Write')) {
            return;
        }

        return (
            <Button
                label="Add Coupon"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/coupon-management/addNew')}
            />
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteCoupon(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    _deleteCoupon = (rowData) => {
        this.setState({ message: 'Coupon  deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.couponcode,
            e.title,
            e.description,
            e.validfrom,
            e.validtill,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    pageSizeChangecallback = (pagesize) => {
        this.setState({ pageSize: pagesize });
        const { body } = this.createFetchUrl();
        const requestBody = {
            body: {
                ...body,
                pagesize: pagesize,
                pageno: this.state.pageNo,
                status: 'active',
            },
        };
        this.props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/search',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    pageChangeCallback = (currPage) => {
      this.setState({ pageNo: currPage });
  
      const { body } = this.createFetchUrl();
  
      const requestBody = {
          body: {
              ...body,
              pagesize: this.state.pageSize,
              pageno: currPage,
              status: 'active',
          },
      };
  
      const { dispatch } = this.props;
  
      dispatch(
          searchGeneral(
              'campaign/v1/coupon/search',
              requestBody,
              'SEARCH_ARTICLE_INFO_SUCCESS',
              'SEARCH_ARTICLE_INFO_FAILURE'
          )
      );
  };
  

    render() {
        const {
            articleFlowData,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (!articleFlowData && !errormsg) {
            return <Loading />;
        }
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'couponcode', header: 'Coupon Code' },
            { field: 'title', header: 'Title' },
            { field: 'description', header: 'Description' },
            { field: 'validfrom', header: 'Valid From' },
            { field: 'validtill', header: 'Valid Till' },
            { field: 'status', header: 'Status' },
        ];

        return (
          <div className="form_height">
          <div className="row">
              <div className="col-lg-6 col-sm-12">
                  <h2 className="page-header">
                      Coupon Management
                      <img
                          title="Refresh"
                          onClick={() => {
                              this.callQueryApiForFetch('ONLOAD');
                          }}
                          className="refreshtree"
                          src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                      />
                  </h2>
              </div>
              <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                  {this._renderAddNewBtn()}
              </div>
          </div>
          <div className="row form-container">
              <div className="col">
                  <form className="frmDiv">
                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <div className='col-md-4'>
                        <SimpleSearcher
                                  filterOptions={searchFields}
                                  setAndSearch={this.searchQuery}
                                  coupon_field={true}
                                  search_condi={
                                      this.state.search_condi
                                  }
                                  search_value={
                                      this.state.search_value
                                  }
                                  search_field={
                                      this.state.search_field
                                  }
                              />
                        </div>
                    </div>
                      <div className="title_devide" />
                      <PrimeTable
                          list={articleFlowData}
                          columns={columns}
                          actionColumn={this.actionTemplate}
                          pageSizeChangecallback={
                              this.pageSizeChangecallback
                          }
                          pageChangeCallback={this.pageChangeCallback}
                          tablePageSize={10}
                      />
                  </form>
              </div>
          </div>
          <ConfirmAlert
              show={this.state.openDeleteprompt}
              isPrompt={true}
              children={
                  <div className="deleteAlertDiv">
                      Are you sure you want to delete this coupon
                      article?
                      <div style={{ margin: '2em' }}>
                          <button
                              className="btn btn-themes btn-rounded btn-sec deletBtn"
                              onClick={() => this.shdDeleteRec(false)}
                          >
                              No
                          </button>
                          <button
                              className="btn btn-themes  btn-rounded deletBtn"
                              onClick={() => this.shdDeleteRec(true)}
                          >
                              Yes
                          </button>
                      </div>
                  </div>
              }
          />
          <ConfirmAlert
              show={isSuccess}
              handleClose={this.hideAlertModal}
              children={
                  <div
                      style={
                          isSuccess
                              ? { padding: '2em', color: 'green' }
                              : { padding: '2em', color: 'red' }
                      }
                  >
                      {isSuccess ? this.state.message : ''}
                  </div>
              }
          />
          <ConfirmAlert
              show={errormsg}
              handleClose={(event) =>
                  this.hideErrorAlertModal(event, refresh)
              }
              children={
                  <div
                      style={
                          errormsg
                              ? {
                                    padding: '2em',
                                    color: 'red',
                                    fontWeight: '500',
                                }
                              : { padding: '2em', color: 'red' }
                      }
                  >
                      {errormsg ? `${errormsg}.` : ''}
                  </div>
              }
          />
      </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayCoupons);
