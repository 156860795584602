import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { searchGeneral, storeSearchByFields, resetAction } from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import PrimeTable from '../../components/PrimeTable';
import { statusSelectConfigOptions } from '../../helpers/Util';
import PreviewImage from '../../components/ImageComponents/PreviewImage';
import PrimeStoreSelector from '../../components/PrimeStoreSelector';
import SalesReturnDetail from './SalesReturnDetail';
import RefreshIcon from '../../components/ImageComponents/RefreshIcon';

const SalesReturnList = (props) => {
    const { stores, saleReturnList } = props.queryData;
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [date_from, setDateFrom] = useState(new Date());
    const [filterData, setFilterData] = useState();
    const [returnList, setReturnList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [initialParams, setInitialParams] = useState();

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(storeSearchByFields(`companyid=${companyId}`));
        props.dispatch(resetAction());
    }, []);
    
    useEffect(() => {
        if (saleReturnList.length > 0) {
            setReturnList(saleReturnList);
        }
        else {
            setReturnList([])
        }
    }, [saleReturnList]);

    useEffect(() => {
        const options = stores.map((e) => ({
            label: '(' + e.storeid + ') ' + e.title,
            value: e.storeid,
        }));
        setStoreList(options);
    }, [stores]);

    const callApi = (params) => {
        props.dispatch(
            searchGeneral(
                'ext-ns/v1/sales/return',
                params,
                CONSTANTS.SEARCH_SALE_RETURN_SUCCESS,
                CONSTANTS.SEARCH_SALE_RETURN_FAILURE
            )
        );
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        const params = getParsedValue(size, currentPage);
        callApi(params);
    };

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
        const params = getParsedValue(pageSize, pageNo);
        callApi(params);
    };

    const initLoad = () => {
        callApi(initialParams);
    };

    const getParsedValue = (pageSize, pageNo) => {
        const parsedValues = {
            ...filterData,
            date_from: filterData.date_from
                ? moment(filterData.date_from).format('DD-MM-YYYY')
                : '',
            date_till: filterData.date_till
                ? moment(filterData.date_till).format('DD-MM-YYYY')
                : '',
            msgsend: filterData.msgsend ? filterData.msgsend : '',
            status: filterData.status ? filterData.status.code : '',
        };
        Object.keys(parsedValues).forEach((key) => {
            if (parsedValues[key] === '') {
                delete parsedValues[key];
            }
        });
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: pageNo,
            },
        };
        return requestBody;
    };

    const clearEmptyParam = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
    };

    const handleSearch = (data) => {
        setDateFrom(data.date_from);
        clearEmptyParam(data);
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data,
            status: data.status ? data.status.code : '',
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        clearEmptyParam(parsedValues);
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: currentPage,
            },
        };
        setInitialParams(requestBody);
        callApi(requestBody);
    };

    const handleSelectedRecord = (e, row) => {
        e.preventDefault();
        setSelectedRecord(row);
        setShowDetail(true);
    };

    const actionTemplate = (row) => {
        return (
            <span onClick={(e) => handleSelectedRecord(e, row)}>
                <PreviewImage />
            </span>
        );
    };

    const customerTemplate = (row) => {
        const { customerid, customername } = row;
        return `(${customerid}) ${customername}`
    };

    const requestorTemplate = row => {
        const { salespersonid, additionalfields} = row
        const { salesperson } = additionalfields
        return `(${salespersonid}) ${salesperson}`
    }

    const columns = [
        { field: 'storeid', header: 'Store Id', sortable: true },
        { field: 'terminalid', header: 'Termianl Id', sortable: true },
        { field: 'trxno', header: 'Trx No' },
        { field: 'returnref', header: 'Return No' },
        { field: 'returndate', header: 'Return Date' },
        { field: 'customername', header: 'Customer', template: customerTemplate },
        { field: 'salespersonid', header: 'Requestor', template: requestorTemplate },
        { field: 'status', header: 'Status' },
    ];

    const renderView = () => {
        if (showDetail) {
            return (
                <SalesReturnDetail
                    record={selectedRecord}
                    handleClose={() => setShowDetail(false)}
                />
            );
        }

        if (!showDetail) {
            return (
                <div className="form_height px-4">
                    <div className="grid mb-4">
                        <div className="col-9">
                            <h2 className="page-header">
                                Sales Return
                                <RefreshIcon handleClick={initLoad} />
                            </h2>
                        </div>
                    </div>
                    <div className="frmDiv">
                        <FilterForm
                            handleSearch={handleSearch}
                            data={filterData}
                            storeList={storeList}
                        />
                        <PrimeTable
                            tableRows={pageSize}
                            list={returnList}
                            columns={columns}
                            actionColumn={actionTemplate}
                            pageSizeChangecallback={handlePageSizeChange}
                            pageChangeCallback={handlePageChange}
                        />
                    </div>
                </div>
            );
        }
    };

    return <>{renderView()}</>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

const FilterForm = (props) => {
    const { handleSearch, data } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));
    const [selectedStore, setSelectedStore] = useState({});
    const formik = useFormik({
        initialValues: {
            salespersonid: '',
            returnref: '',
            terminalid: '',
            customerid: '',
            trxno: '',
            date_from: data?.date_from ?? new Date(),
            date_till: data?.date_till ?? new Date(),
            date_type: 'lastupdated',
            status: statusSelectConfigOptions.find(
                (status) => status.code == 'active'
            ),
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid: storeid,
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(
                    ([_, value]) =>
                        value !== undefined && value !== '' && value !== null
                )
            );
            handleSearch(filteredData);
        },
    });

    const handleStoreSelection = (selectedStore) => {
        setStoreid(selectedStore.value);
        setSelectedStore(selectedStore);
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="p-fluid formgrid grid w-100 mb-4">
                    <div className="field col-3">
                        <label htmlFor="storeid">Store Id</label>
                        <PrimeStoreSelector
                            storeList={props.storeList}
                            handleStoreSelection={handleStoreSelection}
                            selectedStore={selectedStore}
                            required={false}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="terminalid">Terminal Id</label>
                        <InputText
                            id="terminalid"
                            type="text"
                            name="terminalid"
                            value={formik.values.terminalid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            maxDate={formik.values.date_till}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="date_till">Date To</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="returnref">Trx No</label>
                        <InputText
                            id="trxno"
                            type="text"
                            name="trxno"
                            value={formik.values.trxno}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="returnref">Return No</label>
                        <InputText
                            id="returnref"
                            type="text"
                            name="returnref"
                            value={formik.values.returnref}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="customerid">Customer Code</label>
                        <InputText
                            id="customerid"
                            type="text"
                            name="customerid"
                            value={formik.values.customerid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3">
                        <label htmlFor="userid">Requestor Id</label>
                        <InputText
                            id="salespersonid"
                            type="text"
                            name="salespersonid"
                            value={formik.values.salespersonid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusSelectConfigOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3"
                        style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default connect(mapStateToProps)(SalesReturnList);
