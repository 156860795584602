import React from "react";
import { Chart } from "chart.js";
class DoughnutChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets[0].data = this.props.data;
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "doughnut",
      options: {
        maintainAspectRatio: false,
        percentageInnerCutout: 10,
        cutoutPercentage: 80,
        legend: { display: true, position: "bottom" }
      },
      data: {
        labels: this.props.labels,
        datasets: [
          {
            data: this.props.data,
            backgroundColor: this.props.colors
          }
        ]
      }
    });
  }

  render() {
    return (
      <div className="chartCanvas">
        <canvas ref={this.canvasRef} id={this.props.id}/>
      </div>
    );
  }
}
export default DoughnutChart;
