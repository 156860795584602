import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    crudReset,
    userGroupSearch,
    storeSearchByFields,
    companySearchByFields,
    userAccessSearch,
    createTaxConfiguration,
    updateTaxConfiguration,
} from '../../../../actions/actions';
import StatusComp from '../../../../components/StatusComp';
import ConfirmAlert from '../../../../components/ConfirmAlert';
import 'rc-time-picker/assets/index.css';

class AddTC extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.countryId = localStorage.getItem('country_id');
        this.userGroups = [];

        this.state = {
            isEdit: false,
            isView: false,

            id: 0,
            title: '',
            categorycode: '',
            description: '',
            pid: 0,
            catgroup: 'TAX',
            catvalue: '',
            sortorder: 0,
            status: '',
            lastupdated: '',
            lastupdatedby: '',

            // form validation
            titleError: '',
            formError: false,
            formErrorMsg: '',

            showSearchDiv: false,
            enteredStoreId: '',
            selectedCountry: '',
            selectedCity: '',
            selectedStoreGroup: '',
            selectedStores: [],

            prevpassword: '',
            prevusercodepassword: '',
        };
    }

    // api calls to be called on componentDidMount, called once
    componentDidMount = () => {
        this.props.queryData.userRoles = []; //reset
        this.props.dispatch(
            userGroupSearch(`companyid=${this.companyId}&status=Active`),
        );
        this.props.dispatch(
            companySearchByFields(`companyid=${this.companyId}&status=Active`),
        );
        this.props.dispatch(storeSearchByFields(`countryid=${this.countryId}`));
        this.props.dispatch(userAccessSearch('status=Active'));
    };

    componentWillMount = () => {
        //clear if there is any existing errors
        this.setState({ formError: false, formErrorMsg: '' });
        this.props.crudData.errormsg = '';
        this.props.crudData.isSuccess = false;
        this.props.queryData.userRoles = [];

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if ((isEdit || isView) && rec) {
                const {
                    id,
                    categorycode,
                    title,
                    description,
                    pid,
                    catgroup,
                    catvalue,
                    sortorder,
                    status,
                    lastupdated,
                    lastupdatedby,
                } = rec;

                this.setState({
                    id: id,
                    categorycode: categorycode,
                    title: title,
                    description: description,
                    pid: pid,
                    catgroup: catgroup,
                    catvalue: catvalue,
                    sortorder: sortorder,
                    status: status,
                    lastupdated: lastupdated,
                    lastupdatedby: lastupdatedby,
                });
            }
        }
    };

    _isEditMode = () => {
        return (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.isEdit
        );
    };

    _isViewMode = () => {
        return (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.isView
        );
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;

        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderTaxConfigurationDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Tax Configuration Details</div>

                <div className="row">
                    {(this._isViewMode() || this._isEditMode()) && (
                        <>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label htmlFor="id" className="floatLeft">
                                    Id
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="id"
                                    value={this.state.id}
                                    onChange={(e) =>
                                        this.setState({ id: e.target.value })
                                    }
                                    disabled={
                                        this._isEditMode() || this._isViewMode()
                                    }
                                ></input>
                            </div>
                        </>
                    )}
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(e) =>
                                this.setState({ title: e.target.value })
                            }
                            disabled={this._isViewMode()}
                        ></input>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="categorycode" className="floatLeft">
                            Category Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="categorycode"
                            value={this.state.categorycode}
                            onChange={(e) =>
                                this.setState({ categorycode: e.target.value })
                            }
                            disabled={this._isViewMode()}
                        ></input>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="description" className="floatLeft">
                            Tax Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            value={this.state.description}
                            onChange={(e) =>
                                this.setState({ description: e.target.value })
                            }
                            disabled={this._isViewMode()}
                        ></input>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="catvalue" className="floatLeft">
                            Catvalue
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="catvalue"
                            value={this.state.catvalue}
                            onChange={(e) =>
                                this.setState({ catvalue: e.target.value })
                            }
                            disabled={this._isViewMode()}
                        ></input>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Sort Order
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            onChange={(e) =>
                                this.setState({ sortorder: e.target.value })
                            }
                            disabled={this._isViewMode()}
                        ></input>
                    </div>

                    {(this._isViewMode() || this._isEditMode()) && (
                        <>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="lastupdated"
                                    className="floatLeft"
                                >
                                    Last Updated
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastupdated"
                                    value={this.state.lastupdated}
                                    onChange={(e) =>
                                        this.setState({
                                            lastupdated: e.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() || this._isEditMode()
                                    }
                                ></input>
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="lastupdatedby"
                                    className="floatLeft"
                                >
                                    Last Updated By
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastupdatedby"
                                    value={this.state.lastupdatedby}
                                    onChange={(e) =>
                                        this.setState({
                                            lastupdatedby: e.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() || this._isEditMode()
                                    }
                                ></input>
                            </div>
                        </>
                    )}
                </div>
            </React.Fragment>
        );
    };

    _posUserCodeOnChange = (e) => {
        const usercode = e.target.validity.valid
            ? e.target.value
            : this.state.usercode;
        this.setState({ usercode });
    };

    _posUserCodePasswordOnChange = (e) => {
        const usercodepassword = e.target.validity.valid
            ? e.target.value
            : this.state.usercodepassword;
        this.setState({ usercodepassword });
    };

    // Drop down box for Status
    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="status"
                                value={this.state.status}
                                disabled={true}
                            />
                        ) : (
                            <StatusComp
                                selectedOption={this.state.status}
                                callbackChangeFilter={(v) => {
                                    this.setState({ status: v });
                                }}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _openSearchDiv = () => {
        this.setState({ showSearchDiv: true });
    };
    _closeSearchDiv = () => {
        this.setState({ showSearchDiv: false });
    };

    getCountryList = (storeGroups) => {
        let countries = [];
        storeGroups.forEach((e) => {
            if (e.country !== '') {
                countries.push(e.country);
            }
        });
        return countries;
    };

    getCitiesMap = (storeGroups) => {
        let cities = {};
        // storeGroups.forEach((e) => {
        //   if (e.country === "") { continue }

        // })
        return cities;
    };

    groupByAccessTypes = (userAccess) => {
        return userAccess.reduce((cache, access) => {
            const accesstype = access.accesstype;
            if (accesstype in cache) {
                return {
                    ...cache,
                    [accesstype]: cache[accesstype].concat(access),
                };
            }
            return { ...cache, [accesstype]: [access] };
        }, {});
    };

    groupByModules = (userAccess) => {
        return userAccess.reduce((cache, access) => {
            const module = access.module;
            if (module in cache) {
                return { ...cache, [module]: cache[module].concat(access) };
            }
            return { ...cache, [module]: [access] };
        }, {});
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center">
                            {this._renderTaxConfigurationDetails()}
                        </form>
                    </div>

                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec">
                            {this._renderStatusDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/tax-configuration"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createTaxConfiguration}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createTaxConfiguration}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/tax-configuration"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _clearErrorMessages = () => {
        this.setState({
            titleError: '',

            formError: false,
            formErrorMsg: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { title } = this.state;

        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleError: 'Please enter a title' });
            valid = false;
        }

        return valid;
    };

    getCreatePayload = () => {
        const {
            id,
            categorycode,
            title,
            description,
            pid,
            catgroup,
            catvalue,
            sortorder,
            status,
            lastupdated,
            lastupdatedby,
        } = this.state;

        let body = {
            id: id ? id : null,
            categorycode,
            title: title,
            description,
            pid: pid === 0 ? this.props.location.state.pid : pid,
            catgroup,
            catvalue,
            sortorder,
            status,
            lastupdated,
            lastupdatedby,
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };
        return payload;
    };

    _createTaxConfiguration = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateTaxConfiguration(
                        this.getCreatePayload(),
                        this.state.id,
                    ),
                );
            } else {
                this.props.dispatch(
                    createTaxConfiguration(this.getCreatePayload()),
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Please clear all the errors to continue !!!',
            });
            window.scrollTo(0, 0);
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Tax Configuration'
                                    : this._isViewMode()
                                      ? 'View Tax Configuration'
                                      : 'Add Tax Configuration'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/tax-configuration"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Tax Configuration updated successfully'
                                    : 'Tax Configuration created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddTC);
