import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    generateThumbnailApi,
    createArticleInfoApi,
    updateArticleInfoApi,
    getStoreById,
    productArticleReset,
} from '../../../actions/actions';

import ConfirmAlert from '../../../components/ConfirmAlert';
import ImagePick from '../../../components/ImagePick';
import StatusComp from '../../../components/StatusComp';
import { crudReset } from '../../../actions/actions';
import { produce } from 'immer';
import Autocomplete from './../../../components/Autocomplete';
import DynamicTableImage from '../../../components/DynamicTableImage';
import DynamicTable from '../../../components/DynamicTable';
import Modal from 'react-awesome-modal';
import { convertStrNum } from '../../../helpers/Util';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';
import LanguageSelector from '../../../components/SelectorComponents/LanguageSelector';

class AddProductInfo extends React.PureComponent {
    defaultimageField = [
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 0,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 1,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 2,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 3,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 4,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 5,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 6,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 7,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 8,
        },
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 9,
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            showProdModal: false,
            // Formrelated
            articlecode: '',
            storeid: '',
            articletitle: '',
            productcode: '',
            language: '',
            langlist: {},
            title: '',
            title_long: '',
            description: '',
            description_long: '',
            prodtitle: '',
            productinfo: [{ title: '', desc: '', id: 0 }],
            images: {
                storeicon: '',
                productimageone: '',
                productimagetwo: '',
                productimagethree: '',
            },
            imagesgall: this.defaultimageField,
            nxtImgCounter: this.defaultimageField.length + 1,
            sortorder: 1,
            // language: "",
            storelabel: '',
            prodspecs: '',
            // Errors
            productcodeerror: '',
            articletitleerror: '',
            description_long_error: '',
            titleerror: '',
            title_longerror: '',
            descriptionerror: '',
            languageerror: '',
            status: 'New',
        };
    }

    getCreatePayload = () => {
        const {
            storeid,
            title_long,
            description,
            description_long,
            articlecode,
            productcode,
            title,
            language,
            sortorder,
            images,
            imagesgall,
            productinfo,
            status,
        } = this.state;
        const productdetails = this.arrayToObject(productinfo);
        let imagefield = {};
        let imagefieldCounter = 1;
        imagesgall.forEach((obj) => {
            if (obj.uri !== '') {
                const newObj = Object.assign({}, obj);
                if (
                    typeof newObj.sortorder === 'undefined' ||
                    newObj.sortorder === ''
                ) {
                    newObj['sortorder'] = 0;
                } else {
                    try {
                        newObj['sortorder'] = Number(newObj['sortorder']);
                    } catch (e) {
                        newObj['sortorder'] = 0;
                    }
                }
                imagefield[`image${imagefieldCounter++}`] = newObj;
            }
        });

        let body = {
            title: title,
            language: language,
            storeid: storeid,
            articlecode,
            publishmode: 'manual',
            articletype: 'Product Information',
            status /*: 'Active'*/,
            sortorder: sortorder,
            productcode,
            fields: {
                productcode,
                title,
                title_long,
                description_long,
                description,
                productinfo: productdetails,
            },
            images: {
                storeicon: images.image1 || '',
                productimageone: images.image2 || '',
                productimagetwo: images.image3 || '',
                productimagethree: images.image4 || '',
            },
            imagegallery: imagefield,
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    arrayToObject = (array) => {
        var info = [];
        var len = array.length;
        for (var i = 0; i < len; i++) {
            var item = array[i];

            let newitem = {
                title: item.title,
                description: item.description,
            };

            info.push(newitem);
        }
        return info;
    };

    _createProductInformation = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateArticleInfoApi(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(
                    createArticleInfoApi(this.getCreatePayload())
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            articletitleerror: '',
            productcodeerror: '',
            description_long_error: '',
            titleerror: '',
            title_longerror: '',
            descriptionerror: '',
            languageerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { productcode, title, description, language } = this.state;
        let valid = true;

        if (productcode.trim() === '') {
            this.setState({ productcodeerror: 'Product Code is required.' });
            valid = false;
        }
        if (title.trim() === '') {
            this.setState({ articletitleerror: 'Article Title is required.' });
            valid = false;
        }
        if (description.trim() === '') {
            this.setState({ descriptionerror: 'Description is required.' });
            valid = false;
        }

        if (language.trim() === '') {
            this.setState({ languageerror: 'Language is required.' });
            valid = false;
        }

        return valid;
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.productinfo.length;
        let lastele = this.state.productinfo[rowLength - 1];
        const item = {
            title: '',
            desc: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                productinfo: [...this.state.productinfo, item],
            },
            () => {
                console.log('Rows After Add', this.state.productinfo);
            }
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.productinfo.length === 0) {
                    draft.productinfo.push(obj);
                } else {
                    let found = false;
                    draft.productinfo.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.productinfo.push(obj);
                    }
                }
            }),
            () => {}
        );
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.productinfo.length;

        if (rowLength > 1) {
            const updateValues = this.state.productinfo.filter(
                (value) => rowId !== value.id
            );

            this.setState({ productinfo: updateValues });
        }
    };

    handleImageAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.imagesgall.length;
        let lastele = this.state.imagesgall[rowLength - 1];
        const item = {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: parseInt(lastele.id + 1),
        };
        this.setState(
            {
                imagesgall: [...this.state.imagesgall, item],
            },
            () => {
                console.log('Rows After Add', this.state.imagesgall);
            }
        );
    };

    handleImageInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'uri'
                      ? event
                      : dataField === 'width'
                        ? event
                        : dataField === 'height'
                          ? event
                          : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.imagesgall.length === 0) {
                    draft.imagesgall.push(obj);
                } else {
                    let found = false;
                    draft.imagesgall.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.imagesgall.push(obj);
                    }
                }
            }),
            () => {}
        );
    };
    _deleteImageRow = (row) => {
        let rowId = row.id;
        const updateValues = this.state.imagesgall.filter(
            (value) => rowId !== value.id
        );
        updateValues.push({
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: this.state.nxtImgCounter,
        });

        this.setState(
            produce((draft) => {
                draft.nxtImgCounter = this.state.nxtImgCounter + 1;
                draft.imagesgall = updateValues;
            })
        );
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(productArticleReset());
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/product-info"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createProductInformation();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createProductInformation}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/product-info"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    _onSelectStore = (selectedStores) => {
        if (selectedStores) {
            this._setTenant(selectedStores.value, selectedStores.label);
        }
    };

    UNSAFE_componentWillReceiveProps = () => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }

        if (this._isEditMode() || this._isViewMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) {
                storeArray.storeid === this.state.storeid
                    ? this.setState({ storelabel: storeArray.title })
                    : this.setState({ storelabel: this.state.storelabel });
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        const { generateThumbnailSuccess, data } = this.props.crudData;
        const { generateThumbnailSuccess: prevGenerateThumbnailSuccess } =
            prevProps.crudData;
        if (generateThumbnailSuccess !== prevGenerateThumbnailSuccess) {
            if (data && data.length > 1) {
                this.setState(
                    produce((draft) => {
                        draft.images = {
                            image1: '',
                            image2: data[0].filepathnew,
                            image3: data[1].filepathnew,
                            image4: data[2].filepathnew,
                        };
                    })
                );
            }
        }
    };

    _renderMainFormDetails = () => {
        const { articletitleerror, productcodeerror } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="productcode"
                            className="floatLeft required"
                        >
                            Product Code
                        </label>
                        <input
                            type="text"
                            value={this.state.productcode}
                            className="addoninput productcodemodal"
                            readOnly={true}
                            onClick={this._showProductModal}
                        />
                        <div className="invalid-feedback">
                            {productcodeerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Article Title
                        </label>
                        <input
                            type="text"
                            className={
                                articletitleerror === ''
                                    ? 'form-control'
                                    : 'error-control'
                            }
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {articletitleerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="articlecode" className="floatLeft">
                            Article Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="articlecode"
                            value={this.state.articlecode}
                            readOnly={true}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _showProductModal = (event) => {
        this.setState({
            showProdModal: true,
        });
        if (event) event.preventDefault();
    };

    _closeProductModal = (event) => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };
    _updateProdCode = (param, param1) => {
        this.setState({ productcode: param, title: param1 }, () =>
            this._closeProductModal()
        );
    };

    _renderProductModal = () => {
        return (
            <Modal
                visible={this.state.showProdModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProductModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: '15em' }}>
                        <Autocomplete
                            updateTitle={this._updateProdCode}
                        ></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };

    _renderGeneralInformation = () => {
        const { descriptionerror } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title_long" className="floatLeft">
                            Title Long
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title_long"
                            value={this.state.title_long}
                            onChange={(event) =>
                                this.setState({
                                    title_long: event.target.value,
                                })
                            }
                        />
                        {/* <div className="invalid-feedback">{title_longerror}</div> */}
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="title_long"
                            className="floatLeft required"
                        >
                            Description
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            value={this.state.description}
                            onChange={(event) =>
                                this.setState({
                                    description: event.target.value,
                                })
                            }
                        />
                        <div className="invalid-feedback">
                            {descriptionerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="description_long" className="floatLeft">
                            Description Long
                        </label>
                        <textarea
                            className="form-control"
                            id="description_long"
                            value={this.state.description_long}
                            onChange={(event) =>
                                this.setState({
                                    description_long: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    setImages = (images) => {
        this.setState({ images: images });
    };

    _renderProductInformation = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Product Information</div>
                <div className="row">
                    <div className="form-group col-sm-12 col-lg-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'title',
                                    label: 'Title',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'description',
                                    label: 'Description',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.productinfo}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderImageDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Product Image </div>

                <div className="row">
                    <div className="col-12">
                        <ImagePick
                            disableFirstIcon={true}
                            setImages={this.setImages}
                            images={this.state.images}
                            limit={4}
                        ></ImagePick>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _generateThumbnail = (fileToGenerateThumbnail) => {
        this.props.dispatch(crudReset());
        const payload = {
            body: [
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x1',
                    width: 150,
                    height: 150,
                    quality: 95,
                    hidden: 'N',
                },
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x2',
                    width: 200,
                    height: 200,
                    quality: 95,
                    hidden: 'N',
                },
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x3',
                    width: 250,
                    height: 250,
                    quality: 95,
                    hidden: 'N',
                },
            ],
        };
        this.props.dispatch(generateThumbnailApi(payload));
    };

    _renderImageGallery = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Image Gallery </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTableImage
                            columns={[
                                {
                                    dataFieldId: 'uri',
                                    label: 'uri',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'title',
                                    label: 'Title',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'alttext',
                                    label: 'Alternate Text',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'width',
                                    label: 'Width',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'height',
                                    label: 'Height',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'tag',
                                    label: 'Tag',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'sortorder',
                                    label: 'Order',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'AG',
                                    label: '',
                                },
                            ]}
                            rows={this.state.imagesgall}
                            deleteRow={this._deleteImageRow}
                            addRow={this.handleImageAddRow}
                            generateThumbnail={this._generateThumbnail}
                            handleImageInputChange={this.handleImageInputChange}
                            mediaImageList={this.props.queryData.mediaList}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Language
                        </label>
                        <LanguageSelector
                            language={this.state.language}
                            handleChange={(value) => {
                                this.setState({ language: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.languageerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            disabled={true}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Sort Order
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    textareaHandler = (val) => {
        const { prodspecs } = this.state;
        let filterdata = prodspecs.filter((item) => item.value !== val);
        this.setState({
            prodspecs: filterdata,
        });
    };

    getPropArr = (objlist) => {
        const newList = objlist.map((i) => {
            return Object.keys(i.additionalfields).map((e) => {
                var field = {
                    group: e,
                    label: i.additionalfields[e],
                    value: i.additionalfields[e],
                };
                return field;
            });
        });
        return newList[0];
    };

    setProductSpecs = (values) => {
        this.setState({
            prodspecs: values,
        });
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="grid rowBottom">
                    <div className="col-9">
                        <form className="splitFrmDiv">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderProductInformation()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderImageDetails()}
                        </form>
                    </div>
                    {/* <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec">
                            {this._renderStatusDetails()}
                        </form>
                    </div> */}
                    <div className="col-3">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderStatusDetails()}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 noPadding noMargin">
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderImageGallery()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _convertToImageGallery = (imageGalleryArray, size) => {
        for (let loop = imageGalleryArray.length; loop < size; loop++) {
            imageGalleryArray.push({
                uri: '',
                title: '',
                alttext: '',
                width: '',
                height: '',
                tag: '',
                sortorder: 0,
                id: loop,
            });
        }
        for (let loop = 0; loop < size; loop++) {
            //reassign.
            imageGalleryArray[loop].id = loop;
        }
        return imageGalleryArray;
    };

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    title,
                    articlecode,
                    status,
                    sortorder,
                    fields,
                    storeid,
                    images,
                    imagegallery,
                    language,
                } = rec;
                this.props.dispatch(getStoreById(storeid));

                let defaultproductinfo = [{ title: '', desc: '', id: 0 }];

                let imagearr = this._convertToImageGallery(
                    convertStrNum(imagegallery),
                    this.defaultimageField.length
                );
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.articletitle = title || '';
                        draft.title = title || '';
                        draft.articlecode = articlecode || '';
                        draft.storeid = storeid ? storeid : '';
                        draft.language = language ? language : '';
                        draft.status =
                            !isView && !isEdit
                                ? 'New'
                                : isView
                                  ? status
                                      ? status
                                      : 'New'
                                  : 'New';
                        draft.sortorder = sortorder ? sortorder : 0;

                        draft.productcode = fields.productcode || '';
                        draft.description = fields.description || '';
                        draft.title_long = fields.title_long || '';
                        draft.description_long = fields.description_long || '';

                        draft.images = {
                            image1:
                                rec.hasOwnProperty('images') === true
                                    ? images.storeicon
                                    : '',
                            image2:
                                rec.hasOwnProperty('images') === true
                                    ? images.productimageone
                                    : '',
                            image3:
                                rec.hasOwnProperty('images') === true
                                    ? images.productimagetwo
                                    : '',
                            image4:
                                rec.hasOwnProperty('images') === true
                                    ? images.productimagethree
                                    : '',
                        };
                        draft.imagesgall =
                            typeof imagearr !== 'undefined' &&
                            imagearr.length !== 0
                                ? imagearr
                                : this.defaultimageField;
                    })
                );
                if (fields) {
                    if (typeof fields.productinfo !== 'undefined') {
                        this.setState({ productinfo: fields.productinfo });
                    } else {
                        this.setState({ productinfo: defaultproductinfo });
                    }
                }
            }
        }
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Product Article'
                                    : this._isViewMode()
                                      ? 'View Product Article'
                                      : 'Add Product Article'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/product-info"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Product Article updated successfully'
                                    : 'Product Article created successfully'}
                            </div>
                        }
                    />
                </div>
                {this.state.showProdModal && this._renderProductModal()}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddProductInfo);
