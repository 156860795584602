import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  crudReset,
  updateArticleForm,
  createArticleForm
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusComp from "../../../components/StatusComp";
import { produce } from "immer";
import DynamicTable from "./../../../components/DynamicTable";
class AddArticleForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: "",
      articletype: "",
      articlecodeprefix: "",
      articlegroup: "",
      status: "New",
      sortorder: 1,
      formError: false,
      formErrorMsg: "",
      data: {},
      additionalFields: [
        { fieldname: "", fieldtype: "", validation: [], comments: "", id: 0 }
      ],
      images: [
        { fieldname: "", fieldtype: "", validation: [], comments: "", id: 0 }
      ]
    };
  }

  componentWillMount = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          fields,
          articletype,
          articlecodeprefix,
          status,
          sortorder,
          articlegroup
        } = rec;
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.articletype = articletype ? articletype : "";
            draft.articlecodeprefix = articlecodeprefix
              ? articlecodeprefix
              : "";
            draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.data = fields ? fields : {};
          })
        );
        if (fields) {
          let data = fields;
          var additionalFieldsData = Object.keys(data)
            .filter(key => {
              if (key.indexOf("additionalfield") >= 0) return data[key];
            })
            .map((key, index) => {
              data[key].id = index;
              return data[key];
            });
          if (additionalFieldsData.length === 0)
            additionalFieldsData = [
              {
                fieldname: "",
                fieldtype: "",
                validation: [],
                comments: "",
                id: 0
              }
            ];
          this.setState({ additionalFields: additionalFieldsData });

          var additionalImagesData = Object.keys(data)
            .filter(key => {
              if (key.indexOf("image") >= 0) return data[key];
            })
            .map((key, index) => {
              data[key].id = index;
              return data[key];
            });
          if (additionalImagesData.length === 0)
            additionalImagesData = [
              {
                fieldname: "",
                fieldtype: "",
                validation: [],
                comments: "",
                id: 0
              }
            ];
          this.setState({ images: additionalImagesData });
        }
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;

    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  arrayToObject = (array, value) => {
    var fields = {};
    var len = array.length;
    var additionalFieldNum;
    if (len === 0) {
      additionalFieldNum = 0;
    } else {
      additionalFieldNum = 50;
    }
    if (this.state.articletype === "Faq" && value === "additionalfield") {
      for (var i = 0; i < len; i++) {
        var item = array[i];

        if (item.fieldname === "arttype") {
          let newitem = {
            fieldname: item.fieldname,
            fieldtype: item.fieldtype,
            // validation: item.validation !== null ? item.validation.split(",") : [],
            validation: item.validation,
            comments: item.comments,
            id: item.id
          };

          fields[`${value}1`] = newitem;
        } else {
          let newitem = {
            fieldname: item.fieldname,
            fieldtype: item.fieldtype,
            // validation: item.validation !== null ? item.validation.split(",") : [],
            validation: item.validation,
            comments: item.comments,
            id: item.id
          };

          fields[`${value}${[++additionalFieldNum]}`] = newitem;
        }
      }
    } else {
      for (var i = 0; i < len; i++) {
        var item = array[i];

        let newitem = {
          fieldname: item.fieldname,
          fieldtype: item.fieldtype,
          // validation: item.validation !== null ? item.validation.split(",") : [],
          validation: item.validation,
          comments: item.comments,
          id: item.id
        };

        fields[`${value}${[i + 1]}`] = newitem;
      }
    }
    return fields;
  };
  getCreatePayload = () => {
    const {
      id,
      additionalFields,
      images,
      articletype,
      articlegroup,
      articlecodeprefix,
      sortorder,
      status
    } = this.state;

    const fields = this.arrayToObject(additionalFields, "additionalfield");
    const imageList = this.arrayToObject(images, "image");
    const fieldObj = Object.assign(fields, imageList);

    let body = {
      articletype: articletype,
      articlecodeprefix: articlecodeprefix,
      articlegroup: articlegroup,
      status: status,
      sortorder: sortorder,
      fields: fieldObj
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createArticle = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleForm(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleForm(this.getCreatePayload()));
      }
    }
  };
  _clearErrorMessages = () => {
    this.setState({ formError: false, formErrorMsg: "" });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { articletype, articlecodeprefix } = this.state;
    if (articletype.trim() === "" || articlecodeprefix.trim() === "") {
      this.setState({
        formError: true,
        formErrorMsg: "Please fill out all mandatory fields"
      });
      return false;
    }
    return true;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
               type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
               type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/article-form"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="articletype" className="floatLeft">
              Article Type
            </label>
            <input
              type="text"
              className="form-control"
              id="articletype"
              value={this.state.articletype}
              onChange={event =>
                this.setState({ articletype: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event =>
                this.setState({ articlegroup: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="articlecodeprefix" className="floatLeft">
              Article Code Prefix
            </label>
            <input
              type="text"
              className="form-control"
              id="articlecodeprefix"
              value={this.state.articlecodeprefix}
              onChange={event =>
                this.setState({ articlecodeprefix: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12 label2">
            <label htmlFor="sortorder" className="floatLeft">
              Additional Fields
            </label>
          </div>
          <div className="form-group col-lg-12 col-sm-12">
            <DynamicTable
              columns={[
                { dataFieldId: "fieldname", label: "Field Name", addRow: true },
                { dataFieldId: "fieldtype", label: "Field Type", addRow: true },
                {
                  dataFieldId: "validation",
                  label: "Validation",
                  addRow: true
                },
                { dataFieldId: "comments", label: "Comments", addRow: true },

                {
                  dataFieldId: "AD",
                  label: ""
                }
              ]}
              rows={this.state.additionalFields}
              deleteRow={this.deleteRow}
              addRow={this.handleAddRow}
              handleInputChange={this.handleInputChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12 label2">
            <label htmlFor="images" className="floatLeft">
              Images
            </label>
          </div>
          <div className="form-group col-lg-12 col-sm-12">
            <DynamicTable
              columns={[
                { dataFieldId: "fieldname", label: "Field Name", addRow: true },
                { dataFieldId: "fieldtype", label: "Field Type", addRow: true },
                {
                  dataFieldId: "validation",
                  label: "Validation",
                  addRow: true
                },
                { dataFieldId: "comments", label: "Comments", addRow: true },

                {
                  dataFieldId: "AD",
                  label: ""
                }
              ]}
              rows={this.state.images}
              deleteRow={this.deleteImageRow}
              addRow={this.handleImageAddRow}
              handleInputChange={this.handleImageInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Sort Order
            </label>
            <input
              type="text"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  // Dynamic Table ---starts
  handleAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.additionalFields.length;
    let lastele = this.state.additionalFields[rowLength - 1];
    const item = {
      fieldname: "",
      fieldtype: "",
      validation: [],
      comments: "",
      id: lastele.id + 1
    };
    this.setState(
      {
        additionalFields: [...this.state.additionalFields, item]
      },
      () => {
        console.log("Rows After Add", this.state.additionalFields);
      }
    );
  };

  handleInputChange = (event, dataField, row) => {
    let obj = {
      id: row.id,
      [dataField]:
        dataField === "validation" ? [event.target.value] : event.target.value
    };
    this.setState(
      produce(draft => {
        if (draft.additionalFields.length === 0) {
          draft.additionalFields.push(obj);
        } else {
          let found = false;
          draft.additionalFields.forEach(command => {
            if (command.id === obj.id) {
              Object.assign(command, obj);
              found = true;
            }
          });
          if (!found) {
            draft.additionalFields.push(obj);
          }
        }
      }),
      () => {}
    );
  };
  deleteRow = row => {
    let rowId = row.id;
    const rowLength = this.state.additionalFields.length;

    if (rowLength > 1) {
      /*  this.setState(
        produce(draft => {
          const objIndex = draft.additionalFields.findIndex(
            field => field.id === rowId
          );
          if (objIndex > -1) {
            draft.additionalFields.splice(objIndex, 1);
          }
        })
      ); */
      const updateValues = this.state.additionalFields.filter(
        value => rowId !== value.id
      );

      this.setState({ additionalFields: updateValues });
    }
  };

  // Images
  handleImageAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.images.length;
    let lastele = this.state.images[rowLength - 1];
    const item = {
      fieldname: "",
      fieldtype: "",
      validation: [],
      comments: "",
      id: lastele.id + 1
    };
    this.setState(
      {
        images: [...this.state.images, item]
      },
      () => {
        console.log("Rows After Add", this.state.images);
      }
    );
  };

  handleImageInputChange = (event, dataField, row) => {
    let obj = {
      id: row.id,
      [dataField]: event.target.value
    };
    this.setState(
      produce(draft => {
        if (draft.images.length === 0) {
          draft.images.push(obj);
        } else {
          let found = false;
          draft.images.forEach(command => {
            if (command.id === obj.id) {
              Object.assign(command, obj);
              found = true;
            }
          });
          if (!found) {
            draft.images.push(obj);
          }
        }
      }),
      () => {}
    );
  };
  deleteImageRow = row => {
    let rowId = row.id;
    const rowLength = this.state.images.length;
    if (rowLength > 1) {
      /*  this.setState(
        produce(draft => {
          const objIndex = draft.additionalFields.findIndex(
            field => field.id === rowId
          );
          if (objIndex > -1) {
            draft.additionalFields.splice(objIndex, 1);
          }
        })
      ); */
      const updateValues = this.state.images.filter(
        value => rowId !== value.id
      );
      this.setState({ images: updateValues }, () => {});
    }
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Update Article Form"
                  : "Add Article Form"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/article-form"
            children={
              <div className="alert alert-success">
                {this._isEditMode() === true
                  ? "Article form updated successfully"
                  : "Article form created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return { queryData: state.queryData, crudData: state.crudData };
};

export default connect(mapStateToProps)(AddArticleForm);
