import produce from 'immer';
import React from 'react';
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import { usePapaParse } from 'react-papaparse';
import { Dialog } from 'primereact/dialog';
import {
    crudReset,
    exportReport,
    resetAction,
    searchGeneral,
    storeOpenSearchByFields,
} from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert';
import {
    filterFormatter,
    formatDate,
    formatLanguageArray,
    permissions,
} from '../../helpers/Util';
import * as CONSTANTS from '../../constants/constants';
import StoreSearchSingle from '../system/Users/StoreSearchSingle';
import MultiSelect from '../../components/MultiSelect';
import MasterReportPdf from './BIRReportTable/MasterReportPdf';
import PrimeTable from '../../components/PrimeTable';
import { Column } from 'primereact/column';
import Loading from '../../components/Loading';

class ExportAndDownloadReport extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'REPORTS';
        this.group = 'DE_REPORTS_EXPORT_AND_DOWNLOAD';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,

            storeid: '',
            trxno: '',
            invoiceno: '',
            signonid: '',
            storesessionid: '',
            usercode: '',
            terminalid: '',
            orderid: '',
            trxdate: '',
            storedate: new Date(),
            trxtype: '',
            trxgroup: '',
            trxtypes: {},
            trxgroups: {},
            paytype: '',
            paytypes: {},
            storesessions: [{ name: 'Select', id: '' }],

            message: '',
            action: '',
            date_from: new Date(),
            date_till: new Date(),

            status: '',
            pagesize: this.pageSize,

            showLogDetails: false,
            selectedLog: {},

            storeiderror: '',
            storesessionerror: '',
            exportVisible: false,
            exportData: [],
            loading: false
        };
    }

    actionTemplate = row => {
        if (row.status !== 'exported') {
            return;
        }
        if (this.props.reporttype === 'POS_MASTER_DETAILS_REPORT') {
            return (
                <div className='flex'>
                    <span className='mr-2' onClick={() => this.downloadPdf(row)}>
                        <i className="pi pi-file-pdf cursor-pointer" title="download pdf" style={{ fontSize: '20px'}}></i>
                    </span>
                    <span className='mr-2' onClick={() => this.download(row)}>
                        <i className="pi pi-file-export cursor-pointer" title="download csv" style={{ fontSize: '20px'}}></i>
                    </span>
                </div>
            )
        }
        return (
            <span>
                <img
                    title="Download"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                    onClick={() => this.download(row)}
                />
            </span>
        );
    }

    fetchCsvFile = async (url) => {
        const { readRemoteFile } = usePapaParse();
        const config = {
            header: true,
            dynamicTyping: true,
            complete: this.handleFile,
          };
        readRemoteFile(url, config);
      };
    
    handleFile = (result) => {
            this.setState({ exportData: result.data })
            this.setState({ exportVisible: true, loading: false })
      };

    getPdf = () => {
        return (
            <MasterReportPdf data={this.state.exportData} />
        )
    }

    downloadPdf = row => {
        const url = localStorage.getItem('imagepath') + row.title;
        this.fetchCsvFile(url)
        this.setState({ loading: true })
    }

    download = (row) => {
        var link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
            var url = localStorage.getItem('imagepath') + row.title;
            link.setAttribute('href', url);

            var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    pagesize: pageSize,
                    pageno: currPage,
                    tasktype: this.getTaskType(),
                },
            };
            return requestBody;
        }
        if (this.requestBody['tasktype'] === undefined) {
            this.requestBody['tasktype'] = this.getTaskType();
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        // var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    componentWillMount() {
        if (this.props.params) {
            this.setState(
                {
                    storeid: this.props.params.storeid,
                    s_storelabel: this.props.params.s_storelabel,
                    trxtypes: this.props.params.trxtypes,
                    trxgroups: this.props.params.trxgroups,
                    paytypes: this.props.params.paytypes,
                }
            );
        }
        this.callQueryApiForFetch('ONLOAD');
        this.allPermissions = permissions(this.module, this.group);
    }

    fetchStoreSessions = () => {
        const { storeid, date_from, date_till } = this.state;

        //reset before query
        this.props.queryData.storeSessions = [];
        if (storeid && storeid !== '' && date_from !== '') {
            const queryParams = [];
            queryParams.push(`storeid=${storeid}`);
            //queryParams.push(`storedate=${formatDate(date_from)}`);
            queryParams.push(`date_from=${formatDate(date_from)}`);
            queryParams.push(`date_till=${formatDate(date_till)}`);
            this.props.dispatch(
                storeOpenSearchByFields(
                    queryParams.join('&'),
                    CONSTANTS.SEARCH_STORE_OPEN_SUCCESS_SILENT,
                    CONSTANTS.SEARCH_STORE_OPEN_FAILURE_SILENT
                )
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeSessions } = this.props.queryData;
        const { storeSessions: prevstoreSessions } = prevProps.queryData;
        if (storeSessions !== prevstoreSessions) {
            if (storeSessions && storeSessions.length > 0) {
                var sessions = [{ name: 'Select', id: '' }];
                var returnedSessions = storeSessions.map((e) => ({
                    name: `${e.trxdate} (${e.status})`,
                    id: e.storesessionid,
                }));
                this.setState({
                    storesessions: sessions.concat(returnedSessions),
                });
            } else {
                this.setState({ storesessions: [{ name: 'Select', id: '' }] });
            }
        }
    };

    componentWillReceiveProps = () => {
        const { storeSessions, propConfigs } = this.props.queryData;
        if (storeSessions && storeSessions.length > 0) {
            var sessions = [{ name: 'Select', id: '' }];
            var returnedSessions = storeSessions.map((e) => ({
                name: `${e.trxdate} (${e.status})`,
                id: e.storesessionid,
            }));
            this.setState({ storesessions: sessions.concat(returnedSessions) });
        }
        if (
            propConfigs &&
            propConfigs.length > 0 &&
            propConfigs[0].additionalfields
        ) {
            if (
                this.props.reporttype === CONSTANTS.POS_MASTER_DETAILS_REPORT ||
                this.props.reporttype === CONSTANTS.SALES_DETAILS_REPORT ||
                this.props.reporttype === CONSTANTS.TAG_DETAILS_REPORT
            ) {
                this.setState({ trxtypes: propConfigs[0].additionalfields });
            } else if (
                this.props.reporttype === CONSTANTS.TENDER_LISTING_REPORT
            ) {
                this.setState({ paytypes: propConfigs[0].additionalfields });
            }
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.queryData.reportexport = {};
        this.callQueryApiForFetch('ONLOAD');
    };

    exportReport = (e) => {
        if (e) e.preventDefault();
        const {
            storeid,
            trxno,
            usercode,
            terminalid,
            storedate,
            date_from,
            date_till,
            trxtype,
            paytype,
            storesessionid,
            status,
            categorycode,
        } = this.state;

        this.requestBody = {};
        const queryParams = [];
        if (this.state.storeid === '' || this.state.storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return;
        } else {
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
            queryParams.push(`storeid=${storeid}`);
        }

        switch (this.props.reporttype) {
            case CONSTANTS.POS_MASTER_DETAILS_REPORT:
            case CONSTANTS.SALES_DETAILS_REPORT:
                this.requestBody['trxtype'] = trxtype;
                queryParams.push(`trxtype=${trxtype}`);
                break;
            case CONSTANTS.TENDER_LISTING_REPORT:
                this.requestBody['paytype'] = paytype;
                queryParams.push(`paytype=${paytype}`);
                break;
            case CONSTANTS.TAG_DETAILS_REPORT:
                this.requestBody['trxtype'] = trxtype;
                queryParams.push(`trxtype=${trxtype}`);
                this.requestBody['taggroup'] = 'RabbitLoyalty';
                queryParams.push(`taggroup=RabbitLoyalty`);
                this.requestBody['additionalfields'] = 'rabbit_point';
                queryParams.push(`additionalfields=rabbit_point`);
                break;
            case CONSTANTS.REFUND_DETAILS_REPORT:
                this.requestBody['refundstatus'] = 'refunded';
                queryParams.push(`refundstatus=refunded`);
                break;
            case CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT:
                this.requestBody['status'] = 'close';
                queryParams.push(`status=close`);
            default:
                break;
        }

        if (
            this.props.reporttype !== CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT &&
            this.props.reporttype !== CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT
        ) {
            if (usercode !== '') {
                this.requestBody['usercode'] = usercode;
                queryParams.push(`usercode=${usercode}`);
            }

            if (terminalid !== '') {
                this.requestBody['terminalid'] = terminalid;
                queryParams.push(`terminalid=${terminalid}`);
            }

            if (trxno !== '') {
                this.requestBody['trxno'] = trxno;
                queryParams.push(`trxno=${trxno}`);
            }
        }

        if (
            this.props.reporttype === CONSTANTS.POS_MASTER_DETAILS_REPORT ||
            this.props.reporttype === CONSTANTS.SALES_DETAILS_REPORT ||
            this.props.reporttype === CONSTANTS.REFUND_DETAILS_REPORT ||
            this.props.reporttype === CONSTANTS.TENDER_LISTING_REPORT ||
            this.props.reporttype === CONSTANTS.TAG_DETAILS_REPORT
        ) {
            if (date_from !== '') {
                this.requestBody['date_from'] = formatDate(date_from);
                queryParams.push(`date_from=${formatDate(date_from)}`);
            }
            if (date_till !== '') {
                this.requestBody['date_till'] = formatDate(date_till);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            }
            if (storesessionid !== '') {
                this.requestBody['storesessionid'] = storesessionid;
                queryParams.push(`storesessionid=${storesessionid}`);
            }
        } else if (
            this.props.reporttype === CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT
        ) {
            if (status !== '') {
                this.requestBody['status'] = status;
                queryParams.push(`status=${status}`);
            }

            if (categorycode !== '') {
                this.requestBody['categorycode'] = categorycode;
                queryParams.push(`categorycode=${categorycode}`);
            }
        }
        //query
        this.setState({ message: 'Exported Sucessfully' });
        this.props.dispatch(
            exportReport(this.exportReportUrl(), queryParams.join('&'))
        );
    };

    reportTitle = () => {

        switch (this.props.reporttype) {
            case CONSTANTS.POS_MASTER_DETAILS_REPORT:
                return 'Master Report';
            case CONSTANTS.SALES_DETAILS_REPORT:
                return 'Sales Report';
            case CONSTANTS.TENDER_LISTING_REPORT:
                return 'Tender Listing Report';
            case CONSTANTS.TAG_DETAILS_REPORT:
                return 'Rabbit Member Report';
            case CONSTANTS.REFUND_DETAILS_REPORT:
                return 'Refund Report';
            case CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT:
                return 'Product Price Details Report';
            case CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT:
                return 'Product Sales Report';
            default:
                return '';
        }
    };

    exportReportUrl = () => {
        switch (this.props.reporttype) {
            case CONSTANTS.POS_MASTER_DETAILS_REPORT:
                return '/pos/v1/report/master/details/export';
            case CONSTANTS.SALES_DETAILS_REPORT:
                return '/pos/v1/report/sales/details/export';
            case CONSTANTS.TENDER_LISTING_REPORT:
                return '/pos/v1/report/sales/payment/details/export';
            case CONSTANTS.TAG_DETAILS_REPORT:
                return '/pos/v1/report/sales/tag/details/export';
            case CONSTANTS.REFUND_DETAILS_REPORT:
                return '/pos/v1/report/sales/refund/details/export';
            case CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT:
                return '/sales/v1/product/report/price/details/export';
            case CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT:
                return '';
            default:
                return '';
        }
    };

    getTaskType = () => {
        switch (this.props.reporttype) {
            case CONSTANTS.POS_MASTER_DETAILS_REPORT:
                return 'pos_master_details';
            case CONSTANTS.SALES_DETAILS_REPORT:
                return 'pos_sales_details';
            case CONSTANTS.TENDER_LISTING_REPORT:
                return 'pos_sales_payment_details';
            case CONSTANTS.TAG_DETAILS_REPORT:
                return 'pos_sales_tag_details';
            case CONSTANTS.REFUND_DETAILS_REPORT:
                return 'pos_sales_refund_details';
            case CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT:
                return 'sales_price_details';
            default:
                return '';
        }
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }

            if (prop === 'title') {
                obj['subtitle'] = obj['categorycode'];
            }
        }

        return obj;
    };

    _renderSearchCiteria = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="fromDate" className="floatLeft">
                            Date From
                        </label>
                        <DatePicker
                            onChange={(value) =>
                                this.setState({ date_from: value }, () => {
                                    if (
                                        this.state.date_from >
                                        this.state.date_till
                                    ) {
                                        this.setState({
                                            date_till: this.state.date_from,
                                        });
                                    }
                                    this.fetchStoreSessions();
                                })
                            }
                            value={this.state.date_from}
                            maxDate={new Date()}
                            format="d/M/yy"
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="toDate" className="floatLeft">
                            Date To
                        </label>
                        <DatePicker
                            minDate={this.state.date_from}
                            onChange={(value) =>
                                this.setState({ date_till: value }, () => {
                                    this.fetchStoreSessions();
                                })
                            }
                            value={this.state.date_till}
                            maxDate={new Date()}
                            format="d/M/yy"
                        />
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="terminalid" className="floatLeft">
                            Terminal Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="terminalid"
                            value={this.state.terminalid}
                            onChange={(event) => {
                                this.setState({
                                    terminalid: event.target.value,
                                });
                            }}
                        />
                    </div>

                    {(this.props.reporttype ===
                        CONSTANTS.TENDER_LISTING_REPORT ||
                        this.props.reporttype ===
                        CONSTANTS.SALES_DETAILS_REPORT ||
                        this.props.reporttype ===
                            CONSTANTS.POS_MASTER_DETAILS_REPORT) && (
                        <div className="form-group col-lg-3 col-sm-4">
                            <label
                                htmlFor="storesessionid"
                                className="floatLeft"
                            >
                                Store Session
                            </label>
                            <div className="row">
                                <select
                                    name="storesession"
                                    className="simpleSearchSelect2"
                                    value={this.state.storesessionid}
                                    onChange={(event) =>
                                        this.setState({
                                            storesessionid: event.target.value,
                                        })
                                    }
                                >
                                    {this.state.storesessions.length !== 0 &&
                                        this.state.storesessions.map(
                                            (option, index) => {
                                                const { name, id } = option;
                                                return (
                                                    <option
                                                        key={index}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                            <div className="invalid-feedback">
                                {this.state.storesessionerror}
                            </div>
                        </div>
                    )}
                    {this.props.reporttype ===
                        CONSTANTS.POS_MASTER_DETAILS_REPORT && (
                        <div className="form-group col-lg-3 col-sm-4">
                            <label htmlFor="trxgroup" className="floatLeft">
                                Trx Group
                            </label>
                            <div className="row">
                                <MultiSelect
                                    filterOptions={formatLanguageArray(
                                        this.state.trxgroups
                                    )}
                                    setSelectedFieldValues={(value) => {
                                        this.setState({ trxgroup: value });
                                    }}
                                    id="trxgroup"
                                    itemsSelected={this.state.trxgroup}
                                    label="Select Trx Group"
                                />
                            </div>
                        </div>
                    )}

                    {(this.props.reporttype ===
                        CONSTANTS.POS_MASTER_DETAILS_REPORT ||
                        this.props.reporttype ===
                            CONSTANTS.SALES_DETAILS_REPORT ||
                        this.props.reporttype ===
                            CONSTANTS.TAG_DETAILS_REPORT) && (
                        <div className="form-group col-lg-3 col-sm-4">
                            <label htmlFor="trxtype" className="floatLeft">
                                Trx Type
                            </label>
                            <div className="row">
                                <MultiSelect
                                    filterOptions={formatLanguageArray(
                                        this.state.trxtypes
                                    )}
                                    setSelectedFieldValues={(value) => {
                                        this.setState({ trxtype: value });
                                    }}
                                    id="trxtype"
                                    itemsSelected={this.state.trxtype}
                                    label="Select Trx Type"
                                />
                            </div>
                        </div>
                    )}
                    {this.props.reporttype ===
                        CONSTANTS.TENDER_LISTING_REPORT && (
                        <div className="form-group col-lg-3 col-sm-4">
                            <label htmlFor="paytype" className="floatLeft">
                                Pay Type
                            </label>
                            <div className="row">
                                <MultiSelect
                                    filterOptions={formatLanguageArray(
                                        this.state.paytypes
                                    )}
                                    setSelectedFieldValues={(value) => {
                                        this.setState({ paytype: value });
                                    }}
                                    id="paytype"
                                    itemsSelected={this.state.paytype}
                                    label="Select Pay Type"
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="usercode" className="floatLeft">
                            User Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="usercode"
                            value={this.state.usercode}
                            onChange={(event) => {
                                this.setState({ usercode: event.target.value });
                            }}
                        />
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="trxno" className="floatLeft">
                            Transaction No
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxno"
                            value={this.state.trxno}
                            onChange={(event) => {
                                this.setState({ trxno: event.target.value });
                            }}
                        />
                    </div>
                    <div
                        className="col-lg-3 col-sm-12 btn-container1"
                        style={{ marginTop: '1em' }}
                    >
                        <button
                            type="submit"
                            onClick={(e) => this.exportReport(e)}
                            className="btn btn-themes btn-rounded"
                        >
                            Export
                        </button>
                    </div>
                </div>
                <div className="form-group col-lg-7 col-sm-3 noPadding" />
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState(
            { storeid: p1, s_storelabel: p2, search_value: p1 },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
            }
        );
        this.setState({ storeiderror: '' });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    filterFormatter = (
        row,
        excludedfields = ['pagesize', 'pageno', 'filepath']
      ) => {
        let filters = '';
        if (row.additionalfields) {
            let filterArr = [];
            for (const [key, value] of Object.entries(row.additionalfields)) {
                if (excludedfields.includes(key)) {
                    continue;
                }
                filterArr.push(`${key}=${value}`);
            }
            filters =  filterArr.join(' | ');
        }
        return filters
      };

    filterBodyTemplate = (data) => {
        return this.filterFormatter(data)
    }

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            jobtasklogs,
            reportexport,
        } = this.props.queryData;

        return (
            <React.Fragment>
                {this.state.loading && (
                    <Loading />
                )}
                <Dialog
                    visible={this.state.exportVisible}
                        style={{ width: '80vw' }}
                        onHide={() => this.setState({ exportVisible: !this.state.exportVisible})
                        }
                    >
                    {this.getPdf()}
                </Dialog>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">
                                {this.reportTitle()}
                            </h2>
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <div className="row">
                                    {this._renderSearchCiteria()}
                                </div>
                                <div className="title_devide" />
                                <PrimeTable
                                        tablePageSize={this.pageSize}
                                        showFilterSearch={false}
                                        list={jobtasklogs}
                                        columns={[]}
                                        actionColumn={this.actionTemplate}
                                        pageSizeChangecallback={(pageSize) => {
                                            this.setState(
                                                produce(this.state, (draft) => {
                                                    if (draft.pageSize < pageSize) {
                                                        draft.currPage = 1;
                                                    }
                                                    draft.pageSize = pageSize;
                                                }),
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                        pageChangeCallback={(currPage) => {
                                            this.setState(
                                                {
                                                    currPage: currPage,
                                                },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                        lastChildLocation={true}
                                    >
                                        <Column
                                            style={{width:'20%'}}
                                            field="title"
                                            header="Path"
                                        />
                                        <Column
                                            field="status"
                                            header="Status"
                                        />
                                        <Column
                                            style={{width:'30%'}}
                                            field="filters"
                                            header="Filters"
                                            body={this.filterBodyTemplate}
                                        />
                                        <Column
                                            field="createdon"
                                            header="Exported On"
                                        />
                                </PrimeTable>
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={
                            reportexport && reportexport.status === 'exporting'
                                ? true
                                : false
                        }
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    reportexport &&
                                    reportexport.status === 'exporting'
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {reportexport &&
                                reportexport.status === 'exporting'
                                    ? this.state.message
                                    : ''}
                            </div>
                        }
                    />
                </div>
                <div
                    className="row btn-container form-button"
                    style={{ zIndex: '1' }}
                >
                    <div className="col-sm-12 col-lg-12">
                        {this._renderMainButton()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    _renderMainButton = () => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.queryData.errormsg = '';
                        this.props.queryData.servererror = '';
                        this.props.crudData.errormsg = '';
                        this.props._hideExport();
                    }}
                >
                    Back
                </button>
            </React.Fragment>
        );
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            id: '',
            storeid: '',
            status: '',
            title: '',

            storeiderror: '',
            storesessionerror: '',
        });
        //clear the search results
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ExportAndDownloadReport);
