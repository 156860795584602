import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TenantCode from "../../../components/TenantCode";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-date-picker";
import {
    toDate,
    renderConfigInMultiSelect,
    dateRange,
} from "./../../../helpers/Util";
import {
    searchGeneral,
    resetAction,
    crudReset,
} from "../../../actions/actions";
import ExportButton from "../../../components/TableExportButton";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleSelect from "../../../components/SimpleSelect";
import Modal from "react-awesome-modal";
import TimePicker from "rc-time-picker";
import moment from "moment";
import ReportTable from "../../../components/ReportTable";
import StoreSearchSingle from "../../system/Users/StoreSearchSingle";

const SalesProductReport = (props) => {
    const timings = {
        t1: "00:00-05:59",
        t2: "06:00-10:29",
        t3: "10:30-16:00",
        t4: "16:01-23:59",
    };

    const tableColsProd = [
        {
            dataField: "description",
            text: "Product Name",
            sort: true,
        },
        {
            dataField: "categorycode",
            text: "Category Code",
            sort: true,
        },
        {
            dataField: "addon",
            text: "Add On",
            sort: true,
        },
        {
            dataField: "totalqtybyproduct",
            text: "Total Qty",
            sort: true,
        },
        {
            dataField: "totalamountbyproduct",
            text: "Total Amt",
            sort: true,
        },
    ];

    const tableColsCat = [
        {
            dataField: "description",
            text: "Category Name",
            sort: true,
        },
        {
            dataField: "categorycode",
            text: "Category Code",
            sort: true,
        },
        {
            dataField: "totalqtybycategory",
            text: "Total Qty",
            sort: true,
        },
        {
            dataField: "totalamountbycategory",
            text: "Total Amt",
            sort: true,
        },
    ];

    const [showSearchDiv, setShowSearchDiv] = useState(false);
    const [storeInfo, setStoreInfo] = useState({
        storeid: "",
        storelabel: "",
    });
    const [currency, setCurrency] = useState("");
    const [status, setStatus] = useState("close");
    const [overalldate, setOveralldate] = useState([
        new Date("June 1, 2020 0:00:00"),
        new Date(),
    ]);
    const [date_from, setDate_from] = useState(
        new Date("June 1, 2020 0:00:00")
    );
    const [date_till, setDate_till] = useState(new Date());
    const [prodsalesdata, setProdsalesdata] = useState({
        data: [],
        list: [],
    });
    const [catsalesdata, setCatsalesdata] = useState({
        data: [],
        list: [],
    });
    const [time, setTime] = useState([
        "06:00-10:29",
        "10:30-16:00",
        "16:01-23:59",
    ]);
    const [showProdReport, setShowProdReport] = useState(true);
    const [showCategoryReport, setShowCategoryReport] = useState(false);
    const [timefrom, setTimefrom] = useState("");
    const [timeto, setTimeto] = useState("");
    const [storeiderror, setStoreiderror] = useState("");
    const [productaddon, setProductaddon] = useState("N");
    const [formError, setFormError] = useState({
        formError: false,
        formErrorMsg: "",
    });
    const [overalldateerror, setOveralldateerror] = useState("");
    const [buttonProdActive, setButtonProdActive] = useState(true);
    const [buttonCatActive, setButtonCatActive] = useState(false);

    // useEffect(() => {
    //   props.dispatch(
    //     searchGeneral(
    //       "/sales/v1/store/search/fields",
    //       {
    //         body: {
    //           groupid: localStorage.getItem("storegroup_access")
    //         }
    //       },
    //       "SEARCH_CITY_BY_COUNTRY_SUCCESS",
    //       "SEARCH_CITY_BY_COUNTRY_FAILURE"
    //     )
    //   )
    // }, [])

    useEffect(() => {
        const { dataByCountry, salesReportData } = props.queryData;
        if (dataByCountry.length > 0) {
            _setCurrency();
        }
        if (storeInfo.storeid === "") {
            if (dataByCountry.length > 0) {
                setStoreInfo({
                    storeid: dataByCountry[0].storeid,
                    storelabel: dataByCountry[0].title,
                });
            }
        }
        if (JSON.stringify(salesReportData) !== "{}") {
            _updateSalesData();
            if (
                salesReportData.categories &&
                salesReportData.categories.length > 0
            ) {
                let modifiedlistcat = createObjectCat(salesReportData);
                if (modifiedlistcat.length > 0) {
                    setCatsalesdata({
                        data: salesReportData.categories,
                        list: modifiedlistcat,
                    });
                }
            }
        }
    }, [props.queryData]);

    useEffect(() => {
        if (storeInfo.storeid !== "") _validateAndSearch("ONLOAD");
    }, [storeInfo]);

    const handleChange = (time, name) => {
        let timeformat = moment(time).format("HH:mm");
        if (timeformat === "Invalid date") {
            time = "";
        }

        switch (name) {
            case "timefrom":
                setTimefrom(time);
                break;
            case "timeto":
                setTimeto(time);
                break;
        }
    };

    const textareaHandler = (val) => {
        let filterdata = time.filter((item) => item !== val);
        setTime(filterdata);
    };

    const buttonHandler = () => {
        if (timefrom < timeto && timefrom !== "" && timeto !== "") {
            let timeset = [timefrom + "-" + timeto];
            setTimeto("");
            setTimefrom("");
            setTime([...time, ...timeset]);
        }
    };

    const _renderSearchForm = () => {
        var contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "100px",
            maxWidth: "450px",
        };
        let intervals = [...new Set(time)];
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="fromDate" className="floatLeft">
                            Date From
                        </label>
                        <DatePicker onChange={setDate_from} value={date_from} />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="Transaction Date From"
                            className="floatLeft"
                        >
                            Date To
                        </label>
                        <DatePicker onChange={setDate_till} value={date_till} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="search_value"
                            className="floatLeft required"
                        >
                            Choose Store
                        </label>
                        <StoreSearchSingle
                            value={storeInfo.storelabel}
                            onSelectStore={_onSelectStore}
                        />
                        {/* <TenantCode
                  value={storeInfo.storelabel}
                  setTenantCode={_setTenant}
                /> */}
                        <div className="invalid-feedback" style={{ height: '1em'}}>{storeiderror}</div>
                    </div>
                    <div className="form-group col-sm-12 col-lg-6">
                        <label htmlFor="productaddon" className="floatLeft">
                            Addon
                        </label>
                        <SimpleSelect
                            objArray={{ Y: "Yes", N: "No" }}
                            callbackChangeFilter={setProductaddon}
                            selectedOption={productaddon}
                            id="productaddon"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex ml-3">
                        <div>
                            <label htmlFor="timefrom" className="floatLeft">
                                Time From
                            </label>
                            <div>
                                <TimePicker
                                    onClose={buttonHandler}
                                    value={
                                        moment(timefrom, "HH:mm").isValid()
                                            ? moment(timefrom, "HH:mm")
                                            : ""
                                    }
                                    onChange={(time) =>
                                        handleChange(time, "timefrom")
                                    }
                                    showSecond={false}
                                />
                            </div>
                        </div>
                        <div style={{ margin: "20px 10px" }}>-</div>
                        <div>
                            <label htmlFor="timeto" className="floatLeft">
                                Time Till
                            </label>
                            <div>
                                <TimePicker
                                    onClose={buttonHandler}
                                    value={
                                        moment(timeto, "HH:mm").isValid()
                                            ? moment(timeto, "HH:mm")
                                            : ""
                                    }
                                    onChange={(time) =>
                                        handleChange(time, "timeto")
                                    }
                                    showSecond={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-6 col-sm-12"
                        style={{ margin: "10px" }}
                    >
                        <div style={contentareastyle}>
                            {intervals.map((datevalue) => {
                                return (
                                    <div
                                        className="text-content"
                                        key={datevalue.toString()}
                                    >
                                        <input
                                            type="text"
                                            value={datevalue}
                                            className="inputfield"
                                            readOnly
                                        />
                                        <button
                                            className="btn-primary btntext"
                                            type="button"
                                            onClick={() =>
                                                textareaHandler(datevalue)
                                            }
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                _validateAndSearch("SEARCH");
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                _closeSearchDiv();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const _setCurrency = () => {
        const { dataByCountry } = props.queryData;
        if (dataByCountry.length > 0) {
            let getcnobj = dataByCountry.filter(
                (e) => e.storeid === storeInfo.storeid
            );
            if (getcnobj.length > 0) {
                setCurrency(getcnobj[0].basecur);
            }
        }
    };

    const _setTenant = (p1, p2) => {
        setStoreInfo({
            storeid: p1,
            storelabel: p2,
        });
    };

    const _onSelectStore = (selectedStores) => {
        _setTenant(selectedStores.value, selectedStores.label);
    };

    const _callFields = () => {
        //const daterange = dateRange(overalldate);
        const requestBody = {
            body: {
                storeid: storeInfo.storeid,
                date_from: toDate(date_from),
                date_till: toDate(date_till),
                time: time.toString(),
                status,
            },
        };
        return requestBody;
    };

    const _callFetch = (fetchType) => {
        props.dispatch(
            searchGeneral(
                "pos/v1/report/product",
                _callFields(),
                "SEARCH_PRODUCTREPORTTIME_SUCCESS",
                "SEARCH_PRODUCTREPORTTIME_FAILURE"
            )
        );
    };

    const _validateAndSearch = (type) => {
        if (_validateForm()) {
            _callFetch(type);
            _closeSearchDiv();
        } else {
            setFormError({
                formError: true,
                formErrorMsg: "Clear all the errors to continue",
            });
            window.scrollTo(0, 0);
        }
    };

    const _validateForm = () => {
        _clearErrorMessages();
        let valid = true;
        if (storeInfo.storeid.trim() === "") {
            setStoreiderror("Please select store id");
            valid = false;
        }
        if (overalldate == null) {
            setOveralldateerror("Please select date");
            valid = false;
        }
        return valid;
    };

    const _clearErrorMessages = () => {
        setFormError({
            formError: false,
            formErrorMsg: "",
        });
        setStoreiderror("");
        setOveralldateerror("");
    };

    const handleProductReport = () => {
        setButtonProdActive(!buttonProdActive);
        setButtonCatActive(false);
        setShowProdReport(true);
        setShowCategoryReport(false);
    };

    const handleCategoryReport = () => {
        setButtonProdActive(false);
        setButtonCatActive(!buttonCatActive);
        setShowProdReport(false);
        setShowCategoryReport(true);
    };

    const _updateSalesData = () => {
        const { salesReportData } = props.queryData;
        if (salesReportData.products && salesReportData.products.length > 0) {
            let byaddonlist = salesReportData.products.filter(
                (e) => e.addon === productaddon
            );
            let modifiedlistprod = createObjectProd(salesReportData);
            if (modifiedlistprod.length > 0) {
                setProdsalesdata({
                    data: byaddonlist,
                    list: modifiedlistprod,
                });
            }
        }
    };

    const createObjectProd = (obj) => {
        if (JSON.stringify(obj) !== "{}") {
            let results = [
                { id: "title", Header: "Products", accessor: "description" },
            ];
            for (let item of obj.products) {
                for (let opt in obj.weeks) {
                    // const columns = [{ col: 'desc', value: item.description }];
                    const columns = [];
                    for (let key in obj.times) {
                        // var ob1 = {[obj.times[key].starttime+"-"+obj.times[key].endtime]: item[opt][key].qty}
                        // Object.assign(details,ob1)
                        columns.push({
                            id:
                                key +
                                "_" +
                                `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` +
                                "_" +
                                item.productcode,
                            Header: `${obj.times[key].starttime}-${obj.times[key].endtime}`,
                            accessor: opt + "." + key + ".qty", //item[opt][key].qty
                        });
                    }
                    // var t1 =  {totalqty: item[opt].totalqty};
                    // var t2 =  {totalamount: item[opt].totalamount}
                    // details = {...details,...t1,...t2 }
                    results.push({
                        id: opt + "_" + item.productcode,
                        Header: `${
                            obj.weeks[opt].startdate +
                            " to " +
                            obj.weeks[opt].enddate
                        }`,
                        columns: columns.concat([
                            {
                                id:
                                    "totalqty_" +
                                    `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` +
                                    "_" +
                                    item.productcode,
                                Header: "Total Qty",
                                accessor: opt + ".totalqty", //item[opt].totalqty
                                // col: 'totalqty',
                                // value: item[opt].totalqty
                            },
                            {
                                id:
                                    "totalamount_" +
                                    `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` +
                                    "_" +
                                    item.productcode,
                                Header: "Total Amt",
                                accessor: opt + ".totalamount", //item[opt].totalamount
                                // col: 'totalamount',
                                // value: item[opt].totalamount
                            },
                        ]),
                    });
                }
            }
            let uniquelist = [
                ...new Map(
                    results.map((item) => [item["Header"], item])
                ).values(),
            ];
            return uniquelist;
        }
    };

    const createObjectCat = (obj) => {
        if (JSON.stringify(obj) !== "{}") {
            let results = [
                { id: "title", Header: "Categories", accessor: "description" },
            ];
            for (let item of obj.categories) {
                for (let opt in obj.weeks) {
                    // const columns = [{ col: 'desc', value: item.description }];
                    const columns = [];
                    for (let key in obj.times) {
                        // var ob1 = {[obj.times[key].starttime+"-"+obj.times[key].endtime]: item[opt][key].qty}
                        // Object.assign(details,ob1)
                        columns.push({
                            id:
                                key +
                                "_" +
                                `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` +
                                "_" +
                                item.categorycode,
                            Header: `${obj.times[key].starttime}-${obj.times[key].endtime}`,
                            accessor: opt + "." + key + ".qty", //item[opt][key].qty
                        });
                    }
                    // var t1 =  {totalqty: item[opt].totalqty};
                    // var t2 =  {totalamount: item[opt].totalamount}
                    // details = {...details,...t1,...t2 }
                    results.push({
                        id: opt + "_" + item.categorycode,
                        Header: `${
                            obj.weeks[opt].startdate +
                            " to " +
                            obj.weeks[opt].enddate
                        }`,
                        columns: columns.concat([
                            {
                                id:
                                    "totalqty_" +
                                    `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` +
                                    "_" +
                                    item.categorycode,
                                Header: "Total Qty",
                                accessor: opt + ".totalqty", //item[opt].totalqty
                                // col: 'totalqty',
                                // value: item[opt].totalqty
                            },
                            {
                                id:
                                    "totalamount_" +
                                    `${obj.weeks[opt].startdate} ${obj.weeks[opt].enddate}` +
                                    "_" +
                                    item.categorycode,
                                Header: "Total Amt",
                                accessor: opt + ".totalamount", //item[opt].totalamount
                                // col: 'totalamount',
                                // value: item[opt].totalamount
                            },
                        ]),
                    });
                }
            }
            let uniquelist = [
                ...new Map(
                    results.map((item) => [item["Header"], item])
                ).values(),
            ];
            return uniquelist;
        }
    };

    const _renderTable = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="btn-group" role="group">
                            <button
                                type="button"
                                style={{
                                    borderTopRightRadius: "0px",
                                    borderBottomRightRadius: "0px",
                                    whiteSpace: "nowrap",
                                }}
                                className={
                                    buttonProdActive
                                        ? "active btn btn-outline-primary"
                                        : "icon_btn display-inline"
                                }
                                onClick={handleProductReport}
                            >
                                Product Report
                            </button>
                            <button
                                type="button"
                                style={{
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    whiteSpace: "nowrap",
                                }}
                                className={
                                    buttonCatActive
                                        ? "active btn btn-outline-primary"
                                        : "icon_btn display-inline"
                                }
                                onClick={handleCategoryReport}
                            >
                                Category Report
                            </button>
                        </div>
                    </div>
                </div>

                <div className="tab-content" id="myTabContent">
                    {showProdReport && prodsalesdata.data.length > 0 ? (
                        <ReportTable
                            rowdata={prodsalesdata.data}
                            columndata={prodsalesdata.list}
                        ></ReportTable>
                    ) : (
                        <div></div>
                    )}
                    {showCategoryReport === true &&
                    catsalesdata.data.length > 0 ? (
                        <ReportTable
                            rowdata={catsalesdata.data}
                            columndata={catsalesdata.list}
                        ></ReportTable>
                    ) : (
                        <div></div>
                    )}
                </div>
            </React.Fragment>
        );
    };

    const _openSearchDiv = () => {
        setShowSearchDiv(true);
    };

    const _closeSearchDiv = () => {
        setShowSearchDiv(false);
    };

    const _renderSearchModal = () => {
        return (
            <Modal
                visible={showSearchDiv}
                width="70%"
                height="70%"
                effect="fadeInRight"
                onClickAway={_closeSearchDiv}
            >
                <div className="modalForm queryFormModal">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            Report Search Fields
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a onClick={_closeSearchDiv} className="closeIcon">
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {_renderSearchForm()}
                </div>
            </Modal>
        );
    };

    const _renderSearchBtn = () => {
        return (
            <button
                type="submit"
                className="page-header-btn icon_btn display-inline"
                onClick={(event) => {
                    if (event) event.preventDefault();
                    _openSearchDiv();
                }}
            >
                Advanced Filter
            </button>
        );
    };

    const hideErrorAlertModal = (e, refresh) => {
        e.preventDefault();
        props.dispatch(crudReset());
        props.dispatch(resetAction());
        // if (refresh === true) {
        //   this.callQueryApiForFetch("ONLOAD");
        // }
    };

    const getTableDataProd = (records, headers) => {
        if (typeof records !== "undefined" && records.length > 0) {
            const tablerow = records.map((e) => [
                e.description,
                e.categorycode,
                e.addon,
                e.totalqtybyproduct,
                e.totalamountbyproduct,
            ]);
            const colheader = headers.map((e) => ({
                label: e.text,
                key: e.dataField,
            }));
            tablerow.unshift(colheader.map((e) => e.label));
            return tablerow;
        }
    };

    const getTableDataCat = (records, headers) => {
        if (typeof records !== "undefined" && records.length > 0) {
            const tablerow = records.map((e) => [
                e.description,
                e.categorycode,
                e.totalqtybycategory,
                e.totalamountbycategory,
            ]);
            const colheader = headers.map((e) => ({
                label: e.text,
                key: e.dataField,
            }));
            tablerow.unshift(colheader.map((e) => e.label));
            return tablerow;
        }
    };

    const {
        servererror: serverError,
        errormsg: queryError,
        salesReportData,
    } = props.queryData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
        errormsg = serverError;
        refresh = false;
    }
    if (queryError) {
        errormsg = queryError;
        refresh = true;
    }
    return (
        <React.Fragment>
            {showSearchDiv && _renderSearchModal()}
            <div className="form_height">
                <div className="row">
                    <div className="col-md-9">
                        <h2 className="page-header">Sales Report</h2>
                    </div>
                    <div className="col-md-3 text-right noPadding noMargin">
                        {buttonProdActive === true ? (
                            <ExportButton
                                title="Sales Product Report"
                                tabledata={getTableDataProd(
                                    salesReportData.products,
                                    tableColsProd
                                )}
                            />
                        ) : (
                            <ExportButton
                                title="Sales Category Report"
                                tabledata={getTableDataCat(
                                    salesReportData.categories,
                                    tableColsCat
                                )}
                            />
                        )}
                    </div>
                </div>
                <div className="row form-container">
                    <div className="col">
                        <form className="frmDiv">
                            <div className="row">
                                <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                    <h3 className="page-title">Reports</h3>
                                </div>
                                <div className="col-lg-9 col-sm-12 noPadding noMargin alignRight">
                                    {_renderSearchBtn()}
                                </div>
                            </div>
                            <div className="title_devide" />
                            <div className="invalid-feedback">{errormsg}</div>
                            {_renderTable()}
                        </form>
                    </div>
                </div>
                <ConfirmAlert
                    show={errormsg}
                    handleClose={(event) => hideErrorAlertModal(event, refresh)}
                    children={
                        <div
                            style={
                                errormsg
                                    ? {
                                          padding: "2em",
                                          color: "red",
                                          fontWeight: "500",
                                      }
                                    : { padding: "2em", color: "red" }
                            }
                        >
                            {errormsg ? `${errormsg}.` : ""}
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};
SalesProductReport.propTypes = {};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        responses: state.crudReducer,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(SalesProductReport);
