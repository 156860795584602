import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { searchGeneral } from '../../actions/actions';
import { formatDate, permissions } from '../../helpers/Util';
import * as CONSTANTS from '../../constants/constants';
import ViewCartSyncReport from './ViewCartSyncReport';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import CartSyncReportFilter from './CartSyncReportFilter';
import { FilterMatchMode } from 'primereact/api';

const CartSyncReport = (props) => {
    const { cartSyncList } = props.queryData;
    const [first, setFirst] = useState(0);
    const [tableRows, setRows] = useState(50);
    const [pageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [showMessageDetail, setShowMessageDetail] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const date_from = new Date();
    const date_till = new Date()
    const [filterData, setFilterData] = useState({
        storeid,
        date_from,
        date_till,
    });

    const [cartSyncData, setCartSyncData] = useState([]);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    useEffect(() => {
        if (storeid) {
            createFetchUrl(getRequestBody());
        }
    }, [storeid]);

    useEffect(() => {
        if (cartSyncList.length > 0) {
            getCartSyncFields();
        }
        if (cartSyncList.length == 0) {
            setCartSyncData([]);
        }
    }, [cartSyncList]);

    const getCartSyncFields = () => {
        const serviceFieldList = cartSyncList.map((cartsync) => ({
            ...cartsync,
            entbrokerfilestatus:
                cartsync.entbrokerfilestatus == ''
                    ? 'pending'
                    : cartsync.entbrokerfilestatus,
            brokerstatus:
                cartsync.brokerstatus == '' ? 'pending' : cartsync.brokerstatus,
        }));
        setCartSyncData(serviceFieldList);
    };

    const createFetchUrl = (params) => {
        props.dispatch(
            searchGeneral(
                'pos/v1/cart/sync/search/fields',
                params,
                CONSTANTS.SEARCH_CART_SYNC_SUCCESS,
                CONSTANTS.SEARCH_CART_SYNC_FALIURE
            )
        );
    };

    const getRequestBody = () => {
        const requestBody = {
            body: {
                storeid,
                date_from: formatDate(date_from),
                date_till: formatDate(date_till),
                pagesize: pageSize,
                pageno: currentPage,
            },
        };
        return requestBody;
    };

    const renderPaginationTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span
                        className="mx-1"
                        style={{
                            color: 'var(--text-color)',
                            userSelect: 'none',
                        }}
                    >
                        Items per page:{' '}
                    </span>
                    <Dropdown
                        value={pageSize}
                        options={dropdownOptions}
                        onChange={(e) => handlePageSizeChange(e)}
                    />
                </React.Fragment>
            );
        },
        CurrentPageReport: () => {
            return (
                <div>
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled': currentPage == 1,
                        })}
                        disabled={currentPage == 1}
                        aria-label="Previous Page"
                        onClick={handlePrevPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                    <button
                        type="button"
                        className={classNames({
                            'p-paginator-prev p-paginator-element p-link': true,
                            'p-disabled':
                                cartSyncList && cartSyncList.length < pageSize,
                        })}
                        aria-label="Next Page"
                        onClick={handleNextPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-right"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                </div>
            );
        },
    };

    const handlePageSizeChange = (e) => {
        e.preventDefault();
        setPageSize(e.target.value);
        setCurrentPage(1);
        setRows(e.target.value);
        createFetchUrl(getParsedValue(e.target.value, currentPage));
    };

    const getParsedValue = (pageSize, pageNo) => {
        const parsedValues = {
            ...filterData,
            date_from: filterData.date_from
                ? moment(filterData.date_from).format('DD-MM-YYYY')
                : '',
            date_till: filterData.date_till
                ? moment(filterData.date_till).format('DD-MM-YYYY')
                : '',
        };
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: pageNo,
            },
        };
        return requestBody;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        createFetchUrl(getParsedValue(pageSize, currentPage + 1));
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        createFetchUrl(getParsedValue(pageSize, currentPage - 1));
    };

    const handleSearch = (data) => {
        setCurrentPage(1)
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
        setFilterData(data); //set search data into filter data
        const parsedValues = {
            ...data,
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        const requestBody = {
            body: {
                ...parsedValues,
                pagesize: pageSize,
                pageno: 1,
            },
        };
        createFetchUrl(requestBody);
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex">
                <img
                    title="View"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    onClick={() => viewMessageDetails(rowData)}
                />
            </div>
        );
    };

    const viewMessageDetails = (rowData) => {
        setShowMessageDetail(true);
        setSelectedData(rowData);
    };

    const hideLogDetails = () => {
        setShowMessageDetail(false);
        setSelectedData({});
    };

    const renderSearchHeader = () => {
        return (
            <div
                className="flex"
                style={{ justifyContent: 'flex-end', marginBottom: '20px' }}
            >
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Search"
                        />
                    </span>
                </div>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const columns = [
        { field: 'orderid', header: 'Order Id' },
        { field: 'storeid', header: 'Store Id' },
        { field: 'terminalid', header: 'Terminal Id' },
        { field: 'trxdate', header: 'Trx Date' },
        { field: 'trxtype', header: 'Trx Type' },
        { field: 'trxno', header: 'Trx No' },
        { field: 'topicstatus', header: 'Topic Status' },
        { field: 'status', header: 'Status' },
        { field: 'topicmsgid', header: 'Topic Msg Id' },
        { field: 'synctype', header: 'Sync Type' },
        { field: 'brokerstatus', header: 'Broker Status' },
        { field: 'entsyncedstatus', header: 'Synced Status' },
        { field: 'entsalescartstatus', header: 'Sales Cart Status' },
        { field: 'entposmasterstatus', header: 'Pos Master Status' },
        { field: 'entbrokerfilestatus', header: 'Broker File Status' },
    ];

    const sortableColumn = [
        'storeid',
        'terminalid',
        'orderid',
        'trxdate',
        'topicmsgid',
    ];

    const styleColumnList = [
        'status',
        'topicstatus',
        'brokerstatus',
        'entsyncedstatus',
        'entsalescartstatus',
        'entposmasterstatus',
        'entbrokerfilestatus',
    ];

    const getStyleClassName = (fieldValue) =>
        fieldValue === 'pending'
            ? 'c-warning status-badge'
            : fieldValue === 'error'
            ? 'c-error status-badge'
            : '';

    const renderColumnTemplate = (record, field) => {
        return (
            <span
                className={
                    styleColumnList.includes(field)
                        ? getStyleClassName(record[field])
                        : ''
                }
            >
                {record[field]}
            </span>
        );
    };

    return (
        <>
            {showMessageDetail ? (
                <ViewCartSyncReport
                    hideLogDetails={hideLogDetails}
                    record={selectedData}
                />
            ) : (
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">Cart Sync Report</h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <CartSyncReportFilter
                                    handleSearch={handleSearch}
                                />
                                <div className="row mt-4">
                                    <div className="col-lg-12 col-sm-12">
                                        {renderSearchHeader()}
                                        <DataTable
                                            value={cartSyncData}
                                            showGridlines
                                            columnResizeMode="fit"
                                            responsiveLayout="scroll"
                                            paginatorClassName="justify-content-end"
                                            paginatorTemplate={
                                                renderPaginationTemplate
                                            }
                                            paginator
                                            first={first}
                                            rows={tableRows}
                                            onPage={onCustomPage}
                                            emptyMessage="Your search does not retrieve any data. Please search again using another criteria."
                                            filters={filters}
                                            globalFilterFields={[
                                                'storeid',
                                                'terminalid',
                                                'trxtype',
                                                'topicstatus',
                                                'status',
                                                'topicmsgid',
                                                'synctype',
                                                'entbrokerfilestatus',
                                                'entposmasterstatus',
                                                'entsalescartstatus',
                                                'entsyncedstatus',
                                            ]}
                                        >
                                            {columns.map((column) => (
                                                <Column
                                                    key={column.field}
                                                    field={column.field}
                                                    header={column.header}
                                                    sortable={sortableColumn.includes(
                                                        column.field
                                                    )}
                                                    body={(rowData) =>
                                                        renderColumnTemplate(
                                                            rowData,
                                                            column.field
                                                        )
                                                    }
                                                />
                                            ))}
                                            <Column
                                                header="Actions"
                                                body={actionTemplate}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(CartSyncReport);
