import React from "react";
import LineChart from "../../Charts/LineChart";
import { connect } from "react-redux";
import FixedColTable from "../../../components/FixedColTable";
import { headers, rows } from "./tableCol";
import BarChart from "./../../Charts/BarChart";
import DoughnutChart from "./../../Charts/DoughnutChart";

function getData() {
  let data = [];

  return data;
}
class SurveyAnalytics extends React.PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: getData()
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.setInterval(() => {
      this.setState({
        data: getData()
      });
    }, 5000);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  _renderSurveyAnalyticsSelect = (
    formattedObjArray,
    selectedOption,
    dropDownChange
  ) => {
    return (
      <select
        id="statusComp"
        className={`form-control chartSelect col-lg-2 col-sm-12 alignRight `}
        value={selectedOption}
        onChange={dropDownChange}
      >
        {formattedObjArray != undefined ? (
          formattedObjArray.map((t, i) => (
            <option key={i} value={t.id}>
              {t.name}
            </option>
          ))
        ) : (
          <option>No option</option>
        )}
      </select>
    );
  };
  barchartChange = value => {
    return console.log(value);
  };
  _renderQuickViewDiv = () => {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12 ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_survey.svg`}
            />
            <p className="card2Text1">Today Survey</p>
            <p className="card2Text2">2</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_responses.svg`}
            />
            <p className="card2Text1">Today Responses</p>
            <p className="card2Text2">50</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_survey01.svg`}
            />
            <p className="card2Text1">Survey Active</p>
            <p className="card2Text2">1</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_draft.svg`}
            />
            <p className="card2Text1">Draft</p>
            <p className="card2Text2">3</p>
          </div>
        </div>
      </div>
    );
  };

  _renderChartDiv = () => {
    return (
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-10 col-sm-12 alignLeft">
                Responses Volume
              </div>

              {this._renderSurveyAnalyticsSelect(
                [
                  { id: "sa", name: "Survey A" },
                  { id: "sb", name: "Survey B" }
                ],
                "sa",
                this.barchartChange
              )}
            </div>
            <div className="title_devide"></div>
            <BarChart
              data={[20, 10, 50, 0, 0, 80, 15, 2, 10, 10, 11, 2]}
              labels={[
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC"
              ]}
              color="#55B979"
            />
          </div>
        </div>
      </div>
    );
  };
  _renderQuestDiv = () => {
    return (
      <ul id="menu" className="noPadding noMargin">
        <li className="questDiv questionlist">Question</li>
        <li className="questDiv selectedquest">Q1</li>
        <li className="questDiv">Q2</li>
        <li className="questDiv">Q3</li>
        <li className="questDiv">Q4</li>
        <li className="questDiv">Q5</li>
        <li className="questDiv">Q6</li>
        <li className="questDiv">Q7</li>
        <li className="questDiv">Q8</li>
      </ul>
    );
  };
  _renderChartDiv2 = () => {
    return (
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-10 col-sm-12 alignLeft">
                Survey Summaries
              </div>

              {this._renderSurveyAnalyticsSelect(
                [
                  { id: "sa", name: "Survey A" },
                  { id: "sb", name: "Survey B" }
                ],
                "sa",
                this.doughnutchartchange
              )}
            </div>

            <div className="title_devide"></div>
            <div className="row noPadding noMargin">
              {this._renderQuestDiv()}
            </div>
            <div className="row noPadding noMargin showQuest">
              What is your top #1 favourite store in Singapore?
            </div>
            <DoughnutChart
              labels={["Yes", "No"]}
              colors={["#4769B0", "#56BA7A"]}
              data={[60, 40]}
            />
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="chartLeft chartDiv" style={{ height: "509px" }}>
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-10 col-sm-12 alignLeft">
                Survey Summaries
              </div>

              {this._renderSurveyAnalyticsSelect(
                [
                  { id: "sa", name: "Survey A" },
                  { id: "sb", name: "Survey B" }
                ],
                "sa",
                this.tableonchange
              )}
            </div>

            <div className="title_devide"></div>
            <div className="row noPadding noMargin">
              {this._renderQuestDiv()}
            </div>
            <div className="row noPadding noMargin showQuest">
              What is your top #1 favourite store in Singapore?
            </div>
            <div className="title_devide"></div>
            <div className="row noPadding noMargin subText">
              {`Answered:11       Skipped:0`}
            </div>
            <div className="title_devide"></div>
            <div className="row noPadding noMargin">
              <div className="col-lg-6 col-sm-12 alignLeft">ANSWER CHOICES</div>
              <div className="col-lg-6 col-sm-12 alignLeft">RESPONSES</div>
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-6 col-sm-12 alignLeft">Yes</div>
              <div className="col-lg-3 col-sm-12 alignLeft">69%</div>
              <div className="col-lg-3 col-sm-12 alignLeft">7</div>
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-6 col-sm-12 alignLeft">No</div>
              <div className="col-lg-3 col-sm-12 alignLeft">31%</div>
              <div className="col-lg-3 col-sm-12 alignLeft">3</div>
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-6 col-sm-12 alignLeft">Total</div>
              <div className="col-lg-3 col-sm-12 alignLeft"></div>
              <div className="col-lg-3 col-sm-12 alignLeft">10</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  _renderForm = () => {
    return (
      <form>
        {this._renderQuickViewDiv()}
        {this._renderChartDiv()}
        {this._renderChartDiv2()}
      </form>
    );
  };
  render() {
    return (
      <div className="form_height">
        <div className="row form-container">
          <div className="col">{this._renderForm()}</div>
        </div>
      </div>
    );
  }
}

SurveyAnalytics.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(SurveyAnalytics);
