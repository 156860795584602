import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createItem, updateItem, crudReset } from "./../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import ImagePick from "./../../../components/ImagePick";
import StatusOptionComp from "./../../../components/StatusOptionComp";
import { renderCouponOptionsFilter, convertStrNum, objtoarr1, catTypeOptions } from "./../../../helpers/Util";
import Filter from "./../../../components/Filter";
import DynamicTableImage from "../../../components/DynamicTableImage";
import DynamicTable from "../../../components/DynamicTable";


class AddProdCategory extends React.Component {
  statusprod = [
    { name: "Select", id: "" },
    { name: "New", id: "New" },
    { name: "Active", id: "Active" },
    { name: "Updated", id: "Updated" },
    { name: "Deleted", id: "Deleted" }
  ]
  // deliveryperiod = [
  //   { title: "Minute", value: "MI"},
  //   { title: "Hours", value: "HH"},
  //   { title: "Day", value: "DD"}
  // ]
  cattypeoptions = catTypeOptions

  defaultimageField =  [{ uri: "", title: "", alttext: "", width: "", height: "", tag: "", sortorder: 0, id: 0}];

  constructor(props) {
    super(props);

      console.log("XXXX");
    this.state = {
      id: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      title: "",
      categorycode: "",
      description: "",
      pid: "",
      sortorder: "",
      msgcode: "",
      images: {},
      titleerror: "",
      descerror: "",
      status: "Active",
      cattype: "static",
      cattypevalue:"",
      // deliveryperiod: "",
      // deliveryperiodvalue: "",
      // deliverynearby:"",
      // bulkyitem:"",
      additionalFields: [
        { name: "", value: "", id: 0 }
      ],
      imagesgall: [...this.defaultimageField],
      nxtImgCounter: this.defaultimageField.length + 1
    };
  }
  setImages = images => {
    this.setState({ images: images });
  };
  getCreatePayload = () => {
    const {
      title,
      catcode,
      description,
      pid,
      sortorder,
      msgcode,
      id,
      images,
      status,
      additionalFields,
      categorycode,
      // deliveryperiodvalue,
      // deliveryperiod,
      // deliverynearby,
      // bulkyitem,
      imagesgall,
      cattype,
      cattypevalue
    } = this.state;
    let imagefield = {};
    let imagefieldCounter = 1;
    imagesgall.forEach((obj, idx) => {
      if(obj.uri !== "") {
        const newObj = Object.assign({}, obj)
        if (typeof newObj.sortorder === 'undefined' || newObj.sortorder === "") {
          newObj['sortorder'] = 0;
        }
        else {
          try {
            newObj['sortorder'] = Number(newObj['sortorder']);
          }
          catch(e) {
            newObj['sortorder'] = 0
          }
        }
        imagefield[`image${imagefieldCounter++}`] = newObj
      }
    });
    let addfield = additionalFields.reduce(function (acc, item) {
      if(item.name.trim() !== "" && item.value.trim() !== ""){
          acc[item.name] = item.value
          return acc
        }
          return acc
    }, {})
    let body = {
      //categorycode: catcode,
      title: title,
      description: description,
      pid: pid,
      sortorder,
      msgcode,
      status,
      cattype,
      cattypevalue,
      additionalfields: addfield,
      imagegallery: imagefield,
      imageurl: images.image1
    };
    if (this._isEditMode()) {
      body["id"] = id;
      body["categorycode"] = categorycode
    }
    const payload = {
      body: body
    };
   // console.log(payload);
    return payload;
  };

  _convertToImageGallery = (imageGalleryArray) => {
    if(typeof imageGalleryArray === 'undefined' || imageGalleryArray === null) {
      return []
    }

    const newImageGalleryArray = [];

    for(let loop=0; loop<imageGalleryArray.length; loop++) {
      const _imageGalleryArray = Object.assign({}, imageGalleryArray[loop]);
      _imageGalleryArray.id = loop;
      newImageGalleryArray.push(_imageGalleryArray)
    }
    return newImageGalleryArray;
  }

  componentWillMount = () => {
    console.log("XXXX");
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isAdd = this.props.location.state.isAdd;
      if (rec) {
        const {
          id,
          categorycode,
          title,
          description,
          pid,
          sortorder,
          msgcode,
          imageurl,
          status,
          additionalfields,
          imagegallery,
          cattype,
          cattypevalue
        } = rec;
        let defaultimageField = [...this.defaultimageField];
        let imagearr = this._convertToImageGallery(convertStrNum(imagegallery));
        let defaultaddField =  {name: ""};

        if (isEdit === true) {
          this.setState(
            produce(draft => {
              draft.id = id ? id : "";
              draft.isEdit = isEdit;
              draft.title = title ? title : "";
              draft.categorycode = categorycode ? categorycode : "";
              draft.description = description ? description : "";
              draft.status = status ? status : "";
              draft.pid = pid ? pid : 0;
              draft.sortorder = sortorder ? sortorder : 1;
              draft.msgcode = msgcode ? msgcode : "";
              draft.cattype = rec.hasOwnProperty("cattype")  ? cattype : "";
              draft.cattypevalue = rec.hasOwnProperty("cattypevalue") ? cattypevalue : "";
              // draft.deliveryperiod = rec.hasOwnProperty("additionalfields") && additionalfields.hasOwnProperty("deliveryperiod") ? additionalfields.deliveryperiod : "";
              // draft.deliveryperiodvalue = rec.hasOwnProperty("additionalfields") && additionalfields.hasOwnProperty("deliveryperiodvalue") ? additionalfields.deliveryperiodvalue : "";
              // draft.deliverynearby = rec.hasOwnProperty("additionalfields") && additionalfields.hasOwnProperty("deliverynearby") ? additionalfields.deliverynearby : "";
              // draft.bulkyitem = rec.hasOwnProperty("additionalfields") && additionalfields.hasOwnProperty("bulkyitem") ? additionalfields.bulkyitem : "";
              draft.images = {
                image1: imageurl || ""
              };
              draft.imagesgall = typeof imagearr !== "undefined" &&
                imagearr.length !== 0
                ? imagearr :
                defaultimageField;
              draft.nxtImgCounter = typeof imagearr !== "undefined" &&
                imagearr.length !== 0
                ? imagearr[imagearr.length -1].id + 1 :
                1;
              draft.additionalFields = typeof additionalfields !== "undefined" &&
                Object.keys(additionalfields).length !== 0
                ? objtoarr1(additionalfields) :
                objtoarr1(defaultaddField);
            })
          );
        }
        if (isAdd === true) {
          this.setState(
            produce(draft => {
              draft.pid = id ? id : 0;
            })
          );
        }
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _createProductCat = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateItem(
            "sales/v1/category/update",
            this.getCreatePayload(),
            this.state.id
          )
        );
      } else {
        this.props.dispatch(
          createItem("sales/v1/category/save", this.getCreatePayload())
        );
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      titleerror: "",
      descerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { title } = this.state;
    let valid = true;
    let regextitle = /[^\s&_-\w]/;
    let checktitle = !regextitle.test(title);
    if (checktitle === false || title.trim() === "") {
      this.setState({
        titleerror: "Enter a title (Allowed special characters -_&)"
      });
      valid = false;
    }
    // if (this._isEditMode() !== true) {
    //   if (categorycode.trim() === "") {
    //     this.setState({
    //       categorycodeerror: "Enter a category code"
    //     });
    //     valid = false;
    //   }
    // }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };


  handleAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.additionalFields.length;
    let lastele = this.state.additionalFields[rowLength - 1];
    const item = {
        name: "",
        value: "",
        id: lastele.id + 1
    };
    this.setState(
        {
            additionalFields: [...this.state.additionalFields, item]
        },
        () => {
            console.log("Rows After Add", this.state.additionalFields);
        }
    );
};

handleInputChange = (event, dataField, row) => {
    let obj = {
        id: row.id,
        [dataField]:
            dataField === "validation" ? [event.target.value] : event.target.value
    };
    this.setState(
        produce(draft => {
            if (draft.additionalFields.length === 0) {
                draft.additionalFields.push(obj);
            } else {
                let found = false;
                draft.additionalFields.forEach(command => {
                    if (command.id === obj.id) {
                        Object.assign(command, obj);
                        found = true;
                    }
                });
                if (!found) {
                    draft.additionalFields.push(obj);
                }
            }
        }),
        () => { }
    );
};

deleteRow = row => {
    let rowId = row.id;
    const rowLength = this.state.additionalFields.length;

    if (rowLength > 1) {
        const updateValues = this.state.additionalFields.filter(
            value => rowId !== value.id
        );

        this.setState({ additionalFields: updateValues });
    }
  };

  _renderAdditionalFields = () => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="form-group col-lg-12 col-sm-12 label2">
                    <label htmlFor="additionalfields" className="floatLeft">
                        Additional Fields
                    </label>
                </div>
                <div className="form-group col-lg-12 col-sm-12">
                    <DynamicTable
                        columns={[
                            { dataFieldId: "name", label: "Name", addRow: true },
                            { dataFieldId: "value", label: "Value", addRow: true },
                            { dataFieldId: "AD", label: "" }
                        ]}
                        rows={this.state.additionalFields}
                        deleteRow={this.deleteRow}
                        addRow={this.handleAddRow}
                        handleInputChange={this.handleInputChange}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createProductCat()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createProductCat()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/product-category"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  _renderImageGallery = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Image Gallery </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <DynamicTableImage
              columns={[
                { dataFieldId: "uri", label: "uri", addRow: true },
                { dataFieldId: "title", label: "Title", addRow: true },
                { dataFieldId: "alttext", label: "Alternate Text", addRow: true },
                { dataFieldId: "width", label: "Width", addRow: true },
                { dataFieldId: "height", label: "Height", addRow: true },
                { dataFieldId: "tag", label: "Tag", addRow: true },
                { dataFieldId: "sortorder", label: "Order", addRow: true },
                {
                  dataFieldId: "AD",
                  label: ""
                }
              ]}
              rows={this.state.imagesgall}
              deleteRow={this.deleteImageRow}
              addRow={this.handleImageAddRow}
              handleImageInputChange={this.handleImageInputChange}
              mediaImageList={this.props.queryData.mediaList}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderAdditionalFields()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 noPadding noMargin">
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderImageGallery()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  handleImageAddRow = e => {
    if (e) e.preventDefault();

    const item = {
      uri: "",
      title: "",
      alttext: "",
      width: "",
      height: "",
      tag: "",
      sortorder: 0,
      id: parseInt(this.state.nxtImgCounter)
    };
    this.setState(
      produce(draft => {
        draft.nxtImgCounter = this.state.nxtImgCounter + 1;
        draft.imagesgall= [...this.state.imagesgall, item];
      }),
      () => {
          console.log("Rows After Add", this.state.imagesgall);
      }
    );
  };


  handleImageInputChange = (event, dataField, row) => {
    let obj = {
      id: row.id,
      [dataField]:
        dataField === "validation" ? [event.target.value] :
          dataField === "uri" ? event :
            dataField === "width" ? event :
              dataField === "height" ? event :
                event.target.value
    };
    this.setState(
      produce(draft => {
        if (draft.imagesgall.length === 0) {
          draft.imagesgall.push(obj);
        } else {
          let found = false;
          draft.imagesgall.forEach(command => {
            if (command.id === obj.id) {
              Object.assign(command, obj);
              found = true;
            }
          });
          if (!found) {
            draft.imagesgall.push(obj);
          }
        }
      }),
      () => { }
    );
  };
  deleteImageRow = row => {
    let rowId = row.id;
    const updateValues = this.state.imagesgall.filter(
      value => rowId !== value.id
    );

    this.setState(
      produce(draft => {
        draft.imagesgall=updateValues;
      })
    );
  };


  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row sub-title">Actions</div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Sortorder
            </label>
            <input
              type="number"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event => {
                this.setState({ sortorder: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusOptionComp
              statusoption={this.statusprod}
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  _renderMainFormDetails = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="categorycode" className="floatLeft">
              Category Code
            </label>
            <input
              type="text"
              className="form-control"
              id="categorycode"
              value={this.state.categorycode}
              readOnly={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="description" className="floatLeft">
              Description
            </label>
            <textarea
              className="form-control"
              id="description"
              value={this.state.description}
              onChange={event => {
                this.setState({ description: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.descerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="pid" className="floatLeft">
              Parent Id
            </label>
            <input
              type="text"
              className="form-control"
              id="pid"
              value={this.state.pid}
              onChange={event => {
                this.setState({ pid: event.target.value });
              }}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="msgcode" className="floatLeft">
              Message Code
            </label>
            <input
              type="text"
              className="form-control"
              id="msgcode"
              value={this.state.msgcode}
              onChange={event => {
                this.setState({ msgcode: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="cattype" className="floatLeft">
              Category Type
            </label>
            <Filter
              cssClass="form-control"
              filterOptions={renderCouponOptionsFilter(this.cattypeoptions)}
              selectedOption={this.state.cattype}
              callbackChangeFilter={event => {
                this.setState({ cattype: event.target.value })
              }}
              id="cattype"
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="cattypevalue" className="floatLeft">
              Category Type Value
            </label>
            <input
              type="text"
              className="form-control"
              id="cattypevalue"
              value={this.state.cattypevalue}
              onChange={event =>
                this.setState({ cattypevalue: event.target.value })
              }
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="deliveryperiod" className="floatLeft">
              Delivery Period
            </label>
            <Filter
              cssClass="form-control"
              filterOptions={renderCouponOptionsFilter(this.deliveryperiod)}
              selectedOption={this.state.deliveryperiod}
              callbackChangeFilter={event => {
                this.setState({ deliveryperiod: event.target.value })
              }}
              id="deliveryperiod"
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="deliveryperiodvalue" className="floatLeft">
              Delivery Period Value
                        </label>
            <input
              type="text"
              className="form-control"
              id="deliveryperiodvalue"
              value={this.state.deliveryperiodvalue}
              onChange={event =>
                this.setState({ deliveryperiodvalue: parseInt(event.target.value) })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-12 col-lg-6">
            <label htmlFor="deliverynearby " className="floatLeft">
              Delivery NearBy
                    </label>
            <SimpleSelect
              objArray={{ Y: "Yes", N: "No" }}
              callbackChangeFilter={value => this.setState({ deliverynearby: value })}
              selectedOption={this.state.deliverynearby}
              id="deliverynearby"
            />
          </div>
          <div className="form-group col-sm-12 col-lg-6">
            <label htmlFor="bulkyitem" className="floatLeft">
              Bulky Item
                    </label>
            <SimpleSelect
              objArray={{ Y: "Yes", N: "No" }}
              callbackChangeFilter={value => this.setState({ bulkyitem: value })}
              selectedOption={this.state.bulkyitem}
              id="bulkyitem"
            />
          </div>
        </div> */}

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="level" className="floatLeft">
              Image
            </label>
          </div>
          <div className="form-group col-lg-12 col-sm-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={1}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Edit Product Category"
                  : "Add Product Category"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/product-category"
            children={
              <div className="alert alert-success">
                {this._isEditMode() === true
                  ? "Product Category updated successfully"
                  : "Product Category created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddProdCategory);
