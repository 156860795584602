import React from 'react';
import DatePicker from 'react-date-picker';
import { produce } from 'immer';
import {
    searchGeneral,
    resetErrorOnly,
} from './../../../actions/actions';
import {
    canView,
    formatDate,
    permissions
} from '../../../helpers/Util';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmAlert from '../../../components/ConfirmAlert';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';
import PrimeTable from '../../../components/PrimeTable';

const status = [
    { name: 'Select', id: '' },
    { name: 'New', id: 'new' },
    { name: 'Sales', id: 'sales' },
    { name: 'Void', id: 'void' },
    { name: 'Close', id: 'close' },
    { name: 'Suspend', id: 'suspend' },
    { name: 'Cancel', id: 'cancel' },
];

const trainingModeOptions = [
    { name: 'Select', id: '' },
    { name: 'Yes', id: 'Y' },
    { name: 'No', id: 'N' },
];

class DisplayCarts extends React.PureComponent {
    companyId = localStorage.getItem('company_id');
    group = 'DE_SAL_SALES_ORDER';
    module = 'SALES';
    allPermissions = [];

    constructor(props) {
        super(props);
        this.state = {
            s_status: '',
            traintrx: '',
            s_posorderstatus: '',
            s_memberid: '',
            s_posorderid: '',
            s_trxno: '',
            s_invoiceno: '',
            s_orderid: '',
            s_toDate: new Date(),
            s_fromDate: new Date(),
            s_storeid: '',
            s_terminalid: '',
            s_storelabel: '',
            pages: 10,
            currPage: 1,
            fromDate: new Date(),
            toDate: new Date(),
            order_id: '',
            session_id: '',
            search_field: 'storeid',
            search_condi: 'like',
            search_value: '',
            cart_id: '',
            date_type: 'trxdate',
            showSearchDiv: false,
            searchbtn: false,
            searchfieldbtn: false,
            orderid: '',
            posorderid: '',
            storeid: '',
            terminalid: '',
            trxno: '',
            invoiceno: '',
            storelabel: '',
            memberid: '',
            cartdata: [],
            posorderstatus: '',
            status: '',
            usercode: '',
            storeiderror: '',
        };
    }

    componentDidUpdate = (prevProps) => {
        const { cartList } = this.props.queryData;
        const { cartList: prevcartList } = prevProps.queryData;
        if (cartList !== prevcartList) {
            if (cartList.length && cartList.length > 0) {
                this.setState({
                    cartdata: cartList,
                });
            } else {
                this.setState({
                    cartdata: [],
                });
            }
        }
    };

    actionTemplate = (row) => {
        const {
            fromDate,
            toDate,
            search_condi,
            date_type,
            storeid,
            storelabel,
            memberid,
            orderid,
            posorderid,
            status,
            posorderstatus,
            searchbtn,
            searchfieldbtn,
        } = this.state;

        if (canView(this.allPermissions)) {
            return (
                <span>
                    <Link
                        to={{
                            pathname: '/ui/view-cart',
                            state: {
                                item: row,
                                obj: {
                                    fromDate,
                                    toDate,
                                    search_condi,
                                    date_type,
                                    storeid,
                                    storelabel,
                                    memberid,
                                    posorderid,
                                    orderid,
                                    posorderstatus,
                                    status,
                                    searchfieldbtn,
                                    searchbtn,
                                },
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            alt="View"
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                </span>
            );
        }
    };

    toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const {
            pages,
            currPage,
            fromDate,
            toDate,
            orderid,
            terminalid,
            trxno,
            invoiceno,
            memberid,
            storeid,
            traintrx,
            status,
        } = this.state;
        let requestBody = {};
        if (fetchType === 'SEARCH_FIELDS') {
            requestBody = {
                body: {
                    date_from: formatDate(fromDate),
                    date_till: formatDate(toDate),
                    orderid,
                    trxno,
                    terminalid,
                    invoiceno,
                    memberid,
                    // posorderstatus,
                    status,
                    traintrx,
                    storeid: storeid === null ? '' : storeid,
                    pagesize: pages,
                    pageno: currPage,
                },
            };
        }
        return requestBody;
    };

    callFetch = (fetchType) => {
        if (fetchType === 'SEARCH_FIELDS') {
            this.props.dispatch(
                searchGeneral(
                    '/pos/v1/cart/search/fields',
                    this.createFetchUrl(fetchType),
                    'CART_SEARCH_SUCCESS',
                    'CART_SEARCH_FAILURE'
                )
            );
        } else {
            this.props.dispatch(
                searchGeneral(
                    'pos/v1/cart/search',
                    this.createFetchUrl(fetchType),
                    'CART_SEARCH_SUCCESS',
                    'CART_SEARCH_FAILURE'
                )
            );
        }
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('SEARCH_FIELDS');
                break;

            case 'SEARCH_FIELDS':
                this.setState(
                    produce(this.state, (draft) => {
                        draft.currPage = 1;
                    }),
                    () => {
                        this.callFetch('SEARCH_FIELDS');
                    }
                );
                break;

            case 'PAGECLICK':
                this.callFetch('SEARCH_FIELDS');
                break;
            default:
        }
    };

    componentWillMount() {
        const fieldval = this.props.location.state;
        this.setState({
            storeid: localStorage.getItem('storeid'),
            s_storeid: localStorage.getItem('storeid'),
            s_storelabel: localStorage.getItem('storelabel'),
        });

        if (typeof fieldval !== 'undefined') {
            this.setState(
                {
                    s_status: fieldval.fields.status,
                    traintrx: fieldval.fields.traintrx,
                    s_posorderstatus: fieldval.fields.posorderstatus,
                    s_memberid: fieldval.fields.memberid,
                    s_posorderid: fieldval.fields.posorderid,
                    s_orderid: fieldval.fields.orderid,
                    s_toDate: fieldval.fields.toDate,
                    s_fromDate: fieldval.fields.fromDate,
                    s_storeid: fieldval.fields.storeid,
                    s_storelabel: fieldval.fields.storelabel,
                    fromDate: fieldval.fields.fromDate,
                    toDate: fieldval.fields.toDate,
                    storeid: fieldval.fields.storeid,
                    storelabel: fieldval.fields.storelabel,
                    date_type: fieldval.fields.date_type,
                    memberid: fieldval.fields.memberid,
                    posorderid: fieldval.fields.posorderid,
                    orderid: fieldval.fields.orderid,
                    status: fieldval.fields.status,
                    posorderstatus: fieldval.fields.posorderstatus,
                },
                () => {
                    this.callQueryApiForFetch('SEARCH_FIELDS');
                }
            );
        } else {
            const defLanguage = this.props.loginData.language;
            this.setState({ language: defLanguage }, () =>
                this.callQueryApiForFetch('ONLOAD')
            );
        }

        this.allPermissions = permissions(this.module, this.group);
    }

    _setTenant = (p1, p2) => {
        const storeLabel = p2.substring(
            p2.indexOf('-1') + p1.length + 4,
            p2.length
        );
        this.setState(
            { s_storeid: p1, s_storelabel: storeLabel, search_value: p1 },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', storeLabel);
            }
        );
        this.setState({ storeiderror: '' });
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();

        this.props.dispatch(resetErrorOnly());
    };

    getTableData = (records, headers) => {
        const _putAsArray = (data, headers) => {
            return headers
                .filter((e) => e.dataField !== '')
                .map((e) => data[e.dataField]);
        };

        if (records && records.length > 0) {
            const tablerow = records.map((e) => _putAsArray(e, headers));
            const colheader = headers
                .filter((e) => e.text !== 'Actions')
                .map((e) => ({ label: e.text, key: e.dataField }));
            tablerow.unshift(colheader.map((e) => e.label));
            return tablerow;
        } else {
            return [];
        }
    };

    _validateAndSearch = (event) => {
        if (event) event.preventDefault();
        if (this.state.s_storeid === '' || this.state.s_storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return;
        } else {
            this.setState({ storeiderror: '' });
        }

        this.setState(
            produce(this.state, (draft) => {
                draft.status = this.state.s_status;
                draft.traintrx = this.state.traintrx;
                draft.memberid = this.state.s_memberid;
                draft.terminalid = this.state.s_terminalid;
                draft.trxno = this.state.s_trxno;
                draft.invoiceno = this.state.s_invoiceno;
                draft.orderid = this.state.s_orderid;
                draft.toDate = this.state.s_toDate;
                draft.fromDate = this.state.s_fromDate;
                draft.storeid = this.state.s_storeid;
                draft.storelabel = this.state.s_storelabel;
            }),
            () => {
                this.callQueryApiForFetch('SEARCH_FIELDS');
            }
        );
    };

    _renderSearchCiteria = () => {
        return (
            <React.Fragment>
                <div className="row"></div>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                            checkStoreAccess={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="terminalid" className="floatLeft">
                            Terminal Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="terminalid"
                            value={this.state.s_terminalid}
                            onChange={(event) => {
                                this.setState({
                                    s_terminalid: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="fromDate" className="floatLeft">
                            Date From
                        </label>
                        <DatePicker
                            onChange={(value) =>
                                this.setState({ s_fromDate: value }, () => {
                                    if (
                                        this.state.s_fromDate >
                                        this.state.s_toDate
                                    ) {
                                        this.setState({
                                            s_toDate: this.state.s_fromDate,
                                        });
                                    }
                                })
                            }
                            value={this.state.s_fromDate}
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="toDate" className="floatLeft">
                            Date To
                        </label>
                        <DatePicker
                            minDate={this.state.s_fromDate}
                            onChange={(value) =>
                                this.setState({ s_toDate: value })
                            }
                            value={this.state.s_toDate}
                        />
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="orderid" className="floatLeft">
                                Order Id
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="orderid"
                                value={this.state.s_orderid}
                                onChange={(event) => {
                                    this.setState({
                                        s_orderid: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="trxno" className="floatLeft">
                                Transaction No
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="trxno"
                                value={this.state.s_trxno}
                                onChange={(event) => {
                                    this.setState({
                                        s_trxno: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="invoiceno" className="floatLeft">
                                Invoice No
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceno"
                                value={this.state.s_invoiceno}
                                onChange={(event) => {
                                    this.setState({
                                        s_invoiceno: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="memberid" className="floatLeft">
                                Customer Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="memberid"
                                value={this.state.s_memberid}
                                onChange={(event) => {
                                    this.setState({
                                        s_memberid: event.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="usercode" className="floatLeft">
                                User Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="usercode"
                                value={this.state.usercode}
                                onChange={(event) => {
                                    this.setState({
                                        usercode: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-4">
                            <label htmlFor="status" className="floatLeft">
                                Status
                            </label>
                            <div className="row">
                                <select
                                    name="status"
                                    className="simpleSearchSelect2"
                                    value={this.state.s_status}
                                    onChange={(event) =>
                                        this.setState({
                                            s_status: event.target.value,
                                        })
                                    }
                                >
                                    {status.length !== 0 &&
                                        status.map((option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-lg-3 col-sm-4">
                            <label htmlFor="traintrx" className="floatLeft">
                                Training Mode
                            </label>
                            <div className="row">
                                <select
                                    name="traintrx"
                                    className="simpleSearchSelect2"
                                    value={this.state.traintrx}
                                    onChange={(event) =>
                                        this.setState({
                                            traintrx: event.target.value,
                                        })
                                    }
                                >
                                    {trainingModeOptions.length !== 0 &&
                                        trainingModeOptions.map(
                                            (option, index) => {
                                                const { name, id } = option;
                                                return (
                                                    <option
                                                        key={index}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-1 noPadding">
                            <img
                                alt="search"
                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                                onClick={(event) =>
                                    this._validateAndSearch(event)
                                }
                                className="searchBtn"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    render() {
        const {
            servererror: serverError,
            errormsg: queryError,
        } = this.props.queryData;
        const { cartdata } = this.state;

        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }

        const columns = [
            { field: 'storeid', header: 'Store Id', sortable: true },
            { field: 'terminalid', header: 'Terminal Id', sortable: true },
            { field: 'usercode', header: 'User Id', sortable: true },
            { field: 'trxno', header: 'Transaction No', sortable: true },
            { field: 'invoiceno', header: 'Invoice No', sortable: true },
            { field: 'cartid', header: 'Cart Id', sortable: true },
            { field: 'orderid', header: 'Order Id' },
            { field: 'trxdate', header: 'Transaction Date' },
            { field: 'status', header: 'Status' },
            { field: 'totalamount', header: 'Total Amount' },
            { field: 'totalpaidamount', header: 'Total Paid Amount' },
            { field: 'totalrefundamount', header: 'Total Refund Amount' },
            { field: 'itemcount', header: 'Item Count' },
            { field: 'traintrx', header: 'Training Mode' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">Sales Management</h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <form
                                className="frmDiv"
                                onSubmit={this._validateAndSearch}
                            >
                                <input type="submit" className="d-none"></input>
                                <div className="row">
                                    {this._renderSearchCiteria()}
                                </div>
                                <div className="title_devide" />
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    list={cartdata}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    responsive
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pages < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pages = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayCarts);
