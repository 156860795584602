import 'react-sortable-tree/style.css';
import React from 'react';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import Modal from 'react-awesome-modal';
import ImagePick from '../../../components/ImagePick';
import { produce } from 'immer';
import DynamicTableImage from '../../../components/DynamicTableImage';
import DynamicTable from '../../../components/DynamicTable';
import StatusOptionComp from '../../../components/StatusOptionComp';
import {
    renderCouponOptionsFilter,
    convertStrNum,
    objtoarr1,
    catTypeOptions,
    canWrite,
    canView,
    canDelete,
} from './../../../helpers/Util';
import Filter from './../../../components/Filter';

export default class SampleTree extends React.PureComponent {
    statusprod = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Updated', id: 'Updated' },
        { name: 'Deleted', id: 'Deleted' },
    ];
    cattypeoptions = catTypeOptions;
    defaultimageField = [
        {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            sortorder: 0,
            id: 0,
        },
    ];
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            treeData: [],
            addAsFirstChild: false,
            showAddProdCatModal: false,
            status: '',
            title: '',
            description: '',
            categorycode: '',
            cattype: 'static',
            cattypevalue: '',
            node: '',
            path: '',
            treeIndex: '',
            images: {},
            titleerror: '',
            categorycodeerror: '',
            descerror: '',
            nodesExpanded: false,
            additionalFields: [{ name: '', value: '', id: 0 }],
            imagesgall: [...this.defaultimageField],
            nxtImgCounter: this.defaultimageField.length + 1,
        };
    }
    setImages = (images) => {
        this.setState({ images: images });
    };
    componentWillReceiveProps = (newProps) => {
        this.setState({ treeData: newProps.data });
    };

    componentWillMount = () => {
        this.setState({ treeData: this.props.data });
    };
    componentDidMount = () => {
        this.toggleAllNodes();
    };

    toggleAllNodes = (event) => {
        if (event) event.preventDefault();
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded: !this.state.nodesExpanded,
            }),
            nodesExpanded: !this.state.nodesExpanded,
        });
    };

    _closeProdCatModal = (event) => {
        if (event) event.preventDefault();
        this.setState({
            showAddProdCatModal: false,
            title: '',
            description: '',
            categorycode: '',
            isEdit: false,
            imagesgall: [...this.defaultimageField],
            nxtImgCounter: this.defaultimageField.length + 1,
        });
    };
    _openProdCatModal = () => {
        this.setState({ showAddProdCatModal: true });
    };
    _updateTree = (event) => {
        if (event) event.preventDefault();
        if (this.validateForm()) {
            const {
                title,
                categorycode,
                description,
                sortoder,
                msgcode,
                images,
                node,
                path,
                treeIndex,
                sortorder,
                isEdit,
                status,
                cattypevalue,
                cattype,
                imagesgall,
                additionalFields
            } = this.state;
            let imageurl = images.image1 || '';
            let imagefield = {};
            let imagefieldCounter = 1;
            imagesgall.forEach((obj, idx) => {
                if (obj.uri !== '') {
                    const newObj = Object.assign({}, obj);
                    if (
                        typeof newObj.sortorder === 'undefined' ||
                        newObj.sortorder === ''
                    ) {
                        newObj['sortorder'] = 0;
                    } else {
                        try {
                            newObj['sortorder'] = Number(newObj['sortorder']);
                        } catch (e) {
                            newObj['sortorder'] = 0;
                        }
                    }
                    imagefield[`image${imagefieldCounter++}`] = newObj;
                }
            });
            let addfield = additionalFields.reduce(function (acc, item) {
                if (item.name.trim() !== '' && item.value.trim() !== '') {
                    acc[item.name] = item.value;
                    return acc;
                }
                return acc;
            }, {});
            if (isEdit === true) {
                this.props.update(
                    title,
                    categorycode,
                    description,
                    imageurl,
                    node.pid,
                    node.level,
                    node.id,
                    sortorder,
                    msgcode,
                    true,
                    status,
                    cattype,
                    cattypevalue,
                    addfield,
                    imagefield,
                );
            } else {
                this.props.create(
                    title,
                    categorycode,
                    description,
                    imageurl,
                    node.id,
                    1,
                    '',
                    sortorder,
                    msgcode,
                    false,
                    status,
                    cattype,
                    cattypevalue,
                    addfield,
                    imagefield,
                );
            }
            this._closeProdCatModal();
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            titleerror: '',
            categorycodeerror: '',
            descerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { title, categorycode, description, isEdit } = this.state;
        let valid = true;
        let regextitle = /[^\s&_-\w]/;
        let checktitle = !regextitle.test(title);
        if (checktitle === false || title.trim() == '') {
            this.setState({
                titleerror: 'Enter a title (Allowed special characters -_&)',
            });
            valid = false;
        }
        if (isEdit === true) {
            if (categorycode.trim() === '') {
                this.setState({
                    categorycodeerror: 'Enter a category code',
                });
                valid = false;
            }
        }
        if (description.trim() === '') {
            this.setState({
                descerror: 'Enter a description',
            });
            valid = false;
        }
        return valid;
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            name: '',
            value: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log('Rows After Add', this.state.additionalFields);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };
    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ additionalFields: updateValues });
        }
    };

    handleImageAddRow = (e) => {
        if (e) e.preventDefault();
        const item = {
            uri: '',
            title: '',
            alttext: '',
            width: '',
            height: '',
            tag: '',
            id: parseInt(this.state.nxtImgCounter),
        };
        this.setState(
            produce((draft) => {
                draft.nxtImgCounter = this.state.nxtImgCounter + 1;
                draft.imagesgall = [...this.state.imagesgall, item];
            }),
            () => {
                console.log('Rows After Add', this.state.imagesgall);
            },
        );
    };

    handleImageInputChange = (event, dataField, row) => {
        console.log(row, 'row.id');
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'uri'
                      ? event
                      : dataField === 'width'
                        ? event
                        : dataField === 'height'
                          ? event
                          : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.imagesgall.length === 0) {
                    draft.imagesgall.push(obj);
                } else {
                    let found = false;
                    draft.imagesgall.forEach((command) => {
                        if (
                            command.id === obj.id &&
                            typeof obj.id !== 'undefined'
                        ) {
                            // console.log('command', command)
                            // console.log('obj', obj)
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.imagesgall.push(obj);
                    }
                }
            }),
            () => {},
        );
    };
    deleteImageRow = (row) => {
        let rowId = row.id;
        const updateValues = this.state.imagesgall.filter(
            (value) => rowId !== value.id,
        );

        this.setState(
            produce((draft) => {
                draft.imagesgall = updateValues;
            }),
        );
    };

    _renderImageGallery = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Image Gallery
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTableImage
                            columns={[
                                {
                                    dataFieldId: 'uri',
                                    label: 'uri',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'title',
                                    label: 'Title',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'alttext',
                                    label: 'Alternate Text',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'width',
                                    label: 'Width',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'height',
                                    label: 'Height',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'tag',
                                    label: 'Tag',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'sortorder',
                                    label: 'Order',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'AD',
                                    label: '',
                                },
                            ]}
                            rows={this.state.imagesgall}
                            deleteRow={this.deleteImageRow}
                            addRow={this.handleImageAddRow}
                            handleImageInputChange={this.handleImageInputChange}
                            mediaImageList={this.props.mediadata}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderAdditionalFields = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'name',
                                    label: 'Name',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderAddProdCatModal = () => {
        const { isEdit } = this.state;
        return (
            <Modal
                visible={this.state.showAddProdCatModal}
                width="80%"
                height="90%"
                effect="fadeInRight"
                onClickAway={() => this._closeProdCatModal()}
            >
                <div className="modalFormTree">
                    <div className="row">
                        <div className="col-xs-12 col-md-10 alignLeft noPadding noMargin">
                            <h5>Create New Product Category</h5>
                        </div>
                        <div className="col-xs-12 col-md-2 alignRight">
                            <a onClick={this._closeProdCatModal}>X</a>
                        </div>
                    </div>
                    <div className="title_devide"></div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="title"
                                className="floatLeft required"
                            >
                                Product Title
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                value={this.state.title}
                                onChange={(event) => {
                                    this.setState({
                                        title: event.target.value,
                                    });
                                }}
                            />
                            <div className="invalid-feedback">
                                {this.state.titleerror}
                            </div>
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="categorycode" className="floatLeft">
                                Category Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="categorycode"
                                value={this.state.categorycode}
                                // readOnly={true}
                                onChange={(e) => {
                                    this.setState({
                                        categorycode: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <label htmlFor="description" className="floatLeft">
                                Description
                            </label>
                            <textarea
                                className="form-control"
                                id="description"
                                value={this.state.description}
                                onChange={(event) => {
                                    this.setState({
                                        description: event.target.value,
                                    });
                                }}
                            />
                            <div className="invalid-feedback">
                                {this.state.descerror}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="msgcode" className="floatLeft">
                                Message Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="msgcode"
                                value={this.state.msgcode}
                                onChange={(event) => {
                                    this.setState({
                                        msgcode: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="sortorder" className="floatLeft">
                                Sortorder
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="sortorder"
                                value={this.state.sortorder}
                                onChange={(event) => {
                                    this.setState({
                                        sortorder: event.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="cattype" className="floatLeft">
                                Category Type
                            </label>
                            <Filter
                                cssClass="form-control"
                                filterOptions={renderCouponOptionsFilter(
                                    this.cattypeoptions,
                                )}
                                selectedOption={this.state.cattype}
                                callbackChangeFilter={(event) => {
                                    this.setState({
                                        cattype: event.target.value,
                                    });
                                }}
                                id="cattype"
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="cattypevalue" className="floatLeft">
                                Category Type Value
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="cattypevalue"
                                value={this.state.cattypevalue}
                                onChange={(event) =>
                                    this.setState({
                                        cattypevalue: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-lg-6 col-sm-12">
                            <label htmlFor="postal" className="floatLeft">
                                Status
                            </label>
                            <StatusOptionComp
                                statusoption={this.statusprod}
                                selectedOption={this.state.status}
                                callbackChangeFilter={(value) => {
                                    this.setState({ status: value });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-sm-12 col-md-12 form-group alignLeft">
                            Image
                        </label>
                        <div className="col-sm-12 col-md-8">
                            <ImagePick
                                setImages={this.setImages}
                                images={this.state.images}
                                limit={1}
                            ></ImagePick>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {this._renderAdditionalFields()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {this._renderImageGallery()}
                        </div>
                    </div>
                    <div className="row btn-container1">
                        <div className="col-lg-12 col-md-12 col-sm-12 alignRight">
                            <button
                                type="submit"
                                onClick={this._updateTree}
                                className="btn btn-themes btn-rounded"
                            >
                                OK
                            </button>

                            <button
                                type="submit"
                                onClick={this._closeProdCatModal}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    _convertToImageGallery = (imageGalleryArray) => {
        if (
            typeof imageGalleryArray === 'undefined' ||
            imageGalleryArray === null
        ) {
            return [];
        }

        const newImageGalleryArray = [];

        for (let loop = 0; loop < imageGalleryArray.length; loop++) {
            const _imageGalleryArray = Object.assign(
                {},
                imageGalleryArray[loop],
            );
            _imageGalleryArray.id = loop;
            newImageGalleryArray.push(_imageGalleryArray);
        }
        return newImageGalleryArray;
    };

    render() {
        const getNodeKey = ({ treeIndex }) => treeIndex;
        let defaultimageField = [...this.defaultimageField];
        let defaultaddField = { name: '', value: '', id: 0 };
        return (
            <React.Fragment>
                <div className="form-group row">
                    <div
                        className={`col-sm-12 col-md-10 noPadding noMargin
               display-inline`}
                        style={{ height: '100vh' }}
                    >
                        <SortableTree
                            treeData={this.state.treeData}
                            onChange={(treeData) => this.setState({ treeData })}
                            style={{ height: '100%' }}
                            generateNodeProps={({
                                rowInfo,
                                node,
                                path,
                                treeIndex,
                            }) => {
                                let imagearr = this._convertToImageGallery(
                                    convertStrNum(node.imagegallery),
                                );
                                let fieldarr = objtoarr1(node.additionalfields);

                                if (node.status.toLowerCase() === 'deleted') {
                                    return { buttons: [] };
                                }

                                if (
                                    canDelete(this.props.allPermissions) &&
                                    canWrite(this.allPermissions)
                                ) {
                                    return {
                                        buttons: [
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                                                onClick={(event) => {
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            title: node.title,
                                                            description:
                                                                node.description,
                                                            categorycode:
                                                                node.categorycode,
                                                            msgcode:
                                                                node.msgcode,
                                                            sortorder:
                                                                node.sortorder,
                                                            isEdit: false,
                                                            images: {
                                                                image1:
                                                                    node.imageurl ||
                                                                    '',
                                                            },
                                                            status: node.status,
                                                            cattype:
                                                                node.cattype,
                                                            cattypevalue:
                                                                node.cattypevalue,
                                                            imagesgall:
                                                                node.hasOwnProperty(
                                                                    'imagegallery',
                                                                ) &&
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr
                                                                    : [
                                                                          ...defaultimageField,
                                                                      ],
                                                            nxtImgCounter:
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr[
                                                                          imagearr.length -
                                                                              1
                                                                      ].id + 1
                                                                    : 1,
                                                            additionalFields:
                                                                node.hasOwnProperty(
                                                                    'additionalfields',
                                                                ) &&
                                                                typeof fieldarr !==
                                                                    'undefined' &&
                                                                fieldarr.length !==
                                                                    0
                                                                    ? fieldarr
                                                                    : [
                                                                          defaultaddField,
                                                                      ],
                                                        },
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                    if (event)
                                                        event.preventDefault();
                                                }}
                                            ></img>,
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_addbtn_blue.svg`}
                                                onClick={(event) => {
                                                    if (event)
                                                        event.preventDefault();
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            isEdit: false,
                                                            images: '',
                                                            title: '',
                                                            description: '',
                                                            categorycode: '',
                                                            msgcode: '',
                                                            sortorder: '',
                                                            isEdit: false,
                                                            status: 'Active',
                                                            cattype: 'static',
                                                            cattypevalue: '',
                                                            additionalfields:
                                                                {},
                                                            imagegallery: {},
                                                        },
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                }}
                                            ></img>,
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                                                onClick={(event) => {
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            title: node.title,
                                                            description:
                                                                node.description,
                                                            categorycode:
                                                                node.categorycode,
                                                            msgcode:
                                                                node.msgcode,
                                                            sortorder:
                                                                node.sortorder,
                                                            isEdit: true,
                                                            images: {
                                                                image1:
                                                                    node.imageurl ||
                                                                    '',
                                                            },
                                                            status: node.status,
                                                            cattype:
                                                                node.cattype,
                                                            cattypevalue:
                                                                node.cattypevalue,
                                                            imagesgall:
                                                                node.hasOwnProperty(
                                                                    'imagegallery',
                                                                ) &&
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr
                                                                    : [
                                                                          ...defaultimageField,
                                                                      ],
                                                            nxtImgCounter:
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr[
                                                                          imagearr.length -
                                                                              1
                                                                      ].id + 1
                                                                    : 1,
                                                            additionalFields:
                                                                node.hasOwnProperty(
                                                                    'additionalfields',
                                                                ) &&
                                                                typeof fieldarr !==
                                                                    'undefined' &&
                                                                fieldarr.length !==
                                                                    0
                                                                    ? fieldarr
                                                                    : [
                                                                          defaultaddField,
                                                                      ]},
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                    if (event)
                                                        event.preventDefault();
                                                }}
                                            ></img>,

                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                                onClick={(event) => {
                                                    this.props.delete(
                                                        node.id ? node.id : '',
                                                        true,
                                                    );
                                                    if (event)
                                                        event.preventDefault();
                                                }}
                                            />,
                                        ],
                                    };
                                }

                                if (canWrite(this.props.allPermissions)) {
                                    return {
                                        buttons: [
                                            // node.pid === 0 || node.pid === 1 || node.pid === 2 ?
                                            //node.hasOwnProperty("children") ?
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                                                onClick={(event) => {
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            title: node.title,
                                                            description:
                                                                node.description,
                                                            categorycode:
                                                                node.categorycode,
                                                            msgcode:
                                                                node.msgcode,
                                                            sortorder:
                                                                node.sortorder,
                                                            isEdit: false,
                                                            images: {
                                                                image1:
                                                                    node.imageurl ||
                                                                    '',
                                                            },
                                                            status: node.status,
                                                            cattype:
                                                                node.cattype,
                                                            cattypevalue:
                                                                node.cattypevalue,
                                                            imagesgall:
                                                                node.hasOwnProperty(
                                                                    'imagegallery',
                                                                ) &&
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr
                                                                    : [
                                                                          ...defaultimageField,
                                                                      ],
                                                            nxtImgCounter:
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr[
                                                                          imagearr.length -
                                                                              1
                                                                      ].id + 1
                                                                    : 1,
                                                            additionalFields:
                                                                node.hasOwnProperty(
                                                                    'additionalfields',
                                                                ) &&
                                                                typeof fieldarr !==
                                                                    'undefined' &&
                                                                fieldarr.length !==
                                                                    0
                                                                    ? fieldarr
                                                                    : [
                                                                          defaultaddField,
                                                                      ],
                                                        },
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                    if (event)
                                                        event.preventDefault();
                                                }}
                                            ></img>,
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_addbtn_blue.svg`}
                                                onClick={(event) => {
                                                    if (event)
                                                        event.preventDefault();
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            isEdit: false,
                                                            images: '',
                                                            title: '',
                                                            description: '',
                                                            categorycode: '',
                                                            msgcode: '',
                                                            sortorder: '',
                                                            isEdit: false,
                                                            status: 'Active',
                                                            cattype: 'static',
                                                            cattypevalue: '',
                                                            additionalfields:
                                                                {},
                                                            imagegallery: {},
                                                        },
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                }}
                                            ></img>,
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                                                onClick={(event) => {
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            title: node.title,
                                                            description:
                                                                node.description,
                                                            categorycode:
                                                                node.categorycode,
                                                            msgcode:
                                                                node.msgcode,
                                                            sortorder:
                                                                node.sortorder,
                                                            isEdit: true,
                                                            images: {
                                                                image1:
                                                                    node.imageurl ||
                                                                    '',
                                                            },
                                                            status: node.status,
                                                            cattype:
                                                                node.cattype,
                                                            cattypevalue:
                                                                node.cattypevalue,
                                                            imagesgall:
                                                                node.hasOwnProperty(
                                                                    'imagegallery',
                                                                ) &&
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr
                                                                    : [
                                                                          ...defaultimageField,
                                                                      ],
                                                            nxtImgCounter:
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr[
                                                                          imagearr.length -
                                                                              1
                                                                      ].id + 1
                                                                    : 1,
                                                            additionalFields:
                                                                node.hasOwnProperty(
                                                                    'additionalfields',
                                                                ) &&
                                                                typeof fieldarr !==
                                                                    'undefined' &&
                                                                fieldarr.length !==
                                                                    0
                                                                    ? fieldarr
                                                                    : [
                                                                          defaultaddField,
                                                                      ]},
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                    if (event)
                                                        event.preventDefault();
                                                }}
                                            ></img>,
                                        ],
                                    };
                                }

                                if (canView(this.props.allPermissions)) {
                                    return {
                                        buttons: [
                                            // node.pid === 0 || node.pid === 1 || node.pid === 2 ?
                                            //node.hasOwnProperty("children") ?
                                            <img
                                                className="dynamicBtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                                                onClick={(event) => {
                                                    this.setState(
                                                        {
                                                            node,
                                                            path,
                                                            treeIndex,
                                                            title: node.title,
                                                            description:
                                                                node.description,
                                                            categorycode:
                                                                node.categorycode,
                                                            msgcode:
                                                                node.msgcode,
                                                            sortorder:
                                                                node.sortorder,
                                                            isEdit: false,
                                                            images: {
                                                                image1:
                                                                    node.imageurl ||
                                                                    '',
                                                            },
                                                            status: node.status,
                                                            cattype:
                                                                node.cattype,
                                                            cattypevalue:
                                                                node.cattypevalue,
                                                            imagesgall:
                                                                node.hasOwnProperty(
                                                                    'imagegallery',
                                                                ) &&
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr
                                                                    : [
                                                                          ...defaultimageField,
                                                                      ],
                                                            nxtImgCounter:
                                                                typeof imagearr !==
                                                                    'undefined' &&
                                                                imagearr.length !==
                                                                    0
                                                                    ? imagearr[
                                                                          imagearr.length -
                                                                              1
                                                                      ].id + 1
                                                                    : 1,
                                                            additionalFields:
                                                                node.hasOwnProperty(
                                                                    'additionalfields',
                                                                ) &&
                                                                typeof fieldarr !==
                                                                    'undefined' &&
                                                                fieldarr.length !==
                                                                    0
                                                                    ? fieldarr
                                                                    : [
                                                                          defaultaddField,
                                                                      ],
                                                        },
                                                        () => {
                                                            this._openProdCatModal();
                                                        },
                                                    );
                                                    if (event)
                                                        event.preventDefault();
                                                }}
                                            ></img>,
                                        ],
                                    };
                                }
                            }}
                        />
                    </div>
                </div>
                {this.state.showAddProdCatModal === true &&
                    this._renderAddProdCatModal()}
            </React.Fragment>
        );
    }
}
