import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    createCouponRule,
    updateCouponRule,
    getStoreById,
    searchCouponByFields,
} from '../../../actions/actions';

import ConfirmAlert from '../../../components/ConfirmAlert';
import StatusOptionComp from '../../../components/StatusOptionComp';
import { crudReset } from '../../../actions/actions';
import { produce } from 'immer';
import FrequencyComp from '../../../components/FrequencyComp';
import CouponValidity from './SubComponents/CouponValidity';
import { formatDate, objtoarr } from '../../../helpers/Util';
import Member from './SubComponents/Member';
import SimpleDropdown from '../../../components/SimpleDropdown';
import StoreSelector from '../../../components/StoreSelector';
import { AutoComplete } from 'primereact/autocomplete';

class AddCouponRule extends React.PureComponent {
    ruletype = [
        { id: '', name: 'Select' },
        { id: 'Member', name: 'Member' },
        { id: 'Tag', name: 'Tag' },
        { id: 'Discount', name: 'Discount' },
    ];
    ruletypes = [
        { id: '', name: 'Select' },
        { id: 'Point', name: 'Point' },
        { id: 'Manual', name: 'Manual' },
        { id: 'Birthday', name: 'Birthday' },
        { id: 'Payment', name: 'Payment' },
    ];
    couponvaluetypes = [
        { id: '', name: 'Select' },
        { id: 'Amt', name: 'Amount' },
        { id: 'Percent', name: 'Percent %' },
    ];
    categorypoints = [
        { id: '', name: 'Select' },
        { id: 'Reward', name: 'Reward' },
    ];
    statusCoupon = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Save', id: 'Save' },
        { name: 'Update', id: 'Update' },
        { name: 'Expired', id: 'Expired' },
    ];

    couponTypeArr = ['Manual', 'Payment'];

    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');

        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            // Formrelated
            couponcode: '',
            title: '',
            ruletype: '',
            rulegroup: '',
            couponvaluetype: '',
            couponvalue: '',
            // storegroups: [],
            storecodes: '',
            pointcategory: '',
            pointbal: '',
            pointbalmin: '',
            pointbalmax: '',
            pointburn: '',
            pointburncategory: '',
            member: [{ field: '', value: '', id: 0 }],
            maxqty: '',
            qtylimitby: 'Once',
            status: 'Active',
            fromdate: '',
            tilldate: '',
            by: 'Day',
            svalue: '',
            evalue: '',
            couponvalidextend: '',
            storelabel: '',
            sortorder: 0,
            extendexpiry: '',
            //storegrouptitle: {},
            // Errors
            titleError: '',
            couponCodeError: '',
            couponValueError: '',
            // storegroupsError: "",
            storecodesEror: '',
            membertrxError: '',
            membertrxdateError: '',
            couponvalidityError: '',
            maxqtyError: '',
            qtylimitbyError: '',
            ruletypeerror: '',
            couponslist: [],
            couponcodeObj: '',
            filteredCouponCodes: null,
        };
    }

    handleValidFromDateChange = (value) => {
        this.setState({
            fromdate: value,
        });
    };
    handleValidTillDateChange = (value) => {
        this.setState({
            tilldate: value,
        });
    };

    toDate = (dateStr) => {
        let parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    getCreatePayload = () => {
        const {
            couponcode,
            title,
            ruletype,
            rulegroup,
            couponvaluetype,
            couponvalue,
            storecodes,
            member,
            maxqty,
            qtylimitby,
            status,
            svalue,
            by,
            evalue,
            date,
            pointcategory,
            pointbal,
            pointbalmin,
            pointbalmax,
            couponvalidextend,
            extendexpiry,
            sortorder,
            pointburn,
            pointburncategory,
        } = this.state;

        let memfield = member.reduce(function (acc, item) {
            if (item.field.trim() !== '' && item.value.trim() !== '') {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {});

        const couponTypeArr = ['Manual', 'Payment'];

        let body = {
            companyid: this.companyId,
            couponcode,
            title,
            ruletype,
            rulegroup,
            couponvaluetype: couponTypeArr.includes(ruletype)
                ? couponvaluetype
                : '',
            couponvalue: couponTypeArr.includes(ruletype) ? couponvalue : 0,
            storeid: storecodes || '',
            pointcategory,
            pointbal: parseInt(pointbal) || '',
            pointbalmin: parseInt(pointbalmin) || '',
            pointbalmax: parseInt(pointbalmax) || '',
            couponvalidby: by,
            couponvalidextend: parseInt(couponvalidextend) || '',
            qtylimitby,
            qtymax: parseInt(maxqty) || '',
            status,
            extendexpiry,
            pointburn: parseInt(pointburn) || '',
            pointburncategory,
            sortorder,
            memberupdate: memfield,
        };
        if (by === 'Day') {
            body['couponvalidfrom'] = parseInt(svalue) || 0;
            body['couponvalidtill'] = parseInt(evalue) || 0;
        }
        if (by === 'fixdate') {
            body['couponvalidfrom'] = formatDate(svalue) || '';
            body['couponvalidtill'] = formatDate(evalue) || '';
        }
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    setStoreCodes = (value) => {
        this.setState({
            storecodes: value,
        });
    };

    _createCouponRule = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateCouponRule(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createCouponRule(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleError: '',
            couponcodeError: '',
            // storegroupsError: "",
            storecodesEror: '',
            membertrxError: '',
            membertrxdateError: '',
            couponvalidityError: '',
            maxqtyError: '',
            qtylimitbyError: '',
            couponValueError: '',
            ruletypeerror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            ruletype,
            svalue,
            evalue,
            by,
            qtylimitby,
            couponcodeObj,
            couponvalue,
        } = this.state;
        const couponcode =
            couponcodeObj && couponcodeObj.id ? couponcodeObj.id : '';
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleError: 'Please enter a title' });
            valid = false;
        }
        if (couponcode.trim() === '') {
            this.setState({ couponcodeError: 'Please select an coupon title' });
            valid = false;
        }

        if (ruletype.trim() === '') {
            this.setState({ ruletypeerror: 'Please select an ruletype' });
            valid = false;
        }

        if (this.couponTypeArr.includes(ruletype)) {
            if (couponvalue.trim() === '') {
                valid = false;
                this.setState({
                    couponValueError: 'Please enter coupon value',
                });
            }
        }

        if (by === 'Day' && (svalue.trim() === '' || evalue.trim() === '')) {
            this.setState({
                couponvalidityError:
                    'Please select coupon validity (Days From and Days Till)',
            });
            valid = false;
        }
        if (
            by === 'fixdate' &&
            (svalue.toString().trim() === '' || evalue.toString().trim() === '')
        ) {
            this.setState({
                couponvalidityError: 'Please select coupon validity',
            });
            valid = false;
        }
        if (qtylimitby.trim() === '') {
            this.setState({ qtylimitbyError: 'Please enter quantity limit' });
            valid = false;
        }
        return valid;
    };

    searchCouponCode = (event) => {
        const { couponslist } = this.state;
        setTimeout(() => {
            let _filteredCouponCodes;
            if (!event.query.trim().length) {
                _filteredCouponCodes = [...couponslist];
            } else {
                _filteredCouponCodes = couponslist.filter((country) => {
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCouponCodes: _filteredCouponCodes });
        }, 250);
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/coupon-rules"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createCouponRule();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createCouponRule}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/coupon-rules"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.code, selectedStores.name);
    };

    _setTenant = (p1, p2) => {
        this.setState({ storecodes: p1, storelabel: p2 });
    };

    setCouponTitle = (code) => {
        const { couponsList } = this.props.queryData;
        let getcode = couponsList.filter((e) => e.couponcode == code);
        if (getcode.length > 0) {
            this.setState({
                title: getcode[0].title,
            });
        }
    };

    handleChangeCouponObj = (e) => {
        this.setState({
            couponcodeObj: e.target.value,
            couponcode: e.target.value ? e.target.value.id : '',
        });
        if (e.target.value && e.target.value !== '') {
            this.setCouponTitle(e.target.value.id);
        }
    };

    _renderMainFormDetails = () => {
        const { couponcodeError } = this.state;
        const couponTypeArr = ['Manual', 'Payment'];
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="couponcode" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="couponcode"
                            value={this.state.title}
                            onChange={(e) =>
                                this.setState({ title: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Coupon Code *
                        </label>

                        <AutoComplete
                            className="w-100"
                            value={this.state.couponcodeObj}
                            suggestions={this.state.filteredCouponCodes}
                            completeMethod={this.searchCouponCode}
                            field="name"
                            dropdown
                            forceSelection
                            onChange={this.handleChangeCouponObj}
                            aria-label="Coupon Code"
                            dropdownAriaLabel="Select Coupon Code"
                        />
                        <div className="invalid-feedback">
                            {couponcodeError}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="ruletype" className="floatLeft">
                            Rule Type *
                        </label>
                        <SimpleDropdown
                            id="rulegroup"
                            listoptions={this.ruletypes}
                            selectedOption={this.state.ruletype}
                            callbackChangeFilter={(value) => {
                                this.setState({ ruletype: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.ruletypeerror}
                        </div>
                    </div>
                </div>
                {couponTypeArr.includes(this.state.ruletype) && (
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="ruletype" className="floatLeft">
                                Coupon Value Type
                            </label>
                            <SimpleDropdown
                                id="couponvaluetype"
                                listoptions={this.couponvaluetypes}
                                selectedOption={this.state.couponvaluetype}
                                callbackChangeFilter={(value) => {
                                    this.setState({ couponvaluetype: value });
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="couponvalue"
                                className="floatLeft required"
                            >
                                Coupon Value
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="couponvalue"
                                value={this.state.couponvalue}
                                onChange={(event) => {
                                    this.setState({
                                        couponvalue: event.target.value,
                                    });
                                }}
                            />
                            <div className="invalid-feedback">
                                {this.state.couponValueError}
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    _renderStoreDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store Id
                        </label>
                        <StoreSelector
                            storeid={this.state.storeid}
                            storelabel={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    setMemberProps = (member) => {
        this.setState({ member: member });
    };
    _setCouponBy = (value) => {
        this.setState({ by: value });
    };
    _setStartValue = (value) => {
        this.setState({ svalue: value });
    };
    _setEndValue = (value) => {
        this.setState({ evalue: value });
    };
    _setCouponExtend = (value) => {
        this.setState({ couponvalidextend: value });
    };
    _setCouponExpiry = (value) => {
        this.setState({ extendexpiry: value });
    };
    _setCouponMaxQty = (value) => {
        this.setState({ maxqty: value });
    };

    _renderMemberInformation = () => {
        const { member } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="member" className="floatLeft">
                            Member
                        </label>
                        <Member
                            member={member}
                            setMemberProps={this.setMemberProps}
                        ></Member>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderPointInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointcategory" className="floatLeft">
                            Point Category
                        </label>
                        <SimpleDropdown
                            id="pointcategory"
                            listoptions={this.categorypoints}
                            selectedOption={this.state.pointcategory}
                            callbackChangeFilter={(value) => {
                                this.setState({ pointcategory: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointbal" className="floatLeft">
                            Point Balance (Batch)
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointbalance"
                            value={this.state.pointbal}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    pointbal: parseInt(event.target.value),
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointbalmin" className="floatLeft">
                            Minimum Point Balance
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointbalmin"
                            value={this.state.pointbalmin}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    pointbalmin: parseInt(event.target.value),
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointbalmax" className="floatLeft">
                            Maximum Point Balance
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointbalance"
                            value={this.state.pointbalmax}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    pointbalmax: parseInt(event.target.value),
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="pointburncategory"
                            className="floatLeft"
                        >
                            Point Burn Category
                        </label>
                        <SimpleDropdown
                            id="pointburncategory"
                            listoptions={this.categorypoints}
                            selectedOption={this.state.pointburncategory}
                            callbackChangeFilter={(value) => {
                                this.setState({ pointburncategory: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointburn" className="floatLeft">
                            Point Burn
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointburn"
                            value={this.state.pointburn}
                            onChange={(event) =>
                                this.setState({
                                    pointburn: parseInt(event.target.value),
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderGeneralInformation = () => {
        const {
            by,
            svalue,
            evalue,
            date,
            couponvalidextend,
            extendexpiry,
            maxqty,
        } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="validfrom" className="floatLeft">
                            Quantity Limit By
                        </label>
                        <FrequencyComp
                            frequency={this.state.qtylimitby}
                            setFrequency={(value) => {
                                this.setState({ qtylimitby: value });
                            }}
                        ></FrequencyComp>
                        <div className="invalid-feedback">
                            {this.state.qtylimitbyError}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="validfrom" className="floatLeft">
                            Coupon Validity
                        </label>
                        <CouponValidity
                            couponby={by}
                            svalue={svalue}
                            evalue={evalue}
                            couponextend={couponvalidextend}
                            extendexpiry={extendexpiry}
                            maxqty={maxqty}
                            setCouponBy={this._setCouponBy}
                            setStartValue={this._setStartValue}
                            setEndValue={this._setEndValue}
                            setCouponExpiry={this._setCouponExpiry}
                            setCouponExtend={this._setCouponExtend}
                            setCouponMaxQty={this._setCouponMaxQty}
                            couponvalidityError={this.state.couponvalidityError}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.statusCoupon}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft ">
                            Sortorder
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            min="0"
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderMemberInformation()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec">
                            {this._renderStoreDetails()}
                        </form>
                        <form
                            className="splitFrmDivSec text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderStatusDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    toDate = (dateStr) => {
        let parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    componentDidMount = () => {
        this.props.dispatch(
            searchCouponByFields({ body: { status: 'Active' } })
        );
    };

    UNSAFE_componentWillReceiveProps = () => {
        const { couponsList } = this.props.queryData;

        if (couponsList && couponsList.length > 0) {
            let returnedData = couponsList.map((e) => ({
                name: e.title + ' - ' + e.couponcode,
                id: e.couponcode,
                title: e.title,
            }));
            this.setState({ couponslist: returnedData });
        } else {
            this.setState({ couponslist: [{ name: 'Select', id: '' }] });
        }
    };

    componentDidUpdate(prevProps) {
        const { location, queryData } = this.props;
        const { couponsList } = queryData;
        const { couponsList: prevCouponsList } = prevProps.queryData;

        if (couponsList !== prevCouponsList) {
            let couponsListData = [];

            if (couponsList && couponsList.length > 0) {
                couponsListData = couponsList.map((coupon) => ({
                    name: `${coupon.title} - ${coupon.couponcode}`,
                    id: coupon.couponcode,
                    title: coupon.title,
                }));
            }

            const couponsListWithSelect = [
                { name: 'Select', id: '' },
                ...couponsListData,
            ];

            this.setState({ couponslist: couponsListWithSelect });
            if (location && location.state) {
                const { item } = location.state;
                const { couponcode } = item;
                const couponObj = couponsListData.find(
                    (data) => data.id == couponcode
                );
                this.setState({ couponcodeObj: couponObj });
            } else {
                this.setState({ couponcodeObj: null });
            }
        }
    }

    componentWillMount = () => {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    couponcode,
                    title,
                    ruletype,
                    rulegroup,
                    couponvaluetype,
                    couponvalue,
                    // storegroups,
                    storeid,
                    pointcategory,
                    pointbal,
                    pointbalmin,
                    pointbalmax,
                    couponvalidby,
                    couponvalidfrom,
                    couponvalidtill,
                    couponvalidextend,
                    qtylimitby,
                    qtymax,
                    status,
                    memberupdate,
                    extendexpiry,
                    pointburncategory,
                    pointburn,
                    sortorder,
                } = rec;

                if (storeid) {
                    this.props.dispatch(getStoreById(storeid));
                }
                this.setState({ storeid });
                let sval = couponvalidfrom;
                let endval = couponvalidtill;
                if (couponvalidby == 'fixdate') {
                    sval = this.toDate(sval);
                    endval = this.toDate(endval);
                }
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.couponcode = couponcode ? couponcode : '';
                        draft.title = title ? title : '';
                        draft.ruletype = ruletype ? ruletype : '';
                        draft.couponvaluetype = couponvaluetype
                            ? couponvaluetype
                            : '';
                        draft.couponvalue = couponvalue ? couponvalue : '';
                        draft.rulegroup = rulegroup ? rulegroup : '';
                        // draft.storegroups = storegroups ? storegroups.split(',') : [];
                        draft.storecodes = storeid ? storeid : '';
                        draft.pointcategory = pointcategory
                            ? pointcategory
                            : '';
                        draft.pointbal = pointbal ? pointbal : 0;
                        draft.pointbalmin = pointbalmin ? pointbalmin : 0;
                        draft.pointbalmax = pointbalmax ? pointbalmax : 0;
                        draft.by = couponvalidby ? couponvalidby : 'Day';
                        draft.svalue = couponvalidfrom ? sval : 0;
                        draft.evalue = couponvalidtill ? endval : 0;
                        draft.couponvalidextend = couponvalidextend
                            ? couponvalidextend
                            : '';
                        draft.qtylimitby = qtylimitby ? qtylimitby : '';
                        draft.maxqty = qtymax ? qtymax : 0;
                        draft.status = status ? status : 'New';
                        draft.extendexpiry = extendexpiry ? extendexpiry : '';
                        draft.pointburn = pointburn ? pointburn : 0;
                        draft.pointburncategory = pointburncategory
                            ? pointburncategory
                            : '';
                        draft.sortorder = sortorder ? sortorder : 0;
                        draft.member = memberupdate
                            ? objtoarr(memberupdate)
                            : [{ field: '', value: '', id: 0 }];
                    })
                );
            }
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Coupon Rule'
                                    : this._isViewMode()
                                      ? 'View Coupon Rule'
                                      : 'Add Coupon Rule'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}

                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/coupon-rules"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Coupon Rule updated successfully'
                                    : 'Coupon Rule created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddCouponRule);
