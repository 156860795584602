import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    searchGeneral,
    storeSearchByFields,
} from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import PrimeStoreSelector from '../../components/PrimeStoreSelector';
import PrimeTable from '../../components/PrimeTable';

const statusOptions = [
    { name: 'Select', code: '' },
    { name: 'Active', code: 'active' },
    { name: 'Deleted', code: 'deleted' }
];

const SaleStockReport = (props) => {
    const { stores, replenishmentList } = props.queryData;
    const [storeList, setStoreList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(storeSearchByFields(`companyid=${companyId}`));
    }, []);

    const createFetchUrl = (params) => {
        props.dispatch(
            searchGeneral(
                `ext-ns/v1/sales/stock/report`,
                { body: params },
                CONSTANTS.REPLENISHMENT_GET_ALL_SUCCESS,
                CONSTANTS.REPLENISHMENT_GET_ALL_FAILURE
            )
        );
    };

    const pageSizeChangecallback = (recordCount) => {
        setPageSize(recordCount);
        setCurrPage(1)
        createFetchUrl({
            storeid: searchParams.storeid ? searchParams.storeid : '',
            status: searchParams.status ? searchParams.status : '',
            pagesize: recordCount,
            pageno: 1,
        });
    };

    const pageChangeCallback = (pageNo) => {
        setCurrPage(pageNo);
        createFetchUrl({
            storeid: searchParams.storeid ? searchParams.storeid : '',
            status: searchParams.status ? searchParams.status : '',
            pagesize: pageSize,
            pageno: pageNo,
        });
    };

    useEffect(() => {
        initLoad();
    }, []);

    useEffect(() => {
        const options = stores.map((e) => ({
            label: '(' + e.storeid + ') ' + e.title,
            value: e.storeid,
        }));
        setStoreList(options);
    }, [stores]);

    const initLoad = () => {
        createFetchUrl({
            pagesize: pageSize,
            pageno: currPage,
        });
    };

    const handleSearch = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
        setSearchParams(data);
        const requestBody = {
            status: data.status ? data.status : '',
            storeid: data.storeid ? data.storeid : '',
            pageno: currPage,
            pagesize: pageSize,
        };
        Object.keys(requestBody).forEach((key) => {
            if (requestBody[key] === '') {
                delete requestBody[key];
            }
        });
        createFetchUrl(requestBody);
    };

    const columns = [
        { field: 'storeid', header: 'Store Id', sortable: true },
        { field: 'terminalid', header: 'Terminal Id', sortable: true},
        { field: 'productcode', header: 'Product Code'},
        { field: 'vanid', header: 'Van Id' },
        { field: 'stockqty', header: 'Stock Qty' },
        { field: 'posqty', header: 'Pos Qty' },
        { field: 'diffqty', header: 'Difference' },
        { field: 'lastupdated', header: 'Last updated on'},
        { field: 'status', header: 'Status'}
    ];

    return (
        <div className="form_height">
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h2 className="page-header">
                        Sale Stock Report
                        <img
                            alt=""
                            title="Refresh"
                            onClick={initLoad}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                        />
                    </h2>
                </div>
            </div>
            <div className="frmDiv mx-3 mt-3">
                <ReportFilter
                    handleSearch={handleSearch}
                    storeList={storeList}
                />
                <div className="mt-4">
                <PrimeTable
                            list={replenishmentList}
                            columns={columns}
                            pageSizeChangecallback={(pageSize) => {
                                pageSizeChangecallback(pageSize);
                            }}
                            pageChangeCallback={(currPage) => {
                                pageChangeCallback(currPage);
                            }}
                        >
                        </PrimeTable>
                </div>
            </div>
        </div>
    );
};

const ReportFilter = (props) => {
    const { handleSearch } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));

    const [selectedStore, setSelectedStore] = useState({});

    const formik = useFormik({
        initialValues: {
            storeid: '',
            status: statusOptions.find((status) => status.code == ''),
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                status: values.status ? values.status.code : '',
                storeid: storeid,
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(
                    ([_, value]) => value !== undefined && value !== ''
                )
            );
            handleSearch(filteredData);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleStoreSelection = (selectedStore) => {
        setStoreid(selectedStore.value);
        setSelectedStore(selectedStore);
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="storeid">Select Store</label>
                        <PrimeStoreSelector
                            storeList={props.storeList}
                            handleStoreSelection={handleStoreSelection}
                            selectedStore={selectedStore}
                        />
                        {getFormErrorMessage('storeid')}
                    </div>
                    <div className="field col-3 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field mb-2" style={{ display: 'flex' }}>
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(SaleStockReport);
