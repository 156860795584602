import produce from 'immer';
import React from 'react'
import { connect } from 'react-redux';
import { crudReset, searchGeneral, storeTerminalsSearchByFields } from '../../actions/actions';
import SimpleTable from '../../components/SimpleTable';
import { filterFormatter, permissions } from '../../helpers/Util';
import * as CONSTANTS from '../../constants/constants';


class SalesSyncExceptionReport extends React.PureComponent {

  constructor(props) {

    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.module = "REPORTS"
    this.group = "DE_REPORTS_SALES_SYNC_EXCEPTION"
    this.allPermissions = []

    this.requestBody = {} //current one
    this.currPage = 1
    this.pageSize = 10

    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,

      message: "",
      action: '',
      tableCols: [
        // {
        //   dataField: "tasktype",
        //   text: "Task Type",
        //   sort: true
        // },
        {
          dataField: "title",
          text: "Path",
          sort: true
        },
        // {
        //   dataField: "id",
        //   text: "Id",
        //   sort: true
        // },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "additionalfields",
          text: "Filters",
          sort: false,
          formatter: this._filterFormatter
        },
        // {
        //   dataField: "memberid",
        //   text: "Member Id",
        //   sort: true
        // },
        // {
        //   dataField: "storeid",
        //   text: "Store Id",
        //   sort: true
        // },
        {
          dataField: "createdon",
          text: "Exported On",
          sort: true
        },
        {
          dataField: "lastupdatedby",
          text: "Generated by",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ],



      pagesize: this.pageSize,

    };
  }

  _filterFormatter = (cell, row, rowIndex, formatExtraData) => {
    return filterFormatter(cell, row, rowIndex, formatExtraData, ['pagesize', 'pageno', 'filepath', 'report'])
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status !== 'exported') { return }
    if (row.additionalfields["filepath"] === undefined) { return }
    return (
      <span>
        <img
          title="Download"
          className="tableImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
          onClick={() => this.download(row)}
        />
      </span>
    );
  };

  download = (row) => {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
      var url = localStorage.getItem('imagepath') + row.additionalfields["filepath"];
      console.log('url::', url)
      link.setAttribute("href", url);

      var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // API Calls
  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage
    } = this.state;

    if (fetchType === 'ONLOAD') {
      const requestBody = {
        body: {
          pagesize: pageSize,
          pageno: currPage,
          tasktype: this.getTaskType()
        }
      };
      console.log("...requestBody for ONLOAD::", requestBody)
      return requestBody;
    }

    console.log('tasktype:::', this.requestBody['tasktype'])
    if (this.requestBody['tasktype'] === undefined) {
      this.requestBody['tasktype'] = this.getTaskType()
    }

    const requestBody = {
      body: { ...this.requestBody, pagesize: pageSize, pageno: currPage }
    };

    console.log("...requestBody::", requestBody)
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "/system/v1/jobtask/search/fields",
        this.createFetchUrl(fetchType),
        CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
        CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE,
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    // var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "PAGECLICK":
        this.callFetch()
        break;
    }
  };

  componentWillMount() {

    this.callQueryApiForFetch("ONLOAD")
    this.allPermissions = permissions(this.module, this.group)
  }

  componentDidUpdate = (prevProps) => {

  }


  componentWillReceiveProps = () => {

  }


  hideAlertModal = e => {
    this.setState({ message: "", action: '' });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.queryData.reportexport = {}
    this.callQueryApiForFetch("ONLOAD");
  };

  fetchStoreTerminals = () => {
    const {
      storeid
    } = this.state

    this.props.queryData.storeTerminals = []
    if (storeid && storeid !== '') {
      const queryParams = []
      queryParams.push(`storeid=${storeid}`)
      queryParams.push(`devicegroup=terminal`)
      this.props.dispatch(
        storeTerminalsSearchByFields(queryParams.join('&'),
          CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
          CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE_SILENT
        ))
    }
  }



  reportTitle = () => {
    return 'Sales Sync Exception Report'
  }

  getTaskType = () => {
    return 'eiptopicmsg-sales-sync-report'
  }




  render() {
    const {
      errormsg: queryError,
      servererror: serverError,
      jobtasklogs,
      reportexport
    } = this.props.queryData;

    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">{this.reportTitle()}
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
          </div>

          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
               
                <div className="title_devide" />
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={jobtasklogs}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    }, () => {
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }), () => {
                      this.callQueryApiForFetch("PAGECLICK")
                    })
                  }}

                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />

              </form>
            </div>
          </div>
          {/* <ConfirmAlert
            show={(reportexport && reportexport.status === 'exporting') ? true : false}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  (reportexport && reportexport.status === 'exporting')
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {(reportexport && reportexport.status === 'exporting') ? this.state.message : ""}
              </div>
            }
          /> */}


        </div>

      </React.Fragment>
    );

  };
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(SalesSyncExceptionReport);