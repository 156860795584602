import * as CONSTANTS from '../constants/constants';

export const loginToDashboard = (userid, password) => {
    return {
        type: CONSTANTS.LOGIN_REQUEST,
        payload: { userid, password },
    };
};

export const logoutFromDashboard = () => {
    return {
        type: CONSTANTS.LOGOUT_SUCCESS,
    };
};

export const requestPin = () => ({
    type: CONSTANTS.PIN_REQUEST,
});

export const submitPin = (pin) => ({
    type: CONSTANTS.ENTER_PIN,
    payload: { pin },
});

export const updateDefaultConfig = (data) => {
    return {
        type: CONSTANTS.UPDATE_DEFAULT_CONFIG,
        data: data.body,
    };
};

export const contentChange = (data) => {
    return {
        type: CONSTANTS.CONTENT_CHANGE,
        data: data,
    };
};

export const updateArticleMode = (data) => {
    return {
        type: CONSTANTS.UPDATE_ARTICLE_MODE,
        data: data.body,
    };
};

export const updateArticleModeType = (data) => {
    return {
        type: CONSTANTS.UPDATE_ARTICLE_MODE_TYPE,
        data: data.body,
    };
};
// QUERY APIzzz
export const queryApi = ({ request, ...payloadWithoutRequest }) => {
    return {
        type: request,
        payload: payloadWithoutRequest,
    };
};

export const queryTerminalInfoReset = () => ({
    type: CONSTANTS.QUERY_TERMINAL_BIRINFO_RESET,
});
// Beacons and Geofence info for multiselect

export const getBeaconsList = (payload) =>
    queryApi({
        request: CONSTANTS.GET_BEACONS,
        url: `cms/v1/article-workflow/articletype/Beacon Information`,
        encode: false,
        success: CONSTANTS.GET_BEACONS_SUCCESS,
        failure: CONSTANTS.GET_BEACONS_FAILURE,
        ...payload,
    });
export const getGeofenceList = (payload) =>
    queryApi({
        request: CONSTANTS.GET_GEOFENCE,
        url: `cms/v1/article-workflow/articletype/GeoFence Information`,
        encode: false,
        success: CONSTANTS.GET_GEOFENCE_SUCCESS,
        failure: CONSTANTS.GET_GEOFENCE_FAILURE,
        ...payload,
    });

export const queryArticleFormOptions = (payload) =>
    queryApi({
        request: CONSTANTS.ARTICLE_OPTIONS,
        url: `cms/v1/article-form/options`,
        encode: false,
        success: CONSTANTS.ARTICLE_OPTIONS_SUCCESS,
        failure: CONSTANTS.ARTICLE_OPTIONS_FAILURE,
        ...payload,
    });

export const getCouponCodeList = (payload) =>
    queryApi({
        request: CONSTANTS.GET_COUPONCODE,
        url: `campaign/v1/coupon/report/coupon/options`,
        encode: false,
        success: CONSTANTS.GET_COUPONCODE_SUCCESS,
        failure: CONSTANTS.GET_COUPONCODE_FAILURE,
        ...payload,
    });

export const searchByCouponRule = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_COUPONRULE,
        url: `campaign/v1/coupon/rule/search/fields`,
        encode: false,
        success: CONSTANTS.SEARCH_COUPONRULE_SUCCESS,
        failure: CONSTANTS.SEARCH_COUPONRULE_FAILURE,
        ...payload,
    });

export const searchCoupon = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_COUPON,
        url: `/campaign/v1/coupon/search`,
        encode: false,
        success: CONSTANTS.SEARCH_COUPON_SUCCESS,
        failure: CONSTANTS.SEARCH_COUPON_FAILURE,
        ...payload,
    });

export const searchCouponByFields = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_COUPON_BY_FIELDS,
        url: `/campaign/v1/coupon/search/fields`,
        encode: false,
        success: CONSTANTS.SEARCH_COUPON_BY_FIELDS_SUCCESS,
        failure: CONSTANTS.SEARCH_COUPON_BY_FIELDS_FAILURE,
        ...payload,
    });

// Media List
export const queryMediaList = (payload) =>
    queryApi({
        request: CONSTANTS.GET_MEDIA_LIST,
        url: `cms/v1/media/list`,
        encode: false,
        success: CONSTANTS.GET_MEDIA_LIST_SUCCESS,
        failure: CONSTANTS.GET_MEDIA_LIST_FAILURE,

        noLoading: true,
        ...payload,
    });
export const queryMediaFolders = (payload) =>
    queryApi({
        request: CONSTANTS.GET_MEDIA_FOLDERS,
        url: `cms/v1/media/list`,
        encode: false,
        success: CONSTANTS.GET_MEDIA_FOLDERS_SUCCESS,
        failure: CONSTANTS.GET_MEDIA_FOLDERS_FAILURE,
        noLoading: true,
        ...payload,
    });

export const loadingApi = () => ({
    type: CONSTANTS.API_PENDING,
})

// CRUD APIzzz

export const crudApi = ({ request, ...payloadWithoutRequest }) => {
    return {
        type: request,
        payload: payloadWithoutRequest,
    };
};
export const crudReset = () => ({
    type: CONSTANTS.CRUD_RESET,
});
export const productArticleReset = () => ({
    type: CONSTANTS.PRODUCT_ARTICLE_RESET,
});

export const updateStoreHours = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/store/update`,
        encode: false,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createFolderApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/media/folder/create`,
        encode: false,
        success: CONSTANTS.CREATE_FOLDER_SUCCESS,
        failure: CONSTANTS.CREATE_FOLDER_FAILURE,
        method: 'POST',
        ...payload,
    });
export const deleteFolderApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/media/folder/delete`,
        encode: false,
        crudQuery: true,
        success: CONSTANTS.DELETE_FOLDER_SUCCESS,
        failure: CONSTANTS.DELETE_FOLDER_FAILURE,
        method: 'POST',
        ...payload,
    });
export const uploadFileApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/media/file/upload`,
        // encode: false,
        success: CONSTANTS.UPLOAD_FILE_SUCCESS,
        failure: CONSTANTS.UPLOAD_FILE_FAILURE,
        method: 'POST',
        ...payload,
    });
export const deleteFileApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/media/file/delete`,
        encode: false,
        crudQuery: true,
        success: CONSTANTS.REMOVE_FILE_SUCCESS,
        failure: CONSTANTS.REMOVE_FILE_FAILURE,
        method: 'POST',
        ...payload,
    });
export const generateThumbnailApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/media/resize`,
        success: CONSTANTS.GENERATE_THUMBNAIL_SUCCESS,
        failure: CONSTANTS.GENERATE_THUMBNAIL_FAILURE,
        method: 'POST',
        ...payload,
    });
export const moveFileApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/cms/v1/media/file/move`,
        encode: false,
        crudQuery: true,
        success: CONSTANTS.MOVE_FILE_SUCCESS,
        failure: CONSTANTS.MOVE_FILE_FAILURE,
        method: 'POST',
        noLoading: true,
        ...payload,
    });
export const copyFileApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/cms/v1/media/file/copy`,
        encode: false,
        crudQuery: true,
        success: CONSTANTS.COPY_FILE_SUCCESS,
        failure: CONSTANTS.COPY_FILE_FAILURE,
        method: 'POST',
        ...payload,
    });
export const renameFileApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/media/file/delete`,
        encode: false,
        crudQuery: true,
        success: CONSTANTS.REMOVE_FILE_SUCCESS,
        failure: CONSTANTS.REMOVE_FILE_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateMediaList = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/cms/v1/media/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const resetAction = () => ({
    type: 'RESET',
});

export const resetErrorOnly = () => ({
    type: 'RESET_ERROR_ONLY',
});

export const createArticleInfoApi = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-workflow/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateArticleInfoApi = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-workflow/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteArticleInfoApi = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-workflow/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const copyArticleInfoApi = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-workflow/copy/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
    });
};

export const unpublishArticleInfoApi = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article/unpublish/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
    });
};

export const publisArticleInfoApi = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article/publish/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
    });
};

// Article Forms
export const queryArticleForm = (payload) =>
    queryApi({
        request: CONSTANTS.FETCH_ARTCILE_FORM,
        url: `cms/v1/article-form/search`,
        encode: false,
        success: CONSTANTS.FETCH_ARTCILE_FORM_SUCCESS,
        failure: CONSTANTS.FETCH_ARTCILE_FORM_FAILURE,
        ...payload,
    });
export const createArticleForm = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-form/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateArticleForm = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-form/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteArticleForm = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/article-form/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// Config Actions
export const queryDefaultConfigByTitle = (
    payload,
    REQUEST,
    SUCCESS,
    FAILURE
) => {
    return queryApi({
        request: REQUEST,
        url: `system/v1/config-value/title`,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    });
};

//search/fields api
export const queryDefaultConfigByFields = (
    payload,
    REQUEST,
    SUCCESS,
    FAILURE
) => {
    return queryApi({
        request: REQUEST,
        url: `system/v1/config-value/search/fields`,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    });
};

//search/fields api
// export const queryStoreGroupsByFields = (
//   payload, REQUEST, SUCCESS, FAILURE
// ) => {
//   return queryApi({
//     request: REQUEST,
//     url: `system/v1/store/group/search/fields`,
//     encode: false,
//     success: SUCCESS,
//     failure: FAILURE,
//     noLoading: true,
//     ...payload
//   })
// }

// language prop
export const queryPropertyConfigByFields = (
    payload,
    REQUEST,
    SUCCESS,
    FAILURE
) => {
    return queryApi({
        request: REQUEST,
        url: `cms/v1/prop/config/search/fields`,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    });
};

// CRM MODULE

// Article Forms

export const createUserProfile = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `crm/v1/member/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateUserProfile = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `crm/v1/member/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteUserProfile = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `crm/v1/member/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};
export const searchUserProfile = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_USERPROFILE,
        url: `crm/v1/member/search/fields`,
        encode: false,
        success: CONSTANTS.SEARCH_USERPROFILE_SUCCESS,
        failure: CONSTANTS.SEARCH_USERPROFILE_FAILURE,
        ...payload,
    });

export const createMemberTag = (payload) => {
    return crudApi({
        request: CONSTANTS.CUSTOMER_TAG_REQUEST,
        url: `crm/v1/member/tag/save`,
        success: CONSTANTS.CUSTOMER_TAG_SUCCESS,
        failure: CONSTANTS.CUSTOMER_TAG_FAILURE,
        method: 'POST',
        ...payload,
    });
}
    
export const updateMemberTag = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `crm/v1/member/tag/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteMemberTag = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `crm/v1/member/tag/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// Common Crud request
export const createItem = (url, payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: url,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateItem = (url, payload, id, method = 'PUT') =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: url,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: method,
        ...payload,
    });

export const updateInventoryByStore = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/sales/v1/store/inventory/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteItem = (url, id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `${url}/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};
export const deleteAddonRow = (url, id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `${url}${id}`,
        success: CONSTANTS.ADDON_DELETE_SUCCESS,
        failure: CONSTANTS.ADDON_DELETE_FAILURE,
        method: 'DELETE',
    });
};
export const searchGeneral = (url, payload, SUCCESS, FAILURE, noLoading) => {
    return queryApi({
        request: CONSTANTS.SEARCH_DATA,
        url: url,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    })
}
// Get product tree
export const queryCategoryTree = (
    payload,
    categoryCode,
    extraParam,
    request = CONSTANTS.CATEGORY_TREE,
    success = CONSTANTS.CATEGORY_TREE_SUCCESS,
    failure = CONSTANTS.CATEGORY_TREE_FAILURE
) =>
    queryApi({
        request: request,
        url: `sales/v1/category/categorytree/${categoryCode}?cached=N${
            extraParam ? '&' + extraParam : ''
        }`,
        encode: false,
        success: success,
        failure: failure,
        noLoading: true,
        ...payload,
    });
// Get list by category code
export const queryByCategoryCode = (payload, categoryCode) =>
    queryApi({
        request: CONSTANTS.PROD_CAT_SEARCH,
        url: `sales/v1/category/categorycode/${categoryCode}`,
        encode: false,
        success: CONSTANTS.PROD_CAT_SEARCH_SUCCESS,
        failure: CONSTANTS.PROD_CAT_SEARCH_FAILURE,
        ...payload,
    });

// Configuration
export const createDefaultConfig = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/config-value/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateConfiguation = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/config-value/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteDefaultConfig = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/config-value/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

//for add map store
export const createAddMapStore = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/sales/v1/store/product/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const deleteStoreProductMap = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/sales/v1/store/product/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// delete product catalog
export const deleteProductCatalog = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/sales/v1/product/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// For sales summary
export const querySalesSummaryInfoByDate = (payload) =>
    queryApi({
        request: CONSTANTS.SALES_SUMMARY_DATE,
        url: `pos/v1/report/sales/summary`,
        encode: false,
        success: CONSTANTS.SALES_SUMMARY_DATE_SUCCESS,
        failure: CONSTANTS.SALES_SUMMARY_DATE_FAILURE,
        ...payload,
    });

export const querySalesSummaryInfoByHour = (payload) =>
    queryApi({
        request: CONSTANTS.SALES_SUMMARY_HOUR,
        url: `pos/v1/report/sales/summary`,
        encode: false,
        success: CONSTANTS.SALES_SUMMARY_HOUR_SUCCESS,
        failure: CONSTANTS.SALES_SUMMARY_HOUR_FAILURE,
        ...payload,
    });

export const querySalesSummaryInfoByMonth = (payload) =>
    queryApi({
        request: CONSTANTS.SALES_SUMMARY_MONTH,
        url: `pos/v1/report/sales/summary`,
        encode: false,
        success: CONSTANTS.SALES_SUMMARY_MONTH_SUCCESS,
        failure: CONSTANTS.SALES_SUMMARY_MONTH_FAILURE,
        ...payload,
    });

export const querySalesSummaryInfoByYear = (payload) =>
    queryApi({
        request: CONSTANTS.SALES_SUMMARY_YEAR,
        url: `pos/v1/report/sales/summary`,
        encode: false,
        success: CONSTANTS.SALES_SUMMARY_YEAR_SUCCESS,
        failure: CONSTANTS.SALES_SUMMARY_YEAR_FAILURE,
        ...payload,
    });

//product sales summary
export const queryProductSalesSummary = (payload) =>
    queryApi({
        request: CONSTANTS.PRODUCT_SALES_SUMMARY,
        url: `pos/v1/report/product/sales/summary`,
        encode: false,
        success: CONSTANTS.PRODUCT_SALES_SUMMARY_SUCCESS,
        failure: CONSTANTS.PRODUCT_SALES_SUMMARY_FAILURE,
        ...payload,
    });

//member sales summary
export const queryMemberSalesSummary = (payload) =>
    queryApi({
        request: CONSTANTS.MEMBER_SALES_SUMMARY,
        url: `pos/v1/report/member/sales/summary`,
        encode: false,
        success: CONSTANTS.MEMBER_SALES_SUMMARY_SUCCESS,
        failure: CONSTANTS.MEMBER_SALES_SUMMARY_FAILURE,
        ...payload,
    });

//member report
export const queryDeviceByMember = (payload) =>
    queryApi({
        request: CONSTANTS.MEMBER_DEVICE_MODEL,
        url: `/crm/v1/member/report/devicemodel/summary`,
        encode: false,
        success: CONSTANTS.MEMBER_DEVICE_MODEL_SUCCESS,
        failure: CONSTANTS.MEMBER_DEVICE_MODEL_FAILURE,
        ...payload,
    });

export const queryDeviceByGuest = (payload) =>
    queryApi({
        request: CONSTANTS.GUEST_DEVICE_MODEL,
        url: `/crm/v1/member/report/devicemodel/summary`,
        encode: false,
        success: CONSTANTS.GUEST_DEVICE_MODEL_SUCCESS,
        failure: CONSTANTS.GUEST_DEVICE_MODEL_FAILURE,
        ...payload,
    });

export const deviceTypeMember = (payload) =>
    queryApi({
        request: CONSTANTS.MEMBER_DEVICE_TYPE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.MEMBER_DEVICE_TYPE_SUCCESS,
        failure: CONSTANTS.MEMBER_DEVICE_TYPE_FAILURE,
        ...payload,
    });

export const queryActiveMembers = (payload) =>
    queryApi({
        request: CONSTANTS.ACTIVE_MEMBER_DEVICE_TYPE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.ACTIVE_MEMBER_DEVICE_TYPE_SUCCESS,
        failure: CONSTANTS.ACTIVE_MEMBER_DEVICE_TYPE_FAILURE,
        ...payload,
    });

export const queryNewMembers = (payload) =>
    queryApi({
        request: CONSTANTS.NEW_MEMBER_DEVICE_TYPE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.NEW_MEMBER_DEVICE_TYPE_SUCCESS,
        failure: CONSTANTS.NEW_MEMBER_DEVICE_TYPE_FAILURE,
        ...payload,
    });

export const queryInactiveMembers = (payload) =>
    queryApi({
        request: CONSTANTS.INACTIVE_MEMBER_DEVICE_TYPE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.INACTIVE_MEMBER_DEVICE_TYPE_SUCCESS,
        failure: CONSTANTS.INACTIVE_MEMBER_DEVICE_TYPE_FAILURE,
        ...payload,
    });

export const deviceTypeGuest = (payload) =>
    queryApi({
        request: CONSTANTS.GUEST_DEVICE_TYPE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.GUEST_DEVICE_TYPE_SUCCESS,
        failure: CONSTANTS.GUEST_DEVICE_TYPE_FAILURE,
        ...payload,
    });

export const deviceTypeGuestDate = (payload) =>
    queryApi({
        request: CONSTANTS.GUEST_DEVICE_TYPE_DATE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.GUEST_DEVICE_TYPE_DATE_SUCCESS,
        failure: CONSTANTS.GUEST_DEVICE_TYPE_DATE_FAILURE,
        ...payload,
    });

export const deviceTypeMemberDate = (payload) =>
    queryApi({
        request: CONSTANTS.MEMBER_DEVICE_TYPE_DATE,
        url: `/crm/v1/member/report/devicetype/summary`,
        encode: false,
        success: CONSTANTS.MEMBER_DEVICE_TYPE_DATE_SUCCESS,
        failure: CONSTANTS.MEMBER_DEVICE_TYPE_DATE_FAILURE,
        ...payload,
    });

export const retrieveDeviceInfo = (id) =>
    queryApi({
        request: CONSTANTS.MEMBER_DEVICE_INFO,
        url: `/crm/v1/member/device/id/${id}`,
        encode: false,
        success: CONSTANTS.MEMBER_DEVICE_INFO_SUCCESS,
        failure: CONSTANTS.MEMBER_DEVICE_INFO_FAILURE,
    });

// For store product mapping
export const queryStoreCategoryInfo = (payload) =>
    queryApi({
        request: CONSTANTS.STORE_CAT_SEARCH,
        url: `sales/v1/store/product/search/fields`,
        encode: false,
        success: CONSTANTS.STORE_CAT_SEARCH_SUCCESS,
        failure: CONSTANTS.STORE_CAT_SEARCH_FAILURE,
        ...payload,
    });

export const storeProductSearchByFields = (queryParam) =>
    crudApi({
        request: CONSTANTS.STORE_PRODUCT_SEARCH,
        url: `sales/v1/store/product/search/fields?${queryParam}`,
        encode: false,
        success: CONSTANTS.STORE_PRODUCT_SEARCH_SUCCESS,
        failure: CONSTANTS.STORE_PRODUCT_SEARCH_FAILURE,
        method: 'GET',
    });

export const getProductMapInfoById = (payload) => {
    return queryApi({
        request: CONSTANTS.PRODUCT_UPDATE,
        url: `/sales/v1/product/search`,
        encode: false,
        success: CONSTANTS.PRODUCT_UPDATE_SUCCESS,
        failure: CONSTANTS.PRODUCT_UPDATE_FAILURE,
        ...payload,
    });
};

// cart
export const voidCart = (payload, cartid) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v2/cart/${cartid}/void`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
};
export const suspendCart = (payload, cartid) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v2/cart/${cartid}/suspend`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
};
export const closeCart = (payload, cartid) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v2/cart/${cartid}/close`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
};
export const getByOrderId = (url) => {
    return queryApi({
        request: CONSTANTS.GET_BY_ORDER_ID,
        url: url,
        encode: false,
        success: CONSTANTS.GET_BY_ORDER_ID_SUCCESS,
        failure: CONSTANTS.GET_BY_ORDER_ID_FAILURE,
    });
};
export const getByOrderAndSessionId = (payload, cartid, orderid) =>
    queryApi({
        request: CONSTANTS.GET_BY_ORDER_SESSION_ID,
        url: `sales/v2/cart/${cartid}/${orderid}`,
        encode: false,
        success: CONSTANTS.GET_BY_ORDER_SESSION_ID_SUCCESS,
        failure: CONSTANTS.GET_BY_ORDER_SESSION_ID_FAILURE,
        ...payload,
    });

// Coupon Management
export const createCoupon = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateCoupon = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteCoupon = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// Coupon Rules
export const createCouponRule = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/rule/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateCouponRule = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/rule/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteCouponRule = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/rule/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// Coupon Redemption Rules
export const createCouponRedemptionRule = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/redemption/rule/save `,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateCouponRedemptionRule = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/redemption/rule/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteCouponRedemptionRule = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/redemption/rule/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};
// delete coupon trx
export const deleteCouponTransactionApi = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/transaction/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};
export const saveCouponTrx = (payload) => {
    const { couponqty } = payload.body
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/transaction/save?${couponqty}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
};
export const saveMultipleCouponTrx = (payload, id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/transaction/save/batch`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
};
export const issueCoupon = (payload, qty, autoexport, validityoverride) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/transaction/save?issueqty=${qty}&export=${autoexport}&validityoverride=${validityoverride}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
};
export const updateCouponTrx = (payload, id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/coupon/transaction/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
};

export const queryCouponTrx = (payload) =>
    queryApi({
        request: CONSTANTS.STORE_INV_SEARCH,
        url: `/campaign/v1/coupon/transaction/search`,
        encode: true,
        success: CONSTANTS.STORE_INV_SEARCH_SUCCESS,
        failure: CONSTANTS.STORE_INV_SEARCH_FAILURE,
        noLoading: true,
        ...payload,
    });

export const couponTrxSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_COUPON_TRX_BY_FIELDS,
        url: `/campaign/v1/coupon/transaction/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_COUPON_TRX_SUCCESS,
        failure: CONSTANTS.SEARCH_COUPON_TRX_FAILURE,
        method: 'GET',
    });
};

// point rule
export const createPointRule = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/point/rule/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updatePointRule = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/point/rule/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deletePointRule = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `campaign/v1/point/rule//delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

//Product Mapping(sale document)
export const createSaleDocument = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/product/document/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateSaleDocument = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/product/document/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const deleteSaleDocument = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/product/document/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// Product Addon
export const createAddon = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/store/product/addon/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateAddons = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/store/product/addon/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

// export const createAddon = (payload, isForMultiple) => {
//   debugger
//   return crudApi({
//     request:
//       isForMultiple === true
//         ? CONSTANTS.CRUD_REQUEST_FOR_ADDON
//         : CONSTANTS.CRUD_REQUEST,
//     url: `sales/v1/store/product/addon/save`,
//     success:
//       isForMultiple === true
//         ? CONSTANTS.CRUD_REQUEST_FOR_ADDON_SUCCESS
//         : CONSTANTS.CREATE_ADDON_SUCCESS,
//     failure:
//       isForMultiple === true
//         ? CONSTANTS.CRUD_REQUEST_FOR_ADDON_FAILURE
//         : CONSTANTS.CREATE_ADDON_FAILURE,
//     method: "POST",
//     ...payload
//   });
// };
// export const updateAddons = (payload, isForMultiple) => {
//   debugger
//   return crudApi({
//     request:
//       isForMultiple === true
//         ? CONSTANTS.CRUD_REQUEST_FOR_ADDON
//         : CONSTANTS.CRUD_REQUEST,
//     url: `sales/v1/store/product/addon/update`,
//     success:
//       isForMultiple === true
//         ? CONSTANTS.CRUD_REQUEST_FOR_ADDON_SUCCESS
//         : CONSTANTS.UPDATE_ADDON_SUCCESS,
//     failure:
//       isForMultiple === true
//         ? CONSTANTS.CRUD_REQUEST_FOR_ADDON_FAILURE
//         : CONSTANTS.UPDATE_ADDON_FAILURE,
//     method: "PUT",
//     ...payload
//   });
// }

export const searchByStoreProdId = (payload, id) =>
    queryApi({
        request: CONSTANTS.SEARCH_BY_STOREPRODID,
        url: 'sales/v1/store/product/addon/search/fields',
        encode: false,
        success: CONSTANTS.SEARCH_BY_STOREPRODID_SUCCESS,
        failure: CONSTANTS.SEARCH_BY_STOREPRODID_FAILURE,
        ...payload,
    });

// Inventory Service

export const createAddMapInventory = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `/sales/v1/store/inventory/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const queryInventoryByStore = (payload) =>
    queryApi({
        request: CONSTANTS.STORE_INV_SEARCH,
        url: `/sales/v1/store/inventory/search/fields`,
        encode: true,
        success: CONSTANTS.STORE_INV_SEARCH_SUCCESS,
        failure: CONSTANTS.STORE_INV_SEARCH_FAILURE,
        noLoading: true,
        ...payload,
    });

export const queryInvTrx = (payload) =>
    queryApi({
        request: CONSTANTS.INV_TRX_SEARCH,
        url: `/sales/v1/store/inventory/tr/search/fields`,
        encode: false,
        success: CONSTANTS.INV_TRX_SEARCH_SUCCESS,
        failure: CONSTANTS.INV_TRX_SEARCH_FAILURE,
        noLoading: true,
        ...payload,
    });

/*
export const getStoreById = id => {
  return queryApi({
    request: CONSTANTS.SEARCH_STORE_BY_ID,
    url: `sales/v1/store/storeid/${id}`,
    success: CONSTANTS.SEARCH_STORE_BY_ID_SUCCESS,
    failure: CONSTANTS.SEARCH_STORE_BY_ID_FAILURE
  });
};
*/

export const getStoreById = (id) => {
    return queryApi({
        request: CONSTANTS.SEARCH_STORE_BY_ID,
        url: `system/v1/store/id/${id}`,
        success: CONSTANTS.SEARCH_STORE_BY_ID_SUCCESS,
        failure: CONSTANTS.SEARCH_STORE_BY_ID_FAILURE,
    });
};

export const getPaymentInvoiceDetail = (invoiceId) => {
    return queryApi({
        request: CONSTANTS.SEARCH_SALE_INVOICE_PAYMENT_TYPES,
        url: `ext-ns/v1/collection/payment?invoiceid=${invoiceId}`,
        success: CONSTANTS.SEARCH_SALE_INVOICE_PAYMENT_TYPES_SUCCESS,
        failure: CONSTANTS.SEARCH_SALE_INVOICE_PAYMENT_TYPES_FAILURE,
    });
};

export const getStoreProductCode = (payload) => {
    return queryApi({
        request: CONSTANTS.STORE_PRODUCTCODE,
        url: `sales/v1/product-search/productcodes`,
        success: CONSTANTS.STORE_PRODUCTCODE_SUCCESS,
        failure: CONSTANTS.STORE_PRODUCTCODE_FAILURE,
        ...payload,
    });
};

//sales promo
export const createSalesPromo = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateSalesPromo = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteSalesPromo = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const createSalesPromoPurchaseGroup = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/purchase/save`,
        success: CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateSalesPromoPurchaseGroup = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/purchase/update`,
        success: CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteSalesPromoPurchaseGroup = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/purchase/delete/${id}`,
        success: CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const createSalesPromoTargetGroup = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/product/save`,
        success: CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateSalesPromoTargetGroup = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/product/update`,
        success: CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteSalesPromoTargetGroup = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `pos/v1/promo/product/delete/${id}`,
        success: CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

//property config
export const createPropertyConfig = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/prop/config/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updatePropertyConfig = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/prop/config/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
export const copyPropertyConfig = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/prop/config/copy/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
    });
};
export const deletePropertyConfig = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/prop/config/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const queryPropertyConfig = (
    payload,
    request = CONSTANTS.SEARCH_PROPCONFIG,
    success = CONSTANTS.SEARCH_PROPCONFIG_SUCCESS,
    failure = CONSTANTS.SEARCH_PROPCONFIG_FAILURE
) =>
    queryApi({
        request: request,
        url: `cms/v1/prop/config/search/fields`,
        encode: false,
        success: success,
        failure: failure,
        ...payload,
    });

export const deliverycountryConfig = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_CNCONFIG,
        url: `cms/v1/prop/config/search/fields`,
        encode: false,
        success: CONSTANTS.SEARCH_CNCONFIG_SUCCESS,
        failure: CONSTANTS.SEARCH_CNCONFIG_FAILURE,
        ...payload,
    });

export const deliverytypeConfig = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_DTYPECONFIG,
        url: `cms/v1/prop/config/search/fields`,
        encode: false,
        success: CONSTANTS.SEARCH_DTYPECONFIG_SUCCESS,
        failure: CONSTANTS.SEARCH_DTYPECONFIG_FAILURE,
        ...payload,
    });

//word translation

export const createWordTranslation = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/word/translation/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateWordTranslation = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/word/translation/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteWordTranslation = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `cms/v1/word/translation/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// delivery area

export const createDeliveryArea = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/delivery/area/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateDeliveryArea = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/delivery/area/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteDeliveryArea = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/delivery/area/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// operation slot
export const createDeliverySlot = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/delivery/slot/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateDeliverySlot = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/delivery/slot/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteDeliverySlot = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/delivery/slot/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const searchfieldsDeliverySlot = (payload) =>
    queryApi({
        request: CONSTANTS.SEARCH_DELIVERYSLOT,
        url: `ops/v1/delivery/slot/search/fields`,
        encode: false,
        success: CONSTANTS.SEARCH_DELIVERYSLOT_SUCCESS,
        failure: CONSTANTS.SEARCH_DELIVERYSLOT_FAILURE,
        ...payload,
    });

// inventory transaction
export const createInvTransaction = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/store/inventory/tr/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateInvTransaction = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `sales/v1/store/inventory/tr/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

// order fulfillment
//delivery
export const createDelivery = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/delivery/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const updateDelivery = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/delivery/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteDelivery = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/delivery/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

// shipping
export const createShipping = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/shipping/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateShipping = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/shipping/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

// return item
export const createReturnItem = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/shipping/return/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateReturnItem = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ops/v1/task/shipping/return/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const userAccessSearch = (queryParam) =>
    crudApi({
        request: CONSTANTS.SEARCH_USERACCESS_BY_FIELDS,
        url: `system/v1/user/access/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_USERACCESS_SUCCESS,
        failure: CONSTANTS.SEARCH_USERACCESS_FAILURE,
        method: 'GET',
    });

export const userRoleAccessSearch = (queryParam) =>
    crudApi({
        request: CONSTANTS.SEARCH_USERROLESACCESS_BY_FIELDS,
        url: `system/v1/user/role/access/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_USERROLESACCESS_SUCCESS,
        failure: CONSTANTS.SEARCH_USERROLESACCESS_FAILURE,
        method: 'GET',
    });

export const userGroupSearch = (queryParam) =>
    crudApi({
        request: CONSTANTS.SEARCH_USERGROUPS_BY_FIELDS,
        url: `system/v1/user/group/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_USERGROUPS_SUCCESS,
        failure: CONSTANTS.SEARCH_USERGROUPS_FAILURE,
        method: 'GET',
    });

export const userRoleSearch = (queryParam) =>
    crudApi({
        request: CONSTANTS.SEARCH_USERROLES_BY_FIELDS,
        url: `system/v1/user/role/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_USERROLES_SUCCESS,
        failure: CONSTANTS.SEARCH_USERROLES_FAILURE,
        method: 'GET',
    });

export const createUser = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateUser = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createUserGroup = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/group/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateUserGroup = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/group/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createUserRoleAccesses = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/role/access/save/batch`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateUserRoleAccesses = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/role/access/update/batch`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createUserRole = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/role/save`,
        success: CONSTANTS.USERROLE_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.USERROLE_CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateUserRole = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/user/role/update`,
        success: CONSTANTS.USERROLE_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.USERROLE_CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const storeSearchByFields = (
    queryParam,
    success = CONSTANTS.SEARCH_STORE_SUCCESS,
    failure = CONSTANTS.SEARCH_STORE_FAILURE
) =>
    crudApi({
        request: CONSTANTS.SEARCH_STORE_BY_FIELDS,
        url: `system/v1/store/search/fields?${queryParam}`,
        success: success,
        failure: failure,
        method: 'GET',
    });

export const createStore = (payload) =>
    crudApi({
        request: CONSTANTS.STORE_CREATE_UPDATE_REQUEST,
        url: `system/v1/store/save`,
        success: CONSTANTS.STORE_CREATE_UPDATE_SUCCESS,
        failure: CONSTANTS.STORE_CREATE_UPDATE_FAILURE,
        method: 'POST',
        ...payload,
    });

export const createStoreTagBatch = (payload) => {
    return crudApi({
        request: CONSTANTS.STORE_TAG_BATCH_REQUEST,
        url: `system/v1/store/tag/save/batch`,
        success: CONSTANTS.STORE_TAG_BATCH_SUCCESS,
        failure: CONSTANTS.STORE_TAG_BATCH_FAILURE,
        method: 'POST',
        ...payload,
    });
}  

export const createDeviceTagBatch = (payload) => {
    return crudApi({
        request: CONSTANTS.DEVICE_TAG_BATCH_REQUEST,
        url: `system/v1/store/device/save/batch`,
        success: CONSTANTS.DEVICE_TAG_BATCH_SUCCESS,
        failure: CONSTANTS.DEVICE_TAG_BATCH_FAILURE,
        method: 'POST',
        ...payload,
    });
} 

export const updateStore = (payload, id) =>
    crudApi({
        request: CONSTANTS.STORE_CREATE_UPDATE_REQUEST,
        url: `system/v1/store/update`,
        success: CONSTANTS.STORE_CREATE_UPDATE_SUCCESS,
        failure: CONSTANTS.STORE_CREATE_UPDATE_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteStore = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const createStoreGroup = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/group/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateStoreGroup = (payload, id) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/group/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const storeGroupSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_STORE_GROUP_BY_FIELDS,
        url: `system/v1/store/group/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_STORE_GROUP_SUCCESS,
        failure: CONSTANTS.SEARCH_STORE_GROUP_FAILURE,
        method: 'GET',
    });
};

export const deleteStoreGroup = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/group/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const companySearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_COMPANY_BY_FIELDS,
        url: `system/v1/company/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_COMPANY_SUCCESS,
        failure: CONSTANTS.SEARCH_COMPANY_FAILURE,
        method: 'GET',
    });
};

export const countrySearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_COUNTRY_BY_FIELDS,
        url: `system/v1/country/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_COUNTRY_SUCCESS,
        failure: CONSTANTS.SEARCH_COUNTRY_FAILURE,
        method: 'GET',
    });
};

export const citySearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_CITY_BY_FIELDS,
        url: `system/v1/city/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_CITY_SUCCESS,
        failure: CONSTANTS.SEARCH_CITY_FAILURE,
        method: 'GET',
    });
};

export const promotionSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_PROMOTIONS_BY_FIELDS,
        url: `pos/v1/promo/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_PROMOTIONS_SUCCESS,
        failure: CONSTANTS.SEARCH_PROMOTIONS_FAILURE,
        method: 'GET',
    });
};

export const promotionPGroupSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_PROMOTIONS_PGROUP_BY_FIELDS,
        url: `pos/v1/promo/purchase/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_PROMOTIONS_PGROUP_SUCCESS,
        failure: CONSTANTS.SEARCH_PROMOTIONS_PGROUP_FAILURE,
        method: 'GET',
    });
};

export const promotionTGroupSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_PROMOTIONS_TGROUP_BY_FIELDS,
        url: `pos/v1/promo/product/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_PROMOTIONS_TGROUP_SUCCESS,
        failure: CONSTANTS.SEARCH_PROMOTIONS_TGROUP_FAILURE,
        method: 'GET',
    });
};

export const createStoreTerminal = (payload) =>
    crudApi({
        request: CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_REQUEST,
        url: `system/v1/store/device/save`,
        success: CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_SUCCESS,
        failure: CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateStoreTerminal = (payload) =>
    crudApi({
        request: CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_REQUEST,
        url: `system/v1/store/device/update`,
        success: CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_SUCCESS,
        failure: CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createStoreDevice = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/device/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateStoreDevice = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/device/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteStoreDevice = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/device/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const storeDevicesSearchByFields = (
    queryParam,
    request = CONSTANTS.SEARCH_STORE_DEVICES_FIELDS,
    success = CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS,
    failure = CONSTANTS.SEARCH_STORE_DEVICES_FAILURE
) => {
    return crudApi({
        request: request,
        url: `system/v1/store/device/search/fields?${queryParam}`,
        success: success,
        failure: failure,
        method: 'GET',
    });
};

export const storeTerminalsSearchByFields = (
    queryParam,
    success = CONSTANTS.SEARCH_STORE_TERMINALS_SUCCESS,
    failure = CONSTANTS.SEARCH_STORE_TERMINALS_FAILURE
) => {
    return crudApi({
        request: CONSTANTS.SEARCH_STORE_TERMINALS_FIELDS,
        url: `system/v1/store/device/search/fields?${queryParam}`,
        success: success,
        failure: failure,
        method: 'GET',
    });
};

export const createStoreTag = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/tag/save`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateStoreTag = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/tag/update`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const deleteStoreTag = (id) => {
    return crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `system/v1/store/tag/delete/${id}`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};

export const storeTagsSearchByFields = (
    queryParam,
    request = CONSTANTS.SEARCH_STORE_TAGS_FIELDS,
    success = CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
    failure = CONSTANTS.SEARCH_STORE_TAGS_FAILURE
) => {
    return crudApi({
        request: request,
        url: `system/v1/store/tag/search/fields?${queryParam}`,
        success: success,
        failure: failure,
        method: 'GET',
    });
};

export const apiLogsSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_API_LOGS_FIELDS,
        url: `system/v1/apilog/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_API_LOGS_SUCCESS,
        failure: CONSTANTS.SEARCH_API_LOGS_FAILURE,
        method: 'GET',
    });
};

export const epiMessageSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_EIP_MESSAGE_FIELDS,
        url: `system/v1/apilog/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_EIP_MESSAGE_SUCCESS,
        failure: CONSTANTS.SEARCH_EIP_MESSAGE_FAILURE,
        method: 'GET',
    });
};

export const apiLogsById = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_API_LOGS_BY_ID,
        url: `system/v1/apilog/id/${queryParam}`,
        success: CONSTANTS.SEARCH_API_LOGS_BY_ID_SUCCESS,
        failure: CONSTANTS.SEARCH_API_LOGS_BY_ID_FAILURE,
        method: 'GET',
    });
};

export const jobTaskLogsSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_JOB_TASK_LOGS_BY_ID,
        url: `system/v1/jobtask/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
        failure: CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE,
        method: 'GET',
    });
};

export const jobTaskLogsById = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_JOB_TASK_LOGS_BY_ID,
        url: `system/v1/jobtask/id/${queryParam}`,
        success: CONSTANTS.SEARCH_JOB_TASK_LOGS_BY_ID_SUCCESS,
        failure: CONSTANTS.SEARCH_JOB_TASK_LOGS_BY_ID_FAILURE,
        method: 'GET',
    });
};

export const dataSyncLogsSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_DATASYNC_LOGS_FIELDS,
        url: `system/v1/datasynclog/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_DATASYNC_LOGS_SUCCESS,
        failure: CONSTANTS.SEARCH_DATASYNC_LOGS_FAILURE,
        method: 'GET',
    });
};

export const dataSyncLogsById = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_DATASYNC_LOGS_BY_ID,
        url: `system/v1/datasynclog/id/${queryParam}`,
        success: CONSTANTS.SEARCH_DATASYNC_LOGS_BY_ID_SUCCESS,
        failure: CONSTANTS.SEARCH_DATASYNC_LOGS_BY_ID_FAILURE,
        method: 'GET',
    });
};

export const exportedCouponTransactions = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_EXPORTED_COUPON_TRANSACTIONS,
        url: `system/v1/jobtask/search/fields?${queryParam}`,
        success: CONSTANTS.SEARCH_EXPORTED_COUPON_TRANSACTIONS_SUCCESS,
        failure: CONSTANTS.SEARCH_EXPORTED_COUPON_TRANSACTIONS_FAILURE,
        method: 'GET',
    });
};

export const exportCouponTransactions = (queryParam) => {
    return crudApi({
        request: CONSTANTS.EXPORT_COUPON_TRANSACTIONS,
        url: `/campaign/v1/coupon/transaction/search/fields/export?${queryParam}`,
        success: CONSTANTS.EXPORT_COUPON_TRANSACTIONS_SUCCESS,
        failure: CONSTANTS.EXPORT_COUPON_TRANSACTIONS_FAILURE,
        method: 'GET',
    });
};

export const salesDetailsReport = (queryParam) =>
    crudApi({
        request: CONSTANTS.SALES_DETAILS_REPORT,
        url: `pos/v1/report/sales/details?${queryParam}`,
        encode: false,
        success: CONSTANTS.SALES_DETAILS_REPORT_SUCCESS,
        failure: CONSTANTS.SALES_DETAILS_REPORT_FAILURE,
        method: 'GET',
    });

export const posMasterDetailsReport = (queryParam) =>
    crudApi({
        request: CONSTANTS.POS_MASTER_DETAILS_REPORT,
        url: `pos/v1/report/master?${queryParam}`,
        encode: false,
        success: CONSTANTS.POS_MASTER_DETAILS_REPORT_SUCCESS,
        failure: CONSTANTS.POS_MASTER_DETAILS_REPORT_FAILURE,
        method: 'GET',
    });

export const tenderListingReport = (queryParam) =>
    crudApi({
        request: CONSTANTS.TENDER_LISTING_REPORT,
        url: `pos/v1/report/sales/payment/details?${queryParam}`,
        encode: false,
        success: CONSTANTS.TENDER_LISTING_REPORT_SUCCESS,
        failure: CONSTANTS.TENDER_LISTING_REPORT_FAILURE,
        method: 'GET',
    });

export const tagDetailsReport = (queryParam) =>
    crudApi({
        request: CONSTANTS.TAG_DETAILS_REPORT,
        url: `pos/v1/report/sales/tag/details?${queryParam}`,
        encode: false,
        success: CONSTANTS.TAG_DETAILS_REPORT_SUCCESS,
        failure: CONSTANTS.TAG_DETAILS_REPORT_FAILURE,
        method: 'GET',
    });

export const refundDetailsReport = (queryParam) =>
    crudApi({
        request: CONSTANTS.REFUND_DETAILS_REPORT,
        url: `pos/v1/report/sales/refund/details?${queryParam}`,
        encode: false,
        success: CONSTANTS.REFUND_DETAILS_REPORT_SUCCESS,
        failure: CONSTANTS.REFUND_DETAILS_REPORT_FAILURE,
        method: 'GET',
    });

export const productPriceDetailsReport = (queryParam) =>
    crudApi({
        request: CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT,
        url: `/sales/v1/product/report/price/details?${queryParam}`,
        encode: false,
        success: CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT_SUCCESS,
        failure: CONSTANTS.PRODUCT_PRICE_DETAILS_REPORT_FAILURE,
        method: 'GET',
    });

export const productSalesSummary = (queryParam) =>
    crudApi({
        request: CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT,
        url: `pos/v1/report/product/sales/summary?${queryParam}`,
        encode: false,
        success: CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT_SUCCESS,
        failure: CONSTANTS.PRODUCT_SALES_SUMMARY_REPORT_FAILURE,
        method: 'GET',
    });

export const storeOpenSearchByFields = (queryParam, success, failure) =>
    crudApi({
        request: CONSTANTS.SEARCH_STORE_OPEN_FIELDS,
        url: `pos/v1/store/open/search/fields?${queryParam}`,
        encode: false,
        success: success,
        failure: failure,
        method: 'GET',
    });

export const exportReport = (url, queryParam) => {
    return crudApi({
        request: CONSTANTS.EXPORT_REPORT,
        url: `${url}?${queryParam}`,
        success: CONSTANTS.EXPORT_REPORT_SUCCESS,
        failure: CONSTANTS.EXPORT_REPORT_FAILURE,
        method: 'GET',
    });
};

export const runDataValidation = (payload) =>
    crudApi({
        request: CONSTANTS.EXECUTE_DATA_VALIDATION_REPORT,
        url: `eip/v1/dx/report/validate/data`,
        encode: false,
        success: CONSTANTS.EXECUTE_DATA_VALIDATION_REPORT_SUCCESS,
        failure: CONSTANTS.EXECUTE_DATA_VALIDATION_REPORT_FAILURE,
        method: 'POST',
        ...payload,
    });

export const exportEjt = (
    queryParam,
    success = CONSTANTS.EXPORT_EJT_SUCCESS,
    failure = CONSTANTS.EXPORT_EJT_FAILURE
) => {
    return crudApi({
        request: CONSTANTS.EXPORT_EJT,
        url: `/cms/v1/media/folder/download?${queryParam}`,
        success: success,
        failure: failure,
        method: 'GET',
    });
};

export const ejSearchByFields = (queryParam, success, failure) =>
    crudApi({
        request: CONSTANTS.SEARCH_EJ_BY_FIELDS,
        url: `pos/v1/terminal/ejt/search/fields?${queryParam}`,
        encode: false,
        success: success,
        failure: failure,
        method: 'GET',
    });

export const generateEJ = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `eip/v1/dx/ejt/generate`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

// tax configuration
export const searchTaxConfiguration = (
    url,
    payload,
    SUCCESS,
    FAILURE,
    noLoading
) =>
    queryApi({
        request: CONSTANTS.SEARCH_TAXCONFIGURATION,
        url: url,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    });
export const taxConfigurationSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_TAXCONFIGURATION_FIELDS,
        url: `sales/v1/category/search/fields?cached=N&catgroup=TAX&${queryParam}`,
        success: CONSTANTS.SEARCH_TAXCONFIGURATION_SUCCESS,
        failure: CONSTANTS.SEARCH_TAXCONFIGURATION_FAILURE,
        method: 'GET',
    });
};
export const createTaxConfiguration = (payload) =>
    crudApi({
        request: CONSTANTS.TAXCONFIGURATION_CRUD_REQUEST,
        url: `sales/v1/category/save`,
        success: CONSTANTS.TAXCONFIGURATION_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.TAXCONFIGURATION_CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const deleteTaxConfiguration = (url, id) => {
    return crudApi({
        request: CONSTANTS.DELETE_TAXCONFIGURATION_CRUD_REQUEST,
        url: `${url}/${id}`,
        success: CONSTANTS.DELETE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.DELETE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};
export const updateTaxConfiguration = (payload, id) =>
    crudApi({
        request: CONSTANTS.UPDATE_TAXCONFIGURATION_CRUD_REQUEST,
        url: `sales/v1/category/update`,
        success: CONSTANTS.UPDATE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.UPDATE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });
// special discount
export const searchSpecialDiscount = (
    url,
    payload,
    SUCCESS,
    FAILURE,
    noLoading
) =>
    queryApi({
        request: CONSTANTS.SEARCH_SPECIALDISCOUNT,
        url: url,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    });
export const searchSpecialDiscountPid = (
    url,
    payload,
    SUCCESS,
    FAILURE,
    noLoading
) =>
    queryApi({
        request: CONSTANTS.SEARCH_SPECIALDISCOUNT_PID,
        url: url,
        encode: false,
        success: SUCCESS,
        failure: FAILURE,
        noLoading: true,
        ...payload,
    });
export const specialDiscountSearchByFields = (queryParam) => {
    return crudApi({
        request: CONSTANTS.SEARCH_SPECIALDISCOUNT_FIELDS,
        url: `sales/v1/category/search/fields?cache=N&${queryParam}`,
        success: CONSTANTS.SEARCH_SPECIALDISCOUNT_FIELDS_SUCCESS,
        failure: CONSTANTS.SEARCH_SPECIALDISCOUNT_FIELDS_FAILURE,
        method: 'GET',
    });
};
export const createSpecialDiscount = (payload) =>
    crudApi({
        request: CONSTANTS.SPECIALDISCOUNT_CRUD_REQUEST,
        url: `sales/v1/category/save`,
        success: CONSTANTS.SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.SPECIALDISCOUNT_CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
export const deleteSpecialDiscount = (url, id) => {
    return crudApi({
        request: CONSTANTS.DELETE_SPECIALDISCOUNT_CRUD_REQUEST,
        url: `${url}/${id}`,
        success: CONSTANTS.DELETE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.DELETE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE,
        method: 'DELETE',
    });
};
export const updateSpecialDiscount = (payload, id) =>
    crudApi({
        request: CONSTANTS.UPDATE_SPECIALDISCOUNT_CRUD_REQUEST,
        url: `sales/v1/category/update`,
        success: CONSTANTS.UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const sendEipMessage = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `eip/v1/broker/consumer/msg/resend`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateReplenishment = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ext-ns/v1/inventory/replenishment`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const updateReplenishmentStatus = (payload) =>
    crudApi({
        request: CONSTANTS.UPDATE_REPLENISHMENT_REQUEST,
        url: `ext-ns/v1/inventory/replenishment`,
        success: CONSTANTS.UPDATE_REPLENISHMENT_SUCCESS,
        failure: CONSTANTS.UPDATE_REPLENISHMENT_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const updateCollectionInvoice = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ext-ns/v1/collection/invoice`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createCollectionInvoice = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ext-ns/v1/collection/invoice`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });

export const updateSaleReturn = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ext-ns/v1/sales/return`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'PUT',
        ...payload,
    });

export const createSaleReturn = (payload) =>
    crudApi({
        request: CONSTANTS.CRUD_REQUEST,
        url: `ext-ns/v1/sales/return`,
        success: CONSTANTS.CRUD_REQUEST_SUCCESS,
        failure: CONSTANTS.CRUD_REQUEST_FAILURE,
        method: 'POST',
        ...payload,
    });
