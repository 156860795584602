import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MediaFileUpload from './MediaFileUpload';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataViewLayoutOptions } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import {
    queryMediaList,
    createFolderApi,
    deleteFolderApi,
    crudReset,
    uploadFileApi,
    deleteFileApi,
    copyFileApi,
    moveFileApi,
    generateThumbnailApi,
} from '../../actions/actions';
import { permissions } from '../../helpers/Util';
import MediaForm from './MediaForm';
import Loading from './../../components/Loading';

const sortOptions = [
    { id: 'medianame', name: 'Sort by medianame' },
    { id: 'mediatype', name: 'Sort by mediatype' },
    { id: 'createdon', name: 'Sort by createdon' },
    { id: 'lastupdated', name: 'Sort by lastupdated' },
    { id: 'sortorder', name: 'Sort by sortorder' },
];

const MediaManagement = (props) => {
    const toast = useRef(null);
    const { queryData, crudData } = props;
    const {
        isUploadSuccess,
        createFolderSuccess,
        createFolderFailure,
        crudApiPending,
        deleteFolderSuccess,
        deleteFolderFailure,
        errormsg,
        removeFileSuccess,
        generateThumbnailSuccess,
        generateThumbnailFailure,
        copyFileSuccess, copyFileFailure
    } = crudData;
    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState('mediatype');

    const getFolderPath = () => {
        const folderaccess = localStorage.getItem('folderaccess');
        if (folderaccess === '/') {
            return '/media';
        } else {
            return folderaccess;
        }
    };

    const [state, setState] = useState({
        folderPath: getFolderPath() || '',
        status: 'Active',
        folderList: [],
        showAsGrid: true,
        isCreateFolderModalOpen: false,
        isUploadFileModalOpen: false,
        newFolderName: '',
        fileRejectVisible: false
    });

    useEffect(() => {
        props.dispatch(queryMediaList(createFetchUrl(state.folderPath)));
    }, [sortKey]);

    useEffect(() => {
        const showSuccessToast = (detail) => {
            toast.current.show({
                severity: 'success',
                summary: '',
                detail: detail,
                life: 3000,
            });
        };
        const showErrorToast = (detail) => {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: detail,
                life: 3000,
            });
        };
        if (isUploadSuccess && !crudApiPending) {
            showSuccessToast('File Uploaded Successfully!');
            props.dispatch(crudReset());
            callApiForFetch(state.folderPath);
        }
        if (createFolderSuccess && !createFolderFailure && !crudApiPending) {
            showSuccessToast('Folder Created Successfully!');
            props.dispatch(crudReset());
            callApiForFetch(state.folderPath);
        }
        if (removeFileSuccess) {
            showSuccessToast('File deleted successfully!');
            props.dispatch(crudReset());
            callApiForFetch(state.folderPath);
        }
        if (deleteFolderSuccess) {
            showSuccessToast('Folder Deleted Successfully!');
            props.dispatch(crudReset());
            callApiForFetch(state.folderPath);
        } else if (!deleteFolderSuccess && deleteFolderFailure) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: `${errormsg} -> Folder deletion unsucessfull `,
                life: 3000,
            });
            props.dispatch(crudReset());
        }
        //Generate Thumbnail
        if (generateThumbnailSuccess) {
            showSuccessToast('Thumbnail Created Successfully!');
            props.dispatch(crudReset());
            callApiForFetch(state.folderPath);
        } else if (!generateThumbnailSuccess && generateThumbnailFailure) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: `${errormsg} -> Thumbnail created unsucessfull `,
                life: 3000,
            });
            props.dispatch(crudReset());
        }

        if (copyFileSuccess && !copyFileFailure && !crudApiPending) {
            showSuccessToast('File copy successfully');
            props.dispatch(crudReset());
            callApiForFetch(state.folderPath);
        }
        if (!copyFileSuccess && copyFileFailure && !crudApiPending) {
            showErrorToast(`${errormsg} -> File copy unsucessfull .Check logs for more details`);
            props.dispatch(crudReset());
        }
    }, [
        isUploadSuccess,
        crudApiPending,
        createFolderSuccess,
        createFolderFailure,
        deleteFolderSuccess,
        deleteFolderFailure,
        removeFileSuccess,
        generateThumbnailSuccess,
        generateThumbnailFailure,
        copyFileSuccess, copyFileFailure
    ]);

    const callApiForFetch = (folderPath) => {
        props.dispatch(queryMediaList(createFetchUrl(folderPath)));
    };

    const createFetchUrl = (folderPath) => {
        const { status } = state;
        const requestBody = {
            body: {
                path: folderPath,
                status: status,
                sort: sortKey,
            },
        };
        return requestBody;
    };

    const renderHeader = () => {
        return (
            <div className="grid mx-3 align-items-center mt-5">
                <h1
                    className="text-2xl font-normal"
                    style={{ color: '#212121' }}
                >
                    Media Management
                </h1>
                <div className="ml-auto">
                <Button
                label="Create Folder"
                icon="pi pi-folder"
                className="p-button-outlined mr-2"
                onClick={() =>
                    setState({ ...state, isCreateFolderModalOpen: true })
                }
            />
                    <Button
                label="Upload File"
                icon="pi pi-upload"
                className="p-button-outlined"
                onClick={(e) => {
                    e.preventDefault()
                    openUploadFileModal()
                }}
            />
                </div>
            </div>
        );
    };

    const onSortChange = (sortVal) => {
        const { value } = sortVal;
        setSortKey(value.id);
    };

    const handleLayoutChange = (layoutType) => {
        const { value } = layoutType;
        setLayout(value);
        setState({ ...state, showAsGrid: value === 'grid' });
    };

    const renderSwitchView = () => {
        return (
            <div className="grid w-100 pl-4 pr-2 mt-2 align-items-center">
                <Dropdown
                        options={sortOptions}
                        value={sortOptions.find((e) => e.id === sortKey)}
                        optionLabel="name"
                        onChange={onSortChange}
                    />
                <div className="ml-auto">
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={handleLayoutChange}
                    />
                </div>
            </div>
        );
    };

    const containsSpecialCharacters = (str) => {
        let regex = /[!$%^&*()+|~=`{}\[\]:";'<>?,.]/g;
        return regex.test(str);
    };

    const createFolder = (newFolder) => {
        let checkFoldername = containsSpecialCharacters(newFolder);
        if (checkFoldername) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: `${errormsg} No Special Characters Allowed in Foldername`,
                life: 3000,
            });
            props.dispatch(crudReset());
            let path = state.folderPath;
            callApiForFetch(path);
        } else {
            props.dispatch(createFolderApi(getCreatePayload(newFolder)));
        }
    };

    const getCreatePayload = (newFolder) => {
        const { folderPath } = state;
        let body = {
            path: folderPath,
        };
        if (newFolder) {
            body['foldername'] = newFolder;
        }
        const payload = {
            body: body,
        };

        return payload;
    };

    const deleteFolder = (folder) => {
        props.dispatch(crudReset());
        const payload = {
            body: {
                path: folder,
            },
        };
        props.dispatch(deleteFolderApi(payload));
    };

    const getPayloadForUploadFile = (filename, encodedStr) => {
        const { folderPath } = state;

        let body = {
            path: folderPath,
            filename: filename,
            filetext: encodedStr,
        };
        const payload = {
            body: body,
        };
        return payload;
    };

    const uploadFile = (filename, encodedStr, filetype) => {
        const allowedFileTypes = [
            'image/jpeg',
            'image/png',
            'text/css',
            'text/html',
            'text/plain',
        ];
        if (!allowedFileTypes.includes(filetype)) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: 'Selected file is not supported to upload.',
                life: 3000,
            });
            return
        }
        props.dispatch(crudReset());
        props.dispatch(
            uploadFileApi(getPayloadForUploadFile(filename, encodedStr))
        );
        setState({ ...state, isUploadFileModalOpen: false });
    };

    const deleteFile = (fileToDelete) => {
        props.dispatch(crudReset());
        const payload = {
            body: {
                path: fileToDelete,
            },
        };
        props.dispatch(deleteFileApi(payload));
    };

    const moveFile = (fromFile, tofile) => {
        props.dispatch(crudReset());
        const payload = {
            body: {
                filepath: fromFile,
                newfilepath: tofile,
            }
        };
        props.dispatch(moveFileApi(payload));
    };

    const copyFile = (fromFile, tofile) => {
        props.dispatch(crudReset());
        const payload = {
            body: {
                filepath: fromFile,
                newfilepath: tofile,
            },
        };
        props.dispatch(copyFileApi(payload));
    };

    const generateThumbnail = (fileToGenerateThumbnail) => {
        props.dispatch(crudReset());
        const payload = {
            body: [
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x1',
                    width: 150,
                    height: 150,
                    quality: 95,
                    hidden: 'N',
                },
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x2',
                    width: 200,
                    height: 200,
                    quality: 95,
                    hidden: 'N',
                },
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x3',
                    width: 250,
                    height: 250,
                    quality: 95,
                    hidden: 'N',
                },
            ],
        };
        props.dispatch(generateThumbnailApi(payload));
    };

    const openUploadFileModal = (value) => {
        props.dispatch(crudReset());
        if (value) {
            setState({ ...state, folderPath: value, isUploadFileModalOpen: true });
        }
        else {
            setState({ ...state, isUploadFileModalOpen: true });
        }
        
    };

    const folderOnClick = (folderpath) => {
        setState({ ...state, folderPath: folderpath });
        let path = folderpath ? folderpath : state.folderPath;
        callApiForFetch(path);
    };

    const setIsUploading = () => {};

    const isFileRejected = () => setState({ ...state, fileRejectVisible: false })

    const handleFileUpload = (data) => {
        const { filename, filetext, filetype } = data;
        uploadFile(filename, filetext, filetype);
    };

    const closeCreateFolderModal = (event) => {
        setState({
            ...state,
            isCreateFolderModalOpen: false,
            newFolderName: '',
        });
        props.dispatch(crudReset());
        if (event) event.preventDefault();
    };

    const callCreateFolderApi = () => {
        createFolder(state.newFolderName);
        closeCreateFolderModal();
    };

    const renderCreateFolderFooter = () => {
        return (
            <div>
                <Button label="Create" onClick={callCreateFolderApi} />
                <Button
                    label="Cancel"
                    onClick={closeCreateFolderModal}
                    className="p-button-outlined p-button-secondary"
                />
            </div>
        );
    };

    const renderCreateFolderModal = () => {
        return (
            <Dialog
                header="New Folder"
                visible={state.isCreateFolderModalOpen}
                style={{ width: '50vw' }}
                footer={renderCreateFolderFooter()}
                onHide={() => closeCreateFolderModal()}
            >
                <InputText
                    className="w-100"
                    placeholder="Untitled folder"
                    value={state.newFolderName}
                    onChange={(e) => {
                        let value = e.target.value.replace(/\s+/g, '-');
                        setState({ ...state, newFolderName: value });
                    }}
                />
            </Dialog>
        );
    };

    const renderUploadFileModal = () => {
        return (
            <Dialog
                header="Upload File"
                visible={state.isUploadFileModalOpen}
                onHide={() =>
                    setState({ ...state, isUploadFileModalOpen: false })
                }
                style={{ width: '50vw', textAlign: 'center' }}
            >
                <MediaFileUpload handleFileUpload={handleFileUpload} />
            </Dialog>
        );
    };

    // const setPresentFolder = (value, callback) => {
    //     setState({ ...state, folderPath: value })
    //     callback(value)
    // };

    const setPresentFolder = (value, callback) => {
        setState(prevState => {
          const newState = { ...prevState, folderPath: value };
          callback(value);
          return newState;
        });
      };
      

    if (!queryData || queryData.apiPending || crudData.crudApiPending) {
        return <Loading />;
    }

    return (
        <div className="form_height">
            <Toast ref={toast} />
            {renderHeader()}
            <div className="grid br-2 bg-white py-4 px-2 border-round-xl shadow-1 mx-3 mt-4">
                {renderSwitchView()}
                <div className="w-100 p-3">
                    <MediaForm
                        showAsGrid={state.showAsGrid}
                        queryData={queryData}
                        updateFolderPath={setPresentFolder}
                        createFolder={createFolder}
                        deleteFolder={deleteFolder}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                        moveFile={moveFile}
                        copyFile={copyFile}
                        generateThumbnail={generateThumbnail}
                        openUploadFile={openUploadFileModal}
                        folderOnClick={folderOnClick}
                        folderPath={state.folderPath}
                        setIsUploading={setIsUploading}
                        isFileRejected={isFileRejected}
                    />
                </div>
            </div>

            {renderCreateFolderModal()}
            {renderUploadFileModal()}
            <Dialog
                    className="bg-white"
                    showHeader={false}
                    header="Header"
                    visible={state.fileRejectVisible}
                    onHide={() => setState({ ...state, fileRejectVisible: false })}
                    style={{ paddingTop: '20px', textAlign: 'center' }}
                >
                    <p
                        className="p-dialog-title"
                        style={{ fontSize: '18px', fontWeight: '700' }}
                    >
                        Selected file is not supported.
                    </p>
                    <p style={{ color: 'red' }}>
                        <span style={{ color: 'red' }}>
                            File size should be less than 5 MB.
                        </span>
                        <br />
                        Please select a file with file type
                        jpg|gif|png|html|css|ftl
                    </p>
                    <Button
                        type="button"
                        label="Ok"
                        className="mt-2 p-button p-component p-button-danger mr-2 mh-4"
                        onClick={() => setState({ ...state, fileRejectVisible: false })}
                    />
                </Dialog>
        </div>
    );
};

MediaManagement.propTypes = {};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
    };
};

export default connect(mapStateToProps)(MediaManagement);
