import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "../../../../components/LinkButton";
import { connect } from "react-redux";
// Local Imports
// Local Imports
import ImagePick from "../../../../components/ImagePick";
import {
  updateArticleInfoApi,
  createArticleInfoApi,
  crudReset,
  getBeaconsList,
  getGeofenceList,
  getStoreById,
  updateArticleMode
} from "../../../../actions/actions";
import ConfirmAlert from "../../../../components/ConfirmAlert";
import StatusComp from "../../../../components/StatusComp";
import { produce } from "immer";
import TenantCode from "../../../../components/TenantCode";
import SimpleSelect from "./../../../../components/SimpleSelect";
import MultiSelect from "./../../../../components/MultiSelect";
import { renderBeaconOrGeofenceList } from "../../../../helpers/Util";

class AddAboutApp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      articlecode: "",
      arttype: "",
      articletype: "About App",
      title: "",
      desc: "",
      status: "New",
      images: {},
      sortorder: 0,
      language: "",
      langlist: {},
      appname: "",
      version: "",
      copyright: "",
      articlegroup: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      storeiderror: "",
      titleerror: "",
      appnameError: "",
      copyerror: "",
      beacons: "",
      geofences: "",
      storelabel: ""
    };
    this.lastId = -1;
  }
  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      //if (storeArray) this.setState({ storelabel: storeArray.title });
      if (storeArray) {
        storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
      this.setState({ language: this.props.history.location.state.item.language });
    }    
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };
  setBeaconsSelected = value => {
    this.setState({
      beacons: value
    });
  };
  setGeoFenceSelected = value => {
    this.setState({
      geofences: value
    });
  };
  componentWillMount = () => {
    this.props.dispatch(
      getBeaconsList({ body: { language: "en", status: "New,Active" } })
    );
    this.props.dispatch(
      getGeofenceList({ body: { language: "en", status: "New,Active" } })
    );

    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          storeid,
          title,
          articlecode,
          fields,
          status,
          sortorder,
          images,
          articletype,
          articlegroup
        } = rec;
        this.props.dispatch(getStoreById(storeid));
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.arttype = fields.arttype || "";
            draft.articletype = articletype || "";
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.articlecode = articlecode ? articlecode : "";
            draft.beacons = fields.beacons ? fields.beacons.split(",") : "";
            draft.geofences = fields.geofences
              ? fields.geofences.split(",")
              : "";

            draft.appname = fields.appname ? fields.appname : "";
            draft.version = fields.version ? fields.version : "";
            draft.copyright = fields.copyright ? fields.copyright : "";
            draft.images = {
              image1:  rec.hasOwnProperty("images") === true ? images.app_article_1 : "",
              image2:  rec.hasOwnProperty("images") === true ? images.app_article_2 : ""
            };
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  getCreatePayload = () => {
    const {
      storeid,
      title,
      articlecode,
      status,
      sortorder,
      images,
      language,
      arttype,
      articletype,
      appname,
      version,
      copyright,
      geofences,
      beacons,
      articlegroup
    } = this.state;

    let body = {
      storeid: storeid,
      title: title,
      language: language,
      articlecode,
      publishmode: "manual",
      articletype: articletype,
      status: status,
      sortorder: sortorder,
      articlegroup,
      fields: {
        arttype,
        appname,
        version,
        copyright
        /* geofences: geofences.toString(),
        beacons: beacons.toString() */
      },
      images: {
        app_article_1: images.image1 || "",
        app_article_2: images.image2 || ""
      }
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createArticle = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
        this._updateArticleMode();
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
        this._updateArticleMode();
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      titleerror: "",
      appnameError: "",
      copyerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, title, appname, copyright } = this.state;
    let valid = true;
    /* if (storeid.trim() === "") {
      this.setState({
        storeiderror: "Please enter store id"
      });
      valid = false;
    } */
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter title"
      });
      valid = false;
    }
    if (appname.trim() === "") {
      this.setState({
        appnameError: "Please enter app name"
      });
      valid = false;
    }
    if (copyright.trim() === "") {
      this.setState({
        copyerror: "Please enter the field"
      });
      valid = false;
    }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _updateArticleMode = () =>{
    const isEditMode = this._isEditMode();
    if(!isEditMode){
      const { articleMode } = this.props.location.state;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articleMode } }) 
      );
    }
    else {
      const { articletype } = this.props.location.state.item;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articletype } }) 
      );
    }
  }
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Update
          </button>
        )}
         <LinkButton
          to="/ui/articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          onClick={() => this._updateArticleMode()}
        >
          {`Cancel`}
        </LinkButton>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
     return (
       <React.Fragment>
         <div className="row sub-title">Actions</div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
         </div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
       </React.Fragment>
     )
  }


  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderMainFormDetails = () => {
    const { languageConfig, beaconsList, geoFencesList } = this.props.queryData;

    return (
      <React.Fragment>
        <div className=" row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articletype" className="floatLeft">
              Article Type
            </label>
            <input
              type="text"
              className="form-control"
              id="articletype"
              value="About App"
              disabled={true}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => {
                this.setState({ articlegroup: event.target.value });
              }}
            />
          </div>           
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlecode" className="floatLeft">
              Article Code
            </label>
            <input
              type="text"
              className="form-control"
              id="articlecode"
              value={this.state.articlecode}
              readOnly={true}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="arttype" className="floatLeft">
              Article SubType
            </label>
            <input
              type="text"
              className="form-control"
              id="arttype"
              value={this.state.title}
              readOnly={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>        
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="appname" className="floatLeft required">
              App Name
            </label>
            <input
              type="text"
              className="form-control"
              id="appname"
              value={this.state.appname}
              onChange={event => {
                this.setState({ appname: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.appnameError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="version" className="floatLeft">
              Version
            </label>
            <input
              type="text"
              className="form-control"
              id="version"
              value={this.state.version}
              onChange={event => {
                this.setState({ version: event.target.value });
              }}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="copyright" className="floatLeft required">
              CopyRight
            </label>
            <input
              type="text"
              className="form-control"
              id="copyright"
              value={this.state.copyright}
              onChange={event => {
                this.setState({ copyright: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.copyerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="beacons" className="floatLeft">
              Beacons
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(beaconsList)}
              setSelectedFieldValues={this.setBeaconsSelected}
              id="beacons"
              itemsSelected={this.state.beacons}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="geofences" className="floatLeft">
              GeoFences
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(geoFencesList)}
              setSelectedFieldValues={this.setGeoFenceSelected}
              id="geofences"
              itemsSelected={this.state.geofences}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="image1" className="floatLeft">
              Images
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={2}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  handleAppAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.app.length;
    let lastele = this.state.app[rowLength - 1];
    const item = {
      fieldname: "",
      fieldtype: "",
      validation: [],
      comments: "",
      id: lastele.id + 1
    };
    this.setState(
      {
        app: [...this.state.app, item]
      },
      () => {
        console.log("Rows After Add", this.state.app);
      }
    );
  };

  handleAppInputChange = (event, dataField, row) => {
    let obj = {
      id: row.id,
      [dataField]: event.target.value
    };
    this.setState(
      produce(draft => {
        if (draft.app.length === 0) {
          draft.app.push(obj);
        } else {
          let found = false;
          draft.app.forEach(command => {
            if (command.id === obj.id) {
              Object.assign(command, obj);
              found = true;
            }
          });
          if (!found) {
            draft.app.push(obj);
          }
        }
      }),
      () => {}
    );
  };
  deleteAppRow = row => {
    let rowId = row.id;
    const rowLength = this.state.app.length;
    if (rowLength > 1) {
      const updateValues = this.state.app.filter(value => rowId !== value.id);
      this.setState({ app: updateValues }, () => {});
    }
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Edit  About App"
                  : "Add  About App"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/articles"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "Article updated successfully"
                  : "Article created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddAboutApp);
