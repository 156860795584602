import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';

const PrimeStoreSelector = (props) => {
    const { storeList, selectedStore, handleStoreSelection, required } = props
    const localStoreId = localStorage.getItem('storeid')
    const getStoreId = () => {
        if (typeof selectedStore.value !== "undefined") {
            return selectedStore.value
          } 
        return localStoreId
    }

    const [ storeid, setStoreid ]= useState(getStoreId())
    const [filterStoreList, setFilterStoreList] = useState([])

    useEffect(() => {
        let filteredStores = [];

        const store_access = localStorage.getItem('store_access');
        if (store_access && storeList.length > 0) {
            const accessStores = store_access.split(',');
            filteredStores = storeList.filter((e) =>
                accessStores.includes(e.value)
            );
            setFilterStoreList(filteredStores)
        }
    }, [storeList])
    
    const handleChange = (e) => {
        const newStoreid = e.target.value
        setStoreid(newStoreid)
        const storeObj = storeList.filter(store => store.value === newStoreid)
        handleStoreSelection(Array.isArray(storeObj) ? storeObj[0] : {} )
    }
    return (
        <div className='w-100'>
            <Dropdown
                className='w-100'
                id="storeid"
                name="storeid"
                value={storeid}
                onChange={handleChange}
                options={filterStoreList}
                optionLabel="label"
                placeholder="Select store"
            />
            {required && !storeid && (
                <small className="p-error">Store id is required.</small>
            )}
        </div>
    )

}

export default PrimeStoreSelector
