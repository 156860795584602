import React from "react";
 

class ChartOptions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleOpen = () => {
    this.setState({ isOpen: true }, () => {
      document.addEventListener('click', this.toggleClosed)
    })
  }

  toggleClosed = () => {
    this.setState({ isOpen: false }, () => {
      document.removeEventListener('click', this.toggleClosed)
    })
  }

  exportJPEG = () => {
    var canvas = document.getElementById(this.props.chartid);
    if (canvas !== null) {
      var dataURL = canvas.toDataURL("image/jpeg", 1.0);
      console.log(dataURL);
      var a = document.createElement('a');
      a.href = dataURL;
      a.download = "chart.jpg";
      document.body.appendChild(a);
      a.click();
    }
  }

  convertToCSV(objArray) {
    const array = [Object.keys(objArray[0])].concat(objArray)
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }

  exportCSV = () => {
    const items = this.props.chartdata;
    if (items.length > 0) {
      const csv = this.convertToCSV(items);
      var exportedFilenmae = "chart.csv" || "export.csv";
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

    }
  }

  

  render() {
    return (
      <div className="row noPadding noMargin">
        <div className="col-lg-3 col-sm-3 alignLeft noPadding noMargin">
          <img src={`${process.env.PUBLIC_URL}/assets/icons/tableicon.png`}  style={{margin: "8px 2px"}}
          id={this.props.tableimgid} onClick={this.props.toggleTable} width="25px" height="25px" />
        </div>
        <div className="col-lg-3 col-sm-3 alignLeft noPadding noMargin">
          <img src={`${process.env.PUBLIC_URL}/assets/icons/charticon.png`} style={{margin: "8px 2px"}}
          id={this.props.tableimgid} onClick={this.props.toggleChart} width="25px" height="25px" />
        </div>
        <div className="col-lg-3 col-sm-6 alignLeft noPadding noMargin">
          <div className="dropdown chartdropdown"  onClick={this.toggleOpen}>
            <img src={`${process.env.PUBLIC_URL}/assets/icons/hamburger.png`} width="40px" height="40px" />
            { this.state.isOpen && (
            <div className="dropdown-menu show">
              <a className="dropdown-item" onClick={this.exportCSV}>
                Download as CSV
              </a>
              <a className="dropdown-item" onClick={this.exportJPEG}>
                Download as JPEG
              </a>
            </div>
            )}
          </div>
        </div>  
      </div>
    );
  }
}

export default ChartOptions;
