import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  updateArticleInfoApi,
  createArticleInfoApi,
  crudReset,
  getStoreById
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusComp from "../../../components/StatusComp";
import { produce } from "immer";
import TenantCode from "../../../components/TenantCode";
import SimpleSelect from "./../../../components/SimpleSelect";

class AddGeoFence extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      articlecode: "",
      title: "",
      latitude: "",
      longitude: "",
      radiusmeter: "",
      city: "",
      zone: "",
      status: "New",
      sortorder: 0,
      language: "",
      langlist: {},
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      storeiderror: "",
      titleerror: "",
      laterror: "",
      longerror: "",
      radiuserror: "",
      storelabel: ""
    };
  }

  componentWillMount = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const { id, storeid, title, fields, status, language, sortorder } = rec;
        this.props.dispatch(getStoreById(storeid));
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            draft.status = status ? status : "";
            draft.language = language ? language : "en";

            draft.latitude = fields.latitude ? fields.latitude : "";
            draft.longitude = fields.longitude ? fields.longitude : "";
            draft.radiusmeter = fields.radiusmeter ? fields.radiusmeter : "";
            draft.city = fields.city ? fields.city : "";
            draft.zone = fields.zone ? fields.zone : "";
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  getCreatePayload = () => {
    const {
      storeid,
      title,
      status,
      language,
      latitude,
      longitude,
      radiusmeter,
      city,
      zone
    } = this.state;
    let body = {
      storeid: storeid,
      title: title,
      language: language,
      publishmode: "manual",
      articletype: "GeoFence Information",
      status: status,
      fields: {
        latitude: latitude,
        longitude: longitude,
        radiusmeter: radiusmeter,
        city: city,
        zone: zone
      }
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createGeofence = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      titleerror: "",
      laterror: "",
      longerror: "",
      radiuserror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, title, latitude, longitude, radiusmeter } = this.state;
    let valid = true;
    /* if (storeid.trim() === "") {
      this.setState({
        storeiderror: "Please select a store id"
      });
      valid = false;
    } */
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter a title"
      });
      valid = false;
    }
    if (latitude.trim() === "") {
      this.setState({
        laterror: "Please enter latitude"
      });
      valid = false;
    }
    if (longitude.trim() === "") {
      this.setState({
        longerror: "Please enter longitude"
      });
      valid = false;
    }
    if (radiusmeter.trim() === "") {
      this.setState({
        radiuserror: "Please enter radius"
      });
      valid = false;
    }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createGeofence()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createGeofence()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/geo-fence"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      //if (storeArray) this.setState({ storelabel: storeArray.title });
      if (storeArray) {
        storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };

  _renderFormArea = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className=" row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="latitude" className="floatLeft required">
              GMap Latitude
            </label>
            <input
              type="text"
              className="form-control"
              id="latitude"
              value={this.state.latitude}
              onChange={event => {
                this.setState({ latitude: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.laterror}</div>
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="longitude" className="floatLeft required">
              GMap Longitude
            </label>
            <input
              type="text"
              className="form-control"
              id="longitude"
              value={this.state.longitude}
              onChange={event => {
                this.setState({ longitude: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.longerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="radiusmeter" className="floatLeft required">
              GMap Radius
            </label>
            <input
              type="text"
              className="form-control"
              id="radiusmeter"
              value={this.state.radiusmeter}
              onChange={event => {
                this.setState({ radiusmeter: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.radiuserror}</div>
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="city" className="floatLeft required">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={this.state.city}
              onChange={event => {
                this.setState({ city: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.radiuserror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="zone" className="floatLeft required">
              Zone
            </label>
            <input
              type="text"
              className="form-control"
              id="zone"
              value={this.state.zone}
              onChange={event => {
                this.setState({ zone: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.radiuserror}</div>
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>
          <div className="row">
            <div className="form-group col-lg-4 col-sm-12">
              <label htmlFor="postal" className="floatLeft">
                Status
              </label>
              <StatusComp
                selectedOption={this.state.status}
                callbackChangeFilter={value => {
                  this.setState({ status: value });
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Edit Geofence Information"
                  : "Add Geofence Information"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv text-center">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/geo-fence"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "Geofence information updated successfully"
                  : "Geofence Information created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddGeoFence);
