import React from "react";
import { connect } from "react-redux";
import LinkButton from "../../../components/LinkButton";
import SimpleSearcher from "../../../components/SimpleSearcher";
import { searchFieldForSlot, statusValues } from "./tableCols";
import { canDelete, canWrite, permissions, sortTableDateTime } from "../../../helpers/Util";
import {
  deleteDeliverySlot,
  unpublishArticleInfoApi,
  publisArticleInfoApi,
  copyArticleInfoApi,
  crudReset,
  searchGeneral,
  resetAction
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleTable from "../../../components/SimpleTable";
import Modal from "react-awesome-modal";
import DatePicker from "react-date-picker";
import { toDate } from "../../../helpers/Util";

class DisplayDeliverySlot extends React.PureComponent {
  dataType = [
    { name: "Select Date Type", id: "-1" },
    { name: "Slot Book Date", id: "slotbookdate" }
  ];
  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.module = "SOP"
    this.group = "DE_SOP_OS"
    this.allPermissions = []

    this.state = {
      pages: 10,
      currPage: 0,
      status: "Active",
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      status: "",
      datefrom: new Date(),
      datetill: new Date(),
      datetype: "slotbookdate",
      memberid: "",
      showSearchDiv: false,

      datefromerror: "",
      datetillerror: "",
      datetypeerror: "",

      tableCols: [
        {
          dataField: "slotcode",
          text: "Slot Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
            dataField: "slotbookdate",
            text: "Slot Book Date",
            sort: true,
            sortFunc: sortTableDateTime
        },
        {
            dataField: "slotgroup",
            text: "Slot Group",
            sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }

  componentWillMount = () => {
    this.callQueryApiForFetch("ONLOAD");
    this.allPermissions = permissions(this.module, this.group)
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    

    if (row.status !== "Deleted" && canWrite(this.allPermissions)) {
      return (
        <span>
          <Link
            to={{
              pathname: "/ui/operation-slot/addNew",
              state: {
                item: row,
                isEdit: true
              }
            }}
          >
            <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link>

{canDelete(this.allPermissions) && (
          <img
            title="Delete"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.deleteStoreInfo(row)}
          />
          )}
        </span>
      );
    }
  };


  copyRecord = row => {
    this.setState({ message: "Item Copied Suucessfully" });
    this.props.dispatch(copyArticleInfoApi(row.id));
  };


  // API Calls
  createFetchUrl = () => {
    const { search_field, search_condi, search_value } = this.state;

    const requestBody = {
      body: {
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value
      }
    };
    return requestBody;
  };

  createFetchByFields  = () => {
    const { datefrom, datetill, memberid, datetype, status } = this.state;
      const requestBody = {
        body: {
          datefrom: toDate(datefrom),
          datetill: toDate(datetill),
          datetype,
          memberid,
          status
        }
      };
      return requestBody;
  }


  callFetch = fetchType => {
    if(fetchType === "SEARCH_FIELDS"){
      this.props.dispatch(
        searchGeneral(
          "ops/v1/delivery/slot/search/fields",
          this.createFetchByFields(fetchType),
          "SEARCH_ARTICLE_INFO_SUCCESS",
          "SEARCH_ARTICLE_INFO_FAILURE"
        )
      );
    }
    else {
      this.props.dispatch(
        searchGeneral(
          "ops/v1/delivery/slot/search",
          this.createFetchUrl(fetchType),
          "SEARCH_ARTICLE_INFO_SUCCESS",
          "SEARCH_ARTICLE_INFO_FAILURE"
        )
      );
    }
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "SEARCH_FIELDS":
        this.callFetch("SEARCH_FIELDS");
        break;
    }
  };
  searchQuery = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("SEARCH");
      }
    );
  };

  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      saledate: ""
    });
  };

  validateForm = () => {
    this._clearErrorMessages();
    const { datefrom, datetill, datetype } = this.state;
    let valid = true;
    if (datefrom === "") {
      this.setState({ datefromerror: "Please select from date" });
      valid = false;
    }
    if (datetill === "") {
      this.setState({ datetillerror: "Please select till date" });
      valid = false;
    }
    if (datetype === "") {
      this.setState({ datetypeerror: "Please select date type" });
      valid = false;
    }
    return valid;
  }

  _renderAddNewBtn = () => {
    
    if (!canWrite(this.allPermissions)) { return }
    return (
    
        <Link
          to="/ui/operation-slot/addNew"
        >
          <button className="page-header-btn icon_btn display-inline">
            <img
              title="edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
            />
            {`Add New`}
          </button>
        </Link> 
    );
  };

  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteDeliverySlot(this.state.delete_rec_id));
    }
  };
  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  deleteStoreInfo = rowData => {
    this.setState({ message: "Operation Slot deleted successfully!" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };
  unpublishArticle = rowData => {
    this.setState({ message: "Operation Slot unpublished successfully!" });
    this.props.dispatch(unpublishArticleInfoApi(rowData.id));
  };
  publishArticle = rowData => {
    this.setState({ message: "Operation Slot published successfully!" });
    this.props.dispatch(publisArticleInfoApi(rowData.id));
  };

  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };
  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };

  handlefromdateChange = value => {
    this.setState({
      datefrom: value
    });
  };

  handletodateChange = value => {
    this.setState({
      datetill: value
    });
  };

  _renderSearchForm = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.handlefromdateChange}
              value={this.state.datefrom}
            />
            <div className="invalid-feedback">{this.state.datefromerror}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="toDate" className="floatLeft">
              Date To
            </label>
            <DatePicker
              minDate={this.state.datefrom}
              onChange={this.handletodateChange}
              value={this.state.datetill}
            />
            <div className="invalid-feedback">{this.state.datetillerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="datetype" className="floatLeft">
              Date Type
            </label>
            <div className="row">
              <select
                name="datetype"
                className="simpleSearchSelect2"
                value={this.state.datetype}
                onChange={event =>
                  this.setState({ datetype: event.target.value })
                }
              >
                {this.dataType.length != 0 &&
                  this.dataType.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="invalid-feedback">{this.state.datetypeerror}</div>
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="memberid" className="floatLeft">
              Member Id
            </label>
            <input
              type="text"
              className="form-control"
              id="memberid"
              value={this.state.memberid}
              onChange={event => {
                this.setState({ memberid: event.target.value });
              }}
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
              <select
                className="form-control"
                value={this.state.status}
                onChange={event =>
                  this.setState({ status: event.target.value })
                }
              >
                {statusValues.length != 0 &&
                  statusValues.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
          </div>
        </div>
        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                if(this.validateForm()===true){
                  this.callQueryApiForFetch("SEARCH_FIELDS");
                  this._closeSearchDiv();
                }
                else {
                  this.setState({
                    formError: true,
                    formErrorMsg: "Clear all the errors to continue"
                  });
                  window.scrollTo(0, 0);
                }
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="50%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Operation Slot Search</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {this._renderSearchForm()}
        </div>
      </Modal>
    );
  };

  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  render() {
    const {
      articleFlowData,
      errormsg: queryError,
      languageConfig,
      servererror: serverError
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }
    /*  if (!articleFlowData && !errormsg) {
      return <Loading />;
    } */
    if (this.props.queryData.apiPending === true) {
      return <Loading />;
    }

    return (
      <React.Fragment>
      {this.state.showSearchDiv && this._renderSearchModal()}
        <div className="form_height">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <h2 className="page-header">
                Operation Slot
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Operation Slot Registration</h3>
                  </div>
                  <div className="col-lg-4  col-md-12 col-sm-12 noPadding noMargin">
                      <SimpleSearcher
                        filterOptions={searchFieldForSlot}
                        setAndSearch={this.searchQuery}
                        search_condi={this.state.search_condi}
                        search_value={this.state.search_value}
                        search_field={this.state.search_field}
                        //searchValues={statusValues}
                      ></SimpleSearcher>
                  </div>
                  <div className="col-lg-2  col-md-6 col-sm-12 alignRight noPadding noMargin">
                   {this._renderSearchBtn()}
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 alignRight noPadding noMargin">
                    {this._renderAddNewBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>

                <React.Fragment>
                  <SimpleTable
                    columns={this.state.tableCols}
                    rows={articleFlowData}
                    callback={() => {
                      this.callQueryApiForFetch("ONLOAD");
                    }}
                  />
                </React.Fragment>
              </form>
            </div>
          </div>

          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this Operation slot?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayDeliverySlot);
