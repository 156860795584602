import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import {
    crudReset,
    updateStoreTag,
    createStoreTag,
    storeTerminalsSearchByFields,
    queryPropertyConfigByFields,
    storeDevicesSearchByFields,
} from '../../../../actions/actions';

import ConfirmAlert from '../../../../components/ConfirmAlert';
import StatusOptionComp from '../../../../components/StatusOptionComp';

import { produce } from 'immer';
import Select, { components } from 'react-select';
import { formatLanguageArray } from '../../../../helpers/Util';
import {
    SEARCH_PROP_CONFIG_TOPUPTAGTYPES,
    SEARCH_PROP_CONFIG_TOPUPTAGTYPES_FAILURE,
    SEARCH_PROP_CONFIG_TOPUPTAGTYPES_SUCCESS,
    TOPUPTAGTYPES,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
} from '../../../../constants/constants';
import SimpleImageInput from '../../../../components/SimpleImageInput';
import ReceiptLineConfig from '../../../../components/ReceiptLineConfig';
import TaxReportHeaderConfig from '../common/TaxReportHeaderConfig';
import DynamicTable from '../../../../components/DynamicTable';
import { modifyObject } from '../../../../helpers/Util';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

let defaultaddField = [
    { field: 'vehicle', value: '', id: 0 },
    { field: 'maxvolume', value: '', id: 1 },
];

let sampeaddField = [ 
    { field: 'exceedcreditlimit', value: '', id: 0 },
];

class AddTerminalOptions extends React.PureComponent {
    editModeStatus = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'InActive', id: 'InActive' },
    ];

    topupGroupOptions = [
        { name: 'Select', id: '' },
        { name: 'rabbit', id: 'rabbit' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: '',
            terminalid: '',
            title: '',
            taggroup: 'tprops',
            tagtype: '',
            topupTagTypeOptions: {},
            tagtypevalue: '',
            tendergroup: '',
            storedevices: [],
            deviceid: '',

            status: 'New',
            sortorder: 1,
            additionalFields: {},
            storeterminals: [],
            selectedTerminal: {},

            storelabel: '',
            // Errors
            terminaliderror: '',
            titleerror: '',
            topupdescerror: '',
            storeiderror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tagtypevalueerror: '',
            returntypeerror: '',
            topupgrouperror: '',
            topupproductcodeerror: '',
            companynameerror: '',

            tender: false,
            tenderdesc: '',
            opencashdrawer: false,
            loan: false,
            pickup: false,
            voidtender: false,
            returntender: false,
            minamount: 0,
            maxamount: 0,
            returntype: '',
            autopickup: false,
            tenderimg: '',
            tenderinstruction: '',
            tenderanimationimg: '',
            storecashinout: false,
            cashcount: false,
            mgrintervention: false,
            offlinetender: false,

            //default props
            autolocktimeout: 0,
            autolocktimeouterror: '',
            rabbittandcarticlecode: '',

            //receiptheader || receiptfooter || invoiceheader props
            receiptlines: [{ text: '', alignment: 'L', id: 0 }],
            invoiceheaderlogo: '',
            companyname: '',

            //topup
            topupdesc: '',
            topupgroup: '',
            topupcardtype: '',
            topupurl: '',
            topupacceptedtenders: 'cash', //TODO
            topupminamount: 0,
            topupmaxamount: 0,
            topupimg: '',
            topupinstruction: '',
            topupanimationimg: '',
            topupproductcode: '',
            topupdeviceid: '',
            vehicle: '',
            maxvolume: '',
            exceedcreditlimit: '',

            fieldvaltype: '0',
            truckAdditionalFields: defaultaddField,
            creditAdditionalFields: sampeaddField,
            tagTypeOptions: []
        };
    }

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.receiptlines.length;
        let lastele = this.state.receiptlines[rowLength - 1];
        const item = {
            text: '',
            alignment: 'L',
            id: lastele.id + 1,
        };
        this.setState(
            {
                receiptlines: [...this.state.receiptlines, item],
            },
            () => {
                console.log('Rows After Add', this.state.receiptlines);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]: event.target.value,
        };

        this.setState(
            produce((draft) => {
                if (draft.receiptlines.length === 0) {
                    draft.receiptlines.push(obj);
                } else {
                    let found = false;
                    draft.receiptlines.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.receiptlines.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    handleAlignmentChange = (value, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]: value,
        };

        this.setState(
            produce((draft) => {
                if (draft.receiptlines.length === 0) {
                    draft.receiptlines.push(obj);
                } else {
                    let found = false;
                    draft.receiptlines.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.receiptlines.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.receiptlines.length;

        if (rowLength > 1) {
            const updateValues = this.state.receiptlines.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ receiptlines: updateValues });
        }
    };

    handleTenderSwitch = (tender) => {
        this.setState({ tender });
    };

    handleLoanSwitch = (loan) => {
        this.setState({ loan });
    };

    handlePickupSwitch = (pickup) => {
        this.setState({
            pickup: pickup.target.value === 'Pickup',
            autopickup: pickup.target.value === 'AutoPickup',
        });
    };

    handleAutoPickupSwitch = (autopickup) => {
        this.setState(
            {
                autopickup: autopickup.target.value === 'AutoPickup',
                pickup: autopickup.target.value === 'Pickup',
            },
            () => {
                if (this.state.autopickup) {
                    //reset
                    this.setState({
                        loan: false,
                        cashcount: false,
                        storecashinout: false,
                    });
                }
            },
        );
    };

    getAdditionalFields = () => {
        const { tagtype, additionalFields, truckAdditionalFields, creditAdditionalFields } = this.state;

        if (this.state.tagtype === 'tprops') {
            return {
                autolocktimeout: this.state.autolocktimeout,
                rabbittandcarticlecode: this.state.rabbittandcarticlecode,
            };
        } else if (
            tagtype === 'receiptheader' ||
            tagtype === 'receiptfooter' ||
            tagtype === 'invoiceheader' ||
            tagtype === 'topupreceiptheader' ||
            tagtype === 'topupreceiptfooter'
        ) {
            var response = {};
            if (
                this.state.receiptlines.length === 1 &&
                this.state.receiptlines[0].text === ''
            ) {
            } else {
                const lines = this.state.receiptlines.map((e) => {
                    return { alignment: e.alignment, text: e.text };
                });
                response.lines = lines;
            }
            response.logo = this.state.invoiceheaderlogo;
            response.companyname = this.state.companyname;
            return response;
        } else if (tagtype === 'topup') {
            return {
                topupdesc: this.state.topupdesc,
                topupgroup: this.state.topupgroup,
                topupcardtype: this.state.topupcardtype,
                topupurl: this.state.topupurl,
                acceptedtenders: this.state.topupacceptedtenders,
                minamount: this.state.topupminamount,
                maxamount: this.state.topupmaxamount,
                topupimg: this.state.topupimg,
                topupinstruction: this.state.topupinstruction,
                topupanimationimg: this.state.topupanimationimg,
                topup_product_code: this.state.topupproductcode,
                deviceid: this.state.topupdeviceid,
            };
        } else if (
            tagtype === 'taxreportheader' ||
            tagtype === 'topupreportheader'
        ) {
            return additionalFields;
        } else if (tagtype === 'truckinfo') {
            let addfield = truckAdditionalFields.reduce(function (
                acc,
                { field, value },
            ) {
                Array.isArray(value)
                    ? (acc[field] = [...value])
                    : (acc[field] = value);
                return acc;
            }, {});
            return addfield;
        } 
        else if (tagtype === 'creditinfo') {
            let addfield = creditAdditionalFields.reduce(function (
                acc,
                { field, value },
            ) {
                Array.isArray(value)
                    ? (acc[field] = [...value])
                    : (acc[field] = value);
                return acc;
            }, {});
            return addfield;
        }
        else {
            return null;
        }
    };

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
        } = this.state;

        let body = {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
            additionalfields: this.getAdditionalFields(),
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };

        return payload;
    };

    _saveTerminalConfig = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreTag(this.getCreatePayload(), this.state.id),
                );
            } else {
                this.props.dispatch(createStoreTag(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tagtypevalueerror: '',
            topupdescerror: '',
            topupgrouperror: '',
            topupproductcodeerror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            storeid,
            terminalid,
            taggroup,
            tagtype,
            tagtypevalue,
            topupcardtype,
            topupdesc,
            topupgroup,
            topupproductcode,
            companyname,
        } = this.state;

        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'Please enter storeid' });
            valid = false;
        }
        if (terminalid.trim() === '') {
            this.setState({ terminaliderror: 'Please enter terminal id' });
            valid = false;
        }
        if (taggroup.trim().length <= 0) {
            this.setState({ taggrouperror: 'Please select tag group' });
            valid = false;
        }

        if (tagtype.trim().length <= 0) {
            this.setState({ tagtypeerror: 'Please select tag type' });
            valid = false;
        }

        if (tagtype.trim() === 'topup') {
            if (topupcardtype.trim().length <= 0) {
                this.setState({
                    topupcardtypeerror: 'Please select topup card value',
                });
                valid = false;
            }
            if (topupdesc.trim().length <= 0) {
                this.setState({
                    topupdescerror: 'Please enter the topup description',
                });
                valid = false;
            }
            if (topupgroup.trim().length <= 0) {
                this.setState({ topupgrouperror: 'Please select topup group' });
                valid = false;
            }

            if (tagtypevalue.trim() === '') {
                this.setState({
                    tagtypevalueerror: 'Please select tag type value',
                });
                valid = false;
            }

            if (topupproductcode.trim() === '') {
                this.setState({
                    topupproductcodeerror: 'Please enter the product code',
                });
                valid = false;
            }
        }

        if (
            tagtype.trim() === 'invoiceheader' ||
            tagtype.trim() === 'receiptheader' ||
            tagtype.trim() === 'topupreceiptheader'
        ) {
            if (companyname === '') {
                this.setState({
                    companynameerror: 'Please enter the company name',
                });
                valid = false;
            }
        }

        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger w-100" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger w-100" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <>
                    <Link
                        to="/ui/terminaloptions"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._saveTerminalConfig(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._saveTerminalConfig(e)}
                    >
                        Update
                    </button>
                )}
                <Link
                    to={{
                        pathname: '/ui/terminaloptions',
                        state: { tagtype: this.state.tagtype },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    handleTerminalSelection = (selected) => {
        this.setState({
            selectedTerminal: selected,
        });
        if (selected) {
            this.setState({ terminalid: selected.value, terminaliderror: '' });
        } else {
            this.setState({ terminalid: '' });
        }
    };

    _renderBasicDetails = () => {
        return (
            <>
                <div className="row sub-title">Basic Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecode"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={(event) =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label
                                htmlFor="terminalid"
                                className="floatLeft required"
                            >
                                Terminal Id
                            </label>
                        </div>
                        {this._isViewMode() || this._isEditMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="terminalid"
                                value={
                                    this.state.selectedTerminal &&
                                    this.state.selectedTerminal.length > 0
                                        ? this.state.selectedTerminal[0].label
                                        : ''
                                }
                                disabled={true}
                            />
                        ) : (
                            <Select
                                options={this.state.storeterminals}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={this.handleTerminalSelection}
                                allowSelectAll={false}
                                value={this.state.selectedTerminal}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.terminaliderror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="taggroup" className="col-form-label ">
                            Tag Group
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="taggroup"
                            value={this.state.taggroup}
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.taggrouperror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="tagtype" className="col-form-label ">
                            Tag Type
                        </label>
                        {this._isViewMode() || this._isEditMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtype"
                                value={this.state.tagtype}
                                disabled={true}
                            />
                        ) : (
                            <StatusOptionComp
                                statusoption={this.state.tagTypeOptions}
                                selectedOption={this.state.tagtype}
                                callbackChangeFilter={(value) => {
                                    this.setState(
                                        { tagtype: value, tagtypevalue: '' },
                                        () => {
                                            if (
                                                this.state.tagtype ===
                                                    'receiptheader' ||
                                                this.state.tagtype ===
                                                    'receiptfooter' ||
                                                this.state.tagtype ===
                                                    'topupreceiptheader' ||
                                                this.state.tagtype ===
                                                    'topupreceiptfooter' ||
                                                this.state.tagtype ===
                                                    'invoiceheader' ||
                                                this.state.tagtype ===
                                                    'truckinfo'
                                            ) {
                                                this.setState({
                                                    tagtypevalue: value,
                                                });
                                            }
                                        },
                                    );
                                }}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.tagtypeerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="tagtypevalue"
                            className="col-form-label "
                        >
                            Tag Type Value
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                disabled={true}
                            />
                        ) : this.state.tagtype === 'topup' &&
                          !(this._isEditMode() || this._isViewMode()) ? (
                            <StatusOptionComp
                                statusoption={formatLanguageArray(
                                    this.state.topupTagTypeOptions,
                                )}
                                selectedOption={this.state.tagtypevalue}
                                callbackChangeFilter={(value) => {
                                    this.setState({
                                        tagtypevalue: value,
                                        topupcardtype: value,
                                    });
                                }}
                            />
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                onChange={(event) =>
                                    this.setState({
                                        tagtypevalue: event.target.value,
                                    })
                                }
                                disabled={
                                    this.state.tagtype === 'receiptheader' ||
                                    this.state.tagtype === 'receiptfooter' ||
                                    this.state.tagtype ===
                                        'topupreceiptheader' ||
                                    this.state.tagtype ===
                                        'topupreceiptfooter' ||
                                    this.state.tagtype === 'truckinfo' ||
                                    this.state.tagtype === 'invoiceheader'
                                }
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.tagtypevalueerror}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    _renderTopupSettings = () => {
        if (this.state.tagtype === 'topup') {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="row">
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="topupdesc"
                                className="floatLeft required"
                            >
                                Description
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                value={this.state.topupdesc}
                                onChange={(event) =>
                                    this.setState({
                                        topupdesc: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.topupdescerror}
                            </div>
                        </div>

                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="topupgroup"
                                className="floatLeft required"
                            >
                                Topup Group
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.topupgroup}
                                    disabled={true}
                                />
                            ) : (
                                <StatusOptionComp
                                    statusoption={this.topupGroupOptions}
                                    selectedOption={this.state.topupgroup}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ topupgroup: value });
                                    }}
                                />
                            )}
                            <div className="invalid-feedback">
                                {this.state.topupgrouperror}
                            </div>
                        </div>
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="selecteddevices"
                                className="floatLeft required"
                            >
                                Select Device
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deviceid"
                                    value={this.state.topupdeviceid}
                                    disabled={true}
                                />
                            ) : !this._isViewMode() ? (
                                <StatusOptionComp
                                    statusoption={this.state.storedevices}
                                    selectedOption={this.state.topupdeviceid}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ topupdeviceid: value });
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deviceid"
                                    value={this.state.topupdeviceid}
                                    onChange={(event) =>
                                        this.setState({
                                            topupdeviceid: event.target.value,
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>

                    <div className="row" />

                    <div className="row">
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="topupproductcode"
                                className="floatLeft"
                            >
                                Product Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="topupproductcode"
                                value={this.state.topupproductcode}
                                onChange={(event) =>
                                    this.setState({
                                        topupproductcode: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.topupproductcodeerror}
                            </div>
                        </div>
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="topupminamount"
                                className="floatLeft"
                            >
                                Min Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="topupminamount"
                                value={this.state.topupminamount}
                                onChange={(event) =>
                                    this.setState({
                                        topupminamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="topupmaxamount"
                                className="floatLeft"
                            >
                                Max Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="topupmaxamount"
                                value={this.state.topupmaxamount}
                                onChange={(event) =>
                                    this.setState({
                                        topupmaxamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <label htmlFor="topupimg" className="floatLeft">
                                Topup Image
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="topupimg"
                                    value={this.state.topupimg}
                                    onChange={(event) =>
                                        this.setState({
                                            topupimg: event.target.value,
                                        })
                                    }
                                    disabled={true}
                                />
                            ) : (
                                <SimpleImageInput
                                    placeholder="Choose Image.."
                                    setImage={(value) => {
                                        this.setState({ topupimg: value });
                                    }}
                                    value={this.state.topupimg}
                                ></SimpleImageInput>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <label
                                htmlFor="topupinstruction"
                                className="floatLeft"
                            >
                                Topup Instructions
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="topupinstruction"
                                value={this.state.topupinstruction}
                                onChange={(event) =>
                                    this.setState({
                                        topupinstruction: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <label
                                htmlFor="topupanimationimg"
                                className="floatLeft"
                            >
                                Topup Animation
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="topupanimationimg"
                                value={this.state.topupanimationimg}
                                onChange={(event) =>
                                    this.setState({
                                        topupanimationimg: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };

    _renderDefaultSettings = () => {
        if (this.state.tagtype === 'tprops') {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="tenderdesc"
                                className="floatLeft required"
                            >
                                Auto Lock Timeout
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                value={this.state.autolocktimeout}
                                onChange={(event) =>
                                    this.setState({
                                        autolocktimeout: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.autolocktimeouterror}
                            </div>
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="rabbittandcarticlecode"
                                className="floatLeft required"
                            >
                                Member T&amp;C Article Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="rabbittandcarticlecode"
                                value={this.state.rabbittandcarticlecode}
                                onChange={(event) =>
                                    this.setState({
                                        rabbittandcarticlecode:
                                            event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };

    renderTruckDetails = () => {
        if (this.state.tagtype === 'truckinfo') {
            return this._renderAdditionalFields();
        }
    };

    renderCreditDetails = () => {
        if (this.state.tagtype === 'creditinfo') {
            return this._renderCreditInfoFields()
        }
        
    }

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const { fieldvaltype } = this.state;
        const rowLength = this.state.truckAdditionalFields.length;
        let lastele = this.state.truckAdditionalFields[rowLength - 1];
        const item = {
            field: '',
            value: fieldvaltype === 'array' ? [''] : '',
            image: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                truckAdditionalFields: [
                    ...this.state.truckAdditionalFields,
                    item,
                ],
            },
            () => {
                console.log('Rows After Add', this.state.truckAdditionalFields);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            disabled: false,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'image'
                      ? event
                      : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.truckAdditionalFields.length === 0) {
                    draft.truckAdditionalFields.push(obj);
                } else {
                    let found = false;
                    draft.truckAdditionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.truckAdditionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.truckAdditionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.truckAdditionalFields.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ truckAdditionalFields: updateValues });
        }
    };

    _renderAdditionalFields = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group override col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'field',
                                    label: 'Key',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.truckAdditionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </>
        );
    };

    deleteCreditRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.creditAdditionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.creditAdditionalFields.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ creditAdditionalFields: updateValues });
        }
    };


    handleAddCreditRow = (e) => {
        if (e) e.preventDefault();
        const { fieldvaltype } = this.state;
        const rowLength = this.state.creditAdditionalFields.length;
        let lastele = this.state.creditAdditionalFields[rowLength - 1];
        const item = {
            field: '',
            value: fieldvaltype === 'array' ? [''] : '',
            image: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                creditAdditionalFields: [
                    ...this.state.creditAdditionalFields,
                    item,
                ],
            },
            () => {
                console.log('Rows After Add', this.state.creditAdditionalFields);
            },
        );
    };

    _renderCreditInfoFields = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group override col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'field',
                                    label: 'Key',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.creditAdditionalFields}
                            deleteRow={this.deleteCreditRow}
                            addRow={this.handleAddCreditRow}
                            handleInputChange={this.handleCreditInputChange}
                        />
                    </div>
                </div>
            </>
        );
    };

    handleCreditInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            disabled: false,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'image'
                      ? event
                      : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.creditAdditionalFields.length === 0) {
                    draft.creditAdditionalFields.push(obj);
                } else {
                    let found = false;
                    draft.creditAdditionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.creditAdditionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    _renderReceiptInvoiceDetails = () => {
        if (
            this.state.tagtype === 'receiptheader' ||
            this.state.tagtype === 'receiptfooter' ||
            this.state.tagtype === 'topupreceiptheader' ||
            this.state.tagtype === 'topupreceiptfooter' ||
            this.state.tagtype === 'invoiceheader'
        ) {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    {this.state.tagtype !== 'receiptfooter' &&
                        this.state.tagtype !== 'topupreceiptfooter' && (
                            <div>
                                <div className="row">
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label
                                            htmlFor="companyname"
                                            className="floatLeft required"
                                        >
                                            Company Name
                                        </label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            id="companyname"
                                            value={this.state.companyname}
                                            onChange={(event) =>
                                                this.setState({
                                                    companyname:
                                                        event.target.value,
                                                })
                                            }
                                            disabled={this._isViewMode()}
                                        />
                                        <div className="invalid-feedback">
                                            {this.state.companynameerror}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label
                                            htmlFor="productplaceholderimg"
                                            className="floatLeft"
                                        >
                                            Logo
                                        </label>
                                        {this._isViewMode() ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="productplaceholderimg"
                                                value={
                                                    this.state.invoiceheaderlogo
                                                }
                                                onChange={(event) =>
                                                    this.setState({
                                                        invoiceheaderlogo:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={true}
                                            />
                                        ) : (
                                            <SimpleImageInput
                                                placeholder="Choose Image.."
                                                setImage={(value) => {
                                                    this.setState({
                                                        invoiceheaderlogo:
                                                            value,
                                                    });
                                                }}
                                                value={
                                                    this.state.invoiceheaderlogo
                                                }
                                            ></SimpleImageInput>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <ReceiptLineConfig
                                columns={[
                                    {
                                        dataFieldId: 'text',
                                        label: 'Text',
                                        addRow: true,
                                    },
                                    {
                                        dataFieldId: 'alignment',
                                        label: 'Alignment',
                                        addRow: true,
                                    },
                                    { dataFieldId: 'AD', label: '' },
                                ]}
                                rows={this.state.receiptlines}
                                deleteRow={this.deleteRow}
                                addRow={this.handleAddRow}
                                handleInputChange={this.handleInputChange}
                                handleAlignmentChange={
                                    this.handleAlignmentChange
                                }
                                isViewMode={this._isViewMode()}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };

    _renderTaxReportHeaderDetails = () => {
        if (
            this.state.tagtype === 'taxreportheader' ||
            this.state.tagtype === 'topupreportheader'
        ) {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <TaxReportHeaderConfig
                        isView={this._isViewMode}
                        fields={this.state.additionalFields}
                        _updateTaxReportHeaderDetails={
                            this._updateTaxReportHeaderDetails
                        }
                    />
                </>
            );
        }
    };

    _updateTaxReportHeaderDetails = (language, _additionalFields) => {
        this.setState({
            additionalFields: {
                ...this.state.additionalFields,
                [language]: _additionalFields,
            },
        });
    };

    _renderPeripheralsSection = () => {
        if (!this._isEditMode()) {
            return;
        }
        return (
            <>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Peripherals</div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecodes"
                            className="floatLeft required"
                        >
                            Select Peripherals
                        </label>
                        {/* <StoreSearchSingle value={this.state.storelabel} onSelectStore={this._onSelectStore} /> */}
                    </div>
                </div>
            </>
        );
    };

    _renderOtherPropDetails = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="status"
                                value={this.state.status}
                                disabled={true}
                            />
                        ) : (
                            <StatusOptionComp
                                statusoption={this.editModeStatus}
                                selectedOption={this.state.status}
                                callbackChangeFilter={(value) => {
                                    this.setState({ status: value });
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    };

    _renderMainForm = () => {
        return (
            <>
                <div className="grid px-3">
                    <div className="col-9">
                        <form className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderBasicDetails()}
                            {this._renderDefaultSettings()}
                            {this._renderReceiptInvoiceDetails()}
                            {this._renderTopupSettings()}
                            {this._renderTaxReportHeaderDetails()}
                            {this.renderTruckDetails()}
                            {this.renderCreditDetails()}
                        </form>
                    </div>
                    <div className="col-3">
                        <form className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderOtherPropDetails()}
                        </form>
                    </div>
                </div>
            </>
        );
    };

    componentWillReceiveProps = () => {
        const { storeDevices, topupTagTypes: propConfigs } =
            this.props.queryData;

        if (storeDevices && storeDevices.length > 0) {
            var returnedDevices = storeDevices.map((e) => ({
                name: e.title,
                id: e.deviceid,
            }));
            this.setState({
                storedevices: [{ name: 'Select', id: '' }].concat(
                    returnedDevices,
                ),
            });
        } else {
            this.setState({ storedevices: [{ name: 'Select', id: '' }] });
        }

        if (
            propConfigs &&
            propConfigs.length > 0 &&
            propConfigs[0].additionalfields
        ) {
            this.setState({
                topupTagTypeOptions: Object.assign(
                    { Select: 'Select' },
                    propConfigs[0].additionalfields,
                ),
            });
        }
    };

    componentDidMount = () => {
        this.props.dispatch(
            storeTerminalsSearchByFields(
                `storeid=${this.state.storeid}&devicegroup=terminal&status=Active`,
            ),
        );
    };

    componentWillMount = () => {
        const storeid = localStorage.getItem('storeid');
        this.setState({ storeid });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    title,
                    taggroup,
                    tagtype,
                    tagtypevalue,
                    status,
                    additionalfields,
                } = rec;

                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.terminalid = terminalid ? terminalid : '';
                        draft.taggroup = taggroup ? taggroup : '';
                        draft.tagtype = tagtype ? tagtype : '';
                        draft.tagtypevalue = tagtypevalue ? tagtypevalue : '';
                        draft.topupcardtype =
                            tagtype === 'topup' ? draft.tagtypevalue : '';
                        draft.status = status ? status : '';

                        draft.additionalFields = additionalfields;
                        if (
                            typeof additionalfields !== 'undefined' &&
                            additionalfields !== null &&
                            Object.keys(additionalfields).length !== 0 &&
                            tagtype === 'truckinfo'
                        ) {
                            let newAdditionalFields =
                                modifyObject(additionalfields);
                            draft.truckAdditionalFields = newAdditionalFields;
                        } else {
                            draft.truckAdditionalFields = defaultaddField;
                        }

                        if (
                            typeof additionalfields !== 'undefined' &&
                            additionalfields !== null &&
                            Object.keys(additionalfields).length !== 0 &&
                            tagtype === 'creditinfo'
                        ) {
                            let newAdditionalFields =
                                modifyObject(additionalfields);
                            draft.creditAdditionalFields = newAdditionalFields;
                        } else {
                            draft.creditAdditionalFields = defaultaddField;
                        }

                        if (additionalfields) {
                            if (additionalfields['rabbittandcarticlecode']) {
                                draft.rabbittandcarticlecode =
                                    additionalfields['rabbittandcarticlecode'];
                            }

                            //receiptheader || receiptfooter || invoiceheader
                            if (
                                tagtype === 'receiptheader' ||
                                tagtype === 'receiptfooter' ||
                                tagtype === 'topupreceiptheader' ||
                                tagtype === 'topupreceiptfooter' ||
                                tagtype === 'invoiceheader'
                            ) {
                                if (additionalfields['lines']) {
                                    draft.receiptlines = additionalfields[
                                        'lines'
                                    ].map((e, i) => {
                                        return {
                                            text: e.text,
                                            alignment: e.alignment,
                                            id: i,
                                        };
                                    });

                                    //draft.receiptlines = additionalfields['lines']
                                }
                                if (additionalfields['logo']) {
                                    draft.invoiceheaderlogo =
                                        additionalfields['logo'];
                                }
                                if (additionalfields['companyname']) {
                                    draft.companyname =
                                        additionalfields['companyname'];
                                }
                            }

                            //topup
                            if (additionalfields['topupdesc']) {
                                draft.topupdesc = additionalfields['topupdesc'];
                            }
                            if (additionalfields['topupminamount']) {
                                draft.topupminamount =
                                    additionalfields['topupminamount'];
                            }
                            if (additionalfields['topupmaxamount']) {
                                draft.topupmaxamount =
                                    additionalfields['topupmaxamount'];
                            }
                            if (additionalfields['topupimg']) {
                                draft.topupimg = additionalfields['topupimg'];
                            }
                            if (additionalfields['topupinstruction']) {
                                draft.topupinstruction =
                                    additionalfields['topupinstruction'];
                            }
                            if (additionalfields['topupanimationimg']) {
                                draft.topupanimationimg =
                                    additionalfields['topupanimationimg'];
                            }
                            if (additionalfields['topupgroup']) {
                                draft.topupgroup =
                                    additionalfields['topupgroup'];
                            }
                            if (additionalfields['topup_product_code']) {
                                draft.topupproductcode =
                                    additionalfields['topup_product_code'];
                            }

                            if (tagtype === 'topup') {
                                if (additionalfields['deviceid']) {
                                    draft.topupdeviceid =
                                        additionalfields['deviceid'];
                                }
                            }
                        }
                    }),
                );
            }
        }

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: TOPUPTAGTYPES,
                    },
                },
                SEARCH_PROP_CONFIG_TOPUPTAGTYPES,
                SEARCH_PROP_CONFIG_TOPUPTAGTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_TOPUPTAGTYPES_FAILURE,
            ),
        );

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propcode: 'TERMINAL_OPTIONS_TYPES',
                    },
                },
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
            ),
        );

        if (storeid && storeid !== '') {
            this.props.dispatch(
                storeDevicesSearchByFields(
                    `status=Active&storeid=${storeid}&devicegroup=eft`,
                ),
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeTerminals, storeDevices, masterTrxGrpTypes } = this.props.queryData;
        const {
            storeTerminals: prevStoreTerminals,
            storeDevices: prevstoreDevices,
            masterTrxGrpTypes: prevmasterTrxGrpTypes,
        } = prevProps.queryData;

        if (storeTerminals !== prevStoreTerminals) {
            if (storeTerminals && storeTerminals.length > 0) {
                const options = storeTerminals.map((e) => ({
                    label: e.terminalid + ' - ' + e.title,
                    value: e.terminalid,
                }));
                this.setState({ storeterminals: options });
                if (this.state.terminalid != '') {
                    const _selectedTerminal = storeTerminals.filter(
                        (e) => e.terminalid === this.state.terminalid,
                    );
                    if (_selectedTerminal && _selectedTerminal.length > 0) {
                        const selectedTerminal = _selectedTerminal.map((e) => ({
                            label: e.terminalid + ' - ' + e.title,
                            value: e.terminalid,
                        }));
                        this.setState({ selectedTerminal: selectedTerminal });
                    }
                }
            } else {
                this.setState({ storeterminals: [], selectedTerminal: {} });
            }
        }

        if (storeDevices !== prevstoreDevices) {
            if (storeDevices && storeDevices.length > 0) {
                var returnedDevices = storeDevices.map((e) => ({
                    name: e.title,
                    id: e.deviceid,
                }));
                this.setState({
                    storedevices: [{ name: 'Select', id: '' }].concat(
                        returnedDevices,
                    ),
                });
            } else {
                this.setState({ storedevices: [{ name: 'Select', id: '' }] });
            }
        }
        if (
            masterTrxGrpTypes !== prevmasterTrxGrpTypes &&
            masterTrxGrpTypes.length > 0 &&
            masterTrxGrpTypes[0].additionalfields
        ) {
            const output = Object.entries(
                masterTrxGrpTypes[0].additionalfields,
            ).map(([name, id]) => ({ name, id }));

            // Add the default "Select" option
            output.unshift({ name: 'Select', id: '' });
            this.setState({ tagTypeOptions: output });
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    getTitle = () => {
        return this._isEditMode()
            ? 'Edit Terminal Config'
            : this._isViewMode()
              ? 'View Terminal Config'
              : 'Add Terminal Config';
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <>
                <div className="form_height px-2">
                    <div className="grid pl-3 ml-1 mb-4">
                        <h2 className="page-header">{this.getTitle()}</h2>
                    </div>
                    <div className="grid px-3grid px-3 w-100 mx-1">
                        {this._renderErrorMessage()}
                    </div>

                    {this._renderMainForm()}

                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/terminaloptions"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Terminal Config updated successfully'
                                    : 'Terminal Config created successfully'}
                            </div>
                        }
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddTerminalOptions);
