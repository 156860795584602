import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    issueCoupon,
    searchByCouponRule,
    searchCouponByFields,
} from '../../../actions/actions';

import ConfirmAlert from '../../../components/ConfirmAlert';
import StatusOptionComp from '../../../components/StatusOptionComp';
import { crudReset } from '../../../actions/actions';
import Filter from '../../../components/Filter';
import { formatDate } from '../../../helpers/Util';
import Loading from '../../../components/Loading';
import LoadingSubmit from '../../../components/LoadingSubmit';
import ReactSwitch from 'react-switch';
import DatePicker from 'react-date-picker';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AutoComplete } from 'primereact/autocomplete';

class IssueCouponBatch extends React.PureComponent {
    statusCoupon = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Save', id: 'Save' },
        { name: 'Update', id: 'Update' },
        { name: 'Expired', id: 'Expired' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',

            // Formrelated
            storeid: '',
            storelabel: '',
            couponcode: '',
            rulecode: '',
            category: '',
            qty: '',
            title: '',
            autoexport: false,
            validityoverride: false,

            issuemode: '',
            couponvalue: 0,
            couponvaluetype: '',
            couponqty: 1,
            couponusedqty: 0,
            validfrom: '',
            validtill: '',
            status: 'Active',

            couponslist: [],
            couponrules: [],

            // Errors
            storeiderror: '',
            couponcodeerror: '',
            rulecodeerror: '',
            qtyerror: '',
            validDateError: '',

            //loading
            isLoading: false,

            //warning
            acceptWarning: false,
            limitWarning: false,
            couponcodeObj: '',
            filteredCouponCodes: null,
        };
    }

    componentDidUpdate = (prevProps) => {
        const { couponsList, couponRuleList } = this.props.queryData;

        const {
            couponsList: prevcouponsList,
            couponRuleList: prevcouponRuleList,
        } = prevProps.queryData;

        if (couponsList !== prevcouponsList) {
            if (couponsList && couponsList.length > 0) {
                let returnedData = couponsList.map((e) => ({
                    name: e.title + ' - ' + e.couponcode,
                    id: e.couponcode,
                }));
                this.setState({
                    couponcode: '',
                    couponslist: returnedData
                });
            } else {
                this.setState({
                    couponcode: '',
                    couponslist: [{ name: 'Select', id: '' }],
                });
            }
        }

        if (couponRuleList !== prevcouponRuleList) {
            if (couponRuleList && couponRuleList.length > 0) {
                let returnedData = couponRuleList.map((e) => ({
                    name: e.rulecode + ' - ' + e.title,
                    id: e.rulecode,
                }));
                this.setState({
                    rulecode: returnedData[0].id,
                    couponrules: returnedData
                });
            } else {
                this.setState({
                    rulecode: '',
                    couponrules: [{ name: 'Select', id: '' }],
                });
            }
        }

        const { isSuccess } = this.props.crudData;
        if (isSuccess) {
            this.setState({ isLoading: false });
        }
    };

    getRuleCode = (couponcode) => {
        this.props.dispatch(
            searchByCouponRule({
                body: { couponcode: couponcode, status: 'Active' },
            })
        );
    };

    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    getCreatePayload = () => {
        const {
            title,
            couponcode,
            issuemode,
            rulecode,
            couponvalue,
            couponvalueby,
            couponqty,
            couponusedqty,
            validfrom,
            validtill,
            status,
        } = this.state;

        let body = {
            title,
            couponcode,
            issuemode,
            category: 'Member',
            // category,
            rulecode,
            memberid: '',
            couponvalue: parseInt(couponvalue),
            couponvalueby,
            couponqty: parseInt(couponqty),
            couponusedqty: parseInt(couponusedqty),
            validfrom: formatDate(validfrom),
            validtill: formatDate(validtill),
            expirydate: formatDate(validtill),
            status,
            additionalfields: {},
        };
        const payload = {
            body: body,
        };
        return payload;
    };

    _createCouponTrx = () => {
        const autoExport = this.state.autoexport ? 'Y' : 'N'
        const validityoverride = this.state.validityoverride ? 'Y' : 'N'
        this.setState({ isLoading: true, limitWarning: false });
        this.props.dispatch(
            issueCoupon(this.getCreatePayload(), this.state.qty, autoExport, validityoverride)
        );
    };

    handleCreateCoupon = () => {
        if (this.validateForm()) {
            this.setState({ limitWarning: true})
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    }

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            couponcodeerror: '',
            rulecodeerror: '',
            qtyerror: '',
            validDateError: ''
        });
    };


    validateForm = () => {
        this._clearErrorMessages();
        const {
            // storeid,
            couponcode,
            rulecode,
            qty,
            validityoverride,
            validfrom,
            validtill
        } = this.state;
        let valid = true;
        if (couponcode.trim() === '') {
            this.setState({ couponcodeerror: 'Please select coupon code' });
            valid = false;
        }
        if (rulecode.trim() === '') {
            this.setState({ rulecodeerror: 'Please select rule code' });
            valid = false;
        }
        if (qty <= 0) {
            this.setState({ qtyerror: 'Please enter the valid quantity' });
            valid = false;
        }
        if (validityoverride && validtill.length == 0 && validfrom.length == 0) {
          this.setState({ validDateError: 'Please select valid from and valid till' });
          valid = false
        }
        return valid;
    };

    _setTenant = (p1, p2) => {
        const storeLabel = p2.substring(
            p2.indexOf('-1') + p1.length + 4,
            p2.length
        );
        this.setState({ storeid: p1, storelabel: storeLabel });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderMainButton = () => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => {
                        this.handleCreateCoupon();
                    }}
                    disabled={this.state.isLoading}
                >
                    Save
                </button>

                <Link
                    to="/ui/issue-export-coupon"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    searchCouponCode = (event) => {
        const { couponslist } = this.state
        setTimeout(() => {
            let _filteredCouponCodes;
            if (!event.query.trim().length) {
                _filteredCouponCodes = [...couponslist];
            }
            else {
                _filteredCouponCodes = couponslist.filter((coupon) => {
                    return coupon.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCouponCodes: _filteredCouponCodes})
        }, 250);
    }

    handleChangeCouponObj = (e) => {
        this.setState({ 
            couponcodeObj: e.target.value, 
            couponcode : e.target.value ? e.target.value.id : ''
        })

        if (e.target.value && e.target.value.id) {
            this.getRuleCode(e.target.value.id);
        }
    }

    _renderMainFormDetails = () => {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="couponcode"
                            className="floatLeft required"
                        >
                            Coupon Code
                        </label>
                        <AutoComplete 
                            className="w-100"
                            value={this.state.couponcodeObj} 
                            suggestions={this.state.filteredCouponCodes} 
                            completeMethod={this.searchCouponCode} 
                            field="name" 
                            dropdown 
                            forceSelection 
                            onChange={this.handleChangeCouponObj}
                            aria-label="Coupon Code" 
                            dropdownAriaLabel="Select Coupon Code" 
                        />
                        <div className="invalid-feedback">
                            {this.state.couponcodeerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="rulecode"
                            className="floatLeft required"
                        >
                            Rule Code
                        </label>
                        <Filter
                            cssClass={
                                this.state.rulecodeerror === ''
                                    ? 'form-control'
                                    : 'form-control error-control'
                            }
                            filterOptions={this.state.couponrules}
                            selectedOption={this.state.rulecode}
                            callbackChangeFilter={(event) => {
                                this.setState({ rulecode: event.target.value });
                            }}
                            id="rulecode"
                        />
                        <div className="invalid-feedback">
                            {this.state.rulecodeerror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="description"
                            className="floatLeft required"
                        >
                            Qty(1-100,000)
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="qty"
                            value={this.state.qty}
                            min="0"
                            max="10000"
                            maxLength="5"
                            onChange={(event) => this.handleQtyChange(event)}
                        />
                        <div className="invalid-feedback">
                            {this.state.qtyerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12" style={{ display: 'flex', flexDirection: 'column'}}>
                        <label
                            htmlFor="validityoverride"
                        >
                            Coupon validation override
                        </label>
                        <ReactSwitch
                                checked={this.state.validityoverride}
                                onChange={this.handleValiditySwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                            />
                    </div>
                </div>
                {this.state.validityoverride && (
                  <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Valid From
                        </label>
                        <DatePicker
                            onChange={(value) => {
                                this.setState({
                                    validfrom: value,
                                });
                            }}
                            value={this.state.validfrom}
                        />
                        <div className="invalid-feedback">
                            {this.state.validDateError}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Valid Till
                        </label>
                        <DatePicker
                            minDate={this.state.validfrom}
                            onChange={(value) => {
                                this.setState({
                                    validtill: value,
                                });
                            }}
                            value={this.state.validtill}
                        />
                    </div>
                    
                  </div>
                )}
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12" style={{ display: 'flex', flexDirection: 'column'}}>
                        <label
                            htmlFor="autoexport"
                        >
                            Auto Export
                        </label>
                        <ReactSwitch
                                checked={this.state.autoexport}
                                onChange={this.handleExportSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                            />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    handleExportSwitch = (value) => {
      this.setState({ autoexport: value })
    }

    handleValiditySwitch = validityoverride => {
      this.setState({ validityoverride })
    }

    handleQtyChange = (event) => {
        const value = Math.max(0, Math.min(100000, Number(event.target.value)));
        this.setState({ qty: parseInt(value) });
    };

    _renderStatusDetails = () => {
        const { languageConfig } = this.props.queryData;
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.statusCoupon}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderMainFormDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    componentWillMount = () => {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        // this.props.dispatch(getCouponCodeList());
        this.props.dispatch(
            searchCouponByFields({ body: { status: 'Active' } })
        );
    };

    componentWillReceiveProps = () => {
        const { couponsList } = this.props.queryData;

        if (couponsList && couponsList.length > 0) {
            let returnedData = couponsList.map((e) => ({
                name: e.title + ' - ' + e.couponcode,
                id: e.couponcode,
            }));
            this.setState({
                couponslist: [{ name: 'Select', id: '' }].concat(returnedData),
            });
        } else {
            this.setState({ couponslist: [{ name: 'Select', id: '' }] });
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    toggleLimitWarning = () => {
      this.setState({ acceptWarning: true})
    }

    renderDialogFooter = () => {
        return (
            <div style={{ justifyContent: 'center', display: 'flex'}}>
            <Button 
            className='p-button-danger'
            label="OK" icon="pi pi-check" onClick={() =>this._createCouponTrx()} autoFocus />
        </div>
        )
    }

    renderDialogHeader = () => {
        return (
            <></>
        )
    }

    render() {
        const { isSuccess } = this.props.crudData;
        if (
            this.props.queryData.apiPending ||
            this.props.crudData.crudApiPending
        ) {
            return <Loading />;
        }
        return (
            <React.Fragment>
                {this.state.isLoading && <LoadingSubmit />}
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">Issue Coupon</h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/issue-export-coupon"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                Coupon issued successfully
                            </div>
                        }
                    />
                  {/* <Dialog 
                        showHeader={false}
                        contentClassName="warning-dialog-content"
                        header={this.renderDialogHeader()}
                        visible={this.state.limitWarning && !this.state.acceptWarning} 
                        footer={this.renderDialogFooter()} 
                        onHide={() => this.toggleLimitWarning()}>
                        <p style={{ color: '#c31e1e', fontWeight: '500', fontSize: '16px'}}>Generating more than 1000 coupon codes will impact system permormance.</p>
                    </Dialog> */}
                    <ConfirmDialog 
                        visible={this.state.limitWarning} 
                        onHide={() => this.toggleLimitWarning()} 
                        message="Generating more than 1000 coupon codes will impact system permormance."
                        header="" icon="pi pi-exclamation-triangle" 
                        accept={this._createCouponTrx} 
                        reject={() => this.setState({ limitWarning: false })} 
                        acceptLabel="Ok"
                        rejectLabel="Cancel"
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(IssueCouponBatch);
