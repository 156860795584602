export const searchFields = [
  { id: "id", name: "Id" },
  { id: "title", name: "Title" },
  { id: "status", name: "Status" }
];

export const statusValues = [
  { name: "New", id: "New" },
  { name: "Active", id: "Active" },
  { name: "Deleted", id: "Deleted" },
  { id: "Published", name: "Published" },
  { id: "Unpublished", name: "Unpublished" }
];
