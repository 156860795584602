import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
const { SearchBar } = Search;

class SimpleTable_CheckBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {  };
  }

  render() {
    const option = {
      noDataText: (
        <i
          className="fa fa-circle-o-notch fa-spin"
          style={{ fontSize: "24px" }}
        ></i>
      )
    };
    const selectRow = {
      mode: "checkbox",
      //clickToSelect: true,
      onSelect: this.props.handleOnSelect,
      onSelectAll: this.props.handleOnSelectAll,
      selected: this.props.selected
    };

    const { columns, rows, selectMode } = this.props;
    
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="idx"
          data={rows}
          columns={columns}
          search
          bootstrap4
        >
          {props => (
            <div>
              {
                <div className="col-12 alignRight noPadding noMargin">
                  <SearchBar
                    style={{
                      border: "1px solid #007bff",
                      height: "2.9em",
                      borderRadius: "4px",
                      color: "#007bff",
                      marginLeft: "2px",
                      marginBottom: "4px"
                    }}
                    {...props.searchProps}
                  />
                </div>
              }
              <div className="table-wrapper">
                <BootstrapTable
                  {...props.baseProps}
                  //pagination={paginationFactory(options)}
                  options={option}
                  selectRow={selectRow}
                />
                {this.props.rows.length === 0 && (
                  <div className="tableNoData">
                    Your search does not retrieve any data. Please search again
                    using another criteria.
                  </div>
                )}
              </div>
            </div>
          )}
        </ToolkitProvider>
      </React.Fragment>
    );
  }
}
SimpleTable_CheckBox.propTypes = {};

export default SimpleTable_CheckBox;
