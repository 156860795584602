import React from "react";
import { connect } from "react-redux";
import MultiLineChart from "./../../Charts/MultiLineChart";
import { toDate, generateColor } from "../../../helpers/Util";
import ChartOptions from "./../../../components/ChartOptions";
import ChartTable from "./../../../components/ChartTable";
import { 
  queryMemberSalesSummary
} from "../../../actions/actions";
import StoreSearchSingle from "../../system/Users/StoreSearchSingle";

class MemberAnalysis extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showTableId: null,
      showTable: false,
      analysisfromdate: new Date("Jan 1, 2019 0:00:00"),
      analysistilldate: new Date(),
      storeid: "1010",
      storelabel: "H+H Coffee Centropolis",
      status: "",
      updated: false,
      ismember: "Y",
      totalmembers: "",
      // newmembers: "",
      // activemembers: "",
      // inactivemembers: "",
      membersalesumm: "",
      pageno: "1",
      pagesize: "20"
    };
  }

  componentDidMount() {
    this.callFetch();
  }


  callFetch(){
    // this.props.dispatch(queryActiveMembers(this.createFetchUrl("Active")));
    // this.props.dispatch(queryNewMembers(this.createFetchUrl("New")));
    // this.props.dispatch(queryInactiveMembers(this.createFetchUrl("Inactive")));
    this.props.dispatch(queryMemberSalesSummary(this.createFetchMember()));
  }

  createFetchMember = () => {
    const { analysisfromdate, analysistilldate, pageno, pagesize } = this.state;
    const requestBody = {
      body: {
        date_from: toDate(analysisfromdate),
        date_till: toDate(analysistilldate),
        status: "close",
        pageno: pageno,
        pagesize: pagesize
      }
    }
    return requestBody;
  }

  createFetchUrl=(status)=>{
    const { analysisfromdate, analysistilldate, ismember} = this.state;
    const requestBody = {
      body: {
        date_from: toDate(analysisfromdate),
        date_till: toDate(analysistilldate),
        date_type: "createdon",
        status: status,
        member: ismember,
        groupby: "month"
      }
    };
    return requestBody;
  }  

  componentDidUpdate = () => {
    const  currentProps  = this.props.queryData;
    const  currentState  = this.state;
    if(currentProps.dataByCountry.length  !== Object.entries(currentState).length){
      this.setState({
        updated: true
      })
      this.renderMemberState(currentProps);
    } 
  }

  handleTable = (e) => {
    e.preventDefault();
    this.setState({
      showTableId: e.target.id,
      showTable: true
    });
  }

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 },()=>{
      this._validateAndSearch();
    });
  };

  _onSelectStore = (selectedStores) => {
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  _validateAndSearch = () => {
    if (this.validateForm() === true) {
      this.callFetch();
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };

  validateForm = () => {
    this._clearErrorMessages();
    const { storeid } = this.state;
    let valid = true;
    if (storeid.trim() === "") {
      this.setState({ storeiderror: "Please select store id" });
      valid = false;
    }
    return valid;
  };

  handleChart = (e) => {
    e.preventDefault();
    this.setState({
      showTable: false
    })
  }
  
  renderMemberState(newProps){
    const { newMemberDevice, activeMemberDevice, inactiveMemberDevice, memberSaleSummary } = newProps; 
    const total = [...newMemberDevice, ...activeMemberDevice, ...inactiveMemberDevice];
    this.setState({
      // activemembers: renderTotalRec(activeMemberDevice),
      // newmembers: renderTotalRec(newMemberDevice),
      // inactivemembers: renderTotalRec(inactiveMemberDevice),
      //totalmembers: renderTotalRec(total),
      membersalesumm: memberSaleSummary
    })
  }

  renderMemberSummary = (memsale) => {
    const labeldata = memsale.map(e=>e.memberid);
    const objdata = [{
      label: "totalrecords",
      data: memsale.map(e=>e.totalrec),
      yAxisID : "y-axis-1"
    }, {
      label: "totalamount",
      data: memsale.map(e=>e.totalamount),
      yAxisID : "y-axis-2"
    }]
    return this.renderMultiLineChart("overallmembersale",labeldata, objdata, "member", "totalamount/totalrecords");
  }

  renderMultiLineChart  = (id,labeldata, objdata, xaxes, yaxes) => {  
    const buildObj = obj => {
      const color = generateColor();
      const newobj = { ...obj, fill: false, borderWidth: 1, backgroundColor: color, borderColor: color };
      return newobj;
    }    
    const linedata = objdata.map(o => buildObj(o));
    return <MultiLineChart
      id={id}
      data={linedata}
      labels={labeldata}
      xaxes={xaxes}
      yaxes={yaxes}
    />
  }

  
  _renderQuickViewDiv = () => {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12 ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_totalmember.svg`}
            />
            <p className="card2Text1">Total Members</p>
            <p className="card2Text2">{this.state.totalmembers}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_newmember.svg`}
            />
            <p className="card2Text1">New Members</p>
            <p className="card2Text2">{this.state.newmembers}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_memberactive.svg`}
            />
            <p className="card2Text1">Active Members</p>
            <p className="card2Text2">{this.state.activemembers}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_memberinactive.svg`}
            />
            <p className="card2Text1">Inactive Members</p>
            <p className="card2Text2">{this.state.inactivemembers}</p>
          </div>
        </div>
      </div>
    );
  };

  _renderSelect = (formattedObjArray, selectedOption, dropDownChange) => {
    return (
      <select
        id="statusComp"
        className="form-control"
        value={selectedOption}
        onChange={dropDownChange}
      >
        {formattedObjArray !== undefined ? (
          formattedObjArray.map((t, i) => (
            <option key={i} value={t.id}>
              {t.name}
            </option>
          ))
        ) : (
          <option>No option</option>
        )}
      </select>
    );
  };

  _selectOnChangeSize = (event) => {
    this.setState ({
      pagesize: event.target.value
    },()=>{
      this.props.dispatch(queryMemberSalesSummary(this.createFetchMember()));
    })
  };

  _selectOnChange = (event) => {
    this.setState ({
      pageno: event.target.value
    },()=>{
      this.props.dispatch(queryMemberSalesSummary(this.createFetchMember()));
    })
  };

  _renderStore = () =>{
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            {/* <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            /> */}
            <StoreSearchSingle value={this.state.storelabel}
              onSelectStore={this._onSelectStore}
            />
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  _renderChartDiv = () => {
    const { memberSaleSummary } = this.props.queryData;
    return (
      <React.Fragment>
      {/* <div className="row noPadding noMargin">
        <div className="col-md-12 col-sm-12">
          <form className="splitFrmDiv">
            {this._renderStore()}
          </form>
        </div>
      </div> */}
      <div className="row noPadding noMargin">
        <div className="col-md-12 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row">
              <div className="chartTitle col-lg-6 col-sm-5">Overall Member Sale Summary</div>  
              <div className="col-lg-2 col-sm-2">
                PageNo: {this._renderSelect(
                  [
                    { id: "1", name: "1" },
                    { id: "2", name: "2" },
                    { id: "3", name: "3" },
                    { id: "4", name: "4" },
                    { id: "5", name: "5" },
                    { id: "6", name: "6" },
                    { id: "7", name: "7" },
                    { id: "8", name: "8" },
                    { id: "9", name: "9" },
                    { id: "10", name: "10" },
                  ],
                  this.state.pageno,
                  this._selectOnChange
                )}                 
              </div>
              <div className="col-lg-2 col-sm-2">
                  PageSize: {this._renderSelect(
                  [
                    { id: "5", name: "5" },
                    { id: "10", name: "10" },
                    { id: "20", name: "20" },
                    { id: "30", name: "30" }                                    
                  ],
                  this.state.pagesize,
                  this._selectOnChangeSize
                )}       
              </div>
              <div className="col-lg-2 col-sm-3 alignRight">
               <ChartOptions 
                toggleTable={this.handleTable}
                toggleChart={this.handleChart}
                chartid="overallmembersale"
                tableimgid="salebymonth"
                chartdata={this.state.membersalesumm}
                />
              </div>
            </div>

            <div className="title_devide"></div>
            <div className="row">
              <div className="col-md-12 col-md-12">
              {this.state.showTable === true && this.state.showTableId === "salebymonth" ?
               <ChartTable tabledata={this.state.membersalesumm}/> : this.renderMemberSummary(memberSaleSummary)}
              </div>
            </div>
          </div>
        </div>       
      </div>
      </React.Fragment>
    );
  };
  _renderForm = () => {
    return (
      <div className="row noPadding noMargin">
        <div className="col-lg-12 col-sm-12">
          <form>
            {/* {this._renderQuickViewDiv()} */}
            {this._renderChartDiv()}
          </form>
        </div>
      </div>  
    );
  };
  render() {
    return (
      <div className="form_height">
        <div className="row form-container">
          <div className="col">{this._renderForm()}</div>
        </div>
      </div>
    );
  }
}

MemberAnalysis.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(MemberAnalysis);
