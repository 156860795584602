import React from 'react';
import { searchFields } from './tableCols';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import {
    deleteCouponRedemptionRule,
    searchGeneral,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleTable from '../../../components/SimpleTable';
import Loading from '../../../components/Loading';
import SimpleSearcher from '../../../components/SimpleSearcher';
import {
    permissions,
    canView,
    canDelete,
    canWrite,
} from '../../../helpers/Util';
import EditImage from '../../../components/ImageComponents/EditImage';
import PreviewImage from '../../../components/ImageComponents/PreviewImage';
import PrimeTable from '../../../components/PrimeTable';

class DisplayCouponRedemption extends React.Component {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'CAMP';
        this.group = 'DE_CAMP_COUPON_REDEMPTION_RULES';
        this.allPermissions = [];

        this.state = {
            pages: 10,
            currPage: 0,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            tableCols: [
                {
                    dataField: 'title',
                    text: 'Title',
                    sort: true,
                },
                {
                    dataField: 'storeid',
                    text: 'Store Id',
                    sort: true,
                },
                {
                    dataField: 'couponcode',
                    text: 'Coupon Code',
                    sort: true,
                },
                {
                    dataField: 'rulecode',
                    text: 'Rule Code',
                    sort: true,
                },
                {
                    dataField: 'categorycodes',
                    text: 'Category Codes',
                    sort: true,
                },
                {
                    dataField: 'productcodes',
                    text: 'Product Codes',
                    sort: true,
                },
                {
                    dataField: 'validatelevel',
                    text: 'Validate Level',
                    sort: true,
                },
                {
                    dataField: 'amountmin',
                    text: 'Amount Min',
                    sort: true,
                },
                {
                    dataField: 'qtymin',
                    text: 'Qty Min',
                    sort: true,
                },
                {
                    dataField: 'usedqtymax',
                    text: 'Used Qty Max',
                    sort: true,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'ED',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],
        };
    }

    actionTemplate = row => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {/* {canView(this.allPermissions) && ( */}
                    <Link
                        to={{
                            pathname: '/ui/coupon-redemption-rules/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <PreviewImage />
                    </Link>
                {/* )} */}

                {/* {canWrite(this.allPermissions) && ( */}
                    <Link
                        to={{
                            pathname: '/ui/coupon-redemption-rules/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                                isView: false,
                            },
                        }}
                    >
                        <EditImage />
                    </Link>
                {/* )} */}

                {/* {canDelete(this.allPermissions) && ( */}
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteCouponRule(row)}
                    />
                {/* )} */}
            </span>
        );
    }

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/coupon-redemption-rules/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <PreviewImage />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/coupon-redemption-rules/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                                isView: false,
                            },
                        }}
                    >
                        <EditImage />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteCouponRule(row)}
                    />
                )}
            </span>
        );
    };
    // API Calls
    createFetchUrl = (fetchType) => {
        const { search_field, search_condi, search_value } = this.state;

        const requestBody = {
            body: {
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/redemption/rule/search',
                this.createFetchUrl(fetchType),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                const [pgNumber] = rest;

                newState = produce(this.state, (draft) => {
                    draft.currPage = pgNumber - 1;
                });
                this.setState({ ...newState }, () => {
                    this.callFetch('PAGECLICK');
                });

                break;
            default:
                break;
        }
    };
    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            }
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('ONLOAD')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    pageNumberOnClick = (pgNumber) => {
        this.callQueryApiForFetch('PAGECLICK', pgNumber);
    };

    _renderAddNewBtn = () => {
        const { history } = this.props
        // if (!canWrite(this.allPermissions)) {
        //     return;
        // }
        return (
            <Button
                label="Add Redemption Rule"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/coupon-redemption-rules/addNew')}
            />
        );
    };
    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteCouponRedemptionRule(this.state.delete_rec_id)
            );
        }
    };
    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    _deleteCouponRule = (rowData) => {
        this.setState({
            message: 'Coupon Redemption Rule deleted successfully',
        });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.rulecode,
            e.title,
            e.storegroups,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    render() {
        let errormsg = '';
        const {
            articleFlowData,
            languageConfig,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;

        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }

        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (!articleFlowData && !errormsg) {
            return <Loading />;
        }
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'rulecode', header: 'Rule Code' },
            { field: 'title', header: 'Title' },
            { field: 'storeid', header: 'Store Id' },
            { field: 'storegroups', header: 'Store Group' },
            { field: 'status', header: 'Status' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            <h2 className="page-header">
                                Coupon Redemption Rule
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                        <div className="col-lg-3 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                            <div
                                    className="row"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <div className="col-md-4">
                                        <SimpleSearcher
                                            filterOptions={searchFields}
                                            setAndSearch={this.searchQuery}
                                            coupon_field={true}
                                            search_condi={
                                                this.state.search_condi
                                            }
                                            search_value={
                                                this.state.search_value
                                            }
                                            search_field={
                                                this.state.search_field
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="title_devide" />
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    list={articleFlowData}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    pageSizeChangecallback={
                                        this.pageSizeChangecallback
                                    }
                                    pageChangeCallback={this.pageChangeCallback}
                                    tablePageSize={10}
                                />
                                {/* <SimpleTable
                                    columns={this.state.tableCols}
                                    rows={articleFlowData}
                                    callback={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                /> */}
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this redemption
                                rule?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? errormsg : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayCouponRedemption);
