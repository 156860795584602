import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { ConfirmDialog } from 'primereact/confirmdialog';
import {
    crudReset,
    updateReplenishment,
    updateReplenishmentStatus,
} from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert';

const ReplenishmentDetail = (props) => {
    const { location } = props;
    const { isSuccess } = props.crudData;
    const [record, setRecord] = useState({});
    const [requestList, setRequestList] = useState([]);
    const [isViewMode, setIsViewMode] = useState(false);
    const [dataStatus, setDataStatus] = useState('');
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        if (location && location.state) {
            const { item, isView } = location.state;
            const { status, id } = item;
            setRecord(item);
            setRequestList(item.details);
            if (isView) {
                setIsViewMode(true);
            }
            if (status == 'New' || status == 'Request') {
                const loggedUser = localStorage.getItem('fname');
                const data = {
                    id,
                    status: 'Preparing',
                    lastupdated: moment().format('DD-MM-YYYY HH:mm:ss'),
                    lastupdatedby: loggedUser,
                };
                setRecord({
                    ...item,
                    status: data.status,
                    lastupdated: data.lastupdated,
                    lastupdatedby: data.lastupdatedby,
                });
                props.dispatch(updateReplenishmentStatus({ body: data }));
            } else {
                setDataStatus(status);
            }
        }
    }, [location]);

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        switch (field) {
            case 'requestedqty':
            case 'readyqty':
                if (isPositiveInteger(newValue)) rowData[field] = newValue;
                else event.preventDefault();
                break;

            default:
                rowData[field] = newValue;
                break;
        }
    };

    const isPositiveInteger = (val) => {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, '') || '0';
        let n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    };

    const IconHeader = () => {
        return (
            <div>
                To load
                <i
                    className="pi pi-pencil"
                    style={{ marginLeft: '0.5rem' }}
                ></i>
            </div>
        );
    };

    const RemarkHeader = () => (
        <div>
            Remark
            <i className="pi pi-pencil" style={{ marginLeft: '0.5rem' }}></i>
        </div>
    );

    const handleSave = (updatedStatus) => {
        const { id, details, status, readyby, readyon, requestedby } = record;

        let reqDetails = details.map((item) => ({
            idx: item.idx,
            requestid: item.requestid,
            remark: item.remark,
            status: updatedStatus ? updatedStatus : item.status,
            readyqty: item.readyqty,
        }));

        const allToLoadShouldGreaterZero = reqDetails.some(
            (item) => item.readyqty > 0,
        );

        if (!allToLoadShouldGreaterZero && updatedStatus !== 'Rejected') {
            showError();
            return;
        }

        const data = {
            id,
            status: updatedStatus ? updatedStatus : status,
            details: reqDetails,
            readyby:
                updatedStatus === 'Ready'
                    ? localStorage.getItem('fname')
                    : readyby,
            readyon:
                updatedStatus === 'Ready'
                    ? moment().format('DD-MM-YYYY HH:mm:ss')
                    : readyon,
            requestedby: requestedby,
        };
        props.dispatch(updateReplenishment({ body: data }));
    };

    const showError = () => {
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'At least one of to load quantity should be greater than 0',
            life: 3000,
        });
    };

    const renderMainButton = () => {
        const { status } = record;
        if (isViewMode) {
            return <CloseBtn />;
        }
        if (status == 'New' || status == 'Preparing' || status == 'Request') {
            return (
                <>
                    <CloseBtn />
                    <RejectBtn />
                    <SaveBtn />
                    <ReadyBtn />
                </>
            );
        } else if (status == 'Ready') {
            return (
                <>
                    <CloseBtn />
                    <RejectBtn />
                </>
            );
        } else {
            return <CloseBtn />;
        }
    };

    const CloseBtn = () => {
        return (
            <Button
                label="Close"
                className="p-button-secondary p-button-lg"
                onClick={props.history.goBack}
                style={{ marginRight: '20px' }}
            />
        );
    };

    const RejectBtn = () => {
        return (
            <Button
                label="Reject"
                className="p-button-warning p-button-lg"
                onClick={() => setVisible(true)}
                style={{ marginRight: '20px' }}
            />
        );
    };

    const accept = () => handleSave('Rejected');

    const reject = () => setVisible(false);

    const SaveBtn = () => {
        return (
            <Button
                label="Save"
                className="p-button-lg"
                onClick={() => handleSave('Preparing')}
                style={{ marginRight: '20px' }}
            />
        );
    };

    const ReadyBtn = () => {
        return (
            <Button
                label="Ready for loading"
                onClick={() => handleSave('Ready')}
                style={{ marginRight: '20px' }}
                className="p-button-lg p-button-success"
            />
        );
    };

    const hideModal = (e) => {
        e.preventDefault();
        props.dispatch(crudReset());
    };

    const getReplinishmentColumn = () => {
        const { status } = record;
        if (isViewMode) {
            return <Column field="readyqty" header={<IconHeader />} />;
        } else if (
            status == 'Request' ||
            status == 'New' ||
            status == 'Preparing'
        ) {
            return (
                <Column
                    field="readyqty"
                    header={<IconHeader />}
                    editor={(options) => textEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                />
            );
        } else {
            return <Column field="readyqty" header="To replenish" />;
        }
    };

    const getRemarkColumn = () => {
        const { status } = record;
        if (isViewMode) {
            return <Column field="remark" header={<RemarkHeader />} />;
        } else if (
            status == 'Request' ||
            status == 'New' ||
            status == 'Preparing'
        ) {
            return (
                <Column
                    field="remark"
                    header={<RemarkHeader />}
                    editor={(options) => textEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                />
            );
        } else {
            return <Column field="remark" header="Remark" />;
        }
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">Replenishment Detail</h2>
                    </div>
                </div>
                <div className="row form-container">
                    <div className="col">
                        <div className="frmDiv">
                            <div className="row mt-4">
                                {record && (
                                    <div
                                        className="col-lg-3 col-sm-3"
                                        style={{
                                            borderRight: '1px solid #dee2e6',
                                        }}
                                    >
                                        <h5 className="mt-2">
                                            Replenishment Request
                                        </h5>
                                        <h6 className="mb-3">
                                            ({record.invrequestref})
                                        </h6>
                                        <span style={{ display: 'none' }}>
                                            {record.id}
                                        </span>
                                        <SlipComponent data={record} />
                                    </div>
                                )}
                                <div className="col-lg-9 col-sm-9">
                                    <DataTable
                                        value={requestList}
                                        dataKey="idx"
                                        responsiveLayout="scroll"
                                        className="bg-transparent"
                                    >
                                        <Column
                                            field="description"
                                            header="Product Name"
                                        />
                                        <Column
                                            field="productcode"
                                            header="Product Code"
                                        />
                                        <Column
                                            field="requestedqty"
                                            header="Request"
                                        />

                                        {record.status &&
                                            getReplinishmentColumn()}
                                        {record.status &&
                                            record.status === 'Loaded' && (
                                                <Column
                                                    field="loadedqty"
                                                    header="Loaded"
                                                />
                                            )}
                                        {record.status && getRemarkColumn()}
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row btn-container form-button">
                    <div className="col-12">{renderMainButton()}</div>
                </div>
            </div>
            <ConfirmAlert
                show={isSuccess}
                handleClose={hideModal}
                to="/ui/replenishment"
                children={
                    <div style={{ padding: '2em', color: 'green' }}>
                        Replenishment updated successfully
                    </div>
                }
            />
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="Are you sure you want to reject this replenishment?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={accept}
                reject={reject}
            />
        </>
    );
};

const SignComponent = (props) => {
    const { record } = props;
    if (record.additionalfields?.filepath) {
        return (
            <>
                <Image
                    src={`${localStorage.getItem('imagepath')}${record.additionalfields.filepath}`}
                    alt="Image"
                    width="250"
                />
            </>
        );
    }
    return <></>;
};

const SlipComponent = (props) => {
    const { data } = props;
    const {
        storeid,
        terminalid,
        requestedby,
        requestedon,
        invallocationid,
        status,
        readyby,
        readyon,
        loadedby,
        loadedon,
        remark,
        lastupdatedby,
        lastupdated,
    } = data;

    const rejectTypes = ['Rejected', 'Reject'];
    const prepareTypes = ['Preparing', 'Ready'];
    const preparedTypes = ['Ready', 'Loaded', 'Completed', 'Rejected'];
    const completeTypes = ['Loaded', 'Completed'];
    return (
        <>
            <SlipData slipLabel={'Store Id'} slipData={storeid} />
            <SlipData slipLabel={'Terminal Id'} slipData={terminalid} />
            <SlipData slipLabel="Inv Allocation Id" slipData={invallocationid} />
            <SlipData slipLabel="Requested Date" slipData={requestedon} />
            <SlipData slipLabel="Requestor" slipData={requestedby} />
            <SlipData
                slipLabel="Status"
                slipData={status === 'Loaded' ? 'Completed' : status}
            />
            {prepareTypes.includes(status) && (
                <>
                    <SlipData
                        slipLabel={'Preparing By'}
                        slipData={lastupdatedby}
                    />
                    <SlipData
                        slipLabel={'Preparing Date'}
                        slipData={lastupdated}
                    />
                </>
            )}
            {rejectTypes.includes(status) && (
                <>
                    <SlipData
                        slipLabel="Rejected By"
                        slipData={
                            status === 'Rejected' ? lastupdatedby : readyby
                        }
                    />
                    <SlipData
                        slipLabel="Rejected Date"
                        slipData={lastupdated}
                    />
                </>
            )}
            {status === 'Canceled' && (
                <>
                    <SlipData
                        slipLabel="Canceld By"
                        slipData={
                            status === 'Canceled' ? lastupdatedby : readyby
                        }
                    />
                    <SlipData slipLabel="Canceld Date" slipData={lastupdated} />
                </>
            )}
            {preparedTypes.includes(status) && (
                <>
                    <SlipData slipLabel={'Prepared By'} slipData={readyby} />
                    <SlipData slipLabel="Prepared Date" slipData={readyon} />
                </>
            )}
            {completeTypes.includes(status) && (
                <>
                    <SlipData slipLabel="Received By" slipData={loadedby} />
                    <SlipData slipLabel="Received Date" slipData={loadedon} />
                </>
            )}
            
            <SlipData slipLabel="Remark" slipData={remark} />
            {data.additionalfields?.filepath && (
                <div className="mt-2">
                    <h6 className="mb-3">Proof of Acknowledgement:</h6>
                    <SignComponent record={data} />
                </div>
            )}
        </>
    );
};

const SlipData = (props) => {
    const { slipLabel, slipData } = props;
    return (
        <li
            className="flex align-items-center flex-wrap"
            style={{ paddingBottom: '12px' }}
        >
            <div className="w-6 font-medium">{slipLabel}:</div>
            <div className="text-900 md:flex-order-0 flex-order-1">
                {slipData ? slipData : '-'}
            </div>
        </li>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ReplenishmentDetail);
