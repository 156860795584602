import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

const generateOptionList = (list, key, label) => {
    if (list.length > 0) {
        return list.map((item) => ({
            name: item[key],
            code: item[label],
        })); 
    }
    else {
        return []
    }
}
    

var myHeaders = new Headers();
myHeaders.append(
    'Authorization',
    '2546vmese8ee73897021dfc801c98ddd7315848c00c497df1c70e892113ef22b3eba24335b7b60f68f0632d453b0935d50952232fla16ld5'
);
myHeaders.append(
    'Cookie',
    'JSESSIONID=node017sfwrpljg8mg90k58cehphq833452.node0'
);

var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
};

const CollectorSelector = (props) => {
    const { onChange, collectorid } = props;
    const [collectorUsers, setCollectorUsers] = useState([]);
    const [collectorPerson, setCollectorPerson] = useState('');
    const [collectorPersonArr, setCollectorList] = useState([]);
    const [collectorOptions, setCollectorOptions] = useState([])

    const URL = window && window.config && window.config.END_POINT;

    useEffect(() => {
        fetch(
            `${URL}cms/v1/prop/config/search/fields?propgroup=COLLECTOR_GROUP_ID`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => setCollectorUsers(result))
            .catch((error) => console.log('error', error));
    }, []);

    useEffect(() => {
        if (
            collectorUsers &&
            collectorUsers.length > 0 &&
            collectorUsers[0].additionalfields
        ) {
            const { groupid } = collectorUsers[0].additionalfields;
            fetch(
                `${URL}system/v1/user/search/fields?usergroup=${groupid}&status=active`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => setCollectorList(result))
                .catch((error) => console.log('error', error));
        }
    }, [collectorUsers]);

    useEffect(() => {
        const optionList = generateOptionList(
            collectorPersonArr,
            'firstname',
            'usercode'
        );
        optionList.unshift({ name: 'Select', code: ''})
        setCollectorOptions(optionList);
    }, [collectorPersonArr]);

    useEffect(() => {
        if (collectorOptions.length > 0) {
            setCollectorPerson(collectorOptions.find((u) => u.code == collectorid));
        }
    }, [collectorid, collectorOptions]);

    const handleUserChange = (e) => {
        setCollectorPerson(e.target.value);
        onChange(e.target.value);
    };
    return (
        <>
            <Dropdown
                className="w-100"
                id="collectorPersonGroup"
                name="collectorPersonGroup"
                value={collectorPerson}
                onChange={handleUserChange}
                options={collectorOptions}
                optionLabel="name"
                placeholder="Select"
                //disabled
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(CollectorSelector);
