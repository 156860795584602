import Autosuggest from "react-autosuggest";
import React from "react";
import { searchGeneral } from "../actions/actions";
import { connect } from "react-redux";
import CategoryTree from "./CategoryTree";
import { queryCategoryTree } from "./../actions/actions";

const getSuggestionValue = suggestion => suggestion.title;
const renderSuggestion = suggestion => <span>{suggestion.title}</span>;

class Autocomplete extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      categorycodes: "",
      categories: "",
      suggestions: []
    };
  }

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.updateTitle &&
      this.props.updateTitle(suggestion.productcode.toUpperCase(), suggestion.title,suggestion.categorycodes);
  };
  getSuggestions = value => {
    const { prodKeySearch } = this.props.queryData;
    let retArray = [];
    retArray = prodKeySearch.filter(row => {
      return row.title.indexOf(value) > -1;
    });
    return retArray;
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  rename = obj => {
    for (var prop in obj) {
      if (Array.isArray(obj[prop])) {
        obj[prop] = obj[prop].map(this.rename);
      }

      if (prop === "subcategories") {
        obj.children = obj[prop];
        delete obj[prop];
      }

      if (prop === "title") {
        obj['subtitle'] = obj['categorycode']
      }
    }

    return obj;
  };
  componentDidMount = () => {
    this.props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
  };
  componentWillReceiveProps = newProps => {
    // this.setState({ treeData: newProps.queryData.categoryTree });
  };
  callProds = () => {
    const { categorycodes } = this.state;
    this.props.dispatch(
      searchGeneral(
        `sales/v1/product/category/${categorycodes}`,
        {
          body: {
            status: "Active"
          }
        },
        "PROD_KEY_SEARCH_SUCCESS",
        "PROD_KEY_SEARCH_FAILURE",
        true
      )
    );
  };
  render() {
    const { categoryTree } = this.props.queryData;



    const newArrayOfObj = [categoryTree].map(this.rename);



    console.log ('newArrayObj::', newArrayOfObj)
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Search Product Title",
      value,
      onChange: this.onChange
    };

    return (
      <React.Fragment>
        <div className="row" style={{ height: "1em" }}>
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="categorycodes" className="floatLeft required">
              Select Product Category
            </label>

            <CategoryTree
              treeData={newArrayOfObj}
              categoryTree={categoryTree}
              setCategoryCode={(code, title) => {
                this.setState(
                  { categorycodes: code, categories: title },
                  () => {
                    this.callProds();
                  }
                );
              }}
              value={this.state.categories}
            ></CategoryTree>
          </div>
        </div>
        {this.state.categories === "" ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className="row">
            <div className="form-group col-lg-12 col-sm-12">
              <label htmlFor="categorycodes" className="floatLeft required">
                Suggestions
              </label>
              {this.state.categorycodes !== "" && (
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  onSuggestionSelected={this.onSuggestionSelected}
                  alwaysRenderSuggestions={true}
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(Autocomplete);
