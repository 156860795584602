import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
const { SearchBar } = Search;

class SimpleTable_Expand extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state =  {
      page: 1,
      sizePerPage: 10
    };
  }

  // componentDidUpdate = () =>{
  //   const { page, sizePerPage } = this.state;
  //   if(this.props.rows.length === 0) {
  //     this.setState({
  //       page: 1
  //      },
  //     () => {
  //        setTimeout(() => {
  //           this.props.pageClick(page,sizePerPage);
  //        }, 1000);
  //      })
  //   }
  // }

  render() {
    const { exprows, expcolumns, orderrows, ordercolumns } = this.props;
    const pageButtonRenderer  = ({
       page,
      currentPage,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        if(typeof page !== "string"){
         this.setState({ page: page});
         this.props.pageClick(page, this.state.sizePerPage);
        }
        else{
          if(page === ">"){
            this.setState({page: this.state.page+1},()=>{
            this.props.pageClick(this.state.page, this.state.sizePerPage);
            });
          }
          if(page === "<"){
            this.setState({page: this.state.page-1},()=>{
              this.props.pageClick(this.state.page, this.state.sizePerPage);
            });
          }
        }
      };  
      return (
        <div>
          {this.props.rows.length > 0 && 
            <li className="page-item">
              <a href="#" className="page-link" onClick={handleClick}>{page}</a>
            </li>
          }
        </div>
      );
    };
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="btn-group" role="group">
        {this.props.rows.length !== 0 &&
          options.map(option => (
            <button
              key={option.text}
              type="submit"
              onClick={e => {
                onSizePerPageChange(option.page);
                if (e) e.preventDefault();
              }}
              className={`btn ${
                currSizePerPage === `${option.page}`
                  ? "btn-secondary"
                  : "btn-warning"
              }`}
            >
              {option.text}
            </button>
          ))}
      </div>
    );

    const options = {
      alwaysShowAllBtns: true,
      withFirstAndLast: false,
      sizePerPageRenderer,
      pageButtonRenderer,
      totalSize: 1000,
      hidePageListOnlyOnePage: true,
      onSizePerPageChange: (sizePerPage, page) => {
        this.setState({page: page, sizePerPage: sizePerPage},()=>{
         this.props.pageClick(page,sizePerPage);
        });
      }
    };
    const option = {
      noDataText: (
        <i
          className="fa fa-circle-o-notch fa-spin"
          style={{ fontSize: "24px" }}
        ></i>
      )
    };
     const selectRow = {
        mode: "checkbox",
        clickToSelect: true
     };

    const expandRow = {
        showExpandColumn: true,
        expandColumnPosition: 'right',
        renderer: row => (
          <div>
            {
              typeof orderrows !== "undefined" && orderrows.length > 0 ?
              <div className="table-wrapper1">
                <div className="row">
                  <div className="col-11 tableheader">
                    Order Details
                  </div>
                </div>
                <BootstrapTable
                  condensed
                  keyField="idx"
                  id="expand-table"
                  data={orderrows}
                  columns={ordercolumns}
                />
              </div>
              :
              <div className="tableNoData"> No Items Found</div>
            }
            <div style={{height: "10px"}}></div>
            { typeof exprows !== "undefined" && exprows.length > 0?
              <div className="table-wrapper1">
                 <div className="row">
                  <div className="col-11 tableheader">
                    Shipping Details
                  </div>
                </div>
                <BootstrapTable
                  condensed
                  keyField="idx"
                  id="expand-table"
                  data={exprows}
                  columns={expcolumns}
                />
              </div>
                :
              <div className="tableNoData">
                No Shipment Record.
              </div>
            }
          </div>
        ),
        expanded: [this.props.expanded],
        onExpand: this.props.handleOnExpand
    };
    const { columns, rows, selectMode } = this.props;
    
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={rows}
          columns={columns}
          search
          bootstrap4
        >
          {props => (
            <div>
              {
                <div className="col-12 alignRight noPadding noMargin">
                  <SearchBar
                    style={{
                      border: "1px solid #007bff",
                      height: "2.9em",
                      borderRadius: "4px",
                      color: "#007bff",
                      marginLeft: "2px",
                      marginBottom: "4px"
                    }}
                    {...props.searchProps}
                  />
                </div>
              }
              <div className="table-wrapper ordership">
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  options={option}
                  id="ordership"
                  expandRow={ expandRow }
                />
                {this.props.rows.length === 0 && (
                  <div className="tableNoData">
                    Your search does not retrieve any data. Please search again
                    using another criteria.
                  </div>
                )}
              </div>
            </div>
          )}
        </ToolkitProvider>
      </React.Fragment>
    );
  }
}
SimpleTable_Expand.propTypes = {};
const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(SimpleTable_Expand);
