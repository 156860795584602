import React from 'react';
import {
    useTable,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    usePagination,
} from 'react-table';
import styled from 'styled-components';
import { Pagination } from 'react-bootstrap';

const Styles = styled.div`
    padding: 1rem;
    ${
        '' /* These styles are suggested for the table fill all available space in its containing element */
    }
    display: block;
    ${
        '' /* These styles are required for a horizontaly scrollable table overflow */
    }
    overflow: auto;

    .table {
        border-spacing: 0;
        border: 1px solid #9e9e9e;

        .thead {
            ${
                '' /* These styles are required for a scrollable body to align with the header properly */
            }
            overflow-y: auto;
            overflow-x: hidden;
        }

        .tbody {
            ${'' /* These styles are required for a scrollable table body */}
            overflow-y: scroll;
            overflow-x: hidden;
            min-height: 250px;
            height: 70vh;
        }

        .tr {
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
            border-bottom: 1px solid #9e9e9e;
        }

        .th,
        .td {
            margin: 0;
            padding: 0.6rem 0.5rem;
            border-right: 1px solid #9e9e9e;

            ${
                '' /* In this example we use an absolutely position resizer,
     so this is required. */
            }
            position: relative;

            :last-child {
                border-right: 0;
            }

            .resizer {
                right: 0;
                ${'' /*background: blue;*/}
                width: 10px;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 1;
                ${
                    '' /* prevents from scrolling while dragging on touch devices */
                }
                touch-action :none;

                &.isResizing {
                    background: red;
                }
            }
        }
    }
    .pagination-select {
        padding: 10px 0;
        float: left;
    }
`;

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = 'left') => [
    props,
    {
        style: {
            justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
        },
    },
];

const _drawPages = (pageCount, gotoPage, pageIndex) => {
    let active = 2;
    let items = [];
    const total = pageCount || 0;
    const currentPage = pageIndex || 0;
    const startPage = currentPage > 0 ? currentPage : 1;
    const maxDisplay = 5 + startPage;

    if (currentPage > 1) {
        items.push(
            <Pagination.Item
                key={'pg-first'}
                onClick={() => {
                    gotoPage(currentPage - 1);
                }}
            >
                &lt;
            </Pagination.Item>
        );
    }

    for (
        let number = startPage;
        number <= total && number < maxDisplay;
        number++
    ) {
        const isCurrentPage = number === currentPage + 1;
        items.push(
            <Pagination.Item
                key={`pg-number${number}`}
                active={isCurrentPage}
                onClick={() => {
                    if (!isCurrentPage) gotoPage(number - 1);
                }}
            >
                {number}
            </Pagination.Item>
        );
    }

    if (maxDisplay < total) {
        items.push(
            <Pagination.Item
                key={'pg-last'}
                onClick={() => {
                    gotoPage(maxDisplay);
                }}
            >
                &gt;
            </Pagination.Item>
        );
    }

    return items;
};

function Table({ columns, data, paginationType }) {
    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 200, // maxWidth is only used as a limit for resizing
        }),
        []
    );

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0 },
        },
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        usePagination,
        (hooks) => {}
    );

    return (
        <div>
            <div {...getTableProps()} className="table">
                <div className="headertitle">
                    {headerGroups.map((headerGroup) => (
                        <div
                            {...headerGroup.getHeaderGroupProps({
                                // style: { paddingRight: '15px' },
                            })}
                            className="tr"
                        >
                            {headerGroup.headers.map((column) => (
                                <div
                                    {...column.getHeaderProps(headerProps)}
                                    className="th"
                                >
                                    {column.render('Header')}
                                    {/* Use column.getResizerProps to hook up the events correctly */}
                                    {column.canResize && (
                                        <div
                                            {...column.getResizerProps()}
                                            className={`resizer ${
                                                column.isResizing
                                                    ? 'isResizing'
                                                    : ''
                                            }`}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="tbody">
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            {...cell.getCellProps(cellProps)}
                                            className="td"
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="row">
                <div className="col-2 pagination-select">
                    <select
                        value={pageSize}
                        className="form-control"
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                {paginationType === 'scroll' && (
                    <div className="col-10 float-right">
                        <Pagination>
                            {_drawPages(pageCount, gotoPage, pageIndex)}
                        </Pagination>
                    </div>
                )}
            </div>
        </div>
    );
}

function ReportTable(props) {
    const { rowdata, columndata, paginationtype } = props;
    const columns = React.useMemo(() => columndata);
    return (
        <Styles>
            {rowdata !== undefined && rowdata.length > 0 ? (
                <div>
                    <Table
                        columns={columns}
                        data={rowdata}
                        paginationType={paginationtype}
                    />
                </div>
            ) : (
                <div className="tableNoData">
                    Your search does not retrieve any data. Please search again
                    using another criteria.
                </div>
            )}
        </Styles>
    );
}

export default ReportTable;
