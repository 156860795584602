import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    crudReset,
    searchGeneral,
    createReturnItem,
    updateReturnItem    
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleSelect from "../../../components/SimpleSelect";
import { produce } from "immer";
import StatusOptionComp from "../../../components/StatusOptionComp";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import SimpleTable_CheckBox from "../../../components/SimpleTable_CheckBox";

class AddReturnItem extends React.PureComponent {
    statusReturn = [
        { name: "Select", id: "" },
        { name: "New", id: "New" },
        { name: "In Process", id: "In Process" },
        { name: "Completed", id: "Completed" },
        { name: "Refunded", id: "Refunded" },
        { name: "Rejected", id: "Rejected" },
        { name: "Exchanged", id: "Exchanged" }
      ];
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            // Formrelated
            productcode: "",
            orderid: "",
            expanded: "",
            selected1: [],
            selected2: [],
            selectedrow1: [],
            selectedrow2: [],
            items: [],
            cartupdated: "",
            isChecked: true,
            deliverytaskcode: "",
            shippingtaskcode: "",
            shipfrom_contactname: "",
            shipfrom_contactno: "",
            shipfrom_address: "",
            shipfrom_areacode: "",
            shipfrom_building: "",
            shipfrom_city: "",
            shipfrom_country: "",
            shipfrom_state: "",
            shipfrom_town: "",
            shipfrom_postalcode: "",
            shipfrom_remarks: "",
            shipto_contactname: "",
            shipto_contactno: "",
            shipto_address: "",
            shipto_areacode: "",
            shipto_building: "",
            shipto_city: "",
            shipto_country: "",
            shipto_state: "",
            shipto_town: "",
            shipto_postalcode: "",
            shipto_remarks: "",
            width: "",
            height: "",
            weight: "",
            isinsured: "",
            currency: "",
            amount: "",
            courier: "",
            trackingno: "",
            trackingurl: "",
            sms: "",
            notification: "",
            email: "",   
            status: "", 
            remark: "",    
            requestedon: new Date(),
            refundedon: new Date(),
            rejectedon: new Date(), 
            exchangedon: new Date(),
            completedon: new Date(),
            trxdate: "",
            trxtype: "",
            currency: "",
            trxamt: "",
            trxref: "",
            trxstatus: "",
            itemheaders: [
                {
                  dataField: "productcode",
                  text: "Product Name",
                  sort: true,
                  formatter: this.showProdDesc
                },
                {
                  dataField: "images",
                  text: "Images",
                  formatter: this.ImgFormatter,
                  sort: true
                },    
                {
                  dataField: "shippingqty",
                  text: "Requested Qty",
                  sort: true,
                  formatter: (cellContent, row) => {
                    return (
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          id="shippingqty"
                          defaultValue={
                            row.shippingqty !== null && row.shippingqty!=="" && row.hasOwnProperty("shippingqty") == true ? 
                            row.shippingqty = parseInt(row.shippingqty) : 0}
                          min="0"
                          style={{ borderRadius: "8px", width: "8em" }}
                          onChange={event => {
                            row["shippingqty"] = parseInt(event.target.value);
                          }}
                        />                    
                      </div>
                    );
                  }
                },
                {
                  dataField: "refundedamt",
                  text: "Refunded Amt",
                  sort: true,
                  formatter: (cellContent, row) => {
                    return (
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          id="refundamt"
                          defaultValue={
                            row.refundedamt !== null && row.refundedamt !=="" && row.hasOwnProperty("refundedamt") == true ?
                            parseInt(row.refundedamt) : 0}
                          min="0"
                          style={{ borderRadius: "8px", width: "8em" }}
                          onChange={event => {
                            row["refundedamt"] = parseInt(event.target.value);
                          }}
                        />                    
                      </div>
                    );
                  }
                },
                {
                  dataField: "quantity",
                  text: "Ordered Qty",
                  sort: true
                },  
                {
                  dataField: "unitprice",
                  text: "Unit Price",
                  sort: true,
                  formatter: this.showUnitPrice
                },
                {
                  dataField: "totalamount",
                  text: "Total Amt",
                  formatter: this.showAmount,
                  sort: true
                },
                {
                  dataField: "shippingstatus",
                  text: "Return Status",
                  sort: true
                }
              ],
            //errors
            items_error: "",
            shipqty_error: "",
            addressfrom_error: "",
            addressto_error: "",
            contactnamefrom_error: "",
            contactnameto_error: "",
            contactnofrom_error: "",
            contactnoto_error: "",
            countryfrom_error: "",
            countryto_error: "",
            cityfrom_error: "",
            cityto_error: "",
            postalcodefrom_error: "",
            postalcodeto_error: "",
            formError: false,
            formErrorMsg: ""
        };
    }

    showAmount = (cell, row, rowIndex, formatExtraData) => {
      if(row.currency !== null){
        return  row.currency+" "+row.totalamount;
      }
      else {
        return row.totalamount;
      }
    }
    
    showUnitPrice = (cell, row, rowIndex, formatExtraData) => {
      if(row.currency !== null){
        return (
          <div>
            {row.currency+" "+row.unitprice}
          </div>
        )
      }
      else {
        return row.unitprice;
      }
    }

   
    showStore = (cell, row, rowIndex, formatExtraData) => {
      const strid = row.storeid;
      const { dataByCountry } = this.props.queryData;
      if(dataByCountry.length > 0){
        const filterstr = dataByCountry.filter(e=>e.storeid===strid);
        if (filterstr.length > 0) {
          const strname = filterstr[0].title;
          return strname;
        }
        else {
          return ""
        }
      }
    }

    showProdDesc = (cell, row, rowIndex, formatExtraData) => {
      if(JSON.stringify("row") !== "{}"){
        return(
          <div>
            <div className="row">
              <div className="col noPadding noMargin">
                {row.productcode + "-" + row.description}
                <div style={{ color: "#6d6d6d", fontSize: "12px" }}>{"[" + row.itemsize + "/" + row.itemcolor + "]"}</div>
              </div>
            </div>
          </div> 
        )
      }
      else{
         return ""
      }
    }

    ImgFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (row.images !== "") {
       if(row.itemcolor !== "" || row.itemsize !== ""){
          return (
            <div>
              <img
                className="img-responsive w-75"
                src={`${localStorage.getItem("imagepath")}` + row["images"]}
                alt="Card image cap" width="10%" />
            </div>
          );
        }
      }
    }   


  getList = () => {
    const { selectedrow1 } = this.state;
    this.setState({
        items: selectedrow1
    })
  }

     componentDidMount = () => {
      // this.props.dispatch(
      //   searchGeneral(
      //     "/sales/v1/store/search/fields",
      //     {
      //       body: {
      //         groupid: localStorage.getItem("storegroup_access")
      //       }
      //     },
      //     "SEARCH_CITY_BY_COUNTRY_SUCCESS",
      //     "SEARCH_CITY_BY_COUNTRY_FAILURE"
      //   )
      // );
    }

 
    componentWillMount = () => {
      if(this.props.selectedrow){
        const rec = this.props.selectedrow.item;
        const isEdit = this.props.selectedrow.isEdit;

        if(isEdit === false){
          const { deliverydata } = this.props;
          const rec1 = this.props.deliverydata.deliveryinfo;
          this.setState(
            produce(draft=> {
              draft.deliverytaskcode = deliverydata.taskcode ? deliverydata.taskcode : "";
              draft.orderid = deliverydata.orderid ? deliverydata.orderid : "";
              draft.shipto_address = rec1 ? rec1.address : "";
              draft.shipto_contactname = rec1 ? rec1.contactname : "";
              draft.shipto_contactno = rec1 ? rec1.contactno : "";
              draft.shipto_state = rec1 ? rec1.state : "";
              draft.shipto_country = rec1 ? rec1.country : "";
              draft.shipto_city = rec1 ? rec1.city : "";
              draft.shipto_town = rec1 ? rec1.town : "";
              draft.shipto_building = rec1 ? rec1.building : "";
              draft.shipto_areacode = rec1 ? rec1.areacode: "";
              draft.shipto_postalcode = rec1 ? rec1.postalcode : "";
              draft.shipto_remarks = rec1 ? rec1.remark : "";
            })
          )
        }
        if (rec) {
          const {
              id,
              deliverytaskcode,
              orderid,
              shippingtaskcode,
              shipfrom,
              shipto,
              shippackage,
              customdeclaration,
              shippingby,
              notification,
              items,
              status,
              requestedon,
              refundedon,
              exchangedon,
              rejectedon,
              completedon,
              refundtrx,
              remark,
              shippingstatus
          } = rec;
          let checkrow1 = []
          if(items.length > 0 ){
            checkrow1 = items.map(e=>e.idx);
          }
          this.setState(
              produce(draft => {
                  draft.id = id ? id : "";
                  draft.isEdit = isEdit;
                  draft.orderid = rec.orderid ? rec.orderid : "";
                  draft.deliverytaskcode = this.props.deliverydata ? this.props.deliverydata.taskcode : "";
                  draft.shippingtaskcode = shippingtaskcode ? shippingtaskcode : "";
                  draft.shippingstatus = shippingstatus ? shippingstatus : "";
                  draft.shipfrom_address = shipfrom ? shipfrom.address : "";
                  draft.shipfrom_contactname = shipfrom ? shipfrom.contactname : "";
                  draft.shipfrom_contactno = shipfrom ? shipfrom.contactno : "";
                  draft.shipfrom_state = shipfrom ? shipfrom.state : "";
                  draft.shipfrom_country = shipfrom ? shipfrom.country : "";
                  draft.shipfrom_city = shipfrom ? shipfrom.city : "";
                  draft.shipfrom_town = shipfrom ? shipfrom.town : "";
                  draft.shipfrom_building = shipfrom ? shipfrom.building : "";
                  draft.shipfrom_areacode = shipfrom ? shipfrom.areacode: "";
                  draft.shipfrom_postalcode = shipfrom ? shipfrom.postalcode : "";
                  draft.shipfrom_remarks = shipfrom ? shipfrom.remark : ""

                  draft.shipto_address = shipto ? shipto.address : "";
                  draft.shipto_contactname = shipto ? shipto.contactname : "";
                  draft.shipto_contactno = shipto ? shipto.contactno : "";
                  draft.shipto_state = shipto ? shipto.state : "";
                  draft.shipto_country = shipto ? shipto.country : "";
                  draft.shipto_city = shipto ? shipto.city : "";
                  draft.shipto_town = shipto ? shipto.town : "";
                  draft.shipto_building = shipto ? shipto.building : "";
                  draft.shipto_areacode = shipto ? shipto.areacode: "";
                  draft.shipto_postalcode = shipto ? shipto.postalcode : "";
                  draft.shipto_remarks = shipto ? shipto.remark : "";

                  draft.weight = shippackage ? shippackage.weight : "";
                  draft.height = shippackage ? shippackage.height : "";
                  draft.width = shippackage ? shippackage.width : "";

                  draft.isinsured = customdeclaration ? customdeclaration.isinsured : "";
                  draft.curreny = customdeclaration ? customdeclaration.curreny : "";
                  draft.amount = customdeclaration ? customdeclaration.amount : "";

                  draft.courier  = shippingby ? shippingby.courier : "";
                  draft.trackingurl = shippingby ? shippingby.trackingurl : "";
                  draft.trackingno = shippingby ? shippingby.trackingno : "";

                  draft.notification = notification ? notification.notification : "";
                  draft.sms = notification ? notification.sms : "";
                  draft.email = notification ? notification.email : "";
                  draft.status = status ?  status : "";

                  draft.selected1 = items.length > 0 ? checkrow1: [];
                  draft.items = items.length > 0 ? items : [];

                  draft.trxtype = refundtrx !==null && refundtrx.hasOwnProperty("trxtype") ? refundtrx.trxtype : "";
                  draft.currency =refundtrx !==null && refundtrx.hasOwnProperty("currency")  ? refundtrx.currency : "";
                  draft.trxamt =refundtrx !==null && refundtrx.hasOwnProperty("trxamt")  ? refundtrx.trxamt : "";
                  draft.trxdate = refundtrx !==null && refundtrx.hasOwnProperty("trxdate")  ? this.toDate(refundtrx.trxdate) : "";
                  draft.trxref =refundtrx !==null && refundtrx.hasOwnProperty("trxref")  ? refundtrx.trxref : "";
                  draft.trxstatus = refundtrx !==null && refundtrx.hasOwnProperty("trxstatus")  ? refundtrx.trxstatus : "";

                  draft.remark = remark ? remark : "";

                  draft.requestedon = requestedon ? this.toDate(requestedon) : "";
                  draft.refundedon = refundedon ? this.toDate(refundedon) : "";
                  draft.rejectedon = rejectedon ? this.toDate(rejectedon) : "";
                  draft.exchangedon = exchangedon ? this.toDate(exchangedon) : "";
                  draft.completedon = completedon ? this.toDate(completedon) : "";
              })
          );
      }
      }
    }
    
    componentDidUpdate = () => {
      const { isChecked } = this.state;
      if(isChecked === true) {
        this.setState({
          cartupdated: "Y"
        })
      }
      else {
        this.setState({
          cartupdated: "N"
        })
      }
    }

    formatDate = inpDate => {
      if(inpDate !== "" && typeof inpDate !== "undefined") {
        return moment(inpDate).format("DD-MM-YYYY HH:mm");
      }
    };
   
    toDate = datestr  => {
      if(datestr !== ""){
        return moment(datestr, 'DD-MM-YYYY HH:mm');
      }
    }

    _renderErrorMessage = () => {
        const { isSuccess, errorMessage } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errorMessage && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    getCreatePayload = () => {
        const {
          cartupdated,
          deliverytaskcode,
          shippingtaskcode,
          shipfrom_address,
          shipfrom_contactname,
          shipfrom_contactno,
          shipfrom_building,
          shipfrom_areacode,
          shipfrom_town,
          shipfrom_country,
          shipfrom_city,
          shipfrom_state,
          shipfrom_postalcode,
          shipfrom_remarks,
          shipto_address,
          shipto_contactname,
          shipto_contactno,
          shipto_areacode,
          shipto_building,
          shipto_town,
          shipto_country,
          shipto_city,
          shipto_remarks,
          shipto_postalcode,
          shipto_state,
          width,
          height,
          weight,
          isinsured,
          curreny,
          amount,
          courier,
          trackingno,
          trackingurl,
          sms,
          email,
          notification,
          items,
          shippingstatus,
          status,
          completedon,
          requestedon,
          refundedon ,
          rejectedon,
          exchangedon,          
          orderid,
          remark    
        } = this.state;
        let body = {
            deliverytaskcode,
            shippingtaskcode,
            cartupdated,
            orderid,
            shipfrom: {
              contactname: shipfrom_contactname,
              contactno: shipfrom_contactno,
              address: shipfrom_address,
              state: shipfrom_state,
              country: shipfrom_country,
              city: shipfrom_city,
              town: shipfrom_town,
              building: shipfrom_building,
              postalcode: shipfrom_postalcode,
              areacode: shipfrom_areacode,
              remark: shipfrom_remarks
            },
            shipto: {
              contactname: shipto_contactname,
              contactno: shipto_contactno,
              address: shipto_address,
              state: shipto_state,
              country: shipto_country,
              city: shipto_city,
              town: shipto_town,
              building: shipto_building,
              postalcode: shipto_postalcode,
              areacode: shipto_areacode,
              remark: shipto_remarks
            },
            shippackage:{
              width,
              height,
              weight
            },
            customdeclaration: {
              isinsured,
              curreny,
              amount
            },
            shippingby: {
              courier,
              trackingno,
              trackingurl
            },
            notification: {
              email,
              sms,
              notification
            },
            items,
            status,
            remark,
            shippingstatus,
            completedon: this.formatDate(completedon),
            requestedon: this.formatDate(requestedon),
            refundedon: this.formatDate(refundedon),
            rejectedon: this.formatDate(rejectedon),
            exchangedon: this.formatDate(exchangedon)
        };
        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body
        };
        //console.log(payload);
        return payload;
    };

      _createReturnItemService = () => {
       if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateReturnItem(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createReturnItem(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Please clear all the errors to continue !!!"
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
          items_error: "",
          addressfrom_error: "",
          addressto_error: "",
          contactnamefrom_error: "",
          contactnameto_error: "",
          contactnofrom_error: "",
          contactnoto_error: "",
          countryfrom_error: "",
          countryto_error: "",
          cityfrom_error: "",
          cityto_error: "",
          postalcodefrom_error: "",
          postalcodeto_error: "",
          formError: false,
          formErrorMsg: ""
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const {
            shipfrom_contactname,
            shipfrom_contactno,
            shipfrom_address,
            shipfrom_country,
            shipfrom_city,
            shipfrom_postalcode,
            shipto_contactname,
            shipto_contactno,
            shipto_address,
            shipto_country,
            shipto_city,
            shipto_postalcode,
            selected1,
            selectedrow1,
            items
        } = this.state;
        let valid = true;
        if (items.length > 0){
          items.forEach(e=>{
            if(e.shippingqty === "" || isNaN(e.shippingqty) || e.shippingqty === 0){
              this.setState({
                items_error: "Please enter Requested Qty"
              });
              valid = false;
            }
            if(e.refundedamt === "" || isNaN(e.refundedamt)){
              this.setState({
                items_error: "Please enter Refund Amount"
              });
              valid = false;
            }
            if(e.shippingqty > e.quantity){
              this.setState({
                items_error: "Item Requested Qty exceeds the Ordered Qty"
              });
              valid = false;
            }
            if(e.refundedamt > e.totalamount){
              this.setState({
                items_error: "Item Refund Amount exceeds the Total Amount"
              });
              valid = false;
            }
          })
        }
        if (selected1.length == 0) {
          this.setState({
            items_error: "Please select items"
          });
          valid = false;
        }
        // if (shipfrom_contactname.trim() === "") {
        //   this.setState({
        //       contactnamefrom_error: "Please enter contact name"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_contactno.trim() === "") {
        //   this.setState({
        //       contactnofrom_error: "Please enter contact number"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_address.trim() === "") {
        //   this.setState({
        //       addressfrom_error: "Please enter address"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_country.trim() === "") {
        //   this.setState({
        //       countryfrom_error: "Please enter country"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_city.trim() === "") {
        //   this.setState({
        //       cityfrom_error: "Please enter city"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_postalcode.trim() === "") {
        //   this.setState({
        //       postalcodefrom_error: "Please enter postalcode"
        //   });
        //   valid = false;
        // }

        // if (shipto_contactname.trim() === "") {
        //   this.setState({
        //       contactnameto_error: "Please enter contact name"
        //   });
        //   valid = false;
        // }
        // if (shipto_contactno.trim() === "") {
        //   this.setState({
        //       contactnoto_error: "Please enter contact number"
        //   });
        //   valid = false;
        // }
        // if (shipto_address.trim() === "") {
        //   this.setState({
        //       addressto_error: "Please enter address"
        //   });
        //   valid = false;
        // }
        // if (shipto_country.trim() === "") {
        //   this.setState({
        //       countryto_error: "Please enter country"
        //   });
        //   valid = false;
        // }
        // if (shipto_city.trim() === "") {
        //   this.setState({
        //       cityto_error: "Please enter city"
        //   });
        //   valid = false;
        // }
        // if (shipto_postalcode.trim() === "") {
        //   this.setState({
        //       postalcodeto_error: "Please enter postalcode"
        //   });
        //   valid = false;
        // }
        return valid;
    };
    _isEditMode = () => {
        return this.state.isEdit;
    };
    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createReturnItemService();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createReturnItemService();    
                    
                        }}
                    >
                        Update
                    </button>
                )}
                 <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.hideReturnPage();
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };
    _renderRefundTrx = () =>{
      return (
        <React.Fragment>
          <div className="row">
             <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="trxtype" className="floatLeft">
                  Transaction Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="trxtype"
                  value={this.state.trxtype}
                  onChange={event =>
                    this.setState({ trxtype: event.target.value })
                  }
                /> 
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="currency" className="floatLeft">
                  Currency
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="currency"
                  value={this.state.currency}
                  onChange={event =>
                    this.setState({ currency: event.target.value })
                  }
                /> 
              </div>
          </div>
          <div className="row">
             <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="trxamt" className="floatLeft">
                  Transaction Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="trxamt"
                  value={this.state.trxamt}
                  onChange={event =>
                    this.setState({ trxamt: event.target.value })
                  }
                /> 
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="trxdate" className="floatLeft">
                  Transaction Date
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.trxdate}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  name="trxdate"
                  onChange={(value)=>this.handleShipDate("trxdate", value)}
                  value={this.state.trxdate}
                />
              </div>
          </div>
          <div className="row">
             <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="trxref" className="floatLeft">
                  Transaction Referance
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="trxref"
                  value={this.state.trxref}
                  onChange={event =>
                    this.setState({ trxref: event.target.value })
                  }
                /> 
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="trxstatus" className="floatLeft">
                  Transaction Status
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="trxstatus"
                  value={this.state.trxstatus}
                  onChange={event =>
                    this.setState({ trxstatus: event.target.value })
                  }
                /> 
              </div>
          </div>       
        </React.Fragment>
      )
    }

    _renderRemark = () => {
      return (
        <React.Fragment>
        <div className="row sub-title">Internal Remarks</div>
        <div className="row">
            <div className="form-group col-lg-12 col-sm-12">
              <label htmlFor="remark" className="floatLeft">
                Remarks
              </label>
              <textarea
                className="form-control"
                id="remark"
                value={this.state.remark}
                onChange={event => {
                  this.setState({ remark: event.target.value });
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }

    _renderShippingFrom = () => {   
        return (
          <React.Fragment>
            <div className="row sub-title">Shipping From</div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_contactname" className="floatLeft">
                  Contact Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_contactname"
                  value={this.state.shipfrom_contactname}
                  onChange={event => this.setState({ shipfrom_contactname: event.target.value })}
                /> 
                <div className="invalid-feedback">{this.state.contactnamefrom_error}</div>
              </div>
    
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_contactno" className="floatLeft">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_contactno"
                  value={this.state.shipfrom_contactno}
                  onChange={event => this.setState({ shipfrom_contactno: event.target.value })}
                />
                <div className="invalid-feedback">{this.state.contactnofrom_error}</div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_address" className="floatLeft">
                  Address
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_address"
                  value={this.state.shipfrom_address}
                  onChange={event =>
                    this.setState({ shipfrom_address: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.addressfrom_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_areacode" className="floatLeft">
                  Area Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_areacode"
                  value={this.state.shipfrom_areacode}
                  onChange={event => this.setState({ shipfrom_areacode: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_building" className="floatLeft">
                  Building
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_building"
                  value={this.state.shipfrom_building}
                  onChange={event =>
                    this.setState({ shipfrom_building: event.target.value })
                  }
                />
              </div>  
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_town" className="floatLeft">
                  Town
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_town"
                  value={this.state.shipfrom_town}
                  onChange={event =>
                    this.setState({ shipfrom_town: event.target.value })
                  }
                />
              </div>  
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_country" className="floatLeft">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_country"
                  value={this.state.shipfrom_country}
                  onChange={event =>
                    this.setState({ shipfrom_country: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.countryfrom_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_city" className="floatLeft">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_city"
                  value={this.state.shipfrom_city}
                  onChange={event =>
                    this.setState({ shipfrom_city: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.contactnamefrom_error}</div>
              </div>            
            </div>

           
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_state" className="floatLeft">
                  State
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_state"
                  value={this.state.shipfrom_state}
                  onChange={event =>
                    this.setState({ shipfrom_state: event.target.value })
                  }
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_postalcode" className="floatLeft">
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_postalcode"
                  value={this.state.shipfrom_postalcode}
                  onChange={event => this.setState({ shipfrom_postalcode: event.target.value.replace(/[^0-9]/g, '') })}
                />
                <div className="invalid-feedback">{this.state.postalcodefrom_error}</div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="shipfrom_remarks" className="floatLeft">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  id="shipfrom_remarks"
                  value={this.state.shipfrom_remarks}
                  onChange={event => {
                    this.setState({ shipfrom_remarks: event.target.value });
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        );
      };


    handleShipDate = (name,value) =>{
      if(value !== ""){
        var dateformat = moment(value, "DD-MM-YYYY HH:mm", true).isValid();
        if(dateformat === true){
          this.setState({
            [name]: value
          })
        }
        else{
          this.setState({
            [name]: ""
          })
        }
      }
  }


      _renderMainForm = () =>{
        const { ordershippedon, ordercompletedon, orderreceivedon } = this.state; 
        return (
          <React.Fragment>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="status" className="floatLeft">
                  Return Status
                </label>
                <StatusOptionComp
                  statusoption={this.statusReturn}
                  selectedOption={this.state.shippingstatus}
                  callbackChangeFilter={value => {
                    this.setState({ shippingstatus: value });
                  }}
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="deliverytaskcode" className="floatLeft">
                  Delivery Taskcode
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="deliverytaskcode"
                  value={this.state.deliverytaskcode}
                  readOnly={true}
                /> 
              </div>
            </div>
            <div className="row">
              {/* <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shippingtaskcode" className="floatLeft">
                  Shipping Taskcode
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shippingtaskcode"
                  value={this.state.shippingtaskcode}
                  readOnly={true}
                /> 
              </div>    */}
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="orderid" className="floatLeft">
                  Order Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="orderid"
                  value={this.state.orderid}
                  readOnly={true}
                /> 
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="requestedon" className="floatLeft">
                Requested Date
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.requestedon}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  name="requestedon"
                  inputProps={{disabled: true }}
                  //onChange={(value)=>this.handleShipDate("requestedon", value)}
                  value={this.state.requestedon}
                />
              </div>
            </div>
            <div className="row">   
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="exchangedon" className="floatLeft">
                Exchanged  Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.exchangedon}
                  name="exchangedon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"  
                  onChange={(value)=>this.handleShipDate("exchangedon", value)}
                  value={this.state.exchangedon}
                />
              </div>           
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="refundedon" className="floatLeft">
                Refunded Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.refundedon}
                  name="refundedon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  onChange={(value)=>this.handleShipDate("refundedon", value)}
                  value={this.state.refundedon}
                />
              </div>
            </div>
            <div className="row">            
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="rejectedon" className="floatLeft">
                Rejected Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.rejectedon}
                  name="rejectedon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"  
                  onChange={(value)=>this.handleShipDate("rejectedon", value)}
                  value={this.state.rejectedon}
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="completedon" className="floatLeft">
                Completion Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode()}
                  name="completedon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"  
                  onChange={(value)=>this.handleShipDate("completedon", value)}
                  value={this.state.completedon}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }


      _renderShippingTo = () => {  
        return (
          <React.Fragment>
            <div className="row sub-title">Shipping To</div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_contactname" className="floatLeft">
                  Contact Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_contactname"
                  value={this.state.shipto_contactname}
                  onChange={event => this.setState({ shipto_contactname: event.target.value })}
                />
                <div className="invalid-feedback">{this.state.contactnameto_error}</div>
              </div>
    
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_contactno" className="floatLeft">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_contactno"
                  value={this.state.shipto_contactno}
                  onChange={event => this.setState({ shipto_contactno: event.target.value })}
                />
                <div className="invalid-feedback">{this.state.contactnoto_error}</div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_address" className="floatLeft">
                  Address
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_address"
                  value={this.state.shipto_address}
                  onChange={event =>
                    this.setState({ shipto_address: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.addressto_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_areacode" className="floatLeft">
                  Area Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_areacode"
                  value={this.state.shipto_areacode}
                  onChange={event => this.setState({ shipto_areacode: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_building" className="floatLeft">
                  Building
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_building"
                  value={this.state.shipto_building}
                  onChange={event =>
                    this.setState({ shipto_building: event.target.value })
                  }
                />
              </div>  
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_town" className="floatLeft">
                  Town
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_town"
                  value={this.state.shipto_town}
                  onChange={event =>
                    this.setState({ shipto_town: event.target.value })
                  }
                />
              </div>  
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_country" className="floatLeft">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_country"
                  value={this.state.shipto_country}
                  onChange={event =>
                    this.setState({ shipto_country: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.countryto_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_city" className="floatLeft">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_city"
                  value={this.state.shipto_city}
                  onChange={event =>
                    this.setState({ shipto_city: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.cityto_error}</div>
              </div>            
            </div>

           
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_state" className="floatLeft">
                  State
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_state"
                  value={this.state.shipto_state}
                  onChange={event =>
                    this.setState({ shipto_state: event.target.value })
                  }
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_postalcode" className="floatLeft">
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_postalcode"
                  value={this.state.shipto_postalcode}
                  onChange={event => this.setState({ shipto_postalcode: event.target.value.replace(/[^0-9]/g, '') })}
                />
              </div>
              <div className="invalid-feedback">{this.state.postalcodeto_error}</div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="shipto_remarks" className="floatLeft">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  id="shipto_remarks"
                  value={this.state.shipto_remarks}
                  onChange={event => {
                    this.setState({ shipto_remarks: event.target.value });
                  }}
                />
              </div>
            </div>
            </React.Fragment>
        );
    }; 
    
   
    setNotify = value => {
        this.setState({
          notifycustomer: value
        });
      };

  
    _renderShipPackage = () => {
        return (
          <React.Fragment>
            <div className="row sub-title">Ship Package</div>
           
            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="weight" className="floatLeft">
                  Weight
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="weight"
                  value={this.state.weight}
                  onChange={event =>
                    this.setState({ weight: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="width" className="floatLeft">
                  Width
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="width"
                  value={this.state.width}
                  onChange={event =>
                    this.setState({ width: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="height" className="floatLeft">
                  Height
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="height"
                  value={this.state.height}
                  onChange={event =>
                    this.setState({ height: event.target.value })
                  }
                />
              </div>
            </div>
        
          </React.Fragment>
        );
      };

      _renderCustomerForm = () =>{
          return (
              <React.Fragment>
                  <div className="row sub-title">Customer Declaration</div>
                  <div className="row">
                      <div className="form-group col-sm-12 col-lg-12">
                          <label htmlFor="insured" className="floatLeft">
                              Insured
                          </label>
                          <SimpleSelect
                              objArray={{ Y: "Yes", N: "No" }}
                              callbackChangeFilter={value => this.setState({ isinsured: value })}
                              selectedOption={this.state.isinsured}
                              id="insured"
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="form-group col-sm-12 col-lg-12">
                          <label htmlFor="currency" className="floatLeft">
                            Currency 
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="currency"
                              value={this.state.curreny}
                              onChange={event =>
                                  this.setState({ curreny: event.target.value })
                              }
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="form-group col-sm-12 col-lg-12">
                          <label htmlFor="amount" className="floatLeft">
                            Amount  
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="amount"
                              value={this.state.amount}
                              onChange={event =>
                                  this.setState({ amount: event.target.value })
                              }
                          />
                      </div>
                  </div>
              </React.Fragment>
          )
      }

      _renderNotification = () =>{
        return (
            <React.Fragment>
                <div className="row sub-title">Customer Notification</div>
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="sms" className="floatLeft">
                      SMS
                            </label>
                    <SimpleSelect
                      objArray={{ Y: "Yes", N: "No" }}
                      callbackChangeFilter={value => this.setState({ sms: value })}
                      selectedOption={this.state.sms}
                      id="sms"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="email" className="floatLeft">
                      Email
                    </label>
                    <SimpleSelect
                      objArray={{ Y: "Yes", N: "No" }}
                      callbackChangeFilter={value => this.setState({ email: value })}
                      selectedOption={this.state.email}
                      id="email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="notification" className="floatLeft">
                      Notification
                   </label>
                    <SimpleSelect
                      objArray={{ Y: "Yes", N: "No" }}
                      callbackChangeFilter={value => this.setState({ notification: value })}
                      selectedOption={this.state.notification}
                      id="notification"
                    />
                  </div>
                </div>
            </React.Fragment>
        )
    }

      _renderShippingTrackForm = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Shipping Tracker</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="courier" className="floatLeft">
                            Logistic Company
                       </label>
                        <input
                            type="text"
                            className="form-control"
                            id="courier"
                            value={this.state.courier}
                            onChange={event =>
                                this.setState({ courier: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="trackingurl" className="floatLeft">
                            Tracking URL
                       </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trackingurl"
                            value={this.state.trackingurl}
                            onChange={event =>
                                this.setState({ trackingurl: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="trackingno" className="floatLeft">
                            Tracking No
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trackingno"
                            value={this.state.trackingno}
                            onChange={event =>
                                this.setState({ trackingno: event.target.value })
                            }
                        />
                    </div>
                </div>              
            </React.Fragment>
        )
      }

      handleOnSelect1 = (row, isSelect) => {
         console.log("select1")
        if (isSelect) {
          this.setState(()=>({
            selected1: [...this.state.selected1, row.idx],
            selectedrow1: [...this.state.selectedrow1, row]
          }),()=>{
            this.getList();
          });
        } else {
          this.setState(() => ({
            selected1: this.state.selected1.filter(x => x !== row.idx),
            selectedrow1: this.state.selectedrow1.filter(x => x.idx !== row.idx)
          }));
        }
      };

      handleOnSelectAll1 = (isSelect, rows) => {
        const ids = rows.map(r => r.idx);
        if (isSelect) {
          this.setState(() => ({
            selected1: ids,
            selectedrow1: rows
          }));
        } else {
          this.setState(() => ({
            selected1: [],
            selectedrow1: []
          }));
        }
      }; 
      
      toggleChange = () => {
        const { cartupdated, isChecked } = this.state;
        this.setState({
          isChecked: !this.state.isChecked
        })
      }
    
      _renderItemDetails = () => {
       const checkedrow1 = [...new Set(this.state.selected1)];
          return (
            <React.Fragment>
              <div>
                <div className="row sub-title">Item List</div>
                  <SimpleTable_CheckBox
                    columns={this.state.itemheaders}
                    rows={this._isEditMode() ?  this.props.selectedrow.item.items : this.props.deliverydata.items}
                    handleOnSelect={this.handleOnSelect1}
                    handleOnSelectAll={this.handleOnSelectAll1}
                    selected={checkedrow1}
                  />
                <div className="invalid-feedback">{this.state.items_error}</div>
              </div>
              <div className="row">
                <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="cartupdate" className="floatLeft">
                      <input 
                        type="checkbox"
                        checked={this.state.isChecked}
                        onChange={this.toggleChange}
                      />
                     &nbsp; Update Customer Shopping Cart   
                  </label>
                </div>
              </div>
            </React.Fragment>
          )
      }

   
       
    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div>
                <div className="row rowBottom">
                    <div className="col-md-12 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center">
                            {this._renderItemDetails()}
                        </form> 
                    </div>
                </div>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">   
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderMainForm()}
                        </form>      
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderRefundTrx()}
                        </form>                    
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderShippingFrom()}
                        </form>  
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderShippingTo()}
                        </form>                   
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderShippingTrackForm()}
                        </form> 
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderShipPackage()}
                        </form>
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderCustomerForm()}
                        </form>                       
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderNotification()}
                        </form> 
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderRemark()}
                        </form>                                              
                    </div>
                </div>
                </div>
            </React.Fragment>
        );
    };
    hideModal = e => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.hideReturnPage();
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? "Edit Return Item Details"
                                    : "Add Return Item Details"}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                      <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to={this.props.shipreturnpage === true ? 
                          "/ui/order-fulfillment" : "/ui/orders-fulfillment"}
                        children={
                          <div style={{ padding: "2em", color: "green" }}>
                            {this._isEditMode()
                              ? "Return Item Details updated Successfully."
                              : "Return Item Details saved Successfully."}
                          </div>
                        }
                      />          
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddReturnItem);
