import React, { Component } from "react";
import { renderFilter } from "../helpers/Util";

const operatorsBeforeFormat = [
  { name: "Equal", id: "eq" },
  { name: "Like", id: "like" }
];
const statusValues = [
  { name: "Select", id: "" },
  { name: "New", id: "New" },
  { name: "Active", id: "Active" },
  { name: "Inactive", id: "Inactive" },
  { name: "Updated", id: "Updated" },
  { name: "Deleted", id: "Deleted" }
];
const statusInv = [
  { name: "Select", id: "" },
  { name: "Issued", id: "Issued" },
  { name: "Used", id: "Used" },
  { name: "Void", id: "Void" }
];
const statusCoupon = [
  { name: "Select", id: "" },
  { name: "New", id: "New" },
  { name: "Active", id: "Active" },
  { name: "Save", id: "Save" },
  { name: "Update", id: "Update" },
  { name: "Expired", id: "Expired" }
]
const statusTask = [
    { name: "Select", id: "" },
    { name: "Confirmed", id: "Confirmed" },
    { name: "Shipping", id: "Shipping" },
    { name: "Shipped", id: "Shipped" },
    { name: "Delivered", id: "Delivered" },
    { name: "Completed", id: "Completed" },
    { name: "Cancel", id: "Cancel" }
];

const statusShip = [
  { name: "Select", id: "" },
  { name: "Shipping", id: "Shipping" },
  { name: "Shipped", id: "Shipped" },
  { name: "Received", id: "Received" },
  { name: "Delivered", id: "Delivered" },
  { name: "Completed", id: "Completed" },
  { name: "Cancel", id: "Cancel" }
]

const statusReturn = [
  { name: "Select", id: "" },
  { name: "New", id: "New" },
  { name: "In Process", id: "In Process" },
  { name: "Completed", id: "Completed" },
  { name: "Refunded", id: "Refunded" },
  { name: "Rejected", id: "Rejected" },
  { name: "Exchanged", id: "Exchanged" }
]

class SimpleSearcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.props.search_value || "",
      operator: this.props.search_condi || "",
      option: this.props.search_field || "",
      optioninv: this.props.couponinv || "",
      optioncoupon: this.props.coupon_field || "",
      optiontask: this.props.task_field || "",
      optionship: this.props.ship_field || "",
      optionreturn: this.props.shipreturn_field || ""
    };
  }

  componentWillReceiveProps = nextProps => {
    const { search_field, search_condi, search_value, searchValues } = nextProps;
    const { query, option, operator} = this.state;
    this.setState({
      query: search_value,
      operator: search_condi,
      option: search_field
    });
  };

  blockEnterKeySearch = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  changeInput = event => {
    if (event) event.preventDefault();
    this.setState({ query: event.target.value }, () => this.searchTable());
  };
  searchTable = () => {
    const { option, operator, query } = this.state;
    this.props.setAndSearch(option, operator, query);
  };
  searchTableOnKeyDown = event => {
    var code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      event.preventDefault();
      const { option, operator, query } = this.state;
      this.props.setAndSearch(option, operator, query);
    }
  };
  changeInputField = event => {
    if (event) event.preventDefault();
    this.setState({ query: event.target.value });
  };
  render() {
    const { filterOptions } = this.props;
    const { query, operator, option, optioninv, optioncoupon, optiontask, optionship, optionreturn } = this.state;
    const operators = renderFilter(operatorsBeforeFormat);
    const filters = renderFilter(filterOptions);
    let searchValues = [];
    // status option for coupon inventory
    if (option === "status" && optioninv === true) {
      searchValues = statusInv;
    }
    // status option for coupons
    else if (option === "status" && optioninv === "" && optioncoupon === true){
      searchValues = statusCoupon
    }
    // status option for task
    else if (option === "status" && optioninv === "" && optioncoupon === "" && optiontask === true){
      searchValues = statusTask
    }
    // status option for shipping
    else if (option === "shippingstatus" && optioninv === "" && optioncoupon === "" && optiontask === "" && optionship === true){
      searchValues = statusShip
    }
     // status option for shipping return
     else if (option === "shippingstatus" && optioninv === "" && optioncoupon === "" && optiontask === "" && optionship === "" && optionreturn === true){
      searchValues = statusReturn
    }
    // default status option
    else if (option === "status" && optioninv === "" && optioncoupon === ""){
      searchValues = statusValues;
    }
    else {
      searchValues = this.props.searchValues;
    }
    return (
      <React.Fragment>
        <div className="input-group noPadding noMargin">
          <select
            name="example"
            className="simpleSearchSelect"
            value={this.state.option}
            onChange={event => {
              let queryParam = event.target.value === "id" ? 0 : "";
              if(event.target.value==="ptype" && queryParam===""){
                this.setState({
                  option: event.target.value,
                  query: this.props.searchValues[0].name
                },()=> this.searchTable())
              }
              else {
              this.setState(
                {
                  option: event.target.value,
                  query: queryParam
                },
                () => this.searchTable(event)
              );
              }
            }}
          >
            {filters.length !== 0 &&
              filters.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>
          <select
            name="example"
            className="simpleSearchSelect"
            value={this.state.operator}
            onChange={event => {
              this.setState({ operator: event.target.value });
            }}
            style={{ marginLeft: "2px" }}
          >
            {operators.length !== 0 &&
              operators.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>

          {option !== "shippingstatus" && option !== "status" && option !== "ptype" ? (
            <input
              type="text"
              value={this.state.query}
              className="form-control navbarSearcher1 respSearcher1"
              placeholder="Enter value"
              onChange={event => this.changeInputField(event)}
              onBlur={event => this.searchTable(event)}
              onKeyDown={event => this.searchTableOnKeyDown(event)}
            />
          ) : (
            <select
              name="example"
              className="form-control navbarSearcher2 respSearcher1"
              value={this.state.query}
              onChange={event => this.changeInput(event)}
            >
              {searchValues.length !== 0 &&
                searchValues.map((option, index) => {
                  const { name, id } = option;
                  return (
                    <option key={index} value={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
          )}
        </div>
      </React.Fragment>
    );
  }
}
SimpleSearcher.propTypes = {};

export default SimpleSearcher;
