import React, { Component } from "react";
import StatusOptionComp from "./StatusOptionComp";

class ReceiptLineConfig extends Component {

  alignments = [
    { name: "Left", id: "L" },
    { name: "Center", id: "C" },
    { name: "Right", id: "R" },
  ]

  constructor(props) {
    super(props);
    this.state = {};
  }

  getAlignment = (id) => {
    return this.alignments.filter(e => {
      return e.id === id
    })[0].name
  }

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );

  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;

    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderRowData = (column, row, col, index, rowId, rowleng) => {
    if (column.addRow) {
      return (

        <td key={`tableview-td-${rowId}-${index}`}>
          {col === 'alignment' ? (
            this.props.isViewMode ? 
            <input
              type="text"
              // defaultValue={this.getAlignment(row[column.dataFieldId])}
              defaultValue={this.getAlignment(row[column.dataFieldId])}
              placeholder={
                column.dataFieldId.charAt(0).toUpperCase() +
                column.dataFieldId.slice(1)
              }
              disabled={true}
            />
            :
            <StatusOptionComp
            statusoption={this.alignments}
            selectedOption={row[column.dataFieldId]}
            callbackChangeFilter={value => {
              this.props.handleAlignmentChange(value, column.dataFieldId, row)
            }}
        />
          )
          :
          <input
            type="text"
            defaultValue={row[column.dataFieldId]}
            placeholder={
              column.dataFieldId.charAt(0).toUpperCase() +
              column.dataFieldId.slice(1)
            }
            disabled={(column['disabled']||false) && (row[`disabled`]||false)}
            onChange={event =>
              this.props.handleInputChange(event, column.dataFieldId, row)
            }
         
          />
        }
        </td>
      );
    }
    if (this.props.isViewMode) { return }
    if (col === "AD") {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          <img
            className="addBtn1"
            onClick={this.props.addRow}
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
          />

          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row)}
            className="addBtn2"
          />
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };

  render() {
    return (
      <React.Fragment>
        <div className="dynamicTable">
          <table style={{width: "100%"}}>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default ReceiptLineConfig;
