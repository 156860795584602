import React, { PureComponent } from "react";
import { connect } from "react-redux";

class SimpleDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        objArray: this.props.listoptions || "",
        selectedOption: this.props.selectedOption 
    };
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({ selectedOption: nextProps.selectedOption });
  }
  dropDownChange = event => {
    this.setState({ selectedOption: event.target.value });
    this.props.callbackChangeFilter(event.target.value);
  };

  render() {
    const { objArray } = this.state;
    return (
      <div className="filterClass form-filter">
        <select
          id="statusComp"
          className="form-control"
          // disabled={disabled ? true : false}
          value={this.state.selectedOption}
          onChange={this.dropDownChange}
        >
                {objArray != undefined ? (
                    objArray.map((t, i) => (
                        <option key={i} value={t.id}>
                            {t.name}
                        </option>
                    ))
                ) : (
                        <option>No option</option>
                    )}
        </select>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(SimpleDropdown);
