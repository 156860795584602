export const headers = [
  {
    dataField: "tenant",
    label: "Store"
  },
  {
    dataField: "jan",
    label: "Jan"
  },
  {
    dataField: "feb",
    label: "Feb"
  },
  {
    dataField: "mar",
    label: "Mar"
  },
  {
    dataField: "apr",
    label: "Apr"
  },
  {
    dataField: "may",
    label: "May"
  },
  {
    dataField: "jun",
    label: "Jun"
  },
  {
    dataField: "jul",
    label: "July"
  },
  {
    dataField: "aug",
    label: "Aug"
  },
  {
    dataField: "sep",
    label: "Sep"
  },
  {
    dataField: "oct",
    label: "Oct"
  },
  {
    dataField: "nov",
    label: "Nov"
  },
  {
    dataField: "dec",
    label: "Dec"
  }
];

export const rows = [
  {
    tenant: "Bedok Store",
    jan: "2",
    feb: "1",
    mar: "33",
    apr: "2",
    may: "33",
    jun: "2",
    jul: "3",
    aug: "20",
    sep: "3",
    oct: "3",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Little India Store",
    jan: "23",
    feb: "1",
    mar: "33",
    apr: "3",
    may: "33",
    jun: "2",
    jul: "34",
    aug: "20",
    sep: "3",
    oct: "3",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Jurong Store",
    jan: "2",
    feb: "1",
    mar: "33",
    apr: "2",
    may: "33",
    jun: "2",
    jul: "3",
    aug: "20",
    sep: "3",
    oct: "3",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Changi Store",
    jan: "2",
    feb: "1",
    mar: "33",
    apr: "2",
    may: "33",
    jun: "2",
    jul: "3",
    aug: "20",
    sep: "3",
    oct: "3",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Outram Store",
    jan: "2",
    feb: "1",
    mar: "33",
    apr: "2",
    may: "33",
    jun: "2",
    jul: "3",
    aug: "20",
    sep: "3",
    oct: "3",
    nov: "-",
    dec: "-"
  }
];
