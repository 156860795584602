import React from "react";
import DatePicker from "react-date-picker";
import {
  crudReset,
  resetAction,
  storeTerminalsSearchByFields,
  exportEjt
} from '../../actions/actions';
import { formatDate, permissions } from '../../helpers/Util'

import "react-accessible-accordion/dist/fancy-example.css";
import { connect } from "react-redux";
import ConfirmAlert from '../../components/ConfirmAlert';
import StoreSearchSingle from '../system/Users/StoreSearchSingle';
import { SEARCH_STORE_TERMINALS_FAILURE_SILENT, SEARCH_STORE_TERMINALS_SUCCESS_SILENT } from "../../constants/constants";

class EJ extends React.PureComponent {

  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.group = "DE_REPORTS_DOWNLOAD_EJ"
    this.module = "REPORTS"
    this.allPermissions = []

    this.requestBody = {}
    this.currPage = 1
    this.pageSize = 10

    this.state = {
      storeid: '',
      trxno: '',
      terminalid: '',
      storedate: new Date(),
      terminals: [{name: "Select", id: ''}],
      params: {},
      ejtexport: {},

      s_storelabel: "",
      storedateerror: '',
      storeiderror: '',
      terminaliderror: '',
      trxnoerror: ''
    };
  }

  downloadEJ = (event) => {

    if (event) event.preventDefault();

    const {
      storeid,
      trxno,
      terminalid,
      storedate
    } = this.state


    if (storeid === '' || storeid === null) {
      this.setState({storeiderror: 'Please select the store'})
      return
    }

    if (storedate === '' || storedate === null) {
      this.setState({storedateerror: 'Please select the date'})
      return
    }

    // if (terminalid === null || terminalid === '') {
    //   this.setState({terminaliderror: 'Please select the terminal'})
    //   return
    // }

    if (trxno !== '') { //then terminalid is mandatory
      if (terminalid === null || terminalid === '') {
        this.setState({terminaliderror: 'Please select the terminal'})
        return
      }
    } else {
      this._downloadEJ()
      return
    }

    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
        var trxDate = formatDate(storedate).replaceAll("-","").substring(0,8)
        var url =  localStorage.getItem('imagepath') + "/media/ejt/" + trxDate + "/" + storeid + "/" + terminalid 
        if (trxno && trxno !== '') {
          url += "/" + trxno + ".pdf";
        } else {
          url = localStorage.getItem('imagepath') + "/cms/v1/media/folder/download?folderpath=/media/ejt/" + trxDate + "/" + storeid + "/" + terminalid 
        }

        console.log('url::', url)
        link.setAttribute("href", url);

        var exportedFilenmae = storeid + '-' + terminalid + '-' + trxno + '.pdf' || 'export.pdf';
        link.setAttribute("download", exportedFilenmae);
        link.target = 'blank'
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  }

  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage,
      storeid,
      storedate,
    } = this.state;

    const queryParams = []

    if (fetchType === "ONLOAD") {
      queryParams.push(`storeid=${storeid}`)
      queryParams.push(`storedate=${formatDate(storedate)}`)
      queryParams.push(`pagesize=${this.pageSize}`)
      queryParams.push(`pageno=${this.currPage}`)

      return queryParams.join('&');
    }

    this.requestBody['pagesize'] = pageSize
    this.requestBody['pageno'] = currPage
    
    for (const [key, value] of Object.entries(this.requestBody)) {
      queryParams.push(`${key}=${value}`)
    }

    console.log('params::', queryParams)
    return queryParams.join('&');
  };

  callFetch = fetchType => {
      
  };

  callQueryApiForFetch = (triggerPoint) => {
    switch (triggerPoint) {
      case "ONLOAD":
        // this.setState(produce(this.state, draft => {
        //   draft.currPage = 1;
        // }), () => {
          this.callFetch(triggerPoint);
        // });
        break;
      case "PAGECLICK":
        this.callFetch();
        break;
      default:
    }
  };

  componentWillMount() {

    this.setState({
      storeid: localStorage.getItem("storeid"),
      s_storelabel: localStorage.getItem("storelabel")
    }, () => {
      this.fetchStoreTerminals()
    });
    
    this.allPermissions = permissions(this.module, this.group)
  }

  fetchStoreTerminals = () => {
    const {
      storeid
    } = this.state

    this.props.queryData.storeTerminals = []
    if (storeid && storeid !== '') {
      const queryParams = []
      queryParams.push(`storeid=${storeid}`)
      queryParams.push(`devicegroup=terminal`)
      this.props.dispatch(
        storeTerminalsSearchByFields(queryParams.join('&'),
        SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
        SEARCH_STORE_TERMINALS_FAILURE_SILENT
        ))
    }
  }

  componentDidUpdate = (prevProps) => {
    const { storeTerminals, ejtexport } = this.props.queryData;
    const { storeTerminals: prevstoreTerminals, ejtexport: prevejtexport } = prevProps.queryData;

      if (storeTerminals !== prevstoreTerminals) {
        if (storeTerminals && storeTerminals.length > 0) {
          var terminals = [
            {name: "Select", id: ''}
          ]
          var returnedTerminals = storeTerminals.map(e => 
            ({name : e.terminalid, id : e.terminalid})
          )
          this.setState( { terminals: terminals.concat(returnedTerminals) })
        } else {
          this.setState( { terminals: [{name: "Select", id: ''}], })
        }
      }

      if (ejtexport !== prevejtexport) {
        if (ejtexport && ejtexport.file && ejtexport.file !== '') {
          this.setState( { ejtexport: ejtexport })
        }
      }
  }

  componentWillReceiveProps = () => {

    const { storeTerminals, ejtexport } = this.props.queryData;

    if (storeTerminals && storeTerminals.length > 0) {
      var terminals = [
        {name: "Select", id: ''}
      ]
      var returnedTerminals = storeTerminals.map(e => 
        ({name : e.terminalid, id : e.terminalid})
      )
      this.setState( { terminals: terminals.concat(returnedTerminals) })
    }

    if (ejtexport && ejtexport.file && ejtexport.file !== '') {
      this.setState( { ejtexport: ejtexport })
    }
  }

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, s_storelabel: p2, search_value: p1 },
      ()=>{
      localStorage.setItem("storeid", p1);
      localStorage.setItem("storelabel", p2);

      this.fetchStoreTerminals()
    });
    this.setState({storeiderror: ''})
  };

  hideErrorAlertModal = (e) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());

    this.fetchStoreTerminals()
  };

  _downloadEJ = () => {

    const {
      storeid,
      terminalid,
      storedate
    } = this.state

    var trxDate = formatDate(storedate).replaceAll("-","").substring(0,8)
    var url = "/media/ejt/" + trxDate + "/" + storeid
    if (terminalid !== '') {
      url += "/" + terminalid
    }
    console.log('url::', url)

    const queryParams = []
    queryParams.push(`folderpath=${url}`)
    //query
    this.props.dispatch(exportEjt(queryParams.join('&')))
  }

  _renderSearchCiteria = () => {
    console.log('_renderSearchCriteria.....')

    const {
      propConfigs,
    } = this.props.queryData;

    if (propConfigs && propConfigs.length > 0 && propConfigs[0].additionalfields ) {
      this.setState( {trxtypes: propConfigs[0].additionalfields}) //propConfigs[0].additionalfields.values().join(',')
    }

    return (
      <React.Fragment>
        <div className="row" />
        <div className="row">
          <div className="form-group col-lg-3 col-sm-12 ">
            <label htmlFor="storeid" className="floatLeft required">
              Select Store
            </label>
            <StoreSearchSingle value={this.state.s_storelabel} onSelectStore={this._onSelectStore}/>
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>

          <div className="form-group col-lg-3 col-sm-12">
            <label htmlFor="storeDate" className="floatLeft required">
              Store Date
            </label>
            <DatePicker
              onChange={(value)=>this.setState({storedate: value}, () => {
                
              })
              }
              value={this.state.storedate}
              maxDate={new Date()}
            />
            <div className="invalid-feedback">{this.state.storedateerror}</div>
          </div>

          <div className="form-group col-lg-3 col-sm-4">
            <label htmlFor="terminalid" className="floatLeft">
              Terminal Id
            </label>
            <div className="row">
              <select
                name="terminalid"
                className="simpleSearchSelect2"
                value={this.state.terminalid}
                onChange={event =>
                  this.setState({ terminalid: event.target.value }, () => {
                    this.setState({ terminaliderror: ''})
                  })
                }
              >
                {this.state.terminals.length !== 0 &&
                  this.state.terminals.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
              <div className="invalid-feedback">{this.state.terminaliderror}</div>
            </div>
          </div>

          <div className="form-group col-lg-3 col-sm-12">
            <label htmlFor="trxno" className="floatLeft">
              Transaction No
            </label>
            <input
              type="text"
              className="form-control"
              id="trxno"
              value={this.state.trxno}
              onChange={event => {
                this.setState({ trxno: event.target.value });
              }}
            />
          </div>

          <div className="col-lg-2 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
            <button
              type="submit"
              onClick={(e) => this.downloadEJ(e)}
              className="btn btn-themes btn-rounded"
            >
              Download
            </button>
          </div>
        </div>

        <div className="form-group col-lg-7 col-sm-3 noPadding" />
        
      </React.Fragment>
    );
  }

  _onSelectStore = (selectedStores) => {
    console.log('selected stores from storesearch:;', selectedStores, this.state.storeid)
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  _renderMainButton = () => {

    return (
        <React.Fragment>
            <button
                type="submit"
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                onClick={() => {
                  this.props.queryData.errormsg = ''
                  this.props.queryData.servererror = ''
                  this.props.crudData.errormsg = ''
                    this.props._hideExport();
                }}
            >
                Back
            </button>
        </React.Fragment>
    );
};

  render() {
    const {
      servererror: serverError,
      errormsg: queryError,
    } = this.props.queryData;

    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }

    console.log('ejtexport::', this.state.ejtexport)

    if (this.state.ejtexport && this.state.ejtexport.file && this.state.ejtexport.file !== '') {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url =  localStorage.getItem('imagepath') + this.state.ejtexport.file 

          console.log('url::', url)
          link.setAttribute("href", url);

          //var trxDate = formatDate(this.state.storedate).replaceAll("-","").substring(0,8)
          //var exportedFilenmae = this.state.storeid + '-' + trxDate + '.zip' || 'export.zip';
          link.setAttribute("download", '');
          link.target = 'blank'
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }

      //reset
      this.props.queryData.ejtexport = {}
    }

    
    
    return (
    
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Download EJ</h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv" onSubmit={this._validateAndSearch}>
                <input type="submit" className="d-none"></input>
                <div className="row">
                  {this._renderSearchCiteria()}
                </div>
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />

        <div className="row btn-container form-button">
          <div className="col-sm-12 col-lg-12">
              {this._renderMainButton()}
          </div>
        </div>

        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(EJ);
