import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import { createCoupon, updateCoupon } from "../../../actions/actions";
// Local Imports
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusOptionComp from "../../../components/StatusOptionComp";
import { crudReset } from "../../../actions/actions";
import { produce } from "immer";
import SimpleImageInput from "./../../../components/SimpleImageInput";
import DynamicTable from "../../../components/DynamicTable";
import { formatDate, objtoarr } from "../../../helpers/Util";
import SimpleDropdown from "../../../components/SimpleDropdown";

class AddCoupon extends React.PureComponent {

  coupongroup = [
    { id: "", name:  "Select" },
    { id: "Reward", name: "Reward" },
    { id: "Discount", name: "Discount" },
    { id: "Payment", name: "Payment" },
  ]
  
  statusCoupon = [
    { name: "Select", id: "" },
    { name: "New", id: "New" },
    { name: "Active", id: "Active" },
    { name: "Save", id: "Save" },
    { name: "Update", id: "Update" },
    { name: "Expired", id: "Expired" }
  ]

  companyId = localStorage.getItem("company_id")

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: false,
      isView: false,

      // Formrelated
      // scouponcode: "",
      couponcode: "",
      title: "",
      description: "",
      coupongroup: "",
      discountbyvalue: "",
      discountby: "",
      status: "Active",
      validfrom: "",
      validtill: "",
      qtymax: "",
      qtybal: "",
      images: [],
      couponimg: "",
      additionalFields: [
        { field: "", value: "", id: 0 }
      ],
      // Errors
      // scouponcodeerror: "",
      descriptionerror: "",
      discountbyvalueerror: "",
      titleerror: "",
      coupongrouperror: "",
      amountError: "",
      validfromerror: "",
      validtillerror: "",
      maxqtyerror: ""
    };
  }

  handleAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.additionalFields.length;
    let lastele = this.state.additionalFields[rowLength - 1];
    const item = {
      field: "",
      value: "",
      id: lastele.id + 1
    };
    this.setState(
      {
        additionalFields: [...this.state.additionalFields, item]
      },
      () => {
        console.log("Rows After Add", this.state.additionalFields);
      }
    );
  };

  handleInputChange = (event, dataField, row) => {
    let obj = {
      id: row.id,
      [dataField]:
        dataField === "validation" ? [event.target.value] : event.target.value
    };
    this.setState(
      produce(draft => {
        if (draft.additionalFields.length === 0) {
          draft.additionalFields.push(obj);
        } else {
          let found = false;
          draft.additionalFields.forEach(command => {
            if (command.id === obj.id) {
              Object.assign(command, obj);
              found = true;
            }
          });
          if (!found) {
            draft.additionalFields.push(obj);
          }
        }
      }),
      () => {}
    );
  };
  deleteRow = row => {
    let rowId = row.id;
    const rowLength = this.state.additionalFields.length;

    if (rowLength > 1) {
      /*  this.setState(
        produce(draft => {
          const objIndex = draft.additionalFields.findIndex(
            field => field.id === rowId
          );
          if (objIndex > -1) {
            draft.additionalFields.splice(objIndex, 1);
          }
        })
      ); */
      const updateValues = this.state.additionalFields.filter(
        value => rowId !== value.id
      );

      this.setState({ additionalFields: updateValues });
    }
  };

  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  getCreatePayload = () => {
    const {
      // scouponcode,
      companyid = this.companyId,
      couponcode,
      title,
      description,
      coupongroup,
      status,
      discountby,
      discountbyvalue,
      validfrom,
      validtill,
      qtymax,
      qtybal,
      couponimg,
      additionalFields
    } = this.state;
    let addfield =  additionalFields.reduce(function (acc, item) {
      if(item.field.trim() !== "" && item.value.trim() !== ""){
        acc[item.field] = item.value
        return acc
      }
      return acc
    }, {})
    let body = {
      // scouponcode,
      //couponcode,
      companyid,
      title,
      description,
      coupongroup,
      status,
      discountby,
      discountbyvalue,
      validfrom: formatDate(validfrom),
      validtill: formatDate(validtill),
      qtymax,
      qtybal,
      images: {image1: couponimg},
      additionalfields: addfield
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
      body["couponcode"]= this.state.couponcode;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createCoupons = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateCoupon(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createCoupon(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      titleerror: "",
      descriptionerror: "",
      coupongrouperror: "",
      validfromerror: "",
      validtillerror: "",
      maxqtyerror: "",
      // scouponcodeerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const {
      title,
      coupongroup,
      validfrom,
      validtill,
      qtymax,
      // scouponcode,
      description
    } = this.state;
    let valid = true;
    if (title.trim() === "") {
      this.setState({ titleerror: "Please enter a title" });
      valid = false;
    }
    if (description.trim() === "") {
      this.setState({ descriptionerror: "Please enter description" });
      valid = false;
    }
    // if(scouponcode.trim() === "") {
    //   this.setState({ scouponcodeerror: "Please enter coupon code"});
    //   valid = false;
    // }
    if (coupongroup.trim() === "") {
      this.setState({ coupongrouperror: "Please select a coupon type" });
      valid = false;
    }

    if (validfrom.toString().trim() === "") {
      this.setState({ validfromerror: "Please select a from date" });
      valid = false;
    }
    if (validtill.toString().trim() === "") {
      this.setState({ validtillerror: "Please enter to date" });
      valid = false;
    }
    // if (qtymax === 0) {
    //   this.setState({ maxqtyerror: "Please enter maximum quantity" });
    //   valid = false;
    // }
    return valid;
  };

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  
  _isEditMode = () => {
    return this.state.isEdit;
  };

  _isViewMode = () => {
    return this.state.isView
  }

  _renderMainButton = () => {
    if (this._isViewMode()) {
      return (
        <React.Fragment>
          <Link
            to="/ui/coupon-management"
            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          >
            Close
          </Link>
        </React.Fragment>
      );
    }

    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => {
              this._createCoupons();
            }}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createCoupons}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/coupon-management"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };

  _renderMainFormDetails = () => {
    return (
      <React.Fragment>
        <div className="row">
        <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => this.setState({ title: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="couponcode" className="floatLeft">
              Coupon Code
            </label>
            <input
              type="text"
              className="form-control"
              id="couponcode"
              value={this.state.couponcode}
              readOnly={true}
            />
          </div>     
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="coupongroup" className="floatLeft required">
              Coupon Group
            </label>
            <SimpleDropdown
              id="coupongroup"
              listoptions={this.coupongroup}
              selectedOption={this.state.coupongroup}
              callbackChangeFilter={value => {
                this.setState({ coupongroup: value })}
              }
            />
            <div className="invalid-feedback">{this.state.coupongrouperror}</div>
          </div>  
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="description" className="floatLeft required">
              Description
            </label>
            <input
              className="form-control"
              id="description"
              value={this.state.description}
              onChange={event =>
                this.setState({ description: event.target.value })
              }
            />
           <div className="invalid-feedback">{this.state.descriptionerror}</div>
          </div>                
        </div>
      </React.Fragment>
    );
  };
  _renderLocationDetails = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sku" className="floatLeft required">
              SKU
            </label>
            <input
              type="text"
              className="form-control"
              id="sku"
              value={this.state.sku}
              onChange={event => this.setState({ sku: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.skuerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="price" className="floatLeft required">
              Price
            </label>
            <input
              type="text"
              className="form-control"
              id="price"
              value={this.state.price}
              onChange={event => this.setState({ price: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.priceerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="msg1" className="floatLeft ">
              Message 1
            </label>
            <input
              type="text"
              className="form-control"
              id="msg1"
              value={this.state.msg1}
              onChange={event => this.setState({ msg1: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="msg2" className="floatLeft ">
              Message 2
            </label>
            <input
              type="text"
              className="form-control"
              id="msg2"
              value={this.state.msg2}
              onChange={event => this.setState({ msg2: event.target.value })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderGeneralInformation = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="validfrom" className="floatLeft">
              Valid From Date
            </label>
            <DatePicker
              minDate={this._isEditMode() ? this.state.validfrom : new Date}
              onChange={value => {
                this.setState({ validfrom: value });
              }}
              value={this.state.validfrom}
            />
            <div className="invalid-feedback">{this.state.validfromerror}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="validtill" className="floatLeft">
              Valid Till Date
            </label>
            <DatePicker
              minDate={this.state.validfrom}
              onChange={value => {
                this.setState({ validtill: value });
              }}
              value={this.state.validtill}
            />
            <div className="invalid-feedback">{this.state.validtillerror}</div>
          </div>
        </div>
        <div className="row" style={{ display: "none"}}>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="maxqty" className="floatLeft required">
              Maximum Quantity
            </label>
            <input
              type="number"
              className="form-control"
              id="qtymax"
              value={this.state.qtymax}
              min="0"
              onChange={event => this.setState({ qtymax: parseInt(event.target.value) })}
            />
            <div className="invalid-feedback">{this.state.maxqtyerror}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="balanceqty" className="floatLeft">
              Balance Quantity
            </label>
            <input
              type="number"
              className="form-control"
              id="qtybal"
              value={this.state.qtybal}
              readOnly={true}
            />
          </div>
          {/* <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="issuedqty" className="floatLeft required">
              Issued Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="issuedqty"
              value={this.state.issuedqty}
              onChange={event =>
                this.setState({ issuedqty: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.issueqtyerror}</div>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="usedqty" className="floatLeft required">
              Used Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="usedqty"
              value={this.state.usedqty}
              onChange={event => this.setState({ usedqty: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.usedqtyerror}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="expiredqty" className="floatLeft">
              Expired Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="expiredqty"
              value={this.state.expiredqty}
              onChange={event =>
                this.setState({ expiredqty: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.expiredqtyerror}</div>
          </div>
        </div> */}      
      </React.Fragment>
    );
  };

  _renderImageDetails = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Images</div>
        {/* <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="menulink" className="floatLeft">
              Background Image
            </label>
            <SimpleImageInput
              placeholder="Store Logo.."
              setImage={value => {
                this.setState({ bgimg: value });
              }}
              value={this.state.bgimg}
            ></SimpleImageInput>
            <div className="invalid-feedback">{this.state.bgimgError}</div>
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="menulink" className="floatLeft">
              Coupon Image
            </label>
            <SimpleImageInput
              placeholder="Store Background.."
              setImage={value => {
                this.setState({ couponimg: value });
              }}
              value={this.state.couponimg}
            ></SimpleImageInput>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusOptionComp
              statusoption={this.statusCoupon}
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft required">
              Sort Order
            </label>
            <input
              type="text"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />
          </div>
        </div> */}
      </React.Fragment>
    );
  };
  _renderAdditionalFields = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12 label2">
            <label htmlFor="additionalfields" className="floatLeft">
              Additional Fields
            </label>
          </div>
          <div className="form-group col-lg-12 col-sm-12">
            <DynamicTable
              columns={[
                { dataFieldId: "field", label: "Key", addRow: true },
                { dataFieldId: "value", label: "Value", addRow: true },
                { dataFieldId: "AD", label: "" }
              ]}
              rows={this.state.additionalFields}
              deleteRow={this.deleteRow}
              addRow={this.handleAddRow}
              handleInputChange={this.handleInputChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderGeneralInformation()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em"}}
            >
              {this._renderAdditionalFields()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderImageDetails()}
            </form>
            {/* <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderAdditionalFields()}
            </form> */}
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form
              className="splitFrmDivSec text-center"
            >
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };
  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isView = this.props.location.state.isView;
      if (rec) {
        const {
          id,
          status,
          // scouponcode,
          couponcode,
          title,
          description,
          coupongroup,
          discountby,
          discountbyvalue,
          validfrom,
          validtill,
          qtymax,
          qtybal,
          images,
          additionalfields
        } = rec;
        let defaultaddField =  [{field: "", value: "", id:0}];
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.isView = isView;
            draft.title = title ? title : "";
            draft.couponcode = couponcode ? couponcode : "";
            draft.status = status ? status : [];
            draft.description = description ? description : "";
            draft.discountby = discountby ? discountby : "";
            draft.discountbyvalue  = discountbyvalue ? discountbyvalue : 0;
            draft.coupongroup = coupongroup ? coupongroup : "";
            draft.validfrom = validfrom ? this.toDate(validfrom) : "";
            draft.validtill = validtill ? this.toDate(validtill) : "";
            draft.qtymax = qtymax ? qtymax : 0;
            draft.qtybal = qtybal ? qtybal : 0;
            draft.couponimg = images ? images.image1 : "";
            draft.additionalFields = typeof additionalfields !== "undefined" &&
            Object.keys(additionalfields).length !== 0        
            ? objtoarr(additionalfields) : 
            defaultaddField   
          })
        );
      }
    }
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() ? "Edit Coupons" : this._isViewMode() ? "View Coupon" : "Add Coupon"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/coupon-management"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode()
                  ? "Coupon updated successfully"
                  : "Coupon created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddCoupon);
