import React from "react";
import Filter from "./Filter";
import ColorPicker from "./ColorPicker";
import { renderFilterForDynamicFields } from "./../helpers/Util";

class DynamicDesc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //values: [{ title: "", desc: "", id: 0 }],
      values: [],
      Json: []
    };
    this.lastId = -1;
  }

  componentWillReceiveProps = newProps => {
    if (newProps.variantField !== this.props.variantField) {
      this.setState({
        values: newProps.dynamicDesc && newProps.dynamicDesc
      });
      /* if (this.props.isEdit) {
        this.setState({
          values: newProps.dynamicDesc && newProps.dynamicDesc
        });
      } */
    }
  };
  arrayToObject = () => {
    let array = this.state.values;
    var fields = {};
    var len = array.length;
    for (var i = 0; i < len; i++) {
      var item = array[i];
      fields[item["title"]] = item["desc"];
    }
    return fields;
  };
  setColors = array => {
    let newArray = [];
    array.map(value => {
      newArray.push({
        content: value,
        id: ++this.lastId,
        itemWidth: 40
      });
    });
    return newArray;
  };
  createUI() {
    return this.state.values.map(value => {
      let idx = value.id;
      let title = value.title;
      return (
        <div className="row" key={idx}>
          <div className="col-md-5 col-sm-12 noPadding">
            <Filter
              cssClass="form-control"
              filterOptions={renderFilterForDynamicFields(
                this.props.variantField
              )}
              selectedOption={value.title}
              className="dynamicDescInp"
              callbackChangeFilter={event => {
                this.handleChange(event, idx);
              }}
              id="title"
            />
          </div>
          <div className="col-md-5 col-sm-12">
            {title === "color" ? (
              <ColorPicker
                setColor={value => this.seColorPickerValues(idx, value)}
                id="desc"
                colorSelected={this.setColors(value.desc.split(","))}
              ></ColorPicker>
            ) : title === "size" ? (
              <input
                id="desc"
                type="text"
                value={value.desc}
                className="dynamicDescInp"
                placeholder="XS,S,M,L,XL,XXL"
                onChange={event => this.handleChange(event, idx)}
              />
            ) : title === "select" ? (
              <input
                id="desc"
                type="text"
                value={value.desc}
                disabled={true}
                className="dynamicDescInp"
              />
            ) : (
              <input
                id="desc"
                type="text"
                value={value.desc}
                className="dynamicDescInp"
                onChange={event => this.handleChange(event, idx)}
              />
            )}
          </div>
          <div className="col-md-2 col-sm-12 alignLeft">
            <img
              className="dynamicBtn1"
              onClick={event => this.removeClick(event, idx)}
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            />
          </div>
        </div>
      );
    });
  }

  handleChange = (evt, id) => {
    const newProducts = this.state.values.map(value => {
      if (id !== value.id) return value;
      return { ...value, [evt.target.id]: evt.target.value };
    });

    this.setState({ values: newProducts }, () => {
      let finalVl = this.arrayToObject();
      this.props.setDesc(finalVl);
    });
  };
  seColorPickerValues = (id, colorArray) => {
    let colors = colorArray.map(value => value.content);
    let colorString = colors.toString();

    const newProducts = this.state.values.map(value => {
      if (id !== value.id) return value;
      return { ...value, desc: colorString };
    });

    this.setState({ values: newProducts }, () => {
      let finalVl = this.arrayToObject();
      this.props.setDesc(finalVl);
    });
  };
  addClick = event => {
    let lastEle = this.state.values[this.state.values.length];

    let len = 0;
    if (lastEle === undefined) {
      len = 0;
    } else {
      len = lastEle.id;
    }
    const item = {
      title: "",
      desc: "",
      id: len
    };
    this.setState({
      values: [...this.state.values, item]
    });
    if (event) event.preventDefault();
  };
  removeClick = (evt, idx) => {
    // const updateValues = [...this.state.values];
    // updateValues.splice(idx, 1);
    const updateValues = this.state.values.filter(value => idx !== value.id);

    this.setState({ values: updateValues });
  };
  render() {
    return (
      <React.Fragment>
        {this.createUI()}
        <div className="row">
          <button className="addNewRow" onClick={event => this.addClick(event)}>
            Add New
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicDesc;
