import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Divider } from "primereact/divider";
import ReactSwitch from "react-switch";
import { AutoComplete } from "primereact/autocomplete";
import { promotionPGroupSearchByFields } from "../../../actions/actions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import CreatePromoPurchaseGroup from "./CreatePromoPurchaseGroup";
import { Dialog } from "primereact/dialog";

const PurchaseGroupSelector = (props) => {
    const { storeid, isViewMode, formik, handleStepChange } = props;

    const { promotionspurchasegroups, promotionstargetgroups } = props.queryData;
    const { pgroupData } = props.crudData;

    const [selectedPromotionGroup, setSelectedPromotionGroup] = useState({});
    const [filteredCountries, setFilteredCountries] = useState(null);
    const [selectedPromoGroupCategories, setSelectedPromoGroupCategories] =
        useState([]);
    const [selectedPromoGroupProducts, setSelectedPromoGroupProducts] =
    useState([]);
    const [qtymin, setQtymin] = useState(0);
    const [qtymax, setQtymax] = useState(0);
    const [amountmin, setAmountmin] = useState(0);
    const [amountmax, setAmountmax] = useState(0);
    const [showCreatePromoPurchaseDialog, setShowCreatePromoPurchaseDialog] = useState(false)

    useEffect(() => {
        props.dispatch(
            promotionPGroupSearchByFields(
                `storeid=${props.storeid}&status=Active`
            )
        );
    }, [storeid]);

    useEffect(() => {
        if (pgroupData && pgroupData.id) {
            props.dispatch(
                promotionPGroupSearchByFields(
                    `storeid=${props.storeid}&status=Active`
                )
            );
        }
    }, [pgroupData])

    useEffect(() => {
        if (promotionstargetgroups.length > 0 && promotionspurchasegroups.length > 0) {
            const purchaseGroup = promotionspurchasegroups.find(pg => pg.promopurchasecode == promotionstargetgroups[0].promopurchasecode)
            setSelectedPromotionGroup(purchaseGroup)
            handleSearch(purchaseGroup)
        }
    }, [promotionstargetgroups, promotionspurchasegroups])

    const searchCountry = (event) => {
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...promotionspurchasegroups];
            } else {
                _filteredCountries = promotionspurchasegroups.filter(
                    (country) => {
                        return country.title
                            .toLowerCase()
                            .startsWith(event.query.toLowerCase());
                    }
                );
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    };

    const itemTemplate = (item) => {
        return (
            <div className="country-item">
                <div>{item.title}</div>
            </div>
        );
    };

    const handleSearch = (record) => {
        if (record && record.id) {
            setSelectedPromotionGroup(record);
            const { categorycodes, productcodes } = record;
            const categories =
                categorycodes.trim().length > 0
                    ? categorycodes.includes(",")
                        ? categorycodes.split(",")
                        : [...new Array(categorycodes)]
                    : [];
            const products =
                productcodes.trim().length > 0
                        ? productcodes.includes(",")
                            ? productcodes.split(",")
                            : [...new Array(productcodes)]
                        : [];
            setSelectedPromoGroupCategories(categories);
            setSelectedPromoGroupProducts(products)
            setAmountmax(record.amountmax)
            setAmountmin(record.amountmin)
            setQtymax(record.qtymax)
            setQtymin(record.qtymin)
        }
    };

    const handlePromoGroupSelectHide = () => {
        setShowCreatePromoPurchaseDialog(false)
    }
    return (
        <>
            <div className="pv-20">
                <div className="row ph-20" style={{ marginBottom: "16px" }}>
                    <div className="field flex flex-column">
                        <label htmlFor="storeid">Purchase Group</label>
                        <div>
                        <AutoComplete
                            value={
                                selectedPromotionGroup
                                    ? selectedPromotionGroup.title
                                    : ""
                            }
                            suggestions={filteredCountries}
                            completeMethod={searchCountry}
                            field="title"
                            dropdown
                            forceSelection
                            itemTemplate={itemTemplate}
                            onChange={(e) => handleSearch(e.value)}
                            aria-label="Countries"
                            placeholder="Type to search..."
                        />
                            {/* <Button 
                                type="button"
                                className="ml-2 p-button-secondary p-button-outlined" 
                                icon="pi pi-plus" 
                                onClick={() => setShowCreatePromoPurchaseDialog(true)}
                            /> */}
                            <button type="button" 
                                className="p-button p-component ml-2 p-button-secondary p-button-outlined p-button-icon-only"
                                onClick={() => setShowCreatePromoPurchaseDialog(true)}
                            >
                                <span className="p-button-icon p-c pi pi-plus" />
                                <span className="p-button-label p-c">&nbsp;</span>
                            </button>
                        </div>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <Panel header="Category">
                            <div className="flex align-items-center flex-wrap">
                                {selectedPromoGroupCategories.map((s) => {
                                    return (
                                        <Chip
                                            label={s}
                                            key={s}
                                            className="mr-2 mb-2 bg-blue c-white"
                                        />
                                    );
                                })}
                            </div>
                        </Panel>
                        <div className="row">
                            <div
                                className="p-fluid formgrid grid w-100"
                                style={{ marginTop: "16px" }}
                            >
                                <div className="field form-group col-lg-6 col-sm-12">
                                    <label htmlFor="amountmin">
                                        Amount Min *
                                    </label>
                                    <InputText
                                        id="amountmin"
                                        type="text"
                                        name="amountmin"
                                        value={amountmin}
                                        onChange={() => {}}
                                        disabled={isViewMode}
                                    />
                                </div>
                                <div className="field form-group col-lg-6 col-sm-12">
                                    <label htmlFor="amountmax">
                                        Amount Max *
                                    </label>
                                    <InputText
                                        id="amountmax"
                                        type="text"
                                        name="amountmax"
                                        value={amountmax}
                                        onChange={() => {}}
                                        disabled={isViewMode}
                                    />
                                </div>
                            </div>
                            <div
                                className="p-fluid formgrid grid w-100"
                                style={{ marginTop: "16px" }}
                            >
                                <div className="field form-group col-lg-6 col-sm-12">
                                    <label htmlFor="amountmin">Qty Min *</label>
                                    <InputText
                                        id="qtymin"
                                        type="text"
                                        name="qtymin"
                                        value={qtymin}
                                        onChange={() => {}}
                                        disabled={isViewMode}
                                    />
                                </div>
                                <div className="field form-group col-lg-6 col-sm-12">
                                    <label htmlFor="qtymax">Qty Max *</label>
                                    <InputText
                                        id="qtymax"
                                        type="text"
                                        name="qtymax"
                                        value={qtymax}
                                        onChange={() => {}}
                                        disabled={isViewMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <Panel header="Product Code">
                            <div className="flex align-items-center flex-wrap">
                                {selectedPromoGroupProducts.map((s) => {
                                    return (
                                        <Chip
                                            label={s}
                                            key={s}
                                            className="mr-2 mb-2 bg-blue c-white"
                                        />
                                    );
                                })}
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
            {!isViewMode && (
                    <div className="row">
                    <div className="col-6 alignRight alignWithPageHeader ml-auto ph-16">
                        <Button
                            type="button"
                            className={classNames({
                                "p-button-success":
                                    formik.values.promogroup &&
                                    formik.values.discountvalue &&
                                    formik.values.discountcapamt &&
                                    formik.values.title &&
                                    formik.values.promotype,
                                "p-button-secondary":
                                    !formik.values.promogroup ||
                                    !formik.values.discountvalue ||
                                    !formik.values.discountcapamt ||
                                    !formik.values.title ||
                                    !formik.values.promotype,
                            })}
                            label="Next"
                            onClick={() => handleStepChange(3)}
                            disabled={
                                !formik.values.promogroup ||
                                !formik.values.discountvalue ||
                                !formik.values.discountcapamt ||
                                !formik.values.title ||
                                !formik.values.promotype
                            }
                        />
                    </div>
                </div>
                )}
            <Dialog
                header="Eligible Purchase Group"
                visible={showCreatePromoPurchaseDialog}
                style={{ width: "50vw" }}
                onHide={handlePromoGroupSelectHide}
                className="no-padding"
            >
                <CreatePromoPurchaseGroup
                    storeid={storeid} 
                    onHideCreatePurchasePromo={handlePromoGroupSelectHide}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData
    };
};

export default connect(mapStateToProps)(PurchaseGroupSelector);
