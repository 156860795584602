import React from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { countrySearchByFields, searchGeneral } from "../actions/actions";

import { formatCountryArray, formatStoreArray, formatCityArray } from "../helpers/Util";
import Filter from "./Filter";

class TenantCode extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showTenantCodeModal: false,
      tenantCode: "",
      tenantCountry: "",
      tenantCity: "",
      tenantGrp: "",
      showTenantCity: false,
      showTenantStoreGrp: false,
      showTenantCode: false,
      showBtns: false,
      tenantList: {},
      disableOK: true,
      storegrparray: [],
      storearray: [],
      tenantlabel: "",
      clear: false
    };
  }
  
  componentWillMount = () => {
    this.props.dispatch(countrySearchByFields(`companyid=${this.companyid}`))
    this.props.dispatch(
      searchGeneral(
        "/system/v1/store/search/fields",
        {
          body: {
            companyid: localStorage.getItem("company_id")
          }
        },
        "SEARCH_CITY_BY_COUNTRY_SUCCESS",
        "SEARCH_CITY_BY_COUNTRY_FAILURE"
      )
    );
  };

  _openTenantCodeModal = event => {
    const fil = this.props.queryData.dataByCountry.filter(e=>e.title === this.props.value);
    if(fil.length > 0 && this.state.clear === false){
      let getstorearr = this.props.queryData.dataByCountry.filter(e=>e.city === fil[0].city);
      this.setState({
        storearray: getstorearr,
        tenantCountry: fil[0].country,
        tenantCity: fil[0].city,
        tenantlabel: fil[0].title,
        tenantCode: fil[0].storeid
      })
    }
    else {
      this.setState({
        storearray: [],
        tenantCountry: "",
        tenantCity: "",
        tenantlabel: "",
        tenantCode: "",
        clear: false
      })
    }
    this.setState({
      showTenantCodeModal: true
    });
    
    event.preventDefault();
  };

  _closeTenantCodeModal = event => {
    this.setState({
      showTenantCodeModal: false,
      showTenantCity: false,
      showTenantStoreGrp: false,
      showTenantCode: false,
      //tenantCountry: "",
      //tenantCity: "",
      tenantGrp: ""
    });
    if (event) event.preventDefault();
  };
  _setTenantCode = event => {
    let tenantCode = this.state.tenantCode;
    let tenantlabel = this.state.tenantlabel;
    this.props.setTenantCode(tenantCode, tenantlabel);
    this._closeTenantCodeModal();
    if (event) event.preventDefault();
  };

  _clearTenantCodeModal = event => {
    let tenantCode = "";
    let tenantlabel = "";
    this.setState({
      tenantCountry: "",
      tenantCity: "",
      tenantlabel: "",
      tenantCode: "",
      clear: true
    });
    this.props.setTenantCode(tenantCode, tenantlabel);
    this._closeTenantCodeModal();
    if (event) event.preventDefault();
  }

  _tenantCountryOnChange = selectedValue => {
    if (selectedValue === "-1") {
      this.setState({
        showTenantCode: false,
        tenantCountry: selectedValue,
        showBtns: false
      });
    } else {
      this.setState({
        showTenantCity: true,
        tenantCountry: selectedValue
      });
      this.setState({
        tenantCode: "",
        tenantCity: "",
        tenantGrp: "",
        storegrparray: [],
        storearray: []
      });
    }
  };
  _tenantCityOnChange = selectedValue => {
    if (selectedValue === "-1") {
      this.setState({
        showTenantStoreGrp: false,
        tenantCity: selectedValue,
        showBtns: false
      });
    } else {
      this.setState({
        showTenantStoreGrp: true,
        tenantCity: selectedValue
      });
    }
    this.setState({
      tenantCode: "",
      tenantGrp: "",
      storegrparray: [],
      storearray: [],
    });
    let groupid = localStorage.getItem("storegroup_access");
    this._getStoreByGroup(selectedValue, groupid);
  };

  _tenantCodeOnChange = (selectedValue, selectedtenant) => {
    this.setState({
      tenantCode: selectedValue,
      tenantlabel: selectedtenant,
    });
    let tenantCode = this.state.tenantCode;
    if (selectedValue !== "-1") {
      this.setState({ showBtns: true });
    } else {
      this.setState({ showBtns: false });
    }
  };

  _getStoreGroupFromCity = selectedValue => {
    const { dataByCountry } = this.props.queryData;
    const { tenantCountry } = this.state;
    const storegroups = dataByCountry.filter(data => {
      return data.city === selectedValue && data.country === tenantCountry;
    });
    this.setState({ storegrparray: storegroups });
  };
  _getStoreByGroup = (selectedValue, storegrp) => {
    let storegroup = storegrp && storegrp.split(",");
    const { dataByCountry } = this.props.queryData;
    const { tenantCountry } = this.state;
    const storegroups = dataByCountry.filter(data => {
      return data.city === selectedValue && data.country === tenantCountry;
    });
    const storearray = storegroups.map(storegrp => {
      for (var i = 0; i < storegroup.length; i++) {
        if (storegrp.groupid === storegroup[i]) {
          return storegrp;
        }
      }
    });
    this.setState({ storearray: storearray });
  };
  _renderTenantCodeModal = () => {
    const { showBtns, storearray } = this.state;
    const { dataByCountry } = this.props.queryData;
    return (
      <Modal
        visible={this.state.showTenantCodeModal}
        width="50%"
        height="48%"
        effect="fadeInRight"
        onClickAway={() => this._closeTenantCodeModal()}
      >
        <div className="modalForm tenantModalForm">
          <div className="row noPadding noMargin ">
            <div className="col-xs-12 col-md-10 alignLeft noPadding noMargin">
              <h5>Choose store by country</h5>
            </div>
            <div className="col-xs-12 col-md-2 alignRight">
              <a
                onClick={this._closeTenantCodeModal}
                className="closeIcon alignRight"
              >
                X
              </a>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 col-md-4 col-form-label alignLeft">
              Choose Country
            </label>
            <div className="col-sm-12 col-md-8">
              <Filter
                cssClass="form-control"
                filterOptions={formatCountryArray(dataByCountry, "country")}
                selectedOption={this.state.tenantCountry}
                className="dynamicDescInp"
                autoFocus={true}
                callbackChangeFilter={event => {
                  this._tenantCountryOnChange(event.target.value);
                }}
                id="countries"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 col-md-4 col-form-label alignLeft">
              Choose City
            </label>
            <div className="col-sm-12 col-md-8">
              <Filter
                cssClass="form-control"
                filterOptions={formatCityArray(dataByCountry, "city", this.state.tenantCountry)}
                selectedOption={this.state.tenantCity}
                className="dynamicDescInp"
                callbackChangeFilter={event => {
                  this._tenantCityOnChange(event.target.value);
                }}
                id="tenants"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-md-4 col-form-label alignLeft">
              Choose Store
            </label>
            <div className="col-sm-12 col-md-8">
              <Filter
                cssClass="form-control"
                filterOptions={formatStoreArray(storearray)}
                selectedOption={this.state.tenantCode}
                className="dynamicDescInp"
                callbackChangeFilter={event => {
                  let options = event.target.options;
                  let selectedtenant = "";
                  for (var i = 0; i < options.length; i++) {
                    if (options[i].value === event.target.value) {
                      selectedtenant = options[i].label;
                    }
                  }

                  this._tenantCodeOnChange(event.target.value, selectedtenant);
                }}
                id="tenantCode"
              />
            </div>
          </div>

          <div className="row btn-container1">
            <div className="col-lg-12 col-md-12 col-sm-12 alignRight">
              <button
                type="submit"
                onClick={this._setTenantCode}
                className="btn btn-themes btn-rounded"
              >
                OK
              </button>

              <button
                type="submit"
                onClick={this._closeTenantCodeModal}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Cancel
              </button>

              <button
                type="submit"
                onClick={this._clearTenantCodeModal}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Clear
              </button>

            </div>
          </div>
        </div>
      </Modal>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.showTenantCodeModal && this._renderTenantCodeModal()}
        <div className="row">
          <div className="col-12 noPadding noMargin">
            <input
              type="text"
              className=" treemodal"
              id="tenant"
              readOnly={true}
              value={this.props.value}
              onClick={this._openTenantCodeModal}
              disabled={this.props.disabled || false}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(TenantCode);
