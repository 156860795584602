import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import { getUserInfo } from "../../actions/actions";

class NameDisplay extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const firstname = localStorage.getItem("fname");
    const lastname = localStorage.getItem("lname");

    return (
      <span
        style={{ paddingRight: "1em" }}
      >{`${firstname}, ${lastname}       `}</span>
    );
  }
}

const mapStateToProps = state => {
  return { data: state.loginData };
};

export default connect(mapStateToProps)(NameDisplay);
