import axios from "axios";

const URL = window && window.config && window.config.END_POINT; //for deve
//const imagepath = "http://plutobizsuite.net:9000"; //dev
// const port = window && window.config.PORT_NO;
// const protocol = window && window.config.PROTOCOL;
// const hostname = window && window.location.hostname;
//const URL = window && `${protocol}${hostname}:${port}/`; //for build
//const imagepath = window && `${protocol}${hostname}:${port}`; //build
const imagepath = window && window.config &&  window.config.MEDIA_ENDPOINT;
localStorage.setItem("imagepath", imagepath);
localStorage.setItem("url", URL);

function toQueryString(params) {
  return (
    Object.keys(params)
      /* .map(key => {
      if (params[key] === "") return encodeURIComponent(key);
      else
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    }) */
      .map(key => {
        if (params[key] === "") return key;
        else return key + "=" + (typeof params[key] === 'string'?params[key].replaceAll(/%/g, '%25'):params[key])
      })
      .join("&")
  );
}

function toQuery(params) {
  return Object.keys(params)
    .map(key => {
      if (params[key] === "") return key;
      else return key + "=" + (typeof params[key] === 'string'?params[key].replaceAll(/%/g, '%25'):params[key])
    })
    .join("&");
}

export function loginApi(payload) {
  return axios({
    method: "POST",
    // url: `${URL}system/v1/user/auth?${toQueryString(payload)}`
    url: `${URL}system/v1/userauth/channel/ui/login`,
    data: payload
  });
}

export function logoutApi() {
  return axios({ method: "POST", url: `${URL}/logout` });
}

/* export function getStoreDownloadUrl() {
  return `${URL}${ROOT_DOMAIN}stores/download`;
}

export function getEventDownloadUrl(actionType) {
  return `${URL}${ROOT_DOMAIN}event/${actionType}/report/csv`;
}

export function getOfflineDownloadUrl() {
  return `${URL}${ROOT_DOMAIN}store/report/csv`;
}

export function getNewEventsDownloadUrl({ body }) {
  return `${URL}${ROOT_DOMAIN}events/report/csv?${toQueryString(body)}`;
}

export function getIncidentReportsDownloadUrl({ body }) {
  return `${URL}${ROOT_DOMAIN}event/report/csv?${toQueryString(body)}`;
} */

//Dashboard fetch
/* export function storeApi(payload) {
  const { pgSize, orderBy, currPage, sortBy, zoneId, storeId } = payload;
  const storeUrl = `${URL}${ROOT_DOMAIN}stores?o_by=${orderBy}&pg_sz=${pgSize}&c_p=${currPage}&s=${sortBy}&f_zone_id=${zoneId}&f_store_id=${storeId}`;
  return axios.get(storeUrl);
} */

// Generalising
export function crudApi(body, url, method, encode, crudQuery) {

  return axios({
    method: method,
    url: crudQuery === true ? `${URL}${url}?${toQuery(body)}` : `${URL}${url}`,
    headers: {
      Accept: "application/json",

      Authorization: localStorage.getItem("session"),
      Deviceid: localStorage.getItem("randomDeviceId"),
      Copyrightyear :  localStorage.getItem("copyrightyear"),
      Buildversion: localStorage.getItem("buildversion"),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      crossorigin: true
    },
    data: crudQuery === true ? "" : body
  });
}

export function queryApi({ body, url, encode, noformat }) {
  const requestBody = body || {};
  let isBodyNull = Object.entries(requestBody).length === 0;
  let restqry = "";
  if (encode === false) {
    if (isBodyNull) restqry = ``;
    else restqry = `?${toQuery(requestBody)}`;
  } else {
    if (isBodyNull) restqry = ``;
    else restqry = `?${toQueryString(requestBody)}`;
  }
  let newurl =
    noformat === true
      ? `${URL}${url}`
      : restqry === ""
      ? `${URL}${url}`
      : `${URL}${url}${restqry}`;
  return axios({
    method: "GET",
    timeout: 30000,
    url: newurl,
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("session"),
      Deviceid: localStorage.getItem("randomDeviceId"),
      Copyrightyear: localStorage.getItem("copyrightyear"),
      Buildversion: localStorage.getItem("buildversion"),
      Storeid: localStorage.getItem("storeid"),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Pragma: "no-cache",
      crossorigin: true,
      "Access-Control-Allow-Methods":
        "GET, HEAD, POST, PUT, DELETE, TRACE, OPTIONS, PATCH"
    }
  });
}
