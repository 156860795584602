import "react-sortable-tree/style.css";
import React from "react";
import SortableTree, { toggleExpandedForAll } from "react-sortable-tree";

class DisplayTree extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addAsFirstChild: false,
      categorycode: "",
      treeData: this.props.treeData,
      nodesExpanded: true,
      selectedValue: this.props.selectedValue ? this.props.selectedValue : ""
    };
  }
  componentWillReceiveProps = newProps => {
    this.setState({ treeData: newProps.treeData }, () => this.toggleAllNodes());
  };
  componentDidMount = () => {
    this.toggleAllNodes();
  };
  toggleAllNodes = event => {
    if (event) event.preventDefault();
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded: this.state.nodesExpanded
      }),
      nodesExpanded: this.state.nodesExpanded
    });
  };
  _selectNode = ({
    node,
    path,
    treeIndex,
    lowerSiblingCounts: _lowerSiblingCounts
  }) => {
    let categorycode = node.categorycode;
    let categorytitle = node.title;

    this.setState({ selectedValue: categorycode });
    this.props.setCategoryCode(categorycode, categorytitle);
  };
  render() {
    return (
      <React.Fragment>
        <div className="form-group row">
          <div
            className={`col-sm-12 col-md-10 noPadding noMargin  
               display-inline`}
            style={{ height: "100vh" }}
          >
            <SortableTree
              treeData={this.state.treeData}
              onChange={treeData => this.setState({ treeData: treeData })}
              style={{ height: "100%" }}
              generateNodeProps={rowInfo => {
                let nodeProps = {
                  buttons: [
                    <img
                      className="dynamicBtn"
                      src={`${process.env.PUBLIC_URL}/assets/icons/ic_okay.png`}
                      onClick={() => {
                        this._selectNode(rowInfo);
                      }}
                    ></img>
                  ]
                };
                if (this.state.selectedValue === rowInfo.node.categorycode) {
                  nodeProps.className = "selected-node";
                }
                return nodeProps;
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DisplayTree;
