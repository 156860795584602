import React, { useState, useEffect } from 'react';
import { produce } from 'immer';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { objtoarr } from '../../../../helpers/Util';
import FileSelector from '../../../../components/FileSelector';

const DynamicFileInput = (props) => {
    const { additionalFields, fieldArr, handleChange, updateAdditionalFields } = props;
    const [formFields, setFormFields] = useState(
        fieldArr.length > 0 ? fieldArr : [{ field: '', value: '' }]
    );

    useEffect(() => {
        getDraft();
    }, [additionalFields]);

    const getDraft = () => {
        const envprop =
            additionalFields !== null &&
            Object.keys(additionalFields).length !== 0
                ? objtoarr(additionalFields)
                : [];
        setFormFields(envprop);
    };

    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        //handleChange(data);
    };

    const handleSetFileValue = (filePath, index) => {
        let data = [...formFields];
        data[index]['value'] = filePath;
        setFormFields(data);
        const outuputData = data.reduce((result, { field, value }) => {
            result[field] = value;
            return result;
          }, {});
        updateAdditionalFields(outuputData);
    };

    const addFields = () => {
        let object = {
            field: '',
            value: '',
        };
        setFormFields([...formFields, object]);
    };

    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1);
        setFormFields(data);
    };

    return (
        <>
            {formFields.map((form, index) => (
                <FileInputField
                    key={index}
                    form={form}
                    index={index}
                    handleFormChange={handleFormChange}
                    addFields={addFields}
                    removeFields={removeFields}
                    formFields={formFields}
                    handleSetFileValue={handleSetFileValue}
                />
            ))}
        </>
    );
};

const FileInputField = (props) => {
    const {
        form,
        index,
        handleFormChange,
        formFields,
        addFields,
        removeFields,
        handleSetFileValue,
    } = props;
    const [openFileSelect, setOpenFileSelect] = useState(false);
    const openFileSelectorModal = () => setOpenFileSelect(true);

    const handleFormFieldChange = (event, index) => {
        handleFormChange(event, index);
    };

    return (
        <div key={index}>
            <div
                className="flex-row mt-2 w-100"
                style={{ display: 'inline-flex' }}
            >
                <InputText
                    name="field"
                    onChange={(event) => handleFormFieldChange(event, index)}
                    value={form.field}
                    placeholder="Field"
                    className="mr-2"
                />
                <span
                    className="p-input-icon-right mr-2"
                    style={{ display: 'block', width: '50%' }}
                >
                    <i className="pi pi-file" />
                    <InputText
                        value={form.value}
                        onChange={(event) =>
                            handleFormFieldChange(event, index)
                        }
                        placeholder=""
                        className="w-100"
                        readOnly={true}
                        style={{ cursor: 'default' }}
                        onClick={openFileSelectorModal}
                    />
                </span>
                {index == formFields.length - 1 && (
                    <Button
                        icon="pi pi-plus"
                        className="p-button-rounded mr-2"
                        aria-label="Add"
                        onClick={addFields}
                        type="button"
                    />
                )}

                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Remove"
                    onClick={removeFields}
                    type="button"
                />
            </div>
            {openFileSelect && (
                <FileSelector
                    handleSetFileValue={(filePath) =>
                        handleSetFileValue(filePath, index)
                    }
                    closeFileSelect={() => setOpenFileSelect(false)}
                />
            )}
        </div>
    );
};

export default DynamicFileInput;
