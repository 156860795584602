import React from 'react';
import { connect } from 'react-redux';
import CategoryTree from '../../../components/CategoryTree';
import { produce } from 'immer';
import { Link } from 'react-router-dom';
import validator from 'validator';
import debounce from 'lodash.debounce';
import { Toast } from 'primereact/toast';
import {
    queryCategoryTree,
    queryInventoryByStore,
    updateItem,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import 'react-tabs/style/react-tabs.css';
import UpdateValues from './UpdateValues';
import StatusComp from '../../../components/StatusComp';
import SimpleTable from '../../../components/SimpleTable';
import Filter from '../../../components/Filter';
import SimpleSelect from '../../../components/SimpleSelect';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Autocomplete from '../../../components/Autocomplete';
import AddStock from './AddStock';
import Modal from 'react-awesome-modal';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';
import { canView, canWrite, permissions } from '../../../helpers/Util';
import { Button } from 'primereact/button';
import EditImage from '../../../components/ImageComponents/EditImage';
// Local Imports

class InventoryList extends React.PureComponent {
    companyId = localStorage.getItem('company_id');
    group = 'DE_SAL_INVENTORY';
    module = 'SALES';
    allPermissions = [];

    constructor(props) {
        super(props);
        this.state = {
            showSearchDiv: false,
            showProdModal: false,
            newprodcode: '',
            pageSize: 10,
            currPage: 1,
            categorycodes: '',
            categories: '',
            storeid: '',
            status: '',
            storelabel: '',
            stopsell: '0',
            stock: '0',
            productcode: '',
            iproductcode: '',
            barcode: '',
            sku: '',
            title: '',
            minavlitems: '',
            maxavlitems: '',
            mapProducts: false,
            formError: false,
            formErrorMsg: '',
            tempMappedProd: this.props.queryData.invData || [],
            showSecForm: 'false',
            isView: false,
            showStockForm: 'false',
            selectedProdId: '',
            prodData: {},
            storeiderror: '',
            categoryerror: '',
            showTable: false,
            invproductdata: [],
            additionalfields: {},
            numericerror: '',
            invallocationid: '',
            tableCols: [
                {
                    dataField: 'additionalfields.title',
                    text: 'Title',
                    sort: true,
                    editable: () => false,
                },
                {
                    dataField: 'productcode',
                    text: 'Product Code',
                    sort: true,
                    editable: () => false,
                },
                {
                    dataField: 'stopsell',
                    text: 'Stop Sell',
                    sort: true,
                    editable: () => false,
                    formatter: (cellContent, row) => {
                        if (canWrite(this.allPermissions)) {
                            return (
                                <Filter
                                    cssClass="form-control"
                                    filterOptions={[
                                        {
                                            id: 'Y',
                                            name: 'No Sale',
                                            css: 'selectNosale',
                                        },
                                        {
                                            id: 'N',
                                            name: 'On Sale',
                                            css: 'selectsale',
                                        },
                                    ]}
                                    isForTable={true}
                                    selectedOption={row.stopsell}
                                    className="dynamicDescInp"
                                    callbackChangeFilter={(event) => {
                                        if (row)
                                            row['stopsell'] =
                                                event.target.value;
                                        if (event.target.value === 'Y') {
                                            row['status'] = 'Inactive';
                                        }
                                        if (event.target.value === 'N') {
                                            row['status'] = 'Active';
                                        }
                                        this._update(row);
                                    }}
                                    id="field"
                                />
                            );
                        } else {
                            if (row.stopsell === 'Y') {
                                return (
                                    <h5>
                                        <span className="badge  badge-success">
                                            No Sell
                                        </span>
                                    </h5>
                                );
                            }
                            return (
                                <h5>
                                    <span className="badge  badge-danger">
                                        OnSale
                                    </span>
                                </h5>
                            );
                        }
                    },
                },
                {
                    dataField: 'stock',
                    text: 'Stock',
                    sort: true,
                    editable: () => false,
                    formatter: (cellContent, row) => {
                        if (row.stock === 'Y') {
                            return (
                                <h5>
                                    <span className="badge badge-pill badge-success">
                                        Available
                                    </span>
                                </h5>
                            );
                        }
                        return (
                            <h5>
                                <span className="badge badge-pill badge-danger">
                                    Out of stock
                                </span>
                            </h5>
                        );
                    },
                },
                {
                    dataField: 'qty',
                    text: 'Qty',
                    sort: true,
                    editable: () => false,
                },
                {
                    dataField: 'invallocationid',
                    text: 'Inventory Allocation Id',
                    sort: true,
                    editable: () => false,
                },
                {
                    dataField: 'avlitems',
                    text: 'Avl.Items',
                    sort: true,
                    editable: () => false,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                    formatExtraData: {
                        alert,
                    },
                    editable: () => false,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    editable: () => false,
                    formatExtraData: {
                        action: 'ED',
                    }
                },
            ],
        };
    }

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        const {
            storeid,
            storelabel,
            status,
            categorycodes,
            categories,
            stopsell,
            stock,
        } = this.state;

        return (
            <span>
                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/add-map-inventory',
                            state: {
                                item: row,
                                fields: {
                                    storeid,
                                    categorycodes,
                                    storelabel,
                                    status,
                                    categories,
                                    stopsell,
                                    stock,
                                },
                                isEdit: true,
                            },
                        }}
                    >
                        <EditImage />
                    </Link>
                )}
                {canView(this.allPermissions) && (
                    <img
                        title="Details"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_details.png`}
                        onClick={() => {
                            this.showUpdateForm('true', row);
                        }}
                    />
                )}
            </span>
        );
    };

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
    };

    getCategoryDetail = (obj) => {
        const { categoryTree } = this.props.queryData;
        if (categoryTree.children) {
            let getcategory = [];
            for (let i = 0; i < categoryTree.children.length; i++) {
                getcategory.push({
                    ...categoryTree.children[i],
                });
            }
            const mergeproduct = [].concat.apply(
                ...(getcategory || []).map((o) => o.children)
            );
            const getcode = mergeproduct.filter(
                (e) => e.categorycode === obj.categorycodes
            );
            const producttitle = getcode.length > 0 ? getcode[0].title : '';
            return producttitle;
        }
    };

    componentWillMount = () => {
        this.setState({
            storeid: localStorage.getItem('storeid'),
            storelabel: localStorage.getItem('storelabel'),
        });
        let checkstate = this.props.location.state;

        if (typeof checkstate !== 'undefined') {
            this.setState(
                {
                    storeid: checkstate.storeid,
                    categorycodes: checkstate.categorycodes,
                    status: checkstate.status,
                    storelabel: checkstate.storelabel,
                    categories: checkstate.categorygrp,
                    stopsell: checkstate.stopsell,
                    stock: checkstate.stock,
                },
                () => {
                    this._searchProductsMapped();
                }
            );
        }

        // this.delayedSearchCallback = debounce(function (event) {
        //     const {
        //         categorycodes,
        //         title,
        //         productcode,
        //         storeid,
        //         status,
        //         stopsell,
        //         stock,
        //         currPage,
        //         pageSize,
        //     } = this.state;

        //     const newTitle = event.title || title;
        //     const newProductcode = event.productcode || productcode;
        //     const newStock = event.stock || stock;
        //     const newStopSell = event.stopsell || stopsell;

        //     this._doSearch(
        //         categorycodes,
        //         newTitle,
        //         newProductcode,
        //         storeid,
        //         status,
        //         newStopSell,
        //         newStock,
        //         currPage,
        //         pageSize
        //     );
        // }, 500);

        this.allPermissions = permissions(this.module, this.group);
    };

    _searchProductsMapped = (event) => {
        const {
            categorycodes,
            title,
            productcode,
            storeid,
            status,
            stopsell,
            stock,
            currPage,
            pageSize,
            invallocationid,
        } = this.state;
        this._doSearch(
            invallocationid,
            categorycodes,
            title,
            productcode,
            storeid,
            status,
            stopsell,
            stock,
            currPage,
            pageSize
        );
    };

    _doSearch = (
        invallocationid,
        categorycodes,
        title,
        productcode,
        storeid,
        status,
        stopsell,
        stock,
        currPage,
        pageSize
    ) => {
        (stopsell === 0 || stopsell === '0') && (stock === 0 || stock === '0')
            ? this.props.dispatch(
                  queryInventoryByStore({
                      body: {
                        invallocationid: invallocationid,
                          categorycodes: categorycodes,
                          additionalfields: title,
                          productcode: productcode,
                          storeid: storeid,
                          status: status,
                          pageno: currPage,
                          pagesize: pageSize,
                      },
                  })
              )
            : (stopsell === 'Y' || stopsell === 'N') && stock == 0
              ? this.props.dispatch(
                    queryInventoryByStore({
                        body: {
                            invallocationid: invallocationid,
                            categorycodes: categorycodes,
                            additionalfields: title,
                            productcode: productcode,
                            storeid: storeid,
                            status: status,
                            stopsell: stopsell,
                            pageno: currPage,
                            pagesize: pageSize,
                        },
                    })
                )
              : (stock === 'Y' || stock === 'N') && stopsell == 0
                ? this.props.dispatch(
                      queryInventoryByStore({
                          body: {
                            invallocationid: invallocationid,
                              categorycodes: categorycodes,
                              additionalfields: title,
                              productcode: productcode,
                              storeid: storeid,
                              status: status,
                              stock: stock,
                              pageno: currPage,
                              pagesize: pageSize,
                          },
                      })
                  )
                : this.props.dispatch(
                      queryInventoryByStore({
                          body: {
                            invallocationid: invallocationid,
                              categorycodes: categorycodes,
                              additionalfields: title,
                              productcode: productcode,
                              storeid: storeid,
                              status: status,
                              stopsell: stopsell,
                              stock: stock,
                              pageno: currPage,
                              pagesize: pageSize,
                          },
                      })
                  );
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }

            if (prop === 'title') {
                obj['subtitle'] = obj['categorycode'];
            }
        }

        return obj;
    };
    _mapProductsToStore = (event) => {
        if (event) event.preventDefault();
        this.setState({ mapProducts: true });
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            categoryerror: '',
            numericerror: '',
        });
    };

    _validateAndSearch = (event) => {
        event.preventDefault();
        if (this.validateForm() === true) {
            this.setState({ showTable: true });
            this._searchProductsMapped(event);
        }
        return false;
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { storeid } = this.state;
        let valid = true;
        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select store id' });
            valid = false;
        }
        return valid;
    };

    validateFilterForm = () => {
        this._clearErrorMessages();
        const { storeid, minavlitems, maxavlitems } = this.state;
        let valid = true;
        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select store id' });
            valid = false;
        }
        if (validator.isNumeric(minavlitems) && maxavlitems == '') {
            this.setState({ numericerror: 'Enter value' });
            valid = false;
        }
        if (validator.isNumeric(maxavlitems) && minavlitems == '') {
            this.setState({ numericerror: 'Enter value' });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    _clearCriteria = (event) => {
        if (event) event.preventDefault();
        this._clearErrorMessages();
        this.setState({ showTable: true, storeid: '', categorycodes: '' });
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 }, () => {
            localStorage.setItem('storeid', p1);
            localStorage.setItem('storelabel', p2);
        });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _openSearchDiv = () => {
        this.setState({ showSearchDiv: true });
    };
    _closeSearchDiv = () => {
        this.setState({ showSearchDiv: false });
    };

    renderActionBtns = () => {
        return (
            <>
                {this._renderAddNewBtn()}
                <Button
                    label="Advanced Filter"
                    icon="pi pi-search"
                    className="p-button-outlined"
                    onClick={() => this._openSearchDiv()}
                />
            </>
        )
    }

    _renderAddNewBtn = () => {
        const { history } = this.props
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <Button
                label="Add Sales Inventory"
                icon="pi pi-plus"
                className="p-button-outlined mr-2"
                onClick={() => history.push('/ui/add-map-inventory')}
            />
        );
    };

    _renderInvBtn = () => {
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <>
                <button
                    type="button"
                    className="page-header-btn inv-btn icon_btn display-inline addbutton"
                    onClick={(event) => {
                        if (event) event.preventDefault();
                        this.showUpdateForm('true');
                    }}
                >
                    Inventory Transaction
                </button>
            </>
        );
    };

    _renderProductModal = () => {
        return (
            <Modal
                visible={this.state.showProdModal}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProductModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: '15em' }}>
                        <Autocomplete
                            updateTitle={this._updateProdCode}
                        ></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };

    _showProductModal = (event) => {
        this.setState({
            showProdModal: true,
        });
        if (event) event.preventDefault();
    };
    _closeProductModal = (event) => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };
    _updateProdCode = (param, param1, param2) => {
        this.setState(
            { iproductcode: param, title: param1, categorycodes: param2 },
            () => this._closeProductModal()
        );
    };

    isValid = (e) => {
        let regEx = /^-?\d*\.?\d{0,6}$/;
        let text = e.target.value;
        return text.match(regEx) !== null;
    };

    handleMin = (e) => {
        if (this.isValid(e)) {
            this.setState(
                {
                    minavlitems: e.target.value,
                },
                () => {
                    this.handleAvlitems(
                        this.state.maxavlitems,
                        this.state.minavlitems
                    );
                }
            );
        }
    };

    handleChangeMax = (e) => {
        if (this.isValid(e)) {
            if (parseInt(e.target.value) <= parseInt(this.state.minavlitems)) {
                e.target.value = '';
            }
            this.setState({
                maxavlitems: e.target.value,
            });
        }
    };

    handleAvlitems = (max, min) => {
        if (max !== '' && min !== '') {
            this.setState({
                avlitems: min.toString() + ',' + max.toString(),
            });
        } else {
            this.setState({
                avlitems: '',
            });
        }
    };

    handleMax = (e) => {
        if (this.isValid(e)) {
            this.setState(
                {
                    maxavlitems: e.target.value,
                },
                () => {
                    this.handleAvlitems(
                        this.state.maxavlitems,
                        this.state.minavlitems
                    );
                }
            );
        }
    };

    callQueryApiForFetch = () => {
        const {
            avlitems,
            title,
            storeid,
            stock,
            stopsell,
            categorycodes,
            iproductcode,
            status,
            barcode,
            sku,
        } = this.state;
        this.props.dispatch(
            queryInventoryByStore({
                body: {
                    categorycodes,
                    additionalfields: title,
                    productcode: iproductcode,
                    storeid: storeid,
                    status: status,
                    stopsell: stopsell,
                    stock: stock,
                    barcode,
                    sku,
                    avlitems,
                },
            })
        );
        stopsell == 0 && stock == 0
            ? this.props.dispatch(
                  queryInventoryByStore({
                      body: {
                          categorycodes,
                          productcode: iproductcode,
                          storeid: storeid,
                          status: status,
                          barcode,
                          sku,
                          avlitems,
                      },
                  })
              )
            : (stopsell == 'Y' || stopsell == 'N') && stock == 0
              ? this.props.dispatch(
                    queryInventoryByStore({
                        body: {
                            categorycodes: categorycodes,
                            productcode: iproductcode,
                            storeid,
                            status,
                            stopsell,
                            stock,
                            barcode,
                            sku,
                            avlitems,
                        },
                    })
                )
              : (stock == 'Y' || stock == 'N') && stopsell == 0
                ? this.props.dispatch(
                      queryInventoryByStore({
                          body: {
                              categorycodes,
                              storeid,
                              status,
                              stopsell,
                              stock,
                              barcode,
                              sku,
                              avlitems,
                          },
                      })
                  )
                : this.props.dispatch(
                      queryInventoryByStore({
                          body: {
                              categorycodes,
                              storeid,
                              status,
                              stopsell,
                              stock,
                              avlitems,
                          },
                      })
                  );
        this._renderMainForm();
    };

    _renderSearchModal = () => {
        return (
            <Modal
                visible={this.state.showSearchDiv}
                width="80%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeSearchDiv()}
            >
                <div className="modalForm queryFormModal">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            Inventory Search
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this._closeSearchDiv}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {this._renderSearchForm()}
                </div>
            </Modal>
        );
    };

    _renderSearchForm = () => {
        const { categoryTree } = this.props.queryData;
        const newArrayOfObj = [categoryTree].map(this.rename);
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft required">
                            Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="avlitems" className="floatLeft">
                            Available Items
                        </label>
                        <div className="row">
                            <div className="col-lg-5 col-sm-5 noPadding noMargin">
                                <input
                                    type="text"
                                    placeholder="min"
                                    className="form-control"
                                    id="minavlitems"
                                    value={this.state.minavlitems}
                                    onChange={this.handleMin}
                                />
                            </div>
                            <div className="col-lg-1 col-sm-1 alignCenter noPadding noMargin">
                                <b>-</b>
                            </div>
                            <div className="col-lg-6 col-sm-6 noPadding noMargin">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="max"
                                    id="maxavlitems"
                                    value={this.state.maxavlitems}
                                    onChange={this.handleMax}
                                />
                            </div>
                        </div>
                        <div className="invalid-feedback">
                            {this.state.numericerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="categorycodes" className="floatLeft">
                            Select Product Category
                        </label>
                        <CategoryTree
                            treeData={newArrayOfObj}
                            categoryTree={categoryTree}
                            setCategoryCode={(code, title) => {
                                this.setState({
                                    categorycodes: code,
                                    categories: title,
                                });
                            }}
                            value={this.state.categories}
                        ></CategoryTree>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="productcode" className="floatLeft">
                            Product Code
                        </label>
                        <input
                            type="text"
                            value={this.state.iproductcode}
                            onChange={(event) =>
                                this.setState({
                                    iproductcode: event.target.value,
                                })
                            }
                            id="productcode"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="stopsell" className="floatLeft">
                            Products Not In Sale
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={(value) =>
                                this.setState({ stopsell: value })
                            }
                            selectedOption={this.state.stopsell}
                            id="stopsell"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12 ">
                        <label htmlFor="stock" className="floatLeft">
                            Products In Stock
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={(value) =>
                                this.setState({ stock: value })
                            }
                            selectedOption={this.state.stock}
                            id="stock"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Barcode
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="barcode"
                            value={this.state.barcode}
                            onChange={(event) =>
                                this.setState({ barcode: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="sku" className="floatLeft">
                            SKU
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sku"
                            value={this.state.sku}
                            onChange={(event) =>
                                this.setState({ sku: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="button"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                if (this.validateFilterForm() === true) {
                                    this.callQueryApiForFetch();
                                    this._closeSearchDiv();
                                }
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this._closeSearchDiv();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </>
        );
    };

    _handleTitleKeyUp = (event) => {
        const value = { title: event.target.value };
        event.persist();
        this.setState(value);
    };

    _handleProductCodeKeyUp = (event) => {
        const value = { productcode: event.target.value };
        event.persist();
        this.setState(value);
    };

    _updateStatus = (value, event) => {
        const _value = { status: value };
        event.persist();
        this.setState(_value);
    };

    _updateCategory = (code, title) => {
        const _value = { categorycodes: code, categories: title };
        this.setState(_value);
    };

    _updateProductInStocks = (value, event) => {
        const _value = { stock: value };
        event.persist();
        this.setState(_value);
    };

    _updateProductStopSell = (value, event) => {
        const _value = { stopsell: value };
        event.persist();
        this.setState(_value);
    };

    _renderCriteria = () => {
        const { categoryTree } = this.props.queryData;
        const newArrayOfObj = [categoryTree].map(this.rename);
        return (
            <>
                {this.state.showSearchDiv && this._renderSearchModal()}
                <div className="row p-fluid formgrid grid">
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group field col-3 md:col-3">
                        <label htmlFor="status" className="floatLeft">
                            Select Status
                        </label>
                        <StatusComp
                            allowBlank={true}
                            selectedOption={this.state.status}
                            callbackChangeFilter={this._updateStatus}
                        />
                    </div>
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="categorycodes" className="floatLeft">
                            Select Product Category
                        </label>
                        <CategoryTree
                            treeData={newArrayOfObj}
                            categoryTree={categoryTree}
                            setCategoryCode={this._updateCategory}
                            value={this.state.categories}
                        />
                    </div>
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="stopsell" className="floatLeft">
                            Products Not In Sale
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={this._updateProductStopSell}
                            selectedOption={this.state.stopsell}
                            id="stopsell"
                        />
                    </div>
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className={'form-control'}
                            id="title"
                            value={this.state.title}
                            onChange={this._handleTitleKeyUp}
                        />
                    </div>
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="title" className="floatLeft">
                            Product Code
                        </label>
                        <input
                            type="text"
                            className={'form-control'}
                            id="productcode"
                            value={this.state.productcode}
                            onChange={this._handleProductCodeKeyUp}
                        />
                    </div>
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="title" className="floatLeft">
                            Inventory Allocation Id
                        </label>
                        <input
                            type="text"
                            className={'form-control'}
                            id="invallocationid"
                            value={this.state.invallocationid}
                            onChange={(e) =>
                                this.setState({
                                    invallocationid: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="stock" className="floatLeft">
                            Products In Stock
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={this._updateProductInStocks}
                            selectedOption={this.state.stock}
                            id="stock"
                        />
                    </div>
                    <div className="form-group col-lg-1 col-sm-12">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                            onClick={this._validateAndSearch}
                            className="searchBtn"
                        />
                    </div>
                </div>
            </>
        );
    };

    showStock = (value, row) => {
        if (row) {
            this.setState(
                { newprodcode: row.productcode, prodData: row },
                () => {
                    if (row) {
                        this.setState({ showStockForm: value });
                    }
                }
            );
        } else {
            this.setState({ showStockForm: value });
        }
    };

    hideStock = () => {
        this.setState({
            showStockForm: 'false',
            showSecForm: 'false',
            isView: false,
        });
    };
    
    showUpdateForm = (value, row, isView) => {
        if (row) {
            this.setState(
                { newprodcode: row.productcode, prodData: row },
                () => {
                    if (row) {
                        this.setState({ showSecForm: value, isView: isView });
                    }
                }
            );
        } else {
            this.setState({ showSecForm: value, isView: isView });
        }
    };

    _update = (row) => {
        this.props.dispatch(
            updateItem(
                'sales/v1/store/inventory/update',
                {
                    body: {
                        id: row.id,
                        storeid: row.storeid,
                        productcode: row.productcode,
                        stopsell: row.stopsell,
                        stock: row.stock,
                        avlintems: row.avlintems,
                        status: row.status,
                        additionalfields:
                            row.additionalfields,
                    },
                },
                this.state.id
            )
        );
        this.toast.show({severity:'success', summary: '', detail:`Inventory updated successfully`, life: 5000, position: "top-left"});
    };

    _renderTable = () => {
        const { invData } = this.props.queryData;
        return (
            <div>
                <SimpleTable
                    columns={this.state.tableCols}
                    rows={invData}
                    pageChangeCallback={(currPage) => {
                        this.setState(
                            {
                                currPage: currPage,
                            },
                            this._searchProductsMapped
                        );
                    }}
                    callback={(pageSize) => {
                        this.setState(
                            produce(this.state, (draft) => {
                                if (draft.pageSize < pageSize) {
                                    draft.currPage = 1;
                                }
                                draft.pageSize = pageSize;
                            }),
                            this._searchProductsMapped
                        );
                    }}
                    withoutSearchBar={true}
                    withPageSize={this.state.pageSize}
                    withCurrPage={this.state.currPage}
                    withPageFeedback={true}
                />
            </div>
        );
    };

    
    _renderMainForm = () => {
        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-6">
                            <h2 className="page-header">
                                Inventory Management
                            </h2>
                        </div>
                        <div className="col-6 alignRight alignWithPageHeader">
                            {this.renderActionBtns()}
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            <form
                                className="splitFrmDiv"
                                onSubmit={this._validateAndSearch}
                            >
                                <input type="submit" className="d-none"></input>
                                {this._renderCriteria()}
                                <div
                                    style={{ height: '2em' }}
                                    className="title_devide"
                                ></div>
                                {this._renderTable()}
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const {
            showSecForm,
            isView,
            showStockForm,
            storeid,
            storelabel,
            newprodcode,
            status,
            categorycodes,
            categories,
            stopsell,
            stock,
        } = this.state;
        const { isSuccess, errormsg: crudError, error } = this.props.crudData;
        const { errormsg: queryError, servererror: serverError } =
            this.props.queryData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        // if (this.props.queryData.apiPending) {
        //   return <Loading />;
        // }
        return (
            <>
                <Toast ref={(el) => this.toast = el} />
                {showSecForm === 'true' ? (
                    <UpdateValues
                        data={this.state.prodData}
                        newprodcode={this.state.newprodcode}
                        updateForm={this.showUpdateForm}
                        fields={{
                            storeid,
                            categorycodes,
                            storelabel,
                            status,
                            categories,
                            stopsell,
                            stock,
                        }}
                        isView={isView}
                    ></UpdateValues>
                ) : showStockForm === 'true' ? (
                    <AddStock
                        updateStock={this.hideStock}
                        propDetails={{
                            storeid: storeid,
                            storelabel: storelabel,
                            productcode: newprodcode,
                            status: status,
                            fields: {
                                storeid,
                                categorycodes,
                                storelabel,
                                status,
                                categories,
                                stopsell,
                                stock,
                            },
                        }}
                        isView={isView}
                    />
                ) : (
                    this._renderMainForm()
                )}
                <ConfirmAlert
                    show={errormsg}
                    handleClose={(event) =>
                        this.hideErrorAlertModal(event, refresh)
                    }
                    children={
                        <div
                            style={
                                errormsg
                                    ? {
                                          padding: '2em',
                                          color: 'red',
                                          fontWeight: '500',
                                      }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {errormsg ? `${errormsg}.` : ''}
                        </div>
                    }
                />
                {this.state.showProdModal && this._renderProductModal()}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(InventoryList);
