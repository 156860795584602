import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    crudReset,
    resetAction,
    searchGeneral,
    createShipping,
    updateShipping,
    queryInventoryByStore
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleSelect from "../../../components/SimpleSelect";
import { produce } from "immer";
import { objtoarr, renderOptionsFilter, renderConfigInMultiSelect } from "../../../helpers/Util";
import SimpleExpandTable from "../../../components/SimpleExpandTable";
import StatusOptionComp from "../../../components/StatusOptionComp";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { Type } from "react-bootstrap-table2-editor";

class AddShippingItem extends React.PureComponent {
  statusShip = [
    { name: "Select", id: "" },
    { name: "Shipping", id: "Shipping" },
    { name: "Shipped", id: "Shipped" },
    { name: "Received", id: "Received" },
    { name: "Delivered", id: "Delivered" },
    { name: "Completed", id: "Completed" },
    { name: "Cancel", id: "Cancel" }
  ];

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            // Formrelated
            productcode: "",
            expanded: "",
            selected1: [],
            selected2: [],
            selectedrow1: [],
            selectedrow2: [],
            items: [],
            orderid: "",
            deliverytaskcode: "",
            shipfrom_contactname: "",
            shipfrom_contactno: "",
            shipfrom_address: "",
            shipfrom_areacode: "",
            shipfrom_building: "",
            shipfrom_city: "",
            shipfrom_country: "",
            shipfrom_state: "",
            shipfrom_town: "",
            shipfrom_postalcode: "",
            shipfrom_remarks: "",
            shipto_contactname: "",
            shipto_contactno: "",
            shipto_address: "",
            shipto_areacode: "",
            shipto_building: "",
            shipto_city: "",
            shipto_country: "",
            shipto_state: "",
            shipto_town: "",
            shipto_postalcode: "",
            shipto_remarks: "",
            width: "",
            height: "",
            weight: "",
            isinsured: "",
            currency: "",
            amount: "",
            courier: "",
            trackingno: "",
            trackingurl: "",
            sms: "",
            notification: "",
            email: "Y",   
            invlist: [],
            itemlist: [],
            shippingstatus: "",    
            remark: "", 
            ordershippedon: new Date(),
            orderdeliveredon: new Date(),
            ordercompletedon: new Date(),   
            cancelledon: new Date(),
            itemheaders: [
                {
                  dataField: "productcode",
                  text: "Product Name",
                  sort: true,
                  editable: false,
                  formatter: this.showProdDesc
                },
                {
                  dataField: "images",
                  text: "Images",
                  formatter: this.ImgFormatter,
                  editable: false,
                  sort: true
                },    
                {
                  dataField: "shippingqty",
                  text: "Shipping Qty",
                  editable: true,
                  formatter: this.showShipQty,
                  editor: {
                    type: Type.TEXTBOX
                  }
                },
                {
                  dataField: "quantity",
                  text: "Ordered Qty",
                  editable: false,
                  sort: true
                },                       
                {
                  dataField: "giftmsg",
                  text: "Gift Msg",
                  sort: true,
                  editable: false,
                  formatter: (cellContent, row) => {
                    return (
                      <textarea
                        className="form-control"
                        id="giftmsg"
                        style={{width:"100px", height: "100px"}}
                        defaultValue={row.giftmsg}
                        readOnly={true}
                      />
                    );
                  }
                },
                {
                  dataField: "unitprice",
                  text: "Unit Price",
                  editable: false,
                  sort: true,
                  formatter: this.showUnitPrice
                },
                {
                  dataField: "totalamount",
                  text: "Total Amt",
                  editable: false,
                  sort: true,
                  formatter: this.showAmount
                },
                {
                  dataField: "deliverystatus",
                  text: "Shipping Status",
                  editable: false,
                  sort: true,
                }
              ],
            invheaders: [
              {
                dataField: "storeid",
                text: "Store",
                editable: false,
                formatter: this.showStore
              }, 
              {
                dataField: "productmodel",
                editable: false,
                text: "Model"
              },      
              {
                dataField: "qty",
                text: "Shipping Qty",
                editable: true,
                formatter: this.showInvQty
              },     
              {
                dataField: "qtystock",
                text: "Qty in Stock",
                editable: false
              },           
            ],

            //errors
            items_error: "",
            addressfrom_error: "",
            addressto_error: "",
            contactnamefrom_error: "",
            contactnameto_error: "",
            contactnofrom_error: "",
            contactnoto_error: "",
            countryfrom_error: "",
            countryto_error: "",
            cityfrom_error: "",
            cityto_error: "",
            postalcodefrom_error: "",
            postalcodeto_error: "",
            formError: false,
            formErrorMsg: ""
        };
    }


    handleItemList = (row) => {
      const { invlist } = this.state;
      if(invlist.length > 0){
        const inprow = [row];
        const updaterow = invlist.map(item1 => inprow.find(item2 => item1.id === item2.id) || item1);
        this.setState({
          invlist: updaterow
        },()=>{this.getList()})
      }
      else{
        this.getList();
      }
    }

    showAmount = (cell, row, rowIndex, formatExtraData) => {
      return  row.currency+" "+row.totalamount;
    }

      showUnitPrice = (cell, row, rowIndex, formatExtraData) => {
        if(row.totalamount !== ""){
          return (
            <div>
              {row.currency+" "+row.unitprice}
            </div>
          )
        }
        else {
          return ""
        }
      }

    showShipQty = (cell, row, rowIndex, formatExtraData) => {
      return (
        <div>
          <input
            type="number"
            className="form-control"
            id="shippingqty"
            defaultValue={
              row.shippingqty === null && typeof shippingqty === "undefined" ?                             
              row.shippingqty=parseInt(row.quantity) : 
              row.shippingqty !== "" && row.hasOwnProperty("shippingqty") == true ? 
              row.shippingqty=parseInt(row.shippingqty) :
              row.shippingqty=parseInt(row.quantity)}
            min="0"
            style={{ borderRadius: "8px", width: "6em" }}
            readOnly={true}
          />
        </div>
      );
    }

    showInvQty = (cell, row, rowIndex, formatExtraData) => {
      if(row.qtystock <= 0){
         return (
          <div>
            {row.qty}
          </div>
         );
      }
      else {
        return (
          <input
            type="number"
            className="form-control"
            id="shippingqty"
            defaultValue={
              row.qty !== null && row.qty !== "" && row.hasOwnProperty("qty") == true ?
                row.qty = parseInt(row.qty) : row.qty = parseInt(row.qtystock)}
            min="0"
            style={{ borderRadius: "8px", width: "6em" }}
            onChange={event => {
              row.qty = parseInt(event.target.value);
              this.handleItemList(row)
            }}
          />
        );
      }
      
    }

    showStore = (cell, row, rowIndex, formatExtraData) => {
      const strid = row.storeid;
      const { dataByCountry } = this.props.queryData;
      if(dataByCountry.length > 0){
        const filterstr = dataByCountry.filter(e=>e.storeid===strid);
        if (filterstr.length > 0) {
          const strname = filterstr[0].title;
          return strname;
        }
        else {
          return
        }
      }
    }

    showProdDesc = (cell, row, rowIndex, formatExtraData) => {
      if(JSON.stringify("row") !== "{}"){
        return(
          <div>
            <div className="row">
              <div className="col noPadding noMargin">
                {row.productcode + "-" + row.description}
                <div style={{ color: "#6d6d6d", fontSize: "12px" }}>{"[" + row.itemsize + "/" + row.itemcolor + "]"}</div>
              </div>
            </div>
          </div> 
        )
      }
      else {
        return ""
      }
    }

    ImgFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (row.images !== "") {
       if(row.itemcolor !== "" || row.itemsize !== ""){
          return (
          <div>
            <img
              className="img-responsive w-50"
              src={`${localStorage.getItem("imagepath")}` + row["images"]}
              alt="Card image cap" />
          </div>
          );
        }
      }
    }  
  
    combineArrays =(array1, array2) =>{
      const { selected2, items } = this.state;
      if (array1.length > 0 && array2.length) {
        //console.log(array1, array2);
        let combinelist = array1.map(array1Item => {
          return {
            ...array1Item,
            inventory: (typeof inventory !== "undefined" ? array1Item.inventory.filter(e => e.hasOwnProperty("storeinvid") && e.hasOwnProperty("storeid")) : array1Item.inventory || []).concat(array2
            .filter(array2Item => array2Item.productcode === array1Item.productcode)
              .map(array2Item => ({ storeid: array2Item.storeid.toString(), storeinvid: array2Item.id, qty: parseInt(array2Item.qty) || 0 })))
          }
        });
        // remove duplicates and apply sum of inv qty to shippingqty
        var result = combinelist.reduce((acc, elem) => {
          elem.inventory = [...new Map(elem.inventory.map(i => [i.storeinvid, i])).values()];
          elem.shippingqty = elem.inventory.filter(i => selected2.includes(i.storeinvid)).reduce((sum, { qty }) => sum + qty, 0);
          acc = [...acc, elem];
          return acc;
        }, []);
        let uniquelist = [...new Map(result.map(item => [item["idx"], item])).values()];
        return uniquelist;
      }
      else {
        return items;
      }
    }

    // add inventory to that item 
    setItemList = () =>{
      const { selectedrow1, selectedrow2, selected1, selected2, items, invlist } = this.state;
      const getobj = this.combineArrays(selectedrow1, selectedrow2); //add inventory
      const selectedlist = items.map(item1 => getobj.find(item2 => item1.idx === item2.idx) || item1);
      const finallist = selectedlist.map(e => ({
        ...e,
        shippingqty: selected1.includes(e.idx) ? parseInt(e.shippingqty) : parseInt(0),
        inventory: selected1.includes(e.idx) ? e.inventory.filter(i=>selected2.includes(i.storeinvid)) : []
      }));
      if (finallist.length > 0) {
        this.setState({
          items: finallist
        },()=>{this.showInvCheckedRows(finallist)})
      }
      else {
        this.setState({
          items: items
        })
      }
    }

    updateItemSelection = () => {
      const { items } = this.state;
      let updateitemlist = items.map(e=>({
        ...e,
        inventory: []
      }))
      this.showInvCheckedRows(updateitemlist);
      this.setState({
        items: updateitemlist
      })
    }

    //get item selection and inv selection
    itemselectionList = (list, invenlist) => {
      const { selectedrow1, selected1, selectedrow2,selected2, items } = this.state;
      const getitemrow = list.filter(e => selected1.includes(e.idx));
      const getinvrow = invenlist.filter(e => selected2.includes(e.id));
      if(getinvrow.length === 0 || getitemrow === 0){
        this.updateItemSelection();
      }
      if(getinvrow.length > 0) {
        let select2 = [...selectedrow2,...getinvrow];
        let uniqueselect2 =[...new Map(select2.map(item => [item["id"], item])).values()];
        this.setState({
          selectedrow2: uniqueselect2
        },()=>{ this.setItemList()})
      }
      if(getitemrow.length > 0 ){
        let select1 = [...selectedrow1,...getitemrow];
        let uniqueselect1 = [...new Map(select1.map(item => [item["idx"], item])).values()];
        this.setState({
          selectedrow1: uniqueselect1
        },()=>{ this.setItemList()})
      }
    }

  getList = () => {
    const { invlist, items } = this.state;
    if (this._isEditMode()) {
       this.itemselectionList(items, invlist);      
    }
    else {
      this.itemselectionList(items, invlist);  
    }   
  }

     componentDidMount = () => {
      this.props.dispatch(crudReset());
      this.props.dispatch(resetAction());
      // this.props.dispatch(
      //   searchGeneral(
      //     "/sales/v1/store/search/fields",
      //     {
      //       body: {
      //         groupid: localStorage.getItem("storegroup_access")
      //       }
      //     },
      //     "SEARCH_CITY_BY_COUNTRY_SUCCESS",
      //     "SEARCH_CITY_BY_COUNTRY_FAILURE"
      //   )
      // );
    }

  updateInventory = (obj1, obj2) => {
    const arr1 = obj1.reduce((res, group) => [ ...res, ...group.inventory], []);
    const result = obj2.map(e => {
      const match = arr1.find(x => x.storeinvid === e.id);
      return { ...e, qty: match ? match.qty : 0 };
   });
   return result;   
  }

  handleInvRowList = (list1, list2) => {
    const checkinv = list1.filter(elm => elm.hasOwnProperty("inventory"));
    if (checkinv.length > 0) {
      const newinvlist = this.updateInventory(list1, list2);
      this.setState({
        invlist: newinvlist
      })
    }
    else {
      this.setState({
        invlist: list2
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { invData } = this.props.queryData;
    const { selected1, selected2, selectedrow2, selectedrow1, items, invlist, expanded} = this.state;
    const { invData: previnvData } = prevProps.queryData;
    const { deliverydata} = this.props
  
    if (invData !== previnvData) {
      const newlist =
        invData.reduce((acc, curr) => {
          if (curr.hasOwnProperty("stockinfo")) {
            curr.stockinfo.forEach(x => {
              return (
                x.storeid = curr.storeid,
                x.id = curr.id,
                x.productcode = curr.productcode
                //x.key = curr.id + "-" + curr.storeid + "-" + x.productmodel
              );
            })
          }
          return [...acc, { ...curr }]
        }, []);
      if (newlist.length > 0) {
        const prodinv = newlist.map(e => {
          if (e.hasOwnProperty("stockinfo")) {
            return e.stockinfo
          }
        }).flat().filter(e => typeof e !== "undefined");
        if (this._isEditMode()) {
            if(items.length > 0){              
            let expandedrow = items.filter(i=>i.idx==expanded);
            const invbyprmodel =  prodinv.filter(o1 => expandedrow.some(o2 => o1.productmodel === [o2.itemcolor, o2.itemsize].join('-')));
            this.handleInvRowList(items, invbyprmodel);           
          }
        }
        else {
          if(items.length > 0){           
            let expandedrow = items.filter(i=>i.idx==expanded);
            const invbyprmodel = prodinv.filter(o1 => expandedrow.some(o2 => o1.productmodel === [o2.itemcolor, o2.itemsize].join('-')));
            this.handleInvRowList(items, invbyprmodel);     
          }
        }      
      }
    }    
  }


   componentWillMount = () => {
      if(this.props.selectedrow){
        const rec = this.props.selectedrow.item;
        const isEdit = this.props.selectedrow.isEdit;

        if(isEdit === false){
          const { deliverydata } = this.props;
          const rec1 = this.props.deliverydata.deliveryinfo;
          let newitemlist = [];
          if(deliverydata.items.length > 0){
            newitemlist = deliverydata.items.map(e=>({
              ...e,
              inventory:[]
            }))
          }
          this.setState(
            produce(draft=> {
              draft.items = deliverydata.items ? newitemlist : [] ;
              draft.deliverytaskcode = deliverydata.taskcode ? deliverydata.taskcode : "";
              draft.orderid = deliverydata.orderid ? deliverydata.orderid : "";
              draft.shipto_address = rec1 ? rec1.address : "";
              draft.shipto_contactname = rec1 ? rec1.contactname : "";
              draft.shipto_contactno = rec1 ? rec1.contactno : "";
              draft.shipto_state = rec1 ? rec1.state : "";
              draft.shipto_country = rec1 ? rec1.country : "";
              draft.shipto_city = rec1 ? rec1.city : "";
              draft.shipto_town = rec1 ? rec1.town : "";
              draft.shipto_building = rec1 ? rec1.building : "";
              draft.shipto_areacode = rec1 ? rec1.areacode: "";
              draft.shipto_postalcode = rec1 ? rec1.postalcode : "";
              draft.shipto_remarks = rec1 ? rec1.remark : "";
            })
          )
        }
        if (rec) {
          const {
              id,
              deliverytaskcode,
              orderid,
              shipfrom,
              shipto,
              shippackage,
              customdeclaration,
              shippingby,
              notification,
              items,
              shippingstatus,
              orderdeliveredon,
              ordershippedon,
              ordercompletedon,
              cancelledon,
              remark
          } = rec;
          this.showItemCheckedRows(items);
          this.showInvCheckedRows(items);
          this.setState(
              produce(draft => {
                  draft.id = id ? id : "";
                  draft.isEdit = isEdit;
                  draft.items = this.props.selectedrow.item ? 
                  this.props.selectedrow.item.items : [];
                  draft.orderid = rec.orderid ? rec.orderid : "";
                  draft.deliverytaskcode = this.props.deliverydata ? this.props.deliverydata.taskcode : "";
                  draft.shipfrom_address = shipfrom ? shipfrom.address : "";
                  draft.shipfrom_contactname = shipfrom ? shipfrom.contactname : "";
                  draft.shipfrom_contactno = shipfrom ? shipfrom.contactno : "";
                  draft.shipfrom_state = shipfrom ? shipfrom.state : "";
                  draft.shipfrom_country = shipfrom ? shipfrom.country : "";
                  draft.shipfrom_city = shipfrom ? shipfrom.city : "";
                  draft.shipfrom_town = shipfrom ? shipfrom.town : "";
                  draft.shipfrom_building = shipfrom ? shipfrom.building : "";
                  draft.shipfrom_areacode = shipfrom ? shipfrom.areacode: "";
                  draft.shipfrom_postalcode = shipfrom ? shipfrom.postalcode : "";
                  draft.shipfrom_remarks = shipfrom ? shipfrom.remark : ""

                  draft.shipto_address = shipto ? shipto.address : "";
                  draft.shipto_contactname = shipto ? shipto.contactname : "";
                  draft.shipto_contactno = shipto ? shipto.contactno : "";
                  draft.shipto_state = shipto ? shipto.state : "";
                  draft.shipto_country = shipto ? shipto.country : "";
                  draft.shipto_city = shipto ? shipto.city : "";
                  draft.shipto_town = shipto ? shipto.town : "";
                  draft.shipto_building = shipto ? shipto.building : "";
                  draft.shipto_areacode = shipto ? shipto.areacode: "";
                  draft.shipto_postalcode = shipto ? shipto.postalcode : "";
                  draft.shipto_remarks = shipto ? shipto.remark : "";

                  draft.weight = shippackage ? shippackage.weight : "";
                  draft.height = shippackage ? shippackage.height : "";
                  draft.width = shippackage ? shippackage.width : "";

                  draft.isinsured = customdeclaration ? customdeclaration.isinsured : "";
                  draft.curreny = customdeclaration ? customdeclaration.curreny : "";
                  draft.amount = customdeclaration ? customdeclaration.amount : "";

                  draft.courier  = shippingby ? shippingby.courier : "";
                  draft.trackingurl = shippingby ? shippingby.trackingurl : "";
                  draft.trackingno = shippingby ? shippingby.trackingno : "";

                  draft.notification = notification ? notification.notification : "";
                  draft.sms = notification ? notification.sms : "";
                  draft.email = notification ? notification.email : "";
                  draft.shippingstatus = shippingstatus ?  shippingstatus : "";

                  //draft.selected1 = items.length > 0 ? itemselection: [];
                  //draft.selected2 = items.length > 0 ? invselection : [];
                  //draft.items = items.length > 0 ? items : [];

                  draft.remark = remark ? remark : "";

                  draft.ordershippedon = ordershippedon ? this.toDate(ordershippedon) : "";
                  draft.orderdeliveredon = orderdeliveredon ? this.toDate(orderdeliveredon) : "";
                  draft.ordercompletedon = ordercompletedon ? this.toDate(ordercompletedon) : "";
                  draft.cancelledon = cancelledon ? this.toDate(cancelledon): "";
              })
          );
      }
      }
    }

    showItemCheckedRows = (items) => {
      let checkrow1 = [];        
      if(items.length > 0 ){
        checkrow1 = items.map(e=>e.idx);
        this.setState({
          selected1: checkrow1
        })
      }
      else{
        this.setState({
          selected1: []
        })
      }
    }

    showInvCheckedRows = (items) => {
       let checkrow2 = [];
       if(items.length > 0 ){
        checkrow2 = items.map(e=>e.inventory).flat().map(i=>typeof i !== "undefined" ? i.storeinvid : []);
        this.setState({
          selected2: checkrow2
        })
      }
      else {
        this.setState({
          selected2: []
        })
      }
    }

    formatDate = inpDate => {
      if(inpDate !== "" && typeof inpDate !== "undefined") {
        return moment(inpDate).format("DD-MM-YYYY HH:mm");
      }
    };
   
    toDate = datestr  => {
      if(datestr !== ""){
        return moment(datestr, 'DD-MM-YYYY HH:mm');
      }
    }

    _renderErrorMessage = () => {
        const { isSuccess, errorMessage } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errorMessage && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    getCreatePayload = () => {
        const {
          deliverytaskcode,
          orderid,
          shipfrom_address,
          shipfrom_contactname,
          shipfrom_contactno,
          shipfrom_building,
          shipfrom_areacode,
          shipfrom_town,
          shipfrom_country,
          shipfrom_city,
          shipfrom_state,
          shipfrom_postalcode,
          shipfrom_remarks,
          shipto_address,
          shipto_contactname,
          shipto_contactno,
          shipto_areacode,
          shipto_building,
          shipto_town,
          shipto_country,
          shipto_city,
          shipto_remarks,
          shipto_postalcode,
          shipto_state,
          width,
          height,
          weight,
          isinsured,
          curreny,
          amount,
          courier,
          trackingno,
          trackingurl,
          sms,
          email,
          notification,
          items,
          shippingstatus,
          ordershippedon,
          ordercompletedon,
          orderdeliveredon,
          cancelledon,
          remark   
        } = this.state;
        let body = {
            deliverytaskcode,
            orderid,
            shipfrom: {
              contactname: shipfrom_contactname,
              contactno: shipfrom_contactno,
              address: shipfrom_address,
              state: shipfrom_state,
              country: shipfrom_country,
              city: shipfrom_city,
              town: shipfrom_town,
              building: shipfrom_building,
              postalcode: shipfrom_postalcode,
              areacode: shipfrom_areacode,
              remark: shipfrom_remarks
            },
            shipto: {
              contactname: shipto_contactname,
              contactno: shipto_contactno,
              address: shipto_address,
              state: shipto_state,
              country: shipto_country,
              city: shipto_city,
              town: shipto_town,
              building: shipto_building,
              postalcode: shipto_postalcode,
              areacode: shipto_areacode,
              remark: shipto_remarks
            },
            shippackage:{
              width,
              height,
              weight
            },
            customdeclaration: {
              isinsured,
              curreny,
              amount
            },
            shippingby: {
              courier,
              trackingno,
              trackingurl
            },
            notification: {
              email,
              sms,
              notification
            },
            items,
            shippingstatus,
            remark,
            ordershippedon: this.formatDate(ordershippedon),
            orderdeliveredon: this.formatDate(orderdeliveredon),
            ordercompletedon: this.formatDate(ordercompletedon),
            cancelledon: this.formatDate(cancelledon),
            orderreceivedon: ""
        };
        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body
        };
        return payload;
    };

    handleOnExpand = (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        this.setState({
          expanded: row.idx,
          productcode: row.productcode
        },()=>{
          this.props.dispatch(queryInventoryByStore({body: {status: "Active", stock: "Y", productcode: row.productcode}}));
        });
      } else {
        this.setState(() => ({
          expanded: ""
        }));
      }
    }

    _createShippingService = () => {
      const { orderid } = this.state;
       if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateShipping(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createShipping(this.getCreatePayload()));
            }           
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Please clear all the errors to continue !!!"
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
          items_error: "",
          addressfrom_error: "",
          addressto_error: "",
          contactnamefrom_error: "",
          contactnameto_error: "",
          contactnofrom_error: "",
          contactnoto_error: "",
          countryfrom_error: "",
          countryto_error: "",
          cityfrom_error: "",
          cityto_error: "",
          postalcodefrom_error: "",
          postalcodeto_error: "",
          formError: false,
          formErrorMsg: ""
        });
    };


    
    validateForm = () => {
        this._clearErrorMessages();
        const {
            shipfrom_contactname,
            shipfrom_contactno,
            shipfrom_address,
            shipfrom_country,
            shipfrom_city,
            shipfrom_postalcode,
            shipto_contactname,
            shipto_contactno,
            shipto_address,
            shipto_country,
            shipto_city,
            shipto_postalcode,
            selected1,
            selected2,
            selectedrow1,
            selectedrow2,
            items
        } = this.state;
        const { invlist } = this.state;
        let valid = true;
       // console.log(items, selectedrow1, selectedrow2, selected1, selected2)
        if(this._isEditMode()===false){
          if (invlist.length > 0){
            // error if inventory ship qty greater than quantity
            let checkqty2 = invlist.filter(e=>e.qty !==0 && e.qty > e.qtystock);
            let getprcode2 = checkqty2.length > 0 ? [...new Set(checkqty2.map(i=>i.productcode))] : "";
            if(checkqty2.length > 0){
              this.setState({
                items_error: "Shipping Qty exceeds the available Qty "+getprcode2
              });
              valid = false;
            }
          }
        }
        


        if (items.length > 0){
          items.forEach(e=>{
            // inventory selection is missed out
            if(typeof e.inventory === "undefined"){
              this.setState({
                items_error: "Please select inventory for productcode "+ e.productcode.toString()
              });
              valid = false;
            }

            let checkitem = items.filter(e=>selected1.includes(e.idx));
            if(checkitem.length > 0){
              let checkinvt = checkitem.filter(i=>i.inventory.length === 0);
              if(checkinvt.length > 0){
               let invpr = checkinvt.length > 0 ? [...new Set(checkinvt.map(i=>i.productcode))] : "";
               this.setState({
                items_error: "Please select inventory for productcodes "+ invpr
               });
                valid = false;
              }
            }

            if(e.shippingqty > e.quantity){
              this.setState({
                items_error: "Item Shipping Qty exceeds the available Qty "+ e.productcode 
              });
              valid = false;
            }

            if(typeof inventory !== "undefined" && e.inventory.length > 0){
              // error if ship qty greater than quantity
              let checkqty1 = items.filter(e=>e.shippingqty > e.quantity);
              let checkqty2 = selectedrow2.filter(e=>e.qty!==0 && e.qty > e.qtystock);
              let getprcode1 = checkqty1.length > 0 ? [...new Set(checkqty1.map(i=>i.productcode))] : "";
             
              if(checkqty1.length > 0){
                this.setState({
                  items_error: "Item Shipping Qty exceeds the available Qty "+getprcode1
                });
                valid = false;
              }
 
              // error if inventory sum of qty  not equal to item ship qty
              if(checkqty1.length === 0 && checkqty2.length === 0){
                var checkqty = items.filter(k=>k.shippingqty!=k.inventory.reduce((a, {qty})=>a+qty,0));
                let getprcode = checkqty.length > 0 ? [...new Set(checkqty.map(i=>i.productcode))] : "";
                if(checkqty.length > 0){
                  this.setState({
                    items_error: "Shipping Qty not same as sum of Store Qty "+getprcode
                  })
                  valid = false;
                }   
              }
            }
          })
        } 
        
   
        if (selected1.length === 0 || selected2 === 0) {
          this.setState({
            items_error: "Please select items and inventory"
          });
          valid = false;
        }
        // if (shipfrom_contactname.trim() === "") {
        //   this.setState({
        //       contactnamefrom_error: "Please enter contact name"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_contactno.trim() === "") {
        //   this.setState({
        //       contactnofrom_error: "Please enter contact number"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_address.trim() === "") {
        //   this.setState({
        //       addressfrom_error: "Please enter address"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_country.trim() === "") {
        //   this.setState({
        //       countryfrom_error: "Please enter country"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_city.trim() === "") {
        //   this.setState({
        //       cityfrom_error: "Please enter city"
        //   });
        //   valid = false;
        // }
        // if (shipfrom_postalcode.trim() === "") {
        //   this.setState({
        //       postalcodefrom_error: "Please enter postalcode"
        //   });
        //   valid = false;
        // }

        // if (shipto_contactname.trim() === "") {
        //   this.setState({
        //       contactnameto_error: "Please enter contact name"
        //   });
        //   valid = false;
        // }
        // if (shipto_contactno.trim() === "") {
        //   this.setState({
        //       contactnoto_error: "Please enter contact number"
        //   });
        //   valid = false;
        // }
        // if (shipto_address.trim() === "") {
        //   this.setState({
        //       addressto_error: "Please enter address"
        //   });
        //   valid = false;
        // }
        // if (shipto_country.trim() === "") {
        //   this.setState({
        //       countryto_error: "Please enter country"
        //   });
        //   valid = false;
        // }
        // if (shipto_city.trim() === "") {
        //   this.setState({
        //       cityto_error: "Please enter city"
        //   });
        //   valid = false;
        // }
        // if (shipto_postalcode.trim() === "") {
        //   this.setState({
        //       postalcodeto_error: "Please enter postalcode"
        //   });
        //   valid = false;
        // }
        return valid;
    };
    _isEditMode = () => {
        return this.state.isEdit;
    };
    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createShippingService();
                            // if(this.validateForm()=== true){
                            //   setTimeout(() => {
                            //     this.props.hidePage();
                            //   }, 3000);
                            // }
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createShippingService();   
                        }}
                    >
                        Update
                    </button>
                )}
                 <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.hidePage();
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };


    _renderShippingFrom = () => {   
        return (
          <React.Fragment>
            <div className="row sub-title">Shipping From</div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_contactname" className="floatLeft">
                  Contact Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_contactname"
                  value={this.state.shipfrom_contactname}
                  onChange={event => this.setState({ shipfrom_contactname: event.target.value })}
                /> 
                <div className="invalid-feedback">{this.state.contactnamefrom_error}</div>
              </div>
    
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_contactno" className="floatLeft">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_contactno"
                  value={this.state.shipfrom_contactno}
                  onChange={event => this.setState({ shipfrom_contactno: event.target.value })}
                />
                <div className="invalid-feedback">{this.state.contactnofrom_error}</div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_address" className="floatLeft">
                  Address
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_address"
                  value={this.state.shipfrom_address}
                  onChange={event =>
                    this.setState({ shipfrom_address: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.addressfrom_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_areacode" className="floatLeft">
                  Area Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_areacode"
                  value={this.state.shipfrom_areacode}
                  onChange={event => this.setState({ shipfrom_areacode: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_building" className="floatLeft">
                  Building
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_building"
                  value={this.state.shipfrom_building}
                  onChange={event =>
                    this.setState({ shipfrom_building: event.target.value })
                  }
                />
              </div>  
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_town" className="floatLeft">
                  Town
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_town"
                  value={this.state.shipfrom_town}
                  onChange={event =>
                    this.setState({ shipfrom_town: event.target.value })
                  }
                />
              </div>  
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_country" className="floatLeft">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_country"
                  value={this.state.shipfrom_country}
                  onChange={event =>
                    this.setState({ shipfrom_country: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.countryfrom_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_city" className="floatLeft">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_city"
                  value={this.state.shipfrom_city}
                  onChange={event =>
                    this.setState({ shipfrom_city: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.contactnamefrom_error}</div>
              </div>            
            </div>

           
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_state" className="floatLeft">
                  State
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_state"
                  value={this.state.shipfrom_state}
                  onChange={event =>
                    this.setState({ shipfrom_state: event.target.value })
                  }
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipfrom_postalcode" className="floatLeft">
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipfrom_postalcode"
                  value={this.state.shipfrom_postalcode}
                  onChange={event => this.setState({ shipfrom_postalcode: event.target.value.replace(/[^0-9]/g, '') })}
                />
                <div className="invalid-feedback">{this.state.postalcodefrom_error}</div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="shipfrom_remarks" className="floatLeft">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  id="shipfrom_remarks"
                  value={this.state.shipfrom_remarks}
                  onChange={event => {
                    this.setState({ shipfrom_remarks: event.target.value });
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        );
      };


    handleShipDate = (name,value) =>{
      if(value !== ""){
        var dateformat = moment(value, "DD-MM-YYYY HH:mm", true).isValid();
        if(dateformat === true){
          this.setState({
            [name]: value
          })
        }
        else{
          this.setState({
            [name]: new Date()
          })
        }
      }
  }


      _renderMainForm = () =>{
        const { ordershippedon, ordercompletedon, orderreceivedon } = this.state; 
        return (
          <React.Fragment>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="status" className="floatLeft">
                  Shipping Status
                </label>
                <StatusOptionComp
                  statusoption={this.statusShip}
                  selectedOption={this.state.shippingstatus}
                  callbackChangeFilter={value => {
                    this.setState({ shippingstatus: value });
                  }}
                />
              </div>             
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="deliverytaskcode" className="floatLeft">
                  Delivery Taskcode
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="deliverytaskcode"
                  value={this.state.deliverytaskcode}
                  readOnly={true}
                /> 
              </div>
            </div>
            <div className="row">              
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="orderid" className="floatLeft">
                  Order Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="orderid"
                  value={this.state.orderid}
                  readOnly={true}
                /> 
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="ordershippedon" className="floatLeft">
                  Shipped Date
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.ordershippedon}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  name="ordershippedon"
                  onChange={(value)=>this.handleShipDate("ordershippedon", value)}
                  value={this.state.ordershippedon}
                />
                <div className="invalid-feedback">{this.state.ordershippedonerror}</div>
              </div>
            </div>
            <div className="row">             
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="orderdeliveredon" className="floatLeft">
                 Delivered Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.orderdeliveredon}
                  name="orderdeliveredon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  onChange={(value)=>this.handleShipDate("orderdeliveredon", value)}
                  value={this.state.orderdeliveredon}
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="ordercompletedon" className="floatLeft">
                   Completion Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.ordercompletedon}
                  name="ordercompletedon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"  
                  onChange={(value)=>this.handleShipDate("ordercompletedon", value)}
                  value={this.state.ordercompletedon}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="cancelledon" className="floatLeft">
                   Cancel Date 
                </label>
                <Datetime
                  defaultValue={new Date()}
                  minDate={!this._isEditMode() ? new Date() : this.state.cancelledon}
                  name="cancelledon"
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"  
                  onChange={(value)=>this.handleShipDate("cancelledon", value)}
                  value={this.state.cancelledon}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }


      _renderShippingTo = () => {  
        return (
          <React.Fragment>
            <div className="row sub-title">Shipping To</div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_contactname" className="floatLeft">
                  Contact Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_contactname"
                  value={this.state.shipto_contactname}
                  onChange={event => this.setState({ shipto_contactname: event.target.value })}
                />
                <div className="invalid-feedback">{this.state.contactnameto_error}</div>
              </div>
    
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_contactno" className="floatLeft">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_contactno"
                  value={this.state.shipto_contactno}
                  onChange={event => this.setState({ shipto_contactno: event.target.value })}
                />
                <div className="invalid-feedback">{this.state.contactnoto_error}</div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_address" className="floatLeft">
                  Address
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_address"
                  value={this.state.shipto_address}
                  onChange={event =>
                    this.setState({ shipto_address: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.addressto_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_areacode" className="floatLeft">
                  Area Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_areacode"
                  value={this.state.shipto_areacode}
                  onChange={event => this.setState({ shipto_areacode: event.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_building" className="floatLeft">
                  Building
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_building"
                  value={this.state.shipto_building}
                  onChange={event =>
                    this.setState({ shipto_building: event.target.value })
                  }
                />
              </div>  
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_town" className="floatLeft">
                  Town
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_town"
                  value={this.state.shipto_town}
                  onChange={event =>
                    this.setState({ shipto_town: event.target.value })
                  }
                />
              </div>  
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_country" className="floatLeft">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_country"
                  value={this.state.shipto_country}
                  onChange={event =>
                    this.setState({ shipto_country: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.countryto_error}</div>
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_city" className="floatLeft">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_city"
                  value={this.state.shipto_city}
                  onChange={event =>
                    this.setState({ shipto_city: event.target.value })
                  }
                />
                <div className="invalid-feedback">{this.state.cityto_error}</div>
              </div>            
            </div>

           
            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_state" className="floatLeft">
                  State
                 </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_state"
                  value={this.state.shipto_state}
                  onChange={event =>
                    this.setState({ shipto_state: event.target.value })
                  }
                />
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="shipto_postalcode" className="floatLeft">
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shipto_postalcode"
                  value={this.state.shipto_postalcode}
                  onChange={event => this.setState({ shipto_postalcode: event.target.value.replace(/[^0-9]/g, '') })}
                />
              </div>
              <div className="invalid-feedback">{this.state.postalcodeto_error}</div>
            </div>

            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="shipto_remarks" className="floatLeft">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  id="shipto_remarks"
                  value={this.state.shipto_remarks}
                  onChange={event => {
                    this.setState({ shipto_remarks: event.target.value });
                  }}
                />
              </div>
            </div>
            </React.Fragment>
        );
    }; 
    
   
    setNotify = value => {
        this.setState({
          notifycustomer: value
        });
      };

  
    _renderShipPackage = () => {
        return (
          <React.Fragment>
            <div className="row sub-title">Ship Package</div>
           
            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="weight" className="floatLeft">
                  Weight
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="weight"
                  value={this.state.weight}
                  onChange={event =>
                    this.setState({ weight: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="width" className="floatLeft">
                  Width
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="width"
                  value={this.state.width}
                  onChange={event =>
                    this.setState({ width: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="height" className="floatLeft">
                  Height
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="height"
                  value={this.state.height}
                  onChange={event =>
                    this.setState({ height: event.target.value })
                  }
                />
              </div>
            </div>
        
          </React.Fragment>
        );
      };

      _renderRemark = () => {
        return (
          <React.Fragment>
          <div className="row sub-title">Internal Remarks</div>
          <div className="row">
              <div className="form-group col-lg-12 col-sm-12">
                <label htmlFor="remark" className="floatLeft">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  id="remark"
                  value={this.state.remark}
                  onChange={event => {
                    this.setState({ remark: event.target.value });
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        );
      }

      _renderCustomerForm = () =>{
          return (
              <React.Fragment>
                  <div className="row sub-title">Customer Declaration</div>
                  <div className="row">
                      <div className="form-group col-sm-12 col-lg-12">
                          <label htmlFor="insured" className="floatLeft">
                              Insured
                          </label>
                          <SimpleSelect
                              objArray={{ Y: "Yes", N: "No" }}
                              callbackChangeFilter={value => this.setState({ isinsured: value })}
                              selectedOption={this.state.isinsured}
                              id="insured"
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="form-group col-sm-12 col-lg-12">
                          <label htmlFor="currency" className="floatLeft">
                            Currency 
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="currency"
                              value={this.state.curreny}
                              onChange={event =>
                                  this.setState({ curreny: event.target.value })
                              }
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="form-group col-sm-12 col-lg-12">
                          <label htmlFor="amount" className="floatLeft">
                            Amount  
                          </label>
                          <input
                              type="text"
                              className="form-control"
                              id="amount"
                              value={this.state.amount}
                              onChange={event =>
                                  this.setState({ amount: event.target.value })
                              }
                          />
                      </div>
                  </div>
              </React.Fragment>
          )
      }

      _renderNotification = () =>{
        return (
            <React.Fragment>
                <div className="row sub-title">Customer Notification</div>
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="sms" className="floatLeft">
                      SMS
                            </label>
                    <SimpleSelect
                      objArray={{ Y: "Yes", N: "No" }}
                      callbackChangeFilter={value => this.setState({ sms: value })}
                      selectedOption={this.state.sms}
                      id="sms"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="email" className="floatLeft">
                      Email
                    </label>
                    <SimpleSelect
                      objArray={{ Y: "Yes", N: "No" }}
                      callbackChangeFilter={value => this.setState({ email: value })}
                      selectedOption={this.state.email}
                      id="email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="notification" className="floatLeft">
                      Notification
                   </label>
                    <SimpleSelect
                      objArray={{ Y: "Yes", N: "No" }}
                      callbackChangeFilter={value => this.setState({ notification: value })}
                      selectedOption={this.state.notification}
                      id="notification"
                    />
                  </div>
                </div>
            </React.Fragment>
        )
    }

      _renderShippingTrackForm = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Shipping Tracker</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="courier" className="floatLeft">
                            Logistic Company
                       </label>
                        <input
                            type="text"
                            className="form-control"
                            id="courier"
                            value={this.state.courier}
                            onChange={event =>
                                this.setState({ courier: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="trackingurl" className="floatLeft">
                            Tracking URL
                       </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trackingurl"
                            value={this.state.trackingurl}
                            onChange={event =>
                                this.setState({ trackingurl: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="trackingno" className="floatLeft">
                            Tracking No
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trackingno"
                            value={this.state.trackingno}
                            onChange={event =>
                                this.setState({ trackingno: event.target.value })
                            }
                        />
                    </div>
                </div>              
            </React.Fragment>
        )
      }

      handleOnSelect1 = (row, isSelect) => {
         console.log("select1")
        if (isSelect) {
          this.setState(()=>({
            selected1: [...this.state.selected1, row.idx],
            selectedrow1: [...this.state.selectedrow1, row]
          }),()=>{
            this.getList();
          });
        } else {
          this.setState(() => ({
            selected1: this.state.selected1.filter(x => x !== row.idx),
            selectedrow1: this.state.selectedrow1.filter(x => x.idx !== row.idx)
          }),()=>{
            this.getList();
          });
        }
      };

      handleOnSelectAll1 = (isSelect, rows) => {
        const ids = rows.map(r => r.idx);
        if (isSelect) {
          this.setState(() => ({
            selected1: ids,
            selectedrow1: rows
          }));
        } else {
          this.setState(() => ({
            selected1: [],
            selectedrow1: []
          }));
        }
      };

      handleOnSelect2 = (row, isSelect) => {
        console.log("select2")
        if (isSelect) {
          this.setState(()=>({
            selected2: [...this.state.selected2, row.id],
            selectedrow2: [...this.state.selectedrow2, row]
          }),()=>{
            this.getList();
          });
        } else {
          this.setState(() => ({
            selected2: this.state.selected2.filter(x => x !== row.id),
            selectedrow2: this.state.selectedrow2.filter(x => x.id !== row.id)
          }),()=>{
            this.getList();
          });
        }
      };

    
      _renderItemDetails = () => {
        const { invlist, items } = this.state;
        const checkedrow1 = [...new Set(this.state.selected1)];
        const checkedrow2 = [...new Set(this.state.selected2)];
          return (
            <React.Fragment>
             <div className="row sub-title">Item List</div>
                <SimpleExpandTable
                  columns={this.state.itemheaders}
                  rows={items}
                  expcolumns={this.state.invheaders}
                  exprows={invlist}
                  handleOnSelect1={this.handleOnSelect1}
                  handleOnSelect2={this.handleOnSelect2}
                  handleOnSelectAll1={this.handleOnSelectAll1}
                  handleOnExpand={this.handleOnExpand}
                  expanded ={this.state.expanded}
                  selected1={checkedrow1}
                  selected2={checkedrow2}
                />
                <div className="invalid-feedback">{this.state.items_error}</div>
            </React.Fragment>
          )
      }

   
       
    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div>
                <div className="row rowBottom">
                    <div className="col-md-12 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center">
                            {this._renderItemDetails()}
                        </form> 
                    </div>
                </div>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">   
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderMainForm()}
                        </form>                         
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderShippingFrom()}
                        </form>  
                        <form className="splitFrmDiv text-center "
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderShippingTo()}
                        </form>                   
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderShippingTrackForm()}
                        </form> 
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderShipPackage()}
                        </form>
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderCustomerForm()}
                        </form>                       
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderNotification()}
                        </form> 
                        <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
                            {this._renderRemark()}
                        </form>                                                 
                    </div>
                </div>
                </div>
            </React.Fragment>
        );
    };
    hideModal = e => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.hidePage();
    };

    render() {
      console.log(this.props);
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? "Edit Shipping Details"
                                    : "Add Shipping Details"}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                <ConfirmAlert
                  show={isSuccess}
                  handleClose={this.hideModal}
                  to={this.props.shipreturnpage === true ? 
                    "/ui/order-fulfillment" : "/ui/orders-fulfillment"}
                  children={
                    <div style={{ padding: "2em", color: "green" }}>
                      {this._isEditMode()
                        ? "Shipping Details updated Successfully."
                        : "Shipping Details saved Successfully."}
                    </div>
                  }
                />
                              
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddShippingItem);
