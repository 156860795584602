import React from 'react';
import SimpleTable from '../../components/SimpleTable';
import DatePicker from 'react-date-picker';
import { produce } from 'immer';
import {
    crudReset,
    resetAction,
    posMasterDetailsReport,
    queryPropertyConfigByFields,
    storeOpenSearchByFields,
    storeTerminalsSearchByFields,
    ejSearchByFields,
    resetErrorOnly,
} from '../../actions/actions';
import {
    canView,
    formatDate,
    permissions,
    sortTableDateTime,
} from '../../helpers/Util';

import 'react-accessible-accordion/dist/fancy-example.css';
import { connect } from 'react-redux';
import ConfirmAlert from '../../components/ConfirmAlert';
import StoreSearchSingle from '../system/Users/StoreSearchSingle';
import {
    EJ_EXPORT,
    POS_MASTER_DETAILS_REPORT,
    SEARCH_EJ_BY_FIELDS_FAILURE,
    SEARCH_EJ_BY_FIELDS_SUCCESS,
    SEARCH_PROP_CONFIG_EJ_TRXTYPES,
    SEARCH_PROP_CONFIG_EJ_TRXTYPES_FAILURE,
    SEARCH_PROP_CONFIG_EJ_TRXTYPES_SUCCESS,
    SEARCH_STORE_OPEN_FAILURE_SILENT,
    SEARCH_STORE_OPEN_SUCCESS_SILENT,
    SEARCH_STORE_TERMINALS_FAILURE_SILENT,
    SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
} from '../../constants/constants';
import DownloadEJ from './DownloadEJ';
import GenerateEJ from './GenerateEJ';
import PrimeTable from '../../components/PrimeTable';

const GenerateEJList = ['sales', 'termx', 'termz', 'void', 'ejt'];

class EJ extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_REPORTS_EJ';
        this.module = 'REPORTS';
        this.allPermissions = [];

        this.requestBody = {};
        this.currPage = 1;
        this.pageSize = 10;

        this.trxtype = '';
        this.trxgroup = '';
        this.action = '';

        this.state = {
            storeid: '',
            trxno: '',
            storesessionid: '',
            terminalid: '',
            trxdate: '',
            storedate: new Date(),
            date_from: new Date(),
            date_till: new Date(),
            trxtypes: {},
            storesessions: [{ name: 'Select', id: '' }],
            terminals: [{ name: 'Select', id: '' }],
            params: {},
            showGenerateEJ: false,
            showDownloadEJ: false,

            ejpath: '',

            s_storelabel: '',
            pageSize: this.pageSize,
            currPage: this.currPage,
            fromDate: new Date(),
            toDate: new Date(),
            date_type: 'trxdate',
            searchbtn: false,
            searchfieldbtn: false,
            storelabel: '',
            cartdata: [],
            storeiderror: '',
            selectedEj: ''
        };
    }

    styleDisplayRow = (row, rowIndex) => {
        const style = {};
        return style;
    };

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        //only show download btn that have ej
        if (
            canView(this.allPermissions) &&
            GenerateEJList.includes(row.trxtype)
        ) {
            return (
                <span>
                    <img
                        title="Download EJ"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                        onClick={() => this.searchEJ(row)}
                    />
                </span>
            );
        }
    };

    actionTemplate = (row) => {
        if (
            canView(this.allPermissions) &&
            GenerateEJList.includes(row.trxtype)
        ) {
            return (
                <span>
                    <img
                        title="Download EJ"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                        onClick={() => this.searchEJ(row)}
                    />
                </span>
            );
        }
    }

    searchEJ = (row) => {
        this.action = 'searchej';
        let queryParams = [];
        this.setState({ selectedEj: row.id})
        queryParams.push(`storeid=${row.storeid}`);
        queryParams.push(`terminalid=${row.terminalid}`);
        queryParams.push(`ptrxno=${row.trxno}`);
        this.props.dispatch(
            ejSearchByFields(
                queryParams.join('&'),
                SEARCH_EJ_BY_FIELDS_SUCCESS,
                SEARCH_EJ_BY_FIELDS_FAILURE
            )
        );
    };

    downloadEJ = (row) => {
        let link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute

            let url = localStorage.getItem('imagepath') + row.filepath;
            link.setAttribute('href', url);

            let exportedFilename = row.trxno + '.pdf' || 'export.pdf';
            link.setAttribute('download', exportedFilename);
            link.target = 'blank';
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, storeid, storedate } = this.state;

        const queryParams = [];

        if (fetchType === 'ONLOAD') {
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`storedate=${formatDate(storedate)}`);
            queryParams.push(`pagesize=${this.pageSize}`);
            queryParams.push(`pageno=${this.currPage}`);

            return queryParams.join('&');
        }

        this.requestBody['pagesize'] = pageSize;
        this.requestBody['pageno'] = currPage;

        for (const [key, value] of Object.entries(this.requestBody)) {
            queryParams.push(`${key}=${value}`);
        }
        return queryParams.join('&');
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            posMasterDetailsReport(this.createFetchUrl(fetchType))
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch(triggerPoint);
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
        }
    };

    componentWillMount() {
        this.setState(
            {
                storeid: localStorage.getItem('storeid'),
                s_storelabel: localStorage.getItem('storelabel'),
            },
            () => {
                this.fetchStoreSessions();
                this.fetchStoreTerminals();
            }
        );

        //reset the existing report data
        this.props.queryData.posMasterDetailsReport = [];

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'EJ_TRXTYPES',
                    },
                },
                SEARCH_PROP_CONFIG_EJ_TRXTYPES,
                SEARCH_PROP_CONFIG_EJ_TRXTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_EJ_TRXTYPES_FAILURE
            )
        );

        this.allPermissions = permissions(this.module, this.group);
    }

    fetchStoreTerminals = () => {
        const { storeid } = this.state;

        this.props.queryData.storeTerminals = [];
        if (storeid && storeid !== '') {
            const queryParams = [];
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`devicegroup=terminal`);
            queryParams.push(`status=Active`);
            this.props.dispatch(
                storeTerminalsSearchByFields(
                    queryParams.join('&'),
                    SEARCH_STORE_TERMINALS_SUCCESS_SILENT,
                    SEARCH_STORE_TERMINALS_FAILURE_SILENT
                )
            );
        }
    };

    fetchStoreSessions = () => {
        const { storeid, date_from, date_till } = this.state;

        //reset before query
        this.props.queryData.storeSessions = [];
        if (storeid && storeid !== '' && date_from !== '') {
            const queryParams = [];
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`date_from=${formatDate(date_from)}`);
            queryParams.push(`date_till=${formatDate(date_till)}`);
            this.props.dispatch(
                storeOpenSearchByFields(
                    queryParams.join('&'),
                    SEARCH_STORE_OPEN_SUCCESS_SILENT,
                    SEARCH_STORE_OPEN_FAILURE_SILENT
                )
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeSessions, storeTerminals } = this.props.queryData;
        const {
            storeSessions: prevstoreSessions,
            storeTerminals: prevstoreTerminals,
        } = prevProps.queryData;

        if (storeSessions !== prevstoreSessions) {
            if (storeSessions && storeSessions.length > 0) {
                var sessions = [{ name: 'Select', id: '' }];
                var returnedSessions = storeSessions.map((e) => ({
                    name: `${e.trxdate} (${e.status})`,
                    id: e.trxval,
                }));
                this.setState({
                    storesessions: sessions.concat(returnedSessions),
                });
            } else {
                this.setState({ storesessions: [{ name: 'Select', id: '' }] });
            }
        }

        if (storeTerminals !== prevstoreTerminals) {
            if (storeTerminals && storeTerminals.length > 0) {
                var terminals = [{ name: 'Select', id: '' }];
                var returnedTerminals = storeTerminals.map((e) => ({
                    name: e.terminalid,
                    id: e.terminalid,
                }));
                this.setState({
                    terminals: terminals.concat(returnedTerminals),
                });
            } else {
                this.setState({ terminals: [{ name: 'Select', id: '' }] });
            }
        }

        const { ejTrxTypes } = this.props.queryData;

        const { ejTrxTypes: prevejTrxTypes } = prevProps.queryData;

        if (ejTrxTypes !== prevejTrxTypes) {
            if (
                ejTrxTypes &&
                ejTrxTypes.length > 0 &&
                ejTrxTypes[0].additionalfields
            ) {
                this.trxtype = Object.values(ejTrxTypes[0].additionalfields);
            }
        }

        const { ej } = this.props.queryData;

        const { ej: prevej } = prevProps.queryData;

        const { selectedEj } = this.state

        if (ej !== prevej) {
            if (ej && ej.length > 0) {
                const eJournal = ej.find(e => e.id === selectedEj) || ej[ej.length - 1];
                if (eJournal && eJournal.filepath !== '') {
                    this.setState({ ejpath: eJournal.filepath });
                    this.downloadEJ(eJournal);
                }
            }
            
        }
    };

    componentWillReceiveProps = () => {
        const { storeSessions, storeTerminals } = this.props.queryData;

        const { ejTrxTypes } = this.props.queryData;

        if (
            ejTrxTypes &&
            ejTrxTypes.length > 0 &&
            ejTrxTypes[0].additionalfields
        ) {
            this.trxtype = Object.values(ejTrxTypes[0].additionalfields);
        }

        if (storeSessions && storeSessions.length > 0) {
            var sessions = [{ name: 'Select', id: '' }];
            var returnedSessions = storeSessions.map((e) => ({
                name: `${e.trxdate} (${e.status})`,
                id: e.trxval,
            }));

            this.setState({ storesessions: sessions.concat(returnedSessions) });
        }

        if (storeTerminals && storeTerminals.length > 0) {
            var terminals = [{ name: 'Select', id: '' }];
            var returnedTerminals = storeTerminals.map((e) => ({
                name: e.terminalid,
                id: e.terminalid,
            }));
            this.setState({ terminals: terminals.concat(returnedTerminals) });
        }
    };

    _setTenant = (p1, p2) => {
        this.setState(
            { storeid: p1, s_storelabel: p2, search_value: p1 },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);

                this.fetchStoreSessions();
                this.fetchStoreTerminals();
            }
        );
        this.setState({ storeiderror: '' });
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();

        if (this.action === 'searchej') {
            this.props.dispatch(resetErrorOnly());
            this.action = ''; //reset
            return;
        }

        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());

        this.fetchStoreSessions();
        this.fetchStoreTerminals();
    };

    _renderGenerateEJ = () => {
        if (!canView(permissions('REPORTS', 'DE_REPORTS_EXPORT_EJ'))) {
            return;
        }

        return (
            <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={(e) => {
                    this.viewGenerateEJ(e);
                }}
            >
                Generate EJ
            </button>
        );
    };

    _renderDownloadEJ = () => {
        if (!canView(permissions('REPORTS', 'DE_REPORTS_DOWNLOAD_EJ'))) {
            return;
        }

        return (
            <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={(e) => {
                    this.viewDownloadEJ(e);
                }}
            >
                Download EJ
            </button>
        );
    };

    viewGenerateEJ = (row) => {
        this.setState(
            {
                params: {
                    storeid: this.state.storeid,
                    s_storelabel: this.state.s_storelabel,
                },
            },
            () => {
                this.setState({ showGenerateEJ: true });
            }
        );
    };

    viewDownloadEJ = (row) => {
        this.setState(
            {
                params: {
                    storeid: this.state.storeid,
                    s_storelabel: this.state.s_storelabel,
                },
            },
            () => {
                this.setState({ showDownloadEJ: true });
            }
        );
    };

    _validateAndSearch = (event) => {
        if (event) event.preventDefault();

        const {
            storeid,
            trxno,
            terminalid,
            date_from,
            date_till,
            storesessionid,
        } = this.state;

        this.requestBody = {};
        const queryParams = [];
        if (this.state.storeid === '' || this.state.storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return;
        } else {
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
            queryParams.push(`storeid=${storeid}`);
        }

        if (date_from !== '') {
            this.requestBody['date_from'] = formatDate(date_from);
            queryParams.push(`date_from=${formatDate(date_from)}`);
        }

        if (date_till !== '') {
            this.requestBody['date_till'] = formatDate(date_till);
            queryParams.push(`date_till=${formatDate(date_till)}`);
        }

        if (terminalid !== '') {
            this.requestBody['terminalid'] = terminalid;
            queryParams.push(`terminalid=${terminalid}`);
        }

        this.requestBody['trxgroup'] = this.trxgroup;
        queryParams.push(`trxgroup=${this.trxgroup}`);

        this.requestBody['trxtype'] = this.trxtype;
        queryParams.push(`trxtype=${this.trxtype}`);

        if (trxno !== '') {
            this.requestBody['trxno'] = trxno;
            queryParams.push(`trxno=${trxno}`);
        }

        if (storesessionid !== '') {
            this.requestBody['storesessionid'] = storesessionid;
            queryParams.push(`storesessionid=${storesessionid}`);
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        this.setState({ isSearched: true, pageSize: this.pageSize });
        //query
        this.props.dispatch(posMasterDetailsReport(queryParams.join('&')));
    };

    _hideExport = () => {
        this.props.queryData.reportexport = {};
        this.setState({ showDownloadEJ: false, showGenerateEJ: false });
    };

    _renderSearchCiteria = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label
                            htmlFor="date_from"
                            className="floatLeft required"
                        >
                            Date From
                        </label>
                        <DatePicker
                            onChange={(value) =>
                                this.setState({ date_from: value }, () => {
                                    if (
                                        this.state.date_from >
                                        this.state.date_till
                                    ) {
                                        this.setState({
                                            date_till: this.state.date_from,
                                        });
                                    }
                                    this.fetchStoreSessions();
                                })
                            }
                            value={this.state.date_from}
                            maxDate={new Date()}
                            format="d/M/yy"
                        />
                    </div>

                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="toDate" className="floatLeft required">
                            Date To
                        </label>
                        <DatePicker
                            minDate={this.state.date_from}
                            onChange={(value) =>
                                this.setState({ date_till: value }, () => {
                                    this.fetchStoreSessions();
                                })
                            }
                            value={this.state.date_till}
                            maxDate={new Date()}
                            format="d/M/yy"
                        />
                    </div>

                    <div className="form-group col-lg-3 col-sm-4">
                        <label htmlFor="terminalid" className="floatLeft">
                            Terminal Id
                        </label>
                        <div className="row">
                            <select
                                name="terminalid"
                                className="simpleSearchSelect2"
                                value={this.state.terminalid}
                                onChange={(event) =>
                                    this.setState({
                                        terminalid: event.target.value,
                                    })
                                }
                            >
                                {this.state.terminals.length !== 0 &&
                                    this.state.terminals.map(
                                        (option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                    </div>

                    <div className="form-group col-lg-3 col-sm-4">
                        <label htmlFor="storesessionid" className="floatLeft">
                            Store Session
                        </label>
                        <div className="row">
                            <select
                                name="storesession"
                                className="simpleSearchSelect2"
                                value={this.state.storesessionid}
                                onChange={(event) =>
                                    this.setState({
                                        storesessionid: event.target.value,
                                    })
                                }
                            >
                                {this.state.storesessions.length !== 0 &&
                                    this.state.storesessions.map(
                                        (option, index) => {
                                            const { name, id } = option;
                                            return (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="trxno" className="floatLeft">
                            Transaction No
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxno"
                            value={this.state.trxno}
                            onChange={(event) => {
                                this.setState({ trxno: event.target.value });
                            }}
                        />
                    </div>

                    <div className="form-group col-1 noPadding">
                        <img
                            alt="search"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                            onClick={(event) => this._validateAndSearch(event)}
                            className="searchBtn"
                        />
                    </div>
                </div>

                <div className="form-group col-lg-7 col-sm-3 noPadding" />
            </>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    pageSizeChangecallback = (pageSize) => {
        this.setState(
            produce(this.state, (draft) => {
                if (
                    draft.pageSize <
                    pageSize
                ) {
                    draft.currPage = 1;
                }
                draft.pageSize = pageSize;
                draft.isSearched = false
            }),
            () => {
                this.callQueryApiForFetch(
                    'PAGECLICK'
                );
            }
        );
    }

    render() {
        const {
            posMasterDetailsReport,
            servererror: serverError,
            errormsg: queryError,
        } = this.props.queryData;

        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }

        const columns = [
            { field: 'storeid', header: 'Store Id' },
            { field: 'storesessionid', header: 'Store Session Id' },
            { field: 'trxgroup', header: 'Trx Group' },
            { field: 'terminalid', header: 'Terminal Id' },
            { field: 'usercode', header: 'User Id' },
            { field: 'trxdate', header: 'Trx Date' },
            { field: 'trxno', header: 'Trx No' },
            { field: 'trxtype', header: 'Trx Type' },
            { field: 'amount', header: 'Amount' }
        ];

        if (this.state.showDownloadEJ) {
            return (
                <>
                    <DownloadEJ
                        params={this.state.params}
                        _hideExport={this._hideExport}
                        reporttype={POS_MASTER_DETAILS_REPORT}
                    />
                </>
            );
        } else if (this.state.showGenerateEJ) {
            return (
                <>
                    <GenerateEJ
                        params={this.state.params}
                        _hideExport={this._hideExport}
                        reporttype={EJ_EXPORT}
                    />
                </>
            );
        } else {
            return (
                <>
                    <div className="form_height">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2 className="page-header">
                                    Electronic Journal
                                </h2>
                            </div>
                            <div className="col-lg-6 col-sm-12 alignRight">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 alignRight alignWithPageHeader">
                                        {this._renderGenerateEJ()}
                                    </div>
                                    <div
                                        className="col-lg-12 col-sm-12 alignRight"
                                        style={{ marginTop: 10 }}
                                    >
                                        {this._renderDownloadEJ()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row form-container">
                            <div className="col">
                                <form
                                    className="frmDiv"
                                    onSubmit={this._validateAndSearch}
                                >
                                    <div className="row">
                                        {this._renderSearchCiteria()}
                                    </div>
                                    <div className="title_devide" />
                                    <div className="invalid-feedback">
                                        {errormsg}
                                    </div>
                                    <PrimeTable
                                        tablePageSize={this.state.pageSize}
                                        list={posMasterDetailsReport}
                                        isSearched={this.state.isSearched}
                                        columns={columns}
                                        actionColumn={this.actionTemplate}
                                        linkColumn={this.linkTemplate}
                                        pageSizeChangecallback={(pageSize) => this.pageSizeChangecallback(pageSize)}
                                        pageChangeCallback={(currPage) => {
                                            this.setState(
                                                {
                                                    currPage: currPage,
                                                },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={errormsg}
                            handleClose={(event) =>
                                this.hideErrorAlertModal(event, refresh)
                            }
                            children={
                                <div
                                    style={
                                        errormsg
                                            ? {
                                                  padding: '2em',
                                                  color: 'red',
                                                  fontWeight: '500',
                                              }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {errormsg ? `${errormsg}.` : ''}
                                </div>
                            }
                        />
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(EJ);
