
// Local imports
import * as CONSTANTS from "../constants/constants";

export const initialState = {
  data: {},
  reportexport: {},
  error: false,
  errormsg: "",
  isSuccess: false,
  isPromoPGroupSuccess: false,
  isPromoPGroupFailure: false,
  isPromoTGroupSuccess: false,
  isPromoTGroupFailure: false,
  userRoleIsSuccess: false,
  isUploadSuccess: false,
  isUploadFailure: false,
  createFolderSuccess: false,
  createFolderFailure: false,
  deleteFolderSuccess: false,
  deleteFolderFailure: false,
  generateThumbnailSuccess: false,
  generateThumbnailFailure: false,
  removeFileSuccess: false,
  removeFileFailure: false,
  moveFileSuccess: false,
  moveFileFailure: false,
  copyFileSuccess: false,
  copyFileFailure: false,
  crudApiPending: false,
  addonData: [],
  createAddOnSuccess: false,
  createAddOnFailure: false,
  updateAddOnSuccess: false,
  updateAddOnFailure: false,
  addondelesuccess: false,
  addondelefailure: false,
  updateStoreHours: false,
  createUpdateStoreSuccess: false,
  createUpdateStoreTerminalSuccess: false,
  isCouponRedemptionRuleSuccess: false,
  isCouponRedemptionRuleFaliure: false
};

const crudReducer = (state = initialState, { type, data, errormsg }) => {
  switch (type) {
    case CONSTANTS.CRUD_API_PENDING:
      return {
        ...state,
        crudApiPending: true
      };
    case CONSTANTS.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        data: data,
        errormsg: errormsg,
        createFolderSuccess: true,
        createFolderFailure: false,
        crudApiPending: false
      };
    case CONSTANTS.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        errormsg: errormsg,
        createFolderSuccess: false,
        createFolderFailure: true,
        crudApiPending: false
      };
    case CONSTANTS.DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        data: data,
        deleteFolderSuccess: true,
        deleteFolderFailure: false,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.DELETE_FOLDER_FAILURE:
      return {
        ...state,
        deleteFolderSuccess: false,
        deleteFolderFailure: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.GENERATE_THUMBNAIL_SUCCESS:
      return {
        ...state,
        data: data,
        generateThumbnailSuccess: true,
        generateThumbnailFailure: false,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.GENERATE_THUMBNAIL_FAILURE:
      return {
        ...state,
        generateThumbnailSuccess: false,
        generateThumbnailFailure: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        data: data,
        isUploadSuccess: true,
        isUploadFailure: false,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        data: data,
        isUploadSuccess: false,
        isUploadFailure: true,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.REMOVE_FILE_SUCCESS:
      return {
        ...state,
        data: data,
        removeFileSuccess: true,
        removeFileFailure: false,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.REMOVE_FILE_FAILURE:
      return {
        ...state,
        data: data,
        removeFileSuccess: false,
        removeFileFailure: true,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.MOVE_FILE_SUCCESS:
      return {
        ...state,
        data: data,
        moveFileSuccess: true,
        moveFileFailure: false,
        crudApiPending: false,
        errormsg: errormsg
      };
    case CONSTANTS.MOVE_FILE_FAILURE:
      return {
        ...state,
        data: data,
        moveFileSuccess: false,
        moveFileFailure: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.COPY_FILE_SUCCESS:
      return {
        ...state,
        data: data,
        copyFileSuccess: true,
        copyFileFailure: false,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.COPY_FILE_FAILURE:
      return {
        ...state,
        data: data,
        copyFileSuccess: false,
        copyFileFailure: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.STORE_HOURS:
        return {
          ...state,
          data: data,
          updateStoreHours: true,
          errormsg: errormsg,
          crudApiPending: false
      };
    case CONSTANTS.CRUD_REQUEST_SUCCESS:
      return {
        ...state,
        data: data,
        isSuccess: true,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.CRUD_REQUEST_FAILURE:
      return {
        ...state,
        data: data,
        isSuccess: false,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.CUSTOMER_TAG_SUCCESS:
        return {
          ...state,
          data: data,
          isSuccessTag: true,
          error: false,
          errormsg: "",
          crudApiPending: false
        };
    case CONSTANTS.CUSTOMER_TAG_FAILURE:
        return {
          ...state,
          data: data,
          isSuccessTag: false,
          error: false,
          errormsg: "",
          crudApiPending: false
        };
      case CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_SUCCESS:
      return {
        ...state,
        pgroupData: data,
        isPromoPGroupSuccess: true,
        isPromoPGroupFailure: false,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.PROMOTION_PGROUP_CRUD_REQUEST_FAILURE:
      return {
        ...state,
        pgroupData: data,
        isPromoPGroupSuccess: false,
        isPromoPGroupFailure: true,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
      case CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_SUCCESS:
      return {
        ...state,
        data: data,
        isPromoTGroupSuccess: true,
        isPromoTGroupFailure: false,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.PROMOTION_TGROUP_CRUD_REQUEST_FAILURE:
      return {
        ...state,
        data: data,
        isPromoTGroupSuccess: false,
        isPromoTGroupFailure: true,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.CREATE_ADDON_SUCCESS:
      return {
        ...state,
        addonData: data,
        createAddOnSuccess: true,
        createAddOnFailure: false,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.CREATE_ADDON_FAILURE:
      return {
        ...state,
        addonData: data,
        createAddOnSuccess: false,
        createAddOnFailure: true,

        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.UPDATE_ADDON_SUCCESS:
      return {
        ...state,
        addonData: data,
        updateAddOnSuccess: true,
        updateAddOnFailure: false,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.UPDATE_ADDON_FAILURE:
      return {
        ...state,
        addonData: data,
        updateAddOnSuccess: false,
        updateAddOnFailure: true,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.CRUD_REQUEST_FOR_ADDON_SUCCESS:
      return {
        ...state,
        data: data,
        isSuccess: true,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.CRUD_REQUEST_FOR_ADDON_FAILURE:
      return {
        ...state,
        data: data,
        isSuccess: false,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.ADDON_DELETE_SUCCESS:
      return {
        ...state,
        data: data,
        addondelesuccess: true,
        addondelefailure: false,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.ADDON_DELETE_FAILURE:
      return {
        ...state,
        data: data,
        addondelesuccess: false,
        addondelefailure: true,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
      case CONSTANTS.USERROLE_CRUD_REQUEST_SUCCESS:
        return {
          ...state,
          data: data,
          userRoleIsSuccess: true,
          error: false,
          errormsg: "",
          crudApiPending: false
        };
      case CONSTANTS.USERROLE_CRUD_REQUEST_FAILURE:
        return {
          ...state,
          data: data,
          userRoleIsSuccess: false,
          error: true,
          errormsg: errormsg,
          crudApiPending: false
        };
      // tax configuration
      case CONSTANTS.TAXCONFIGURATION_CRUD_REQUEST_SUCCESS:
        return {
          ...state,
          data: data,
          isSuccess: true,
          error: false,
          errormsg: "",
          crudApiPending: false
        };
      case CONSTANTS.TAXCONFIGURATION_CRUD_REQUEST_FAILURE:
        return {
          ...state,
          data: data,
          isSuccess: false,
          error: true,
          errormsg: errormsg,
          crudApiPending: false
        };
      case CONSTANTS.DELETE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS:
      return {
        ...state,
        data: data,
        isSuccess: true,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.DELETE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE:
      return {
        ...state,
        data: data,
        isSuccess: false,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
      case CONSTANTS.UPDATE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS:
      return {
        ...state,
        data: data,
        isSuccess: true,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.UPDATE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE:
      return {
        ...state,
        data: data,
        isSuccess: false,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    // special discount
    case CONSTANTS.SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS:
      return {
        ...state,
        data: data,
        isSuccess: true,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
    case CONSTANTS.SPECIALDISCOUNT_CRUD_REQUEST_FAILURE:
      return {
        ...state,
        data: data,
        isSuccess: false,
        error: true,
        errormsg: errormsg,
        crudApiPending: false
      };
    case CONSTANTS.DELETE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS:
    return {
      ...state,
      data: data,
      isSuccess: true,
      error: false,
      errormsg: "",
      crudApiPending: false
    };
  case CONSTANTS.DELETE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE:
    return {
      ...state,
      data: data,
      isSuccess: false,
      error: true,
      errormsg: errormsg,
      crudApiPending: false
    };
    case CONSTANTS.UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS:
    return {
      ...state,
      data: data,
      isSuccess: true,
      error: false,
      errormsg: "",
      crudApiPending: false
    };
  case CONSTANTS.UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE:
    return {
      ...state,
      data: data,
      isSuccess: false,
      error: true,
      errormsg: errormsg,
      crudApiPending: false
    };
    case CONSTANTS.STORE_CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        data: data,
        createUpdateStoreSuccess: true,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
      case CONSTANTS.STORE_CREATE_UPDATE_FAILURE:
        return {
          ...state,
          data: data,
          createUpdateStoreSuccess: false,
          isSuccess: false,
          error: true,
          errormsg: errormsg,
          crudApiPending: false
        };
      case CONSTANTS.STORE_CREATE_UPDATE_SUCCESS:
        return {
          ...state,
          data: data,
          storeTagBatchSuccess: true,
          error: false,
          errormsg: "",
          crudApiPending: false
        };
        case CONSTANTS.STORE_CREATE_UPDATE_FAILURE:
          return {
            ...state,
            data: data,
            storeTagBatchSuccess: false,
            isSuccess: false,
            error: true,
            errormsg: errormsg,
            crudApiPending: false
          };
    case CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        data: data,
        createUpdateStoreTerminalSuccess: true,
        error: false,
        errormsg: "",
        crudApiPending: false
      };
      case CONSTANTS.STORE_TERMINAL_CREATE_UPDATE_FAILURE:
        return {
          ...state,
          data: data,
          createUpdateStoreTerminalSuccess: false,
          isSuccess: false,
          error: true,
          errormsg: errormsg,
          crudApiPending: false
        };
      case CONSTANTS.EXECUTE_DATA_VALIDATION_REPORT_SUCCESS:
        return {
          ...state,
          data: data,
          isSuccess: true,
          error: false,
          errormsg: "",
          crudApiPending: false
        };
      case CONSTANTS.EXECUTE_DATA_VALIDATION_REPORT_FAILURE:
        return {
          ...state,
          data: data,
          isSuccess: false,
          error: true,
          errormsg: errormsg,
          crudApiPending: false
        };
    case CONSTANTS.CRUD_RESET:
      return initialState;
    default:
      return state;
  }
};
export default crudReducer;
