import React, { Component } from "react";
import Select, { components } from "react-select";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

class DynamicProductGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMapModal: this.props.showProp || false,
      selected: this.props.productspecs || []
    };
  }

  _hideMapModal = () => {
    this.setState({ showMapModal: false });
  };

  sortgroupobj(obj) {
    return Object.keys(obj).reduce((sortedObj, key) => {
      const value = obj[key];
      const sortedValue = value.sort((a, b) => a.value.localeCompare(b.value));
  
      return {
        ...sortedObj,
        [key]: sortedValue
      };
    }, {});
  }

  groupBy = (data, key) => {
    let groupedArray = [];
    const groupoptions = data.reduce(
      (result, item) => ({
        ...result,
        [item["group"]]: [...(result[item["group"]] || []), item]
      }),
      {}
    );
    //groupoptions.sort((a, b) => a.value - b.value);
    // const sortgroupoptions = groupoptions.forEach(grp=>{
    //   return grp.sort((a, b) => a.value - b.value)
    // })
    const sortgroupoptions = this.sortgroupobj(groupoptions);
    Object.entries(groupoptions).forEach(value => {
      let item = { label: value[0], options: value[1].sort() };
      groupedArray.push(item);
    });
    //console.log(groupedArray);
    return groupedArray;
  }

  _renderMapModal = () => {
    const Group = props => (
      <div style={groupStyles}>
        <components.Group {...props} />
      </div>
    );
    const Option = props => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
            <label style={{ marginLeft: "2px" }}>{props.label}</label>
          </components.Option>
        </div>
      );
    };
    const allOption = {
      label: "Select all",
      value: "*"
    };
    const MultiValue = props => {
      let labelToBeDisplayed = `${props.data.label} `;
      if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All is selected";
      }
      return (
        <components.MultiValue {...props}>
          <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
      );
    };
    
    return(
      <React.Fragment>
        <div className="row">
          <div className="col-12" style={{ height: "22em" }}>
              <Select
                value={this.state.selected}
                onChange={value => {
                  this.setState({ selected: value });
                }}
                options={this.groupBy(this.props.propoptions)}
                components={{
                  Option,
                  MultiValue,
                  //ValueContainer,
                  //animatedComponents,
                  Group
                }}
                classNamePrefix="react-select"
                className="react-select-container"
                autosize={true}
                noResultsText="No Products Found"
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "#6c90c1",
                    primary25: "#f5f5f5"
                  }
                })}
              />
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12" style={{ textAlign: "right" }}>
              <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={() => {
                  let values = [];
                  if (this.state.selected && this.state.selected.length !== 0) {
                    values = this.state.selected.map(val => {
                      return val.value;
                    });
                  }
                  this.props.hidePropModal();
                  this._hideMapModal();
                  this.props.setProductSpecs(this.state.selected);
                }}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={() => {
                  this.props.hidePropModal();
                  this._hideMapModal();
                }}
              >Cancel</button>
            </div>
          </div>
      </React.Fragment>
    )
  };

  
  render() {
    return (
      <React.Fragment>
          {this.state.showMapModal && this._renderMapModal()}
      </React.Fragment>
    );
  }
}

export default DynamicProductGroup;
