import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import {
    crudReset,
    updateStoreGroup,
    createStoreGroup,
} from '../../../../actions/actions';
import ConfirmAlert from '../../../../components/ConfirmAlert';

import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const statusConfigOptions = [
    { name: 'New', code: 'New' },
    { name: 'Active', code: 'Active' },
    { name: 'Inactive', code: 'Inactive' },
    { name: 'Deleted', code: 'Deleted' },
];

const AddStoreGroup = (props) => {
    const { location } = props;
    const { isSuccess } = props.crudData;
    const { state } = location;
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [record, setRecord] = useState({});

    useEffect(() => {
        if (state) {
            const { isEdit, isView, item } = state;

            if (isEdit) {
                setIsEditMode(true);
            }

            if (isView) {
                setIsViewMode(true);
            }

            if (item && item.id) {
                setRecord(item);
            }
        }
    }, [state]);

    const formik = useFormik({
        initialValues: {
            title: record?.title || '',
            groupid: record?.groupid || '',
            companyid: 'C00001',
            status:
                record && record.status
                    ? statusConfigOptions.find(
                          (item) => item.code === record.status
                      )
                    : statusConfigOptions.find(
                          (status) => status.code == 'New'
                      ),
        },
        validate: (data) => {
            let errors = {};
            if (!data.title) {
                errors.title = 'Please enter a title';
            }
            if (!data.groupid) {
                errors.groupid = 'Please enter a valid group id';
            }
            return errors;
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
                status: values.status ? values.status.code : '',
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleSubmit = (data) => {
        if (isEditMode) {
            props.dispatch(updateStoreGroup({ body: data }));
        } else {
            props.dispatch(createStoreGroup({ body: data }));
        }
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="field col-6 md:col-6 mb-2">
                    <label
                        htmlFor="title"
                        className={classNames({
                            'p-error': isFormFieldValid('title'),
                        })}
                    >
                        Title*
                    </label>
                    <InputText
                        id="title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        readOnly={isViewMode}
                    />
                    {getFormErrorMessage('title')}
                </div>
                <div className="field col-6 md:col-6 mb-2">
                    <label
                        htmlFor="groupid"
                        className={classNames({
                            'p-error': isFormFieldValid('groupid'),
                        })}
                    >
                        Group Id*
                    </label>
                    <InputText
                        id="groupid"
                        name="groupid"
                        value={formik.values.groupid}
                        onChange={formik.handleChange}
                        readOnly={isViewMode}
                    />
                    {getFormErrorMessage('groupid')}
                </div>
            </>
        );
    };

    const renderMainButton = () => {
        if (isViewMode) {
            return (
                <Button
                    label="Close"
                    className="p-button-secondary p-button-lg"
                    onClick={props.history.goBack}
                    type="button"
                />
            );
        }
        return (
            <>
                <Button
                    className="p-button-success p-button-lg mr-2 "
                    label={isEditMode ? 'Update' : 'Save'}
                    type="submit"
                />

                <Button
                    type="button"
                    label="Cancel"
                    className="p-button-secondary p-button-lg"
                    onClick={props.history.goBack}
                />
            </>
        );
    };

    const hideModal = (e) => {
        e.preventDefault();
        props.dispatch(crudReset());
    };

    const renderStatusDetails = () => {
        return (
            <div className="formgrid grid p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                <div className="field col flex flex-column">
                    <label htmlFor="status">Status</label>
                    <Dropdown
                        id="status"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        options={statusConfigOptions}
                        optionLabel="name"
                        placeholder="Select"
                        disabled={isViewMode}
                    />
                </div>
            </div>
        );
    };

    const renderErrorMessage = () => {
        const { isSuccess, errormsg } = props.crudData;
        if (errormsg && !isSuccess) {
            return (
                <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ marginBottom: '0px' }}
                >
                    {errormsg ? errormsg : ''}
                </div>
            );
        }
        return;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height">
                    <div className="grid px-3">
                        <div className="col-12">
                            <h2 className="page-header">
                                {isEditMode
                                    ? 'Edit Store Group'
                                    : 'Add Store Group'}
                            </h2>
                        </div>
                    </div>
                    <div className="grid px-3">
                        <div className="col-12">{renderErrorMessage()}</div>
                    </div>
                    <div className="grid mb-4 px-3">
                        <div className="col-9">
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                                {renderFormArea()}
                            </div>
                        </div>
                        <div className="col-3">{renderStatusDetails()}</div>
                    </div>
                    <div className="grid form-container">
                        <div className="grid btn-container form-button w-100">
                            <div className="col-12">{renderMainButton()}</div>
                        </div>
                    </div>
                </div>
            </form>
            <ConfirmAlert
                show={isSuccess}
                handleClose={hideModal}
                to="/ui/storegroups"
                children={
                    <div style={{ padding: '2em', color: 'green' }}>
                        {isEditMode
                            ? 'Store group updated successfully'
                            : 'Store group created successfully'}
                    </div>
                }
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStoreGroup);
