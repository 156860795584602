import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import { searchGeneral, deleteItem, crudReset } from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from './../../../components/Loading';
import PrimeTable from '../../../components/PrimeTable';

class SalesCategory extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'ADMIN';
        this.group = 'DE_ADMIN';
        this.allPermissions = [];

        this.state = {
            openDeleteprompt: false,
            delete_rec_id: '',
            search_field: 'pid',
            search_condi: 'eq',
            search_value: '0',
            pages: 10,
            currPage: 1,
        };
    }

    actionTemplate = row => {
      const permissions = localStorage.getItem('permission');
        //if (row.status !== 'Deleted' && permissions.split(',').includes('W')) {
            return (
                <span>
                    <Link
                        to={{
                            pathname: '/ui/sales-category/addNew',
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteProdCateory(row)}
                    />
                </span>
            );
        //}
    }
    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('SEARCH')
        );
    }

    // API Calls
    createFetchUrl = (pages, currPage) => {
        const { search_field, search_condi, search_value } = this.state;
        const requestBody = {
            body: {
                pagesize: pages,
                pageno: currPage,
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
            },
        };
        return requestBody;
    };

    callFetch = () => {
        const { pages, currPage } = this.state;
        this.props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                this.createFetchUrl(pages, currPage),
                'SALES_SEARCH_SUCCESS',
                'SALES_SEARCH_FAILURE'
            )
        );
    };

    handlePageSizeChange = pagesize => {
      const { currPage } = this.state;
      this.setState({ pages: pagesize})
      this.props.dispatch(
          searchGeneral(
              'sales/v1/category/search',
              this.createFetchUrl(pagesize, currPage),
              'SALES_SEARCH_SUCCESS',
              'SALES_SEARCH_FAILURE'
          )
      );
    }

    pageChangeCallback = pageno => {
      const { pages } = this.state;
      this.setState({ currPage: pageno})
      this.props.dispatch(
          searchGeneral(
              'sales/v1/category/search',
              this.createFetchUrl(pages, pageno),
              'SALES_SEARCH_SUCCESS',
              'SALES_SEARCH_FAILURE'
          )
      );
    }

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                const [pgNumber] = rest;

                newState = produce(this.state, (draft) => {
                    draft.currPage = pgNumber - 1;
                });
                this.setState({ ...newState }, () => {
                    this.callFetch('PAGECLICK');
                });

                break;
            default:
                break;
        }
    };
    searchQuery = (val1, val2, val3) => {
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => this.callQueryApiForFetch('SEARCH')
        );
    };
    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteItem(
                    'sales/v1/category/delete/',
                    this.state.delete_rec_id
                )
            );
        }
    };
    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    deleteProdCateory = (rowData) => {
        this.setState({ message: 'Sales Category deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _renderHeader = () => {
        return (
            <div className="row mb-2">
                <div className="col-lg-6 col-sm-12">
                    <h1 className="page-header">
                        Sales Category
                        <img
                            title="Refresh"
                            onClick={() => {
                                this.callQueryApiForFetch('ONLOAD');
                            }}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                        />
                    </h1>
                </div>
                <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                    {this._renderAddNewBtn()}
                </div>
            </div>
        );
    };
    _renderAddNewBtn = () => {
        const { history } = this.props;
        const permissions = localStorage.getItem('permission');
        return (
            <Button
                label="Add New"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/sales-category/addNew')}
                //disabled={!permissions.split(',').includes('W')}
            />
        );
    };
    render() {
        const { isSuccess, errormsg } = this.props.crudData;
        const { salesCategoryList } = this.props.queryData;
        
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'id', header: 'Id' },
            { field: 'categorycode', header: 'Category Code' },
            { field: 'title', header: 'Company TIN' },
            { field: 'pid', header: 'Parent Id' }
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    {this._renderHeader()}
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <form className="formDiv">
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    tableRows={this.state.pages}
                                    list={salesCategoryList}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    pageSizeChangecallback={this.handlePageSizeChange}
                                    pageChangeCallback={this.pageChangeCallback}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div style={{ color: 'green', padding: '2em' }}>
                                Are you sure you want to delete this sales
                                category?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(SalesCategory);
