import React, { Component } from "react";
import { renderFilter } from "./../helpers/Util";

const defaultchecked = [
  { id: "Y", name: "Yes" },
  { id: "N", name: "No" }
];
class DynamicTableAddONs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;
    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  renderRowData = (column, row, col, index, rowId, rowleng) => {
    const groups = renderFilter(this.props.groups || [{}]);

    if (column.addRow) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{ width: "30em", verticalAlign: "initial" }}
        >
          <input
            type="text"
            value={row[column.dataFieldId]}
            placeholder={
              column.dataFieldId.charAt(0).toUpperCase() +
              column.dataFieldId.slice(1)
            }
            disabled={column.disabled}
            onChange={event =>
              this.props.handleInputChange(event, column.dataFieldId, row)
            }
          />
        </td>
      );
    }
    if (column.group) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{ width: "50em", verticalAlign: "initial" }}
        >
          <select
            name="example"
            className="form-control"
            value={row[column.dataFieldId]}
            onChange={event =>
              this.props.handleInputChange(event, column.dataFieldId, row)
            }
          >
            {groups.length !== 0 &&
              groups.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </td>
      );
    }
    if (column.check) {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{ width: "30em", verticalAlign: "initial" }}
        >
          <select
            name="example"
            className="form-control"
            value={row[column.dataFieldId]}
            onChange={event =>
              this.props.handleInputChange(event, column.dataFieldId, row)
            }
            disabled={column.disabled}
          >
            {defaultchecked.length !== 0 &&
              defaultchecked.map((option, index) => {
                const { name, id } = option;
                return (
                  <option key={index} value={id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </td>
      );
    }

    if (col === "AD") {
      return (
        <td
          key={`tableview-td-${rowId}-${index}`}
          style={{ width: "30em", verticalAlign: "initial" }}
        >
          <img
            className="addBtn1"
            onClick={this.props.addRow}
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
          />

          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row)}
            className="addBtn2"
          />
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <React.Fragment>
        {this.props.rows.length === 0 ? (
          <button
            className="addNewRow"
            onClick={event => {
              if (event) event.preventDefault();
              this.props.addRow();
            }}
          >
            Add New
          </button>
        ) : (
          <div className="dynamicTableAddon">
            <table>
              {this.tableHeaders()}
              {this.tableBody()}
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default DynamicTableAddONs;
