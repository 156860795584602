import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { promotionPGroupSearchByFields } from "../../../actions/actions";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import ProductSearch from "./ProductSearch";
import Modal from "react-awesome-modal";

const productSelectOption = [
    { id: "DEP", name: "Disc on each purchase" },
    { id: "DSEP", name: "Disc split on each purchase" },
    { id: "DSP", name: "Disc on selected products" },
    { id: "DSSPEP", name: "Disc split on selected products & each purchase" },
    { id: "DCP", name: "Disc on cheapest purchase" },
];

const TargetGroupSelector = (props) => {
    const { storeid, isEditMode } = props;

    const { promotionspurchasegroups, promotionstargetgroups } =
        props.queryData;

    const [selectedPromotionGroup, setSelectedPromotionGroup] = useState({});
    const [filteredCountries, setFilteredCountries] = useState(null);
    const [selectedPromoGroupCategories, setSelectedPromoGroupCategories] =
        useState([]);

    const [showProductInput, setShowProductInput] = useState(false);
    const [productcodes, setProductcodes] = useState([]);
    const _isViewInEditMode = false;
    const [productcodeserror, setProductcodeserror] = useState(false);
    const [enteredProducts, setEnteredProducts] = useState("");
    //const [productselection, setProductselection] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        props.dispatch(
            promotionPGroupSearchByFields(
                `storeid=${props.storeid}&status=Active`
            )
        );
    }, [storeid]);

    const searchCountry = (event) => {
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...promotionspurchasegroups];
            } else {
                _filteredCountries = promotionspurchasegroups.filter(
                    (country) => {
                        return country.title
                            .toLowerCase()
                            .startsWith(event.query.toLowerCase());
                    }
                );
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    };

    const itemTemplate = (item) => {
        return (
            <div className="country-item">
                <div>{item.title}</div>
            </div>
        );
    };

    const handleSearch = (record) => {
        if (record && record.id) {
            setSelectedPromotionGroup(record);
            props.setPromopurchasecode(record.promopurchasecode);
            const { categorycodes } = record;
            const categories =
                categorycodes.trim().length > 0
                    ? categorycodes.includes(",")
                        ? categorycodes.split(",")
                        : [...new Array(categorycodes)]
                    : [];
            setSelectedPromoGroupCategories(categories);
        }
    };

    const showAddProductControl = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({ showProductInput: true }, () => {});
    };

    const _updateSelectedProducts = (selectedProducts) => {
        if (selectedProducts === "") {
            return;
        }
        let newlyAddedProducts = selectedProducts.filter(
            (e) => !productcodes.includes(e)
        );
        setProductcodes(newlyAddedProducts);
        props.setTargetProductcodes(newlyAddedProducts);
    };

    const validateStoreSelection = () => {
        // if (this.props.storeid !== '') {
        //     return true
        // } else {
        //     this.setState({ storeiderror: 'select store' })
        //     return false
        // }
        return true;
    };

    const _renderAddProductControl = () => {
        return (
            <Modal
                visible={showProductInput}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => setShowProductInput(false)}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">Product Codes</div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={() => setShowProductInput(false)}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea
                                className="form-control"
                                id="enteredProducts"
                                value={enteredProducts}
                                onChange={(event) =>
                                    setEnteredProducts(event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        {_renderAddProductsActionButton()}
                    </div>
                </div>
            </Modal>
        );
    };

    const _updateEnteredProducts = () => {
        if (enteredProducts === "") {
            setShowProductInput(false);
            return;
        }
        var selectedProducts = enteredProducts.trim().split(",");
        selectedProducts = selectedProducts.map((e) => e.trim());
        var newlyAddedProducts = selectedProducts.filter(
            (e) => !productcodes.includes(e)
        );
        if (newlyAddedProducts.length <= 0) {
            setEnteredProducts("");
            setShowProductInput(false);
            return;
        }

        setProductcodes(productcodes.concat(newlyAddedProducts));
        props.setTargetProductcodes(productcodes.concat(newlyAddedProducts));
        setEnteredProducts("");
        setShowProductInput(false);
    };

    const _renderAddProductsActionButton = () => {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="btn btn-themes btn-rounded"
                    onClick={() => {
                        _updateEnteredProducts();
                    }}
                >
                    Add
                </button>

                <button
                    type="button"
                    className="btn btn-themes btn-rounded"
                    onClick={() => setShowProductInput(false)}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    const _renderProductConfiguration = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 noPadding noMargin promoPeriods">
                        <div
                            className="promoPeriods"
                            style={{
                                minHeight: 100,
                                margin: "1em",
                                background: "#fff",
                            }}
                        >
                            {_renderSelectedProducts()}
                        </div>
                        {!_isViewInEditMode && (
                            <div className="row">
                                <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                                <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                                <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                    <button
                                        className="page-header-btn icon_btn display-inline"
                                        onClick={(event) =>
                                            setShowProductInput(true)
                                        }
                                        type="button"
                                    >
                                        <img
                                            title="edit"
                                            className="tableImage"
                                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                        />
                                        Add Product Code
                                    </button>
                                </div>
                                <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                    <ProductSearch
                                        storeid={storeid}
                                        nodes={props.nodes}
                                        onSelectedProducts={
                                            _updateSelectedProducts
                                        }
                                        validateStoreSelection={
                                            validateStoreSelection
                                        }
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="invalid-feedback">
                            {productcodeserror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const _renderSelectedProducts = () => {
        if (productcodes.length <= 0) {
            return;
        }

        var contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "150px",
        };

        return (
            <div className="row" style={{ marginTop: "0.6em" }}>
                <div style={contentareastyle}>
                    {productcodes.map((s) => {
                        return (
                            <div
                                key={s}
                                style={{
                                    padding: "2px 2px",
                                    margin: "2px 2px",
                                    borderRadius: "4px",
                                    background: "#347bf6",
                                }}
                            >
                                <label style={{ color: "white" }}>{s}</label>
                                {!_isViewInEditMode && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            _removeSelectedProduct(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const _removeSelectedProduct = (e, s) => {
        if (e) {
            e.preventDefault();
        }
        let selectedProducts = productcodes;
        selectedProducts = selectedProducts.filter((e) => e != s);
        setProductcodes(selectedProducts);
    };

    const handleProductionSelecitonChange = (e) => {
        props.setProductselection(e.target.value);
    };

    const renderCreateMode = () => {
        return (
            <div className="pv-20">
                <div className="row ph-20" style={{ marginBottom: "16px" }}>
                    <div className="field form-group flex flex-column">
                        <label htmlFor="storeid">Purchase Group Code</label>
                        <AutoComplete
                            value={
                                selectedPromotionGroup
                                    ? selectedPromotionGroup.title
                                    : ""
                            }
                            suggestions={filteredCountries}
                            completeMethod={searchCountry}
                            field="title"
                            dropdown
                            forceSelection
                            itemTemplate={itemTemplate}
                            onChange={(e) => handleSearch(e.value)}
                            aria-label="Countries"
                            disabled={isEditMode}
                        />
                    </div>
                </div>
                <div className="formgrid grid" style={{ margin: "0px" }}>
                    <div className="field col flex flex-column">
                        <label htmlFor="targetGroupTitle">Title</label>
                        <InputText
                            id="targetGroupTitle"
                            type="text"
                            value={props.targetGroupTitle}
                            onChange={(e) =>
                                props.setTargetGroupTitle(e.target.value)
                            }
                        />
                    </div>
                    <div className="field col flex flex-column"></div>
                </div>
                {!isEditMode && (
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <Panel
                                header="Product Codes"
                                className="no-padding"
                            >
                                {_renderProductConfiguration()}
                            </Panel>
                        </div>
                    </div>
                )}
                <div
                    className="formgrid grid"
                    style={{ margin: "0px", marginTop: "20px" }}
                >
                    <div className="field col flex flex-column">
                        <label className="w-100" htmlFor="status">
                            Product Selection *
                        </label>
                        <Dropdown
                            className="w-100"
                            id="productselection"
                            name="productselection"
                            value={props.productselection}
                            onChange={(e) => handleProductionSelecitonChange(e)}
                            options={productSelectOption}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col flex flex-column"></div>
                </div>
            </div>
        );
    };

    const renderViewMode = () => {
        return (
            <div className="pv-20">
                <div className="row ph-20" style={{ marginBottom: "16px" }}>
                    <div className="field form-group flex flex-column">
                        <label htmlFor="storeid">Purchase Group Code</label>
                        <InputText
                            id="targetGroupTitle"
                            type="text"
                            value={
                                promotionstargetgroups.length > 0
                                    ? promotionstargetgroups[0]
                                          .promopurchasecode
                                    : ""
                            }
                            disabled
                        />
                    </div>
                </div>
                <div className="formgrid grid" style={{ margin: "0px" }}>
                    <div className="field col flex flex-column">
                        <label htmlFor="targetGroupTitle">Title</label>
                        <InputText
                            id="targetGroupTitle"
                            type="text"
                            value={
                                promotionstargetgroups.length > 0
                                    ? promotionstargetgroups[0].title
                                    : ""
                            }
                            disabled
                        />
                    </div>
                    <div className="field col flex flex-column"></div>
                </div>
                <div
                    className="formgrid grid"
                    style={{ margin: "16px 0px 0px" }}
                >
                    <div className="field col flex flex-column">
                        <label className="w-100" htmlFor="status">
                            Product Selection
                        </label>
                        <InputText
                            id="targetGroupTitle"
                            type="text"
                            value={
                                promotionstargetgroups.length > 0
                                    ? promotionstargetgroups[0].productselection
                                    : ""
                            }
                            disabled
                        />
                    </div>
                    <div className="field col flex flex-column"></div>
                </div>
            </div>
        );
    };
    return (
        <>
            {props.isViewMode || props.isEditMode
                ? renderViewMode()
                : renderCreateMode()}
            {_renderAddProductControl()}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(TargetGroupSelector);
