import React from 'react';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ProductCategorySelector from '../../../components/ProductCategorySelector';

const statusOptions = [
    { name: 'Select', code: '' },
    { name: 'Active', code: 'active' },
    { name: 'New', code: 'new' },
    { name: 'Updated', code: 'updated' },
    { name: 'Deleted', code: 'deleted' },
    { name: 'Inactive', code: 'inactive' },
];

const ProductFilter = (props) => {
    const { handleSearch, productTypeOptions, productcategorytreeList } = props;
    const formik = useFormik({
        initialValues: {
            title: '',
            productcode: '',
            ptype: '',
            status: '',
            categorycode: ''
        },
        onSubmit: (values) => {
            //setFormData(values);
            const data = {
                ...values,
                ptype: values.ptype ? values.ptype.id : '',
                status: values.status ? values.status.code : ''
            };
            handleSearch(data);
        },
    });

    const onSelectProductCategory = categorycode => {
        formik.setFieldValue('categorycode', categorycode);
    }
    const renderFormArea = () => {
        return (
            <>
                <div className="grid p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-6 md:col-6 mb-2">
                        <label htmlFor="category">Category</label>
                        <ProductCategorySelector
                            productcategorytreeList={productcategorytreeList}
                            onSelectProductCategory={onSelectProductCategory}
                        />
                    </div>
                    <div className="p-form-group field col-6 md:col-6 mb-2"></div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="title">Title</label>
                        <InputText 
                            id="title" 
                            value={formik.values.title}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-6 md:col-6 mb-2">
                        <label htmlFor="productcode">Product Code</label>
                        <InputText 
                            id="productcode" 
                            value={formik.values.productcode}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="ptype">Product Type</label>
                        <Dropdown
                            id="ptype"
                            name="ptype"
                            value={formik.values.ptype}
                            onChange={formik.handleChange}
                            options={productTypeOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3 mb-2"
                        style={{ display: 'flex'}}
                    >
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="p-button-success mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default ProductFilter;
