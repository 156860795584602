import React, {useEffect, useState} from "react";
import { Dialog } from "primereact/dialog";
import { promotionPGroupSearchByFields } from "../../../actions/actions";

const productSelectOption = [
    { id: "DEP", name: "Disc on each purchase" },
    { id: "DSEP", name: "Disc split on each purchase" },
    { id: "DSP", name: "Disc on selected products" },
    { id: "DSSPEP", name: "Disc split on selected products & each purchase" },
    { id: "DCP", name: "Disc on cheapest purchase" },
];

const PromoConfirmDialog = (props) => {
    const {
        payloadData,
        renderFooter,
        showConfirmDialog,
        setShowConfirmDialog,
        storeid
    } = props;
    const { body } = payloadData;
    const { promotionspurchasegroups, promotionstargetgroups } = props.queryData;
    const [selectedPcgp, setSelectedPcgp] = useState({})

    useEffect(() => {
        props.dispatch(
            promotionPGroupSearchByFields(
                `storeid=${props.storeid}&status=Active`
            )
        );
    }, [storeid]);

    useEffect(() => {
        if (promotionstargetgroups.length > 0 && promotionspurchasegroups.length > 0) {
            const purchaseGroup = promotionspurchasegroups.find(pg => pg.promopurchasecode == promotionstargetgroups[0].promopurchasecode)
            setSelectedPcgp(purchaseGroup)
        }
    }, [promotionstargetgroups, promotionspurchasegroups])

    const renderProductTargetType = () => {
        if (promotionstargetgroups.length > 0 && promotionstargetgroups[0].productselection) {
            const productType = productSelectOption.find(
                (cust) => cust.id == promotionstargetgroups[0].productselection
            )
            return productType.name
        }
        return ""
    }
    
    return (
        <>
            <Dialog
                header="Summary"
                visible={showConfirmDialog}
                style={{ width: "50vw" }}
                footer={renderFooter()}
                onHide={() => setShowConfirmDialog(false)}
                className="no-padding"
            >
                <div className="flex w-100" style={{ background: "#F5F6FA" }}>
                    <div className="col-4 md:col-4">
                        <h6>Basic information</h6>
                    </div>
                    <div className="col-4 md:col-4">
                        <h6>Discount details</h6>
                    </div>
                    <div className="col-4 md:col-4">
                        <h6>Other details</h6>
                    </div>
                </div>
                <div className="flex w-100">
                    <div className="col-4 md:col-4 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Store Id:</label>
                                <span className="f-12 fw-500">
                                    {payloadData.body.storeid
                                        ? body.storeid
                                        : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Promo Code:</label>
                                <span className="f-12 fw-500">
                                    {body.promocode ? body.promocode : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Promo Name:</label>
                                <span className="f-12 fw-500">
                                    {body.title ? body.title : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Promo Group:
                                </label>
                                <span className="f-12 fw-500">
                                    {body.promogroup ? body.promogroup : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Promo Type:</label>
                                <span className="f-12 fw-500">
                                    {body.promotype ? body.promotype : ""}
                                </span>
                            </li>
                            {body.promotypevalue && (
                                <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Coupon Code:</label>
                                <span className="f-12 fw-500">
                                    {body.promotypevalue ? body.promotypevalue : ""}
                                </span>
                            </li>
                            )}
                            
                        </ul>
                    </div>
                    <div className="col-4 md:col-4 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Discount Value:
                                </label>
                                <span className="f-12 fw-500">
                                    {body.discountvalue
                                        ? body.discountvalue
                                        : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Discount Cap Amount:
                                </label>
                                <span className="f-12 fw-500">
                                    {body.discountcapamt
                                        ? body.discountcapamt
                                        : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Tax Discount:
                                </label>
                                <span className="f-12 fw-500">
                                    {props.isItTaxable ? 'On' : 'Off'}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Batch:</label>
                                <span className="f-12 fw-500">
                                    {(body.discountbatch && body.discountbatch == 'Y') ? 'On' : 'Off'}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Batch Value:
                                </label>
                                <span className="f-12 fw-500">
                                    {body.discountbatchvalue ? body.discountbatchvalue : ""}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-4 md:col-4 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Price Segment:
                                </label>
                                <span className="f-12 fw-500">
                                    {props.pricesegments &&
                                    props.pricesegments.label
                                        ? props.pricesegments.label
                                        : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Priority:</label>
                                <span className="f-12 fw-500">
                                    {body.priority}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Weightage:</label>
                                <span className="f-12 fw-500">
                                    {body.priorityweight}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Exclude discount:
                                </label>
                                <span className="f-12 fw-500">
                                    {props.excludediscount}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Status:</label>
                                <span className="f-12 fw-500">
                                    {body.status ? body.status : ""}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex w-100" style={{ background: "#F5F6FA" }}>
                    <div className="col-4 md:col-4">
                        <h6>Promotion Period</h6>
                    </div>
                </div>
                <div className="flex w-100">
                    <div className="col-4 md:col-4 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Start Date:</label>
                                <span className="f-12 fw-500">
                                    {body.validfrom ? body.validfrom : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">End Date:</label>
                                <span className="f-12 fw-500">
                                    {body.validtill ? body.validtill : ""}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">
                                    Active Days:
                                </label>
                                <span className="f-12 fw-500">
                                    {props.activeDays}
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Start Time:</label>
                                <span className="f-12 fw-500">00:00</span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">End Time:</label>
                                <span className="f-12 fw-500">23:59</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {body.promotype == "Item" && (
                    <>
                        <div
                            className="flex w-100"
                            style={{ background: "#F5F6FA" }}
                        >
                            <div className="col-4 md:col-4">
                                <h6>Purchase Group</h6>
                            </div>
                        </div>
                        <div className="flex w-100">
                    <div className="col-6 md:col-6 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Purchase Group:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.title ? selectedPcgp.title : "" }
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Validation Level:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.validatelevel ? selectedPcgp.validatelevel : "" }
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Product Codes:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.productcodes ? selectedPcgp.productcodes : "" }
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Product Codes:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.productcodes ? selectedPcgp.productcodes : "" }
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 md:col-6 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Amount Min:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.amountmin ? selectedPcgp.amountmin : "0" }
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Amount Max:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.amountmax ? selectedPcgp.amountmax : "" }
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Qty Min:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.qtymin ? selectedPcgp.qtymax : "" }
                                </span>
                            </li>
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Qty Max:</label>
                                <span className="f-12 fw-500">
                                    {selectedPcgp && selectedPcgp.qtymax ? selectedPcgp.qtymax : "" }
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                    </>
                )}
                {body.promotype == "Item" && (
                    <>
                        <div
                            className="flex w-100"
                            style={{ background: "#F5F6FA" }}
                        >
                            <div className="col-4 md:col-4">
                                <h6>Target Group</h6>
                            </div>
                        </div>
                        <div className="flex w-100">
                    <div className="col-12 md:col-12 ">
                        <ul
                            className="list-none p-0 mt-12 flex-grow-1"
                            style={{ marginTop: "8px" }}
                        >
                            <li className="flex align-items-center mb-1">
                                <label className="mr-2 f-12">Product Selection:</label>
                                <span className="f-12 fw-500">
                                    {promotionstargetgroups.length > 0 ? renderProductTargetType() : ""}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default PromoConfirmDialog;
