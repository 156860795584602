import React, { PureComponent } from "react";

class SimpleSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selectedOption ? this.props.selectedOption : ""
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ selectedOption: nextProps.selectedOption });
  }
  dropDownChange = event => {
    this.setState({ selectedOption: event.target.value });
    this.props.callbackChangeFilter(event.target.value, event);
  };
  render() {
    const { objArray, cssClass, disabled } = this.props;
    var formattedObjArray = { "0": "Select" };
    if (objArray != undefined) Object.assign(formattedObjArray, objArray);
    return (
      <div className="filterClass form-filter">
        <select
          id="statusComp"
          className={`form-control ${cssClass ? cssClass : ""}`}
          disabled={disabled ? true : false}
          value={this.state.selectedOption}
          onChange={this.dropDownChange}
          style={this.props.style ? this.props.style : {}}
        >
          {formattedObjArray != undefined ? (
            Object.entries(formattedObjArray).map((t, i) => (
              <option key={i} value={t[0]}>
                {t[1]}
              </option>
            ))
          ) : (
            <option>No option</option>
          )}
        </select>
      </div>
    );
  }
}

export default SimpleSelect;
