
import React from "react";
import { Chart } from "chart.js";
class StackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    // this.myChart.data.labels = this.props.labels;
    // this.myChart.data.datasets = this.props.data;
    // this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "bar",
      options: {
        maintainAspectRatio: false,
        legend: { display: true, position: "bottom" },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [
            /*  { stacked: true },
            {
              ticks: {
                min: 0,
                max: 100
              }
            } */
            {
              stacked: true,
              position: "left",
              id: "y-axis-0"
            } /* ,
            {
              stacked: false,
              position: "right",
              id: "y-axis-1"
            } */
          ]
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data
      }
    });
  }

  render() {
    return (
      <div className="chartCanvas">
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}
export default StackedBarChart;