import React, { useEffect } from 'react';
import { connect } from "react-redux";
import {
    searchGeneral
} from '../../../actions/actions';

const ProductTypeSelector = props => {
    useEffect(() => {
        props.dispatch(
            searchGeneral(
                '/sales/v1/category/search',
                {
                    body: {
                        search_field: 'pid',
                        search_condi: 'eq',
                        search_value: 181,
                    },
                },
                'FETCH_PROD_SPEC_SUCCESS',
                'FETCH_PROD_SPEC_FAILURE',
            ),
        );
    }, [])
    const { prodSpecList } = props.queryData;
    let prodType = prodSpecList;
    console.log('detect', prodSpecList)
    return (
        <>selector</>
    )
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ProductTypeSelector);