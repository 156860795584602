import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userid: '',
            password: '',
            fuserid: '',
            newPasswrd: '',
            confirmPasswrd: '',
            forgotPassword: false,
            rememberMe: false,
            showpassword: false,
            is2FAuth: this.props.data.auth_2f ? this.props.data.auth_2f : false,
        };
    }
    componentWillReceiveProps = (nextProps) => {
        this.setState({ is2FAuth: nextProps.data.auth_2f });
    };
    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };
    toggleRememberMe() {
        this.setState({
            rememberMe: !this.state.rememberMe,
        });
    }
    componentDidMount = () => {
        const rememberMe = localStorage.getItem('rememberMe');
        const userid = rememberMe ? localStorage.getItem('userid') : '';
        const password = rememberMe ? localStorage.getItem('password') : '';
        this.setState({ userid, password });
    };
    onSubmit = (e) => {
        e.preventDefault();
        const { userid, password, rememberMe } = this.state;
        localStorage.setItem('rememberMe', rememberMe);
        localStorage.setItem('userid', rememberMe ? userid : '');
        localStorage.setItem('password', rememberMe ? password : '');

        this.props.loginToDashboard(
            userid == null ? userid : userid.trim(),
            password
        );
    };
    _closeOTPModal = () => {
        this.setState({ is2FAuth: false });
    };
    _setOTP = () => {
        this._closeOTPModal();
    };

    onChangePassword = (e) => {
        this.setState({ password: e.target.value });
    };

    _renderLoginForm = () => {
        return (
            <form
                className="card loginCard card-block bg-faded"
                onSubmit={this.onSubmit}
            >
                <h3>LOGIN</h3>
                <h5>Please login to your account</h5>

                <div className="formloginwrapper">
                    <div
                        className="p-float-label"
                        style={{ marginBottom: '10px', marginTop: '20px' }}
                    >
                        <InputText
                            style={{ width: '100%' }}
                            id="userid"
                            value={this.state.userid}
                            onChange={this.onChange}
                            required
                            autoFocus
                        />
                        <label htmlFor="userid">UserId</label>
                    </div>

                    <div
                        className="p-float-label"
                        style={{ marginTop: '20px' }}
                    >
                        <Password
                            style={{ width: '100%' }}
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                            toggleMask
                            feedback={false}
                        />
                        <label htmlFor="password">Password</label>
                    </div>
                    <div className="form-group" style={{ paddingLeft: '12px'}}>
                        <div className="row login_row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-12 rmb01">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="remMe"
                                    onChange={() => this.toggleRememberMe()}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="remMe"
                                >
                                    Remember me
                                </label>
                            </div>
                        </div>
                    </div>
                    {this.props.apiPending === true ? (
                        <div
                            className="text-center btnMargin"
                            style={{ height: '3em' }}
                        >
                            <div className="btn-rounded spinner-border text-success"></div>
                        </div>
                    ) : (
                        <div className="text-center">
                            <Button
                                type="submit"
                                label="Login"
                                className="p-button-success p-button-lg"
                                severity="success"
                                style={{ marginTop: '20px', width: '200px' }}
                            />
                        </div>
                    )}
                </div>
            </form>
        );
    };
    render() {
        const { loginErrorMsg } = this.props.data;

        return (
            <div className="container-fluid">
                <div className="row login_row fullHeight">
                    <div className="col-lg-6 col-xs-12 leftSpace" />
                    <div className="col-lg-6 col-xs-12 noMargin noPadding">
                        <div
                            id="login-row"
                            className="row login_row login-class "
                        >
                            <div id="login-column" className="col-12">
                                <div id="login-box">
                                    {this._renderLoginForm()}
                                    {loginErrorMsg && (
                                        <div className="invalid-feedback-log">
                                            {loginErrorMsg}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 loginFooter">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/icons/toshiba-01.svg`}
                                        className="login_footer_img"
                                    />
                                    <span className="login_footer_text">
                                        {new Date().getFullYear()} © Toshiba
                                        Global Commerce Solutions
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Login.propTypes = {
    userid: PropTypes.string,
    password: PropTypes.string,
};

export default Login;
