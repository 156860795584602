import React from "react";
import Modal from "react-awesome-modal";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateColorPicker = this.updateColorPicker.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      currentcolor: [
        "#531CB3",
        "#7149EE",
        "#B754FF",
        "#ED4FEF",
        "#ED49AB",
        "#ED4FEF",
        "#B754FF",
        "#7149EE"
      ],
      content_add: "",
      width: 50,
      myItems: this.props.colorSelected || [],
      background: "#fff"
    };
    this.lastId = -1;
  }
  _closeColorPicker = () => {
    this.setState({
      isColorPickerOpen: false,
      background: "#fff",
      colorSelected: ""
    });
  };
  _showColorPicker = () => {
    this.setState({ isColorPickerOpen: true });
  };
  handleChangeComplete = color => {
    this.setState({ background: color.hex });
  };
  _selectColor = event => {
    if (event) event.preventDefault();

    this.setState({ content_add: this.state.background }, () => {
      console.log(this.state);
      this._closeColorPicker();
      this.updateColorPicker(event);
    });
  };
  _renderColorPickerModal = () => {
    const { background } = this.state;
    return (
      <Modal
        visible={this.state.isColorPickerOpen}
        width="80%"
        height="90%"
        effect="fadeInRight"
        onClickAway={this._closeColorPicker}
      >
        <div className="modalForm colorPickerModalForm">
          <div className="row">
            <div className="col-xs-12 col-md-6" />
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeColorPicker}>X</a>
            </div>
          </div>
          <div className="title-devide" />
          <SketchPicker
            color={background}
            onChangeComplete={this.handleChangeComplete}
          />
          <div className="row btn-container1">
            <div className="col-lg-12 col-md-12 col-sm-12 " />
            <div className="col-lg-12 col-md-12 col-sm-12 alignRight">
              <button
                type="submit"
                onClick={this._selectColor}
                className="btn btn-themes btn-rounded"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  handleFocus(event) {
    this.setState({ content_add: "" });
  }

  handleChange(event) {
    const usr_input = event.target.value;
    this.setState({ content_add: usr_input });
  }

  updateColorPicker(event) {
    var newArray = this.state.myItems;
    var currentcontent = this.state.content_add.trim();
    if (!currentcontent) {
      return;
    }

    // var currentWidth = this.helperspan.offsetWidth;
    newArray.push({
      content: currentcontent,
      id: ++this.lastId,
      itemWidth: 40
    });
    this.setState(
      {
        myItems: newArray,
        content_add: ""
      },
      () => {
        this.makeAddedList();
        this.props.setColor(this.state.myItems);
      }
    );
  }

  handleBlur(event) {
    this.setState({ content_add: " " });
  }

  handleClick(event) {
    const idToRemove = Number(event.target.dataset["item"]);
    const newArray = this.state.myItems.filter(listitem => {
      return listitem.id !== idToRemove;
    });
    this.setState({ myItems: newArray });
  }

  makeAddedList() {
    console.log(this.state.myItems);
    const elements = this.state.myItems.map((listitem, index) => (
      <li
        key={listitem.id}
        onClick={this.handleClick}
        data-item={listitem.id}
        className="colorPickerList"
        style={{
          backgroundColor: listitem.content
        }}
      >
        {/* {listitem.content} */}
      </li>
    ));
    return elements;
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          {this.makeAddedList()}
          <input
            id="add"
            type="text"
            name="initvalue"
            autoComplete="off"
            maxLength="70"
            readOnly={true}
            // onFocus={this.handleFocus}
            // onChange={this.handleChange}
            onClick={this._showColorPicker}
            // onKeyPress={this.handleKeypress}
            // onBlur={this.handleBlur}
            value={this.state.content_add}
            style={{ width: this.state.width }}
            className="colorPicker"
          />
        </div>
        {this._renderColorPickerModal()}
      </React.Fragment>
    );
  }
}
export default ColorPicker;
