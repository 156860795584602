import React, { Component } from "react";
import ReactDOM from "react-dom";

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionArray: [],
      multiSelect: "",
      selectedValues: [],
      showCheckBoxes: false,
      checkedListAll: this._getItems(),
      checkedLabels: [],
      ItemsChecked: false
    };
  }
  _getItems = () => {
    if (this.props.itemsSelected === "") return [];
    else if (this.props.itemsSelected !== "") return this.props.itemsSelected;
    else return [];
  };
  componentDidMount() {
    //  this.props.beforeclick && this.props.beforeclick();
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showCheckBoxes: false
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ checkedListAll: nextProps.itemsSelected });
  }
  selectItem = checked => {
    const collection = [];
    if (checked) {
      this.props.filterOptions.map(function(option) {
        collection.push(option.id);
      });
    }
    this.setState(
      {
        checkedListAll: collection,
        ItemsChecked: checked
      },
      () =>
        this.props.setSelectedFieldValues(
          this.state.checkedListAll,
          this.props.id,
          checked
        )
    );
  };
  handleSelect = (e, id) => {
    const { checked } = e.target;
    if (id === -1) {
      this.selectItem(checked);
    } else {
      if (checked) {
        this.setState(
          prevState => ({
            checkedListAll: [...prevState.checkedListAll, id]
          }),
          () =>
            this.props.setSelectedFieldValues(
              this.state.checkedListAll,
              this.props.id,
              checked
            )
        );
      } else {
        this.setState(
          prevState => ({
            checkedListAll: prevState.checkedListAll.filter(
              checkedId => checkedId !== id
            )
          }),
          () =>
            this.props.setSelectedFieldValues(
              this.state.checkedListAll,
              this.props.id,
              checked,
              id
            )
        );
      }
    }
  };

  showCheckBoxDiv = () => {
    this.setState({ showCheckBoxes: !this.state.showCheckBoxes });
  };

  renderChkBoxDiv = () => {
    const { filterOptions, id } = this.props;
    const optId = id;
    return (
      <div id="multiChk">
        {filterOptions.map((option, index) => {
          const { name, id } = option;
          return (
            <label
              key={`mul${optId}_${id}_${index}`}
              htmlFor={`mul${optId}_${id}_${index}`}
            >
              <input
                type="checkbox"
                id={`mul${optId}_${id}_${index}`}
                onChange={event => {
                  this.handleSelect(event, id);
                }}
                //checked={this.state.checkedListAll.indexOf(id) >= 0}
                checked={this.state.checkedListAll.includes(id)}
              />
              {`  ${name}`}
            </label>
          );
        })}
      </div>
    );
  };
  getLabelfrmcheckedlist = () => {
    var chck = [];
    let array = [];
    for (var i = 0; i < this.state.checkedListAll.length; i++) {
      chck = this.props.filterOptions.map(checked => {
        if (checked.id === this.state.checkedListAll[i]) {
          array.push(checked.name);
          return checked.name;
        }
      });
    }

    if (array.length === 0) {
      return "";
    }

    let string = "";

    for (var i = 0; i < array.length; i++) {
      if (array[i] !== undefined) {
        string = string + array[i] + ",";
      }
    }
    return string;
  };
  render() {
    const itemsLength = this.state.checkedListAll.length;

    let itemstoshow = this.getLabelfrmcheckedlist();
    itemstoshow = itemstoshow.replace(/^,|,$/g, "");
    const filterOptions = this.props;
    const label = this.props.label || "Select";
    if (this.props.filterOptions.length === 0) {
      return (
        <div style={{ width: '100%'}}>
          <div
            className={
              this.props.addclass === true
                ? "multiselect mulclass"
                : "multiselect"
            }
          >
            <div className="selectBox" onClick={this.showCheckBoxDiv}>
              <select>
                <option>No Data</option>
              </select>
              <div className="overSelect" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          className={
            this.props.addclass === true
              ? "multiselect mulclass"
              : "multiselect"
          }
        >
          <div className="selectBox" onClick={this.showCheckBoxDiv}>
            <select>
              <option>{itemstoshow === "" ? label : `${itemstoshow}`}</option>
            </select>
            <div className="overSelect" />
          </div>
          {this.state.showCheckBoxes ? this.renderChkBoxDiv() : ""}
        </div>
      </React.Fragment>
    );
  }
}
MultiSelect.propTypes = {};

export default MultiSelect;
