import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";

class ChartTable extends React.PureComponent {
  constructor(props) {
    super(props);   

  }
  getData =(objArray) =>{
    if(objArray.length>0){
    var allkeys = Object.keys(Object.assign({}, ...objArray));
    var header = allkeys.map(e=>({dataField: e, text: e}));
    return header;
    } return []
  }

  render() {
   const columns = this.getData(this.props.tabledata);
   const rows = this.props.tabledata !== undefined ? this.props.tabledata : [];
   const options = {
    hideSizePerPage: true,
    sizePerPage: 10
    
  };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="table-wrapper">
            {columns.length > 0 && rows.length > 0 && (
            <BootstrapTable
              hover
              condensed
              maxHeight={15}
              id="chart-table"
              keyField="id"
              data={rows}
              columns={columns}
              pagination={paginationFactory(options)}
            />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ChartTable;