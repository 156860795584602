import React, { Component } from "react";
import Select, { components } from "react-select";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

class DynamicGroupRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMapModal: this.props.showProp || false,
      colorselected: this.props.colorselected || [],
      sizeselected: this.props.sizeselected || [],
      prodmodel: "",
      sizeerror: "",
      colorerror: ""
    };
  }

  _hideMapModal = () => {
    this.setState({ showMapModal: false });
  };

  validateField = () => {
    this._clearErrorMessages();
    const {
      colorselected,
      sizeselected
    } = this.state;
    let valid = true;

    if (colorselected.length == 0 || typeof JSON.stringify(colorselected) == "{}") {
      this.setState({
        colorerror: "Please select color"
      });
      valid = false;
    }   
    if (sizeselected.length == 0 || typeof JSON.stringify(sizeselected) == "{}") {
        this.setState({
          sizeerror: "Please select size"
        });
        valid = false;
    }
    return valid;
  }

  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      colorerror: "",
      sizeerror: ""
    });
  };

  handleSubmit = () => {
      const { colorselected, sizeselected } = this.state;
    if (this.validateField() === true) {
        if (colorselected && colorselected.length !== 0 &&
            sizeselected && sizeselected.length !== 0
            ) {
            let model =  colorselected.value+"-"+sizeselected.value;
            this.props.setProductModel(model);
            this.props.hidePropModal();
            this._hideMapModal();
        }
    }
  }

  groupBy = (data, key) => {
    let groupedArray = [];
    const groupoptions = data.reduce(
      (result, item) => ({
        ...result,
        [item["group"]]: [...(result[item["group"]] || []), item]
      }),
      {}
    );
    Object.entries(groupoptions).forEach(value => {
      let item = { label: value[0], options: value[1] };
      groupedArray.push(item);
    });
    return groupedArray;
  }

  _renderMapModal = () => {
    const Group = props => (
      <div style={groupStyles}>
        <components.Group {...props} />
      </div>
    );
    const Option = props => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
            <label style={{ marginLeft: "2px" }}>{props.label}</label>
          </components.Option>
        </div>
      );
    };
    const allOption = {
      label: "Select all",
      value: "*"
    };
    const MultiValue = props => {
      let labelToBeDisplayed = `${props.data.label} `;
      if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All is selected";
      }
      return (
        <components.MultiValue {...props}>
          <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
      );
    };
    
    return(
      <React.Fragment>
            <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-12">
                    <div className="row">
                        <label htmlFor="color" className="floatLeft required">
                            Color
                         </label>
                    </div>
                    <Select
                        value={this.state.colorselected}
                        onChange={value => {
                            this.setState({ colorselected: value });
                        }}
                        options={this.groupBy(this.props.colorprop)}
                        components={{
                            Option,
                            MultiValue,
                            //ValueContainer,
                            //animatedComponents,
                            Group
                        }}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        autosize={true}
                        noResultsText="No Data Found"
                        isMulti={false}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: "#6c90c1",
                                primary25: "#f5f5f5"
                            }
                        })}
                    />
                    <div className="invalid-feedback">{this.state.colorerror}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ height: "10em" }}>
                    <div className="row">
                        <label htmlFor="size" className="floatLeft required">
                            Size
                         </label>
                    </div>
                    <Select
                        value={this.state.sizeselected}
                        onChange={value => {
                            this.setState({ sizeselected: value });
                        }}
                        options={this.groupBy(this.props.sizeprop)}
                        components={{
                            Option,
                            MultiValue,
                            //ValueContainer,
                            //animatedComponents,
                            Group
                        }}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        autosize={true}
                        noResultsText="No Data Found"
                        isMulti={false}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: "#6c90c1",
                                primary25: "#f5f5f5"
                            }
                        })}
                    />
                    <div className="invalid-feedback">{this.state.sizeerror}</div>
                </div>
            </div>

       
        <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12" style={{ textAlign: "right" }}>
              <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={() => {
                   this.handleSubmit();
                  //this.props.setProductSpecs(this.state.selected);
                }}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={() => {
                  this.props.hidePropModal();
                  this._hideMapModal();
                }}
              >Cancel</button>
            </div>
          </div>
      </React.Fragment>
    )
  };

  
  render() {
    return (
      <React.Fragment>
          {this.state.showMapModal && this._renderMapModal()}
      </React.Fragment>
    );
  }
}

export default DynamicGroupRadio;
