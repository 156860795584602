import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { searchGeneral, deleteItem, crudReset } from '../../../actions/actions';

import ConfirmAlert from '../../../components/ConfirmAlert';
import { produce } from 'immer';
import Loading from '../../../components/Loading';
import { Link } from 'react-router-dom';
import { canDelete, canWrite, permissions } from '../../../helpers/Util';
import PrimeTable from '../../../components/PrimeTable';
import PreviewImage from '../../../components/ImageComponents/PreviewImage';
import EditImage from '../../../components/ImageComponents/EditImage';
import DeleteImage from '../../../components/ImageComponents/DeleteImage';

class UserGroups extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_ACCESS_USERGROUPS';
        this.module = 'ACCESS';
        this.allPermissions = []; //permissions()

        this.state = {
            pageSize: 10,
            currPage: 1,

            openDeleteprompt: false,
            delete_rec_id: '',
        };
    }

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/usergroups/addNew',
                            state: { item: row, isView: true },
                        }}
                    >
                        <PreviewImage />
                    </Link>
                )}
                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/usergroups/addNew',
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <EditImage />
                    </Link>
                )}
                {canDelete(this.allPermissions) && (
                    <DeleteImage
                        handleClick={() => this.deleteUserGroup(row)}
                    />
                )}
            </span>
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('SEARCH')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    createFetchUrl = () => {
        const { pageSize, currPage } = this.state;
        const requestBody = {
            body: {
                companyid: this.companyId,
                pagesize: pageSize,
                pageno: currPage,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/user/group/search/fields',
                this.createFetchUrl(fetchType),
                'SEARCH_USERGROUPS_SUCCESS',
                'SEARCH_USERGROUPS_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
                break;
        }
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteItem(
                    'system/v1/user/group/delete/',
                    this.state.delete_rec_id
                )
            );
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    deleteUserGroup = (rowData) => {
        this.setState({ message: 'Usergroup deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _showAsTable = (arg) => {
        this.setState({ showAsTable: arg });
    };

    _renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h1 className="page-header">
                        User Groups
                        <img
                            title="Refresh"
                            onClick={() => {
                                this.callQueryApiForFetch('ONLOAD');
                            }}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                        />
                    </h1>
                </div>
                <div className="col-6 alignRight alignWithPageHeader">
                    {this._renderAddNewBtn()}
                </div>
            </div>
        );
    };

    _renderAddNewBtn = () => {
        const { history } = this.props;
        if (this.allPermissions.includes('Write')) {
            return (
                <Button
                    label="Add New Group"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    onClick={() => history.push('/ui/usergroups/addNew')}
                />
            );
        }
    };

    render() {
        const { isSuccess, errormsg } = this.props.crudData;
        const { userGroups } = this.props.queryData;

        let _userGruops = userGroups.length !== undefined && userGroups;
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'title', header: 'Group Name' },
            { field: 'groupcode', header: 'Group Code' },
            { field: 'status', header: 'Status' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-12">{this._renderHeader()}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="formDiv">
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    list={_userGruops}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div style={{ color: 'green', padding: '2em' }}>
                                Are you sure you want to delete this usergroup?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(UserGroups);
