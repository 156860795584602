import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createArticleInfoApi,
  updateArticleInfoApi,
  deliverycountryConfig,
  crudReset
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusComp from "../../../components/StatusComp";
import { renderOptionsFilter } from "../../../helpers/Util";
import Filter from "./../../../components/Filter";
import SimpleSelect from "../../../components/SimpleSelect";

const tablecols = [
  {
    dataField: "productcode",
    text: "Product Code"  
  },
  {
    dataField: "description",
    text: "Product Name"  
  },
  {
    dataField: "quantity",
    text: "Quantity"  
  },
  {
    dataField: "netprice",
    text: "Net Price"  
  },
  {
    dataField: "taxamount",
    text: "Tax Amount"  
  },
  {
    dataField: "totalamount",
    text: "Total Amount"  
  }
] 

class ReadyToShip extends React.PureComponent { 
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      // Formrelated
      weight: "",
      height: "",
      company: "",
      trackno: "",
      insurance: "",
      contactname: "",
      contactnum : "",
      country: "",
      address: "",
      city: "",
      state: "",
      postalcode: "",
      areacode: "",
      remarks: ""
      // Errors
    };
  }

  getCreatePayload = () => {
    const {
      storeid,
      articletitle,
      title_long,
      description,
      articlecode,
      groupid,
      title,
      language,
      sortorder,
      status,
      images
    } = this.state;
    let body = {
      title: title,
      language: language,
      storeid: storeid,
      articlecode,
      publishmode: "manual",
      articletype: "Product Addon Groups",
      status: status,
      sortorder: sortorder,
      images: {
        icon: images.image1
      }
    };

    const payload = {
      body: body
    };
    return payload;
  };

  componentDidUpdate = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const { productrows } = this.state;
      if(productrows !== rec){
        this.setState({
          productrows: rec
        })
      }
    }
  }

  _createProductAddon = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      articletitleerror: "",
      groupiderror: "",
      titleerror: "",
      title_longerror: "",
      descriptionerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const {
      storeid,
      groupid,
      title,
      title_long,
      description,
      articletitle
    } = this.state;
    let valid = true;
    /*   if (storeid.trim() === "") {
      this.setState({ storeidError: "Store id is required." });
      valid = false;
    }
 */
    if (title.trim() === "") {
      this.setState({ titleerror: "Title is required." });
      valid = false;
    }
    if (groupid.trim() === "") {
      this.setState({ groupiderror: "Group id is required." });
      valid = false;
    }
    /*   if (articletitle.trim() === "") {
      this.setState({ articletitleerror: "Article Title is required." });
      valid = false;
    } */
    if (title_long.trim() === "") {
      this.setState({ title_longerror: "Title long is required." });
      valid = false;
    }
    if (description.trim() === "") {
      this.setState({ descriptionerror: "Description is required." });
      valid = false;
    }

    return valid;
  };

  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _renderMainButton = () => {
    return (
      <React.Fragment>
        {
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            // onClick={() => {
            //   this._createProductAddon();
            // }}
          >
            Ready To ship
          </button>
        }
        <Link
          to="/ui/orders-fulfillment"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };

  _renderTableRows = (rows, headers) => {
    if (rows.length === 0) {
      return (
        <tr style={{ width: "100%" }}>
          <td colspan={6} style={{ textAlign: "center" }}>
            No data found
          </td>
        </tr>
      );
    }
    return rows.map((row, index) => {
      return (
        <tr>
          {headers.map((column, index) => {
            const col = column.dataField.replace(/\s/g, "");
            return (
             <td key={`tableview-td-${index}-${index}`}>
               {row[col]}
             </td>
            );
          })}
        </tr>
      );
    });
  };

  _renderMainFormDetails = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      return (
        <React.Fragment>
          <table class="table table-bordered">
            <thead class="thead-green">
              <tr>
                {tablecols.map((column, index) => {
                  return <th key={`tableview-th-${index}`}>{column.text}</th>;
                })}
              </tr>
            </thead>
            <tbody>{this._renderTableRows(rec, tablecols)}</tbody>
          </table>
        </React.Fragment>
      );
    }
  };

  _renderGeneralInformation = () => {
    const { contactnameerror } = this.state;
    const { deliverycountryList } = this.props.queryData;
    let cnlist = deliverycountryList.map(e=>({title: e.propvalue, value: e.propvalue}));
    let citylist = Object.assign({}, ...deliverycountryList.map(e=>(e.additionalfields)));

    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="contactname" className="floatLeft">
              Contact Name
            </label>
            <input
              type="text"
              className={contactnameerror === "" ? "form-control" : "error-control"}
              id="contactname"
              value={this.state.contactname}
              onChange={event => this.setState({ contactname: event.target.value })}
            /> 
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="contactno" className="floatLeft">
              Contact Number
            </label>
            <input
              type="text"
              className="form-control"
              id="contactno"
              value={this.state.contactno}
              onChange={event => this.setState({ contactno: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="address" className="floatLeft">
              Address
                        </label>
            <input
              type="text"
              className="form-control"
              id="address"
              value={this.state.address}
              onChange={event =>
                this.setState({ address: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="country" className="floatLeft">
              Country
            </label>
            <Filter
              cssClass="form-control"
              filterOptions={renderOptionsFilter(cnlist)}
              selectedOption={this.state.country}
              callbackChangeFilter={event => {
                this.setState({ country: event.target.value })
              }}
              id="country"
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title_long" className="floatLeft">
              City
            </label>
            <SimpleSelect
              objArray={citylist}
              callbackChangeFilter={value =>
                this.setState({ city: value })
              }
              selectedOption={this.state.city}
              id="city"
            />  
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="state" className="floatLeft">
              State
                        </label>
            <input
              type="text"
              className="form-control"
              id="state"
              value={this.state.state}
              onChange={event =>
                this.setState({ state: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="postalcode" className="floatLeft">
              Postal Code
                        </label>
            <input
              type="text"
              className="form-control"
              id="postalcode"
              value={this.state.postalcode}
              onChange={event => this.setState({ postalcode: event.target.value.replace(/[^0-9]/g, '') })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="remarks" className="floatLeft required">
              Remarks
            </label>
            <textarea
              className="form-control"
              id="remarks"
              value={this.state.remarks}
              onChange={event => {
                this.setState({ remarks: event.target.value });
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

   _renderShipPackage = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Ship Package</div>
       
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="weight" className="floatLeft">
              Weight
            </label>
            <input
              type="number"
              className="form-control"
              id="weight"
              value={this.state.weight}
              onChange={event =>
                this.setState({ weight: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="height" className="floatLeft">
              Height
            </label>
            <input
              type="number"
              className="form-control"
              id="height"
              value={this.state.height}
              onChange={event =>
                this.setState({ height: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderShip = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Ship</div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="company" className="floatLeft">
              Logistic Company
           </label>
            <input
              type="text"
              className="form-control"
              id="company"
              value={this.state.company}
              onChange={event =>
                this.setState({ company: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="shiptrackno" className="floatLeft">
              Shipping Tracking No
           </label>
            <input
              type="text"
              className="form-control"
              id="shiptrackno"
              value={this.state.shiptrackno}
              onChange={event =>
                this.setState({ shiptrackno: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="shipinsurance" className="floatLeft">
              Shipping Insurance
           </label>
            <input
              type="text"
              className="form-control"
              id="shipinsurance"
              value={this.state.shipinsurance}
              onChange={event =>
                this.setState({ shipinsurance: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderGeneralInformation()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderShipPackage()}
            </form>
            <form className="splitFrmDivSec text-center" style={{ marginTop: "2em" }}>
              {this._renderShip()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  componentWillMount = () => {
    this.props.dispatch(deliverycountryConfig({body: { status: "Active", propgroup: "Country"}}));
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      //const isEdit = this.props.location.state.isEdit;
    //   if (rec) {
    //     const {
    //       id,
    //       title,
    //       articlecode,
    //       status,
    //       sortorder,
    //       fields,
    //       storeid,
    //       images
    //     } = rec;
    //     this.props.dispatch(getStoreById(storeid));
    //     this.setState(
    //       produce(draft => {
    //         draft.id = id ? id : "";
    //         draft.isEdit = isEdit;
    //         draft.articletitle = title || "";
    //         draft.articlecode = articlecode || "";
    //         draft.storeid = storeid ? storeid : "";
    //         //draft.status = status ? status : [];
    //         draft.sortorder = sortorder ? sortorder : 0;

    //         // draft.description = fields.description || "";
    //         // draft.groupid = fields.groupid || "";
    //         // draft.title = fields.title || "";
    //         // draft.title_long = fields.title_long || "";

    //         draft.images = {
    //           image1: rec.hasOwnProperty("images") === true ? images.icon : ""
    //         };
    //       })
    //     );
    //   }
    }
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                  Ready to Ship
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/orders-fulfillment"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                 Shipment Done successfully
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(ReadyToShip);
