import React, { Component } from 'react';
import SimpleImageInput from './SimpleImageInput';
import { connect } from 'react-redux';

class MultipleDynamicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    updateImage = (value, name, field, rows) => {
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.props.handleInputChange(value, field, rows);
            }
        );
    };

    tableHeaders = () => (
        <thead>
            <tr>
                {this.props.columns.map((column, index) => {
                    return (
                        <th key={`tableview-th-${index}`}>{column.label}</th>
                    );
                })}
            </tr>
        </thead>
    );
    tableBody = () => {
        var rowleng = this.props.rows ? this.props.rows.length : 0;

        return (
            <tbody>
                {this.props.rows.map((row) => {
                    let index = row.id;
                    const rowId = `row_${index}`;
                    return (
                        <tr key={`tableview-tr-inner-${index}`} id={rowId}>
                            {this.props.columns.map((column, index) => {
                                const col = column.dataFieldId.replace(
                                    /\s/g,
                                    ''
                                );
                                return this.renderRowData(
                                    column,
                                    row,
                                    col,
                                    index,
                                    rowId,
                                    rowleng
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    };
    renderRowData = (column, row, col, index, rowId, rowleng) => {
        if (column.addRow) {
            return column.dataFieldId === 'image' ? (
                <td key={`tableview-td-img-${rowId}`} style={{ width: '20em' }}>
                    <SimpleImageInput
                        placeholder="image"
                        name={'image_' + rowId}
                        setImage={(value) => {
                            this.updateImage(
                                value,
                                'image_' + rowId,
                                column.dataFieldId,
                                row
                            );
                        }}
                        value={
                            row[column.dataFieldId] ||
                            this.state['image_' + rowId]
                        }
                    ></SimpleImageInput>
                </td>
            ) : (
                <td key={`tableview-td-key-${rowId}`} style={{ width: '20em' }}>
                    <input
                        type="text"
                        defaultValue={row[column.dataFieldId]}
                        placeholder={
                            column.dataFieldId.charAt(0).toUpperCase() +
                            column.dataFieldId.slice(1)
                        }
                        onChange={(event) =>
                            this.props.handleInputChange(
                                event,
                                column.dataFieldId,
                                row
                            )
                        }
                    />
                </td>
            );
        }

        if (col === 'value' && column.addRow === false) {
            var contentareastyle = {
                display: 'inline-flex',
                flexFlow: 'wrap',
                border: '1px solid #dcdcdc',
                padding: '10px',
                overflowY: 'scroll',
                maxHeight: '200px',
            };
            return (
                <td key={`tableview-td-value-${rowId}`}>
                    <div style={contentareastyle}>
                        {row[column.dataFieldId] !== '' &&
                        Array.isArray(row[column.dataFieldId]) ? (
                            row[column.dataFieldId].map((datevalue, i) => {
                                return (
                                    <div
                                        id={`content_${i}`}
                                        className="text-content"
                                        key={`tableview-td-${i}`}
                                    >
                                        <input
                                            type="text"
                                            className="inputfield"
                                            value={datevalue}
                                            onChange={(event) =>
                                                this.props.handleTextFieldChange(
                                                    event,
                                                    row,
                                                    i
                                                )
                                            }
                                        />
                                        <button
                                            className="btn-light"
                                            type="button"
                                            onClick={() =>
                                                this.props.openPreview(row, i)
                                            }
                                        >
                                            <img
                                                width="20em"
                                                className="viewbtn"
                                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                                                alt=""
                                            />
                                        </button>
                                        <button
                                            className="btn-primary btntext"
                                            type="button"
                                            onClick={() =>
                                                this.props.addtextarea(row)
                                            }
                                        >
                                            +
                                        </button>
                                        <button
                                            className="btn-danger btntext"
                                            type="button"
                                            onClick={() =>
                                                this.props.deletetextarea(
                                                    row,
                                                    i
                                                )
                                            }
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <div></div>
                        )}
                    </div>
                </td>
            );
        }

        if (col === 'AD') {
            return (
                <td key={`tableview-td-${rowId}-${index}`}>
                    <img
                        className="addBtn1"
                        onClick={this.props.addRow}
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
                        alt=""
                    />

                    <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.props.deleteRow(row)}
                        className="addBtn2"
                        alt=""
                    />
                </td>
            );
        }
        return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
    };

    render() {
        return (
            <React.Fragment>
                <div className="dynamicTable">
                    <table style={{ width: '100%' }}>
                        {this.tableHeaders()}
                        {this.tableBody()}
                    </table>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(MultipleDynamicTable);
