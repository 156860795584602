import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import {
  createArticleInfoApi,
  updateArticleInfoApi
} from "../../../actions/actions";
// Local Imports
// Local Imports
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleSelect from "./../../../components/SimpleSelect";
import MultiSelect from "./../../../components/MultiSelect";
import ImagePick from "./../../../components/ImagePick";
import StatusComp from "./../../../components/StatusComp";
import {
  crudReset,
  getBeaconsList,
  getGeofenceList,
  getStoreById
} from "./../../../actions/actions";
import { produce } from "immer";
import TenantCode from "./../../../components/TenantCode";
import { formatDate, renderBeaconOrGeofenceList } from "../../../helpers/Util";
import StoreSearchSingle from "../../system/Users/StoreSearchSingle";
class AddEvent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: false,
      isView: false,
      showTenantCodeModal: false,
      tenantCode: "",
      tenantCountry: "",
      tenantCity: "",
      tenantCodeDesc: "",
      langlist: {},
      showTenantCity: false,
      showTenantCode: false,
      // Formrelated
      storeid: "",
      eventcode: "",
      title: "",
      subtitle: "",
      desc: "",
      desclong: "",
      locationone: "",
      loconelink: "",
      locationtwo: "",
      loctwolink: "",
      startdate: new Date(),
      expirydate: new Date(),
      starttime:"",
      endtime: "",
      beacons: "",
      geofences: "",
      status: "",
      promocode: "",
      notmsg: "",
      articlegroup: "",
      images: {},
      termscondi: "",
      sortorder: 0,
      language: "",
      storelabel: "",

      // Errors
      storeidError: "",
      titleError: "",
      descError: "",
      locOneError: "",
      locOneLinkError: "",
      sortordererror: ""
    };
  }
  
  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  
  getCreatePayload = () => {
    const {
      storeid,
      title,
      subtitle,
      desc,
      eventcode,
      desclong,
      locationone,
      loconelink,
      locationtwo,
      loctwolink,
      startdate,
      expirydate,
      starttime,
      endtime,
      beacons,
      geofences,
      termscondi,
      language,
      sortorder,
      status,
      images,
      promocode,
      notmsg,
      articlegroup
    } = this.state;
    let body = {
      title: title,
      language: language,
      storeid: storeid,
      publishmode: "manual",
      articletype: "Event Information",
      articlegroup,
      status: status,
      sortorder: sortorder,
      startdate: formatDate(startdate),
      expirydate: formatDate(expirydate),
      fields: {
        eventcode: eventcode,
        title: title,
        subtitle: subtitle,
        desc: desc,
        desclong: desclong,
        locationone: locationone,
        loconelink: loconelink,
        locationtwo: locationtwo,
        loctwolink: loctwolink,
        geofences: geofences.toString(),
        beacons: beacons.toString(),
        termscondi: termscondi,
        language: language,
        promocode,
        notmsg,
        starttime: starttime,
        endtime: endtime
      },
      images: {
        image1: images.image1 || "",
        image2: images.image2 || "",
        image3: images.image3 || "",
        image4: images.image4 || ""
      }
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createEvents = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeidError: "",
      locOneLinkError: "",
      locOneError: "",
      titleError: "",
      descError: "",
      sortordererror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, title, desc, locationone, loconelink, sortorder } = this.state;
    let valid = true;
    if (storeid.trim() === "") {
      this.setState({ storeidError: "Store is required." });
      valid = false;
    }

    if (title.trim() === "") {
      this.setState({ storeidError: "Title is required." });
      valid = false;
    }
    if (desc.trim() === "") {
      this.setState({ descError: "Description is required." });
      valid = false;
    }
    if (locationone.trim() === "") {
      this.setState({ locOneError: "Location one is required." });
      valid = false;
    }
    if (loconelink.trim() === "") {
      this.setState({ locOneLinkError: "Location one link is required." });
      valid = false;
    }
    if (sortorder === "") {
      this.setState({ sortordererror: "sortorder is required." });
      valid = false;
    }
    return valid;
  };
  handlestartdateChange = value => {
    this.setState({
      startdate: value
    });
  };
  handleexpirydateChange = value => {
    this.setState({
      expirydate: value
    });
  };
  setBeaconsSelected = value => {
    this.setState({
      beacons: value
    });
  };
  setGeoFenceSelected = value => {
    this.setState({
      geofences: value
    });
  };
  setSelectedFieldValuesForZone = values => {
    this.setState({ zonesSelected: values });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _isEditMode = () => {
    return this.state.isEdit;
  };

  _isViewMode = () => {
    return this.state.isView;
  };

  _renderMainButton = () => {
    if (this._isViewMode()) {
      return (
        <React.Fragment>
          <Link
            to="/ui/events-management"
            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          >
            Close
          </Link>
        </React.Fragment>
      );
    }

    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => {
              this._createEvents();
            }}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createEvents}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/events-management"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };

  componentWillReceiveProps = () => {
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      
      if (storeArray) {
        this.setState({ storelabel: storeArray.title })      
      };
    }
  };

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  _onSelectStore = (selectedStores) => {
    this._setTenant(selectedStores.value, selectedStores.label)
  }

  _renderMainFormDetails = () => {
    console.log('_renderMainFormDetails.....')
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            {/* <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            /> */}
            <StoreSearchSingle value={this.state.storelabel} onSelectStore={this._onSelectStore} />
            <div className="invalid-feedback">{this.state.storeidError}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="eventcode" className="floatLeft">
              Event Code
            </label>
            <input
              type="text"
              className="form-control"
              id="eventcode"
              value={this.state.eventcode}
              onChange={event =>
                this.setState({ eventcode: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => {
                this.setState({ articlegroup: event.target.value });
              }}
            />
          </div>   

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="subtitle" className="floatLeft">
              Sub Title
            </label>
            <input
              type="text"
              className="form-control"
              id="subtitle"
              value={this.state.subtitle}
              onChange={event =>
                this.setState({ subtitle: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
         <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => this.setState({ title: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.titleError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desc" className="floatLeft required">
              Description
            </label>
            <textarea
              className="form-control"
              id="desc"
              value={this.state.desc}
              onChange={event => this.setState({ desc: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.descError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desclong" className="floatLeft">
              Description Long
            </label>
            <textarea
              className="form-control"
              id="desclong"
              value={this.state.desclong}
              onChange={event =>
                this.setState({ desclong: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderLocationDetails = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="locationone" className="floatLeft required">
              Location One
            </label>
            <input
              type="text"
              className="form-control"
              id="locationone"
              value={this.state.locationone}
              onChange={event =>
                this.setState({ locationone: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.locOneError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="loconelink" className="floatLeft required">
              Location One Link
            </label>
            <input
              type="text"
              className="form-control"
              id="loconelink"
              value={this.state.loconelink}
              onChange={event =>
                this.setState({ loconelink: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.locOneLinkError}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="locationtwo" className="floatLeft ">
              Location Two
            </label>
            <input
              type="text"
              className="form-control"
              id="locationtwo"
              value={this.state.locationtwo}
              onChange={event =>
                this.setState({ locationtwo: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="loctwolink" className="floatLeft ">
              Location Two Link
            </label>
            <input
              type="text"
              className="form-control"
              id="loctwolink"
              value={this.state.loctwolink}
              onChange={event =>
                this.setState({ loctwolink: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderGeneralInformation = () => {
    const { beaconsList, geoFencesList } = this.props.queryData;

    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="startdate" className="floatLeft">
              Start Date
            </label>
            <DatePicker
              minDate={!this._isEditMode() ? new Date() : this.state.startdate}
              onChange={this.handlestartdateChange}
              value={this.state.startdate}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="expirydate" className="floatLeft">
              Expiry Date
            </label>
            <DatePicker
              minDate={this.state.startdate}
              onChange={this.handleexpirydateChange}
              value={this.state.expirydate}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="starttime" className="floatLeft">
              Start Time
            </label>
            <input
              type="text"
              className="form-control"
              id="starttime"
              value={this.state.starttime}
              onChange={event =>
                this.setState({ starttime: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="endtime" className="floatLeft">
              End Time
            </label>
            <input
              type="text"
              className="form-control"
              id="endtime"
              value={this.state.endtime}
              onChange={event =>
                this.setState({ endtime: event.target.value })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="beacons" className="floatLeft">
              Beacons
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(beaconsList)}
              setSelectedFieldValues={this.setBeaconsSelected}
              id="beacons"
              itemsSelected={this.state.beacons}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="geofences" className="floatLeft">
              GeoFences
            </label>
            <MultiSelect
              filterOptions={renderBeaconOrGeofenceList(geoFencesList)}
              setSelectedFieldValues={this.setGeoFenceSelected}
              id="geofences"
              itemsSelected={this.state.geofences}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="promocode" className="floatLeft">
              PromoCode
            </label>
            <input
              type="text"
              className="form-control"
              id="promocode"
              value={this.state.promocode}
              onChange={event =>
                this.setState({ promocode: event.target.value })
              }
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="notmsg" className="floatLeft">
              Notification Message
            </label>
            <input
              type="text"
              className="form-control"
              id="notmsg"
              value={this.state.notmsg}
              onChange={event => this.setState({ notmsg: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="termscondi" className="floatLeft">
              Terms And Conditions
            </label>
            <textarea
              className="form-control"
              id="termscondi"
              value={this.state.termscondi}
              onChange={event =>
                this.setState({ termscondi: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  _renderImageDetails = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Images </div>

        <div className="row">
          <div className="col-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row sub-title">Actions</div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft required">
              Sort Order
            </label>
            <input
              type="text"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />
            <div className="invalid-feedback">{this.state.sortordererror}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderGeneralInformation()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderImageDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderLocationDetails()}
            </form>
            <form
              className="splitFrmDivSec text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  componentDidUpdate = (prevProps) => {
    const { languageConfig } = this.props.queryData;
    const { languageConfig: prevlanguageConfig } = prevProps.queryData;
    if(languageConfig !== prevlanguageConfig){
      if (languageConfig.length > 0) {
        const list = languageConfig[0].additionalfields
        this.setState({
          langlist: list
        })
      }
    }
  }

  componentWillMount = () => {
    this.props.dispatch(
      getBeaconsList({ body: { language: "en", status: "New,Active" } })
    );
    this.props.dispatch(
      getGeofenceList({ body: { language: "en", status: "New,Active" } })
    );
    if (this.props.location && this.props.location.state) {
      const defLanguage = this.props.loginData.language;
      this.setState({ language: defLanguage });
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isView = this.props.location.state.isView;
      if (rec) {
        const {
          id,
          title,
          status,
          sortorder,
          fields,
          storeid,
          images,
          startdate,
          expirydate,
          promocode,
          notmsg,
          articlegroup
        } = rec;
        this.props.dispatch(getStoreById(storeid));
        console.log('damn', rec)
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.isView = isView;
            
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.eventcode = fields.eventcode ? fields.eventcode : "";
            draft.subtitle = fields.subtitle ? fields.subtitle : "";
            draft.desc = fields.desc ? fields.desc : "";
            draft.desclong = fields.desclong ? fields.desclong : "";
            draft.locationone = fields.locationone ? fields.locationone : "";
            draft.loconelink = fields.loconelink ? fields.loconelink : "";
            draft.locationtwo = fields.locationtwo ? fields.locationtwo : "";
            draft.loctwolink = fields.loctwolink ? fields.loctwolink : "";

            draft.startdate = startdate ? this.toDate(startdate) : new Date();
            draft.expirydate = expirydate
              ? this.toDate(expirydate)
              : new Date();
            draft.beacons = fields.beacons ? fields.beacons.split(",") : "";
            draft.geofences = fields.geofences
              ? fields.geofences.split(",")
              : "";
            draft.promocode = promocode ? promocode : "";
            draft.notmsg = notmsg ? notmsg : "";
            draft.starttime =fields.starttime ? fields.starttime : "";
            draft.endtime = fields.endtime ? fields.endtime : "";
            draft.images = {
              image1: rec.hasOwnProperty("images") === true ? images.image1 : "",
              image2: rec.hasOwnProperty("images") === true ? images.image2 : "",
              image3: rec.hasOwnProperty("images") === true ? images.image3 : "",
              image4: rec.hasOwnProperty("images") === true ? images.image4 : ""
            };
            draft.termscondi = fields.termscondi ? fields.termscondi : "";
          })
        );
      }
    }
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() ? "Edit Event"
                  : this._isViewMode() ? "View Event" : "Event Registration"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/events-management"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode()
                  ? "Event updated successfully!"
                  : "Event created successfully!"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddEvent);
