import React from "react";
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
  SubMenu
} from "react-contextmenu";
import Modal from "react-awesome-modal";
const ID = "ID";

class ListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImagePreview: false,
      value: "",
      copy_to_file: "",
      folderList: [],
      editText: false,
      editFileId: "",
      newFileName: ""
    };
  }
  _editText = file => {
    this.setState({
      newFileName: file.medianame,
      editFileId: file.id,
      editText: true
    });
  };
  _openImagePreviewModal = (event, image) => {
    this.setState(
      {
        value: image
      },
      () => {
        this.setState({ showImagePreview: true });
      }
    );
    if (event) event.preventDefault();
  };
  componentWillMount = () => {
    let folderList = this.props.foldersList.filter(mediaList => {
      return mediaList.mediatype === "FL";
    });
    this.setState({ folderList: folderList }, () => {});
  };
  _closeImagePreviewModal = event => {
    this.setState({
      showImagePreview: false
    });
    if (event) event.preventDefault();
  };

  _renderImagePreviewModal = () => {
    console.log(this.state.value);
    const { showImagePreview } = this.state;
    return (
      <Modal
        visible={this.state.showImagePreview}
        width="80%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeImagePreviewModal()}
      >
        <form className="modalForm">
          <img
            style={{ width: "400px", height: "350px" }}
            src={`${localStorage.getItem("imagepath")}${this.state.value}`}
            onError={e => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
            }}
            alt="Missing Image"
          />
        </form>
      </Modal>
    );
  };
  _handleClick = (event, data) => {
    alert(data);
  };
  _renderFolder = file => {
    return (
      <div
        className={`col-12 folderRow`}
        onClick={() => {
          this.props.folderOnClick(file.path);
        }}
      >
        <ContextMenuTrigger id={file.id.toString()}>
          <div className="card noBorder noPadding noMargin">
            <div className="card-body noPadding noMargin">
              <div className="row noPadding noMargin">
                <div className="col-3 noPadding noMargin">
                  <p className="display-inline">{file.medianame}</p>
                </div>
                <div className="col-3 noPadding noMargin">
                  <p>{file.lastupdated}</p>
                </div>
                <div className="col-3 noPadding noMargin">
                  <p>{file.size}</p>
                </div>
                <div className="col-3 noPadding noMargin">
                  <p>{file.status}</p>
                </div>
              </div>
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenu id={file.id.toString()} props={this.props}>
          <MenuItem
            data={{ action: "createFolder" }}
            onClick={() => {
              this.props.updateFolderPath(
                file.path,
                this.props.openCreateFolder
              );
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_folder.svg`}
            />
            {` Create Folder`}
          </MenuItem>

          <MenuItem
            data={{ action: "duplicateFolder" }}
            onClick={() => {
              this.props.createFolder(`${file.medianame}_copy`);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
            />
            {` Duplicate`}
          </MenuItem>
          <MenuItem
            data={{ action: "deleteFolder" }}
            onClick={() => {
              this.props.deleteFolder(file.path);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_remove2.svg`}
            />
            {` Remove`}
          </MenuItem>
          <MenuItem
            data={{ action: "uploadFile" }}
            onClick={() => {
              this.props.updateFolderPath(file.path, this.props.openUploadFile);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_upload2.svg`}
            />
            {` Upload File`}
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };
  _renderImagePreview = file => {
    let folders = this.props.foldersList.filter(mediaList => {
      return mediaList.mediatype === "FL";
    });
    const foldersPath = folders.map((folder, index) => {
      return folder.path;
    });
    return (
      <div className="col-12 folderRow">
        <ContextMenuTrigger id={file.id.toString()}>
          <div className="card noBorder noPadding noMargin">
            <div className="card-body noPadding noMargin">
              <div className="row noPadding noMargin">
                <div className="col-3 noPadding noMargin">
                  {this.state.editText === true &&
                  this.state.editFileId === file.id ? (
                    <input
                      type="text"
                      className="form-control"
                      id="newFileName"
                      value={this.state.newFileName}
                      onBlur={() => {
                        {
                          let pathWoFile = file.path.split(file.medianame)[0];

                          this.setState(
                            { editFileId: "", editText: "" },
                            () => {
                              this.props.moveFile(
                                file.path,
                                `${pathWoFile}${this.state.newFileName}`
                              );
                            }
                          );
                        }
                      }}
                      onChange={event => {
                        this.setState({ newFileName: event.target.value });
                      }}
                    />
                  ) : (
                    <p className="card-text text-center">{file.medianame}</p>
                  )}
                </div>
                <div className="col-3 noPadding noMargin">
                  <p>{file.lastupdated}</p>
                </div>
                <div className="col-3 noPadding noMargin">
                  <p>{file.size}</p>
                </div>
                <div className="col-3 noPadding noMargin">
                  <p>{file.status}</p>
                </div>
              </div>
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenu id={file.id.toString()}>
          <MenuItem
            data={{ action: "preview" }}
            onClick={event => {
              this._openImagePreviewModal(event, file.path);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
            />
            {` Preview`}
          </MenuItem>

          <MenuItem data={{ action: "rename" }} onClick={() => alert("jelks")}>
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_rename.svg`}
            />
            {` Rename`}
          </MenuItem>
          {/* <SubMenu title="Move to">
            {foldersPath.map(pathname => {
              return (
                <MenuItem
                  onClick={event => {
                    this.props.moveFile(
                      file.path,
                      `${pathname}/${file.medianame}`
                    );
                  }}
                >
                  {pathname}
                </MenuItem>
              );
            })}
          </SubMenu> */}
          <MenuItem
            data={{ action: "duplicteFile" }}
            onClick={event => {
              this.props.copyFile(file.path, this.state.copy_to_file);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
            />
            {` Duplicate`}
          </MenuItem>
          <MenuItem
            data={{ action: "removeFile" }}
            onClick={() => {
              this.props.deleteFile(file.path);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_folder.svg`}
            />
            {` Remove`}
          </MenuItem>
          <MenuItem
            data={{ action: "generateThumbnail" }}
            onClick={() => {
              this.props.generateThumbnail(file.path);
            }}
          >
            <img
              className="contextMenuImg"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_move.svg`}
            />
            {` Generate Thumbnail`}
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };

  render() {
    const { list } = this.props;
    return (
      <React.Fragment>
        <div className="row listViewHeaderRow noPadding noMargin">
          <div className="col-3 noPadding noMargin">
            <p className="display-inline listViewHeader">File Name</p>
          </div>
          <div className="col-3 noPadding noMargin">
            <p className="listViewHeader">Last Updated</p>
          </div>
          <div className="col-3 noPadding noMargin">
            <p className="listViewHeader">Size</p>
          </div>
          <div className="col-3 noPadding noMargin">
            <p className="listViewHeader">Status</p>
          </div>
        </div>
        <div className="row">
          {list.map(file =>
            file.mimetype === ""
              ? this._renderFolder(file)
              : this._renderImagePreview(file)
          )}
        </div>
        {this._renderImagePreviewModal()}
      </React.Fragment>
    );
  }
}

ListView.propTypes = {};

/* const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(MediaManagement); */
export default ListView;
