import React from 'react';
import { padNumber } from '../../../helpers/Util';

const getDateFormat = (dateStr) => {
    const dateParts = dateStr.split(' ');
    const dateArr = dateParts[0].split('-')
    return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2]
};

const getSpecialDiscountAmount = item => {
    const { specialdiscount } = item
    const { naac20 } = specialdiscount
    if (naac20 && naac20.specialdiscountamount) {
        return isVoidTrxType(item.trxtype, naac20.specialdiscountamount)
    }
    return '';
}

const isVoidTrxType = (trxType, value) => {
    if (trxType == 'void' && value > 0) {
        return '-'+ value.toFixed(2)
    }
    return value.toFixed(2)
}

const getReturnOrVoidNo = (item) => {
    const { trxtype, voidno, retuno } = item
    if (trxtype === 'void') {
        return voidno
    }
    else if (trxtype === 'return') {
        return retuno
    }
}

const NaccTable = (props) => {
    const { data } = props;
    return (
        <div className="table-wrapper" style={{ overflowX: 'auto' }}>
            <table className="pdf-table" cellSpacing="0" border="0" style={{ width: '100%'}}>
                <thead>
                    <tr>
                        <td
                            colSpan={12}
                            height="29"
                            align="center"
                            valign="bottom"
                            style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                padding: '12px',
                            }}
                        >
                            National Athletes and Coaches Sales Book/Report
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            height="78"
                            align="center"
                            valign="middle"
                            bgcolor="#A6A6A6"
                        >
                            Date
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#A6A6A6"
                        >
                            Transaction Type
                        </td>

                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#00B0F0"
                        >
                            Name of National Athlete/Coach
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#FFFF00"
                        >
                            PNSTM ID No.
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#ED7D31"
                        >
                            SI/OR Number
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#FFD966"
                        >
                            Return/Void No
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#E7E6E6"
                        >
                            Gross Sales/Receipts
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#5B9BD5"
                        >
                            Sales Discount
                        </td>
                        <td
                            className="t-cell"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#A6A6A6"
                        >
                            Net Sales
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {data.length == 0 && (
                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                            <td
                                colSpan={20}
                                style={{ textAlign: 'center', padding: '16px' }}
                            >
                                Your search does not retrieve any data. Please
                                search again using another criteria.
                            </td>
                        </tr>
                    )}
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                            >
                                {getDateFormat(item.trxdate)}
                            </td>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                            >
                                {item.trxtype}
                            </td>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                            >
                                {item.membername}
                            </td>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                            >
                                {item.memberid}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {item.invoicenoresetno}-{padNumber(item.invoiceno, 9)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {getReturnOrVoidNo(item)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {item.grossprice.toFixed(2)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {getSpecialDiscountAmount(item)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {isVoidTrxType(item.trxtype, item.netamount)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default NaccTable