import React, { PureComponent } from "react";

import { connect } from "react-redux";

class StatusOptionComp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedOption: this.props.selectedOption };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedOption: nextProps.selectedOption });
  }
  dropDownChange = event => {
    this.setState({ selectedOption: event.target.value });
    this.props.callbackChangeFilter(event.target.value);
  };

  render() {
    let objArray = this.props.statusoption;
    return (
      <div className="filterClass form-filter">
        <select
          id="statusComp"
          className="form-control"
          // disabled={disabled ? true : false}
          value={this.state.selectedOption}
          onChange={this.dropDownChange}
        >
          {objArray.length !== 0 ?
            objArray.map((option, index) => {
              const { name, id } = option;
              return (
                <option key={index} value={id}>
                  {name}
                </option>
              );
            })
           : (
            <option>No option</option>
          )}
        </select>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(StatusOptionComp);
