import React from "react";
import ReactSwitch from "react-switch";

class SimpleSwitch extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  onChange = (checked) => {
    this.setState ({checked}, () => {
      this.props.onChange(checked)
    })
  }

  render() {
    return (
      <React.Fragment>
        <ReactSwitch
              checked={this.state.checked}
              onChange={this.onChange}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="discount-tax-switch"
              disabled={this.props.disabled}
          />
      </React.Fragment>
    );
  }
}

export default SimpleSwitch;
