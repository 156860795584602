import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Divider } from 'primereact/divider'
import GridView from './GridView';
import ListView from './ListView';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

class MediaForm extends React.PureComponent {
    sortoptions = [
        { id: '', name: 'Select' },
        { id: 'medianame', name: 'medianame' },
        { id: 'mediatype', name: 'mediatype' },
        { id: 'createdon', name: 'createdon' },
        { id: 'lastupdated', name: 'lastupdated' },
        { id: 'sortorder', name: 'sortorder' },
    ];
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            const acceptedFiles = files.map(file =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            );
            this.props.setIsUploading(true);

            this.getBase64(files, this.props.uploadFile);

            this.props.setIsUploading(false);
            this.setState({ files: acceptedFiles });
        };
    }

    getBase64 = (files, callback) => {
        var reader = new FileReader();

        for (var i = 0; i < files.length; i++) {
            const file = files[i];
            reader = new FileReader();
            reader.onload = (function (file) {
                return function (e) {
                    callback(
                        file.name,
                        e.target.result.split(';')[1].split(',')[1],
                        file.type
                    );
                };
            })(file);
            reader.readAsDataURL(file);
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };
    _overLayDiv = () => {
        return (
            <div className="overlayContainer">
                <img
                    className="overlayImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_emptyfolder.svg`}
                />
                <div className="overlayText">It is empty</div>
                <div className="overlayText1">Drag file here upload</div>
                <div className="overlayText2">them to media management</div>
            </div>
        );
    };

    onDropRejected = () => {
        this.props.isFileRejected()
    };

    _renderDrag = () => {
        const { mediaList } = this.props.queryData;
        return (
            <Dropzone
                onDrop={this.onDrop}
                multiple={false}
                noClick={true}
                maxSize={5000000}
                onDropRejected={this.onDropRejected}
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {!isDragActive ? (
                        mediaList.length === 0 ||
                        mediaList.length === undefined ? (
                            this._overLayDiv()
                        ) : (
                            this._renderMediaList()
                        )
                    ) : (
                        <div>
                            {mediaList.length === 0 ||
                            mediaList.length === undefined
                                ? this._overLayDiv()
                                : this._renderMediaList()}
                            <div className="onDnDMsg">
                                <img
                                    className="bluearrow"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_bluearrow.svg`}
                                />
                                <div className="dragMsg">
                                    <img
                                        className="bluearrow"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/Group 735.svg`}
                                    />
                                    Drop files to instantly upload them
                                    to here
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                )}
            </Dropzone>
        );
    };

    _renderMediaList = () => {
        const { mediaList, foldersList } = this.props.queryData;
        const { showAsGrid } = this.props;
        return (
            <React.Fragment>
                {showAsGrid === true ? (
                    <GridView
                        list={mediaList}
                        foldersList={foldersList}
                        updateFolderPath={this.props.updateFolderPath}
                        createFolder={this.props.createFolder}
                        deleteFolder={this.props.deleteFolder}
                        uploadFile={this.props.uploadFile}
                        deleteFile={this.props.deleteFile}
                        moveFile={this.props.moveFile}
                        copyFile={this.props.copyFile}
                        renameFile={this.props.renameFile}
                        openCreateFolder={this.props.openCreateFolder}
                        generateThumbnail={this.props.generateThumbnail}
                        openUploadFile={this.props.openUploadFile}
                        folderOnClick={this.props.folderOnClick}
                    />
                ) : (
                    <ListView
                        list={mediaList}
                        foldersList={foldersList}
                        updateFolderPath={this.props.updateFolderPath}
                        createFolder={this.props.createFolder}
                        deleteFolder={this.props.deleteFolder}
                        uploadFile={this.props.uploadFile}
                        deleteFile={this.props.deleteFile}
                        moveFile={this.props.moveFile}
                        copyFile={this.props.copyFile}
                        renameFile={this.props.renameFile}
                        generateThumbnail={this.props.generateThumbnail}
                        openCreateFolder={this.props.openCreateFolder}
                        openUploadFile={this.props.openUploadFile}
                        folderOnClick={this.props.folderOnClick}
                    />
                )}
            </React.Fragment>
        );
    };
    _renderBreadCrumb = () => {
        const { folderPath } = this.props;
        let folders = folderPath.slice(1).split('/');

        return (
            <div className="breadcrumb">
                {folders.length !== 0 &&
                    folders.map((folder, i) => {
                        let link =
                            folderPath.substring(
                                0,
                                folderPath.lastIndexOf(folder)
                            ) + folder;
                        return (
                            <a
                                onClick={() => this.props.folderOnClick(link)}
                                key={i}
                            >
                                {folder}
                            </a>
                        );
                    })}
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <ContextMenuTrigger id="mainForm">
                    {this._renderBreadCrumb()}
                    {this._renderDrag()}
                </ContextMenuTrigger>
                <ContextMenu id="mainForm">
                    <MenuItem
                        data={{ action: 'createFolder' }}
                        onClick={() => this.props.openCreateFolder()}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_folder.svg`}
                        />
                        {` Create Folder`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'uploadFile' }}
                        onClick={(event) => {
                            this.props.openUploadFile(event);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_upload2.svg`}
                        />
                        {` Upload File`}
                    </MenuItem>
                </ContextMenu>
            </React.Fragment>
        );
    }
}

MediaForm.propTypes = {};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
    };
};

export default connect(mapStateToProps)(MediaForm);
