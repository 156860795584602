import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import { searchGeneral, deleteItem, crudReset } from '../../../actions/actions';

import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from './../../../components/Loading';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../helpers/Util';
import PrimeTable from '../../../components/PrimeTable';

class Users extends React.PureComponent {
    companyId = localStorage.getItem('company_id');
    group = 'DE_ACCESS_USERS';
    module = 'ACCESS';
    //allAccesses = {}
    allPermissions = []; //permissions()

    constructor(props) {
        super(props);
        this.state = {
            currPage: 1,
            pageSize: 10,

            openDeleteprompt: false,
            delete_rec_id: '',
        };
    }

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span className="flex">
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/users/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                                currPage: this.state.currPage,
                                pageSize: this.state.pageSize,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                            alt="View"
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/users/addNew',
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                            alt=""
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteUser(row)}
                        alt=""
                    />
                )}
            </span>
        );
    };

    componentDidMount() {
        const { language } = this.props.loginData;
        const { location } = this.props;
      
        if (location && location.state) {
            const { currPage, pageSize } = location.state;
            this.setState({
                currPage: currPage || 1,
                pageSize: pageSize || 10
            });
        }
      
        this.setState({ language }, () => this.callQueryApiForFetch('SEARCH'));
        this.allPermissions = permissions(this.module, this.group);
      }
      

    createFetchUrl = () => {
        const { pageSize, currPage } = this.state;
        const requestBody = {
            body: {
                companyid: this.companyId,
                pagesize: pageSize,
                pageno: currPage,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/user/search/fields',
                this.createFetchUrl(fetchType),
                'SEARCH_USERS_SUCCESS',
                'SEARCH_USERS_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
                break;
        }
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteItem('system/v1/user/delete/', this.state.delete_rec_id)
            );
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    deleteUser = (rowData) => {
        this.setState({ message: 'User deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _showAsTable = (arg) => {
        this.setState({ showAsTable: arg });
    };

    _renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h1 className="page-header">
                        Users
                        <img
                            title="Refresh"
                            onClick={() => {
                                this.callQueryApiForFetch('ONLOAD');
                            }}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                        />
                    </h1>
                </div>
                <div className="col-6 alignRight alignWithPageHeader">
                    {this._renderAddNewBtn()}
                </div>
            </div>
        );
    };

    _renderAddNewBtn = () => {
        const { history } = this.props;
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <Button
                label="Add New"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/users/addNew')}
            />
        );
    };

    render() {
        const { isSuccess, errormsg } = this.props.crudData;
        const { users } = this.props.queryData;

        let _users = users.length !== undefined && users;
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'userid', header: 'User Id', sortable: true },
            { field: 'firstname', header: 'Name' },
            { field: 'usergroup', header: 'User Group' },
            { field: 'store_access', header: 'Stores', width: '25%' },
            { field: 'status', header: 'Status' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-12">{this._renderHeader()}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <form className="formDiv">
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    showFilterSearch
                                    pageNo={this.state.currPage}
                                    tablePageSize={this.state.pageSize}
                                    list={_users}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div style={{ color: 'green', padding: '2em' }}>
                                Are you sure you want to delete this user?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

//export default connect(mapStateToProps)(Users);
export default connect(mapStateToProps)(withRouter(Users));
