import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import StoreSelector from '../../components/StoreSelector';
import {
    queryPropertyConfigByFields,
} from '../../actions/actions';

import {
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
} from '../../constants/constants';

const syncTypeOptions = [
    { name: 'Select', code: '' },
    { name: 'Offline', code: 'offline' },
    { name: 'Online', code: 'online' },
];

const CartSyncReportFilter = (props) => {
    const { handleSearch, queryData } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));
    const [storeLabel, setStoreLabel] = useState(
        localStorage.getItem('storelabel')
    );
    const [statusOptionList, setStatusOptionList] = useState([])

    useEffect(() => {
        props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'CART_SYNC_STATUS',
                    },
                },
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE
            )
        );
    }, [])

    useEffect(() => {
        const { masterTrxGrpTypes } = queryData;
        if (
            masterTrxGrpTypes &&
            masterTrxGrpTypes.length > 0 &&
            masterTrxGrpTypes[0].additionalfields
        ) {
            const trxGrpTypesKeys = Object.keys(
                masterTrxGrpTypes[0].additionalfields
            );
            const obj = trxGrpTypesKeys.reduce((accumulator, value) => {
                return { ...accumulator, [value]: value };
            }, {});
            const output = Object.entries(obj).reduce((result, [name, code]) => {
                result.push({ name, code });
                return result;
              }, [{ name: 'Select', code: '' }]);
            setStatusOptionList(output)
        }
    }, [queryData])

    const formik = useFormik({
        initialValues: {
            date_from: new Date(),
            date_till: new Date(),
            terminalid: '',
            topicmsgid: '',
            trxno: '',
            status: '',
            synctype: '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid: storeid,
                status: values.status ? values.status.code : '',
                synctype: values.synctype ? values.synctype.code : '',
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== undefined && value !== "")
            );
            handleSearch(filteredData);
        },
    });
    const onSelectStore = (selectedStores) => {
        const { name, code } = selectedStores;
        setStoreid(code);
        setStoreLabel(name);
        localStorage.setItem('storeid', code ? code : '');
        localStorage.setItem('storelabel', name ? name : '');
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="storeid">Select Store</label>
                        <StoreSelector
                            storelabel={storeLabel}
                            storeid={storeid}
                            onSelectStore={onSelectStore}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_from">Terminal Id</label>
                        <InputText
                            id="terminalid"
                            value={formik.values.terminalid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_from">Topic Msg Id</label>
                        <InputText
                            id="topicmsgid"
                            value={formik.values.topicmsgid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="trxno">Trx No</label>
                        <InputText
                            id="trxno"
                            value={formik.values.trxno}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusOptionList}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="synctype">Sync Type</label>
                        <Dropdown
                            id="synctype"
                            name="synctype"
                            value={formik.values.synctype}
                            onChange={formik.handleChange}
                            options={syncTypeOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3 mb-2"
                        style={{ display: 'flex' }}
                    >
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="p-button-success mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(CartSyncReportFilter);