import React from 'react';

const RefreshIcon = ({handleClick}) => {
    return (
        <img
            title="refresh"
            className="ml-3"
            src={`${process.env.PUBLIC_URL}/assets/icons/refresh-icon.svg`}
            alt="refresh"
            onClick={handleClick}
            style={{ width: '1.2em', height: '1.2em'}}
        />
    );
};

export default RefreshIcon;
