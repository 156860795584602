import React from "react";
import { connect } from "react-redux";
import LinkButton from "../../../components/LinkButton";
import { searchFields } from "./tableCols";
import { sortTableDateTime } from "../../../helpers/Util";
import {
  crudReset,
  searchGeneral,
  resetAction,
  updateDefaultConfig,
  deleteDelivery,
  deliverytypeConfig
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleSearcher from "../../../components/SimpleSearcher";
import SimpleTable_Expand from "../../../components/SimpleTable_Expand";
import DatePicker from "react-date-picker";
import TenantCode from "../../../components/TenantCode";
import Filter from "../../../components/Filter";
import { formatLanguageArray, toDate, renderOptionsFilter } from "../../../helpers/Util";
import StatusOptionComp from "../../../components/StatusOptionComp";
import SimpleSelect from "../../../components/SimpleSelect";
import Modal from "react-awesome-modal";
import ItemDetails from "./ItemDetails";

const dateType = [
  { name: "Select Date Type", id: "" },
  { name: "Order Date", id: "taskdate" },
  { name: "Shipped Date", id: "ordershippedon" },
  { name: "Order Received Date", id: "orderreceivedon" },
  { name: "Order Completion Date", id: "ordercompletedon" },
  { name: "Order Paid Date", id: "orderpaidon"},
  { name: "Date Created", id: "createdon" },
  { name: "Last Updated", id: "lastupdated" }
];

class DisplayTaskService extends React.PureComponent {
  statusTask = [
    { name: "Select", id: "" },
    { name: "Confirmed", id: "Confirmed" },
    { name: "Shipping", id: "Shipping" },
    { name: "Shipped", id: "Shipped" },
    { name: "Delivered", id: "Delivered" },
    { name: "Completed", id: "Completed" },
    { name: "Cancel", id: "Cancel" }
  ];

  constructor(props) {
    super(props);
    this.state = {
      pagesize: 10,
      pageno: 1,
      status: "",
      openDeleteprompt: false,
      delete_rec_id: "",
      orderlist: [],
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      language: "",
      langlist: {},
      datefrom: new Date(),
      datetill: new Date(),
      datetype: "taskdate",
      orderid: "",
      memberid: "",
      membername: "",
      storeid: "",
      status1: "",
      deliverystatus: "",
      salestype:"",
      expanded: "",
      selected: "",
      showSearchDiv: false,
      itembyproductlist:[],
      showItemPage: false,
      deliverylist: [],
      deliveryorderid: "",
      tableCols: [
        {
            dataField: "orderid",
            text: "Order Id",
            sort: true,
        },
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
          dataField: "totalqty",
          text: "Ordered Qty",
          sort: true
        },
        {
          dataField: "orderdate",
          text: "Order Date",
          sort: true,
          sortFunc: sortTableDateTime
        },
        // {
        //   dataField: "totalamount",
        //   text: "Total Amt",
        //   sort: true,
        //   formatter: this.AmtFormatter,
        // },
        {
          dataField: "deliverystatus",
          text: "Order Status",
          sort: true,
          style: () =>{
            return {
              color: "#58ad0b",
              fontWeight: 600
            }
          }
        },
        // {
        //   dataField: "status",
        //   text: "Shipping Status",
        //   sort: true,
        //   style: () =>{
        //     return {
        //       color: "#215bde",
        //       fontWeight: 600
        //     }
        //   }
        // },
        // {
        //   dataField: "deliveryreturnstatus",
        //   text: "Return Status",
        //   sort: true,
        //   style: () =>{
        //     return {
        //       color: "#f59303",
        //       fontWeight: 600
        //     }
        //   }
        // },
        // {
        //   dataField: "taskqno",
        //   text: "Queue No",
        //   sort: true
        //},
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          }
        }
      ],
      exptableCols : [
        {
          dataField: "items",
          text: "Product Name",
          formatter: this.itemFormatter
        },
        // {
        //   dataField: "images",
        //   text: "Images",
        //   formatter: this.ImgFormatter
        // },
        {
          dataField: "shippingqty",
          text: "Shipping Qty",
          formatter: this.showShippingQty
        },
        {
          dataField: "ordershippedon",
          text: "Shipped Date",
          formatter: this.showShipDate
        },
        {
          dataField: "orderdeliveredon",
          text: "Delivered Date",
          formatter: this.showDeliverDate
        },
        {
          dataField: "cancelledon",
          text: "Cancel Date",
          formatter: this.showCancelDate
        },
        {
          dataField: "shippingstatus",
          text: "Shipping Status",
          formatter: this.showStatus
        }
      ],
      ordertableCols: [
        {
          dataField: "items",
          text: "Product Name",
          formatter: this.itemFormatter
        },
        {
          dataField: "items",
          text: "Ordered Qty",
          formatter: this.showOrderQty
        }
      ]
    };
  }

  // AmtFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   if(row.totalamount !== ""){
  //     return (
  //       <div>
  //         {row.currency+" "+row.totalamount}
  //       </div>
  //     )
  //   }
  //   else {
  //     return ""
  //   }
  // }

  showOrderQty = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.quantity}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  itemFormatter = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
            <div className="row-content">
              <div className="flex-row">
                <div className="col-wrapper">
                   {e.productcode + "-" + e.description}
                   <div style={{ color: "#6d6d6d", fontSize: "12px" }}>{"[" + e.itemsize + "/" + e.itemcolor + "]"}</div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showShippingQty = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingqty}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }


  showShipDate = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                {e.shippingstatus == "Shipped" || e.shippingstatus == "Delivered" || e.shippingstatus== "Completed"?
                 e.ordershippedon : ""
                }
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showCancelDate = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingstatus=="Cancel" ? e.cancelledon : ""}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showDeliverDate = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingstatus==="Delivered" || e.shippingstatus==="Completed" ? e.orderdeliveredon : ""}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showStatus = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
          <div className="row-content">
            <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingstatus}
              </div>
            </div>
          </div>
       </div>
        )
      }
      else{
        return ""
      }
    }
  }
  // ImgFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   if (row.images !== "") {
  //       return (
  //         <div>
  //           <img
  //             className="img-responsive w-50"
  //             src={`${localStorage.getItem("imagepath")}` + row["images"]}
  //             alt="Card image cap" />
  //         </div>
  //       );
  //   }
  //   else {
  //     //remove image column
  //     const newCols = this.state.exptableCols.filter(e=>e.dataField !== "images");
  //     this.setState({
  //       exptableCols: newCols
  //     })
  //   }
  // }

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
};

componentDidMount = () => {
  this.props.dispatch(deliverytypeConfig({body: { language: "en", propgroup: "OPS", propvalue: "Delivery" }}));
}

  componentWillMount() {
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD")
  }

  componentWillReceiveProps = (prevProps) => {
    const { languageConfig } = this.props.queryData;
      if (languageConfig.length > 0) {
        const list = languageConfig[0].additionalfields
        this.setState({
          langlist: list
        })
      }
  }

  shipItem = (cell, row, rowIndex, formatExtraData) => {
    const permissions = localStorage.getItem("permission");
    if (permissions.split(",").includes("W")) {
      return (
          <span>
            <Link
            to={{
              pathname: "/ui/orders-fulfillment/shipping-item/addNew",
              state: {
                item: [row],
              }
            }}>Ready to Ship</Link>
          </span>
      );
    }
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const permissions = localStorage.getItem("permission");
    if (row.status !== "Deleted" && permissions.split(",").includes("W")) {
      let rowlist = Object.assign({},row,{items: row.items.map(e=>({...e,currency:row.currency}))});
      return (
        <span>
          {/* <Link
            to={{
              pathname: "/ui/orders-fulfillment/addNew",
              state: {
                item: row,
                isEdit: true
              }
            }}
          >
            <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link> */}

          <img
            title="Shipment"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/courier.png`}
            onClick={() => {
              this.showItemForm(true, rowlist)
            }}
          />

           <img
            title="Delete"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.deleteStoreInfo(row)}
          />
        </span>
      );
    }
  };


  showItemForm = (value, row) => {
    if (row) {
      this.setState({ deliverylist: row }, () => {
        if (row) {
          this.setState({ showItemPage: value });
        }
      });
    } else {
      this.setState({ showItemPage: value },()=>{
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        this.callQueryApiForFetch("ONLOAD")
      });
    }
  };

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      this.setState({
        expanded: row.id
      },()=>{
        this.props.dispatch(
          searchGeneral(
            "ops/v1/task/shipping/search/fields",
            {body:{orderid: row.orderid}},
            "SEARCH_SHIPPINGTASK_SUCCESS",
            "SEARCH_SHIPPINGTASK_FAILURE"
          )
        );
        this.renderOrderDetail(row.id);
      });
    } else {
      this.setState(() => ({
        expanded: ""
      }));
    }
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.idx]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.idx)
      }));
    }
  };

  componentDidUpdate = (prevProps) => {
    const { shippingTaskList } = this.props.queryData;
    const { shippingTaskList: prevshippingTaskList } = prevProps.queryData;
    if(shippingTaskList !== prevshippingTaskList) {
      if (shippingTaskList.length > 0) {
        let newshiplist = shippingTaskList.map(e => ({
          ...e,
          items: e.items.map(i => ({
            ...i,
            currency: e.currency,
            ordershippedon: e.ordershippedon,
            orderdeliveredon: e.orderdeliveredon,
            cancelledon: e.cancelledon,
            shippingstatus: e.shippingstatus
          }))
        }))
        this.setState({
          itembyproductlist: newshiplist
        })
      }
      else{
        this.setState({
          itembyproductlist: []
        })
      }
    }
  }

  renderOrderDetail = (id) =>{
    const { deliveryTaskList } = this.props.queryData;
    if(deliveryTaskList.length > 0){
      let getorderlist = deliveryTaskList.filter(e=>e.id==id);
      this.setState({
        orderlist: getorderlist
      })
    }
  }


  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.idx);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  // API Calls
  createFetchUrl = () => {
    const { search_field, search_condi, search_value, pagesize, pageno } = this.state;

    const requestBody = {
      body: {
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value,
        pageno: pageno,
        pagesize: pagesize
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    if(fetchType === "SEARCH"){
      this.props.dispatch(
        searchGeneral(
          "ops/v1/task/delivery/search/fields",
          this.createFetchByFields(fetchType),
          "SEARCH_DELIVERYTASK_SUCCESS",
          "SEARCH_DELIVERYTASK_FAILURE"
        )
      );
    }
    else {
      this.props.dispatch(
        searchGeneral(
          "ops/v1/task/delivery/search",
          this.createFetchUrl(fetchType),
          "SEARCH_DELIVERYTASK_SUCCESS",
          "SEARCH_DELIVERYTASK_FAILURE"
        )
      );
    }
  };

  createFetchByFields  = () =>  {
    const { storeid, datetype, memberid, membername, orderid, datefrom, datetill, status } = this.state;
      const requestBody = {
        body: {
          datetype,
          datefrom: toDate(datefrom),
          datetill: toDate(datetill),
          storeid,
          orderid,
          memberid,
          membername,
          status
        }
      };
      return requestBody;
  }

  pageClick = (num, size) => {
    this.setState({
      pageno: num,
      pagesize: size
    },()=>{
      this.callQueryApiForFetch("ONLOAD");
    })
  }

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
    }
  };



  searchQuery = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("ONLOAD");
      }
    );
  };

  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };

  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };

  handlebookdateChange = value => {
    this.setState({
        bookdate: value
    });
  };

  handleStartDate = value => {
    this.setState({
        startdate: value
    });
  };

  handleEndDate = value => {
    this.setState({
        enddate: value
    });
  };

  hanldedatefrom = value => {
    this.setState({
      datefrom: value
    });
  };

  hanldedatetill = value => {
    this.setState({
      datetill: value
    });
  };

  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="70%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Order Fulfillment Search</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {this._renderSearchForm()}
        </div>
      </Modal>
    );
  };

  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  _renderSearchForm = () => {
    const { deliverytypeList } = this.props.queryData;
    let dlist = Object.assign({}, ...deliverytypeList.map(e=>(e.additionalfields)));
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="salestype" className="floatLeft">
              Sales Type
            </label>
            <SimpleSelect
              objArray={dlist}
              callbackChangeFilter={value =>
                this.setState({ salestype: value })
              }
              selectedOption={this.state.salestype}
              id="salestype"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="orderid" className="floatLeft">
              Order Id
            </label>
            <input
              type="text"
              className="form-control"
              id="orderid"
              value={this.state.orderid}
              onChange={event => this.setState({ orderid: event.target.value })}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="date_type" className="floatLeft">
              Date Type
            </label>
            <div className="row">
              <select
                name="date_type"
                style={{width: "100%"}}
                className="simpleSearchSelect2"
                value={this.state.datetype}
                onChange={event =>
                  this.setState({ datetype: event.target.value })
                }
              >
                {dateType.length != 0 &&
                  dateType.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="datefrom" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.hanldedatefrom}
              value={this.state.datefrom}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="datetill" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.hanldedatetill}
              value={this.state.datetill}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="memberid" className="floatLeft">
              Member Id
            </label>
            <input
              type="text"
              className="form-control"
              id="memberid"
              value={this.state.memberid}
              onChange={event => this.setState({ memberid: event.target.value })}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="membername" className="floatLeft">
              Member Name
            </label>
            <input
              type="text"
              className="form-control"
              id="membername"
              value={this.state.membername}
              onChange={event => this.setState({ membername: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Order Status
            </label>
            <StatusOptionComp
              statusoption={this.statusTask}
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>

        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                  this.callQueryApiForFetch("SEARCH");
                  this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  _renderAddNewBtn = () => {
    const permissions = localStorage.getItem("permission");
    return (
      permissions.split(",").includes("W") ?
        <Link
          to={{
            pathname: "/ui/orders-fulfillment/addNew",
            state: {
              language: this.state.language[0]
            }
          }}
        >
          <button className="page-header-btn icon_btn display-inline">
            <img
              title="edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
            />
            {`Add New`}
          </button>
        </Link> :
        <LinkButton
          to="/ui/orders-fulfillment/addNew"
          className="page-header-btn icon_btn display-inline "
          onClick={event => event.preventDefault()}
          disabled={!permissions.split(",").includes("W")}
        >
          <img
            title="edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
          />
          {`Add New`}
        </LinkButton>
    );
  };

  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteDelivery(this.state.delete_rec_id));
    }
  };
  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    //this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    console.log("entry",e)
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  deleteStoreInfo = rowData => {
    this.setState({ message: "Order Fulfillment deleted successfully!" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };

  render() {
    const {
      deliveryTaskList,
      errormsg: queryError,
      servererror: serverError
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }
    if (!deliveryTaskList && !errormsg) {
      return <Loading />;
    }
    if (this.props.queryData.apiPending === true) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        {
          this.state.showItemPage === true ?
          <ItemDetails
            deliverydata={this.state.deliverylist}
            hidePage={this.showItemForm}
          />
        :
        <div>
        {this.state.showSearchDiv && this._renderSearchModal()}
        <div className="form_height">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <h2 className="page-header">
                Orders Fulfillment
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-4 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Orders Fulfillment Registration</h3>
                  </div>
                  <div className="col-lg-4 col-sm-12 noPadding noMargin">
                     <SimpleSearcher
                        filterOptions={searchFields}
                        task_field={true}
                        setAndSearch={this.searchQuery}
                        search_condi={this.state.search_condi}
                        search_value={this.state.search_value}
                        search_field={this.state.search_field}
                      ></SimpleSearcher>
                  </div>
                  <div className="col-lg-2  col-md-6 col-sm-6 alignRight noPadding noMargin">
                     {this._renderSearchBtn()}
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 alignRight noPadding noMargin">
                    {this._renderAddNewBtn()}
                  </div>
                </div>

                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                <React.Fragment>
                  <SimpleTable_Expand
                    columns={this.state.tableCols}
                    rows={deliveryTaskList}
                    expcolumns={this.state.exptableCols}
                    ordercolumns={this.state.ordertableCols}
                    orderrows={this.state.orderlist}
                    exprows={this.state.itembyproductlist}
                    expanded ={this.state.expanded}
                    selected={this.state.selected}
                    handleOnExpand={this.handleOnExpand}
                    pageClick={this.pageClick}
                    //handleOnSelect={this.handleOnSelect}
                    //handleOnSelectAll={this.handleOnSelectAll}
                     callback={() => {
                      this.callQueryApiForFetch("ONLOAD");
                    }}
                  />
                </React.Fragment>
              </form>
            </div>
          </div>

          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
        </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayTaskService);
