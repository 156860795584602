import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    crudReset,
    userAccessSearch,
    userRoleAccessSearch,
    createUserRoleAccesses,
    updateUserRole,
    createUserRole,
    updateUserRoleAccesses,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import 'rc-time-picker/assets/index.css';
import { getAccessTypeDesc, getModuleDesc } from '../../../helpers/Util';
import 'react-checkbox-tree-enhanced/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree-enhanced';

class AddRole extends React.PureComponent {
    constructor(props) {
        super(props);

        this.rolecode = '';
        this.companyid = localStorage.getItem('company_id');
        this.existingRoles = [];
        this.existingRolesActual = [];
        this.newRoles = [];
        this.savedOnce = false;

        this.state = {
            id: '',
            isEdit: false,
            isView: false,
            // Formrelated
            rolename: '',
            status: 'Active',
            roles: [],

            //value: "",
            //roles: [],//["Web Admin", "Admin"],
            //userroles: [],
            rolenameError: '',
            newRolesError: '',
            formError: false,
            formErrorMsg: '',
            selectedRoles: [],
            expanded: [],
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this._isEditMode() || this._isViewMode()) {
            if (
                prevProps.queryData.userRolesAccess !==
                this.props.queryData.userRolesAccess
            ) {
                const { userRolesAccess } = this.props.queryData;

                // let newRoles = []
                //if (userRolesAccess !== 'undefined') {
                this.existingRolesActual = userRolesAccess;
                this.existingRoles = userRolesAccess.flatMap(
                    (e) => e.accesscode
                );
                // this.existingRoles = newRoles
                this.setState({ selectedRoles: this.existingRoles });
                //}
            }
        }
    };

    componentDidMount = () => {
        this.props.queryData.userRolesAccess = [];
        if (this._isEditMode() || this._isViewMode()) {
            this.props.dispatch(
                userRoleAccessSearch(`rolecode=${this.rolecode}&status=Active`)
            );
        }
    };

    componentWillMount = () => {
        this.props.dispatch(userAccessSearch('status=Active'));
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const { id, title } = rec;

                this.rolecode = rec.rolecode;
                this.setState({
                    id: id,
                    rolename: title,
                    isEdit: isEdit,
                    isView: isView,
                });
            }
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    getUserRolePayload = () => {
        if (!this._isEditMode()) {
            this.rolecode = 'R' + Math.floor(Math.random() * 899999 + 100000);
        }
        let body = {
            rolecode: this.rolecode,
            title: this.state.rolename,
            companyid: this.companyid,
            status: 'Active',
        };
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    getCreatePayload = () => {
        const rolecode = this.rolecode;
        const body = [];

        if (this.savedOnce) {
            let deletedRoles = [];
            this.existingRoles.forEach((e) => {
                if (!this.newRoles.includes(e)) {
                    deletedRoles.push(e);
                }
            });

            this.newRoles.forEach((e) => {
                const role = {
                    rolecode: rolecode,
                    accesscode: e,
                    status: 'Active',
                };
                if (this._isEditMode) {
                    if (this.existingRolesActual.length > 0) {
                        const a = this.existingRolesActual.find(
                            (ex) => ex.accesscode === e
                        );
                        if (a !== undefined) {
                            role['id'] = a.id;
                        }
                    }
                }
                body.push(role);
            });

            deletedRoles.forEach((e) => {
                const role = {
                    rolecode: rolecode,
                    accesscode: e,
                    status: 'Deleted',
                };
                if (this._isEditMode) {
                    if (this.existingRolesActual.length > 0) {
                        const a = this.existingRolesActual.find(
                            (ex) => ex.accesscode === e
                        );
                        if (a !== undefined) {
                            role['id'] = a.id;
                        }
                    }
                }
                body.push(role);
            });
        } else {
            this.existingRoles.forEach((e) => {
                const role = {
                    rolecode: rolecode,
                    accesscode: e,
                    status: 'Active',
                };
                if (this._isEditMode) {
                    if (this.existingRolesActual.length > 0) {
                        const a = this.existingRolesActual.find(
                            (ex) => ex.accesscode === e
                        );
                        if (a !== undefined) {
                            role['id'] = a.id;
                        }
                    }
                }
                body.push(role);
            });
        }
        const payload = {
            body: body,
        };

        return payload;
    };

    _createUserRole = () => {
        //remove this after debug
        if (this.validateForm()) {
            if (this._isEditMode() === true) {
                this.props.dispatch(
                    updateUserRole(this.getUserRolePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createUserRole(this.getUserRolePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Please clear all the errors to continue !!!',
            });
            window.scrollTo(0, 0);
        }
    };

    _createUserRoleAccesses = () => {
        const batchPayload = this.getCreatePayload();
        if (this._isEditMode()) {
            this.props.dispatch(updateUserRoleAccesses(batchPayload));
        } else {
            this.props.dispatch(createUserRoleAccesses(batchPayload));
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            rolenameError: '',
            newRolesError: '',
            formError: false,
            formErrorMsg: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const rolename = this.state.rolename;

        let valid = true;
        if (rolename.trim() === '') {
            this.setState({
                titleError: 'Please enter a role name',
            });
            valid = false;
        }
        if (this.existingRoles.length <= 0 && this.newRoles.length <= 0) {
            this.setState({
                newRolesError: 'Please select atleast one role',
            });
            valid = false;
        }
        return valid;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <>
                    <Link
                        to="/ui/userroles"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createUserRole}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createUserRole}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/userroles"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };

    _renderMainFormDetails = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12 ">
                        <label
                            htmlFor="user_name"
                            className="floatLeft required"
                        >
                            Role
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="role_code"
                            value={this.state.rolename}
                            onChange={(event) =>
                                this.setState({ rolename: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.rolenameError}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    groupByAccessTypes = (userAccess) => {
        return userAccess.reduce((cache, access) => {
            const accesstype = access.accesstype;
            if (accesstype in cache) {
                return {
                    ...cache,
                    [accesstype]: cache[accesstype].concat(access),
                };
            }
            return { ...cache, [accesstype]: [access] };
        }, {});
    };

    groupByModules = (userAccess) => {
        return userAccess.reduce((cache, access) => {
            const module = access.module;
            if (module in cache) {
                return { ...cache, [module]: cache[module].concat(access) };
            }
            return { ...cache, [module]: [access] };
        }, {});
    };

    _saveRoleAccesses = (isSuccess) => {
        if (isSuccess) {
            this.props.crudData.userRoleIsSuccess = false;
            this._createUserRoleAccesses();
        }
    };

    getNodes = () => {
        //let userAccess = this.props.queryData.userAccess.concat(dummyArr);
        let userAccess = this.props.queryData.userAccess
        const nodes = [];
        const groupOfAccessTypes = this.groupByAccessTypes(userAccess);
        for (const [accesstype, access] of Object.entries(groupOfAccessTypes)) {
            const groupOfModules = this.groupByModules(access);
            const accessnode = {
                value: accesstype,
                label: getAccessTypeDesc(accesstype),
                icon: '',
                children: [],
                showCheckbox: !this._isViewMode(),
            };
            const accessNodes = [];
            for (const [module, access] of Object.entries(groupOfModules)) {
                const moduleDesc = getModuleDesc(module);
                if (moduleDesc === 'UNDEFINED') {
                    continue;
                }
                const children = [];
                access.forEach((e) => {
                    let child = {
                        value: '',
                        label: '',
                        icon: '',
                        showCheckbox: !this._isViewMode(),
                    };
                    child.value = e.accesscode;
                    child.label = e.title;
                    children.push(child);
                });
                const node = {
                    value: '',
                    label: '',
                    icon: '',
                    children: [],
                    showCheckbox: !this._isViewMode(),
                };
                node.label = moduleDesc; //  getModuleDesc(module)
                node.value = module;
                node.children = children;
                accessNodes.push(node);
            }

            accessnode.children = accessNodes;
            nodes.push(accessnode);
        }
        return nodes;
    };

    getViewNode = () => {
        const userAccessOri = this.props.queryData.userAccess;

        const userAccess = userAccessOri.filter((e) =>
            this.state.selectedRoles.includes(e.accesscode)
        );

        const nodes = [];
        const groupOfAccessTypes = this.groupByAccessTypes(userAccess);
        for (const [accesstype, access] of Object.entries(groupOfAccessTypes)) {
            const groupOfModules = this.groupByModules(access);

            const accessnode = {
                value: accesstype,
                label: getAccessTypeDesc(accesstype),
                icon: '',
                children: [],
                showCheckbox: !this._isViewMode(),
            };
            const accessNodes = [];
            for (const [module, access] of Object.entries(groupOfModules)) {
                const moduleDesc = getModuleDesc(module);
                if (moduleDesc === 'UNDEFINED') {
                    continue;
                }
                const children = [];
                access.forEach((e) => {
                    let child = {
                        value: '',
                        label: '',
                        icon: '',
                        showCheckbox: !this._isViewMode(),
                    };
                    child.value = e.accesscode;
                    child.label = e.title;
                    children.push(child);
                });
                const node = {
                    value: '',
                    label: '',
                    icon: '',
                    children: [],
                    showCheckbox: !this._isViewMode(),
                };
                node.label = moduleDesc; //  getModuleDesc(module)
                node.value = module;
                node.children = children;
                accessNodes.push(node);
            }

            accessnode.children = accessNodes;
            nodes.push(accessnode);
        }
        return nodes;
    };

    _renderAccessDetails = () => {
        const nodes = this._isViewMode() ? this.getViewNode() : this.getNodes();
        return (
            <>
                <div className="row sub-title">Access Details</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        {nodes.length > 0 && (
                            <div className="customOl">
                                <CheckboxTree
                                    nodes={nodes}
                                    checked={this.state.selectedRoles}
                                    expanded={this.state.expanded}
                                    onCheck={this.onCheck}
                                    onExpand={this.onExpand}
                                    icons={{
                                        check: (
                                            <span className="rct-icon rct-icon-check" />
                                        ),
                                    }}
                                    nativeCheckboxes={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    onCheck = (selectedRoles) => {
        this.setState({ selectedRoles: selectedRoles });
        this.saveSelected(selectedRoles);
        // this.newRoles = selectedRoles
    };

    onExpand = (expanded) => {
        this.setState({ expanded: expanded });
    };

    saveSelected = (e) => {
        this.newRoles = e;
        this.savedOnce = true;
    };

    _renderFormArea = () => {
        return (
            <>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderAccessDetails()}
                        </form>
                    </div>
                </div>
            </>
        );
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        const { userRoleIsSuccess } = this.props.crudData;

        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode() === true
                                    ? 'Edit Role'
                                    : this._isViewMode()
                                    ? 'View Role'
                                    : 'Add Role'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <div>{this._saveRoleAccesses(userRoleIsSuccess)}</div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/userroles"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode() === true
                                    ? 'User Role updated successfully'
                                    : 'User Role created successfully'}
                            </div>
                        }
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddRole);
