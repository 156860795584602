import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Local Imports
// Local Imports
import {
    crudReset,
    searchGeneral,
    updateDelivery,
    resetAction
} from "../../../actions/actions";
import Loading from "../../../components/Loading";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import AddShippingItem from "../ShippingItem/AddShippingItem";
import DisplayShippingItem from  "../ShippingItem/DisplayShippingItem";
import AddReturnItem from "../ReturnItem/AddReturnItem";
import DisplayReturnItem from  "../ReturnItem/DisplayReturnItem";
import Modal from "react-awesome-modal";
import StatusOptionComp  from "../../../components/StatusOptionComp";


class ShipReturnTask extends React.Component {
    statusTask = [
        { name: "Select", id: "" },
        { name: "Confirmed", id: "Confirmed" },
        { name: "Shipping", id: "Shipping" },
        { name: "Shipped", id: "Shipped" },
        { name: "Delivered", id: "Delivered" },
        { name: "Completed", id: "Completed" },
        { name: "Cancel", id: "Cancel" }
      ];
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            datalist: {},
            search_field: "status",
            search_condi: "like",
            search_value: "",
            templatetype:"Shipping",
            selectedshiprow: {},
            selectedreturnrow: {},
            showShipPage: false,
            showReturnPage: false,
            formError: false,
            formErrorMsg: "",
            updateStatus: false,
            deliverystatus: "",
            deliverystatuserror: ""
        };
    }

    callFetch = () => {
       const { datalist } = this.state;
       if(JSON.stringify(datalist)!== "{}"){
           this.callApi(datalist.orderid);
       }
    }

    callApi = (orderid) => {
        this.props.dispatch(
            searchGeneral(
                "ops/v1/task/delivery/search/fields",
                { body: { orderid: orderid } },
                "SEARCH_DELIVERYTASK_SUCCESS",
                "SEARCH_DELIVERYTASK_FAILURE"
            )
        );
    }

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            this.callApi(rec.orderid);
        }
    }

    componentDidUpdate = (prevProps) => {
        const { deliveryTaskList } = this.props.queryData;
        const { datalist } = this.state;
        const { deliveryTaskList:  prevdeliveryTaskList } = prevProps.queryData;
        if(deliveryTaskList !== prevdeliveryTaskList){
            if(deliveryTaskList.length > 0) {
                let newdeliverylist = deliveryTaskList.map(e=>({
                    ...e,
                    items: e.items.map(i=>({...i,currency:e.currency}))
                }))
                let finalobj = Object.assign({}, ...newdeliverylist);
                if(JSON.stringify(datalist) === "{}"){
                    this.setState({
                        datalist: finalobj
                    })
                }
                else{
                   if(datalist.deliverystatus !== finalobj.deliverystatus){
                    this.setState({
                        datalist: finalobj
                    })
                   } 
                }
            }
        }       
    }

    // componentWillMount = () => {
    //     console.log(this.props);
    //     const { deliverydata } = this.props;     
    //     if(JSON.stringify(deliverydata) !== "{}"){
    //         if(deliverydata.hasOwnProperty("items") && deliverydata.items.length > 0){
    //             let newdeliverylist = Object.assign({},deliverydata,{items: deliverydata.items.map(e=>({...e,currency:deliverydata.currency}))});
    //             this.setState({
    //                 datalist: newdeliverylist
    //             })
    //         }
           
    //     }
    // }

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    
    updateDeliveryStatus = () => {
        this.props.dispatch(
            updateDelivery(this.getPayload(), this.state.id)
        );
    }

    getPayload = () => {
        const  { deliverystatus,datalist } = this.state;
        let body = datalist;
        body["deliverystatus"] = deliverystatus;
        const payload ={
            body: body
        }
        return payload;
    }

    _renderBtn = () => {
        return (
          <button
            type="submit"
            className="page-header-btn icon_btn display-inline"
            style={{fontSize: "14px"}}
            onClick={event => {
              if (event) event.preventDefault();
              this._openDiv();
            }}
          >
           Update Status
          </button>
        );
      };


      _openDiv = () => {
        this.setState({ updateStatus: true });
      };
      _closeDiv = () => {
        this.setState({ updateStatus: false });
      };

      _renderModal = () => {
        return (
          <Modal
            visible={this.state.updateStatus}
            width="50%"
            height="80%"
            effect="fadeInRight"
            onClickAway={() => this._closeDiv()}
          >
            <div className="modalForm queryFormModal">
              <div className="row">
                <div className="col-xs-12 col-md-6 alignLeft">Delivery Status Update</div>
                <div className="col-xs-12 col-md-6 alignRight">
                  <a onClick={this._closeDiv} className="closeIcon">
                    X
                  </a>
                </div>
              </div>
              <div className="title_devide" />
              {this._renderUpdateForm()}
            </div>
          </Modal>
        );
      };

      _renderUpdateForm= () => {
          return (
              <React.Fragment>
                  <div className="row">
                      <div className="form-group col-lg-6 col-sm-12">
                          <label htmlFor="status" className="floatLeft">
                              Delivery Status
                          </label>
                          <StatusOptionComp
                              statusoption={this.statusTask}
                              selectedOption={this.state.deliverystatus}
                              callbackChangeFilter={value => {
                                  this.setState({ deliverystatus: value });
                              }}
                          />
                          <div className="invalid-feedback">{this.state.deliverystatuserror}</div> 
                      </div>
                  </div>
                  <div className="row btn-container1 form-button">
                      <div className="col-sm-12 col-lg-8">
                          <button
                              type="submit"
                              className="btn btn-themes btn-rounded"
                              onClick={event => {
                                  if (event) event.preventDefault();
                                  if(this.validStatus()=== true){
                                    this.updateDeliveryStatus();
                                    setTimeout(() => {
                                        this.callFetch();
                                    }, 1000);
                                    this._closeDiv();
                                  }                                 
                              }}
                          >
                              Submit
                         </button>
                          <button
                              type="submit"
                              className="btn btn-themes btn-rounded"
                              onClick={event => {
                                  if (event) event.preventDefault();
                                  this._closeDiv();
                              }}
                          >
                              Cancel
                        </button>
                      </div>
                  </div>
              </React.Fragment>
          )
      }
      validStatus = () => {
        this._clearErrorMessages();
        const { deliverystatus } = this.state;
        let valid = true;
        if(deliverystatus===""){
            this.setState({ deliverystatuserror: "Select Delivery Status"});
            valid = false;
        }
        return valid;
      }

      _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            deliverystatuserror: ""
        })
      }
    _renderButton = () => {
        if (this.props.location && this.props.location.state) {
        const { ordership, orderreturn } = this.props.location.state;
        if (ordership === true) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/orders-fulfillment/shipping"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                </React.Fragment>
            );
        }
        if (orderreturn === true) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/orders-fulfillment/shipping-return"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                </React.Fragment>
            );
        }
        }
    };

    showShipForm = (row) => {
        if (row) {
            this.setState({
                selectedshiprow: row
            }, () => {
                this.setState({
                    showShipPage: true
                })
            })
        }
        else {
            this.setState({
                showShipPage: true
            })
        }
    }

    showReturnForm = (row) => {
        if (row) {
            this.setState({
                selectedreturnrow: row
            }, () => {
                this.setState({
                    showReturnPage: true
                })
            })
        }
        else {
            this.setState({
                showReturnPage: true
            })
        }
    }

    hideReturnForm = () => {
        this.setState({
            showReturnPage: false
        })
    }

    hideShipForm = () => {
        this.setState({
            showShipPage: false
        })
    }

    _renderFormArea = () => {
        const {
            errormsg: queryError,
            servererror: serverError
          } = this.props.queryData;
          const { isSuccess, errormsg: crudError } = this.props.crudData;
          let errormsg = "";
          let refresh = false;
          if (serverError) {
            errormsg = serverError;
            refresh = false;
          }
          if (queryError) {
            errormsg = queryError;
            refresh = true;
          }
          if (crudError) {
            errormsg = crudError;
            refresh = true;
          }
      
          if (this.props.queryData.apiPending) {
            return <Loading />;
          }
      
        return (
            <React.Fragment>
                <div>
                  <div className="row sub-title noPadding noMargin">
                        <div className="col-lg-10 col-sm-12 noPadding noMargin">
                            Orders Summary
                          <img
                                title="Refresh"
                                onClick={() => {
                                    this.callFetch();
                                }}
                                className="refreshtree"
                                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                            />
                        </div>
                            <div className="col-lg-2 col-md-12 col-sm-12 alignRight noPadding noMargin">
                                {this._renderBtn()}
                            </div>
                    </div>
                    <div className="title_devide"></div>
                    <form className="splitFrmDiv1Sec text-center">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12 col-md-12 noPadding noMargin">
                                {this._renderBasicInformation1()}
                            </div>
                            <div className="col-sm-12 col-lg-12 col-md-12 noPadding noMargin">
                                {this._renderBasicInformation2()}
                            </div>
                            <div className="col-sm-12 col-lg-12 col-md-12 noPadding noMargin">
                                {this._renderBasicInformation3()}
                            </div>
                        </div>
                    </form>
                    <div className="invalid-feedback">{errormsg}</div>                    
                </div>
            </React.Fragment>
        );
    };
    _renderBasicInformation1 = () => {
        const { datalist } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Order ID
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.orderid}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Store Id
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.storeid}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Sales Type
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.salestype}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                            Ordered Qty
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.totalqty}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderBasicInformation2 = () => {
        const { datalist } = this.state;
        return (
            <React.Fragment>
                <div className="row">                    
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Delivery Qty
                           </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.deliveryqty}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Delivery Return Qty
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.deliveryreturnqty}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Delivery Status
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.deliverystatus}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Delivery Msg
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.deliverymsg}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    _renderBasicInformation3 = () => {
        const { datalist } = this.state;
        return (
            <React.Fragment>
                <div className="row">                    
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Member Id
                           </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.memberid}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                                Member Name
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.membername}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                              Email Id
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass" style={{wordBreak: "break-word"}}>{datalist.memberemailid}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-5 col-form-label alignLeft mini_title">
                              Total Amount
                            </label>
                            <div className="col-sm-3 col-md-7">
                                <p className="text-left textclass">{datalist.currency+" "+datalist.totalamount || ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    hideAlertModal = e => {
        this.setState({ message: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch("ONLOAD");
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        // if (refresh === true) {
        //   this.callQueryApiForFetch("ONLOAD");
        // }
    };
  
    render() {
        const { datalist } = this.state;
        const {
            errormsg: queryError,
            servererror: serverError
          } = this.props.queryData;
          const { isSuccess, errormsg: crudError } = this.props.crudData;
          let errormsg = "";
          let refresh = false;
          if (serverError) {
            errormsg = serverError;
            refresh = false;
          }
          if (queryError) {
            errormsg = queryError;
            refresh = true;
          }
          if (crudError) {
            errormsg = crudError;
            refresh = true;
          }
        if (this.state.showShipPage === true) {
            return (
                <React.Fragment>
                    <AddShippingItem 
                    deliverydata={this.state.datalist}
                    hidePage={this.hideShipForm}
                    selectedrow={this.state.selectedshiprow}
                    shipreturnpage={true}
                    />
                </React.Fragment>
            )
        }
        else if(this.state.showReturnPage === true) {
            return (
                <React.Fragment>
                    <AddReturnItem 
                    deliverydata={this.state.datalist}
                    hideReturnPage={this.hideReturnForm}
                    selectedrow={this.state.selectedreturnrow}
                    shipreturnpage={true}
                    />
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                     {this.state.updateStatus && this._renderModal()}
                    <div className="form_height">
                        <div className="row">
                            <div className="col-lg-9 col-sm-12">
                                <h2 className="page-header">
                                    Orders Fulfillment
                                </h2>
                            </div>
                        </div>
                        <div className="row form-container">
                            <div className="col">
                                <form className="frmDiv">
                                    {this._renderErrorMessage()}
                                    {this._renderFormArea()}
                                </form>
                                <form className="frmDiv" style={{ marginTop: "2em" }}>
                                    {JSON.stringify(datalist) !== "{}" ? 
                                      <DisplayShippingItem 
                                         deliverydata={this.state.datalist}
                                         showPage={this.showShipForm} 
                                    /> : 
                                     ""
                                    }
                                </form> 
                                <form className="frmDiv" style={{ marginTop: "2em" }}>
                                  {JSON.stringify(datalist) !== "{}" ? <DisplayReturnItem 
                                         deliverydata={this.state.datalist}
                                         showReturnPage={this.showReturnForm} 
                                    /> : 
                                     ""
                                   }
                                </form> 
                            </div>
                            <div className="row btn-container form-button">
                                <div className="col-12">{this._renderButton()}</div>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={errormsg}
                            handleClose={event => this.hideErrorAlertModal(event, refresh)}
                            children={
                                <div
                                    style={
                                        errormsg
                                            ? { padding: "2em", color: "red", fontWeight: "500" }
                                            : { padding: "2em", color: "red" }
                                    }
                                >
                                    {errormsg ? `${errormsg}.` : ""}
                                </div>
                            }
                        />
                    </div>                    
                </React.Fragment>
            );
        }
    }
}
const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(ShipReturnTask);
