import React from 'react';
import { withRouter } from 'react-router';
import { Button } from 'primereact/button';

const ConfirmAlert = ({
    handleClose,
    show,
    to,
    children,
    isPrompt,
    history,
    modalindex,
}) => {
    const showHideClassName = show
        ? 'modal display-block'
        : 'modal display-none';
    const mindex = modalindex ? 'deleteslotmodal' : '';
    const handleClick = (e) => {
        handleClose(e);
        if (typeof to !== 'undefined') {
            if (to === 'back') {
                history.goBack();
            } else {
                history.push(to);
            }
        }
    };
    return (
        <div className={showHideClassName} id={mindex}>
            <div
                className="text-center border-round-xl fixed bg-white top-50 left-50 p-4"
                style={{ transform: 'translate(-50%, -50%)' }}
                id="alert"
            >
                {children}
                {!isPrompt ? (
                    <Button
                        label="OK"
                        className="p-button-success px-4"
                        onClick={e => handleClick(e)}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
ConfirmAlert.propTypes = {};

export default withRouter(ConfirmAlert);
