import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Local Imports
// Local Imports
import SimpleSelect from "../../../components/SimpleSelect";
import ImagePick from "../../../components/ImagePick";
import {
  updateArticleInfoApi,
  createArticleInfoApi,
  queryDefaultConfigByTitle,
  crudReset,
  getStoreById
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusComp from "../../../components/StatusComp";
import { produce } from "immer";
import TenantCode from "../../../components/TenantCode";
import MultiSelect from "./../../../components/MultiSelect";
class AddMobile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      title: "",
      status: "",
      messagecode: "",
      messagetype: "",
      code: "",
      messagebody: "",
      desc: "",
      allowduplicate: "N",
      beaconid: "",
      geofencing: "",
      articlegroup: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      storeiderror: "",
      titleerror: "",
      msgbodyerror: "",
      msgcodeerror: "",
      language: "",
      langlist: {},
      storelabel: "",
      sortorder: 0,
      images: {},
      mobilemsgtype: {}
    };
  }

  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    // const requestBody = {
    //   body: {
    //     title: "MESSAGE_TYPES",
    //     category: "Config"
    //   }
    // };
    // this.props.dispatch(
    //   queryDefaultConfigByTitle(
    //     requestBody,
    //     "GET_MOBILE_MESSAGE_TYPE_BY_TITLE",
    //     "GET_MOBILE_MESSAGE_TYPE_BY_TITLE_SUCCESS",
    //     "GET_MOBILE_MESSAGE_TYPE_BY_TITLE_FAILURE"
    //   )
    // );
    const getmobilemsgtype = localStorage.getItem("mobilemsgtype");
    this.setState({
      mobilemsgtype: JSON.parse(getmobilemsgtype)
    })
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;

      if (rec) {
        const { id, storeid, title, fields, status, language, images, sortorder, articlegroup } = rec;
        this.props.dispatch(getStoreById(storeid));
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            draft.language = language ? language : "en";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.messagecode = fields.messagecode ? fields.messagecode : "";
            draft.messagetype = fields.messagetype ? fields.messagetype : "";
            draft.code = fields.code ? fields.code : "";
            draft.messagebody = fields.messagebody ? fields.messagebody : "";
            draft.allowduplicate = fields.allowduplicate
              ? fields.allowduplicate
              : "";
            draft.desc = fields.desc ? fields.desc : "";
            draft.beaconid = fields.beaconid ? fields.beaconid.split(",") : "";
            draft.geofencing = fields.geofencing
              ? fields.geofencing.split(",")
              : "";
            draft.images = {
              image1: rec.hasOwnProperty("images") === true ?  images.image1 : "",
              image2: rec.hasOwnProperty("images") === true ? images.image2 : "",
              image3: rec.hasOwnProperty("images") === true ? images.image3 : "",
              image4: rec.hasOwnProperty("images") === true ? images.image4 : ""
            };
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  getCreatePayload = () => {
    const {
      storeid,
      status,
      title,
      messagecode,
      messagetype,
      code,
      messagebody,
      allowduplicate,
      beaconid,
      geofencing,
      language,
      desc,
      sortorder,
      images,
      articlegroup
    } = this.state;
    let body = {
      storeid: storeid,
      title: title,
      language: language,
      publishmode: "manual",
      articletype: "Mobile Notification",
      status: status,
      sortorder: sortorder,
      articlegroup,
      fields: {
        messagecode: messagecode,
        messagetype: messagetype,
        code: code,
        messagebody: messagebody,
        allowduplicate: allowduplicate,
        desc,
        beaconid: beaconid.toString(),
        geofencing: geofencing.toString()
      },
      images: {
        image1: images.image1 || "",
        image2: images.image2 || "",
        image3: images.image3 || "",
        image4: images.image4 || ""
      }
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createMobileNotification = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      titleerror: "",
      msgbodyerror: "",
      msgcodeerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, title, messagebody, messagecode } = this.state;
    let valid = true;
    /*  if (storeid.trim() === "") {
      this.setState({
        storeiderror: "Please enter store id"
      });
      valid = false;
    } */
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter a title"
      });
      valid = false;
    }

    if (messagebody.trim() === "") {
      this.setState({
        msgbodyerror: "Please enter message body"
      });
      valid = false;
    }
    if (messagecode.trim() === "") {
      this.setState({
        msgcodeerror: "Please enter message code"
      });
      valid = false;
    }
    // if (desc.trim() === "") {
    //   this.setState({
    //     msgcodeerror: "Please enter description"
    //   });
    //   valid = false;
    // }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createMobileNotification()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createMobileNotification()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/mobile-notification"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      //if (storeArray) this.setState({ storelabel: storeArray.title });
      if (storeArray) {
        storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage.toString() }); 
    }
  };

  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
     return (
       <React.Fragment>
         <div className="row sub-title">Actions</div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
         </div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
         </div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft ">
              Sortorder
            </label>
            <input
              type="number"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />           
          </div> 
         </div>
       </React.Fragment>
     )
  }

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderMainFormDetails = () => {
    const { languageConfig } = this.props.queryData;
    const { mobilemsgtype } = this.state;
    let messagetypelist = Object.assign({}, mobilemsgtype.pvalues,{task: "Task"});
    const messagetypelabel =
      this.state.messagetype.charAt(0).toUpperCase() +
      this.state.messagetype.slice(1);
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={languageConfig.pvalues}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => {
                this.setState({ articlegroup: event.target.value });
              }}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="messagebody" className="floatLeft required">
              Message Body
            </label>
            <input
              type="text"
              className="form-control"
              id="messagebody"
              value={this.state.messagebody}
              onChange={event => {
                this.setState({ messagebody: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.msgbodyerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="messagetype" className="floatLeft">
              MessageType
            </label>
            <SimpleSelect
              objArray={messagetypelist}
              callbackChangeFilter={value =>
                this.setState({ messagetype: value })
              }
              selectedOption={this.state.messagetype}
              id="messagetype"
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="code" className="floatLeft">
              {messagetypelabel === "0" ? "Code" : `${messagetypelabel} Code`}
            </label>
            <input
              type="text"
              className="form-control"
              id="code"
              value={this.state.code}
              onChange={event => {
                this.setState({ code: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="messagecode" className="floatLeft required">
              Message Code
            </label>
            <input
              type="text"
              className="form-control"
              id="messagecode"
              value={this.state.messagecode}
              onChange={event => {
                this.setState({ messagecode: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.msgcodeerror}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="allowduplicate" className="floatLeft">
              Allow Duplicate
            </label>
            <SimpleSelect
              objArray={{ Y: "Yes", N: "No" }}
              callbackChangeFilter={value =>
                this.setState({ allowduplicate: value })
              }
              selectedOption={this.state.allowduplicate}
              id="allowduplicate"
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desc" className="floatLeft">
              Description
            </label>
            <textarea
              className="form-control"
              id="desc"
              value={this.state.desc}
              onChange={event => {
                this.setState({ desc: event.target.value });
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="geofencing" className="floatLeft">
              GeoFencing
            </label>
            <MultiSelect
              filterOptions={[
                { name: "home", id: "h1" },
                { name: "Office", id: "off" }
              ]}
              setSelectedFieldValues={value =>
                this.setState({ geofencing: value })
              }
              id="geofencing"
              itemsSelected={this.state.geofencing}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="beaconid" className="floatLeft">
              Beacon Ids
            </label>
            <MultiSelect
              filterOptions={[
                { name: "beacone one", id: "b1" },
                { name: "beacone two", id: "b2" },
                { name: "beacone three", id: "b3" },
                { name: "beacone three", id: "b3" },
                { name: "beacone three", id: "b3" },
                { name: "beacone three", id: "b3" },
                { name: "beacone three", id: "b3" },
                { name: "beacone three", id: "b3" }
              ]}
              setSelectedFieldValues={value =>
                this.setState({ beaconid: value })
              }
              id="beaconid"
              itemsSelected={this.state.beaconid}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <label htmlFor="images" className="floatLeft">
              Images
            </label>
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={4}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Edit Mobile Notification"
                  : "Add Mobile Notification"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/mobile-notification"
            children={
              <div className="alert alert-success">
                {this._isEditMode() === true
                  ? "Application information updated successfully"
                  : "Application Information created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddMobile);
