import React from 'react';
import { connect } from 'react-redux';
import { apiLogsById } from '../../../actions/actions';

class ViewApiLog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            deviceid: '',
            createdon: '',
            uri: '',
            processtime: '',
            querystring: '',
            requesttext: '',
            responsetextsize: '',
            responsetext: '',
            companyid: '',
            userid: '',
            memberid: '',
            storeid: '',
            terminalid: '',
            serverid: '',
            instanceid: '',
            serviceid: '',
            requesttag: '',
            useros: '',
            requestid: '',
            devicetype: '',
            requesthashcode: '',
            headerval: '',
            responsestatus: '',
            responsecontenttype: '',
            cookies: '',
            requestmethod: '',
            ipaddress: '',
            sessionid: '',
            errorcode: '',
            contenttype: '',
            userinfo: '',
            userbrowser: '',
            serveripaddress: '',
            serverport: '',
            orderid: '',
        };
    }

    componentWillMount() {
        console.log('willmount..');
        if (this.props.logid) {
            console.log('willmount..', this.props.logid);
            this.props.dispatch(apiLogsById(this.props.logid));
        }
    }

    componentDidUpdate = (prevProps) => {
        const { apilog } = this.props.queryData;
        const { apilog: prevapilog } = prevProps.queryData;
        if (apilog !== prevapilog) {
            console.log('update apilog::', apilog);

            if (apilog) {
                console.log('received the apilog:::', apilog);

                const {
                    id,
                    deviceid,
                    createdon,
                    uri,
                    processtime,
                    querystring,
                    requesttext,
                    responsetextsize,
                    responsetext,
                    companyid,
                    userid,
                    memberid,
                    storeid,
                    terminalid,
                    serverid,
                    instanceid,
                    serviceid,
                    requesttag,
                    useros,
                    requestid,
                    devicetype,
                    requesthashcode,
                    headerval,
                    responsestatus,
                    responsecontenttype,
                    cookies,
                    requestmethod,
                    ipaddress,
                    sessionid,
                    errorcode,
                    contenttype,
                    userinfo,
                    userbrowser,
                    serveripaddress,
                    serverport,
                    orderid,
                } = apilog;

                this.setState({
                    id,
                    deviceid,
                    createdon,
                    uri,
                    processtime,
                    querystring,
                    requesttext,
                    responsetextsize,
                    responsetext,
                    companyid,
                    userid,
                    memberid,
                    storeid,
                    terminalid,
                    serverid,
                    instanceid,
                    serviceid,
                    requesttag,
                    useros,
                    requestid,
                    devicetype,
                    requesthashcode,
                    headerval,
                    responsestatus,
                    responsecontenttype,
                    cookies,
                    requestmethod,
                    ipaddress,
                    sessionid,
                    errorcode,
                    contenttype,
                    userinfo,
                    userbrowser,
                    serveripaddress,
                    serverport,
                    orderid,
                });
            }
        }
    };

    render() {
        console.log('debug', this.state);
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">Api Log</h2>
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col col-lg-9">
                            <form className="frmDiv">
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Log Id
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="id"
                                            value={this.state.id}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Request Id
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="requestid"
                                            value={this.state.requestid}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Service Id
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="serverid"
                                            value={this.state.serverid}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Device Id
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="deviceid"
                                            value={this.state.deviceid}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Uri
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="uri"
                                            value={this.state.uri}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Query String
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="querystring"
                                            value={this.state.querystring}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Request Body
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="requesttext"
                                            value={this.state.requesttext}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Header
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="headerval"
                                            value={this.state.headerval}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Request Method
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="requestmethod"
                                            value={this.state.requestmethod}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Request Tag
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="requesttag"
                                            value={this.state.requesttag}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Response
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <textarea
                                            className="form-control apilogLeftRightPadding"
                                            id="responsetext"
                                            value={this.state.responsetext}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Response Status
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="responsestatus"
                                            value={this.state.responsestatus}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Process Time(ms)
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="processtime"
                                            value={this.state.processtime}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Response Text Size
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="responsetextsize"
                                            value={this.state.responsetextsize}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Response Content Type
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="responsetextsize"
                                            value={
                                                this.state.responsecontenttype
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            User OS
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="useros"
                                            value={this.state.useros}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Device Type
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="devicetype"
                                            value={this.state.devicetype}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            IP Address
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="ipaddress"
                                            value={this.state.ipaddress}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Cookies
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="cookies"
                                            value={this.state.cookies}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Session Id
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="sessionid"
                                            value={this.state.sessionid}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Error Code
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="errorcode"
                                            value={this.state.errorcode}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            User Info
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="userinfo"
                                            value={this.state.userinfo}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            User Browser
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="userbrowser"
                                            value={this.state.userbrowser}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Server IP Address
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="serveripaddress"
                                            value={this.state.serveripaddress}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Server Port
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="serverport"
                                            value={this.state.serverport}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 alignRight">
                                        <label className="col-form-label">
                                            Created On
                                        </label>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control apilogLeftRightPadding"
                                            id="createdon"
                                            value={this.state.createdon}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row btn-container form-button">
                    <div className="col-sm-12 col-lg-12">
                        {this._renderMainButton()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    _renderMainButton = () => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.hideLogDetails();
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ViewApiLog);
