import React from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { queryMediaList } from '../actions/actions';

const FileTypes = ['ftl', 'css', 'html'];

class FileSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            background: '#fff',
            showFileList: true,
            fileSelected: '',
            id: '',
            folderPath: '/media',
        };
    }
    folderOnClick = (folderpath) => {
        this.setState({ folderPath: folderpath }, () => {
            this.callQueryApiForFetch('FETCHLIST', folderpath);
        });
    };

    createFetchUrl = (folderPath) => {
        const requestBody = {
            body: {
                path: folderPath,
            },
        };
        return requestBody;
    };
    callFetch = () => {
        const { folderPath } = this.state;
        this.props.dispatch(queryMediaList(this.createFetchUrl(folderPath)));
    };
    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'FETCHLIST':
                const [path] = rest;
                newState = produce(this.state, (draft) => {
                    draft.folderPath = path;
                });
                this.setState({ ...newState }, () => {
                    this.callFetch();
                });
        }
    };
    componentWillMount = () => {
        let path = this.state.folderPath;
        this.callQueryApiForFetch('FETCHLIST', '/media');
    };
    _openFileList = (event) => {
        this.setState({ showFileList: true }, () =>
            this.callQueryApiForFetch('FETCHLIST', '/media')
        );

        if (event) event.preventDefault();
    };
    _closeFileList = (event) => {
        this.setState({ folderPath: '/media' });
        this.props.closeFileSelect()
        if (event) event.preventDefault();
    };
    _selectFile = (event) => {
        if (event) event.preventDefault();
        this._closeFileList();
        this.setState({ fileSelected: this.state.fileSelected }, () => {
            this._closeFileList();
        });
        if (event) event.preventDefault();
        this.props.handleSetFileValue(this.state.fileSelected);
    };
    _clearFile = (event) => {
        if (event) event.preventDefault();
        this.setState({ fileSelected: '' }, () => {
            this._closeFileList();
        });
        if (event) event.preventDefault();
    };
    // Render images list display
    _renderFolder = (file, index) => {
        return (
            <div
                key={index}
                className="col-xs-6 col-lg-2 noPadding noMargin folderStyle"
            >
                <div
                    className="card "
                    onClick={() => this.folderOnClick(file.path)}
                    style={{ padding: '8px' }}
                >
                    <div className="card-body">
                        <img
                            className="card-img-top folderPreview"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_media_folder.svg`}
                            alt="Card image cap"
                        />
                        <div className="title_devide2" />
                        <p className="card-text text-center">
                            {file.medianame}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    _renderBreadCrumb = () => {
        const { folderPath } = this.state;
        let folders = folderPath.slice(1).split('/');

        return (
            <div className="breadcrumb">
                {folders.length != 0 &&
                    folders.map((folder, i) => {
                        let link =
                            folderPath.substring(
                                0,
                                folderPath.lastIndexOf(folder)
                            ) + folder;
                        return (
                            <a onClick={() => this.folderOnClick(link)} key={i}>
                                {folder}
                            </a>
                        );
                    })}
            </div>
        );
    };

    getFileDefaultIcon = (fileType) => {
        let iconUrl = ''
        switch (fileType) {
          case 'html':
            iconUrl = `${process.env.PUBLIC_URL}/assets/icons/html-file-icon.png`
            break;
          case 'css':
            iconUrl = `${process.env.PUBLIC_URL}/assets/icons/css-file-icon.png`
            break;
          default:
            iconUrl = `${process.env.PUBLIC_URL}/assets/icons/code-file-icon.png`
        }
        return iconUrl
      }

    _renderFilePreview = (file, index) => {
        let fileTypeArr = file.medianame.split('.');
        let fileType = fileTypeArr[fileTypeArr.length - 1]
        let isFileType = FileTypes.includes(
            fileTypeArr[fileTypeArr.length - 1]
        );

        let defaultFileIcon = isFileType
            ? this.getFileDefaultIcon(fileType)
            : `${localStorage.getItem('imagepath')}${
                file.path
            }`;
        return (
            <div
                key={index}
                className="col-lg-2 col-sm-6 noPadding noMargin folderStyle"
            >
                <div
                    className={`card ${
                        this.state.id === file.id
                            ? `border-blue`
                            : `imageNotSelected`
                    }`}
                    style={{ padding: '8px' }}
                >
                    <div
                        className="card-body"
                        onClick={() => {
                            let image = file.path;
                            this.setState({
                                fileSelected: image,
                                id: file.id,
                            });
                        }}
                        onDoubleClick={() => {
                            let image = file.path;
                            this.setState(
                                { fileSelected: image, id: file.id },
                                () => {
                                    this._selectFile();
                                    this._closeFileList();
                                }
                            );
                        }}
                    >
                        <img
                            className="card-img-top imgPreview"
                            src={defaultFileIcon}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
                            }}
                            alt="Missing File"
                        />
                        <div className="title_devide2" />
                        <p className="card-text text-center">
                            {file.medianame}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    // Render images list display
    _renderForm = (mediaList) => {
        return (
            <div className="row">
                {mediaList.length === undefined || mediaList.length === 0 ? (
                    <React.Fragment />
                ) : (
                    mediaList.map((file, index) =>
                        file.mediatype === 'FL'
                            ? this._renderFolder(file, index)
                            : this._renderFilePreview(file, index)
                    )
                )}
            </div>
        );
    };
    _renderFileModal = () => {
        const { mediaList } = this.props.queryData;
        const filteredFiles = mediaList.filter(file => {
            const path = file.path;
            return path.endsWith('.css') || path.endsWith('.ftl') || path.endsWith('.html') || file.mediatype === 'FL'
          });
          
        return (
            <Modal
                visible={this.state.showFileList}
                width="80%"
                height="80%"
                effect="fadeInRight"
                onClickAway={this._closeFileList}
            >
                <div className="modalForm imageModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6" />
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a onClick={this._closeFileList}>X</a>
                        </div>
                    </div>
                    <div className="title-devide" />
                    {this._renderBreadCrumb()}
                    {this._renderForm(filteredFiles)}

                    <div className="row btn-container1">
                        <div className="col-lg-6 col-md-12 col-sm-12 " />
                        <div className="col-lg-6 col-md-12 col-sm-12 alignRight">
                            <button
                                type="submit"
                                onClick={this._selectFile}
                                className="btn btn-themes btn-rounded"
                            >
                                Select
                            </button>
                            <button
                                type="submit"
                                onClick={this._closeFileList}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={this._clearFile}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div className={`${this.props.className || 'row'}`}>
                {this._renderFileModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(FileSelector);
