import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const DynamicInput = props => {
    const { fieldArr, handleChange } = props
    const [formFields, setFormFields] = useState(fieldArr.length > 0 ? fieldArr : [{ field: '', value: '' }]);

    useEffect(() => {
        if (fieldArr.length > 0) {
            setFormFields(fieldArr)
        }
    }, [fieldArr])

    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        handleChange(data)
    };
    return (
        <>
            {formFields.map((form, index) => {
                return (
                    <div className="flex flex-row mt-2" key={index}>
                        <InputText
                                name="field"
                                onChange={(event) => handleFormChange(event, index)}
                                value={form.field}
                                placeholder="Key"
                                className="mr-2"
                                disabled
                            />
                        <InputText
                                name="value"
                                onChange={(event) => handleFormChange(event, index)}
                                value={form.value}
                                placeholder="Value"
                                className="mr-2"
                                disabled
                            />
                    </div>
                );
            })}
        </>
    );
};

export default DynamicInput;
