import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Local Imports
// Local Imports
import SimpleSelect from "../../../components/SimpleSelect";
import {
  updateArticleInfoApi,
  createArticleInfoApi,
  queryDefaultConfigByTitle,
  crudReset,
  getStoreById
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusComp from "../../../components/StatusComp";
import { produce } from "immer";
import TenantCode from "../../../components/TenantCode";
class AddBeacon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      beaconid: "",
      title: "",
      uuid: "",
      minor: "",
      major: "",
      rssi: "",
      brand: "",
      status: "",
      remark: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      language: "en",
      storeiderror: "",
      titleerror: "",
      beaconiderrror: "",
      branderror: "",
      storelabel: ""
    };
  }

  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    const requestBody = {
      body: {
        title: "BEACON_BRAND",
        category: "Config"
      }
    };
    this.props.dispatch(
      queryDefaultConfigByTitle(
        requestBody,
        "GET_BEACON_BRAND_BY_TITLE",
        "GET_BEACON_BRAND_BY_TITLE_SUCCESS",
        "GET_BEACON_BRAND_BY_TITLE_FAILURE"
      )
    );
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const { id, storeid, title, fields, status, language } = rec;
        this.props.dispatch(getStoreById(storeid));
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            draft.status = status ? status : [];
            draft.language = language ? language : "en";
            draft.beaconid = fields.beaconid ? fields.beaconid : "";
            draft.uuid = fields.uuid ? fields.uuid : "";
            draft.minor = fields.minor ? fields.minor : "";
            draft.major = fields.major ? fields.major : "";
            draft.rssi = fields.rssi ? fields.rssi : "";
            draft.brand = fields.brand ? fields.brand : "";
            draft.remark = fields.remark ? fields.remark : "";
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  getCreatePayload = () => {
    const {
      storeid,
      beaconid,
      title,
      uuid,
      minor,
      major,
      rssi,
      brand,
      status,
      remark,
      language
    } = this.state;
    let body = {
      storeid: storeid,
      title: title,
      language: language,
      publishmode: "manual",
      articletype: "Beacon Information",
      status: status,
      fields: {
        beaconid: beaconid,
        uuid: uuid,
        minor: minor,
        major: major,
        rssi: rssi,
        brand: brand,
        remark: remark
      }
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createArticle = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      storeiderror: "",
      titleerror: "",
      beaconiderrror: "",
      branderror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { storeid, title, beaconid, brand } = this.state;
    let valid = true;
    if (storeid.trim() === "") {
      this.setState({
        storeiderror: "Please select a  store id"
      });
      valid = false;
    }
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter a title"
      });
      valid = false;
    }
    if (beaconid.trim() === "") {
      this.setState({
        beaconiderrror: "Please enter beacon id"
      });
      valid = false;
    }
    if (brand.trim() === "") {
      this.setState({
        branderror: "Please select a brand"
      });
      valid = false;
    }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/beacon-information"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  componentWillReceiveProps = () => {
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      //if (storeArray) this.setState({ storelabel: storeArray.title });
      if (storeArray) {
        storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
    }
  };

  _renderFormArea = () => {
    const { beaconBrands, languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className=" row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft required">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
            <div className="invalid-feedback">{this.state.storeiderror}</div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="beaconid" className="floatLeft required">
              Beacon ID
            </label>
            <input
              type="text"
              className="form-control"
              id="beaconid"
              value={this.state.beaconid}
              onChange={event => {
                this.setState({ beaconid: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.beaconiderrror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="uuid" className="floatLeft">
              UUID
            </label>
            <input
              type="text"
              className="form-control"
              id="uuid"
              value={this.state.uuid}
              onChange={event => {
                this.setState({ uuid: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="minor" className="floatLeft">
              Minor
            </label>
            <input
              type="text"
              className="form-control"
              id="minor"
              value={this.state.minor}
              onChange={event => {
                this.setState({ minor: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="major" className="floatLeft">
              Major
            </label>
            <input
              type="text"
              className="form-control"
              id="major"
              value={this.state.major}
              onChange={event => {
                this.setState({ major: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="rssi" className="floatLeft">
              RSSI
            </label>
            <input
              type="text"
              className="form-control"
              id="rssi"
              value={this.state.rssi}
              onChange={event => {
                this.setState({ rssi: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="brand" className="floatLeft required">
              Brand
            </label>
            <SimpleSelect
              objArray={beaconBrands.pvalues}
              callbackChangeFilter={value => this.setState({ brand: value })}
              selectedOption={this.state.brand}
              id="brand"
            />
            <div className="invalid-feedback">{this.state.branderror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="remark" className="floatLeft">
              Remarks
            </label>
            <textarea
              className="form-control"
              id="remark"
              value={this.state.remark}
              onChange={event => {
                this.setState({ remark: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={languageConfig.pvalues}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true ? "Edit Beacon" : "Add Beacon"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv text-center">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/beacon-information"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "Beacon information updated successfully"
                  : "Beacon Information created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddBeacon);
