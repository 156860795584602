import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';

const ProductCategorySelector = (props) => {

    const { onSelectProductCategory, productcategorytreeList } = props;

    const [nodes, setNodes] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);
    const [storeValue, setStoreValue] = useState('');
    const [categoryCode, setCategoryCode] = useState('');
    const [showSelectorModal, setShowSelectorModal] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState({});
    const openStoreSearchModal = () => {
        setShowSelectorModal(true);
    };

    useEffect(() => {
        if (productcategorytreeList && productcategorytreeList.id) {
            const newArrayOfObj = [productcategorytreeList].map(convertData);
            setNodes(newArrayOfObj);
            let _expandedKeys = {};
            for (let node of newArrayOfObj) {
                expandNode(node, _expandedKeys);
            }

            setExpandedKeys(_expandedKeys);
        }
    }, [productcategorytreeList]);

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    }

    const onNodeSelect = (event) => {
        const { node } = event
        if (node.children && node.children.length > 0) {
            // Node has children, do not handle the click event
            return;
          }
        //console.log('here', node)
        setStoreValue(node.title);
        setCategoryCode(node.categorycode);
        onSelectProductCategory(node.categorycode);
        setShowSelectorModal(false);
    };

    const onNodeUnselect = (node) => {
        console.log('node', node.label);
    };

    const convertData = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(convertData);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }

            if (prop === 'title') {
                obj['subtitle'] = obj['categorycode'];
            }
            obj['key'] = obj['categorycode'];
            obj['label'] = obj['title'];
            obj['sublabel'] = obj['categorycode']
        }
        return obj;
    };

    const nodeTemplate = (node, options) => {
        let label = <span>{node.label}</span>;
        
        if (node.sublabel) {
            label = <span><span className='f-14 fw-500'>{node.label}</span><br/><span className='f-11'>{node.categorycode}</span></span>
        }
        return (
            <span className={options.className}>
                {label}
            </span>
        )
    }

    const removeProduct = () => {
        setStoreValue('');
        setCategoryCode();
        onSelectProductCategory();
    }

    const getProductAction = () => {
        if (categoryCode) {
            return (
                <i
                    className="pi pi-times-circle"
                    onClick={removeProduct}
                    style={{ cursor: 'pointer' }}
                />
            );
        }
        return <i className="pi pi-caret-right" />;
    };

    return (
        <React.Fragment>
            <Dialog
                header="Product Category Select"
                visible={showSelectorModal}
                style={{ width: '50vw' }}
                onHide={() => setShowSelectorModal(false)}
            >
                <Tree
                    value={nodes}
                    selectionMode="single"
                    selectionKeys={selectedKey}
                    onSelectionChange={(e) => setSelectedKey(e.value)}
                    onSelect={onNodeSelect}
                    onUnselect={onNodeUnselect}
                    expandedKeys={expandedKeys}
                    nodeTemplate={nodeTemplate}
                />
            </Dialog>
            <span className="p-input-icon-right w-100">
                {getProductAction()}
                <InputText
                    className='w-100'
                    value={storeValue}
                    readOnly={true}
                    onClick={openStoreSearchModal}
                    style={{ cursor: 'pointer'}}
                />
            </span>
        </React.Fragment>
    );
};

export default ProductCategorySelector;
