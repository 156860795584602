import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import Filter from '../../components/Filter';
import {
    crudReset,
    exportReport,
    searchGeneral,
    searchCouponByFields,
} from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert';
import * as CONSTANTS from '../../constants/constants';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import Loading from "../../components/Loading";
import ReportWarning from '../../components/ReportWarning';

const CouponTransactionReport = (props) => {
    const {
        errormsg: queryError,
        servererror: serverError,
        jobtasklogs,
        reportexport,
        couponsList,
        apiPending
    } = props.queryData;
    const [couponList, setCouponList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [message, setMessage] = useState('');

    const [firstRow, setFirst2] = useState(0);
    const [tableRows, setRows2] = useState(10);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        props.dispatch(searchCouponByFields({ body: { status: 'Active' } }));
    }, []);

    useEffect(() => {
        if (!apiPending) {
            setIsLoading(false)
        }
    }, [apiPending])

    useEffect(() => {
        if (couponsList.length > 0) {
            let returnedData = couponsList.map((e) => ({
                name: e.couponcode + ' - ' + e.title,
                id: e.couponcode,
            }));
            setCouponList([{ name: 'Select', id: '' }].concat(returnedData));
        }
    }, [couponsList]);

    useEffect(() => {
        callQueryApiForFetch()
    }, []);

    const callQueryApiForFetch = () => {
        props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                createFetchUrl(),
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    }

    const createFetchUrl = () => {
        const requestBody = {
            body: {
                pagesize: pageSize,
                pageno: currPage,
                tasktype: getTaskType(),
            },
        };
        return requestBody;
    };

    const getTaskType = () => {
        return 'coupon_transaction_export';
    };

    const formik = useFormik({
        initialValues: {
            couponcode: '',
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.couponcode) {
                errors.couponcode = 'Coupon code is required.';
            }
            console.log('error', errors);
            return errors;
        },
        onSubmit: (values) => {
            //setFormData(values);
            const data = {
                ...values,
                couponcode: values.couponcode,
            };
            handleFilter(data);
            //formik.resetForm();
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleFilter = (data) => {
        const { couponcode } = data;
        const queryParams = [];
        // if (storeid !== '') {
        //     queryParams.push(`storeid=${storeid}`)
        // }
        if (couponcode !== '') {
            queryParams.push(`couponcode=${couponcode}`);
        }
        setMessage('Request submitted.')
        setIsLoading(true)
        props.dispatch(exportReport(exportReportUrl(), queryParams.join('&')));
    };

    const exportReportUrl = () => {
        return '/campaign/v1/coupon/transaction/report/export';
    };

    const renderSearchCiteria = () => {
        return (
            <div className="row">
                <div className="form-group col-lg-3 col-sm-12">
                    <label htmlFor="terminalid" className="floatLeft required">
                        Coupon Code
                    </label>
                    <Filter
                        filterOptions={couponList}
                        selectedOption={formik.values.couponcode}
                        callbackChangeFilter={(event) => {
                            formik.setFieldValue(
                                'couponcode',
                                event.target.value
                            );
                        }}
                        id="title"
                    />
                    {getFormErrorMessage('couponcode')}
                </div>
                <div
                    className="col-lg-3 col-sm-12 btn-container1"
                    style={{ marginTop: '1em' }}
                >
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Export
                    </button>
                </div>
            </div>
        );
    };

    const actionTemplate = (row) => {
        if (row.status !== 'exported') {
            return;
        }
        if (row.additionalfields['filepath'] === undefined) {
            return;
        }
        return (
            <span>
                <img
                    title="Download"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                    onClick={() => download(row)}
                />
            </span>
        );
    };

    const download = (row) => {
        var link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
            var url =
                localStorage.getItem('imagepath') +
                row.additionalfields['filepath'];
            console.log('url::', url);
            link.setAttribute('href', url);

            var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const hideAlertModal = (e) => {
        // this.setState({ message: "", action: '' });
        e.preventDefault();
        setMessage('')
        props.dispatch(crudReset());
        props.queryData.reportexport = {}
        callQueryApiForFetch();
    };

    const handleNextPage = () => {
        setCurrPage(currPage + 1);
        props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                {
                    body: {
                        pagesize: pageSize,
                        pageno: currPage + 1,
                        tasktype: getTaskType(),
                    },
                },
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    const handlePrevPage = () => {
        setCurrPage(currPage - 1);
        props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                {
                    body: {
                        pagesize: pageSize,
                        pageno: currPage - 1,
                        tasktype: getTaskType(),
                    },
                },
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    const handlePageChange = (e) => {
        e.preventDefault();
        setPageSize(e.target.value);
        props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                {
                    body: {
                        pagesize: pageSize,
                        pageno: currPage,
                        tasktype: getTaskType(),
                    },
                },
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    const renderPaginationTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span
                        className="mx-1"
                        style={{
                            color: "var(--text-color)",
                            userSelect: "none",
                        }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={pageSize}
                        options={dropdownOptions}
                        onChange={(e) => handlePageChange(e)}
                    />
                </React.Fragment>
            );
        },
        CurrentPageReport: () => {
            return (
                <div>
                    <button
                        type="button"
                        className={classNames({
                            "p-paginator-prev p-paginator-element p-link": true,
                            "p-disabled": currPage == 1,
                        })}
                        disabled={currPage == 1}
                        aria-label="Previous Page"
                        onClick={handlePrevPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                    <button
                        type="button"
                        className={classNames({
                            "p-paginator-prev p-paginator-element p-link": true,
                            "p-disabled": jobtasklogs.length < pageSize,
                        })}
                        aria-label="Next Page"
                        onClick={handleNextPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-right"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                </div>
            );
        },
    };

    const onCustomPage2 = (event) => {
        setFirst2(event.first);
        setRows2(event.rows);
    };

    return (
        <>
            {isLoading && (
                <Loading />
            )}
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">
                            Coupon transaction report
                            <img
                                title="Refresh"
                                onClick={() => {
                                    callQueryApiForFetch();
                                }}
                                className="refreshtree"
                                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                            />
                        </h2>
                    </div>
                </div>

                <div className="row form-container">
                    <div className="col">
                        <div className="frmDiv">
                            <form className="" onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    {renderSearchCiteria()}
                                </div>
                                <div className="title_devide" />
                            </form>
                            <ReportWarning />
                            <DataTable
                                value={jobtasklogs}
                                showGridlines
                                columnResizeMode="fit"
                                responsiveLayout="scroll"
                                emptyMessage="There are no any data."
                                paginatorClassName="justify-content-end"
                                sortField="createdon"
                                sortOrder={-1}
                                paginator
                                paginatorTemplate={renderPaginationTemplate}
                                first={firstRow}
                                rows={tableRows}
                                onPage={onCustomPage2}
                            >
                                <Column field="id" header="Id" sortable />
                                <Column field="title" header="Title" sortable />
                                <Column
                                    field="createdon"
                                    header="Exported On"
                                />
                                <Column
                                    field="status"
                                    header="Status"
                                    sortable
                                />
                                <Column
                                    header="Actions"
                                    body={actionTemplate}
                                ></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <ConfirmAlert
                    show={
                        reportexport && reportexport.status === 'exported'
                            ? true
                            : false
                    }
                    handleClose={hideAlertModal}
                    children={
                        <div
                            style={
                                reportexport &&
                                reportexport.status === 'exported'
                                    ? { padding: '2em', color: 'green' }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {reportexport && reportexport.status === 'exported'
                                ? message
                                : ''}
                        </div>
                    }
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(CouponTransactionReport);
