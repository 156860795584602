import React from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Divider } from "primereact/divider";
import ReactSwitch from "react-switch";

const promoGroupOptions = [
    { name: "By Percentage", id: "Percent" },
    { name: "By Amount", id: "Value" },
    { name: "Buy X Get Y", id: "BuyGet" },
];

const customerTypeOptions = [
    { name: "Guest", id: "Guest" },
    { name: "Member", id: "Member" },
];

const promoTypeOptions = [
    { name: "Item", id: "Item" },
    { name: "Cart", id: "Cart" },
    { name: "RTC", id: "Rtc" },
    { name: "Coupon", id: "Coupon" },
    { name: "Cart Coupon", id: "CartCoupon" },
];

const PromotionBasicInformationInput = (props) => {
    const {
        formik,
        isFormFieldValid,
        getFormErrorMessage,
        isItTaxable,
        handleTaxDiscountChange,
        handleBatchChange,
        handleStepChange,
        isItBatch,
        isViewMode
    } = props;

    const getPromoTypeAddOn = () => {
        if (formik.values.promogroup.id === 'Percent') {
            return '%'
        }
        if (formik.values.promogroup.id === 'Value') {
            return '$'
        }
        if (formik.values.promogroup.id === 'BuyGet') {
            return 'Qty'
        }
        return ''
    }
    return (
        <>
            <div className="ph-20 pv-20">
                <div className="row">
                    <div className="p-fluid formgrid grid w-100">
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label htmlFor="storeid">Store Id</label>
                            <InputText
                                id="storeid"
                                type="text"
                                value={formik.values.storeid}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="promogroup"
                                className={classNames({
                                    "p-error": isFormFieldValid("promogroup"),
                                })}
                            >
                                Promo Group*
                            </label>
                            <Dropdown
                                id="promogroup"
                                name="promogroup"
                                value={formik.values.promogroup}
                                onChange={formik.handleChange}
                                options={promoGroupOptions}
                                optionLabel="name"
                                placeholder="Select"
                                disabled={isViewMode}
                            />
                            {getFormErrorMessage("promogroup")}
                        </div>
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label htmlFor="customertype">Customer Type</label>
                            <Dropdown
                                id="customertype"
                                name="customertype"
                                value={formik.values.customertype}
                                onChange={formik.handleChange}
                                options={customerTypeOptions}
                                optionLabel="name"
                                placeholder="Select"
                                disabled={isViewMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="p-fluid formgrid grid w-100">
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="promocode"
                                className={classNames({
                                    "p-error": isFormFieldValid("promocode"),
                                })}
                            >
                                Promo Code*
                            </label>
                            <InputText
                                id="promocode"
                                type="text"
                                value={formik.values.promocode}
                                onChange={formik.handleChange}
                                disabled={isViewMode}
                            />
                            {getFormErrorMessage("promocode")}
                        </div>
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="promotype"
                                className={classNames({
                                    "p-error": isFormFieldValid("promotype"),
                                })}
                            >
                                Promo Type*
                            </label>
                            <Dropdown
                                id="promotype"
                                name="promotype"
                                value={formik.values.promotype}
                                onChange={formik.handleChange}
                                options={promoTypeOptions}
                                optionLabel="name"
                                placeholder="Select"
                                disabled={isViewMode}
                            />
                            {getFormErrorMessage("promotype")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="p-fluid formgrid grid w-100">
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="title"
                                className={classNames({
                                    "p-error": isFormFieldValid("title"),
                                })}
                            >
                                Promo Name*
                            </label>
                            <InputText
                                id="title"
                                type="text"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                disabled={isViewMode}
                            />
                            {getFormErrorMessage("title")}
                        </div>
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label htmlFor="promotypevalue">Coupon Code</label>
                            <InputText
                                id="promotypevalue"
                                type="text"
                                name="promotypevalue"
                                value={formik.values.promotypevalue}
                                onChange={formik.handleChange}
                                disabled={isViewMode || ((formik.values.promotype.id !== 'Coupon') && (formik.values.promotype.id !== 'CartCoupon'))}
                            />
                            {getFormErrorMessage("promotypevalue")}
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="pv-20 ph-20">
                <h6 className="mh-8">Discount Details</h6>
                <div className="row">
                    <div className="p-fluid formgrid grid w-100">
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="discountvalue"
                                className={classNames({
                                    "p-error":
                                        isFormFieldValid("discountvalue"),
                                })}
                            >
                                Discount Value*
                            </label>
                            <div className="p-inputgroup">
                                <InputText
                                    id="discountvalue"
                                    type="number"
                                    value={formik.values.discountvalue}
                                    onChange={formik.handleChange}
                                    disabled={isViewMode}
                                />
                                <span className="p-inputgroup-addon">
                                    {getPromoTypeAddOn()}
                                </span>
                            </div>
                            {getFormErrorMessage("discountvalue")}
                        </div>
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="isItTaxable"
                                className="w-100 mb-12"
                            >
                                Tax Discount
                            </label>
                            <ReactSwitch
                                checked={isItTaxable}
                                name="taxondiscount"
                                onChange={handleTaxDiscountChange}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={isViewMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="p-fluid formgrid grid w-100">
                        <div className="field form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="discountcapamt"
                                className={classNames({
                                    "p-error":
                                        isFormFieldValid("discountcapamt"),
                                })}
                            >
                                Discount Cap Amount*
                            </label>
                            <InputText
                                id="discountcapamt"
                                type="number"
                                value={formik.values.discountcapamt}
                                onChange={formik.handleChange}
                                disabled={isViewMode}
                            />
                            {getFormErrorMessage("discountcapamt")}
                        </div>
                        <div className="field form-group col-lg-1 col-sm-12">
                            <label htmlFor="isItBatch" className="w-100 mb-12">
                                Batch
                            </label>
                            <ReactSwitch
                                checked={isItBatch}
                                onChange={handleBatchChange}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={isViewMode}
                            />
                        </div>
                        {isItBatch && (
                            <div className="field form-group col-lg-4 col-sm-12">
                                <label htmlFor="batchvalue">Batch Value</label>
                                <InputText
                                    id="batchvalue"
                                    type="number"
                                    value={formik.values.batchvalue}
                                    onChange={formik.handleChange}
                                    disabled={isViewMode}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isViewMode && (
                    <div className="row">
                    <div className="col-6 alignRight alignWithPageHeader ml-auto ph-16">
                        <Button
                            type="button"
                            className={classNames({
                                "p-button-success":
                                    formik.values.promogroup &&
                                    formik.values.discountvalue &&
                                    formik.values.discountcapamt &&
                                    formik.values.title &&
                                    formik.values.promotype,
                                "p-button-secondary":
                                    !formik.values.promogroup ||
                                    !formik.values.discountvalue ||
                                    !formik.values.discountcapamt ||
                                    !formik.values.title ||
                                    !formik.values.promotype,
                            })}
                            label="Next"
                            onClick={() => handleStepChange(1)}
                            disabled={
                                !formik.values.promogroup ||
                                !formik.values.discountvalue ||
                                !formik.values.discountcapamt ||
                                !formik.values.title ||
                                !formik.values.promotype
                            }
                        />
                    </div>
                </div>
                )}
            </div>
        </>
    );
};

export default PromotionBasicInformationInput;
