export const getElevateDateFromJsDate = jsDate => {
  let convertedDate = new Date(jsDate);
  let month = convertedDate.getMonth() + 1;
  month = month<10 ? `0${month}`:month
  let day = convertedDate.getDate();
  day = day<10 ? `0${day}`:day
  let year = convertedDate.getFullYear();
  let shortDate = `${day}-${month}-${year}`;
  return shortDate;
};

export const getJsDateFromElevateDate = dateStr => {
  const [day, month, year] = dateStr.split('-');
  return new Date(year, month - 1, day);
};
