import React from "react";
import { connect } from "react-redux";
import 'rc-time-picker/assets/index.css';
import SimpleSelect from "../../../components/SimpleSelect";
import moment from 'moment';
import {
    createSalesPromoTargetGroup,
    updateSalesPromoTargetGroup,
    promotionPGroupSearchByFields
} from "../../../actions/actions";
// Local Imports
// Local Imports
import { produce } from "immer";
import Modal from "react-awesome-modal";
import ProductSearch from "./ProductSearch";
import Select, { components } from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


class AddPromoTargetGroup extends React.PureComponent {


    constructor(props) {
        super(props);

        //this.newCategories = []
        this.savedOnce = false

        this.state = {
            id: "",
            isEdit: false,
            isViewInEdit: false,
            isView: false,
            isForApproval: false,

            showProductConfig: true,
            showProductCodeSelection: false,
            showProductInput: false,
            // selectedProducts: [],
            enteredProducts: "",
            expandCategoryForProductsList: [],
            selectCategoryForProductsList: [],

            // Formrelated
            // promocode: "",
            title: "",
            // storeid: this.props.storeid,
            promoproductcode: "",
            productcodes: [],
            promopurchasecode: "",
            //purchasegroupcode: 'PR99991', //TODO
            productselection: "",
            sortorder: 1,
            status: "Active",
            promotionspurchasegroups: [],
            selectedPurchaseGroups: {},

            // Errors
            titleerror: "",
            productcodeserror: '',
            productselectionerror: "",
            promopurchasecodeerror: "",
            // purchasegroupcodeerror: ""
        };
    }

    getCreatePayload = () => {
        const {
            //promoproductcode,
            // storeid,
            title,
            productcodes,
            promopurchasecode,
            productselection,
            // promocode,
            sortorder,
            status
        } = this.state;

        let body = {
            //promoproductcode,
            storeid: this.props.storeid,
            title,
            productcodes: productcodes.length > 0 ?  productcodes.join(',') : '',
            promopurchasecode,
            productselection,
            promocode: this.props.promocode,
            sortorder,
            status
        };

        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body
        };
        return payload;
    };

    _createSalesPromoTargetGroup = (e) => {
        if (e) { e.preventDefault() }
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateSalesPromoTargetGroup(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createSalesPromoTargetGroup(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue"
            });
            // window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            titleerror: "",
            promocodeerror: "",
            promogrouperror: "",
            promotypevalueerror: "",
            validfromerror: "",
            validtillerror: "",
            storeidserror: ""
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            promopurchasecode,
            productcodes,
            productselection
        } = this.state;


        console.log('states', title, productcodes, productselection)

        let valid = true;
        if (title.trim() === "") {
            this.setState({ titleerror: "Please enter a title" });
            valid = false;
        }

        if (promopurchasecode.trim() === '') {
            this.setState({ promopurchasecodeerror: 'Please select the purchase group' })
            valid = false
        }
        if (productcodes.length <= 0) {
            if (this.state.productselection !== 'DEP' && this.state.productselection !== 'DSEP' && this.state.productselection !== 'DCP') {
                this.setState({ productcodeserror: "Please add product codes" });
                valid = false;
            }
        }
        if (productselection.length === 0) {
            this.setState({ productselectionerror: "Please select a productselection" });
            valid = false;
        }
        console.log('isValid', valid)
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        } else {
            return (<div />)
        }
        return;
    };

    _isViewInEditMode = () => {
        return this.state.isViewInEdit
    }

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _isApprovalMode = () => {
        return this.state.isForApproval;
    }

    handleChange(time, name) {
        if (time !== null) {
            let timeformat = moment(time).format("HH:mm");
            this.setState({
                [name]: timeformat
            });
        }
        else {
            this.setState({
                [name]: ""
            })
        }
    }



    buttonHanlder = (value) => {
        var dateformat = [("0" + value.getDate()).slice(-2) + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + value.getFullYear()];
        this.setState(prevState => (
            { closedatearr: [...prevState.closedatearr, ...dateformat] }
        ));
    }

    textareaHandler = (val) => {
        const { closedatearr } = this.state;
        let filterdata = closedatearr.filter(item =>
            item !== val
        );
        this.setState({
            closedatearr: filterdata
        })
    }

    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        const isViewInEditMode = this._isViewInEditMode()
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._createSalesPromoTargetGroup(e)
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && !isViewInEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => { this._createSalesPromoTargetGroup(e) }}
                    >
                        Update
                    </button>
                )}

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => this.props.hide(this.state)}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    _renderAddProductsActionButton = () => {
        return (
            <React.Fragment>

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={() => {
                        this._updateEnteredProducts();
                    }}
                >
                    Add
                </button>

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={this.hideAddProductControl}
                >
                    Cancel
                </button>



            </React.Fragment>
        );
    };

    _removeSelectedProduct = (e, s) => {
        if (e) { e.preventDefault() }
        //let selectedProducts = this.state.selectedProducts
        let selectedProducts = this.state.productcodes
        selectedProducts = selectedProducts.filter(e => e != s)
        //this.setState({ selectedProducts: selectedProducts })
        this.saveSelectedProducts(selectedProducts)
    }

    _renderSelectedProducts = () => {

        if (this.state.productcodes.length <= 0) { return }

        var contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '150px'
        }

        return (
            <div className="row" style={{ marginTop: "0.6em" }}>
                <div style={contentareastyle}>
                    {this.state.productcodes.map((s) => {
                        return (
                            <div style={{ padding: "2px 2px", margin: "2px 2px", borderRadius: "4px", background: "#347bf6" }}>
                                <label style={{ color: "white" }} >{s}</label>
                                {!this._isViewInEditMode() && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) => this._removeSelectedProduct(e, s)}
                                    />
                                )}
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        )
    }

    validateStoreSelection = () => {
        if (this.props.storeid !== '') {
            return true
        } else {
            this.setState({ storeiderror: 'select store' })
            return false
        }
    }

    _renderProductConfiguration = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-lg-12 col-sm-12 noPadding noMargin promoPeriods">
                        <div className="row">
                            <label htmlFor="productcodes" className="floatLeft" style={{ marginLeft: "2", marginTop: "2" }}>
                                Product Codes
                            </label>
                        </div>
                        <div className="promoPeriods" style={{ minHeight: 100, margin: "1em", background: "#fff" }}>
                            {this._renderSelectedProducts()}
                        </div>
                        {!this._isViewInEditMode() && (
                            <div className="row">
                                <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                                <div className="col-lg-3 col-sm-12 noPadding noMargin" />
                                <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                    <button
                                        className="page-header-btn icon_btn display-inline"
                                        onClick={event => this.showAddProductControl(event)}
                                    >
                                        <img
                                            title="edit"
                                            className="tableImage"
                                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                        />
                                        Add Product Code
                                    </button>
                                </div>
                                <div className="col-lg-3 col-sm-12 noPadding noMargin">
                                    {/* <button
                                    className="page-header-btn icon_btn display-inline"
                                    onClick={event => this.showProductCodeSelectionTree(event)}
                                >
                                    <img
                                        title="edit"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                                    />
                                    {`Select Product`}
                                </button> */}
                                    <ProductSearch storeid={this.props.storeid} nodes={this.props.nodes} onSelectedProducts={this._updateSelectedProducts} validateStoreSelection={this.validateStoreSelection} disabled={this._isViewMode()} />
                                </div>
                            </div>
                        )}
                        <div className="invalid-feedback">{this.state.productcodeserror}</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    showAddProductControl = (e) => {
        if (e) { e.preventDefault() }
        this.setState({ showProductInput: true }, () => {

        })
    }

    hideAddProductControl = () => {
        this.setState({ showProductInput: false })
    }

    _updateSelectedProducts = (selectedProducts) => {
        console.log('updating selected products...', selectedProducts)
        // if (selectedProducts === '') {
        //     return
        // }

        selectedProducts = selectedProducts.map((e) => {
            e.trim()
        })

        console.log('updating selected products...', selectedProducts)

        var newlyAddedProducts = selectedProducts.filter(e => !this.state.productcodes.includes(e))
        console.log('newlyAddedProducts', newlyAddedProducts)
        if (newlyAddedProducts.length <= 0) {
            this.setState({ enteredProducts: "" })
            this.hideAddProductControl()
            return
        }

        this.setState({
            //selectedProducts: [...this.state.selectedProducts, selectedProducts]
            //selectedProducts: this.state.selectedProducts.concat(selectedProducts)
            productcodes: this.state.productcodes.concat(selectedProducts)
        }, () => {
            this.setState({ enteredProducts: "" })
            // this.saveSelectedProducts(this.state.selectedProducts)
        })
    }

    _updateEnteredProducts = () => {
        console.log('enteredproducts', this.state.enteredProducts, this.state.productcodes)
        if (this.state.enteredProducts === '') {
            this.hideAddProductControl()
            return
        }
        var selectedProducts = this.state.enteredProducts.trim().split(",")
        selectedProducts = selectedProducts.map (e => e.trim())
        var newlyAddedProducts = selectedProducts.filter(e => !this.state.productcodes.includes(e))
        console.log('newlyAddedProducts', newlyAddedProducts)
        if (newlyAddedProducts.length <= 0) {
            this.setState({ enteredProducts: "" })
            this.hideAddProductControl()
            return
        }

        this.setState({
            //selectedProducts: [...this.state.selectedProducts, selectedProducts]
            //selectedProducts: this.state.selectedProducts.concat(selectedProducts)
            productcodes: this.state.productcodes.concat(newlyAddedProducts)
        }, () => {
            this.setState({ enteredProducts: "" })
            this.hideAddProductControl()
            //this.saveSelectedProducts(this.state.selectedProducts)
        })
    }

    showProductCodeSelectionTree = (e) => {
        if (e) { e.preventDefault() }
        this.setState({ showProductCodeSelection: true }, () => {
            //this.props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
        })
    }

    hideProductCodeSelectionTree = () => {
        this.setState({ showProductCodeForExclusion: false, showProductCodeSelection: false })
    }

    onCheckCategory = (selectedCategories) => {
        console.log('newly selected::', selectedCategories)
        this.setState({ selectedCategories: selectedCategories })
        this.saveSelected(selectedCategories)
        // this.newRoles = selectedRoles
    }

    onExpandCategory = (expanded) => {
        this.setState({ expandedCategories: expanded })
    }

    onCheckCategoryForProductsList = (selectedProducts) => {

    }

    onExpandategoryForProductsList = () => {

    }

    saveSelectedProducts = (e) => {
        this.setState({ productcodes: e })
        this.savedOnce = true
    }

    // rename = obj => {
    //     console.log('rename for obj::', obj)
    //     for (var prop in obj) {
    //         if (Array.isArray(obj[prop])) {
    //             if (obj[prop].length > 0) {
    //                 obj.showCheckbox = false
    //             }
    //             obj[prop] = obj[prop].map(this.renme);
    //         }

    //         if (prop === 'categorycode') {
    //             obj.value = obj[prop]
    //             obj.icon = ''
    //         } else if (prop === 'title') {
    //             obj.label = obj[prop]
    //         } else if (prop === "subcategories") {
    //             obj.children = obj[prop];
    //         }
    //         delete obj[prop];
    //     }

    //     return obj;
    // };

    _renderAddProductControl = () => {
        return (
            <Modal
                visible={this.state.showProductInput}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this.hideAddProductControl()}
            >

                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">
                                Product Codes
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a onClick={this.hideAddProductControl} className="closeIcon">
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea
                                className="form-control"
                                id="enteredProducts"
                                value={this.state.enteredProducts}
                                onChange={event => this.setState({ enteredProducts: event.target.value })}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {this._renderAddProductsActionButton()}
                    </div>
                </div>

            </Modal>
        )
    }

    // _renderProductSelectionTree = () => {
    //     if (this.state.showProductCodeSelection) {
    //     return (
    //         <React.Fragment>
    //             <ProductSearch />
    //         </React.Fragment>
    //     )
    //     }
    // }

    _renderProductSelection = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="productSelection" className="floatLeft required">
                            Product Selection
                        </label>
                        {this._isViewInEditMode() && (
                            <input
                                type="text"
                                className="form-control"
                                id="targetgroupname"
                                value={this.state.productselection}
                                onChange={event =>
                                    this.setState({ productselection: event.target.value })
                                }
                                disabled={true}
                            />
                        )}
                        {!this._isViewInEditMode() && (
                            <SimpleSelect
                                objArray={{
                                    DEP: "Disc on each purchase",
                                    DSEP: "Disc split on each purchase",
                                    DSP: "Disc on selected products",
                                    DSSPEP: "Disc split on selected products & each purchase",
                                    DCP: "Disc on cheapest purchase",
                                }}
                                callbackChangeFilter={value =>
                                    this.setState({ productselection: value, productselectionerror: '' })
                                }
                                selectedOption={this.state.productselection}
                                id="productselection"
                            />
                        )}
                    </div>
                    <div className="invalid-feedback">{this.state.productselectionerror}</div>
                </div>
            </React.Fragment>
        )
    }

    handlePPGChange = (selected) => {
        this.setState({
            selectedPurchaseGroups: selected
        });

        console.log('selected PPG::', selected)
        if (selected) {
            this.setState({ promopurchasecode: selected.value, promopurchasecodeerror: '' })
        } else {
            this.setState({ promopurchasecode: '' })
        }

    };

    _renderMainDefinition = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">
                    Promo Target Group
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 noPadding noMargin">
                        {/* <form className="splitFrmDiv text-center "> */}
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label htmlFor="promoname" className="floatLeft required">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="targetgroupname"
                                    value={this.state.title}
                                    onChange={event =>
                                        this.setState({ title: event.target.value })
                                    }
                                    disabled={this._isViewInEditMode()}
                                />
                                <div className="invalid-feedback">{this.state.titleerror}</div>
                            </div>


                            <div className="form-group col-lg-6 col-sm-12">
                                <div className="row" style={{ marginBottom: 0 }}>
                                    <label htmlFor="purchasegroupcode" className="floatLeft required">
                                        Purchase Group Code
                                    </label>
                                </div>
                                {this._isViewInEditMode() ? (
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="promotionspurchasegroups"
                                        value={this.state.selectedPurchaseGroups && this.state.selectedPurchaseGroups.length > 0 && this.state.selectedPurchaseGroups[0].label}
                                        disabled={true}
                                    />) : (
                                    <Select
                                        options={this.state.promotionspurchasegroups}
                                        isMulti={false}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        onChange={this.handlePPGChange}
                                        allowSelectAll={false}
                                        value={this.state.selectedPurchaseGroups}
                                    />
                                )}
                                <div className="invalid-feedback">{this.state.promopurchasecodeerror}</div>
                            </div>
                        </div>
                        {this.state.showProductConfig && this._renderProductConfiguration()}
                        {this._renderProductSelection()}
                        {this._renderMainButton()}
                        {/* </form> */}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    // componentWillReceiveProps = () => {
    //     // if (this._isEditMode()) {
    //     //     const { storeArray } = this.props.queryData;
    //     //     if (storeArray) this.setState({ storelabel: storeArray.title });
    //     // }
    //     console.log('AddPromoTargetGroup componentWillReceiveProps.......')
    //     const { promotionspurchasegroups } = this.props.queryData
    //     console.log('promotionspurchasegroups::',promotionspurchasegroups)
    //     if (promotionspurchasegroups && promotionspurchasegroups.length > 0) {
    //         const options = promotionspurchasegroups.map((e) => ({ label: e.promopurchasecode, value: e.promopurchasecode }))
    //         this.setState({promotionspurchasegroups: options})
    //     } else {
    //         this.setState({promotionspurchasegroups: []})
    //     }
    // };

    componentDidMount = () => {
        // this.props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
        console.log('AddPromoTargetGroup mounting.......')
        this.props.dispatch(promotionPGroupSearchByFields(`storeid=${this.props.storeid}&status=Active`))
    };

    // componentWillMount = () => {
    //     // this.props.dispatch(getCouponCodeList());
    //     if (this.props.location && this.props.location.state) {
    //         const rec = this.props.location.state.item;
    //         const isEdit = this.props.location.state.isEdit;
    //         const isView = this.props.location.state.isView;
    //         const isForApproval = this.props.location.state.isForApproval;

    //         if (rec) {
    //             const {
    //                 id,
    //                 promocode,
    //                 storeid,
    //                 title,
    //                 promotype,
    //                 promotypevalue,
    //                 promogroup,
    //                 categorytype,
    //                 categorytypevalue,
    //                 categoryfilterby,
    //                 categoryfiltervalues,
    //                 cartqtymax,
    //                 cartqtymin,
    //                 cartamountmax,
    //                 cartamountmin,
    //                 qtymax,
    //                 qtymin,
    //                 amountmax,
    //                 amountmin,
    //                 pointcategory,
    //                 pointburn,
    //                 discountby,
    //                 discountbybatchvalue,
    //                 discountbyvalue,
    //                 discountbyminvalue,
    //                 discountbymaxvalue,
    //                 discountcapamt,
    //                 memberonly,
    //                 memberfilter,
    //                 validfrom,
    //                 validtill,
    //                 opentime,
    //                 closetime,
    //                 validdays,
    //                 closedates,
    //                 itemfilter,
    //                 status,
    //                 sortorder,
    //                 additionalfields
    //             } = rec;
    //             //this.props.dispatch(getStoreById(storeid));
    //             let close_dates = closedates.split(",").map(e => e);
    //             let closedatearray = [...new Set(close_dates)];
    //             let defaultaddField = [{ field: "", value: "", id: 0 }];
    //             let defaultmemField = [{ field: "", value: "", id: 0 }];
    //             this.setState(
    //                 produce(draft => {
    //                     draft.id = id ? id : "";
    //                     draft.isEdit = isEdit;
    //                     draft.isView = isView;
    //                     draft.isForApproval = isForApproval;
    //                     draft.title = title ? title : "";
    //                     draft.promocode = promocode ? promocode : "";
    //                     draft.storeid = storeid ? storeid : "";
    //                     draft.promogroup = promogroup ? promogroup : "-1";
    //                     draft.promotype = promotype ? promotype : "";
    //                     draft.promotypevalue = promotypevalue ? promotypevalue : "";
    //                     draft.categorytype = categorytype ? categorytype : "";
    //                     draft.categorytypevalue = categorytypevalue ? categorytypevalue.split(',') : [];
    //                     draft.categoryfilterby = categoryfilterby ? categoryfilterby : "";
    //                     draft.categoryfiltervalues = categoryfiltervalues ? categoryfiltervalues : "";
    //                     draft.cartqtymax = cartqtymax ? cartqtymax : "";
    //                     draft.cartqtymin = cartqtymin ? cartqtymin : "";
    //                     draft.cartamountmax = cartamountmax ? cartamountmax : 0;
    //                     draft.cartamountmin = cartamountmin ? cartamountmin : 0;
    //                     draft.qtymax = qtymax ? qtymax : 0;
    //                     draft.qtymin = qtymin ? qtymin : 0;
    //                     draft.amountmax = amountmax ? amountmax : 0;
    //                     draft.amountmin = amountmin ? amountmin : 0;
    //                     draft.pointcategory = pointcategory ? pointcategory : "";
    //                     draft.pointburn = pointburn ? pointburn : 0;
    //                     draft.discountby = discountby ? discountby : "";
    //                     draft.discountbybatchvalue = discountbybatchvalue ? discountbybatchvalue : 0;
    //                     draft.discountbymaxvalue = discountbymaxvalue ? discountbymaxvalue : 0;
    //                     draft.discountbyminvalue = discountbyminvalue ? discountbyminvalue : 0;
    //                     draft.discountbyvalue = discountbyvalue ? discountbyvalue : 0;
    //                     draft.discountcapamt = discountcapamt ? discountcapamt : 0;
    //                     draft.validfrom = validfrom ? this.toDate(validfrom) : "";
    //                     draft.validtill = validtill ? this.toDate(validtill) : "";
    //                     draft.opentime = opentime ? opentime : "";
    //                     draft.closetime = closetime ? closetime : "";
    //                     draft.closedatearr = closedatearray ? closedatearray : [];
    //                     draft.closedates = closedates ? closedates : "";
    //                     draft.validdays = validdays ? validdays : "";
    //                     draft.sortorder = sortorder ? sortorder : 1;
    //                     draft.memberonly = memberonly ? memberonly : "";
    //                     draft.itemfilter = itemfilter ? itemfilter : "";
    //                     draft.status = status ? status : "Active";

    //                     draft.memberfilter = typeof memberfilter !== "undefined" &&
    //                         Object.keys(memberfilter).length !== 0 ?
    //                         objtoarr(memberfilter) :
    //                         defaultmemField;

    //                     draft.additionalFields = typeof additionalfields !== "undefined" &&
    //                         Object.keys(additionalfields).length !== 0
    //                         ? objtoarr(additionalfields) :
    //                         defaultaddField
    //                 })
    //             );
    //         }
    //     }
    // };

    componentWillMount = () => {

        const rec = this.props.promotargetgroup
        console.log('props:::', this.props)
        // const isEdit = this.props.location.state.isEdit;
        // const isView = this.props.location.state.isView;
        // const isForApproval = this.props.location.state.isForApproval;

        const isViewInEdit = this.props.isViewInEdit

        if (rec && rec.id) {
            const {
                id,
                promoproductcode,
                promocode,
                storeid,
                title,
                productcodes,
                promopurchasecode,
                productselection,
                status,
                sortorder,
                additionalfields
            } = rec;

            console.log('zzzzzz rec.id', rec.id)
            //this.props.dispatch(getStoreById(storeid));

            this.setState(
                produce(draft => {
                    draft.id = id ? id : "";
                    draft.isEdit = true;//isEdit;
                    draft.isViewInEdit = isViewInEdit
                    // draft.isView = isView;
                    // draft.isForApproval = isForApproval;
                    draft.title = title ? title : "";
                    draft.promocode = promocode ? promocode : "";
                    draft.storeid = storeid ? storeid : "";
                    draft.promoproductcode = promoproductcode ? promoproductcode : "";
                    draft.productcodes = productcodes ? (!Array.isArray(productcodes) ? productcodes.split(',') : productcodes) : [];
                    console.log('draft.productcodes::', productcodes, '::', draft.productcodes)
                    draft.promopurchasecode = promopurchasecode ? promopurchasecode : "";
                    draft.productselection = productselection ? productselection : "";
                    draft.sortorder = sortorder ? sortorder : 1;
                    draft.status = status ? status : "Active";

                })
            );
        }
    }

    componentDidUpdate = (prevProps) => {

        // console.log('AddPromoTargetGroup componentDidUpdate.......')
        const { promotionspurchasegroups } = this.props.queryData
        // console.log('promotionspurchasegroups::', promotionspurchasegroups)
        const { promotionspurchasegroups: prevPPG } = prevProps.queryData
        // console.log('prevPPG promotionspurchasegroups::', promotionspurchasegroups)
        if (promotionspurchasegroups !== prevPPG) {
            if (promotionspurchasegroups && promotionspurchasegroups.length > 0) {
                const options = promotionspurchasegroups.map((e) => ({ label: e.promopurchasecode, value: e.promopurchasecode }))
                this.setState({ promotionspurchasegroups: options })
                if (this.state.promopurchasecode != '') {
                    const _selectedPPG = promotionspurchasegroups.filter((e) => e.promopurchasecode === this.state.promopurchasecode)
                    if (_selectedPPG && _selectedPPG.length > 0) {
                        const selectedPPG = _selectedPPG.map((e) => ({ label: e.promopurchasecode, value: e.promopurchasecode }))
                        this.setState({ selectedPurchaseGroups: selectedPPG })
                    }
                }
            } else {
                this.setState({ promotionspurchasegroups: [], selectedPurchaseGroups: {} })
            }
        }
    }

    hideModal = e => {
        e.preventDefault();
    };

    renderAlertMessage = (isSuccess, isFailure) => {
        this.setState({
            formError: true,
            formErrorMsg: isSuccess && this._isEditMode() ? "Target Group updated successfully" : isSuccess ? "Target Group created successfully" : isFailure ? 'Failed' : ""
        }, () => {

            this.setState({
                formError: false,
                formErrorMsg: ''
            })

            if (isSuccess) {
                const { data } = this.props.crudData
                console.log('data:::', data)
                if (data.id) {
                    this.setState({ id: data.id }, () => {
                        this.props.hide(this.state)
                    })
                }

                this.props.crudData.isPromoTGroupSuccess = false
                this.props.crudData.isPromoTGroupFailure = false
            }
            // //todo not working
            // setTimeout(() => {
            //     this.setState({
            //         formError: false,
            //         formErrorMsg: ''
            //     })
            //   }, 2000);
        });
    }

    render() {
        const { data, isPromoTGroupSuccess, isPromoTGroupFailure } = this.props.crudData;
        return (
            <React.Fragment>
                {this.state.showProductInput && this._renderAddProductControl()}
                {/* {this.state.showProductCodeSelection && this._renderProductSelectionTree()} */}

                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderMainDefinition()}
                            {/* {this._renderMainButton()} */}
                        </form>
                    </div>
                </div>
                {this.renderAlertMessage(isPromoTGroupSuccess, isPromoTGroupFailure)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddPromoTargetGroup);
