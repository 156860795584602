import React, { PureComponent } from "react";

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selectedOption ? this.props.selectedOption : ""
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ selectedOption: nextProps.selectedOption });
  }
  dropDownChange = event => {
    this.setState({ selectedOption: event.target.value });

    this.props.callbackChangeFilter(event);
  };
  render() {
    const {
      filterOptions,
      cssClass,
      id,
      disabled,
      isForTable,
      autoFocus
    } = this.props;
    let colorArray = [];
    const { selectedOption } = this.state;
    let cssClassName = "form-control";
    if (cssClass) {
      cssClassName = cssClass;
    }
    if (isForTable) {
      colorArray = filterOptions.filter(option => {
        return option.id === selectedOption;
      });
      let css =  colorArray[0].css;
      if (css) {
        cssClassName = `${css} form-control`;
      }
    }

    return (
      <div className="filterClass form-filter">
        <select
          id={id ? id : "show"}
          className={cssClassName}
          disabled={disabled ? true : false}
          value={this.state.selectedOption}
          onChange={this.dropDownChange}
          autoFocus={autoFocus ? autoFocus : false}
        >
          {filterOptions.length !== 0 ? (
            filterOptions.map(option => {
              return (
                <option key={`${option.title}-${option.id}`} value={option.id}>
                  {option.name}
                </option>
              );
            })
          ) : (
            <option>No option</option>
          )}
        </select>
      </div>
    );
  }
}

export default Filter;
