import React from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import {
    deletePointRule,
    searchGeneral,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import Loading from '../../../components/Loading';
import { Link } from 'react-router-dom';
import { canDelete, canWrite, canView, permissions } from '../../../helpers/Util';
import PrimeTable from '../../../components/PrimeTable';
import { Button } from 'primereact/button';

class DisplayRabbitPointRule extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'STOREMGMT';
        this.group = 'DE_RABBIT_POINT_RULE';
        this.allPermissions = [];

        this.state = {
            pages: 10,
            currPage: 1,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            search_field: 'rulegroup',
            search_condi: 'like',
            search_value: 'RabbitCart',
        };
    }

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }
        return (
            <span>
                {canView(this.allPermissions) && (
                <Link
                    to={{
                        pathname: '/ui/rabbit-point-rule/addNew',
                        state: {
                            item: row,
                            isView: true,
                        },
                    }}
                >
                    <img
                        title="View"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    />
                </Link>
                )}
                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/rabbit-point-rule/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                    <img
                        title="Edit"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                    />
                </Link>
                )}
                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteCoupon(row)}
                    />
                )}
            </span>
        );
    };

    // API Calls
    createFetchUrl = () => {
        const {
            pages,
            currPage,
            language,
            search_field,
            search_condi,
            search_value,
        } = this.state;
        const requestBody = {
            body: {
                articletype: 'Point Rule',
                language: 'en',
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
                pagesize: pages,
                pageno: currPage,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                '/campaign/v1/point/rule/search',
                this.createFetchUrl(fetchType),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.setState({ ...newState }, () => {
                    this.callFetch('PAGECLICK');
                });

                break;
        }
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('ONLOAD')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    _renderAddNewBtn = () => {
        const { history } = this.props;
        return (
            <Button
                label="Add Rabbit Point Rule"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/rabbit-point-rule/addNew')}
            />
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deletePointRule(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    _deleteCoupon = (rowData) => {
        this.setState({ message: 'Point Rule  deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    render() {
        const {
            articleFlowData,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (!articleFlowData && !errormsg) {
            return <Loading />;
        }
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'rulecode', header: 'Rule Code' },
            { field: 'title', header: 'Title' },
            { field: 'rulegroup', header: 'Rule Group' },
            { field: 'validfrom', header: 'Valid From' },
            { field: 'validtill', header: 'Valid Till' },
            { field: 'status', header: 'Status' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            <h2 className="page-header">
                                Rabbit Point Rule
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                        <div className="col-lg-3 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <PrimeTable
                                    list={articleFlowData}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pages < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pages = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this point rule?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayRabbitPointRule);
