import React from "react";
import loadingIcon from "./Loading.gif";

const Loading = () => {
  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#ffffff",
        textAlign: "center"
      }}
      className="loading_wrapper"
    >
      <img style={{ margin: "15em 14em" }} src={loadingIcon} />
    </div>
  );
};
Loading.propTypes = {};

export default Loading;
