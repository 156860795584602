import React, { Component } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { renderTimeOptionsFilter } from "../helpers/Util";
import Filter from "./Filter";

export const daylist = [
    { title: "Sunday", value: "Sun" },
    { title: "Monday", value: "Mon" },
    { title: "Tuesday", value: "Tue" },
    { title: "Wednesday", value: "Wed" },
    { title: "Thursday", value: "Thu" },
    { title: "Friday", value: "Fri" },
    { title: "Saturday", value: "Sat" }
]

class DynamicTimePicker extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDay = (value, name, field, row) => {
      this.props.handleInputChange(value, field, row);
      this.setState({ [name]: value });
  };

  handleTimePicker = (time, name, field, row) => {
    let timeformat = moment(time).format("HH:mm");
        if(timeformat !== "Invalid date"){
            this.setState({
                [name]: timeformat
            },()=>{
                this.props.handleInputChange(timeformat, field, row);
            })
        }
        else {
            this.setState({
                [name]: ""
            },()=>{
                this.props.handleInputChange(timeformat, field, row);
            })
        }
  };

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;
    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  renderRowData = (column, row, col, index, rowId, rowleng) => {
    if (column.addRow) {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          {column.dataFieldId === "day" ? (
                  <Filter
                      cssClass="form-control"
                      filterOptions={renderTimeOptionsFilter(daylist)}
                      selectedOption={this.state["day_" + rowId] || this.props.rows.filter(r=>r.id===row.id)[0].day}
                      callbackChangeFilter={e =>
                        this.handleDay(
                        e.target.value,
                        "day_" + rowId,
                        column.dataFieldId,
                        row
                      )}
                      id="day"
                  />
          ) : column.dataFieldId === "activetimefrom" ? (
            <TimePicker
              name={"activetimefrom_" + rowId}
              onChange={e =>
                this.handleTimePicker(
                  e,
                  "activetimefrom_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={(moment(this.state["activetimefrom_" + rowId], 'HH:mm').isValid() ?
              moment(this.state["activetimefrom_" + rowId], 'HH:mm') : '')
               || (moment(this.props.rows.filter(r=>r.id===row.id)[0].activetimefrom, "HH:mm").isValid() ?
               moment(this.props.rows.filter(r=>r.id===row.id)[0].activetimefrom, "HH:mm") : ''
               )}
              showSecond={false}
            />
          ) : (
            <TimePicker
              name={"activetimetill_" + rowId}
              onChange={e =>
                this.handleTimePicker(
                  e,
                  "activetimetill_" + rowId,
                  column.dataFieldId,
                  row
                )
              }
              value={(moment(this.state["activetimetill_" + rowId], "HH:mm").isValid() ?
              moment(this.state["activetimetill_" + rowId], 'HH:mm') : '')
              || (moment(this.props.rows.filter(r=>r.id===row.id)[0].activetimetill, "HH:mm").isValid() ?
              moment(this.props.rows.filter(r=>r.id===row.id)[0].activetimetill, "HH:mm") : ''
              )}
              showSecond={false}
            />
          )}
        </td>
      );
    }
    if (col === "AD") {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          <img
            className="addBtn1"
            onClick={this.props.addRow}
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
          />

          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row)}
            className="addBtn2"
          />
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <React.Fragment>
        <div className="dynamicDateTimePicker">
          <table className="timetable">
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicTimePicker;
