import produce from 'immer';
import React from 'react'
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import { crudReset, jobTaskLogsSearchByFields, queryPropertyConfigByFields, resetAction, searchGeneral } from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleTable from '../../../components/SimpleTable';
import { formatDate, permissions } from '../../../helpers/Util';
import * as CONSTANTS from '../../../constants/constants'
import ViewJobTaskLog from './ViewJobTaskLog';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

class JobTaskLog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.module = "LOGS"
    this.group = "DE_LOGS_JOBTASKS"
    this.allPermissions = []

    this.requestBody = {} //current one
    this.currPage = 1
    this.pageSize = 100

    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,
      showSearchModal: false,
      query: {},
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      action: '',
      search_field: "status",
      search_condi: "like",
      search_value: "",
      date_from: new Date(),
      date_till: new Date(),
      fromtime: '00:00',
      tilltime: '23:59',
      tableCols: [
        {
          dataField: "tasktype",
          text: "Task Type",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "id",
          text: "Id",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
          dataField: "storeid",
          text: "Store Id",
          sort: true
        },
        {
          dataField: "createdon",
          text: "CreatedOn",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ],

      tasktypes: [{name: "Select", id: ''}],
      tasktype: "",
      title: "",
      id: "",
      storeid: "",
      memberid: "",
      status: "",
      pagesize: this.pageSize,

      showLogDetails: false,
      selectedLog: {}
    };
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        
          <img
            title="View"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
            onClick={() => this.viewLogDetails(row)}
          />
        
      </span>
    );
  };

  viewLogDetails = (row) => {
    this.setState({selectedLog: row, showLogDetails: true})
  }

  // API Calls
  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage,
      date_from,
      date_till,
      fromtime,
      tilltime
    } = this.state;

    if (fetchType === 'ONLOAD') {
      const requestBody = {
        body: {
          pagesize: pageSize,
          pageno: currPage,
          date_from: formatDate(date_from),
          date_till: formatDate(date_till),
          date_from_time: fromtime,
          date_till_time: tilltime
        }
      };
      console.log("...requestBody for ONLOAD::", requestBody)
      return requestBody;
    }

    const requestBody = {
      body: { ...this.requestBody, pagesize: pageSize, pageno: currPage }
    };
    console.log("...requestBoyd::", requestBody)
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "system/v1/jobtask/search/fields",
        this.createFetchUrl(fetchType),
        CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
        CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE,
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    // var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        this.callFetch()
        break;
    }
  };

  componentWillMount() {
    this.callQueryApiForFetch("ONLOAD")

    this.props.dispatch(queryPropertyConfigByFields(
      {
        body: {
          propgroup: "JOBTASKTYPES",
        }
      },
      CONSTANTS.SEARCH_PROP_CONFIG_FIELDS,
      CONSTANTS.SEARCH_PROP_CONFIG_SUCCESS,
      CONSTANTS.SEARCH_PROP_CONFIG_FAILURE
    ))

    this.allPermissions = permissions(this.module, this.group)
  }

  componentWillReceiveProps = () => {
    const { propConfigs } = this.props.queryData;
    
    if (propConfigs && propConfigs.length > 0 && propConfigs[0].additionalfields ) {
      const list = propConfigs[0].additionalfields
      var configs = [
        {name: "Select", id: ''}
      ]

      const returnedConfigs = Object.entries(list).map(list => {
        return { id: list[0], name: list[1] };
      });
    
      this.setState( { tasktypes: configs.concat(returnedConfigs) })
    }
  }
  
  handleChange(time, name) {
    let timeformat = moment(time).format("HH:mm");
    this.setState({[name]: timeformat}, () => {
        //this.updateStateValues();
    });
}

  hideAlertModal = e => {
    this.setState({ message: "", action: '' });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };

  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "", action: '' });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };

  getTableData = (records, headers) => {
    const tablerow = records.map(e => [e.id, e.title, e.promocode, e.promogroup, e.storeid, e.promotypevalue, e.categorytypevalue, e.validfrom, e.validtill, e.status]);
    const colheader = headers.filter(e => e.text !== "Actions").map(e => ({ label: e.text, key: e.dataField }))
    tablerow.unshift(colheader.map(e => e.label));
    return tablerow;
  }

  _hideLogDetails = () => {
    this.props.queryData.jobtasklog = {}
    this.setState({showLogDetails: false})
  }

  render() {
    const {
      errormsg: queryError,
      servererror: serverError,
      jobtasklogs
    } = this.props.queryData;

    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }

    // if (!articleFlowData && !errormsg) {
    //   return <Loading />;
    // }
    // if (this.props.queryData.apiPending) {
    //   return <Loading />;
    // }

    if (this.state.showLogDetails) {
      return (
        <React.Fragment>
          <ViewJobTaskLog
          rec={this.state.selectedLog}
          logid={this.state.selectedLog.id}
          hideLogDetails={this._hideLogDetails}
          />
        </React.Fragment>
      )
    }
    else {
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Job Task Logs</h2>
            </div>
          </div>

          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  
                  <div className="form-group col-lg-2 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Date From
                    </label>
                    <DatePicker
                      onChange={value => {
                        this.setState({ date_from: value });
                      }}
                      value={this.state.date_from}
                    />
                  </div>
                  <div className="form-group col-lg-1 col-sm-12">
                      <label className="col-form-label alignLeft">
                        From Time
                      </label>
                      <TimePicker value={moment(this.state.fromtime, "HH:mm").isValid() ? moment(this.state.fromtime, "HH:mm"): ''} name="fromtime" onChange={(time) => this.handleChange(time, "fromtime")} showSecond={false} allowEmpty={false}/>
                    </div>

                  <div className="form-group col-lg-2 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Date Till
                    </label>
                    <DatePicker
                    minDate={this.state.date_from}
                      onChange={value => {
                        this.setState({ date_till: value });
                      }}
                      value={this.state.date_till}
                    />
                  </div>
                  <div className="form-group col-lg-1 col-sm-12">
                      <label className="col-form-label alignLeft">
                        Till Time
                      </label>
                      <TimePicker value={moment(this.state.tilltime, "HH:mm").isValid() ? moment(this.state.tilltime, "HH:mm"): ''} name="fromtime" onChange={(time) => this.handleChange(time, "tilltime")} showSecond={false} allowEmpty={false}/>
                    </div>
                  <div className="form-group col-lg-2 col-sm-12">
                    <label className="col-form-label alignLeft">
                    Id
                    </label>
                    <input
                            type="text"
                            className="form-control"
                            id="id"
                            value={this.state.id}
                            onChange={event =>
                                this.setState({ id: event.target.value })
                            }
                        />
                  </div>

                  <div className="form-group col-lg-2 col-sm-12">
                    <label htmlFor="tasktype" className="col-form-label alignLeft">
                      Task Type
                    </label>
                    <div className="row">
                      <select
                        name="tasktype"
                        className="simpleSearchSelect2"
                        value={this.state.tasktype}
                        onChange={event =>
                          this.setState({ tasktype: event.target.value })
                        }
                      >
                        {this.state.tasktypes.length !== 0 &&
                          this.state.tasktypes.map((option, index) => {
                            const { name, id } = option;
                            return (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="form-group col-lg-2 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Store Id
                    </label>
                    <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={event =>
                                this.setState({ storeid: event.target.value })
                            }
                        />
                  </div>

                  <div className="col-lg-2"> </div>

                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Status
                    </label>
                    <input
                            type="text"
                            className="form-control"
                            id="responsestatus"
                            value={this.state.responsestatus}
                            onChange={event =>
                                this.setState({ responsestatus: event.target.value })
                            }
                        />
                  </div>


                  <div className="col-lg-2 col-sm-12">
                    <label className="col-form-label alignLeft">
                      Member Id
                    </label>
                    <input
                            type="text"
                            className="form-control"
                            id="memberid"
                            value={this.state.memberid}
                            onChange={event =>
                                this.setState({ memberid: event.target.value })
                            }
                        />
                  </div>


                  

                  <div className="col-lg-4 col-sm-12" />



                  <div className="col-lg-4 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
                    <button
                      type="submit"
                      onClick={(e) => this._searchJobTaskLogs(e)}
                      className="btn btn-themes btn-rounded"
                    >
                      Search
                    </button>

                    <button
                      type="submit"
                      onClick={this._clearFiltersAndResults}
                      className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                      Clear
                    </button>
                  </div>

                </div>

                <div className="title_devide" />
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={jobtasklogs}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    }, () => {
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }), () => {
                      this.callQueryApiForFetch("PAGECLICK")
                    })
                  }}
                  // withoutSearchBar={true}
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                  hideSizePerPage={true}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />

          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    )};
  }

  _searchJobTaskLogs = (e) => {
    if (e) { e.preventDefault() }
    console.log('_search Logs')
    const {
      date_from,
      date_till,
      fromtime,
      tilltime,
      tasktype,
      title,
      id,
      memberid,
      storeid,
      status,
    } = this.state

    this.requestBody = {}
    const queryParams = []

    if (date_from === '' && date_till === '') {
      //do nothing
    } else {
      if (date_from !== '' && date_till !== '') {
        queryParams.push(`date_from=${formatDate(date_from)}`)
        queryParams.push(`date_till=${formatDate(date_till)}`)
      } else if (date_from !== '') {
        queryParams.push(`date_from=${formatDate(date_from)}`)
        queryParams.push(`date_till=${formatDate(date_from)}`)
      } else if (date_till !== '') {
        queryParams.push(`date_from=${formatDate(date_till)}`)
        queryParams.push(`date_till=${this.formatDate(date_till)}`)
      }
      this.requestBody["date_from"] = formatDate(date_from)
      this.requestBody["date_till"] = formatDate(date_till)
      this.requestBody["date_till_time"] = fromtime
      this.requestBody["date_from_time"] = tilltime
      queryParams.push(`date_till_time=${tilltime}`)
      queryParams.push(`date_from_time=${fromtime}`)
    }


    if (title !== '') {
      queryParams.push(`title=${title}`)
      this.requestBody["title"] = title
    }
    if (id !== '') {
      queryParams.push(`id=${id}`)
      this.requestBody["id"] = id
    }
    if (memberid !== '') {
      queryParams.push(`memberid=${memberid}`)
      this.requestBody["memberid"] = memberid
    }
    if (tasktype !== '') {
      queryParams.push(`tasktype=${tasktype}`)
      this.requestBody["tasktype"] = tasktype
    }
    if (storeid !== '') {
      queryParams.push(`storeid=${storeid}`)
      this.requestBody["storeid"] = storeid
    }
    if (status !== '') {
      queryParams.push(`status=${status}`)
      this.requestBody["status"] = status
    }

    queryParams.push(`pagesize=${this.pageSize}`)
    queryParams.push(`pageno=${this.currPage}`)

    console.log('requstBody::', this.requestBody)
    //query
    this.props.dispatch(jobTaskLogsSearchByFields(queryParams.join('&')))
    // if (e) { e.preventDefault() }
  }

  _clearFiltersAndResults = (e) => {
    if (e) { e.preventDefault() }
    //clear all the filters
    this.setState({
      tasktype: "",
      id: '',
      memberid: "",
      storeid: "",
      status: '',
      title: "",
      date_from: new Date(),
      date_till: new Date(),
      fromtime: '00:00',
      tilltime: '23:59',
    })
    //clear the search results
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(JobTaskLog);