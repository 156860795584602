import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { InputText } from 'primereact/inputtext';
import DynamicInput from '../../components/DynamicInput';
import { objtoarr } from '../../helpers/Util';

const ViewCartSyncReport = (props) => {
    const { record, hideLogDetails } = props;
    const additionalfields = typeof record.additionalfields == 'object' && record.additionalfields !== null &&
                    Object.keys(record.additionalfields).length !== 0        
                    ? objtoarr(record.additionalfields) : 
                    [];

    //const additionalfields = []
    const initialValues = {
        orderid: record.orderid,
        posmasterid: record.posmasterid,
        synctype: record.synctype,
        storeid: record.storeid,
        terminalid: record.terminalid,
        storesessionid: record.storesessionid,
        signonid: record.signonid,
        trxno: record.trxno,
        trxtype: record.trxtype,
        trxdate: record.trxdate,
        trxamount: record.trxamount,
        trxstatus: record.trxstatus,
        savetodisk: record.savetodisk,
        savetodiskpath: record.savetodiskpath,
        topicmsgid: record.topicmsgid,
        topicstatus: record.topicstatus,
        topicconsumers: record.topicconsumers,
        brokerfileid: record.brokerfileid,
        brokerstatus: record.brokerstatus,
        entsync: record.entsync,
        entsynced: record.entsynced,
        entsyncedstatus: record.entsyncedstatus,
        entsalescartstatus: record.entsalescartstatus,
        entposmasterstatus: record.entposmasterstatus,
        entbrokerfilestatus: record.entbrokerfilestatus,
        cronjobstatus: record.cronjobstatus,
        status: record.status,
        createdon: record.createdon,
        lastupdated: record.lastupdated
    };

    const onSubmit = () => {};

    const handleDynamicInputChange = () => {

    }

    const renderMainButton = () => {
        return (
            <>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        hideLogDetails();
                    }}
                >
                    Cancel
                </button>
            </>
        );
    };
    return (
        <React.Fragment>
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">View Cart Sync Report</h2>
                    </div>
                </div>

                <div className="row form-container">
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        <Form className="view-form row">
                            <div className="col col-lg-9">
                                <div className="frmDiv">
                                    <div className="row p-fluid formgrid grid w-100">
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="orderid">
                                                Order ID
                                            </label>
                                            <Field
                                                name="orderid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="posmasterid">
                                                POS Master ID
                                            </label>
                                            <Field
                                                name="posmasterid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="synctype">
                                                Sync Type
                                            </label>
                                            <Field
                                                name="synctype"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="storeid">
                                                Store Id
                                            </label>
                                            <Field
                                                name="storeid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="storeid">
                                                Terminal Id
                                            </label>
                                            <Field
                                                name="terminalid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="storeid">
                                                Store Session Id
                                            </label>
                                            <Field
                                                name="storesessionid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-4 md:col-4">
                                            <label htmlFor="signonid">
                                                Sign On Id
                                            </label>
                                            <Field
                                                name="signonid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="frmDiv"
                                    style={{ marginTop: '20px' }}
                                >
                                    <div className="row p-fluid formgrid grid w-100">
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="trxno">
                                                Trx No
                                            </label>
                                            <Field
                                                name="trxno"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="trxtype">
                                                Trx Type
                                            </label>
                                            <Field
                                                name="trxtype"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="trxdate">
                                                Trx Date
                                            </label>
                                            <Field
                                                name="trxdate"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="trxamount">
                                                Trx Amount
                                            </label>
                                            <Field
                                                name="trxamount"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="trxstatus">
                                                Trx Status
                                            </label>
                                            <Field
                                                name="trxstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="frmDiv"
                                    style={{ marginTop: '20px' }}
                                >
                                    <div className="row p-fluid formgrid grid w-100">
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="savetodisk">
                                                Save To Disk
                                            </label>
                                            <Field
                                                name="savetodisk"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="savetodiskpath">
                                                Save To Disk Path
                                            </label>
                                            <Field
                                                name="savetodiskpath"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="topicmsgid">
                                                Topic Msg Id
                                            </label>
                                            <Field
                                                name="topicmsgid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="topicstatus">
                                                Topic Status
                                            </label>
                                            <Field
                                                name="topicstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-6 md:col-6">
                                            <label htmlFor="topicconsumers">
                                                Topic Consumers
                                            </label>
                                            <Field
                                                name="topicconsumers"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="brokerfileid">
                                                Broker File Id
                                            </label>
                                            <Field
                                                name="brokerfileid"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="brokerstatus">
                                                Broker Status
                                            </label>
                                            <Field
                                                name="brokerstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="frmDiv"
                                    style={{ marginTop: '20px' }}
                                >
                                    <div className="row p-fluid formgrid grid w-100">
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="entsync">
                                                Ent Sync
                                            </label>
                                            <Field
                                                name="entsync"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="savetodiskpath">
                                                Ent Synced
                                            </label>
                                            <Field
                                                name="savetodiskpath"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="entsyncedstatus">
                                                Ent Synced Status
                                            </label>
                                            <Field
                                                name="entsyncedstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="entsalescartstatus">
                                                Ent Sales Cart Status
                                            </label>
                                            <Field
                                                name="entsalescartstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="entposmasterstatus">
                                                Ent Pos Master Status
                                            </label>
                                            <Field
                                                name="entposmasterstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-3 md:col-3">
                                            <label htmlFor="entbrokerfilestatus">
                                                Ent Broker File Status
                                            </label>
                                            <Field
                                                name="entbrokerfilestatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="frmDiv"
                                    style={{ marginTop: '20px' }}
                                >
                                    <div className="p-fluid formgrid w-100">
                                        <h5 style={{ marginLeft: '15px'}}>Additional Fields</h5>
                                        <div className="col col-lg-8">
                                            <DynamicInput 
                                                fieldArr={additionalfields} 
                                                handleChange={handleDynamicInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-3">
                                <div className="frmDiv">
                                    <div className="row p-fluid formgrid grid w-100">
                                        <div className="field col-12 md:col-12">
                                            <label htmlFor="status">
                                                Status
                                            </label>
                                            <Field
                                                name="status"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-12 md:col-12">
                                            <label htmlFor="cronjobstatus">
                                                Cron Job Status
                                            </label>
                                            <Field
                                                name="cronjobstatus"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-12 md:col-12">
                                            <label htmlFor="createdon">
                                                Created On
                                            </label>
                                            <Field
                                                name="createdon"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                        <div className="field col-12 md:col-12">
                                            <label htmlFor="lastupdated">
                                                Last Updated
                                            </label>
                                            <Field
                                                name="lastupdated"
                                                as={InputText}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
            <div className="row btn-container form-button">
                <div className="col-sm-12 col-lg-12">{renderMainButton()}</div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ViewCartSyncReport);
