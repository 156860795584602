import React from 'react';
import { produce } from 'immer';
import DynamicTable from '../../../../components/DynamicTable';
import { objtoarr } from '../../../../helpers/Util';

class AdditionalFieldSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalFields: [{ field: '', value: '', id: 0 }],
        };
    }

    componentDidMount = () => {
        if (this.props.additionalFields) {
            this.setState(this.getDraft());
        }
    };

    getDraft = () => {
        const { additionalFields } = this.props;
        let defaultaddField = [{ field: '', value: '', id: 0 }];
        return produce((draft) => {
            draft.additionalFields =
                typeof additionalFields !== 'undefined' &&
                Object.keys(additionalFields).length !== 0
                    ? objtoarr(additionalFields)
                    : defaultaddField;
        });
    };

    componentDidUpdate(prevProps) {
        const { isView, isEdit } = this.props
        // if ((this.props.additionalFields !== prevProps.additionalFields) && (!isEdit && !isView)) {
        //     this.setState(this.getDraft());
        // }
        if (this.props.additionalFields !== prevProps.additionalFields && !isEdit && !isView) {
            this.setState(
              isEdit || isView
                ? null
                : this.getDraft()
            );
          }
          
    }

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id
            );

            this.setState({ additionalFields: updateValues });
            this.props.updateAdditionalFields(this.state.additionalFields)
        }
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            field: '',
            value: '',
            id: lastele.id + 1,
        };
        this.setState({
            additionalFields: [...this.state.additionalFields, item],
        });
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {
                this.props.updateAdditionalFields(this.state.additionalFields)
            }
        );
    };

    render() {
        return (
            <div className="row">
                <div className="form-group override col-lg-12 col-sm-12">
                    <DynamicTable
                        columns={[
                            {
                                dataFieldId: 'field',
                                label: 'Key',
                                addRow: true,
                            },
                            {
                                dataFieldId: 'value',
                                label: 'Value',
                                addRow: true,
                            },
                            { dataFieldId: 'AD', label: '' },
                        ]}
                        rows={this.state.additionalFields}
                        deleteRow={this.deleteRow}
                        addRow={this.handleAddRow}
                        handleInputChange={this.handleInputChange}
                    />
                </div>
            </div>
        );
    }
}

export default AdditionalFieldSetting;
