import React from "react";
import { Chart } from "chart.js";
import currencyFormatter from "currency-formatter";
class MultiLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: this.props.currency || ""
    }
    this.canvasRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
     if(prevProps.currency !== prevState.currency){
      this.setState({
        currency: prevProps.currency
      })
     } 
     this.myChart.data.labels = this.props.labels;
     this.myChart.data.datasets = this.props.data;
     this.myChart.update();
  }

  componentDidMount() {
    let currentThis = this;
    Chart.plugins.register({
      beforeDraw: function (c) {
        var ctx = c.chart.ctx;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      }
    })
    var c = document.getElementById(this.props.id);
    var ctx = c.getContext("2d");
    if (typeof this.myChart != "undefined") {
      this.myChart.destroy();
    }
    
    this.myChart = new Chart(this.canvasRef.current, {
      type: "line",
      data: {
        labels: this.props.labels,
        datasets: this.props.data
      },
      options: {
        responsive: true,
        legend: { display: true, position: "bottom" },
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title: function (tooltipItems, data) {
              return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
            }                     
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.props.xaxes
            }
          }],
          yAxes: [
            {
              id: "y-axis-2",
              position: 'right',
              type: 'linear',
              ticks: {
                beginAtZero: true,
                userCallback: function(value, index, values) {
                  return currencyFormatter.format(value, { code: currentThis.state.currency});
                }
              },
              scaleLabel: {
                display: true,
                labelString: currentThis.props.data[1].label
              }
            },
            {
              id: "y-axis-1",
              position: 'left',
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: this.props.data[0].label
              }
            }
          ],
          // yAxes: [ 
          //   {
          //     display: true,
          //     scaleLabel: {
          //       display: true,
          //       labelString: this.props.yaxes
          //     },
          //     ticks: {
          //       min: 10
          //     }
          //   }
          // ]
        }
      }
    });
  }

  render() {
     return (
      <div className="chartCanvas">
        <canvas ref={this.canvasRef} id={this.props.id} />
      </div>
    );
  }
}
export default MultiLineChart;