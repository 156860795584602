import React from 'react';

const DeleteImage = ({handleClick}) => {
    return (
        <img
            title="Edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            alt="delete"
            onClick={handleClick}
        />
    );
};

export default DeleteImage;
