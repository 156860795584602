import React from 'react';
import { connect } from 'react-redux';

const ViewEipMessage = (props) => {
    const { record, hideLogDetails } = props;
    const renderMainButton = () => {
        return (
            <>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        hideLogDetails();
                    }}
                >
                    Cancel
                </button>
            </>
        );
    };
    return (
        <>
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">Eip Message</h2>
                    </div>
                </div>

                <div className="row form-container">
                    <div className="col col-lg-9">
                        <form className="frmDiv">
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">Id</label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="id"
                                        value={record.id}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Msg Id
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="msgid"
                                        value={record.msgid}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Consumer Id
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="consumerid"
                                        value={record.consumerid}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Msg Status
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="msgstatus"
                                        value={record.msgstatus}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Msg Send
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="msgsend"
                                        value={record.msgsend}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Topic
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="topic"
                                        value={record.topic}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Log Text
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <textarea
                                        className="form-control apilogLeftRightPadding"
                                        id="logtext"
                                        value={record.logtext}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Created On
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control apilogLeftRightPadding"
                                        id="createdon"
                                        value={record.createdon}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 alignRight">
                                    <label className="col-form-label">
                                        Msg
                                    </label>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <textarea
                                        className="form-control apilogLeftRightPadding"
                                        id="msg"
                                        value={record.msg}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row btn-container form-button">
                <div className="col-sm-12 col-lg-12">{renderMainButton()}</div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ViewEipMessage);
