import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import LinkButton from "../../../components/LinkButton";
import { DataTable } from "primereact/datatable";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { connect } from "react-redux";
import moment from "moment";
import {
    deleteSalesPromo,
    searchGeneral,
    crudReset,
    promotionSearchByFields,
    updateSalesPromo,
    storeSearchByFields,
} from "../../../actions/actions";
import * as CONSTANTS from "../../../constants/constants";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import SingleStoreSearch from "../../system/Users/SingleStoreSearch";
import ConfirmAlert from "../../../components/ConfirmAlert";
import {
    canApprove,
    canDelete,
    canPublish,
    canUnPublish,
    canView,
    canWrite,
    permissions,
} from "../../../helpers/Util";
import { OverlayPanel } from "primereact/overlaypanel";
import { RadioButton } from "primereact/radiobutton";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";

const promoGroupOptions = [
    { name: "By Percentage", id: "Percent" },
    { name: "By Amount", id: "Value" },
    { name: "Buy X Get Y", id: "BuyGet" },
];

const promoTypeOptions = [
    { name: "Item", id: "Item" },
    { name: "Cart", id: "Cart" },
    { name: "RTC", id: "Rtc" },
    { name: "Coupon", id: "Coupon" },
    { name: "Cart Coupon", id: "CartCoupon" },
];

const PromotionList = (props) => {
    const { queryData } = props;
    const { promotions } = queryData;
    const { isSuccess, errormsg: crudError } = props.crudData;

    const op = useRef(null);

    const [companyid, setCompanyId] = useState(
        localStorage.getItem("company_id")
    );
    const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
    const [storelabel, setStoreLabel] = useState(
        localStorage.getItem("storelabel")
    );

    const [promoList, setPromoList] = useState([]);

    const [openDeleteprompt, setOpenDeleteprompt] = useState(false);
    const [deleteRecId, setDeleteRecId] = useState("");
    const [message, setMessage] = useState("");
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(10);

    const [filterQuery, setFilterQuery] = useState()
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const [pageSize, setPageSize] = useState(20);
    const [currPage, setCurrPage] = useState(1);

    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'promocode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'validfrom': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const module = "SALESPRM";
    const group = "DE_SAL_SALES_PROMO";
    const allPermissions = permissions(module, group);

    useEffect(() => {
        if (promotions.length > 0) {
            setPromoList(promotions);
        }
    }, [promotions]);

    useEffect(() => {
        props.dispatch(
            storeSearchByFields(
                `companyid=${companyid}&status=Active`,
                CONSTANTS.STORESEARCH_STORE_SUCCESS,
                CONSTANTS.STORESEARCH_STORE_FAILURE
            )
        );
    }, [companyid]);

    useEffect(() => {
        if (storeid && storeid !== "" && storelabel && storelabel !== "") {
            callQueryApiForFetch("ONLOAD");
        }
    }, [storeid, storelabel]);

    const callQueryApiForFetch = (triggerPoint) => {
        switch (triggerPoint) {
            case "ONLOAD":
                callFetch("ONLOAD");
                break;
            case "PAGECLICK":
                callFetch();
                break;
        }
    };

    const callFetch = (fetchType) => {
        props.dispatch(
            searchGeneral(
                "/pos/v1/promo/search/fields",
                createFetchUrl(fetchType),
                CONSTANTS.SEARCH_PROMOTIONS_SUCCESS,
                CONSTANTS.SEARCH_PROMOTIONS_FAILURE
            )
        );
    };

    const createFetchUrl = (fetchType) => {
        let requestBody = {
            body: {
                storeid: storeid,
                pagesize: pageSize,
                pageno: currPage,
            },
        };

        if (fetchType === "ONLOAD") {
            requestBody["storeid"] = storeid;
            return requestBody;
        }

        requestBody = {
            body: { ...requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    const renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h2 className="page-header">
                        Sales Promotion
                        <img
                            title="Refresh"
                            onClick={handleRefresh}
                            className="ml-2"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic-refresh.png`}
                        />
                    </h2>
                </div>
                <div className="col-6 ml-auto ph-16">
                    <div
                        className="flex"
                        style={{
                            marginTop: "20px",
                            justifyContent: "flex-end",
                        }}
                    >
                        {renderAddNewBtn()}
                    </div>
                </div>
            </div>
        );
    };

    const handleRefresh = () => {
        callQueryApiForFetch("ONLOAD");
    };

    const singleStoreSearch = () => {
        return (
            <SingleStoreSearch
                value={storelabel}
                onSelectStore={_onSelectStore}
            />
        );
    };

    const _onSelectStore = (selectedStores) => {
        _setStore(selectedStores.value, selectedStores.label);
    };

    const _setStore = (p1, p2) => {
        setStoreId(p1);
        setStoreLabel(p2);
        localStorage.setItem("storeid", p1);
        localStorage.setItem("storelabel", p2);
    };

    const renderAddNewBtn = () => {
        if (!canWrite(allPermissions)) {
            return;
        }

        return (
            <LinkButton
                to="/ui/sales-promotions/addNew"
                className="page-header-btn icon_btn display-inline "
                onClick={(event) => event.preventDefault()}
                disabled={!storeid || !storelabel}
            >
                <img
                    title="edit"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                />
                {`Add Sales Promo`}
            </LinkButton>
        );
    };

    const getCreatePayload = (row, status) => {
        const {
            id,
            promocode,
            storeid,
            promogroup,
            title,
            promotype,
            promotypevalue,
            discounton,
            discountvalue,
            discountcapamt,
            discountbatch,
            discountbatchvalue,
            customertype,
            validfrom,
            validtill,
            activedays,
            closedates,
            pricesegments,
            priority,
            sortorder,
        } = row;

        let body = {
            id,
            promocode,
            storeid,
            promogroup,
            title,
            promotype,
            promotypevalue,
            discounton,
            discountvalue,
            discountcapamt,
            discountbatch,
            discountbatchvalue,
            customertype,
            status: status,
            validfrom,
            validtill,
            activedays,
            closedates,
            pricesegments,
            priority,
            sortorder,
        };

        const payload = {
            body: body,
        };

        return payload;
    };

    const _unPublishPromo = (rowData) => {
        setMessage("Promotion UnPublished");
        props.dispatch(
            updateSalesPromo(getCreatePayload(rowData, "InActive"), rowData.id)
        );
    };

    const actionTemplate = (row) => {
        if (row.status === "Deleted") {
            return;
        }
        return (
            <div className="flex">
                {canView(allPermissions) && (
                    <Link
                        to={{
                            pathname: "/ui/sales-promotions-new/addNew",
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                                isViewInEdit: false,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(allPermissions) && row.status !== "Active" && (
                    <Link
                        to={{
                            pathname: "/ui/sales-promotions-new/addNew",
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}

                {canApprove(allPermissions) &&
                    row.status === "PendingApproval" && (
                        <Link
                            to={{
                                pathname: "/ui/sales-promotions/addNew",
                                state: {
                                    item: row,
                                    isEdit: true,
                                    isView: false,
                                    isViewInEdit: true,
                                    isForApproval: true,
                                },
                            }}
                        >
                            <img
                                title="Approve"
                                className="tableImage"
                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_approve.svg`}
                            />
                        </Link>
                    )}

                {canPublish(allPermissions) && row.status === "Approved" && (
                    <img
                        title="Publish"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_published.svg`}
                        onClick={() => _publishPromo(row)}
                    />
                )}

                {canUnPublish(allPermissions) && row.status === "Active" && (
                    <img
                        title="UnPublish"
                        className="tableImage"
                        alt="unpublish"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_published.svg`}
                        onClick={() => _unPublishPromo(row)}
                    />
                )}

                {row.status === "InActive" && (
                    <img
                        title="InActive"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_inactive.svg`}
                    />
                )}

                {canDelete(allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => _deletePromo(row)}
                    />
                )}
            </div>
        );
    };

    const _publishPromo = (rowData) => {
        setMessage("Promotion Published");
        props.dispatch(
            updateSalesPromo(getCreatePayload(rowData, "Active"), rowData.id)
        );
    };

    const _deletePromo = (rowData) => {
        setOpenDeleteprompt(true);
        setMessage("Promotion deleted");
        setDeleteRecId(rowData.id);
    };

    const shdDeleteRec = () => {
        setOpenDeleteprompt(false);
        props.dispatch(deleteSalesPromo(deleteRecId));
    };

    const handlePageChange = (e) => {
        e.preventDefault();
        setPageSize(e.target.value);
        props.dispatch(
            searchGeneral(
                "/pos/v1/promo/search/fields",
                {
                    body: {
                        storeid: storeid,
                        pagesize: e.target.value,
                        pageno: currPage,
                    },
                },
                CONSTANTS.SEARCH_PROMOTIONS_SUCCESS,
                CONSTANTS.SEARCH_PROMOTIONS_FAILURE
            )
        );
    };

    const handleNextPage = () => {
        setCurrPage(currPage + 1);
        props.dispatch(
            searchGeneral(
                "/pos/v1/promo/search/fields",
                {
                    body: {
                        storeid: storeid,
                        pagesize: pageSize,
                        pageno: currPage + 1,
                    },
                },
                CONSTANTS.SEARCH_PROMOTIONS_SUCCESS,
                CONSTANTS.SEARCH_PROMOTIONS_FAILURE
            )
        );
    };

    const handlePrevPage = () => {
        setCurrPage(currPage - 1);
        props.dispatch(
            searchGeneral(
                "/pos/v1/promo/search/fields",
                {
                    body: {
                        storeid: storeid,
                        pagesize: pageSize,
                        pageno: currPage - 1,
                    },
                },
                CONSTANTS.SEARCH_PROMOTIONS_SUCCESS,
                CONSTANTS.SEARCH_PROMOTIONS_FAILURE
            )
        );
    };

    const renderPaginationTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span
                        className="mx-1"
                        style={{
                            color: "var(--text-color)",
                            userSelect: "none",
                        }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={pageSize}
                        options={dropdownOptions}
                        onChange={(e) => handlePageChange(e)}
                    />
                </React.Fragment>
            );
        },
        CurrentPageReport: () => {
            return (
                <div>
                    <button
                        type="button"
                        className={classNames({
                            "p-paginator-prev p-paginator-element p-link": true,
                            "p-disabled": currPage == 1,
                        })}
                        disabled={currPage == 1}
                        aria-label="Previous Page"
                        onClick={handlePrevPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-left"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                    <button
                        type="button"
                        className={classNames({
                            "p-paginator-prev p-paginator-element p-link": true,
                            "p-disabled": promotions.length < pageSize,
                        })}
                        aria-label="Next Page"
                        onClick={handleNextPage}
                    >
                        <span className="p-paginator-icon pi pi-angle-right"></span>
                        <span role="presentation" className="p-ink"></span>
                    </button>
                </div>
            );
        },
    };

    const onCustomPage2 = (event) => {
        setFirst2(event.first);
        setRows2(event.rows);
    };

    const formik = useFormik({
        initialValues: {
            filterStatus: "",
            filterPromoGroup: "",
            filterPromoType: "",
            validfrom: "",
            validtill: "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                storeid,
                status: values.filterStatus ? values.filterStatus : "",
                promogroup: values.filterPromoGroup
                    ? values.filterPromoGroup.id
                    : "",
                promotype: values.filterPromoType
                    ? values.filterPromoType.id
                    : "",
                date_from: values.validfrom
                    ? moment(values.validfrom).format("DD-MM-YYYY")
                    : "",
                date_till: values.validtill
                    ? moment(values.validtill).format("DD-MM-YYYY")
                    : "",
                // pagesize: pageSize,
                // pageno: currPage,
            };
            op.current.hide()
            let queryParams = data;
            Object.keys(queryParams).forEach((key) => {
                if (queryParams[key] === "") {
                    delete queryParams[key];
                }
            });
            setFilterQuery(queryParams)
            const queryStr = Object.keys(queryParams)
                .map((key) => {
                    return `${key}=${encodeURIComponent(queryParams[key])}`;
                })
                .join("&");
            props.dispatch(promotionSearchByFields(queryStr));
        },
    });

    const handleClearFilter = () => {
        formik.resetForm()
        op.current.hide()
        handleRefresh()
    }

    const renderFilterForm = () => {
        return (
            <form onSubmit={formik.handleSubmit}>
                <div className="flex justify-content-between align-items-center">
                    <label htmlFor="status" className="fw-500">
                        Status
                    </label>
                    <div className="field-radiobutton mb-0">
                        <RadioButton
                            inputId="status1"
                            name="filterStatus"
                            value="Active"
                            onChange={formik.handleChange}
                            checked={formik.values.filterStatus === "Active"}
                        />
                        <label htmlFor="city1">Active</label>
                    </div>
                    <div className="field-radiobutton mb-0">
                        <RadioButton
                            inputId="status2"
                            name="filterStatus"
                            value="PendingApproval"
                            onChange={formik.handleChange}
                            checked={
                                formik.values.filterStatus === "PendingApproval"
                            }
                        />
                        <label htmlFor="status2">Pending Approval</label>
                    </div>
                    <div className="field-radiobutton mb-0">
                        <RadioButton
                            inputId="status3"
                            name="filterStatus"
                            value="Inactive"
                            onChange={formik.handleChange}
                            checked={formik.values.filterStatus === "Inactive"}
                        />
                        <label htmlFor="status3">Inactive</label>
                    </div>
                </div>
                <Divider />
                <div className="row">
                    <div className="p-fluid formgrid grid w-100">
                        <div className="field form-group col-lg-6 col-sm-12">
                            <label htmlFor="promocode">Promo Group</label>
                            <Dropdown
                                id="promogroup"
                                name="filterPromoGroup"
                                value={formik.values.filterPromoGroup}
                                onChange={formik.handleChange}
                                options={promoGroupOptions}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                        <div className="field form-group col-lg-6 col-sm-12">
                            <label htmlFor="promotype">Promo Type</label>
                            <Dropdown
                                id="filterPromoType"
                                name="filterPromoType"
                                value={formik.values.filterPromoType}
                                onChange={formik.handleChange}
                                options={promoTypeOptions}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                    </div>
                </div>

                <div className="p-fluid formgrid grid">
                    <div className="field col-6 md:col-2">
                        <label htmlFor="validfrom">Start Date</label>
                        <Calendar
                            id="validfrom"
                            value={formik.values.validfrom}
                            onChange={formik.handleChange}
                            dateFormat="mm-dd-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-6 md:col-2">
                        <label htmlFor="validtill">End Date</label>
                        <Calendar
                            id="validtill"
                            value={formik.values.validtill}
                            onChange={formik.handleChange}
                            dateFormat="mm-dd-yy"
                            showIcon
                        />
                    </div>
                </div>
                <Divider />
                <div className="p-dialog-footer">
                    <div className="flex">
                        <Button
                            label="Clear All filter"
                            className="p-button-secondary p-button-text"
                            style={{ textDecoration: "underline" }}
                            onClick={handleClearFilter}
                            type="button"
                        />
                        <div className="ml-auto">
                            <Button
                                label="Cancel"
                                type="button"
                                onClick={(e) => op.current.hide()}
                                className="p-button-secondary p-button-text"
                            />
                            <Button
                                label="Apply"
                                type="submit"
                                className="p-button-text"
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const renderSearchHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                {singleStoreSearch()}
                <div>
                <span className="p-input-icon-left" style={{ marginRight: '20px'}}>
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
                    <Button
                        type="button"
                        icon="pi pi-bars"
                        label="Filter"
                        iconPos="right"
                        onClick={(e) => op.current.toggle(e)}
                        aria-haspopup
                        aria-controls="overlay_panel"
                        className="p-button-secondary"
                    />
                </div>
                
            </div>
        )
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const renderList = () => {
        return (
            <>
                {renderHeader()}
                <div className="bg-white ma-16 pv-20 ph-20 br-6 shadow-white">
                    <DataTable
                        value={promoList}
                        showGridlines
                        columnResizeMode="fit"
                        responsiveLayout="scroll"
                        emptyMessage="There are no any data. Please select store."
                        paginatorClassName="justify-content-end"
                        sortField="id"
                        sortOrder={-1}
                        paginator
                        paginatorTemplate={renderPaginationTemplate}
                        first={first2}
                        rows={rows2}
                        onPage={onCustomPage2}
                        header={renderSearchHeader()}
                        filters={filters}
                    >
                        <Column field="id" header="Id" sortable />
                        <Column
                            field="promocode"
                            header="Promo Code"
                            sortable
                        />
                        <Column
                            field="title"
                            header="Promotion Name"
                            sortable
                        />
                        <Column field="promogroup" header="Promo Group" />
                        <Column
                            field="promotype"
                            header="Promo Type"
                            sortable
                        />
                        <Column
                            field="validfrom"
                            header="Valid From"
                            sortable
                        />
                        <Column
                            field="validtill"
                            header="Valid Till"
                            sortable
                        />
                        <Column field="status" header="Status" sortable />
                        <Column header="Actions" body={actionTemplate}></Column>
                    </DataTable>
                    <OverlayPanel
                        ref={op}
                        showCloseIcon
                        id="overlay_panel"
                        style={{ width: "450px" }}
                        className="overlaypanel-demo"
                    >
                        {renderFilterForm()}
                    </OverlayPanel>
                </div>
            </>
        );
    };

    const hideAlertModal = (e) => {
        setMessage("");
        e.preventDefault();
        props.dispatch(crudReset());
        callQueryApiForFetch("ONLOAD");
    };

    return (
        <React.Fragment>
            <div className="form_height">{renderList()}</div>
            <ConfirmAlert
                show={openDeleteprompt}
                isPrompt={true}
                children={
                    <div className="deleteAlertDiv">
                        Are you sure you want to delete this promotion?
                        <div style={{ margin: "2em" }}>
                            <button
                                className="btn btn-themes btn-rounded btn-sec deletBtn"
                                onClick={() => setOpenDeleteprompt(false)}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-themes  btn-rounded deletBtn"
                                onClick={() => shdDeleteRec()}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                }
            />
            <ConfirmAlert
                show={isSuccess}
                handleClose={hideAlertModal}
                children={
                    <div
                        style={
                            isSuccess
                                ? { padding: "2em", color: "green" }
                                : { padding: "2em", color: "red" }
                        }
                    >
                        {isSuccess ? message : ""}
                    </div>
                }
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(PromotionList);
