import React from "react";
import loadingIcon from "./Loading.gif";

const LoadingSubmit = () => {
  return (
    <div
      style={{
        height: '100%',
        textAlign: 'center',
        position: 'absolute',
        top: '0px',
        width: '100%',
        zIndex: '99',
        opacity: '400',
        bottom: '0',
        left: '0',
        right: '0'
      }}
    >
      <img style={{ margin: "15em 14em" }} src={loadingIcon} />
    </div>
  );
};
LoadingSubmit.propTypes = {};

export default LoadingSubmit;
