import React from 'react';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';

const FileUploadDialog = (props) => {
    const {
        displayFileUploadDialog,
        handleFileUploadDialogClose,
        uploadFileHandler,
        onUpload,
    } = props;
    return (
        <Dialog
            header="Upload Image"
            visible={displayFileUploadDialog}
            style={{ width: '50vw' }}
            onHide={handleFileUploadDialogClose}
        >
            <p className="alignCenter">
                <i
                    className="pi pi-cloud-upload"
                    style={{ fontSize: '2em' }}
                />
            </p>
            <p className="alignCenter">
                Max file size: 5mb, accepted: jpg|gif|png
            </p>
            <div className="flex justify-center" style={{ justifyContent: 'center' }}>
                <FileUpload
                    mode="basic"
                    name="media[]"
                    uploadHandler={uploadFileHandler}
                    accept="image/*"
                    maxFileSize={500000000000}
                    onUpload={onUpload}
                    auto
                    chooseLabel="Choose Image"
                    customUpload={true}
                />
            </div>
            <div style={{ height: '1em'}} />
        </Dialog>
    );
};

export default FileUploadDialog;
