import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createDefaultConfig,
  updateConfiguation
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import SimpleSelect from "./../../../components/SimpleSelect";
import DynamicConfig from "../../../components/DynamicConfig";
class AddConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: "",
      title: "",
      pgroup: "",
      pvalues: {},
      ptype: "",
      comments: "",
      formError: false,
      formErrorMsg: ""
    };
  }

  componentWillMount = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const { id, pgroup, title, pvalues, ptype, comments } = rec;
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.pgroup = pgroup ? pgroup : "";
            draft.title = title ? title : "";
            draft.pvalues = pvalues ? pvalues : "";
            draft.ptype = ptype ? ptype : "";
            draft.comments = comments ? comments : "";
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;

    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  arrayToObject = array => {
    var fields = {};
    var len = array.length;
    for (var i = 0; i < len; i++) {
      var item = array[i];
      fields[item["title"]] = item["desc"];
    }

    return fields;
  };
  getCreatePayload = () => {
    const { id, pgroup, title, pvalues, ptype, comments } = this.state;

    let body = {
      pgroup: pgroup,
      category: "Config",
      title: title,
      pvalues: this.arrayToObject(pvalues),
      ptype: ptype,
      comments: comments,
      status: "active"
    };
    if (this._isEditMode()) {
      body["id"] = id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createConfig = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateConfiguation(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createDefaultConfig(this.getCreatePayload()));
      }
    }
  };
  _clearErrorMessages = () => {
    this.setState({ formError: false, formErrorMsg: "" });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { pgroup, title, pvalues } = this.state;
    if (pgroup.trim() === "" || title.trim() === "" || pvalues === {}) {
      this.setState({
        formError: true,
        formErrorMsg: "Please fill out all mandatory fields"
      });
      return false;
    }
    return true;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
               type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createConfig()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
               type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createConfig()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/default-configuration"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="pgroup" className="floatLeft required">
              Group
            </label>
            <input
              type="text"
              className="form-control"
              id="pgroup"
              value={this.state.pgroup}
              onChange={event => this.setState({ pgroup: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => this.setState({ title: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="pvalues" className="floatLeft  required">
              Value
            </label>
            <DynamicConfig
              setDesc={value => {
                this.setState({ pvalues: value });
              }}
            ></DynamicConfig>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="pvalues" className="floatLeft">
              Type
            </label>
            <SimpleSelect
              objArray={{ static: "Static", system: "System" }}
              callbackChangeFilter={value => this.setState({ ptype: value })}
              selectedOption={this.state.ptype}
              id="ptype"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="comments" className="floatLeft">
              Comments
            </label>
            <input
              type="text"
              className="form-control"
              id="comments"
              value={this.state.comments}
              onChange={event =>
                this.setState({ comments: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Update Article Form"
                  : "Add Article Form"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/default-configuration"
            children={
              <div className="alert alert-success">
                {this._isEditMode() === true
                  ? "Configuration updated successfully"
                  : "Configuration created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return { queryData: state.queryData, crudData: state.crudData };
};

export default connect(mapStateToProps)(AddConfiguration);
