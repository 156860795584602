import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { searchGeneral } from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import { Dropdown } from 'primereact/dropdown';

const LanguageSelector = (props) => {
    const { language, handleChange } = props
    const { languageConfig } = props.queryData;
    const [langArr, setLangArr] = useState([])
    const [selectedLang, setSelectedLang] = useState()

    useEffect(() => {
        if (languageConfig.length > 0) {
            const languageObject = languageConfig[0].additionalfields;
            const languageArray = Object.entries(languageObject).map(([code, name]) => ({ code, name }));
            setLangArr(languageArray)
            if (language && languageArray.length > 0) {
                setSelectedLang(languageArray.find(l => l.code === language))
            }
        }
    }, [languageConfig, language])
    
    const handleSelectChange = e => {
        setSelectedLang(e.value)
        handleChange(e.value.code)
    }
    return (
        <>
            <Dropdown
                className="w-100"
                id="langauge"
                name="language"
                value={selectedLang}
                onChange={handleSelectChange}
                options={langArr}
                optionLabel="name"
                placeholder="Select"
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData
    };
};

export default connect(mapStateToProps)(LanguageSelector);
