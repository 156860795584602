import React from 'react';
//import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { padNumber } from '../../../helpers/Util';

// const getDateFormat = (dateStr) => {
//     const dateParts = dateStr.split(' ');
//     const dateArr = dateParts[0].split('-');
//     return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
// };

// const styles = StyleSheet.create({
//     pdfTable: {
//         width: '100%',
//     },
//     tableHeader: {
//         fontWeight: '700',
//         fontSize: '16px',
//         textAlign: 'center',
//         borderBottom: '1px solid #dee2e6',
//         color: '#000',
//         padding: 8,
//     },
//     title: {
//         height: 29,
//         textAlign: 'center',
//         fontSize: 16,
//         fontWeight: 'bold',
//         padding: 12,
//         color: '#000',
//     },
//     tCellRow: {
//         display: 'flex',
//         flexDirection: 'row',
//         borderBottom: '1px solid #dee2e6',
//     },
//     tCell: {
//         flex: 1,
//         textAlign: 'center',
//         //backgroundColor: 'lightgray',
//         padding: '16px 4px 8px 4px',
//         fontSize: '10px',
//         borderRight: '1px solid #dee2e6',
//     },
//     tDataCell: {
//         flex: 1,
//         textAlign: 'center',
//         padding: 4,
//         fontSize: '10px',
//         borderRight: '1px solid #dee2e6',
//     },
//     mb4: {
//         marginBottom: '4px',
//     },
// });

// function getCurrentDateTime() {
//     const now = new Date();

//     const twoDigit = (num) => (num < 10 ? `0${num}` : num);

//     const year = now.getFullYear();
//     const month = twoDigit(now.getMonth() + 1); // Months are zero-based
//     const day = twoDigit(now.getDate());
//     const hours = twoDigit(now.getHours());
//     const minutes = twoDigit(now.getMinutes());
//     const seconds = twoDigit(now.getSeconds());

//     const formattedDateTime = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;

//     return formattedDateTime;
// }

// const getSpecialDiscountAmount = item => {
//     const { specialdiscount } = item
//     const { naac20 } = specialdiscount
//     if (naac20 && naac20.specialdiscountamount) {
//         return isVoidTrxType(item.trxtype, naac20.specialdiscountamount)
//     }
//     return '';
// }

// const isVoidTrxType = (trxType, value) => {
//     if (trxType == 'void' && value > 0) {
//         return '-'+ value.toFixed(2)
//     }
//     return value.toFixed(2)
// }

// const getTerminalInfo = (terminalInfo) => {
//     const { additionalfields } = terminalInfo;
//     const { birinfo } = additionalfields;
//     return birinfo.activate ? birinfo : {};
// };

// const getReturnOrVoidNo = (item) => {
//     const { trxtype, voidno, retuno } = item
//     if (trxtype === 'void') {
//         return voidno
//     }
//     else if (trxtype === 'return') {
//         return retuno
//     }
// }

// const getVersionName = versionList => {
//     return versionList[0].translation['system.pos.product.header']
// }

// // const NaccPdf = (props) => {
// //     const { data, storeInfo, terminalInfo, reportDate, versionList } = props;
// //     const { additionalfields } = storeInfo;
// //     const terminalData = getTerminalInfo(terminalInfo);
// //     const storeData = additionalfields.birinfo ? additionalfields.birinfo : {};
// //     const version = versionList.length > 0 ? getVersionName(versionList) : 'Digital eXperience (DX) Version 1.0'
// //     return (
// //         <PDFViewer width="100%" height="600">
// //             <Document>
// //                 <Page size="A4" style={styles.page} orientation="landscape">
// //                     <View
// //                         style={{
// //                             fontSize: '10px',
// //                             textAlign: 'center',
// //                             marginTop: '20px',
// //                         }}
// //                     >
// //                         <Text style={styles.mb4}>{storeData.businessname}</Text>
// //                         {storeData.storetype !== '' && (
// //                             <Text style={styles.mb4}>{storeData.storetype}</Text>
// //                         )}
// //                         <Text style={styles.mb4}>{storeData.registeredname}</Text>
// //                         <Text style={styles.mb4}>{storeData.branchaddress}</Text>
// //                         <Text style={styles.mb4}>{storeData.tin}</Text>
// //                         <Text style={styles.mb4}>
// //                             Report Date: {reportDate}
// //                         </Text>
// //                     </View>
// //                     <View
// //                         style={{
// //                             fontSize: '10px',
// //                             marginTop: '20px',
// //                             marginLeft: '20px',
// //                         }}
// //                     >
// //                         <Text style={styles.mb4}>
// //                             {version}
// //                         </Text>
// //                         <Text style={styles.mb4}>
// //                             Branch: {storeData.branchcode}
// //                         </Text>
// //                         <Text style={styles.mb4}>
// //                             Terminal: {terminalInfo.terminalid}
// //                         </Text>
// //                         <Text style={styles.mb4}>
// //                             Serial No: {terminalData.serialno}
// //                         </Text>
// //                         <Text style={styles.mb4}>MIN: {terminalData.min}</Text>
// //                         <Text style={styles.mb4}>PTU: {terminalData.ptu}</Text>
// //                         <Text style={styles.mb4}>
// //                             Date and Time Generated: {getCurrentDateTime()}
// //                         </Text>
// //                         <Text style={styles.mb4}>
// //                             Generated By: {localStorage.getItem('userId')}
// //                         </Text>
// //                     </View>
// //                     <View style={styles.pdfTable}>
// //                         <View>
// //                             <Text style={styles.tableHeader}>
// //                                 National Athletes and Coaches Sales Book/Report
// //                             </Text>
// //                         </View>
// //                         <View style={styles.tCellRow}>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#A6A6A6',
// //                                 }}
// //                             >
// //                                 Date
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#A6A6A6',
// //                                 }}
// //                             >
// //                                 Transaction Type
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#00B0F0',
// //                                 }}
// //                             >
// //                                 Name of National Athlete/Coach
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#FFFF00',
// //                                 }}
// //                             >
// //                                 PNSTM ID No.
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#ED7D31',
// //                                 }}
// //                             >
// //                                 SI/OR Number
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#FFD966',
// //                                 }}
// //                             >
// //                                 Return/Void No
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#E7E6E6',
// //                                 }}
// //                             >
// //                                 Gross Sales/Receipts
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#5B9BD5',
// //                                 }}
// //                             >
// //                                 Sales Discount
// //                             </Text>
// //                             <Text
// //                                 style={{
// //                                     ...styles.tCell,
// //                                     backgroundColor: '#A6A6A6',
// //                                 }}
// //                             >
// //                                 Net Sales
// //                             </Text>
// //                         </View>
// //                         {data.map((item, index) => (
// //                             <View style={styles.tCellRow} key={index}>
// //                                 <Text style={styles.tDataCell}>
// //                                     {getDateFormat(item.trxdate)}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {item.trxtype}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {item.membername}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {item.memberid}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                 {item.invoicenoresetno} - {padNumber(item.invoiceno, 9)}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {getReturnOrVoidNo(item)}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {item.grossprice.toFixed(2)}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {getSpecialDiscountAmount(item)}
// //                                 </Text>
// //                                 <Text style={styles.tDataCell}>
// //                                     {isVoidTrxType(item.trxtype, item.netamount)}
// //                                 </Text>
// //                             </View>
// //                         ))}
// //                     </View>
// //                 </Page>
// //             </Document>
// //         </PDFViewer>
// //     );
// // };

const NaccPdf = () => {
    return (
        <></>
    )
}

export default NaccPdf;
