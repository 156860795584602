import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NameDisplay from './NameDisplay';
import {
    queryPropertyConfigByFields,
    logoutFromDashboard,
    queryDefaultConfigByFields,
} from '../../actions/actions';

const Header = (props) => {
    const [settings, setSettings] = useState({
        isThemeVisible: false,
        isNotificationVisible: false,
    });

    useEffect(() => {
        const requestBody = {
            body: {
                propcode: 'Language',
            },
        };

        props.dispatch(
            queryPropertyConfigByFields(
                requestBody,
                'GET_LANGUAGE_CONFIG_BY_TITLE',
                'LANGUAGE_CONFIG_BY_TITLE_SUCCESS',
                'LANGUAGE_CONFIG_BY_TITLE_FAILURE',
            ),
        );

        const requestBody2 = {
            body: {
                title: 'status_config',
                category: 'Config',
                status: 'Active',
            },
        };

        props.dispatch(
            queryDefaultConfigByFields(
                requestBody2,
                'GET_STATUS_CONFIG_BY_TITLE',
                'STATUS_CONFIG_BY_TITLE_SUCCESS',
                'STATUS_CONFIG_BY_TITLE_FAILURE',
            ),
        );

        const requestBody3 = {
            body: {
                title: 'MESSAGE_TYPES',
                category: 'Config',
            },
        };

        props.dispatch(
            queryDefaultConfigByFields(
                requestBody3,
                'GET_MOBILE_MESSAGE_TYPE_BY_TITLE',
                'GET_MOBILE_MESSAGE_TYPE_BY_TITLE_SUCCESS',
                'GET_MOBILE_MESSAGE_TYPE_BY_TITLE_FAILURE',
            ),
        );
    }, []);

    const _setNewTheme = (event) => {
        props.setNewTheme(event.target.id);
    };

    const _logout = () => {
        props.dispatch(logoutFromDashboard());
        localStorage.clear();
        window.location.href = '/';
    };

    const _onSettingsClick = () => {
        setSettings({
            isThemeVisible: !settings.isThemeVisible,
            isNotificationVisible: false,
        });
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid ">
                <div className="grid justify-content-between w-full">
                    <span className="sidebar-header" id="sidebar-header"></span>
                    <span>
                        Hi, <NameDisplay />
                        <img
                            alt=""
                            className="profile_img"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_profile.svg`}
                        />
                        <button
                            type="submit"
                            className="col-1 btn btn-default navBarIcons"
                            onClick={_onSettingsClick}
                        >
                            <img
                                alt=""
                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_profilearrow.svg`}
                            />
                        </button>
                    </span>
                    <div
                        className={
                            settings.isThemeVisible
                                ? 'slide display-block'
                                : 'slide display-none'
                        }
                    >
                        <a
                            className="dropdown-item"
                            href="/"
                            onClick={(event) => {
                                event.preventDefault();
                                _logout();
                            }}
                        >
                            Log Out
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

const themeDark = {
    border: '1px solid #28343a',
    float: 'left',
    width: '18px',
    height: '18px',
    margin: '2px',
    borderRadius: '10px',
    fontSize: '8px',
    backgroundColor: '#28343a',
    marginLeft: '10px',
};
const themeBlue = {
    border: '1px solid #3354b4',
    float: 'left',
    width: '18px',
    height: '18px',
    margin: '2px',
    borderRadius: '10px',
    fontSize: '8px',
    backgroundColor: '#3354b4',
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(Header);
