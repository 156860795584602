import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { searchGeneral, storeTagsSearchByFields, storeSearchByFields } from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { Dialog } from 'primereact/dialog';
import SpTable from './BIRReportTable/SpTable';
import NaccTable from './BIRReportTable/NaccTable';
import SpecialDiscountTable from './BIRReportTable/SpecialDiscountTable';
import SpPdf from './BIRReportTable/SpPdf';
import NaccPdf from './BIRReportTable/NaccPdf';
import ScPdf from './BIRReportTable/ScPdf';
import PrimeStoreSelector from '../../components/PrimeStoreSelector';

const SpTerminalSummaryReport = (props) => {
    const { specialDiscountTypeData, stores } = props.queryData;
    const [dataType, setDataType] = useState('');
    const [reportData, setReportData] = useState([]);
    const [exportVisible, setExportVisible] = useState(false);
    const [reportDate, setReportDate] = useState('')
    const [storeList, setStoreList] = useState([])

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(storeSearchByFields(`companyid=${companyId}`));
        getVersion()
    }, [])

    const createFetchUrl = (params) => {
        props.dispatch(
            searchGeneral(
                'pos/v1/terminal/report/specialdiscount',
                params,
                CONSTANTS.SPECIAL_DISCOUNT_TYPE_SUCCESS,
                CONSTANTS.SPECIAL_DISCOUNT_TYPE_FAILURE
            )
        );
    };

    useEffect(() => {
        const options = stores.map((e) => ({
            label: '(' + e.storeid + ') ' + e.title,
            value: e.storeid,
        }));
        setStoreList(options)
    }, [stores])

    useEffect(() => {
        if (specialDiscountTypeData.length > 0) {
            const allowType = ['sales', 'void', 'return'];
            const filterData = specialDiscountTypeData.filter((d) =>
                allowType.includes(d.trxtype)
            );
            const sortData = filterData.sort((a, b) => {
                const dateA = new Date(
                    a.trxdate.split(' ')[0].split('-').reverse().join('-') +
                        ' ' +
                        a.trxdate.split(' ')[1]
                );
                const dateB = new Date(
                    b.trxdate.split(' ')[0].split('-').reverse().join('-') +
                        ' ' +
                        b.trxdate.split(' ')[1]
                );
                return dateA - dateB;
            });
            setReportData(sortData);
            //setReportData(specialDiscountTypeData)
        }
        else {
            setReportData([]);
        }
    }, [specialDiscountTypeData]);

    const queryStoreBIRInfo = (data) => {
        let queryParam = `storeid=${data.storeid}&taggroup=storeprops&tagtype=birinfo&status=Active`;
            props.dispatch(
                storeTagsSearchByFields(
                    queryParam,
                    CONSTANTS.SEARCH_STORE_BIRINFO_FIELDS_SILENT,
                    CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_SUCCESS,
                    CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_FAILURE
                )
            );
    };

    const queryTerminalBirInfo = (data) => {
        const requestBody = {
            body: {
                tagtype: 'birinfo',
                storeid: data.storeid,
                taggroup: 'tprops',
                terminalid: data.terminalid,
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/store/tag/search/fields',
                requestBody,
                CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
                CONSTANTS.SEARCH_STORE_TAGS_FAILURE
            )
        );
    };

    const getVersion = () => {
        const requestBody = {
            body: {
                language: 'en',
                search_field: 'wrdcode',
                search_condi: 'like',
                search_value: 'Fixed',
            },
        }
        props.dispatch(
            searchGeneral(
                'cms/v1/word/translation/search',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    }

    const handleSearch = (data) => {
        queryStoreBIRInfo(data);
        queryTerminalBirInfo(data);
        setDataType(data.specialdiscount);
        setReportDate('From ' + moment(data.date_from).format('MM-DD-YYYY') + ' ' + 'Till ' + moment(data.date_till).format('MM-DD-YYYY'))
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
        const parsedValues = {
            ...data,
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        const requestBody = {
            body: {
                ...parsedValues,
            },
        };
        createFetchUrl(requestBody);
    };

    const getTable = () => {
        if (dataType == 'sp') {
            return <SpTable data={reportData} />;
        } else if (dataType == 'naac') {
            return <NaccTable data={reportData} />;
        } else {
            return <SpecialDiscountTable data={reportData} dataType={dataType} />;
        }
    };

    const handlePdfRender = () => {
        setExportVisible(true);
    };

    const getPdf = () => {
        const { storeBIRInfo, storeTags, articleFlowData } = props.queryData;
        const terminalInfo = storeTags.length > 0 ? storeTags[0] : {}
        const storeInfo = storeBIRInfo.length > 0 ? storeBIRInfo[0] : {}
        const versionArr = articleFlowData.filter(a => a.language == 'en')
        if (dataType == 'sp') {
            return (
                <SpPdf
                    data={reportData}
                    storeInfo={storeInfo}
                    terminalInfo={terminalInfo}
                    reportDate={reportDate}
                    versionList={versionArr}
                />
            );
        }
        else if (dataType == 'naac') {
            return (
                <NaccPdf
                    data={reportData}
                    storeInfo={storeInfo}
                    terminalInfo={terminalInfo}
                    reportDate={reportDate}
                    versionList={versionArr}
                />
            );
        }
        else if (dataType == 'sc' || dataType == 'pwd') {
            return (
                <ScPdf
                    data={reportData}
                    storeInfo={storeInfo}
                    terminalInfo={terminalInfo}
                    dataType={dataType}
                    reportDate={reportDate}
                    versionList={versionArr}
                />
            );
        }
        return '';
    };

    const currentPdfArr = ['sp', 'naac', 'sc', 'pwd']
    return (
        <>
            <div className="form_height">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">
                            Special Discount Type Report
                        </h2>
                    </div>
                </div>
                <div className="row form-container">
                    <div className="col">
                        <div className="frmDiv">
                            <ReportFilter handleSearch={handleSearch} storeList={storeList} />
                            {reportData.length > 0 && currentPdfArr.includes(dataType) && (
                                <div className="row mt-4">
                                    <Button
                                        style={{
                                            marginLeft: 'auto',
                                            marginRight: '20px',
                                        }}
                                        label="Export/Download Report"
                                        className="p-button-success"
                                        onClick={handlePdfRender}
                                    />
                                </div>
                            )}

                            {exportVisible && (
                                <Dialog
                                    visible={exportVisible}
                                    style={{ width: '80vw' }}
                                    onHide={() =>
                                        setExportVisible(!exportVisible)
                                    }
                                >
                                    {getPdf()}
                                </Dialog>
                            )}

                            <div className="row mt-4">
                                <div className="col-lg-12 col-sm-12">
                                    {getTable()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


const discountTypeOptions = [
    { name: 'SC', code: 'sc' },
    { name: 'PWD', code: 'pwd' },
    { name: 'NAAC', code: 'naac' },
    { name: 'SP', code: 'sp' },
];

const ReportFilter = (props) => {
    const { handleSearch } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));
    const [selectedStore, setSelectedStore] = useState({})

    const formik = useFormik({
        initialValues: {
            date_from: new Date(),
            date_till: new Date(),
            terminalid: '',
            //specialdiscount: discountTypeOptions.
            specialdiscount: discountTypeOptions.find(
                (status) => status.code == 'sc'
            ),
        },
        validate: (data) => {
            let errors = {};
            if (!data.terminalid) {
                errors.terminalid = 'Terminal id is required.';
            }
            if (!storeid) {
                errors.storeid = 'Store id is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid: storeid,
                specialdiscount: values.specialdiscount.code,
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(
                    ([_, value]) => value !== undefined && value !== ''
                )
            );
            handleSearch(filteredData);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleStoreSelection = (selectedStore) => {
        setStoreid(selectedStore.value)
        setSelectedStore(selectedStore)
    }

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="storeid">Select Store</label>
                        <PrimeStoreSelector
                                storeList={props.storeList}
                                handleStoreSelection={
                                    handleStoreSelection
                                }
                                selectedStore={selectedStore}
                                required
                            />
                        {getFormErrorMessage('storeid')}
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label
                            htmlFor="date_from"
                            className={classNames({
                                'p-error': isFormFieldValid('terminalid'),
                            })}
                        >
                            Terminal Id
                        </label>
                        <InputText
                            id="terminalid"
                            value={formik.values.terminalid}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('terminalid')}
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            maxDate={formik.values.date_till}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="specialdiscount">Discount Type</label>
                        <Dropdown
                            id="specialdiscount"
                            name="specialdiscount"
                            value={formik.values.specialdiscount}
                            onChange={formik.handleChange}
                            options={discountTypeOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3 mb-2"
                        style={{ display: 'flex' }}
                    >
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(SpTerminalSummaryReport);
