import React from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';

import Filter from '../../../components/Filter';
import {
    citySearchByFields,
    storeGroupSearchByFields,
    storeSearchByFields,
} from '../../../actions/actions';
import SimpleTable_Select from '../../../components/SimpleTable_Select';
import * as CONSTANTS from '../../../constants/constants';

class StoreSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');

        this.state = {
            showStoreSearchModal: false,
            selectedCountry: '',
            selectedCity: '',
            selectedStoreGroup: '',

            storeToSearch: '',
            selectedStores: '',
            columns: [
                {
                    dataField: 'storeid',
                    text: 'Store Id',
                    sort: true,
                },
                {
                    dataField: 'title',
                    text: 'Store',
                    sort: true,
                },
                {
                    dataField: 'groupid',
                    text: 'Store Group',
                    sort: true,
                },
                {
                    dataField: 'country',
                    text: 'Country',
                    sort: true,
                },
                {
                    dataField: 'city',
                    text: 'City',
                    sort: false,
                },
            ],
        };
    }

    componentWillMount = () => {
        this.props.queryData.storeSearchStores = [];
        let queryParam = `companyid=${this.companyId}`;
        this.props.dispatch(storeGroupSearchByFields(queryParam));
    };

    _openStoreSearchModal = (event) => {
        this.setState({
            showStoreSearchModal: true,
        });

        event.preventDefault();
    };

    _closeStoreSearchModal = (event) => {
        this.setState({
            showStoreSearchModal: false,
            selectedCountry: '',
            selectedCity: '',
            selectedStoreGroup: '',
            storeToSearch: '',
            selectedStores: '',
        });
        this.props.queryData.storeSearchStores = [];
        if (event) event.preventDefault();
    };

    _searchStores = (event) => {
        if (this.state.storeToSearch !== '') {
            this.props.dispatch(
                storeSearchByFields(
                    `storeid=${this.state.storeToSearch}`,
                    CONSTANTS.STORESEARCH_STORE_SUCCESS,
                    CONSTANTS.STORESEARCH_STORE_FAILURE
                )
            );
            if (event) event.preventDefault();
            return;
        }

        this.props.dispatch(
            storeSearchByFields(
                `companyid=${this.companyId}&groupid=${this.state.selectedStoreGroup}`,
                CONSTANTS.STORESEARCH_STORE_SUCCESS,
                CONSTANTS.STORESEARCH_STORE_FAILURE
            )
        );
        if (event) event.preventDefault();
    };

    _updateSelectedStores = (event) => {
        this.props.onSelectStore(this.state.selectedStores);
        this._closeStoreSearchModal();
        if (event) event.preventDefault();
    };

    _selectedCountryOnChange = (selectedValue) => {
        if (selectedValue === '-1') {
            this.setState({
                selectedCountry: selectedValue,
            });
        } else {
            this.setState({
                selectedCountry: selectedValue,
            });
            this.props.dispatch(
                citySearchByFields(`countrycode=${selectedValue}&status=Active`)
            );
        }
    };

    _selectedCityOnChange = (selectedValue) => {
        console.log('selectedcity::', selectedValue);
        if (selectedValue === '-1') {
            this.setState({
                selectedCity: selectedValue
            });
        } else {
            this.setState({
                selectedCity: selectedValue,
            });

            let queryParam = `status=Active&country=${this.state.selectedCountry}&city=${selectedValue}`;
            this.props.dispatch(storeGroupSearchByFields(queryParam));
        }
    };

    _storeGroupOnChange = (selectedValue) => {
        this.setState({
            selectedStoreGroup: selectedValue,
        });
    };

    _filterCountries = (list) => {
        if (!list) {
            return [];
        }

        const mappedFilters = list.map((listItem, index) => {
            return {
                key: index,
                id: listItem['countrycode'],
                name: listItem['title'],
            };
        });

        var uniquelist = mappedFilters.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);
        uniquelist.unshift({ id: '', name: 'Select' });
        return uniquelist;
    };

    _filterCities = (list) => {
        if (!list) {
            return [];
        }

        const mappedFilters = list.map((listItem, index) => {
            return {
                key: index,
                id: listItem['citycode'],
                name: listItem['title'],
            };
        });

        var uniquelist = mappedFilters.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);
        uniquelist.unshift({ id: '', name: 'Select' });
        return uniquelist;
    };

    _filterStoreGroups = (list) => {
        if (!list) {
            return [];
        }

        const mappedFilters = list.map((listItem, index) => {
            return {
                key: index,
                id: listItem['groupid'],
                name: listItem['title'],
            };
        });

        var uniquelist = mappedFilters.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);
        uniquelist.unshift({ id: '', name: 'Select' });
        return uniquelist;
    };

    _renderStoreSearchModal = () => {
        const { cities } = this.props.queryData;
        const { storeGroups } = this.props.queryData;
        const { storeSearchStores } = this.props.queryData;

        return (
            <Modal
                visible={this.state.showStoreSearchModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeStoreSearchModal()}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">Store Search</div>
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this._closeStoreSearchModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-4 col-sm-12">
                            <label className="col-sm-12 col-md-6 col-form-label alignLeft">
                                Store Group
                            </label>
                            <Filter
                                cssClass="form-control"
                                filterOptions={this._filterStoreGroups(
                                    storeGroups
                                )}
                                selectedOption={this.state.selectedStoreGroup}
                                className="dynamicDescInp"
                                callbackChangeFilter={(event) => {
                                    this._storeGroupOnChange(
                                        event.target.value
                                    );
                                }}
                                id="storeGroup"
                            />
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <label className="col-sm-12 col-md-4 col-form-label alignLeft">
                                Store Id
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                onChange={(e) =>
                                    this.setState({
                                        storeToSearch: e.target.value,
                                    })
                                }
                            ></input>
                        </div>

                        <div
                            className="col-lg-4 col-sm-12 btn-container1"
                            style={{ marginTop: '1em' }}
                        >
                            <button
                                type="submit"
                                onClick={this._searchStores}
                                className="btn btn-themes btn-rounded"
                            >
                                Search
                            </button>

                            <button
                                type="submit"
                                onClick={this._closeStoreSearchModal}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <SimpleTable_Select
                                columns={this.state.columns}
                                rows={storeSearchStores}
                                handleOnSelect={this.handleOnSelect}
                                handleOnSelectAll={this.handleOnSelectAll}
                                pagination={false}
                            />
                        </div>
                    </div>

                    <div className="row btn-container1">
                        <div className="col-lg-12 col-md-12 col-sm-12 alignRight">
                            <button
                                type="submit"
                                onClick={this._updateSelectedStores}
                                className="btn btn-themes btn-rounded"
                            >
                                Save
                            </button>

                            <button
                                type="submit"
                                onClick={this._closeStoreSearchModal}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Discard
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selectedStores: [...this.state.selectedStores, row.storeid],
            }));
        } else {
            this.setState(() => ({
                selectedStores: this.state.selectedStores.filter(
                    (x) => x !== row.storeid
                ),
            }));
        }
    };

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map((r) => r.storeid);
        if (isSelect) {
            this.setState(() => ({
                selectedStores: ids,
            }));
        } else {
            this.setState(() => ({
                selectedStores: [],
            }));
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.state.showStoreSearchModal &&
                    this._renderStoreSearchModal()}
                <div className="row">
                    <div className="col-12 noPadding noMargin">
                        <input
                            type="text"
                            className=" treemodal"
                            id="tenant"
                            readOnly={true}
                            value={this.props.value}
                            onClick={this._openStoreSearchModal}
                            disabled={this.props.disabled || false}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(StoreSearch);
