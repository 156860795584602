// Import local files

export const routesForSideBar = [

  {
    label: "Content Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_content.svg`,
    keywords:
      "store,signature product,promotion,events management,application,articles,coupon article",
    marginDimension: "0.5em",
    childMenu: "contentSubMenu",
    highlightClass: "",
    module: "CMS",
    configaccess: "showCMS",
    child: [
      {
        label: "Store Information",
        path: "/ui/store-information",
        group: "DE_CMS_ST",
        module: "CMS",
      },
      {
        label: "Events Management",
        path: "/ui/events-management",
        group: "DE_CMS_EVENT",
        module: "CMS",
      },
      {
        label: "Articles",
        path: "/ui/articles",
        group: "DE_CMS_ARTICLES",
        module: "CMS",
      },
      {
        label: "Product Article",
        path: "/ui/product-info",
        group: "DE_CMS_PRODUCT_ARTICLE",
        module: "CMS",
      },
      {
        label: "Product Addon",
        path: "/ui/product-addon",
        group: "DE_CMS_PRODUCT_ADDONS",
        module: "CMS",
      },
      {
        label: "Coupon Article",
        path: "/ui/coupon-info",
        group: "DE_CMS_COUPON_ARTICLE",
        module: "CMS",
      },
      {
        label: "Property Configuration",
        path: "/ui/property-config",
        group: "DE_CMS_PROPERTY_CONFIG",
        module: "CMS",
      },
      {
        label: "Word Translation",
        path: "/ui/word-translation",
        group: "DE_CMS_WORD_TRANSLATION",
        module: "CMS",
      },
      {
        label: "Page Articles",
        path: "/ui/page-articles",
        group: "DE_CMS_PAGE_ARTICLES",
        module: "CMS",
      },
    ]
  },
  {
    label: "Campaign Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_campaign.svg`,
    keywords: "campaign,coupon,utilisation report,coupon rules",
    marginDimension: "0.5em",
    childMenu: "campaignSubMenu",
    highlightClass: "",
    module: "CAMP",

    child: [
      // {
      //   label: "Analysis",
      //   path: "/ui/campaign-analysis",
      //   configaccess: "showCampAnalysis"
      // },
      {
        label: "Coupon Management",
        path: "/ui/coupon-management",
        group: "DE_CAMP_COUPONS",
        module: "CAMP",
      },
      {
        label: "Coupon Rules",
        path: "/ui/coupon-rules",
        group: "DE_CAMP_COUPON_RULES",
        module: "CAMP",
      },
      {
        label: "Coupon Redemption Rule",
        path: "/ui/coupon-redemption-rules",
        group: "DE_CAMP_COUPON_REDEMPTION_RULES",
        module: "CAMP",
      },
      {
        label: "Coupon Inventory",
        path: "/ui/coupon-inventory",
        group: "DE_CAMP_COUPON_INVENTORY",
        module: "CAMP",
      },
      {
        label: "Coupon Transaction",
        path: "/ui/coupon-transaction",
        group: "DE_CAMP_COUPON_TRANSACTION",
        module: "CAMP",
      },
      {
        label: "Issue And Export Coupon",
        path: "/ui/issue-export-coupon",
        group: "DE_CAMP_ISSUE_EXPORT_COUPON",
        module: "CAMP",
      },
      {
        label: "Point Rule",
        path: "/ui/point-rule",
        group: "DE_CAMP_POINT_RULE",
        //group: "DE_CAMP_ISSUE_EXPORT_COUPON",
        module: "CAMP",
      }
    ]
  },
  {
    label: "Promotion Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_promotion.svg`,
    keywords:
      "Sales Promotion",
    marginDimension: "0.5em",
    childMenu: "promotionSubMenu",
    highlightClass: "",
    module: "SALESPRM",
    configaccess: "showSALESPRM",
    child: [
      {
        label: "Promotions",
        path: "/ui/sales-promotions",
        group: "DE_SAL_SALES_PROMO",
        module: "SALESPRM"
      },
      {
        label: "Promotions Purchase Groups",
        path: "/ui/sales-promotions-pgroups",
        group: "DE_SAL_SALES_PROMO_PGROUP",
        module: "SALESPRM"
      },
    ]
  },
  {
    label: "Replenishment Management",
    path: "/ui/replenishment",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_survey.svg`,
    keywords: "replenishment management",
    marginDimension: "0.5em",
    highlightClass: "",
    group: "DE_UI_REPLENISHMENT",
    module: "EXTENSIONUI"
  },
  // {
  //   label: "NS Extension Management",
  //   path: "/ui/replenishment",
  //   icon: `${process.env.PUBLIC_URL}/assets/icons/ic_survey.svg`,
  //   keywords: "replenishment management",
  //   marginDimension: "0.5em",
  //   highlightClass: "",
  //   group: "DE_REPLENISHMENT",
  //   module: "REPORTS"
  // },
  {
    label: "Extensions",
    icon: `${process.env.PUBLIC_URL}/assets/icons/extension.svg`,
    keywords:
      "customer management,member profile,member coupon,member-profile-report,transaction report,mobile analytics,member analytics,analytics",
    marginDimension: "0.5em",
    childMenu: "extensionSubMenu",
    highlightClass: "",
    module: "EXTENSIONUI",
    configaccess: "showExt",
    iconClass: 'custom-svg mx-2',
    child: [
      {
        label: "Return",
        path: "/ui/sales-return",
        group: "DE_UI_RETURN",
        module: "EXTENSIONUI"
      },
      {
        label: "Sales Invoices",
        path: "/ui/sales-invoice",
        group: "DE_UI_COLLECTION_INVOICE",
        module: "EXTENSIONUI"
      },
      {
        label: "Sale Return Price",
        path: "/ui/extension/sale-return-price",
        group: "DE_UI_SALE_RETURN_PRICE",
        module: "EXTENSIONUI",
      },
      {
        label: "Sale Stock Report",
        path: "/ui/extension/sale-stock-report",
        group: "DE_UI_SALE_STOCK_REPORT",
        module: "EXTENSIONUI",
      },
      {
        label: "File Integration",
        path: "/ui/extension/file-integration-report",
        group: "DE_UI_FILE_INTEGRATION",
        module: "EXTENSIONUI",
      }
    ]
  },

  {
    label: "Customer Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_crm.svg`,
    keywords:
      "customer management,member profile,member coupon,member-profile-report,transaction report,mobile analytics,member analytics,analytics",
    marginDimension: "0.5em",
    childMenu: "customerSubMenu",
    highlightClass: "",
    module: "CRM",
    configaccess: "showCRM",
    child: [
      // {
      //   label: "Mobile Analytics",
      //   path: "/ui/mobile-analytics",
      //   group: "DE_CRM_MOBILE_ANALYTICS",
      //   module: "CRM",
      // },
      // {
      //   label: "Member Analytics",
      //   path: "/ui/member-analytics",
      //   group: "DE_CRM_MEMBER_ANALYTICS",
      //   module: "CRM",
      // },
      {
        label: "Customer Profile",
        path: "/ui/member-profile",
        group: "DE_CRM_MEMBER_PROFILE",
        module: "CRM",
      },
      {
        label: "Issue Customer Coupon",
        path: "/ui/member-coupon",
        group: "DE_CRM_ISSUE_MEMBER_COUPON",
        module: "CRM",
      },
      {
        label: "Customer Coupon List",
        path: "/ui/member-coupon/list",
        group: "DE_CRM_MEMBER_COUPONS",
        module: "CRM",
      },
      // {
      //   label: "Member Profile Report",
      //   path: "/ui/member-profile-report",
      //   group: "DE_CRM_MEMBER_PROFILE_REPORT",
      //   module: "CRM",
      // },
      // {
      //   label: "Sales Member Report",
      //   path: "/ui/sales-member-report",
      //   group: "DE_CRM_SALES_MEMBER_REPORT",
      //   module: "CRM"
      // },
    ]
  },
  {
    label: "Communication",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_comm.svg`,
    keywords:
      "communicaion,email,beacon,geofence,mobile,notification,information",
    marginDimension: "0.5em",
    childMenu: "communicationSubMenu",
    highlightClass: "",
    module: "COMM",
    configaccess: "showCOMM",
    child: [
      {
        label: "Geo Fence",
        path: "/ui/geo-fence",
        group: "DE_COMM_GEOFENCE",
        module: "COMM"
      },
      {
        label: "Email",
        path: "/ui/email",
        group: "DE_COMM_EMAIL_1",
        module: "COMM"
      },
      {
        label: "Mobile Notification",
        path: "/ui/mobile-notification",
        group: "DE_COMM_MOB_NOTIFICATION",
        module: "COMM"
      }
    ]
  },
  {
    label: "Sales Service",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_salesservice.svg`,
    keywords: "sales service,product catalog,sales order,sales,product,service",
    marginDimension: "0.5em",
    childMenu: "selfServiceSubMenu",
    highlightClass: "",
    module: "SALES",
    configaccess: "showSAL",
    child: [
      {
        label: "Sales Dashboard",
        path: "/ui/store-analytics"
      },
      // {
      //   label: "Sales Analytics",
      //   path: "/ui/sales-analytics",
      //   group: "DE_SAL_SALES_ANALYTICS",
      //   module: "SALES"
      // },
      {
        label: "Sales Order",
        path: "/ui/cart",
        group: "DE_SAL_SALES_ORDER",
        module: "SALES"
      },
      {
        label: "Product Catalog",
        path: "/ui/product-catalog",
        group: "DE_SAL_PRODUCT_CATALOG",
        module: "SALES"
      },
      {
        label: "Store Product Mapping",
        path: "/ui/map-store-product",
        group: "DE_SAL_STORE_PRD_MAPPING",
        module: "SALES"
      },
      {
        label: "Inventory Management",
        path: "/ui/inventory",
        group: "DE_SAL_INVENTORY",
        module: "SALES"
      },
      // {
      //   label: "Sales Promotions Rule",
      //   path: "/ui/sales-promotions",
      //   group: "DE_SAL_SALES_PROMO_RULE",
      //   module: "SALES"
      // },
      // {
      //   label: "Promotions Purchase Groups",
      //   path: "/ui/sales-promotions-pgroups",
      //   group: "DE_SAL_SALES_PROMO_PGROUP",
      //   module: "SALES"
      // },
      {
        label: "Product Category",
        path: "/ui/product-category",
        group: "DE_SAL_PRD_CATEGORY",
        module: "SALES"
      },
      {
        label: "Sales Report",
        path: "/ui/sales-report",
        group: "DE_SAL_SALES_REPORT",
        module: "SALES"
      },
      {
        label: "Sales Tracker Report",
        path: "/ui/sales-tracker-report",
        group: "DE_SAL_8",
        module: "SALES"
      },
      {
        label: "Product Mapping",
        path: "/ui/sales-document",
        group: "DE_SAL_PRD_MAPPING",
        module: "SALES"
      }
    ]
  },
  {
    label: "Reports",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_reports.svg`,
    keywords: "reports",
    marginDimension: "0.5em",
    childMenu: "reportsSubMenu",
    highlightClass: "",
    module: "REPORTS",
    configaccess: "showREPORTS",
    child: [
      {
        label: "Sales Dashboard",
        path: "/ui/store-analytics"
      },
      {
        label: "Sales Analytics",
        path: "/ui/report/sales-analytics",
        group: "DE_REPORTS_SALES_ANALYTICS",
        module: "REPORTS"
      },
      {
        label: "Master Report",
        path: "/ui/report/pos-master",
        group: "DE_REPORTS_POS_MASTER",
        module: "REPORTS"
      },
      {
        label: "Sales Report",
        path: "/ui/report/sales",
        group: "DE_REPORTS_SALES_DETAILS",
        module: "REPORTS"
      },
      {
        label: "Tender Listing Report",
        path: "/ui/report/tender-listing",
        group: "DE_REPORTS_TENDER_LISTING",
        module: "REPORTS"
      },
      {
        label: "Return Report",
        path: "/ui/report/refund",
        group: "DE_REPORTS_REFUND",
        module: "REPORTS"
      },
      {
        label: "Rabbit Member Report",
        path: "/ui/report/rabbit-member",
        group: "DE_REPORTS_RABBIT_MEMBER_REPORT",
        module: "REPORTS"
      },
      {
        label: "Product Price Report",
        path: "/ui/report/product-price",
        group: "DE_REPORTS_PRODUCT_PRICE_REPORT",
        module: "REPORTS"
      },
      {
        label: "Product Sales Report",
        path: "/ui/report/product-sales",
        group: "DE_REPORTS_PRODUCT_SALES_SUMMARY",
        module: "REPORTS"
      },
      {
        label: "Electronic Journal",
        path: "/ui/report/ej",
        group: "DE_REPORTS_EJ",
        module: "REPORTS"
      },
      {
        label: "Daily Sales Report",
        path: "/ui/report/daily-sales",
        group: "DE_REPORTS_DAILY_SALES",
        module: "REPORTS"
      },
      {
        label: "Monthly Sales Report",
        path: "/ui/report/monthly-sales",
        group: "DE_REPORTS_MONTHLY_SALES",
        module: "REPORTS"
      },
      {
        label: "Services Report",
        path: "/ui/report/services",
        group: "DE_REPORTS_SERVICES",
        module: "REPORTS"
      },
      // {
      //   label: "Terminal Sales Summary Report",
      //   path: "/ui/report/term-sales-summary-report",
      //   group: "DE_REPORTS_BIR_TERM_SALES_SUMMARY",
      //   module: "REPORTS"
      // },
      {
        label: "Coupon Transaction Report",
        path: "/ui/report/coupon-transaction-report",
        group: "DE_CAMP_COUPON_TRANSACTION",
        module: "REPORTS"
      },
      // {
      //   label: "Special Discounts Report",
      //   path: "/ui/report/special-discounts-report",
      //   group: "DE_REPORTS_BIR_SPECIAL_DISC_REPORT",
      //   module: "REPORTS"
      // },
      {
        label: "Data Validation",
        path: "/ui/report/data-validation",
        group: "DE_REPORTS_DATA_VALIDATION",
        module: "REPORTS"
      },
      {
        label: "Sales Sync Exception",
        path: "/ui/report/sales-sync-exception-report",
        group: "DE_REPORTS_SALES_SYNC_EXCEPTION",
        module: "REPORTS"
      },
      {
        label: "Cart Sync Report",
        path: "/ui/report/cart-sync",
        group: "DE_REPORTS_CART_SYNC",
        module: "REPORTS"
      },
      {
        label: "BIR Sales Summary Report",
        path: "/ui/report/special-discount-summary-report",
        group: "DE_REPORTS_BIR_TERM_SALES_SUMMARY",
        module: "REPORTS"
      },
      {
        label: "Special Discount Report",
        path: "/ui/report/special-discount-type-report",
        group: "DE_REPORTS_BIR_SPECIAL_DISC_REPORT",
        module: "REPORTS"
      },
    ]
  },
  {
    label: "Sales Operation",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_sales operation.svg`,
    keywords:
      "Order Fulfillment, delivery task, delivery area",
    marginDimension: "0.5em",
    childMenu: "salesoperationSubMenu",
    highlightClass: "",
    module: "SOP",
    configaccess: "showSALOP",
    child: [
      {
        label: "Delivery Area",
        path: "/ui/delivery-area",
        group: "DE_SOP_DA",
        module: "SOP"
      },
      {
        label: "Order Fulfillment",
        path: "/ui/orders-fulfillment",
        group: "DE_SOP_2",
        module: "SOP"
      },
      {
        label: "Order Shipping",
        path: "/ui/orders-fulfillment/shipping",
        group: "DE_SOP_3",
        module: "SOP"
      },
      {
        label: "Order Return",
        path: "/ui/orders-fulfillment/shipping-return",
        group: "DE_SOP_4",
        module: "SOP"
      },
      {
        label: "Operation Slot",
        path: "/ui/operation-slot",
        group: "DE_SOP_OS",
        module: "SOP"
      }
    ]
  },
  {
    label: "Media Management",
    path: "/ui/media-management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_media.svg`,
    keywords: "media management",
    marginDimension: "0.5em",
    highlightClass: "",
    module: "MEDIA"
  },
  {
    label: "ADMIN",
    path: "",
    icon: ``,
    keywords: "admin",
    marginDimension: "0.5em",
    highlightClass: "",
    module: "ADMIN"
  },
  {
    label: "Access Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_accessmgmt.svg`,
    keywords:
      "store,signature product,promotion,events management,application,articles,coupon article",
    marginDimension: "0.5em",
    childMenu: "accessSubMenu",
    highlightClass: "",
    module: "ACCESS",
    configaccess: "showUSER",
    child: [
      {
        label: "Users",
        path: "/ui/users",
        group: "DE_ACCESS_USERS",
        module: "ACCESS",
      },
      {
        label: "User Groups",
        path: "/ui/usergroups",
        group: "DE_ACCESS_USERGROUPS",
        module: "ACCESS",
      },
      {
        label: "Roles",
        path: "/ui/userroles",
        group: "DE_ACCESS_USERROLES",
        module: "ACCESS",
      }
    ]
  },
  {
    label: "Store Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_storemgm.svg`,
    keywords:
      "store,terminals,brokers,devices,peripherals",
    marginDimension: "0.5em",
    childMenu: "storeMgmtSubMenu",
    highlightClass: "",
    module: "STOREMGMT",
    configaccess: "showStoreMgmt",
    child: [
      {
        label: "Store Groups",
        path: "/ui/storegroups",
        group: "DE_STOREMGMT_STOREGROUPS",
        module: "STOREMGMT",
      },
      {
        label: "Stores",
        path: "/ui/stores",
        group: "DE_STOREMGMT_STORES",
        module: "STOREMGMT",
      },
      {
        label: "Store Options",
        path: "/ui/storeoptions",
        group: "DE_STOREMGMT_STORE_OPTIONS",
        module: "STOREMGMT",
      },
      {
        label: "Store Tenders",
        path: "/ui/storetenders",
        group: "DE_STOREMGMT_STORE_TENDERS",
        module: "STOREMGMT",
      },
      {
        label: "Device Managers",
        path: "/ui/devicebrokers",
        group: "DE_STOREMGMT_DEVICE_BROKER_MGMT",
        module: "STOREMGMT",
      },
      {
        label: "Peripherals",
        path: "/ui/posperipherals",
        group: "DE_STOREMGMT_PERIPHERAL_MGMT",
        module: "STOREMGMT",
      },
      {
        label: "Terminals",
        path: "/ui/terminals",
        group: "DE_STOREMGMT_TERMINAL_MGMT",
        module: "STOREMGMT",
      },
      {
        label: "Terminal Options",
        path: "/ui/terminaloptions",
        group: "DE_STOREMGMT_TERMINAL_OPTIONS",
        module: "STOREMGMT",
      },
      {
        label: "Terminal Tenders",
        path: "/ui/terminaltenders",
        group: "DE_STOREMGMT_TERMINAL_TENDERS",
        module: "STOREMGMT",
      },
      {
        label: "Tax Configuration",
        path: "/ui/tax-configuration",
        group: "DE_STOREMGMT_TAX_CONFIGURATION",
        module: "STOREMGMT"
      },
      {
        label: "Special Discount",
        path: "/ui/specialdiscount",
        group: "DE_STOREMGMT_SPECIAL_DISCOUNT",
        module: "STOREMGMT"
      },
      {
        label: "Store Hours",
        path: "/ui/store-hours",
        group: "DE_STOREMGMT_STOREGROUPS",
        module: "STOREMGMT",
      },
      {
        label: "Store Setting",
        path: "/ui/store-config",
        group: "DE_STOREMGMT_STOREGROUPS",
        module: "STOREMGMT"
      },
    ]
  },
  {
    label: "System Management",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_systemlogs.svg`,
    keywords:
      "api logs,jobtask logs",
    marginDimension: "0.5em",
    childMenu: "logsSubMenu",
    highlightClass: "",
    module: "LOGS",
    configaccess: "showLogs",
    child: [
      {
        label: "API Logs",
        path: "/ui/apilogs",
        group: "DE_LOGS_APILOGS",
        module: "LOGS",
      },
      {
        label: "Job Task Logs",
        path: "/ui/jobtasklogs",
        group: "DE_LOGS_JOBTASKS",
        module: "LOGS",
      },
      {
        label: "Data Sync Logs",
        path: "/ui/datasynclogs",
        group: "DE_LOGS_DATASYNC",
        module: "LOGS",
      },
      {
        label: "EIP Messages",
        path: "/ui/eip-messages",
        group: "DE_EIPMESSAGE",
        module: "STOREMGMT",
      },
      {
        label: "Rabbit Point Rules",
        path: "/ui/rabbit-point-rule",
        group: "DE_RABBIT_POINT_RULE",
        module: "STOREMGMT",
      },
    ]
  },
  {
    label: "Article Form",
    path: "/ui/article-form",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_articleform.svg`,
    keywords: "article form",
    marginDimension: "0.5em",
    highlightClass: "",
    module: "ADMIN"
  },
  {
    label: "Sales Catagories",
    path: "/ui/sales-category",
    icon: `${process.env.PUBLIC_URL}/assets/icons/ic_catagories.svg`,
    keywords: "sales catagories",
    marginDimension: "0.5em",
    highlightClass: "",
    module: "ADMIN"
  },
  // {
  //   label: "Settings",
  //   icon: `${process.env.PUBLIC_URL}/assets/icons/settings.png`,
  //   keywords: "store hours",
  //   marginDimension: "0.5em",
  //   highlightClass: "",
  //   childMenu: "settingsSubMenu",
  //   module: "DE_SET",
  //   child: [
  //     {
  //       label: "Store Hours",
  //       path: "/ui/store-hours",
  //       roleid: "DE_SOP_1"
  //     },
  //     {
  //       label: "Store Setting",
  //       path: "/ui/store-config",
  //       group: "DE_SET_STORE",
  //       module: "SET"
  //     },
  //   ]
  // },
  // {
  //   label: "Settings",
  //   icon: `${process.env.PUBLIC_URL}/assets/icons/settings.png`,
  //   keywords: "store hours",
  //   marginDimension: "0.5em",
  //   highlightClass: "",
  //   childMenu: "settingsSubMenu",
  //   module: "STOREMGMT",
  //   child: [
  //     {
  //       label: "Store Hours",
  //       path: "/ui/store-hours",
  //       group: "DE_STOREMGMT_STORES",
  //     },
  //     {
  //       label: "Store Setting",
  //       path: "/ui/store-config",
  //       group: "DE_STOREMGMT_STORES",
  //       module: "DE_STOREMGMT_STORES"
  //     },
  //   ]
  // }
];
