import React from 'react'
import { connect } from 'react-redux';
import { jobTaskLogsById } from '../../../actions/actions';

class ViewJobTaskLog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      title: '',
      tasktype: '',
      storeid: '',
      memberid: '',
      status: '',
      additionalfields: '',
      createdon: '',
      lastupdated: '',
      lastupdatedby: '',
    };
  }

  componentWillMount() {
    console.log('willmount..')
    if (this.props.logid) {
      console.log('willmount..', this.props.logid)
      this.props.dispatch(jobTaskLogsById(this.props.logid))
    }
  }

  componentDidUpdate = (prevProps) => {
    const { jobtasklog } = this.props.queryData;
    const { jobtasklog: prevjobtasklog } = prevProps.queryData;
    if (jobtasklog !== prevjobtasklog) {
      console.log('update jobtasklog::', jobtasklog)

      if (jobtasklog) {

        console.log('received teh jobtasklog:::', jobtasklog)

        const {
          id,
          title,
          tasktype,
          storeid,
          memberid,
          status,
          additionalfields,
          createdon,
          lastupdated,
          lastupdatedby
        } = jobtasklog

        this.setState({
          id,
          title,
          tasktype,
          storeid,
          memberid,
          status,
          additionalfields,
          createdon,
          lastupdated,
          lastupdatedby
        })
      }

    }
  }

  /*
   componentWillReceiveProps = () => {
     const { apilog } = this.props.queryData
     if (apilog) {
       
             console.log('received teh apilog:::', apilog)
       
       const {
         id,
         deviceid,
       createdon,
       uri,
       processtime,
       querystring,
       requesttext,
       responsetextsize,
       responsetext,
       companyid,
       userid,
       memberid,
       storeid,
       terminalid,
       serverid,
       instanceid,
       serviceid,
       requesttag,
       useros,
       requestid,
       devicetype,
       requesthashcode,
       headerval,
       responsestatus,
       responsecontenttype,
       cookies,
       requestmethod,
       ipaddress,
       sessionid,
       errorcode,
       contenttype,
       userinfo,
       userbrowser,
       serveripaddress,
       serverport,
       orderid,
       } = apilog
 
       this.setState({
         id,
         deviceid,
       createdon,
       uri,
       processtime,
       querystring,
       requesttext,
       responsetextsize,
       responsetext,
       companyid,
       userid,
       memberid,
       storeid,
       terminalid,
       serverid,
       instanceid,
       serviceid,
       requesttag,
       useros,
       requestid,
       devicetype,
       requesthashcode,
       headerval,
       responsestatus,
       responsecontenttype,
       cookies,
       requestmethod,
       ipaddress,
       sessionid,
       errorcode,
       contenttype,
       userinfo,
       userbrowser,
       serveripaddress,
       serverport,
       orderid
       })
     }
   }
   */

  render() {
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">Job Task Log</h2>
            </div>
          </div>

          <div className="row form-container">
            <div className="col col-lg-9">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Log Id
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="id"
                      value={this.state.id}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Task Type
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="tasktype"
                      value={this.state.tasktype}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Title
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="title"
                      value={this.state.title}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Store Id
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="storeid"
                      value={this.state.storeid}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Member Id
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="memberid"
                      value={this.state.memberid}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Status
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="status"
                      value={this.state.status}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                    Additional Fields
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <textarea
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="additionalfields"
                      value={this.state.additionalfields ? JSON.stringify(this.state.additionalfields) : "" }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                      Created On
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="createdon"
                      value={this.state.createdon}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                    Last Updated
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="lastupdated"
                      value={this.state.lastupdated}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-12 alignRight">
                    <label className="col-form-label">
                    Last Updated By
                    </label>
                  </div>
                  <div className="col-lg-10 col-sm-12">
                    <input
                      type="text"
                      className="form-control apilogLeftRightPadding"
                      id="lastupdatedby"
                      value={this.state.lastupdatedby}
                      disabled={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row btn-container form-button">
          <div className="col-sm-12 col-lg-12">
            {this._renderMainButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  _renderMainButton = () => {

    return (
      <React.Fragment>
        <button
          type="submit"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          onClick={() => {
            this.props.hideLogDetails();
          }}
        >
          Cancel
        </button>
      </React.Fragment>
    );
  };
}


const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(ViewJobTaskLog);