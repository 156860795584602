import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../../helpers/Util';
import {
    searchGeneral,
    crudReset,
    deleteStoreDevice,
    storeSearchByFields,
    resetAction,
    storeDevicesSearchByFields
} from '../../../../actions/actions';
import * as CONSTANTS from '../../../../constants/constants';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import PrimeTable from '../../../../components/PrimeTable';
import DuplicateTerminalForm from './DuplicateTerminalForm';
import PrimeStoreSelector from '../../../../components/PrimeStoreSelector';
import BreadCrumbWrapper from '../../../../components/BreadCrumbWrapper';
import ConfirmAlert from '../../../../components/ConfirmAlert';

const items = [
    { label: 'Stores', path: '/ui/stores' },
    { label: 'Terminals', path: '/ui/terminals' },
];

class DisplayStoreTerminals extends React.PureComponent {
    statusOptions = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Deleted', id: 'Deleted' },
        { name: 'Inactive', id: 'Inactive' },
    ];

    devicegroup = 'terminal';

    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_STOREMGMT_TERMINAL_MGMT';
        this.module = 'STOREMGMT';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            // pages: 10,
            // currPage: 0,
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',

            storeid: '',
            storelabel: '',
            terminalid: '',
            status: '',
            selectedStore: {},
            storesList: [],
            storeiderror: '',
            //duplicate purpose
            selectedTerminal: '',
            openDuplicatePrompt: false,
            openSuccessDuplicate: false,
        };
    }

    linkTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }
        return (
            <>
                <Button
                    label="Options"
                    className="p-button-sm p-button-outlined mr-2 mb-2"
                    onClick={() => this.linkToRelated(row, 'terminaloptions')}
                />
                <Button
                    label="Tenders"
                    className="p-button-sm p-button-outlined p-button-secondary mr-2 mb-2"
                    onClick={() => this.linkToRelated(row, 'terminaltenders')}
                />
            </>
        );
    };

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }
        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/terminals/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/terminals/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                )}
                {canWrite(this.allPermissions) && (
                    <img
                        title="Duplicate"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        onClick={() => this.duplicateRecord(row)}
                    />
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this._deleteTerminal(row)}
                    />
                )}
            </span>
        );
    };

    duplicateRecord = (row) => {
        this.setState({ selectedTerminal: row, openDuplicatePrompt: true });
    };

    linkToRelated = (row, urlName) => {
        const { terminalid } = row;
        this.props.history.push(`/ui/${urlName}`, { terminalid });
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const {
            pageSize,
            currPage,
            storeid,
            // devicegroup
        } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    devicegroup: this.devicegroup,
                    storeid: storeid,
                    pagesize: pageSize,
                    pageno: currPage,
                },
            };
            this.requestBody['storeid'] = storeid;
            return requestBody;
        }

        const requestBody = {
            body: {
                ...this.requestBody,
                pagesize: pageSize,
                pageno: currPage,
                devicegroup: this.devicegroup,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/store/device/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS,
                CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        // var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    checkStoreIdSelected = () => {
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.callQueryApiForFetch('ONLOAD');
        } else {
            this.setState({ storeiderror: 'Select store' });
        }
    };

    componentDidUpdate = (prevProps) => {
        const { stores } = this.props.queryData;
        const { stores: prevStores } = prevProps.queryData;

        if (stores !== prevStores) {
            if (stores && stores.length > 0) {
                const options = stores.map((e) => ({
                    label: '(' + e.storeid + ') ' + e.title,
                    value: e.storeid,
                }));
                this.setState({ storesList: options });
                if (this.state.storeid != '') {
                    const _selectedStores = stores.filter(
                        (e) => e.storeid == this.state.storeid
                    );
                    if (_selectedStores && _selectedStores.length > 0) {
                        const selectedStore = _selectedStores.map((e) => ({
                            label: '(' + e.storeid + ') ' + e.title,
                            value: e.storeid,
                        }));
                        this.setState({ selectedStore: selectedStore });
                    }
                }
            } else {
                this.setState({ storesList: [], selectedStore: {} });
            }
        }
    };

    componentWillMount() {
        this.props.queryData.storeDevices = [];
        this._searchStores();
        const storeid = localStorage.getItem('storeid');
        const storelabel = localStorage.getItem('storelabel');
        if (storeid && storeid !== '' && storelabel && storelabel !== '') {
            this.setState({ storeid: storeid, storelabel: storelabel }, () =>
                this.callQueryApiForFetch('ONLOAD')
            );
        } else {
            this.state.storeiderror = 'Select store';
        }
        this.allPermissions = permissions(this.module, this.group);
    }

    _searchStores = () => {
        this.props.dispatch(storeSearchByFields(`companyid=${this.companyId}`));
    };

    _renderAddNewBtn = () => {
        const { history } = this.props;
        if (!canWrite(this.allPermissions)) {
            return;
        }

        return (
            <Button
                label="Add Terminal"
                icon="pi pi-plus"
                className="p-button-outlined"
                disabled={!this.state.storeid}
                onClick={() => history.push('/ui/terminals/addNew')}
            />
        );
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteStoreDevice(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    _deleteTerminal = (rowData) => {
        this.setState({ message: 'Terminal deleted', action: 'Delete' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _handleStoreSelection = (selected) => {
        this.setState({ selectedStore: selected });
        if (selected.label.indexOf('(') !== -1) {
            const storeid = selected.value; // selected.substring(0, selected.indexOf('-'))
            const storeLabel = selected.label.substring(
                selected.label.indexOf('-1') + selected.value.length + 4,
                selected.label.length
            );
            this._setStore(storeid, storeLabel);
        }
    };

    handleDuplicateSuccess = (store) => {
        this.setState({ openDuplicatePrompt: false });
        const { value, label } = store;
        if (value) {
            localStorage.setItem('storeid', value);
            localStorage.setItem('storelabel', label);
            this.setState({ selectedStore: store });
            const requestBody = {
                body: {
                    devicegroup: this.devicegroup,
                    storeid: store.value,
                    pagesize: this.state.pageSize,
                    pageno: this.state.currPage,
                },
            };
            this.props.dispatch(
                searchGeneral(
                    'system/v1/store/device/search/fields',
                    requestBody,
                    CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS,
                    CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS
                )
            );
        }
    };

    onSuccessTerminalCreate = () => {
        this.props.dispatch(crudReset());
        this.setState({
            openDuplicatePrompt: false,
            openSuccessDuplicate: true,
        });
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            storeDevices,
            // stores
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        const columns = [
            { field: 'storeid', header: 'Store Id' },
            { field: 'terminalid', header: 'Terminal Id' },
            { field: 'title', header: 'Title' },
            { field: 'status', header: 'Status' }
        ];

        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <h2 className="page-header">
                                Terminals
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.checkStoreIdSelected();
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                    alt="RefreshButton"
                                />
                            </h2>
                        </div>
                        <div className="form-group col-lg-3 col-sm-12 alignWithPageHeader">
                            <PrimeStoreSelector
                                storeList={this.state.storesList}
                                handleStoreSelection={
                                    this._handleStoreSelection
                                }
                                selectedStore={this.state.selectedStore}
                                required
                            />
                        </div>
                        <div className="col-lg-4 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div
                                className="frmDiv"
                                style={{ paddingTop: '10px' }}
                            >
                                <BreadCrumbWrapper items={items} />
                                <form>
                                    <div className="row">
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label
                                                htmlFor="terminalid"
                                                className="col-form-label "
                                            >
                                                Terminal Id
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="terminalid"
                                                value={this.state.terminalid}
                                                onChange={(event) =>
                                                    this.setState({
                                                        terminalid:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                            {/* <div className="invalid-feedback">{this.state.terminaliderror}</div> */}
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Status
                                            </label>
                                            <StatusOptionComp
                                                statusoption={
                                                    this.statusOptions
                                                }
                                                selectedOption={
                                                    this.state.status
                                                }
                                                callbackChangeFilter={(
                                                    value
                                                ) => {
                                                    this.setState({
                                                        status: value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-sm-12" />

                                        <div
                                            className="col-lg-4 col-sm-12 btn-container1"
                                            style={{ marginTop: '1em' }}
                                        >
                                            <button
                                                type="submit"
                                                onClick={(e) =>
                                                    this._searchTerminals(e)
                                                }
                                                className="btn btn-themes btn-rounded"
                                            >
                                                Search
                                            </button>

                                            <button
                                                type="submit"
                                                onClick={
                                                    this._clearFiltersAndResults
                                                }
                                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="title_devide" />
                                <PrimeTable
                                    list={storeDevices}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        header={`Duplicate ${this.state.selectedTerminal.title} (store - ${this.state.selectedTerminal.storeid})`}
                        visible={this.state.openDuplicatePrompt}
                        style={{ width: '50vw' }}
                        onHide={() =>
                            this.setState({ openDuplicatePrompt: false })
                        }
                    >
                        <DuplicateTerminalForm
                            selectedTerminal={this.state.selectedTerminal}
                            onSuccess={this.handleDuplicateSuccess}
                            storeList={this.state.storesList}
                            onSuccessTerminalCreate={
                                this.onSuccessTerminalCreate
                            }
                        />
                    </Dialog>
                    <ConfirmAlert
                        show={isSuccess && this.state.action === 'Delete'}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this terminal?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />

                    {/* <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    /> */}
                    <ConfirmAlert
                        show={this.state.openSuccessDuplicate}
                        handleClose={() =>
                            this.setState({ openSuccessDuplicate: false })
                        }
                        to="/ui/terminals"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                Terminal duplicated successfully
                            </div>
                        }
                    />
                </div>
            </>
        );
    }

    handleCloseDuplicatePrompt = () => {
        this.setState({ openSuccessDuplicate: false });
        this.props.dispatch(crudReset());
    };

    _searchTerminals = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { storeid, terminalid, status } = this.state;

        this.requestBody = {};
        const queryParams = [];
        let atleastOneFilter = false;
        if (storeid !== '') {
            queryParams.push(`storeid=${storeid}`);
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
        } else {
            this.setState({ storeiderror: 'Select store' });
            return;
        }

        if (terminalid !== '') {
            queryParams.push(`terminalid=${terminalid}`);
            this.requestBody['terminalid'] = terminalid;
        }

        if (status !== '') {
            queryParams.push(`status=${status}`);
            this.requestBody['status'] = status;
        }

        //validate the filters
        if (queryParams.length <= 0) {
            console.log('select atlease one filter');
            return;
        }

        queryParams.push(`devicegroup=${this.devicegroup}`);
        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        //query
        this.props.dispatch(storeDevicesSearchByFields(queryParams.join('&')));
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            terminalid: '',
            status: '',
            storeiderror: '',
        });
    };

    _setStore = (p1, p2) => {
        this.setState(
            { storeid: p1, storelabel: p2, search_value: p1, storeiderror: '' },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
            }
        );
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};
export default connect(mapStateToProps)(DisplayStoreTerminals);
