import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
    crudReset,
    storeSearchByFields,
    updateCollectionInvoice,
    createCollectionInvoice,
    getPaymentInvoiceDetail
} from '../../actions/actions';
import { statusConfigOptions } from '../../helpers/Util';

const CollectionInvoiceDetail = (props) => {
    const { queryData, location } = props;
    const { paymentInvoiceList } = queryData;
    const { state } = location;
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [record, setRecord] = useState({});
    const [storeid, setStoreId] = useState('');
    const [invoicedetails, setInvoiceDetails] = useState([]);

    useEffect(() => {
    const { isEdit, isView, item } = state || {};
    
    isEdit && setIsEditMode(true);
    isView && setIsViewMode(true);
    
    if (item?.id) {
        const { storeid, invoicedetails, invoicedate } = item;
    
        setRecord(item);
        setStoreId(storeid);
        invoicedetails && setInvoiceDetails(invoicedetails);
    }
    }, [state]);
      

    useEffect(() => {
        
        if (record && record.invoiceid) {
            const { invoiceid } = record;
            props.dispatch(getPaymentInvoiceDetail(invoiceid));
        }
    }, [record.invoiceid]);

    const formik = useFormik({
        initialValues: {
            invoiceid: record?.invoiceid || '',
            invoicetype: record?.invoicetype || '',
            invoicetypevalue: record?.invoicetypevalue || '',
            customerid: record?.customerid || '',
            customername: record?.customername || '',
            description: record?.description || '',
            orderid: record?.orderid || '',
            invoiceamount: record?.invoiceamount || '',
            invoiceno: record?.invoiceno || '',
            invoicebalamount: record?.invoicebalamount || '',
            paymentamount: record?.paymentamount || '',
            paymentdate: record?.paymentdate || '',
            invoiceage: record?.invoiceage || '',
            remark: record?.remark || '',
            trxno: record?.trxno || '',
            accinvbalamount: record?.accinvbalamount || '',
            invoicebalamount: record?.invoicebalamount || '',
            taxnumber: record.additionalfields?.taxnumber || '',
            terminalid: record?.terminalid || '',
            status:
                record && record.status
                    ? statusConfigOptions.find(
                          (item) => item.code === record.status
                      )
                    : statusConfigOptions.find(
                          (status) => status.code == 'New'
                      ),
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid,
                status: values.status ? values.status.code : '',
                invoicedate: moment(values.invoicedate).format(
                    'DD-MM-YYYY HH:mm:ss'
                ),
            };
            if (isEditMode) {
                data['id'] = record.id;
                data['invoicedetails'] = invoicedetails;
            }
            handleSubmit(data);
        },
    });

    const handleSubmit = (data) => {
        if (isEditMode) {
            props.dispatch(updateCollectionInvoice({ body: data }));
        } else {
            props.dispatch(createCollectionInvoice({ body: data }));
        }
    };

    const renderFormArea = () => {
        const { 
            invoiceid,
            storeid,
            customerid,
            customername,
            terminalid,
            invoicetypevalue,
            orderid,
            invoicedate,
            invoicetype,
            invoiceamount,
            description,
            paymentamount,
            invoiceage,
            invoicebalamount,
            status,
            remark
        } = record
        return (
            <>
                <div className="bg-white pv-20 ph-20 br-2 bg-white py-4 border-round-xl shadow-1">
                    <div className="grid">
                        <RecordRow label="Invoice Id" value={invoiceid} />
                        <RecordRow label="Store Id" value={storeid} />
                        <RecordRow
                            label="Customer"
                            value={`(${customerid}) ${customername}`}
                        />
                        <RecordRow label="Terminal Id" value={terminalid} />
                        <RecordRow label="Reference No." value={invoicetypevalue} />
                        <RecordRow label="Order Id" value={orderid} />
                        <RecordRow label="Invoice Date" value={invoicedate} />
                        <RecordRow label="Invoice Type" value={invoicetype} />
                        <RecordRow label="Total Amount" value={invoiceamount} />
                        <RecordRow label="Description" value={description} />
                        <RecordRow label="Paid Amount" value={paymentamount} />
                        <RecordRow label="Invoice age" value={invoiceage} />
                        <RecordRow label="Balance Amount" value={invoicebalamount} />
                        <RecordRow label="Status" value={status} />
                        <RecordRow label="Remark" value={remark || '-'} />
                    </div>
                </div>
            </>
        );
    };

    const collectorTemplate = (row) => {
        const { collectionpersonid, collectionpersonname } = row
        return `(${collectionpersonid}) ${collectionpersonname}`
    }

    const paytypeTemplate = ({ additionalfields, paymenttype }) => {
        if (paymenttype !== 'cash') {
            const { chequedate, chequenumber, bankname } = additionalfields;
            
            return [chequedate, chequenumber, bankname]
                .filter(Boolean) // Removes any undefined or null values
                .join('/');      // Joins the remaining values with '/'
        }
        return '';
    };
    

    const receiptTemplate = row => {
        const { additionalfields} = row
        const { collectionreceiptno, provisionalreceiptno } = additionalfields
        if (collectionreceiptno) {
            return `${collectionreceiptno}`
        }
        else if (provisionalreceiptno) {
            return `${provisionalreceiptno} *`
        }
        return ''
    }

    const paymentRefTemplate = row => {
        const { paymentdetails } = row
        if (paymentdetails && paymentdetails.length > 0) {
            return paymentdetails[0].paymentid
        }
    }

    const renderItemDetail = () => {
        return (
            <div className="p-fluid formgrid br-2 bg-white py-4 border-round-xl shadow-1 mt-2 mb-6">
                <h4 className="pl-3">Invoice payment details</h4>
                <DataTable
                    value={paymentInvoiceList}
                    dataKey="id"
                    className="bg-transparent"
                >
                    <Column
                        field="storeid"
                        header="Store Id"
                    />
                    <Column
                        field="terminalid"
                        header="Terminal Id"
                    />
                    <Column
                        field="trxno"
                        header="Trx No"
                    />
                    <Column
                        field="paymentdetails.paymentid"
                        body={paymentRefTemplate}
                        header="Payment Ref Id"
                    />
                    <Column
                        field="additionalfields"
                        body={receiptTemplate}
                        header="Receipt No. *"
                    />
                    <Column
                        field="collectionpersonname"
                        body={collectorTemplate}
                        header="Collected By"
                    />
                    <Column
                        field="paymentdate"
                        header="Date"
                    />
                    <Column
                        field="paymenttype"
                        header="Tender Type"
                    />
                    <Column
                        field="paymentamount"
                        header="Amount"
                    />
                    <Column
                        field="paymentamount"
                        body={paytypeTemplate}
                        header="Additional Info"
                    />
                </DataTable>
            </div>
        );
    };

    const renderMainButton = () => {
        if (isViewMode) {
            return (
                <Link
                    to={{
                        pathname: '/ui/sales-invoice',
                        state: {
                            currPage: state ? state.currPage : 1,
                            pageSize: state ? state.pageSize : 10,
                        },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Close
                </Link>
            );
        }
        return (
            <>
                <Button
                    label={isEditMode ? 'Update' : 'Save'}
                    className="p-button-lg p-button-success mr-3"
                    type="submit"
                    onClick={formik.handleSubmit}
                />

                <Button
                    label="Cancel"
                    className="p-button-secondary p-button-lg"
                    onClick={props.history.goBack}
                    style={{ marginRight: '20px' }}
                    type="button"
                />
            </>
        );
    };

    const renderErrorMessage = () => {
        const { isSuccess, errormsg } = props.crudData;
        if (errormsg && !isSuccess) {
            return (
                <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ marginBottom: '0px' }}
                >
                    {errormsg ? 'Invalid Param' : ''}
                </div>
            );
        }
        return;
    };

    const getComponentHeader = () => {
        const mode = isEditMode ? 'Edit' : isViewMode ? 'View' : 'Add';
        return `Sales Invoice`;
    };

    return (
        <>
            <div className="form_height px-2">
                    <div className="grid pl-3 ml-1">
                        <h2 className="page-header">{getComponentHeader()}</h2>
                    </div>
                    <div className="row">
                        <div className="col">{renderErrorMessage()}</div>
                    </div>
                    <div className="grid px-3">
                        <div className="col-12">
                            {renderFormArea()}
                            {renderItemDetail()}
                        </div>
                    </div>
                    <div
                        className="grid fixed w-100 bottom-0 surface-900 py-2 justify-content-center"
                        style={{ left: '3%' }}
                    >
                        {renderMainButton()}
                    </div>
                </div>
        </>
    );
};

const RecordRow = (props) => {
    const { label, value } = props;
    return (
        <div className="col-6 col-md-6 flex">
            <div className="row">
                <div className="col-6 col-md-6">
                    <label htmlFor="storeid" className="c-blue fw-500 f-16 mini_title">
                        {label}
                    </label>
                </div>
                <div className="col-6 col-md-6">
                    <label htmlFor="storeid">{value}</label>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
    };
};

export default connect(mapStateToProps)(CollectionInvoiceDetail);
