import React from 'react';
import { connect } from 'react-redux';
import LinkButton from '../../../components/LinkButton';
import {
    updatePropertyConfig,
    createPropertyConfig,
    crudReset,
    getStoreById,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import StatusComp from '../../../components/StatusComp';
import SimpleSelect from '../../../components/SimpleSelect';
import { produce } from 'immer';
import DynamicTable from '../../../components/DynamicTable';
import MultipleDynamicTable from '../../../components/MultipleDynamicTable';
import SimpleImageInput from '../../../components/SimpleImageInput';
import { modifyObject, stringtoarray } from '../../../helpers/Util';
import Modal from 'react-awesome-modal';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';

class AddPropConfig extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            storeid: '',
            language: '',
            langlist: {},
            propcode: '',
            propgroup: '',
            propvalue: '',
            status: 'Active',
            additionalFields: [],
            formError: false,
            formErrorMsg: '',
            isEdit: false,
            isView: false,
            imageurl: '',
            fieldvaltype: '0',
            showPreview: false,
            previewselectedrow: '',
            previewtext: '',
            previewindex: '',
            storelabel: '',
            // Errors
            propcodeerror: '',
            propgrouperror: '',
            propvalueerror: '',
        };
    }

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    propcode,
                    propgroup,
                    propvalue,
                    additionalfields,
                    status,
                    storeid,
                    imageurl,
                    fieldvaltype,
                } = rec;
                let defaultaddField = [{ field: '', value: '', id: 0 }];
                this.props.dispatch(getStoreById(storeid));
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.storeid = storeid ? storeid : '';
                        draft.propcode = propcode ? propcode : '';

                        if (
                            typeof additionalfields !== 'undefined' &&
                            additionalfields !== null &&
                            Object.keys(additionalfields).length !== 0
                        ) {
                            let newAdditionalFields =
                                modifyObject(additionalfields);
                            if (
                                propgroup &&
                                propgroup.toUpperCase() === 'COUNTRY'
                            ) {
                                newAdditionalFields = newAdditionalFields.map(
                                    (e) => ({ ...e, disabled: true }),
                                );
                            }
                            draft.additionalFields = newAdditionalFields;
                        } else {
                            draft.additionalFields = defaultaddField;
                        }
                        draft.propgroup = propgroup ? propgroup : '';
                        draft.propvalue = propvalue ? propvalue : '';
                        draft.status = status ? status : [];
                        draft.imageurl = imageurl ? imageurl : '';
                        draft.fieldvaltype =
                            typeof fieldvaltype !== 'undefined' && fieldvaltype
                                ? fieldvaltype
                                : '0';
                    }),
                );
            }
        }
    };

    componentWillReceiveProps = () => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }

        if (this._isEditMode() || this._isViewMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) {
                storeArray.storeid === this.state.storeid
                    ? this.setState({ storelabel: storeArray.title })
                    : this.setState({ storelabel: this.state.storelabel });
            }
            this.setState({
                language: this.props.history.location.state.item.language,
            });
        }
        // else {
        //   const defLanguage = this.props.location.state.language;
        //   this.setState({ language: defLanguage });
        // }
        if (
            this.state.fieldvaltype == 'array' ||
            this.state.fieldvaltype === 'string' ||
            this.state.fieldvaltype === '' ||
            this.state.fieldvaltype == '0'
        ) {
            if (this.state.additionalFields.length === 0) {
                this.updateAddField(this.state.fieldvaltype);
            }
        }
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const { fieldvaltype } = this.state;
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            field: '',
            value: fieldvaltype === 'array' ? [''] : '',
            image: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log('Rows After Add', this.state.additionalFields);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            disabled: false,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'image'
                      ? event
                      : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    handleTextFieldChange = (event, row, i) => {
        if (event) event.preventDefault();
        var newArray = JSON.parse(
            JSON.stringify([...this.state.additionalFields]),
        );
        newArray[row.id].value[i] = event.target.value;
        this.setState({ additionalFields: newArray });
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ additionalFields: updateValues });
        }
    };

    openPreview = (row, index) => {
        if (JSON.stringify(row) !== '{}') {
            let displaytext = row.value[index];
            this.setState({
                showPreview: true,
                previewtext: displaytext,
                previewselectedrow: row,
                previewindex: index,
            });
        }
    };

    closePreview = () => {
        this.setState({
            showPreview: false,
        });
    };

    handlePreviewTextArea = () => {
        const {
            additionalFields,
            previewselectedrow,
            previewindex,
            previewtext,
        } = this.state;
        previewselectedrow.value[previewindex] = previewtext;
    };

    renderPreviewModal = () => {
        return (
            <React.Fragment>
                <Modal
                    visible={this.state.showPreview}
                    width="80%"
                    height="80%"
                    effect="fadeInRight"
                    onClickAway={() => this.closePreview()}
                >
                    <div className="modalForm searchModalForm">
                        <div className="row">
                            <div className="col-xs-12 col-md-12 alignRight">
                                <a
                                    onClick={this.closePreview}
                                    className="closeIcon"
                                >
                                    X
                                </a>
                            </div>
                        </div>
                        <div className="title_devide" />
                        {this._renderPreviewForm()}
                    </div>
                </Modal>
            </React.Fragment>
        );
    };
    _renderPreviewForm = () => {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-10 col-sm-12">
                        <textarea
                            style={{ width: '100%' }}
                            className="form-control"
                            row="5"
                            columns="10"
                            value={this.state.previewtext}
                            onChange={(event) =>
                                this.setState(
                                    { previewtext: event.target.value },
                                    () => {
                                        this.handlePreviewTextArea();
                                    },
                                )
                            }
                        />
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.closePreview();
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    addtextareaHandler = (row) => {
        var newArray = JSON.parse(
            JSON.stringify([...this.state.additionalFields]),
        );
        newArray[row.id].value = row.value.concat('');
        this.setState({
            additionalFields: newArray,
        });
    };

    deletetextareaHandler = (row, index) => {
        var newArray = [...this.state.additionalFields];
        newArray[row.id].value.splice(index, 1);
        this.setState({ additionalFields: newArray });
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    getCreatePayload = () => {
        const {
            storeid,
            language,
            propcode,
            propgroup,
            propvalue,
            additionalFields,
            status,
            imageurl,
            fieldvaltype,
        } = this.state;
        let addfield = additionalFields.reduce(function (
            acc,
            { field, value, image },
        ) {
            if (image !== '' && typeof image !== 'undefined') {
                Array.isArray(value)
                    ? (acc[field] = [...value, image])
                    : (acc[field] = value);
                return acc;
            } else {
                Array.isArray(value)
                    ? (acc[field] = [...value])
                    : (acc[field] = value);
                return acc;
            }
        }, {});
        let body = {
            storeid,
            language,
            propcode,
            propgroup,
            propvalue,
            status,
            imageurl,
            fieldvaltype,
            additionalfields: addfield,
        };
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        //console.log(payload);
        return payload;
    };

    _createPropertyConfig = () => {
        if (this.validateForm()) {
            if (this.state.isEdit === true) {
                this.props.dispatch(
                    updatePropertyConfig(
                        this.getCreatePayload(),
                        this.state.id,
                    ),
                );
            } else {
                this.props.dispatch(
                    createPropertyConfig(this.getCreatePayload()),
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            propcodeerror: '',
            propgrouperror: '',
            propvalueerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { propcode, propgroup, propvalue } = this.state;
        let valid = true;
        if (propcode.trim() === '') {
            this.setState({
                propcodeerror: 'Property code is required.',
            });
            valid = false;
        }
        if (propgroup.trim() === '') {
            this.setState({
                propgrouperror: 'Property group is required.',
            });
            valid = false;
        }
        if (propvalue.trim() === '') {
            this.setState({
                propvalueerror: 'Property value is required.',
            });
            valid = false;
        }
        return valid;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <LinkButton
                        to="/ui/property-config"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        {`Close`}
                    </LinkButton>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createPropertyConfig()}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createPropertyConfig()}
                    >
                        Update
                    </button>
                )}

                <LinkButton
                    to="/ui/property-config"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    {`Cancel`}
                </LinkButton>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        const { languageConfig } = this.props.queryData;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        {/* <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
              disabled={this._isEditMode()}
            /> */}
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                            disabled={this._isEditMode()}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Language
                        </label>
                        <SimpleSelect
                            selectedOption={this.state.language}
                            objArray={this.state.langlist}
                            callbackChangeFilter={(value) => {
                                this.setState({ language: value });
                            }}
                            id="language"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="propcode"
                            className="floatLeft required"
                        >
                            Property Code
                        </label>
                        {this._isEditMode() === true ? (
                            <input
                                type="text"
                                className="form-control"
                                id="propcode"
                                value={this.state.propcode}
                                readOnly={true}
                            />
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="propcode"
                                value={this.state.propcode}
                                onChange={(event) => {
                                    this.setState({
                                        propcode: event.target.value,
                                    });
                                }}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.propcodeerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="propgroup"
                            className="floatLeft required"
                        >
                            Property Group
                        </label>
                        {this._isEditMode() === true ? (
                            <input
                                type="text"
                                className="form-control"
                                id="propgroup"
                                value={this.state.propgroup}
                                readOnly={true}
                            />
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="propgroup"
                                value={this.state.propgroup}
                                onChange={(event) => {
                                    this.setState({
                                        propgroup: event.target.value,
                                    });
                                }}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.propgrouperror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="propvalue"
                            className="floatLeft required"
                        >
                            Property Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="propvalue"
                            value={this.state.propvalue}
                            onChange={(event) => {
                                this.setState({
                                    propvalue: event.target.value,
                                });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.propvalueerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="postal" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="fieldvaltype" className="floatLeft">
                            Field Type
                        </label>
                        <SimpleSelect
                            objArray={{ string: 'String', array: 'Array' }}
                            callbackChangeFilter={(value) =>
                                this.setState({ fieldvaltype: value }, () =>
                                    this.updateAddField(value),
                                )
                            }
                            selectedOption={this.state.fieldvaltype}
                            id="fieldvaltype"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-8 col-sm-12">
                        <label htmlFor="imageurl" className="floatLeft">
                            Image
                        </label>
                        <SimpleImageInput
                            placeholder="Choose Image.."
                            setImage={(value) => {
                                this.setState({ imageurl: value });
                            }}
                            value={this.state.imageurl}
                        ></SimpleImageInput>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 noPadding noMargin">
                        {this._renderAdditionalFields()}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    setImages = (images) => {
        this.setState({ images: images });
    };
    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    updateAddField = (value) => {
        const { additionalFields } = this.state;
        if (value === '0' || value === '' || value === 'string') {
            let defaultfield = [{ id: 0, value: '', field: '' }];
            if (additionalFields.length === 0) {
                this.setState({
                    additionalFields: defaultfield,
                });
            } else {
                let newaddfield = stringtoarray(additionalFields);
                this.setState({
                    additionalFields: newaddfield,
                });
            }
        } else {
            let defaultfield = [{ id: 0, value: [''], field: '', image: '' }];
            if (additionalFields.length === 0) {
                this.setState({
                    additionalFields: defaultfield,
                });
            } else {
                let newaddfield = stringtoarray(additionalFields);
                this.setState({
                    additionalFields: newaddfield,
                });
            }
        }
    };

    _renderAdditionalFields = () => {
        const { fieldvaltype } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    {fieldvaltype == '0' ||
                    fieldvaltype === '' ||
                    fieldvaltype === 'string' ? (
                        <div className="form-group override col-lg-12 col-sm-12">
                            <DynamicTable
                                columns={[
                                    {
                                        dataFieldId: 'field',
                                        label: 'Key',
                                        addRow: true,
                                        disabled: true,
                                    },
                                    {
                                        dataFieldId: 'value',
                                        label: 'Value',
                                        addRow: true,
                                    },
                                    { dataFieldId: 'AD', label: '' },
                                ]}
                                rows={this.state.additionalFields}
                                deleteRow={this.deleteRow}
                                addRow={this.handleAddRow}
                                handleInputChange={this.handleInputChange}
                            />
                        </div>
                    ) : (
                        <MultipleDynamicTable
                            columns={[
                                {
                                    dataFieldId: 'field',
                                    label: 'Key',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: false,
                                },
                                {
                                    dataFieldId: 'image',
                                    label: 'Image',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                            handleTextFieldChange={this.handleTextFieldChange}
                            addtextarea={this.addtextareaHandler}
                            openPreview={this.openPreview}
                            deletetextarea={this.deletetextareaHandler}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                {this.state.showPreview && this.renderPreviewModal()}
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Property Configuration'
                                    : this._isViewMode()
                                      ? 'View Property Configuration'
                                      : 'Add Property Configuration'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col-md-10 col-sm-12 ">
                            <form className="frmDiv text-center">
                                {this._renderErrorMessage()}
                                {this._renderFormArea()}
                            </form>
                            <div className="row btn-container form-button">
                                <div className="col-12">
                                    {this._renderMainButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/property-config"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode() === true
                                    ? 'Property Configuration updated successfully!'
                                    : 'Property Configuration created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddPropConfig);
