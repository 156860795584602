import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import 'rc-time-picker/assets/index.css';
import {
    crudReset, createStoreDevice, updateStoreDevice
} from '../../../../actions/actions';


import ConfirmAlert from '../../../../components/ConfirmAlert';
import StatusOptionComp from '../../../../components/StatusOptionComp';

import { produce } from "immer";

class AddDeviceBroker extends React.PureComponent {

    editModeStatus = [
        { name: "Select", id: "" },
        { name: "New", id: "New" },
        { name: "Active", id: "Active" },
        { name: "InActive", id: "InActive" }
    ]

    devicegroup = 'devicebroker'

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: '',
            terminalid: '',
            deviceid: '',
            // devicegroup: 'devicebroker',
            brokerid: '',
            devicestatus: '',
            provider: '',
            title: "",

            status: "New",
            sortorder: 1,
            additionalFields: {},
            storelabel: "",

            // Errors
            titleerror: "",
            storeiderror: '',
            brokeriderror: ''
        };
    }

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            // brokerid,
            // devicegroup,
            status,
        } = this.state;
       
        let body = {
            storeid,
            terminalid,
            title,
            // brokerid,
            deviceid: this._getDeviceId(),
            devicegroup: this.devicegroup,
            status
        };

        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }

        const payload = {
            body: body
        };

        console.log('payload:::', payload)

        return payload;
    };

    /*
    getDeviceId = (brokerid, maxLength = 3) => {
        brokerid = brokerid.toString();
        while (brokerid.length < maxLength) brokerid = "0" + brokerid;
        return brokerid;
    }

    _getDeviceId = () => {
        if (this.state.deviceid !== '') { return this.state.deviceid }
        const threeDigitsDeviceId = this.getDeviceId(this.state.brokerid)
        return 'DBRK' + threeDigitsDeviceId
    }
    */

    _getDeviceId = () => {
        if (this.state.deviceid !== '') { return this.state.deviceid }
        return 'DBRK' + Math.floor(Math.random() * 899999 + 100000);
    }
    

    _saveDeviceBroker = (e) => {
        if (e) { e.preventDefault() }
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreDevice(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createStoreDevice(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue"
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            titleerror: "",
            brokeriderror: ""
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            // brokerid,
            storeid
        } = this.state;
        let valid = true;
        if (title.trim() === "") {
            this.setState({ titleerror: "Please enter a title" });
            valid = false;
        }

        /*
        if (brokerid.trim() === "") {
            this.setState({ brokeriderror: "Please enter broker id" });
            valid = false;
        } else if (brokerid > 999) {
            this.setState({ brokeriderror: "Please enter valid broker id" });
            valid = false;
        }
        */

        if (storeid.trim() === "") {
            this.setState({ storeiderror: "Please enter storeid" });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {

        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/devicebrokers"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode ? (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._saveDeviceBroker(e);
                        }}
                    >
                        Save
                    </button>
                )

                : (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._saveDeviceBroker(e)}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/devicebrokers"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>


            </React.Fragment>
        );
    }

    _renderDoubleColSubSecDivider = () => {
        return (
            <div className="doubleCol-subsec-divider"></div>
        )
    }

    _renderSubSecDivider = () => {
        return (
            <div className="subsec-divider"></div>
        )
    }

    _renderBasicDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">
                    Basic Information
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storecode" className="floatLeft required">
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={event =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">{this.state.storeiderror}</div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={event =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">{this.state.titleerror}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderOtherPropDetails = () => {

        return (
            <React.Fragment>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.editModeStatus}
                            selectedOption={this.state.status}
                            callbackChangeFilter={value => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMainForm = () => {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderBasicDetails()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form
                            className="splitFrmDivSec"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderOtherPropDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );

    };

    componentWillReceiveProps = () => {
        
    };

    componentDidMount = () => {
        
    };

    componentWillMount = () => {
        
        const storeid = localStorage.getItem('storeid')
        this.setState({storeid})

        if (this.props.location && this.props.location.state) {
            console.log('inside props state')
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            //this.setState({isEdit, isView, isForApproval})

            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    brokerid,
                    title,
                    // devicegroup,
                    deviceid,
                    status,
                    devicestatus,
                    provider,
                    additionalfields,
                } = rec;

                this.setState(
                    produce(draft => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.terminalid = terminalid ? terminalid : '';
                        draft.brokerid = brokerid ? brokerid : '';
                        draft.deviceid = deviceid ? deviceid : ''
                        // draft.devicegroup = devicegroup ? devicegroup : '';
                        draft.provider = provider ? provider : '';
                        draft.status = status ? status : '';
                        draft.devicestatus = devicestatus ? devicestatus : '';

                        draft.additionalFields = additionalfields
                    })
                );
            }
        }
    };

    componentDidUpdate = () => {
    }

    hideModal = e => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode() ? "Edit Device Manager" : this._isViewMode() ? "View Device Manager Definition" : "Add Device Manager"}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderMainForm()}
                        </div>
                    </div>

                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    {/* {this.renderAlertMessage(isSuccess, !isSuccess)} */}

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/devicebrokers"
                        children={
                            <div style={{ padding: "2em", color: "green" }}>
                                {this._isEditMode()
                                    ? "Device Manager updated successfully"
                                    : "Device Manager created successfully"}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddDeviceBroker);
