import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import { connect } from "react-redux";

class SimpleExpandTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {  
     };
  }

  render() {
    const { exprows, expcolumns } = this.props;
    const cellEdit = cellEditFactory({
     // mode: "click",
      blurToSave: true
      // afterSaveCell: (oldValue, newValue, row, column) => {
      //   console.log(oldValue, newValue, row)
      // }
    });

    const option = {
      noDataText: (
        <i
          className="fa fa-circle-o-notch fa-spin"
          style={{ fontSize: "24px" }}
        ></i>
      )
    };
     const selectRow1 = {
        mode: "checkbox",
        selected: this.props.selected1,
        onSelect: this.props.handleOnSelect1,
        onSelectAll: this.props.handleOnSelectAll1
     };

     const selectRow2 = {
      mode: "checkbox",
      selected: this.props.selected2,
      onSelect: this.props.handleOnSelect2,
      onSelectAll: this.props.handleOnSelectAll2
   };

    const expandRow = {
        showExpandColumn: true,
        //clickToSelect: false,
        expandColumnPosition: 'right',
        className: (isExpanded, row, rowIndex) => {
          console.log(row);
        },
        renderer: row => (
          <div>
            { typeof exprows !== "undefined" && exprows.length > 0 ?
              <div className="table-wrapper1">
                <BootstrapTable
                  condensed
                  keyField="id"
                  id="expand-table"
                  data={exprows}
                  columns={expcolumns}
                  selectRow={selectRow2}
                  cellEdit={cellEdit}
                />
              </div>
                :
              <div className="tableNoData">
                Your search does not retrieve any data. Please search again
                using another criteria.
              </div>
            }
          </div>
        ),
        expanded: [this.props.expanded],
        onExpand: this.props.handleOnExpand,
        //onSelectAll: this.props.handleOnSelectAll
    };
    const { columns, rows, selectMode } = this.props;
    
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="idx"
          data={rows}
          columns={columns}
          search
          bootstrap4
        >
          {props => (
            <div>
              <div className="table-wrapper">
                <BootstrapTable
                  {...props.baseProps}
                  options={option}
                  id="itemlist"
                  selectRow={selectRow1}
                  expandRow={ expandRow }
                  //cellEdit={cellEdit}
                />
                {this.props.rows.length === 0 && (
                  <div className="tableNoData">
                    Your search does not retrieve any data. Please search again
                    using another criteria.
                  </div>
                )}
              </div>
            </div>
          )}
        </ToolkitProvider>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(SimpleExpandTable);
