import React from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import CheckboxTree from 'react-checkbox-tree-enhanced'

class CategoryTree extends React.PureComponent {

  constructor(props) {

    super(props);

    this.state = {
      showCategoryTreeModal: false,
      selectedCategories: [],
      expandedCategories: ['PD01'],
    };
  }

  componentWillMount = () => {

  };

  _openCategoryTreeModal = event => {
    this.setState({
      showCategoryTreeModal: true
    });

    event.preventDefault();
  };

  _closeCategoryTreeModal = event => {
    if (event) event.preventDefault();
    this.setState({
      showCategoryTreeModal: false,
      //selectedCategories: [],
      expandedCategories: []
    });

  };

  _updateSelectedCategories = (selectedCategories) => {

    if (this.props.multiSelect) {
      this.setState({ selectedCategories: selectedCategories })
      this.props.onSelectCategory(selectedCategories);
    } else {
    let newlySelectedCategory = selectedCategories.filter(x => !this.state.selectedCategories.includes(x));
    this.setState({ selectedCategories: newlySelectedCategory })
    this.props.onSelectCategory(newlySelectedCategory);
    }
    
    // if (event) event.preventDefault();
  };

  onCheckCategory = (selectedCategories) => {
    // this.setState({ selectedCategories: selectedCategories })
    this._updateSelectedCategories(selectedCategories)
    if (this.props.closeOnSelect) {
      this._closeCategoryTreeModal();
    }
  }

  onExpandCategory = (expanded) => {
    this.setState({ expandedCategories: expanded })
  }

  rename = obj => {
    for (var prop in obj) {
      if (Array.isArray(obj[prop])) {
        if (obj[prop].length > 0) {
          obj.showCheckbox = false
        }
        obj[prop] = obj[prop].map(this.rename);
      }

      if (prop === 'categorycode') {
        obj.value = obj[prop]
        obj.icon = ''
      } else if (prop === 'title') {
        obj.label = obj[prop]
        obj['subtitle'] = obj['categorycode']
      } else if (prop === "subcategories") {
        obj.children = obj[prop];
      }
      delete obj[prop];
    }

    return obj;
  };

  _renderCategoryTreeModal = () => {
    // console.log('..... _ _renderCategoryTreeModal .....')
    // const { categoryTree } = this.props.queryData
    // console.log('categoryTree::', categoryTree)
    // const lCategoryTree = categoryTree
    // console.log('categoryTree::', categoryTree)
    // const nodes = [lCategoryTree].map(this.rename)
    // console.log('nodes::::', nodes)


    return (
      <Modal
        visible={this.state.showCategoryTreeModal}
        width="70%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeCategoryTreeModal()}
      >

        <div className="modalForm storeSearchModalForm">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">
              <div className="row sub-title">
                Sales Categories
              </div>
            </div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeCategoryTreeModal} className="closeIcon">
                X
              </a>
            </div>
          </div>


          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">


              {this.props.nodes.length > 0 && (
                <div className="customOl">

                  <CheckboxTree
                    nodes={this.props.nodes}
                    checked={this.state.selectedCategories}
                    // checked = {selectedRoles}
                    expanded={this.state.expandedCategories}
                    onCheck={this.onCheckCategory}
                    onExpand={this.onExpandCategory}
                    icons={{
                      check: <span className="rct-icon rct-icon-check" />
                    }}
                    expandOnClick={true}
                    onClick={()=>{}}
                    nativeCheckboxes={true}
                    showExpandAll={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

      </Modal>
    )
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showCategoryTreeModal && this._renderCategoryTreeModal()}
        <div className="row">
          <div className="col-12 noPadding noMargin">
            <input
              type="text"
              className=" treemodal"
              id="tenant"
              readOnly={true}
              value={this.state.selectedCategories.length > 0 ? this.state.selectedCategories[0] : ''}
              onClick={this._openCategoryTreeModal}
              disabled={this.props.disabled || false}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(CategoryTree);
