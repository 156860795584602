import React, { Component } from "react";
import SimpleImageInput from "./SimpleImageInput";

class DynamicTableImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateUri = (value, name, field, rows) =>{
    this.setState({
        [name]: value
    },()=>{
        this.props.handleImageInputChange(value, field, rows);
    })
  }

  updateFields = (imgurl, id, rows) => {
    const imgList = this.props.mediaImageList;
    const getwh = imgList.filter(i=>i.path==imgurl);
    if (imgurl !== "") {
      if (getwh.length > 0) {
        this.setState({
          ["width_" + id]: parseInt(getwh[0].width),
          ["height_" + id]: parseInt(getwh[0].height)
        }, () => {
          this.props.handleImageInputChange(getwh[0].width, "width", rows);
          this.props.handleImageInputChange(getwh[0].height, "height", rows);
        });
      }
    }
    else {
      this.setState({
        ["width_" + id]: "",
        ["height_" + id]: ""
      },()=>{
        this.props.handleImageInputChange("", "width", rows);
        this.props.handleImageInputChange("", "height", rows);
      })
    }
  }

  handleChange = (val, name, field, rows) =>{
    this.setState({
        [name]: val
    },()=>{
       this.props.handleImageInputChange(val, field, rows);
    })
  }


  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;
    return (
      <tbody>
        {this.props.rows.map((row, rowIdx) => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-image-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  rowIdx,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  renderRowData = (column, row, rowIdx, col, index, rowId, rowleng) => {
    if (column.addRow) {
      return (
        column.dataFieldId === "uri" ?
        <td key={`dynamic-tableimg-uri-tableview-td-${rowId}-${index}`} width="30%">
             <SimpleImageInput
              className={" "}
              placeholder="uri"
              name={"uri_" + rowId}
              setImage={value => {
                this.updateUri(value, "uri_" + rowId, column.dataFieldId,row);
                this.updateFields(value, rowId, row);
              }}
              value={row[column.dataFieldId] || this.state["uri_" + rowId]}
            ></SimpleImageInput>
        </td> :
        column.dataFieldId === "width" ?
        <td key={`dynamic-tableimg-width-tableview-td-${rowId}-${index}`} width="6%">
        <input
          type="number"
          name={"width_" + rowId}
          min="0"
          value={row[column.dataFieldId] || this.state["width_" + rowId]}
          placeholder={
            column.dataFieldId.charAt(0).toUpperCase() +
            column.dataFieldId.slice(1)
          }
          onChange={event =>
            this.handleChange(parseInt(event.target.value), "width_"+rowId,column.dataFieldId, row)
          }
        />
        </td> :
         column.dataFieldId === "height" ?
         <td key={`dynamic-tableimg-height-tableview-td-${rowId}-${index}`} width="6%">
         <input
           type="number"
           name={"height_" + rowId}
           min="0"
           value={row[column.dataFieldId] || this.state["height_" + rowId]}
           placeholder={
             column.dataFieldId.charAt(0).toUpperCase() +
             column.dataFieldId.slice(1)
           }
           onChange={event =>
            this.handleChange(parseInt(event.target.value), "height_"+rowId, column.dataFieldId, row)
           }
         />
         </td> :
        <td key={`dynamic-tableimg-othr-${col}-tableview-td-${rowId}-${index}`} width="10%">
          <input
            type={column.dataFieldId === "sortorder"?"number":"text"}
            defaultValue={row[column.dataFieldId]}
            placeholder={
              column.dataFieldId.charAt(0).toUpperCase() +
              column.dataFieldId.slice(1)
            }
            onChange={event =>
              this.props.handleImageInputChange(event, column.dataFieldId, row)
            }
          />
        </td>
      );
    }
    if (col === "AD") {
      const shouldShowAdd = (rowIdx === rowleng - 1 || rowleng === 0)
      return (
        <td width="10%" key={`dynamic-tableimg-ad-tableview-td-${rowId}-${index}`}>
          <table>
            <tbody>
              <tr>
              {(row["uri"] && row["uri"] !== "") ?
                <td>
                  <img
                    className="img-responsive w-100"
                    src={`${localStorage.getItem("imagepath")}` + row["uri"]}
                    alt="Card caption" width="20%" />
                </td> :   <td key={`tableview-td-${rowId}-${index}`}> <img  className="img-responsive w-100" src="" width="20%" /> </td>}
              <td>
                {shouldShowAdd && (<img
                  alt="add"
                  className="addBtn1"
                  onClick={this.props.addRow}
                  src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
                />)}
                <img
                  alt="delete"
                  src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                  onClick={() => this.props.deleteRow(row)}
                  className="addBtn2"
                />
              </td>
              </tr>
            </tbody>
          </table>
        </td>
      );
    }
    if (col === "A") {
      return (
        <td width="10%" key={`dynamic-tableimg-a-tableview-td-${rowId}-${index}`}>
          <table>
            <tbody>
            <tr>
            {row["uri"] && row["uri"] !== "" ?
              <td>
                <img
                  className="img-responsive w-100"
                  src={`${localStorage.getItem("imagepath")}` + row["uri"]}
                  alt="Card caption" width="20%" />
              </td> :   <td key={`tableview-td-d-${rowId}-${index}`}></td>}
            <td>
              <img
                alt="add"
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                onClick={() => this.props.deleteRow(row)}
                className="addBtn2"
              />
            </td>
            </tr>
            </tbody>
          </table>
        </td>
      );
    }
    if (col === "AG") {
      return (
        <td width="10%" key={`dynamic-tableimg-g-tableview-td-${rowId}-${index}`}>
          <table>
            <tbody>
            <tr>
            {row["uri"] && row["uri"] !== "" ?
              <>
                <td>
                  <img
                    className="img-responsive w-100"
                    src={`${localStorage.getItem("imagepath")}` + row["uri"]}
                    alt="Card caption" width="20%" />
                </td>
                <td>
                  <img
                    alt="generate"
                    title="generate thumbnail"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                    onClick={() => this.props.generateThumbnail(row["uri"])}
                  />
                </td>
              </> :
              <td key={`tableview-td-g-${rowId}-${index}`}></td>
            }
            <td>
              <img
                alt="delete"
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                onClick={() => this.props.deleteRow(row)}
                className="addBtn2"
              />
            </td>
            </tr>
            </tbody>
          </table>
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <React.Fragment>
        <div className="dynamicTableImage">
          <table>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicTableImage;
