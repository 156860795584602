import React from 'react';
import { connect } from 'react-redux';
import SampleTree from './SampleTree';
import { produce } from 'immer';
import StatusOptionComp from '../../../components/StatusOptionComp';
import SimpleTable from './../../../components/SimpleTable';
import {
    createItem,
    updateItem,
    deleteItem,
    crudReset,
    queryCategoryTree,
    resetAction,
} from '../../../actions/actions';
import treeFlatter from 'tree-flatter';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { Link } from 'react-router-dom';
import LinkButton from '../../../components/LinkButton';
import Loading from './../../../components/Loading';
import {
    canDelete,
    canWrite,
    permissions,
    removeDeletedChildren,
} from './../../../helpers/Util';

class ProductCategory extends React.PureComponent {
    constructor(props) {
        super(props);
        this.companyId = localStorage.getItem('company_id');
        this.module = 'SALES';
        this.group = 'DE_SAL_PRD_CATEGORY';
        this.allPermissions = [];

        this.state = {
            showAsTable: false,
            openDeleteprompt: false,
            delete_rec_id: '',
            treedata: [],
            message:
                'Updated product category.Please refresh to see the changes',
            search_field: 'pid',
            search_condi: 'eq',
            search_value: '1',
            status: '',
            selectedStatus: this.statusOptions[0].id,
            tableCols: [
                {
                    dataField: 'categorycode',
                    text: 'Category Code',
                    sort: true,
                },
                {
                    dataField: 'title',
                    text: 'Title',
                    sort: true,
                },
                {
                    dataField: 'description',
                    text: 'Description',
                    sort: true,
                },
                {
                    dataField: 'pid',
                    text: 'Parent Id',
                    sort: true,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'EDA',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],
        };
    }

    statusOptions = [
        { name: 'Active', id: 'Active' },
        { name: 'Active,New', id: 'Active,New' },
        { name: 'Active,Updated', id: 'Active,Updated' },
        { name: 'Active,Deleted', id: 'Active,Deleted' },
        { name: 'All', id: 'Active,New,Updated,Deleted' },
    ];

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status !== 'Deleted' && canWrite(this.allPermissions)) {
            return (
                <span>
                    {row.pid === 0 || row.pid === 1 || row.pid === 2 ? (
                        <Link
                            to={{
                                pathname: '/ui/product-category/addNew',
                                state: { item: row, isAdd: true },
                            }}
                        >
                            <img
                                title="New"
                                className="tableImage"
                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_addbtn_blue.svg`}
                            />
                        </Link>
                    ) : (
                        ''
                    )}
                    <Link
                        to={{
                            pathname: '/ui/product-category/addNew',
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        />
                    </Link>
                    {canDelete(this.allPermissions) && (
                        <img
                            title="Delete"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                            onClick={() => this.deleteProdCateory(row)}
                        />
                    )}
                </span>
            );
        }
    };
    createFetchUrl = (
        fetchType,
        pages,
        currPage,
        language,
        status,
        articleType,
    ) => {
        let requestBody;
        if (fetchType === 'ONLOAD') {
            requestBody = {
                body: {
                    articletype: articleType,
                    language: language,
                },
            };
        }

        return requestBody;
    };

    callFetch = () => {
        this.props.dispatch(
            queryCategoryTree(
                { body: {} },
                'PD01',
                `status=${this.state.selectedStatus}`,
            ),
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch();
                break;
        }
    };

    componentWillMount() {
        this.callQueryApiForFetch('ONLOAD');
        this.allPermissions = permissions(this.module, this.group);
    }

    rename = (obj) => {
        for (var prop in obj) {
            if (prop === 'status') {
                if (obj[prop].toLowerCase() !== 'active') {
                    const catcode = obj['categorycode'];
                    obj['subtitle'] = () => {
                        const status = obj['status'] || '';
                        switch (status.toLowerCase()) {
                            case 'deleted':
                                return (
                                    <span style={{ color: 'red' }}>
                                        [{catcode} - {status}]
                                    </span>
                                );
                            case 'updated':
                                return (
                                    <span style={{ color: 'green' }}>
                                        [{catcode} - {status}]
                                    </span>
                                );
                            default:
                                return (
                                    <span style={{ color: 'blue' }}>
                                        [{catcode} - {status}]
                                    </span>
                                );
                        }
                    };
                } else {
                    obj['subtitle'] = obj['categorycode'];
                }
            }

            if (prop === 'title') {
            }

            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }
            obj['expanded'] = true;
        }

        return obj;
    };

    getCreatePayload = (
        title,
        categorycode,
        description,
        imageurl,
        pid,
        level,
        id,
        sortorder,
        msgcode,
        isEdit,
        status,
        cattype,
        cattypevalue,
        additionalfields,
        imagegallery,
    ) => {
        let body = {
            categorycode: categorycode,
            title: title,
            description: description,
            imageurl: imageurl,
            sortorder: sortorder || '',
            msgcode,
            pid: pid,
            level: level,
            status,
            cattype,
            cattypevalue,
            additionalfields,
            imagegallery,
        };
        if (isEdit) {
            body['id'] = id;
            body['categorycode'] = categorycode;
        }
        const payload = {
            body: body,
        };
        //console.log(payload);
        return payload;
    };
    createProdCat = (
        title,
        catcode,
        desc,
        imageurl,
        pid,
        level,
        id,
        sortorder,
        msgcode,
        isUpdate,
        status,
        cattype,
        cattypevalue,
        additionalfields,
        imagegallery,
    ) => {
        this.props.dispatch(
            createItem(
                'sales/v1/category/save',
                this.getCreatePayload(
                    title,
                    catcode,
                    desc,
                    imageurl,
                    pid,
                    level,
                    id,
                    sortorder,
                    msgcode,
                    isUpdate,
                    status,
                    cattype,
                    cattypevalue,
                    additionalfields,
                    imagegallery,
                ),
            ),
        );
    };
    updateProdCat = (
        title,
        catcode,
        desc,
        imageurl,
        pid,
        level,
        id,
        sortorder,
        msgcode,
        isUpdate,
        status,
        cattype,
        cattypevalue,
        additionalfields,
        imagegallery,
    ) => {
        this.props.dispatch(
            updateItem(
                'sales/v1/category/update',
                this.getCreatePayload(
                    title,
                    catcode,
                    desc,
                    imageurl,
                    pid,
                    level,
                    id,
                    sortorder,
                    msgcode,
                    isUpdate,
                    status,
                    cattype,
                    cattypevalue,
                    additionalfields,
                    imagegallery,
                ),
            ),
        );
    };
    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteItem(
                    'sales/v1/category/delete/',
                    this.state.delete_rec_id,
                ),
            );
            this.callQueryApiForFetch('ONLOAD');
        }
    };
    hideAlertModal = (e) => {
        // this.setState({ message: "" });
        e.preventDefault();
        this.props.dispatch(crudReset());
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        // if (refresh === true) {
        //   this.callQueryApiForFetch("ONLOAD");
        // }
    };

    deleteProdCateory = (rowData, fromTree) => {
        let id;
        this.setState({ message: 'Product Category deleted successfully' });
        fromTree === true ? (id = rowData) : (id = rowData.id);
        this.setState({ openDeleteprompt: true, delete_rec_id: id });
    };
    _showAsTable = (arg) => {
        this.setState({ showAsTable: arg });
    };
    _renderSwitchView = () => {
        const { showAsTable } = this.state;
        return (
            <span className="switchViewSpan">
                <span className="switchViewTxt">Display By</span>
                <img
                    title="Switch to Grid View"
                    onClick={() => this._showAsTable(true)}
                    className={`switchViewImg ${
                        showAsTable === true ? 'switchViewImgHover' : ''
                    }`}
                    src={
                        showAsTable === false
                            ? `${process.env.PUBLIC_URL}/assets/icons/cat_table.png`
                            : `${process.env.PUBLIC_URL}/assets/icons/cat_tab_hover.png`
                    }
                />
                <img
                    title="Switch to List View"
                    onClick={() => this._showAsTable(false)}
                    className={`switchViewImg ${
                        showAsTable === false ? 'switchViewImgHover' : ''
                    }`}
                    src={
                        showAsTable === true
                            ? `${process.env.PUBLIC_URL}/assets/icons/cat_tree.png`
                            : `${process.env.PUBLIC_URL}/assets/icons/cat_tree_hover.png`
                    }
                />
            </span>
        );
    };

    _renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h1 className="page-header">
                        Product Category
                        <img
                            title="Refresh"
                            onClick={() => {
                                this.callQueryApiForFetch('ONLOAD');
                            }}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                        />
                    </h1>
                </div>
                <div className="col-lg-6 col-12 col-sm-12 alignRight headerIcons page-header-margin noPadding noMargin">
                    {this._renderSwitchView()}
                </div>
            </div>
        );
    };

    _renderSearch = () => (
        <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
                <label htmlFor="status" className="floatLeft">
                    Status
                </label>
                <StatusOptionComp
                    statusoption={this.statusOptions}
                    selectedOption={this.state.selectedStatus}
                    callbackChangeFilter={(value) => {
                        this.setState(
                            produce(this.state, (draft) => {
                                draft.selectedStatus = value;
                            }),
                            this.callFetch,
                        );
                    }}
                />
            </div>
        </div>
    );

    _renderNoData = () => {
        return (
            <React.Fragment>
                <div className="row noStoreform">
                    <div className="col-lg-6 col-sm-12">
                        <p className="noStoreHeader">
                            Create the product category
                        </p>
                        <p className="noStoreBody">
                            Manage your product category list by adding here
                        </p>
                        <div>
                            <LinkButton
                                to="/ui/product-category/addNew"
                                className="btn btn-themes btn-rounded"
                                onClick={(event) => event.preventDefault()}
                            >
                                Create Category
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <img
                            className="noStoreImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/img_vector_product.svg`}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    flattenChildren(obj) {
        if (!obj.children) return;
        obj.children = obj.children.map((child) => {
            if (typeof child === 'object') {
                return child.id;
            }
            return child;
        });
        obj.children.forEach((child) => {
            const nestedChild = obj.children.find((c) => c.id === child);
            if (nestedChild) {
                this.flattenChildren(nestedChild);
            }
        });
    }

    convertToObjectB(obj) {
        const newObj = { ...obj };
        this.flattenChildren(newObj);
        return newObj;
    }

    arraysAreEqual(arr1, arr2) {
        // If lengths are different, arrays are not identical
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Iterate through each element and compare
        for (let i = 0; i < arr1.length; i++) {
            // If elements at current index are not equal, arrays are not identical
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        // If all elements are equal, arrays are identical
        return true;
    }

    render() {
        const { showAsTable, treedata } = this.state;
        const { isSuccess, errormsg: crudError } = this.props.crudData;

        const {
            categoryTree,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }
        const newArrayOfObj = [categoryTree].map(this.rename);

        const options = {
            initNode: (node) => node,
        };
        const list = treeFlatter(newArrayOfObj, 'children', options);

        if (!categoryTree || this.props.queryData.apiPending) {
            return <Loading />;
        }
        return (
            <React.Fragment>
                <div className="form_height">
                    {this._renderHeader()}
                    <div className="row">{this._renderSearch()}</div>
                    <div className="invalid-feedback">{errormsg}</div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <form className="formDiv">
                                {list[0].categorycode ? (
                                    showAsTable === true ? (
                                        <SimpleTable
                                            columns={this.state.tableCols}
                                            rows={list}
                                            callback={() => {
                                                this.callQueryApiForFetch(
                                                    'ONLOAD',
                                                );
                                            }}
                                        />
                                    ) : (
                                        <></>
                                        // <SampleTree
                                        //     create={this.createProdCat}
                                        //     update={this.updateProdCat}
                                        //     delete={this.deleteProdCateory}
                                        //     data={newArrayOfObj}
                                        //     mediadata={
                                        //         this.props.queryData.mediaList
                                        //     }
                                        //     allPermissions={this.allPermissions}
                                        // />
                                    )
                                ) : (
                                    this._renderNoData()
                                )}
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div style={{ color: 'green', padding: '2em' }}>
                                Are you sure you want to delete this article
                                information?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                className={
                                    isSuccess
                                        ? 'alert alert-success'
                                        : 'alert alert-warning'
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ProductCategory);
