import React from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import CategoryTree from '../../components/CategoryTree';
import {
    queryCategoryTree,
    queryStoreCategoryInfo,
    resetErrorOnly,
} from '../../actions/actions';
import 'react-tabs/style/react-tabs.css';

import StatusComp from './../../components/StatusComp';
import ConfirmAlert from '../../components/ConfirmAlert';
import { permissions } from '../../helpers/Util';
import { productPriceDetailsReport } from '../../actions/actions';
import StoreSearchSingle from '../system/Users/StoreSearchSingle';
import ExportAndDownloadProductPriceDetailsReport from './ExportAndDownloadProductPriceDetailsReport';
import { PRODUCT_PRICE_DETAILS_REPORT } from '../../constants/constants';
import ReportWarning from '../../components/ReportWarning';
import PrimeTable from '../../components/PrimeTable';

class ProductPriceDetailsReport extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_REPORTS_PRODUCT_PRICE_REPORT';
        this.module = 'REPORTS';
        this.allPermissions = [];

        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,

            categorycodes: '',
            categories: '',
            productcode: '',
            storeid: '',
            status: 'Active',
            mapProducts: false,
            formError: false,
            formErrorMsg: '',

            showSecForm: 'false',
            isView: false,
            selectedProdId: '',
            prodData: {},
            storeproductdata: [],
            s_storelabel: '',
            storeiderror: '',
            categoryerror: '',
            showTable: false,

            params: {},
            showExportReport: false,
        };
    }

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
    };

    componentWillMount = () => {
        this.setState({
            storeid: localStorage.getItem('storeid'),
            s_storelabel: localStorage.getItem('storelabel'),
        });
        const fieldobj = this.props.location.state;
        if (typeof fieldobj !== 'undefined') {
            this.setState(
                {
                    storeid: fieldobj.storeid,
                    s_storelabel: fieldobj.storelabel,
                    categories: fieldobj.categories,
                    categorycode: fieldobj.categorycode,
                    status: fieldobj.status,
                    categorycodes: fieldobj.categorycode,
                },
                () => {
                    this._validateAndSearch();
                }
            );
        } else {
            console.log('fieldObj is undefined');
        }

        this.allPermissions = permissions(this.module, this.group);
    };

    getStoreData = (obj1) => {
        if (typeof obj1 !== 'undefined') {
            var result = obj1.map((e) => {
                this.compareObject(e);
                if ('children' in e)
                    e.children = e.children.map((child) => {
                        this.compareObject(child);
                        if ('children' in child)
                            child.children.map((c) => {
                                this.compareObject(c);

                                if ('children' in c)
                                    c.children.map((m) => {
                                        this.compareObject(m);
                                    });
                            });
                        return child;
                    });
                return e;
            });
            return result;
        }
    };

    compareObject = (col) => {
        const { storeCategoryMapping } = this.props.queryData;
        storeCategoryMapping.map((e) => {
            if (e.categorycodes == col.categorycode) {
                e['categorytitle'] = col.title;
            }
            return e;
        });
    };

    componentDidUpdate = (prevProps) => {
        const { categoryTree, storeCategoryMapping } = this.props.queryData;
        const { storeCategoryMapping: prevstoreCategoryMapping } =
            prevProps.queryData;
        if (storeCategoryMapping !== prevstoreCategoryMapping) {
            if (storeCategoryMapping.length > 0) {
                this.getStoreData(categoryTree.children);
                this.setState({
                    storeproductdata: storeCategoryMapping,
                });
            } else {
                this.setState({
                    storeproductdata: [],
                });
            }
        }
    };

    hideModal = () => {
        this.setState({ mapProducts: false });
    };

    _searchProductsMapped = (event) => {
        const { categorycodes, storeid, status, pageSize, currPage } =
            this.state;
        this.props.dispatch(
            queryStoreCategoryInfo(
                {
                    body: {
                        categorycode: categorycodes,
                        storeid: storeid,
                        status: status,
                        pagesize: pageSize,
                        pageno: currPage,
                    },
                },
                categorycodes,
                storeid
            )
        );
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }

            if (prop === 'title') {
                obj['subtitle'] = obj['categorycode'];
            }
        }

        return obj;
    };

    _mapProductsToStore = (event) => {
        if (event) event.preventDefault();
        this.setState({ mapProducts: true });
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            categoryerror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { storeid, categorycodes } = this.state;
        let valid = true;
        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select store id' });
            valid = false;
        }
        return valid;
    };

    _renderExportReportBtn = () => {
        return (
            <button
                type="submit"
                className="btn btn-themes btn-rounded"
                onClick={(e) => {
                    this.viewExportReport(e);
                }}
            >
                Export/Download Report
            </button>
        );
    };

    viewExportReport = (row) => {
        this.setState(
            {
                params: {
                    storeid: this.state.storeid,
                    s_storelabel: this.state.s_storelabel,
                    categories: this.state.categories,
                    status: this.state.status,
                    categorycodes: this.state.categorycodes,
                    productcode: this.state.productcode,
                },
            },
            () => {
                this.setState({ showExportReport: true });
            }
        );
    };

    _hideExport = () => {
        this.props.queryData.reportexport = {};
        this.setState({ showExportReport: false });
    };

    _validateAndSearch = (event) => {
        if (event) event.preventDefault();

        const {
            storeid,
            status,
            categorycodes: categorycode,
            productcode,
        } = this.state;

        this.requestBody = {};
        const queryParams = [];
        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return;
        } else {
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
            queryParams.push(`storeid=${storeid}`);
        }

        if (status !== '') {
            this.requestBody['status'] = status;
            queryParams.push(`status=${status}`);
        }

        if (categorycode !== '') {
            this.requestBody['categorycode'] = categorycode;
            queryParams.push(`categorycode=${categorycode}`);
        }

        if (productcode !== '') {
            this.requestBody['productcode'] = productcode;
            queryParams.push(`productcode=${productcode}`);
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        this.setState({ isSearched: true, pageSize: this.pageSize });
        //query
        this.props.dispatch(productPriceDetailsReport(queryParams.join('&')));
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _clearCriteria = (event) => {
        if (event) event.preventDefault();
        this._clearErrorMessages();
        this.setState({
            showTable: true,
            storeid: '',
            categorycodes: '',
            productcode: '',
        });
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, s_storelabel: p2 }, () => {
            localStorage.setItem('storeid', p1);
            localStorage.setItem('storelabel', p2);
        });
    };

    _renderSearchCiteria = () => {
        const { categoryTree } = this.props.queryData;
        const newArrayOfObj = [categoryTree].map(this.rename);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                            checkStoreAccess={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Select Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label htmlFor="categorycodes" className="floatLeft">
                            Select Product Category
                        </label>
                        <CategoryTree
                            treeData={newArrayOfObj}
                            categoryTree={categoryTree}
                            setCategoryCode={(code, title) => {
                                this.setState({
                                    categorycodes: code,
                                    categories: title,
                                });
                            }}
                            value={this.state.categories}
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label
                            htmlFor="productcode"
                            className="col-form-label "
                        >
                            Product Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="productcode"
                            value={this.state.productcode}
                            onChange={(event) =>
                                this.setState({
                                    productcode: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-1 col-sm-12 noPadding">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                            onClick={(event) => this._validateAndSearch(event)}
                            className="searchBtn"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    pageSizeChangecallback = (pageSize) => {
      this.setState(
          produce(this.state, (draft) => {
              if (draft.pageSize < pageSize) {
                  draft.currPage = 1;
              }
              draft.pageSize = pageSize;
              draft.isSearched = false
          }),
          () => {
              this.callQueryApiForFetch('PAGECLICK');
          }
      );
  };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, storeid, status } = this.state;

        const queryParams = [];

        if (fetchType === 'ONLOAD') {
            queryParams.push(`storeid=${storeid}`);
            queryParams.push(`status=${status}`);
            queryParams.push(`pagesize=${this.pageSize}`);
            queryParams.push(`pageno=${this.currPage}`);

            return queryParams.join('&');
        }

        this.requestBody['pagesize'] = pageSize;
        this.requestBody['pageno'] = currPage;

        for (const [key, value] of Object.entries(this.requestBody)) {
            queryParams.push(`${key}=${value}`);
        }

        console.log('params::', queryParams);
        return queryParams.join('&');
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            productPriceDetailsReport(this.createFetchUrl(fetchType))
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch(triggerPoint);
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
        }
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();

        this.props.dispatch(resetErrorOnly());
    };

    render() {
        const {
            productPriceDetailsReport,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;

        const { errormsg: crudError } = this.props.crudData;

        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        const columns = [
          { field: 'categorycodes', header: 'Category Code' },
          { field: 'productcode', header: 'Product Code' },
          { field: 'title', header: 'Title' },
          { field: 'segments', header: 'Segment (Tier)' },
          { field: 'baseprice', header: 'Base Price' },
          { field: 'taxcategory', header: 'Tax Category' },
          { field: 'validfrom', header: 'Valid From' },
          { field: 'validtill', header: 'Valid Till' }
      ];

        if (this.state.showExportReport) {
            //not used
            return (
                <React.Fragment>
                    <ExportAndDownloadProductPriceDetailsReport
                        params={this.state.params}
                        _hideExport={this._hideExport}
                        reporttype={PRODUCT_PRICE_DETAILS_REPORT}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="form_height">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2 className="page-header">
                                    Product Price Report
                                </h2>
                            </div>
                            <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                                {this._renderExportReportBtn()}
                            </div>
                        </div>
                        <div className="row form-container">
                            <div className="col">
                                <form
                                    className="frmDiv"
                                    onSubmit={this._validateAndSearch}
                                >
                                    <input
                                        type="submit"
                                        className="d-none"
                                    ></input>
                                    <div className="row">
                                        {this._renderSearchCiteria()}
                                    </div>
                                    <div className="title_devide" />
                                    <div className="invalid-feedback">
                                        {errormsg}
                                    </div>

                                    <ReportWarning />
                                    <PrimeTable
                                        tablePageSize={this.state.pageSize}
                                        isSearched={this.state.isSearched}
                                        showFilterSearch={false}
                                        list={productPriceDetailsReport}
                                        columns={columns}
                                        actionColumn={this.actionTemplate}
                                        pageSizeChangecallback={(pageSize) =>
                                            this.pageSizeChangecallback(pageSize)
                                        }
                                        pageChangeCallback={(currPage) => {
                                            this.setState(
                                                {
                                                    currPage: currPage,
                                                },
                                                () => {
                                                    this.callQueryApiForFetch('PAGECLICK');
                                                }
                                            );
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={errormsg}
                            handleClose={(event) =>
                                this.hideErrorAlertModal(event, refresh)
                            }
                            children={
                                <div
                                    style={
                                        errormsg
                                            ? {
                                                  padding: '2em',
                                                  color: 'red',
                                                  fontWeight: '500',
                                              }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {errormsg ? `${errormsg}.` : ''}
                                </div>
                            }
                        />
                    </div>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ProductPriceDetailsReport);
