import { takeLatest, call, put, all } from "redux-saga/effects";
import { crudApi } from "../api/services";
import pick from "lodash.pick";
import * as constants from "../constants/constants";

export function* crudApiSaga(action) {
  yield put({ type: constants.CRUD_API_PENDING });
  try {
    const { body, url, method, success, encode, crudQuery } = action.payload;
    const response = yield call(crudApi, body, url, method, encode, crudQuery);

    if (response.data.status === "206") {
      yield put({
        type: action.payload.failure,
        data: {},
        errormsg: `${response.data.message || response.data.msgcode}`,
        serverError: ""
      });
    } else {
      if (response.data === "") {
        yield put({
          type: action.payload.failure,
          data: {},
          errormsg: `${response.data.message || response.data.msgcode}`,
          loginErrorMsg: `No response`,
          serverError: ""
        });
      } else {
        yield put({ type: success, data: response.data });
      }
    }
  } catch (error) {
    let errorMessage;
    if (error.response) {
      switch (error.response.status) {
        case 401:
          errorMessage = "Invalid Username and/or Password.";
          break;
        case 423:
          errorMessage = "User account is locked.";
          break;
        default:
          errorMessage = "Server exception.";
          break;
      }
    } else {
      errorMessage = "Unknown Server exception.";
    }
    yield put({
      type: action.payload.failure,
      data: {},
      errormsg: errorMessage,
      serverError: ""
    });
  }
}
export function* crudApiSagaForAddon(action) {
  yield put({ type: constants.CRUD_API_PENDING });
  try {
    const { body, url, method, success, encode, crudQuery } = action.payload;
    const response = yield all(
      action.payload.body.map(row => {
        let alteredrow = row;
        if (
          row.additionalfields &&
          Object.keys(row.additionalfields).length === 0
        ) {
          alteredrow = pick(row, [
            "productcode",
            "price",
            "taxvalue",
            "selected",
            "itemidx",
            "storeproductid",
            "unitqty",
            "groupid",
            "id",
            "itemmap",
            "status"
          ]);
        }
        if (row.itemmap && Object.keys(row.itemmap).length === 0) {
          alteredrow = pick(row, [
            "productcode",
            "price",
            "taxvalue",
            "selected",
            "itemidx",
            "storeproductid",
            "unitqty",
            "groupid",
            "id",
            "additionalfields",
            "status"
          ]);
        }
        if (
          row.additionalfields &&
          Object.keys(row.additionalfields).length === 0 &&
          row.itemmap &&
          Object.keys(row.itemmap).length === 0
        ) {
          alteredrow = pick(row, [
            "productcode",
            "price",
            "taxvalue",
            "selected",
            "itemidx",
            "storeproductid",
            "unitqty",
            "groupid",
            "id",
            "status"
          ]);
        }
        if (alteredrow.id) {
          return call(
            crudApi,
            alteredrow,
            "sales/v1/store/product/addon/update",
            "PUT",
            encode,
            crudQuery
          );
        } else {
          return call(
            crudApi,
            alteredrow,
            "sales/v1/store/product/addon/save",
            "POST",
            encode,
            crudQuery
          );
        }
      })
    );
    yield put({ type: success, data: response });
  } catch (error) {
    let errorMessage;
    if (error.response) {
      switch (error.response.status) {
        case 401:
          errorMessage = "Invalid Username and/or Password.";
          break;
        case 423:
          errorMessage = "User account is locked.";
          break;
        default:
          errorMessage = "Server exception.";
          break;
      }
    } else {
      errorMessage = "Unknown Server exception.";
    }
    yield put({
      type: action.payload.failure,
      data: [],
      errormsg: errorMessage,
      serverError: errorMessage
    });
  }
}
export function* crudSaga() {
  yield takeLatest(constants.CRUD_REQUEST, crudApiSaga);
  yield takeLatest(constants.CRUD_REQUEST_FOR_ADDON, crudApiSagaForAddon);

  // tax configuration
  yield takeLatest(constants.TAXCONFIGURATION_CRUD_REQUEST, crudApiSaga);
  yield takeLatest(constants.DELETE_TAXCONFIGURATION_CRUD_REQUEST, crudApiSaga);
  yield takeLatest(constants.UPDATE_TAXCONFIGURATION_CRUD_REQUEST, crudApiSaga);
  // special discount
  yield takeLatest(constants.SPECIALDISCOUNT_CRUD_REQUEST, crudApiSaga);
  yield takeLatest(constants.DELETE_SPECIALDISCOUNT_CRUD_REQUEST, crudApiSaga);
  yield takeLatest(constants.UPDATE_SPECIALDISCOUNT_CRUD_REQUEST, crudApiSaga);
  yield takeLatest(constants.STORE_CREATE_UPDATE_REQUEST, crudApiSaga);
  yield takeLatest(constants.STORE_TERMINAL_CREATE_UPDATE_REQUEST, crudApiSaga);
  yield takeLatest(constants.EXECUTE_DATA_VALIDATION_REPORT, crudApiSaga);
  yield takeLatest(constants.STORE_TAG_BATCH_REQUEST, crudApiSaga);
  yield takeLatest(constants.DEVICE_TAG_BATCH_REQUEST, crudApiSaga);
  yield takeLatest(constants.CUSTOMER_TAG_REQUEST, crudApiSaga);
  yield takeLatest(constants.UPDATE_REPLENISHMENT_REQUEST, crudApiSaga);
}
