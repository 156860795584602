export const renderFilterForDynamicFields = filters => {
  if (!filters) {
    return [];
  }
  const mappedFilters = filters.map(filter => {
    return { id: filter.title, name: filter.title };
  });
  mappedFilters.unshift({ id: "-1", name: "Select" });
  return mappedFilters;
};

export const renderFilterForProdSpec = filters => {
  if (!filters) {
    return [];
  }
  const mappedFilters = filters.map(filter => {
    return { id: filter.categorycode, name: filter.title };
  });
  mappedFilters.unshift({ id: "-1", name: "Select" });
  return mappedFilters;
};
export const renderFilter = filters => {
  if (!filters) {
    return [];
  }
  const mappedFilters = filters.map(filter => {
    return { id: filter.id, name: filter.name };
  });
  mappedFilters.unshift({ id: "-1", name: "Select" });
  return mappedFilters;
};
export const renderConfigInMultiSelect = config => {
  if (!config) {
    return [];
  }

  const mappedFilters = Object.entries(config).map(config => {
    return { id: config[0], name: config[1] };
  });
  return mappedFilters;
};
export const renderConfigInMultiSelectForAddON = config => {
  if (!config) {
    return [];
  }

  const mappedFilters = config.map(config => {
    return { value: config.id, label: config.productcode };
  });

  return mappedFilters;
};
export const renderBeaconOrGeofenceList = list => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map(listItem => {
    return { id: listItem.articlecode, name: listItem.title };
  });
  return mappedFilters;
};

export const formatDate = inpDate => {
  let convertedDate = new Date(inpDate);
  let month = convertedDate.getMonth() + 1;
  let day = convertedDate.getDate();
  let year = convertedDate.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  let shortDate = `${day}-${month}-${year}`;

  return shortDate;
};



export const toDate = dateStr => {
  // var splitBYSpace = dateStr.split(" ");
  // var parts = splitBYSpace[0].split("-");
  // let month = parts[1] - 1;
  // return new Date(parts[2], month, parts[0]);
  return dateStr.toLocaleDateString("en-GB").replace(/\//g,"-");
};

export const dateRange = datestr => {
  let result=[];
  if (datestr.length > 0) {
    for (var i = 0; i < datestr.length; i++) {
      let dateformat = datestr[i].toLocaleDateString("en-GB").replace(/\//g, "-");
      result.push(dateformat);
    }
    return result;
  }
}

export const convertDate = dateStr => {
  var splitBYSpace = dateStr.split(" ");
  var parts = splitBYSpace[0].split("-");
  let month = parts[1] - 1;
  return new Date(parts[2], month, parts[0]);
}

export const renderTotalRec = memObj => {
  return memObj.length > 0 ? memObj.map(item => item.totalrec).reduce((prev, next) => prev + next) : 0
}

export const renderTotalAmt = (obj) => {
  return  obj.length > 0 ? obj.map(item => item.totalamount).reduce((prev, next) => prev + next) : 0;
}

export const generateColor = () =>{
  var color = ["#CA7F10","#A51423","#056E89", "#4CAF50", "#2196F3", "#38495B"];
  return color[Math.floor(Math.random() * color.length)];;
}

export const sortandrender = (arrobj) =>{
  const sorted = arrobj.sort((a,b)=>b.salesamount-a.salesamount);
  const topitems = sorted.slice(0,10);
  return topitems;
}

export const getByMonth = (data) => {
  if (data.length > 0) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getdata = data.map(e => ({ ...e, month: months[e.month - 1] }));
    return getdata;
  }
  else {
    return [];
  }
}

export const objtoarr = ob => {
  const { field, value, id, ...fields } = ob;
  const rest = Object.entries(fields)
  .map(([field, value], id) => ({ field, value, id }));
  const result = [...rest];
  return result;
}

export const objtoarr1 = ob => {
  if(typeof ob !== "undefined" && Object.keys(ob).length !== 0 && JSON.stringify(ob) !== "{}" && ob !== null){
    const { name, value, id, ...fields } = ob;
    const rest = Object.entries(fields)
    .map(([name, value], id) => ({ name, value, id }));
    const result = [...rest];
    return result;
  }
  else{
    var result =[{name: "",value:"",id:0}];
    return result;
  }

}

export const modifyObject = obj =>
  Object.entries(obj).map(([field, value], id) =>
    Array.isArray(value) ? { id, field, value, image: /^\/media/.test(value) ? value : "" }
      : { field, value, id }
  );

export const stringtoarray = (obj) => {
  var result = obj.map(o => ({
    ...o,
    value: Array.isArray(o.value) ? o.value.join() : o.value.split(',')
  }));
  return result;
}

export const imagetoarray = ob => {
  const { uri, title, alttext, width, height, tag, id, ...fields } = ob;
  const rest = Object.entries(fields)
  .map(([uri, title, alttext, width, height, tag], id) => ({ uri, title, alttext, width, height, tag, id }));
  const result = [...rest];
  return result;
}

export const containsProperty = (objArr, string) =>
  objArr.findIndex(
  // Is the string contained in the object keys?
  obj => Object.keys(obj).includes(string)
) !== -1

export const isEmpty = inputObject => {
  return Object.keys(inputObject).length === 0;
};

export const renderOptionsFilter = list => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map(listItem => {
    return { id: listItem.value, name: listItem.title };
  });
  mappedFilters.unshift({ id: "-1", name: "Select" });
  return mappedFilters;
};

export const renderTimeOptionsFilter = list => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map(listItem => {
    return { id: listItem.value, name: listItem.title };
  });

  mappedFilters.unshift({ id: "", name: "--Select--" });

  return mappedFilters;
};

export const renderCouponOptionsFilter = list => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map(listItem => {
    return { id: listItem.value, name: listItem.title };
  });
  mappedFilters.unshift({ id: "", name: "Select" });
  return mappedFilters;
};

export const renderArticleOptionsFilter = list => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map(listItem => {
    return { id: listItem.articletype, name: listItem.articletype };
  });
  mappedFilters.unshift({ id: "-1", name: "Select Article" });
  return mappedFilters;
};


export const renderAddOnItemMapping = list => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map(listItem => {
    return { id: listItem.value, name: listItem.title };
  });
  mappedFilters.unshift({ id: "-1", name: "Select" });
  return mappedFilters;
};
export const renderTaxValues = list => {
  if (!list) {
    return [];
  }
  const mappedFilters = list.map(listItem => {
    return { id: listItem.categorycode, name: listItem.title };
  });
  mappedFilters.unshift({ id: "-1", name: "Select Tax" });
  return mappedFilters;
};

export const formatCountryArray = (list, filterparam) => {
  if (!list) {
    return [];
  }

  const mappedFilters = list.map((listItem, index) => {
    return { key: index, id: listItem[filterparam], name: listItem[filterparam] };
  });
  var uniquelist = mappedFilters.reduce((unique, o) => {
    if(!unique.some(obj => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  },[]);
  uniquelist.unshift({ id: "", name: "Select" });
  return uniquelist;
};

export const formatCityArray = (list, filterparam, country) => {
  if (!list) {
    return [];
  }
  const listbycountry = list.filter(e=>e.country === country);
  const mappedFilters = listbycountry.map((listItem, index) => {
    return { key: index, id: listItem[filterparam], name: listItem[filterparam] };
  });
  var uniquelist = mappedFilters.reduce((unique, o) => {
    if(!unique.some(obj => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  },[]);
  uniquelist.unshift({ id: "", name: "Select" });
  return uniquelist;
};

export const formatStoreArray = list => {
  if (!list) {
    return [];
  }
  const mappedFilters = list.map((listItem,index) => {
    return { key: index, id: listItem.storeid, name: listItem.title };
  });
  mappedFilters.unshift({ id: "", name: "Select" });
  return mappedFilters;
};

export const formatStoreGroupArray = (list, filterparam, country, city) => {
  if (!list) {
    return [];
  }
  const listbycountry = list.filter(e=>e.country === country);
  const mappedFilters = listbycountry.map((listItem, index) => {
    return { key: index, id: listItem[filterparam], name: listItem[filterparam] };
  });
  var uniquelist = mappedFilters.reduce((unique, o) => {
    if(!unique.some(obj => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  },[]);
  uniquelist.unshift({ id: "", name: "Select" });
  return uniquelist;
};

export const formatLanguageArray = (list, showSelect = false) => {
  if (!list) {
    return [];
  }
  var mappedFilters = Object.entries(list).map(list => {
    return { id: list[0], name: list[1] };
  });

  if (showSelect) {
    mappedFilters.unshift({ id: "", name: "Select" });
  }
  
  return mappedFilters;
};

export const formatLanguageArrayWithName = list => {
  if (!list) {
    return [];
  }
  const mappedFilters = Object.entries(list).map(list => {
    return { id: list[0], name: list[1] + ' (' + list[0]  + ')'};
  });

  return mappedFilters;
};

export const removeDeletedChildren = (obj) => {
  if (obj.length > 0) {
    var result = []
    obj.forEach(e => {
      if(e.status !== "Deleted") {
        if(e.children && e.children.length > 0) { e.children = removeDeletedChildren(e.children); }

        result.push(e)
      }
    });
    return result;
  }
}


export const treemodalObj= (obj)=>{
  if(!obj.length) return;

    obj.forEach(i => {
        const title = i.title;
        const child = i.children;
        i.label = title;
        i.value = title;
        i.checked = false;
        if(child) {
            treemodalObj(child);
        } else {
            i.title = title;
        }
    });
    return obj;
}

export const getPropertyValues = (inputArr, matchList, outputType, matchProp, childrenProp, checkedProp) => {
  //default params, or they can be overwritten:
  matchProp = matchProp || 'id';
  childrenProp = childrenProp || 'children';
  checkedProp = checkedProp || 'checked';

  const outputTitle = [];
  innerRecursive(inputArr, matchList, outputTitle);

  function innerRecursive(currArr, list, output){
    for (var item of currArr){
      if ( list.includes(item[matchProp]) ){
        output.push(item[outputType]);
      }
      else {
        //it didn't match but it has children, so search them too:
        if (item[childrenProp]) {
         innerRecursive(item[childrenProp], list, output);
        }
      }
    }
  }

  return outputTitle;
}

export const checkPropertyForMatch = (inputArr, matchList, matchProp, childrenProp, checkedProp) => {
   //default params, or they can be overwritten:
   matchProp = matchProp || 'id';
   childrenProp = childrenProp || 'children';
   checkedProp = checkedProp || 'checked';

   innerRecursive(inputArr, matchList);

   //recursively search the nested object:
   function innerRecursive(currArr, list){
     for (var item of currArr){
       if ( list.includes(item[matchProp]) ){
         item[checkedProp] = true;
         if (item[childrenProp]){
           //this parent matched, so mark all children as marked too:
           markAllChildrenChecked(item[childrenProp]);
         }
       }
       else {
        item[checkedProp] = false;
         //it didn't match but it has children, so search them too:
         if (item[childrenProp]) {
          innerRecursive(item[childrenProp], list);
         }
       }
     }
   }

   //this recursively marks all children as checked = true:
   function markAllChildrenChecked(currArr){
     for (var item of currArr){
       item[checkedProp] = true;
       if (item[childrenProp]){
         markAllChildrenChecked(item[childrenProp]);
       }
     }
   }
   return inputArr;
}

export const  uniqueArrayObject = (obj) => {
  return obj.filter((v,i,a)=>a.findIndex(t=>(t.title === v.title && t.id===v.id))===i);
}


export const sortArray = (obj) => {
  return obj.sort((a, b) => a.sortorder - b.sortorder);
}

export const sortArticle = (obj) => {
  return obj.sort((a, b) => {
    const pg1 = a.page
    const pg2 = b.page
    const so1 = a.sortorder
    const so2 = b.sortorder


    return pg1?((pg1.localeCompare(pg2)) || (so1 > so2 ? 1 : -1)):1
  });
}

export const sortByDate = (obj) => {
  return obj.sort((a,b)=>{
    a = a.lastupdated.split('-').reverse().join('');
    b = b.lastupdated.split('-').reverse().join('');
    return b-a;
  })
}

export const sortByGroup = (obj) => {
  if(obj.length > 0){
    var checkkey = obj.some(e => e.hasOwnProperty('propgroup'));
    var result =  obj.sort((a,b)=>{
      if(checkkey){
       return a.propgroup.localeCompare(b.propgroup);
      }
    });
  }
  return result;
}

export const convertStrNum = (obj) => {
  const res = {}
  for (const key in obj) {
    res[key] = {};
    for (const prop in obj[key]) {
      const parsed = parseInt(obj[key][prop], 10);
      res[key][prop] = isNaN(parsed) ? obj[key][prop] : parsed;
    }
  }

  Object.keys(res).forEach((key) => (
    res[key] == null ||
    typeof res[key] == "undefined" ||
    JSON.stringify(res[key]) === "{}"
  ) && delete res[key]);

  return Object.values(res);
}

export const catTypeOptions = [
  { title: "Static", value: "static" },
  { title: "Dynamic", value: "dynamic" },
  { title: "Static Member", value: "static-member"},
  { title: "Dynamic Member", value: "dynamic-member"}
];

export const sortTableDateTime = (a, b, order, dataField) => {
  let dateTimeA = a.split(' ')
  let dateTimeB = b.split(' ')
  let dateA = dateTimeA[0]
  let dateB = dateTimeB[0]
  let timeA = '0:00'
  let timeB = '0:00'
  if(dateTimeA.length > 1) {
    timeA = dateTimeA[1]
    timeB = dateTimeB[1]

  }
  let a1 = dateA.split('-').reverse().join('');
  let b1 = dateB.split('-').reverse().join('');
  if(order==='asc'){
    return (a1-b1 || timeB.localeCompare(timeA))
  }
  else if (order === 'desc') {
    return (b1-a1 || timeA.localeCompare(timeB))
  }
}

export const userAccess = () => {
  const accessAllowed = localStorage.getItem("user_access");
  const allAccesses = JSON.parse(accessAllowed).reduce(
    (cache, access) => {
      const module = access.module
      if (module in cache) {
        return { ...cache, [module]: cache[module].concat(access) }
      }
      return { ...cache, [module]: [access] }
    }, {})

    return allAccesses
}

export const permissions = (module, group) => {
  const allRoles = userAccess()[module]
  if (typeof allRoles === 'undefined') {
    return []
  }

  let accesses = []
  accesses = allRoles.filter((t, i) => {
      return t.accessgroup === group
    }
  )

  if (accesses.length <= 0) { return [] }
  return accesses.flatMap((a) => (a.permission))
}

export const canWrite = (allPermissions) => {
  return allPermissions.includes('Write')
}

export const canDelete = (allPermissions) => {
  return allPermissions.includes('Delete')
}

export const canView = (allPermissions) => {
  return allPermissions.includes('Read')
}

export const canPublish = (allPermissions) => {
  return allPermissions.includes('Publish')
}

export const canApprove = (allPermissions) => {
  return allPermissions.includes('Approve')
}

export const canUnPublish = (allPermissions) => {
  return allPermissions.includes('UnPublish')
}

const modules = [
  { module: "CMS", accessgroup: "DE_CMS", desc: "Content Management" },
  { module: "CAMP", accessgroup: "DE_CAMP", desc: "Campaign Management" },
  { module: "CRM", accessgroup: "DE_CRM", desc: "Customer Management" },
  { module: "COMM", accessgroup: "DE_COMM", desc: "Communication" },
  { module: "SALES", accessgroup: "DE_SALES", desc: "Sales Service" },
  { module: "SOP", accessgroup: "DE_SOP", desc: "Sales Operation" },
  { module: "ACCESS", accessgroup: "DE_ACCESS", desc: "Access Management" },
  { module: "MEDIA", accessgroup: "DE_MEDIA", desc: "Media Managment" },
  { module: "SALESPRM", accessgroup: "DE_SALESPRM", desc: "Promotion Management" },
  //{ module: "SALESPRM", accessgroup: "DE_SALESPRM", desc: "Sales Promotions" },
  { module: "POSNSALES", accessgroup: "POS_NON_SALES", desc: "Non Sales Functions" },
  { module: "POSSALES", accessgroup: "POS_SALES", desc: "Sales Functions" },
  { module: "STOREMGRUI", accessgroup: "STORE_MANAGER_UI", desc: "Store Manager UI" },
  { module: "STOREMGMT", accessgroup: "DE_STOREMGMT", desc: "Store Management" },
  { module: "LOGS", accessgroup: "DE_LOGS", desc: "Logs" },
  { module: "REPORTS", accessgroup: "DE_REPORTS", desc: "Reports" },
  { module: "EXTENSIONUI", accessgroup: "DE_EXTENSION_UI", desc: "UI" },
  { module: "EXTENSIONPOS", accessgroup: "DE_EXTENSION_POS", desc: "Mobile Pos" },
]

const accesstypes = [
  {accesstype: "POS", desc: "POS"},
  {accesstype: "UI", desc: "UI"},
  {accesstype: "EXT", desc: "EXTENSION"}
]

export const getModuleDesc = (module) => {
  const filtered = modules.filter((e) => (
    e.module === module
  ))
  return (filtered !== 'undefined' && filtered.length > 0) ? filtered[0].desc : "UNDEFINED"
}

export const getAccessTypeDesc = (accessType) => {
  const filtered = accesstypes.filter((e) => (
    e.accesstype === accessType
  ))
  return (filtered !== 'undefined' && filtered.length > 0) ? filtered[0].desc : "UNDEFINED"
}

export const filterFormatter = (cell, row, rowIndex, formatExtraData
  , excludedfields = ['pagesize', 'pageno', 'filepath']) => {
  if (row.additionalfields) {
    var filters = []
    for (const [key, value] of Object.entries(row.additionalfields)) {
    //if (key === 'pagesize' || key === 'pageno' || key === 'filepath') {
      if (excludedfields.includes(key)) {
        continue
    }
    filters.push(`${key}=${value}`)
    // console.log('filters::', filters)
    }
    return filters.join(' | ')
  }
  return ''
};

export const removeUndefinedKeys = (object) => {
  const newObj = {};
  Object.entries(object).forEach(([key, value]) => {
    if (value !== undefined) {
      newObj[key] = value;
    }
  });
  return newObj;
};

export const statusConfigOptions = [
  { name: 'New', code: 'New' },
  { name: 'Active', code: 'Active' },
  { name: 'Inactive', code: 'Inactive' },
  { name: 'Updated', code: 'Updated' },
  { name: 'Deleted', code: 'Deleted' },
];

export const statusSelectConfigOptions = [
  { name: 'Select', code: '' },
  { name: 'New', code: 'New' },
  { name: 'Active', code: 'Active' },
  { name: 'Inactive', code: 'Inactive' },
  { name: 'Deleted', code: 'Deleted' },
];

export const padNumber = (number, length) => {
  let numberString = String(number);
  return numberString.padStart(length, '0');
}

