export const API_PENDING = "API_PENDING";
export const CRUD_API_PENDING = "CRUD_API_PENDING";
// Login Constants
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const PIN_REQUEST = "PIN_REQUEST";
export const PIN_REQUEST_SUCCESS = "PIN_REQUEST_SUCCESS";
export const PIN_REQUEST_FAILURE = "PIN_REQUEST_FAILURE";
export const ENTER_PIN = "ENTER_PIN";
export const ENTER_PIN_SUCCESS = "ENTER_PIN_SUCCESS";
export const ENTER_PIN_FAILURE = "ENTER_PIN_FAILURE";

//User Constants
export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAILURE = "USER_INFO_FAILURE";
export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";
export const USER_ROLES_REQUEST = "USER_ROLES_REQUEST";
export const USER_ROLES_SUCCESS = "USER_ROLES_SUCCESS";
export const USER_ROLES_FAILURE = "USER_ROLES_FAILURE";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";
export const QUERY_USER_RESET = "QUERY_USER_RESET";
export const QUERY_TERMINAL_BIRINFO_RESET = "QUERY_TERMINAL_BIRINFO_RESET";

// Crud Api
export const CRUD_REQUEST = "CRUD_REQUEST";
export const CRUD_RESET = "CRUD_RESET";

//STORE HOURS
export const STORE_HOURS = "STORE_HOURS";

//STORE PRODUCT CODE
export const STORE_PRODUCTCODE ="STORE_PRODUCTCODE";
export const STORE_PRODUCTCODE_SUCCESS = "STORE_PRODUCTCODE_SUCCESS";
export const STORE_PRODUCTCODE_FAILURE = "STORE_PRODCUTCODE_FAILURE";

// MEDIA FOLDER
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";
export const GENERATE_THUMBNAIL_SUCCESS = "GENERATE_THUMBNAIL_SUCCESS";
export const GENERATE_THUMBNAIL_FAILURE = "GENERATE_THUMBNAIL_FAILURE";

export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const REMOVE_FILE_SUCCESS = "REMOVE_FILE_SUCCESS";
export const REMOVE_FILE_FAILURE = "REMOVE_FILE_FAILURE";

export const MOVE_FILE_SUCCESS = "MOVE_FILE_SUCCESS";
export const MOVE_FILE_FAILURE = "MOVE_FILE_FAILURE";
export const COPY_FILE_SUCCESS = "COPY_FILE_SUCCESS";
export const COPY_FILE_FAILURE = "COPY_FILE_FAILURE";
// Media Management
export const GET_MEDIA_LIST = "GET_MEDIA_LIST";
export const GET_MEDIA_LIST_SUCCESS = "GET_MEDIA_LIST_SUCCESS";
export const GET_MEDIA_LIST_FAILURE = "GET_MEDIA_LIST_FAILURE";

export const GET_MEDIA_FOLDERS = "GET_MEDIA_FOLDERS";
export const GET_MEDIA_FOLDERS_SUCCESS = "GET_FOLDERS_LIST_SUCCESS";
export const GET_MEDIA_FOLDERS_FAILURE = "GET_FOLDERS_LIST_FAILURE";
// Article Form
export const FETCH_ARTCILE_FORM = "FETCH_ARTCILE_FORM";
export const FETCH_ARTCILE_FORM_SUCCESS = "FETCH_ARTCILE_FORM_SUCCESS";
export const FETCH_ARTCILE_FORM_FAILURE = "FETCH_ARTCILE_FORM_FAILURE";

// Article Workflow

export const CRUD_REQUEST_SUCCESS = "CRUD_REQUEST_SUCCESS";
export const CRUD_REQUEST_FAILURE = "CRUD_REQUEST_FAILURE";

export const SEARCH_ARTICLE_INFO = "SEARCH_ARTICLE_INFO";
export const SEARCH_ARTICLE_INFO_SUCCESS = "SEARCH_ARTICLE_INFO_SUCCESS";
export const SEARCH_ARTICLE_INFO_FAILURE = "SEARCH_ARTICLE_INFO_FAILURE";

export const UPDATE_DEFAULT_CONFIG = "UPDATE_DEFAULT_CONFIG";
export const UPDATE_ARTICLE_MODE = "UPDATE_ARTICLE_MODE";
export const UPDATE_ARTICLE_MODE_TYPE = "UPDATE_ARTICLE_MODE_TYPE";
export const CONTENT_CHANGE = "CONTENT_CHANGE";

// Article Form Options

export const ARTICLE_OPTIONS= "ARTICLE_OPTIONS";
export const ARTICLE_OPTIONS_SUCCESS = "ARTICLE_OPTIONS_SUCCESS";
export const ARTICLE_OPTIONS_FAILURE = "ARTICLE_OPTIONS_FAILURE";
export const PRODUCT_ARTICLE_RESET = "PRODUCT_ARTICLE_RESET";

// CONFIG SEVICE

export const GET_STATUS_CONFIG_BY_TITLE = "GET_STATUS_CONFIG_BY_TITLE";
export const GET_LANGUAGE_CONFIG_BY_TITLE = "GET_LANGUAGE_CONFIG_BY_TITLE";
export const GET_COUNTRY_CONFIG_BY_TITLE = "GET_COUNTRY_CONFIG_BY_TITLE";
export const GET_BEACON_BRAND_BY_TITLE = "GET_BEACON_BRAND_BY_TITLE";
export const STORE_GROUP_BY_TITLE = "STORE_GROUP_BY_TITLE";
export const MEMBER_PROPS_BY_TITLE = "MEMBER_PROPS_BY_TITLE";
export const MEMBER_TXN_BY_TITLE = "MEMBER_TXN_BY_TITLE";
export const FREQ_BY_TITLE = "FREQ_BY_TITLE";
export const OPERATORS_BY_TITLE = "OPERATORS_BY_TITLE";
export const COUPON_VALIDITY_BY_TITLE = "COUPON_VALIDITY_BY_TITLE";
export const GET_MOBILE_MESSAGE_TYPE_BY_TITLE =
  "GET_MOBILE_MESSAGE_TYPE_BY_TITLE";

export const STATUS_CONFIG_BY_TITLE_SUCCESS = "STATUS_CONFIG_BY_TITLE_SUCCESS";
export const STATUS_CONFIG_BY_TITLE_FAILURE = "STATUS_CONFIG_BY_TITLE_FAILURE";
export const COUNTRY_CONFIG_BY_TITLE_SUCCESS =
  "COUNTRY_CONFIG_BY_TITLE_SUCCESS";
export const COUNTRY_CONFIG_BY_TITLE_FAILURE =
  "COUNTRY_CONFIG_BY_TITLE_FAILURE";
export const LANGUAGE_CONFIG_BY_TITLE_SUCCESS =
  "LANGUAGE_CONFIG_BY_TITLE_SUCCESS";
export const LANGUAGE_CONFIG_BY_TITLE_FAILURE =
  "LANGUAGE_CONFIG_BY_TITLE_FAILURE";
export const GET_BEACON_BRAND_BY_TITLE_SUCCESS =
  "GET_BEACON_BRAND_BY_TITLE_SUCCESS";
export const GET_BEACON_BRAND_BY_TITLE_FAILURE =
  "GET_BEACON_BRAND_BY_TITLE_FAILURE";
export const GET_MOBILE_MESSAGE_TYPE_BY_TITLE_SUCCESS =
  "GET_MOBILE_MESSAGE_TYPE_BY_TITLE_SUCCESS";
export const GET_MOBILE_MESSAGE_TYPE_BY_TITLE_FAILURE =
  "GET_MOBILE_MESSAGE_TYPE_BY_TITLE_FAILURE";

export const STORE_GROUP_BY_TITLE_SUCCESS = "STORE_GROUP_BY_TITLE_SUCCESS";
export const STORE_GROUP_BY_TITLE_FAILURE = "STORE_GROUP_BY_TITLE_FAILURE";
export const OPERATORS_BY_TITLE_SUCCESS = "OPERATORS_BY_TITLE_SUCCESS";
export const OPERATORS_BY_TITLE_FAILURE = "OPERATORS_BY_TITLE_FAILURE";
export const MEMBER_PROPS_BY_TITLE_SUCCESS = "MEMBER_PROPS_BY_TITLE_SUCCESS";
export const MEMBER_PROPS_BY_TITLE_FAILURE = "MEMBER_PROPS_BY_TITLE_FAILURE";
export const MEMBER_TXN_BY_TITLE_SUCCESS = "MEMBER_TXN_BY_TITLE_SUCCESS";
export const MEMBER_TXN_BY_TITLE_FAILURE = "MEMBER_TXN_BY_TITLE_FAILURE";
export const FREQ_BY_TITLE_SUCCESS = "FREQ_BY_TITLE_SUCCESS";
export const FREQ_BY_TITLE_FAILURE = "FREQ_BY_TITLE_FAILURE";
export const COUPON_VALIDITY_BY_TITLE_SUCCESS =
  "COUPON_VALIDITY_BY_TITLE_SUCCESS";
export const COUPON_VALIDITY_BY_TITLE_FAILURE =
  "COUPON_VALIDITY_BY_TITLE_FAILURE";
// CRM MODULE
export const SEARCH_MEMBER_PROFILE = "SEARCH_MEMBER_PROFILE";
export const SEARCH_MEMBER_PROFILE_SUCCESS = "SEARCH_MEMBER_PROFILE_SUCCESS";
export const SEARCH_MEMBER_PROFILE_FAILURE = "SEARCH_MEMBER_PROFILE_FAILURE";

export const SEARCH_MEMBER_TAG= "SEARCH_MEMBER_TAG";
export const SEARCH_MEMBER_TAG_SUCCESS = "SEARCH_MEMBER_TAG_SUCCESS";
export const SEARCH_MEMBER_TAG_FAILURE = "SEARCH_MEMBER_TAG_FAILURE";

// Get list by  Category code
export const PROD_CAT_SEARCH = "PROD_CAT_SEARCH";
export const PROD_CAT_SEARCH_SUCCESS = "PROD_CAT_SEARCH_SUCCESS";
export const PROD_CAT_SEARCH_FAILURE = "PROD_CAT_SEARCH_FAILURE";

// Sales
export const SEARCH_DATA = "SEARCH_DATA";
export const SALES_SEARCH_SUCCESS = "SALES_SEARCH_SUCCESS";
export const SALES_SEARCH_FAILURE = "SALES_SEARCH_FAILURE";
export const FETCH_PROD_SPEC_SUCCESS = "FETCH_PROD_SPEC_SUCCESS";
export const FETCH_PROD_SPEC_FAILURE = "FETCH_PROD_SPEC_FAILURE";
export const FETCH_PRODUCTS_CATALOG_SUCCESS = "FETCH_PRODUCTS_CATALOG_SUCCESS";
export const FETCH_PRODUCTS_CATALOG_FAILURE = "FETCH_PRODUCTS_CATALOG_FAILURE";
// System Config
export const FETCH_ALL_DEFAULT_CONFIG_SUCCESS =
  "FETCH_ALL_DEFAULT_CONFIG_SUCCESS";
export const FETCH_ALL_DEFAULT_CONFIG_FAILURE =
  "FETCH_ALL_DEFAULT_CONFIG_FAILURE";

// Category Tree
export const CATEGORY_TREE = "CATEGORY_TREE";
export const CATEGORY_TREE_SUCCESS = "CATEGORY_TREE_SUCCESS";
export const CATEGORY_TREE_FAILURE = "CATEGORY_TREE_FAILURE";

export const CATEGORYTREE_RECOMMENDCODE_SUCCESS = "CATEGORYTREE_RECOMMENDCODE_SUCCESS";
export const CATEGORYTREE_RECOMMENDCODE_FAILURE = "CATEGORYTREE_RECOMMENDCODE_FAILURE";

export const CATEGORYTREE_SIMILARCODE_SUCCESS = "CATEGORYTREE_SIMILARCODE_SUCCESS";
export const CATEGORYTREE_SIMILARCODE_FAILURE = "CATEGORYTREE_SIMILARCODE_FAILURE";

// Query store product mapping
export const STORE_CAT_SEARCH = "STORE_CAT_SEARCH";
export const STORE_CAT_SEARCH_SUCCESS = "STORE_CAT_SEARCH_SUCCESS";
export const STORE_CAT_SEARCH_FAILURE = "STORE_CAT_SEARCH_FAILURE";

export const STORE_PRODUCT_SEARCH = "STORE_PRODUCT_SEARCH";
export const STORE_PRODUCT_SEARCH_SUCCESS = "STORE_PRODUCT_SEARCH_SUCCESS";
export const STORE_PRODUCT_SEARCH_FAILURE = "STORE_PRODUCT_SEARCH_FAILURE";

export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE";
// Group Fetch
export const PRODUCT_GROUP = "PRODUCT_GROUP";
export const PRODUCT_GROUP_SUCCESS = "PRODUCT_GROUP_SUCCESS";
export const PRODUCT_GROUP_FAILURE = "PRODUCT_GROUP_FAILURE";

export const PRODUCT_SUGG = "PRODUCT_SUGG";
export const PRODUCT_SUGG_SUCCESS = "PRODUCT_SUGG_SUCCESS";
export const PRODUCT_SUGG_FAILURE = "PRODUCT_SUGG_FAILURE";

export const SEARCH_BY_STOREPRODID = "SEARCH_BY_STOREPRODID";
export const SEARCH_BY_STOREPRODID_SUCCESS = "SEARCH_BY_STOREPRODID_SUCCESS";
export const SEARCH_BY_STOREPRODID_FAILURE = "SEARCH_BY_STOREPRODID_FAILURE";

//sales summary
export const SALES_SUMMARY_DATE = "SALES_SUMMARY_DATE";
export const SALES_SUMMARY_DATE_SUCCESS = "SALES_SUMMARY_DATE_SUCCESS";
export const SALES_SUMMARY_DATE_FAILURE = "SALES_SUMMARY_DATE_FAILURE";

export const SALES_SUMMARY_HOUR = "SALES_SUMMARY_HOUR";
export const SALES_SUMMARY_HOUR_SUCCESS = "SALES_SUMMARY_HOUR_SUCCESS";
export const SALES_SUMMARY_HOUR_FAILURE = "SALES_SUMMARY_HOUR_FAILURE";

export const SALES_SUMMARY_MONTH = "SALES_SUMMARY_MONTH";
export const SALES_SUMMARY_MONTH_SUCCESS = "SALES_SUMMARY_MONTH_SUCCESS";
export const SALES_SUMMARY_MONTH_FAILURE = "SALES_SUMMARY_MONTH_FAILURE";

export const SALES_SUMMARY_YEAR = "SALES_SUMMARY_YEAR";
export const SALES_SUMMARY_YEAR_SUCCESS = "SALES_SUMMARY_YEAR_SUCCESS";
export const SALES_SUMMARY_YEAR_FAILURE = "SALES_SUMMARY_YEAR_FAILURE";

//product sales summary
export const PRODUCT_SALES_SUMMARY = "PRODUCT_SALES_SUMMARY";
export const PRODUCT_SALES_SUMMARY_SUCCESS = "PRODUCT_SALES_SUMMARY_SUCCESS";
export const PRODUCT_SALES_SUMMARY_FAILURE = "PRODUCT_SALES_SUMMARY_FAILURE";

//member sales summary
export const MEMBER_SALES_SUMMARY = "MEMBER_SALES_SUMMARY";
export const MEMBER_SALES_SUMMARY_SUCCESS = "MEMBER_SALES_SUMMARY_SUCCESS";
export const MEMBER_SALES_SUMMARY_FAILURE = "MEMBER_SALES_SUMMARY_FAILURE";

//member report
export const MEMBER_DEVICE_MODEL = "MEMBER_DEVICE_MODEL";
export const MEMBER_DEVICE_MODEL_SUCCESS = "MEMBER_DEVICE_MODEL_SUCCESS";
export const MEMBER_DEVICE_MODEL_FAILURE = "MEMBER_DEVICE_MODEL_FAILURE";

export const GUEST_DEVICE_MODEL = "GUEST_DEVICE_MODEL";
export const GUEST_DEVICE_MODEL_SUCCESS = "GUEST_DEVICE_MODEL_SUCCESS";
export const GUEST_DEVICE_MODEL_FAILURE = "GUEST_DEVICE_MODEL_FAILURE";

export const MEMBER_DEVICE_TYPE = "MEMBER_DEVICE_TYPE";
export const MEMBER_DEVICE_TYPE_SUCCESS = "MEMBER_DEVICE_TYPE_SUCCESS";
export const MEMBER_DEVICE_TYPE_FAILURE = "MEMBER_DEVICE_TYPE_FAILURE";

export const GUEST_DEVICE_TYPE = "GUEST_DEVICE_TYPE";
export const GUEST_DEVICE_TYPE_SUCCESS = "GUEST_DEVICE_TYPE_SUCCESS";
export const GUEST_DEVICE_TYPE_FAILURE = "GUEST_DEVICE_TYPE_FAILURE";

export const MEMBER_DEVICE_TYPE_DATE = "MEMBER_DEVICE_TYPE_DATE";
export const MEMBER_DEVICE_TYPE_DATE_SUCCESS = "MEMBER_DEVICE_TYPE_DATE_SUCCESS";
export const MEMBER_DEVICE_TYPE_DATE_FAILURE = "MEMBER_DEVICE_TYPE_DATE_FAILURE";

export const MEMBER_DEVICE_INFO = "MEMBER_DEVICE_INFO";
export const MEMBER_DEVICE_INFO_SUCCESS = "MEMBER_DEVICE_INFO_SUCCESS";
export const MEMBER_DEVICE_INFO_FAILURE = "MEMBER_DEVICE_INFO_FAILURE";

export const GUEST_DEVICE_TYPE_DATE = "GUEST_DEVICE_TYPE_DATE";
export const GUEST_DEVICE_TYPE_DATE_SUCCESS = "GUEST_DEVICE_TYPE_DATE_SUCCESS";
export const GUEST_DEVICE_TYPE_DATE_FAILURE = "GUEST_DEVICE_TYPE_DATE_FAILURE";

//member device type by status:
export const ACTIVE_MEMBER_DEVICE_TYPE = "ACTIVE_MEMBER_DEVICE_TYPE";
export const ACTIVE_MEMBER_DEVICE_TYPE_SUCCESS = "ACTIVE_MEMBER_DEVICE_TYPE_SUCCESS";
export const ACTIVE_MEMBER_DEVICE_TYPE_FAILURE = "ACTIVE_MEMBER_DEVICE_TYPE_FAILURE";

export const INACTIVE_MEMBER_DEVICE_TYPE = "INACTIVE_MEMBER_DEVICE_TYPE";
export const INACTIVE_MEMBER_DEVICE_TYPE_SUCCESS = "INACTIVE_MEMBER_DEVICE_TYPE_SUCCESS";
export const INACTIVE_MEMBER_DEVICE_TYPE_FAILURE = "INACTIVE_MEMBER_DEVICE_TYPE_FAILURE";

export const NEW_MEMBER_DEVICE_TYPE = "NEW_MEMBER_DEVICE_TYPE";
export const NEW_MEMBER_DEVICE_TYPE_SUCCESS = "NEW_MEMBER_DEVICE_TYPE_SUCCESS";
export const NEW_MEMBER_DEVICE_TYPE_FAILURE = "NEW_MEMBER_DEVICE_TYPE_FAILURE";


// Cart Operations
export const CART_SEARCH = "CART_SEARCH";
export const CART_SEARCH_SUCCESS = "CART_SEARCH_SUCCESS";
export const CART_SEARCH_FAILURE = "CART_SEARCH_FAILURE";

export const GET_BY_ORDER_SESSION_ID = "GET_BY_ORDER_SESSION_ID";
export const GET_BY_ORDER_SESSION_ID_SUCCESS =
  "GET_BY_ORDER_SESSION_ID_SUCCESS";
export const GET_BY_ORDER_SESSION_ID_FAILURE =
  "GET_BY_ORDER_SESSION_ID_FAILURE";

export const GET_BY_ORDER_ID = "GET_BY_ORDER_ID";
export const GET_BY_ORDER_ID_SUCCESS = "GET_BY_ORDER_ID_SUCCESS";
export const GET_BY_ORDER_ID_FAILURE = "GET_BY_ORDER_ID_FAILURE";

// Beacons and Geofences
export const GET_BEACONS = "GET_BEACONS";
export const GET_BEACONS_SUCCESS = "GET_BEACONS_SUCCESS";
export const GET_BEACONS_FAILURE = "GET_BEACONS_FAILURE";

export const GET_GEOFENCE = "GET_GEOFENCE";
export const GET_GEOFENCE_SUCCESS = "GET_GEOFENCE_SUCCESS";
export const GET_GEOFENCE_FAILURE = "GET_GEOFENCE_FAILURE";

export const SEARCH_COUPONRULE = "SEARCH_COUPONRULE";
export const SEARCH_COUPONRULE_SUCCESS = "SEARCH_COUPONRULE_SUCCESS";
export const SEARCH_COUPONRULE_FAILURE = "SEARCH_COUPONRULE_FAILURE";

export const SEARCH_COUPON = "SEARCH_COUPON";
export const SEARCH_COUPON_SUCCESS = "SEARCH_COUPON_SUCCESS";
export const SEARCH_COUPON_FAILURE = "SEARCH_COUPON_FAILURE";
export const SEARCH_COUPON_BY_FIELDS = "SEARCH_COUPON_BY_FIELDS";
export const SEARCH_COUPON_BY_FIELDS_SUCCESS = "SEARCH_COUPON_BY_FIELDS_SUCCESS";
export const SEARCH_COUPON_BY_FIELDS_FAILURE = "SEARCH_COUPON_BY_FIELDS_FAILURE";

export const GET_COUPONCODE = "GET_COUPONCODE";
export const GET_COUPONCODE_SUCCESS = "GET_COUPONCODE_SUCCESS";
export const GET_COUPONCODE_FAILURE = "GET_COUPONCODE_FAILURE";

// Addon
export const CREATE_ADDON_SUCCESS = "CREATE_ADDON_SUCCESS";
export const CREATE_ADDON_FAILURE = "CREATE_ADDON_FAILURE";
export const UPDATE_ADDON_SUCCESS = "UPDATE_ADDON_SUCCESS";
export const UPDATE_ADDON_FAILURE = "UPDATE_ADDON_FAILURE";
export const CRUD_REQUEST_FOR_ADDON = "CRUD_REQUEST_FOR_ADDON";
export const CRUD_REQUEST_FOR_ADDON_SUCCESS = "CRUD_REQUEST_FOR_ADDON_SUCCESS";
export const CRUD_REQUEST_FOR_ADDON_FAILURE = "CRUD_REQUEST_FOR_ADDON_FAILURE";
export const ADDON_DELETE_SUCCESS = "ADDON_DELETE_SUCCESS";
export const ADDON_DELETE_FAILURE = "ADDON_DELETE_FAILURE";
// Inventory Service
export const STORE_INV_SEARCH = "STORE_INV_SEARCH";
export const STORE_INV_SEARCH_SUCCESS = "STORE_INV_SEARCH_SUCCESS";
export const STORE_INV_SEARCH_FAILURE = "STORE_INV_SEARCH_FAILURE";

export const INV_TRX_SEARCH = "INV_TRX_SEARCH";
export const INV_TRX_SEARCH_SUCCESS = "INV_TRX_SEARCH_SUCCESS";
export const INV_TRX_SEARCH_FAILURE = "INV_TRX_SEARCH_FAILURE";

export const COUPON_TRX_SEARCH = "COUPON_TRX_SEARCH";
export const COUPON_TRX_SEARCH_SUCCESS = "COUPON_TRX_SEARCH_SUCCESS";
export const COUPON_TRX_SEARCH_FAILURE = "COUPON_TRX_SEARCH_FAILURE";
export const SEARCH_COUPON_TRX_BY_FIELDS = "SEARCH_COUPON_TRX_BY_FIELDS"
export const SEARCH_COUPON_TRX_SUCCESS = "SEARCH_COUPON_TRX_SUCCESS"
export const SEARCH_COUPON_TRX_FAILURE = "SEARCH_COUPON_TRX_FAILURE"

// Property Config
export const SEARCH_PROPCONFIG = "SEARCH_PROPCONFIG";
export const SEARCH_PROPCONFIG_SUCCESS = "SEARCH_PROPCONFIG_SUCCESS";
export const SEARCH_PROPCONFIG_FAILURE = "SEARCH_PROPCONFIG_FAILURE";

export const SEARCH_CNCONFIG = "SEARCH_CNCONFIG";
export const SEARCH_CNCONFIG_SUCCESS = "SEARCH_CNCONFIG_SUCCESS";
export const SEARCH_CNCONFIG_FAILURE = "SEARCH_CNCONFIG_FAILURE";

export const SEARCH_DTYPECONFIG = "SEARCH_DTYPECONFIG";
export const SEARCH_DTYPECONFIG_SUCCESS = "SEARCH_DTYPECONFIG_SUCCESS";
export const SEARCH_DTYPECONFIG_FAILURE = "SEARCH_DTYPECONFIG_FAILURE";

export const SEARCH_SIZECONFIG_SUCCESS = "SEARCH_SIZECONFIG_SUCCESS";
export const SEARCH_SIZECONFIG_FAILURE = "SEARCH_SIZECONFIG_FAILURE";

export const SEARCH_COLORCONFIG_SUCCESS = "SEARCH_COLORCONFIG_SUCCESS";
export const SEARCH_COLORCONFIG_FAILURE = "SEARCH_COLORCONFIG_FAILURE";

export const SEARCH_BY_PRODUCT_SUCCESS = "SEARCH_BY_PRODUCT_SUCCESS";
export const SEARCH_BY_PRODUCT_FAILURE = "SEARCH_BY_PRODUCT_FAILURE";

export const SEARCH_PRODUCT_FEATURE_SUCCESS = "SEARCH_PRODUCT_FEATURE_SUCCESS";
export const SEARCH_PRODUCT_FEATURE_FAILURE = "SEARCH_PRODUCT_FEATURE_FAILURE";

//member search
export const SEARCH_USERPROFILE = "SEARCH_USERPROFILE";
export const SEARCH_USERPROFILE_SUCCESS = "SEARCH_USERPROFILE_SUCCESS";
export const SEARCH_USERPROFILE_FAILURE = "SEARCH_USERPROFILE_FAILURE";

// Operation slot
export const SEARCH_DELIVERYSLOT = "SEARCH_DELIVERYSLOT";
export const SEARCH_DELIVERYSLOT_SUCCESS = "SEARCH_DELIVERYSLOT_SUCCESS";
export const SEARCH_DELIVERYSLOT_FAILURE = "SEARCH_DELIVERYSLOT_FAILURE";

// Tax Value Constants

export const TAX_VALUE_SEARCH = "TAX_VALUE_SEARCH";
export const TAX_VALUE_SEARCH_SUCCESS = "TAX_VALUE_SEARCH_SUCCESS";
export const TAX_VALUE_SEARCH_FAILURE = "TAX_VALUE_SEARCH_FAILURE";

// Autosuggest constant
export const PROD_KEY_SEARCH = "PROD_KEY_SEARCH";
export const PROD_KEY_SEARCH_SUCCESS = "PROD_KEY_SEARCH_SUCCESS";
export const PROD_KEY_SEARCH_FAILURE = "PROD_KEY_SEARCH_FAILURE";

// Store country city search
export const SEARCH_CITY_BY_COUNTRY = "SEARCH_CITY_BY_COUNTRY";
export const SEARCH_CITY_BY_COUNTRY_SUCCESS = "SEARCH_CITY_BY_COUNTRY_SUCCESS";
export const SEARCH_CITY_BY_COUNTRY_FAILURE = "SEARCH_CITY_BY_COUNTRY_FAILURE";

// Store label by id
export const SEARCH_STORE_BY_ID = "SEARCH_STORE_BY_ID";
export const SEARCH_STORE_BY_ID_SUCCESS = "SEARCH_STORE_BY_ID_SUCCESS";
export const SEARCH_STORE_BY_ID_FAILURE = "SEARCH_STORE_BY_ID_FAILURE";

// get category by category code
export const SEARCH_BY_CC_SUCCESS = "SEARCH_BY_CC_SUCCESS";
export const SEARCH_BY_CC_FAILURE = "SEARCH_BY_CC_FAILURE";

// delivery task service
export const SEARCH_DELIVERYTASK_SUCCESS = "SEARCH_DELIVERYTASK_SUCCESS";
export const SEARCH_DELIVERYTASK_FAILURE = "SEARCH_DELIVERYTASK_FAILURE";

// shipping task service
export const SEARCH_SHIPPINGTASK_SUCCESS = "SEARCH_SHIPPINGTASK_SUCCESS";
export const SEARCH_SHIPPINGTASK_FAILURE = "SEARCH_SHIPPINGTASK_FAILURE";

// return task service
export const SEARCH_RETURNTASK_SUCCESS = "SEARCH_RETURNTASK_SUCCESS";
export const SEARCH_RETURNTASK_FAILURE = "SEARCH_RETURNTASK_FAILURE";

// product report
export const SEARCH_PRODUCTREPORTTIME_SUCCESS = "SEARCH_PRODUCTREPORTTIME_SUCCESS";
export const SEARCH_PRODCUTREPORTTIME_FAILURE = "SEARCH_PRODCUTREPORTTIME_FAILURE";

// tracker report
export const SEARCH_TRACKER_INVENTORY_SUCCESS = "SEARCH_TRACKER_INVENTORY_SUCCESS";
export const SEARCH_TRACKER_INVENTORY_FAILURE = "SEARCH_TRACKER_INVENTORY_FAILURE";
export const SEARCH_TRACKER_SEARCHKEY_SUCCESS = "SEARCH_TRACKER_SEARCHKEY_SUCCESS";
export const SEARCH_TRACKER_SEARCHKEY_FAILURE = "SEARCH_TRACKER_SEARCHKEY_FAILURE";

// member report
export const SEARCH_MEMBERREPORTTIME_SUCCESS = "SEARCH_MEMBERREPORTTIME_SUCCESS";
export const SEARCH_MEMBERREPORTTIME_FAILURE = "SEARCH_MEMBERREPORTTIME_FAILURE";

// sales document
export const SEARCH_SALEDOC_SUCCESS = "SEARCH_SALEDOC_SUCCESS";
export const SEARCH_SALEDOC_FAILURE = "SEARCH_SALEDOC_FAILURE";

//users management
export const SEARCH_USERS_BY_USERID_SUCCESS = "SEARCH_USERS_BY_USERID_SUCCESS";
export const SEARCH_USERS_BY_USERID_FAILURE = "SEARCH_USERS_BY_USERID_FAILURE"
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE"
export const SEARCH_USERGROUPS_BY_FIELDS = "SEARCH_USERGROUPS_BY_FIELDS"
export const SEARCH_USERGROUPS_SUCCESS = "SEARCH_USERGROUPS_SUCCESS"
export const SEARCH_USERGROUPS_FAILURE = "SEARCH_USERGROUPS_FAILURE"
export const SEARCH_USERROLES_BY_FIELDS = "SEARCH_USERROLES_BY_FIELDS"
export const SEARCH_USERROLES_SUCCESS = "SEARCH_USERROLES_SUCCESS"
export const SEARCH_USERROLES_FAILURE = "SEARCH_USERROLES_FAILURE"
export const SEARCH_USERROLESACCESS_BY_FIELDS = "SEARCH_USERROLESACCESS_BY_FIELDS"
export const SEARCH_USERROLESACCESS_SUCCESS = "SEARCH_USERROLESACCESS_SUCCESS"
export const SEARCH_USERROLESACCESS_FAILURE = "SEARCH_USERROLESACCESS_FAILURE"
export const SEARCH_USERACCESS_BY_FIELDS = "SEARCH_USERACCESS_BY_FIELDS"
export const SEARCH_USERACCESS_SUCCESS = "SEARCH_USERACCESS_SUCCESS"
export const SEARCH_USERACCESS_FAILURE = "SEARCH_USERACCESS_FAILURE"
export const SEARCH_STORE_GROUP_BY_FIELDS = "SEARCH_STORE_GROUP_BY_FIELDS"
export const SEARCH_STORE_GROUP_SUCCESS = "SEARCH_STORE_GROUP_SUCCESS"
export const SEARCH_STORE_GROUP_FAILURE = "SEARCH_STORE_GROUP_FAILURE"
export const SEARCH_STORE_BY_FIELDS = "SEARCH_STORE_BY_FIELDS"
export const SEARCH_STORE_SUCCESS = "SEARCH_STORE_SUCCESS"
export const SEARCH_STORE_FAILURE = "SEARCH_STORE_FAILURE"
export const STORESEARCH_STORE_SUCCESS = "STORESEARCH_STORE_SUCCESS"
export const STORESEARCH_STORE_FAILURE = "STORESEARCH_STORE_FAILURE"
export const USERROLE_CRUD_REQUEST_SUCCESS = "USERROLE_CRUD_REQUEST_SUCCESS";
export const USERROLE_CRUD_REQUEST_FAILURE = "USERROLE_CRUD_REQUEST_FAILURE";
export const SEARCH_COMPANY_BY_FIELDS = "SEARCH_COMPANY_BY_FIELDS"
export const SEARCH_COMPANY_SUCCESS = "SEARCH_COMPANY_SUCCESS"
export const SEARCH_COMPANY_FAILURE = "SEARCH_COMPANY_FAILURE"
export const SEARCH_COUNTRY_BY_FIELDS = "SEARCH_COUNTRY_BY_FIELDS"
export const SEARCH_COUNTRY_SUCCESS = "SEARCH_COUNTRY_SUCCESS"
export const SEARCH_COUNTRY_FAILURE = "SEARCH_COUNTRY_FAILURE"
export const SEARCH_CITY_BY_FIELDS = "SEARCH_CITY_BY_FIELDS"
export const SEARCH_CITY_SUCCESS = "SEARCH_CITY_SUCCESS"
export const SEARCH_CITY_FAILURE = "SEARCH_CITY_FAILURE"
export const SEARCH_PROMOTIONS_BY_FIELDS = "SEARCH_PROMOTIONS_BY_FIELDS"
export const SEARCH_PROMOTIONS_SUCCESS = "SEARCH_PROMOTIONS_SUCCESS"
export const SEARCH_PROMOTIONS_FAILURE = "SEARCH_PROMOTIONS_FAILURE"
export const SEARCH_PROMOTIONS_PGROUP_BY_FIELDS = "SEARCH_PROMOTIONS_PGROUP_BY_FIELDS"
export const SEARCH_PROMOTIONS_PGROUP_SUCCESS = "SEARCH_PROMOTIONS_PGROUP_SUCCESS"
export const SEARCH_PROMOTIONS_PGROUP_FAILURE = "SEARCH_PROMOTIONS_PGROUP_FAILURE"
export const SEARCH_PROMOTIONS_TGROUP_BY_FIELDS = "SEARCH_PROMOTIONS_TGROUP_BY_FIELDS"
export const SEARCH_PROMOTIONS_TGROUP_SUCCESS = "SEARCH_PROMOTIONS_TGROUP_SUCCESS"
export const SEARCH_PROMOTIONS_TGROUP_FAILURE = "SEARCH_PROMOTIONS_TGROUP_FAILURE"
export const PROMOTION_PGROUP_CRUD_REQUEST_SUCCESS = "PROMOTION_PGROUP_CRUD_REQUEST_SUCCESS";
export const PROMOTION_PGROUP_CRUD_REQUEST_FAILURE = "PROMOTION_PGROUP_CRUD_REQUEST_FAILURE";
export const PROMOTION_TGROUP_CRUD_REQUEST_SUCCESS = "PROMOTION_TGROUP_CRUD_REQUEST_SUCCESS";
export const PROMOTION_TGROUP_CRUD_REQUEST_FAILURE = "PROMOTION_TGROUP_CRUD_REQUEST_FAILURE";
export const SEARCH_STORE_DEVICES_FIELDS = "SEARCH_STORE_DEVICES_FIELDS"
export const SEARCH_STORE_DEVICES_SUCCESS = "SEARCH_STORE_DEVICES_SUCCESS"
export const SEARCH_STORE_DEVICES_FAILURE = "SEARCH_STORE_DEVICES_FAILURE"
export const SEARCH_CUST_DISPLAY = "SEARCH_CUST_DISPLAY"
export const SEARCH_CUST_DISPLAY_SUCCESS = "SEARCH_CUST_DISPLAY_SUCCESS"
export const SEARCH_CUST_DISPLAY_FAILURE = "SEARCH_CUST_DISPLAY_FAILURE"
export const SEARCH_VIRTUAL_PRINTERS = "SEARCH_VIRTUAL_PRINTERS"
export const SEARCH_VIRTUAL_PRINTERS_SUCCESS = "SEARCH_VIRTUAL_PRINTERS_SUCCESS"
export const SEARCH_VIRTUAL_PRINTERS_FAILURE = "SEARCH_VIRTUAL_PRINTERS_FAILURE"
export const SEARCH_STORE_TERMINALS_FIELDS = "SEARCH_STORE_TERMINALS_FIELDS"
export const SEARCH_STORE_TERMINALS_SUCCESS = "SEARCH_STORE_TERMINALS_SUCCESS"
export const SEARCH_STORE_TERMINALS_FAILURE = "SEARCH_STORE_TERMINALS_FAILURE"
export const SEARCH_STORE_TERMINALS_SUCCESS_SILENT = "SEARCH_STORE_TERMINALS_SUCCESS_SILENT"
export const SEARCH_STORE_TERMINALS_FAILURE_SILENT = "SEARCH_STORE_TERMINALS_FAILURE_SILENT"
export const SEARCH_STORE_TAGS_SUCCESS_SILENT = "SEARCH_STORE_TAGS_SUCCESS_SILENT"
export const SEARCH_STORE_TAGS_FAILURE_SILENT = "SEARCH_STORE_TAGS_FAILURE_SILENT"
export const SEARCH_STORE_TAGS_FIELDS = "SEARCH_STORE_TAGS_FIELDS"
export const SEARCH_STORE_TAGS_SUCCESS = "SEARCH_STORE_TAGS_SUCCESS"
export const SEARCH_STORE_TAGS_FAILURE = "SEARCH_STORE_TAGS_FAILURE"
export const PERIPHERALSEARCH_DEVICES_SUCCESS = "PERIPHERALSEARCH_DEVICES_SUCCESS"
export const PERIPHERALSEARCH_DEVICES_FAILURE = "PERIPHERALSEARCH_DEVICES_FAILURE"
export const SEARCH_EIP_MESSAGE_FIELDS = "SEARCH_EIP_MESSAGE_FIELDS"
export const SEARCH_EIP_MESSAGE_SUCCESS = "SEARCH_EIP_MESSAGESUCCESS"
export const SEARCH_EIP_MESSAGE_FAILURE = "SEARCH_API_LOGS_FAILURE"
export const SEARCH_API_LOGS_FIELDS = "SEARCH_EIP_MESSAGEFAILURE"
export const SEARCH_CART_SYNC_FIELDS = "SEARCH_CART_SYNC_FIELDS"
export const SEARCH_CART_SYNC_SUCCESS = "SEARCH_CART_SYNC_SUCCESS"
export const SEARCH_CART_SYNC_FALIURE = "SEARCH_CART_SYNC_FALIURE"
export const SEARCH_API_LOGS_SUCCESS = "SEARCH_API_LOGS_SUCCESS"
export const SEARCH_API_LOGS_FAILURE = "SEARCH_API_LOGS_FAILURE"
export const SEARCH_API_LOGS_BY_ID = "SEARCH_API_LOGS_BY_ID"
export const SEARCH_API_LOGS_BY_ID_SUCCESS = "SEARCH_API_LOGS_BY_ID_SUCCESS"
export const SEARCH_API_LOGS_BY_ID_FAILURE = "SEARCH_API_LOGS_BY_ID_FAILURE"
export const SEARCH_JOB_TASK_LOGS_FIELDS = "SEARCH_JOB_TASK_LOGS_FIELDS"
export const SEARCH_JOB_TASK_LOGS_SUCCESS = "SEARCH_JOB_TASK_LOGS_SUCCESS"
export const SEARCH_JOB_TASK_LOGS_FAILURE = "SEARCH_JOB_TASK_LOGS_FAILURE"
export const SEARCH_JOB_TASK_LOGS_BY_ID = "SEARCH_JOB_TASK_LOGS_BY_ID"
export const SEARCH_JOB_TASK_LOGS_BY_ID_SUCCESS = "SEARCH_JOB_TASK_LOGS_BY_ID_SUCCESS"
export const SEARCH_JOB_TASK_LOGS_BY_ID_FAILURE = "SEARCH_JOB_TASK_LOGS_BY_ID_FAILURE"
export const SEARCH_DATASYNC_LOGS_FIELDS = "SEARCH_DATASYNC_LOGS_FIELDS"
export const SEARCH_DATASYNC_LOGS_SUCCESS = "SEARCH_DATASYNC_LOGS_SUCCESS"
export const SEARCH_DATASYNC_LOGS_FAILURE = "SEARCH_DATASYNC_LOGS_FAILURE"
export const SEARCH_DATASYNC_LOGS_BY_ID = "SEARCH_DATASYNC_LOGS_BY_ID"
export const SEARCH_DATASYNC_LOGS_BY_ID_SUCCESS = "SEARCH_DATASYNC_LOGS_BY_ID_SUCCESS"
export const SEARCH_DATASYNC_LOGS_BY_ID_FAILURE = "SEARCH_DATASYNC_LOGS_BY_ID_FAILURE"
export const SEARCH_EXPORTED_COUPON_TRANSACTIONS = "SEARCH_EXPORTED_COUPON_TRANSACTIONS"
export const SEARCH_EXPORTED_COUPON_TRANSACTIONS_SUCCESS = "SEARCH_EXPORTED_COUPON_TRANSACTIONS_SUCCESS"
export const SEARCH_EXPORTED_COUPON_TRANSACTIONS_FAILURE = "SEARCH_EXPORTED_COUPON_TRANSACTIONS_FAILURE"
export const EXPORT_COUPON_TRANSACTIONS = "EXPORT_COUPON_TRANSACTIONS"
export const EXPORT_COUPON_TRANSACTIONS_SUCCESS = "EXPORT_COUPON_TRANSACTIONS_SUCCESS"
export const EXPORT_COUPON_TRANSACTIONS_FAILURE = "EXPORT_COUPON_TRANSACTIONS_FAILURE"

//Reports
export const PRODUCT_SALES_SUMMARY_REPORT = "PRODUCT_SALES_SUMMARY_REPORT";
export const PRODUCT_SALES_SUMMARY_REPORT_SUCCESS = "PRODUCT_SALES_SUMMARY_REPORT_SUCCESS";
export const PRODUCT_SALES_SUMMARY_REPORT_FAILURE = "PRODUCT_SALES_SUMMARY_REPORT_FAILURE";
export const PRODUCT_PRICE_DETAILS_REPORT = "PRODUCT_PRICE_DETAILS_REPORT"
export const PRODUCT_PRICE_DETAILS_REPORT_SUCCESS = "PRODUCT_PRICE_DETAILS_REPORT_SUCCESS"
export const PRODUCT_PRICE_DETAILS_REPORT_FAILURE = "PRODUCT_PRICE_DETAILS_REPORT_FAILURE"
export const SALES_DETAILS_REPORT = "SALES_DETAILS_REPORT"
export const SALES_DETAILS_REPORT_SUCCESS = "SALES_DETAILS_REPORT_SUCCESS"
export const SALES_DETAILS_REPORT_FAILURE = "SALES_DETAILS_REPORT_FAILURE"
export const POS_MASTER_DETAILS_REPORT = "POS_MASTER_DETAILS_REPORT"
export const POS_MASTER_DETAILS_REPORT_SUCCESS = "POS_MASTER_DETAILS_REPORT_SUCCESS"
export const POS_MASTER_DETAILS_REPORT_FAILURE = "POS_MASTER_DETAILS_REPORT_FAILURE"
export const REFUND_DETAILS_REPORT = "REFUND_DETAILS_REPORT"
export const REFUND_DETAILS_REPORT_SUCCESS = "REFUND_DETAILS_REPORT_SUCCESS"
export const REFUND_DETAILS_REPORT_FAILURE = "REFUND_DETAILS_REPORT_FAILURE"
export const TENDER_LISTING_REPORT = "TENDER_LISTING_REPORT"
export const TENDER_LISTING_REPORT_SUCCESS = "TENDER_LISTING_REPORT_SUCCESS"
export const TENDER_LISTING_REPORT_FAILURE = "TENDER_LISTING_REPORT_FAILURE"
export const TAG_DETAILS_REPORT = "TAG_DETAILS_REPORT"
export const TAG_DETAILS_REPORT_SUCCESS = "TAG_DETAILS_REPORT_SUCCESS"
export const TAG_DETAILS_REPORT_FAILURE = "TAG_DETAILS_REPORT_FAILURE"
export const SEARCH_PROP_CONFIG_FIELDS = "SEARCH_PROP_CONFIG_FIELDS"
export const SEARCH_PROP_CONFIG_SUCCESS = "SEARCH_PROP_CONFIG_SUCCESS"
export const SEARCH_PROP_CONFIG_FAILURE = "SEARCH_PROP_CONFIG_FAILURE"
export const SEARCH_STORE_OPEN_FIELDS = "SEARCH_STORE_OPEN_FIELDS"
export const SEARCH_STORE_OPEN_SUCCESS_SILENT = "SEARCH_STORE_OPEN_SUCCESS_SILENT"
export const SEARCH_STORE_OPEN_FAILURE_SILENT = "SEARCH_STORE_OPEN_FAILURE_SILENT"
export const EXPORT_REPORT = "EXPORT_REPORT"
export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS"
export const EXPORT_REPORT_FAILURE = "EXPORT_REPORT_FAILURE"
export const EXPORT_EJT = "EXPORT_EJT"
export const EXPORT_EJT_SUCCESS = "EXPORT_EJT_SUCCESS"
export const EXPORT_EJT_FAILURE = "EXPORT_EJT_FAILURE"
export const SEARCH_PROP_CONFIG_MASTER_TRXTYPES = "SEARCH_PROP_CONFIG_MASTER_TRXTYPES"
export const SEARCH_PROP_CONFIG_MASTER_TRXTYPES_SUCCESS = "SEARCH_PROP_CONFIG_MASTER_TRXTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_MASTER_TRXTYPES_FAILURE = "SEARCH_PROP_CONFIG_MASTER_TRXTYPES_FAILURE"
export const SEARCH_PROP_CONFIG_PAYSTATUS = "SEARCH_PROP_CONFIG_PAYSTATUS"
export const SEARCH_PROP_CONFIG_PAYSTATUS_SUCCESS = "SEARCH_PROP_CONFIG_PAYSTATUS_SUCCESS"
export const SEARCH_PROP_CONFIG_PAYSTATUS_FAILURE = "SEARCH_PROP_CONFIG_PAYSTATUS_FAILURE"
export const SEARCH_PROP_CONFIG_SALES_TRXTYPES = "SEARCH_PROP_CONFIG_SALES_TRXTYPES"
export const SEARCH_PROP_CONFIG_SALES_TRXTYPES_SUCCESS = "SEARCH_PROP_CONFIG_SALES_TRXTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_SALES_TRXTYPES_FAILURE = "SEARCH_PROP_CONFIG_SALES_TRXTYPES_FAILURE"
export const SEARCH_PROP_CONFIG_JOBTASKTYPES = "SEARCH_PROP_CONFIG_JOBTASKTYPES"
export const SEARCH_PROP_CONFIG_JOBTASKTYPES_SUCCESS = "SEARCH_PROP_CONFIG_JOBTASKTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_JOBTASKTYPES_FAILURE = "SEARCH_PROP_CONFIG_JOBTASKTYPES_FAILURE"
export const SEARCH_PROP_CONFIG_MASTER_TRXGROUPS = "SEARCH_PROP_CONFIG_MASTER_TRXGROUPS"
export const SEARCH_PROP_CONFIG_MASTER_TRXGROUPS_SUCCESS = "SEARCH_PROP_CONFIG_MASTER_TRXGROUPS_SUCCESS"
export const SEARCH_PROP_CONFIG_MASTER_TRXGROUPS_FAILURE = "SEARCH_PROP_CONFIG_MASTER_TRXGROUPS_FAILURE"

export const SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES = "SEARCH_PROP_CONFIG_COLLECTOR_TYPES"
export const SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS = "SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE = "SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE"

export const SEARCH_PROP_CONFIG_COLLECTOR_TYPES = "SEARCH_PROP_CONFIG_COLLECTOR_TYPES"
export const SEARCH_PROP_CONFIG_COLLECTOR_TYPES_SUCCESS = "SEARCH_PROP_CONFIG_COLLECTOR_TYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_COLLECTOR_TYPES_FAILURE = "SEARCH_PROP_CONFIG_COLLECTOR_TYPES_FAILURE"

export const SEARCH_PROP_CONFIG_POINTRULETYPES = "SEARCH_PROP_CONFIG_POINTRULETYPES"
export const SEARCH_PROP_CONFIG_POINTRULETYPES_SUCCESS = "SEARCH_PROP_CONFIG_POINTRULETYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_POINTRULETYPES_FAILURE = "SEARCH_PROP_CONFIG_POINTRULETYPES_FAILURE"

export const SEARCH_PROP_CONFIG_REFUNDTYPES = "SEARCH_PROP_CONFIG_REFUNDTYPES"
export const SEARCH_PROP_CONFIG_REFUNDTYPES_SUCCESS = "SEARCH_PROP_CONFIG_REFUNDTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_REFUNDTYPES_FAILURE = "SEARCH_PROP_CONFIG_REFUNDTYPES_FAILURE"
export const PAYTYPES = "PAYTYPES"
export const SEARCH_PROP_CONFIG_PAYTYPES = "SEARCH_PROP_CONFIG_PAYTYPES"
export const SEARCH_PROP_CONFIG_PAYTYPES_SUCCESS = "SEARCH_PROP_CONFIG_PAYTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_PAYTYPES_FAILURE = "SEARCH_PROP_CONFIG_PAYTYPES_FAILURE"
export const TENDERTAGTYPES = "TENDERTAGTYPES"


export const TENDER_GROUP_PTYPES = "TENDER_GROUP_PTYPES"
export const SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES = "SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES"
export const SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS = "SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE = "SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES_FAILURE"

export const SEARCH_SALE_INVOICE_PAYMENT = "SEARCH_SALE_INVOICE_PAYMENT"
export const SEARCH_SALE_INVOICE_PAYMENT_TYPES = "SEARCH_SALE_INVOICE_PAYMENT_TYPES"
export const SEARCH_SALE_INVOICE_PAYMENT_TYPES_SUCCESS = "SEARCH_SALE_INVOICE_PAYMENT_TYPES_SUCCESS"
export const SEARCH_SALE_INVOICE_PAYMENT_TYPES_FAILURE = "SEARCH_SALE_INVOICE_PAYMENT_TYPES_FAILURE"

export const SEARCH_PROP_CONFIG_TENDERTAGTYPES = "SEARCH_PROP_CONFIG_TENDERTAGTYPES"
export const SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS = "SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE = "SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE"

export const TOPUPTAGTYPES = "TOPUPTAGTYPES"
export const SEARCH_PROP_CONFIG_TOPUPTAGTYPES = "SEARCH_PROP_CONFIG_TOPUPTAGTYPES"
export const SEARCH_PROP_CONFIG_TOPUPTAGTYPES_SUCCESS = "SEARCH_PROP_CONFIG_TOPUPTAGTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_TOPUPTAGTYPES_FAILURE = "SEARCH_PROP_CONFIG_TOPUPTAGTYPES_FAILURE"
export const EJ_EXPORT = "EJ_EXPORT"
export const SEARCH_PROP_CONFIG_EJ_TRXTYPES = "SEARCH_PROP_CONFIG_EJ_TRXTYPES"
export const SEARCH_PROP_CONFIG_EJ_TRXTYPES_SUCCESS = "SEARCH_PROP_CONFIG_EJ_TRXTYPES_SUCCESS"
export const SEARCH_PROP_CONFIG_EJ_TRXTYPES_FAILURE = "SEARCH_PROP_CONFIG_EJ_TRXTYPES_FAILURE"
export const SEARCH_EJ_BY_FIELDS = "SEARCH_EJ_BY_FIELDS"
export const SEARCH_EJ_BY_FIELDS_SUCCESS = "SEARCH_EJ_BY_FIELDS_SUCCESS"
export const SEARCH_EJ_BY_FIELDS_FAILURE = "SEARCH_EJ_BY_FIELDS_FAILURE"

export const SPECIAL_DISCOUNT_TREE_SEARCH = "SPECIAL_DISCOUNT_TREE_SEARCH";
export const SPECIAL_DISCOUNT_SEARCH_SUCCESS = "SPECIAL_DISCOUNT_SEARCH_SUCCESS";
export const SPECIAL_DISCOUNT_SEARCH_FAILURE = "SPECIAL_DISCOUNT_SEARCH_FAILURE";

export const SPECIAL_DISCOUNT_SUMMARY_SUCCESS = "SPECIAL_DISCOUNT_SUMMARY_SUCCESS";
export const SPECIAL_DISCOUNT_SUMMARY_FAILURE = "SPECIAL_DISCOUNT_SUMMARY_FAILURE";

export const SPECIAL_DISCOUNT_TYPE_SUCCESS = "SPECIAL_DISCOUNT_TYPE_SUCCESS";
export const SPECIAL_DISCOUNT_TYPE_FAILURE = "SPECIAL_DISCOUNT_TYPE_FAILURE";

// tax configuration
export const SEARCH_TAXCONFIGURATION = "SEARCH_TAXCONFIGURATION";
export const SEARCH_TAXCONFIGURATION_SUCCESS = "SEARCH_TAXCONFIGURATION_SUCCESS";
export const SEARCH_TAXCONFIGURATION_FAILURE = "SEARCH_TAXCONFIGURATION_FAILURE";
export const SEARCH_TAXCONFIGURATION_FIELDS = "SEARCH_TAXCONFIGURATION_FIELDS";
export const TAXCONFIGURATION_CRUD_REQUEST = "TAXCONFIGURATION_CRUD_REQUEST";
export const TAXCONFIGURATION_CRUD_REQUEST_SUCCESS = "TAXCONFIGURATION_CRUD_REQUEST_SUCCESS";
export const TAXCONFIGURATION_CRUD_REQUEST_FAILURE = "TAXCONFIGURATION_CRUD_REQUEST_FAILURE";
export const DELETE_TAXCONFIGURATION_CRUD_REQUEST = "DELETE_TAXCONFIGURATION_CRUD_REQUEST";
export const DELETE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS = "DELETE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS";
export const DELETE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE = "DELETE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE";
export const UPDATE_TAXCONFIGURATION_CRUD_REQUEST = "UPDATE_TAXCONFIGURATION_CRUD_REQUEST";
export const UPDATE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS = "UPDATE_TAXCONFIGURATION_CRUD_REQUEST_SUCCESS";
export const UPDATE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE = "UPDATE_TAXCONFIGURATION_CRUD_REQUEST_FAILURE";

// special discount
export const SEARCH_SPECIALDISCOUNT = "SEARCH_SPECIALDISCOUNT";
export const SEARCH_SPECIALDISCOUNT_SUCCESS = "SEARCH_SPECIALDISCOUNT_SUCCESS";
export const SEARCH_SPECIALDISCOUNT_FAILURE = "SEARCH_SPECIALDISCOUNT_FAILURE";
export const SEARCH_SPECIALDISCOUNT_PID = "SEARCH_SPECIALDISCOUNT_PID";
export const SEARCH_SPECIALDISCOUNT_PID_SUCCESS = "SEARCH_SPECIALDISCOUNT_PID_SUCCESS";
export const SEARCH_SPECIALDISCOUNT_PID_FAILURE = "SEARCH_SPECIALDISCOUNT_PID_FAILURE";
export const SEARCH_SPECIALDISCOUNT_FIELDS = "SEARCH_SPECIALDISCOUNT_FIELDS";
export const SEARCH_SPECIALDISCOUNT_FIELDS_SUCCESS = "SEARCH_SPECIALDISCOUNT_FIELDS_SUCCESS";
export const SEARCH_SPECIALDISCOUNT_FIELDS_FAILURE = "SEARCH_SPECIALDISCOUNT_FIELDS_FAILURE";
export const SPECIALDISCOUNT_CRUD_REQUEST = "SPECIALDISCOUNT_CRUD_REQUEST";
export const SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS = "SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS";
export const SPECIALDISCOUNT_CRUD_REQUEST_FAILURE = "SPECIALDISCOUNT_CRUD_REQUEST_FAILURE";
export const DELETE_SPECIALDISCOUNT_CRUD_REQUEST = "DELETE_SPECIALDISCOUNT_CRUD_REQUEST";
export const DELETE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS = "DELETE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS";
export const DELETE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE = "DELETE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE";
export const UPDATE_SPECIALDISCOUNT_CRUD_REQUEST = "UPDATE_SPECIALDISCOUNT_CRUD_REQUEST";
export const UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS = "UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_SUCCESS";
export const UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE = "UPDATE_SPECIALDISCOUNT_CRUD_REQUEST_FAILURE";
export const SEARCH_STORE_BIRINFO_FIELDS_SILENT = "SEARCH_STORE_BIRINFO_FIELDS_SILENT"
export const SEARCH_STORE_BIRINFO_SILENT_SUCCESS = "SEARCH_STORE_BIRINFO_SILENT_SUCCESS"
export const SEARCH_STORE_BIRINFO_SILENT_FAILURE = "SEARCH_STORE_BIRINFO_SILENT_FAILURE"
export const SEARCH_TERMINAL_BIRINFO_FIELDS_SILENT = "SEARCH_TERMINAL_BIRINFO_FIELDS_SILENT"
export const SEARCH_TERMINAL_BIRINFO_SILENT_SUCCESS = "SEARCH_TERMINAL_BIRINFO_SILENT_SUCCESS"
export const SEARCH_TERMINAL_BIRINFO_SILENT_FAILURE = "SEARCH_TERMINAL_BIRINFO_SILENT_FAILURE"

export const STORE_CREATE_UPDATE_REQUEST = "STORE_CREATE_UPDATE_REQUEST";
export const STORE_CREATE_UPDATE_SUCCESS = "STORE_CREATE_UPDATE_SUCCESS";
export const STORE_CREATE_UPDATE_FAILURE = "STORE_CREATE_UPDATE_FAILURE";
export const STORE_TERMINAL_CREATE_UPDATE_REQUEST = "STORE_TERMINAL_CREATE_UPDATE_REQUEST";
export const STORE_TERMINAL_CREATE_UPDATE_SUCCESS = "STORE_TERMINAL_CREATE_UPDATE_SUCCESS";
export const STORE_TERMINAL_CREATE_UPDATE_FAILURE = "STORE_TERMINAL_CREATE_UPDATE_FAILURE";
export const SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS = "SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS"
export const SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_SUCCESS = "SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_SUCCESS"
export const SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_FAILURE = "SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS_FAILURE"
export const EXECUTE_DATA_VALIDATION_REPORT = "EXECUTE_DATA_VALIDATION_REPORT"
export const EXECUTE_DATA_VALIDATION_REPORT_SUCCESS = "EXECUTE_DATA_VALIDATION_REPORT_SUCCESS"
export const EXECUTE_DATA_VALIDATION_REPORT_FAILURE = "EXECUTE_DATA_VALIDATION_REPORT_FAILURE"
export const SEARCH_ITEMSALESTYPE_PROPCONFIG = "SEARCH_ITEMSALESTYPE_PROPCONFIG";
export const SEARCH_ITEMSALESTYPE_PROPCONFIG_SUCCESS = "SEARCH_ITEMSALESTYPE_PROPCONFIG_SUCCESS";
export const SEARCH_ITEMSALESTYPE_PROPCONFIG_FAILURE = "SEARCH_ITEMSALESTYPE_PROPCONFIG_FAILURE";
export const SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG = "SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG";
export const SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG_SUCCESS = "SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG_SUCCESS";
export const SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG_FAILURE = "SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG_FAILURE";


export const STORE_TAG_BATCH_REQUEST = "STORE_TAG_BATCH_REQUEST";
export const STORE_TAG_BATCH_SUCCESS = "STORE_TAG_BATCH_SUCCESS";
export const STORE_TAG_BATCH_FAILURE = "STORE_TAG_BATCH_FAILURE";

export const DEVICE_TAG_BATCH_REQUEST = "DEVICE_TAG_BATCH_REQUEST";
export const DEVICE_TAG_BATCH_SUCCESS = "DEVICE_TAG_BATCH_SUCCESS";
export const DEVICE_TAG_BATCH_FAILURE = "DEVICE_TAG_BATCH_FAILURE";

export const REPLENISHMENT_GET_ALL_REQUEST = "REPLENISHMENT_GET_ALL_REQUEST";
export const REPLENISHMENT_GET_ALL_SUCCESS = "REPLENISHMENT_GET_ALL_SUCCESS";
export const REPLENISHMENT_GET_ALL_FAILURE = "REPLENISHMENT_GET_ALL_FAILURE";


export const CUSTOMER_TAG_REQUEST = "CUSTOMER_TAG_REQUEST";
export const CUSTOMER_TAG_SUCCESS = "CUSTOMER_TAG_SUCCESS";
export const CUSTOMER_TAG_FAILURE = "CUSTOMER_TAG_FAILURE";

export const UPDATE_REPLENISHMENT_REQUEST = "UPDATE_REPLENISHMENT_REQUEST";
export const UPDATE_REPLENISHMENT_SUCCESS = "UPDATE_REPLENISHMENT_SUCCESS";
export const UPDATE_REPLENISHMENT_FAILURE = "UPDATE_REPLENISHMENT_FAILURE";

export const SEARCH_SALE_RETURN_SUCCESS = "SEARCH_SALE_RETURN_SUCCESS";
export const SEARCH_SALE_RETURN_FAILURE = "SEARCH_SALE_RETURN_FAILURE";