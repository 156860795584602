import React from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";

import { queryStoreCategoryInfo } from "../../../../actions/actions";
import SimpleTable_Select from "../../../../components/SimpleTable_Select"
import CategoryTree from "./CategoryTree";

class ProductSearch extends React.PureComponent {

  constructor(props) {

    super(props);

    this.companyId = localStorage.getItem('company_id')

    this.state = {
      showProductSearchModal: false,
      categorycodes: "",
      // storeid: this.props.storeid,
      status: "Active",
      storeproductdata: [],

      productCodeToSearch: "",
      selectedProducts: [],
      columns: [
        {
          dataField: "additionalfields.title",
          text: "Title",
          sort: true
        },
        {
          dataField: "productcode",
          text: "Product Code",
          sort: true
        },
        // {
        //   dataField: "groupid",
        //   text: "Store Group",
        //   sort: true
        // },
        // {
        //   dataField: "country",
        //   text: "Country",
        //   sort: true
        // },
        // {
        //   dataField: "city",
        //   text: "City",
        //   sort: false
        // }
      ]
    };
  }

  componentWillMount = () => {
    // this.props.queryData.storeSearchStores = []
    // //this.props.dispatch(countrySearchByFields(`status=Active`))
    // let queryParam = `status=Active&companyid=${this.companyId}`
    // this.props.dispatch(storeGroupSearchByFields(queryParam))
  };

  componentDidUpdate = (prevProps) => {
    const {  storeCategoryMapping} = this.props.queryData;
    const { storeCategoryMapping: prevstoreCategoryMapping } = prevProps.queryData;
    if(storeCategoryMapping !== prevstoreCategoryMapping) {
      if(storeCategoryMapping.length > 0){
        this.setState({
          storeproductdata: storeCategoryMapping
        })      
      }
      else{
        this.setState({
          storeproductdata: []
        }) 
      }
    }
  }

  _searchProducts = event => {
    if (event) { event.preventDefault()}
    console.log('_searchProducts Called')
    const { categorycodes, status, productCodeToSearch } = this.state;

    console.log('selected query::', this)

    if (categorycodes === '' && productCodeToSearch === '') {
      this.setState({categorycodeerror: 'Select Category'})
      return
    }
    this.props.dispatch(
      queryStoreCategoryInfo(
        {
          body: this.getRequstBody()
        },
        categorycodes,
        this.props.storeid
      )
    );
  };

  getRequstBody = () => {
    const { categorycodes, status, productCodeToSearch } = this.state;
    if (productCodeToSearch !== '') {
      return {
        categorycode: categorycodes,
        storeid: this.props.storeid,
        status: status,
        productcode: productCodeToSearch
      }
     } else {
        return {
          categorycode: categorycodes,
          storeid: this.props.storeid,
          status: status,
        }
      }
    }
  

  _openProductSearchModal = event => {
    if (event) { event.preventDefault(); }
    if (this.props.validateStoreSelection && this.props.validateStoreSelection()) {
    this.setState({
      showProductSearchModal: true
    });
  }
  };

  _closeProductSearchModal = event => {
    if (event) event.preventDefault();
    this.setState({
      showProductSearchModal: false,
      productCodeToSearch: "",
      selectedProducts: [],
      storeproductdata: [],
      categorycodes: "",
      categorycodeerror: ''
    });
  };

  _onSelectCategory = (selectedCategories) => {
    console.log('selectedCategories::', selectedCategories)
    if (selectedCategories && selectedCategories.length > 0) {
      this.setState({categorycodes: selectedCategories[0], categorycodeerror: ''})
    } else {
      this.setState({categorycodes: ''})
    }
  }

  _updateSelectedProducts = event => {
    if (event) event.preventDefault();
    console.log('selectedProducts::', this.state.selectedProducts)
    this.props.onSelectedProducts(this.state.selectedProducts);
    this._closeProductSearchModal();
  };

  _renderProductSearchModal = () => {
    console.log('..... _ _renderProductSearchModal .....')
    // const { countries } = this.props.queryData;
    // console.log('countries...', countries)
    // const { cities } = this.props.queryData;
    // const { storeGroups } = this.props.queryData
    // console.log('cities::', cities)
    // const { storeSearchStores } = this.props.queryData;

    // const { categoryTree } = this.props.queryData;
    // const nodes = [categoryTree].map(this.rename)

    return (
      <Modal
        visible={this.state.showProductSearchModal}
        width="50%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeProductSearchModal()}
      >
        <div className="modalForm storeSearchModalForm">

          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">
              <div className="row sub-title">
                Product Search
              </div>
            </div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeProductSearchModal} className="closeIcon">
                X
              </a>
            </div>
          </div>


          <div className="form-group row">
            <div className="col-lg-4 col-sm-12">


              <label htmlFor="stores" className="floatLeft required"
              >
                Category
              </label>
              <br />
              <CategoryTree nodes={this.props.nodes} onSelectCategory={this._onSelectCategory} multiSelect={false} closeOnSelect={true} />
              <div className="invalid-feedback">{this.state.categorycodeerror}</div>
            </div>

            <div className="col-lg-4 col-sm-12">
              <label className="floatLeft alignLeft">
                Product Code
              </label>
              <input type="text" className="form-control" id="productcode" onChange={e => this.setState({ productCodeToSearch: e.target.value })}></input>
            </div>

            <div className="col-lg-4 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
              <button
                type="submit"
                onClick={(e) => this._searchProducts(e)}
                className="btn btn-themes btn-rounded"
              >
                Search
              </button>

              <button
                type="submit"
                onClick={this._closeProductSearchModal}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Cancel
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-12">
              
              <SimpleTable_Select
                columns={this.state.columns}
                rows={this.state.storeproductdata}
                handleOnSelect={this.handleOnSelect}
                handleOnSelectAll={this.handleOnSelectAll}
                pagination={true}
              />
              
            </div>
          </div>

          <div className="row btn-container1">
            <div className="col-lg-12 col-md-12 col-sm-12 alignRight">
              <button
                type="submit"
                onClick={this._updateSelectedProducts}
                className="btn btn-themes btn-rounded"
              >
                Insert
              </button>

              <button
                type="submit"
                onClick={this._closeProductSearchModal}
                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              >
                Discard
              </button>

            </div>
          </div>

        </div>
      </Modal>
    );
  };

  handleOnSelect = (row, isSelect) => {
    console.log('handleOnSelect::', row, isSelect, this.state.selectedProducts)
    if (isSelect) {
      this.setState(() => ({
        selectedProducts: [...this.state.selectedProducts, row.productcode]
      }));
    } else {
      this.setState(() => ({
        selectedProducts: this.state.selectedProducts.filter(x => x !== row.productcode)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.productcode);
    if (isSelect) {
      this.setState(() => ({
        selectedProducts: ids
      }));
    } else {
      this.setState(() => ({
        selectedProducts: []
      }));
    }
  }

  rename = obj => {
    console.log('rename for obj::', obj)
    for (var prop in obj) {
      if (Array.isArray(obj[prop])) {
        if (obj[prop].length > 0) {
          obj.showCheckbox = false
        }
        obj[prop] = obj[prop].map(this.rename);
      }

      if (prop === 'categorycode') {
        obj.value = obj[prop]
        obj.icon = ''
      } else if (prop === 'title') {
        obj.label = obj[prop]
        obj['subtitle'] = obj['categorycode']
      } else if (prop === "subcategories") {
        obj.children = obj[prop];
      }
      delete obj[prop];
    }

    return obj;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showProductSearchModal && this._renderProductSearchModal()}
        {/* <div className="row">
          <div className="col-12 noPadding noMargin">
            <input
              type="text"
              className=" treemodal"
              id="tenant"
              readOnly={true}
              value={this.state.selectedCategories.length > 0 ? this.state.selectedCategories[0] : ''}
              onClick={this._openCategoryTreeModal}
              disabled={this.props.disabled || false}
            />
          </div>
        </div> */}
        <div className="row">
        <button
          className="page-header-btn icon_btn display-inline"
          onClick={event => this._openProductSearchModal(event)}
          disabled={this.props.disabled || false}
        >
          <img
            title="edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
          />
          {`Select Product`}
        </button>
        </div>
      </React.Fragment>
    );

    // return (
    //   <React.Fragment>
    //     <div className="row sub-title">
    //       Store
    //     </div>
    //     <div className="row">
    //       <div className="form-group col-lg-12 col-sm-12">
    //         <label htmlFor="stores" className="floatLeft required"
    //         >
    //           Category
    //         </label>
    //         <br />
    //         <CategoryTree onSelectCategory={this._onSelectCategory} multiSelect={false} closeOnSelect={true} />
    //       </div>
    //     </div>
    //   </React.Fragment>
    // )
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData
  };
};

export default connect(mapStateToProps)(ProductSearch);
