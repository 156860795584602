import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import * as CONSTANTS from '../../../../constants/constants';
import {
    createStore,
    searchGeneral,
    createStoreTagBatch,
    createDeviceTagBatch,
    crudReset,
} from '../../../../actions/actions';

const StoreTagsObj = {
    storeTenders: 'tender',
    storeOptions:
        'storeprops,deliverytype,receiptheader,receiptfooter,invoiceheader,taxreportheader,topupreportheader,topupreceiptheader',
    terminalOptions:
        'topup,receiptheader,receiptfooter,invoiceheader,taxreportheader,topupreportheader,topupreceiptheader',
    terminalTenders: 'tender',
    birinfo: 'birinfo'
};

const DeviceTags = {
    deviceManager: 'devicebroker',
    terminal: 'terminal',
    posperipherals: 'eft,printer,customerdisplay,virtualprinter,cashdrawer,light',
};

const DuplicateStoreForm = (props) => {
    const { selectedStore, crudData, queryData } = props;
    const { storeTags, storeDevices } = queryData;
    const [savedStoreTag, setSavedStoreTag] = useState(false);

    const companyid = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(
            searchGeneral(
                'system/v1/store/search/fields',
                { body: { companyid } },
                CONSTANTS.SEARCH_STORE_SUCCESS,
                CONSTANTS.SEARCH_STORE_FAILURE
            )
        );
        getStoreTagValues();
        getDeviceTagValues();
    }, []);

    const getStoreTagValues = () => {
        const {
            birinfo,
            terminalOptions,
            terminalTenders,
            storeOptions,
            storeTenders,
        } = formik.values;
        const { storeid } = selectedStore;
        const tags = [
            storeOptions && StoreTagsObj.storeOptions,
            terminalOptions && StoreTagsObj.terminalOptions,
            terminalTenders && StoreTagsObj.terminalTenders,
            storeTenders && StoreTagsObj.storeTenders,
            terminalTenders && StoreTagsObj.terminalTenders,
            birinfo && StoreTagsObj.birinfo,
        ].filter(Boolean);

        let tagsType = tags.join(',');
        const requestBody = {
            body: {
                tagtype: tagsType,
                storeid: storeid,
                status: 'Active'
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/store/tag/search/fields',
                requestBody,
                CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
                CONSTANTS.SEARCH_STORE_TAGS_FAILURE
            )
        );
    };

    const getDeviceTagValues = () => {
        const { storeid } = selectedStore;
        const { terminal, deviceManager, posperipherals } = formik.values;
        const tags = [
            terminal && DeviceTags.terminal,
            deviceManager && DeviceTags.deviceManager,
            posperipherals && DeviceTags.posperipherals,
        ].filter(Boolean);
        let tagsType = tags.join(',');
        const requestBody = {
            body: {
                tagtype: tagsType,
                storeid: storeid,
                status: 'Active'
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/store/device/search/fields',
                requestBody,
                CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS,
                CONSTANTS.SEARCH_STORE_DEVICES_SUCCESS
            )
        );
    };

    const formik = useFormik({
        initialValues: {
            storeid: '',
            title: '',
            storeOptions: true,
            storeTenders: true,
            terminalOptions: true,
            terminalTenders: true,
            terminal: true,
            deviceManager: true,
            posperipherals: true,
            birinfo: true,
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.title) {
                errors.title = 'Title is required.';
            }
            if (!data.storeid) {
                errors.storeid = 'Store id is required.';
            }
            if (data.storeid && checkStoreExist(data.storeid)) {
                errors.storeid = 'Store already exist.';
            }
            return errors;
        },
        onSubmit: (values) => {
            applyDuplicateStore(values);
            //testing
            // const devicepayload = getStoreTagsPayload();
            // console.log('what is payload', devicepayload)
        },
    });

    useEffect(() => {
        //batch save
        if (crudData?.data?.id && !savedStoreTag) {
            setSavedStoreTag(true);
            const payload = getStoreTagsPayload();
            const devicepayload = getDeviceTagsPayload();
            props.dispatch(createStoreTagBatch({ body: payload }));
            props.dispatch(createDeviceTagBatch({ body: devicepayload }));
            props.dispatch(crudReset());
            props.onSuccess();
        }
    }, [queryData, crudData]);

    const applyDuplicateStore = (values) => {
        const {
            id,
            storeid,
            title,
            lastupdatedby,
            lastupdated,
            createdon,
            ...rest
        } = selectedStore;

        const storeData = Object.fromEntries(Object.entries(rest));
        storeData['storeid'] = values.storeid;
        storeData['title'] = values.title;
        storeData['title_short'] = values.title;
        storeData['status'] = 'New';
        props.dispatch(createStore({ body: storeData }));
    };
    
    const replacePosPeripheralDeviceId = (deviceList, oldDeviceId, newDeviceId) => {
        const peripheralList = deviceList.filter(d => d.brokerid == oldDeviceId)
        peripheralList.map(p => {
            p['brokerid'] = newDeviceId
        })
    }

    const getDeviceTagsPayload = () => {
        const { terminal, deviceManager, posperipherals } = formik.values;
        let deviceTagsPayload = [];
        let deviceList = storeDevices
        let deviceTagsData = deviceList.map((item) => {
            const {
                id,
                storeid,
                lastupdatedby,
                lastupdated,
                createdon,
                ...rest
            } = item;
            const restData = Object.fromEntries(Object.entries(rest));
            let deviceid = getDeviceId(item.deviceid)
            if (item.devicegroup === 'devicebroker') {
                deviceList.map(device => {
                    if ((device.devicegroup !== 'devicebroker' || device.devicegroup !== 'terminal') && device.brokerid == item.deviceid) {
                        replacePosPeripheralDeviceId(deviceList, device.brokerid, deviceid)
                        device['brokerid'] = deviceid
                    }
                })
    
            }
            return {
                ...restData,
                storeid: formik.values.storeid,
                title: getDeviceTitle(item),
                deviceid
            };
        });
        if (terminal) {
            let terminalList = deviceTagsData.filter(
                (item) => item.devicegroup === 'terminal'
            );
            deviceTagsPayload = [...deviceTagsPayload, ...terminalList];
        }
        if (deviceManager) {
            let brokerList = deviceTagsData.filter(
                (item) => item.devicegroup === 'devicebroker'
            );
            deviceTagsPayload = [...deviceTagsPayload, ...brokerList];
        }
        if (posperipherals) {
            const posperipheralArr = DeviceTags.posperipherals.split(',');
            let peripheralList = deviceTagsData.filter((item) =>
                posperipheralArr.includes(item.devicegroup)
            );
            deviceTagsPayload = [...deviceTagsPayload, ...peripheralList];
        }
        return deviceTagsPayload;
    };

    const getDeviceTitle = (item) => {
        if (item.devicegroup == 'terminal') {
            return `${item.devicegroup}_${formik.values.storeid}_${item.terminalid}`
        }
        return `${item.devicegroup}_${formik.values.storeid}`
    }

    const getTagTitle = item => {
        // if (item.tagtype === 'tprops' && item.terminalid) {
        //     return `${item.tagtypevalue}_${formik.values.storeid}_${item.terminalid}`
        // }
        if (item.terminalid) {
            return `${item.tagtypevalue}_${formik.values.storeid}_${item.terminalid}`
        }
        return `${item.tagtypevalue}_${formik.values.storeid}`
    }

    const getStoreTagsPayload = () => {
        //testing purpose
        const { storeOptions, storeTenders, terminalOptions, terminalTenders } =
            formik.values;
        let stroeTagsPayload = [];
        let storeTagsData = storeTags.map((item) => {
            const {
                id,
                storeid,
                lastupdatedby,
                lastupdated,
                createdon,
                ...rest
            } = item;
            const restData = Object.fromEntries(Object.entries(rest));
            return {
                ...restData,
                storeid: formik.values.storeid,
                title: getTagTitle(item),
            };
        });
        if (storeOptions) {
            const storeOptionsArr = StoreTagsObj.storeOptions.split(',');
            let storeOptionsList = storeTagsData.filter(
                (item) =>
                    storeOptionsArr.includes(item.tagtype) &&
                    item.taggroup == 'storeprops'
            );
            stroeTagsPayload = [...stroeTagsPayload, ...storeOptionsList];
        }
        if (storeTenders) {
            let storeTenderList = storeTagsData.filter(
                (item) =>
                    item.tagtype === 'tender' && item.taggroup == 'storeprops'
            );
            stroeTagsPayload = [...stroeTagsPayload, ...storeTenderList];
        }
        if (terminalOptions) {
            const terminalOptionsArr = StoreTagsObj.terminalOptions.split(',');
            let terminalOptionList = storeTagsData.filter(
                (item) =>
                    terminalOptionsArr.includes(item.tagtype) &&
                    item.taggroup == 'tprops'
            );
            stroeTagsPayload = [...stroeTagsPayload, ...terminalOptionList];
        }
        if (terminalTenders) {
            let terminalTenderList = storeTagsData.filter(
                (item) => item.tagtype === 'tender' && item.taggroup == 'tprops'
            );
            stroeTagsPayload = [...stroeTagsPayload, ...terminalTenderList];
        }
        return storeTagsData;
    };

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const getDeviceId = (deviceid) => {
        const letters = deviceid.match(/[a-zA-Z]+/g);
        return letters + Math.floor(Math.random() * 899999 + 100000);
    };

    const handleChangeTerminalTender = (e) => {
        const { checked } = e.target;
        formik.setFieldValue('terminalTenders', !formik.values.terminalTenders);
        if (checked) {
            formik.setFieldValue('terminal', true);
        }
    };

    const handleChangeTerminalOption = (e) => {
        const { checked } = e.target;
        formik.setFieldValue('terminalOptions', !formik.values.terminalOptions);
        if (checked) {
            formik.setFieldValue('terminal', true);
        }
    };

    const handleStoreIdChange = (event) => {
        const inputValue = event.target.value;
        const storeid = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        if (storeid.length <= 4) {
            formik.setFieldValue('storeid', storeid);
        }
        if (checkStoreExist(storeid)) {
            formik.setFieldError('storeid', 'Store already existed.');
        }
    };

    const checkStoreExist = (storeid) => {
        const { stores } = queryData;
        const isStoreExist = stores.find(
            (element) => element.storeid === storeid
        );
        if (isStoreExist) {
            return true;
        }
        return false;
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row p-fluid formgrid grid w-100">
                <div
                    className="field col-12 col-md-6"
                    style={{ paddingLeft: '0px' }}
                >
                    <label
                        htmlFor="storeid"
                        className={classNames({
                            'p-error': isFormFieldValid('storeid'),
                        })}
                    >
                        Store Id*
                    </label>
                    <InputText
                        id="storeid"
                        type="text"
                        name="storeid"
                        value={formik.values.storeid}
                        onChange={handleStoreIdChange}
                    />
                    {/* {getFormErrorMessage('storeid')} */}
                    {formik.errors.storeid && (
                        <small className="p-error">
                            {formik.errors.storeid}
                        </small>
                    )}
                </div>
                <div
                    className="field col-12 col-md-6"
                    style={{ paddingLeft: '0px' }}
                >
                    <label
                        htmlFor="title"
                        className={classNames({
                            'p-error': isFormFieldValid('title'),
                        })}
                    >
                        Title*
                    </label>
                    <InputText
                        id="title"
                        type="text"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                    />
                    {getFormErrorMessage('title')}
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="storeOptions"
                        name="storeOptions"
                        value="formik.values.storeOptions"
                        onChange={formik.handleChange}
                        checked={formik.values.storeOptions}
                    />
                    <label htmlFor="storeOptions">Store Options</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="storeTenders"
                        name="storeTenders"
                        value="formik.values.storeTenders"
                        onChange={formik.handleChange}
                        checked={formik.values.storeTenders}
                    />
                    <label htmlFor="storeTenders">Store Tenders</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="terminal"
                        name="terminal"
                        value="formik.values.terminal"
                        onChange={formik.handleChange}
                        checked={formik.values.terminal}
                    />
                    <label htmlFor="terminal">Terminal</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="terminalOptions"
                        name="terminalOptions"
                        value="formik.values.terminalOptions"
                        onChange={handleChangeTerminalOption}
                        checked={formik.values.terminalOptions}
                    />
                    <label htmlFor="terminalOptions">Terminal Options</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="terminalTenders"
                        name="terminalTenders"
                        value="formik.values.terminalTenders"
                        onChange={handleChangeTerminalTender}
                        checked={formik.values.terminalTenders}
                    />
                    <label htmlFor="terminalTenders">Terminal Tenders</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="deviceManager"
                        name="deviceManager"
                        value="formik.values.deviceManager"
                        onChange={formik.handleChange}
                        checked={formik.values.deviceManager}
                    />
                    <label htmlFor="deviceManager">Device Manager</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="posperipherals"
                        name="posperipherals"
                        value="formik.values.posperipherals"
                        onChange={formik.handleChange}
                        checked={formik.values.posperipherals}
                    />
                    <label htmlFor="posperipherals">Peripherals</label>
                </div>
                <div className="field-checkbox col-12 col-md-3 pl-0">
                    <Checkbox
                        inputId="birinfo"
                        name="birinfo"
                        value="formik.values.birinfo"
                        onChange={formik.handleChange}
                        checked={formik.values.birinfo}
                    />
                    <label htmlFor="birinfo">BIR Info</label>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button type="submit" label="Apply" icon="pi pi-check" />
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};
export default connect(mapStateToProps)(DuplicateStoreForm);
