import produce from 'immer';
import React from 'react';
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import {
    dataSyncLogsSearchByFields,
    crudReset,
    resetAction,
    searchGeneral,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleTable from '../../../components/SimpleTable';
import { formatDate, permissions } from '../../../helpers/Util';
import * as CONSTANTS from '../../../constants/constants';
import ViewDataSyncLog from './ViewDataSyncLog';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

class DataSyncLog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'LOGS';
        this.group = 'DE_LOGS_DATASYNC';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 100;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            date_from: new Date(),
            date_till: new Date(),
            fromtime: '00:00',
            tilltime: '23:59',

            tableCols: [
                {
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                },
                {
                    dataField: 'topic',
                    text: 'Topic',
                    sort: true,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },
                {
                    dataField: 'error',
                    text: 'Error',
                    sort: true,
                },
                {
                    dataField: 'action',
                    text: 'Action',
                    sort: true,
                },
                {
                    dataField: 'recid',
                    text: 'Rec Id',
                    sort: true,
                },
                {
                    dataField: 'recpk',
                    text: 'Rec Pk',
                    sort: true,
                },
                {
                    dataField: 'msgcode',
                    text: 'Msg Code',
                    sort: true,
                },
                {
                    dataField: 'createdon',
                    text: 'CreatedOn',
                    sort: true,
                },

                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'ED',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],

            id: '',
            topic: '',
            status: '',
            recid: '',
            recpk: '',
            msgcode: '',

            pagesize: this.pageSize,

            showLogDetails: false,
            selectedLog: {},
        };
    }

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span>
                <img
                    title="View"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    onClick={() => this.viewLogDetails(row)}
                    alt="ViewButton"
                />
            </span>
        );
    };

    viewLogDetails = (row) => {
        // this.props.dispatch(apiLogsById(row.id))
        this.setState({ selectedLog: row, showLogDetails: true });
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const {
            sort,
            pageSize,
            currPage,
            // date_from,
            // date_till,
            // fromtime,
            // tilltime
        } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    sort: 'topic',
                    pagesize: pageSize,
                    pageno: currPage,
                    // date_from: formatDate(date_from),
                    // date_till: formatDate(date_till),
                    // date_from_time: fromtime,
                    // date_till_time: tilltime
                },
            };
            return requestBody;
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/datasynclog/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_DATASYNC_LOGS_SUCCESS,
                CONSTANTS.SEARCH_DATASYNC_LOGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        // var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            // case "SEARCH":
            //   this.callFetch("SEARCH");
            //   break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
                break;
        }
    };

    componentWillMount() {
        this.callQueryApiForFetch('ONLOAD');
        this.allPermissions = permissions(this.module, this.group);
    }

    handleChange(time, name) {
        let timeformat = moment(time).format('HH:mm');
        this.setState({ [name]: timeformat }, () => {
            //this.updateStateValues();
        });
    }

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        // if (refresh === true) {
        //   this.callQueryApiForFetch("ONLOAD");
        // }
    };

    getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.id,
            e.title,
            e.promocode,
            e.promogroup,
            e.storeid,
            e.promotypevalue,
            e.categorytypevalue,
            e.validfrom,
            e.validtill,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    _hideLogDetails = () => {
        this.props.queryData.apilog = {};
        this.setState({ showLogDetails: false });
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            datasynclogs,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (this.state.showLogDetails) {
            return (
                <>
                    <ViewDataSyncLog
                        rec={this.state.selectedLog}
                        logid={this.state.selectedLog.id}
                        hideLogDetails={this._hideLogDetails}
                    />
                </>
            );
        } else {
            return (
                <>
                    <div className="form_height">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2 className="page-header">
                                    Data Sync Logs
                                    <img
                                        title="Refresh"
                                        onClick={() => {
                                            this.callQueryApiForFetch('ONLOAD');
                                        }}
                                        className="refreshtree"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                        alt="RefreshButtonImage"
                                    />
                                </h2>
                            </div>
                        </div>

                        <div className="row form-container">
                            <div className="col">
                                <form className="frmDiv">
                                    <div className="row">
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft required">
                                                Date From
                                            </label>
                                            <DatePicker
                                                onChange={(value) => {
                                                    this.setState({
                                                        date_from: value,
                                                    });
                                                }}
                                                value={this.state.date_from}
                                            />
                                        </div>
                                        <div className="form-group col-lg-1 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                From Time
                                            </label>
                                            <TimePicker
                                                value={
                                                    moment(
                                                        this.state.fromtime,
                                                        'HH:mm'
                                                    ).isValid()
                                                        ? moment(
                                                              this.state
                                                                  .fromtime,
                                                              'HH:mm'
                                                          )
                                                        : ''
                                                }
                                                name="fromtime"
                                                onChange={(time) =>
                                                    this.handleChange(
                                                        time,
                                                        'fromtime'
                                                    )
                                                }
                                                showSecond={false}
                                                allowEmpty={false}
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft required">
                                                Date Till
                                            </label>
                                            <DatePicker
                                                minDate={this.state.date_from}
                                                onChange={(value) => {
                                                    this.setState({
                                                        date_till: value,
                                                    });
                                                }}
                                                value={this.state.date_till}
                                            />
                                        </div>
                                        <div className="form-group col-lg-1 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Till Time
                                            </label>
                                            <TimePicker
                                                value={
                                                    moment(
                                                        this.state.tilltime,
                                                        'HH:mm'
                                                    ).isValid()
                                                        ? moment(
                                                              this.state
                                                                  .tilltime,
                                                              'HH:mm'
                                                          )
                                                        : ''
                                                }
                                                name="tilltime"
                                                onChange={(time) =>
                                                    this.handleChange(
                                                        time,
                                                        'tilltime'
                                                    )
                                                }
                                                showSecond={false}
                                                allowEmpty={false}
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Topic
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="topic"
                                                value={this.state.topic}
                                                onChange={(event) =>
                                                    this.setState({
                                                        topic: event.target
                                                            .value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Status
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="status"
                                                value={this.state.status}
                                                onChange={(event) =>
                                                    this.setState({
                                                        status: event.target
                                                            .value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Msg Code
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="msgcode"
                                                value={this.state.msgcode}
                                                onChange={(event) =>
                                                    this.setState({
                                                        msgcode:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Rec Id
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="recid"
                                                value={this.state.recid}
                                                onChange={(event) =>
                                                    this.setState({
                                                        recid: event.target
                                                            .value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Rec Pk
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="recpk"
                                                value={this.state.recpk}
                                                onChange={(event) =>
                                                    this.setState({
                                                        recpk: event.target
                                                            .value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="col-lg-5 col-sm-12" />

                                        <div
                                            className="col-lg-3 col-sm-12 btn-container1"
                                            style={{ marginTop: '1em' }}
                                        >
                                            <button
                                                type="submit"
                                                onClick={(e) =>
                                                    this._searchDataSyncLogs(e)
                                                }
                                                className="btn btn-themes btn-rounded"
                                            >
                                                Search
                                            </button>

                                            <button
                                                type="submit"
                                                onClick={
                                                    this._clearFiltersAndResults
                                                }
                                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    </div>

                                    <div className="title_devide" />
                                    <SimpleTable
                                        columns={this.state.tableCols}
                                        rows={datasynclogs}
                                        pageChangeCallback={(currPage) => {
                                            this.setState(
                                                {
                                                    currPage: currPage,
                                                },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                        callback={(pageSize) => {
                                            this.setState(
                                                produce(this.state, (draft) => {
                                                    if (
                                                        draft.pageSize <
                                                        pageSize
                                                    ) {
                                                        draft.currPage = 1;
                                                    }
                                                    draft.pageSize = pageSize;
                                                }),
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'PAGECLICK'
                                                    );
                                                }
                                            );
                                        }}
                                        // withoutSearchBar={true}
                                        withPageSize={this.state.pageSize}
                                        withCurrPage={this.state.currPage}
                                        withPageFeedback={true}
                                        hideSizePerPage={true}
                                    />
                                    {/* <TableFooter /> */}
                                </form>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={isSuccess}
                            handleClose={this.hideAlertModal}
                            children={
                                <div
                                    style={
                                        isSuccess
                                            ? { padding: '2em', color: 'green' }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {isSuccess ? this.state.message : ''}
                                </div>
                            }
                        />

                        <ConfirmAlert
                            show={errormsg}
                            handleClose={(event) =>
                                this.hideErrorAlertModal(event, refresh)
                            }
                            children={
                                <div
                                    style={
                                        errormsg
                                            ? {
                                                  padding: '2em',
                                                  color: 'red',
                                                  fontWeight: '500',
                                              }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {errormsg ? `${errormsg}.` : ''}
                                </div>
                            }
                        />
                    </div>
                </>
            );
        }
    }

    _searchDataSyncLogs = (e) => {
        if (e) {
            e.preventDefault();
        }
        const {
            date_from,
            date_till,
            fromtime,
            tilltime,

            topic,
            status,
            recid,
            recpk,
            msgcode,
        } = this.state;

        this.requestBody = {};
        const queryParams = [];

        if (date_from === '' && date_till === '') {
            //do nothing
        } else {
            if (date_from !== '' && date_till !== '') {
                queryParams.push(`date_from=${formatDate(date_from)}`);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            } else if (date_from !== '') {
                queryParams.push(`date_from=${formatDate(date_from)}`);
                queryParams.push(`date_till=${formatDate(date_from)}`);
            } else if (date_till !== '') {
                queryParams.push(`date_from=${formatDate(date_till)}`);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            }
            this.requestBody['date_from'] = formatDate(date_from);
            this.requestBody['date_till'] = formatDate(date_till);
            this.requestBody['date_till_time'] = tilltime;
            this.requestBody['date_from_time'] = fromtime;
            queryParams.push(`date_till_time=${tilltime}`);
            queryParams.push(`date_from_time=${fromtime}`);
        }

        if (topic !== '') {
            queryParams.push(`topic=${topic}`);
            this.requestBody['topic'] = topic;
        }
        if (status !== '') {
            queryParams.push(`status=${status}`);
            this.requestBody['status'] = status;
        }
        if (recid !== '') {
            queryParams.push(`recid=${recid}`);
            this.requestBody['recid'] = recid;
        }
        if (recpk !== '') {
            queryParams.push(`recpk=${recpk}`);
            this.requestBody['recpk'] = recpk;
        }
        if (msgcode !== '') {
            queryParams.push(`msgcode=${msgcode}`);
            this.requestBody['msgcode'] = msgcode;
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        queryParams.push(`sort=topic`);
        //query
        this.props.dispatch(dataSyncLogsSearchByFields(queryParams.join('&')));
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            id: '',
            topic: '',
            status: '',
            actionstr: '',
            recid: '',
            recpk: '',
            msgcode: '',

            date_from: new Date(),
            date_till: new Date(),
            fromtime: '00:00',
            tilltime: '23:59',
        });

        // does a reload
        this.callQueryApiForFetch('ONLOAD');
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DataSyncLog);
