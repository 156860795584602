import React from "react";
import { connect } from "react-redux";
import SimpleTable from "../../../../components/SimpleTable";
import { 
  searchSpecialDiscount, 
  searchSpecialDiscountPid,
  deleteTaxConfiguration, 
  crudReset,
  
  specialDiscountSearchByFields
} from "../../../../actions/actions";

import ConfirmAlert from "../../../../components/ConfirmAlert";
import { produce } from "immer";
import Loading from "../../../../components/Loading";
import LinkButton from "../../../../components/LinkButton";
import { Link } from "react-router-dom";
import { canDelete, canView, canWrite, permissions } from "../../../../helpers/Util";
import StatusOptionComp from '../../../../components/StatusOptionComp'

class DisplaySpecialDiscount extends React.PureComponent {

  companyId = localStorage.getItem("company_id")
  group = "DE_STOREMGMT_SPECIAL_DISCOUNT"
  module = "STOREMGMT"
  //allAccesses = {}
  allPermissions = []//permissions()

  statusOptions = [
    { name: "Select", id: "" },
    { name: "Active", id: "Active" },
    { name: "Inactive", id: "Inactive" },
    { name: "Updated", id: "Updated" },
    { name: "Deleted", id: "Deleted" },
]

  constructor(props) {
    super(props);

    this.currPage = 1
    this.pageSize = 10

    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,

      openDeleteprompt: false,
      delete_rec_id: "",
      
      id: "",
      categorycode: "",
      title: "",
      description: "",
      catgroup: "",
      catvalue: "",
      status: "",
      lastupdated: "",

      columns: [
        {
          dataField: "categorycode",
          text: "Category Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "catgroup",
          text: "Catgroup",
          sort: true
        },
        {
          dataField: "catvalue",
          text: "Catvalue",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "lastupdated",
          text: "Last Updated",
          sort: true,
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    
    if (row.status === "Deleted") { return }

    return (
      <span>
        {canView(this.allPermissions) && (
        <Link
          to={{
            pathname: "/ui/specialdiscount/addNew",
            state: { item: row, isEdit: false, isView: true }
          }}
        >
          <img
            title="View"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
            alt="ViewButtonImage"
          />
        </Link>
        )}

        {canWrite(this.allPermissions) && (
        <Link
          to={{
            pathname: "/ui/specialdiscount/addNew",
            state: { item: row, isEdit: true }
          }}
        >
          <img
            title="Edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            alt="EditButtonImage"
          />
        </Link>
        )}

        {canDelete(this.allPermissions) && (
          <img
            title="Delete"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.deleteBrokerClient(row)}
            alt="DeleteButtonImage"
          />
        )}
      </span>
    );
    
  };

  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage }, () =>
      this.callQueryApiForFetch("SEARCH")
    );
    //this.accesses()
    this.allPermissions = permissions(this.module, this.group)
    console.log('allPermissions:', this.allPermissions)
  }

  createFetchUrl = (fetchType) => {
    //const { search_field, search_condi, search_value } = this.state;
    const { pageSize, currPage } = this.state
    const requestBody = {
      body: {
        pagesize: pageSize,
        pageno: currPage,
        cached: "N"
      }
    };
    return requestBody;
  };

  // call search api here to fetch data
  callFetch = fetchType => {
    //const { pages, currPage } = this.state;
    this.props.dispatch(
      searchSpecialDiscount(
        "sales/v1/category/categorytree/SPECIALDISCOUNT",
        //this.createFetchUrl(pages, currPage),
        this.createFetchUrl(fetchType),
        "SEARCH_SPECIALDISCOUNT_SUCCESS",
        "SEARCH_SPECIALDISCOUNT_FAILURE"
      )
    );

    // called the second time to retrieve pid for searchbyfields call
    this.props.dispatch(
      searchSpecialDiscountPid(
        "sales/v1/category/categorytree/SPECIALDISCOUNT",

        this.createFetchUrl(fetchType),
        "SEARCH_SPECIALDISCOUNT_PID_SUCCESS",
        "SEARCH_SPECIALDISCOUNT_PID_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    //var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        this.callFetch();
        break;
      default:
          break
    }
  };

  // call delete api here to delete an item by row id
  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(
        deleteTaxConfiguration("sales/v1/category/delete", this.state.delete_rec_id)
      );
    }
  };

  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };

  deleteBrokerClient = rowData => {
    this.setState({ message: "Special Discount deleted successfully" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };

  _showAsTable = arg => {
    this.setState({ showAsTable: arg });
  };

  _searchSpecialDiscount = (e) => {
    
    // use the id of specialdiscount as pid to search for results by fields
    const { specialdiscountpid } = this.props.queryData;
    let _pid = specialdiscountpid.id;
    console.log("amanda, specialdiscount id:", _pid)

    if (e) { e.preventDefault() }
    console.log('_search Special Discount')
    const {
      id,
      title,
      description,
      catgroup,
      catvalue,
      status

    } = this.state

    this.requestBody = {}
    const queryParams = []

    if (_pid !== '') {
      queryParams.push(`pid=${_pid}`)
      this.requestBody["pid"] = _pid
    }

    if (id !== '') {
      queryParams.push(`id=${id}`)
      this.requestBody["id"] = id
    }
    if (title !== '') {
      let encoded = encodeURIComponent(title);
      queryParams.push(`title=${encoded}`)
      this.requestBody["title"] = encoded
    }
    if (description !== '') {
      let encoded = encodeURIComponent(description);
      queryParams.push(`description=${encoded}`)
      this.requestBody["description"] = encoded
    }
    if (catgroup !== '') {
      queryParams.push(`catgroup=${catgroup}`)
      this.requestBody["catgroup"] = catgroup
    }
    if (catvalue !== '') {
      queryParams.push(`catvalue=${catvalue}`)
      this.requestBody["catvalue"] = catvalue
    }
    if (status !== '') {
      queryParams.push(`status=${status}`)
      this.requestBody["status"] = status
    }
    
    queryParams.push(`pagesize=${this.pageSize}`)
    queryParams.push(`pageno=${this.currPage}`)

    console.log('requestBody::', this.requestBody)
    //query
    this.props.dispatch(specialDiscountSearchByFields(queryParams.join('&')))
    // if (e) { e.preventDefault() }
  }
  

  _clearFiltersAndResults = (e) => {
    if (e) { e.preventDefault() }
    //clear all the filters
    this.setState({
      id: "",
      title: "",
      description: "",
      catgroup: "",
      catvalue: "",
      status: "",
    })
    //does an auto refresh to display data
    this.callQueryApiForFetch("ONLOAD");
  }

  _renderHeader = () => {
    return (
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h1 className="page-header">
            Special Discount
            <img
              title="Refresh"
              onClick={() => {
                this.callQueryApiForFetch("ONLOAD");
              }}
              className="refreshtree"
              src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
              alt="RefreshButtonImage"
            />
          </h1>
        </div>
        <div className="col-6 alignRight alignWithPageHeader">
          {this._renderAddNewBtn()}
        </div>
      </div>
    );
  };

  _renderSearchRow = () => {

    return (
      <>
      <div className="row">
          <div className="form-group col-lg-2 col-sm-12">
            <label className="col-form-label alignLeft">
              Id
            </label>
            <input
              type="text"
              className="form-control"
              id="Id"
              value={this.state.id}
              onChange={event =>
                  this.setState({ id: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label className="col-form-label alignLeft">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event =>
                  this.setState({ title: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label className="col-form-label alignLeft">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              value={this.state.description}
              onChange={event =>
                  this.setState({ description: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label className="col-form-label alignLeft">
              Catgroup
            </label>
            <input
              type="text"
              className="form-control"
              id="catgroup"
              value={this.state.catgroup}
              onChange={event =>
                  this.setState({ catgroup: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label className="col-form-label alignLeft">
              Catvalue
            </label>
            <input
              type="text"
              className="form-control"
              id="catvalue"
              value={this.state.catvalue}
              onChange={event =>
                  this.setState({ catvalue: event.target.value })
              }
            />
          </div>
          <div className="form-group col-lg-2 col-sm-12">
            <label className="col-form-label alignLeft">
              Status
            </label>
            <StatusOptionComp
              statusoption={this.statusOptions}
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                  this.setState({ status: value });
              }}
            />
          </div>
          
            
          <div className="col-md-auto" />

        </div>

        <div className="row">
          
          <div className="col-lg-9 col-sm-12" />
          <div className="col-lg-3 col-sm-12 btn-container1" style={{ marginTop: "1em" }}>
            <button
              type="submit"
              onClick={(e) => this._searchSpecialDiscount(e)}
              className="btn btn-themes btn-rounded"
              style={{ float: "right"}}
            >
              Search
            </button>

            <button
              type="submit"
              onClick={this._clearFiltersAndResults}
              className="btn btn-themes btn-rounded btn-sec link-sec-btn"
              style={{ float: "right" }}
            >
              Clear
            </button>
          </div>

        </div>
      </>
    );
  };

  _renderAddNewBtn = () => {

    const { specialdiscountpid } = this.props.queryData;
    let _pid = specialdiscountpid.id;
    //console.log("amanda, specialdiscount id:", _pid)
    
    if (this.allPermissions.includes('Write')) {
    //if (this.hasPermission('Write')) {
      return (
        <LinkButton
          to={{
            pathname: "/ui/specialdiscount/addNew",
            state: { pid: _pid}
          }}
          className="page-header-btn icon_btn display-inline"
          onClick={event => event.preventDefault()}
        >
          <img
            title="edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
            alt="AddNewBtnLogoImage"
          />
          {`Add New`}
        </LinkButton>
      );
    }

  };

  render() {
    const { isSuccess, errormsg } = this.props.crudData;
    const { specialdiscounts } = this.props.queryData;

    let _specialdiscounts = specialdiscounts.length !== undefined && specialdiscounts;

    // // There can be 2 sets of specialdiscounts here depending whether it is search by fields or normal search
    // var _specialdiscountsarr = []
    // if (specialdiscounts.categorycode === "SPECIALDISCOUNT") {
    //   console.log("This is results from general search")
    //   // results searched from general search
    //   if (specialdiscounts['subcategories'] !== undefined) {
    //     specialdiscounts.subcategories.forEach(e=> {
    //       _specialdiscountsarr.push(e)
    //     })
    //   }
    // }
    // else{
    //   // results searched from searchbyfields
    //   //_specialdiscountsarr = specialdiscounts.length !== undefined && specialdiscounts
    //   console.log("This is results searchbyfield::", specialdiscounts)
    // }

    // console.log("specialdiscountarr::", _specialdiscountsarr)



    // var _specialdiscountsarr = []
    // if (specialdiscounts === undefined 
    //   || specialdiscounts['subcategories'] === undefined 
    //   || specialdiscounts['subcategories'].length <= 0) 
    // {
    //   // do nothing
    // } else {
    //   specialdiscounts.subcategories.forEach(e=> {
    //     //console.log("Amanda each subcat::", e)
    //     _specialdiscountsarr.push(e)
    //   })
    // }

    
    if (this.props.queryData.apiPending) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-12">{this._renderHeader()}</div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <form className="formDiv">

                {this._renderSearchRow()}
                <div className="invalid-feedback">{errormsg}</div>
                <SimpleTable
                  columns={this.state.columns}
                  rows={_specialdiscounts}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    },()=>{
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pageSize < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pageSize = pageSize
                    }), () => { this.callQueryApiForFetch("PAGECLICK")
                  })
                  }}
                  // withoutSearchBar={true}
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div style={{ color: "green", padding: "2em" }}>
                Are you sure you want to delete this Special Discount?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplaySpecialDiscount);
