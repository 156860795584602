import React from 'react';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    deletePropertyConfig,
    publisArticleInfoApi,
    unpublishArticleInfoApi,
    createPropertyConfig,
    searchGeneral,
    crudReset,
    resetAction,
    updateDefaultConfig,
} from '../../../actions/actions';
import ConfirmAlert from './../../../components/ConfirmAlert';
import MultiSelect from './../../../components/MultiSelect';
import {
    canDelete,
    canView,
    canWrite,
    formatLanguageArray,
    permissions,
    sortByGroup,
} from '../../../helpers/Util';
import { searchFields } from './tablesCols';
import Loading from './../../../components/Loading';
import SimpleSearcher from './../../../components/SimpleSearcher';
import PrimeTable from '../../../components/PrimeTable';

class DisplayPropConfig extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'CMS';
        this.group = 'DE_CMS_PROPERTY_CONFIG';
        this.allPermissions = [];

        this.state = {
            pages: 10,
            currPage: 1,
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            arttype: '',
            artMode: '',
            language: '',
            langlist: {},
        };
    }

    actionTemplate = (row) => {
      if (row.status === 'Deleted') {
        return;
    }

    return (
        <span>
            {canView(this.allPermissions) && (
                <Link
                    to={{
                        pathname: '/ui/property-config/addNew',
                        state: {
                            item: row,
                            isEdit: false,
                            isView: true,
                        },
                    }}
                >
                    <img
                        title="View"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                    />
                </Link>
            )}

            {canWrite(this.allPermissions) && (
                <Link
                    to={{
                        pathname: '/ui/property-config/addNew',
                        state: {
                            item: row,
                            isEdit: true,
                        },
                    }}
                >
                    <img
                        title="Edit"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                    />
                </Link>
            )}

            {canDelete(this.allPermissions) && (
                <img
                    title="Delete"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                    onClick={() => this.deleteStoreInfo(row)}
                />
            )}

            {canWrite(this.allPermissions) && (
                <img
                    title="Copy"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                    onClick={() => this.copyRecord(row)}
                />
            )}
        </span>
    );
    }

    componentWillReceiveProps = (prevProps) => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }
    };

    // API Calls
    createFetchUrl = (pagesize, pageno) => {
        const { search_field, search_condi, search_value, language } =
            this.state;

        const requestBody = {
            body: {
                language: language.toString(),
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
                pagesize,
                pageno
            },
        };
        return requestBody;
    };

    callFetch = (pagesize, pageno) => {
        this.props.dispatch(
            searchGeneral(
                '/cms/v1/prop/config/search',
                this.createFetchUrl(pagesize, pageno),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    handlePageSizeChange = (pageSize) => {
        this.setState({ pages: pageSize })
        this.callFetch(pageSize, this.state.currPage)
    }

    handlePageChange = pageno => {
        this.setState({ pageno: pageno })
        this.callFetch(this.state.pages, pageno)
    }

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch(this.state.pages, this.state.currPage);
                break;
            case 'SEARCH':
                this.callFetch(this.state.pages, this.state.currPage);
                break;
            case 'PAGECLICK':
                const [pgNumber] = rest;

                newState = produce(this.state, (draft) => {
                    draft.currPage = pgNumber - 1;
                });
                this.setState({ ...newState }, () => {
                    this.callFetch('PAGECLICK');
                });

                break;
        }
    };

    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            }
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        const articleMode = this.props.queryData.articleMode;
        this.setState(
            {
                language: defLanguage.toString().split(','),
                artMode: articleMode,
            },
            () => this.callQueryApiForFetch('ONLOAD')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    getCreatePayload = (row) => {
        const requestdata = {
            body: {
                ...row,
                propcode: '[COPY-' + row.propcode + ']' + row.propcode,
            },
        };
        return requestdata;
    };

    copyRecord = (row) => {
        this.setState({ message: 'Item Copied Suucessfully' });
        this.props.dispatch(createPropertyConfig(this.getCreatePayload(row)));
    };

    _renderAddNewBtn = () => {
        const { history } = this.props
          if (!canWrite(this.allPermissions)) {
              return;
          }
  
          return (
            <Button
                label="Add New"
                icon="pi pi-plus"
                className="p-button-outlined"
                onClick={() => history.push('/ui/property-config/addNew')}
            />
          );
      };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                unpublishArticleInfoApi(this.state.delete_rec_id)
            );
            this.props.dispatch(deletePropertyConfig(this.state.delete_rec_id));
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };
    deleteStoreInfo = (rowData) => {
        this.setState({
            message: 'Property Configuration deleted successfully',
        });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };
    unpublishArticle = (rowData) => {
        this.setState({ message: 'Article unpublished successfully' });
        this.props.dispatch(unpublishArticleInfoApi(rowData.id));
    };
    publishArticle = (rowData) => {
        this.setState({ message: 'Article published successfully' });
        this.props.dispatch(publisArticleInfoApi(rowData.id));
    };
    render() {
        const {
            articleFlowData,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;

        const { isSuccess, errormsg: crudError } = this.props.crudData;
        if (
            !articleFlowData ||
            this.props.queryData.apiPending ||
            this.props.crudData.crudApiPending
        ) {
            return <Loading />;
        }

        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (!articleFlowData && !errormsg) {
            return <Loading />;
        }
        if (!articleFlowData || this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
          { field: 'propcode', header: 'Property Code' },
          { field: 'propgroup', header: 'Property Group' },
          { field: 'propvalue', header: 'Property Value' },
          { field: 'status', header: 'Status' },
          { field: 'storeid', header: 'Store Id' }
      ];

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">
                            Property Configuration
                            <img
                                title="Refresh"
                                onClick={() => {
                                    this.callQueryApiForFetch('ONLOAD');
                                }}
                                className="refreshtree"
                                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                alt="RefreshButton"
                            />
                        </h2>
                    </div>
                    <div className="col-lg-6 col-sm-12 alignRight alignWithPageHeader">
                            {this._renderAddNewBtn()}
                        </div>
                </div>
                <div className="row form-container">
                    <div className="col">
                        <form className="frmDiv">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12 noPadding noMargin">
                                </div>

                                <div className="col-lg-4 col-sm-12 alignRight noPadding noMargin">
                                    <SimpleSearcher
                                        filterOptions={searchFields}
                                        setAndSearch={this.searchQuery}
                                        search_field={this.state.search_field}
                                        search_condi={this.state.search_condi}
                                        search_value={this.state.search_value}
                                    ></SimpleSearcher>
                                </div>
                                <div className="col-lg-2 col-sm-12 noPadding noMargin alignRight">
                                    <MultiSelect
                                        filterOptions={formatLanguageArray(
                                            this.state.langlist
                                        )}
                                        setSelectedFieldValues={(value) => {
                                            this.setState(
                                                { language: value },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'ONLOAD'
                                                    );
                                                }
                                            );
                                            this.props.dispatch(
                                                updateDefaultConfig(
                                                    {
                                                        body: {
                                                            language: value,
                                                        },
                                                    },
                                                    () => {
                                                        this.callQueryApiForFetch(
                                                            'ONLOAD'
                                                        );
                                                    }
                                                )
                                            );
                                        }}
                                        id="language"
                                        itemsSelected={this.state.language}
                                        label="Select Language"
                                    />
                                </div>
                            </div>
                            <div className="title_devide" />
                            <div className="invalid-feedback">{errormsg}</div>
                            <PrimeTable
                                responsive
                                list={articleFlowData.length > 0
                                    ? sortByGroup(articleFlowData)
                                    : articleFlowData
                                }
                                columns={columns}
                                actionColumn={this.actionTemplate}
                                pageSizeChangecallback={(pageSize) => {
                                    this.handlePageSizeChange(pageSize)
                                }}
                                pageChangeCallback={(currPage) => {
                                    this.handlePageChange(currPage)
                                }}
                            />
                        </form>
                    </div>
                </div>
                <ConfirmAlert
                    show={this.state.openDeleteprompt}
                    isPrompt={true}
                    children={
                        <div className="deleteAlertDiv">
                            Are you sure you want to delete this property
                            configuration ?
                            <div style={{ margin: '2em' }}>
                                <button
                                    className="btn btn-themes btn-rounded btn-sec "
                                    onClick={() => this.shdDeleteRec(false)}
                                >
                                    No
                                </button>
                                <button
                                    className="btn btn-themes  btn-rounded deletBtn"
                                    onClick={() => this.shdDeleteRec(true)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    }
                />
                <ConfirmAlert
                    show={isSuccess}
                    handleClose={this.hideAlertModal}
                    children={
                        <div
                            style={
                                isSuccess
                                    ? { padding: '2em', color: 'green' }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {isSuccess ? this.state.message : ''}
                        </div>
                    }
                />
                <ConfirmAlert
                    show={errormsg}
                    handleClose={(event) =>
                        this.hideErrorAlertModal(event, refresh)
                    }
                    children={
                        <div
                            style={
                                errormsg
                                    ? {
                                          padding: '2em',
                                          color: 'red',
                                          fontWeight: '500',
                                      }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {errormsg ? `${errormsg}.` : ''}
                        </div>
                    }
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayPropConfig);
