import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    searchGeneral,
    queryPropertyConfigByFields,
} from '../../../actions/actions';
import { Dropdown } from 'primereact/dropdown';
import {
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
} from '../../../constants/constants';

const generateOptionList = (list, key, label) =>
    list.map((item) => ({
        name: item[key],
        code: item[label],
    }));

const SalePersonSelector = props => {
    const { onChange, salespersonid, storeid } = props
    const { masterTrxGrpTypes } = props.queryData
    const { usersByuserId } = props.queryData
    const [salePerson, setSalePerson] = useState('')
    const [salePersonArr, setSalePersonArr] = useState([])

    useEffect(() => {
        props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'SALE_PERSON_GROUP_ID',
                    },
                },
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE
            )
        ); 
    }, [])

    useEffect(() => {
        if (masterTrxGrpTypes && masterTrxGrpTypes.length > 0 && masterTrxGrpTypes[0].additionalfields) {
            const { groupid } = masterTrxGrpTypes[0].additionalfields
            props.dispatch(
                searchGeneral(
                    'system/v1/user/search/fields',
                    { body: { usergroup: groupid, status: 'active' } },
                    'SEARCH_USERS_BY_USERID_SUCCESS',
                    'SEARCH_USERS_BY_USERID_FAILURE'
                )
            );
        }
        
    }, [masterTrxGrpTypes])

    useEffect(() => {
        let filterUsers = usersByuserId.filter(u => u.store_access.includes(storeid))
        let optionList = generateOptionList(
            filterUsers,
            'firstname',
            'usercode'
        )
        optionList.unshift({ name: 'Select', code: ''})
        setSalePersonArr(optionList)
    }, [usersByuserId, storeid])

    useEffect(() => {
        if (salePersonArr.length > 0) {
            setSalePerson(salePersonArr.find(u => u.code == salespersonid))
        }
    }, [salespersonid, salePersonArr])

    const handleUserChange = e => {
        setSalePerson(e.target.value)
        onChange(e.target.value)
    }
    
    return (
        <>
            <Dropdown
                className='w-100'
                id="salePersonGroup"
                name="salePersonGroup"
                value={salePerson}
                onChange={handleUserChange}
                options={salePersonArr}
                optionLabel="name"
                placeholder="Select"
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData
    };
};

export default connect(mapStateToProps)(SalePersonSelector);