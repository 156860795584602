import React, { Component } from 'react';
import Select, { components } from 'react-select';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class StoreSelection extends Component {

    constructor(props) {
        super(props);

        this.isMulti = true;
        this.closeMenuOnSelect = false;
        if (this.props.isMulti !== 'undefined') {
            this.isMulti = this.props.isMulti;
        }
        if (this.props.closeMenuOnSelect !== 'undefined') {
            this.closeMenuOnSelect = this.props.closeMenuOnSelect;
        }

        this.state = {
            optionSelected: this.props.selectedStores,
            storesList: [],
        };
    }

    handleChange = (selected) => {
        this.defaultStore = '';

        this.setState({
            optionSelected: selected,
        });

        this.props.updateSelectedStore &&
            this.props.updateSelectedStore(selected);
    };

    render() {
        const stores = this.props.stores;
        let storeSelectOptions = [];
        stores.map((store) => {
            let item = {
                label: '(' + store.storeid + ') ' + store.title,
                value: store.storeid,
            };
            storeSelectOptions.push(item);
        });
        return (
            <React.Fragment>
              <Select
                    options={storeSelectOptions}
                    isMulti={this.isMulti}
                    closeMenuOnSelect={this.closeMenuOnSelect}
                    hideSelectedOptions={false}
                    components={{
                        Option,
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={true}
                    value={this.state.optionSelected}
                />
            </React.Fragment>
        );
    }
}
export default StoreSelection;
