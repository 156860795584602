import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import {
  createArticleInfoApi,
  updateArticleInfoApi,
  //getStoreById
} from "../../../actions/actions";
// Local Imports
// Local Imports
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleSelect from "../../../components/SimpleSelect";
import ImagePick from "../../../components/ImagePick";
import StatusComp from "../../../components/StatusComp";
import { crudReset } from "../../../actions/actions";
import { produce } from "immer";
import { formatDate } from "../../../helpers/Util";
class AddCouponInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: false,
      // Formrelated
      couponcode: "",
      title: "",
      desc: "",
      langlist: {},
      language: "",
      termscondi: "",
      titlelong: "",
      startdate: new Date(),
      expirydate: new Date(),
      publishmode: "",
      publishdate: "",
      unpublishdate: "",
      storeid: "",
      images: {},
      status: "New",
      sortorder: 0 ,     
      //errors
      couponcodeerror: "",
      titleerror: "",
      descerror: "",
      termscondierror: "",
      titlelongerror: ""
    };
  }

  getCreatePayload = () => {
    const {
      couponcode,
      desc,
      termscondi,
      title,
      titlelong,
      language,
      sortorder,
      startdate,
      expirydate,
      publishdate,
      unpublishdate,
      publishmode,
      storeid,
      status,
      images
    } = this.state;
    let body = {
      language: language,
      articletype: "Coupon Information",
      title,
      status: status,
      sortorder: sortorder,
      startdate: formatDate(startdate),
      expirydate: formatDate(expirydate),
      publishdate,
      unpublishdate,
      publishmode,
      storeid,
      fields: {
      couponcode,
      titlelong,
      desc,
      termscondi
      },
      images: {
        image1: images.image1 || "",
        image2: images.image2 || "",
      }
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  handlestartdateChange = value => {
    this.setState({
      startdate: value
    });
  };
  handleexpirydateChange = value => {
    this.setState({
      expirydate: value
    });
  };

  _createCouponInformation = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      couponcodeerror: "",
      titleerror: "",
      descerror: "",
      termscondierror: "",
      titlelongerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const {
    couponcode,
    title,
    desc,
    termscondi,
    titlelong
    } = this.state;
    let valid = true;

    if (couponcode.trim() === "") {
      this.setState({ couponcodeerror: "Coupon Code is required." });
      valid = false;
    }
    if (title.trim() === "") {
      this.setState({ titleerror: "Coupon Title is required." });
      valid = false;
    }
    if (desc.trim() === "") {
      this.setState({
        descerror: "Description is required."
      });
      valid = false;
    }
    if (titlelong.trim() === "") {
      this.setState({
        titlelongerror: "Title long is required."
      });
      valid = false;
    }
    if (termscondi.trim() === "") {
        this.setState({ termscondierror: "Terms and Conditions required." });
        valid = false;
    }
    return valid;
  };

  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _isEditMode = () => {
    return this.state.isEdit;
  };

  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => {
              this._createCouponInformation();
            }}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createCouponInformation}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/coupon-info"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode()) {
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };

  _renderMainFormDetails = () => {
    const { couponcodeerror, titleerror } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="couponcode" className="floatLeft required">
              Coupon Code
            </label>
            <input
              type="text"
              className={
                couponcodeerror === "" ? "form-control" : "error-control"
              }
              id="title"
              value={this.state.couponcode}
              onChange={event => this.setState({ couponcode: event.target.value })}
            />
            <div className="invalid-feedback">{couponcodeerror}</div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className={
                titleerror === "" ? "form-control" : "error-control"
              }
              value={this.state.title}
              onChange={event =>
                this.setState({ title: event.target.value })
              }
            />
            <div className="invalid-feedback">{titleerror}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _showProductModal = event => {
    this.setState({
      showProdModal: true
    });
    if (event) event.preventDefault();
  };
  _closeProductModal = event => {
    this.setState({ showProdModal: false });
    if (event) event.preventDefault();
  };
  _updateProdCode = (param, param1) => {
    this.setState({ productcode: param, title: param1 }, () =>
      this._closeProductModal()
    );
  };

  _renderGeneralInformation = () => {
    const {
      descerror,
      termscondierror,
      titlelongerror
    } = this.state;
    return (
      <React.Fragment>
         <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="startdate" className="floatLeft">
              Start Date
            </label>
            <DatePicker
              minDate={this._isEditMode() ? this.state.startdate : new Date()}
              onChange={this.handlestartdateChange}
              value={this.state.startdate}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="expirydate" className="floatLeft">
              Expiry Date
            </label>
            <DatePicker
              minDate={this.state.startdate}
              onChange={this.handleexpirydateChange}
              value={this.state.expirydate}
            />
          </div>
        </div>
        <div className="row">
           <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="description" className="floatLeft required">
              Description
            </label>
            <input
              type="text"
              className={
                descerror === "" ? "form-control" : "error-control"
              }
              id="description"
              value={this.state.desc}
              onChange={event =>
                this.setState({ desc: event.target.value })
              }
            />
            <div className="invalid-feedback">{descerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="titlelong" className="floatLeft required">
             Title Long
            </label>
            <textarea
              className="form-control"
              id="titlelong"
              value={this.state.titlelong}
              onChange={event =>
                this.setState({ titlelong: event.target.value })
              }
            />
            <div className="invalid-feedback">{titlelongerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="termscondi" className="floatLeft required">
              Terms and Conditions
            </label>
            <textarea
              className="form-control"
              id="termscondi"
              value={this.state.termscondi}
              onChange={event =>
                this.setState({ termscondi: event.target.value })
              }
            />
            <div className="invalid-feedback">{termscondierror}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  _renderImageDetails = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Store Image </div>

        <div className="row">
          <div className="col-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={4}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row sub-title">Actions</div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              selectedOption={this.state.language}
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              id="language"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Sortorder
            </label>
            <input
              type="text"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderGeneralInformation()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderImageDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  componentWillMount = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          status,
          sortorder,
          fields,
          title,
          startdate,
          expirydate,
          publishdate,
          unpublishdate,
          publishmode,
          storeid,
          images
        } = rec;
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.couponcode = fields.couponcode || "";
            draft.title = title || "";
            //draft.status = status ? status : "New";
            draft.desc = fields.desc || "";
            draft.termscondi = fields.termscondi || "";
            draft.titlelong = fields.titlelong || "";
            draft.sortorder = sortorder ? sortorder : 0;
            draft.startdate = startdate ? this.toDate(startdate) : new Date();
            draft.expirydate = expirydate ? this.toDate(expirydate) : new Date();
            draft.publishdate = publishdate || "";
            draft.unpublishdate = unpublishdate || "";
            draft.publishmode = publishmode || "";
            draft.storeid = storeid ? storeid : "";
            draft.images = {
              image1: rec.hasOwnProperty("images") === true ? images.image1 : "",
              image2: rec.hasOwnProperty("images") === true ? images.image2 : "",
            };
          })
        );
      }
    }
  };

  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode()
                  ? "Edit Coupon Information"
                  : "Add Coupon Information"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/coupon-info"            
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode()
                  ? "Coupon Information updated successfully"
                  : "Coupon Information created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddCouponInfo);
