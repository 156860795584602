import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { produce } from 'immer';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import {
    updateUserProfile,
    createUserProfile,
    crudReset,
    createMemberTag,
    searchGeneral,
    searchTaxConfiguration,
    queryPropertyConfigByFields,
} from '../../../actions/actions';
import {
    SEARCH_PROP_CONFIG_FAILURE,
    SEARCH_PROP_CONFIG_FIELDS,
    SEARCH_PROP_CONFIG_SUCCESS,
} from '../../../constants/constants';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleSelect from './../../../components/SimpleSelect';
import { objtoarr } from '../../../helpers/Util';
import UserTypeSelector from './UserTypeSelector';
import StoreSearchSingle from '../../../components/StoreComponents/StoreSearchSingle';
import CollectorSelector from './CollectorSelector';
import FileUploadDialog from './FileUploadDialog';
import DynamicTable from '../../../components/DynamicTable';

const signupConfigOptions = [
    { name: 'By Email', code: 'email' },
    { name: 'By Facebook', code: 'facebook' },
    { name: 'By Google', code: 'google' },
    { name: 'By Instagram', code: 'instagram' },
];

const getBase64 = (files, callback) => {
    let reader = new FileReader();

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        reader = new FileReader();
        reader.onload = (function (file) {
            return function (e) {
                callback(
                    file.name,
                    e.target.result.split(';')[1].split(',')[1]
                );
            };
        })(file);
        reader.readAsDataURL(file);
    }
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
};

class AddMemberProfile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            userid: '',
            signupby: 'email',
            socialid: '',
            memberid: '',
            firstname: '',
            lastname: '',
            password: '',
            membertype: '',
            membersegment: '',
            mobileno: '',
            emailid: '',
            storegroup: [],
            address: '',
            city: '',
            houseno: '',
            postal: '',
            image1url: {},
            formError: false,
            formErrorMsg: '',
            isEdit: false,
            isView: false,
            storegrouptitle: {},
            additionalFields: [{ field: '', value: '', id: 0 }],
            // error messages
            useriderror: '',
            email: '',
            firstnameerror: '',
            lastnameerror: '',
            doberror: '',
            emailiderror: '',
            mobilenoerror: '',
            passworderror: '',
            addressError: '',
            cityError: '',
            houseNoError: '',
            postalError: '',
            creditrating: '',
            creditstatus: '',
            salespersonid: '',
            collectorid: '',
            creditlimit: '',
            creditbalance: '',
            standarddiscount: '',
            contactno: '',
            longitude: '',
            latitude: '',
            taxnumber: '',
            companyname: '',
            gender: '',
            location: '',
            s_storelabel: '',
            storeid: '',
            storeiderror: '',
            companyNameError: '',
            companytinError: '',
            salespersonname: '',
            collectorname: '',
            status: 'New',
            dateofbirth: '',
            displayFileUploadDialog: false,
            taxvaluetype: '',
            taxvalue: '',
            customerGroupList: [],
            bizstyle: '',
            taxvaluetyperror: ''
        };
    }

    toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
    };

    createFetchUrl = () => {
        const requestBody = {
            body: {
                cached: 'N',
                catgroup: 'TAX',
            },
        };
        return requestBody;
    };

    componentWillMount = () => {
        const getstoregrouptitle = localStorage.getItem('storegrouptitle');
        this.setState({
            storegrouptitle: JSON.parse(getstoregrouptitle),
        });
        this.props.dispatch(
            searchTaxConfiguration(
                'sales/v1/category/search/fields',
                this.createFetchUrl(),
                'SEARCH_TAXCONFIGURATION_SUCCESS',
                'SEARCH_TAXCONFIGURATION_FAILURE'
            )
        );
        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: 'MEMBER_GROUP',
                    },
                },
                SEARCH_PROP_CONFIG_FIELDS,
                SEARCH_PROP_CONFIG_SUCCESS,
                SEARCH_PROP_CONFIG_FAILURE
            )
        );
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    userid,
                    signupby,
                    socialid,
                    memberid,
                    membergroup,
                    membergroupid,
                    firstname,
                    lastname,
                    mobileno,
                    emailid,
                    address,
                    city,
                    houseno,
                    postal,
                    membertype,
                    membersegment,
                    image1url,
                    storegroup,
                    additionalfields,
                    creditlimit,
                    creditrating,
                    creditstatus,
                    longitude,
                    latitude,
                    location,
                    standarddiscount,
                    collectorid,
                    salespersonid,
                    contactno,
                    creditbalance,
                    taxnumber,
                    companyname,
                    gender,
                    accesstype,
                    accesstypevalue,
                    storeid,
                    collectorname,
                    salespersonname,
                    status,
                    dateofbirth,
                    taxvaluetype,
                    taxvalue,
                } = rec;
                this.setState(
                    produce((draft) => {
                        draft.dateofbirth = dateofbirth
                            ? moment(dateofbirth, 'DD-MM-YYYY').toDate()
                            : '';
                        draft.status = status ? status : '';
                        draft.taxvaluetype = taxvaluetype ? taxvaluetype : '';
                        draft.taxvalue = taxvalue ? taxvalue : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.s_storelabel =
                            typeof additionalfields !== 'undefined' &&
                            additionalfields !== null &&
                            additionalfields.s_storelabel
                                ? additionalfields.s_storelabel
                                : '';
                        draft.bizstyle =
                            typeof additionalfields !== 'undefined' &&
                            additionalfields !== null &&
                            additionalfields.bizstyle
                                ? additionalfields.bizstyle
                            : '';
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.userid = userid ? userid : '';
                        draft.signupby = signupby ? signupby : '';
                        draft.memberid = memberid ? memberid : '';
                        draft.membergroup = membergroup ? membergroup : '';
                        draft.gender = gender ? gender : '';
                        draft.membergroupid = membergroupid
                            ? membergroupid
                            : '';
                        draft.accesstype = accesstype ? accesstype : '';
                        draft.accesstypevalue = accesstypevalue
                            ? accesstypevalue
                            : '';
                        draft.socialid = socialid ? socialid : '';
                        draft.firstname = firstname ? firstname : '';
                        draft.lastname = lastname ? lastname : '';
                        draft.mobileno = mobileno ? mobileno : '';
                        draft.emailid = emailid ? emailid : '';
                        draft.address = address ? address : '';
                        draft.city = city ? city : '';
                        draft.houseno = houseno ? houseno : '';
                        draft.postal = postal ? postal : '';
                        draft.membertype = membertype ? membertype : '';
                        draft.creditrating = creditrating ? creditrating : '';
                        draft.creditlimit = creditlimit ? creditlimit : '';
                        draft.creditstatus = creditstatus ? creditstatus : '';
                        draft.longitude = longitude ? longitude : '';
                        draft.latitude = latitude ? latitude : '';
                        draft.location = location ? location : '';
                        draft.collectorname = collectorname
                            ? collectorname
                            : '';
                        draft.standarddiscount = standarddiscount
                            ? standarddiscount
                            : '';
                        draft.collectorid = collectorid ? collectorid : '';
                        draft.salespersonid = salespersonid
                            ? salespersonid
                            : '';
                        draft.salespersonname = salespersonname
                            ? salespersonname
                            : '';
                        draft.contactno = contactno ? contactno : '';
                        draft.creditbalance = creditbalance
                            ? creditbalance
                            : '';
                        draft.companyname = companyname ? companyname : '';
                        draft.taxnumber = taxnumber ? taxnumber : '';
                        draft.membersegment = membersegment
                            ? membersegment
                            : '';
                        draft.storegroup = storegroup
                            ? storegroup.split(',')
                            : [];
                        //draft.additionalFields = this.getAdditionalFields(additionalfields)
                        draft.image1url = image1url
                            ? {
                                  image1: image1url,
                              }
                            : {};
                    })
                );

                const requestBody = {
                    body: {
                        memberid: memberid,
                    },
                };
                if (status !== 'New' && status !== 'Reject') {
                    this.props.dispatch(
                        searchGeneral(
                            'crm/v1/member/tag/search/fields',
                            requestBody,
                            'SEARCH_MEMBER_TAG_SUCCESS',
                            'SEARCH_MEMBER_TAG_FAILURE'
                        )
                    );
                }
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        const { propConfigs } = this.props.queryData;
        const { propConfigs: prevPropConfigs } = prevProps.queryData;
        if (
            propConfigs !== prevPropConfigs &&
            propConfigs &&
            propConfigs.length > 0 &&
            propConfigs[0].additionalfields
        ) {
            const resultArray = Object.entries(
                propConfigs[0].additionalfields
            ).map(([code, name]) => ({ code, name }));
            this.setState({ customerGroupList: resultArray });
        }
    };
    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    getCreatePayload = () => {
        const {
            userid,
            signupby,
            socialid,
            memberid,
            membergroup,
            membergroupid,
            firstname,
            lastname,
            password,
            mobileno,
            emailid,
            address,
            city,
            houseno,
            postal,
            image1url,
            membertype,
            membersegment,
            additionalFields,
            storegroup,
            creditrating,
            creditstatus,
            salespersonid,
            collectorid,
            creditlimit,
            creditbalance,
            standarddiscount,
            contactno,
            longitude,
            latitude,
            location,
            taxnumber,
            companyname,
            accesstype,
            accesstypevalue,
            gender,
            salespersonname,
            storeid,
            s_storelabel,
            collectorname,
            dateofbirth,
            taxvalue,
            taxvaluetype,
            bizstyle
        } = this.state;

        let addfield =  additionalFields.reduce(function (acc, item) {
            if(item.field.trim() !== "" && item.value.trim() !== ""){
              acc[item.field] = item.value
              return acc
            }
            return acc
          }, {})

        let formattedDob = '';
        if (dateofbirth !== '') {
            formattedDob = moment(dateofbirth).format('DD-MM-YYYY');
        }
        let body = {
            storeid,
            userid: userid,
            signupby: signupby,
            socialid: socialid,
            memberid: memberid,
            firstname: firstname,
            lastname: lastname,
            membergroup: membergroup,
            membergroupid: membergroupid,
            membertype,
            membersegment,
            mobileno: mobileno,
            emailid: emailid,
            address: address,
            city: city,
            houseno: houseno,
            postal: postal,
            image1url: image1url.image1 || '',
            additionalfields: addfield,
            storegroup: storegroup.toString(),
            creditrating,
            creditstatus,
            salespersonid,
            collectorid,
            creditlimit,
            creditbalance,
            standarddiscount,
            contactno,
            longitude,
            latitude,
            location,
            taxnumber,
            companyname,
            accesstype,
            accesstypevalue,
            gender,
            salespersonname,
            collectorname,
            dateofbirth: formattedDob,
            taxvaluetype,
            taxvalue,
            //status: this.state.status
        };
        if (password !== '') {
            body.password = password;
        }
        if (s_storelabel !== '') {
            body.additionalfields.s_storelabel = s_storelabel;
        }
        if (bizstyle !== '') {
            body.additionalfields.bizstyle = bizstyle;
        }
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            field: '',
            value: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log('Rows After Add', this.state.additionalFields);
            }
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {}
        );
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id
            );

            this.setState({ additionalFields: updateValues });
        }
    };

    _createMemberProfile = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateUserProfile(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createUserProfile(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            useriderror: '',
            email: '',
            firstnameerror: '',
            lastnameerror: '',
            doberror: '',
            emailiderror: '',
            mobilenoerror: '',
            passworderror: '',
            addressError: '',
            cityError: '',
            houseNoError: '',
            postalError: '',
            companyNameError: '',
            companytinError: '',
            taxvaluetyperror: ''
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { companyname, firstname, taxvaluetype, emailid } = this.state;
        let valid = true;

        if (companyname.trim() === '') {
            this.setState({
                companyNameError: 'Company name is required.',
            });
            valid = false;
        }
        if (firstname.trim() === '') {
            this.setState({
                firstnameerror: 'First name is required.',
            });
            valid = false;
        }
        if (taxvaluetype.trim() === '') {
            this.setState({
                taxvaluetyperror: 'Tax value type is required.',
            });
            valid = false;
        }

        if (this._isEditMode() && emailid.trim() === '') {
            this.setState({
                emailiderror: 'Email is required.',
            });
            valid = false
        }

        return valid;
    };
    _isEditMode = () => {
        return this.state.isEdit;
    };
    _isViewMode = () => {
        return this.state.isView;
    };
    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <Button
                    label="Close"
                    className="p-button-secondary p-button-lg"
                    onClick={this.props.history.goBack}
                    style={{ marginRight: '20px' }}
                />
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <>
                <Button
                    label={isEditMode ? 'Update' : 'Save'}
                    className="p-button-lg p-button-success"
                    onClick={() => this._createMemberProfile()}
                    style={{ marginRight: '20px' }}
                />
                {isEditMode && this.state.status === 'New' && (
                    <>
                        <Button
                            label="Reject"
                            className="p-button-danger p-button-lg"
                            onClick={this.handleReject}
                            style={{ marginRight: '20px' }}
                        />
                        <Button
                            label="Approve"
                            className="p-button-lg"
                            onClick={this.handleApprove}
                            style={{ marginRight: '20px' }}
                        />
                    </>
                )}
                <Button
                    label="Cancel"
                    className="p-button-secondary p-button-lg"
                    onClick={this.props.history.goBack}
                    style={{ marginRight: '20px' }}
                />
            </>
        );
    };

    handleReject = () => {
        const mainBody = this.getCreatePayload();
        mainBody.body['status'] = 'Reject';
        this.props.dispatch(updateUserProfile(mainBody, this.state.id));
    };

    _renderFormArea = () => {
        const { status } = this.state;
        return (
            <>
                {this.locationInfo()}
                {this.creditInfo()}
                {this.basicInformation()}
                {this.addressInfo()}
                {/* {this.extraInfo()} */}
                {(status !== 'New' || status !== 'Reject') &&
                    this.additionalInfo()}
            </>
        );
    };
    setImages = (images) => {
        this.setState({ image1url: images });
    };
    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    handleDobChange = (event) => {
        this.setState({ dateofbirth: event.target.value });
    };

    basicInformation = () => {
        return (
            <div
                className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                style={{ marginTop: '20px' }}
            >
                <div className="col-4 mb-3">
                    <label htmlFor="userid">User Id</label>
                    <InputText
                        id="userid"
                        name="userid"
                        value={this.state.userid}
                        onChange={(event) => {
                            this.setState({ userid: event.target.value });
                        }}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="signupby" className="floatLeft w-100">
                        SignUp By
                    </label>
                    <Dropdown
                        id="signupby"
                        name="signupby"
                        value={signupConfigOptions.find(
                            (item) => item.code == this.state.signupby
                        )}
                        onChange={this.handleSignUpChange}
                        options={signupConfigOptions}
                        optionLabel="name"
                        placeholder="Select"
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="socialid" className="floatLeft">
                        Social Id
                    </label>
                    <InputText
                        id="socialid"
                        name="socialid"
                        value={this.state.socialid}
                        onChange={(event) => {
                            this.setState({ socialid: event.target.value });
                        }}
                        readOnly
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="firstname" className="floatLeft required">
                        First Name
                    </label>
                    <InputText
                        id="firstname"
                        name="firstname"
                        value={this.state.firstname}
                        onChange={(event) => {
                            this.setState({
                                firstname: event.target.value,
                            });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.firstnameerror}
                    </div>
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="lastname" className="floatLeft">
                        Last Name
                    </label>
                    <InputText
                        id="lastname"
                        name="lastname"
                        value={this.state.lastname}
                        onChange={(event) => {
                            this.setState({ lastname: event.target.value });
                        }}
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="password" className="floatLeft">
                        Password
                    </label>
                    <Password
                        style={{ width: '100%' }}
                        id="password"
                        name="password"
                        value={this.state.password}
                        onChange={(event) => {
                            this.setState({ password: event.target.value });
                        }}
                        toggleMask
                        feedback={false}
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="dateofbirth" className="floatLeft">
                        Date of Birth
                    </label>
                    <Calendar
                        className="w-100"
                        id="dateofbirth"
                        value={this.state.dateofbirth}
                        onChange={this.handleDobChange}
                        dateFormat="dd-mm-yy"
                        showIcon
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="gender" className="floatLeft">
                        Gender
                    </label>
                    <SimpleSelect
                        objArray={{
                            U: 'Unknown',
                            M: 'Male',
                            F: 'Female',
                        }}
                        callbackChangeFilter={(value) =>
                            this.setState({ gender: value })
                        }
                        selectedOption={this.state.gender}
                        id="gender"
                        cssClass={'form-control'}
                        style={{ height: '44px' }}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="mobileno" className="floatLeft">
                        Mobile No
                    </label>
                    <InputText
                        id="mobileno"
                        name="mobileno"
                        value={this.state.mobileno}
                        onChange={(event) => {
                            this.setState({ mobileno: event.target.value });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.mobilenoerror}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="emailid" className="floatLeft">
                        Email Address
                    </label>
                    <InputText
                        id="emailid"
                        name="emailid"
                        value={this.state.emailid}
                        onChange={(event) => {
                            this.setState({ emailid: event.target.value });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.emailiderror}
                    </div>
                </div>
            </div>
        );
    };

    locationInfo = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                <div className="col-4 mb-3">
                    <label htmlFor="memberid" className="floatLeft">
                        Customer Code
                    </label>
                    <InputText
                        id="memberid"
                        name="memberid"
                        value={this.state.memberid}
                        onChange={(event) => {
                            this.setState({ memberid: event.target.value });
                        }}
                        readOnly={this._isEditMode()}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="companyname" className="floatLeft required">
                        Customer Name
                    </label>
                    <InputText
                        id="companyname"
                        name="companyname"
                        value={this.state.companyname}
                        onChange={(event) => {
                            this.setState({
                                companyname: event.target.value,
                            });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.companyNameError}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="taxnumber" className="floatLeft">
                        Tax identification number (TIN)
                    </label>
                    <InputText
                        id="taxnumber"
                        name="taxnumber"
                        value={this.state.taxnumber}
                        onChange={(event) => {
                            this.setState({
                                taxnumber: event.target.value,
                            });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.companytinError}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="membergroupid" className="floatLeft">
                        Customer Group Id
                    </label>
                    <Dropdown
                        className="w-100"
                        id="customergroup"
                        name="customergroup"
                        value={this.state.customerGroupList.find(
                            (item) => item.code == this.state.membergroupid
                        )}
                        onChange={(e) => {
                            this.setState({
                                membergroupid: e.value.code,
                                membergroup: e.value.name,
                            });
                        }}
                        options={this.state.customerGroupList}
                        optionLabel="name"
                        placeholder="Select"
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="membertype" className="floatLeft">
                        Customer Type
                    </label>
                    <SimpleSelect
                        objArray={{
                            B2B: 'B2B',
                            Direct: 'Direct',
                        }}
                        callbackChangeFilter={(value) =>
                            this.setState({ membertype: value })
                        }
                        selectedOption={this.state.membertype}
                        id="membertype"
                        style={{ height: '44px' }}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="membersegment" className="floatLeft">
                        Customer Segment
                    </label>
                    <InputText
                        id="membersegment"
                        name="membersegment"
                        value={this.state.membersegment}
                        onChange={(event) => {
                            this.setState({
                                membersegment: event.target.value,
                            });
                        }}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="contactno" className="floatLeft">
                        Contact Number
                    </label>
                    <InputText
                        id="contactno"
                        name="contactno"
                        value={this.state.contactno}
                        onChange={(event) => {
                            this.setState({
                                contactno: event.target.value,
                            });
                        }}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="contactno" className="floatLeft">
                        Business Style
                    </label>
                    <InputText
                        id="bizstyle"
                        name="bizstyle"
                        value={this.state.bizstyle}
                        onChange={(event) => {
                            this.setState({
                                bizstyle: event.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    handleSignUpChange = (e) => {
        this.setState({ signupby: e.target.value.code });
    };

    handleSaleChange = ({ name, code }) => {
        const salespersonData = code.length > 0 
            ? { salespersonid: code, salespersonname: name } 
            : { salespersonid: '', salespersonname: '' };
        
        this.setState(salespersonData);
    };

    handleCollectorChange = ({ name, code }) => {
        const salespersonData = code.length > 0 
            ? { collectorid: code, collectorname: name } 
            : { collectorid: '', collectorname: '' };
        
        this.setState(salespersonData);
    };

    creditInfo = () => {
        return (
            <div
                className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                style={{ marginTop: '20px' }}
            >
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="creditlimit" className="floatLeft">
                        Credit Limit
                    </label>
                    <InputNumber
                        id="creditlimit"
                        name="creditlimit"
                        value={this.state.creditlimit}
                        onChange={(event) => {
                            this.setState({
                                creditlimit: event.value,
                            });
                        }}
                        mode="decimal"
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="creditbalance" className="floatLeft">
                        Credit Balance
                    </label>
                    <InputNumber
                        id="creditbalance"
                        name="creditbalance"
                        value={this.state.creditbalance}
                        onChange={(event) => {
                            this.setState({
                                creditbalance: event.value,
                            });
                        }}
                        mode="decimal"
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="creditrating" className="floatLeft">
                        Credit Rating
                    </label>
                    <SimpleSelect
                        objArray={{
                            R: 'R',
                            A: 'A',
                            G: 'G',
                        }}
                        callbackChangeFilter={(value) =>
                            this.setState({ creditrating: value })
                        }
                        selectedOption={this.state.creditrating}
                        id="creditrating"
                        cssClass="form-control"
                        style={{ height: '44px' }}
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="creditstatus" className="floatLeft">
                        Credit Status(days)
                    </label>
                    <InputText
                        id="creditstatus"
                        name="creditstatus"
                        value={this.state.creditstatus}
                        onChange={(event) => {
                            this.setState({
                                creditstatus: event.target.value,
                            });
                        }}
                        mode="decimal"
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <label htmlFor="standarddiscount" className="floatLeft">
                        Standard Discount
                    </label>
                    <InputNumber
                        id="standarddiscount"
                        name="standarddiscount"
                        value={this.state.standarddiscount}
                        onChange={(event) => {
                            this.setState({
                                standarddiscount: event.value,
                            });
                        }}
                        mode="decimal"
                        max={100}
                        minFractionDigits={1}
                        maxFractionDigits={1}
                    />
                </div>
            </div>
        );
    };

    addressInfo = () => {
        return (
            <div
                className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                style={{ marginTop: '20px' }}
            >
                <div className="col-4 mb-3">
                    <label htmlFor="address" className="floatLeft">
                        Address
                    </label>
                    <InputText
                        id="address"
                        name="address"
                        value={this.state.address}
                        onChange={(event) => {
                            this.setState({ address: event.target.value });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.addressError}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="city" className="floatLeft">
                        City
                    </label>
                    <InputText
                        id="city"
                        name="city"
                        value={this.state.city}
                        onChange={(event) => {
                            this.setState({ city: event.target.value });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.cityError}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="postal" className="floatLeft">
                        Postal
                    </label>
                    <InputText
                        id="postal"
                        name="postal"
                        value={this.state.postal}
                        onChange={(event) => {
                            this.setState({ postal: event.target.value });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.postalError}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="houseno" className="floatLeft">
                        House No
                    </label>
                    <InputText
                        id="houseno"
                        name="houseno"
                        value={this.state.houseno}
                        onChange={(event) => {
                            this.setState({ houseno: event.target.value });
                        }}
                    />
                    <div className="invalid-feedback">
                        {this.state.houseNoError}
                    </div>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="longitude" className="floatLeft">
                        Longitude
                    </label>
                    <InputText
                        id="longitude"
                        name="longitude"
                        value={this.state.longitude}
                        onChange={(event) => {
                            this.setState({
                                longitude: event.target.value,
                            });
                        }}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="latitude" className="floatLeft">
                        Latitude
                    </label>
                    <InputText
                        id="latitude"
                        name="latitude"
                        value={this.state.latitude}
                        onChange={(event) => {
                            this.setState({ latitude: event.target.value });
                        }}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="location" className="floatLeft">
                        Geo Location
                    </label>
                    <InputText
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={(event) => {
                            this.setState({ location: event.target.value });
                        }}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="accesstype" className="floatLeft">
                        Access Type
                    </label>
                    <InputText
                        id="accesstype"
                        name="accesstype"
                        value={this.state.accesstype}
                        onChange={(event) => {
                            this.setState({
                                accesstype: event.target.value,
                            });
                        }}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="accesstypevalue" className="floatLeft">
                        Access Type Value
                    </label>
                    <InputText
                        id="accesstypevalue"
                        value={this.state.accesstypevalue}
                        onChange={(event) => {
                            this.setState({
                                accesstypevalue: event.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    extraInfo = () => {
        return (
            <div
                className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                style={{ marginTop: '20px' }}
            >
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'field',
                                    label: 'Key',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </div>
        );
    };

    additionalInfo = () => {
        const { memberTagList } = this.props.queryData;
        let memberTagListImages = memberTagList.filter(
            (m) => m.tagtype === 'MemberApproval'
        );
        if (memberTagListImages.length > 0) {
            const { additionalfields } =
                memberTagListImages[memberTagListImages.length - 1];
            let imagePath =
                localStorage.getItem('imagepath') + additionalfields.filepath;
            return (
                <div
                    className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                    style={{ marginTop: '20px' }}
                >
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="image1" className="floatLeft">
                                Document
                            </label>
                        </div>
                    </div>
                    <div className="row imgPickRow">
                        <Image
                            src={imagePath}
                            alt="Image"
                            width="250"
                            preview
                        />
                    </div>
                </div>
            );
        }
        return <></>;
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, s_storelabel: p2, search_value: p1 });
        this.setState({ storeiderror: '' });
    };

    dialogFileUploadHandler = (e) => {
        const { files, options } = e;
        getBase64(files, this.uploadDialogFile);
        options.clear();
    };

    getCustomerApprovalPayload = () => {};

    uploadDialogFile = (filename, encodedStr) => {
        const { memberid } = this.state;
        let data = {
            title: filename.toLowerCase(),
            filename: memberid + '-' + filename.toLowerCase(),
            filetext: encodedStr,
        };
        let body = {
            memberid: memberid,
            tagtype: 'MemberApproval',
            tagcode: this.state.id,
            additionalfields: {
                ...data,
            },
        };
        this.props.dispatch(createMemberTag({ body: body }));

        const mainBody = this.getCreatePayload();
        mainBody.body['status'] = 'Active';
        this.props.dispatch(updateUserProfile(mainBody, this.state.id));
        this.setState({ displayFileUploadDialog: false });
    };

    handleApprove = () => {
        this.setState({ displayFileUploadDialog: true });
    };

    onUpload = () => {
        console.log('done');
    };

    findMaxDescription = (arr) => {
        return arr.reduce((max, current) => {
            return (parseFloat(current.description) > parseFloat(max.description)) ? current : max;
        }, arr[0]);
    };

    handleTaxVauleTypeChange = (e) => {
        const { taxentries } = this.props.queryData;
        const selectedTaxType = taxentries.filter(t => t.catvalue === e.target.value.code)
        this.setState({ taxvaluetype: e.target.value.code });
        if (selectedTaxType.length === 1) {
            this.setState({ taxvalue: selectedTaxType[0].description });
        }
        else {
            //get biggest value of multiple taxtype
            this.setState({ taxvalue: this.findMaxDescription(selectedTaxType).description });
        }
    };

    transformData = (input) => {
        // Use a Set to collect unique catvalue entries
        const uniqueValues = new Set(input.map(item => item.catvalue));
    
        // Map the unique values to the desired output format
        const output = Array.from(uniqueValues).map(value => ({
            name: value,
            code: value
        }));
    
        return output;
    };

    render() {
        const { isSuccess } = this.props.crudData;
        const { taxentries } = this.props.queryData;
        let taxableArr = taxentries.filter((tax) => tax.catvalue === 'TX');
        let taxValueOptions = taxableArr.map((tax) => ({
            name: tax.description,
            code: tax.description,
        }));
        taxValueOptions.sort((obj1, obj2) => obj1.code - obj2.code).reverse();

        const taxValueTypeOptions = this.transformData(taxentries)
        return (
            <div className="form_height">
                <div className="grid pl-3 ml-1 mb-3">
                    <h2 className="page-header">
                        {this._isEditMode()
                            ? 'Edit Customer Profile'
                            : this._isViewMode()
                              ? 'View Customer Profile'
                              : 'Add Customer Profile'}
                    </h2>
                </div>
                <div className="grid px-3">
                    <div className="col-9">
                        {this._renderErrorMessage()}
                        {this._renderFormArea()}
                        
                    </div>
                    <div className="col-3">
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginBottom: '2em' }}
                        >
                            <div className="row">
                                <div className="mb-2 col-lg-12 col-sm-12">
                                    <label htmlFor="msgstatus">Status</label>
                                    <InputText
                                        className="w-100"
                                        id="status"
                                        value={this.state.status}
                                        onChange={(event) => {
                                            this.setState({
                                                status: event.target.value,
                                            });
                                        }}
                                        readOnly
                                    />
                                </div>
                                <div className="form-group mb-2 col-lg-12 col-sm-12">
                                    <label
                                        htmlFor="collectorid"
                                        className="floatLeft w-100"
                                    >
                                        Store Id
                                    </label>
                                    <StoreSearchSingle
                                        value={this.state.s_storelabel}
                                        onSelectStore={this._onSelectStore}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.storeiderror}
                                    </div>
                                </div>
                                <div className="mb-2 col-lg-12 col-sm-12">
                                    <label
                                        htmlFor="standarddiscount"
                                        className="w-100 required"
                                    >
                                        Tax Value Type
                                    </label>
                                    <Dropdown
                                        className="w-100"
                                        id="taxvaluetype"
                                        name="taxvaluetype"
                                        value={taxValueTypeOptions.find(
                                            (item) =>
                                                item.code ==
                                                this.state.taxvaluetype
                                        )}
                                        onChange={this.handleTaxVauleTypeChange}
                                        options={taxValueTypeOptions}
                                        optionLabel="name"
                                        placeholder="Select"
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.taxvaluetyperror}
                                    </div>
                                </div>
                                <div className="mb-2 col-lg-12 col-sm-12">
                                    <label htmlFor="taxvalue" className="w-100">
                                        Tax Value
                                    </label>
                                    {(this.state.taxvaluetype !== 'TX') && (
                                        <InputNumber
                                            className="w-100"
                                            id="taxvalue"
                                            name="taxvalue"
                                            value={this.state.taxvalue}
                                            onChange={(event) => {
                                                this.setState({
                                                    taxvalue: event.value,
                                                });
                                            }}
                                            mode="decimal"
                                            max={100}
                                            minFractionDigits={1}
                                            maxFractionDigits={1}
                                            readOnly
                                        />
                                    )}
                                    {this.state.taxvaluetype === 'TX' && (
                                        <Dropdown
                                            className="w-100"
                                            id="taxvalue"
                                            name="taxvalue"
                                            value={taxValueOptions.find(
                                                (item) =>
                                                    item.code ==
                                                    this.state.taxvalue
                                            )}
                                            onChange={(ev) => {
                                                this.setState({
                                                    taxvalue: ev.value.code,
                                                });
                                            }}
                                            options={taxValueOptions}
                                            optionLabel="name"
                                            placeholder="Select"
                                        />
                                    )}
                                </div>
                                <div className="mb-2 col-lg-12 col-sm-12">
                                    <label
                                        htmlFor="salespersonid"
                                        className="floatLeft w-100"
                                    >
                                        Salesperson
                                    </label>
                                    <UserTypeSelector
                                        salespersonid={this.state.salespersonid}
                                        onChange={this.handleSaleChange}
                                        type={'sale'}
                                        storeid={this.state.storeid}
                                    />
                                </div>
                                <div className="mb-2 col-lg-12 col-sm-12">
                                    <label
                                        htmlFor="collectorid"
                                        className="floatLeft w-100"
                                    >
                                        Collector
                                    </label>
                                    <CollectorSelector
                                        collectorid={this.state.collectorid}
                                        onChange={this.handleCollectorChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row btn-container form-button">
                            <div className="col-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                </div>
                <ConfirmAlert
                    show={isSuccess}
                    handleClose={this.hideModal}
                    to="back"
                    children={
                        <div style={{ color: 'green', padding: '2em' }}>
                            {this._isEditMode()
                                ? 'Customer profile updated successfully'
                                : 'Customer profile created successfully'}
                        </div>
                    }
                />
                <FileUploadDialog
                    displayFileUploadDialog={this.state.displayFileUploadDialog}
                    handleFileUploadDialogClose={() =>
                        this.setState({ displayFileUploadDialog: false })
                    }
                    uploadFileHandler={this.dialogFileUploadHandler}
                    onUpload={this.onUpload}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddMemberProfile);
