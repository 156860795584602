import React from "react";
import { connect } from "react-redux";
import GroupedBarChart from "../../Charts/GroupedBarChart";
import DoughnutChart from "../../Charts/DoughnutChart";
import LineChart from "./../../Charts/LineChart";
import DatePicker from "react-date-picker";
import { toDate, getByMonth } from "../../../helpers/Util";
import ChartOptions from "../../../components/ChartOptions";
import ChartTable from "../../../components/ChartTable";

import { queryDeviceByMember, 
         queryDeviceByGuest,
         deviceTypeGuest,
         deviceTypeMember,
         deviceTypeMemberDate,
         deviceTypeGuestDate } 
from "../../../actions/actions";

class MobileAnalysis extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showTableId: null,
      showTable: false,
      oversalesfromdate: new Date("Jan 1, 2019 0:00:00"),
      oversalestilldate: new Date(),
      status: "Active",
      analysisfromdate: new Date("Jan 1, 2019 0:00:00"),
      analysistilldate: new Date(),
      hideChart: false,
      mobiledevicemodel: "",
      mobiledevicetype: "",
      memberdevicemodel: "",
      guestdevicemodel: "",
      memberdevicetype: "",
      guestdevicetype: "",
      memberdevicetypedate: "",
      guestdevicetypedate: ""
    };
  }

  componentDidMount = () => {
    this.callFetch();
    this.callFetchDate();
  }

  
  componentDidUpdate = (prevProps) =>{
    const { memberDeviceModel, guestDeviceModel, memberDeviceType, guestDeviceType, memberDeviceTypeDate, guestDeviceTypeDate }= this.props.queryData;
    const obj = [...memberDeviceModel, ...guestDeviceModel];
    const { memberDeviceModel: prevmemberDeviceModel, guestDeviceModel: prevguestDeviceModel,
       memberDeviceType: prevmemberDeviceType, guestDeviceType: prevguestDeviceType, 
      memberDeviceTypeDate: prevmemberDeviceTypeDate, guestDeviceTypeDate: prevguestDeviceTypeDate} = prevProps.queryData;
    if (prevmemberDeviceModel.length !== memberDeviceModel.length || prevguestDeviceModel.length !== guestDeviceModel.length) {
      if (obj.length > 0) {
        let memobj = this.formatObject(memberDeviceModel);
        let mmodel = memobj.map(e=>({...e,category: "member"}));
        let guesobj = this.formatObject(guestDeviceModel);
        let gmodel  = guesobj.map(e=>({...e, category: "guest"}));
        let result = [...memobj, ...guesobj];
        this.setState({ 
          mobiledevicemodel: result,
          memberdevicemodel: mmodel,
          guestdevicemodel: gmodel
        });
      }
    }
    if(prevmemberDeviceTypeDate !== memberDeviceTypeDate) {
      if(memberDeviceTypeDate.length > 0) {
        let mtypedate = memberDeviceTypeDate.map(i=>({category: "member", ...i}));
        this.setState({memberdevicetypedate: mtypedate})
      }
    }

    if(prevguestDeviceTypeDate !== guestDeviceTypeDate){
      if(guestDeviceTypeDate.length > 0){
        let gtypedate = guestDeviceTypeDate.map(i=>({category: "guest", ...i}));
        this.setState({ guestdevicetypedate: gtypedate})
      }
    }

    if(prevmemberDeviceType !== memberDeviceType || prevguestDeviceType !== guestDeviceType) {
      if(memberDeviceType.length > 0 || guestDeviceType.length > 0){
        let mtype = memberDeviceType.map(e=>({category: "member", ...e}));
        let gtype = guestDeviceType.map(e=>({category: "guest", ...e}));
        let devtype = [...mtype,...gtype];
        this.setState({mobiledevicetype: devtype, memberdevicetype: mtype, guestdevicetype: gtype})
      }
    }
  }

  createFetchUrl = (isMember) => {
    const {oversalesfromdate , oversalestilldate , status} = this.state;
    const requestBody = {
      body: {
        date_from: toDate(oversalesfromdate),
        date_till: toDate(oversalestilldate),
        date_type: "createdon",
        status: status,
        member: isMember,
        groupby: "month"
      }
    };
    return requestBody;
  };

  createFetchUrlDate = (isMember) => {
    const {analysisfromdate , analysistilldate , status} = this.state;
    const requestBody = {
      body: {
        date_from: toDate(analysisfromdate),
        date_till: toDate(analysistilldate),
        date_type: "createdon",
        status: status,
        member: isMember,
        groupby: "date"
      }
    };
    return requestBody;
  };

  callFetch = () => {
    this.props.dispatch(
      queryDeviceByMember(this.createFetchUrl("Y"))
    )
    this.props.dispatch(
      queryDeviceByGuest(this.createFetchUrl("N"))
    )
    this.props.dispatch(
      deviceTypeMember(this.createFetchUrl("Y"))
    )
    this.props.dispatch(
      deviceTypeGuest(this.createFetchUrl("N"))
    )
  }

  callFetchDate = () => {
    this.props.dispatch(
      deviceTypeMemberDate(this.createFetchUrlDate("Y"))
    )
    this.props.dispatch(
      deviceTypeGuestDate(this.createFetchUrlDate("N"))
    )
  }

  handleTable = (e) => {
    e.preventDefault();
    this.setState({
      showTableId: e.target.id,
      showTable: true
    });
  }

  handleChart = (e) => {
    e.preventDefault();
    this.setState({
      showTable: false
    })
  }
  
  renderMobileTraffic = () =>{
    const { memberdevicetype, guestdevicetype } = this.state;
    if (memberdevicetype.length > 0 || guestdevicetype.length > 0) {
      let deviceModel = [...memberdevicetype, ...guestdevicetype];
      const labeldata = [...new Set(deviceModel.map(e => e.devicetype))];
      let mobiledeviceobj = this.formatdevicetypeobj(deviceModel);
      let deviceAndroid = mobiledeviceobj.filter(e => e.devicetype == "Android").map(i => i.totalrec);
      let deviceIOS = mobiledeviceobj.filter(e => e.devicetype == "iOS").map(i => i.totalrec);
      const data = [deviceAndroid, deviceIOS];
      console.log(data);
      return this.renderDoughnutChart("mobiletraffic",labeldata, data);
    }
  } 

  renderDoughnutChart = (id,label, data) => {
    return <DoughnutChart
      id={id}
      labels={label}
      colors={["#7991e0", "#4F5965"]}
      data={data}
    />
  }

  renderDeviceType = () => {
    const { memberdevicetype, guestdevicetype } = this.state;
    if (memberdevicetype.length > 0 || guestdevicetype.length > 0) {
      const memtype = getByMonth(memberdevicetype);
      const guestype = getByMonth(guestdevicetype);
      const labeldata = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let memdata = memtype.map(e => ({
        x: e.month,
        y: e.totalrec
      }));
      let guesdata = guestype.map(e => ({
        x: e.month,
        y: e.totalrec
      }));
      const objdata = [{
        label: "Member",
        data: memdata,
        backgroundColor: "#4774ba"
      }, {
        label: "Guest",
        data: guesdata,
        backgroundColor: "#68f288"
      }]
      return this.renderGroupChart("devicetypemonth",labeldata, objdata, "Device Type", "Totalrecords");
    }
  }

  renderGroupChart = (id,label, data, xaxes, yaxes) => {
    return (
      <div>
        <GroupedBarChart
          id={id}
          data={data}
          color="#5487D8"
          labels={label}
          xaxes={xaxes}
          yaxes={yaxes}
        /> 
      </div>
    );
  }

  renderDeviceTypeDate = () =>{
    const { memberdevicetypedate, guestdevicetypedate } = this.state;
    const alltype = [...memberdevicetypedate, ...guestdevicetypedate];
    if (alltype.length > 0) {
      const labeldata = alltype.map(e => e.createdon);
      var dtypeandroid = alltype.filter(i => i.devicetype == "Android");
      var dtypeios = alltype.filter(i => i.devicetype == "iOS");
      var objAndroid = dtypeandroid.map(e => ({
        x: e.createdon,
        y: e.totalrec
      }));
      var objiOS = dtypeios.map(e => ({
        x: e.createdon,
        y: e.totalrec
      }));
      const objdata = [{
        label: "Android",
        data: objAndroid,
        backgroundColor: "#4682B4",
        borderWidth: 1,
        borderColor: "#4682B4"
      }, {
        label: "iOS",
        data: objiOS,
        backgroundColor: "#B0C4DE",
        borderWidth: 1,
        borderColor: "#B0C4DE"
      }]
      return this.renderLineChart("devicetypedate",labeldata, objdata, "Date", "TotalRecords");
    }
  }

  renderLineChart = ( id,label,data, xaxes, yaxes) => {
    return <LineChart
    id={id}
    labels={label}
    data={data}
    color="#5487D8"
    xaxes={xaxes}
    yaxes={yaxes}
  />
  }

  formatObject = (obj) => {
    let newobj =  obj.reduce((a, c) => {
      let filtered = a.filter(el => el.devicemodel === c.devicemodel);
      if(filtered.length > 0){
        a[a.indexOf(filtered[0])].totalrec += +c.totalrec;
      }else{
        a.push(c);
      }
      return a;
    }, []);
    return newobj;
  }

  formatdevicetypeobj = (obj) => {
    let newobj =obj.reduce((a, c) => {
      let found = a.find(el => el.devicetype == c.devicetype);
      if (found) {
        found.totalrec += c.totalrec;
      } else {
        a.push(c);
      }
      return a;
    }, []);   
    return newobj;
  }

  renderDeviceModel = () => {
     const { memberdevicemodel, guestdevicemodel } = this.state;
     if(memberdevicemodel.length > 0 || guestdevicemodel.length > 0){
     const allmodel = getByMonth(memberdevicemodel, guestdevicemodel);
     const labeldata = [...new Set(allmodel.map(e=>e.devicemodel))];
    let memdata = memberdevicemodel.map(e => ({
      x: e.devicemodel,
      y: e.totalrec
    }))
    let guesdata = guestdevicemodel.map(e => ({
      x: e.devicemodel,
      y: e.totalrec
    }))    
    const objdata = [{
      label: "Member",
      data: memdata,
      backgroundColor: "#4774ba"
    }, {
      label: "Guest",
      data: guesdata,
      backgroundColor: "#68f288"
    }]

     return this.renderGroupChart("devicemodel",labeldata, objdata, "Device Model", "TotalRecords"); 
  }
  }
   

  _renderChartDiv1 = () => {
    return (
      <div className="row noMargin noPadding">
        <div className="col-lg-6 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-8 col-sm-8 alignLeft">
                Mobile Traffic
              </div>
              <div className="col-lg-4 col-sm-4 alignRight">
                 <ChartOptions
                   chartid="mobiletraffic"
                   toggleTable={this.handleTable}
                   toggleChart={this.handleChart}
                   tableimgid="mobiletype"
                   chartdata={getByMonth(this.state.mobiledevicetype)}
                 />
              </div>
            </div>
            <div className="row noPadding noMargin">
              <div className="title_devide"></div>
              {this.state.showTable === true && this.state.showTableId === "mobiletype" ?
               <ChartTable tabledata={getByMonth(this.state.mobiledevicetype)}/> : this.renderMobileTraffic()}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="chartRight chartDiv">
            <div className="row noMargin noPadding">
              <div className="chartTitle col-lg-8 col-sm-8 alignLeft">
                Device Type
              </div>
              <div className="col-lg-4 col-sm-4 alignRight">
                <ChartOptions
                   chartid="devicetypemonth"
                   toggleTable={this.handleTable}
                   toggleChart={this.handleChart}
                   tableimgid="devicebymonth"
                   chartdata={getByMonth(this.state.mobiledevicetype)}
                />
              </div>
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-12 col-sm-12">
                 <div className="title_devide"></div>
                 {this.state.showTable === true && this.state.showTableId === "devicebymonth" ?
                  <ChartTable tabledata={getByMonth(this.state.mobiledevicetype)}/> : this.renderDeviceType()}
              </div>              
            </div>
          </div>
        </div>
      </div>     
    );
  };
  _renderChartDiv2 = () => {
    return (
      <div className="row noMargin noPadding">
        <div className="col-lg-6 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noMargin noPadding">
              <div className="chartTitle col-lg-12 col-sm-12 alignLeft">
                Mobile Devices
              </div>
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-12 col-sm-12">
              <div className="title_devide"></div>
              {this.state.mobiledevicemodel.length > 0 ? this.state.mobiledevicemodel.map(e=>{
              return (
                 <div>
                   <div className="row">
                     <div className="col-lg-6 col-sm-12 alignLeft">{e.devicemodel}</div>
                     <div
                       className="col-lg-6 col-sm-12 alignRight"
                       style={{ color: "blue" }}
                     >
                       {e.totalrec}
                     </div>
                   </div>
                   <div className="title_devide"></div>
                </div>)
            }): <div>Loading...</div>}
               </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
        <div className="chartRight chartDiv">
            <div className="row noMargin noPadding">
              <div className="chartTitle col-lg-8 col-sm-8 alignLeft">
                Device Model
              </div>
              <div className="col-lg-4 col-sm-4 alignRight">
                <ChartOptions
                   chartid="devicetypemonth"
                   toggleTable={this.handleTable}
                   toggleChart={this.handleChart}
                   tableimgid="modelbymonth"
                   chartdata={getByMonth(this.state.mobiledevicemodel)}
                />
              </div>
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-12 col-sm-12">
                 <div className="title_devide"></div>
                 {this.state.showTable === true && this.state.showTableId === "modelbymonth" ?
                  <ChartTable tabledata={getByMonth(this.state.mobiledevicemodel)}/> : this.renderDeviceModel()}
              </div>           
            </div>
          </div>
        </div>
      </div>     
    );
  };
  _renderChartDiv3 = () => {
    return (
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-4 col-sm-12 alignLeft">
                Mobile Analytics
              </div>
              <div className="col-lg-3 col-sm-4 noPadding noMargin alignRight">
                <DatePicker
                  locale = "en-GB"
                  onChange={value => {
                    this.setState({ analysisfromdate: value },()=>{
                      this.callFetchDate();
                    });
                  }}
                  value={this.state.analysisfromdate}
                />
              </div>
              <div className="col-lg-3 col-sm-4 noPadding noMargin alignRight">
                <DatePicker
                  locale = "en-GB"
                  onChange={value => {
                    this.setState({ analysistilldate: value }, ()=>{
                      this.callFetchDate();
                    });
                  }}
                  value={this.state.analysistilldate}
                />
              </div>
              <div className="col-lg-2 col-sm-4 alignRight">
                <ChartOptions
                  chartid="devicetypedate"
                  toggleTable={this.handleTable}
                  toggleChart={this.handleChart}
                  tableimgid="devicebydate"
                  chartdata={this.state.memberdevicetypedate}
                />
              </div> 
            </div>
            <div className="row noPadding noMargin">
              <div className="col-lg-12 col-sm-12">
                <div className="title_devide"></div>
                {this.state.showTable === true && this.state.showTableId === "devicebydate" ?
                  <ChartTable tabledata={this.state.memberdevicetypedate}/> : this.renderDeviceTypeDate()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  _renderForm = () => {
    return (
      <form>
        {this._renderChartDiv1()}
        {this._renderChartDiv2()}
        {this._renderChartDiv3()}
      </form>
    );
  };
  render() {
    return (
      <div className="form_height">
        <div className="row form-container">
          <div className="col">{this._renderForm()}</div>
        </div>
      </div>
    );
  }
}

MobileAnalysis.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(MobileAnalysis);
