import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { produce } from 'immer';
import { Button } from 'primereact/button';
import {
    searchTaxConfiguration,
    deleteTaxConfiguration,
    crudReset,
    taxConfigurationSearchByFields,
} from '../../../../actions/actions';
import ConfirmAlert from '../../../../components/ConfirmAlert';
import Loading from '../../../../components/Loading';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../../helpers/Util';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import PrimeTable from '../../../../components/PrimeTable';

class DisplayTaxConfiguration extends React.PureComponent {
    companyId = localStorage.getItem('company_id');
    group = 'DE_STOREMGMT_TAX_CONFIGURATION';
    module = 'STOREMGMT';
    //allAccesses = {}
    allPermissions = []; //permissions()

    statusOptions = [
        { name: 'Select', id: '' },
        { name: 'Active', id: 'Active' },
        { name: 'Inactive', id: 'Inactive' },
        { name: 'Updated', id: 'Updated' },
        { name: 'Deleted', id: 'Deleted' },
    ];

    constructor(props) {
        super(props);

        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,

            openDeleteprompt: false,
            delete_rec_id: '',

            id: '',
            categorycode: '',
            title: '',
            description: '',
            catvalue: '',
            status: '',
            lastupdated: '',
        };
    }

    actionTemplate = (row) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/tax-configuration/addNew',
                            state: { item: row, isEdit: false, isView: true },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                            alt="ViewButtonImage"
                        />
                    </Link>
                )}

                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/tax-configuration/addNew',
                            state: { item: row, isEdit: true },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                            alt="EditButtonImage"
                        />
                    </Link>
                )}

                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteBrokerClient(row)}
                        alt="DeleteButtonImage"
                    />
                )}
            </span>
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage }, () =>
            this.callQueryApiForFetch('SEARCH')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    createFetchUrl = () => {
        const { pageSize, currPage } = this.state;
        const requestBody = {
            body: {
                pagesize: pageSize,
                pageno: currPage,
                cached: 'N',
                catgroup: 'TAX',
            },
        };
        return requestBody;
    };

    // call search api here to fetch data
    callFetch = (fetchType) => {
        this.props.dispatch(
            searchTaxConfiguration(
                'sales/v1/category/search/fields',
                //this.createFetchUrl(pages, currPage),
                this.createFetchUrl(fetchType),
                'SEARCH_TAXCONFIGURATION_SUCCESS',
                'SEARCH_TAXCONFIGURATION_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        //var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
                break;
        }
    };

    // call delete api here to delete an item by row id
    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteTaxConfiguration(
                    'sales/v1/category/delete',
                    this.state.delete_rec_id
                )
            );
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    deleteBrokerClient = (rowData) => {
        this.setState({ message: 'Tax Configuration deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    _showAsTable = (arg) => {
        this.setState({ showAsTable: arg });
    };

    _searchTaxConfiguration = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { id, title, description, catvalue, status } = this.state;

        this.requestBody = {};
        const queryParams = [];

        if (id !== '') {
            queryParams.push(`id=${id}`);
            this.requestBody['id'] = id;
        }
        if (title !== '') {
            let encoded = encodeURIComponent(title);
            queryParams.push(`title=${encoded}`);
            this.requestBody['title'] = encoded;
        }
        if (description !== '') {
            queryParams.push(`description=${description}`);
            this.requestBody['description'] = description;
        }
        if (catvalue !== '') {
            queryParams.push(`catvalue=${catvalue}`);
            this.requestBody['catvalue'] = catvalue;
        }
        if (status !== '') {
            queryParams.push(`status=${status}`);
            this.requestBody['status'] = status;
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        //query
        this.props.dispatch(
            taxConfigurationSearchByFields(queryParams.join('&'))
        );
        // if (e) { e.preventDefault() }
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            id: '',
            title: '',
            description: '',
            catvalue: '',
            status: '',
        });
        //does an auto refresh to display data
        this.callQueryApiForFetch('ONLOAD');
    };

    _renderHeader = () => {
        return (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h1 className="page-header">
                        Tax Configuration
                        <img
                            title="Refresh"
                            onClick={() => {
                                this.callQueryApiForFetch('ONLOAD');
                            }}
                            className="refreshtree"
                            src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                            alt="RefreshButtonImage"
                        />
                    </h1>
                </div>
                <div className="col-6 alignRight alignWithPageHeader">
                    {this._renderAddNewBtn()}
                </div>
            </div>
        );
    };

    _renderSearchRow = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-2 col-sm-12">
                        <label className="col-form-label alignLeft">Id</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Id"
                            value={this.state.id}
                            onChange={(event) =>
                                this.setState({ id: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Description
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            value={this.state.description}
                            onChange={(event) =>
                                this.setState({
                                    description: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Catvalue
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="catvalue"
                            value={this.state.catvalue}
                            onChange={(event) =>
                                this.setState({ catvalue: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.statusOptions}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>

                    <div className="col-md-auto" />
                </div>

                <div className="row">
                    <div className="col-lg-9 col-sm-12" />
                    <div
                        className="col-lg-3 col-sm-12 btn-container1"
                        style={{ marginTop: '1em' }}
                    >
                        <button
                            type="submit"
                            onClick={(e) => this._searchTaxConfiguration(e)}
                            className="btn btn-themes btn-rounded"
                            style={{ float: 'right' }}
                        >
                            Search
                        </button>

                        <button
                            type="submit"
                            onClick={this._clearFiltersAndResults}
                            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            style={{ float: 'right' }}
                        >
                            Clear
                        </button>
                    </div>
                </div>
            </>
        );
    };

    _renderAddNewBtn = () => {
        const { history } = this.props;
        const { taxentries } = this.props.queryData;
        let _taxentries = taxentries.length !== undefined && taxentries;
        let _pid = 0;

        if (_taxentries[0] !== undefined) {
            _pid = _taxentries[0]['pid'];
        }
        if (this.allPermissions.includes('Write')) {
            return (
                <Button
                    label="Add New"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    onClick={() => history.push({
                        pathname: '/ui/tax-configuration/addNew',
                        state: { pid: _pid }
                    })}
                />
            );
        }
    };

    render() {
        const { isSuccess, errormsg } = this.props.crudData;
        const { taxentries } = this.props.queryData;

        let _taxentries = taxentries.length !== undefined && taxentries;
        if (this.props.queryData.apiPending) {
            return <Loading />;
        }

        const columns = [
            { field: 'id', header: 'Id', sortable: true },
            { field: 'categorycode', header: 'Category Code', sortable: true },
            { field: 'title', header: 'Title' },
            { field: 'description', header: 'Tax Value' },
            { field: 'catvalue', header: 'Category Value' },
            { field: 'lastupdated', header: 'Last Updated' },
            { field: 'status', header: 'Status' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-12">{this._renderHeader()}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <form className="formDiv">
                                {this._renderSearchRow()}
                                <div className="invalid-feedback">
                                    {errormsg}
                                </div>
                                <PrimeTable
                                    list={_taxentries}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    linkColumn={this.linkTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div style={{ color: 'green', padding: '2em' }}>
                                Are you sure you want to delete this Tax
                                Configuration?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayTaxConfiguration);
