import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import {
    ContextMenu,
    MenuItem,
    ContextMenuTrigger,
    SubMenu,
} from 'react-contextmenu';
import { Toast } from 'primereact/toast';
import { updateMediaList } from './../../actions/actions';

const FileTypes = ['ftl', 'css', 'html'];

class GridView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showImagePreview: false,
            showSortOrderForm: false,
            value: '',
            copy_to_file: '',
            folderList: [],
            editText: false,
            editFileId: '',
            newFileName: '',
            id: '',
        };
    }

    exportImg = async (event, filename, file) => {
        let imgurl = `${localStorage.getItem('imagepath')}` + filename;
        const image = await fetch(imgurl);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = file.medianame;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    _openImagePreviewModal = (event, image) => {
        this.setState(
            {
                value: image,
            },
            () => {
                this.setState({ showImagePreview: true });
            }
        );
        if (event) event.preventDefault();
    };
    _closeImagePreviewModal = (event) => {
        this.setState({
            showImagePreview: false,
        });
        if (event) event.preventDefault();
    };
    _openSortOrderModal = (event, id) => {
        this.setState(
            {
                id: id,
            },
            () => {
                this.setState({ showSortOrderForm: true });
            }
        );
        if (event) event.preventDefault();
    };
    _closeSortOrderModal = (event) => {
        this.setState({
            showSortOrderForm: false,
        });
        if (event) event.preventDefault();
    };

    _editText = (file) => {
        this.setState({
            newFileName: file.medianame,
            editFileId: file.id,
            editText: true,
        });
    };

    _renderImagePreviewModal = () => {
        const { showImagePreview } = this.state;
        return (
            <Modal
                visible={showImagePreview}
                width="80%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeImagePreviewModal()}
            >
                <div className="modalForm">
                    <img
                        style={{ width: '400px', height: '350px' }}
                        src={`${localStorage.getItem('imagepath')}${
                            this.state.value
                        }`}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
                        }}
                        alt="Missing Image"
                    />
                </div>
            </Modal>
        );
    };

    getCreatePayload = () => {
        const { id, sortorder } = this.state;
        let body = {
            id,
            sortorder: parseInt(sortorder),
        };
        const payload = {
            body: body,
        };
        return payload;
    };

    _renderSortOrderModal = () => {
        return (
            <Modal
                visible={this.state.showSortOrderForm}
                width="80%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeSortOrderModal()}
            >
                <div className="modalForm searchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeSortOrderModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {this._renderSortForm()}
                </div>
            </Modal>
        );
    };

    _renderSortForm = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="fromDate" className="floatLeft">
                            Sort Order
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            onChange={(event) => {
                                this.setState({
                                    sortorder: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.props.dispatch(
                                    updateMediaList(
                                        this.getCreatePayload(),
                                        this.state.id
                                    )
                                );
                                this._closeSortOrderModal();
                            }}
                        >
                            Update
                        </button>
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this._closeSortOrderModal();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFolder = (file, id) => {
        return (
            <div
                className="mb-2 px-2 col-2 md:col-3 lg:col-2"
                key={id}
            >
                <ContextMenuTrigger id={file.id + ''}>
                    <div
                        className="card p-2"
                        onClick={() => {
                            this.props.folderOnClick(file.path);
                        }}
                        style={{ padding: '8px' }}
                    >
                        <img
                            className="card-img-top folderImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_media_folder.svg`}
                            alt="Card image cap"
                        />
                        <div className="title_devide2" />
                        <div className="card-body">
                            <p className="card-text text-center">{file.medianame}</p>
                        </div>
                    </div>
                </ContextMenuTrigger>
                <ContextMenu id={file.id + ''} props={this.props}>
                    <MenuItem
                        data={{ action: 'createFolder' }}
                        onClick={() => {
                            this.props.updateFolderPath(
                                file.path,
                                this.props.openCreateFolder
                            );
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_folder.svg`}
                        />
                        {` Create Folder`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'uploadFile' }}
                        onClick={() => {
                            this.props.updateFolderPath(
                                file.path,
                                this.props.openUploadFile
                            );
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_upload2.svg`}
                        />
                        {` Upload File`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'duplicateFolder' }}
                        onClick={() => {
                            this.props.createFolder(`${file.medianame}_copy`);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        />
                        {` Duplicate`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'deleteFolder' }}
                        onClick={() => {
                            this.props.deleteFolder(file.path);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_remove2.svg`}
                        />
                        {` Remove`}
                    </MenuItem>
                </ContextMenu>
            </div>
        );
    };
    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    getFileDefaultIcon = (fileType) => {
      let iconUrl = ''
      switch (fileType) {
        case 'html':
          iconUrl = `${process.env.PUBLIC_URL}/assets/icons/html-file-icon.png`
          break;
        case 'css':
          iconUrl = `${process.env.PUBLIC_URL}/assets/icons/css-file-icon.png`
          break;
        default:
          iconUrl = `${process.env.PUBLIC_URL}/assets/icons/code-file-icon.png`
      }
      return iconUrl
    }

    copyFilePath = (file) => {
        let imgurl = `${localStorage.getItem('imagepath')}` + file.path.replace('/', '');
        this.copyToClipboard(imgurl)
    }

    copyToClipboard(text) {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                this.toast.show({
                    severity: 'success',
                    summary: '',
                    detail: 'File path copied successfully',
                    life: 3000,
                });
            }).catch(err => {
                this.toast.show({
                    severity: 'error',
                    summary: '',
                    detail: 'Failed to copy file path',
                    life: 3000,
                });
            });
        } else {
            // Fallback for HTTP or older browsers
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                this.toast.show({
                    severity: 'success',
                    summary: '',
                    detail: 'File path copied successfully',
                    life: 3000,
                });
            } catch (err) {
                this.toast.show({
                    severity: 'error',
                    summary: '',
                    detail: 'Failed to copy file path',
                    life: 3000,
                });
            }
            document.body.removeChild(textArea);
        }
    }

    _renderImagePreview = (file, id) => {
        let folders = this.props.foldersList.filter((mediaList) => {
            return mediaList.mediatype === 'FL';
        });
        const foldersPath = folders.map((folder, index) => {
            return folder.path;
        });

        let fileTypeArr = file.medianame.split('.');
        let fileType = fileTypeArr[fileTypeArr.length - 1]
        let isFileType = FileTypes.includes(
            fileTypeArr[fileTypeArr.length - 1]
        );

        let defaultImageIcon = isFileType
            ? this.getFileDefaultIcon(fileType)
            : `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
        return (
            <div
                className="mb-2 px-2 col-2 md:col-3 lg:col-2"
                key={id}
            >
                <ContextMenuTrigger id={file.id.toString()}>
                    <div className="card" style={{ padding: '8px' }}>
                        <div className="card-body gridbox">
                            <img
                                className="card-img-top imgPreview"
                                src={`${localStorage.getItem('imagepath')}${
                                    file.path
                                }`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultImageIcon;
                                }}
                                alt="Missing Image"
                            />
                            <div className="title_devide2" />
                            {this.state.editText === true &&
                            this.state.editFileId === file.id ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="newFileName"
                                    value={this.state.newFileName}
                                    onBlur={() => {
                                        {
                                            let pathWoFile = file.path.split(
                                                file.medianame
                                            )[0];

                                            this.setState(
                                                {
                                                    editFileId: '',
                                                    editText: '',
                                                },
                                                () => {
                                                    this.props.moveFile(
                                                        file.path,
                                                        `${pathWoFile}${this.state.newFileName}`
                                                    );
                                                }
                                            );
                                        }
                                    }}
                                    onChange={(event) => {
                                        this.setState({
                                            newFileName: event.target.value,
                                        });
                                    }}
                                />
                            ) : (
                                <p className="card-text text-center">
                                    {file.medianame}
                                </p>
                            )}
                        </div>
                    </div>
                </ContextMenuTrigger>
                <ContextMenu id={file.id.toString()}>
                    <MenuItem
                        data={{ action: 'download' }}
                        onClick={(event) => {
                            this.exportImg(event, file.path, file);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_download.svg`}
                        />
                        {` Download`}
                    </MenuItem>
                    {!isFileType && (
                        <MenuItem
                            data={{ action: 'preview' }}
                            onClick={(event) => {
                                this._openImagePreviewModal(event, file.path);
                            }}
                        >
                            <img
                                className="contextMenuImg"
                                src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                            />
                            {` Preview`}
                        </MenuItem>
                    )}

                    <MenuItem
                        data={{ action: 'rename' }}
                        onClick={(event) => {
                            this._editText(file);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_rename.svg`}
                        />
                        {` Rename`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'duplicteFile' }}
                        onClick={(event) => {
                            let copyToFile = `${
                                file.medianame.split('.')[0]
                            }_copy.${file.medianame.split('.')[1]}`;
                            this.props.copyFile(
                                file.path,
                                file.path.replace(file.medianame, copyToFile)
                            );
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        />
                        {` Duplicate`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'removeFile' }}
                        onClick={() => this.props.deleteFile(file.path)}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_folder.svg`}
                        />
                        {` Remove`}
                    </MenuItem>

                    <MenuItem
                        data={{ action: 'generateThumbnail' }}
                        onClick={() => {
                            this.props.generateThumbnail(file.path);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_move.svg`}
                        />
                        {` Generate Thumbnail`}
                    </MenuItem>

                    <MenuItem
                        data={{ action: 'sortorder' }}
                        onClick={(event) => {
                            this._openSortOrderModal(event, file.id);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            style={{ width: '20px' }}
                            src={`${process.env.PUBLIC_URL}/assets/icons/sort.png`}
                        />
                        {` Set SortOrder`}
                    </MenuItem>
                    <MenuItem
                        data={{ action: 'copy' }}
                        onClick={(event) => {
                            this.copyFilePath(file);
                        }}
                    >
                        <img
                            className="contextMenuImg"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
                        />
                        {` Copy File Path`}
                    </MenuItem>
                </ContextMenu>
            </div>
        );
    };
    render() {
        const { list } = this.props;
        return (
            <React.Fragment>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="grid">
                    {list.length !== 0 &&
                        list.map((file, id) =>
                            file.mediatype === 'FL'
                                ? this._renderFolder(file, id)
                                : this._renderImagePreview(file, id)
                        )}
                    {this._renderImagePreviewModal()}
                    {this._renderSortOrderModal()}
                </div>
            </React.Fragment>
        );
    }
}

GridView.propTypes = {};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        responses: state.crudReducer,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(GridView);
