import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    updateDelivery,
    createDelivery,
    deliverytypeConfig,
    searchfieldsDeliverySlot,
    crudReset,
    getStoreById,
    deleteDeliverySlot,
    deliverycountryConfig
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleSelect from "../../../components/SimpleSelect";
import { produce } from "immer";
import DatePicker from "react-date-picker";
import TenantCode from "../../../components/TenantCode";
import { formatDate, renderOptionsFilter } from "../../../helpers/Util";
import Filter from "../../../components/Filter";
import StatusOptionComp from "../../../components/StatusOptionComp";
import SimpleTable_Radio from "../../../components/SimpleTable_Radio";
import Modal from "react-awesome-modal";

class AddTaskService extends React.PureComponent {
    templatetype = [
        { title: "Delivery", value: "Delivery" },
        { title: "Shipping", value: "Shipping" },
        { title: "Return Item", value: "Return Item" }
    ]

    taskgroup = [
        { title: "Cart", value: "Cart" },
        { title: "Sales Order", value: "Sales Order" }
    ]

    tasktype = [
        { title: "Take Away", value: "Take Away" },
        { title: "Courior", value: "Courior" }
    ]

    statusTask = [
        { name: "Select", id: "" },
        { name: "To Pay", id: "To Pay" },
        { name: "Received", id: "Received" },
        { name: "To Ship", id: "To Ship" },
        { name: "Shipped", id: "Shipped" },
        { name: "Ready To Pickup", id: "Ready To Pickup" },
        { name: "To Received", id: "To Received" },
        { name: "Completed", id: "Completed" },
        { name: "Delivered", id: "Delivered" },
        { name: "Cancelled", id: "Cancelled" }
      ];

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            showMemberDiv: false,
            // Formrelated
            openDeleteprompt: false,
            delete_rec_id: "",
            message: "",    
            storeid: "",
            templatetype: "",
            orderid: "",
            taskcode: "",
            memberid: "",
            taskgroup: "",
            tasktype: "",
            tasktypevalue: "",
            fromid: "",
            toid: "",
            fromidtype: "",
            toidtype: "",
            status: "Active",
            sortorder: 0,
            taskread: "N",
            taskpost: "N",
            sendnotification: "N",
            sendemail: "N",
            sendsms: "N",
            bookdate: "",
            bookslot: "",
            bookamount: "",
            bookcur: "",
            bookstatus: "",
            bookqty: "",
            orderidxtype: "",
            orderidxvalue: "",
            startdate: "",
            enddate: "",
            closedate: "",
            expirydate: "",
            checkedrow: "",
            additionalFields: [
                { field: "", value: "", id: 0 }
            ],
            tableColsMem: [
                {
                  dataField: "memberid",
                  text: "Member Id",
                  sort: true
                },
                {
                  dataField: "title",
                  text: "Title",
                  sort: true
                },
                {
                  dataField: "slotgroup",
                  text: "Slot Group",
                  sort: true
                },                
                {
                  dataField: "storeid",
                  text: "Storeid",
                  sort: true
                },
                {
                  dataField: "qtytype",
                  text: "Qty Type",
                  sort: true
                },
                {
                  dataField: "status",
                  text: "Status",
                  sort: true
                },
                {
                    dataField: "",
                    text: "Actions",
                    formatter: this.cellFormatter,
                    formatExtraData: {
                      action: "ED"
                    },
                    classes: () => {
                      return "lastTd";
                    }
                }
              ],
            // Errors
            tenantCodeError: ""
        };
    }

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        const permissions = localStorage.getItem("permission");
    
        if (row.status !== "Deleted" && permissions.split(",").includes("W")) {
          return (
            <span>
              <Link
                to={{
                  pathname: "/ui/operation-slot/addNew",
                  state: {
                    item: row,
                    isEdit: true
                  }
                }}
              >
                <img
                  title="Edit"
                  className="tableImage"
                  src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                />
              </Link>
    
              <img
                title="Delete"
                className="tableImage"
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                onClick={() => this.deleteStoreInfo(row)}
              />
            </span>
          );
        }
      };
  
    deleteStoreInfo = rowData => {
        this.setState({ message: "Operation Slot deleted successfully!" });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    shdDeleteRec = arg => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(deleteDeliverySlot(this.state.delete_rec_id));
        }
    };
    
    componentDidMount = () => {
        this.props.dispatch(searchfieldsDeliverySlot({body: { status: "Active" }}));
        this.props.dispatch(deliverytypeConfig({body: { language: "en", propgroup: "OPS", propvalue: "Delivery" }}));
    }

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            if (rec) {
                const {
                    id,
                    taskgroup,
                    memberid,
                    orderid,
                    appid,
                    storeid,
                    currency,
                    totalamount,
                    orderdate,
                    totalqty,
                    salestype,
                    status,
                    deliverystatus,
                    deliverymsg,
                    deliveryinfo
                } = rec;
                this.props.dispatch(getStoreById(storeid));
                this.props.dispatch(deliverycountryConfig({body: { status: "Active", propgroup: "Country", storeid }}));
                 let odate = this.toDate(orderdate);
                this.setState(
                    produce(draft => {
                        draft.id = id ? id : "";
                        draft.isEdit = isEdit;
                        draft.storeid = storeid ? storeid : "";
                        draft.status = status ? status : [];
                        draft.deliverystatus = deliverystatus ? deliverystatus : "";
                        draft.orderid = orderid ? orderid : "";
                        draft.taskgroup = taskgroup ? taskgroup : "";
                        draft.memberid = memberid ? memberid : "";
                        draft.appid = appid ? appid : "";
                        draft.currency = currency ? currency : "";
                        draft.totalamount = totalamount ? totalamount : "";
                        draft.orderdate = orderdate ? odate : "";
                        draft.totalqty = totalqty ? totalqty : "";
                        draft.salestype = salestype ? salestype  : "";
                        draft.deliverymsg = deliverymsg ? deliverymsg : "";
                        draft.contactno = deliveryinfo ? deliveryinfo.contactno : "";
                        draft.contactname = deliveryinfo ? deliveryinfo.contactname : "";
                        draft.country = deliveryinfo ? deliveryinfo.country : "";
                        draft.state = deliveryinfo ? deliveryinfo.state : "";
                        draft.city = deliveryinfo ? deliveryinfo.city : "";
                        draft.town = deliveryinfo ? deliveryinfo.town : "";
                        draft.building = deliveryinfo ? deliveryinfo.building : "";
                        draft.postalcode = deliveryinfo ? deliveryinfo.postalcode : "";
                        draft.areacode = deliveryinfo ? deliveryinfo.areacode : "" ;
                        draft.address = deliveryinfo ? deliveryinfo.address : "";
                        draft.remark = deliveryinfo ? deliveryinfo.remark : "";
                    })
                );
            }
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errorMessage } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errorMessage && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    getCreatePayload = () => {
        const {
          taskgroup,
          orderid,
          appid,
          taskcode,
          memberid,
          storeid,
          currency,
          totalamount,
          orderdate,
          totalqty,
          salestype,
          status,
          deliverystatus,
          deliverymsg,
          contactno,
          contactname,
          country,
          state,
          city,
          town,
          building,
          postalcode,
          areacode,
          address,
          remark
        } = this.state;

        let body = {
           taskgroup,
           orderid,
           appid,
           taskcode,
           memberid,
           storeid,
           currency,
           totalamount,
           orderdate: formatDate(orderdate),
           totalqty,
           salestype,
           status,
           deliverystatus,
           deliverymsg,
           deliveryinfo: {
             contactname,
             contactno,
             country,
             state,
             city,
             town,
             building,
             postalcode,
             areacode,
             address,
             remark
           }
        };
        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body
        };
        return payload;
    };

    toDate = dateStr => {
        let totalStr = dateStr.split(" ");
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
        else{
            var parts = dateStr.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    callFetch = () =>{
        const { storeid } = this.state;
        this.props.dispatch(deliverycountryConfig({body: { status: "Active", propgroup: "Country", storeid }}));
        this.updateFields();
    }

    updateFields = () => {
        const  { dataByCountry } = this.props.queryData;
        const { storeid } = this.state;
        if(dataByCountry.length > 0){
            let getbystore = dataByCountry.filter(e=>e.storeid === storeid);
            if(getbystore.length > 0){
                this.setState({
                    country: getbystore[0].country,
                    city: getbystore[0].city
                })
            }
        }
    }

    _createDeliveryService = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateDelivery(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createDelivery(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Please clear all the errors to continue !!!"
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            titleError: "",
            formError: false,
            formErrorMsg: ""
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            storeid
        } = this.state;
        let valid = true;
        if (storeid.trim() === "") {
            this.setState({
                tenantCodeError: "Please enter a storeid"
            });
            valid = false;
        }
        return valid;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createDeliveryService();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={()=>this._createDeliveryService()}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/orders-fulfillment"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 }, ()=>{
            this.callFetch();
        });
    };

    componentWillReceiveProps = () => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
          const list = languageConfig[0].additionalfields
          this.setState({
            langlist: list
          })
        }
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) {
                storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
                    this.setState({ storelabel: this.state.storelabel })
            };
        }
    };

    handleorderdate = value => {
        this.setState({
          orderdate: value
        });
    };

    _openMemberDiv = () => {
        this.setState({ showMemberDiv: true });
    };

    _closeMemberDiv = () => {
        this.setState({ showMemberDiv: false });
    };

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: row.memberid,
                checkedrow: row.id,
                bookslot: row.title,
                toidtype: "Member",
                toid: row.memberid
            }));
        } else {
            this.setState(() => ({
                selected: ""
            }));
        }
    };

    showTable = () => {
        const { deliveryslotList } = this.props.queryData;
        return(
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-sm-12 col-lg-12">
                        <SimpleTable_Radio
                            columns={this.state.tableColsMem}
                            rows={deliveryslotList}
                            handleOnSelect={this.handleOnSelect}
                            checkedrow={this.state.checkedrow}
                            callback={() => {
                                this.callFetchMem();
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }

     _renderMemberModal = () => {
        return (
            <Modal
                visible={this.state.showMemberDiv}
                width="90%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeMemberDiv()}
            >
                <div className="modalForm queryFormModal">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">Member Search</div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a onClick={this._closeMemberDiv} className="closeIcon">
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {this._renderMemberForm()}
                </div>
            </Modal>
        );
    };

    _renderMemberForm = () => {
        return (
            <React.Fragment>
                {this.state.showMemberDiv === true ?
                    <div>
                        <div>{this.showTable()}</div>
                        <div className="row btn-modal form-button">
                            <div className="col-sm-12 col-lg-12">
                                <button
                                    type="submit"
                                    className="btn btn-themes btn-rounded"
                                    onClick={event => {
                                        if (event) event.preventDefault();
                                        this.setState({
                                            memberid: this.state.selected
                                        }, () => {
                                            this._closeMemberDiv();
                                        })
                                    }}
                                >
                                    Submit
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-themes btn-rounded"
                                    onClick={event => {
                                        if (event) event.preventDefault();
                                        this._closeMemberDiv();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>  
                    </div>
                : <div></div>
                }
            </React.Fragment>
        )
    }

    _renderMainFormDetails = () => {
        const { deliverytypeList } = this.props.queryData;
        let dlist = Object.assign({}, ...deliverytypeList.map(e=>(e.additionalfields)));
        return (
            <React.Fragment>
                   <div className="row">
                    <div className="form-group col-lg-6 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        />
                        <div className="invalid-feedback">{this.state.tenantCodeError}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="appid" className="floatLeft">
                           App Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="appid"
                            value={this.state.appid}
                            onChange={event => this.setState({ appid: event.target.value })}
                        />
                    </div>
                </div>
                
                <div className="row">
                   <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="taskgroup" className="floatLeft">
                           Task Group
                         </label>
                         <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(this.taskgroup)}
                            selectedOption={this.state.taskgroup}
                            callbackChangeFilter={event => {
                                this.setState({ taskgroup: event.target.value })
                            }}
                            id="taskgroup"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="salestype" className="floatLeft">
                           Sales Type
                        </label>
                        <SimpleSelect
                            objArray={dlist}
                            callbackChangeFilter={value =>
                                this.setState({ salestype: value })
                            }
                            selectedOption={this.state.salestype}
                            id="salestype"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                       <label htmlFor="memberid" className="floatLeft">
                          Member Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="memberid"
                            value={this.state.memberid}
                            onClick={event => {
                                if (event) event.preventDefault();
                                this._openMemberDiv();
                            }}
                        />  
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                       <label htmlFor="orderid" className="floatLeft">
                            Order Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="orderid"
                            value={this.state.orderid}
                            onChange={event => this.setState({ orderid: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">       
                    <div className="form-group col-lg-6 col-sm-12">
                       <label htmlFor="orderdate" className="floatLeft">
                            Order Date
                        </label>
                        <DatePicker
                            onChange={this.handleorderdate}
                            value={this.state.orderdate}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                       <label htmlFor="totalqty" className="floatLeft">
                            Total Quantity
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="totalqty"
                            value={this.state.totalqty}
                            onChange={event => this.setState({ totalqty: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">                  
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="totalamount" className="floatLeft">
                            Total Amount
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="totalamount"
                            value={this.state.totalamount}
                            onChange={event => this.setState({ totalamount: event.target.value })}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                       <label htmlFor="currency" className="floatLeft">
                           Currency
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="currency"
                            value={this.state.currency}
                            onChange={event => this.setState({ currency: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="deliverymsg" className="floatLeft">
                           Delivery Message
                        </label>
                        <textarea
                            className="form-control"
                            id="deliverymsg"
                            value={this.state.deliverymsg}
                            onChange={event => this.setState({ deliverymsg: event.target.value })}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderDeliveryInformation = () => {
        const { deliverycountryList } = this.props.queryData;
        let cnlist = deliverycountryList.map(e=>({title: e.propvalue, value: e.propvalue}));
        let citylist = Object.assign({}, ...deliverycountryList.map(e=>(e.additionalfields)));
        return (
            <React.Fragment>
                <div className="row sub-title">Delivery Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="contactname" className="floatLeft">
                            Contact Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactname"
                            value={this.state.contactname}
                            onChange={event => this.setState({ contactname: event.target.value })}
                        />
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="contactno" className="floatLeft">
                            Contact Number
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactno"
                            value={this.state.contactno}
                            onChange={event => this.setState({ contactno: event.target.value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="address" className="floatLeft">
                            Address
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={this.state.address}
                            onChange={event =>
                                this.setState({ address: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="areacode" className="floatLeft">
                            Area Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="areacode"
                            value={this.state.areacode}
                            onChange={event => this.setState({ areacode: event.target.value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="building" className="floatLeft">
                            Building
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="building"
                            value={this.state.building}
                            onChange={event =>
                                this.setState({ building: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="town" className="floatLeft">
                            Town
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="town"
                            value={this.state.town}
                            onChange={event =>
                                this.setState({ town: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="country" className="floatLeft">
                            Country
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(cnlist)}
                            selectedOption={this.state.country}
                            callbackChangeFilter={event => {
                                this.setState({ country: event.target.value })
                            }}
                            id="country"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="city" className="floatLeft">
                            City
                        </label>
                        <SimpleSelect
                            objArray={citylist}
                            callbackChangeFilter={value =>
                                this.setState({ city: value })
                            }
                            selectedOption={this.state.city}
                            id="city"
                        />  
                    </div>
                </div>


                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="state" className="floatLeft">
                            State
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="state"
                            value={this.state.state}
                            onChange={event =>
                                this.setState({ state: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="postalcode" className="floatLeft">
                            Postal Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="postalcode"
                            value={this.state.postalcode}
                            onChange={event => this.setState({ postalcode: event.target.value.replace(/[^0-9]/g, '') })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="remark" className="floatLeft">
                            Remarks
                        </label>
                        <textarea
                            className="form-control"
                            id="remark"
                            value={this.state.remark}
                            onChange={event => {
                                this.setState({ remark: event.target.value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

     
    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>  
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption = {this.statusTask}
                            selectedOption={this.state.status}
                            callbackChangeFilter={value => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                   <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="deliverystatus" className="floatLeft">
                           Delivery Status
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="deliverystatus"
                            value={this.state.deliverystatus}
                            readOnly={true}
                        />
                        {/* <StatusOptionComp
                            statusoption = {this.statusTask}
                            selectedOption={this.state.deliverystatus}
                            callbackChangeFilter={value => {
                                this.setState({ status: value });
                            }}
                        /> */}
                    </div>
                </div>
            </React.Fragment>
        );
    };
 
    
    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>     
                        <form className="splitFrmDiv text-center" style={{ marginTop: "2em" }}>
                            {this._renderDeliveryInformation()}
                        </form>               
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec text-center">
                            {this._renderStatusDetails()}
                        </form>                        
                    </div>
                </div>
            </React.Fragment>
        );
    };
    hideModal = e => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        console.log(this.props);
        return (
            <React.Fragment>
                {this.state.showMemberDiv && this._renderMemberModal()}
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? "Edit Delivery Details"
                                    : "Add Delivery Details"}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/orders-fulfillment"
                        children={
                            <div style={{ padding: "2em", color: "green" }}>
                                {this._isEditMode()
                                    ? "Delivery Details updated Successfully"
                                    : "Delivery Details created Successfully"}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        modalindex={true}
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this Operation slot?
                                <div style={{ margin: "2em" }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddTaskService);
