import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

class SimpleTable_Select extends React.PureComponent {
  constructor(props) {
    super(props);

    // if (props.pagination === undefined) {
    //   props.pagination = true
    // }
    
    this.state = {  };
  }

  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="btn-group" role="group">
        {this.props.rows.length !== 0 &&
          options.map(option => (
            <button
              key={option.text}
              type="submit"
              onClick={() => {
                onSizePerPageChange(option.page);
              }}
              className={`btn ${
                currSizePerPage === `${option.page}`
                  ? "btn-secondary"
                  : "btn-warning"
              }`}
            >
              {option.text}
            </button>
          ))}
      </div>
    );

    const options = {
      sizePerPageRenderer,
      onSizePerPageChange: (sizePerPage, page) => {
        this.props.callback();
      }
    };
    const option = {
      noDataText: (
        <i
          className="fa fa-circle-o-notch fa-spin"
          style={{ fontSize: "24px" }}
        ></i>
      )
    };
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      onSelect: this.props.handleOnSelect,
      onSelectAll: this.props.handleOnSelectAll
     // selected: this.props.selected
    };

    const { columns, rows, selectMode } = this.props;
    
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={rows}
          columns={columns}
          search
          bootstrap4
        >
          {props => (
            <div>
              {
                <div className="col-12 alignRight noPadding noMargin">
                  <SearchBar
                    style={{
                      border: "1px solid #007bff",
                      height: "2.9em",
                      borderRadius: "4px",
                      color: "#007bff",
                      marginLeft: "2px",
                      marginBottom: "4px"
                    }}
                    {...props.searchProps}
                  />
                </div>
              }
              <div className="table-wrapper">
                <BootstrapTable
                  {...props.baseProps}
                  // {...props.pagination && {
                  //   pagination={paginationFactory(options)}
                  // }}
                  pagination={this.props.pagination ? paginationFactory(options) : null}
                  options={option}
                  selectRow={selectRow}
                />
                {this.props.rows.length === 0 && (
                  <div className="tableNoData">
                    Your search does not retrieve any data. Please search again
                    using another criteria.
                  </div>
                )}
              </div>
            </div>
          )}
        </ToolkitProvider>
      </React.Fragment>
    );
  }
}
SimpleTable_Select.propTypes = {};

export default SimpleTable_Select;
