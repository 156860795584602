import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import {
  createArticleInfoApi,
  updateArticleInfoApi,
  crudReset,
  updateArticleModeType
} from "../../../../actions/actions";
// Local Imports
// Local Imports
import ConfirmAlert from "../../../../components/ConfirmAlert";
import SimpleSelect from "../../../../components/SimpleSelect";
import StatusComp from "../../../../components/StatusComp";
import { produce } from "immer";
import DynamicTable from "../../../../components/DynamicTable";
import DynamicTableImage from "../../../../components/DynamicTableImage";
import { objtoarr, convertStrNum, formatDate } from "../../../../helpers/Util";

class AddWebArticle extends React.PureComponent {

  defaultimageField =  { uri: "", title: "", alttext: "", width: "", height: "", tag: "", sortorder: 0, id: 0};

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEdit: false,
      // Formrelated
      articlecode: "",
      articletype: "",
      title: "",
      titlelong: "",
      desc: "",
      desclong: "",
      group: "",
      page: "",
      section: "",
      properties: [
          { field: "", value: "", id:0 }
      ],
      startdate: new Date(),
      expirydate: new Date(),
      status: "New",
      images: [Object.assign({}, this.defaultimageField)],
      nxtImgCounter: 1,
      sortorder: 0,
      language: "",
      langlist: {},
      // Errors
      titleError: "",
      descError: "",
      propertyError: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    const { languageConfig } = this.props.queryData;
    const { languageConfig: prevlanguageConfig } = prevProps.queryData;
    if(languageConfig !== prevlanguageConfig){
      if (languageConfig.length > 0) {
        const list = languageConfig[0].additionalfields
        this.setState({
          langlist: list
        })
      }
    }
  }

    handleAddRow = e => {
        if (e) e.preventDefault();
        const rowLength = this.state.properties.length;
        let lastele = this.state.properties[rowLength - 1];
        const item = {
            field: "",
            value: "",
            id: lastele.id + 1
        };
        this.setState(
            {
                properties: [...this.state.properties, item]
            },
            () => {
                console.log("Rows After Add", this.state.properties);
            }
        );
    };

    handleInputChange = (event, dataField, row) => {
      const value = event.target.value.trim()
      let obj = {
          id: row.id,
          [dataField]:
              dataField === "validation" ? [value] : value
      };
      this.setState(
          produce(draft => {
              if (draft.properties.length === 0) {
                  draft.properties.push(obj);
              } else {
                  let found = false;
                  draft.properties.forEach(command => {
                      if (command.id === obj.id) {
                          Object.assign(command, obj);
                          found = true;
                      }
                  });
                  if (!found) {
                      draft.properties.push(obj);
                  }
              }
          }),
          () => { }
      );
    };
    deleteRow = row => {
        let rowId = row.id;
        const rowLength = this.state.properties.length;
        if (rowLength > 1) {
            const updateValues = this.state.properties.filter(
                value => rowId !== value.id
            );

            this.setState({ properties: updateValues });
        }
    };

    handleImageAddRow = e => {
        if (e) e.preventDefault();

        const item = {
            uri: "",
            title: "",
            alttext: "",
            width: "",
            height: "",
            tag: "",
            sortorder: 0,
            id: parseInt(this.state.nxtImgCounter)
        };

        this.setState(
          produce(draft => {
            draft.nxtImgCounter = this.state.nxtImgCounter + 1;
            draft.images= [...this.state.images, item];
          }),
          () => {
              console.log("Rows After Add", this.state.images);
          }
        );
    };


    handleImageInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === "validation" ? [event.target.value] :
                dataField === "uri" ? event :
                dataField === "width" ? event :
                dataField === "height" ? event :
                event.target.value
        };
        this.setState(
            produce(draft => {
                if (draft.images.length === 0) {
                    draft.images.push(obj);
                } else {
                    let found = false;
                    draft.images.forEach(command => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.images.push(obj);
                    }
                }
            }),
            () => { }
        );
    };

  deleteImageRow = row => {
      let rowId = row.id;
      const updateValues = this.state.images.filter(
        value => rowId !== value.id
      );

      this.setState(
        produce(draft => {
          draft.images=updateValues;
        }),
        () => {
          if(this.state.images.length === 0) {
            this.handleImageAddRow()
          }
        }
      );
  };

  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  getCreatePayload = () => {
    const {
      articlecode,
      title,
      titlelong,
      desc,
      desclong,
      page,
      section,
      properties,
      group,
      startdate,
      expirydate,
      language,
      sortorder,
      status,
      images,
      articletype
    } = this.state;
    let addfield = properties.reduce(function (acc, item) {
      if(item.field.trim() !== "" && item.value.trim() !== ""){
        acc[item.field] = item.value
        return acc
      }
        return acc
    }, {});
    let imagefield = {};
    let imagefieldCounter = 1;
    images.forEach((obj, idx) => {
      if(obj.uri !== "") {
        const newObj = Object.assign({}, obj)
        if (typeof newObj.sortorder === 'undefined' || newObj.sortorder === "") {
          newObj['sortorder'] = 0;
        }
        else {
          try {
            newObj['sortorder'] = Number(newObj['sortorder']);
          }
          catch(e) {
            newObj['sortorder'] = 0
          }
        }
        imagefield[`image${imagefieldCounter++}`] = newObj
      }
    });
    let body = {
      title: title,
      articlecode,
      publishmode: "manual",
      articletype,
      status: status,
      sortorder: sortorder,
      startdate: formatDate(startdate),
      expirydate: formatDate(expirydate),
      language,
      articlegroup: group,
      page,
      section,
      fields: {
        title,
        title_long: titlelong,
        desc: desc,
        desc_long: desclong,
        properties: addfield
      },
      imagegallery: imagefield
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createWebArticle = () => {
    if (this._validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(updateArticleInfoApi(this.getCreatePayload(), this.state.id));
        this._updateArticleMode();
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
        this._updateArticleMode();
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };

  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      titleError: "",
      descError: "",
      propertyError: ""
    });
  };

  _isWebSection = (section) => {
    if(section) {
      switch(section.trim()) {
        case 'image':
        case 'banner':
        case 'figure':
          return true;
        default:
          return false;
      }
    }
  }

  _isUploadedImageCorrect = () => {
    const imgGalleries = this.state.images
    const props = this.state.properties || []
    for(let loop=0; loop < props.length; loop++) {

      const field = props[loop].field

      if((field.includes('_') || field.includes(' with ')) && this._isWebSection(this.state.section)) {
        console.log("Cater for web")
        const splitType1 = field.split('_')
        const splitType2 = field.split(' with ')
        const key = splitType1.length === 2?splitType1[0]:splitType2.length === 2?splitType2[0]:''
        if(key !== '' && imgGalleries.length > 0 && imgGalleries[0].uri !== '') {
          const foundMatch = imgGalleries.find((image) => {
            const tag = image.tag || ''
            return (tag.startsWith(key+",") || (tag === key))
          })
          if(typeof foundMatch === 'undefined') {
            return [false, key]
          }
        }
      }
    }
    return [true, '']
  }
  _validateForm = () => {
    this._clearErrorMessages();
    const { title, desc } = this.state;
    let valid = true;
    if (title.trim() === "") {
      this.setState({ titleError: "Title is required." });
      valid = false;
    }

    const imgCheck = this._isUploadedImageCorrect();
    if (!imgCheck[0]) {
      this.setState({ propertyError: `Image tags and key("${imgCheck[1]}") do not match.` });
      valid = false;
    }
    return valid;
  };
  handlestartdateChange = value => {
    this.setState({
      startdate: value
    });
  };
  handleexpirydateChange = value => {
    this.setState({
      expirydate: value
    });
  };

  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  _isEditMode = () => {
    return this.state.isEdit;
  };
  
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createWebArticle}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={this._createWebArticle}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/page-articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          onClick={() => this._updateArticleMode()}
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  componentWillReceiveProps = () => {
    if (this._isEditMode()) {
        this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
        const defLanguage = this.props.location.state.language;
        const articleModeType = this.props.location.state.articleModeType;
        this.setState({ language: defLanguage, articletype: articleModeType });
    }
  };

  _updateArticleMode = () =>{
    const isEditMode = this._isEditMode();
    if(!isEditMode){
      const { articleModeType } = this.props.location.state;
      return  this.props.dispatch(
        updateArticleModeType({ body: { mode: articleModeType } })
      );
    }
    else {
      const { articleModeType } = this.props.location.state;
      return this.props.dispatch(
        updateArticleModeType({ body: { mode: articleModeType } })
      );
    }
  }

  _renderMainFormDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlecode" className="floatLeft">
              Article Type
            </label>
            <input
              type="text"
              className="form-control"
              id="articletype"
              value={this.state.articletype}
              disabled={true}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlecode" className="floatLeft">
              Article Code
            </label>
            <input
              type="text"
              className="form-control"
              id="articlecode"
              value={this.state.articlecode}
              readOnly={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => this.setState({ title: event.target.value })}
            />
            <div className="invalid-feedback">{this.state.titleError}</div>
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.group}
              onChange={event => {
                this.setState({ group: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="page" className="floatLeft">
              Page
            </label>
            <input
              type="text"
              className="form-control"
              id="page"
              value={this.state.page}
              onChange={event => this.setState({ page: event.target.value })}
            />
          </div>

          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="section" className="floatLeft">
              Section
            </label>
            <input
              type="text"
              className="form-control"
              id="section"
              value={this.state.section}
              onChange={event => {
                this.setState({ section: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="titlelong" className="floatLeft">
              Title Long
            </label>
            <textarea
              className="form-control"
              id="titlelong"
              value={this.state.titlelong}
              onChange={event => this.setState({ titlelong: event.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desc" className="floatLeft">
              Description
            </label>
            <textarea
              className="form-control"
              id="desc"
              value={this.state.desc}
              onChange={event =>
                this.setState({ desc: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="desclong" className="floatLeft">
              Description Long
            </label>
            <textarea
              className="form-control"
              id="desclong"
              value={this.state.desclong}
              onChange={event =>
                this.setState({ desclong: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="startdate" className="floatLeft">
              Start Date
            </label>
            <DatePicker
              minDate={new Date()}
              onChange={this.handlestartdateChange}
              value={this.state.startdate}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="expirydate" className="floatLeft">
              Expiry Date
            </label>
            <DatePicker
              minDate={this.state.startdate}
              onChange={this.handleexpirydateChange}
              value={this.state.expirydate}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderProperties = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Properties </div>
        <div className="invalid-feedback col-lg-12 col-sm-12 invalid-feedback">{this.state.propertyError}</div>
        <div className="form-group col-lg-12 col-sm-12">
          <DynamicTable
            columns={[
              { dataFieldId: "field", label: "Key", addRow: true },
              { dataFieldId: "value", label: "Value", addRow: true },
              {
                dataFieldId: "AD",
                label: ""
              }
            ]}
            rows={this.state.properties}
            deleteRow={this.deleteRow}
            addRow={this.handleAddRow}
            handleInputChange={this.handleInputChange}
          />
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  _renderImageDetails = () => {
    return (
      <React.Fragment>
        <div className="row sub-title">Images </div>

        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
          <DynamicTableImage
              columns={[
                { dataFieldId: "uri", label: "uri", addRow: true },
                { dataFieldId: "title", label: "Title", addRow: true },
                { dataFieldId: "alttext", label: "Alternate Text", addRow: true },
                { dataFieldId: "width", label: "Width", addRow: true },
                { dataFieldId: "height", label: "Height", addRow: true },
                { dataFieldId: "tag", label: "Tag", addRow: true },
                { dataFieldId: "sortorder", label: "Order", addRow: true },
                {
                  dataFieldId: "AD",
                  label: ""
                }
              ]}
              rows={this.state.images}
              deleteRow={this.deleteImageRow}
              addRow={this.handleImageAddRow}
              handleImageInputChange={this.handleImageInputChange}
              mediaImageList={this.props.queryData.mediaList}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row sub-title">Actions</div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              disabled={true}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft required">
              Sort Order
            </label>
            <input
              type="text"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderProperties()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form
              className="splitFrmDivSec text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 noPadding noMargin">
            <form
              className="splitFrmDiv text-center"
              style={{ marginTop: "2em" }}
            >
              {this._renderImageDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _convertToImageGallery = (imageGalleryArray) => {
    if(typeof imageGalleryArray === 'undefined' || imageGalleryArray === null) {
      return []
    }

    const newImageGalleryArray = [];

    for(let loop=0; loop<imageGalleryArray.length; loop++) {
      const _imageGalleryArray = Object.assign({}, imageGalleryArray[loop]);
      _imageGalleryArray.id = loop;
      newImageGalleryArray.push(_imageGalleryArray)
    }
    return newImageGalleryArray;
  }

  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          title,
          status,
          sortorder,
          articlegroup,
          page,
          section,
          fields,
          imagegallery,
          startdate,
          expirydate,
          articletype,
          articlecode
        } = rec;
        let defaultpropField = [{ field: "", value: "", id: 0 }];
        let defaultimageField = [Object.assign({}, this.defaultimageField)];
        let imagearr = this._convertToImageGallery(convertStrNum(imagegallery));

        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.articletype = articletype ? articletype : "";
            draft.articlecode = articlecode ? articlecode : "";
            draft.group = articlegroup ? articlegroup : "";
            draft.desc = fields.desc ? fields.desc : "";
            draft.desclong = fields.desc_long ? fields.desc_long : "";
            draft.page = page ? page : "";
            draft.section = section ? section : "";
            draft.titlelong = fields.title_long ? fields.title_long : "";
            draft.startdate = startdate ? this.toDate(startdate) : new Date();
            draft.expirydate = expirydate
              ? this.toDate(expirydate)
              : new Date();
            draft.properties = typeof fields.properties !== "undefined" &&
                        Object.keys(fields.properties).length !== 0
                        ? objtoarr(fields.properties) :
                        defaultpropField
            draft.images = typeof imagearr !== "undefined" &&
            imagearr.length !== 0
            ? imagearr :
            defaultimageField ;
            draft.nxtImgCounter = typeof imagearr !== "undefined" &&
            imagearr.length !== 0
            ? imagearr[imagearr.length -1].id + 1 :
            1;
          })
        );
      }
    }
  };
  render() {
    const { isSuccess } = this.props.crudData;
    const { articletype } = this.state;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode()
                  ? "Edit "+articletype
                  : "Add "+articletype}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              {this._renderErrorMessage()}
              {this._renderFormArea()}
            </div>
            <div className="row btn-container form-button">
              <div className="col-sm-12 col-lg-12">
                {this._renderMainButton()}
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/page-articles"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() 
                  ? articletype+" updated successfully!"
                  : articletype+" created successfully!"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddWebArticle);
