import React from "react";
import { searchFields } from "./tableCols";
import { connect } from "react-redux";
import { produce } from "immer";
import {
  searchGeneral,
  crudReset,
  resetAction,
  deleteSaleDocument
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleTable from "../../../components/SimpleTable";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleSearcher from "../../../components/SimpleSearcher";
import { canDelete, canView, canWrite, permissions } from "../../../helpers/Util";

class SalesDocumentList extends React.PureComponent {

  constructor(props) {

    super(props);
    this.companyId = localStorage.getItem("company_id")
    this.module = "SALES"
    this.group = "DE_SAL_PRD_MAPPING"
    this.allPermissions = []

    this.state = {

      pages: 10,
      currPage: 1,
      showSearchModal: false,
      query: {},
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      tableCols: [
        {
          dataField: "categorycode",
          text: "Category Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "storeid",
          text: "Store Id",
          sort: true
        },
        {
          dataField: "docgroup",
          text: "Doc Grp",
          sort: true
        },
        {
          dataField: "doctype",
          text: "Doc Type",
          sort: true
        },
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },

        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {

    if (row.status === "Deleted") { return }

    return (
      <span>
        {canView(this.allPermissions) && (
          <Link
            to={{
              pathname: "/ui/sales-document/addNew",
              state: {
                item: row,
                isEdit: false,
                isView: true
              }
            }}
          >
            <img
              title="View"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
            />
          </Link>
        )}

        {canWrite(this.allPermissions) && (
          <Link
            to={{
              pathname: "/ui/sales-document/addNew",
              state: {
                item: row,
                isEdit: true
              }
            }}
          >
            <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link>
        )}

        {canDelete(this.allPermissions) && (
          <img
            title="Delete"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this._deleteData(row)}
          />
        )}
      </span>
    );
  };

  // API Calls
  createFetchUrl = fetchType => {
    const {
      search_field,
      search_condi,
      search_value,
      pages,
      currPage
    } = this.state;

    const requestBody = {
      body: {
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value,
        pagesize: pages,
        pageno: currPage
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "sales/v1/product/document/search",
        this.createFetchUrl(fetchType),
        "SEARCH_SALEDOC_SUCCESS",
        "SEARCH_SALEDOC_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        /*
        const [pgNumber] = rest;

        newState = produce(this.state, draft => {
          draft.currPage = pgNumber - 1;
        });
        this.setState({ ...newState }, () => {
          this.callFetch("PAGECLICK");
        });
        */
    this.callFetch();
        break;
    }
  };
  searchQuery = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("SEARCH");
      }
    );
  };

  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage }, () =>
      this.callQueryApiForFetch("ONLOAD")
    );
    this.allPermissions = permissions(this.module, this.group)
  }

  /*
  pageNumberOnClick = pgNumber => {
    this.callQueryApiForFetch("PAGECLICK", pgNumber);
  };
  */

  _renderAddNewBtn = () => {

    if (!canWrite(this.allPermissions)) { return }
    return (

      <Link
        to="/ui/sales-document/addNew"
      >
        <button className="page-header-btn icon_btn display-inline">
          <img
            title="edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
          />
          {`Add New`}
        </button>
      </Link>
    );
  };
  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteSaleDocument(this.state.delete_rec_id));
    }
  };
  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  _deleteData = rowData => {
    this.setState({ message: "Product Mapping  deleted successfully" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };

  render() {
    const {
      saleDocument,
      
      errormsg: queryError,
      servererror: serverError
    } = this.props.queryData;

    const { isSuccess, errormsg: crudError } = this.props.crudData;

    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }

    if (!saleDocument && !errormsg) {
      return <Loading />;
    }
    if (this.props.queryData.apiPending) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">
                Product Mapping
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-5 col-sm-12 noPadding noMargin">
                    {/* <h3 className="page-title">Point Rule Registration</h3> */}
                  </div>
                  <div className="col-lg-5 col-sm-12 alignRight noPadding noMargin">
                    <SimpleSearcher
                      filterOptions={searchFields}
                      setAndSearch={this.searchQuery}
                      search_condi={this.state.search_condi}
                      search_value={this.state.search_value}
                      search_field={this.state.search_field}
                    ></SimpleSearcher>
                  </div>

                  <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
                    {this._renderAddNewBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                {/* <div className="invalid-feedback">{errormsg}</div> */}
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={saleDocument}
                  pageChangeCallback={(currPage) => {
                    this.setState({
                      currPage: currPage
                    },()=>{
                      this.callQueryApiForFetch("PAGECLICK");
                    })
                  }}
                  callback={(pageSize) => {
                    this.setState(produce(this.state, draft => {
                      if (draft.pages < pageSize) {
                        draft.currPage = 1
                      }
                      draft.pages = pageSize
                    }), () => { this.callQueryApiForFetch("PAGECLICK")
                  })
                  }}
                  withPageSize={this.state.pages}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this  point rule?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec deletBtn"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(SalesDocumentList);
