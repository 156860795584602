import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    createAddMapInventory,
    updateInventoryByStore,
    searchGeneral,
    queryInventoryByStore,
    storeSearchByFields,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { crudReset } from '../../../actions/actions';
import Autocomplete from './../../../components/Autocomplete';
import SimpleSelect from './../../../components/SimpleSelect';
import Modal from 'react-awesome-modal';
import { produce } from 'immer';
import AddStock from './AddStock';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';
import { Button } from 'primereact/button';

class AddStoreInventory extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            showProdModal: false,
            isEdit: false,
            title: '',
            // Formrelated
            storeid: '',
            storelabel: '',
            productcode: '',
            stopsell: 'N',
            stock: 'Y',
            avlintems: '0',
            categorycode: '',
            categories: '',
            status: 'Active',
            storelabel: '',
            showStockPage: false,
            // Errors
            productcodeerror: '',
            titleerror: '',
            qty: '',
            invallocationid: '',
        };
    }

    getCreatePayload = () => {
        const {
            storeid,
            productcode,
            stopsell,
            stock,
            status,
            avlintems,
            categorycode,
            id,
            title,
            qty,
            invallocationid,
        } = this.state;
        let body = {
            productcode,
            stopsell,
            stock,
            storeid: storeid,
            status: status,
            avlintems,
            categorycodes: categorycode,
            qty,
            invallocationid,
            additionalfields: {
                title,
            },
        };
        if (this._isEditMode()) {
            body['id'] = id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    componentWillMount = () => {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isAdd = this.props.location.state.isAdd;
            if (rec) {
                const {
                    id,
                    storeid,
                    productcode,
                    stopsell,
                    stock,
                    status,
                    avlitems,
                    categorycodes,
                    title,
                    qty,
                    invallocationid,
                } = rec;
                this.props.dispatch(storeSearchByFields(`storeid=${storeid}`));
                this.props.dispatch(
                    searchGeneral(
                        `sales/v1/product/category/${categorycodes}`,
                        {
                            body: {
                                status: 'Active',
                            },
                        },
                        'PROD_KEY_SEARCH_SUCCESS',
                        'PROD_KEY_SEARCH_FAILURE',
                        true
                    )
                );
                if (isEdit === true) {
                    this.setState(
                        produce((draft) => {
                            draft.id = id ? id : '';
                            draft.isEdit = isEdit;
                            draft.title = title ? title : '';
                            draft.categorycode = categorycodes
                                ? categorycodes
                                : '';
                            draft.storeid = storeid ? storeid : '';
                            draft.productcode = productcode ? productcode : '';
                            draft.stopsell = stopsell ? stopsell : '';
                            draft.stock = stock ? stock : '';
                            draft.avlintems = avlitems ? avlitems : '0';
                            draft.status = status ? status : 'Active';
                            draft.qty = qty ? qty : '';
                            draft.invallocationid = invallocationid
                                ? invallocationid
                                : '';
                        })
                    );
                }
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        const { invData, prodKeySearch } = this.props.queryData;
        const { invData: previnvData, prodKeySearch: prevprodKeySearch } =
            prevProps.queryData;
        if (invData !== previnvData) {
            this._renderProductByStore(invData);
        }
        if (prodKeySearch !== prevprodKeySearch) {
            let getbycode = prodKeySearch.filter(
                (e) => e.productcode === this.state.productcode
            );
            let gettitle = getbycode.length > 0 ? getbycode[0].title : '';
            this.setState({ title: gettitle });
        }
    };

    componentWillReceiveProps = (prevProps) => {
        if (this._isEditMode()) {
            //const { storeArray, prodKeySearch } = this.props.queryData;
            const { stores, prodKeySearch } = this.props.queryData;
            if (stores && stores.length > 0) {
                stores[0].storeid === this.state.storeid
                    ? this.setState({ storelabel: stores[0].title })
                    : this.setState({ storelabel: this.state.storelabel });
            }
        }
    };

    showStock = (value) => {
        this.setState({
            showStockPage: value,
        });
    };

    hideStock = () => {
        this.setState({ showStockPage: false });
    };

    _createProductInformation = () => {
        const { errormsg } = this.props.crudData;
        const { productcode } = this.state;
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateInventoryByStore(this.getCreatePayload())
                );
            } else {
                this.props.dispatch(
                    queryInventoryByStore({
                        body: {
                            productcode,
                        },
                    })
                );
                this.props.dispatch(
                    createAddMapInventory(this.getCreatePayload())
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            productcodeerror: '',
            titleerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { title, storeid, productcode } = this.state;
        let valid = true;

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'StoreID is required.' });
            valid = false;
        }
        if (productcode.trim() === '') {
            this.setState({ productcodeerror: 'Product Code is required.' });
            valid = false;
        }
        if (title.trim() === '') {
            this.setState({ titleerror: 'Title is required.' });
            valid = false;
        }
        return valid;
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.clearAllFields();
    };

    clearAllFields() {
        this.setState({
            storeid: '',
            productcode: '',
            stopsell: 'N',
            stock: 'Y',
            avlintems: '',
            categorycode: '',
            status: 'Active',
            storelabel: '',
        });
    }

    getPayload = (obj) => {
        const payload = {
            body: obj,
        };
        return payload;
    };

    _renderProductByStore = (obj) => {
        const { storeid } = this.state;
        const getproduct = obj.filter((e) => e.storeid == storeid);
        if (getproduct.length > 0) {
            this.props.dispatch(
                updateInventoryByStore(this.getPayload(getproduct[0]))
            );
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg === 'Invalid Parameter') {
            return '';
        } else if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createProductInformation();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createProductInformation()}
                    >
                        Update
                    </button>
                )}
                {isEditMode && (
                    <Link
                        to={{
                            pathname: '/ui/inventory',
                            state: {
                                storeid:
                                    this.props.location.state.fields.storeid,
                                status: this.props.location.state.fields.status,
                                storelabel:
                                    this.props.location.state.fields.storelabel,
                                categorycodes:
                                    this.props.location.state.fields
                                        .categorycodes,
                                categorygrp:
                                    this.props.location.state.fields.categories,
                                stopsell:
                                    this.props.location.state.fields.stopsell,
                                stock: this.props.location.state.fields.stock,
                            },
                        }}
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                )}
                {!isEditMode && (
                    <Link
                        to="/ui/inventory"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                )}
            </React.Fragment>
        );
    };
    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _renderMainFormDetails = () => {
        const { storeiderror, productcodeerror, titleerror } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft required">
                            Store
                        </label>
                        {/* <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            /> */}
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">{storeiderror}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="productcode"
                            className="floatLeft required"
                        >
                            Product Code
                        </label>
                        <input
                            type="text"
                            value={this.state.productcode}
                            className="addoninput productcodemodal"
                            onClick={this._showProductModal1}
                        />
                        <div className="invalid-feedback">
                            {productcodeerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="categorycode" className="floatLeft">
                            Category Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="categorycode"
                            value={this.state.categorycode}
                            readOnly={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            readOnly={true}
                        />
                        <div className="invalid-feedback">{titleerror}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderGeneralInformation = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="stopsell" className="floatLeft">
                            Stop Sell
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={(value) =>
                                this.setState({ stopsell: value })
                            }
                            selectedOption={this.state.stopsell}
                            id="stopsell"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="stock" className="floatLeft">
                            Products In Stock
                        </label>
                        <SimpleSelect
                            objArray={{ Y: 'Yes', N: 'No' }}
                            callbackChangeFilter={(value) =>
                                this.setState({ stock: value })
                            }
                            selectedOption={this.state.stock}
                            id="stock"
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="avlintems" className="floatLeft">
                            Available Items
                        </label>
                        <input
                            type="text"
                            id="avlintems"
                            value={this.state.avlintems}
                            onChange={(e) =>
                                this.setState({ avlintems: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="qty" className="floatLeft">
                            Quantity
                        </label>
                        <input
                            type="text"
                            id="avlintems"
                            name="qty"
                            value={this.state.qty}
                            onChange={(e) =>
                                this.setState({ qty: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="invallocationid" className="floatLeft">
                            Inventory Allocation Id
                        </label>
                        <input
                            type="text"
                            id="invallocationid"
                            name="invallocationid"
                            value={this.state.invallocationid}
                            onChange={(e) =>
                                this.setState({
                                    invallocationid: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _showProductModal1 = (event) => {
        this.setState({
            showProdModal: true,
        });
        if (event) event.preventDefault();
    };
    _closeProductModal = (event) => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };
    _updateProdCode = (param, param1, param2) => {
        this.setState(
            { productcode: param, title: param1, categorycode: param2 },
            () => this._closeProductModal()
        );
    };
    _renderProductModal1 = () => {
        return (
            <Modal
                visible={this.state.showProdModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a
                                onClick={this._closeProductModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: '15em' }}>
                        <Autocomplete
                            updateTitle={this._updateProdCode}
                        ></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };

    _showStock = () => {
        this.setState({ showStockPage: true });
    };
    _hideStock = () => {
        this.setState({ showStockPage: false });
    };

    handleTrxDate = (val) => {
        this.setState({
            trxdate: val,
        });
    };

    _renderStockBtn = () => {
        return (
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    {/* <button
                        type="submit"
                        className="page-header-btn icon_btn display-inline"
                        onClick={(event) => {
                            if (event) event.preventDefault();
                            this._showStock();
                        }}
                    >
                        Add Stock
                    </button> */}
                    <Button
                label="Add Stock"
                icon="pi pi-plus"
                className="p-button-outlined mr-2"
                onClick={(event) => {
                    if (event) event.preventDefault();
                    this._showStock();
                }}
            />
                </div>
            </div>
        );
    };

    _renderFormArea = () => {
        return (
            <div className="grid px-4">
                    <div className="col-9">
                        <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                            {this._renderMainFormDetails()}
                        </div>
                        <div
                            className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </div>
                    </div>
                    <div className="col-3">
                        {this._isEditMode() === true ? (
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                                {this._renderStockBtn()}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
        );
    };

    render() {
        const { isSuccess, error } = this.props.crudData;
        const { storeid, productcode, storelabel, status } = this.state;
        if (this.state.showStockPage === true) {
            return (
                <React.Fragment>
                    <AddStock
                        updateStock={this._hideStock}
                        propDetails={{
                            productcode,
                            storeid,
                            storelabel,
                            status,
                            fields: this.props.location.state.fields,
                        }}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="form_height">
                        <div className="grid px-4">
                            <div className="col-9">
                                <h2 className="page-header">
                                    {this._isEditMode() === true
                                        ? 'Edit Inventory To Store'
                                        : 'Map Inventory To Store'}
                                </h2>
                            </div>
                        </div>
                        {this._renderErrorMessage()}
                                {this._renderFormArea()}
                            <div className="row btn-container form-button">
                                <div className="col-sm-12 col-lg-12">
                                    {this._renderMainButton()}
                                </div>
                            </div>
                        <ConfirmAlert
                            show={isSuccess}
                            handleClose={this.hideModal}
                            to={
                                this._isEditMode() === true
                                    ? {
                                          pathname: '/ui/inventory',
                                          state: {
                                              storeid:
                                                  this.props.location.state
                                                      .fields.storeid,
                                              status: this.props.location.state
                                                  .fields.status,
                                              storelabel:
                                                  this.props.location.state
                                                      .fields.storelabel,
                                              categorycodes:
                                                  this.props.location.state
                                                      .fields.categorycodes,
                                              categorygrp:
                                                  this.props.location.state
                                                      .fields.categories,
                                              stopsell:
                                                  this.props.location.state
                                                      .fields.stopsell,
                                              stock: this.props.location.state
                                                  .fields.stock,
                                          },
                                      }
                                    : { pathname: '/ui/inventory' }
                            }
                            children={
                                <div style={{ padding: '2em', color: 'green' }}>
                                    {this._isEditMode() === true
                                        ? 'Inventory Product Updated successfully!'
                                        : 'Inventory Product Mapped successfully!'}
                                </div>
                            }
                        />
                    </div>
                    {this.state.showProdModal && this._renderProductModal1()}
                </React.Fragment>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStoreInventory);
