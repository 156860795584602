import React from "react";
import { Chart } from "chart.js";
import currencyFormatter from "currency-formatter";
class HorizontalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: this.props.currency || ""
    }
    this.canvasRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.currency !== prevState.currency){
      this.setState({
        currency: prevProps.currency
      })
     } 
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets = this.props.data;
    this.myChart.update();
  }

  componentDidMount() {
    let currentThis = this;
    this.myChart = new Chart(this.canvasRef.current, {
      type: "horizontalBar",
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: "bottom",
          display: false
        },
        scales: {         
          xAxes: [{
            barThickness: 6,
            id: "1",
            position: 'bottom',
            ticks: {
              beginAtZero: true,
              userCallback: function (value, index, values) {
                return currencyFormatter.format(value, { code: currentThis.state.currency });
              }
            },
            display:true,
            scaleLabel: {
              display: true,
              labelString: this.props.data[1].label
            }
          },
         ]
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data
      }
    });
    
  }

  render() {
    return (
      <div className="chartCanvas">
        <canvas ref={this.canvasRef} id={this.props.id}/>
      </div>
    );
  }
}
export default HorizontalBarChart;