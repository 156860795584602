import React, { useState, useRef } from 'react';
import { FileUpload } from 'primereact/fileupload';

const getBase64 = (files, callback) => {
    var reader = new FileReader();

    for (var i = 0; i < files.length; i++) {
        const file = files[i];
        reader = new FileReader();
        reader.onload = (function (file) {
            return function (e) {
                callback(
                    file.name,
                    e.target.result.split(';')[1].split(',')[1],
                    file.type
                );
            };
        })(file);
        reader.readAsDataURL(file);
    }
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
};

const MediaFileUpload = (props) => {
    const { handleFileUpload } = props;
    const [errorSummary, setErrorSummary] = useState('');
    const [errorDetail, setErrorDetail] = useState('');

    const fileUploadRef = useRef(null);

    const uploadFileHandler = (e) => {
        setErrorSummary('');
        setErrorDetail('');
        const { files, options } = e;
        getBase64(files, uploadFile);
        options.clear();
    };

    const uploadFile = (filename, encodedStr, filetype) => {
        const allowedFileTypes = ['image/jpeg', 'image/png', 'text/css', 'text/html', 'text/plain']
        if (!allowedFileTypes.includes(filetype)) {
            setErrorSummary(
                `Selecte file type is not supported to upload.`
            );
            return
        }
        let data = {
            filename: filename,
            filetext: encodedStr,
            filetype
        };
        handleFileUpload(data)
    };

    const handleValidationFail = () => {
        const maxFileSize = fileUploadRef.current.props.maxFileSize;
        setErrorSummary(
            `File size should be less than ${maxFileSize / 1000000} MB`
        );
        setErrorDetail(
            `Selected file is too large. Please select a file smaller than ${
                maxFileSize / 1000000
            } MB.`
        );
        fileUploadRef.current.clear();
    };

    return (
        <div className="fileContainer">
            <i className="pi pi-cloud-upload" style={{ fontSize: '4em' }} />
            <p style={{ marginBottom: '10px' }}>
                Max file size: 5mb, accepted: jpg|gif|png|html|css|ftl
            </p>
            <FileUpload
                chooseLabel="Choose Files"
                ref={fileUploadRef}
                maxFileSize={5000000}
                accept="image/*, .css, .html, .ftl"
                mode="basic"
                name="files[]"
                auto
                multiple
                customUpload={true}
                uploadHandler={uploadFileHandler}
                onValidationFail={handleValidationFail}
            />
            <p style={{ color: '#ff5757', marginTop: '20px'}}>{errorSummary}</p>
            {/* <Message severity="error" text={errorSummary} closable={false} /> */}
            <p style={{ color: '#ff5757'}}>{errorDetail}</p>
        </div>
    );
};

export default MediaFileUpload;
