import React from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { queryMediaList } from '../actions/actions';

class ImagePick extends React.Component {
    constructor(props) {
        super(props);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateImagePiker = this.updateImagePiker.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            content_add: 'ic_addbtn_blue',
            width: 50,
            myItems: this._convertImgToArray(this.props.images) || [],
            background: '#fff',
            showImageList: false,
            imageSelected: '',
            id: '',
            folderPath: '/media/images',
            updateitemid: '',
            limit: this.props.limit ? this.props.limit : 4,
        };
        this.lastId = -1;
    }

    componentWillReceiveProps = (newProps) => {
        this.lastId = -1;
        this.setState({ myItems: this._convertImgToArray(newProps.images) });
    };

    folderOnClick = (folderpath) => {
        this.setState({ folderPath: folderpath }, () => {
            this.callQueryApiForFetch('FETCHLIST', folderpath);
        });
    };

    createFetchUrl = (folderPath) => {
        const requestBody = {
            body: {
                path: folderPath,
            },
        };
        return requestBody;
    };
    callFetch = () => {
        const { folderPath } = this.state;
        this.props.dispatch(queryMediaList(this.createFetchUrl(folderPath)));
    };
    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'FETCHLIST':
                const [path] = rest;
                newState = produce(this.state, (draft) => {
                    draft.folderPath = path;
                });
                this.setState({ ...newState }, () => {
                    this.callFetch();
                });
                break;
            default:
                break;
        }
    };

    _openImageList = (event, item) => {
        this.setState(
            { showImageList: true, updateitemid: item ? item.id : '' },
            () => this.callQueryApiForFetch('FETCHLIST', '/media/images')
        );

        if (event) event.preventDefault();
    };
    _closeImageList = (event) => {
        this.setState({ folderPath: '/media/images', showImageList: false });

        if (event) event.preventDefault();
    };
    _selectImage = (event) => {
        if (event) event.preventDefault();
        // this.props.setImage(event, this.state.imageSelected);
        this._closeImageList();
        this.setState({ content_add: 'ic_addbtn_blue' }, () => {
            this._closeImageList();
            this.updateImagePiker(event, this.state.imageSelected);
        });
        if (event) event.preventDefault();
    };
    // Render images list display
    _renderFolder = (file, index) => {
        return (
            <div
                key={`imagepick-rf-${index}`}
                className="col-xs-6 col-lg-2 noPadding noMargin folderStyle"
            >
                <div
                    className="card "
                    onClick={() => this.folderOnClick(file.path)}
                    style={{ padding: '8px' }}
                >
                    <div className="card-body">
                        <img
                            className="card-img-top folderPreview"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_media_folder.svg`}
                            alt="Card cap"
                        />
                        <div className="title_devide2" />
                        <p className="card-text text-center">
                            {file.medianame}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    _renderBreadCrumb = () => {
        const { folderPath } = this.state;
        let folders = folderPath.slice(1).split('/');

        return (
            <div className="breadcrumb">
                {folders.length !== 0 &&
                    folders.map((folder, i) => {
                        let link =
                            folderPath.substring(
                                0,
                                folderPath.lastIndexOf(folder)
                            ) + folder;
                        return (
                            <a
                                onClick={() => this.folderOnClick(link)}
                                key={`imgpick-bc-${i}`}
                            >
                                {folder}
                            </a>
                        );
                    })}
            </div>
        );
    };
    _renderImagePreview = (file, index) => {
        return (
            <div
                key={index}
                className="col-lg-2 col-sm-6 noPadding noMargin folderStyle"
            >
                <div
                    className={`card ${
                        this.state.id === file.id
                            ? `imageSelected`
                            : `imageNotSelected`
                    }`}
                    style={{ padding: '8px' }}
                >
                    <div
                        className="card-body"
                        onClick={() => {
                            let image = file.path;
                            this.setState({
                                imageSelected: image,
                                id: file.id,
                            });
                        }}
                        onDoubleClick={() => {
                            let image = file.path;
                            this.setState(
                                { imageSelected: image, id: file.id },
                                () => {
                                    this.updateImagePiker('', image);
                                    this._closeImageList();
                                }
                            );
                        }}
                    >
                        <img
                            className="card-img-top imgPreview"
                            src={`${localStorage.getItem('imagepath')}${
                                file.path
                            }`}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
                            }}
                            alt="Missing Image"
                        />
                        <div className="title_devide2" />
                        <p className="card-text text-center">
                            {file.medianame}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    // Render images list display
    _renderForm = (mediaList) => {
        return (
            <div className="row">
                {mediaList.length === undefined || mediaList.length === 0 ? (
                    <React.Fragment />
                ) : (
                    mediaList.map((file, index) =>
                        file.mediatype === 'FL'
                            ? this._renderFolder(file, index)
                            : this._renderImagePreview(file, index)
                    )
                )}
            </div>
        );
    };
    _renderImageModal = () => {
        const { mediaList } = this.props.queryData;
        return (
            <Modal
                visible={this.state.showImageList}
                width="80%"
                height="80%"
                effect="fadeInRight"
                onClickAway={this._closeImageList}
            >
                <div className="modalForm imageModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6" />
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a onClick={this._closeImageList}>X</a>
                        </div>
                    </div>
                    <div className="title-devide" />
                    {this._renderBreadCrumb()}
                    {this._renderForm(mediaList)}
                    <div className="row btn-container1">
                        <div className="col-lg-6 col-md-12 col-sm-12 " />
                        <div className="col-lg-6 col-md-12 col-sm-12 alignRight">
                            <button
                                type="submit"
                                onClick={this._selectImage}
                                className="btn btn-themes btn-rounded"
                            >
                                Select
                            </button>
                            <button
                                type="submit"
                                onClick={this._closeImageList}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
    handleFocus(event) {
        this.setState({ content_add: '' });
    }
    componentWillMount = () => {
        this.callQueryApiForFetch('FETCHLIST', '/media/images');
    };

    _convertImgToArray = (images) => {
        let newArray = [];

        for (const key of Object.keys(images)) {
            let imgval = typeof images[key] === 'undefined' ? '' : images[key];
            newArray.push({
                content: imgval,
                id: ++this.lastId,
            });
        }

        return newArray;
    };

    handleChange(event) {
        const usr_input = event.target.value;
        this.setState({ content_add: usr_input });
    }
    setValues = (imgArray) => {
        var len = imgArray.length;
        var fields = {};
        for (var i = 0; i < len; i++) {
            var item = imgArray[i];
            fields[`image${i + 1}`] = item['content'];
        }

        this.props.setImages(fields);
    };
    updateImagePiker(event, image) {
        var newArray = this.state.myItems;
        var currentcontent = image.trim();
        if (!currentcontent) {
            return;
        }
        const { updateitemid } = this.state;
        // var currentWidth = this.helperspan.offsetWidth;
        if (updateitemid !== '') {
            let obj = { content: currentcontent, id: ++this.lastId };
            this.setState(
                produce((draft) => {
                    let found = false;
                    draft.myItems.forEach((command) => {
                        if (command.id === updateitemid) {
                            Object.assign(command, {
                                content: currentcontent,
                                id: command.id,
                            });
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.myItems.push(obj);
                    }
                }),
                () => {
                    this.makeAddedList();
                    this.setValues(this.state.myItems);
                }
            );
        } else {
            newArray.push({
                content: currentcontent,
                id: ++this.lastId,
            });
            this.setState(
                {
                    myItems: newArray,
                    content_add: 'ic_addbtn_blue',
                },
                () => {
                    this.makeAddedList();
                    this.setValues(newArray);
                    // this.props.setColor(this.state.myItems);
                }
            );
        }
    }

    handleBlur(event) {
        this.setState({ content_add: 'ic_addbtn_blue' });
    }

    handleClick(idToRemove) {
        const newArray = this.state.myItems.map((listitem) => {
            if (listitem.id === idToRemove) {
                const replicatedItem = Object.assign({}, listitem);
                replicatedItem.content = '';
                return replicatedItem;
            }
            return listitem;
        });

        this.setState({ myItems: newArray });
        this.setValues(newArray);
    }

    makeAddedList() {
        const { disableFirstIcon } = this.props;
        const elements = this.state.myItems.map((listitem, index) => {
            const isFirstIconDisabled = disableFirstIcon && index === 0;
            const idx = listitem.id;
            return (
                <li
                    key={`imagepick-al-${index}`}
                    className={
                        isFirstIconDisabled
                            ? `col-2 imagePickerCard disabled`
                            : listitem.content === ''
                              ? `card imagePickerCard col-2`
                              : `col-2 imagePickerCard2`
                    }
                    style={{
                        backgroundColor: listitem.content,
                        listStyle: 'none',
                    }}
                >
                    <div>
                        <div className="card-body">
                            <img
                                alt="first icon"
                                className={
                                    isFirstIconDisabled
                                        ? 'imgPickPreview disabledPickPreview'
                                        : listitem.content === ''
                                          ? 'imgPickPreview'
                                          : 'imgPickPreview2'
                                }
                                onClick={
                                    listitem.content === '' &&
                                    !isFirstIconDisabled
                                        ? (event) => {
                                              this._openImageList(
                                                  event,
                                                  listitem
                                              );
                                          }
                                        : () => {}
                                }
                                src={
                                    listitem.content === '' ||
                                    isFirstIconDisabled
                                        ? `${process.env.PUBLIC_URL}/assets/icons/${this.state.content_add}.svg`
                                        : `${localStorage.getItem(
                                              'imagepath'
                                          )}${listitem.content}`
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.PUBLIC_URL}/assets/icons/default_image.png`;
                                }}
                            />
                            <div className="icon">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                    className="text"
                                    onClick={(event) => {
                                        this.handleClick(idx);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
        return elements;
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {this.makeAddedList()}
                    <div
                        className={`card imagePickerCard col-2`}
                        onClick={this._openImageList}
                        style={
                            this.state.myItems.length === this.state.limit
                                ? { display: 'none' }
                                : { display: 'block' }
                        }
                    >
                        <div className="card-body">
                            <img
                                alt="img"
                                className={`imgPickPreview`}
                                src={`${process.env.PUBLIC_URL}/assets/icons/${this.state.content_add}.svg`}
                            />
                        </div>
                    </div>
                </div>
                {this._renderImageModal()}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(ImagePick);
