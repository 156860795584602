import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
    searchGeneral,
    crudReset,
    storeSearchByFields,
    updateSaleReturn,
    createSaleReturn,
} from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert';
import { statusConfigOptions } from '../../helpers/Util';

const SalesReturnDetail = (props) => {
    const { queryData, record } = props;
    const { details } = record;
    const { isSuccess } = props.crudData;
    const { stores } = queryData;
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(true);
    //const [record, setRecord] = useState({});
    const [storeLabel, setStoreLabel] = useState('');
    const [storeid, setStoreId] = useState('');

    useEffect(() => {
        const { storeid } = record;
        if (storeid !== undefined && storeid !== '') {
            props.dispatch(storeSearchByFields(`storeid=${storeid}`));
        }
    }, [record.storeid]);

    useEffect(() => {
        if (stores.length > 0) {
            let s_label =
                (stores.find((s) => s.storeid == record.storeid) ?? {}).title ||
                '';
            setStoreLabel(s_label);
        }
    }, [stores]);
    const formik = useFormik({
        initialValues: {
            orderid: record?.orderid || '',
            storeid: record?.storeid || '',
            returndate: record?.returndate || '',
            remark: record?.remark || '',
            customerid: record?.customerid || '',
            customername: record?.customername || '',
            description: record?.description || '',
            trxno: record?.trxno || '',
            returnref: record?.returnref || '',
            invoiceno: record?.invoiceno || '',
            terminalid: record?.terminalid || '',
            totalqty: record?.totalqty || '',
            totaltaxamount: record?.totaltaxamount || 0,
            totalnetamount: record?.totalnetamount || 0,
            totalamount: record?.totalamount || 0,
            totaldiscountamount: record?.totaldiscountamount || '',
            status:
                record && record.status
                    ? statusConfigOptions.find(
                          (item) => item.code === record.status,
                      )
                    : statusConfigOptions.find(
                          (status) => status.code == 'New',
                      ),
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid,
                status: values.status ? values.status.code : '',
                details,
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const handleSubmit = (data) => {
        if (isEditMode) {
            props.dispatch(updateSaleReturn({ body: data }));
        } else {
            props.dispatch(createSaleReturn({ body: data }));
        }
    };

    const renderItemDetail = () => {
        return (
            <div className="p-fluid formgrid br-2 bg-white p-4 border-round-xl shadow-1 mt-3 mb-6">
                <div className="flex px-2">
                    <h4>Item Details</h4>
                </div>
                {details && details.length > 0 && (
                    <DataTable
                        value={details}
                        dataKey="idx"
                        responsiveLayout="scroll"
                        className="bg-transparent"
                    >
                        <Column
                            field="productcode"
                            header={<IconHeader title="Product Code" />}
                        />
                        <Column
                            field="description"
                            header={<IconHeader title="Product Description" />}
                        />
                        <Column
                            field="status"
                            header={<IconHeader title="Status" />}
                        />

                        <Column
                            field="qty"
                            header={<IconHeader title="Quantity" />}
                        />
                        <Column
                            field="unitprice"
                            header={<IconHeader title="Unit Price" />}
                        />
                        <Column
                            field="totaldiscountamount"
                            header={<IconHeader title="Discount Amount" />}
                        />
                        <Column
                            field="totalamount"
                            header={<IconHeader title="Return Amount" />}
                        />
                    </DataTable>
                )}
            </div>
        );
    };

    const renderFormArea = () => {
        const {
            storeid,
            customerid,
            customername,
            terminalid,
            totalqty,
            taxvaluetype,
            trxno,
            returnref,
            totaldiscountamount,
            returndate,
            totalnetamount,
            status,
            totaltaxamount,
            totalamount,
            remark,
            salespersonid,
            additionalfields
        } = record;
        

        const getTaxValue = taxvaluetype => {
            if (taxvaluetype === 'TX') {
                return 'Taxable'
            }
            else if (taxvaluetype === 'ZR') {
                return 'Zero-rated'
            }
            return ''
        }

        const getUser = (salespersonid, additionalfields) => {
            const { salesperson } = additionalfields
            return `(${salespersonid}) ${salesperson}`
        }
        return (
            <>
                <div className="bg-white pv-20 ph-20 br-2 bg-white py-4 border-round-xl shadow-1">
                    <div className="grid">
                        <RecordRow label="Store" value={storeid} />
                        <RecordRow
                            label="Customer"
                            value={`(${customerid}) ${customername}`}
                        />
                        <RecordRow label="Terminal" value={terminalid} />
                        <RecordRow label="Taxable" value={getTaxValue(taxvaluetype)} />
                        <RecordRow label="Trx No" value={trxno} />
                        <RecordRow label="Total Qty" value={totalqty} />
                        <RecordRow label="Return No" value={returnref} />
                        <RecordRow label="Total Discount Amount" value={totaldiscountamount} />
                        <RecordRow label="Return Date" value={returndate} />
                        <RecordRow label="Total Net Amount" value={totalnetamount || 0} />
                        <RecordRow label="Requestor" value={getUser(salespersonid, additionalfields)} />
                        <RecordRow label="Total Tax Amount" value={totaltaxamount || 0} />
                        <RecordRow label="Status" value={status} />
                        <RecordRow label="Total Amount" value={totalamount} />
                        <RecordRow label="Remark" value={remark || '-'} />
                    </div>
                </div>
            </>
        );
    };

    const renderMainButton = () => {
        if (isViewMode) {
            return (
                <Button
                    label="Close"
                    className="p-button-secondary"
                    onClick={props.handleClose}
                />
            );
        }
        return (
            <>
                <Button
                    label={isEditMode ? 'Update' : 'Save'}
                    className="p-button-lg p-button-success mr-3"
                    type="submit"
                />

                <Button
                    label="Cancel"
                    className="p-button-secondary p-button-lg"
                    onClick={props.history.goBack}
                    style={{ marginRight: '20px' }}
                    type="button"
                />
            </>
        );
    };

    const hideModal = (e) => {
        e.preventDefault();
        props.dispatch(crudReset());
    };

    const renderErrorMessage = () => {
        const { isSuccess, errormsg } = props.crudData;
        if (errormsg && !isSuccess) {
            return (
                <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ marginBottom: '0px' }}
                >
                    {errormsg ? 'Invalid Param' : ''}
                </div>
            );
        }
        return;
    };

    const IconHeader = ({ title }) => {
        return (
            <div>
                {title}
                {!isViewMode && (
                    <i
                        className="pi pi-pencil"
                        style={{ marginLeft: '0.5rem' }}
                    />
                )}
            </div>
        );
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2">
                    <div className="grid pl-4 ml-1">
                        <h2 className="page-header">
                            {isEditMode ? 'Edit Sales Return' : 'Sales Return'}
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col">{renderErrorMessage()}</div>
                    </div>
                    <div className="grid px-3">
                        <div className="col-12">
                            {renderFormArea()}
                            {renderItemDetail()}
                        </div>
                    </div>
                    <div
                        className="grid fixed w-100 bottom-0 surface-900 py-2 justify-content-center"
                        style={{ left: '3%' }}
                    >
                        {renderMainButton()}
                    </div>
                </div>
            </form>
            <ConfirmAlert
                show={isSuccess}
                handleClose={hideModal}
                to="/ui/sales-return"
                children={
                    <div style={{ padding: '2em', color: 'green' }}>
                        {isEditMode
                            ? 'Sale return updated successfully'
                            : 'Sale return created successfully'}
                    </div>
                }
            />
        </>
    );
};

const RecordRow = (props) => {
    const { label, value } = props;
    return (
        <div className="col-6 col-md-6 flex">
            <div className="row">
                <div className="col-6 col-md-6">
                    <label htmlFor="storeid" className="c-blue fw-500 f-16 mini_title">
                        {label}
                    </label>
                </div>
                <div className="col-6 col-md-6">
                    <label htmlFor="storeid">{value}</label>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
    };
};

export default connect(mapStateToProps)(SalesReturnDetail);
