import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Editor } from 'primereact/editor';
import JoditEditor from 'jodit-react';
import 'jodit/build/jodit.min.css';
import SimpleSelect from '../../../components/SimpleSelect';
import {
    updateArticleInfoApi,
    createArticleInfoApi,
    crudReset,
    getStoreById,
    contentChange,
    queryDefaultConfigByTitle,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import StatusComp from '../../../components/StatusComp';
import { produce } from 'immer';
import TenantCode from '../../../components/TenantCode';

class AddEmail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            storeid: '',
            desc: '',
            status: 'New',
            language: '',
            langlist: {},
            application: '',
            subject: '',
            fromemail: '',
            extrainfo: '',
            allowduplicate: '',
            messagetype: '',
            code: '',
            //emailbody: "",
            emailbody: '',
            formError: false,
            formErrorMsg: '',
            isEdit: false,
            isView: false,
            test: '',
            // error
            storeiderror: '',
            titleerror: '',
            fromemailerror: '',
            emailbodyerror: '',
            storelabel: '',
            config: {
                readonly: false,
                enableDragAndDropFileToEditor: true,
                uploader: {
                    url: 'https://xdsoft.net/jodit/connector/index.php?action=fileUpload',
                },
            },
        };
    }

    modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    formats = [
        'header',
        'font',
        'color',
        'align',
        'background',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
    ];

    handleEditorChange = (value) => {
        this.setState({ emailbody: value });
        this.props.dispatch(contentChange(value));
    };

    componentWillMount = () => {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        const requestBody = {
            body: {
                title: 'MESSAGE_TYPES',
                category: 'Config',
            },
        };
        this.props.dispatch(
            queryDefaultConfigByTitle(
                requestBody,
                'GET_MOBILE_MESSAGE_TYPE_BY_TITLE',
                'GET_MOBILE_MESSAGE_TYPE_BY_TITLE_SUCCESS',
                'GET_MOBILE_MESSAGE_TYPE_BY_TITLE_FAILURE'
            )
        );

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    storeid,
                    title,
                    fields,
                    status,
                    language,
                    articlegroup,
                } = rec;
                this.props.dispatch(getStoreById(storeid));
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        //draft.status = status ? status : [];
                        draft.language = language ? language : 'en';
                        draft.articlegroup = articlegroup ? articlegroup : '';
                        draft.application = fields.application
                            ? fields.application
                            : '';
                        draft.subject = fields.subject ? fields.subject : '';
                        draft.fromemail = fields.fromemail
                            ? fields.fromemail
                            : '';
                        draft.extrainfo = fields.extrainfo
                            ? fields.extrainfo
                            : '';
                        draft.allowduplicate = fields.allowduplicate
                            ? fields.allowduplicate
                            : '';
                        draft.emailbody = fields.emailbody
                            ? fields.emailbody
                            : '';
                        draft.messagetype = fields.messagetype
                            ? fields.messagetype
                            : '';
                        draft.code = fields.code ? fields.code : '';
                    })
                );
            }
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    getCreatePayload = () => {
        const {
            storeid,
            title,
            status,
            application,
            subject,
            fromemail,
            extrainfo,
            allowduplicate,
            emailbody,
            language,
            messagetype,
            code,
            articlegroup,
        } = this.state;
        let body = {
            storeid: storeid,
            title: title,
            language: language,
            articlegroup,
            publishmode: 'manual',
            articletype: 'Email Notification',
            status: status,
            fields: {
                application,
                subject,
                fromemail,
                extrainfo,
                allowduplicate,
                emailbody,
                messagetype,
                code,
            },
        };
        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createEmaillNotification = () => {
        if (this.validateForm()) {
            if (this.state.isEdit === true) {
                this.props.dispatch(
                    updateArticleInfoApi(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(
                    createArticleInfoApi(this.getCreatePayload())
                );
            }
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            titleerror: '',
            fromemailerror: '',
            emailbodyerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { title, emailbody, fromemail } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }
        if (emailbody.trim() === '') {
            this.setState({ emailbodyerror: 'Please enter email' });
            valid = false;
        }
        if (fromemail.trim() === '') {
            this.setState({ fromemailerror: 'Please enter from email' });
            valid = false;
        }
        return valid;
    };
    _isEditMode = () => {
        return this.state.isEdit;
    };
    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        const isViewMode = this.state.isView;

        if (isViewMode) {
            return (
                <>
                    <Link
                        to="/ui/email"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                </>
            );
        }

        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createEmaillNotification()}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => this._createEmaillNotification()}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/email"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };
    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    componentWillReceiveProps = () => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) {
                storeArray.storeid === this.state.storeid
                    ? this.setState({ storelabel: storeArray.title })
                    : this.setState({ storelabel: this.state.storelabel });
            }
        }
    };

    _renderStatusDetails = () => {
        return (
            <>
                <div className="row sub-title">Actions</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="language" className="floatLeft">
                            Language
                        </label>
                        <SimpleSelect
                            objArray={this.state.langlist}
                            callbackChangeFilter={(value) => {
                                this.setState({ language: value });
                            }}
                            selectedOption={this.state.language}
                            id="language"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="postal" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    _renderFormArea = () => {
        const { mobileMsgTypes } = this.props.queryData;
        let messagetypelist = Object.assign({}, mobileMsgTypes.pvalues, {
            task: 'Task',
        });
        const messagetypelabel =
            this.state.messagetype.charAt(0).toUpperCase() +
            this.state.messagetype.slice(1);
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-8 col-sm-12 noMargin noPadding">
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="articlegroup"
                                    className="floatLeft"
                                >
                                    Article Group
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="articlegroup"
                                    value={this.state.articlegroup}
                                    onChange={(event) => {
                                        this.setState({
                                            articlegroup: event.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="title"
                                    className="floatLeft required"
                                >
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={this.state.title}
                                    onChange={(event) => {
                                        this.setState({
                                            title: event.target.value,
                                        });
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {this.state.titleerror}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="application"
                                    className="floatLeft"
                                >
                                    Application
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="application"
                                    value={this.state.application}
                                    onChange={(event) => {
                                        this.setState({
                                            application: event.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label htmlFor="storeid" className="floatLeft ">
                                    Store
                                </label>
                                <TenantCode
                                    value={this.state.storelabel}
                                    setTenantCode={this._setTenant}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="messagetype"
                                    className="floatLeft"
                                >
                                    Message Type
                                </label>
                                <SimpleSelect
                                    objArray={messagetypelist}
                                    callbackChangeFilter={(value) =>
                                        this.setState({ messagetype: value })
                                    }
                                    selectedOption={this.state.messagetype}
                                    id="messagetype"
                                />
                            </div>

                            <div className="form-group col-lg-6 col-sm-12">
                                <label htmlFor="code" className="floatLeft">
                                    {messagetypelabel === '0'
                                        ? 'Code'
                                        : `${messagetypelabel} Code`}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="code"
                                    value={this.state.code}
                                    onChange={(event) => {
                                        this.setState({
                                            code: event.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="fromemail"
                                    className="floatLeft required"
                                >
                                    From Email
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fromemail"
                                    value={this.state.fromemail}
                                    onChange={(event) => {
                                        this.setState({
                                            fromemail: event.target.value,
                                        });
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {this.state.fromemailerror}
                                </div>
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label htmlFor="subject" className="floatLeft">
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subject"
                                    value={this.state.subject}
                                    onChange={(event) => {
                                        this.setState({
                                            subject: event.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="extrainfo"
                                    className="floatLeft"
                                >
                                    Extra Info
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="extrainfo"
                                    value={this.state.extrainfo}
                                    onChange={(event) => {
                                        this.setState({
                                            extrainfo: event.target.value,
                                        });
                                    }}
                                />
                            </div>

                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="allowduplicate"
                                    className="floatLeft"
                                >
                                    Allow Duplicate
                                </label>
                                <SimpleSelect
                                    objArray={{ Y: 'Yes', N: 'No' }}
                                    callbackChangeFilter={(value) =>
                                        this.setState({ allowduplicate: value })
                                    }
                                    selectedOption={this.state.allowduplicate}
                                    id="allowduplicate"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <form
                            className="splitFrmDivSec text-center"
                            style={{ backgroundColor: '#f5f6fa' }}
                        >
                            {this._renderStatusDetails()}
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12 noMargin noPadding">
                        <label
                            htmlFor="emailbody"
                            className="floatLeft required"
                        >
                            Email Body
                        </label>
                    </div>

                    <div className="form-group col-lg-12 col-sm-12">
                        <JoditEditor
                            value={this.state.emailbody}
                            config={this.state.config}
                            onChange={this.handleEditorChange}
                        />
                        
                        {/* <Editor 
                            //style={{height:'320px'}} 
                            value={this.state.emailbody} 
                            //onTextChange={(e) => setText(e.htmlValue)} 
                            onTextChange={(e) => this.setState({ emailbody: e.htmlValue})}
                        /> */}
 
                    </div>
                </div>
                <div className="invalid-feedback">
                    {this.state.emailbodyerror}
                </div>
            </>
        );
    };
    exportHtml = () => {
        this.editor.exportHtml((data) => {
            const { design, html } = data;
            this.setState({ emailbody: html });
            console.log('exportHtml', html);
        });
    };
    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };
    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode() === true
                                    ? 'Edit Email Notification'
                                    : 'Add Email Notification'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                {this._renderErrorMessage()}
                                {this._renderFormArea()}
                            </form>
                            <div className="row btn-container form-button">
                                <div className="col-12">
                                    {this._renderMainButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/email"
                        children={
                            <div className="alert alert-success">
                                {this._isEditMode() === true
                                    ? 'Email Notification updated successfully'
                                    : 'Email Notification created successfully'}
                            </div>
                        }
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddEmail);
