export const dateTypeForCoupon = [
  { name: "Select Date Type", id: "" },
  { name: "Date Created", id: "createdon" },
  { name: "Issue Date", id: "issuedate" },
  { name: "Expiry Date", id: "createdon" },
  { name: "Redemption Date", id: "redemptiondate" },
  { name: "Last Updated", id: "lastupdated" }
];
export const searchFieldForCoupon = [
  { name: "Select DataField", id: "" },
  { name: "Title", id: "title" },
  { name: "Coupon Number", id: "couponno" },
  { name: "Coupon Code", id: "couponcode" },
  { name: "Member Id", id: "memberid" },
  { name: "Rule Code", id: "rulecode" },
  { name: "Status", id: "status" }
];
export const operators = [
  { name: "Select Condition", id: "-1" },
  { name: "Equal", id: "eq" },
  { name: "Like", id: "like" }
];
export const dateTypeForMember = [
  { name: "Select Date Type", id: "" },
  { name: "Last Updated", id: "lastupdated" },
  { name: "Date Created", id: "createdon" }
];
export const searchFieldForMember = [
  { name: "Select DataField", id: "" },
  { name: "First Name", id: "firstname" },
  { name: "Last Name", id: "lastname" },
  { name: "Gender", id: "gender" },
  { name: "Email Id", id: "emailid" },
  { name: "Member Id", id: "memberid" },
  { name: "Status", id: "status" }
];
export const statusValues = [
  { name: "All", id: "" },
  { name: "New" , id: "New" },
  { name: "Active", id: "Active" },
  { name: "Deleted", id: "Deleted" }
];
