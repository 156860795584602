import React from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import CategoryTree from './../../../components/CategoryTree';
import {
    queryCategoryTree,
    queryStoreCategoryInfo,
    deleteStoreProductMap,
    resetErrorOnly,
} from './../../../actions/actions';
import SimpleTable from './../../../components/SimpleTable';
import 'react-tabs/style/react-tabs.css';

import StoreProductMappingUpdate from './StoreProductMappingUpdate';
import StatusComp from './../../../components/StatusComp';
import ConfirmAlert from '../../../components/ConfirmAlert';
import LinkButton from '../../../components/LinkButton';
import {
    canDelete,
    canView,
    canWrite,
    permissions,
} from '../../../helpers/Util';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';

class ProductToStoreMapping extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.group = 'DE_SAL_STORE_PRD_MAPPING';
        this.module = 'SALES';
        this.allPermissions = [];

        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,

            categorycodes: '',
            categories: '',
            storeid: '',
            status: 'Active',
            mapProducts: false,
            openDeleteprompt: false,
            delete_rec_id: '',
            formError: false,
            formErrorMsg: '',
            tempMappedProd: this.props.queryData.storeCategoryMapping || [],
            showSecForm: 'false',
            isView: false,
            isEdit: false,
            selectedProdId: '',
            prodData: {},
            storeproductdata: [],
            storelabel: '',
            storeiderror: '',
            categoryerror: '',
            showTable: false,
            tableCols: [
                {
                    dataField: 'additionalfields.title',
                    text: 'Title',
                    sort: true,
                },
                {
                    dataField: 'productcode',
                    text: 'Product Code',
                    sort: true,
                },
                {
                    dataField: 'categorycodes',
                    text: 'Category Code',
                    sort: true,
                },
                {
                    dataField: 'categorytitle',
                    text: 'Category Title',
                    sort: true,
                },
                {
                    dataField: 'baseprice',
                    text: 'Base Price',
                    sort: true,
                },
                {
                    dataField: 'taxcategory',
                    text: 'Tax Category',
                    sort: true,
                },
                {
                    dataField: 'addonitems',
                    text: 'Add on Items',
                    sort: true,
                },
                {
                    dataField: 'sortorder',
                    text: 'Sort Order',
                    sort: true,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'UD',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],
        };
    }

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <img
                        title="View"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                        onClick={() =>
                            this.showUpdateForm('true', row, true, false)
                        }
                    />
                )}
                {canWrite(this.allPermissions) && (
                    <img
                        title="Update"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                        onClick={() =>
                            this.showUpdateForm('true', row, false, true)
                        }
                    />
                )}
                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteStoreInfo(row)}
                    />
                )}
            </span>
        );
    };

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
    };

    componentWillMount = () => {
        let showFields = window && window.config.showAddonItems;
        const { tableCols } = this.state;
        if (showFields === false) {
            let filterfields = tableCols.filter(
                (e) => e.dataField !== 'addonitems'
            );
            this.setState({
                tableCols: filterfields,
            });
        }
        this.setState({
            storeid: localStorage.getItem('storeid'),
            storelabel: localStorage.getItem('storelabel'),
        });
        const fieldobj = this.props.location.state;
        if (typeof fieldobj !== 'undefined') {
            this.setState(
                {
                    storeid: fieldobj.storeid,
                    storelabel: fieldobj.storelabel,
                    categories: fieldobj.categories,
                    categorycode: fieldobj.categorycode,
                    //status: fieldobj.status,
                    status: 'Active',
                    categorycodes: fieldobj.categorycode,
                },
                () => {
                    console.log('validateAndSearch...');
                    this._validateAndSearch();
                }
            );
        } else {
            console.log('fieldObj is undefined');
        }

        this.allPermissions = permissions(this.module, this.group);
    };

    getStoreData = (obj1) => {
        if (typeof obj1 !== 'undefined') {
            var result = obj1.map((e) => {
                this.compareObject(e);
                if ('children' in e)
                    e.children = e.children.map((child) => {
                        this.compareObject(child);
                        if ('children' in child)
                            child.children.map((c) => {
                                this.compareObject(c);

                                if ('children' in c)
                                    c.children.map((m) => {
                                        this.compareObject(m);
                                    });
                            });
                        return child;
                    });
                return e;
            });
            return result;
        }
    };

    compareObject = (col) => {
        const { storeCategoryMapping } = this.props.queryData;
        var result = storeCategoryMapping.map((e) => {
            if (e.categorycodes == col.categorycode) {
                e['categorytitle'] = col.title;
            }
            return e;
        });
    };

    componentDidUpdate = (prevProps) => {
        const { categoryTree, storeCategoryMapping } = this.props.queryData;
        const { storeCategoryMapping: prevstoreCategoryMapping } =
            prevProps.queryData;
        if (storeCategoryMapping !== prevstoreCategoryMapping) {
            if (storeCategoryMapping.length > 0) {
                this.getStoreData(categoryTree.children);
                this.setState({
                    storeproductdata: storeCategoryMapping,
                });
            } else {
                this.setState({
                    storeproductdata: [],
                });
            }
        }
    };

    hideModal = () => {
        this.setState({ mapProducts: false });
    };

    componentWillReceiveProps = (prevProps) => {
        // this.setState({ treeData: newProps.queryData.categoryTree });
    };

    deleteStoreInfo = (rowData) => {
        this.setState({ message: 'Store Product deleted successfully!' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };

    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                deleteStoreProductMap(this.state.delete_rec_id)
            );
            this._searchProductsMapped();
        }
    };

    _searchProductsMapped = (event) => {
        const { categorycodes, storeid, status, pageSize, currPage } =
            this.state;
        this.props.dispatch(
            queryStoreCategoryInfo(
                {
                    body: {
                        categorycode: categorycodes,
                        storeid: storeid,
                        status,
                        pagesize: pageSize,
                        pageno: currPage,
                    },
                },
                categorycodes,
                storeid
            )
        );
    };

    rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === 'subcategories') {
                obj.children = obj[prop];
                delete obj[prop];
            }

            if (prop === 'title') {
                obj['subtitle'] = obj['categorycode'];
            }
        }

        return obj;
    };

    _mapProductsToStore = (event) => {
        if (event) event.preventDefault();
        this.setState({ mapProducts: true });
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeiderror: '',
            categoryerror: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { storeid, categorycodes } = this.state;
        let valid = true;
        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select store id' });
            valid = false;
        }
        // if (categorycodes.trim() === "") {
        //   this.setState({ categoryerror: "Please select category code" });
        //   valid = false;
        // }

        return valid;
    };

    _validateAndSearch = (event) => {
        if (event) event.preventDefault();
        if (this.validateForm()) {
            this.setState({ showTable: true });
            //reset the pagination props
            this.setState(
                { pageSize: this.pageSize, currPage: this.currPage },
                () => {
                    this._searchProductsMapped(event);
                }
            );
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };
    _clearCriteria = (event) => {
        if (event) event.preventDefault();
        this._clearErrorMessages();
        this.setState({ showTable: true, storeid: '', categorycodes: '' });
    };
    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 }, () => {
            localStorage.setItem('storeid', p1);
            localStorage.setItem('storelabel', p2);
        });
    };

    _renderAddNewBtn = () => {
        if (!canWrite(this.allPermissions)) {
            return;
        }

        const { storeid, storelabel, status, categories, categorycodes } =
            this.state;
        return (
            <LinkButton
                to={{
                    pathname: '/ui/add-map-store',
                    state: {
                        storeid: storeid,
                        storelabel: storelabel,
                        categories: categories,
                        categorycode: categorycodes,
                        status: 'Active',
                    },
                }}
                className="page-header-btn icon_btn display-inline addbutton"
                onClick={(event) => event.preventDefault()}
            >
                <img
                    title="edit"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                />
                {`Add Store Product`}
            </LinkButton>
        );
    };

    handleChangeStatus = (value) => {
        this.setState({ status: value });
    };

    _renderCriteria = () => {
        const { categoryTree } = this.props.queryData;
        const newArrayOfObj = [categoryTree].map(this.rename);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._onSelectStore}
                            checkStoreAccess={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Select Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.handleChangeStatus(value);
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-3 col-sm-12">
                        <label htmlFor="categorycodes" className="floatLeft">
                            Select Product Category
                        </label>

                        <CategoryTree
                            treeData={newArrayOfObj}
                            categoryTree={categoryTree}
                            setCategoryCode={(code, title) => {
                                this.setState({
                                    categorycodes: code,
                                    categories: title,
                                });
                            }}
                            value={this.state.categories}
                        />
                    </div>
                    <div className="form-group col-lg-1 col-sm-12 noPadding">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
                            onClick={(event) => this._validateAndSearch(event)}
                            className="searchBtn"
                        />
                    </div>
                    <div className="form-group col-lg-2 col-sm-12 noPadding">
                        {this._renderAddNewBtn()}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    _updateMapping = (event, row, col) => {
        //this.getCreatePayload(row, col, event.target.value);
        /*  this.props.dispatch(
      createItem("sales/v1/store/product/save", this.getCreatePayload())
    ); */
    };

    showUpdateForm = (value, row, isView, isEdit) => {
        this.props.queryData.storeproducts = [];
        if (row) {
            this.setState({ selectedProdId: row.id, prodData: row }, () => {
                if (row) {
                    this.setState({
                        showSecForm: value,
                        isView: isView,
                        isEdit: isEdit,
                    });
                }
            });
        } else {
            this._searchProductsMapped();
            this.setState({
                showSecForm: value,
                isView: isView,
                isEdit: isEdit,
            });
        }
    };

    _renderTable = () => {
        return (
            <>
                <SimpleTable
                    columns={this.state.tableCols}
                    rows={this.state.storeproductdata}
                    pageChangeCallback={(currPage) => {
                        this.setState(
                            {
                                currPage: currPage,
                            },
                            () => {
                                this._searchProductsMapped();
                            }
                        );
                    }}
                    callback={(pageSize) => {
                        this.setState(
                            produce(this.state, (draft) => {
                                if (draft.pageSize < pageSize) {
                                    draft.currPage = 1;
                                }
                                draft.pageSize = pageSize;
                            }),
                            () => {
                                this._searchProductsMapped();
                            }
                        );
                    }}
                    // withoutSearchBar={true}
                    withPageSize={this.state.pageSize}
                    withCurrPage={this.state.currPage}
                    withPageFeedback={true}
                />
            </>
        );
    };

    _renderMainForm = () => {
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                Store Products Management
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            <form className="splitFrmDiv">
                                {this._renderCriteria()}
                                <div
                                    style={{ height: '2em' }}
                                    className="title_devide"
                                ></div>
                                {this.state.showTable && this._renderTable()}
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();

        this.props.dispatch(resetErrorOnly());
    };

    render() {
        const { showSecForm, isView, isEdit } = this.state;
        const { errormsg: queryError, servererror: serverError } =
            this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }
        /* if (this.props.queryData.apiPending === true) {
      return <Loading />;
    } */
        return (
            <React.Fragment>
                {showSecForm === 'true' ? (
                    <StoreProductMappingUpdate
                        data={this.state.prodData}
                        storeid={this.state.storeid}
                        updateForm={this.showUpdateForm}
                        isView={isView}
                        isEdit={isEdit}
                    ></StoreProductMappingUpdate>
                ) : (
                    this._renderMainForm()
                )}
                <ConfirmAlert
                    show={this.state.openDeleteprompt}
                    isPrompt={true}
                    children={
                        <div className="deleteAlertDiv">
                            Are you sure you want to delete this store product?
                            <div style={{ margin: '2em' }}>
                                <button
                                    className="btn btn-themes btn-rounded btn-sec deletBtn"
                                    onClick={() => this.shdDeleteRec(false)}
                                >
                                    No
                                </button>
                                <button
                                    className="btn btn-themes  btn-rounded deletBtn"
                                    onClick={() => this.shdDeleteRec(true)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    }
                />
                <ConfirmAlert
                    show={errormsg}
                    handleClose={(event) =>
                        this.hideErrorAlertModal(event, refresh)
                    }
                    children={
                        <div
                            style={
                                errormsg
                                    ? {
                                          padding: '2em',
                                          color: 'red',
                                          fontWeight: '500',
                                      }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {errormsg ? `${errormsg}.` : ''}
                        </div>
                    }
                />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ProductToStoreMapping);
