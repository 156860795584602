import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import EmailEditor from "react-email-editor";
class Editor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  exportHtml = () => {
    this.editor.exportHtml(data => {
      const { design, html } = data;
      console.log("exportHtml", html);
    });
  };
  componentWillMount = () => {};

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <h1>Editor</h1>

        <div>
          <button onClick={this.exportHtml}>Export HTML</button>
        </div>

        <EmailEditor
          ref={editor => (this.editor = editor)}
          style={{
            padding: "10px",
            border: "2px solid #f6f6f6",
            margin: "5px",
            height: "60em"
          }}
        />
      </React.Fragment>
    );
  };
  // Dynamic Table ---starts

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9"></div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">{this._renderFormArea()}</form>
              <div className="row btn-container form-button"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return { queryData: state.queryData, crudData: state.crudData };
};

export default connect(mapStateToProps)(Editor);
