import React from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import Filter from '../../components/Filter';
import {
    citySearchByFields,
    storeGroupSearchByFields,
    storeSearchByFields,
} from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import StoreSelection from './StoreSelection';

class StoreSearchSingle extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');

        this.state = {
            showStoreSearchModal: false,
            selectedCountry: '',
            selectedCity: '',
            selectedStoreGroup: '',

            storeToSearch: '',
            selectedStores: '',
            showStoreResults: false,
            storeselectionerror: '',
        };
    }

    componentWillMount = () => {
        this.props.queryData.storeSearchStores = [];
        let queryParam = `status=Active&companyid=${this.companyId}`;
        this.props.dispatch(storeGroupSearchByFields(queryParam));
    };

    _openStoreSearchModal = (event) => {
        event.preventDefault();
        this.setState({
            showStoreSearchModal: true,
        });
    };

    _closeStoreSearchModal = (event) => {
        if (event) event.preventDefault();
        this.setState({
            showStoreSearchModal: false,
            showStoreResults: false,
            selectedCountry: '',
            selectedCity: '',
            selectedStoreGroup: '',
            storeToSearch: '',
            selectedStores: '',
            storeselectionerror: '',
        });
        this.props.queryData.storeSearchStores = [];
    };

    _searchStores = (event) => {
        this.props.dispatch(
            storeSearchByFields(
                `companyid=${this.companyId}&status=Active&groupid=${this.state.selectedStoreGroup}`,
                CONSTANTS.STORESEARCH_STORE_SUCCESS,
                CONSTANTS.STORESEARCH_STORE_FAILURE
            )
        );

        this.setState({ showStoreResults: true });
        if (event) event.preventDefault();
    };

    updateSelectedStore = (selected) => {
        this.setState({ selectedStores: selected });
        if (selected !== '') {
            this.setState({ storeselectionerror: '' });
        }
    };

    _updateSelectedStores = (event) => {
        if (event) event.preventDefault();
        if (this.state.selectedStores === '') {
            this.setState({ storeselectionerror: 'Select Store' });
            return;
        }
        this.props.onSelectStore(this.state.selectedStores);
        this._closeStoreSearchModal();
    };

    _selectedCountryOnChange = (selectedValue) => {
        if (selectedValue === '-1') {
            this.setState({
                selectedCountry: selectedValue,
            });
        } else {
            this.setState({
                selectedCountry: selectedValue,
            });
            this.props.dispatch(
                citySearchByFields(`countrycode=${selectedValue}&status=Active`)
            );
        }
    };

    _selectedCityOnChange = (selectedValue) => {
        if (selectedValue === '-1') {
            this.setState({
                // showStoreGrp: false,
                selectedCity: selectedValue,
                // showBtns: false
            });
        } else {
            this.setState({
                // showStoreGrp: true,
                selectedCity: selectedValue,
            });

            let queryParam = `status=Active&country=${this.state.selectedCountry}&city=${selectedValue}`;
            this.props.dispatch(storeGroupSearchByFields(queryParam));
        }
    };

    _storeGroupOnChange = (selectedValue) => {
        this.setState({
            selectedStoreGroup: selectedValue,
        });
    };

    _filterStoreGroups = (list) => {
        if (!list) {
            return [];
        }

        const mappedFilters = list.map((listItem, index) => {
            return {
                key: index,
                id: listItem['groupid'],
                name: listItem['title'],
            };
        });

        let uniquelist = mappedFilters.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);
        uniquelist.unshift({ id: '', name: 'Select' });
        return uniquelist;
    };

    _renderStoreSearchModal = () => {
        const { storeGroups } = this.props.queryData;
        const { storeSearchStores } = this.props.queryData;
        var filteredStores = storeSearchStores;

        //filter the stores only the user has access
        if (this.props.checkStoreAccess | true) {
            const store_access = localStorage.getItem('store_access');
            if (store_access) {
                const stores = store_access.split(',');
                filteredStores = storeSearchStores.filter((e) =>
                    stores.includes(e.storeid)
                );
            }
        }
        return (
            <Modal
                visible={this.state.showStoreSearchModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeStoreSearchModal()}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="flex">
                        <div className="row sub-title">Store Search</div>
                        <div className="ml-auto">
                            <a
                                onClick={this._closeStoreSearchModal}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-4 col-sm-12">
                            <label className="col-form-label alignLeft">
                                Store Group
                            </label>
                            <Filter
                                cssClass="form-control"
                                filterOptions={this._filterStoreGroups(
                                    storeGroups
                                )}
                                selectedOption={this.state.selectedStoreGroup}
                                className="dynamicDescInp"
                                callbackChangeFilter={(event) => {
                                    this._storeGroupOnChange(
                                        event.target.value
                                    );
                                }}
                                id="storeGroup"
                            />
                        </div>

                        <div
                            className="col-lg-4 col-sm-12 btn-container1"
                            style={{ marginTop: '1em' }}
                        >
                            <button
                                type="submit"
                                onClick={this._searchStores}
                                className="btn btn-themes btn-rounded"
                            >
                                Search
                            </button>

                            <button
                                type="submit"
                                onClick={this._closeStoreSearchModal}
                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>

                    {this.state.showStoreResults && (
                        <div>
                            <div className="col-lg-12 col-sm-12">
                                <div
                                    className="row sub-title"
                                    style={{ marginLeft: '0px' }}
                                >
                                    Stores
                                </div>
                                <StoreSelection
                                    stores={filteredStores}
                                    updateSelectedStore={
                                        this.updateSelectedStore
                                    }
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                />
                                <div className="invalid-feedback">
                                    {this.state.storeselectionerror}
                                </div>
                            </div>

                            <div className="row btn-container1">
                                <div className="col-lg-12 col-md-12 col-sm-12 alignRight">
                                    <button
                                        type="submit"
                                        onClick={this._updateSelectedStores}
                                        className="btn btn-themes btn-rounded"
                                    >
                                        Save
                                    </button>

                                    <button
                                        type="submit"
                                        onClick={this._closeStoreSearchModal}
                                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                                    >
                                        Discard
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    };

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selectedStores: [...this.state.selectedStores, row.storeid],
            }));
        } else {
            this.setState(() => ({
                selectedStores: this.state.selectedStores.filter(
                    (x) => x !== row.storeid
                ),
            }));
        }
    };

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map((r) => r.storeid);
        if (isSelect) {
            this.setState(() => ({
                selectedStores: ids,
            }));
        } else {
            this.setState(() => ({
                selectedStores: [],
            }));
        }
    };

    render() {
        return (
            <>
                {this.state.showStoreSearchModal &&
                    this._renderStoreSearchModal()}
                <div className="row">
                    <div className="col-lg-12 noPadding noMargin">
                        <InputText
                            className="treemodal w-100"
                            value={this.props.value || ''}
                            onClick={this._openStoreSearchModal}
                            readOnly
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(StoreSearchSingle);
