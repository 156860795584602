import React from "react";
import { connect } from "react-redux";
import LinkButton from "../../../components/LinkButton";
import { searchFields } from "./tableCols";
import {
  crudReset,
  searchGeneral,
  resetAction
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleSearcher from "../../../components/SimpleSearcher";
import SimpleTable from "../../../components/SimpleTable";
import { toDate } from "../../../helpers/Util";
import Modal from "react-awesome-modal";
import StatusOptionComp from "../../../components/StatusOptionComp";
import DatePicker from "react-date-picker";
import TenantCode from "../../../components/TenantCode";

class DisplayOrderShip extends React.PureComponent {
  statusShip = [
    { name: "Select", id: "" },
    { name: "Shipping", id: "Shipping" },
    { name: "Shipped", id: "Shipped" },
    { name: "Received", id: "Received" },
    { name: "Delivered", id: "Delivered" },
    { name: "Completed", id: "Completed" },
    { name: "Cancel", id: "Cancel" }
  ];
  constructor(props) {
    super(props);
    this.state = {
      showSearchDiv: false,
      pages: 10,
      currPage: 0,
      status: "Active",
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "shippingstatus",
      search_condi: "like",
      search_value: "",
      datefrom: new Date(),
      datetill: new Date(),
      shipstatus: "",
      templatetype: "Shipping",
      memberid: "",
      tableCols: [
        {
          dataField: "orderid",
          text: "Order Id",
          sort: true
        },
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
            dataField: "items",
            text: "Product Name",
            formatter: this.itemFormatter,
            sort: true
        },
        // {
        //     dataField: "items",
        //     text: "Images",
        //     formatter: this.ImgFormatter,
        //     sort: true
        // },
        {
            dataField: "items",
            text: "Shipping Qty",
            formatter: this.showShippingQty,
            sort: true
        },      
        {
          dataField: "ordershippedon",
          text: "Shipped Date",
          formatter: this.showShipDate,
          sort: true
        },
        {
          dataField: "orderdeliveredon",
          text: "Delivered Date",
          formatter: this.showDeliverDate,
          sort: true
        },
        {
          dataField: "cancelledon",
          text: "Cancel Date",
          formatter: this.showCancelDate
        },
        {
          dataField: "shippingstatus",
          text: "Shipping Status",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }


  // ImgFormatter = (cell, row, rowIndex, formatExtraData) => {
  //     if(JSON.stringify(row) !== "{}" && row.items !== null){
  //       if (row.items.length > 0) {
  //         return row.items.map(e =>
  //           <div>
  //             <div className="row">
  //                 <div className="col-sm-12 col-lg-12 noPadding noMargin">
  //                   <img
  //                   className="img-responsive w-100"
  //                   src={`${localStorage.getItem("imagepath")}` + e["images"]}
  //                   alt="Card image cap" width="10%" />
  //                 </div>
  //             </div>
  //           </div>
  //         )
  //       }
  //       else{
  //         return ""
  //       }
  //     }  
  // }  

  showShipDate =  (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}"){
      return (
        <div>
          {row.shippingstatus == "Shipped" || row.shippingstatus == "Delivered" || row.shippingstatus== "Completed"?
                 row.ordershippedon : ""
          }
        </div>
      )
    }
  }

  showDeliverDate =  (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}"){
      return (
        <div>
          {row.shippingstatus==="Delivered" || row.shippingstatus==="Completed" ? row.orderdeliveredon : ""}
        </div>
      )
    }
  }

  showCancelDate =  (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}"){
      return (
        <div>
          {row.shippingstatus=="Cancel" ? row.cancelledon : ""}
        </div>
      )
    }
  }

  itemFormatter = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
        return row.items.map(e =>
          <div>
            <div className="row-box">
              <div className="flex-row">
                <div className="col-wrapper">
                   {e.productcode + "-" + e.description}
                   <div style={{ color: "#6d6d6d", fontSize: "12px" }}>{"[" + e.itemsize + "/" + e.itemcolor + "]"}</div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      else{
        return ""
      }
    }
  }

  showShippingQty = (cell, row, rowIndex, formatExtraData) => {
    if(JSON.stringify(row) !== "{}" && row.items !== null){
      if (row.items.length > 0) {
          return row.items.map(e=>
            <div className="row-box">
             <div className="flex-row">
              <div className="col-wrapper">
                 {e.shippingqty}
              </div>
             </div>
            </div>
          )
      }
      else{
        return
      }
    }    
  }
  
  componentWillMount = () => {
    this.callQueryApiForFetch("ONLOAD");
  }

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const permissions = localStorage.getItem("permission");
    let rowlist = Object.assign({},row,{items: row.items.map(e=> ({...e,currency:row.currency  }))});
    if (row.status !== "Deleted" && permissions.split(",").includes("W")) {
      return (
        <span>
          <Link
          to={{
            pathname: "/ui/order-fulfillment",
            state: {
              item: row,
              isEdit: false,
              ordership: true,
              orderreturn: false
            }
          }}
        >
            <img
              title="Order Fulfillment"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/courier.png`}
            />
          </Link> 
          <Link
            to={{
              pathname: "/ui/orders-fulfillment/shipping/addNew",
              state: {
                item: rowlist,
                isEdit: true
              }
            }}
          >
            <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link>      
        </span>
      );
    }
  };

 
  // API Calls
  createFetchUrl = () => {
    const { search_field, search_condi, search_value } = this.state;

    const requestBody = {
      body: {
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value
      }
    };
    return requestBody;
  };

  createFetchByFields  = () =>  {
    const { storeid, memberid, orderid, datefrom, datetill, shipstatus, templatetype } = this.state;
      const requestBody = {
        body: {
          datefrom: toDate(datefrom),
          datetill: toDate(datetill),
          storeid,
          orderid,
          memberid,
          status: shipstatus,
          templatetype
        }
      };
      return requestBody;   
  }


  callFetchById = () => {
    this.props.dispatch(
      searchGeneral(
        `ops/v1/task/shipping/search`,
        this.createFetchUrl(),
        "SEARCH_SHIPPINGTASK_SUCCESS",
        "SEARCH_SHIPPINGTASK_FAILURE"
      )
    );
  }

  callFetch = fetchType => {
    if(fetchType === "SEARCH"){
        this.props.dispatch(
          searchGeneral(
            "ops/v1/task/shipping/search/fields",
            this.createFetchByFields(fetchType),
            "SEARCH_SHIPPINGTASK_SUCCESS",
            "SEARCH_SHIPPINGTASK_FAILURE"
          )
        );
      }
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetchById("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
    }
  };
 searchQuery  = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("ONLOAD");
      }
    );
  };


  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };

  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };

  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };

  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="70%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Shippment Search</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {this._renderSearchForm()}
        </div>
      </Modal>
    );
  };

  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  hanldedatefrom = value => {
    this.setState({
      datefrom: value
    });
  };

  hanldedatetill = value => {
    this.setState({
      datetill: value
    });
  };

  _renderSearchForm = () => {
    const { deliverytypeList } = this.props.queryData;
    let dlist = Object.assign({}, ...deliverytypeList.map(e=>(e.additionalfields)));
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="templatetype" className="floatLeft">
              Template Type
            </label>
            <input
              type="text"
              className="form-control"
              id="templatetype"
              value={this.state.templatetype}
              readOnly={true}
            />
          </div>              
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="datefrom" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.hanldedatefrom}
              value={this.state.datefrom}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="datetill" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.hanldedatetill}
              value={this.state.datetill}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="orderid" className="floatLeft">
              Order Id
            </label>
            <input
              type="text"
              className="form-control"
              id="orderid"
              value={this.state.orderid}
              onChange={event => this.setState({ orderid: event.target.value })}
            />
          </div>     
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="memberid" className="floatLeft">
              Member Id
            </label>
            <input
              type="text"
              className="form-control"
              id="memberid"
              value={this.state.memberid}
              onChange={event => this.setState({ memberid: event.target.value })}
            />
          </div>  
          </div>  
          <div className="row">      
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Shipping Status
            </label>
            <StatusOptionComp
              statusoption={this.statusShip}
              selectedOption={this.state.shipstatus}
              callbackChangeFilter={value => {
                this.setState({ shipstatus: value });
              }}
            />
          </div>
        </div>

        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                  this.callQueryApiForFetch("SEARCH");
                  this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {
      shippingTaskList,
      errormsg: queryError,
      languageConfig,
      servererror: serverError
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }
    /*  if (!articleFlowData && !errormsg) {
      return <Loading />;
    } */
    if (this.props.queryData.apiPending === true) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        {this.state.showSearchDiv && this._renderSearchModal()}
        <div className="form_height">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <h2 className="page-header">
                Order Shipping  
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Order Shipping</h3>
                  </div>
                  <div className="col-lg-4 col-sm-12 noPadding noMargin">
                     <SimpleSearcher
                        filterOptions={searchFields}
                        setAndSearch={this.searchQuery}
                        ship_field={true}
                        search_condi={this.state.search_condi}
                        search_value={this.state.search_value}
                        search_field={this.state.search_field}
                        //searchValues={statusValues}
                      ></SimpleSearcher>
                  </div>
                  <div className="col-lg-2  col-md-6 col-sm-12 alignRight noPadding noMargin">
                     {this._renderSearchBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>

                <React.Fragment>
                  <SimpleTable
                    columns={this.state.tableCols}
                    rows={shippingTaskList}
                    callback={() => {
                      this.callQueryApiForFetch("ONLOAD");
                    }}
                  />
                </React.Fragment>
              </form>
            </div>
          </div>

      
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayOrderShip);
