import React, { Component } from "react";

class DynamicProductTable extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  updateProducts = (value, name, field, rows) =>{
    this.setState({
        [name]: value
    },()=>{
        this.props.handleProductInputChange(value, field, rows);
    })
  }

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => {

    // console.log('rendering tableBody::', this.props.rows)

    var rowleng = this.props.rows ? this.props.rows.length : 0;

    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderRowData = (column, row, col, index, rowId, rowleng) => {

    // console.log('renderRowData', column, row, col, index, rowId)
    
    if (column.addRow) {
      return (
        column.dataFieldId == "productcode" ?
        <td key={`tableview-td-${rowId}-${index}`} width="40%">
            <input
              type="text"
              defaultValue={row["productcode"]}
              className="addoninput productcodemodal"
              readOnly={true}
              name={"productcode_"+rowId}
              onClick={event =>
                this.props.showDynamicModal(row)
                //this.updateProducts(event.target.value,"productcode_" + rowId, column.dataFieldId,row)
              }
              value={row["productcode"]}
            />
        </td> : column.dataFieldId == 'title' ? 
        <td key={`tableview-td-${rowId}-${index}`} width="50%">
          <input
            type="text"
            defaultValue={row[column.dataFieldId]}
            readOnly={true}
            placeholder={
              column.dataFieldId.charAt(0).toUpperCase() +
              column.dataFieldId.slice(1)
            }
            onChange={event =>
              this.props.handleProductInputChange(event, column.dataFieldId, row)
            }
            value={row[column.dataFieldId]}
          />
        </td> :
        <td key={`tableview-td-${rowId}-${index}`} width="50%">
        <input
          type="text"
          defaultValue={row[column.dataFieldId]}
          placeholder={
            column.dataFieldId.charAt(0).toUpperCase() +
            column.dataFieldId.slice(1)
          }
          onChange={event =>
            this.props.handleProductInputChange(event, column.dataFieldId, row)
          }
        />
      </td>
      );
    }
    if (col === "AD") {
      return (
        <td width="20%">
          <div>
            <td key={`tableview-td-${rowId}-${index}`}>
              <img
                className="addBtn1"
                onClick={this.props.addRow}
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
              />

              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                onClick={() => this.props.deleteRow(row)}
                className="addBtn2"
              />
            </td>
          </div>
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <React.Fragment>
        <div className="dynamicTableImage">
          <table>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicProductTable;
