import React from "react";
import LinkButton from "../../../components/LinkButton";
import SimpleTable from "../../../components/SimpleTable";

import { produce } from "immer";
import {
  searchGeneral,
  deleteUserProfile,
  crudReset
} from "../../../actions/actions";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import Modal from "react-awesome-modal";
import ExportButton from "../../../components/TableExportButton";
import { formatDate } from "../../../helpers/Util";
const dateType = [
  { name: "Select Date Type", id: "" },
  { name: "Last Updated", id: "lastupdated" },
  { name: "Date Created", id: "createdon" }
];
const searchField = [
  { name: "Select DataField", id: "" },
  { name: "First Name", id: "firstname" },
  { name: "Last Name", id: "lastname" },
  { name: "Gender", id: "gender" },
  { name: "Email Id", id: "emailid" },
  { name: "Status", id: "status" }
];
const operators = [
  { name: "Select Condition", id: "-1" },
  { name: "Equal", id: "eq" },
  { name: "Like", id: "like" }
];
const rows = [
  {
    memberid: "M1324",
    name: "Snow",
    company: "XXX",
    city: "",
    question: "Rate your shopping experience?",
    topic: "",
    answer: "Yes"
  },
  {
    memberid: "M1334",
    name: "Snow1",
    company: "XXX",
    city: "",
    question: "Would you like to recommend to your friends?",
    topic: "",
    answer: "Yes"
  }
];
class SurveyReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pages: 10,
      currPage: 0,
      search_field: "status",
      search_condi: "eq",
      search_value: "New",
      date_from: this.toDate("12-07-2019"),
      date_till: this.toDate("12-10-2019"),
      date_type: "createdon",
      showSearchDiv: false,
      tableCols: [
        {
          dataField: "memberid",
          text: "Member ID"
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "company",
          text: "Company",
          sort: true
        },
        {
          dataField: "city",
          text: "City",
          sort: true
        },
        {
          dataField: "question",
          text: "Question",
          sort: true
        },
        {
          dataField: "topic",
          text: "Topic",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }
  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        <Link
          to={{
            pathname: "/ui/survey-webform/add-new",
            state: { item: row, isEdit: true }
          }}
        >
          <img
            title="Edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
          />
        </Link>
        <img
          title="Delete"
          className="tableImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
          onClick={() => this.deleteUser(row)}
        />
      </span>
    );
  };
  
  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  // API Calls
  createFetchUrl = (
    fetchType,
    pages,
    currPage,
    language,
    status,
    articleType
  ) => {
    const {
      search_field,
      search_condi,
      search_value,
      date_from,
      date_till,
      date_type
    } = this.state;
    const requestBody = {
      body: {
        /* pg_sz: pages,
        c_p: currPage */
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value,
        date_from: formatDate(date_from),
        date_till: formatDate(date_till),
        date_type: date_type
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    const { pages, currPage, language, status } = this.state;

    this.props.dispatch(
      searchGeneral(
        "crm/v1/member/search",
        this.createFetchUrl(pages, currPage),
        "SEARCH_MEMBER_PROFILE_SUCCESS",
        "SEARCH_MEMBER_PROFILE_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        const [pgNumber] = rest;

        newState = produce(this.state, draft => {
          draft.currPage = pgNumber - 1;
        });
        this.setState({ ...newState }, () => {
          this.callFetch("PAGECLICK");
        });

        break;
    }
  };
  searchQuery = () => {
    /*  if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("SEARCH");
      }
    ); */
  };
  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage }, () =>
      this.callQueryApiForFetch("ONLOAD")
    );
  }

  pageNumberOnClick = pgNumber => {
    this.callQueryApiForFetch("PAGECLICK", pgNumber);
  };

  _renderAddNewBtn = () => {
    return (
      <LinkButton
        to="/ui/survey-webform/add-new"
        className="page-header-btn icon_btn display-inline "
        onClick={event => event.preventDefault()}
      >
        <img
          title="edit"
          className="tableImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
        />
        {`Add New `}
      </LinkButton>
    );
  };
  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteUserProfile(this.state.delete_rec_id));
    }
  };
  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  deleteUser = rowData => {
    this.setState({ message: "Member profile deleted successfully" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };
  handlefromdateChange = value => {
    this.setState({
      date_from: value
    });
  };
  handletodateChange = value => {
    this.setState({
      date_till: value
    });
  };
  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };
  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };
  _renderSearchByDate = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.handlefromdateChange}
              value={this.state.date_from}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="Transaction Date From" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.handletodateChange}
              value={this.state.date_till}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="date_type" className="floatLeft">
              Date Type
            </label>
            <div className="row">
              <select
                name="date_type"
                className="simpleSearchSelect2"
                value={this.state.date_type}
                onChange={event =>
                  this.setState({ date_type: event.target.value })
                }
              >
                {dateType.length != 0 &&
                  dateType.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_field" className="floatLeft">
              Search By
            </label>
            <div className="row">
              <select
                name="search_field"
                className="simpleSearchSelect2"
                value={this.state.search_field}
                onChange={event =>
                  this.setState({ search_field: event.target.value })
                }
              >
                {searchField.length != 0 &&
                  searchField.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_condi" className="floatLeft">
              Search Field
            </label>
            <div className="row">
              <select
                name="search_condi"
                className="simpleSearchSelect2"
                value={this.state.search_condi}
                onChange={event =>
                  this.setState({ search_condi: event.target.value })
                }
              >
                {operators.length != 0 &&
                  operators.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_value" className="floatLeft">
              Search Value
            </label>
            <input
              type="text"
              className="form-control"
              id="search_value"
              value={this.state.search_value}
              onChange={event => {
                this.setState({ search_value: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("SEARCH");
                this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="80%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm searchModalForm">
          <div className="row">
            <div className="col-xs-12 col-md-12 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          {this._renderSearchByDate()}
        </div>
      </Modal>
    );
  };
  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };
  render() {
    const { userProfileList, errormsg: queryError } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    if (queryError) {
      errormsg = queryError;
    }
    if (crudError) {
      errormsg = crudError;
    }
    if (!userProfileList && !errormsg) {
      return <Loading />;
    }

    if (
      !userProfileList ||
      this.props.queryData.apiPending ||
      this.props.crudData.crudApiPending
    ) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        {this._renderSearchModal()}
        <div className="form_height">
          <div className="row">
            <div className="col-md-6">
              <h2 className="page-header">Survey Report</h2>
            </div>
            <div className="col-lg-6 text-right noPadding noMargin">
              <ExportButton />
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-8 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Survey Report</h3>
                  </div>
                  <div className="col-lg-2 col-sm-12"></div>
                  <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
                    {this._renderAddNewBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                <SimpleTable columns={this.state.tableCols} rows={rows} />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(SurveyReport);
