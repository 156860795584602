import React from "react";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import { connect } from "react-redux";
import { toDate, renderTotalRec, renderTotalAmt, getByMonth } from "../../helpers/Util";
import { 
  querySalesSummaryInfoByMonth,
  queryActiveMembers,
  queryMemberSalesSummary} from "../../actions/actions";
import ChartOptions from "../../components/ChartOptions";
import ChartTable from "../../components/ChartTable";

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showTableId: null,
      showTable: false,
      salesfromdate: new Date(),
      salestodate: new Date(),
      analysisfromdate: new Date("Jan 1, 2019 0:00:00"),
      analysistilldate: new Date(),
      storeid: "1010",
      storelabel: "",
      status: "close",
      totalsales: "",
      totaltrxn: "",
      activemembers: "",
      ismember: "Y",
      salebymonth: "",
      salebymember: ""
    };
  }

  componentDidMount() {
    // this.props.dispatch(
    //   searchGeneral(
    //     "/sales/v1/store/search/fields",
    //     {
    //       body: {
    //         groupid: localStorage.getItem("storegroup_access")
    //       }
    //     },
    //     "SEARCH_CITY_BY_COUNTRY_SUCCESS",
    //     "SEARCH_CITY_BY_COUNTRY_FAILURE"
    //   )
    // );
    this.callFetch();
  }

  callFetch = () => {
    this.props.dispatch(querySalesSummaryInfoByMonth(this.createFetchUrl("month")));
    this.props.dispatch(queryActiveMembers(this.createFetchMember("Active")));
    this.props.dispatch(queryMemberSalesSummary(this.createMemberSale()));
  }

  createFetchUrl = (grp) => {
    const { storeid, analysisfromdate, analysistilldate, status} = this.state;
    const requestBody = {
      body: {
        storeid: storeid,
        date_from: toDate(analysisfromdate),
        date_till: toDate(analysistilldate),
        groupby: grp,
        status: status
      }
    };
    return requestBody;
  }
  
  createFetchMember=(status)=>{
    const { analysisfromdate, analysistilldate, ismember} = this.state;
    const requestBody = {
      body: {
        date_from: toDate(analysisfromdate),
        date_till: toDate(analysistilldate),
        date_type: "createdon",
        status: status,
        member: ismember,
        groupby: "month"
      }
    };
    return requestBody;
  }   

  createMemberSale=()=>{
    const { analysisfromdate, analysistilldate, status} = this.state;
    const requestBody = {
      body: {
        date_from: toDate(analysisfromdate),
        date_till: toDate(analysistilldate),
        status: status
      }
    };
    return requestBody;
  }   


  componentDidUpdate = (prevProps) => {
    const { saleSummaryByMonth, activeMemberDevice, memberSaleSummary, dataByCountry }= this.props.queryData;
    if (dataByCountry.length > 0) {
      if (dataByCountry.length !== 4) {
        let store = dataByCountry[0].storeid;
        this.setState({ storeid: store })
      }
    }
    const { saleSummaryByMonth: prevsaleSummaryByMonth, memberSaleSummary: prevmemberSaleSummary } = prevProps.queryData;
    if (prevsaleSummaryByMonth.length !== saleSummaryByMonth.length || prevmemberSaleSummary.length !== memberSaleSummary.length) {
      if (saleSummaryByMonth.length > 0 ||  memberSaleSummary.length > 0) {
        let totalsale = renderTotalAmt(saleSummaryByMonth);
        let totaltrxn = renderTotalRec(saleSummaryByMonth);
        let sorted = memberSaleSummary.sort(this.compare);
        let topmembersale = sorted.slice(0, 10);
        let activemembers = renderTotalRec(activeMemberDevice);
        this.setState({
          totalsales: totalsale === 0 ? 0 : totalsale,
          totaltrxn: totaltrxn === 0 ? 0 : totaltrxn,
          activemembers: activemembers,
          salebymonth: saleSummaryByMonth,
          salebymember: topmembersale
        })
      }
    }
  }
 
  
  handleTable = (e) => {
    e.preventDefault();
    this.setState({
      showTableId: e.target.id,
      showTable: true
    });
  }

  handleChart = (e) => {
    e.preventDefault();
    this.setState({
      showTable: false
    })
  }
  
  renderSaleSummaryMonth = () =>{
    const  {salebymonth} = this.state;
    if (salebymonth.length > 0) {
      const obj = getByMonth(salebymonth);
      const labeldata = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const dataset1 = obj.map(e => ({
        x: e.month,
        y: e.totalrec
      }));
      const dataset2 = obj.map(e => ({
        x: e.month,
        y: e.totalamount
      }));
      const objdata = [{
        label: "totalrecords",
        data: dataset1,
        backgroundColor: "#0549b5",
        borderColor: "#0549b5",
        borderWidth: 1,
        fill: false
      }, {
        label: "totalamount",
        data: dataset2,
        backgroundColor: "#49A7E6",
        borderColor: "#49A7E6",
        borderWidth: 1,
        fill: false
      }]
      return this.renderLineChart("salesummarymonth", labeldata, objdata, "year", "totalrecords / totalamount");
    }
  }

    
  compare(a, b) {
    return b.totalamount - a.totalamount;
  }

  renderMemberSale = () => {
    const { salebymember } = this.state;
    if (salebymember.length > 0) {
      const labeldata = salebymember.map(e => e.memberid);
      const memobj = [{
        label: "totalamount",
        data: salebymember.map(e => e.totalamount),
        backgroundColor: "#408EC1"
      }]
      return this.renderBarChart("overallmembersale", labeldata, memobj, "member", "totalamount");
    }
  }

  renderBarChart  = (id,labeldata, objdata, xaxes, yaxes) => {   
    return <BarChart
      id={id}
      data={objdata}
      labels={labeldata}
      xaxes={xaxes}
      yaxes={yaxes}
    />
  }

  renderLineChart = (id, labeldata, objdata, xaxes, yaxes) => {  
    return <LineChart
      id={id}
      data={objdata}
      color="#5487D8"
      labels={labeldata}
      xaxes={xaxes}
      yaxes={yaxes}
    />
  }

  _renderQuickViewDiv = () => {
    return (
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_sales.svg`}
            />
            <p className="card2Text1">Total Sales</p>
            <p className="card2Text2">${this.state.totalsales}</p>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_transaction.svg`}
            />
            <p className="card2Text1">Total Transaction</p>
            <p className="card2Text2">{this.state.totaltrxn}</p>
          </div>
        </div>
        {/* <div className="col-lg-3 col-sm-12" title="Total QR Code Scanned : 10">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_qr code.svg`}
            />
            <p className="card2Text1">QR Code Scanned</p>
            <p className="card2Text2">10</p>
          </div>
        </div> */}
        <div className="col-lg-4 col-sm-12">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_newmember.svg`}
            />
            <p className="card2Text1">Active Members</p>
            <p className="card2Text2">{this.state.activemembers}</p>
          </div>
        </div>
      </div>
    );
  };

  _renderChartDiv = () => {
      return (
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-8 col-sm-8 alignLeft">
                Overall Sales Summary 
              </div>
              <div className="col-lg-4 col-sm-4 alignRight">
                <ChartOptions 
                toggleTable={this.handleTable}
                toggleChart={this.handleChart}
                chartid="salesummarymonth"
                tableimgid="salebymonth"
                chartdata={getByMonth(this.state.salebymonth)}
                />
              </div>
            </div>
            <div className="row noPadding noMargin">
               <div className="title_devide"></div>
               <div className="col-lg-12 col-sm-12">
                  {this.state.showTable === true && this.state.showTableId === "salebymonth" ?
                    <ChartTable tabledata={getByMonth(this.state.salebymonth)}/> : this.renderSaleSummaryMonth()}
                </div>
             </div>
          </div>
        </div> 
        <div className="col-lg-6 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-8 col-sm-8 alignLeft">
                Top Overall Performing Members Sale
              </div>
              <div className="col-lg-4 col-sm-4 alignRight">
                <ChartOptions 
                toggleTable={this.handleTable}
                toggleChart={this.handleChart}
                chartid="overallmembersale"
                tableimgid="membersale"
                chartdata={this.state.salebymember}
                />
              </div>
            </div>
            <div className="row noPadding noMargin">
               <div className="title_devide"></div>
               <div className="col-lg-12 col-sm-12">
                 {this.state.showTable === true && this.state.showTableId === "membersale" ?
                 <ChartTable tabledata={this.state.salebymember} />: this.renderMemberSale() }
               </div>
             </div>
          </div>
        </div>           
      </div>
    );
  };
  _renderForm = () => {
    return (
      <form>
        {this._renderQuickViewDiv()}
        {this._renderChartDiv()}
      </form>
    );
  };
  render() {
    return (
      <div className="form_height">
        <div className="row form-container">
          <div className="col">{this._renderForm()}</div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(Dashboard);


