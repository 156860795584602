import React from "react";

// Local imports

class FixedColTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => (
    <tbody>
      {this.props.rows ? (
        this.props.rows.length !== 0 && this.props.rows.length !== undefined ? (
          this.props.rows.map((row, index) => {
            const idString = `chkBox-${index}`;
            const rowId = `row_${index}`;

            return (
              <tr key={`tableview-tr-inner-${index}`} id={rowId}>
                {this.props.columns.map((column, index) => {
                  const col = column.dataField.replace(/\s/g, "");
                  return this.renderRowData(
                    column,
                    row,
                    col,
                    index,
                    idString,
                    rowId
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr key={"tableview-nodata"}>
            <td
              colSpan={this.props.columns.length}
              style={{ backgroundColor: "inherit", textAlign: "center" }}
            >
              No data found
            </td>
          </tr>
        )
      ) : (
        ""
      )}
    </tbody>
  );

  renderRowData = (column, row, col, index, idString, rowId) => {
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <div className="fixed_table">
        {this.props.tableheading ? (
          <div className="tabletitle row noPadding noMargin">
            <div className="cardtitle col-lg-9 col-sm-12 noPadding noMargin">
              {this.props.tableheading ? this.props.tableheading : ""}
            </div>
            <div className="cardtitle col-lg-3 col-sm-12 alignRight">
              {this.props.children}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="title_devide"></div>
        <table>
          {this.tableHeaders()}
          {this.tableBody()}
        </table>
      </div>
    );
  }
}

export default FixedColTable;
