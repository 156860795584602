import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import {
    storeGroupSearchByFields,
    storeSearchByFields,
} from '../actions/actions';
import * as CONSTANTS from '../constants/constants';

const generateOptionList = (list, key, label) =>
    list.map((item) => ({
        name: item[key],
        code: item[label],
    }));

const StoreSelector = (props) => {
    const { onSelectStore, storeid, accessOnly } = props;
    const { storeSearchStores } = props.queryData;
    const [showSelectorModal, setShowSelectorModal] = useState(false);
    const [selectedStore, setSelectedStore] = useState({});
    const [storeGroupId, setStoreGroupId] = useState('');
    const [storeList, setStoreList] = useState([]);
    const [fieldStore, setFieldStore] = useState({})

    const companyId = localStorage.getItem('company_id');

    const openStoreSearchModal = () => setShowSelectorModal(true);

    useEffect(() => {
        let queryParam = `status=Active&companyid=${companyId}`;
        props.dispatch(storeGroupSearchByFields(queryParam));
        props.dispatch(
            storeSearchByFields(
                `companyid=${companyId}&status=Active`,
                CONSTANTS.STORESEARCH_STORE_SUCCESS,
                CONSTANTS.STORESEARCH_STORE_FAILURE
            )
        );
    }, []);

    useEffect(() => {
        if (storeid && storeSearchStores.length > 0) {
            const store = storeSearchStores.find((s) => s.storeid === storeid);
            if (store) {
                setSelectedStore({ name: store.title, code: store.storeid });
            }
            
        }
    }, [storeid, storeSearchStores]);

    const handleStoreGroupChange = (e) => {
        setStoreGroupId(e.target.value);
        const { storeSearchStores } = props.queryData;
        let storeListByGroup = storeSearchStores.filter(
            (store) => store.groupid === e.target.value.code
        );
        if (accessOnly) {
            //show store list assigned to current user only
            const store_access = localStorage.getItem('store_access');
            if (store_access) {
                const stores = store_access.split(',');
                storeListByGroup = storeSearchStores.filter((e) =>
                    stores.includes(e.storeid)
                );
            }
        }
        setStoreList(storeListByGroup);
    };

    const handleStoreChange = (e) => {
        setFieldStore(e.target.value)
    }

    const handleSelectedStore = () => {
        setSelectedStore(fieldStore)
        onSelectStore(fieldStore);
        setShowSelectorModal(false);
    };

    const removeSelectedStore = () => {
        setSelectedStore({})
        onSelectStore('');
    }

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Save"
                    icon="pi pi-check"
                    onClick={handleSelectedStore}
                    autoFocus
                    disabled={!fieldStore.code}
                />
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => setShowSelectorModal(false)}
                    className="p-button-text"
                />
            </div>
        );
    };

    const getStoreAction = () => {
        if (selectedStore.code) {
            return (
                <i
                    className="pi pi-times-circle"
                    onClick={removeSelectedStore}
                    style={{ cursor: 'pointer' }}
                />
            );
        }
        return <i className="pi pi-caret-right" />;
    };
    return (
        <>
            <Dialog
                header="Store Search"
                visible={showSelectorModal}
                footer={renderFooter()}
                style={{ width: '50vw' }}
                onHide={() => setShowSelectorModal(false)}
            >
                <div className="row">
                    <div className="p-fluid formgrid w-100">
                        <div className="field">
                            <label htmlFor="storeGroup">Store Group</label>
                            <Dropdown
                                id="storeGroup"
                                name="storeGroup"
                                value={storeGroupId}
                                onChange={handleStoreGroupChange}
                                options={generateOptionList(
                                    props.queryData.storeGroups,
                                    'title',
                                    'groupid'
                                )}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                    </div>
                    {storeGroupId && (
                        <div className="p-fluid formgrid w-100">
                            <div className="field">
                                <label htmlFor="storeGroup">Store</label>
                                <Dropdown
                                    id="storeGroup"
                                    name="storeGroup"
                                    value={fieldStore}
                                    onChange={handleStoreChange}
                                    options={generateOptionList(
                                        storeList,
                                        'title',
                                        'storeid'
                                    )}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
            <span className="p-input-icon-right w-100">
                {getStoreAction()}
                <InputText
                    className="w-100"
                    value={selectedStore.name || ''}
                    readOnly={true}
                    onClick={openStoreSearchModal}
                    style={{ cursor: 'default' }}
                />
            </span>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
    };
};

export default connect(mapStateToProps)(StoreSelector);
