import React from 'react';
import { padNumber } from '../../../helpers/Util';

const getDateFormat = (dateStr) => {
    const dateParts = dateStr.split(' ');
    const dateArr = dateParts[0].split('-')
    return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2]
};

const getFivePercentDiscountAmount = (item) => {
    const { sc05, pwd05 } = item.specialdiscount
    if (sc05 && sc05.specialdiscountamount) {
        return isVoidTrxType(item.trxtype, sc05.specialdiscountamount)
    }
    if (pwd05 && pwd05.specialdiscountamount) {
        return isVoidTrxType(item.trxtype, pwd05.specialdiscountamount)
    }
    return '';
};

const getTwentyPercentDiscountAmount = (item) => {
    const { sc20, pwd20 } = item.specialdiscount
    if (sc20 && sc20.specialdiscountamount) {
        return isVoidTrxType(item.trxtype, sc20.specialdiscountamount)
    }
    if (pwd20 && pwd20.specialdiscountamount) {
        return isVoidTrxType(item.trxtype, pwd20.specialdiscountamount)
    }
    return '';
};

const isVoidTrxType = (trxType, value) => {
    if (trxType == 'void' && value > 0) {
        return '-'+ value.toFixed(2)
    }
    return value.toFixed(2)
}

const getReturnOrVoidNo = (item) => {
    const { trxtype, voidno, retuno } = item
    if (trxtype === 'void') {
        return voidno
    }
    else if (trxtype === 'return') {
        return retuno
    }
}

const SpecialDiscountTable = (props) => {
    const { data, dataType } = props;

    const getDiscountTypeTitle = () => {
        if (dataType == 'pwd') {
            return 'PWD';
        } else {
            return 'Senior Citizen';
        }
    };

    return (
        <div className="table-wrapper" style={{ overflowX: 'auto' }}>
            <table
                className="pdf-table"
                cellSpacing="0"
                border="0"
                style={{ width: '100%' }}
            >
                <tbody>
                    <tr>
                        <td
                            colSpan={12}
                            height="29"
                            align="center"
                            valign="bottom"
                            style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                padding: '12px',
                            }}
                        >
                            {getDiscountTypeTitle()} Sales Book/Report
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            style={{ borderLeft: 'none' }}
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#A6A6A6"
                        >
                            Date
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#A6A6A6"
                        >
                            Transaction Type
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#00B0F0"
                        >
                            Name of {getDiscountTypeTitle()}
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#FFFF00"
                        >
                            {dataType == 'pwd' ? 'PWD ID No.' : 'OSCA ID No./ SC ID No.'} 
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#9999FF"
                        >
                            {getDiscountTypeTitle()} TIN
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#FFC000"
                        >
                            SI/OR Number
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#FFD966"
                        >
                            Return/Void No
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#92D050"
                        >
                            <font color="#000000">
                                Sales (inclusive of VAT)
                            </font>
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#ED7D31"
                        >
                            <font color="#000000">VAT Amount</font>
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#E7E6E6"
                        >
                            <font color="#000000">VAT Exempt Sales</font>
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            style={{ borderBottom: 'none' }}
                            colSpan={2}
                            align="center"
                            valign="middle"
                            bgcolor="#FFD966"
                        >
                            <font color="#000000">Discount</font>
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={4}
                            align="center"
                            valign="middle"
                            bgcolor="#A6A6A6"
                        >
                            <font color="#000000">Net Sales</font>
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={3}
                            align="center"
                            valign="middle"
                            bgcolor="#FFD966"
                            sdval="0.05"
                            sdnum="1033;0;0%"
                        >
                            <font color="#000000">5%</font>
                        </td>
                        <td
                            className="pv-12 ph-12 th-border f-12"
                            rowSpan={3}
                            align="center"
                            valign="middle"
                            bgcolor="#FFD966"
                            sdval="0.2"
                            sdnum="1033;0;0%"
                        >
                            <font color="#000000">20%</font>
                        </td>
                    </tr>
                    <tr></tr>
                    <tr></tr>
                    {data.length == 0 && (
                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                            <td
                                colSpan={20}
                                style={{ textAlign: 'center', padding: '16px' }}
                            >
                                Your search does not retrieve any data. Please
                                search again using another criteria.
                            </td>
                        </tr>
                    )}
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                                style={{ display: 'block' }}
                            >
                                {getDateFormat(item.trxdate)}
                            </td>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                            >
                                {item.trxtype}
                            </td>
                            <td
                                className="t-cell"
                                align="center"
                                valign="bottom"
                            >
                                {item.membername}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {item.memberid}
                            </td>
                            <td className="t-cell" align="left" valign="bottom">
                                {item.tin} 
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {item.invoicenoresetno}-{padNumber(item.invoiceno, 9)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {getReturnOrVoidNo(item)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {isVoidTrxType(item.trxtype, item.grossprice)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {isVoidTrxType(item.trxtype, item.grosstax)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {isVoidTrxType(item.trxtype, item.salesexemptedamount)}
                            </td>
                            
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {getFivePercentDiscountAmount(item)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {getTwentyPercentDiscountAmount(item)}
                            </td>
                            <td
                                className="t-cell"
                                align="right"
                                valign="bottom"
                            >
                                {isVoidTrxType(item.trxtype, item.netamount)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SpecialDiscountTable