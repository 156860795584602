import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LinkButton from "../../../../components/LinkButton";
// Local Imports
// Local Imports
import ImagePick from "../../../../components/ImagePick";
import {
  updateArticleInfoApi,
  createArticleInfoApi,
  crudReset,
  updateArticleMode
} from "../../../../actions/actions";
import ConfirmAlert from "../../../../components/ConfirmAlert";
import StatusComp from "../../../../components/StatusComp";
import { produce } from "immer";
import SimpleSelect from "./../../../../components/SimpleSelect";

class AddSecurePayment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      articlecode: "",
      title: "",
      desc: "",
      status: "New",
      images: {},
      sortorder: 0,
      language: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      titleerror: "",
      descError: ""
    };
  }

  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          title,
          articlecode,
          fields,
          status,
          sortorder,
          images,
          articletype
        } = rec;

        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            //draft.status = status ? status : [];
            draft.sortorder = sortorder ? sortorder : 0;
            draft.articletype = articletype ? articletype : "";
            draft.articlecode = articlecode ? articlecode : "";
            draft.desc = fields.desc ? fields.desc : "";
            draft.images = {
              image1: rec.hasOwnProperty("images") === true ? images.image1 : "",
              image2: rec.hasOwnProperty("images") === true ? images.image2 : ""
            };
          })
        );
      }
    }
  };

  componentWillReceiveProps = () => {
    if (this._isEditMode()) {
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };

  getCreatePayload = () => {
    const {
      title,
      articlecode,
      desc,
      status,
      sortorder,
      images,
      language
    } = this.state;

    let body = {
      title: title,
      language: language,
      articlecode,
      publishmode: "manual",
      articletype: "Secure Payment",
      status: status,
      sortorder: sortorder,
      fields: {
        desc
      },
      images: {
        image1: images.image1 || "",
        image2: images.image2 || ""
      }
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createArticle = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );  
        this._updateArticleMode();
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
        this._updateArticleMode();
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    } 
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      subtypeerror: "",
      titleerror: "",
      descError: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { title, desc } = this.state;
    let valid = true;
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter title"
      });
      valid = false;
    }
    if (desc.trim() === "") {
      this.setState({
        descError: "Please enter description"
      });
      valid = false;
    }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };

  _updateArticleMode = () =>{
    const isEditMode = this._isEditMode();
    if(!isEditMode){
      const { articleMode } = this.props.location.state;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articleMode } }) 
      );
    }
    else {
      const { articletype } = this.props.location.state.item;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articletype } }) 
      );
    }
  }

  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Update
          </button>
        )}
        {/* <Link
          to = "/ui/articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link> */}
        <LinkButton
          to="/ui/articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          onClick={() => this._updateArticleMode()}
        >
          {`Cancel`}
        </LinkButton>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  _renderFormArea = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="articlecode" className="floatLeft">
              Article Type
            </label>
            <input
              type="text"
              className="form-control"
              id="articlecode"
              value="Secure Payment"
              disabled={true}
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={languageConfig.pvalues}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="articlecode" className="floatLeft">
              Article Code
            </label>
            <input
              type="text"
              className="form-control"
              id="articlecode"
              value={this.state.articlecode}
              readOnly={true}
            />
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="arttype" className="floatLeft">
              Article Sub Type
            </label>
            <input
              type="text"
              className="form-control"
              id="arttype"
              value={this.state.title}
              onChange={event => {
                this.setState({ arttype: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <label htmlFor="desc" className="floatLeft  required">
              Description
            </label>
            <textarea
              className="form-control"
              id="desc"
              value={this.state.desc}
              onChange={event => {
                this.setState({ desc: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.descError}</div>
          </div>
        </div>
      
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>          
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="image1" className="floatLeft">
              Images
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={2}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };

  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true
                  ? "Edit Secure Payment"
                  : "Add Secure Payment"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv text-center">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/articles"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "Secure Payment updated successfully!"
                  : "Secure Payment created successfully!"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddSecurePayment);
