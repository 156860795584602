import React, { Component } from "react";

class DynamicTableFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  tableHeaders = () => (
    <thead>
      <tr>
        {this.props.columns.map((column, index) => {
          return <th key={`tableview-th-${index}`}>{column.label}</th>;
        })}
      </tr>
    </thead>
  );
  tableBody = () => {
    var rowleng = this.props.rows ? this.props.rows.length : 0;

    return (
      <tbody>
        {this.props.rows.map(row => {
          let index = row.id;
          const rowId = `row_${index}`;
          return (
            <tr key={`tableview-tr-inner-${index}`} id={rowId}>
              {this.props.columns.map((column, index) => {
                const col = column.dataFieldId.replace(/\s/g, "");
                return this.renderRowData(
                  column,
                  row,
                  col,
                  index,
                  rowId,
                  rowleng
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  renderRowData = (column, row, col, index, rowId, rowleng) => {
    if (col==="question" || col==="parametertype") {
      return (
        <td key={`tableview-td-${rowId}-${index}`} style={{ width: "40em" }}>
          <input
            type="text"
            defaultValue={row[column.dataFieldId]}
            placeholder={
              column.dataFieldId.charAt(0).toUpperCase() +
              column.dataFieldId.slice(1)
            }
            onChange={event =>
              this.props.handleInputChange(event, column.dataFieldId, row)
            }
          />
        </td>
      );
    }
    if (col === "answer" || col === "parametervalue") {
      return (
        <td key={`tableview-td-${rowId}-${index}`} style={{ width: "40em" }}>
          <textarea
            style={{ width: "100%" }}
            rows="5"
            columns="8"
            defaultValue={row[column.dataFieldId]}
            placeholder={
              column.dataFieldId.charAt(0).toUpperCase() +
              column.dataFieldId.slice(1)
            }
            onChange={event =>
              this.props.handleInputChange(event, column.dataFieldId, row)
            }
          />
        </td>
      );
    }
    if (col === "AD") {
      return (
        <td key={`tableview-td-${rowId}-${index}`}>
          {rowleng < 20 && (
            <img
              className="addBtn1"
              onClick={this.props.addRow}
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_add_blue.png`}
            />
          )}
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.props.deleteRow(row)}
            className="addBtn2"
          />
        </td>
      );
    }
    return <td key={`tableview-td-${rowId}-${index}`}>{row[col]}</td>;
  };
  render() {
    return (
      <React.Fragment>
        <div className="dynamicTable">
          <table>
            {this.tableHeaders()}
            {this.tableBody()}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicTableFAQ;
