import React, {useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { updateMemberTag, createMemberTag, crudReset} from '../../../../actions/actions';
import ConfirmAlert from '../../../../components/ConfirmAlert';
import formik from '../../../../hooks/useFormik'
import * as Yup from 'yup'
import { TAG_TYPE } from './const'

const _validationSchema= Yup.object().shape({
       tagcode: Yup.string()
         .max(200, 'Must be 200 characters or less')
         .required('Required'),
       model: Yup.string()
         .max(200, 'Must be 200 characters or less')
         .required('Required')
     })

const AddItem = (props) => {
  const isEditMode = useMemo(() => {
    if (props.location && props.location.state) {
      return props.location.state.isEdit
    }
    return false;
  }, [props.location])

  const tagInfo = useMemo(() => {
    let rec = {}
    if (props.location && props.location.state) {
      rec = props.location.state.item || {}
    }

    let additionalfields = {}
    if(rec.additionalfields && Object.keys(rec.additionalfields).length > 0) {
      additionalfields = rec.additionalfields
    }

    return {
      id: (rec.id || ''),
      tagtype: (rec.tagtype || TAG_TYPE),
      tagcode: (rec.tagcode || ''),
      model: (additionalfields.model || '')
    }

  }, [props.location])

  const _getCreatePayload = (values) => {
    let body = {
      "memberid": 'h87283978',
      "tagtype": values.tagtype,
      "tagcode": values.tagcode,
      "additionalfields": {
        "model": values.model
      }
    };

    if (isEditMode && tagInfo.id) {
      body['id'] = tagInfo.id;
    }

    return {
      body: body
    };
  }

  const _submitForm = (values, { setSubmitting }) => {
    if (isEditMode) {
      props.dispatch(updateMemberTag(_getCreatePayload(values)))
    } else {
      props.dispatch(createMemberTag(_getCreatePayload(values)))
    }
    setSubmitting(false)
  }

  const _hideModal = useCallback(e => {
    e.preventDefault();
    props.dispatch(crudReset());
  }, [props])

  const {values, errors, handleSubmit, handleChange, handleBlur, isSubmitting, validateForm} = formik(tagInfo, _validationSchema, _submitForm)

  const _renderErrorMessage = useMemo(() => {
    const { isSuccess, errormsg } = props.crudData;
    if ((errormsg && !isSuccess)) {
      return (
        <div className='alert alert-danger' role='alert'>
          {errormsg}
        </div>
      );
    }
    return <React.Fragment/>;
  },[props.crudData])

  const _renderFieldError = useMemo(() => (
    <div className='alert alert-danger' role='alert'>
      Clear all errors
    </div>
  ), [])

  const _renderFooter = useMemo(() => (
    <div className='py-5'></div>
  ), [])

  const _renderFormInputs = useMemo(() => {
    return (
      <>
        <h3 className="text-left">System Information</h3>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='tagtype' className='floatLeft'>
              Tag Type
            </label>
            <input
              disabled
              type='text'
              className='form-control'
              id='tagtype'
              value={values.tagtype}
              onChange={()=>{ }}
            />
          </div>
        </div>
        <div className="title_devide"></div>
        <h3 className="text-left">Product Information</h3>
        <div className='row'>
          <div className='form-group col-lg-12 col-sm-12'>
            <label htmlFor='firstname' className='floatLeft required'>
              Title
            </label>
            <input
              type='text'
              className='form-control'
              id='tagcode'
              value={values.tagcode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.tagcode}</div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='firstname' className='floatLeft required'>
              Model
            </label>
            <input
              type='text'
              className={errors.model? 'form-control' : 'error-control'}
              id='model'
              value={values.model}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.model}</div>
          </div>
        </div>
      </>
    )
  },[values, errors])

  const { isSuccess } = props.crudData;
  return (
    <React.Fragment>
      <div className='form_height'>
        <div className='row'>
          <div className='col-md-9'>
            <h2 className='page-header'>
              {isEditMode? 'Edit Service Item': 'Add Service Item'}
            </h2>
          </div>
        </div>
        <div className='row form-container'>
          <div className='col'>
              <form className='frmDiv text-center' onSubmit={
                (event)=>{
                  event.preventDefault()
                  validateForm().then((errors)=>{
                    if(errors && Object.keys(errors).length !== 0) {
                      window.scrollTo(0,0)
                    }
                    else {
                      handleSubmit()
                    }
                  })
                }
              }>
                {Object.keys(errors).length !== 0 && _renderFieldError}
                {_renderErrorMessage}
                {_renderFormInputs}
                {_renderFooter}
                <div className='row btn-container form-button py-4'>
                  <div className='col-12'>
                    <button
                      type='submit'
                      className='btn btn-themes btn-rounded' disabled={isSubmitting}
                    >
                      {isEditMode?'Update':'Create'}
                    </button>
                    <button
                      onClick={props.history.goBack}
                      className='btn btn-themes btn-rounded btn-sec link-sec-btn'
                    >Cancel</button>
                  </div>
                </div>
              </form>
          </div>
        </div>
        <ConfirmAlert
          show={isSuccess}
          handleClose={_hideModal}
          to='/ui/item-registration'
          children={
            <div style={{ color: 'green', padding: '2em' }}>
              {isEditMode
                ? 'Item updated successfully'
                : 'Item created successfully'}
            </div>
          }
        />
      </div>
    </React.Fragment>
  );

}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData
  };
};

export default connect(mapStateToProps)(AddItem);
