import produce from 'immer';
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { crudReset, generateEJ, searchGeneral } from '../../actions/actions';
import ConfirmAlert from '../../components/ConfirmAlert';
import { permissions } from '../../helpers/Util';
import * as CONSTANTS from '../../constants/constants';
import StoreSearchSingle from '../system/Users/StoreSearchSingle';
import PrimeTable from '../../components/PrimeTable';

class GenerateEJ extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'REPORTS';
        this.group = 'DE_REPORTS_EJ_GENERATE';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,

            storeid: '',
            trxno: '',
            terminalid: '',

            message: '',
            action: '',

            status: '',
            pagesize: this.pageSize,

            storeiderror: '',
            terminaliderror: '',
            trxnoerror: '',
        };
    }

    filterTemplate = row => {
      if (row.additionalfields) {
        let filters = []
        let excludedfields = ['pagesize', 'pageno', 'filepath']
        for (const [key, value] of Object.entries(row.additionalfields)) {
          if (excludedfields.includes(key)) {
            continue
        }
        filters.push(`${key}=${value}`)
        }
        return filters.join(' | ')
      }
      return null
    }

    actionTemplate = row => {
      if (row.status !== 'exported') {
        return;
    }
    return (
        <span>
            <img
                title="Download"
                className="tableImage"
                src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                onClick={() => this.download(row)}
            />
        </span>
    );
    }

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    pagesize: pageSize,
                    pageno: currPage,
                    tasktype: this.getTaskType(),
                },
            };
            return requestBody;
        }
        if (this.requestBody['tasktype'] === undefined) {
            this.requestBody['tasktype'] = this.getTaskType();
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/jobtask/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
                break;
        }
    };

    componentWillMount() {
        if (this.props.params) {
            this.setState({
                storeid: this.props.params.storeid,
                s_storelabel: this.props.params.s_storelabel,
            });
        }
        this.callQueryApiForFetch('ONLOAD');
        this.allPermissions = permissions(this.module, this.group);
    }

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.queryData.reportexport = {};
        this.callQueryApiForFetch('ONLOAD');
    };

    exportEJ = (e) => {
        if (e) e.preventDefault();

        const { storeid, trxno, terminalid } = this.state;

        this.requestBody = {};
        const queryParams = [];

        if (storeid === '' || storeid === null) {
            this.setState({ storeiderror: 'Please select the store' });
            return;
        } else {
            this.setState({ storeiderror: '' });
            this.requestBody['storeid'] = storeid;
            queryParams.push(`storeid=${storeid}`);
        }

        if (terminalid === '' || terminalid === null) {
            this.setState({ terminaliderror: 'Please select the terminal' });
            return;
        } else {
            this.setState({ terminaliderror: '' });
            this.requestBody['terminalid'] = terminalid;
            queryParams.push(`terminalid=${terminalid}`);
        }

        if (trxno === '' || trxno === null) {
            this.setState({ trxnoerror: 'Please enter the trx no' });
            return;
        } else {
            this.setState({ trxnoerror: '' });
            this.requestBody['trxno'] = trxno;
            queryParams.push(`trxno=${trxno}`);
        }
        //query
        this.setState({ message: 'Request Submitted' });

        const payload = {
            body: [].concat(this.requestBody),
        };

        this.props.dispatch(generateEJ(payload));
    };

    getTaskType = () => {
        return 'ejt-export';
    };

    _renderSearchCiteria = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-3 col-sm-12 ">
                        <label htmlFor="storeid" className="floatLeft required">
                            Select Store
                        </label>
                        <StoreSearchSingle
                            value={this.state.s_storelabel}
                            onSelectStore={this._onSelectStore}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-12">
                        <label htmlFor="terminalid" className="w-100">
                            Terminal Id
                        </label>
                        <InputText
                            className='w-100'
                            id="terminalid"
                            name="terminalid"
                            value={this.state.terminalid}
                            onChange={(event) => {
                                this.setState({ terminalid: event.target.value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.terminaliderror}
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-12">
                        <label htmlFor="trxno" className="w-100">
                            Transaction No
                        </label>
                        <InputText
                            className='w-100'
                            id="trxno"
                            name="trxno"
                            value={this.state.trxno}
                            onChange={(event) => {
                                this.setState({ trxno: event.target.value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.trxnoerror}
                        </div>
                    </div>

                    <div
                        className="col-lg-3 col-sm-12 btn-container1"
                        style={{ marginTop: '1em' }}
                    >
                        <button
                            type="submit"
                            onClick={(e) => this.exportEJ(e)}
                            className="btn btn-themes btn-rounded"
                        >
                            Generate
                        </button>
                    </div>
                </div>

                <div className="form-group col-lg-7 col-sm-3 noPadding" />
            </>
        );
    };

    _setTenant = (p1, p2) => {
        this.setState(
            { storeid: p1, s_storelabel: p2, search_value: p1 },
            () => {
                localStorage.setItem('storeid', p1);
                localStorage.setItem('storelabel', p2);
            }
        );
        this.setState({ storeiderror: '' });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label);
    };

    render() {
        const { jobtasklogs } = this.props.queryData;

        const { data: reportexport } = this.props.crudData;

        const columns = [];

        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">Generate EJ</h2>
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <div className="row">
                                    {this._renderSearchCiteria()}
                                </div>

                                <div className="title_devide" />
                                <PrimeTable
                                    list={jobtasklogs}
                                    isSearched={this.state.isSearched}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                      this.setState(
                                        produce(this.state, (draft) => {
                                            if (draft.pageSize < pageSize) {
                                                draft.currPage = 1;
                                            }
                                            draft.pageSize = pageSize;
                                        }),
                                        () => {
                                            this.callQueryApiForFetch(
                                                'PAGECLICK'
                                            );
                                        }
                                    );
                                    }
                                        
                                    }
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                >
                                  <Column field="title" header="Path" />
                                  <Column field="status" header="Status" />
                                  <Column field="additionalfields" header="Filters" body={this.filterTemplate} style={{ wordBreak: 'break-word', width: '20%'}} />
                                  <Column field="createdon" header="Exported On" />
                                  <Column field="lastupdatedby" header="Generated By" />
                                </PrimeTable>
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={
                            reportexport &&
                            reportexport.length > 0 &&
                            reportexport[0].status === 'exporting'
                        }
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    reportexport &&
                                    reportexport.length > 0 &&
                                    reportexport[0].status === 'exporting'
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {reportexport &&
                                reportexport.length > 0 &&
                                reportexport[0].status === 'exporting'
                                    ? this.state.message
                                    : ''}
                            </div>
                        }
                    />
                </div>
                <div className="row btn-container form-button">
                    <div className="col-sm-12 col-lg-12">
                        {this._renderMainButton()}
                    </div>
                </div>
            </>
        );
    }

    _renderMainButton = () => {
        return (
            <>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    onClick={() => {
                        this.props.queryData.errormsg = '';
                        this.props.queryData.servererror = '';
                        this.props.crudData.errormsg = '';
                        this.props._hideExport();
                    }}
                >
                    Back
                </button>
            </>
        );
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            id: '',
            memberid: '',
            storeid: '',
            status: '',
            title: '',

            storeiderror: '',
            terminaliderror: '',
            trxnoerror: '',
        });
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(GenerateEJ);
