import React, { useState } from 'react';
import { Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import history from './helpers/History';
import LoginPage from './pages/LoginPage';
import LandingPage from './pages/LandingPage';

const App = () => {
    const [css, setCss] = useState('dark_theme.css');
    return (
        <>
            <Router history={history}>
                <div className="clearfix">
                    <Helmet>
                        <link
                            rel="stylesheet"
                            href={`${process.env.PUBLIC_URL}/styles/custom.css?v=${process.env.REACT_APP_VERSION}`}
                        />
                        <link
                            rel="stylesheet"
                            href={`${process.env.PUBLIC_URL}/styles/${css}?v=${process.env.REACT_APP_VERSION}`}
                        />
                    </Helmet>
                    <Route exact path="/" component={withRouter(LoginPage)} />
                    <Route
                        path="/ui"
                        component={withRouter((props) => (
                            <LandingPage {...props} />
                        ))}
                    />
                </div>
            </Router>
        </>
    );
};
export default App;
