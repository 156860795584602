import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import Sidebar from './Navigation/Sidebar';
import Header from './Navigation/Header';
import { routesForSideBar } from './Navigation/sideBarRoutes';
import Footer from './Navigation/Footer';
import MainRouterPage from './MainRouterPage';
import {
    logoutFromDashboard,
    resetAction,
    crudReset,
} from '../actions/actions';
import { userAccess } from '../helpers/Util';

const LandingPage = ({ dispatch, setNewTheme, match, location }) => {
    const [isSideBarActive, setIsSideBarActive] = useState(true);
    const [sidebarRoutes, setSidebarRoutes] = useState(routesForSideBar);
    const [displayItems, setDisplayItems] = useState(routesForSideBar);
    const [allAccesses, setAllAccesses] = useState({});

    const _logoutClick = () => {
        dispatch(logoutFromDashboard());
    };

    const _toggleSideBar = () => {
        setIsSideBarActive(!isSideBarActive);
    };

    useEffect(() => {
        dispatch(crudReset());
        dispatch(resetAction());
        window.scrollTo(0, 0);
        accesses();
    }, []);

    const _searchFromMenubar = (searchQ) => {
        setSidebarRoutes(routesForSideBar);
        let searcjQery = searchQ.toLowerCase();
        if (searcjQery === '' || searcjQery === undefined) {
            setDisplayItems(routesForSideBar);
        } else {
            var filteredList = sidebarRoutes.map(function (el) {
                let searchValue = el.keywords.toLowerCase();
                var o = Object.assign({}, el);
                if (searchValue.indexOf(searcjQery) !== -1) {
                    o.highlightClass = 'highlight';
                } else {
                    o.highlightClass = '';
                }
                return o;
            });
            setDisplayItems(filteredList);
        }
    };

    const isLoggedIn = localStorage.getItem('isLoggedIn');

    const accesses = () => {
        setAllAccesses(userAccess());
    };

    if (!isLoggedIn || isLoggedIn === 'false') {
        return (
            <Switch>
                <Redirect to="/" />
            </Switch>
        );
    } else {
        return (
            <div className="wrapper">
                <Sidebar
                    isSideBarActive={isSideBarActive}
                    toggleSideBar={_toggleSideBar}
                    sidebarRoutes={displayItems}
                    allAccesses={allAccesses}
                />
                <div id="content">
                    <Header
                        toggleSideBar={_toggleSideBar}
                        setNewTheme={setNewTheme}
                        searchItem={_searchFromMenubar}
                        logoutClick={_logoutClick}
                    />
                    <MainRouterPage
                        style={{ minHeight: '99vh', maxHeight: '99vh' }}
                        match={match}
                        location={location}
                    />
                    <Footer />
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return { loginData: state.loginData };
};

export default connect(mapStateToProps)(LandingPage);
