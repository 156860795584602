import { takeLatest, call, put } from 'redux-saga/effects';
import { loginApi, logoutApi } from '../api/services';
import * as CONSTANTS from '../constants/constants';

function* loginSaga(action) {
    yield put({ type: CONSTANTS.API_PENDING });
    const data = {};
    try {
        const response = yield call(loginApi, action.payload);
        const status = response.status;
        const data = response.data;
        if (response.data.status === '206') {
            yield put({
                type: CONSTANTS.LOGIN_FAILURE,
                data: [],
                errormsg: `${response.data.message || response.data.msgcode}`,
                loginErrorMsg: `${response.data.message || response.data.msgcode}`,
            });
        } else {
            if (status === 200 && response.data === '') {
                yield put({
                    type: CONSTANTS.LOGIN_FAILURE,
                    data: [],
                    errormsg: `Record not found!`,
                    loginErrorMsg: `Record not found!`,
                    serverError: '',
                });
            } else {
                const randomDeviceId = Math.floor(1000 + Math.random() * 9000);
                localStorage.setItem('randomDeviceId', randomDeviceId);
                var todayDate = new Date();
                var date =
                    ('0' + todayDate.getDate()).slice(-2) +
                    '/' +
                    ('0' + (todayDate.getMonth() + 1)).slice(-2) +
                    '/' +
                    todayDate.getFullYear().toString().substr(-2);
                var copyrightyear = todayDate.getFullYear();
                localStorage.setItem('copyrightyear', copyrightyear);
                const buildversion = 'v' + date.replace(/\//g, '');
                localStorage.setItem('buildversion', buildversion);
                yield put({
                    type: CONSTANTS.LOGIN_SUCCESS,
                    data: data,
                    serverError: '',
                });
            }
        }
    } catch (error) {
        yield put({
            type: CONSTANTS.LOGIN_FAILURE,
            data,
            errormsg: 'Server Error',
            loginErrorMsg: 'Server Error',
        });
    }
}

function* logoutSaga(action) {
    const data = {};
    try {
        const response = yield call(logoutApi, {});
        const data = response.data;
        yield put({ type: CONSTANTS.LOGOUT_SUCCESS, data });
    } catch (error) {
        yield put({ type: CONSTANTS.LOGOUT_FAILURE, data });
    }
}

export function* accessDashboardSaga() {
    yield takeLatest(CONSTANTS.LOGIN_REQUEST, loginSaga);
    yield takeLatest(CONSTANTS.LOGOUT_REQUEST, logoutSaga);
}
