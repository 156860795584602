import React from 'react';
import SimpleTable from './../../../components/SimpleTable';
import { produce } from 'immer';
import {
    deleteWordTranslation,
    updateDefaultConfig,
    publisArticleInfoApi,
    unpublishArticleInfoApi,
    searchGeneral,
    crudReset,
    resetAction,
} from '../../../actions/actions';
import ConfirmAlert from './../../../components/ConfirmAlert';

import { searchFields } from './tablesCols';
import { connect } from 'react-redux';
import Loading from './../../../components/Loading';
import SimpleSearcher from './../../../components/SimpleSearcher';
import MultiSelect from './../../../components/MultiSelect';
import { Link } from 'react-router-dom';
import {
    canDelete,
    canView,
    canWrite,
    formatLanguageArray,
    permissions,
} from '../../../helpers/Util';

class DisplayWordTranslation extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'CMS';
        this.group = 'DE_CMS_WORD_TRANSLATION';
        this.allPermissions = [];

        this.state = {
            pages: 10,
            currPage: 0,
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            langlist: {},
            language: '',
            tableCols: [
                {
                    dataField: 'wrdcode',
                    text: 'Word Code',
                    sort: true,
                },
                {
                    dataField: 'wrdgroup',
                    text: 'Word Group',
                    sort: true,
                },
                {
                    dataField: 'language',
                    text: 'Langauge',
                    sort: true,
                    formatter: this.languageFormatter,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                },
                {
                    dataField: '',
                    text: 'Actions',
                    formatter: this.cellFormatter,
                    formatExtraData: {
                        action: 'ED',
                    },
                    classes: () => {
                        return 'lastTd';
                    },
                },
            ],
        };
    }

    languageFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (
            this.props.queryData.languageConfig[0] &&
            this.props.queryData.languageConfig[0]['additionalfields']
        ) {
            return (
                this.props.queryData.languageConfig[0]['additionalfields'][
                    row.language
                ] || ''
            );
        }
        //return row.language === "en" ? "English" : row.language === "ko" ? "Korean" : row.language === "th" ? "Thai" : row.language === "zh" ? "Chinese" : "";
    };

    cellFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row.status === 'Deleted') {
            return;
        }

        return (
            <span>
                {canView(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/word-translation/addNew',
                            state: {
                                item: row,
                                isEdit: false,
                                isView: true,
                            },
                        }}
                    >
                        <img
                            title="View"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                            alt="preview"
                        />
                    </Link>
                )}
                {canWrite(this.allPermissions) && (
                    <Link
                        to={{
                            pathname: '/ui/word-translation/addNew',
                            state: {
                                item: row,
                                isEdit: true,
                            },
                        }}
                    >
                        <img
                            title="Edit"
                            className="tableImage"
                            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
                            alt="edit-icon"
                        />
                    </Link>
                )}
                {canDelete(this.allPermissions) && (
                    <img
                        title="Delete"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                        onClick={() => this.deleteStoreInfo(row)}
                        alt="delete-icon"
                    />
                )}
            </span>
        );
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { language, search_field, search_condi, search_value } =
            this.state;

        const requestBody = {
            body: {
                language: language.toString(),
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
            },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'cms/v1/word/translation/search',
                this.createFetchUrl(fetchType),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                const [pgNumber] = rest;

                newState = produce(this.state, (draft) => {
                    draft.currPage = pgNumber - 1;
                });
                this.setState({ ...newState }, () => {
                    this.callFetch('PAGECLICK');
                });

                break;
        }
    };
    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            }
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage.toString().split(',') }, () =>
            this.callQueryApiForFetch('ONLOAD')
        );
        this.allPermissions = permissions(this.module, this.group);
    }

    componentWillReceiveProps = (prevProps) => {
        const { languageConfig } = this.props.queryData;
        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState({
                langlist: list,
            });
        }
    };

    pageNumberOnClick = (pgNumber) => {
        this.callQueryApiForFetch('PAGECLICK', pgNumber);
    };

    _renderAddNewBtn = () => {
        if (!canWrite(this.allPermissions)) {
            return;
        }

        const { language } = this.state;

        return (
            <Link
                to={{
                    pathname: '/ui/word-translation/addNew',
                    state: {
                        language: language[0],
                    },
                }}
            >
                <button className="page-header-btn icon_btn display-inline">
                    <img
                        title="edit"
                        className="tableImage"
                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
                        alt='edit-icon'
                    />
                    {`Add New`}
                </button>
            </Link>
        );
    };
    shdDeleteRec = (arg) => {
        this.setState({ openDeleteprompt: false });
        if (arg === true) {
            this.props.dispatch(
                unpublishArticleInfoApi(this.state.delete_rec_id)
            );
            this.props.dispatch(
                deleteWordTranslation(this.state.delete_rec_id)
            );
        }
    };
    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
        // if (refresh === true) {
        //   this.callQueryApiForFetch("ONLOAD");
        // }
    };
    deleteStoreInfo = (rowData) => {
        this.setState({ message: 'Word Translation deleted successfully' });
        this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
    };
    unpublishArticle = (rowData) => {
        this.setState({ message: 'Article unpublished successfully' });
        this.props.dispatch(unpublishArticleInfoApi(rowData.id));
    };
    publishArticle = (rowData) => {
        this.setState({ message: 'Article published successfully' });
        this.props.dispatch(publisArticleInfoApi(rowData.id));
    };
    render() {
        const {
            articleFlowData,
            languageConfig,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;

        const { isSuccess, errormsg: crudError } = this.props.crudData;
        if (
            !articleFlowData ||
            this.props.queryData.apiPending ||
            this.props.crudData.crudApiPending
        ) {
            return <Loading />;
        }

        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        if (!articleFlowData && !errormsg) {
            return <Loading />;
        }
        if (!articleFlowData || this.props.queryData.apiPending) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="page-header">
                            Word Translation
                            <img
                                title="Refresh"
                                onClick={() => {
                                    this.callQueryApiForFetch('ONLOAD');
                                }}
                                className="refreshtree"
                                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                alt="refresh-icon"
                            />
                        </h2>
                    </div>
                </div>
                <div className="row form-container">
                    <div className="col">
                        <form className="frmDiv">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12 noPadding noMargin">
                                </div>

                                <div className="col-lg-4 col-sm-12 alignRight noPadding noMargin">
                                    <SimpleSearcher
                                        filterOptions={searchFields}
                                        setAndSearch={this.searchQuery}
                                        search_field={this.state.search_field}
                                        search_condi={this.state.search_condi}
                                        search_value={this.state.search_value}
                                    ></SimpleSearcher>
                                </div>
                                <div className="col-lg-2 col-sm-12  noPadding noMargin">
                                    <MultiSelect
                                        filterOptions={formatLanguageArray(
                                            this.state.langlist
                                        )}
                                        setSelectedFieldValues={(value) => {
                                            this.setState(
                                                { language: value },
                                                () => {
                                                    this.callQueryApiForFetch(
                                                        'ONLOAD'
                                                    );
                                                }
                                            );
                                            this.props.dispatch(
                                                updateDefaultConfig(
                                                    {
                                                        body: {
                                                            language: value,
                                                        },
                                                    },
                                                    () => {
                                                        this.callQueryApiForFetch(
                                                            'ONLOAD'
                                                        );
                                                    }
                                                )
                                            );
                                        }}
                                        id="language"
                                        itemsSelected={this.state.language}
                                        label="Select Language"
                                    />
                                </div>
                                <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
                                    {this._renderAddNewBtn()}
                                </div>
                            </div>
                            <div className="title_devide" />
                            <div className="invalid-feedback">{errormsg}</div>
                            <SimpleTable
                                columns={this.state.tableCols}
                                rows={articleFlowData}
                                callback={() => {
                                    this.callQueryApiForFetch('ONLOAD');
                                }}
                            />
                            {/* <TableFooter /> */}
                        </form>
                    </div>
                </div>
                <ConfirmAlert
                    show={this.state.openDeleteprompt}
                    isPrompt={true}
                    children={
                        <div className="deleteAlertDiv">
                            Are you sure you want to delete this word
                            translation ?
                            <div style={{ margin: '2em' }}>
                                <button
                                    className="btn btn-themes btn-rounded btn-sec "
                                    onClick={() => this.shdDeleteRec(false)}
                                >
                                    No
                                </button>
                                <button
                                    className="btn btn-themes  btn-rounded deletBtn"
                                    onClick={() => this.shdDeleteRec(true)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    }
                />
                <ConfirmAlert
                    show={isSuccess}
                    handleClose={this.hideAlertModal}
                    children={
                        <div
                            style={
                                isSuccess
                                    ? { padding: '2em', color: 'green' }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {isSuccess ? this.state.message : ''}
                        </div>
                    }
                />
                <ConfirmAlert
                    show={errormsg}
                    handleClose={(event) =>
                        this.hideErrorAlertModal(event, refresh)
                    }
                    children={
                        <div
                            style={
                                errormsg
                                    ? {
                                          padding: '2em',
                                          color: 'red',
                                          fontWeight: '500',
                                      }
                                    : { padding: '2em', color: 'red' }
                            }
                        >
                            {errormsg ? `${errormsg}.` : ''}
                        </div>
                    }
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayWordTranslation);
