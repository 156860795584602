import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import { produce } from 'immer';
import {
    createArticleInfoApi,
    updateArticleInfoApi,
    getBeaconsList,
    getGeofenceList,
    getStoreById,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleSelect from '../../../components/SimpleSelect';
import MultiSelect from '../../../components/MultiSelect';
import ImagePick from '../../../components/ImagePick';
import StatusComp from '../../../components/StatusComp';
import { crudReset } from '../../../actions/actions';
import TenantCode from '../../../components/TenantCode';
import { formatDate, renderBeaconOrGeofenceList } from '../../../helpers/Util';
class AddPromotion extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            showTenantCodeModal: false,
            tenantCode: '',
            tenantCountry: '',
            tenantCity: '',
            tenantCodeDesc: '',
            showTenantCity: false,
            showTenantCode: false,
            // Formrelated
            storeid: '',
            adcode: '',
            title: '',
            subtitle: '',
            desc: '',
            desclong: '',
            locationone: '',
            loconelink: '',
            locationtwo: '',
            loctwolink: '',
            startdate: new Date(),
            expirydate: new Date(),
            beacons: '',
            geofences: '',
            status: '',
            promocode: '',
            notmsg: '',
            sku: '',
            price: '',
            images: {},
            termsandcond: '',
            sortorder: 0,
            language: '',
            storelabel: '',
            // Errors
            storeidError: '',
            titleError: '',
            descError: '',
            skuerror: '',
            priceerror: '',
        };
    }

    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    getCreatePayload = () => {
        const {
            storeid,
            title,
            subtitle,
            desc,
            adcode,
            desclong,
            sku,
            price,
            msg1,
            msg2,
            startdate,
            expirydate,
            beacons,
            termsandcond,
            language,
            sortorder,
            status,
            images,
            promocode,
            notmsg,
        } = this.state;
        let body = {
            title: title,
            language: language,
            storeid: storeid,
            publishmode: 'manual',
            articletype: 'Promotions',
            status: status,
            sortorder: sortorder,
            startdate: formatDate(startdate),
            expirydate: formatDate(expirydate),
            fields: {
                adcode: adcode,
                title: title,
                subtitle: subtitle,
                desc: desc,
                desclong: desclong,
                sku,
                price,
                msg1,
                msg2,
                beacons: beacons.toString(),
                termsandcond: termsandcond,
                language: language,
                promocode,
                notmsg,
            },
            images: {
                promo_img_1: images.promo_img_1,
                promo_img_2: images.promo_img_2,
                promo_img_3: images.promo_img_3,
                promo_img_4: images.promo_img_4,
            },
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createPromotions = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateArticleInfoApi(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(
                    createArticleInfoApi(this.getCreatePayload())
                );
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            storeidError: '',
            skuerror: '',
            priceerror: '',
            titleError: '',
            descError: '',
        });
    };
    validateForm = () => {
        this._clearErrorMessages();
        const { storeid, title, desc, sku, price } = this.state;
        let valid = true;
        if (storeid.trim() === '') {
            this.setState({ storeidError: 'Please enter a store' });
            valid = false;
        }

        if (title.trim() === '') {
            this.setState({ storeidError: 'Please enter a store' });
            valid = false;
        }
        if (desc.trim() === '') {
            this.setState({ descError: 'Please enter a description' });
            valid = false;
        }
        if (sku.trim() === '') {
            this.setState({ skuerror: 'Please enter SKU' });
            valid = false;
        }
        if (price.trim() === '') {
            this.setState({ priceerror: 'Please enter price' });
            valid = false;
        }

        return valid;
    };
    handlestartdateChange = (value) => {
        this.setState({
            startdate: value,
        });
    };
    handleexpirydateChange = (value) => {
        this.setState({
            expirydate: value,
        });
    };
    setBeaconsSelected = (value) => {
        this.setState({
            beacons: value,
        });
    };
    setGeoFenceSelected = (value) => {
        this.setState({
            geofences: value,
        });
    };
    setSelectedFieldValuesForZone = (values) => {
        this.setState({ zonesSelected: values });
    };
    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };
    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createPromotions();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createPromotions}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/promotions"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };
    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    componentWillReceiveProps = () => {
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) this.setState({ storelabel: storeArray.title });
        }
    };

    _renderMainFormDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeidError}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="adcode" className="floatLeft">
                            Promotion Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="adcode"
                            value={this.state.adcode}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.titleError}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="subtitle" className="floatLeft">
                            Sub Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitle"
                            value={this.state.subtitle}
                            onChange={(event) =>
                                this.setState({ subtitle: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="desc" className="floatLeft required">
                            Description
                        </label>
                        <textarea
                            className="form-control"
                            id="desc"
                            value={this.state.desc}
                            onChange={(event) =>
                                this.setState({ desc: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.descError}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="desclong" className="floatLeft">
                            Description Long
                        </label>
                        <textarea
                            className="form-control"
                            id="desclong"
                            value={this.state.desclong}
                            onChange={(event) =>
                                this.setState({ desclong: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderLocationDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sku" className="floatLeft required">
                            SKU
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sku"
                            value={this.state.sku}
                            onChange={(event) =>
                                this.setState({ sku: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.skuerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="price" className="floatLeft required">
                            Price
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="price"
                            value={this.state.price}
                            onChange={(event) =>
                                this.setState({ price: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.priceerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="msg1" className="floatLeft ">
                            Message 1
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="msg1"
                            value={this.state.msg1}
                            onChange={(event) =>
                                this.setState({ msg1: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="msg2" className="floatLeft ">
                            Message 2
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="msg2"
                            value={this.state.msg2}
                            onChange={(event) =>
                                this.setState({ msg2: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderGeneralInformation = () => {
        const { beaconsList, geoFencesList } = this.props.queryData;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="startdate" className="floatLeft">
                            Start Date
                        </label>
                        <DatePicker
                            onChange={this.handlestartdateChange}
                            value={this.state.startdate}
                        />
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="expirydate" className="floatLeft">
                            Expiry Date
                        </label>
                        <DatePicker
                            onChange={this.handleexpirydateChange}
                            value={this.state.expirydate}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="beacons" className="floatLeft">
                            Beacons
                        </label>
                        <MultiSelect
                            filterOptions={renderBeaconOrGeofenceList(
                                beaconsList
                            )}
                            setSelectedFieldValues={this.setBeaconsSelected}
                            id="beacons"
                            itemsSelected={this.state.beacons}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="promocode" className="floatLeft">
                            PromoCode
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="promocode"
                            value={this.state.promocode}
                            onChange={(event) =>
                                this.setState({ promocode: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="notmsg" className="floatLeft">
                            Notification Message
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="notmsg"
                            value={this.state.notmsg}
                            onChange={(event) =>
                                this.setState({ notmsg: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="geofences" className="floatLeft">
                            GeoFences
                        </label>
                        <MultiSelect
                            filterOptions={renderBeaconOrGeofenceList(
                                geoFencesList
                            )}
                            setSelectedFieldValues={this.setGeoFenceSelected}
                            id="geofences"
                            itemsSelected={this.state.geofences}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="termsandcond" className="floatLeft">
                            Terms And Conditions
                        </label>
                        <textarea
                            className="form-control"
                            id="termsandcond"
                            value={this.state.termsandcond}
                            onChange={(event) =>
                                this.setState({
                                    termsandcond: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    setImages = (images) => {
        this.setState({ images: images });
    };
    _renderImageDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Images </div>

                <div className="row">
                    <div className="col-12">
                        <ImagePick
                            setImages={this.setImages}
                            images={this.state.images}
                        ></ImagePick>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderStatusDetails = () => {
        const { languageConfig } = this.props.queryData;
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="sortorder" className="floatLeft">
                            Language
                        </label>
                        <SimpleSelect
                            objArray={languageConfig.pvalues}
                            callbackChangeFilter={(value) => {
                                this.setState({ language: value });
                            }}
                            selectedOption={this.state.language}
                            id="language"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sort Order
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderImageDetails()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec text-center">
                            {this._renderLocationDetails()}
                        </form>
                        <form
                            className="splitFrmDivSec text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderStatusDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };
    componentWillMount = () => {
        this.props.dispatch(
            getBeaconsList({ body: { language: 'en', status: 'New,Active' } })
        );
        this.props.dispatch(
            getGeofenceList({ body: { language: 'en', status: 'New,Active' } })
        );
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            if (rec) {
                const {
                    id,
                    title,
                    status,
                    sortorder,
                    fields,
                    storeid,
                    images,
                    startdate,
                    expirydate,
                } = rec;
                this.props.dispatch(getStoreById(storeid));

                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.status = status ? status : [];
                        draft.sortorder = sortorder ? sortorder : 0;

                        draft.adcode = fields.adcode ? fields.adcode : '';
                        draft.subtitle = fields.subtitle ? fields.subtitle : '';
                        draft.desc = fields.desc ? fields.desc : '';
                        draft.desclong = fields.desclong ? fields.desclong : '';
                        draft.sku = fields.sku ? fields.sku : '';
                        draft.price = fields.price ? fields.price : '';
                        draft.msg1 = fields.msg1 ? fields.msg1 : '';
                        draft.msg2 = fields.msg2 ? fields.msg2 : '';

                        draft.startdate = startdate
                            ? this.toDate(startdate)
                            : new Date();
                        draft.expirydate = expirydate
                            ? this.toDate(expirydate)
                            : new Date();
                        draft.beacons = fields.beacons
                            ? fields.beacons.split(',')
                            : '';
                        draft.geofences = fields.geofences
                            ? fields.geofences.split(',')
                            : '';
                        draft.promocode = fields.promocode
                            ? fields.promocode
                            : '';
                        draft.notmsg = fields.notmsg ? fields.notmsg : '';
                        draft.images = {
                            image1: images.promo_img_1 || '',
                            image2: images.promo_img_2 || '',
                            image3: images.promo_img_3 || '',
                            image4: images.promo_img_4 || '',
                        };
                        draft.termsandcond = fields.termsandcond
                            ? fields.termsandcond
                            : '';
                    })
                );
            }
        }
    };
    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Promotion'
                                    : 'Promotion Registration'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/promotions"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Promotions updated successfully!'
                                    : 'Promotions created successfully!'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddPromotion);
