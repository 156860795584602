import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import { useFormik, Form, Formik, Field } from "formik";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import TimePicker from "rc-time-picker";
import classNames from "classnames";
import {
    storeSearchByFields,
    queryCategoryTree,
    updateSalesPromo,
    createSalesPromo,
    promotionTGroupSearchByFields,
    crudReset,
    createSalesPromoTargetGroup,
} from "../../../actions/actions";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import ReactSwitch from "react-switch";
import Select, { components } from "react-select";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import PromotionBasicInformationInput from "./PromotionBasicInformationInput";
import { formatDate } from "../../../helpers/Util";
import { Messages } from "primereact/messages";
import { Button } from "primereact/button";
import PromoConfirmDialog from "./PromoConfirmDialog";
import PurchaseGroupSelector from "./PurchaseGroupSelector";
import TargetGroupSelector from "./TargetGroupSelector";
import Modal from "react-awesome-modal";

const promoGroupOptions = [
    { name: "By Percentage", id: "Percent" },
    { name: "By Amount", id: "Value" },
    { name: "Buy X Get Y", id: "BuyGet" },
];

const statusConfigOptions = [
    { name: "New", code: "New" },
    { name: "Pending For Approval", code: "PendingApproval" },
];

const priceSegmentOptions = [
    { label: "T1 - Standard Price", value: "T1" },
    { label: "T2 - Special Price", value: "T2" },
    { label: "T3 - Member Price", value: "T3" },
];

const customerTypeOptions = [
    { name: "Guest", id: "Guest" },
    { name: "Member", id: "Member" },
];

const promoTypeOptions = [
    { name: "Item", id: "Item" },
    { name: "Cart", id: "Cart" },
    { name: "RTC", id: "Rtc" },
    { name: "Coupon", id: "Coupon" },
    { name: "Cart Coupon", id: "CartCoupon" },
];

const dayoptions = {
    mon: "monday",
    tue: "tuesday",
    wed: "wednesday",
    thu: "thursday",
    fri: "friday",
    sat: "saturday",
    sun: "sunday",
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const AddSalesPromotionsNew = (props) => {
    const { queryData, history, location } = props;
    const { isSuccess, errormsg, data } = props.crudData;

    const msgs1 = useRef(null);
    const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
    const [storelabel, setStoreLabel] = useState(
        localStorage.getItem("storelabel")
    );

    const [formData, setFormData] = useState({});

    const [activeIndex, setActiveIndex] = useState(0);
    const [isItTaxable, setIsItTaxable] = useState(false);
    const [isItBatch, setIsItBatch] = useState(false);
    const [selectedPriceSegments, setSelectedPriceSegments] = useState({
        label: "T1 - Standard Price",
        value: "T1",
    });
    const [pricesegments, setPricesegments] = useState("T1");
    const [activeDays, setActiveDays] = useState("WholeWeek");
    const [showWholeWeek, setShowWholeWeek] = useState(true);
    const [activedays, setActivedays] = useState([]);
    const [byDays, setByDays] = useState({
        mon_starttime: "00:00",
        mon_endtime: "23:59",
        tue_starttime: "00:00",
        tue_endtime: "23:59",
        wed_starttime: "00:00",
        wed_endtime: "23:59",
        thu_starttime: "00:00",
        thu_endtime: "23:59",
        fri_starttime: "00:00",
        fri_endtime: "23:59",
        sat_starttime: "00:00",
        sat_endtime: "23:59",
        sun_starttime: "00:00",
        sun_endtime: "23:59",
    });
    const [showSpecificDays, setShowSpecificDays] = useState(false);
    const [selectedDays, setSelectedDays] = useState([
        "mon",
        "tue",
        "wed",
        "thu",
        "fri",
        "sat",
        "sun",
    ]);
    const [excludedates, setExcludedates] = useState([]);
    const [excludediscount, setExcludediscount] = useState("N");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [approvalMode, setIsApproval] = useState(false);
    const [payloadData, setPayloadData] = useState(null);
    const [showApproveReject, setShowApproveReject] = useState(false);
    const [openApprovalPrompt, setOpenApprovalPrompt] = useState(false);
    const [openRejectionPrompt, setOpenRejectionPrompt] = useState(false);
    const [approvalmessage, setApprovalmessage] = useState("");
    const [productselection, setProductselection] = useState("");
    const [targetGroupTitle, setTargetGroupTitle] = useState("");
    const [promopurchasecode, setPromopurchasecode] = useState("");
    const [productcodes, setTargetProductcodes] = useState([]);

    //console.log('debug props', props)

    useEffect(() => {
        if (location && location.state) {
            const rec = location.state.item;
            const isEdit = location.state.isEdit;
            const isView = location.state.isView;
            const isViewInEdit = location.state.isViewInEdit;
            const isForApproval = location.state.isForApproval;
            if (rec && rec.id) {
                //console.log("record", rec);
                setRecord(rec);
                setIsViewMode(isView);
                setIsEditMode(isEdit);
                setIsApproval(isForApproval);
                setIsItBatch(rec.discountbatch == "Y" ? true : false);
                setIsItTaxable(rec.taxondiscount == "Y" ? true : false);
                //setShowWholeWeek(rec.activedays.length == 7 ? true : false)
                props.dispatch(
                    promotionTGroupSearchByFields(
                        `storeid=${storeid}&promocode=${rec.promocode}`
                    )
                );
            }
        }
    }, [location]);

    useEffect(() => {
        props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
    }, []);

    useEffect(() => {
        if (isSuccess || data.id) {
            addMessages("success");
        } else if (!isSuccess && errormsg) {
            addMessages("error");
        }
    }, [isSuccess, errormsg, data]);

    const addMessages = (type) => {
        const successMsg = isEditMode
            ? "Sales promotion updated successfully"
            : "Sales promotion created successfully";
        msgs1.current.show([
            {
                sticky: true,
                severity: type,
                summary: "",
                detail: type == "error" ? errormsg : successMsg,
                sticky: true,
            },
        ]);
    };

    const toDate = (dateStr) => {
        let totalStr = dateStr.split(" ");
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            var parts = dateStr.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    const formik = useFormik({
        initialValues: {
            storeid: storeid + "-" + storelabel,
            promogroup: record.promocode
                ? promoGroupOptions.find(
                      (pgroup) => pgroup.id == record.promogroup
                  )
                : "",
            promocode: record.promocode ? record.promocode : "",
            customertype: record.customertype
                ? customerTypeOptions.find(
                      (cust) => cust.id == record.customertype
                  )
                : "",
            promotype: record.promotype
                ? promoTypeOptions.find((promo) => promo.id == record.promotype)
                : "",
            title: record.title ? record.title : "",
            taxondiscount:
                record.taxondiscount && record.taxondiscount == "Y"
                    ? true
                    : false,
            discountvalue: record.discountvalue ? record.discountvalue : "",
            discountcapamt: record.discountcapamt ? record.discountcapamt : "",
            batchvalue: record.discountbatchvalue
                ? record.discountbatchvalue
                : "",
            status: record.status
                ? statusConfigOptions.find(
                      (status) => status.code == record.status
                  )
                : statusConfigOptions.find((status) => status.code == "New"),
            validfrom: record.validfrom ? toDate(record.validfrom) : "",
            validtill: record.validtill ? toDate(record.validtill) : "",
            priority: record.priority ? record.priority : 0,
            priorityweight: record.priorityweight ? record.priorityweight : 0,
            sortorder: record.sortorder ? record.sortorder : 1,
            promotypevalue: record.promotypevalue ? record.promotypevalue : "",
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.promocode) {
                errors.promocode = "promo code is required.";
            }
            if (!data.promogroup) {
                errors.promogroup = "promo group is required.";
            }
            if (!data.promotype) {
                errors.promotype = "promo type is required.";
            }
            if (!data.title) {
                errors.title = "promo name is required.";
            }
            if (!data.discountvalue) {
                errors.discountvalue = "discount value is required.";
            }
            if (!data.discountcapamt) {
                errors.discountcapamt = "discount cap amt is required.";
            }
            if (!data.validfrom) {
                errors.validfrom = "Promo start date is required.";
            }
            if (!data.validtill) {
                errors.validtill = "Promo end date is required.";
            }
            if (
                (data.promotype.id === "Coupon" ||
                    data.promotype.id === "CartCoupon") &&
                data.promotypevalue.trim() === ""
            ) {
                errors.promotypevalue = "Please enter promo type value";
            }
            return errors;
        },
        onSubmit: (values) => {
            setFormData(values);
            const data = getCreatePayload(values);
            if (data.body.promotype == "Item") {
                const targetData = getTargetgroupCreatePayload(values);
                handleItemTypeSubmit(data, targetData);
            } else {
                handleSubmit(data);
            }
        },
    });

    const handleSubmit = (data) => {
        if (data.body.status == "PendingApproval") {
            setPayloadData(data);
            setShowConfirmDialog(true);
        } else if (isEditMode && record.id) {
            props.dispatch(updateSalesPromo(data));
        } else {
            props.dispatch(createSalesPromo(data));
        }
    };

    const handleItemTypeSubmit = (data, targetData) => {
        if (isEditMode && record.id) {
            if (data.body.status == "PendingApproval") {
                setPayloadData(data);
                setShowConfirmDialog(true);
            } else {
                props.dispatch(updateSalesPromo(data));
            }
        } else {
            props.dispatch(createSalesPromo(data));
            props.dispatch(createSalesPromoTargetGroup(targetData));
        }
    };

    const handleConfirm = () => {
        const data = getCreatePayload(formik.values);
        if (isEditMode && payloadData.body.id) {
            props.dispatch(updateSalesPromo(data));
        } else {
            props.dispatch(createSalesPromo(data));
        }
        setShowConfirmDialog(false);
    };

    const getTargetgroupCreatePayload = (values) => {
        let body = {
            storeid,
            title: targetGroupTitle,
            productcodes: productcodes.length > 0 ? productcodes.join(",") : "",
            promopurchasecode,
            productselection: productselection.id ? productselection.id : "",
            promocode: values.promocode ? values.promocode : "",
            // sortorder,
            // status
        };

        // if (this._isEditMode()) {
        //     body["id"] = this.state.id;
        // }
        const payload = {
            body: body,
        };
        return payload;
    };

    const getCreatePayload = (values) => {
        let body = {
            promocode: values.promocode ? values.promocode : "",
            storeid: storeid,
            promogroup: values.promogroup ? values.promogroup.id : "",
            title: values.title,
            promotype: values.promotype.id ? values.promotype.id : "",
            promotypevalue: values.promotypevalue,
            taxondiscount: isItTaxable ? "Y" : "N",
            discountvalue: values.discountvalue,
            discountcapamt: values.discountcapamt,
            discountbatch: isItBatch ? "Y" : "N",
            discountbatchvalue: isItBatch ? values.batchvalue : 0,
            customertype: values.customertype.id ? values.customertype.id : "",
            status: values.status.code ? values.status.code : "",
            validfrom: formatDate(values.validfrom),
            validtill: formatDate(values.validtill),
            activedays: _getActiveDays(),
            closedates: excludedates.length > 0 ? excludedates.join(",") : "",
            pricesegments: pricesegments,
            priority: values.priority,
            priorityweight: values.priorityweight,
            sortorder: values.sortorder,
            //additionalfields: this._getAdditionalFields(),
            excludediscount,
        };

        // if (this._isEditMode() || this._isApprovalMode()) {
        //     body["id"] = this.state.id;
        // }
        // if (promocode.trim().length <= 0) {
        //     delete body["promocode"]
        // }
        if (isEditMode) {
            body["id"] = record.id;
        }
        const payload = {
            body: body,
        };

        return payload;
    };

    const _getActiveDays = () => {
        if (selectedDays.length <= 0) {
            return [];
        }
        const activeDays = selectedDays.map((e) => ({
            day: e,
            opentime: getSelectedTime(e, "start"),
            closetime: getSelectedTime(e, "end"),
        }));
        return activeDays;
    };

    const getSelectedTime = (day, startOrEnd) => {
        return byDays[day + "_" + startOrEnd + "time"].replace(":", "");
    };

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const _saveExcludedDates = (e) => {
        if (
            excludedates.length > 0 &&
            excludedates.includes(formatDate(e.target.value))
        ) {
            return;
        }
        setExcludedates([...excludedates, formatDate(e.target.value)]);
    };

    const _renderExcludedDates = () => {
        if (excludedates.length <= 0) {
            return;
        }

        var contentareastyle = {
            display: "inline-flex",
            flexFlow: "wrap",
            border: "1px solid #dcdcdc",
            padding: "10px",
            overflowY: "scroll",
            maxHeight: "150px",
        };

        return (
            <div className="row" style={{ marginTop: "0.6em" }}>
                <div style={contentareastyle}>
                    {excludedates.map((s) => {
                        return (
                            <div
                                style={{
                                    padding: "2px 2px",
                                    margin: "2px 2px",
                                    borderRadius: "4px",
                                    background: "#347bf6",
                                }}
                                key={s}
                            >
                                <label style={{ color: "white" }}>{s}</label>
                                {!_isViewInEditMode() && (
                                    <img
                                        title="delete"
                                        className="tableImage"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
                                        onClick={(e) =>
                                            _removeExcludedDates(e, s)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const handleDiscountExcludeSwitch = (isExcluded) => {
        setExcludediscount(isExcluded ? "Y" : "N");
    };

    const _removeExcludedDates = (e, s) => {
        if (e) {
            e.preventDefault();
        }
        setExcludedates(excludedates.filter((e) => e != s));
    };

    const handleStepChange = (step) => {
        setActiveIndex(step);
    };

    const handlePriceSegmentsChange = (selected) => {
        setSelectedPriceSegments(selected);
        if (selected) {
            setPricesegments(selected.flatMap((e) => e.value).join(","));
        } else {
            setPricesegments("");
        }
    };

    const renderSideColumn = () => {
        return (
            <div className="col-md-3 col-sm-12">
                <Card style={{ marginBottom: "2em" }}>
                    <div className="formgrid grid">
                        <div className="field col flex flex-column">
                            <label className="w-100" htmlFor="status">
                                Status
                            </label>
                            {!isViewMode && (
                                <Dropdown
                                    className="w-100"
                                    id="status"
                                    name="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    options={statusConfigOptions}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                            )}
                            {isViewMode && (
                                <InputText
                                    id="storeid"
                                    type="text"
                                    value={record.status}
                                    disabled
                                />
                            )}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col flex flex-column">
                            <label htmlFor="status">Price Segments</label>
                            {!isViewMode && (
                                <Select
                                    options={priceSegmentOptions}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                    }}
                                    onChange={handlePriceSegmentsChange}
                                    allowSelectAll={true}
                                    value={selectedPriceSegments}
                                />
                            )}
                            {isViewMode && (
                                <InputText
                                    id="storeid"
                                    type="text"
                                    value={record.pricesegments}
                                    disabled
                                />
                            )}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col flex flex-column">
                            <label htmlFor="priority">Priority</label>
                            <InputText
                                id="priority"
                                type="number"
                                value={formik.values.priority}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col flex flex-column">
                            <label htmlFor="storeid" className="w-100 mb-12">
                                Exclude Discount
                            </label>
                            <ReactSwitch
                                checked={excludediscount === "Y"}
                                onChange={handleDiscountExcludeSwitch}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="discount-tax-switch"
                                disabled={isViewMode}
                            />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col flex flex-column">
                            <label htmlFor="priorityweight">Weightage</label>
                            <InputText
                                id="priorityweight"
                                type="number"
                                value={formik.values.priorityweight}
                                onChange={formik.handleChange}
                                disabled={isViewMode}
                            />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col flex flex-column">
                            <label htmlFor="sortorder">Sort Order</label>
                            <InputText
                                id="sortorder"
                                type="number"
                                value={formik.values.sortorder}
                                onChange={formik.handleChange}
                                disabled={isViewMode}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        );
    };

    const handleChange = (e) => {
        setActiveIndex(e.index);
    };

    const handleTaxDiscountChange = () => setIsItTaxable(!isItTaxable);

    const _renderApproveOrRejectSalesPromoPrompt = () => {
        return (
            <Modal
                visible={
                    (showApproveReject && openApprovalPrompt) ||
                    openRejectionPrompt
                }
                width="40%"
                height="40%"
                effect="fadeInRight"
                onClickAway={() => {
                    setShowApproveReject(false);
                }}
            >
                <div className="modalForm storeSearchModalForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            <div className="row sub-title">
                                Leave your comment
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea
                                className="form-control"
                                id="enteredProducts"
                                value={approvalmessage}
                                onChange={(event) =>
                                    setApprovalmessage(event.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        {_renderApproveOrRejectActions(openApprovalPrompt)}
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderApproveOrRejectActions = (approve) => {
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={(e) => {
                        setShowApproveReject(false);
                        _approveOrRejectSalesPromo(e, approve);
                    }}
                >
                    {approve ? `Approve` : `Reject`}
                </button>

                <button
                    type="submit"
                    className="btn btn-themes btn-rounded"
                    onClick={(e) => {
                        setShowApproveReject(false);
                    }}
                >
                    Cancel
                </button>
            </React.Fragment>
        );
    };

    const _approveOrRejectSalesPromo = (e, approve) => {
        if (e) {
            e.preventDefault();
        }
        let status = formik.values.status ? formik.values.status.code : "";
        if (approve) {
            status = "Approved";
        } else {
            status = "Rejected";
        }
        const data = getCreatePayload(formik.values);
        const payload = { body: { ...data.body, status } };
        props.dispatch(updateSalesPromo(payload));
    };

    const _approveOrRejectSalesPromoPrompt = (e, approve) => {
        if (e) {
            e.preventDefault();
        }
        setOpenApprovalPrompt(approve);
        setOpenRejectionPrompt(!approve);
        setShowApproveReject(true);
    };

    const renderMainButton = () => {
        if (isViewMode) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/sales-promotions"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        if (approvalMode) {
            return (
                <React.Fragment>
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            _approveOrRejectSalesPromoPrompt(e, true);
                        }}
                    >
                        Approve
                    </button>

                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            _approveOrRejectSalesPromoPrompt(e, false);
                        }}
                    >
                        Reject
                    </button>

                    <Link
                        to="/ui/sales-promotions"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                </React.Fragment>
            );
        }

        //const isEditMode = false;
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/sales-promotions"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    const handleBatchChange = () => {
        setIsItBatch(!isItBatch);
    };

    const _renderForWholeWeek = () => {
        if (!showWholeWeek) {
            return;
        }

        let days = Object.values(dayoptions);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-sm-3 label2">Day</div>
                    <div className="col-lg-3 col-sm-3 label2">Start Time</div>
                    <div className="col-lg-3 col-sm-3 label2">End Time</div>
                </div>
                {days.map((elem) => (
                    <div className="row" id={elem} key={elem}>
                        <div className="col-lg-1 col-sm-3 floatLeft">
                            <input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            {elem}
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        byDays[
                                            elem.substring(0, 3) + "_starttime"
                                        ],
                                        "HH:mm"
                                    ).isValid()
                                        ? moment(
                                              byDays[
                                                  elem.substring(0, 3) +
                                                      "_starttime"
                                              ],
                                              "HH:mm"
                                          )
                                        : ""
                                }
                                name={elem + "_starttime"}
                                onChange={(time) =>
                                    handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + "_starttime"
                                    )
                                }
                                showSecond={false}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        byDays[
                                            elem.substring(0, 3) + "_endtime"
                                        ],
                                        "HH:mm"
                                    ).isValid()
                                        ? moment(
                                              byDays[
                                                  elem.substring(0, 3) +
                                                      "_endtime"
                                              ],
                                              "HH:mm"
                                          )
                                        : ""
                                }
                                name={elem + "_endtime"}
                                onChange={(time) =>
                                    handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + "_endtime"
                                    )
                                }
                                showSecond={false}
                            />
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    const handleTimePickerChange = (time, name) => {
        let timeformat = moment(time).format("HH:mm");
        setByDays({ ...byDays, [name]: timeformat });
        _updateActiveDays(name, timeformat);
    };

    const _updateActiveDays = (name, timeformat) => {
        var activeDay = activedays.filter(
            (e) => e.day === name.substring(0, 3)
        );
        if (activeDay) {
            if (name === activeDay.day + "_starttime") {
                activeDay.opentime = timeformat;
            } else if (name === activeDay.day + "_endtime") {
                activeDay.closetime = timeformat;
            }
        } else {
            activeDay = {};
            activeDay.day = name.substring(0, 3);
            if (name === activeDay.day + "_starttime") {
                activeDay.opentime = timeformat;
            } else if (name === activeDay.day + "_endtime") {
                activeDay.closetime = timeformat;
            }
        }
    };

    const handleActiveDaysSelection = (e) => {
        setActiveDays(e.target.value);
        if (e.target.value === "WholeWeek") {
            setShowWholeWeek(true);
            setShowSpecificDays(false);
            setSelectedDays(["mon", "tue", "wed", "thu", "fri", "sat", "sun"]);
        } else {
            setShowWholeWeek(false);
            setShowSpecificDays(true);
        }
    };

    const handleSelectDays = (e, elem) => {
        if (selectedDays.includes(elem)) {
            if (!e.target.checked) {
                setSelectedDays(selectedDays.filter((e) => e !== elem));
            }
        } else {
            if (e.target.checked) {
                setSelectedDays([...selectedDays, elem]);
            }
        }
    };

    const _isViewInEditMode = () => {
        return false;
    };

    const _renderFoSpecificDays = () => {
        if (!showSpecificDays) {
            return;
        }

        let days = Object.values(dayoptions);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-sm-4 label2">Day</div>
                    <div className="col-lg-3 col-sm-3 label2">Start Time</div>
                    <div className="col-lg-3 col-sm-3 label2">End Time</div>
                </div>
                {days.map((elem) => (
                    <div className="row" key={elem}>
                        <div className="col-lg-1 col-sm-1 floatLeft">
                            <input
                                type="checkbox"
                                checked={selectedDays.includes(
                                    elem.substring(0, 3)
                                )}
                                onChange={(e) =>
                                    handleSelectDays(e, elem.substring(0, 3))
                                }
                                disabled={_isViewInEditMode()}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            {elem}
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        byDays[
                                            elem.substring(0, 3) + "_starttime"
                                        ],
                                        "HH:mm"
                                    ).isValid()
                                        ? moment(
                                              byDays[
                                                  elem.substring(0, 3) +
                                                      "_starttime"
                                              ],
                                              "HH:mm"
                                          )
                                        : ""
                                }
                                name={elem + "_starttime"}
                                onChange={(time) =>
                                    handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + "_starttime"
                                    )
                                }
                                showSecond={false}
                                disabled={_isViewInEditMode()}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 floatLeft">
                            <TimePicker
                                value={
                                    moment(
                                        byDays[
                                            elem.substring(0, 3) + "_endtime"
                                        ],
                                        "HH:mm"
                                    ).isValid()
                                        ? moment(
                                              byDays[
                                                  elem.substring(0, 3) +
                                                      "_endtime"
                                              ],
                                              "HH:mm"
                                          )
                                        : ""
                                }
                                name={elem + "_endtime"}
                                onChange={(time) =>
                                    handleTimePickerChange(
                                        time,
                                        elem.substring(0, 3) + "_endtime"
                                    )
                                }
                                showSecond={false}
                                disabled={_isViewInEditMode()}
                            />
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    const handleRemoveMessage = () => {
        if (isSuccess || data.id) {
            props.dispatch(crudReset());
            history.push("/ui/sales-promotions", { replace: true });
        }
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => setShowConfirmDialog(false)}
                    className="p-button-text"
                />
                <Button
                    label="Confirm"
                    icon="pi pi-check"
                    onClick={handleConfirm}
                    autoFocus
                />
            </div>
        );
    };

    const rename = (obj) => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                if (obj[prop].length > 0) {
                    obj.showCheckbox = false;
                }
                obj[prop] = obj[prop].map(rename);
            }

            if (prop === "categorycode") {
                obj.value = obj[prop];
                obj.icon = "";
            } else if (prop === "title") {
                obj.label = obj[prop];
                obj["subtitle"] = obj["categorycode"];
            } else if (prop === "subcategories") {
                obj.children = obj[prop];
            }
            delete obj[prop];
        }

        return obj;
    };

    const getNodes = () => {
        const { categoryTree } = props.queryData;
        let lCategoryTree = JSON.parse(JSON.stringify(categoryTree));
        const nodes = [lCategoryTree].map(rename);
        return nodes;
    };

    return (
        <React.Fragment>
            <div className="form_height">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row ph-20 pv-20">
                        <div className="col-md-8 col-sm-12 noPadding noMargin">
                            <Accordion
                                activeIndex={activeIndex}
                                onTabChange={(e) => handleChange(e)}
                            >
                                <AccordionTab header="Basic Information">
                                    <PromotionBasicInformationInput
                                        formik={formik}
                                        isFormFieldValid={isFormFieldValid}
                                        getFormErrorMessage={
                                            getFormErrorMessage
                                        }
                                        isItTaxable={isItTaxable}
                                        handleTaxDiscountChange={
                                            handleTaxDiscountChange
                                        }
                                        handleBatchChange={handleBatchChange}
                                        handleStepChange={handleStepChange}
                                        isItBatch={isItBatch}
                                        isViewMode={isViewMode}
                                    />
                                </AccordionTab>
                                <AccordionTab header="Promotion Period">
                                    <div className="pv-20 ph-20">
                                        <div className="row">
                                            <div className="p-fluid formgrid grid w-100">
                                                <div className="field col-lg-6 col-sm-12">
                                                    <div className="gray-box">
                                                        <h6>
                                                            Promotion Period
                                                        </h6>
                                                        <div className="p-fluid formgrid grid">
                                                            <div className="field col-6 md:col-2">
                                                                <label
                                                                    htmlFor="validfrom"
                                                                    className={classNames(
                                                                        {
                                                                            "p-error":
                                                                                isFormFieldValid(
                                                                                    "validfrom"
                                                                                ),
                                                                        }
                                                                    )}
                                                                >
                                                                    Start Date*
                                                                </label>
                                                                {isViewMode && (
                                                                    <InputText
                                                                        id="validfrom"
                                                                        type="text"
                                                                        value={moment(
                                                                            formik
                                                                                .values
                                                                                .validfrom
                                                                        ).format(
                                                                            "MM-DD-YYYY"
                                                                        )}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        disabled={
                                                                            isViewMode
                                                                        }
                                                                    />
                                                                )}
                                                                {!isViewMode && (
                                                                    <Calendar
                                                                        id="validfrom"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .validfrom
                                                                        }
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        dateFormat="mm-dd-yy"
                                                                        showIcon
                                                                    />
                                                                )}
                                                                {getFormErrorMessage(
                                                                    "validfrom"
                                                                )}
                                                            </div>
                                                            <div className="field col-6 md:col-2">
                                                                <label
                                                                    htmlFor="validtill"
                                                                    className={classNames(
                                                                        {
                                                                            "p-error":
                                                                                isFormFieldValid(
                                                                                    "validtill"
                                                                                ),
                                                                        }
                                                                    )}
                                                                >
                                                                    End Date*
                                                                </label>
                                                                {isViewMode && (
                                                                    <InputText
                                                                        id="validfrom"
                                                                        type="text"
                                                                        value={moment(
                                                                            formik
                                                                                .values
                                                                                .validtill
                                                                        ).format(
                                                                            "MM-DD-YYYY"
                                                                        )}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        disabled={
                                                                            isViewMode
                                                                        }
                                                                    />
                                                                )}
                                                                {!isViewMode && (
                                                                    <Calendar
                                                                        id="validtill"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .validtill
                                                                        }
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        dateFormat="mm-dd-yy"
                                                                        showIcon
                                                                    />
                                                                )}

                                                                {getFormErrorMessage(
                                                                    "validtill"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field col-lg-6 col-sm-12">
                                                    <div className="gray-box">
                                                        <h6>
                                                            Promotion Exclude
                                                            Date
                                                        </h6>
                                                        <div className="p-fluid formgrid grid">
                                                            <div className="field col-6 md:col-2">
                                                                <label htmlFor="excludedates"></label>
                                                                <Calendar
                                                                    id="validfrom"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        _saveExcludedDates(
                                                                            e
                                                                        );
                                                                    }}
                                                                    dateFormat="mm-dd-yy"
                                                                    showIcon
                                                                    disabled={
                                                                        isViewMode
                                                                    }
                                                                />
                                                                {_renderExcludedDates()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-sm-12">
                                                <h6
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    Active Days
                                                </h6>
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label
                                                            htmlFor="wholeweek"
                                                            className="floatLeft"
                                                        >
                                                            <input
                                                                type="radio"
                                                                value="WholeWeek"
                                                                onChange={(e) =>
                                                                    handleActiveDaysSelection(
                                                                        e
                                                                    )
                                                                }
                                                                checked={
                                                                    activeDays ===
                                                                    "WholeWeek"
                                                                }
                                                                disabled={
                                                                    isViewMode
                                                                }
                                                            />
                                                            Whole Week
                                                        </label>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label
                                                            htmlFor="specificdays"
                                                            className="floatLeft"
                                                        >
                                                            <input
                                                                type="radio"
                                                                value="SpecificDays"
                                                                onChange={(e) =>
                                                                    handleActiveDaysSelection(
                                                                        e
                                                                    )
                                                                }
                                                                checked={
                                                                    activeDays ===
                                                                    "SpecificDays"
                                                                }
                                                                disabled={
                                                                    isViewMode
                                                                }
                                                            />
                                                            Specific Days
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <br /> */}
                                                {_renderForWholeWeek()}
                                                {_renderFoSpecificDays()}
                                            </div>
                                        </div>
                                        {!isViewMode && formik.values.promotype.id == "Item" && (
                                            <div className="row">
                                                <div className="col-6 alignRight alignWithPageHeader ml-auto ph-16">
                                                    <Button
                                                        type="button"
                                                        className={classNames({
                                                            "p-button-success":
                                                                formik.values
                                                                    .validfrom &&
                                                                formik.values
                                                                    .validtill,
                                                            "p-button-secondary":
                                                                !formik.values
                                                                    .validfrom ||
                                                                !formik.values
                                                                    .validtill,
                                                        })}
                                                        label="Next"
                                                        onClick={() =>
                                                            handleStepChange(2)
                                                        }
                                                        disabled={
                                                            !formik.values
                                                                .validfrom ||
                                                            !formik.values
                                                                .validtill
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </AccordionTab>
                                {formik.values.promotype &&
                                    formik.values.promotype.id == "Item" && (
                                        <AccordionTab header="Purchase Group">
                                            <PurchaseGroupSelector
                                                storeid={storeid}
                                                storelabel={storelabel}
                                                isViewMode={isViewMode}
                                                formik={formik}
                                                handleStepChange={
                                                    handleStepChange
                                                }
                                                isFormFieldValid={
                                                    isFormFieldValid
                                                }
                                                getFormErrorMessage={
                                                    getFormErrorMessage
                                                }
                                            />
                                        </AccordionTab>
                                    )}

                                {formik.values.promotype &&
                                    formik.values.promotype.id == "Item" && (
                                        <AccordionTab header="Target Group">
                                            <TargetGroupSelector
                                                storeid={storeid}
                                                storelabel={storelabel}
                                                nodes={getNodes()}
                                                productselection={
                                                    productselection
                                                }
                                                setProductselection={
                                                    setProductselection
                                                }
                                                targetGroupTitle={
                                                    targetGroupTitle
                                                }
                                                setTargetGroupTitle={
                                                    setTargetGroupTitle
                                                }
                                                setPromopurchasecode={
                                                    setPromopurchasecode
                                                }
                                                setTargetProductcodes={
                                                    setTargetProductcodes
                                                }
                                                isViewMode={isViewMode}
                                                isEditMode={isEditMode}
                                            />
                                        </AccordionTab>
                                    )}
                            </Accordion>
                        </div>
                        {renderSideColumn()}
                    </div>

                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {renderMainButton()}
                        </div>
                    </div>
                </form>
                <div className="msg-container">
                    <Messages ref={msgs1} onRemove={handleRemoveMessage} />
                </div>
                {!isViewMode && payloadData && (
                    <PromoConfirmDialog
                        showConfirmDialog={showConfirmDialog}
                        renderFooter={renderFooter}
                        setShowConfirmDialog={setShowConfirmDialog}
                        isItTaxable={isItTaxable}
                        isItBatch={isItBatch}
                        pricesegments={selectedPriceSegments}
                        excludediscount={excludediscount}
                        activeDays={activeDays}
                        payloadData={payloadData}
                        queryData={props.queryData}
                        storeid={storeid}
                        dispatch={props.dispatch}
                    />
                )}
                {_renderApproveOrRejectSalesPromoPrompt()}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddSalesPromotionsNew);
