import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import {
    crudReset,
    updateStoreTag,
    createStoreTag,
    queryPropertyConfigByFields,
    storeDevicesSearchByFields,
    searchGeneral,
} from '../../../../actions/actions';

import ConfirmAlert from '../../../../components/ConfirmAlert';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import { produce } from 'immer';
import {
    SEARCH_PROP_CONFIG_TENDERTAGTYPES,
    SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE,
    SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
    TENDERTAGTYPES,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
    SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
} from '../../../../constants/constants';
import SimpleImageInput from '../../../../components/SimpleImageInput';
import ReceiptLineConfig from '../../../../components/ReceiptLineConfig';
import SimpleSwitch from '../../../../components/SimpleSwitch';
import TaxReportHeaderConfig from '../common/TaxReportHeaderConfig';
import AdditionalFieldSetting from '../common/AdditionalFieldSetting';
import { Divider } from 'primereact/divider';
import DynamicFileInput from '../common/DynamicFileInput';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

const PresetAddtionalFields = {
    NINE_SAP_CONFIG: { cardcode: '', cardname: '' },
    taxreporttemplate: {
        daily_en: '/templates/taxreport/daily_en.ftl',
        daily_th: '/templates/taxreport/daily_th.ftl',
        monthly_en: '/templates/taxreport/monthly_en.ftl',
        monthly_th: '/templates/taxreport/monthly_th.ftl',
    },
    topupreporttemplate: {
        topup_en: '/templates/services/topup/topup_en.ftl',
        topup_th: '/templates/services/topup/topup_th.ftl',
    },
};

class AddStoreOptions extends React.PureComponent {
    editModeStatus = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'InActive', id: 'InActive' },
    ];

    tenderGroupOptions = [
        { name: 'Select', id: '' },
        { name: 'cash', id: 'cash' },
        { name: 'rabbit', id: 'rabbit' },
        { name: 'bbl', id: 'bbl' },
    ];

    quicklookuptemplatetypes = [
        { name: 'Select', id: '' },
        { name: 'With Item Image', id: 'template1' },
        { name: 'Without Item Image', id: 'template2' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: '',
            terminalid: '',
            title: '',
            taggroup: 'storeprops',
            tagtype: '', //to remove empty string back
            tenderTagTypeOptions: {},
            tagtypevalue: '',
            tendergroup: '',
            storedevices: [],
            deviceid: '',

            status: 'New',
            sortorder: 1,
            additionalFields: {},
            storelabel: '',
            // Errors
            titleerror: '',
            tenderdescerror: '',
            storeiderror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tagtypevalueerror: '',
            returntypeerror: '',
            tendergrouperror: '',
            companynameerror: '',

            tender: false,
            tenderdesc: '',
            opencashdrawer: false,
            loan: false,
            pickup: false,
            returntender: false,
            voidtender: false,
            minamount: 0,
            maxamount: 0,
            returntype: '',
            autopickup: false,
            tenderimg: '',
            tenderinstruction: '',
            tenderanimationimg: '',
            storecashinout: false,
            cashcount: false,
            mgrintervention: false,
            offlinetender: false,

            //store default props
            autolocktimeout: 0,
            paymenttimeout: 0,
            quicklookupcatcode: '',
            quicklookupshowalways: true,
            allowitemreturn: true,
            quicklookuptemplatetype: '',
            quicklookupitemscount: 1,
            productplaceholderimg: '',
            promotionprefix: 'COP,RTC',
            ccvcouponcode: '',
            ccvrulecode: '',
            rabbittandcarticlecode: '',

            //receiptheader || receiptfooter || invoiceheader props
            receiptlines: [{ text: '', alignment: 'L', id: 0 }],
            invoiceheaderlogo: '',
            companyname: '',
            rabbitTagTypeOptions: [],
            tagTypeOptions: [],
            sco: null
        };
    }

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.receiptlines.length;
        let lastele = this.state.receiptlines[rowLength - 1];
        const item = {
            text: '',
            alignment: 'L',
            id: lastele.id + 1,
        };
        this.setState(
            {
                receiptlines: [...this.state.receiptlines, item],
            },
            () => {
                console.log('Rows After Add', this.state.receiptlines);
            },
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]: event.target.value,
        };

        this.setState(
            produce((draft) => {
                if (draft.receiptlines.length === 0) {
                    draft.receiptlines.push(obj);
                } else {
                    let found = false;
                    draft.receiptlines.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.receiptlines.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    handleAlignmentChange = (value, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]: value,
        };

        this.setState(
            produce((draft) => {
                if (draft.receiptlines.length === 0) {
                    draft.receiptlines.push(obj);
                } else {
                    let found = false;
                    draft.receiptlines.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.receiptlines.push(obj);
                    }
                }
            }),
            () => {},
        );
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.receiptlines.length;

        if (rowLength > 1) {
            const updateValues = this.state.receiptlines.filter(
                (value) => rowId !== value.id,
            );

            this.setState({ receiptlines: updateValues });
        }
    };

    handleQuickLookupShowAlwaysSwitch = (quicklookupshowalways) => {
        this.setState({ quicklookupshowalways });
    };

    handleAllowItemReturnSwitch = (allowitemreturn) => {
        this.setState({ allowitemreturn });
    };

    handleTenderSwitch = (tender) => {
        this.setState({ tender });
    };

    handleLoanSwitch = (loan) => {
        this.setState({ loan });
    };

    handlePickupSwitch = (pickup) => {
        this.setState({
            pickup: pickup.target.value === 'Pickup',
            autopickup: pickup.target.value === 'AutoPickup',
        });
    };

    handleAutoPickupSwitch = (autopickup) => {
        this.setState(
            {
                autopickup: autopickup.target.value === 'AutoPickup',
                pickup: autopickup.target.value === 'Pickup',
            },
            () => {
                if (this.state.autopickup) {
                    //reset
                    this.setState({
                        loan: false,
                        cashcount: false,
                        storecashinout: false,
                    });
                }
            },
        );
    };

    handleReturnTenderSwitch = (returntender) => {
        this.setState({ returntender });
    };

    handleVoidTenderSwitch = (voidtender) => {
        this.setState({ voidtender });
    };

    handleOpenCashdrawerSwitch = (opencashdrawer) => {
        this.setState({ opencashdrawer });
    };

    handleReturnTypeSelection = (e) => {
        this.setState({ returntype: e.target.value });
    };

    handleStoreCashInOutSwitch = (storecashinout) => {
        this.setState({ storecashinout });
    };

    handleOfflineTenderSwitch = (offlinetender) => {
        this.setState({ offlinetender });
    };

    handleMgrInterventionSwitch = (mgrintervention) => {
        this.setState({ mgrintervention });
    };

    handleCashCountSwitch = (cashcount) => {
        this.setState({ cashcount });
    };

    getAdditionalFields = () => {
        const { tagtype, additionalFields } = this.state;

        if (tagtype === 'tender') {
            return {
                tenderdesc: this.state.tenderdesc,
                tender: this.state.tender ? 'Y' : 'N',
                voidtender: this.state.voidtender ? 'Y' : 'N',
                loan: this.state.loan ? 'Y' : 'N',
                pickup: this.state.pickup ? 'Y' : 'N',
                autopickup: this.state.autopickup ? 'Y' : 'N',
                opencashdrawer: this.state.opencashdrawer ? 'Y' : 'N',
                returntender: this.state.returntender ? 'Y' : 'N',
                returntype: this.state.returntype,
                minamount: this.state.minamount,
                maxamount: this.state.maxamount,
                tenderimg: this.state.tenderimg,
                tenderinstruction: this.state.tenderinstruction,
                tenderanimationimg: this.state.tenderanimationimg,
                tendergroup: this.state.tendergroup,
                offlinetender: this.state.offlinetender ? 'Y' : 'N',
                cashcount: this.state.cashcount ? 'Y' : 'N',
                mgrintervention: this.state.mgrintervention ? 'Y' : 'N',
                storecashinout: this.state.storecashinout ? 'Y' : 'N',
                deviceid: this.state.deviceid,
            };
        } else if (tagtype === 'storeprops') {
            return {
                autolocktimeout: this.state.autolocktimeout,
                paymenttimeout: this.state.paymenttimeout,
                quicklookupcatcode: this.state.quicklookupcatcode,
                quicklookupshowalways: this.state.quicklookupshowalways
                    ? 'Y'
                    : 'N',
                allowitemreturn: this.state.allowitemreturn ? 'Y' : 'N',
                quicklookuptemplatetype: this.state.quicklookuptemplatetype,
                quicklookupitemscount: this.state.quicklookupitemscount,
                productplaceholderimg: this.state.productplaceholderimg,
                promotionprefix: this.state.promotionprefix,
                ccvrulecode: this.state.ccvrulecode,
                ccvcouponcode: this.state.ccvcouponcode,
                rabbittandcarticlecode: this.state.rabbittandcarticlecode,
                //sco: JSON.stringify(this.state.sco)
                sco: this.state.sco
            };
        } else if (
            tagtype === 'receiptheader' ||
            tagtype === 'receiptfooter' ||
            tagtype === 'invoiceheader' ||
            tagtype === 'topupreceiptheader' ||
            tagtype === 'topupreceiptfooter'
        ) {
            var response = {};
            if (
                this.state.receiptlines.length === 1 &&
                this.state.receiptlines[0].text === ''
            ) {
                // return null
            } else {
                const lines = this.state.receiptlines.map((e) => {
                    return { alignment: e.alignment, text: e.text };
                });
                response.lines = lines;
            }
            response.companyname = this.state.companyname;
            response.logo = this.state.invoiceheaderlogo;
            return response;
        } else if (
            tagtype === 'taxreportheader' ||
            tagtype === 'topupreportheader' ||
            tagtype === 'taxreporttemplate' ||
            tagtype === 'NINE_SAP_CONFIG' ||
            tagtype === 'topupreporttemplate'
        ) {
            return additionalFields;
        } else {
            return null;
        }
    };

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
        } = this.state;

        let body = {
            storeid,
            terminalid,
            title,
            taggroup,
            tagtype,
            tagtypevalue,
            status,
            additionalfields: this.getAdditionalFields(),
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };

        return payload;
    };

    _saveStoreConfig = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreTag(this.getCreatePayload(), this.state.id),
                );
            } else {
                this.props.dispatch(createStoreTag(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            taggrouperror: '',
            tagtypeerror: '',
            tagtypevalueerror: '',
            tenderdescerror: '',
            tendergrouperror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title,
            storeid,
            taggroup,
            tagtype,
            tagtypevalue,
            returntender,
            returntype,
            tenderdesc,
            tendergroup,
            companyname,
        } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'Please enter storeid' });
            valid = false;
        }
        if (taggroup.trim() === '') {
            this.setState({ taggrouperror: 'Please select the tag group' });
            valid = false;
        }

        if (tagtype.trim().length <= 0) {
            this.setState({ tagtypeerror: 'Please select tag type' });
            valid = false;
        }

        if (tagtype.trim() === 'tender') {
            if (tagtypevalue.trim().length <= 0) {
                this.setState({
                    tagtypevalueerror: 'Please select tag type value',
                });
                valid = false;
            }
            if (tenderdesc.trim().length <= 0) {
                this.setState({
                    tenderdescerror: 'Please enter the tender description',
                });
            }
            if (tendergroup.trim().length <= 0) {
                this.setState({ tendergrouperror: 'Please select tag group' });
            }
        }

        if (returntender) {
            if (returntype === '') {
                this.setState({ returntypeerror: 'Please select return type' });
                valid = false;
            }
        }

        if (
            tagtype.trim() === 'invoiceheader' ||
            tagtype.trim() === 'receiptheader' ||
            tagtype.trim() === 'topupreceiptheader'
        ) {
            if (companyname === '') {
                this.setState({
                    companynameerror: 'Please enter the company name',
                });
                valid = false;
            }
        }

        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <>
                    <Link
                        to="/ui/storeoptions"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._saveStoreConfig(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._saveStoreConfig(e)}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/storeoptions"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    handleChangeTagType = (value) => {
        const nonTagTypeValueList = ['NINE_SAP_CONFIG', 'rabbitpointrule', 'Splash/logo'];
        this.setState({ tagtype: value });
        if (!nonTagTypeValueList.includes(value)) {
            this.setState({ tagtypevalue: value });
        } else {
            this.setState({ tagtypevalue: '' });
        }
    };

    _renderBasicDetails = () => {
        return (
            <>
                <div className="row sub-title">Basic Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecode"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={(event) =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="taggroup" className="col-form-label ">
                            Tag Group
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="taggroup"
                            value={this.state.taggroup}
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.taggrouperror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="taggroup" className="col-form-label ">
                            Tag Type
                        </label>
                        {this._isViewMode() || this._isEditMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtype"
                                value={this.state.tagtype}
                                disabled={true}
                            />
                        ) : (
                            <StatusOptionComp
                                statusoption={this.state.tagTypeOptions}
                                selectedOption={this.state.tagtype}
                                callbackChangeFilter={(value) =>
                                    this.handleChangeTagType(value)
                                }
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.tagtypeerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="tagtypevalue"
                            className="col-form-label "
                        >
                            Tag Type Value
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                disabled={true}
                            />
                        ) : this.state.tagtype === 'rabbitpointrule' ? (
                            this.renderRabbitTagTypeValue()
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="tagtypevalue"
                                value={this.state.tagtypevalue}
                                onChange={(event) =>
                                    this.setState({
                                        tagtypevalue: event.target.value,
                                    })
                                }
                                disabled={
                                    this.state.tagtype === 'receiptheader' ||
                                    this.state.tagtype === 'receiptfooter' ||
                                    this.state.tagtype ===
                                        'topupreceiptheader' ||
                                    this.state.tagtype ===
                                        'topupreceiptfooter' ||
                                    this.state.tagtype === 'invoiceheader'
                                }
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.tagtypevalueerror}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    handleRabbitTagTypeChange = (tagtypevalue) => {
        this.setState({ tagtypevalue });
    };

    renderRabbitTagTypeValue = () => {
        return (
            <RabbitTagTypeOptionDropdown
                childProps={this.props}
                tagType={this.state.tagtype}
                tagTypeValue={this.state.tagtypevalue}
                handleRabbitTagTypeChange={this.handleRabbitTagTypeChange}
            />
        );
    };

    _renderTenderSettings = () => {
        if (this.state.tagtype === 'tender') {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="row">
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="tenderdesc"
                                className="floatLeft required"
                            >
                                Description
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="storeid"
                                value={this.state.tenderdesc}
                                onChange={(event) =>
                                    this.setState({
                                        tenderdesc: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            <div className="invalid-feedback">
                                {this.state.tenderdescerror}
                            </div>
                        </div>
                        <div className="form-group col-lg-4 col-sm-12">
                            <label
                                htmlFor="tendergroup"
                                className="floatLeft required"
                            >
                                Tender Group
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.tendergroup}
                                    disabled={true}
                                />
                            ) : this.state.tagtype === 'tender' &&
                              !this._isViewMode() ? (
                                <StatusOptionComp
                                    statusoption={this.tenderGroupOptions}
                                    selectedOption={this.state.tendergroup}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ tendergroup: value });
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tendergroup"
                                    value={this.state.tendergroup}
                                    onChange={(event) =>
                                        this.setState({
                                            tendergroup: event.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() ||
                                        (this._isEditMode() &&
                                            this.state.tagtype === 'tender')
                                    }
                                />
                            )}
                            <div className="invalid-feedback">
                                {this.state.tendergrouperror}
                            </div>
                        </div>
                        <div className="form-group col-lg-4 col-sm-12">
                            <label htmlFor="deviceid" className="floatLeft">
                                Select Device
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deviceid"
                                    value={this.state.deviceid}
                                    disabled={true}
                                />
                            ) : this.state.tagtype === 'tender' &&
                              !this._isViewMode() ? (
                                <StatusOptionComp
                                    statusoption={this.state.storedevices}
                                    selectedOption={this.state.deviceid}
                                    callbackChangeFilter={(value) => {
                                        this.setState({ deviceid: value });
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deviceid"
                                    value={this.state.deviceid}
                                    onChange={(event) =>
                                        this.setState({
                                            deviceid: event.target.value,
                                        })
                                    }
                                    disabled={
                                        this._isViewMode() ||
                                        (this._isEditMode() &&
                                            this.state.tagtype === 'tender')
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Sales Tender
                            </label>
                            <SimpleSwitch
                                checked={this.state.tender}
                                onChange={this.handleTenderSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Open Cash Drawer
                            </label>
                            <SimpleSwitch
                                checked={this.state.opencashdrawer}
                                onChange={this.handleOpenCashdrawerSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>

                        {this.state.returntender && (
                            <div className="form-group col-lg-6 col-sm-12">
                                <label
                                    htmlFor="returntype"
                                    className="floatLeft switch-label required"
                                >
                                    Return Type
                                </label>
                                <div className="row">
                                    <div className="form-group col-lg-3 col-sm-12">
                                        <label
                                            htmlFor="wholeweek"
                                            className="floatLeft"
                                        >
                                            <input
                                                type="radio"
                                                value="cash"
                                                onChange={(e) =>
                                                    this.handleReturnTypeSelection(
                                                        e,
                                                    )
                                                }
                                                checked={
                                                    this.state.returntype ===
                                                    'cash'
                                                }
                                            />
                                            Cash
                                        </label>
                                    </div>
                                    <div className="form-group col-lg-3 col-sm-12">
                                        <label
                                            htmlFor="specificdays"
                                            className="floatLeft"
                                        >
                                            <input
                                                type="radio"
                                                value="ccv"
                                                onChange={(e) =>
                                                    this.handleReturnTypeSelection(
                                                        e,
                                                    )
                                                }
                                                checked={
                                                    this.state.returntype ===
                                                    'ccv'
                                                }
                                            />
                                            CCV
                                        </label>
                                    </div>
                                    <div className="invalid-feedback">
                                        {this.state.returntypeerror}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="voidtender"
                                className="floatLeft switch-label"
                            >
                                Void Tender
                            </label>
                            <SimpleSwitch
                                checked={this.state.voidtender}
                                onChange={this.handleVoidTenderSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Return Tender
                            </label>
                            <SimpleSwitch
                                checked={this.state.returntender}
                                onChange={this.handleReturnTenderSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="voidtender"
                                className="floatLeft switch-label"
                            >
                                Manager Intervention
                            </label>
                            <SimpleSwitch
                                checked={this.state.mgrintervention}
                                onChange={this.handleMgrInterventionSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Offline Tender
                            </label>
                            <SimpleSwitch
                                checked={this.state.offlinetender}
                                onChange={this.handleOfflineTenderSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row" />

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label
                                        htmlFor="pickup"
                                        className="floatLeft"
                                    >
                                        <input
                                            type="radio"
                                            value="Pickup"
                                            onChange={(e) =>
                                                this.handlePickupSwitch(e)
                                            }
                                            checked={
                                                this.state.pickup &&
                                                !this.state.autopickup
                                            }
                                            disabled={this._isViewMode()}
                                        />
                                        Pickup
                                    </label>
                                </div>
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label
                                        htmlFor="autopickup"
                                        className="floatLeft"
                                    >
                                        <input
                                            type="radio"
                                            value="AutoPickup"
                                            onChange={(e) =>
                                                this.handleAutoPickupSwitch(e)
                                            }
                                            checked={
                                                this.state.autopickup &&
                                                !this.state.pickup
                                            }
                                            disabled={this._isViewMode()}
                                        />
                                        Auto Pickup
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Loan
                            </label>
                            <SimpleSwitch
                                checked={this.state.loan}
                                onChange={this.handleLoanSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>

                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Cash Count
                            </label>
                            <SimpleSwitch
                                checked={this.state.cashcount}
                                onChange={this.handleCashCountSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="validfrom"
                                className="floatLeft switch-label"
                            >
                                Store Cash In/Out
                            </label>
                            <SimpleSwitch
                                checked={this.state.storecashinout}
                                onChange={this.handleStoreCashInOutSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="minamount" className="floatLeft">
                                Min Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="minamount"
                                value={this.state.minamount}
                                onChange={(event) =>
                                    this.setState({
                                        minamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="maxamount" className="floatLeft">
                                Max Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="maxamount"
                                value={this.state.maxamount}
                                onChange={(event) =>
                                    this.setState({
                                        maxamount: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="tenderimg" className="floatLeft">
                                Tender Image
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderimg"
                                value={this.state.tenderimg}
                                onChange={(event) =>
                                    this.setState({
                                        tenderimg: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="tenderinstruction"
                                className="floatLeft"
                            >
                                Tender Instructions
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderinstruction"
                                value={this.state.tenderinstruction}
                                onChange={(event) =>
                                    this.setState({
                                        tenderinstruction: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                            {/* <div className="invalid-feedback">{this.state.minamounterror}</div> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="tenderanimationimg"
                                className="floatLeft"
                            >
                                Tender Animation
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="tenderanimationimg"
                                value={this.state.tenderanimationimg}
                                onChange={(event) =>
                                    this.setState({
                                        tenderanimationimg: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                </>
            );
        } else if (this.state.tagtype === 'storeprops') {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="autolocktimeout"
                                className="floatLeft"
                            >
                                Autolock Timeout
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="autolocktimeout"
                                value={this.state.autolocktimeout}
                                onChange={(event) =>
                                    this.setState({
                                        autolocktimeout: event.target.value,
                                    })
                                }
                                min="0"
                                max="1000"
                                maxLength="4"
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="paymenttimeout"
                                className="floatLeft"
                            >
                                Payment Timeout
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="paymenttimeout"
                                value={this.state.paymenttimeout}
                                onChange={(event) =>
                                    this.setState({
                                        paymenttimeout: event.target.value,
                                    })
                                }
                                min="60"
                                max="1000"
                                maxLength="4"
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="quicklookupcatcode"
                                className="floatLeft"
                            >
                                Quick Lookup Category Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="quicklookupcatcode"
                                value={this.state.quicklookupcatcode}
                                onChange={(event) =>
                                    this.setState({
                                        quicklookupcatcode: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>

                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="quicklookupshowalways"
                                className="floatLeft switch-label"
                            >
                                Quick Lookup Show Always
                            </label>
                            <SimpleSwitch
                                checked={this.state.quicklookupshowalways}
                                onChange={
                                    this.handleQuickLookupShowAlwaysSwitch
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="quicklookuptemplatetype"
                                className="floatLeft"
                            >
                                Quick Lookup Template Type
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="quicklookuptemplatetype"
                                    value={this.state.quicklookuptemplatetype}
                                />
                            ) : (
                                <StatusOptionComp
                                    statusoption={this.quicklookuptemplatetypes}
                                    selectedOption={
                                        this.state.quicklookuptemplatetype
                                    }
                                    callbackChangeFilter={(value) => {
                                        this.setState({
                                            quicklookuptemplatetype: value,
                                        });
                                    }}
                                />
                            )}
                        </div>

                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="quicklookupitemscount"
                                className="floatLeft switch-label"
                            >
                                Quick Lookup Items Per Page
                            </label>
                            <input
                                type="number"
                                min="1"
                                max="100"
                                maxLength="3"
                                className="form-control"
                                id="quicklookupitemscount"
                                value={this.state.quicklookupitemscount}
                                onChange={(event) =>
                                    this.setState({
                                        quicklookupitemscount:
                                            event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="ccvcouponcode"
                                className="floatLeft"
                            >
                                CCV Coupon Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="ccvcouponcode"
                                value={this.state.ccvcouponcode}
                                onChange={(event) =>
                                    this.setState({
                                        ccvcouponcode: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label htmlFor="ccvrulecode" className="floatLeft">
                                CCV Rule Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="ccvrulecode"
                                value={this.state.ccvrulecode}
                                onChange={(event) =>
                                    this.setState({
                                        ccvrulecode: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="promotionprefix"
                                className="floatLeft"
                            >
                                Promotion Prefix
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="promotionprefix"
                                value={this.state.promotionprefix}
                                onChange={(event) =>
                                    this.setState({
                                        promotionprefix: event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="allowitemreturn"
                                className="floatLeft switch-label"
                            >
                                Allow Item Return
                            </label>
                            <SimpleSwitch
                                checked={this.state.allowitemreturn}
                                onChange={this.handleAllowItemReturnSwitch}
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="productplaceholderimg"
                                className="floatLeft"
                            >
                                Product Placeholder Img
                            </label>
                            {this._isViewMode() ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="productplaceholderimg"
                                    value={this.state.productplaceholderimg}
                                    onChange={(event) =>
                                        this.setState({
                                            productplaceholderimg:
                                                event.target.value,
                                        })
                                    }
                                    disabled={true}
                                />
                            ) : (
                                <SimpleImageInput
                                    placeholder="Choose Image.."
                                    setImage={(value) => {
                                        this.setState({
                                            productplaceholderimg: value,
                                        });
                                    }}
                                    value={this.state.productplaceholderimg}
                                ></SimpleImageInput>
                            )}
                        </div>
                        <div className="form-group col-lg-6 col-sm-12">
                            <label
                                htmlFor="rabbittandcarticlecode"
                                className="floatLeft"
                            >
                                Member T&amp;C Article Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="rabbittandcarticlecode"
                                value={this.state.rabbittandcarticlecode}
                                onChange={(event) =>
                                    this.setState({
                                        rabbittandcarticlecode:
                                            event.target.value,
                                    })
                                }
                                disabled={this._isViewMode()}
                            />
                        </div>
                    </div>
                    <JsonValidator handleSco={this.handleSco} sco={this.state.sco} />
                </>
            );
        }
    };

    handleSco = (scoVal) => {
        this.setState({ sco: scoVal })
    }

    _renderReceiptInvoiceDetails = () => {
        if (
            this.state.tagtype === 'receiptheader' ||
            this.state.tagtype === 'receiptfooter' ||
            this.state.tagtype === 'topupreceiptheader' ||
            this.state.tagtype === 'topupreceiptfooter' ||
            this.state.tagtype === 'invoiceheader'
        ) {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <div className="row sub-title">Settings</div>

                    {this.state.tagtype !== 'receiptfooter' &&
                        this.state.tagtype !== 'topupreceiptfooter' && (
                            <div>
                                <div className="row">
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label
                                            htmlFor="companyname"
                                            className="floatLeft required"
                                        >
                                            Company Name
                                        </label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            id="companyname"
                                            value={this.state.companyname}
                                            onChange={(event) =>
                                                this.setState({
                                                    companyname:
                                                        event.target.value,
                                                })
                                            }
                                            disabled={this._isViewMode()}
                                        />
                                        <div className="invalid-feedback">
                                            {this.state.companynameerror}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label
                                            htmlFor="productplaceholderimg"
                                            className="floatLeft"
                                        >
                                            Logo
                                        </label>
                                        {this._isViewMode() ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="productplaceholderimg"
                                                value={
                                                    this.state.invoiceheaderlogo
                                                }
                                                onChange={(event) =>
                                                    this.setState({
                                                        invoiceheaderlogo:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={true}
                                            />
                                        ) : (
                                            <SimpleImageInput
                                                placeholder="Choose Image.."
                                                setImage={(value) => {
                                                    this.setState({
                                                        invoiceheaderlogo:
                                                            value,
                                                    });
                                                }}
                                                value={
                                                    this.state.invoiceheaderlogo
                                                }
                                            ></SimpleImageInput>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    <div className="row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <ReceiptLineConfig
                                columns={[
                                    {
                                        dataFieldId: 'text',
                                        label: 'Text',
                                        addRow: true,
                                    },
                                    {
                                        dataFieldId: 'alignment',
                                        label: 'Alignment',
                                        addRow: true,
                                    },
                                    { dataFieldId: 'AD', label: '' },
                                ]}
                                rows={this.state.receiptlines}
                                deleteRow={this.deleteRow}
                                addRow={this.handleAddRow}
                                handleInputChange={this.handleInputChange}
                                handleAlignmentChange={
                                    this.handleAlignmentChange
                                }
                                isViewMode={this._isViewMode()}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };

    _renderTaxReportHeaderDetails = () => {
        if (
            this.state.tagtype === 'taxreportheader' ||
            this.state.tagtype === 'topupreportheader'
        ) {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <TaxReportHeaderConfig
                        isView={this._isViewMode}
                        fields={this.state.additionalFields}
                        _updateTaxReportHeaderDetails={
                            this._updateTaxReportHeaderDetails
                        }
                    />
                </>
            );
        }
    };

    getPresetAdditionalFields = (tagtype) => {
        return PresetAddtionalFields[tagtype];
    };

    updateAdditionalFields = (values) => {
        this.setState({ additionalFields: values });
    };

    _renderTaxTopupReportDetails = () => {
        const { tagtype } = this.state;
        const additionalFieldsSettings = ['NINE_SAP_CONFIG'];
        if (additionalFieldsSettings.includes(tagtype)) {
            return (
                <>
                    <Divider />
                    <div className="row sub-title">Additional Fields</div>
                    <AdditionalFieldSetting
                        isView={this._isViewMode()}
                        isEdit={this._isEditMode()}
                        additionalFields={
                            this._isEditMode() || this._isViewMode()
                                ? this.state.additionalFields
                                : this.getPresetAdditionalFields(tagtype)
                        }
                        updateAdditionalFields={this.updateAdditionalFields}
                    />
                </>
            );
        }
    };

    renderTaxTopupFileSelector = () => {
        const { tagtype } = this.state;
        const additionalFieldsSettings = [
            'taxreporttemplate',
            'topupreporttemplate',
        ];
        if (additionalFieldsSettings.includes(tagtype)) {
            return (
                <>
                    <Divider />
                    <div className="row sub-title">Additional Fields</div>
                    <DynamicFileInput
                        fieldArr={this.getPresetAdditionalFields(tagtype)}
                        additionalFields={
                            this._isEditMode() || this._isViewMode()
                                ? this.state.additionalFields
                                : this.getPresetAdditionalFields(tagtype)
                        }
                        updateAdditionalFields={this.updateAdditionalFields}
                    />
                </>
            );
        }
    };

    _renderTopUpReportDetails = () => {
        if (this.state.tagtype === 'topupreporttemplate') {
            return (
                <>
                    {this._renderSubSecDivider()}
                    <TaxReportHeaderConfig
                        isView={this._isViewMode}
                        fields={this.state.additionalFields}
                        _updateTaxReportHeaderDetails={
                            this._updateTaxReportHeaderDetails
                        }
                    />
                </>
            );
        }
    };

    _updateTaxReportHeaderDetails = (language, _additionalFields) => {
        this.setState({
            additionalFields: {
                ...this.state.additionalFields,
                [language]: _additionalFields,
            },
        });
    };

    _renderPeripheralsSection = () => {
        if (!this._isEditMode()) {
            return;
        }
        return (
            <>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Peripherals</div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecodes"
                            className="floatLeft required"
                        >
                            Select Peripherals
                        </label>
                    </div>
                </div>
            </>
        );
    };

    _renderOtherPropDetails = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        {this._isViewMode() ? (
                            <input
                                type="text"
                                className="form-control"
                                id="status"
                                value={this.state.status}
                                disabled={true}
                            />
                        ) : (
                            <StatusOptionComp
                                statusoption={this.editModeStatus}
                                selectedOption={this.state.status}
                                callbackChangeFilter={(value) => {
                                    this.setState({ status: value });
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    };

    _renderMainForm = () => {
        return (
            <div className="grid px-3">
                <div className="col-9">
                    <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                        {this._renderBasicDetails()}
                        {this._renderTenderSettings()}
                        {this._renderReceiptInvoiceDetails()}
                        {this._renderTaxReportHeaderDetails()}
                        {/* {this._renderTopUpReportDetails()} */}
                        {this._renderTaxTopupReportDetails()}
                        {this.renderTaxTopupFileSelector()}
                    </div>
                </div>
                <div className="col-3">
                    <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                        {this._renderOtherPropDetails()}
                    </div>
                </div>
            </div>
        );
    };

    UNSAFE_componentWillReceiveProps = () => {
        const { storeDevices } = this.props.queryData;

        if (storeDevices && storeDevices.length > 0) {
            var returnedDevices = storeDevices.map((e) => ({
                name: e.title,
                id: e.deviceid,
            }));
            this.setState({
                storedevices: [{ name: 'Select', id: '' }].concat(
                    returnedDevices,
                ),
            });
        } else {
            this.setState({ storedevices: [{ name: 'Select', id: '' }] });
        }
    };

    componentDidMount = () => {
        const { tenderTagTypes: propConfigs } = this.props.queryData;

        if (
            propConfigs &&
            propConfigs.length > 0 &&
            propConfigs[0].additionalfields
        ) {
            this.setState({
                tenderTagTypeOptions: propConfigs[0].additionalfields,
            });
        }
    };

    componentWillMount = () => {
        const storeid = localStorage.getItem('storeid');
        this.setState({ storeid });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    title,
                    taggroup,
                    tagtype,
                    tagtypevalue,
                    status,
                    additionalfields,
                } = rec;
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.terminalid = terminalid ? terminalid : '';
                        draft.taggroup = taggroup ? taggroup : '';
                        draft.tagtype = tagtype;
                        draft.tagtypevalue = tagtypevalue;
                        draft.status = status ? status : '';

                        draft.additionalFields = additionalfields;
                        if (additionalfields) {
                            //tender
                            if (additionalfields['tenderdesc']) {
                                draft.tenderdesc =
                                    additionalfields['tenderdesc'];
                            }
                            if (additionalfields['tender']) {
                                draft.tender =
                                    additionalfields['tender'] === 'Y';
                            }
                            if (additionalfields['loan']) {
                                draft.loan = additionalfields['loan'] === 'Y';
                            }
                            if (additionalfields['pickup']) {
                                draft.pickup =
                                    additionalfields['pickup'] === 'Y';
                            }
                            if (additionalfields['opencashdrawer']) {
                                draft.opencashdrawer =
                                    additionalfields['opencashdrawer'] === 'Y';
                            }
                            if (additionalfields['autopickup']) {
                                draft.autopickup =
                                    additionalfields['autopickup'] === 'Y';
                            }
                            if (additionalfields['returntender']) {
                                draft.returntender =
                                    additionalfields['returntender'] === 'Y';
                            }
                            if (additionalfields['voidtender']) {
                                draft.voidtender =
                                    additionalfields['voidtender'] === 'Y';
                            }
                            if (additionalfields['returntype']) {
                                draft.returntype =
                                    additionalfields['returntype'];
                            }
                            if (additionalfields['minamount']) {
                                draft.minamount = additionalfields['minamount'];
                            }
                            if (additionalfields['maxamount']) {
                                draft.maxamount = additionalfields['maxamount'];
                            }
                            if (additionalfields['tenderimg']) {
                                draft.tenderimg = additionalfields['tenderimg'];
                            }
                            if (additionalfields['tenderinstruction']) {
                                draft.tenderinstruction =
                                    additionalfields['tenderinstruction'];
                            }
                            if (additionalfields['tenderanimationimg']) {
                                draft.tenderanimationimg =
                                    additionalfields['tenderanimationimg'];
                            }
                            if (additionalfields['tendergroup']) {
                                draft.tendergroup =
                                    additionalfields['tendergroup'];
                            }
                            if (additionalfields['offlinetender']) {
                                draft.offlinetender =
                                    additionalfields['offlinetender'] === 'Y';
                            }
                            if (additionalfields['cashcount']) {
                                draft.cashcount =
                                    additionalfields['cashcount'] === 'Y';
                            }
                            if (additionalfields['mgrintervention']) {
                                draft.mgrintervention =
                                    additionalfields['mgrintervention'] === 'Y';
                            }
                            if (additionalfields['storecashinout']) {
                                draft.storecashinout =
                                    additionalfields['storecashinout'] === 'Y';
                            }
                            if (additionalfields['deviceid']) {
                                draft.deviceid = additionalfields['deviceid'];
                            }

                            //storeprops
                            if (additionalfields['autolocktimeout']) {
                                draft.autolocktimeout =
                                    additionalfields['autolocktimeout'];
                            }
                            if (additionalfields['paymenttimeout']) {
                                draft.paymenttimeout =
                                    additionalfields['paymenttimeout'];
                            }
                            if (additionalfields['quicklookupcatcode']) {
                                draft.quicklookupcatcode =
                                    additionalfields['quicklookupcatcode'];
                            }
                            if (additionalfields['quicklookupshowalways']) {
                                draft.quicklookupshowalways =
                                    additionalfields[
                                        'quicklookupshowalways'
                                    ] === 'Y';
                            }
                            if (additionalfields['allowitemreturn']) {
                                draft.allowitemreturn =
                                    additionalfields['allowitemreturn'] === 'Y';
                            }
                            if (additionalfields['quicklookuptemplatetype']) {
                                draft.quicklookuptemplatetype =
                                    additionalfields['quicklookuptemplatetype'];
                            }
                            if (additionalfields['quicklookupitemscount']) {
                                draft.quicklookupitemscount =
                                    additionalfields['quicklookupitemscount'];
                            }
                            if (additionalfields['productplaceholderimg']) {
                                draft.productplaceholderimg =
                                    additionalfields['productplaceholderimg'];
                            }
                            if (additionalfields['promotionprefix']) {
                                draft.promotionprefix =
                                    additionalfields['promotionprefix'];
                            }
                            if (additionalfields['ccvcouponcode']) {
                                draft.ccvcouponcode =
                                    additionalfields['ccvcouponcode'];
                            }
                            if (additionalfields['ccvrulecode']) {
                                draft.ccvrulecode =
                                    additionalfields['ccvrulecode'];
                            }
                            if (additionalfields['rabbittandcarticlecode']) {
                                draft.rabbittandcarticlecode =
                                    additionalfields['rabbittandcarticlecode'];
                            }
                            if (additionalfields['sco']) {
                                draft.sco =
                                    additionalfields['sco'];
                            }

                            //receiptheader || receiptfooter || invoiceheader
                            if (
                                tagtype === 'receiptheader' ||
                                tagtype === 'receiptfooter' ||
                                tagtype === 'topupreceiptheader' ||
                                tagtype === 'topupreceiptfooter' ||
                                tagtype === 'invoiceheader'
                            ) {
                                if (additionalfields['lines']) {
                                    draft.receiptlines = additionalfields[
                                        'lines'
                                    ].map((e, i) => {
                                        return {
                                            text: e.text,
                                            alignment: e.alignment,
                                            id: i,
                                        };
                                    });
                                }
                                if (additionalfields['logo']) {
                                    draft.invoiceheaderlogo =
                                        additionalfields['logo'];
                                }
                                if (additionalfields['companyname']) {
                                    draft.companyname =
                                        additionalfields['companyname'];
                                }
                            }
                        }
                    }),
                );
            }
        }

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propgroup: TENDERTAGTYPES,
                    },
                },
                SEARCH_PROP_CONFIG_TENDERTAGTYPES,
                SEARCH_PROP_CONFIG_TENDERTAGTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_TENDERTAGTYPES_FAILURE,
            ),
        );

        this.props.dispatch(
            queryPropertyConfigByFields(
                {
                    body: {
                        propcode: 'STORE_OPTION_TAG_TYPES',
                    },
                },
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_SUCCESS,
                SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES_FAILURE,
            ),
        );

        if (storeid && storeid !== '') {
            this.props.dispatch(
                storeDevicesSearchByFields(
                    `status=Active&storeid=${storeid}&devicegroup=eft`,
                ),
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeDevices, articleFlowData, masterTrxGrpTypes } =
            this.props.queryData;
        const {
            storeDevices: prevstoreDevices,
            articleFlowData: prevarticleFlowData,
            masterTrxGrpTypes: prevmasterTrxGrpTypes,
        } = prevProps.queryData;

        if (storeDevices !== prevstoreDevices) {
            if (storeDevices && storeDevices.length > 0) {
                var returnedDevices = storeDevices.map((e) => ({
                    name: e.title,
                    id: e.deviceid,
                }));
                this.setState({
                    storedevices: [{ name: 'Select', id: '' }].concat(
                        returnedDevices,
                    ),
                });
            } else {
                this.setState({ storedevices: [{ name: 'Select', id: '' }] });
            }
        }

        if (articleFlowData !== prevarticleFlowData) {
            let activeRabbitTag = articleFlowData.filter(
                (i) => i.status == 'Active',
            );
            let rabbitTagTypes = activeRabbitTag.map((rabbit) => ({
                name: rabbit.rulecode + '-' + rabbit.title,
                id: rabbit.rulecode,
            }));
            this.setState({ rabbitTagTypeOptions: rabbitTagTypes });
        }

        if (
            masterTrxGrpTypes !== prevmasterTrxGrpTypes &&
            masterTrxGrpTypes.length > 0 &&
            masterTrxGrpTypes[0].additionalfields
        ) {
            const output = Object.entries(
                masterTrxGrpTypes[0].additionalfields,
            ).map(([name, id]) => ({ name, id }));

            // Add the default "Select" option
            output.unshift({ name: 'Select', id: '' });

            this.setState({ tagTypeOptions: output });
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Store Config'
                                    : this._isViewMode()
                                      ? 'View Store Config'
                                      : 'Add Store Config'}
                            </h2>
                        </div>
                    </div>
                    {this._renderErrorMessage()}
                    {this._renderMainForm()}

                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/storeoptions"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Store Config updated successfully'
                                    : 'Store Config created successfully'}
                            </div>
                        }
                    />
                </div>
            </>
        );
    }
}

const JsonValidator = (props) => {
    const { handleSco, sco } = props
    const [jsonInput, setJsonInput] = useState(sco ? sco : ''); // To store the JSON input
    const [isValidJson, setIsValidJson] = useState(null); // To store whether the JSON is valid or not

    // Function to handle JSON validation
    const handleJsonChange = (e) => {
        const input = e.target.value;
        setJsonInput(input);

        // Try to parse JSON input
        try {
            JSON.parse(input);
            setIsValidJson(true);
            handleSco(input)
        } catch (error) {
            setIsValidJson(false);
        }
    };

    return (
        <>
            <div className="row sub-title">Additional Config:</div>
            <div className='row px-3'>
            <InputTextarea
                value={jsonInput}
                placeholder="Paste your JSON here..."
                onChange={handleJsonChange}
                rows={5}
                cols={30}
            />
            <div style={{ marginTop: '10px' }}>
                {isValidJson === null ? (
                    <p></p>
                ) : isValidJson ? (
                    <p style={{ color: 'green' }}>Valid JSON</p>
                ) : (
                    <p style={{ color: 'red' }}>Invalid JSON</p>
                )}
            </div>
            </div>
        </>
    );
};

const RabbitTagTypeOptionDropdown = (props) => {
    const { childProps, handleRabbitTagTypeChange, tagType, tagTypeValue } =
        props;
    const { articleFlowData } = childProps.queryData;
    const [rabbitTagTypeOptions, setrabbitTagTypeOptions] = useState([]);
    const [rabbittagtypevalue, setRabbitTagTypeValue] = useState({});

    useEffect(() => {
        if (tagType == 'rabbitpointrule' && rabbitTagTypeOptions.length > 0) {
            const tValue = rabbitTagTypeOptions.find(
                (e) => e.id == tagTypeValue,
            );
            setRabbitTagTypeValue(tValue);
        }
    }, [tagType, tagTypeValue, rabbitTagTypeOptions]);
    const createRabbitPayload = () => {
        const requestBody = {
            body: {
                articletype: 'Point Rule',
                language: 'en',
                search_field: 'rulegroup',
                search_condi: 'like',
                search_value: 'RabbitCart',
            },
        };
        return requestBody;
    };
    useEffect(() => {
        childProps.dispatch(
            searchGeneral(
                '/campaign/v1/point/rule/search',
                createRabbitPayload(),
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE',
            ),
        );
    }, []);
    useEffect(() => {
        if (articleFlowData && articleFlowData.length > 0) {
            let activeRabbitTag = articleFlowData.filter(
                (i) => i.status == 'Active',
            );
            let rabbitTagTypes = activeRabbitTag.map((rabbit) => ({
                name: rabbit.rulecode + '-' + rabbit.title,
                id: rabbit.rulecode,
            }));
            setrabbitTagTypeOptions(rabbitTagTypes);
        }
    }, [articleFlowData]);

    const handleChange = (e) => {
        setRabbitTagTypeValue(e.target.value);
        handleRabbitTagTypeChange(e.target.value.id);
    };

    return (
        <div className="w-100">
            <Dropdown
                className="w-100"
                id="rabbittagtypevalue"
                name="rabbittagtypevalue"
                value={rabbittagtypevalue}
                onChange={handleChange}
                options={rabbitTagTypeOptions}
                optionLabel="name"
                placeholder="Select"
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddStoreOptions);
