import { useFormik } from 'formik';

function CreateFormikHook (initialValues, validationSchema, submitForm) {
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {...initialValues},
    validationSchema: validationSchema,
    onSubmit: submitForm
  });

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    validateForm,
    handleChange
  } = formik
  //fix for formik transform
  const handleBlur = (event) => {setFieldValue(event.target.id, event.target.value.trim())}

  return {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    validateForm,
    handleChange,
    handleBlur
  }
}

export default CreateFormikHook
