import React from "react";
import ExportButton from "../../../components/TableExportButton";
import { searchFields } from "./tableCols";
import { connect } from "react-redux";
import { produce } from "immer";
import {
  searchGeneral,
  crudReset,
  resetAction
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleTable from "../../../components/SimpleTable";
import Loading from "../../../components/Loading";
import SimpleSearcher from "../../../components/SimpleSearcher";

class DisplayCouponInventory extends React.PureComponent {

  constructor(props) {
    super(props);
    
    this.currPage = 1
    this.pageSize = 10

    
    this.state = {
      currPage: this.currPage,
      pageSize: this.pageSize,
      showSearchModal: false,
      query: {},
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      tableCols: [
        {
          dataField: "couponno",
          text: "Coupon No",
          sort: true
        },
        {
          dataField: "storeid",
          text: "StoreId",
          sort: true
        },
        {
          dataField: "trtype",
          text: "Type",
          sort: true
        },
        {
          dataField: "trtypevalue",
          text: "Value",
          sort: true
        },
        {
          dataField: "trdate",
          text: "Date",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        }

        // {
        //   dataField: "",
        //   text: "Actions",
        //   formatter: this.cellFormatter,
        //   formatExtraData: {
        //     action: "ED"
        //   },
        //   classes: () => {
        //     return "lastTd";
        //   }
        // }
      ]
    };
  }
  
  // API Calls
  createFetchUrl = fetchType => {
    const {
      pageSize,
      currPage,
      language,
      search_field,
      search_condi,
      search_value
    } = this.state;

    const requestBody = {
      body: {
        language: "en",
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value,
        pageno: currPage,
        pagesize: pageSize
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "campaign/v1/coupon/inventory/search",
        this.createFetchUrl(fetchType),
        "SEARCH_ARTICLE_INFO_SUCCESS",
        "SEARCH_ARTICLE_INFO_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        // const [pgNumber] = rest;

        // newState = produce(this.state, draft => {
        //   draft.currPage = pgNumber - 1;
        // });
        // this.setState({ ...newState }, () => {
        //   this.callFetch("PAGECLICK");
        // });
        this.callFetch();

        break;
      default:
        break;
    }
  };
  searchQuery = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("SEARCH");
      }
    );
  };

  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage }, () =>
      this.callQueryApiForFetch("ONLOAD")
    );
  }
 
//   shdDeleteRec = arg => {
//     this.setState({ openDeleteprompt: false });
//     if (arg === true) {
//       this.props.dispatch(deleteCoupon(this.state.delete_rec_id));
//     }
//   };
  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };
  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  _deleteCoupon = rowData => {
    this.setState({ message: "Coupon  deleted successfully" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };

  getTableData = (records, headers) => {
    const tablerow = records.map(e => [e.couponno, e.storeid, e.trtype, e.trtypevalue, e.trdate, e.status]);
    const colheader = headers.filter(e => e.text !== "Actions").map(e => ({ label: e.text, key: e.dataField }))
    tablerow.unshift(colheader.map(e=>e.label));
    return tablerow;
  }

  render() {
    const {
      articleFlowData,
      languageConfig,
      errormsg: queryError,
      servererror: serverError
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }

    if (!articleFlowData && !errormsg) {
      return <Loading />;
    }
    if (this.props.queryData.apiPending) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">
                Coupon Inventory
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
            <div className="col-lg-6 text-right noPadding noMargin">
             <ExportButton 
                title="Coupon Inventory"
                tabledata ={this.getTableData(articleFlowData, this.state.tableCols)}
              />
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-7 col-sm-12 noPadding noMargin">
                    {/* <h3 className="page-title">Coupon Inventory</h3> */}
                  </div>
                  <div className="col-lg-5 col-sm-12 alignRight noPadding noMargin">
                    <SimpleSearcher
                      filterOptions={searchFields}
                      setAndSearch={this.searchQuery}
                      couponinv={true}
                      search_condi={this.state.search_condi}
                      search_value={this.state.search_value}
                      search_field={this.state.search_field}
                    ></SimpleSearcher>
                  </div>
                </div>
                <div className="title_devide" />
                {/* <div className="invalid-feedback">{errormsg}</div> */}
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={articleFlowData}
                  // callback={() => {
                  //   this.callQueryApiForFetch("ONLOAD");
                  // }}
                  pageChangeCallback={(currPage) => {
                      this.setState({
                          currPage: currPage
                      }, () => {
                          this.callQueryApiForFetch("PAGECLICK");
                      })
                  }}
                  callback={(pageSize) => {
                      this.setState(produce(this.state, draft => {
                          if (draft.pageSize < pageSize) {
                              draft.currPage = 1
                          }
                          draft.pageSize = pageSize
                      }), () => {
                          this.callQueryApiForFetch("PAGECLICK")
                      })
                  }}
                  withPageSize={this.state.pageSize}
                  withCurrPage={this.state.currPage}
                  withPageFeedback={true}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this coupon inventory?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec deletBtn"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          /> 
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayCouponInventory);
