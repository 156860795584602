import React, { useState } from 'react'

const StatusComp = (props) => {

  const disabled = props.disabled
  const uniqueKey = `${new Date().getTime()}`
  const callbackChangeFilter = props.callbackChangeFilter
  const statusConfig = props.customStatus ? props.customStatus : JSON.parse(localStorage.getItem('statusconfig'))
  const [internalSelectOption, setInternalStatusChange] = useState(props.selectedOption)

  const _dropDownChange = event => {
    const value = event.target.value
    setInternalStatusChange(value)
    if(callbackChangeFilter)
      callbackChangeFilter(value, event)
  };

  return (
    <div className='filterClass form-filter'>
      <select
        id='statusComp'
        className='form-control'
        disabled={disabled ? true : false}
        value={callbackChangeFilter? props.selectedOption: internalSelectOption}
        onChange={_dropDownChange}
      >
        {props.allowBlank && <option key={`sc_${uniqueKey}_00`} value=''>Select</option>}
        {typeof statusConfig !== 'undefined' ? (
          Object.entries(statusConfig).map((t, i) => (
            <option key={`sc_${uniqueKey}_${i}`} value={t[0]}>
              {t[1]}
            </option>
          ))
        ) : (
          <option>No option</option>
        )}
      </select>
    </div>
  )
}

export default StatusComp;
