import React from "react";
class DynamicConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = { values: [{ title: "", desc: "", id: 0 }] };
  }

  createUI() {
    return this.state.values.map(value => {
      let idx = value.id;
      return (
        <div className="row" key={idx}>
          <div className="col-md-5 col-sm-12 noPadding">
            <input
              id="title"
              type="text"
              value={value.title}
              className="DynamicConfigInp"
              placeholder="Title..."
              onChange={event => this.handleChange(event, idx)}
            />
          </div>
          <div className="col-md-5 col-sm-12">
            <input
              id="desc"
              type="text"
              value={value.desc}
              className="DynamicConfigInp"
              placeholder="Desc..."
              onChange={event => this.handleChange(event, idx)}
            />
          </div>
          <div className="col-md-2 col-sm-12 alignLeft">
            <img
              className="dynamicBtn1"
              onClick={event => this.removeClick(event, idx)}
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            />
          </div>
        </div>
      );
    });
  }

  handleChange = (evt, id) => {
    const newProducts = this.state.values.map(value => {
      if (id !== value.id) return value;
      return { ...value, [evt.target.id]: evt.target.value };
    });

    this.setState({ values: newProducts }, () => {
      this.props.setDesc(this.state.values);
    });
  };

  addClick = event => {
    let lastEle = this.state.values[this.state.values.length - 1];

    const item = {
      title: "",
      desc: "",
      id: lastEle.id + 1
    };
    this.setState({
      values: [...this.state.values, item]
    });
    if (event) event.preventDefault();
  };
  removeClick = (evt, idx) => {
    const updateValues = this.state.values.filter(value => idx !== value.id);

    this.setState({ values: updateValues });
  };
  render() {
    return (
      <React.Fragment>
        {this.createUI()}
        <div className="row">
          <button className="addNewRow" onClick={event => this.addClick(event)}>
            Add New
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default DynamicConfig;
