import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumbWrapper = props => {
    const { items } = props
  
    const renderLink = (item, index) => {
      if (!item.path) return item.label || '';
      return (
        <Link
          key={index}
          to={{ pathname: item.path }}
        >
          {item.label || ''}
        </Link>
      );
    };
  
    return (
      <nav
        className="p-breadcrumb p-component"
        aria-label="Breadcrumb"
        style={{
          borderRadius: '0px',
          border: 'none',
          borderBottom: '1px solid #f5f6fa',
          marginBottom: '20px',
        }}
      >
        <ul>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <li className={item.className || ''}>{renderLink(item, index)}</li>
              {index !== items.length - 1 && (
                <li className="p-mx-2 p-px-1 p-breadcrumb-chevron pi pi-chevron-right" />
              )}
            </React.Fragment>
          ))}
        </ul>
      </nav>
    );
  };

export default BreadCrumbWrapper;