import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DisplayScoreInfo from "./../ScoreInfo/DisplayScoreInfo";
import DisplayRewardRule from "../RewardRule/DisplayRewardRule";
import DisplayFaq from "../Faq/DisplayFaq";
import DisplayTermsAndConditions from "./../TermsAndConditions/DisplayTermsAndConditions";
import DisplayPrivacyPolicy from "../PrivacyPolicy/DisplayPrivacyPolicy";
import DisplaySecurePayment from "../SecurePayment/DisplaySecurePayment";
import DisplayAnimationArticle from "../AnimationArticle/DisplayAnimationArticle";
import DisplayContactUs from "../ContactUs/DisplayContactUs";
import DisplayAboutApp from "../AboutApp/DisplayAboutApp";
import DisplayAppGuide from "../AppGuide/DisplayAppGuide";
import DisplayAppConfig from "../AppConfig/DisplayAppConfig";
import GenericConfig from "../GenericConfig/DisplayGenericConfig";
import { updateArticleMode, queryArticleFormOptions } from "../../../../actions/actions";
import { permissions, renderArticleOptionsFilter } from "./../../../../helpers/Util";

const DisplayApplicationArticles = ({ queryData, dispatch }) => {
  const group = "DE_CMS_ARTICLES";
  const module = "CMS";
  const allPermissions = permissions(module, group);

  const [articleMode, setArticleMode] = useState(queryData.articleMode || "-1");

  useEffect(() => {
    dispatch(
      queryArticleFormOptions({
        body: {
          status: "Active",
          articlegroup: "Article",
        },
      })
    );
  }, [dispatch]);

  const handleArticleModeChange = (event) => {
    const selectedMode = event.target.value;
    setArticleMode(selectedMode);
    dispatch(updateArticleMode({ body: { mode: selectedMode } }));
  };

  const renderNoData = () => (
    <div className="row noStoreform">
      <div className="col-lg-6 col-sm-12">
        <p className="noStoreHeader">Articles</p>
        <p className="noStoreBody">
          User can create or display different articles here. Choose article
          type to continue.
        </p>
      </div>
      <div className="col-lg-6 col-sm-12">
        <img
          className="noStoreImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/img_vector_product.svg`}
          alt="No Data"
        />
      </div>
    </div>
  );

  const getComponent = () => {
    switch (articleMode) {
      case "Terms And Conditions":
        return <DisplayTermsAndConditions allPermissions={allPermissions} />;
      case "Privacy Policy":
        return <DisplayPrivacyPolicy allPermissions={allPermissions} />;
      case "Secure Payment":
        return <DisplaySecurePayment allPermissions={allPermissions} />;
      case "Animation Article":
        return <DisplayAnimationArticle allPermissions={allPermissions} />;
      case "Score Info":
        return <DisplayScoreInfo allPermissions={allPermissions} />;
      case "Faq":
        return <DisplayFaq allPermissions={allPermissions} />;
      case "Contact Us":
        return <DisplayContactUs allPermissions={allPermissions} />;
      case "About App":
        return <DisplayAboutApp allPermissions={allPermissions} />;
      case "Reward Rule":
        return <DisplayRewardRule allPermissions={allPermissions} />;
      case "App Guide":
        return <DisplayAppGuide allPermissions={allPermissions} />;
      case "App Config":
        return <DisplayAppConfig allPermissions={allPermissions} />;
      default:
        return <GenericConfig title={articleMode} allPermissions={allPermissions} />;
    }
  };

  const articleOptions = renderArticleOptionsFilter(queryData.articleOptions);
  console.log('query', queryData)
  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <h2 className="page-header">Articles Management</h2>
        </div>
        <div className="col-lg-4 col-sm-12 alignRight">
          <select
            name="articleMode"
            className="simpleSearchSelect1"
            value={articleMode}
            onChange={handleArticleModeChange}
            style={{ marginLeft: "2px" }}
          >
            {articleOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {articleMode === "-1" ? renderNoData() : getComponent()}
    </>
  );
};

const mapStateToProps = (state) => ({
  queryData: state.queryData,
});

export default connect(mapStateToProps)(DisplayApplicationArticles);
