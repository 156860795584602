import React from 'react';
import ExportButton from '../../../components/TableExportButton';
import { connect } from 'react-redux';
import { produce } from 'immer';
import {
    searchGeneral,
    crudReset,
    resetAction,
    couponTrxSearchByFields,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import SimpleTable from '../../../components/SimpleTable';
import * as CONSTANTS from '../../../constants/constants';

class DisplayCouponTransaction extends React.PureComponent {
    constructor(props) {
        super(props);

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            couponcode: '',
            couponcodeerror: '',
            tableCols: [
                {
                    dataField: 'couponcode',
                    text: 'Coupon Code',
                    sort: true,
                },
                {
                    dataField: 'couponno',
                    text: 'Coupon No',
                    sort: true,
                },
                {
                    dataField: 'title',
                    text: 'Title',
                    sort: true,
                },
                {
                    dataField: 'storeid',
                    text: 'StoreId',
                    sort: true,
                },
                {
                    dataField: 'couponqty',
                    text: 'Qty',
                    sort: true,
                },
                {
                    dataField: 'couponusedqty',
                    text: 'Used Qty',
                    sort: true,
                },
                {
                    dataField: 'couponvalue',
                    text: 'Value',
                    sort: true,
                },
                {
                    dataField: 'couponvaluetype',
                    text: 'Value Type',
                    sort: true,
                },
                {
                    dataField: 'validfrom',
                    text: 'Valid From',
                    sort: true,
                },
                {
                    dataField: 'validtill',
                    text: 'Valid Till',
                    sort: true,
                },
            ],
        };
    }

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, storeid } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    storeid: storeid,
                    pagesize: pageSize,
                    pageno: currPage,
                },
            };
            this.requestBody['storeid'] = storeid;
            console.log('...requestBody for ONLOAD::', requestBody);
            return requestBody;
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'campaign/v1/coupon/transaction/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.COUPON_TRX_SEARCH_SUCCESS,
                CONSTANTS.COUPON_TRX_SEARCH_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };
    searchQuery = (val1, val2, val3) => {
        if (val1 === 'id') val3 = parseInt(val3);
        this.setState(
            {
                search_field: val1,
                search_condi: val2,
                search_value: val3,
            },
            () => {
                this.callQueryApiForFetch('SEARCH');
            }
        );
    };

    componentWillMount() {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
    }

    pageNumberOnClick = (pgNumber) => {
        this.callQueryApiForFetch('PAGECLICK', pgNumber);
    };
    hideAlertModal = (e) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };
    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };

    getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.couponno,
            e.storeid,
            e.trtype,
            e.trtypevalue,
            e.trdate,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    render() {
        const {
            couponTrxs,
            languageConfig,
            errormsg: queryError,
            servererror: serverError,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">
                                Coupon Transaction
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                        <div className="col-lg-6 text-right noPadding noMargin">
                            <ExportButton
                                title="Coupon Transaction"
                                tabledata={this.getTableData(
                                    couponTrxs,
                                    this.state.tableCols
                                )}
                            />
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <form className="frmDiv">
                                <div className="row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label
                                            htmlFor="name"
                                            className="floatLeft required"
                                        >
                                            Coupon Code
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            value={this.state.couponcode}
                                            onChange={(e) =>
                                                this.setState({
                                                    couponcode: e.target.value,
                                                })
                                            }
                                        ></input>
                                        <div className="invalid-feedback">
                                            {this.state.couponcodeerror}
                                        </div>
                                    </div>
                                    <div
                                        className="col-lg-4 col-sm-12 btn-container1"
                                        style={{ marginTop: '1em' }}
                                    >
                                        <button
                                            type="submit"
                                            onClick={(e) =>
                                                this._searchCouponTransactions(
                                                    e
                                                )
                                            }
                                            className="btn btn-themes btn-rounded"
                                        >
                                            Search
                                        </button>

                                        <button
                                            type="submit"
                                            onClick={
                                                this._clearFiltersAndResults
                                            }
                                            className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="title_devide" />
                                <SimpleTable
                                    columns={this.state.tableCols}
                                    rows={couponTrxs}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    callback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    // withoutSearchBar={true}
                                    withPageSize={this.state.pageSize}
                                    withCurrPage={this.state.currPage}
                                    withPageFeedback={true}
                                />
                                {/* <TableFooter /> */}
                            </form>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openDeleteprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to delete this coupon
                                inventory?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this.shdDeleteRec(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this.shdDeleteRec(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    isSuccess
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {isSuccess ? this.state.message : ''}
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    _searchCouponTransactions = (e) => {
        if (e) {
            e.preventDefault();
        }
        console.log('_searchCouponTransactions');
        const { couponcode } = this.state;

        this.requestBody = {};
        const queryParams = [];
        let atleastOneFilter = false;
        if (couponcode !== '') {
            queryParams.push(`couponcode=${couponcode}`);
            this.setState({ couponcodeerror: '' });
            this.requestBody['couponcode'] = couponcode;
        } else {
            this.setState({ couponcodeerror: 'Enter Coupon Code' });
            return;
        }

        //validate the filters
        if (queryParams.length <= 0) {
            console.log('select atlease one filter');
            return;
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);

        console.log('requstBody::', this.requestBody);
        //query
        this.props.dispatch(couponTrxSearchByFields(queryParams.join('&')));
        // if (e) { e.preventDefault() }
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            couponcode: '',
            couponcodeerror: '',
        });
        //clear the search results
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(DisplayCouponTransaction);
