import React from "react";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { loginToDashboard } from "../actions/actions";
import Login from "../modules/login/Login";

const LoginPage = (props) => {

  const _loginToDashboard = (login, password) => {
    props.dispatch(loginToDashboard(login, password));
  };

  const _logoutFromDashboard = () => {
    //Not triggered from here..but from button
    //props.dispatch(logoutFromDashboard());
  }

  const { isLoggedIn, apiPending } = props.loginData;

  if (isLoggedIn) {
    return (
      <Switch>
        <Redirect to="/ui/stores" />
      </Switch>
    )
  } else {
    return (
      <Login
        loginToDashboard={_loginToDashboard}
        logoutFromDashboard={_logoutFromDashboard}
        data={props.loginData}
        apiPending={apiPending}
      />
    );
  }
}

const mapStateToProps = state => {
  return { loginData: state.loginData };
};

export default connect(mapStateToProps)(LoginPage);
