import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import Autocomplete from "./../../../components/Autocomplete";
import Modal from "react-awesome-modal";
import { notify } from "react-notify-toast";
import {
    createSaleDocument,
    updateSaleDocument,
    queryCategoryTree,
    getStoreById
} from "../../../actions/actions";

import ConfirmAlert from "../../../components/ConfirmAlert";
import StatusOptionComp from "../../../components/StatusOptionComp";
import { crudReset } from "../../../actions/actions";
import { produce } from "immer";
import DynamicTable from "../../../components/DynamicTable";
import DynamicProductTable from "../../../components/DynamicProductTable";
import CategoryTree from "../../../components/CategoryTree";
import SimpleImageInput from "../../../components/SimpleImageInput";
import { objtoarr, renderCouponOptionsFilter, catTypeOptions } from "../../../helpers/Util";
import Filter from "../../../components/Filter";
import StoreSearchSingle from "../../system/Users/StoreSearchSingle";


class AddSalesDocument extends React.PureComponent {
    statusOptions = [
        { name: "Select", id: "" },
        { name: "New", id: "New" },
        { name: "Active", id: "Active" },
        { name: "Deleted", id: "Deleted" }
    ]

    docoptions = catTypeOptions
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isEdit: false,
            isView: false,
            showProdModal: false,
            showModal: false,
            // Formrelated
            storeid: "",
            title: "",
            status: "Active",
            docgroup: "",
            doctype: "static",
            categorycode: "",
            productcode: "",
            memberid: "",
            products: [
                { productcode: "", title: "", sortorder: "1", id: 0 }
            ],
            docimage: "",
            validfrom: "",
            validtill: "",
            additionalFields: [
                { name: "", value: "", id: 0 }
            ],
            validfrom: new Date(),
            validtill: new Date(),
            storelabel: "",
            // Errors
            titleerror: ""
        };
    }


    handleProductAddRow = e => {
        if (e) e.preventDefault();
        const rowLength = this.state.products.length;
        let lastele = this.state.products[rowLength - 1];

        //console.log('count::', rowLength)
        if (rowLength >= window.config.MAX_QL_ITEMS_PER_CATEGORY) { return }

        const item = {
            productcode: "",
            title: "",
            sortorder: "1",
            id: lastele.id + 1
        };
        this.setState(
            {
                products: [...this.state.products, item]
            },
            () => {
                console.log("Rows After Add", this.state.products);
            }
        );
    };

    handleProductInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === "validation" ? [event.target.value] : event.target.value
        };

        // console.log("row", obj)
        this.setState(
            produce(draft => {
                if (draft.products.length === 0) {
                    draft.products.push(obj);
                } else {
                    let found = false;
                    draft.products.forEach(command => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.products.push(obj);
                    }
                }
            }),
            () => { }
        );
    };
    
    deleteProductRow = row => {
        let rowId = row.id;
        const rowLength = this.state.products.length;

        if (rowLength > 1) {
            const updateValues = this.state.products.filter(
                value => rowId !== value.id
            );

            this.setState({ products: updateValues });
        }
    };

    handleAddRow = e => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            field: "",
            value: "",
            id: lastele.id + 1
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item]
            },
            () => {
                console.log("Rows After Add", this.state.additionalFields);
            }
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === "validation" ? [event.target.value] : event.target.value
        };
        this.setState(
            produce(draft => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach(command => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => { }
        );
    };
    deleteRow = row => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            const updateValues = this.state.additionalFields.filter(
                value => rowId !== value.id
            );

            this.setState({ additionalFields: updateValues });
        }
    };

    formatDate = inpDate => {
        if (inpDate !== "" && typeof inpDate !== "undefined") {
            return moment(inpDate).format("DD-MM-YYYY HH:mm");
        }
    };

    toDate = datestr => {
        if (datestr !== "") {
            return moment(datestr, 'DD-MM-YYYY HH:mm');
        }
    }
    getCreatePayload = () => {
        const {
            doccode,
            storeid,
            title,
            docgroup,
            doctype,
            categorycode,
            productcode,
            memberid,
            products,
            additionalFields,
            docimage,
            status,
            validfrom,
            validtill
        } = this.state;
        let addfield = additionalFields.reduce(function (acc, item) {
            if (typeof item.name !== "undefined" && item.name.trim() !== "" &&
                typeof item.value !== "undefined" && item.value.trim() !== "") {
                acc[item.name] = item.value
                return acc
            }
            return acc
        }, {})
        let prodfield = products.map(e => ({
            productcode: e.productcode,
            title: e.title,
            sortorder: e.sortorder
        }))
        let body = {
            storeid,
            title,
            docgroup,
            doctype,
            categorycode,
            productcode,
            memberid,
            products: prodfield,
            images: {
                image1: docimage || ""
            },
            status,
            validfrom: this.formatDate(validfrom),
            validtill: this.formatDate(validtill),
            additionalfields: addfield
        };
        if (doccode) {
            body['doccode'] = doccode;
        }

        if (this._isEditMode()) {
            body["id"] = this.state.id;
        }
        const payload = {
            body: body
        };
        //console.log(payload);
        return payload;
    };

    _createSaleDocument = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateSaleDocument(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createSaleDocument(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: "Clear all the errors to continue"
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: "",
            titleerror: "",
            rulecodeerror: "",
            rulegrouperror: "",
            storegroupserror: "",
            validfromerror: "",
            validtillerror: "",
            storeidserror: ""
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const {
            title
        } = this.state;
        let valid = true;
        if (title.trim() === "") {
            this.setState({ titleerror: "Please enter a title" });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };


    handleDate = (name, value) => {
        if (value !== "") {
            var dateformat = moment(value, "DD-MM-YYYY HH:mm", true).isValid();
            if (dateformat === true) {
                this.setState({
                    [name]: value
                })
            }
            else {
                this.setState({
                    [name]: ""
                })
            }
        }
    }

    _isViewMode = () => {
        return this.state.isView;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    _onSelectStore = (selectedStores) => {
        this._setTenant(selectedStores.value, selectedStores.label)
    }

    setStoreGroups = value => {
        this.setState({
            storegroups: value
        });
    };

    setCategoryTypeValue = value => {
        this.setState({
            categorytypevalue: value
        })
    }

    _showDynamicModal = (row) => {
        this.setState({
            showModal: true,
            row: row
        });
    }

    _closeDynamicModal = () => {
        this.setState({
            showModal: false
        })
    }


    _showProductModal = (event) => {
        this.setState({
            showProdModal: true
        });
        if (event) event.preventDefault();
    };

    _closeProductModal = event => {
        this.setState({ showProdModal: false });
        if (event) event.preventDefault();
    };

    _renderTableModal = () => {
        return (
            <Modal
                visible={this.state.showModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeDynamicModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a onClick={this._closeDynamicModal} className="closeIcon">
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: "15em" }}>
                        <Autocomplete updateTitle={this._updateCode}></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    }

    _renderProductModal = () => {
        return (
            <Modal
                visible={this.state.showProdModal}
                width="50%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeProductModal()}
            >
                <div className="suggestionForm">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 alignRight">
                            <a onClick={this._closeProductModal} className="closeIcon">
                                X
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ height: "15em" }}>
                        <Autocomplete updateTitle={this._updateProdCode}></Autocomplete>
                    </div>
                </div>
            </Modal>
        );
    };

    _updateProdCode = (param) => {
        this.setState({ productcode: param }, () =>
            this._closeProductModal()
        );
    };

    _updateCode = (param, param1, param2) => {
        const { row, products } = this.state;
        row.productcode = param;
        row.title = param1;
        this.setState({
            row
        })
        let newprodlist = products.map(el => el.id === row.id ? row : el)
        this.setState({
            products: newprodlist
        }, () => {
            this._closeDynamicModal();
        })
        const uniquelist = [...new Map(products.map(item => [item["productcode"], item])).values()]
        if (uniquelist.length < products.length) {
            let myColor = { background: "red", text: "#FFFFFF" };
            notify.show("Product code already added !", "custom", 2000, myColor);
            this.setState({
                products: uniquelist
            })
            return;
        }
    };

    _renderMainButton = () => {

        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/sales-document"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createSaleDocument();
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createSaleDocument}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/sales-document"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };


    _renderMainFormDetails = () => {
        const { categoryTree } = this.props.queryData;

        const newArrayOfObj = [categoryTree].map(this.rename);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store ID
                        </label>
                        {/* <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        /> */}
                        <StoreSearchSingle value={this.state.storelabel}
                            onSelectStore={this._onSelectStore} />
                        <div className="invalid-feedback">{this.state.tenantCodeError}</div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={event => this.setState({ title: event.target.value })}
                        />
                        <div className="invalid-feedback">{this.state.titleError}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="docgroup" className="floatLeft">
                            Document Group
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="docgroup"
                            value={this.state.docgroup}
                            onChange={event => this.setState({ docgroup: event.target.value })}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="doctype" className="floatLeft">
                            Document Type
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderCouponOptionsFilter(this.docoptions)}
                            selectedOption={this.state.doctype}
                            callbackChangeFilter={event => {
                                this.setState({ doctype: event.target.value })
                            }}
                            id="doctype"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="categorycodes" className="floatLeft">
                            Category
                        </label>
                        <CategoryTree
                            treeData={newArrayOfObj}
                            categoryTree={categoryTree}
                            setCategoryCode={(code, title) => {
                                this.setState({ categorycode: code, categories: title });
                            }}
                            value={this.state.categorycode}
                        ></CategoryTree>
                        <div className="invalid-feedback">{this.state.categoryerror}</div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="productcode" className="floatLeft">
                            Product Code
                        </label>
                        <input
                            type="text"
                            defaultValue={this.state.productcode}
                            className="addoninput productcodemodal"
                            readOnly={true}
                            onClick={this._showProductModal}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="memberid" className="floatLeft">
                            Memberid
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="memberid"
                            value={this.state.memberid}
                            onChange={event => this.setState({ memberid: event.target.value })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="validfrom" className="floatLeft">
                            Valid From
                        </label>
                        <Datetime
                            defaultValue={new Date()}
                            minDate={!this._isEditMode() ? new Date() : this.state.validfrom}
                            name="validfrom"
                            dateFormat="DD-MM-YYYY"
                            timeFormat="HH:mm"
                            onChange={(value) => this.handleDate("validfrom", value)}
                            value={this.state.validfrom}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="validtill" className="floatLeft">
                            Valid Till
                        </label>
                        <Datetime
                            defaultValue={new Date()}
                            minDate={!this._isEditMode() ? new Date() : this.state.validtill}
                            name="validtill"
                            dateFormat="DD-MM-YYYY"
                            timeFormat="HH:mm"
                            onChange={(value) => this.handleDate("validtill", value)}
                            value={this.state.validtill}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="docimage" className="floatLeft">
                            Image
                        </label>
                        <SimpleImageInput
                            placeholder="select image"
                            setImage={value => {
                                this.setState({ docimage: value });
                            }}
                            value={this.state.docimage}
                        ></SimpleImageInput>
                    </div>
                </div>
            </React.Fragment>
        );
    };


    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.statusOptions}
                            selectedOption={this.state.status}
                            callbackChangeFilter={value => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderAdditionalFields = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                { dataFieldId: "name", label: "Name", addRow: true },
                                { dataFieldId: "value", label: "Value", addRow: true },
                                { dataFieldId: "AD", label: "" }
                            ]}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderProductInfo = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Products
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicProductTable
                            columns={[
                                { dataFieldId: "productcode", label: "Productcode", addRow: true },
                                { dataFieldId: "title", label: "Title", addRow: true },
                                { dataFieldId: "sortorder", label: "Sort Order", addRow: true },
                                { dataFieldId: "AD", label: "" }
                            ]}
                            rows={this.state.products}
                            deleteRow={this.deleteProductRow}
                            addRow={this.handleProductAddRow}
                            handleProductInputChange={this.handleProductInputChange}
                            showDynamicModal={this._showDynamicModal}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }


    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>
                        <form className="splitFrmDiv text-center" style={{ marginTop: "2em" }}>
                            {this._renderProductInfo()}
                        </form>
                        <form className="splitFrmDiv text-center" style={{ marginTop: "2em" }}>
                            {this._renderAdditionalFields()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form
                            className="splitFrmDivSec text-center"
                            style={{ marginTop: "2em" }}
                        >
                            {this._renderStatusDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    // toDate = dateStr => {
    //     var parts = dateStr.split("-");
    //     return new Date(parts[2], parts[1] - 1, parts[0]);
    // };

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, "PD01"));
    };

    componentWillReceiveProps = () => {
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) this.setState({ storelabel: storeArray.title });
        }
    };

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;
            if (rec) {
                const {
                    id,
                    doccode,
                    docgroup,
                    doctype,
                    title,
                    storeid,
                    categorycode,
                    productcode,
                    memberid,
                    products,
                    additionalfields,
                    images,
                    status,
                    validfrom,
                    validtill
                } = rec;
                this.props.dispatch(getStoreById(storeid));
                let defaultaddField = [{ field: "", value: "", id: 0 }];
                let defaultprodField = [{ productcode: "", title: "", sortorder: "1", id: 0 }];
                let prodlist = [];
                if (products !== null && products.length > 0) {
                    prodlist = products.map((item, id) => ({ ...item, id }));
                }
                this.setState(
                    produce(draft => {
                        draft.id = id ? id : "";
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : "";
                        draft.doccode = doccode ? doccode : "";
                        draft.docgroup = docgroup ? docgroup : "";
                        draft.doctype = doctype ? doctype : "";
                        draft.storeid = storeid ? storeid : "";
                        draft.categorycode = categorycode ? categorycode : "";
                        draft.memberid = memberid ? memberid : "";
                        draft.productcode = productcode ? productcode : "";
                        draft.validfrom = validfrom ? this.toDate(validfrom) : "";
                        draft.validtill = validtill ? this.toDate(validtill) : "";
                        draft.status = status ? status : "Active";
                        draft.docimage = images ? images.image1 : "";
                        draft.products = (products && products !== null) ? prodlist : defaultprodField;
                        draft.additionalFields = additionalfields !== null && typeof additionalfields !== "undefined" &&
                            Object.keys(additionalfields).length !== 0
                            ? objtoarr(additionalfields) :
                            defaultaddField
                    })
                );
            }
        }
    };

    rename = obj => {
        for (var prop in obj) {
            if (Array.isArray(obj[prop])) {
                obj[prop] = obj[prop].map(this.rename);
            }

            if (prop === "subcategories") {
                obj.children = obj[prop];
                delete obj[prop];
            }
            obj["expanded"] = true;
            if (prop === "title") {
                obj['subtitle'] = obj['categorycode']
              }
        }

        return obj;
    };

    hideModal = e => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode() ? "Edit Product Mapping" : this._isViewMode() ? "View Product Mapping" : "Add Product Mapping"}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/sales-document"
                        children={
                            <div style={{ padding: "2em", color: "green" }}>
                                {this._isEditMode()
                                    ? "Product Mapping updated successfully"
                                    : "Product Mapping created successfully"}
                            </div>
                        }
                    />
                </div>
                {this.state.showProdModal && this._renderProductModal()}
                {this.state.showModal && this._renderTableModal()}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData
    };
};

export default connect(mapStateToProps)(AddSalesDocument);
