
export const searchFieldForSlot = [
  { name: "Title", id: "title" },
  { name: "Slot Code", id: "slotcode" },
  { name: "Status", id: "status" }
];

export const statusValues = [
  { name: "All", id: "" },
  { name: "New" , id: "New" },
  { name: "Active", id: "Active" },
  { name: "Deleted", id: "Deleted" }
];
