import React from "react";
import SimpleTable from "../../../components/SimpleTable";
import ExportButton from "../../../components/TableExportButton";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import {
  searchGeneral,
  crudReset,
  deleteCouponTransactionApi,
  resetAction
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import DatePicker from "react-date-picker";
import Modal from "react-awesome-modal";
import {
  dateTypeForCoupon,
  searchFieldForCoupon,
  operators,
  statusValues
} from "./tablesCols";
import { formatDate } from "../../../helpers/Util";
class CouponUtilization extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pages: 10,
      currPage: 0,
      search_field: "couponcode",
      search_condi: "like",
      search_value: "",
      date_from: this.toDate("01-05-2024"),
      date_till: new Date(),
      date_type: "createdon",

      showSearchDiv: false,
      selected: "",
      openDeleteprompt: false,

      tableCols: [
        {
          dataField: "id",
          text: "Id",
          sort: true
        },
        {
          dataField: "couponcode",
          text: "Coupon Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "rulecode",
          text: "Rule Code",
          sort: true
        },
        {
          dataField: "couponno",
          text: "Coupon No",
          sort: true
        },
        {
          dataField: "memberid",
          text: "Member Id",
          sort: true
        },
        {
          dataField: "category",
          text: "Category",
          sort: true
        },
        {
          dataField: "issuemode",
          text: "Issue Mode",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }
  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        <Link
          to={{
            pathname: "/ui/member-coupon/issue-coupon",
            state: {
              item: row,
              isEdit: true
            }
          }}
        >
          <img
            title="Edit"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
          />
        </Link>

        <img
          title="Delete"
          className="tableImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
          onClick={() => this.deleteCouponTrx(row)}
        />
      </span>
    );
  };

  toDate = dateStr => {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };

  handlefromdateChange = value => {
    this.setState({
      date_from: value
    });
  };
  handletodateChange = value => {
    this.setState({
      date_till: value
    });
  };

  // API Calls
  createFetchUrl = () => {
    const {
      search_field,
      search_condi,
      search_value,
      date_from,
      date_till,
      date_type
    } = this.state;
    const requestBody = {
      body: {
        /* pg_sz: pages,
        c_p: currPage */
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value,
        date_from: formatDate(date_from),
        date_till: formatDate(date_till),
        date_type: date_type
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    const { pages, currPage } = this.state;

    this.props.dispatch(
      searchGeneral(
        "campaign/v1/coupon/transaction/search",
        this.createFetchUrl(pages, currPage),
        "SEARCH_MEMBER_PROFILE_SUCCESS",
        "SEARCH_MEMBER_PROFILE_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      default:
        break;
    }
  };

  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage }, () =>
      this.callQueryApiForFetch("ONLOAD")
    );
  }
  // Coupon
  _openSearchDiv = () => {
    this.setState({ showSearchDiv: true });
  };
  _closeSearchDiv = () => {
    this.setState({ showSearchDiv: false });
  };

  getTableData = (records, headers) => {
    const tablerow = records.map(e => [e.couponcode, e.title, e.rulecode, e.couponno, e.memberid, e.category,e.issuemode, e.status]);
    const colheader = headers.filter(e => e.text !== "Actions").map(e => ({ label: e.text, key: e.dataField }))
    tablerow.unshift(colheader.map(e=>e.label));
    return tablerow;
  }

  _renderSearchForm = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="fromDate" className="floatLeft">
              Date From
            </label>
            <DatePicker
              onChange={this.handlefromdateChange}
              value={this.state.date_from}
            />
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="Transaction Date From" className="floatLeft">
              Date To
            </label>
            <DatePicker
              onChange={this.handletodateChange}
              value={this.state.date_till}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="date_type" className="floatLeft">
              Date Type
            </label>
            <div className="row">
              <select
                name="date_type"
                className="simpleSearchSelect2"
                value={this.state.date_type}
                onChange={event =>
                  this.setState({ date_type: event.target.value })
                }
              >
                {dateTypeForCoupon.length !== 0 &&
                  dateTypeForCoupon.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_field" className="floatLeft">
              Search By
            </label>
            <div className="row">
              <select
                name="search_field"
                className="simpleSearchSelect2"
                value={this.state.search_field}
                onChange={event =>
                  this.setState({ search_field: event.target.value })
                }
              >
                {searchFieldForCoupon.length !== 0 &&
                  searchFieldForCoupon.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_condi" className="floatLeft">
              Search Condition
            </label>
            <div className="row">
              <select
                name="search_condi"
                className="simpleSearchSelect2"
                value={this.state.search_condi}
                onChange={event =>
                  this.setState({ search_condi: event.target.value })
                }
              >
                {operators.length !== 0 &&
                  operators.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="search_value" className="floatLeft">
              Search Value
            </label>
            {this.state.search_field !== "status" ? (
              <input
                type="text"
                value={this.state.search_value}
                className="form-control "
                placeholder="Enter value"
                onChange={event =>
                  this.setState({ search_value: event.target.value })
                }
              />
            ) : (
              <select
                name="example"
                className="form-control"
                value={this.state.search_value}
                onChange={event =>
                  this.setState({ search_value: event.target.value })
                }
              >
                {statusValues.length !== 0 &&
                  statusValues.map((option, index) => {
                    const { name, id } = option;
                    return (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            )}
            {/* <input
              type="text"
              className="form-control"
              id="search_value"
              value={this.state.search_value}
              onChange={event => {
                this.setState({ search_value: event.target.value });
              }}
            /> */}
          </div>
        </div>
        <div className="row btn-container1 form-button">
          <div className="col-sm-12 col-lg-8">
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("SEARCH");
                this._closeSearchDiv();
              }}
            >
              Search
            </button>
            <button
              type="submit"
              className="btn btn-themes btn-rounded"
              onClick={event => {
                if (event) event.preventDefault();
                this.callQueryApiForFetch("ONLOAD");
                this._closeSearchDiv();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };
  _renderSearchModal = () => {
    return (
      <Modal
        visible={this.state.showSearchDiv}
        width="50%"
        height="80%"
        effect="fadeInRight"
        onClickAway={() => this._closeSearchDiv()}
      >
        <div className="modalForm queryFormModal">
          <div className="row">
            <div className="col-xs-12 col-md-6 alignLeft">Coupon Search</div>
            <div className="col-xs-12 col-md-6 alignRight">
              <a onClick={this._closeSearchDiv} className="closeIcon">
                X
              </a>
            </div>
          </div>
          <div className="title_devide" />
          {this._renderSearchForm()}
        </div>
      </Modal>
    );
  };
  _renderSearchBtn = () => {
    return (
      <button
        type="submit"
        className="page-header-btn icon_btn display-inline"
        onClick={event => {
          if (event) event.preventDefault();
          this._openSearchDiv();
        }}
      >
        Advanced Filter
      </button>
    );
  };

  _renderCouponPublishedReport = () => {
    const { userProfileList, errormsg: queryError } = this.props.queryData;
    const { errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    if (queryError) {
      errormsg = queryError;
    }
    if (crudError) {
      errormsg = queryError;
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-10 col-sm-12 noPadding noMargin">
            <h3 className="page-title">Coupon List</h3>
          </div>
          <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
            {this._renderSearchBtn()}
          </div>
        </div>
        <div className="title_devide" />
        <div className="invalid-feedback">{errormsg}</div>
        <SimpleTable
          columns={this.state.tableCols}
          rows={userProfileList}
          handleOnSelect={this.handleOnSelect}
          handleOnSelectAll={this.handleOnSelectAll}
          callback={() => {
            this.callQueryApiForFetch("ONLOAD");
          }}
        />
      </React.Fragment>
    );
  };

  // Delete
  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteCouponTransactionApi(this.state.rec_id));
    }
  };
  deleteCouponTrx = row => {
    this.setState({ message: "Coupon transaction deleted successfully" });
    this.setState({ openDeleteprompt: true, rec_id: row.id });
  };
  hideErrorAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
  };
  render() {
    const { userProfileList, errormsg: queryError } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    if (queryError) {
      errormsg = queryError;
    }
    if (crudError) {
      errormsg = crudError;
    }
    if (!userProfileList && !errormsg) {
      return <Loading />;
    }

    if (!userProfileList || this.props.queryData.apiPending) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        {this.state.showSearchDiv && this._renderSearchModal()}
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                Member Coupon
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>
            <div className="col-md-3 text-right noPadding noMargin">
              <ExportButton        
                title="Coupon List"
                tabledata ={this.getTableData(userProfileList, this.state.tableCols)}
              />
            </div>
          </div>

          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                {this._renderCouponPublishedReport()}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this coupon transaction?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={this.hideErrorAlertModal}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(CouponUtilization);
