import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";

import {
  updateUserProfile,
  createUserProfile,
  crudReset,
  getStoreById
} from "../../../actions/actions";

import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";
import TenantCode from "../../../components/TenantCode";
import SimpleSelect from "../../../components/SimpleSelect";
import validator from "validator";
import { formatDate } from "../../../helpers/Util";
import StatusComp from "./../../../components/StatusComp";

class AddWebform extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      userid: "",
      signupby: "",
      socialid: "",
      memberid: "",
      storeid: "",
      firstname: "",
      lastname: "",
      password: "",
      membertype: "",
      membersegment: "",
      dateofbirth: new Date(),
      gender: "",
      mobileno: "",
      emailid: "",
      storegroup: "",
      address: "",
      city: "",
      houseno: "",
      postal: "",
      image1url: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      // error messages
      useriderror: "",
      email: "",
      firstnameerror: "",
      lastnameerror: "",
      doberror: "",
      emailiderror: "",
      mobilenoerror: "",
      memberiderror: "",
      passworderror: "",
      store: ""
    };
  }
  setStoreGroups = value => {
    this.setState({
      storegroup: value
    });
  };
  
  toDate = dateStr => {
    const [day, month, year] = dateStr.split("-");
    return new Date(year, month - 1, day);
  };
  componentWillMount = () => {
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          userid,
          signupby,
          socialid,
          memberid,
          storeid,
          firstname,
          lastname,
          password,
          dateofbirth,
          gender,
          mobileno,
          emailid,
          address,
          city,
          houseno,
          postal,
          membertype,
          membersegment,
          image1url,
          storegroup
        } = rec;
        let imagesObj = image1url !== "" && image1url;
        this.props.dispatch(getStoreById(storeid));
        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.userid = userid ? userid : "";
            draft.signupby = signupby ? signupby : "";
            draft.memberid = memberid ? memberid : "";
            draft.socialid = socialid ? socialid : "";
            draft.storeid = storeid ? storeid : "";
            draft.firstname = firstname ? firstname : "";
            draft.lastname = lastname ? lastname : "";
            draft.password = password ? password : "";
            draft.dateofbirth = dateofbirth ? this.toDate(dateofbirth) : "";

            draft.gender = gender ? gender : "";
            draft.mobileno = mobileno ? mobileno : "";
            draft.emailid = emailid ? emailid : "";
            draft.address = address ? address : "";
            draft.city = city ? city : "";
            draft.houseno = houseno ? houseno : "";
            draft.postal = postal ? postal : "";
            draft.membertype = membertype ? membertype : "";
            draft.membersegment = membersegment ? membersegment : "";
            draft.storegroup = storegroup ? storegroup : "";
            draft.image1url = imagesObj;
          })
        );
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  getCreatePayload = () => {
    const {
      userid,
      signupby,
      socialid,
      memberid,
      storeid,
      firstname,
      lastname,
      password,
      dateofbirth,
      gender,
      mobileno,
      emailid,
      address,
      city,
      houseno,
      postal,
      image1url,
      membertype,
      membersegment
    } = this.state;

    let body = {
      userid: userid,
      signupby: signupby,
      socialid: socialid,
      memberid: memberid,
      storeid: storeid,
      firstname: firstname,
      lastname: lastname,
      password: password,
      dateofbirth: formatDate(dateofbirth),
      membertype,
      membersegment,
      gender: gender,
      mobileno: mobileno,
      emailid: emailid,
      address: address,
      city: city,
      houseno: houseno,
      postal: postal,
      image1url: Object.values(image1url)
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createMemberProfile = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateUserProfile(this.getCreatePayload(), this.state.id)
        );
      } else {
        this.props.dispatch(createUserProfile(this.getCreatePayload()));
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      useriderror: "",
      email: "",
      firstnameerror: "",
      lastnameerror: "",
      doberror: "",
      emailiderror: "",
      mobilenoerror: "",
      memberiderror: "",
      passworderror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const {
      userid,
      firstname,
      emailid,
      lastname,
      dateofbirth,
      mobileno,
      memberid,
      password
    } = this.state;

    let valid = true;
    if (userid.trim() === "") {
      this.setState({
        useriderror: "Please enter user id"
      });
      valid = false;
    }
    if (firstname.trim() === "") {
      this.setState({
        firstnameerror: "Please enter first name"
      });
      valid = false;
    }
    if (lastname.trim() === "") {
      this.setState({
        lastnameerror: "Please enter last name"
      });
      valid = false;
    }
    if (dateofbirth.toString().trim() === "") {
      this.setState({
        doberror: "Please enter date of birth"
      });
      valid = false;
    }
    if (mobileno.trim() === "") {
      this.setState({
        mobilenoerror: "Please enter mobile number"
      });
      valid = false;
    }
    if (password.trim() === "") {
      this.setState({
        passworderror: "Please enter password"
      });
      valid = false;
    }
    if (memberid.trim() === "") {
      this.setState({
        memberiderror: "Please enter memberid"
      });
      valid = false;
    }
    if (emailid.trim() !== "" && !validator.isEmail(emailid)) {
      this.setState({
        emailiderror: "Please enter valid email"
      });
      valid = false;
    }
    if (mobileno.trim() !== "" && !validator.isNumeric(mobileno)) {
      this.setState({
        mobileNoError: "Mobile number entered is not valid"
      });
      valid = false;
    }
    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createMemberProfile()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createMemberProfile()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/survey-webform"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  componentWillReceiveProps = () => {
    const { storeArray } = this.props.queryData;
    if (storeArray) this.setState({ storelabel: storeArray.title });
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className=" row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="storeid" className="floatLeft required">
              Store
            </label>
            <TenantCode
              value={this.state.storelabel}
              setTenantCode={this._setTenant}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="socialid" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="socialid"
              value={this.state.socialid}
              onChange={event => {
                this.setState({ socialid: event.target.value });
              }}
            />
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="memberid" className="floatLeft required">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="memberid"
              value={this.state.memberid}
              onChange={event => {
                this.setState({ memberid: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.memberiderror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Form Type
            </label>
            <SimpleSelect
              objArray={{
                mem: "Member",
                guest: "Guest"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
            <div className="invalid-feedback">{this.state.useriderror}</div>
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={{
                email: "English",
                facebook: "Chinese"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
          </div>
        </div>
        <div className="row ">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="fromdate" className="floatLeft">
              Valid From
            </label>
            <DatePicker
              onChange={this.handleValidFromDateChange}
              value={this.state.fromdate}
            />
          </div>

          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="tilldate" className="floatLeft">
              Valid Till
            </label>
            <DatePicker
              onChange={this.handleValidTillDateChange}
              value={this.state.tilldate}
            />
          </div>
          <div className="invalid-feedback">{this.state.membertrxError}</div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="status" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Question 1
            </label>
            <SimpleSelect
              objArray={{
                select: "Select Question",
                favPro: "What is your favourite product?"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Question 2
            </label>
            <SimpleSelect
              objArray={{
                select: "Select Question",
                1: "What is your top favourite store?",
                2: "Rate your shopping experience?",
                3: "Would you like to recommend to your friends?"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Question 3
            </label>
            <SimpleSelect
              objArray={{
                select: "Select Question",
                1: "What is your top favourite store?",
                2: "Rate your shopping experience?",
                3: "Would you like to recommend to your friends?"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Question 4
            </label>
            <SimpleSelect
              objArray={{
                select: "Select Question",
                1: "What is your top favourite store?",
                2: "Rate your shopping experience?",
                3: "Would you like to recommend to your friends?"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12">
            <label htmlFor="signupby" className="floatLeft">
              Question 5
            </label>
            <SimpleSelect
              objArray={{
                select: "Select Question",
                1: "What is your top favourite store?",
                2: "Rate your shopping experience?",
                3: "Would you like to recommend to your friends?"
              }}
              callbackChangeFilter={value => this.setState({ signupby: value })}
              selectedOption={this.state.signupby}
              id="signupby"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode()
                  ? "Edit Survey Form"
                  : "Add Survey Form"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv text-center">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/survey-webform"
            children={
              <div className="alert alert-success">
                {this._isEditMode()
                  ? "Webform updated successfully"
                  : "Webform created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddWebform);
