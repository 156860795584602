import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createItem, updateItem, crudReset } from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import { produce } from "immer";

class AddSalesCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      title: "",
      categorycode: "",
      description: "",
      pid: 0,
      level: 0,
      titleerror: "",
      categorycodeerror: "",
      descerror: ""
    };
  }
  getCreatePayload = () => {
    const { title, catcode, description, pid, level, id } = this.state;
    let body = {
      categorycode: catcode,
      title: title,
      description: description,
      pid: pid,
      level: level
    };
    if (this.isEditMode()) {
      body["id"] = id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      const isAdd = this.props.location.state.isAdd;
      if (rec) {
        const { id, categorycode, title, description, pid, level } = rec;
        if (isEdit === true) {
          this.setState(
            produce(draft => {
              draft.id = id ? id : "";
              draft.isEdit = isEdit;
              draft.title = title ? title : "";
              draft.categorycode = categorycode ? categorycode : "";
              draft.description = description ? description : [];
              draft.pid = pid ? pid : 0;
              draft.level = level ? level : 0;
            })
          );
        }
        if (isAdd === true) {
          this.setState(
            produce(draft => {
              draft.pid = id ? id : 0;
              draft.level = id ? id : 0;
            })
          );
        }
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  
  getCreatePayload = () => {
    const { title, categorycode, description, pid, level } = this.state;
    let body = {
      title: title,
      categorycode: categorycode,
      description: description,
      pid: pid,
      level: level
    };
    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createSalesCat = () => {
    if (this.validateForm()) {
      if (this._isEditMode()) {
        this.props.dispatch(
          updateItem(
            "sales/v1/category/update",
            this.getCreatePayload(),
            this.state.id
          )
        );
      } else {
        this.props.dispatch(
          createItem("sales/v1/category/save", this.getCreatePayload())
        );
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      titleerror: "",
      categorycodeerror: "",
      descerror: ""
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { title, categorycode, description } = this.state;
    let valid = true;
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter title"
      });
      valid = false;
    }
    if (categorycode.trim() === "") {
      this.setState({
        categorycodeerror: "Please enter category code"
      });
      valid = false;
    }
    if (description.trim() === "") {
      this.setState({
        descerror: "Please enter description"
      });
      valid = false;
    }

    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
               type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createSalesCat()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
               type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createSalesCat()}
          >
            Update
          </button>
        )}

        <Link
          to="/ui/sales-category"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
        >
          Cancel
        </Link>
      </React.Fragment>
    );
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className=" row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="title" className="floatLeft required">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={this.state.title}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.titleerror}</div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="categorycode" className="floatLeft required">
              Category Code
            </label>
            <input
              type="text"
              className="form-control"
              id="categorycode"
              value={this.state.categorycode}
              onChange={event => {
                this.setState({ categorycode: event.target.value });
              }}
            />
            <div className="invalid-feedback">
              {this.state.categorycodeerror}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="description" className="floatLeft required">
              Description
            </label>
            <textarea
              className="form-control"
              id="description"
              value={this.state.description}
              onChange={event => {
                this.setState({ description: event.target.value });
              }}
            />
            <div className="invalid-feedback">{this.state.descerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="pid" className="floatLeft">
              Parent Id
            </label>
            <input
              type="text"
              className="form-control"
              id="pid"
              value={this.state.pid}
              onChange={event => {
                this.setState({ pid: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="level" className="floatLeft">
              Level
            </label>
            <input
              type="text"
              className="form-control"
              id="level"
              value={this.state.level}
              onChange={event => {
                this.setState({ level: event.target.value });
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode()
                  ? "Edit Sales Category"
                  : "Add Sales Category"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv text-center">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              </form>
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/sales-category"
            children={
              <div className="alert alert-success">
                {this._isEditMode()
                  ? "Sales Category updated successfully"
                  : "Sales Category created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddSalesCategory);
