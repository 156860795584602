import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    storeSearchByFields,
} from '../../actions/actions';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import PrimeTable from '../../components/PrimeTable';
//import { parse } from 'lossless-json';
import * as JSONbig from 'json-bigint'
import PrimeStoreSelector from '../../components/PrimeStoreSelector'
import RefreshIcon from '../../components/ImageComponents/RefreshIcon';

const statusOptions = [
    { name: 'Select', code: '' },
    { name: 'New', code: 'New' },
    { name: 'Preparing', code: 'Preparing' },
    { name: 'Ready', code: 'Ready' },
    { name: 'Completed', code: 'Loaded' },
    { name: 'Rejected', code: 'Rejected' },
    { name: 'Canceled', code: 'Canceled' },
];

const ReplenishmentList = (props) => {
    const { stores } = props.queryData;
    const [storeList, setStoreList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [recordList, setRecordList] = useState([])

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(storeSearchByFields(`companyid=${companyId}`));
    }, []);

    const fetchApi = (params) => {
        const URL = window && window.config && window.config.END_POINT;
        const authToken = localStorage.getItem('session');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', authToken);
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };
        
        const baseUrl = `${URL}/ext-ns/v1/inventory/replenishment`;
        
        const queryParams = new URLSearchParams(params).toString();
        
        const url = `${baseUrl}?${queryParams}`;

        fetch(
            url,
            requestOptions,
        )
            .then((response) => response.text())
            .then((result) => {
                const json = JSONbig.parse(result)
                let e = json.map((d) => {
                    let { id, ...rest } = d;
                    return {
                        id: d.id.c && d.id.c.length > 0 ? d.id.c.join('') : d.id,
                        ...rest,
                    };
                });
                setRecordList(e);
            })
            .catch((error) => console.error(error));
    }

    const pageSizeChangecallback = (recordCount) => {
        setPageSize(recordCount);
        setCurrPage(1)
        fetchApi({
            status: searchParams.status ? searchParams.status : '',
            storeid: searchParams.storeid ? searchParams.storeid : '',
            terminalid: searchParams.terminalid ? searchParams.terminalid : '',
            pagesize: recordCount,
            pageno: 1,
        });
    };

    const pageChangeCallback = (pageNo) => {
        setCurrPage(pageNo);
        fetchApi({
            status: searchParams.status ? searchParams.status : '',
            storeid: searchParams.storeid ? searchParams.storeid : '',
            terminalid: searchParams.terminalid ? searchParams.terminalid : '',
            pagesize: pageSize,
            pageno: pageNo,
        });
    };

    const handleRefresh = () => {
        if (searchParams.storeid) {
            fetchApi({
                status: searchParams.status ? searchParams.status : '',
                storeid: searchParams.storeid ? searchParams.storeid : '',
                terminalid: searchParams.terminalid ? searchParams.terminalid : '',
                pagesize: pageSize,
                pageno: currPage
            }); 
        }
    }

    // useEffect(() => {
    //     //initLoad();
    //     fetchApi({ pagesize: pageSize, pageno: currPage })
    // }, []);

    useEffect(() => {
        const options = stores.map((e) => ({
            label: '(' + e.storeid + ') ' + e.title,
            value: e.storeid,
        }));
        setStoreList(options);
    }, [stores]);

    const handleSearch = (data) => {
        setRecordList([])
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
        setSearchParams(data);

        const params = {};
        pageSize && (params.pagesize = pageSize);
        currPage && (params.pageno = currPage);
        data.status && (params.status = data.status);
        data.terminalid && (params.terminalid = data.terminalid);
        data.storeid && (params.storeid = data.storeid)
        data.invrequestref && (params.invrequestref = data.invrequestref)
        fetchApi(params)
    };

    const actionTemplate = (row) => {
        return (
            <span>
                <Link
                    to={{
                        pathname: '/ui/replenishment/create',
                        state: {
                            item: row,
                            isEdit: true,
                        },
                    }}
                >
                    <Button
                        label="View Load Document"
                        className="p-button-outlined"
                    />
                </Link>
            </span>
        );
    };

    const statusTemplate = (row) => {
        const { status } = row;
        if (status === 'Request') {
            return 'New';
        }
        if (status === 'Loaded') {
            return 'Completed';
        }
        if (status === 'Reject') {
            return 'Rejected';
        }
        return status;
    };

    const columns = [
        { field: 'storeid', header: 'Store Id', sortable: true },
        { field: 'terminalid', header: 'Terminal Id', sortable: true, sortable: true },
        { field: 'invallocationid', header: 'Inv Allocation Id', sortable: true, sortable: true },
        { field: 'invrequestref', header: 'Request Id', sortable: true },
        { field: 'requestedon', header: 'Request Date', sortable: true },
        { field: 'requestedby', header: 'Requestor', sortable: true },
        { field: 'status', header: 'Status', template: statusTemplate, sortable: true }
    ];

    return (
        <div className="form_height">
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <h2 className="page-header">
                        Replenishment List
                        <RefreshIcon 
                            handleClick={handleRefresh}
                        />
                    </h2>
                </div>
            </div>
            <div className="frmDiv mx-3 mt-3">
                <FilterForm
                    handleSearch={handleSearch}
                    storeList={storeList}
                />
                <div className="row mt-4">
                    <div className="col-lg-12 col-sm-12">
                        <PrimeTable
                            list={recordList}
                            columns={columns}
                            actionColumn={actionTemplate}
                            pageSizeChangecallback={(pageSize) => {
                                pageSizeChangecallback(pageSize);
                            }}
                            pageChangeCallback={(currPage) => {
                                pageChangeCallback(currPage);
                            }}
                        >
                        </PrimeTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FilterForm = (props) => {
    const { handleSearch } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));

    const [selectedStore, setSelectedStore] = useState({});

    const formik = useFormik({
        initialValues: {
            storeid: '',
            terminalid: '',
            invrequestref: '',
            status: statusOptions.find((status) => status.code == ''),
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                status: values.status ? values.status.code : '',
                storeid: storeid,
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(
                    ([_, value]) => value !== undefined && value !== ''
                )
            );
            if (filteredData.storeid) {
                console.log('pass', filteredData)
                handleSearch(filteredData);
            }
        },
    });

    useEffect(() => {
        if (localStorage.getItem('storeid')) {
            handleSearch({ storeid: localStorage.getItem('storeid')})
        }
    }, [])

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleStoreSelection = (selectedStore) => {
        const { label, value } = selectedStore
        setStoreid(selectedStore.value);
        setSelectedStore(selectedStore);
        localStorage.setItem('storeid', value);
        localStorage.setItem('storelabel', label);
    };

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-2 md:col-2 mb-2">
                        <label htmlFor="storeid">Select Store*</label>
                        <PrimeStoreSelector
                            storeList={props.storeList}
                            handleStoreSelection={handleStoreSelection}
                            selectedStore={selectedStore}
                            required
                        />
                        {getFormErrorMessage('storeid')}
                    </div>
                    <div className="field col-2 md:col-2">
                        <label htmlFor="status">Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-2 md:col-2 mb-2">
                        <label
                            htmlFor="terminalid"
                            className={classNames({
                                'p-error': isFormFieldValid('terminalid'),
                            })}
                        >
                            Terminal Id
                        </label>
                        <InputText
                            id="terminalid"
                            value={formik.values.terminalid}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="field col-2 md:col-2 mb-2">
                        <label
                            htmlFor="invrequestref"
                            className={classNames({
                                'p-error': isFormFieldValid('invrequestref'),
                            })}
                        >
                            Request Id
                        </label>
                        <InputText
                            id="invrequestref"
                            value={formik.values.invrequestref}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div>
                <div className="field col-2 md:col-2 mb-2" style={{ display: 'flex' }}>
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                            {/* <Button
                                type="button"
                                label="Cancel"
                                className="p-button-secondary mr-4"
                            /> */}
                        </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ReplenishmentList);
