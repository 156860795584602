import produce from 'immer';
import React from 'react';
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import {
    apiLogsSearchByFields,
    crudReset,
    resetAction,
    searchGeneral,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { formatDate, permissions } from '../../../helpers/Util';
import * as CONSTANTS from '../../../constants/constants';
import ViewApiLog from './ViewApiLog';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import PrimeTable from '../../../components/PrimeTable';

class ApiLog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'LOGS';
        this.group = 'DE_LOGS_APILOGS';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 100;

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            date_from: new Date(),
            date_till: new Date(),
            fromtime: '00:00',
            tilltime: '23:59',
            requesttext: '',
            responsetext: '',
            headerval: '',

            deviceid: '',
            ipaddress: '',
            uri: '',
            serviceid: '',
            requestid: '',
            responsestatus: '',
            querystring: '',
            pagesize: this.pageSize,

            showLogDetails: false,
            selectedLog: {},
        };
    }

    actionTemplate = (row) => {
      return (
        <span>
            <img
                title="View"
                className="tableImage"
                src={`${process.env.PUBLIC_URL}/assets/icons/ic_preview.svg`}
                onClick={() => this.viewLogDetails(row)}
            />
        </span>
      );
    }

    viewLogDetails = (row) => {
        this.setState({ selectedLog: row, showLogDetails: true });
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage, date_from, date_till, fromtime, tilltime } =
            this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    pagesize: pageSize,
                    pageno: currPage,
                    date_from: formatDate(date_from),
                    date_till: formatDate(date_till),
                    date_from_time: fromtime,
                    date_till_time: tilltime,
                },
            };
            return requestBody;
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                'system/v1/apilog/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_API_LOGS_SUCCESS,
                CONSTANTS.SEARCH_API_LOGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
            default:
                break;
        }
    };

    componentWillMount() {
        this.callQueryApiForFetch('ONLOAD');
        this.allPermissions = permissions(this.module, this.group);
    }

    handleChange(time, name) {
        let timeformat = moment(time).format('HH:mm');
        this.setState({ [name]: timeformat }, () => {
            //this.updateStateValues();
        });
    }

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };

    getTableData = (records, headers) => {
        const tablerow = records.map((e) => [
            e.id,
            e.title,
            e.promocode,
            e.promogroup,
            e.storeid,
            e.promotypevalue,
            e.categorytypevalue,
            e.validfrom,
            e.validtill,
            e.status,
        ]);
        const colheader = headers
            .filter((e) => e.text !== 'Actions')
            .map((e) => ({ label: e.text, key: e.dataField }));
        tablerow.unshift(colheader.map((e) => e.label));
        return tablerow;
    };

    _hideLogDetails = () => {
        this.props.queryData.apilog = {};
        this.setState({ showLogDetails: false });
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            apilogs,
        } = this.props.queryData;
        const { isSuccess, errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        const columns = [
          { field: 'requestid', header: 'Rq Id', sortable: true },
          { field: 'serviceid', header: 'Service Id', sortable: true },
          { field: 'uri', header: 'URI', sortable: true },
          { field: 'ipaddress', header: 'ClientIp', sortable: true },
          { field: 'processtime', header: 'Process Time', sortable: true },
          { field: 'deviceid', header: 'Device', sortable: true },
          { field: 'responsestatus', header: 'Status' },
          { field: 'createdon', header: 'Created On' },
      ];

        if (this.state.showLogDetails) {
            return (
                <>
                    <ViewApiLog
                        rec={this.state.selectedLog}
                        logid={this.state.selectedLog.id}
                        hideLogDetails={this._hideLogDetails}
                    />
                </>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="form_height">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2 className="page-header">Api Logs</h2>
                            </div>
                        </div>

                        <div className="row form-container">
                            <div className="col">
                                <form className="frmDiv">
                                    <div className="row">
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft required">
                                                Date From
                                            </label>
                                            <DatePicker
                                                onChange={(value) => {
                                                    this.setState({
                                                        date_from: value,
                                                    });
                                                }}
                                                value={this.state.date_from}
                                            />
                                        </div>
                                        <div className="form-group col-lg-1 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                From Time
                                            </label>
                                            <TimePicker
                                                value={
                                                    moment(
                                                        this.state.fromtime,
                                                        'HH:mm'
                                                    ).isValid()
                                                        ? moment(
                                                              this.state
                                                                  .fromtime,
                                                              'HH:mm'
                                                          )
                                                        : ''
                                                }
                                                name="fromtime"
                                                onChange={(time) =>
                                                    this.handleChange(
                                                        time,
                                                        'fromtime'
                                                    )
                                                }
                                                showSecond={false}
                                                allowEmpty={false}
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft required">
                                                Date Till
                                            </label>
                                            <DatePicker
                                                minDate={this.state.date_from}
                                                onChange={(value) => {
                                                    this.setState({
                                                        date_till: value,
                                                    });
                                                }}
                                                value={this.state.date_till}
                                            />
                                        </div>
                                        <div className="form-group col-lg-1 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Till Time
                                            </label>
                                            <TimePicker
                                                value={
                                                    moment(
                                                        this.state.tilltime,
                                                        'HH:mm'
                                                    ).isValid()
                                                        ? moment(
                                                              this.state
                                                                  .tilltime,
                                                              'HH:mm'
                                                          )
                                                        : ''
                                                }
                                                name="tilltime"
                                                onChange={(time) =>
                                                    this.handleChange(
                                                        time,
                                                        'tilltime'
                                                    )
                                                }
                                                showSecond={false}
                                                allowEmpty={false}
                                            />
                                        </div>
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Service Id
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="serviceid"
                                                value={this.state.serviceid}
                                                onChange={(event) =>
                                                    this.setState({
                                                        serviceid:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Uri
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="uri"
                                                value={this.state.uri}
                                                onChange={(event) =>
                                                    this.setState({
                                                        uri: event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Query String
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="querystring"
                                                value={this.state.querystring}
                                                onChange={(event) =>
                                                    this.setState({
                                                        querystring:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        {/* <div className="col-lg-2"> </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Status
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="responsestatus"
                                                value={
                                                    this.state.responsestatus
                                                }
                                                onChange={(event) =>
                                                    this.setState({
                                                        responsestatus:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Client Ip
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="ipaddress"
                                                value={this.state.ipaddress}
                                                onChange={(event) =>
                                                    this.setState({
                                                        ipaddress:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Device Id
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="deviceid"
                                                value={this.state.deviceid}
                                                onChange={(event) =>
                                                    this.setState({
                                                        deviceid:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Request
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="requesttext"
                                                value={this.state.requesttext}
                                                onChange={(event) =>
                                                    this.setState({
                                                        requesttext:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Response
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="responsetext"
                                                value={this.state.responsetext}
                                                onChange={(event) =>
                                                    this.setState({
                                                        responsetext:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-2 col-sm-12">
                                            <label className="col-form-label alignLeft">
                                                Header
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="headerval"
                                                value={this.state.headerval}
                                                onChange={(event) =>
                                                    this.setState({
                                                        headerval:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div
                                            className="col-lg-4 col-sm-12 btn-container1"
                                            style={{ marginTop: '1em' }}
                                        >
                                            <button
                                                type="submit"
                                                onClick={(e) =>
                                                    this._searchApiLogs(e)
                                                }
                                                className="btn btn-themes btn-rounded"
                                            >
                                                Search
                                            </button>

                                            <button
                                                type="submit"
                                                onClick={
                                                    this._clearFiltersAndResults
                                                }
                                                className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    </div>

                                    <div className="title_devide" />
                                    <PrimeTable
                                        list={apilogs}
                                        columns={columns}
                                        actionColumn={this.actionTemplate}
                                        linkColumn={this.linkTemplate}
                                        tablePageSize={100}
                                        pageSizeChangecallback={(pageSize) => {
                                          this.setState(
                                            produce(this.state, (draft) => {
                                                if (
                                                    draft.pageSize <
                                                    pageSize
                                                ) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                        }}
                                        pageChangeCallback={(currPage) => {
                                          this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                        <ConfirmAlert
                            show={isSuccess}
                            handleClose={this.hideAlertModal}
                            children={
                                <div
                                    style={
                                        isSuccess
                                            ? { padding: '2em', color: 'green' }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {isSuccess ? this.state.message : ''}
                                </div>
                            }
                        />

                        <ConfirmAlert
                            show={errormsg}
                            handleClose={(event) =>
                                this.hideErrorAlertModal(event)
                            }
                            children={
                                <div
                                    style={
                                        errormsg
                                            ? {
                                                  padding: '2em',
                                                  color: 'red',
                                                  fontWeight: '500',
                                              }
                                            : { padding: '2em', color: 'red' }
                                    }
                                >
                                    {errormsg ? `${errormsg}.` : ''}
                                </div>
                            }
                        />
                    </div>
                </React.Fragment>
            );
        }
    }

    _searchApiLogs = (e) => {
        if (e) {
            e.preventDefault();
        }
        const {
            date_from,
            date_till,
            fromtime,
            tilltime,
            deviceid,
            serviceid,
            ipaddress,
            responsestatus,
            requestid,
            uri,
            querystring,
            requesttext,
            responsetext,
            headerval,
        } = this.state;

        this.requestBody = {};
        const queryParams = [];

        if (date_from === '' && date_till === '') {
            //do nothing
        } else {
            if (date_from !== '' && date_till !== '') {
                queryParams.push(`date_from=${formatDate(date_from)}`);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            } else if (date_from !== '') {
                queryParams.push(`date_from=${formatDate(date_from)}`);
                queryParams.push(`date_till=${formatDate(date_from)}`);
            } else if (date_till !== '') {
                queryParams.push(`date_from=${formatDate(date_till)}`);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            }
            this.requestBody['date_from'] = formatDate(date_from);
            this.requestBody['date_till'] = formatDate(date_till);
            this.requestBody['date_till_time'] = tilltime;
            this.requestBody['date_from_time'] = fromtime;
            queryParams.push(`date_till_time=${tilltime}`);
            queryParams.push(`date_from_time=${fromtime}`);
        }

        if (responsestatus !== '') {
            queryParams.push(`responsestatus=${responsestatus}`);
            this.requestBody['responsestatus'] = responsestatus;
        }
        if (serviceid !== '') {
            queryParams.push(`serviceid=${serviceid}`);
            this.requestBody['serviceid'] = serviceid;
        }
        if (uri !== '') {
            queryParams.push(`uri=${uri}`);
            this.requestBody['uri'] = uri;
        }
        if (ipaddress !== '') {
            queryParams.push(`ipaddress=${ipaddress}`);
            this.requestBody['ipaddress'] = ipaddress;
        }
        if (querystring !== '') {
            queryParams.push(`querystring=${querystring}`);
            this.requestBody['querystring'] = querystring;
        }
        if (deviceid !== '') {
            queryParams.push(`deviceid=${deviceid}`);
            this.requestBody['deviceid'] = deviceid;
        }
        if (requestid !== '') {
            queryParams.push(`requestid=${requestid}`);
            this.requestBody['requestid'] = requestid;
        }
        if (requesttext !== '') {
            queryParams.push(`requesttext=${requesttext}`);
            this.requestBody['requesttext'] = requesttext;
        }
        if (responsetext !== '') {
            queryParams.push(`responsetext=${responsetext}`);
            this.requestBody['responsetext'] = responsetext;
        }
        if (headerval !== '') {
            queryParams.push(`headerval=${headerval}`);
            this.requestBody['headerval'] = headerval;
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);

        //console.log('requstBody::', this.requestBody);
        //query
        this.props.dispatch(apiLogsSearchByFields(queryParams.join('&')));
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        //clear all the filters
        this.setState({
            uri: '',
            querystring: '',
            serviceid: '',
            date_from: new Date(),
            date_till: new Date(),
            fromtime: '00:00',
            tilltime: '23:59',
            requestid: '',
            responsestatus: '',
            ipaddress: '',
            deviceid: '',
            requesttext: '',
            responsetext: '',
            headerval: '',
        });
        //clear the search results
    };
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(ApiLog);
