import React from "react";
import { withRouter } from "react-router";

const LinkButton = ({
  history,
  className,
  to,
  onClick,
  children,
  disabled,
  buttonType,
  param
}) => {
  return (
    <button
      className={className}
      type={(buttonType || "submit")}
      disabled={disabled}
      onClick={event => {
        onClick && onClick(event);
        history.push(to, { param: param });
      }}
    >
      {children}
    </button>
  );
};

export default withRouter(LinkButton);
