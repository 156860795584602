export const headers = [
  {
    dataField: "tenant",
    label: "Store"
  },
  {
    dataField: "jan",
    label: "Jan"
  },
  {
    dataField: "feb",
    label: "Feb"
  },
  {
    dataField: "mar",
    label: "Mar"
  },
  {
    dataField: "apr",
    label: "Apr"
  },
  {
    dataField: "may",
    label: "May"
  },
  {
    dataField: "jun",
    label: "Jun"
  },
  {
    dataField: "jul",
    label: "July"
  },
  {
    dataField: "aug",
    label: "Aug"
  },
  {
    dataField: "sep",
    label: "Sep"
  },
  {
    dataField: "oct",
    label: "Oct"
  },
  {
    dataField: "nov",
    label: "Nov"
  },
  {
    dataField: "dec",
    label: "Dec"
  }
];

export const rows = [
  {
    tenant: "Bedok Store",
    jan: "$1,200",
    feb: "$2,000",
    mar: "$3,500",
    apr: "$3,500",
    may: "$3,500",
    jun: "$3,500",
    jul: "$2,500",
    aug: "$3,500",
    sep: "$4,500",
    oct: "$120",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Little India Store",
    jan: "$1,260",
    feb: "$2,200",
    mar: "$3,540",
    apr: "$3,550",
    may: "$3,500",
    jun: "$3,500",
    jul: "$250",
    aug: "$350",
    sep: "$400",
    oct: "$100",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Jurong Store",
    jan: "$1,260",
    feb: "$2,200",
    mar: "$3,540",
    apr: "$3,550",
    may: "$3,500",
    jun: "$3,500",
    jul: "$250",
    aug: "$350",
    sep: "$400",
    oct: "$50",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Changi Store",
    jan: "$1,260",
    feb: "$2,200",
    mar: "$3,540",
    apr: "$3,550",
    may: "$3,500",
    jun: "$3,500",
    jul: "$250",
    aug: "$350",
    sep: "$400",
    oct: "$50",
    nov: "-",
    dec: "-"
  },
  {
    tenant: "Outram Store",
    jan: "$1,260",
    feb: "$2,200",
    mar: "$3,540",
    apr: "$3,550",
    may: "$3,500",
    jun: "$3,500",
    jul: "$250",
    aug: "$350",
    sep: "$400",
    oct: "$50",
    nov: "-",
    dec: "-"
  }
];
