import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchGeneral, storeTagsSearchByFields, storeSearchByFields } from '../../actions/actions';
import * as CONSTANTS from '../../constants/constants';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import TerminalSummaryPdf from './BIRReportTable/TerminalSummaryPdf';
import PrimeStoreSelector from '../../components/PrimeStoreSelector';

const BIRSalesSummaryReport = (props) => {
    const { specialDiscountSummary, stores } = props.queryData;
    const [reportData, setReportData] = useState([])
    const [exportVisible, setExportVisible] = useState(false);
    const [reportDate, setReportDate] = useState('')
    const [storeList, setStoreList] = useState([])

    const companyId = localStorage.getItem('company_id');

    useEffect(() => {
        props.dispatch(storeSearchByFields(`companyid=${companyId}`));
        getVersion()
    }, [])

    const createFetchUrl = (params) => {
        props.dispatch(
            searchGeneral(
                'pos/v1/terminal/report/termz/summary',
                params,
                CONSTANTS.SPECIAL_DISCOUNT_SUMMARY_SUCCESS,
                CONSTANTS.SPECIAL_DISCOUNT_SUMMARY_FAILURE
            )
        );
    };

    useEffect(() => {
        if (specialDiscountSummary.length > 0) {
            const sortData = specialDiscountSummary.sort((a, b) => a.resetno - b.resetno);
            setReportData(sortData)
        }
    }, [specialDiscountSummary])

    useEffect(() => {
        const options = stores.map((e) => ({
            label: '(' + e.storeid + ') ' + e.title,
            value: e.storeid,
        }));
        setStoreList(options)
    }, [stores])

    const queryStoreBIRInfo = (data) => {
        let queryParam = `storeid=${data.storeid}&taggroup=storeprops&tagtype=birinfo&status=Active`;
            props.dispatch(
                storeTagsSearchByFields(
                    queryParam,
                    CONSTANTS.SEARCH_STORE_BIRINFO_FIELDS_SILENT,
                    CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_SUCCESS,
                    CONSTANTS.SEARCH_STORE_BIRINFO_SILENT_FAILURE
                )
            );
    };

    const queryTerminalBirInfo = (data) => {
        const requestBody = {
            body: {
                tagtype: 'birinfo',
                storeid: data.storeid,
                taggroup: 'tprops',
                terminalid: data.terminalid,
            },
        };
        props.dispatch(
            searchGeneral(
                'system/v1/store/tag/search/fields',
                requestBody,
                CONSTANTS.SEARCH_STORE_TAGS_SUCCESS,
                CONSTANTS.SEARCH_STORE_TAGS_FAILURE
            )
        );
    };

    const getVersion = () => {
        const requestBody = {
            body: {
                language: 'en',
                search_field: 'wrdcode',
                search_condi: 'like',
                search_value: 'Fixed',
            },
        }
        props.dispatch(
            searchGeneral(
                'cms/v1/word/translation/search',
                requestBody,
                'SEARCH_ARTICLE_INFO_SUCCESS',
                'SEARCH_ARTICLE_INFO_FAILURE'
            )
        );
    }

    const handleSearch = (data) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });
        queryStoreBIRInfo(data);
        queryTerminalBirInfo(data);
        setReportDate('From ' + moment(data.date_from).format('MM-DD-YYYY') + ' ' + 'Till ' + moment(data.date_till).format('MM-DD-YYYY'))
        const parsedValues = {
            ...data,
            date_from: data.date_from
                ? moment(data.date_from).format('DD-MM-YYYY')
                : '',
            date_till: data.date_till
                ? moment(data.date_till).format('DD-MM-YYYY')
                : '',
        };
        const requestBody = {
            body: {
                ...parsedValues
            },
        };
        createFetchUrl(requestBody);
    };

    const handlePdfRender = () => {
        setExportVisible(true);
    };

    const getPdf = () => {
        const { storeBIRInfo, storeTags, articleFlowData } = props.queryData;
        const terminalInfo = storeTags.length > 0 ? storeTags[0] : {}
        const storeInfo = storeBIRInfo.length > 0 ? storeBIRInfo[0] : {}
        const versionArr = articleFlowData.filter(a => a.language == 'en')
        return (
            <TerminalSummaryPdf
                data={reportData}
                storeInfo={storeInfo}
                terminalInfo={terminalInfo}
                reportDate={reportDate}
                versionList={versionArr}
            />
        );
    };
    return (
        <>
            <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">BIR Sales Summary Report</h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <ReportFilter
                                    handleSearch={handleSearch}
                                    storeList={storeList}
                                />
                    
                                {reportData.length > 0 &&  (
                                <div className="row mt-4">
                                    <Button
                                        style={{
                                            marginLeft: 'auto',
                                            marginRight: '20px',
                                        }}
                                        label="Export/Download Report"
                                        className="p-button-success"
                                        onClick={handlePdfRender}
                                    />
                                </div>
                            )}
                            {exportVisible && (
                                <Dialog
                                    visible={exportVisible}
                                    style={{ width: '80vw' }}
                                    onHide={() =>
                                        setExportVisible(!exportVisible)
                                    }
                                >
                                    {getPdf()}
                                </Dialog>
                            )}
                                <div className="row mt-4">
                                    <div className="col-lg-12 col-sm-12">
                                        <RenderTable data={reportData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};

const getDateFormat = (dateStr) => {
    const dateParts = dateStr.split(' ');
    const dateArr = dateParts[0].split('-')
    return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2]
};

const RenderTable = (props) => {
    const { data } = props;

    return (
        <div className='table-wrapper' style={{ overflowX: 'auto'}}>
            <table className='pdf-table' cellSpacing="0" border="0">
            <thead>
                <tr>
                    <td colSpan={33} height="29" align="center" valign="bottom" style={{ fontWeight: 'bold'}}>
                        BIR SALES SUMMARY REPORT
                    </td>
                </tr>
                <tr>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={4}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                        style={{ borderLeft: '0px' }}
                        width={140}
                    >
                        Date
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Beginning SI/OR No.
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Ending SI/OR No.
                    </td>
                    
                    
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#00B0F0"
                    >
                        Grand Accum. Sales Ending Balance
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#00B0F0"
                    >
                        Grand Accum. Beg.Balance
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#00B0F0"
                    >
                        Sales Issued w/ Manual SI/OR (per RR 16-2018)
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#00B0F0"
                    >
                        Gross Sales for the Day
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#FFFF00"
                    >
                        VATable Sales
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#FFFF00"
                    >
                        VAT Amount
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#FFFF00"
                    >
                        VAT-Exempt Sales
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#FFFF00"
                    >
                        Zero-Rated Sales
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        colSpan={8}
                        align="center"
                        valign="bottom"
                        bgcolor="#FFC000"
                        style={{ borderBottom: '0px' }}
                    >
                        Deductions
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        colSpan={7}
                        align="center"
                        valign="bottom"
                        bgcolor="#92D050"
                        style={{ borderBottom: '1px solid #dee2e6' }}
                    >
                        Adjustment on VAT
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        VAT Payable
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Net Sales
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Sales Overrun /Overflow
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Total Income
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Reset Counter
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Z -Counter
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        style={{
                            borderTop: '1px solid #dee2e6',
                            borderLeft: '1px solid #dee2e6',
                        }}
                        rowSpan={3}
                        align="center"
                        valign="middle"
                        bgcolor="#A6A6A6"
                    >
                        Remarks
                    </td>
                    <td align="left" valign="bottom"></td>
                </tr>
                <tr>
                    <td
                        className="pv-12 ph-12"
                        colSpan={5}
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                        style={{
                            borderTop: '1px solid #dee2e6',
                            borderLeft: '1px solid #dee2e6',
                        }}
                    >
                        Discount
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={2}
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        Returns
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={2}
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        Voids
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={2}
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        Total Deductions
                    </td>
                    <td
                        className="pv-12 ph-12"
                        colSpan={4}
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                        style={{ borderLeft: '1px solid #dee2e6' }}
                    >
                        Discount
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={2}
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                        style={{ borderTop: 'none' }}
                    >
                        VAT on Returns
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={2}
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                        style={{ borderTop: '0px' }}
                    >
                        Others
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        rowSpan={2}
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                        style={{ borderTop: '0px' }}
                    >
                        Total VAT Adjustment
                    </td>
                    <td align="left" valign="bottom"></td>
                </tr>
                <tr>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        SC
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        PWD
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        NAAC
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        Solo Parent
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#FFC000"
                    >
                        Others
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                    >
                        SC
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                    >
                        PWD
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                    >
                        Solo Parent
                    </td>
                    <td
                        className="pv-12 ph-12 th-border f-12"
                        align="center"
                        valign="middle"
                        bgcolor="#92D050"
                    >
                        Others
                    </td>
                    <td align="left" valign="bottom"></td>
                </tr>
            </thead>
            <tbody>
                {data.length == 0 && (
                    <tr>
                        <td colSpan={20} style={{ textAlign: 'center', padding: '16px'}}>
                            Your search does not retrieve any data. Please search again using another criteria.
                        </td>
                    </tr>
                )}
                
                {data.map((item, index) => (
                    <tr key={index}>
                        <td
                            className="t-cell"
                            align="center"
                            valign="bottom"
                            // style={{ height: '51px', width: '100px', display: 'block'}}
                        >
                            {getDateFormat(item.trxdate)}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                          {item.invoiceresetno}-{item.invoicenotxtfrom}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                          {item.invoiceresetno}-{item.invoicenotxttill}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                            {item.newgtotal_saleamt}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                            {item.oldgtotal_saleamt}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                          
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.grosssales.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatablesales.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatgrossamount.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatexemptsales.toFixed(2)}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                          {item.zeroratedsales ? item.zeroratedsales.toFixed(2) : ''}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_sc.toFixed(2)}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                            {item.deduction_pwd.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_naac.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_sp.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_regular}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_return.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_void.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.deduction_total.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatadj_sc.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatadj_pwd.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatadj_sp.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatadj_regular}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.vatadj_return.toFixed(2)}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                            sdval="0"
                        >
                            {item.vatadj_void}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                            {item.vatadjustment.toFixed(2)}
                        </td>
                        <td
                            className="t-cell"
                            align="right"
                            valign="bottom"
                        >
                            {item.vatamount}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.netsales}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.saleoverrunamt}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.totalincome.toFixed(2)}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                            {item.invoiceresetno}
                        </td>
                        <td className="t-cell" align="right" valign="bottom">
                           {item.resetno}
                        </td>
                        <td className="t-cell" align="right" valign="bottom" style={{ borderRight: 'none'}}>
                            
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};

const ReportFilter = (props) => {
    const { handleSearch } = props;
    const [storeid, setStoreid] = useState(localStorage.getItem('storeid'));

    const [selectedStore, setSelectedStore] = useState({})

    const formik = useFormik({
        initialValues: {
            date_from: new Date(),
            date_till: new Date(),
            terminalid: '',
        },
        validate: (data) => {
            let errors = {};
            if (!data.terminalid) {
                errors.terminalid = 'Terminal id is required.';
            }
            if (!storeid) {
                errors.storeid = 'Store id is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                storeid: storeid
            };
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== undefined && value !== "")
            );
            handleSearch(filteredData);
        },
    });

    const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleStoreSelection = (selectedStore) => {
        setStoreid(selectedStore.value)
        setSelectedStore(selectedStore)
    }

    const renderFormArea = () => {
        return (
            <>
                <div className="row p-fluid formgrid grid w-100">
                    <div className="p-form-group field col-3 md:col-3 mb-2">
                        <label htmlFor="storeid">Select Store</label>
                        <PrimeStoreSelector
                                storeList={props.storeList}
                                handleStoreSelection={
                                    handleStoreSelection
                                }
                                selectedStore={selectedStore}
                                required
                            />
                        {getFormErrorMessage('storeid')}
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label 
                            htmlFor="date_from"
                            className={classNames({
                                'p-error': isFormFieldValid('terminalid'),
                            })}
                        >
                            Terminal Id
                        </label>
                        <InputText
                            id="terminalid"
                            value={formik.values.terminalid}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('terminalid')}
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            maxDate={formik.values.date_till}
                        />
                    </div>
                    <div className="field col-3 md:col-3 mb-2">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={formik.handleChange}
                            dateFormat="dd-mm-yy"
                            minDate={formik.values.date_from}
                        />
                    </div>
                    <div
                        className="field col-3 md:col-3 mb-2"
                        style={{ display: 'flex' }}
                    >
                        <div style={{ marginTop: '28px' }}>
                            <Button
                                type="submit"
                                label="Search"
                                className="mr-4"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(BIRSalesSummaryReport);
