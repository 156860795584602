// Local imports
import * as constants from "../constants/constants";

const initialState = {
  isInitial: true,
  auth_2f: "",
  authtoken: "",
  userProfile: [],
  isLoginSuccess: false,
  loginError: false,
  loginErrorMsg: "",
  language: "en",
  selectedlanguage: "",
  isLoggedIn: false,
  firstname: "",
  lastname: "",
  pinRequestData: {},
  errorPinMessage: "",
  errorPinRequestMessage: "",
  apiPending: false
};

const loginReducer = (state = initialState, { type, data, loginErrorMsg }) => {

// console.log(type)
// console.log(data)

  switch (type) {
    case constants.API_PENDING:
      return {
        ...state,
        apiPending: true
      };
    case constants.LOGIN_SUCCESS: {
      localStorage.setItem("session", data.authtoken);
      localStorage.setItem("fname", data.firstname);
      localStorage.setItem("lname", data.lastname);
      localStorage.setItem("userId", data.userid);
      localStorage.setItem("roles", data.roles);
      localStorage.setItem("roles_access", data.roles_access);
      localStorage.setItem("folderaccess", data.folderaccess);
      localStorage.setItem("storegroup_access", data.storegroup_access);
      localStorage.setItem("permission", data.permission);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("company_id", data.companyid);
      localStorage.setItem("store_access", data.store_access)
      localStorage.setItem("user_group", data.usergroup);
      localStorage.setItem("user_access", JSON.stringify(data.useraccess));

      //console.log('localstorage::' + localStorage.getItem("isLoggedIn"))
      //console.log('user_access::' + localStorage.getItem("user_access"))
      return {
        ...state,
        isInitial: false,
        auth_2f: data.auth_2f,
        authtoken: data.authtoken,
        userProfile: data,
        isLoginSuccess: true,
        loginError: false,
        loginErrorMsg: "",
        firstname: data.firstname,
        lastname: data.lastname,
        isLoggedIn: data.authtoken ? true : false,
        apiPending: false
      };
    }
    case constants.LOGIN_FAILURE: {
      localStorage.setItem("session", "");
      localStorage.setItem("fname", "");
      localStorage.setItem("lname", "");
      localStorage.setItem("roles", "");
      localStorage.setItem("roles_access", "");
      localStorage.setItem("folderaccess", "");
      localStorage.setItem("storegroup_access", "");
      localStorage.setItem("store_access", "")
      localStorage.setItem("permission", "");
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("company_id", "");
      localStorage.setItem("user_group", "");
      localStorage.setItem("user_access", "");

      return {
        ...state,
        auth_2f: "",
        isInitial: false,
        authtoken: "",
        userProfile: [],
        isLoginSuccess: false,
        loginError: true,
        firstname: "",
        lastname: "",
        loginErrorMsg: loginErrorMsg,
        isLoggedIn: false,
        apiPending: false
      };
    }
    case constants.LOGOUT_SUCCESS: {
      localStorage.setItem("session", "");
      localStorage.setItem("fname", "");
      localStorage.setItem("lname", "");
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("company_id", "");
      localStorage.setItem("store_access", "");
      localStorage.setItem("user_group", "");
      localStorage.setItem("user_access", "");
      return {
        ...state,
        auth_2f: "",
        isInitial: false,
        authtoken: "",
        userProfile: [],
        isLoginSuccess: false,
        loginError: true,
        firstname: "",
        lastname: "",
        loginErrorMsg: "",
        isLoggedIn: false,
        apiPending: false
      };
    }
    case constants.LOGOUT_FAILURE: {
      localStorage.setItem("session", data.authtoken);
      localStorage.setItem("fname", data.firstname);
      localStorage.setItem("lname", data.lastname);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("company_id", data.companyid);
      localStorage.setItem("store_access", data.store_access)
      localStorage.setItem("user_group", data.usergroup);
      localStorage.setItem("user_access", data.useraccess);

      return {
        ...state,
        auth_2f: "",
        authtoken: data.authtoken,
        isInitial: false,
        userProfile: data.data,
        isLoginSuccess: true,
        loginError: false,
        firstname: data.firstname,
        lastname: data.lastname,
        loginErrorMsg: data.errorMessage,
        isLoggedIn: true,
        apiPending: false
      };
    }
    case constants.ENTER_PIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        errorPinMessage: ""
      };
    }
    case constants.ENTER_PIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        errorPinMessage: data.errorPinMessage
      };
    }
    case constants.PIN_REQUEST: {
      return {
        ...state,
        pinRequestData: {}
      };
    }
    case constants.PIN_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        pinRequestData: data,
        errorPinMessage: "",
        errorPinRequestMessage: ""
      };
    }
    case constants.PIN_REQUEST_FAILURE: {
      return {
        ...state,
        pinRequestData: {},
        errorPinRequestMessage: ""
      };
    }
    case constants.UPDATE_DEFAULT_CONFIG: {
      return {
        ...state,
        language: data.language,
        selectedlanguage: data
      };
    }    
    default:
      return state;
  }
};
export default loginReducer;
