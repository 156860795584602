import React, {useState, useEffect} from "react";
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css'

const DropdownTree = (props) => {

  const { data, data1, ...rest } = props;

  return <DropdownTreeSelect data={data} {...rest} className="bootstrap-demo"/>

}

export default DropdownTree;
