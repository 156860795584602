import React, {useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import { updateUserProfile, createUserProfile, crudReset} from '../../../../actions/actions';
import ConfirmAlert from '../../../../components/ConfirmAlert';
import SimpleSelect from '../../../../components/SimpleSelect';
import MultiSelect from '../../../../components/MultiSelect';
import { getElevateDateFromJsDate, getJsDateFromElevateDate } from '../../../../helpers/DateUtil';
import { renderConfigInMultiSelect } from '../../../../helpers/Util';
import ImagePick from '../../../../components/ImagePick'
import {MEMBER_GROUP, MEMBER_SIGNUP} from './const';
import formik from '../../../../hooks/useFormik'
import * as Yup from 'yup';

const _validationSchema= Yup.object().shape({
       firstname: Yup.string()
         .max(200, 'Must be 200 characters or less')
         .required('Required'),
       lastname: Yup.string()
         .max(200, 'Must be 200 characters or less')
         .required('Required'),
       dateofbirth: Yup.date()
         .typeError('Specify a date')
         .required('Required'),
       gender: Yup.string()
         .oneOf(['M','F','O'])
         .required('Required'),
       mobileno: Yup.string()
         .max(20, 'Must be 20 characters or less')
         .matches(/^\+?[0-9]{2} [0-9]{5,16}$/, 'Valid phone is +99 99999999999')
         .required('Required'),
       emailid: Yup.string()
         .email('Invalid email address')
         .required('Required'),
       image1url: Yup.object()
         .shape({
           image1: Yup.string().required('Required')
         })
     })

const AddTechnicianManager = (props) => {
  const storeGroupTitle = JSON.parse(localStorage.getItem('storegrouptitle'));

  const isEditMode = useMemo(() => {
    if (props.location && props.location.state) {
      return props.location.state.isEdit
    }
    return false;
  }, [props.location])

  const technicianInfo = useMemo(() => {
    let rec = {}
    if (props.location && props.location.state) {
      rec = props.location.state.item || {}
    }

    return {
      id: (rec.id || ''),
      userid: (rec.userid || ''),
      signupby: (rec.signupby || MEMBER_SIGNUP),
      memberid: (rec.memberid || ''),
      membergroup: (rec.membergroup || MEMBER_GROUP),
      membergroupid: (rec.membergroupid || ''),
      socialid: (rec.socialid || ''),
      firstname: (rec.firstname || ''),
      lastname: (rec.lastname || ''),
      password: (rec.password || ''),
      dateofbirth: rec.dateofbirth ? getJsDateFromElevateDate(rec.dateofbirth) : '',
      accesstype: (rec.accesstype || ''),
      accesstypevalue: (rec.accesstypevalue || ''),
      gender: (rec.gender || ''),
      mobileno: (rec.mobileno || ''),
      emailid: (rec.emailid || ''),
      address: (rec.address || ''),
      city: (rec.city || ''),
      houseno: (rec.houseno || ''),
      postal: (rec.postal || ''),
      membertype: (rec.membertype || ''),
      membersegment: (rec.membersegment || ''),
      storegroup: rec.storegroup ? rec.storegroup.split(',') : [],
      image1url: (rec.image1url? { image1: rec.image1url } : {})
    }
  }, [props.location])

  const _getCreatePayload = (values) => {
    let body = {
      userid: values.userid.trim() === '' ? values.emailid: values.userid,
      accesstype: values.accesstype,
      accesstypevalue: values.accesstypevalue,
      signupby: values.signupby,
      socialid: values.socialid,
      firstname: values.firstname,
      lastname: values.lastname,
      dateofbirth: getElevateDateFromJsDate(values.dateofbirth),
      membergroup: values.membergroup,
      membergroupid: values.groupid,
      membertype: values.membertype,
      membersegment: values.membersegment,
      gender: values.gender,
      mobileno: values.mobileno,
      emailid: values.emailid,
      address: values.address,
      city: values.city,
      houseno: values.houseno,
      postal: values.postal,
      image1url: values.image1url.image1 || '',
      storegroup: values.storegroup.toString()
    };

    // if(values.password !== '' ) {
    //   body.password = values.password;
    // }

    if (isEditMode && technicianInfo.id) {
      body['id'] = technicianInfo.id;
    }

    return {
      body: body
    };
  }

  const _submitForm = (values, { setSubmitting }) => {
    if (isEditMode) {
      props.dispatch(updateUserProfile(_getCreatePayload(values)))
    } else {
      props.dispatch(createUserProfile(_getCreatePayload(values)))
    }
    setSubmitting(false)
  }

  const _hideModal = useCallback(e => {
    e.preventDefault();
    props.dispatch(crudReset());
  }, [props])

  const {values, errors, setFieldValue, handleSubmit, handleChange, handleBlur, isSubmitting, validateForm} = formik(technicianInfo, _validationSchema, _submitForm)

  const _renderErrorMessage = useMemo(() => {
    const { isSuccess, errormsg } = props.crudData;
    if ((errormsg && !isSuccess)) {
      return (
        <div className='alert alert-danger' role='alert'>
          {errormsg}
        </div>
      );
    }
    return <React.Fragment/>;
  },[props.crudData])

  const _renderFieldError = useMemo(() => (
    <div className='alert alert-danger' role='alert'>
      Clear all errors
    </div>
  ), [])

  const _renderFooter = useMemo(() => (
    <div className='py-5'></div>
  ), [])

  const _renderFormInputs = useMemo(() => {
    return (
      <>
        <h3 className="text-left">System Information</h3>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='memberid' className='floatLeft'>
              Member Id
            </label>
            <input
              disabled
              type='text'
              className='form-control'
              id='memberid'
              value={values.memberid}
              onChange={()=>{ }}
            />
          </div>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='membergroup' className='floatLeft'>
              Member Group
            </label>
            <input
              disabled
              type='text'
              className='form-control'
              id='membergroup'
              value={values.membergroup}
              onChange={()=>{}}
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='storegroup' className='floatLeft'>
              Store Group
            </label>
            <MultiSelect
              filterOptions={renderConfigInMultiSelect(storeGroupTitle)}
              setSelectedFieldValues={value => {setFieldValue('storegroup', value)}}
              id='storegroup'
              label='Store Group'
              callbackChangeFilter={handleChange}
              itemsSelected={values.storegroup}
            />
          </div>
        </div>
        <div className="title_devide"></div>
        <h3 className="text-left">Technician Information</h3>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='firstname' className='floatLeft required'>
              First Name
            </label>
            <input
              type='text'
              className={errors.firstname? 'form-control' : 'error-control'}
              id='firstname'
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.firstname}</div>
          </div>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='lastname' className='floatLeft required'>
              Last Name
            </label>
            <input
              type='text'
              id='lastname'
              className={errors.lastname? 'form-control' : 'error-control'}
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.lastname||''}</div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='emailid' className='floatLeft required'>
              Email Id
            </label>
            <input
              type='text'
              className={errors.emailid ? 'form-control' : 'error-control'}
              id='emailid'
              value={values.emailid}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.emailid}</div>
          </div>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='mobileno' className='floatLeft required'>
              Mobile No
            </label>
            <input
              type='text'
              className={errors.mobileno? 'form-control' : 'error-control'}
              id='mobileno'
              value={values.mobileno}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.mobileno}</div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='dateofbirth' className='floatLeft required'>
              Date of Birth
            </label>
            <DatePicker onChange={value => {setFieldValue("dateofbirth", value, false)}}
              value={values.dateofbirth}
              className={errors.dateofbirth? '' : 'error-control'}
            />
            <div className='invalid-feedback'>{errors.dateofbirth||''}</div>
          </div>
          <div className='form-group col-lg-6 col-sm-12'>
            <label htmlFor='gender' className='floatLeft required'>
              Gender
            </label>
            <SimpleSelect
              objArray={{
                M: 'Male',
                F: 'Female',
                O: 'Others'
              }}
              callbackChangeFilter={value => {setFieldValue("gender", value, false)}}
              selectedOption={values.gender}
              id='gender'
              cssclassName={errors.gender ? 'form-control' : 'error-control'}
            />
            <div className='invalid-feedback'>{errors.gender||''}</div>
          </div>
          <div className="title_devide"></div>
          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor='image1url' className='floatLeft required'>
                Technician ID
              </label>
            </div>
          </div>
          <div className="row imgPickRow">
            <ImagePick
              id='image1url'
              images={values.image1url}
              setImages={(value) => {setFieldValue('image1url', value, false)}}
              limit={1}
            />

            <div className="form-group col-lg-6 col-sm-12">
              <div className='invalid-feedback'>{errors.image1url?(errors.image1url.image1||''):''}</div>
            </div>
          </div>
        </div>
      </>
    )
  },[values, errors])

  const { isSuccess } = props.crudData;
  return (
    <React.Fragment>
      <div className='form_height'>
        <div className='row'>
          <div className='col-md-9'>
            <h2 className='page-header'>
              {isEditMode? 'Edit Technician Manager Profile': 'Add Technician Manager Profile'}
            </h2>
          </div>
        </div>
        <div className='row form-container'>
          <div className='col'>
              <form className='frmDiv text-center' onSubmit={
                (event)=>{
                  event.preventDefault()
                  validateForm().then((errors)=>{
                    if(errors && Object.keys(errors).length !== 0) {
                      window.scrollTo(0,0)
                    }
                    else {
                      handleSubmit()
                    }
                  })
                }
              }>
                {Object.keys(errors).length !== 0 && _renderFieldError}
                {_renderErrorMessage}
                {_renderFormInputs}
                {_renderFooter}
                <div className='row btn-container form-button py-4'>
                  <div className='col-12'>
                    <button
                      type='submit'
                      className='btn btn-themes btn-rounded' disabled={isSubmitting}
                    >
                      {isEditMode?'Update':'Create'}
                    </button>
                    <button
                      onClick={props.history.goBack}
                      className='btn btn-themes btn-rounded btn-sec link-sec-btn'
                    >Cancel</button>
                  </div>
                </div>
              </form>
          </div>
        </div>
        <ConfirmAlert
          show={isSuccess}
          handleClose={_hideModal}
          to='/ui/technicianmanager-registration'
          children={
            <div style={{ color: 'green', padding: '2em' }}>
              {isEditMode
                ? 'Technician Manager profile updated successfully'
                : 'Technician Manager profile created successfully'}
            </div>
          }
        />
      </div>
    </React.Fragment>
  );

}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddTechnicianManager);
