import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import {
    createPointRule,
    updatePointRule,
    queryCategoryTree,
    getStoreById,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import StatusOptionComp from '../../../components/StatusOptionComp';
import { crudReset } from '../../../actions/actions';
import { produce } from 'immer';
import DynamicTable from '../../../components/DynamicTable';
import {
    formatDate,
    objtoarr,
    renderConfigInMultiSelect,
    renderOptionsFilter,
} from '../../../helpers/Util';
import TenantCode from './../../../components/TenantCode';
import MultiSelect from '../../../components/MultiSelect';
import SimpleDropdown from '../../../components/SimpleDropdown';
import Filter from '../../../components/Filter';
import StoreSearchSingle from '../../system/Users/StoreSearchSingle';

class AddPointRule extends React.PureComponent {
    ruletype = [
        { id: '', name: 'Select' },
        { id: 'Item', name: 'Item' },
    ];
    rulegroup = [
        { id: '', name: 'Select' },
        { id: 'Cart', name: 'Cart' },
        { id: 'RabbitCart', name: 'RabbitCart' },
    ];
    valueby = [{ title: 'Quantity', value: 'Qty' }];
    categorypoints = [
        { id: '', name: 'Select' },
        { id: 'Reward', name: 'Reward' },
    ];
    pointearnvalidityby = [{ title: 'Month', value: 'MM' }];
    categorytype = [
        { id: '', name: 'Select' },
        { id: 'Category', name: 'Category' },
    ];
    statusCoupon = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'Save', id: 'Save' },
        { name: 'Update', id: 'Update' },
        { name: 'Expired', id: 'Expired' },
    ];
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,

            // Formrelated
            rulecode: '',
            title: '',
            ruletype: '',
            rulegroup: '',
            storegroups: [],
            closedatearr: [],
            storeid: '',
            categorytype: '',
            categorytypevalue: [],
            ctypevalueoptions: {},
            cateroryfilerby: '',
            categoryfiltervalues: '',
            valueby: '',
            valuemin: '',
            valuemax: '',
            pointearncategory: '',
            pointearn: '',
            pointearnvalidityby: '',
            pointvaliditybyvalue: '',
            pointburncategory: '',
            pointburn: '',
            validby: '',
            validfrom: '',
            validtill: '',
            opentime: moment(),
            closetime: moment(),
            validdays: '',
            valuebatch: '',
            closedates: '',
            status: 'Active',
            sortorder: 1,
            additionalFields: [{ field: '', value: '', id: 0 }],
            storelabel: '',
            storegrouptitle: {},
            // Errors
            titleerror: '',
            rulecodeerror: '',
            rulegrouperror: '',
            validfromerror: '',
            validtillerror: '',
        };
    }

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const rowLength = this.state.additionalFields.length;
        let lastele = this.state.additionalFields[rowLength - 1];
        const item = {
            field: '',
            value: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                additionalFields: [...this.state.additionalFields, item],
            },
            () => {
                console.log('Rows After Add', this.state.additionalFields);
            }
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.additionalFields.length === 0) {
                    draft.additionalFields.push(obj);
                } else {
                    let found = false;
                    draft.additionalFields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.additionalFields.push(obj);
                    }
                }
            }),
            () => {}
        );
    };
    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.additionalFields.length;

        if (rowLength > 1) {
            /*  this.setState(
              produce(draft => {
                const objIndex = draft.additionalFields.findIndex(
                  field => field.id === rowId
                );
                if (objIndex > -1) {
                  draft.additionalFields.splice(objIndex, 1);
                }
              })
            ); */
            const updateValues = this.state.additionalFields.filter(
                (value) => rowId !== value.id
            );

            this.setState({ additionalFields: updateValues });
        }
    };

    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };
    getCreatePayload = () => {
        const {
            rulecode,
            title,
            ruletype,
            rulegroup,
            storegroups,
            storeid,
            categorytype,
            categorytypevalue,
            cateroryfilerby,
            categoryfiltervalues,
            valueby,
            valuemin,
            valuemax,
            pointearncategory,
            pointearn,
            pointearnvalidityby,
            pointvaliditybyvalue,
            pointburncategory,
            pointburn,
            validby,
            validfrom,
            validtill,
            opentime,
            closetime,
            validdays,
            valuebatch,
            closedates,
            closedatearr,
            status,
            sortorder,
            additionalFields,
        } = this.state;
        let addfield = additionalFields.reduce(function (acc, item) {
            if (item.field.trim() !== '' && item.value.trim() !== '') {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {});
        let body = {
            rulecode,
            title,
            ruletype,
            rulegroup,
            storegroups: storegroups.toString(),
            storeids: storeid,
            categorytype,
            categorytypevalue: categorytypevalue.toString(),
            cateroryfilerby,
            categoryfiltervalues,
            valueby,
            valuemin: parseInt(valuemin) || '',
            valuemax: parseInt(valuemax) || '',
            pointearncategory,
            pointearn: parseInt(pointearn) || '',
            pointearnvalidityby,
            pointvaliditybyvalue: parseInt(pointvaliditybyvalue) || '',
            pointburncategory,
            pointburn: parseInt(pointburn) || '',
            validby,
            validfrom: formatDate(validfrom),
            validtill: formatDate(validtill),
            opentime,
            closetime,
            validdays: parseInt(validdays) || '',
            valuebatch: parseInt(valuebatch) || '',
            closedates: closedatearr.toString().replace(/^,|,$/g, ''),
            status,
            sortorder,
            additionalfields: addfield,
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }
        const payload = {
            body: body,
        };
        return payload;
    };

    _createPointRule = () => {
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updatePointRule(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createPointRule(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };
    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            rulecodeerror: '',
            rulegrouperror: '',
            storegroupserror: '',
            validfromerror: '',
            validtillerror: '',
            storeidserror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { rulecode, title, rulegroup, validfrom, validtill } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }
        if (rulecode.trim() === '') {
            this.setState({ rulecodeerror: 'Please enter rule code' });
            valid = false;
        }
        if (rulegroup.trim() === '') {
            this.setState({ rulegrouperror: 'Please enter rule group' });
            valid = false;
        }
        if (validfrom.toString().trim() === '') {
            this.setState({ validfromerror: 'Please select a from date' });
            valid = false;
        }
        if (validtill.toString().trim() === '') {
            this.setState({ validtillerror: 'Please enter to date' });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _setTenant = (p1, p2) => {
        this.setState({ storeid: p1, storelabel: p2 });
    };

    setStoreGroups = (value) => {
        this.setState({
            storegroups: value,
        });
    };

    setCategoryTypeValue = (value) => {
        this.setState({
            categorytypevalue: value,
        });
    };

    handleChange(time, name) {
        let timeformat = moment(time).format('HH:mm');
        this.setState({
            [name]: timeformat,
        });
    }

    buttonHanlder = (value) => {
        var dateformat = [
            ('0' + value.getDate()).slice(-2) +
                '-' +
                ('0' + (value.getMonth() + 1)).slice(-2) +
                '-' +
                value.getFullYear(),
        ];
        this.setState((prevState) => ({
            closedatearr: [...prevState.closedatearr, ...dateformat],
        }));
    };

    textareaHandler = (val) => {
        const { closedatearr } = this.state;
        let filterdata = closedatearr.filter((item) => item !== val);
        this.setState({
            closedatearr: filterdata,
        });
    };

    _renderMainButton = () => {
        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={() => {
                            this._createPointRule();
                        }}
                    >
                        Save
                    </button>
                )}
                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={this._createPointRule}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/point-rule"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    onSelectStore = () => {

    }

    _renderStoreDetails = () => {
        const { storegrouptitle } = this.state;
        //const { storeGrpConfig } = this.props.queryData;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="storecodes" className="floatLeft">
                            Store Id
                        </label>
                        {/* <TenantCode
                            value={this.state.storelabel}
                            setTenantCode={this._setTenant}
                        /> */}
                        <StoreSearchSingle
                            value={this.state.storelabel}
                            onSelectStore={this._setTenant}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMainFormDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="rulecode"
                            className="floatLeft required"
                        >
                            Rule Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="rulecode"
                            value={this.state.rulecode}
                            onChange={(event) =>
                                this.setState({ rulecode: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.rulecodeerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="rulegrp" className="floatLeft required">
                            Rule Group
                        </label>
                        <SimpleDropdown
                            id="rulegroup"
                            listoptions={this.rulegroup}
                            selectedOption={this.state.rulegroup}
                            callbackChangeFilter={(value) => {
                                this.setState({ rulegroup: value });
                            }}
                        />
                        {/* <input
                            type="text"
                            className="form-control"
                            id="rulegrp"
                            value={this.state.rulegroup}
                            onChange={event =>
                                this.setState({ rulegroup: event.target.value })
                            }
                        /> */}
                        <div className="invalid-feedback">
                            {this.state.rulegrouperror}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="ruletype" className="floatLeft">
                            Rule Type
                        </label>
                        <SimpleDropdown
                            id="ruletype"
                            listoptions={this.ruletype}
                            selectedOption={this.state.ruletype}
                            callbackChangeFilter={(value) => {
                                this.setState({ ruletype: value });
                            }}
                        />
                        {/* <input
                            type="text"
                            className="form-control"
                            id="ruletype"
                            value={this.state.ruletype}
                            onChange={event => this.setState({ ruletype: event.target.value })}
                        /> */}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderGeneralInformation = () => {
        const { closedatearr } = this.state;
        let checkempty = closedatearr.filter((e) => e !== '');
        let closedates = [...new Set(checkempty)];
        var contentareastyle = {
            display: 'inline-flex',
            flexFlow: 'wrap',
            border: '1px solid #dcdcdc',
            padding: '10px',
            overflowY: 'scroll',
            maxHeight: '200px',
            float: 'left',
        };
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="validfrom"
                            className="floatLeft required"
                        >
                            Valid From Date
                        </label>
                        <DatePicker
                            minDate={
                                !this._isEditMode()
                                    ? new Date()
                                    : this.state.validfrom
                            }
                            onChange={(value) => {
                                this.setState({ validfrom: value });
                            }}
                            value={this.state.validfrom}
                        />
                        <div className="invalid-feedback">
                            {this.state.validfromerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="validtill"
                            className="floatLeft required"
                        >
                            Valid Till Date
                        </label>
                        <DatePicker
                            minDate={this.state.validfrom}
                            onChange={(value) => {
                                this.setState({ validtill: value });
                            }}
                            value={this.state.validtill}
                        />
                        <div className="invalid-feedback">
                            {this.state.validtillerror}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="validby" className="floatLeft">
                            Valid By
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validby"
                            value={this.state.validby}
                            onChange={(event) =>
                                this.setState({ validby: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="validdays" className="floatLeft">
                            Valid Days
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="validdays"
                            value={this.state.validdays}
                            min="0"
                            onChange={(event) =>
                                this.setState({ validdays: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="opentime" className="floatLeft">
                            Open Time
                        </label>
                        <div className="timer">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.opentime,
                                        'HH:mm'
                                    ).isValid()
                                        ? moment(this.state.opentime, 'HH:mm')
                                        : ''
                                }
                                onChange={(stime) =>
                                    this.handleChange(stime, 'opentime')
                                }
                                name="opentime"
                                showSecond={false}
                            />
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="closetime" className="floatLeft">
                            Close Time
                        </label>
                        <div className="timer">
                            <TimePicker
                                value={
                                    moment(
                                        this.state.closetime,
                                        'HH:mm'
                                    ).isValid()
                                        ? moment(this.state.closetime, 'HH:mm')
                                        : ''
                                }
                                onChange={(etime) =>
                                    this.handleChange(etime, 'closetime')
                                }
                                name="closetime"
                                showSecond={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="closedates" className="floatLeft">
                            Close Date
                        </label>
                        {/* <input
                            type="text"
                            className="form-control"
                            id="closedates"
                            value={this.state.closedates}
                            onChange={event =>
                                this.setState({ closedates: event.target.value })
                            }
                        /> */}
                        <DatePicker
                            locale="en-GB"
                            onChange={(value) => {
                                this.setState({ closedates: value }, () => {
                                    this.buttonHanlder(value);
                                });
                            }}
                            value={new Date()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <div style={contentareastyle}>
                            {closedates.map((datevalue) => {
                                return (
                                    <div
                                        className="text-content"
                                        key={datevalue.toString()}
                                    >
                                        <input
                                            type="text"
                                            value={datevalue}
                                            className="inputfield"
                                            readOnly
                                        />
                                        <button
                                            className="btn-primary btntext"
                                            type="button"
                                            onClick={() =>
                                                this.textareaHandler(datevalue)
                                            }
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderStatusDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Actions</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.statusCoupon}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label
                            htmlFor="sortorder"
                            className="floatLeft required"
                        >
                            Sort Order
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sortorder"
                            value={this.state.sortorder}
                            min="0"
                            onChange={(event) =>
                                this.setState({ sortorder: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderPointFields = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="pointearncategory"
                            className="floatLeft"
                        >
                            Point Earn Category
                        </label>
                        <SimpleDropdown
                            id="pointearncategory"
                            listoptions={this.categorypoints}
                            selectedOption={this.state.pointearncategory}
                            callbackChangeFilter={(value) => {
                                this.setState({ pointearncategory: value });
                            }}
                        />
                        {/* <input
                            type="text"
                            className="form-control"
                            id="pointearncategory"
                            value={this.state.pointearncategory}
                            onChange={event =>
                                this.setState({ pointearncategory: event.target.value })
                            }
                        /> */}
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointearn" className="floatLeft">
                            Point Earn
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointearn"
                            value={this.state.pointearn}
                            min="0"
                            onChange={(event) =>
                                this.setState({ pointearn: event.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="pointearnvalidityby"
                            className="floatLeft"
                        >
                            Point Earn Validity
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(
                                this.pointearnvalidityby
                            )}
                            selectedOption={this.state.pointearnvalidityby}
                            callbackChangeFilter={(event) => {
                                this.setState({
                                    pointearnvalidityby: event.target.value,
                                });
                            }}
                            id="pointearnvalidityby"
                        />
                        {/* <input
                            type="text"
                            className="form-control"
                            id="pointearnvalidityby"
                            value={this.state.pointearnvalidityby}
                            onChange={event =>
                                this.setState({ pointearnvalidityby: event.target.value })
                            }
                        /> */}
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="pointvaliditybyvalue"
                            className="floatLeft"
                        >
                            Point Validity Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointvaliditybyvalue"
                            value={this.state.pointvaliditybyvalue}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    pointvaliditybyvalue: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="pointburncategory"
                            className="floatLeft"
                        >
                            Point Burn Category
                        </label>
                        <SimpleDropdown
                            id="pointburncategory"
                            listoptions={this.categorypoints}
                            selectedOption={this.state.pointburncategory}
                            callbackChangeFilter={(value) => {
                                this.setState({ pointburncategory: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="pointburn" className="floatLeft">
                            Point Burn
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="pointburn"
                            value={this.state.pointburn}
                            min="0"
                            onChange={(event) =>
                                this.setState({ pointburn: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderCategoryFields = () => {
        const { ctypevalueoptions } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="categorytype" className="floatLeft">
                            Category Type
                        </label>
                        <SimpleDropdown
                            id="categorytype"
                            listoptions={this.categorytype}
                            selectedOption={this.state.categorytype}
                            callbackChangeFilter={(value) => {
                                this.setState({ categorytype: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="categorytypevalue"
                            className="floatLeft"
                        >
                            Category Type Value
                        </label>
                        <MultiSelect
                            filterOptions={renderConfigInMultiSelect(
                                ctypevalueoptions
                            )}
                            setSelectedFieldValues={this.setCategoryTypeValue}
                            id="categorytypevalue"
                            label="Select CategoryType value"
                            itemsSelected={this.state.categorytypevalue}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="cateroryfilerby" className="floatLeft">
                            Category Filter By
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="categoryfilerby"
                            value={this.state.categoryfilerby}
                            onChange={(event) =>
                                this.setState({
                                    categoryfilerby: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="categoryfiltervalue"
                            className="floatLeft"
                        >
                            Category Filter Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="categoryfiltervalue"
                            value={this.state.categoryfiltervalues}
                            onChange={(event) =>
                                this.setState({
                                    categoryfiltervalues: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="valueby" className="floatLeft">
                            Value By
                        </label>
                        <Filter
                            cssClass="form-control"
                            filterOptions={renderOptionsFilter(this.valueby)}
                            selectedOption={this.state.valueby}
                            callbackChangeFilter={(event) => {
                                this.setState({ valueby: event.target.value });
                            }}
                            id="valueby"
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="valuebatch" className="floatLeft">
                            Value Batch
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="valuebatch"
                            value={this.state.valuebatch}
                            min="0"
                            onChange={(event) =>
                                this.setState({
                                    valuebatch: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="valuemin" className="floatLeft">
                            Minimum Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="valuemin"
                            value={this.state.valuemin}
                            min="0"
                            onChange={(event) =>
                                this.setState({ valuemin: event.target.value })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="valuemax" className="floatLeft">
                            Maximum Value
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="valuemax"
                            value={this.state.valuemax}
                            min="0"
                            onChange={(event) =>
                                this.setState({ valuemax: event.target.value })
                            }
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderAdditionalFields = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12 label2">
                        <label htmlFor="additionalfields" className="floatLeft">
                            Additional Fields
                        </label>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTable
                            columns={[
                                {
                                    dataFieldId: 'field',
                                    label: 'Key',
                                    addRow: true,
                                },
                                {
                                    dataFieldId: 'value',
                                    label: 'Value',
                                    addRow: true,
                                },
                                { dataFieldId: 'AD', label: '' },
                            ]}
                            rows={this.state.additionalFields}
                            deleteRow={this.deleteRow}
                            addRow={this.handleAddRow}
                            handleInputChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    _renderFormArea = () => {
        return (
            <React.Fragment>
                <div className="row rowBottom">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv text-center ">
                            {this._renderMainFormDetails()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderCategoryFields()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderPointFields()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderGeneralInformation()}
                        </form>
                        <form
                            className="splitFrmDiv text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderAdditionalFields()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form className="splitFrmDivSec">
                            {this._renderStoreDetails()}
                        </form>
                        <form
                            className="splitFrmDivSec text-center"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderStatusDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    toDate = (dateStr) => {
        var parts = dateStr.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    componentDidMount = () => {
        this.props.dispatch(queryCategoryTree({ body: {} }, 'PD01'));
    };

    componentWillReceiveProps = () => {
        if (this._isEditMode()) {
            const { storeArray } = this.props.queryData;
            if (storeArray) this.setState({ storelabel: storeArray.title });
        }
    };

    componentWillMount = () => {
        // const requestBody = {
        //     body: {
        //         title: "STORE_GROUP",
        //         category: "Config"
        //     }
        // };
        // this.props.dispatch(
        //     queryDefaultConfigByTitle(
        //         requestBody,
        //         "STORE_GROUP_BY_TITLE",
        //         "STORE_GROUP_BY_TITLE_SUCCESS",
        //         "STORE_GROUP_BY_TITLE_FAILURE"
        //     )
        // );
        const getstoregrouptitle = localStorage.getItem('storegrouptitle');
        this.setState({
            storegrouptitle: JSON.parse(getstoregrouptitle),
        });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            console.log('record is', rec);
            const isEdit = this.props.location.state.isEdit;
            if (rec) {
                const {
                    id,
                    rulecode,
                    title,
                    ruletype,
                    rulegroup,
                    storegroups,
                    storeids,
                    categorytype,
                    categorytypevalue,
                    cateroryfilerby,
                    categoryfiltervalues,
                    valueby,
                    valuemin,
                    valuemax,
                    pointearncategory,
                    pointearn,
                    pointearnvalidityby,
                    pointvaliditybyvalue,
                    pointburncategory,
                    pointburn,
                    validby,
                    validfrom,
                    validtill,
                    opentime,
                    closetime,
                    validdays,
                    valuebatch,
                    closedates,
                    status,
                    sortorder,
                    additionalfields,
                } = rec;
                this.props.dispatch(getStoreById(storeids));
                let svalue = this.toDate(validfrom);
                let evalue = this.toDate(validtill);
                let close_dates = closedates.split(',').map((e) => e);
                let closedatearray = [...new Set(close_dates)];
                let defaultaddField = [{ field: '', value: '', id: 0 }];
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.title = title ? title : '';
                        draft.rulecode = rulecode ? rulecode : '';
                        draft.ruletype = ruletype ? ruletype : '';
                        draft.rulegroup = rulegroup ? rulegroup : '';
                        draft.storegroups = storegroups
                            ? storegroups.split(',')
                            : [];
                        draft.storeid = storeids ? storeids : '';
                        draft.categorytype = categorytype ? categorytype : '';
                        draft.categorytypevalue = categorytypevalue
                            ? categorytypevalue.split(',')
                            : [];
                        draft.cateroryfilerby = cateroryfilerby
                            ? cateroryfilerby
                            : '';
                        draft.categoryfiltervalues = categoryfiltervalues
                            ? categoryfiltervalues
                            : '';
                        draft.valueby = valueby ? valueby : '';
                        draft.valuemax = valuemax ? valuemax : 0;
                        draft.valuemin = valuemin ? valuemin : 0;
                        draft.valuebatch = valuebatch ? valuebatch : 0;
                        draft.pointearncategory = pointearncategory
                            ? pointearncategory
                            : '';
                        draft.pointearn = pointearn ? pointearn : 0;
                        draft.pointearnvalidityby = pointearnvalidityby
                            ? pointearnvalidityby
                            : '';
                        draft.pointvaliditybyvalue = pointvaliditybyvalue
                            ? pointvaliditybyvalue
                            : 0;
                        draft.pointburncategory = pointburncategory
                            ? pointburncategory
                            : '';
                        draft.pointburn = pointburn ? pointburn : 0;
                        draft.validby = validby ? validby : '';
                        draft.validfrom = validfrom ? svalue : '';
                        draft.validtill = validtill ? evalue : '';
                        draft.opentime = opentime ? opentime : '';
                        draft.closetime = closetime ? closetime : '';
                        draft.closedatearr = closedatearray
                            ? closedatearray
                            : [];
                        draft.closedates = closedates ? closedates : '';
                        draft.valuebatch = valuebatch ? valuebatch : 0;
                        draft.validdays = validdays ? validdays : 0;
                        draft.sortorder = sortorder ? sortorder : 1;
                        draft.status = status ? status : 'Active';
                        draft.additionalFields =
                            typeof additionalfields !== 'undefined' &&
                            Object.keys(additionalfields).length !== 0
                                ? objtoarr(additionalfields)
                                : defaultaddField;
                    })
                );
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        const { categoryTree } = this.props.queryData;
        const { categoryTree: prevcategoryTree } = prevProps.queryData;
        if (categoryTree !== prevcategoryTree) {
            console.log(categoryTree);
            if (categoryTree.length > 0) {
                let getcategorycode = categoryTree.subcategories.filter(
                    (e) => e.categorycode === 'CAFE01'
                );
                if (getcategorycode.length > 0) {
                    let getcategories = getcategorycode.map((e) => {
                        return e.subcategories.map((i) => {
                            return i.categorycode;
                        });
                    });
                    let arr2obj = getcategories
                        .flat()
                        .reduce(function (acc, curr) {
                            acc[curr] = curr;
                            return acc;
                        }, {});
                    this.setState({
                        ctypevalueoptions: arr2obj,
                    });
                }
            }
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };
    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Point Rule'
                                    : 'Add Point Rule'}
                            </h2>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderFormArea()}
                        </div>
                        <div className="row btn-container form-button">
                            <div className="col-sm-12 col-lg-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/point-rule"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Point Rule updated successfully'
                                    : 'Point Rule created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddPointRule);
