import produce from 'immer';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    crudReset,
    exportCouponTransactions,
    exportedCouponTransactions,
    resetAction,
    searchCouponByFields,
    searchGeneral,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { formatDate, permissions } from '../../../helpers/Util';
import * as CONSTANTS from '../../../constants/constants';
import { AutoComplete } from 'primereact/autocomplete';
import PrimeTable from '../../../components/PrimeTable';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';

class IssueAndExportCoupon extends React.PureComponent {
    constructor(props) {
        super(props);

        this.companyId = localStorage.getItem('company_id');
        this.module = 'CAMP';
        this.group = 'DE_CAMP_ISSUE_EXPORT_COUPON';
        this.allPermissions = [];

        this.requestBody = {}; //current one
        this.currPage = 1;
        this.pageSize = 10;
        this.tasktype = 'coupon_transaction_export';

        this.state = {
            currPage: this.currPage,
            pageSize: this.pageSize,
            showSearchModal: false,
            query: {},
            openDeleteprompt: false,
            delete_rec_id: '',
            message: '',
            action: '',
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            date_from: new Date(),
            date_till: new Date(),
            couponcode: '',
            storeid: '',
            status: '',
            pagesize: this.pageSize,

            showLogDetails: false,
            selectedLog: {},

            couponcodeerror: '',
            storeiderror: '',

            couponslist: [],
            filteredCouponCodes: null,
            couponcodeObj: '',
            visibleDialog: false,
        };
    }

    actionTemplate = (rowData) => {
        if (rowData.status !== 'exported') {
            return;
        }
        return (
            <span>
                <img
                    title="Download"
                    className="tableImage"
                    src={`${process.env.PUBLIC_URL}/assets/icons/list_download.svg`}
                    onClick={() => this.download(rowData)}
                />
            </span>
        );
    };

    download = (row) => {
        var link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            //var url =  localStorage.getItem('imagepath') + "/media" + row.title;
            var url = localStorage.getItem('imagepath') + row.title;
            link.setAttribute('href', url);

            var exportedFilenmae = row.tasktype + '.csv' || 'export.csv';
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // API Calls
    createFetchUrl = (fetchType) => {
        const { pageSize, currPage } = this.state;

        if (fetchType === 'ONLOAD') {
            const requestBody = {
                body: {
                    pagesize: pageSize,
                    pageno: currPage,
                    tasktype: this.tasktype,
                },
            };
            return requestBody;
        }
        if (this.requestBody['tasktype'] === undefined) {
            this.requestBody['tasktype'] = this.tasktype;
        }

        const requestBody = {
            body: { ...this.requestBody, pagesize: pageSize, pageno: currPage },
        };
        return requestBody;
    };

    callFetch = (fetchType) => {
        this.props.dispatch(
            searchGeneral(
                '/system/v1/jobtask/search/fields',
                this.createFetchUrl(fetchType),
                CONSTANTS.SEARCH_JOB_TASK_LOGS_SUCCESS,
                CONSTANTS.SEARCH_JOB_TASK_LOGS_FAILURE
            )
        );
    };

    callQueryApiForFetch = (triggerPoint, ...rest) => {
        // var newState;
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
            case 'PAGECLICK':
                this.callFetch();
                break;
        }
    };

    componentWillMount() {
        this.callQueryApiForFetch('ONLOAD');
        this.allPermissions = permissions(this.module, this.group);

        this.props.dispatch(
            searchCouponByFields({ body: { status: 'Active' } })
        );
    }

    UNSAFE_componentWillReceiveProps = () => {
        const { couponsList } = this.props.queryData;

        if (couponsList && couponsList.length > 0) {
            var returnedData = couponsList.map((e) => ({
                name: e.title + ' - ' + e.couponcode,
                id: e.couponcode,
            }));
            this.setState({
                couponslist: returnedData,
            });
        } else {
            this.setState({ couponslist: [{ name: 'Select', id: '' }] });
        }
    };

    componentDidUpdate = (prevProps) => {
        const { couponsList } = this.props.queryData;

        const { couponsList: prevcouponsList } = prevProps.queryData;

        if (couponsList !== prevcouponsList) {
            if (couponsList && couponsList.length > 0) {
                var returnedData = couponsList.map((e) => ({
                    name: e.title + ' - ' + e.couponcode,
                    id: e.couponcode,
                }));
                this.setState({
                    couponcode: '',
                    couponslist: returnedData,
                });
            } else {
                this.setState({
                    couponcode: '',
                    couponslist: [{ name: 'Select', id: '' }],
                });
            }
        }
    };

    hideAlertModal = (e) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.queryData.couponexport = {};
        this.callQueryApiForFetch('ONLOAD');
    };

    hideErrorAlertModal = (e, refresh) => {
        this.setState({ message: '', action: '' });
        e.preventDefault();
        this.props.dispatch(crudReset());
        this.props.dispatch(resetAction());
    };

    _hideLogDetails = () => {
        this.props.queryData.exportedCouponTransactions = {};
        this.setState({ showLogDetails: false });
    };

    _renderIssueCouponBtn = () => {
        const { history } = this.props;

        if (!this.allPermissions.includes('Write')) {
            return null;
        }

        return (
            <>
                {this._renderExportCouponBtn()}
                <Button
                    label="Issue Coupon"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    onClick={() =>
                        history.push('/ui/coupon-management/issue-coupon')
                    }
                />
            </>
        );
    };

    _renderExportCouponBtn = () => {
        if (!this.allPermissions.includes('Read')) {
            return;
        }
        return (
            <Button
                label="Export Coupon"
                icon="pi pi-file-export"
                className="p-button-success p-button-outlined"
                onClick={() =>
                    this.setState({
                        visibleDialog: !this.state.visibleDialog,
                    })
                }
                style={{ marginRight: '20px' }}
            />
        );
    };

    exportCoupons = (couponcodeObj) => {
        const couponcode =
            couponcodeObj && couponcodeObj.id ? couponcodeObj.id : '';

        let queryParams = [];
        if (couponcode !== '') {
            queryParams.push(`couponcode=${couponcode}`);
        } else {
            this.setState({ couponcodeerror: 'Select coupon code' });
            return;
        }
        //query
        this.setState({
            message: 'Exported Sucessfully',
            visibleDialog: false,
        });
        this.props.dispatch(exportCouponTransactions(queryParams.join('&')));
    };

    searchCouponCode = (event) => {
        const { couponslist } = this.state;
        setTimeout(() => {
            let _filteredCouponCodes;
            if (!event.query.trim().length) {
                _filteredCouponCodes = [...couponslist];
            } else {
                _filteredCouponCodes = couponslist.filter((country) => {
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCouponCodes: _filteredCouponCodes });
        }, 250);
    };

    render() {
        const {
            errormsg: queryError,
            servererror: serverError,
            jobtasklogs,
            couponexport,
        } = this.props.queryData;
        const { errormsg: crudError } = this.props.crudData;
        let errormsg = '';
        let refresh = false;
        if (serverError) {
            errormsg = serverError;
            refresh = false;
        }
        if (queryError) {
            errormsg = queryError;
            refresh = true;
        }
        if (crudError) {
            errormsg = crudError;
            refresh = true;
        }

        const columns = [
            { field: 'tasktype', header: 'Task Type' },
            { field: 'title', header: 'Title' },
            { field: 'id', header: 'Id' },
            { field: 'status', header: 'Status' },
            { field: 'memberid', header: 'Member Id' },
            { field: 'storeid', header: 'Store Id' },
            { field: 'createdon', header: 'Created On' },
        ];

        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="page-header">
                                Issue And Export Coupons
                                <img
                                    title="Refresh"
                                    onClick={() => {
                                        this.callQueryApiForFetch('ONLOAD');
                                    }}
                                    className="refreshtree"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                                />
                            </h2>
                        </div>
                        <div className="col-6 alignRight alignWithPageHeader">
                            {this._renderIssueCouponBtn()}
                        </div>
                    </div>

                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv">
                                <div className="row p-fluid formgrid grid w-100">
                                    <div className="field col-3 md:col-3">
                                        <label htmlFor="date_from">
                                            Date From
                                        </label>
                                        <Calendar
                                            id="date_from"
                                            value={this.state.date_from}
                                            onChange={(e) => {
                                                this.setState({
                                                    date_from: e.value,
                                                });
                                            }}
                                            dateFormat="dd-mm-yy"
                                            maxDate={this.state.date_till}
                                        />
                                    </div>

                                    <div className="field col-3 md:col-3">
                                        <label htmlFor="date_from">
                                            Date Till
                                        </label>
                                        <Calendar
                                            id="date_from"
                                            value={this.state.date_till}
                                            onChange={(e) => {
                                                this.setState({
                                                    date_till: e.value,
                                                });
                                            }}
                                            dateFormat="dd-mm-yy"
                                        />
                                    </div>
                                    <div
                                        className="field col-3 md:col-3"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            className='p-button-success'
                                            onClick={this._searchJobTaskLogs}
                                            label="Search"
                                            style={{ marginRight: '10px'}}
                                        />
                                        <Button
                                            className='p-button-outlined p-button-secondary'
                                            onClick={this._clearFiltersAndResults}
                                            label="Clear"
                                        />
                                    </div>
                                </div>

                                <div className="title_devide" />
                                <PrimeTable
                                    tablePageSize={this.state.pageSize}
                                    showFilterSearch={false}
                                    list={jobtasklogs}
                                    columns={columns}
                                    actionColumn={this.actionTemplate}
                                    pageSizeChangecallback={(pageSize) => {
                                        this.setState(
                                            produce(this.state, (draft) => {
                                                if (draft.pageSize < pageSize) {
                                                    draft.currPage = 1;
                                                }
                                                draft.pageSize = pageSize;
                                            }),
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                    pageChangeCallback={(currPage) => {
                                        this.setState(
                                            {
                                                currPage: currPage,
                                            },
                                            () => {
                                                this.callQueryApiForFetch(
                                                    'PAGECLICK'
                                                );
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        header={'Export Coupon'}
                        visible={this.state.visibleDialog}
                        onHide={() => this.setState({ visibleDialog: false })}
                    >
                        <ExportCouponForm
                            filteredCouponCodes={this.state.filteredCouponCodes}
                            searchCouponCode={this.searchCouponCode}
                            exportCoupons={this.exportCoupons}
                        />
                    </Dialog>
                    <ConfirmAlert
                        show={couponexport.status === 'exporting'}
                        handleClose={this.hideAlertModal}
                        children={
                            <div
                                style={
                                    couponexport.status === 'exporting'
                                        ? { padding: '2em', color: 'green' }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {couponexport.status === 'exporting'
                                    ? this.state.message
                                    : ''}
                            </div>
                        }
                    />

                    <ConfirmAlert
                        show={errormsg}
                        handleClose={(event) =>
                            this.hideErrorAlertModal(event, refresh)
                        }
                        children={
                            <div
                                style={
                                    errormsg
                                        ? {
                                              padding: '2em',
                                              color: 'red',
                                              fontWeight: '500',
                                          }
                                        : { padding: '2em', color: 'red' }
                                }
                            >
                                {errormsg ? `${errormsg}.` : ''}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }

    _searchJobTaskLogs = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { date_from, date_till, status } = this.state;

        this.requestBody = {};
        const queryParams = [];

        if (date_from === '' && date_till === '') {
            //do nothing
        } else {
            if (date_from !== '' && date_till !== '') {
                queryParams.push(`date_from=${formatDate(date_from)}`);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            } else if (date_from !== '') {
                queryParams.push(`date_from=${formatDate(date_from)}`);
                queryParams.push(`date_till=${formatDate(date_from)}`);
            } else if (date_till !== '') {
                queryParams.push(`date_from=${formatDate(date_till)}`);
                queryParams.push(`date_till=${formatDate(date_till)}`);
            }
            this.requestBody['date_from'] = formatDate(date_from);
            this.requestBody['date_till'] = formatDate(date_till);
        }
        queryParams.push(`tasktype=${this.tasktype}`);
        this.requestBody['tasktype'] = this.tasktype;
        if (status !== '') {
            queryParams.push(`status=${status}`);
            this.requestBody['status'] = status;
        }

        queryParams.push(`pagesize=${this.pageSize}`);
        queryParams.push(`pageno=${this.currPage}`);
        //query
        this.props.dispatch(exportedCouponTransactions(queryParams.join('&')));
    };

    _clearFiltersAndResults = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            tasktype: '',
            id: '',
            memberid: '',
            storeid: '',
            status: '',
            title: '',
            couponcode: '',

            storeiderror: '',
            couponcodeerror: '',
        });
    };
}

const ExportCouponForm = (props) => {
    const { filteredCouponCodes, searchCouponCode, exportCoupons } = props;
    const [couponCodeObj, setCouponCodeObj] = useState('');
    const [couponCodeError, setCouponCodeError] = useState('');

    const handleExport = () => {
        if (!couponCodeObj.id) {
            setCouponCodeError('Coupon code is required.');
            return null;
        }
        exportCoupons(couponCodeObj);
    };

    const handleCouponCodeChange = (e) => {
        setCouponCodeError('');
        setCouponCodeObj(e.target.value);
    };
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-end',
                }}
            >
                <div style={{ marginRight: '1em' }}>
                    <label htmlFor="couponcode" className="floatLeft required">
                        Coupon Code
                    </label>
                    <AutoComplete
                        className="w-100"
                        value={couponCodeObj}
                        suggestions={filteredCouponCodes}
                        completeMethod={searchCouponCode}
                        field="name"
                        dropdown
                        forceSelection
                        onChange={handleCouponCodeChange}
                        aria-label="Coupon Code"
                        dropdownAriaLabel="Select Coupon Code"
                    />
                </div>
                <Button onClick={handleExport} label="Export" />
            </div>
            <small className="p-error">{couponCodeError}</small>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(IssueAndExportCoupon);
