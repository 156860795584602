import React from 'react';
import { connect } from 'react-redux';
import 'rc-time-picker/assets/index.css';

import { produce } from 'immer';
import SimpleSelect from '../../../../components/SimpleSelect';
import DynamicTable from '../../../../components/DynamicTable';

class TaxReportHeaderConfig extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',

            language: '',
            langlist: {},
            additionalFields: [],
            selectedlangfields: [],
        };

        //this.setState({ selectedlangfields: props.fields[this.state.language] || {} })
    }

    componentDidMount = () => {
        const { languageConfig } = this.props.queryData;

        if (languageConfig.length > 0) {
            const list = languageConfig[0].additionalfields;
            this.setState(
                {
                    langlist: list,
                },
                () => {}
            );
        }
    };

    componentWillReceiveProps = () => {};

    handleAddRow = (e) => {
        if (e) e.preventDefault();
        const { fieldvaltype } = this.state;
        const rowLength = this.state.selectedlangfields.length;
        let lastele = this.state.selectedlangfields[rowLength - 1];
        const item = {
            field: '',
            value: fieldvaltype === 'array' ? [''] : '',
            image: '',
            id: lastele.id + 1,
        };
        this.setState(
            {
                selectedlangfields: [...this.state.selectedlangfields, item],
            },
            () => {
                // console.log("Rows After Add", this.state.selectedlangfields);
                this.props.fields[this.state.language] =
                    this.state.selectedlangfields;
                this.updateAdditionalFields();
            }
        );
    };

    handleInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            disabled: false,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'image'
                    ? event
                    : event.target.value,
        };
        this.setState(
            produce((draft) => {
                if (draft.selectedlangfields.length === 0) {
                    draft.selectedlangfields.push(obj);
                } else {
                    let found = false;
                    draft.selectedlangfields.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.selectedlangfields.push(obj);
                    }
                }
            }),
            () => {
                //this.props.updateTaxReportHeaderConfig()
                // console.log('selectedlangfields::', this.state.selectedlangfields)
                this.props.fields[this.state.language] =
                    this.state.selectedlangfields;
                this.updateAdditionalFields();
            }
        );
    };

    deleteRow = (row) => {
        let rowId = row.id;
        const rowLength = this.state.selectedlangfields.length;

        if (rowLength > 1) {
            const updateValues = this.state.selectedlangfields.filter(
                (value) => rowId !== value.id
            );

            this.setState({ selectedlangfields: updateValues }, () => {
                this.props.fields[this.state.language] =
                    this.state.selectedlangfields;
                this.updateAdditionalFields();
            });
        }
    };

    _renderMainForm = () => {
        return (
            <>
                <div className="row sub-title">Settings</div>

                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="language" className="floatLeft">
                            Language
                        </label>
                        <SimpleSelect
                            objArray={this.state.langlist}
                            callbackChangeFilter={(value) => {
                                this.setState({ language: value }, () => {
                                    this.updateSelectedLangConfig();
                                    // this.setState({selectedlangfields: this.props.fields[value] === 'undefined' ?
                                    // [].concat(this.props.fields[value]) : [{}]})
                                });
                            }}
                            selectedOption={this.state.language}
                            id="language"
                        />
                    </div>
                </div>
                {this.state.language !== '' && this.state.language !== '0' && (
                    <div className="row">
                        <div className="form-group override col-lg-12 col-sm-12">
                            <DynamicTable
                                columns={[
                                    {
                                        dataFieldId: 'field',
                                        label: 'Key',
                                        addRow: true,
                                        disabled: true,
                                    },
                                    {
                                        dataFieldId: 'value',
                                        label: 'Value',
                                        addRow: true,
                                    },
                                    { dataFieldId: 'AD', label: '' },
                                ]}
                                rows={this.state.selectedlangfields}
                                deleteRow={this.deleteRow}
                                addRow={this.handleAddRow}
                                handleInputChange={this.handleInputChange}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    };

    updateSelectedLangConfig = () => {
        if (this.state.language === '' || this.state.language === '0') {
            return;
        }

        const config = this.props.fields[this.state.language];
        let fields = [];
        let index = 0;

        // console.log('config:::', config)
        if (typeof config === 'undefined') {
            fields.push({ field: '', value: '', id: index++ });
        } else {
            Object.keys(config).map((key) => {
                fields.push({ field: key, value: config[key], id: index++ });
            });
        }

        // console.log('fields:::', fields)
        this.setState({ selectedlangfields: fields });
    };

    updateAdditionalFields = () => {
        let fields = {};

        let value = this.props.fields[this.state.language];

        if (typeof value !== undefined) {
            value.map((m) => {
                //save only non empty keys
                if (m['field'] !== '') {
                    fields[m['field']] = m['value'];
                }
            });
        }
        this.props._updateTaxReportHeaderDetails(this.state.language, fields);
    };

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">{this._renderMainForm()}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(TaxReportHeaderConfig);
