import React from "react";
import ExportButton from "../../../components/TableExportButton";
import LinkButton from "../../../components/LinkButton";
import { searchFields } from "./tableCols";
import { connect } from "react-redux";
import { produce } from "immer";
import {
  deleteArticleInfoApi,
  unpublishArticleInfoApi,
  publisArticleInfoApi,
  copyArticleInfoApi,
  searchGeneral,
  crudReset,
  resetAction
} from "../../../actions/actions";
import ConfirmAlert from "../../../components/ConfirmAlert";
import SimpleTable from "../../../components/SimpleTable";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import SimpleSearcher from "../../../components/SimpleSearcher";
import MultiSelect from "./../../../components/MultiSelect";
import { formatLanguageArray } from "../../../helpers/Util";
class DisplayPromotion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pages: 10,
      currPage: 0,
      showSearchModal: false,
      query: {},
      openDeleteprompt: false,
      delete_rec_id: "",
      message: "",
      search_field: "status",
      search_condi: "like",
      search_value: "",
      tableCols: [
        {
          dataField: "id",
          text: "Id",
          sort: true
        },
        {
          dataField: "articlecode",
          text: "Article Code",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "status",
          text: "Status",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true,
          formatter: this.languageFormatter
        },
        {
          dataField: "fields.desc",
          text: "Description",
          sort: true
        },

        {
          dataField: "",
          text: "Actions",
          formatter: this.cellFormatter,
          formatExtraData: {
            action: "ED"
          },
          classes: () => {
            return "lastTd";
          }
        }
      ]
    };
  }
  languageFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (this.props.queryData.languageConfig[0] && this.props.queryData.languageConfig[0]['additionalfields']) {
      return this.props.queryData.languageConfig[0]['additionalfields'][row.language] || ''
  }
  //return row.language === "en" ? "English" : row.language === "ko" ? "Korean" : row.language === "th" ? "Thai" : row.language === "zh" ? "Chinese" : "";
  };

  cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const permissions = localStorage.getItem("permission");
    if (row.status !== "Deleted" && permissions.split(",").includes("W")) {
      return (
        <span>
          <Link
            to={{
              pathname: "/ui/promotions/addNew",
              state: {
                item: row,
                isEdit: true
              }
            }}
          >
            <img
              title="Edit"
              className="tableImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_edit.svg`}
            />
          </Link>

          <img
            title="Delete"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_delete.svg`}
            onClick={() => this.deleteStoreInfo(row)}
          />
          <img
            title="Copy"
            className="tableImage"
            src={`${process.env.PUBLIC_URL}/assets/icons/ic_duplicate.svg`}
            onClick={() => this.copyRecord(row)}
          />
          {permissions.split(",").includes("A") && (
            <img
              title={
                row.status === "Active"
                  ? "Unpublish Article"
                  : "Publish Article"
              }
              className="tableImage2"
              src={
                row.status === "Active"
                  ? `${process.env.PUBLIC_URL}/assets/icons/ic_unpublic.svg`
                  : `${process.env.PUBLIC_URL}/assets/icons/ic_public.svg`
              }
              onClick={
                row.status === "Active"
                  ? () => this.unpublishArticle(row)
                  : () => this.publishArticle(row)
              }
            />
          )}
        </span>
      );
    }
  };

  // API Calls
  createFetchUrl = fetchType => {
    const {
      pages,
      currPage,
      language,
      search_field,
      search_condi,
      search_value
    } = this.state;

    const requestBody = {
      body: {
        /* pg_sz: pages,
        c_p: currPage */
        articletype: "Promotions",
        language: language.toString(),
        search_field: search_field,
        search_condi: search_condi,
        search_value: search_value
      }
    };
    return requestBody;
  };

  callFetch = fetchType => {
    this.props.dispatch(
      searchGeneral(
        "cms/v1/article-workflow/search",
        this.createFetchUrl(fetchType),
        "SEARCH_ARTICLE_INFO_SUCCESS",
        "SEARCH_ARTICLE_INFO_FAILURE"
      )
    );
  };

  callQueryApiForFetch = (triggerPoint, ...rest) => {
    var newState;
    switch (triggerPoint) {
      case "ONLOAD":
        this.callFetch("ONLOAD");
        break;
      case "SEARCH":
        this.callFetch("SEARCH");
        break;
      case "PAGECLICK":
        const [pgNumber] = rest;

        newState = produce(this.state, draft => {
          draft.currPage = pgNumber - 1;
        });
        this.setState({ ...newState }, () => {
          this.callFetch("PAGECLICK");
        });

        break;
    }
  };
  searchQuery = (val1, val2, val3) => {
    if (val1 === "id") val3 = parseInt(val3);
    this.setState(
      {
        search_field: val1,
        search_condi: val2,
        search_value: val3
      },
      () => {
        this.callQueryApiForFetch("SEARCH");
      }
    );
  };
  copyRecord = row => {
    this.setState({ message: "Item Copied Suucessfully" });
    this.props.dispatch(copyArticleInfoApi(row.id));
  };
  componentWillMount() {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage.split(",") }, () =>
      this.callQueryApiForFetch("ONLOAD")
    );
  }

  pageNumberOnClick = pgNumber => {
    this.callQueryApiForFetch("PAGECLICK", pgNumber);
  };

  _renderAddNewBtn = () => {
    const permissions = localStorage.getItem("permission");
    return (
      <LinkButton
        to="/ui/promotions/addNew"
        className="page-header-btn icon_btn display-inline "
        onClick={event => event.preventDefault()}
        disabled={!permissions.split(",").includes("W")}
      >
        <img
          title="edit"
          className="tableImage"
          src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
        />
        {`Add Promotion `}
      </LinkButton>
    );
  };
  shdDeleteRec = arg => {
    this.setState({ openDeleteprompt: false });
    if (arg === true) {
      this.props.dispatch(deleteArticleInfoApi(this.state.delete_rec_id));
    }
  };
  hideAlertModal = e => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.callQueryApiForFetch("ONLOAD");
  };

  hideErrorAlertModal = (e, refresh) => {
    this.setState({ message: "" });
    e.preventDefault();
    this.props.dispatch(crudReset());
    this.props.dispatch(resetAction());
    // if (refresh === true) {
    //   this.callQueryApiForFetch("ONLOAD");
    // }
  };
  deleteStoreInfo = rowData => {
    this.setState({ message: "Promotion deleted successfully!" });
    this.setState({ openDeleteprompt: true, delete_rec_id: rowData.id });
  };
  unpublishArticle = rowData => {
    this.setState({ message: "Promotion unpublished successfully!" });
    this.props.dispatch(unpublishArticleInfoApi(rowData.id));
  };
  publishArticle = rowData => {
    this.setState({ message: "Promotion published successfully!" });
    this.props.dispatch(publisArticleInfoApi(rowData.id));
  };
  render() {
    const {
      articleFlowData,
      languageConfig,
      errormsg: queryError,
      servererror: serverError
    } = this.props.queryData;
    const { isSuccess, errormsg: crudError } = this.props.crudData;
    let errormsg = "";
    let refresh = false;
    if (serverError) {
      errormsg = serverError;
      refresh = false;
    }
    if (queryError) {
      errormsg = queryError;
      refresh = true;
    }
    if (crudError) {
      errormsg = crudError;
      refresh = true;
    }

    if (this.props.queryData.apiPending) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2 className="page-header">
                Promotions Management
                <img
                  title="Refresh"
                  onClick={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                  className="refreshtree"
                  src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
                />
              </h2>
            </div>

            {/* <div className="col-lg-6 text-right noPadding noMargin">
              <ExportButton />
            </div> */}
          </div>
          <div className="row form-container">
            <div className="col">
              <form className="frmDiv">
                <div className="row">
                  <div className="col-lg-4 col-sm-12 noPadding noMargin">
                    <h3 className="page-title">Promotion Registration</h3>
                  </div>
                  <div className="col-lg-4 col-sm-12 alignRight noPadding noMargin">
                    <SimpleSearcher
                      filterOptions={searchFields}
                      setAndSearch={this.searchQuery}
                      search_condi={this.state.search_condi}
                      search_value={this.state.search_value}
                      search_field={this.state.search_field}
                    ></SimpleSearcher>
                  </div>
                  <div className="col-lg-2 col-sm-12 noPadding noMargin alignRight">
                    <MultiSelect
                      filterOptions={formatLanguageArray(
                        languageConfig.pvalues
                      )}
                      setSelectedFieldValues={value => {
                        this.setState({ language: value }, () => {
                          this.callQueryApiForFetch("ONLOAD");
                        });
                      }}
                      id="language"
                      itemsSelected={this.state.language}
                      label="Select Language"
                    />
                  </div>
                  <div className="col-lg-2 col-sm-12 alignRight noPadding noMargin">
                    {this._renderAddNewBtn()}
                  </div>
                </div>
                <div className="title_devide" />
                <div className="invalid-feedback">{errormsg}</div>
                <SimpleTable
                  columns={this.state.tableCols}
                  rows={articleFlowData}
                  callback={() => {
                    this.callQueryApiForFetch("ONLOAD");
                  }}
                />
                {/* <TableFooter /> */}
              </form>
            </div>
          </div>
          <ConfirmAlert
            show={this.state.openDeleteprompt}
            isPrompt={true}
            children={
              <div className="deleteAlertDiv">
                Are you sure you want to delete this promotion?
                <div style={{ margin: "2em" }}>
                  <button
                    className="btn btn-themes btn-rounded btn-sec deletBtn"
                    onClick={() => this.shdDeleteRec(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-themes  btn-rounded deletBtn"
                    onClick={() => this.shdDeleteRec(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            }
          />
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideAlertModal}
            children={
              <div
                style={
                  isSuccess
                    ? { padding: "2em", color: "green" }
                    : { padding: "2em", color: "red" }
                }
              >
                {isSuccess ? this.state.message : ""}
              </div>
            }
          />
          <ConfirmAlert
            show={errormsg}
            handleClose={event => this.hideErrorAlertModal(event, refresh)}
            children={
              <div
                style={
                  errormsg
                    ? { padding: "2em", color: "red", fontWeight: "500" }
                    : { padding: "2em", color: "red" }
                }
              >
                {errormsg ? `${errormsg}.` : ""}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayPromotion);
