import React from 'react';
import { connect } from 'react-redux';
import {
    searchGeneral,
    queryInvTrx,
} from './../../../actions/actions';
import AddStock from './AddStock';
import Modal from 'react-awesome-modal';
import { toDate } from '../../../helpers/Util';
import DatePicker from 'react-date-picker';
import StatusComp from './../../../components/StatusComp';
import SimpleDropdown from './../../../components/SimpleDropdown';
import { Button } from 'primereact/button';
import PrimeTable from '../../../components/PrimeTable';

class UpdateValues extends React.PureComponent {
    invtypeoptions = [
        { id: '', name: 'Select' },
        { id: 'Stock', name: 'Stock' },
        { id: 'Reversal', name: 'Reversal' },
    ];

    transgrpoptions = [
        { id: '', name: 'Select' },
        { id: 'elevate', name: 'Elevate' },
    ];
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            productcode: '',
            delete_rec_id: '',
            message: '',
            showStockPage: false,
            showSearchDiv: false,
            search_field: 'status',
            search_condi: 'like',
            search_value: '',
            storeid: '',
            invtype: '',
            warehouseid: '',
            productmodel: '',
            invTrxList: [],
            trxtypevalue: '',
            trxgroup: '',
            date_from: new Date(),
            date_till: new Date(),
            sproductcode: '',
            status: 'Active',
            pageno: 1,
            pagesize: 10,
            invallocationid: '',
            filterState: {}
        };
    }

    toDate = (dateStr) => {
        let totalStr = dateStr.split(' ');
        if (totalStr.length > 1) {
            var st = totalStr[0];
            var parts = st.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            var parts = dateStr.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    };

    componentWillMount = () => {
        const { pagesize, pageno } = this.state;
        this.setState({ productcode: this.props.newprodcode }, () => {
            this.props.dispatch(
                queryInvTrx({
                    body: {
                        productcode: this.state.productcode,
                        storeid: this.props.fields
                            ? this.props.fields.storeid
                            : '',
                        pagesize,
                        pageno,
                    },
                }),
            );
        });
    };

    componentDidUpdate = (prevProps) => {
        const { invTrxData } = this.props.queryData;
        const { invTrxData: previnvTrxData } = prevProps.queryData;
        if (invTrxData !== previnvTrxData) {
            this.setState({
                invTrxList: invTrxData,
            });
        }
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _renderMainButton = () => {
        return (
            <Button
                label="Back To Main"
                className="p-button-secondary p-button-lg"
                onClick={() => this.props.updateForm(false)}
            />
        );
    };

    showStock = (value) => {
        this.setState({
            showStockPage: value,
        });
    };

    hideStock = () => {
        this.setState({ showStockPage: false }, () => {
            this.props.dispatch(
                queryInvTrx({
                    body: {
                        productcode: this.state.productcode,
                        storeid: this.props.fields
                            ? this.props.fields.storeid
                            : '',
                    },
                }),
            );
        });
    };

    callQueryApiForFetch = (triggerPoint) => {
        switch (triggerPoint) {
            case 'ONLOAD':
                this.callFetch('ONLOAD');
                break;
            case 'SEARCH':
                this.callFetch('SEARCH');
                break;
        }
    };

    callFetch = (fetchType) => {
        if (fetchType === 'SEARCH') {
            this.props.dispatch(
                searchGeneral(
                    'sales/v1/store/inventory/tr/search/fields',
                    this.createFetchByFields(fetchType),
                    'INV_TRX_SEARCH_SUCCESS',
                    'INV_TRX_SEARCH_SUCCESS',
                ),
            );
        } else {
            this.props.dispatch(
                searchGeneral(
                    'sales/v1/store/inventory/tr/search',
                    this.createFetchUrl(fetchType),
                    'SEARCH_ARTICLE_INFO_SUCCESS',
                    'SEARCH_ARTICLE_INFO_FAILURE',
                ),
            );
        }
    };

    pageSizeChangecallback = (pageSize) => {
        this.setState({ pagesize: pageSize, pageno: 1 });
        this.props.dispatch(
            queryInvTrx({
                body: {
                    productcode: this.state.productcode,
                    storeid: this.props.fields ? this.props.fields.storeid : '',
                    pagesize: pageSize,
                    pageno: 1,
                    ...this.state.filterState
                },
            }),
        );
    };

    pageChangeCallback = (pageNo) => {
        const { pagesize } = this.state;
        this.setState({ pageno: pageNo });
        this.props.dispatch(
            queryInvTrx({
                body: {
                    productcode: this.state.productcode,
                    storeid: this.props.fields ? this.props.fields.storeid : '',
                    pagesize,
                    pageno: pageNo,
                },
            }),
        );
    };

    createFetchByFields = () => {
        const {
            date_from,
            invtype,
            date_till,
            productmodel,
            warehouseid,
            storeid,
            status,
            sproductcode,
            trxgroup,
            trxtypevalue,
            invallocationid,
        } = this.state;
        const data = {
            date_from: toDate(date_from),
            date_till: toDate(date_till),
            storeid,
            productcode: sproductcode,
            trxgroup,
            trxtypevalue,
            productmodel,
            warehouseid,
            invtype,
            status,
            invallocationid,
        };

        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) =>
                    value !== undefined && value !== '' && value !== null,
            ),
        );
        this.setState({ filterState: filteredData })
        const requestBody = {
            body: filteredData,
        };
        return requestBody;
    };

    createFetchUrl = () => {
        const { search_field, search_condi, search_value, pagesize, pageno } =
            this.state;

        const requestBody = {
            body: {
                search_field: search_field,
                search_condi: search_condi,
                search_value: search_value,
                pagesize,
                pageno,
            },
        };
        return requestBody;
    };

    _openSearchDiv = () => {
        this.setState({ showSearchDiv: true });
    };

    _closeSearchDiv = () => {
        this.setState({ showSearchDiv: false });
    };

    _renderSearchModal = () => {
        return (
            <Modal
                visible={this.state.showSearchDiv}
                width="70%"
                height="80%"
                effect="fadeInRight"
                onClickAway={() => this._closeSearchDiv()}
            >
                <div className="modalForm queryFormModal">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 alignLeft">
                            Inventory Transaction Search
                        </div>
                        <div className="col-xs-12 col-md-6 alignRight">
                            <a
                                onClick={this._closeSearchDiv}
                                className="closeIcon"
                            >
                                X
                            </a>
                        </div>
                    </div>
                    <div className="title_devide" />
                    {this._renderSearchForm()}
                </div>
            </Modal>
        );
    };

    _renderSearchForm = () => {
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="datefrom" className="floatLeft">
                            Date From
                        </label>
                        <DatePicker
                            onChange={(value) => {
                                this.setState({ date_from: value });
                            }}
                            value={this.state.date_from}
                        />
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="datetill" className="floatLeft">
                            Date Till
                        </label>
                        <DatePicker
                            minDate={this.state.date_till}
                            onChange={(value) => {
                                this.setState({ date_till: value });
                            }}
                            value={this.state.date_till}
                        />
                    </div>
                    {/* <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="storeid" className="floatLeft">
                            Store
                        </label>
                        <TenantCode
                            value={this.props.storelabel}
                            setTenantCode={this._setTenant}
                        />
                    </div> */}
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="sproductcode" className="floatLeft">
                            Product Code
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sproductcode"
                            value={this.state.sproductcode}
                            onChange={(event) =>
                                this.setState({
                                    sproductcode: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="warehouseid" className="floatLeft">
                            Warehouse Id
                        </label>
                        <input
                            type="text"
                            id="warehouseid"
                            value={this.state.warehouseid}
                            onChange={(event) =>
                                this.setState({
                                    warehouseid: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="productmodel" className="floatLeft">
                            Product Model
                        </label>
                        <input
                            type="text"
                            id="productmodel"
                            value={this.state.productmodel}
                            onChange={(event) =>
                                this.setState({
                                    productmodel: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxgroup" className="floatLeft">
                            Transaction Group
                        </label>
                        <SimpleDropdown
                            id="trxgroup"
                            listoptions={this.transgrpoptions}
                            selectedOption={this.state.trxgroup}
                            callbackChangeFilter={(value) => {
                                this.setState({ trxgroup: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="trxtypevalue" className="floatLeft">
                            Transaction Type Value
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="trxtypevalue"
                            value={this.state.trxtypevalue}
                            onChange={(event) =>
                                this.setState({
                                    trxtypevalue: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="invallocationid" className="floatLeft">
                            Inventory Allocation Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="invallocationid"
                            value={this.state.invallocationid}
                            onChange={(event) =>
                                this.setState({
                                    invallocationid: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="invtype" className="floatLeft">
                            Inventory Type
                        </label>
                        <SimpleDropdown
                            id="invtype"
                            listoptions={this.invtypeoptions}
                            selectedOption={this.state.invtype}
                            callbackChangeFilter={(value) => {
                                this.setState({ invtype: value });
                            }}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusComp
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
                <div className="row btn-container1 form-button">
                    <div className="col-sm-12 col-lg-8">
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.callQueryApiForFetch('SEARCH');
                                this._closeSearchDiv();
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="submit"
                            className="btn btn-themes btn-rounded"
                            onClick={(event) => {
                                if (event) event.preventDefault();
                                this.callQueryApiForFetch('ONLOAD');
                                this._closeSearchDiv();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </>
        );
    };

    _renderMainForm = () => {
        const columns = [
            { field: 'storeid', header: 'Store Id', sortable: true },
            {
                field: 'productcode',
                header: 'Product Code',
                sortable: true,
                sortable: true,
            },
            { field: 'productmodel', header: 'Product Model', sortable: true },
            {
                field: 'invallocationid',
                header: 'Inv Allocation Id',
                sortable: true,
            },
            { field: 'trxdate', header: 'Trx Date', sortable: true },
            { field: 'trxtype', header: 'Trx Type' },
            { field: 'trxtypevalue', header: 'Trx Type Value', sortable: true },
            { field: 'lastupdatedby', header: 'User' },
            { field: 'qty', header: 'Qty', sortable: true },
            { field: 'qtyused', header: 'Qty Used' },
            { field: 'status', header: 'Status' },
        ];
        return (
            <div className="form_height">
                <div className="grid px-4">
                    <div className="col-6">
                        <h2 className="page-header">Inventory Transaction</h2>
                    </div>
                    <div className="col-6">
                        <div
                            className="grid justify-content-end"
                            style={{ marginTop: '30px' }}
                        >
                            <Button
                                label="Advanced Filter"
                                icon="pi pi-search"
                                className="p-button-outlined mr-2"
                                onClick={this._openSearchDiv}
                            />
                            <Button
                                label="Add Stock"
                                icon="pi pi-plus"
                                className="p-button-outlined mr-2"
                                onClick={() => this.showStock(true)}
                            />
                        </div>
                    </div>
                </div>
                <>
                    {this._renderErrorMessage()}
                    <div className="grid br-2 bg-white p-4 border-round-xl shadow-1 mt-3 mb-6 mx-4">
                        <PrimeTable
                            list={this.state.invTrxList}
                            columns={columns}
                            pageSizeChangecallback={(pageSize) => {
                                this.pageSizeChangecallback(pageSize);
                            }}
                            pageChangeCallback={(currPage) => {
                                this.pageChangeCallback(currPage);
                            }}
                        />
                        <div className="grid btn-container form-button w-full">
                            <div className="col-12">
                                {this._renderMainButton()}
                            </div>
                        </div>
                    </div>
                </>
            </div>
        );
    };

    render() {
        const { productcode } = this.state;
        const { storeid, storelabel, status } = this.props.fields;
        if (this.state.showStockPage === true) {
            return (
                <AddStock
                    updateStock={this.hideStock}
                    showStock={this.showStock}
                    propDetails={{
                        productcode,
                        storeid,
                        storelabel,
                        status,
                        fields: this.props.fields,
                    }}
                />
            );
        } else {
            return (
                <>
                    {this.state.showSearchDiv && this._renderSearchModal()}
                    {this._renderMainForm()}
                </>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(UpdateValues);
