import React from 'react';
import Modal from 'react-awesome-modal';
import DisplayTree from './DisplayTree';
import { removeDeletedChildren } from './../helpers/Util';

class CategoryTree extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCategoryTreeModal: false,
            categorycode: this.props.value ? this.props.value : '',
        };
    }

  //   componentDidUpdate = (prevProps) => {
  //     const { categoryTree } = this.props.queryData;
  //     if (prodSpecList !== prevprodSpecList) {
  //         if (prodSpecList.length > 0) {
  //             let fieldarr = prodSpecList.map((e) => ({
  //                 id: e.categorycode,
  //                 name: e.title,
  //             }));
  //             fieldarr.unshift({ id: '', name: 'Select' });
  //             this.setState({
  //                 productTypeOptions: fieldarr,
  //             });
  //         }
  //     }
  //     if (
  //         categoryCodeArray !== prevCategoryCodeArray &&
  //         categoryCodeArray.length > 0
  //     ) {
  //         this.setState({ categories: categoryCodeArray[0].title });
  //     }
  // };
    // Fetch common functions

    _openCategoryTreeModal = (event) => {
        this.setState({
            showCategoryTreeModal: true,
        });
        event.preventDefault();
    };
    _closeCategoryTreeModal = (event) => {
        this.setState({
            showCategoryTreeModal: false,
        });
        if (event) event.preventDefault();
    };
    setCategoryCodeSelected = (code, title) => {
        this._closeCategoryTreeModal();
        this.setState({ categorycode: code });
        this.props.setCategoryCode(code, title);
    };
    _renderCategoryTreeModal = () => {
        if (this.props.categoryTree.categorycode === undefined) {
            return (
                <Modal
                    visible={this.state.showCategoryTreeModal}
                    width="80%"
                    height="90%"
                    effect="fadeInRight"
                    onClickAway={() => this._closeCategoryTreeModal()}
                >
                    <div className="nocategorymodal">
                        <div className="row ">
                            <div className="col-xs-10 col-md-10 ">
                                <p
                                    className="alignLeft"
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                    }}
                                >
                                    Add Category
                                </p>
                            </div>
                            <div className="col-xs-2 col-md-2 ">
                                <a
                                    onClick={this._closeCategoryTreeModal}
                                    className="closeIcon alignRight"
                                >
                                    X
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12 alignLeft">
                            <b>You haven't added any category yet</b>
                        </div>
                        <div className="col-xs-12 col-md-12 alignLeft noPadding ">
                            {/* <LinkButton
                to="/ui/product-category/addNew"
                className="page-header-btn icon_btn display-inline noBorder "
                onClick={event => event.preventDefault()}
              >
                Add Category
              </LinkButton> */}
                            before creating a new product
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return (
                <Modal
                    visible={this.state.showCategoryTreeModal}
                    width="80%"
                    height="90%"
                    effect="fadeInRight"
                    onClickAway={() => this._closeCategoryTreeModal()}
                >
                    <div className="modalForm1">
                        <div className="row">
                            <div className="col-xs-12 col-md-12 alignRight">
                                <a
                                    onClick={this._closeCategoryTreeModal}
                                    className="closeIcon"
                                >
                                    X
                                </a>
                            </div>
                        </div>

                        <DisplayTree
                            treeData={removeDeletedChildren(
                                this.props.treeData
                            )}
                            setCategoryCode={this.setCategoryCodeSelected}
                            selectedValue={this.state.categorycode}
                        ></DisplayTree>
                    </div>
                </Modal>
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.state.showCategoryTreeModal &&
                    this._renderCategoryTreeModal()}
                <div className="row">
                    <div className="col-12 noPadding noMargin">
                        <input
                            type="text"
                            className="treemodal"
                            id="categorycodes"
                            readOnly={true}
                            value={this.props.value}
                            onClick={this._openCategoryTreeModal}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CategoryTree;
