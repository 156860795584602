import React, {useState, useRef} from "react";
import { Link } from "react-router-dom";

// Import local files

const Sidebar = (props) => {
  const [activeIndex, setActiveIndex] = useState("")
  const refContainer = useRef({})

  const _setRef = element => {
    if (element) refContainer.current[element.id] = element;
  };

  const _collapseRef = (id, parent, index) => {
    const _refContainer = refContainer.current

    if (!parent) {
      return;
    } else {
      for (const key of Object.keys(_refContainer)) {
        if (_refContainer[key].classList.contains("show")) {
          if (key !== id && !index.startsWith(activeIndex+"_") && activeIndex !== index) {
            _refContainer[key].classList.toggle("show");
            _refContainer[key].previousSibling.lastElementChild.classList.remove(
              "rotate_down"
            );
            _refContainer[key].previousSibling.lastElementChild.classList.add(
              "rotate"
            );
          }
        }
      }

      if (id) {
        _refContainer[id].classList.toggle("show");

        if (_refContainer[id].classList.contains("show")) {
          _refContainer[id].previousSibling.lastElementChild.classList.add(
            "rotate_down"
          );
        } else {
          _refContainer[id].previousSibling.lastElementChild.classList.remove(
            "rotate_down"
          );
          _refContainer[id].previousSibling.lastElementChild.classList.add(
            "rotate"
          );
        }
      }
    }
  };

  const _checkifPresentInConfig = (configaccess) => {
    const menushow = window && window.config.menushow;
    if (configaccess === "" || configaccess === undefined || typeof menushow === 'undefined') {
      return true;
    }
    return menushow[configaccess];
  };

  const _checkIfAccessible = module => {
    const allRoles = props.allAccesses[module]
    //if atlease 1 access under this group, enable the group
    return typeof allRoles === 'undefined' ? false : allRoles.length > 0
  };

  const _matchIndex = (index) => {
    const splitTexts = index.split("_")
    return splitTexts.findIndex(splitText => (activeIndex === (splitText) || activeIndex.startsWith(splitText+"_"))) > -1
  }

  const _drawMenuWithNoChild = (index, module, highlightClass, icon, isSideBarActive, path, label, marginDimension) => {
    return (<li
      key={index}
      style={
        _checkIfAccessible(module) === true
          ? { display: "block" }
          : { display: "none" }
      }
      onClick={() => {
        if (isSideBarActive) {
          props.toggleSideBar();
        } else {
          _collapseRef("", true, index);
          setActiveIndex(index);
        }
      }}
      className={
        isSideBarActive
          ? _matchIndex(index)
            ? `nav-selected`
            : `${highlightClass}`
          : ""
      }
    >
      <img
        alt=""
        src={icon}
        style={
          isSideBarActive
            ? { display: "inline" }
            : { display: "none" }
        }
      />
      <Link
        to={path}
        className={
          isSideBarActive
            ? ""
            : _matchIndex(index)
            ? `nav-selected`
            : `${highlightClass}`
        }
      >
        <img
          alt=""
          src={icon}
          style={
            isSideBarActive
              ? { display: "none" }
              : { display: "inline" }
          }
        />
        <span style={{ marginLeft: marginDimension, paddingLeft: 0 }}>{label}</span>
      </Link>
      <span className="hover_span">{label}</span>
    </li>)
  }

  // const _drawChild = (child, childMenu, index, roleidallowed) => {
    const  _drawChild = (child, childMenu, index) => {
    const { label, path, configaccess, roleid, group, module, access } = child;
    return (
      <li
        key={index}
        style={
          _checkifPresentInConfig(configaccess) && hasViewAccess(group, module)// && roleidallowed.includes(roleid)
          // _checkifPresentInConfig(configaccess) && roleidallowed.includes(roleid)
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={event => {
          _collapseRef(childMenu, false, index);
          event.stopPropagation();
        }}
      >
        <Link to={path}>{label}</Link>
      </li>
    );
  }

  const hasViewAccess = (group, module) => {

  const allRoles = props.allAccesses[module]
  //console.log("AllRoles:", module,  allRoles)
  if (typeof allRoles === 'undefined') {
    return false
  }

    //allRoles.includes(module + "|VIEW"
  let accesses = []
  accesses =  allRoles.filter((t, i) => {
      return t.accessgroup === group
    }
  )

  // console.log("accesses::", accesses)
  if (accesses.length <= 0) { return false }
  let view = accesses.filter((a, i) => {
    if (a.permission === 'Read') { 
      // console.log("returning true for ", a)
      return a
      }
  })

  return view.length > 0

  }

  const _drawMenuWithChild = (child, childMenu, index, module, highlightClass, icon, isSideBarActive, path, label, marginDimension, iconClass) => {
    return  (<li
        key={index}
        style={
          _checkIfAccessible(module) === true
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(event) => {
          if (isSideBarActive) {
            props.toggleSideBar()
          } else {
            _collapseRef(childMenu, true, index)
            setActiveIndex(index)
            event.stopPropagation()
          }
        }}
        className={
          isSideBarActive
            ? _matchIndex(index)
              ? `nav-selected`
              : `${highlightClass}`
            : ""
        }
      >
        <a
          href="/"
          onClick={event => event.preventDefault()}
          data-toggle="collapse"
          aria-expanded="false"
          className={
            isSideBarActive
              ? ""
              : _matchIndex(index)
              ? `nav-selected`
              : `${highlightClass}`
          }
        >
          {icon && <img alt="" src={icon} className={iconClass ? iconClass : ''} />}
          <span style={{ marginLeft: marginDimension, paddingLeft: 0 }}>{label}</span>
          <img
            alt=""
            id="dropdown"
            className="sidebar_dropdown rotate"
            style={{ height: "0.8em", width: "0.8em" }}
            src={`${process.env.PUBLIC_URL}/assets/icons/sidebar_arrow.png`}
          />
        </a>
        <ul
          className="collapse list-unstyled"
          style={{paddingLeft: `${index.split("_").length - 1}em`}}
          id={childMenu}
          data-parent="#sidebar"
          ref={_setRef}
        >
          {child.map((subChild, subIndex) => {
            // console.log("module::" + subChild.module)
            //const roleidallowed = localStorage.getItem("roles_access");
            // const accessAllowed = localStorage.getItem("user_access");

            if(typeof subChild.child !== 'undefined') {
              return _drawMenu(subChild, `${index}_${subChild.childMenu}`)
            } else {
              //return _drawChild(subChild, childMenu, `${index}_${subIndex.toString()}`, roleidallowed)
              return _drawChild(subChild, childMenu, `${index}_${subIndex.toString()}`)
            }
          })}
        </ul>
        <span className="hover_span">{label}</span>
      </li>);
  }

  const _drawMenu = (route, index) => {
    const {
      path,
      icon,
      label,
      child,
      childMenu,
      marginDimension,
      highlightClass,
      module,
      iconClass
    } = route;

    const { isSideBarActive } = props;

    if (label === "ADMIN") {
      return (
        <li key={index} className="admindiv">
          {label}
        </li>
      );
    } else if (typeof child === "undefined") {
      return _drawMenuWithNoChild(index, module, highlightClass, icon, isSideBarActive, path, label, marginDimension)
    } else {
      return _drawMenuWithChild(child, childMenu, index, module, highlightClass, icon, isSideBarActive, path, label, marginDimension, iconClass)
    }
  }

  const { isSideBarActive } = props;

  return (
    <nav id="sidebar" className={isSideBarActive ? "active " : ""}>
      <div style={{ padding: "1.2em 0" }}>
        <span className="sidebaractive-header"></span>
        <div
          className="menu"
          style={{ display: "inline" }}
          onClick={() => props.toggleSideBar()}
        ></div>
      </div>
      <ul className="list-unstyled components">
        {props.sidebarRoutes.map((route, index) =>
          _drawMenu(route, index.toString())
        )}
      </ul>
    </nav>
  );
}

export default Sidebar;
