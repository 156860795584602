import React, {useState, useEffect, useMemo, useCallback} from 'react'
import LinkButton from '../../../../components/LinkButton'
import SimpleTable from '../../../../components/SimpleTable'

import {
  searchGeneral,
  deleteUserProfile,
  crudReset,
  resetAction
} from '../../../../actions/actions'
import ConfirmAlert from '../../../../components/ConfirmAlert'
import { connect } from 'react-redux'
import Loading from '../../../../components/Loading'
import StatusComp from "../../../../components/StatusComp"
import * as Yup from 'yup'
import {MEMBER_GROUP} from './const'
import formik from '../../../../hooks/useFormik'
import { CreateEditDeleteHook } from '../../../../hooks/useTableEditor'

const _validationSchema= Yup.object().shape({
       memberid: Yup.string()
         .max(200, 'Must be 200 characters or less'),
       status: Yup.string()
         .oneOf(['','Active','Inactive','New','Deleted']),
       emailid: Yup.string()
         .email('Invalid email address'),
       firstname: Yup.string()
          .max(200, 'Must be 200 characters or less'),
       lastname: Yup.string()
          .max(200, 'Must be 200 characters or less')
     })

const TechnicianRegistration = (props) => {
  const _initSearchedInfo = useMemo(() => {
    if (props.location && props.location.state && props.location.state.searchedInfo) {
      return props.location.state.searchedInfo
    } else {
      return {
        pageSize: 10,
        currPage: 1,
        memberid: '',
        status: '',
        emailid: '',
        firstname: '',
        lastname: ''
      }
    }
  }, [props.location])

  const _initSearchInfo = useMemo(() => {
    if (props.location && props.location.state && props.location.state.searchInfo) {
      return props.location.state.searchInfo
    } else {
      return {
        memberid: '',
        status: '',
        emailid: '',
        firstname: '',
        lastname: ''
      }
    }
  }, [props.location])

  const [message, setMessage] = useState('')
  const [searchedInfo, setSearchedInfo] = useState(_initSearchedInfo)
  const initialSearchInfo = _initSearchInfo

  const _submitForm = (values, { setSubmitting }) => {
    const newSearchInfo = {
      memberid: values.memberid,
      status: values.status,
      emailid: values.emailid,
      firstname: values.firstname,
      lastname: values.lastname
    }
   setSearchedInfo({
     ...searchedInfo,
     ...newSearchInfo,
     currPage: 1
   })
   setSubmitting(false)
  }


  const _deleteUser = useCallback((rowData) => {
    setMessage('Technician profile deleted successfully')
    editDeleteTable.setDeleteRecId(rowData.id)
  }, [])

  const {values, errors, setFieldValue, handleSubmit, handleChange, handleBlur, isSubmitting} = formik(initialSearchInfo, _validationSchema, _submitForm)
  const editDeleteTable = CreateEditDeleteHook('/ui/item-registration/addNew', _deleteUser)
  const _cellFormatter = editDeleteTable.cellFormatter

  const tableCols = useMemo(() => [
        {
          dataField: 'memberid',
          text: 'Member Id',
          sort: true
        },
        {
          dataField: 'firstname',
          text: 'Name',
          formatter: (cell, row) => `${row['firstname']}, ${row['lastname']}`,
          sort: true
        },
        {
          dataField: 'emailid',
          text: 'Email',
          sort: true
        },
        {
          dataField: 'mobileno',
          text: 'Mobile',
          sort: true
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true
        },
        {
          dataField: '',
          text: 'Actions',
          formatter: _cellFormatter,
          formatExtraData: {
            action: 'ED'
          },
          classes: () => {
            return 'lastTd';
          }
        }
      ], [_cellFormatter])

  const _createFetchUrl = () => {

    props.history.push(window.location.pathname, {
      searchInfo: {...values},
      searchedInfo: {...searchedInfo}
    })

    const requestBody = {
      body: {
        memberid: searchedInfo.memberid,
        status: searchedInfo.status,
        emailid: searchedInfo.emailid,
        firstname: searchedInfo.firstname,
        lastname: searchedInfo.lastname,
        membergroup: MEMBER_GROUP,
        pagesize: searchedInfo.pageSize,
        pageno: searchedInfo.currPage
      }
    };
    return requestBody;
  }

  const _callFetch = () => {
    props.dispatch(
      searchGeneral(
        'crm/v1/member/search/fields',
        _createFetchUrl(),
        'SEARCH_MEMBER_PROFILE_SUCCESS',
        'SEARCH_MEMBER_PROFILE_FAILURE'
      )
    );
  };

  useEffect(() => {
    _callFetch()
  }, [searchedInfo])

  const _renderBtn = useMemo(() => {
    const permissions = localStorage.getItem('permission');
    return (

      <div className='row pt-4'>
        <div className='col-md-6 col-sm-12'>
          <button
            className='page-header-btn w-100 icon_btn display-inline'
            onClick={handleSubmit}
            disabled={isSubmitting}
            type="submit"
            >
            <img
              alt='search'
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_search_blue.png`}
              className='tableImage'
            />
            Search
          </button>
        </div>
        <div className='col-md-6 col-sm-12'>
          <LinkButton
            to='/ui/technician-registration/addNew'
            className='page-header-btn w-100 icon_btn display-inline '
            onClick={event => event.preventDefault()}
            disabled={!permissions.split(',').includes('W')}
          >
            <img
              alt=''
              title='edit'
              className='tableImage'
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_addstore.svg`}
            />
            Add New
          </LinkButton>
        </div>
      </div>
    );
  }, [handleSubmit, isSubmitting])

  const _shdDeleteRec = useCallback(arg => {
    editDeleteTable.setDeleteRecId(-1)
    if (arg) {
      props.dispatch(deleteUserProfile(editDeleteTable.deleteRecId));
    }
  }, [editDeleteTable.deleteRecId, props])

  const _hideAlertModal = useCallback(e => {
    setMessage('')
    e.preventDefault();
    props.dispatch(crudReset());
    setSearchedInfo({...searchedInfo, currPage: 1})
  }, [props, searchedInfo])

  const _hideErrorAlertModal = useCallback((e, refresh) => {
    setMessage('')
    e.preventDefault();
    props.dispatch(crudReset());
    props.dispatch(resetAction());
  }, [props])

  const _renderSearchCiteria = useMemo(() =>
    (
      <React.Fragment>
        <div className='row'>
          <div className='form-group col-md-6 col-sm-12'>
            <label htmlFor='search_field' className='floatLeft'>
              Member Id
            </label>
            <input
              type='text'
              className='form-control'
              id='memberid'
              value={values.memberid}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className='form-group col-md-6 col-sm-12'>
            <label htmlFor='search_field' className='floatLeft'>
              Email Id
            </label>
            <input
              type='text'
              className={errors.emailid ? 'form-control' : 'error-control'}
              id='emailid'
              value={values.emailid}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className='invalid-feedback'>{errors.emailid}</div>
          </div>
        </div>
        <div className="row">
          <div className='form-group col-md-6 col-sm-12'>
            <label htmlFor='search_field' className='floatLeft'>
              First Name
            </label>
            <input
              type='text'
              className='form-control'
              id='firstname'
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className='form-group col-md-6 col-sm-12'>
            <label htmlFor='search_field' className='floatLeft'>
              Last Name
            </label>
            <input
              type='text'
              className='form-control'
              id='lastname'
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className='row'>
          <div className="form-group col-lg-4 col-sm-8">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              allowBlank={true}
              selectedOption={values.status}
              callbackChangeFilter={value => {setFieldValue("status", value, false)}}
            />
          </div>
        </div>
      </React.Fragment>
  ), [values, errors])

  const {
    userProfileList,
    errormsg: queryError,
    servererror: serverError
  } = props.queryData

  const { isSuccess, errormsg: crudError } = props.crudData;

  let errormsg = '';
  let refresh = false;
  if (serverError) {
    errormsg = serverError;
    refresh = false;
  }
  if (queryError) {
    errormsg = queryError;
    refresh = true;
  }
  if (crudError) {
    errormsg = crudError;
    refresh = true;
  }

  if (!userProfileList && !errormsg) {
    return <Loading />;
  }

  if (!userProfileList || props.queryData.apiPending) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className='form_height'>
        <div className='row'>
          <div className='col-md-9'>
            <h2 className='page-header'>
              Technician List
              <img
                alt=''
                title='Refresh'
                onClick={_callFetch}
                className='refreshtree'
                src={`${process.env.PUBLIC_URL}/assets/icons/Button-Refresh.png`}
              />
            </h2>
          </div>
        </div>
        <div className='row form-container'>
          <div className='col'>
            <form className='frmDiv' onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-lg-8 col-sm-12 noPadding noMargin'>
                  <h3 className='page-title'>Search Filter</h3>
                </div>
                {_renderSearchCiteria}
                <div className="title_devide"></div>
                {_renderBtn}
              </div>

              <div className='title_devide' />
              <div className='invalid-feedback'>{errormsg}</div>

              <SimpleTable
                columns={tableCols}
                rows={userProfileList}
                pageChangeCallback={(currPage) => {
                  setSearchedInfo({...searchedInfo, currPage: currPage})
                }}
                callback={(pageSize) => {
                  setSearchedInfo({...searchedInfo, currPage: 1, pageSize: pageSize})
                }}
                withoutSearchBar={true}
                withPageSize={searchedInfo.pageSize}
                withCurrPage={searchedInfo.currPage}
                withPageFeedback={true}
              />
            </form>
          </div>
        </div>

        <ConfirmAlert
          show={(editDeleteTable.deleteRecId !== -1)}
          isPrompt={true}
          children={
            <div className='deleteAlertDiv'>
              Are you sure you want to delete this technician profile?
              <div style={{ margin: '2em' }}>
                <button
                  className='btn btn-themes btn-rounded btn-sec'
                  onClick={() => _shdDeleteRec(false)}
                >
                  No
                </button>
                <button
                  className='btn btn-themes  btn-rounded deletBtn'
                  onClick={() => _shdDeleteRec(true)}
                >
                  Yes
                </button>
              </div>
            </div>
          }
        />
        <ConfirmAlert
          show={isSuccess}
          handleClose={_hideAlertModal}
          children={
            <div
              style={
                isSuccess
                  ? { padding: '2em', color: 'green' }
                  : { padding: '2em', color: 'red' }
              }
            >
              {isSuccess ? message : ''}
            </div>
          }
        />
        <ConfirmAlert
          show={errormsg}
          handleClose={event => _hideErrorAlertModal(event, refresh)}
          children={
            <div
              style={
                errormsg
                  ? { padding: '2em', color: 'red', fontWeight: '500' }
                  : { padding: '2em', color: 'red' }
              }
            >
              {errormsg ? `${errormsg}.` : ''}
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(TechnicianRegistration);
