import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LinkButton from "../../../../components/LinkButton";
// Local Imports
// Local Imports
import ImagePick from "../../../../components/ImagePick";
import {
  updateArticleInfoApi,
  createArticleInfoApi,
  crudReset,
  updateArticleMode
} from "../../../../actions/actions";
import ConfirmAlert from "../../../../components/ConfirmAlert";
import StatusComp from "../../../../components/StatusComp";
import { produce } from "immer";
import DynamicTableFAQ from "../../../../components/DynamicTableFAQ";
import SimpleSelect from "./../../../../components/SimpleSelect";

class AddAppConfig extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      storeid: "",
      articlecode: "",
      articlegroup: "",
      group: "",
      title: "",
      status: "New",
      images: {},
      language: "",
      langlist: {},
      formError: false,
      formErrorMsg: "",
      isEdit: false,
      parameters: [{ parametertype: "", parametervalue: "", id: 0 }],
      titleerror: ""
    };
  }

  componentWillReceiveProps = () => {
    const { languageConfig } = this.props.queryData;
    if (languageConfig.length > 0) {
      const list = languageConfig[0].additionalfields
      this.setState({
        langlist: list
      })
    }
    if (this._isEditMode()) {
      const { storeArray } = this.props.queryData;
      if (storeArray) {
        storeArray.storeid === this.state.storeid ? this.setState({ storelabel: storeArray.title }) :
        this.setState({ storelabel: this.state.storelabel})        
      };
      this.setState({ language: this.props.history.location.state.item.language });
    }
    else {
      const defLanguage = this.props.location.state.language;
      this.setState({ language: defLanguage }); 
    }
  };
  componentWillMount = () => {
    const defLanguage = this.props.loginData.language;
    this.setState({ language: defLanguage });
    if (this.props.location && this.props.location.state) {
      const rec = this.props.location.state.item;
      const isEdit = this.props.location.state.isEdit;
      if (rec) {
        const {
          id,
          storeid,
          title,
          articlecode,
          fields,
          status,
          images,
          articletype,
          articlegroup
        } = rec;
        //this.props.dispatch(getStoreById(storeid));

        this.setState(
          produce(draft => {
            draft.id = id ? id : "";
            draft.isEdit = isEdit;
            draft.title = title ? title : "";
            draft.storeid = storeid ? storeid : "";
            //draft.status = status ? status : [];
            draft.articletype = articletype || "";
            draft.articlecode = articlecode ? articlecode : "";
            draft.articlegroup = articlegroup ? articlegroup : "";
            draft.group = fields.group ? fields.group : "";
            draft.images = {
              image1: rec.hasOwnProperty("images") === true ? images.image1 : "",
              image2: rec.hasOwnProperty("images") === true ? images.image2 : "",
              image3: rec.hasOwnProperty("images") === true ? images.image3 : "",
              image4: rec.hasOwnProperty("images") === true ? images.image4 : "",
              image5: rec.hasOwnProperty("images") === true ? images.image5 : ""
            };
          })
        );
        if (fields) {
          let data = fields;

          var paramData = data.parameters.map((key, index) => {
            key.id = index;
            return key;
          });
          this.setState({ parameters: paramData });
        }
      }
    }
  };
  _renderErrorMessage = () => {
    const { isSuccess, errormsg } = this.props.crudData;
    const { formError, formErrorMsg } = this.state;
    if (errormsg && !isSuccess) {
      return (
        <div className="alert alert-danger" role="alert">
          {errormsg}
        </div>
      );
    } else if (formError && formErrorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          {formErrorMsg}
        </div>
      );
    }
    return;
  };
  arrayToObject = (array, value) => {
    var arr_param = [];
    var len = array.length;
    for (var i = 0; i < len; i++) {
      var item = array[i];

      let newitem = {
        parametertype: item.parametertype,
        parametervalue: item.parametervalue
      };

      arr_param.push(newitem);
    }
    return arr_param;
  };
  getCreatePayload = () => {
    const {
      storeid,
      title,
      articlecode,
      status,
      images,
      language,
      parameters,
      group,
      articlegroup
    } = this.state;
    const params = this.arrayToObject(parameters);

    let body = {
      storeid: storeid,
      title: title,
      language: language,
      articlecode,
      publishmode: "manual",
      articletype: "App Config",
      status: status,
      articlegroup,
      fields: {
        group,
        parameters: params,
      },
      images: {
        image1: images.image1 || "",
        image2: images.image2 || "",
        image3: images.image3 || "",
        image4: images.image4 || "",
        image5: images.image5 || ""
      }
    };

    if (this._isEditMode()) {
      body["id"] = this.state.id;
    }
    const payload = {
      body: body
    };
    return payload;
  };

  _createArticle = () => {
    if (this.validateForm()) {
      if (this.state.isEdit === true) {
        this.props.dispatch(
          updateArticleInfoApi(this.getCreatePayload(), this.state.id)
        );
        this._updateArticleMode();
      } else {
        this.props.dispatch(createArticleInfoApi(this.getCreatePayload()));
        this._updateArticleMode();
      }
    } else {
      this.setState({
        formError: true,
        formErrorMsg: "Clear all the errors to continue"
      });
      window.scrollTo(0, 0);
    }
  };
  _clearErrorMessages = () => {
    this.setState({
      formError: false,
      formErrorMsg: "",
      titleerror: "",
    });
  };
  validateForm = () => {
    this._clearErrorMessages();
    const { title } = this.state;
    let valid = true;
    if (title.trim() === "") {
      this.setState({
        titleerror: "Please enter title"
      });
      valid = false;
    }

    return valid;
  };
  _isEditMode = () => {
    return this.state.isEdit;
  };
  _updateArticleMode = () =>{
    const isEditMode = this._isEditMode();
    if(!isEditMode){
      const { articleMode } = this.props.location.state;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articleMode } }) 
      );
    }
    else {
      const { articletype } = this.props.location.state.item;
      return this.props.dispatch(
        updateArticleMode({ body: { mode: articletype } }) 
      );
    }
  }
  _renderMainButton = () => {
    const isEditMode = this._isEditMode();
    return (
      <React.Fragment>
        {!isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Save
          </button>
        )}
        {isEditMode && (
          <button
            type="submit"
            className="btn btn-themes btn-rounded"
            onClick={() => this._createArticle()}
          >
            Update
          </button>
        )}

         <LinkButton
          to="/ui/articles"
          className="btn btn-themes btn-rounded btn-sec link-sec-btn"
          onClick={() => this._updateArticleMode()}
        >
          {`Cancel`}
        </LinkButton>
      </React.Fragment>
    );
  };
  _setTenant = (p1, p2) => {
    this.setState({ storeid: p1, storelabel: p2 });
  };

  _renderFormArea = () => {
    return (
      <React.Fragment>
        <div className="row rowBottom">
          <div className="col-md-8 col-sm-12 noPadding noMargin">
            <form className="splitFrmDiv text-center ">
              {this._renderMainFormDetails()}
            </form>
          </div>
          <div className="col-md-3 col-sm-12 doubleCol">
            <form className="splitFrmDivSec text-center">
              {this._renderStatusDetails()}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderStatusDetails = () => {
    const { languageConfig } = this.props.queryData;
     return (
       <React.Fragment>
         <div className="row sub-title">Actions</div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="language" className="floatLeft">
              Language
            </label>
            <SimpleSelect
              objArray={this.state.langlist}
              callbackChangeFilter={value => {
                this.setState({ language: value });
              }}
              selectedOption={this.state.language}
              id="language"
            />
          </div>
         </div>
         <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="postal" className="floatLeft">
              Status
            </label>
            <StatusComp
              selectedOption={this.state.status}
              callbackChangeFilter={value => {
                this.setState({ status: value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-12 col-sm-12">
            <label htmlFor="sortorder" className="floatLeft ">
              Sortorder
            </label>
            <input
              type="number"
              className="form-control"
              id="sortorder"
              value={this.state.sortorder}
              onChange={event =>
                this.setState({ sortorder: event.target.value })
              }
            />           
          </div> 
        </div>
       </React.Fragment>
     )
  }

  _renderMainFormDetails = () => {
    const { languageConfig } = this.props.queryData;
    return (
      <React.Fragment>
        <div className=" row">
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="articletype" className="floatLeft">
                Article Type
              </label>
              <input
                type="text"
                className="form-control"
                id="articletype"
                value="App Config"
                disabled={true}
              />
            </div>
          <div className="form-group col-lg-6 col-sm-12">
            <label htmlFor="articlegroup" className="floatLeft">
              Article Group
            </label>
            <input
              type="text"
              className="form-control"
              id="articlegroup"
              value={this.state.articlegroup}
              onChange={event => {
                this.setState({ articlegroup: event.target.value });
              }}
            />
          </div> 
          </div>
          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="articlecode" className="floatLeft">
                Article Code
              </label>
              <input
                type="text"
                className="form-control"
                id="articlecode"
                value={this.state.articlecode}
                readOnly={true}
              />
            </div>
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="group" className="floatLeft">
                Group
              </label>
              <input
                type="text"
                className="form-control"
                id="group"
                value={this.state.group}
                onChange={event => {
                  this.setState({ group: event.target.value });
                }}
              />
            </div>
          </div>
          
          <div className="row">
             <div className="form-group col-lg-12 col-sm-12">
              <label htmlFor="title" className="floatLeft required">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={this.state.title}
                onChange={event => {
                  this.setState({ title: event.target.value });
                }}
              />
              <div className="invalid-feedback">{this.state.titleerror}</div>
            </div>
          </div>

        <div className="form-group col-lg-11 col-sm-12">
          <DynamicTableFAQ
            columns={[
              { dataFieldId: "parametertype", label: "Parameter Type", addRow: true },
              { dataFieldId: "parametervalue", label: "Parameter Value", addRow: true },
              {
                dataFieldId: "AD",
                label: ""
              }
            ]}
            rows={this.state.parameters}
            deleteRow={this.deleteParameterRow}
            addRow={this.handleParameterAddRow}
            handleInputChange={this.handleInputChange}
          />
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <label htmlFor="image1" className="floatLeft">
              Images
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-8 col-sm-12">
            <ImagePick
              setImages={this.setImages}
              images={this.state.images}
              limit={5}
            ></ImagePick>
          </div>
        </div>
      </React.Fragment>
    );
  };
  setImages = images => {
    this.setState({ images: images });
  };
  hideModal = e => {
    e.preventDefault();
    this.props.dispatch(crudReset());
  };
  handleParameterAddRow = e => {
    if (e) e.preventDefault();
    const rowLength = this.state.parameters.length;
    let lastele = this.state.parameters[rowLength - 1];
    const item = {
      parametertype: "",
      parametervalue: "",
      id: lastele.id + 1
    };
    this.setState(
      {
        parameters: [...this.state.parameters, item]
      },
      () => {
        console.log("Rows After Add", this.state.parameters);
      }
    );
  };

  handleInputChange = (event, dataField, row) => {
    let obj = {
      id: row.id,
      [dataField]: event.target.value
    };
    this.setState(
      produce(draft => {
        if (draft.parameters.length === 0) {
          draft.parameters.push(obj);
        } else {
          let found = false;
          draft.parameters.forEach(command => {
            if (command.id === obj.id) {
              Object.assign(command, obj);
              found = true;
            }
          });
          if (!found) {
            draft.parameters.push(obj);
          }
        }
      }),
      () => {}
    );
  };
  deleteParameterRow = row => {
    let rowId = row.id;
    const rowLength = this.state.parameters.length;
    if (rowLength > 1) {
      const updateValues = this.state.parameters.filter(value => rowId !== value.id);
      this.setState({ parameters: updateValues }, () => {});
    }
  };
  render() {
    const { isSuccess } = this.props.crudData;
    return (
      <React.Fragment>
        <div className="form_height">
          <div className="row">
            <div className="col-md-9">
              <h2 className="page-header">
                {this._isEditMode() === true ? "Edit  Article" : "Add  Article"}
              </h2>
            </div>
          </div>
          <div className="row form-container">
            <div className="col">
                {this._renderErrorMessage()}
                {this._renderFormArea()}
              <div className="row btn-container form-button">
                <div className="col-12">{this._renderMainButton()}</div>
              </div>
            </div>
          </div>
          <ConfirmAlert
            show={isSuccess}
            handleClose={this.hideModal}
            to="/ui/articles"
            children={
              <div style={{ padding: "2em", color: "green" }}>
                {this._isEditMode() === true
                  ? "Article updated successfully"
                  : "Article created successfully"}
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(AddAppConfig);
