import React from "react";
import { connect } from "react-redux";

import { headers, rows } from "./tableCol";
import FixedColTable from "../../../components/FixedColTable";
import StackedBarChart from "../../Charts/StackedBarChart";
import DoughnutChart from "./../../Charts/DoughnutChart";

function getData() {
  let data = [];

  return data;
}
class CampaignAnalysis extends React.PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: getData()
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.setInterval(() => {
      this.setState({
        data: getData()
      });
    }, 5000);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  _renderSelect = (formattedObjArray, selectedOption, dropDownChange) => {
    return (
      <select
        id="statusComp"
        className={`form-control chartSelect alignRight `}
        value={selectedOption}
        onChange={dropDownChange}
      >
        {formattedObjArray !== undefined ? (
          formattedObjArray.map((t, i) => (
            <option key={i} value={t.id}>
              {t.name}
            </option>
          ))
        ) : (
          <option>No option</option>
        )}
      </select>
    );
  };
  _selectOnChange = value => {
    return console.log(value);
  };
  _renderQuickViewDiv = () => {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12 ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_coupon1.svg`}
            />
            <p className="card2Text1">Coupon Utilization</p>
            <p className="card2Text2">776</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_coupon2.svg`}
            />
            <p className="card2Text1">Types Of Coupon</p>
            <p className="card2Text2">3</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_coupon3.svg`}
            />
            <p className="card2Text1">Coupon Expired</p>
            <p className="card2Text2">2</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_coupon4.svg`}
            />
            <p className="card2Text1">Coupon Not-Redeemed</p>
            <p className="card2Text2">2</p>
          </div>
        </div>
      </div>
    );
  };

  _renderChartDiv = () => {
    return (
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-7 col-sm-12 alignLeft">
                Coupon Status
              </div>
              <div className=" col-lg-5 col-sm-12 noPadding noMargin aalignRight">
                {this._renderSelect(
                  [
                    { id: "welcome", name: "$5 Welcome Coupon" },
                    { id: "$5", name: "$5 Redeem Coupon" },
                    { id: "$10", name: "$10 Redeem Coupon" }
                  ],
                  "welcome",
                  this._selectOnChange
                )}
              </div>
            </div>
            <div className="title_devide"></div>
            <DoughnutChart
              labels={["Created", "Redeemed", "Not-Redeemed", "Expired"]}
              colors={["#354656", "#5387EC", "#AFCEE0", "#D9D9D9"]}
              data={[50, 30, 10, 10]}
            />
          </div>
        </div>
        <div className="col-md-7 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-8 col-sm-12 alignLeft">
                Coupon Status
              </div>
              <div className=" col-lg-4 col-sm-12 noPadding noMargin aalignRight">
                {this._renderSelect(
                  [
                    { id: "welcome", name: "$5 Welcome Coupon" },
                    { id: "$5", name: "$5 Redeem Coupon" },
                    { id: "$10", name: "$10 Redeem Coupon" }
                  ],
                  "welcome",
                  this._selectOnChange
                )}
              </div>
            </div>
            <div className="title_devide"></div>
            <StackedBarChart
              labels={[
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC"
              ]}
              data={[
                {
                  label: "Created",
                  data: [10, 20, 10, 20, 30, 20, 10, 10, 20, 10, 20, 10],
                  fill: false,
                  borderColor: "#354656", // Add custom color border (Line)
                  backgroundColor: "#354656",
                  borderWidth: 1
                },
                {
                  label: "Redeemed",
                  data: [10, 20, 10, 20, 30, 20, 10, 10, 20, 10, 20, 10],
                  fill: false,
                  borderColor: "#5387EC", // Add custom color border (Line)
                  backgroundColor: "#5387EC",
                  borderWidth: 1
                },
                {
                  label: "Not-Redeemed",
                  data: [10, 20, 10, 20, 30, 20, 10, 10, 20, 10, 20, 10],
                  fill: false,
                  borderColor: "#AFCEE0", // Add custom color border (Line)
                  backgroundColor: "#AFCEE0",
                  borderWidth: 1
                },
                {
                  label: "Expired",
                  data: [10, 20, 10, 20, 30, 20, 10, 10, 20, 10, 20, 10],
                  fill: false,
                  borderColor: "#D9D9D9", // Add custom color border (Line)
                  backgroundColor: "#D9D9D9",
                  borderWidth: 1
                }
              ]}
            ></StackedBarChart>
          </div>
        </div>
      </div>
    );
  };
  _renderTable = () => {
    return (
      <div className="row  noPadding noMargin">
        <div className="col-12 noPadding noMargin">
          <div className="title_devide"></div>
          <FixedColTable
            columns={headers}
            rows={rows}
            tableheading="Coupon Utilisation"
            children={this._renderSelect(
              [
                { id: "welcome", name: "$5 Welcome Coupon" },
                { id: "$5", name: "$5 Redeem Coupon" },
                { id: "$10", name: "$10 Redeem Coupon" }
              ],
              "welcome",
              this._selectOnChange
            )}
          ></FixedColTable>
        </div>
      </div>
    );
  };
  _renderForm = () => {
    return (
      <form>
        {this._renderQuickViewDiv()}
        {this._renderChartDiv()}
        {this._renderTable()}
      </form>
    );
  };
  render() {
    return (
      <div className="form_height">
        <div className="row form-container">
          <div className="col">{this._renderForm()}</div>
        </div>
      </div>
    );
  }
}

CampaignAnalysis.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(CampaignAnalysis);
